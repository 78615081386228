/**
 * Connected modal component to prompt a user to register with TAFS
 * @module components/pure/modals/TAFSRegister
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/pure/TAFS
 * @property {object} props
 * @property {object} initialValues - initial values to be passed to the TAFS registration form
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';

import Modal from 'components/hoc/Modal';
import TAFS from 'components/pure/TAFS';


const TAFSRegister = ({ closeModal, initialValues, ...rest }) =>
  <div>
    <div className='modal-body'>
      <TAFS closeModal={closeModal} initialValues={initialValues} {...rest} />
    </div>
  </div>
  ;

TAFSRegister.propTypes = {
  closeModal: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
};

export default compose(
  Modal,
)(TAFSRegister);
