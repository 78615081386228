import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import { lifecycle, mapProps, withHandlers, withState } from 'recompose';
import { concat, get, uniqBy } from 'lodash';
import moment from 'moment';

import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';

import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';
import PermissionsError from 'datatypes/PermissionsError';

import { create } from 'actions/resource/debtorpayments';
import { openModal } from 'actions/ui';
import financespaymentsPaginatedResource from 'actions/admin/financespayments';
import DropdownList from 'react-widgets/lib/DropdownList';
import commaQuery from 'helpers/commaQuery';
import Resource from 'components/hoc/Resource';
import { generateReport } from 'actions/invoice';

const FORM_NAME = 'DebtorPayments';
const PAYMENT_ITEM_ID_SELECTOR = 'data.id';
const INVOICE_BALANCE_INPUT_NAME = 'outstanding_balance_';
const valueSelector = formValueSelector(FORM_NAME);
const getInvoiceBalanceInputName = invoice => `${INVOICE_BALANCE_INPUT_NAME}${invoice.id}`;

const FOLLOWUP_STATUS_OPTIONS = [
  {
    text: 'Resubmit Invoice',
    value: 'resubmit_invoice',
  },
  {
    text: 'Document Issue',
    value: 'document_issue',
  },
  {
    text: 'Promised To Pay',
    value: 'promise_to_pay',
  },
  {
    text: 'Talked To Customer',
    value: 'talked_to_customer',
  },
];

const RECEIVABLES_STATUS_OPTIONS = [
  {
    text: '--',
    value: null,
  },
  {
    text: 'Open',
    value: 'open',
  },
  {
    text: 'Paid',
    value: 'paid',
  },
  {
    text: 'Short Paid',
    value: 'short_paid',
  },
  {
    text: 'Write Off',
    value: 'write_off',
  },
  {
    text: 'Double Paid',
    value: 'double_paid',
  },
  {
    text: 'Client Settled',
    value: 'client_settled',
  },
  {
    text: 'Over Paid',
    value: 'over_paid',
  },
];

const StatusFilter = ({ onChange }) => (
  <div
    style={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
    <div
      style={{
        marginLeft: '12px',
      }}
      >
      <h4>Status:</h4>
    </div>
    <DropdownList
      style={{
        minWidth: '11em',
        marginLeft: '12px',
      }}
      data={RECEIVABLES_STATUS_OPTIONS}
      defaultValue={null}
      valueField='value'
      textField='text'
      onChange={onChange}
    />
  </div>
);

class SearchBox extends Component {
  constructor(props) {
    super(props);

    this.state = {
      field: '',
      dropdown: 'invoice_number',
    };

    this.onTextChange = this.onTextChange.bind(this);
    this.onDropdownChange = this.onDropdownChange.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }

  onTextChange(event) {
    this.setState({
      field: event.target.value,
    });
    this.props.getQueryParams({ [this.state.dropdown]: event.target.value });
  }

  onDropdownChange(event) {
    this.setState({
      dropdown: event.value,
    });
    this.props.getQueryParams({ [event.value]: this.state.field });
  }

  onSearch() {
    const query = {};
    const { field, dropdown } = this.state;

    if (field) {
      query[dropdown] = commaQuery(field);
    }
    this.props.onSearch(query);
  }

  render() {
    return (
      <div
        style={{
          display: 'flex',
        }}
        >
        <input
          className='form-control'
          type='text'
          placeholder='Search for'
          value={this.state.field}
          onChange={this.onTextChange}
          onKeyPress={event => {
            if (event.key === 'Enter') {
              this.onSearch();
            }
          }}
        />
        <div>
          <DropdownList
            style={{
              minWidth: '11em',
              marginLeft: '12px',
            }}
            data={[
              {
                text: 'Load Number',
                value: 'user_load_number',
              },
              {
                text: 'Invoice Number',
                value: 'invoice_number',
              },
              {
                text: 'Check Number',
                value: 'reference_number',
              },
              {
                text: 'Client Name',
                value: 'factoring_company_profile_name',
              },
            ]}
            defaultValue='invoice_number'
            valueField='value'
            textField='text'
            onChange={this.onDropdownChange}
          />
        </div>
        <div>
          <button
            style={{
              marginLeft: '12px',
            }}
            className='btn btn-orange'
            type='button'
            onClick={this.onSearch}
            >
            Search
          </button>
        </div>
      </div>
    );
  }
}

const PaymentsTableComponents = createTableItems(({ openModal, data, editableOutstandingBalance, clickView }) => {
  return [
    data.invoice_number,
    formatPennies(data.amount),
    editableOutstandingBalance ? (
      <Field name={getInvoiceBalanceInputName(data)} component={PennyInput} style={{ minWidth: '6em' }} />
    ) : (
      formatPennies(data.outstanding_balance)
    ),
    data.factoring_company_profile.name,
    data.user_load_number,
    get(FOLLOWUP_STATUS_OPTIONS.find(option => option.value === data.response_category), 'text', ''),
    data.followup_date ? moment(data.followup_date).format('l') : '',
    data.receivable_age ? `${data.receivable_age} days` : '',
    get(RECEIVABLES_STATUS_OPTIONS.find(option => option.value === data.receivable_status), 'text', ''),
    <a href={`/admin/factoring/transaction/${data.id}`} target='_blank'>
      <button className='btn-more' type='button'>View Funding Request</button>
    </a>,
    data.receivable_notes ? <button type='button' className='btn-more' onClick={() => openModal(data.receivable_notes)}>View Notes</button> : '',
  ];
});

const DebtorPaymentsForms = ({
  amountValue,
  debtorId,
  outstandingBalancesTotal,
  outstandingBalancesValuesTotal,
  items,
  selectedInvoices,
  debtor,
  setStatusFilter,
  statusFilter,
  searchQuery,
  setSearchQuery,
  handleSubmit,
  setSelectedInvoices,
  formType,
  setFormType,
  submitting,
  isFetching,
  clear,
  fetch,
  fetchNext,
  sort,
  currentOrder,
  openModal,
  clearPayments,
  openLineItemModal,
  selectedInvoiceBalanceValues,
  generateReport,
  openErrorModal,
}) => {
  const isPaymentsForm = formType === 'payments';
  const isNotesForm = formType === 'notes';

  const formButtonClass = 'btn btn-default';
  const selectedFormButtonClass = 'btn btn-primary';

  const totalOutstandingBalances = outstandingBalancesValuesTotal;

  const disableSubmit = !selectedInvoices.length || (isPaymentsForm && amountValue !== totalOutstandingBalances);

  const debtorName = get(debtor, 'data.company_name');
  const mc = get(debtor, 'data.mc');
  const dot = get(debtor, 'data.dot');

  return (
    <form onSubmit={handleSubmit}>
      <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0 10px' }}>
        <h3 style={{ color: '#3ca8ff', margin: '0 1em 0 0' }}>
          <b>Debtor Payment</b> - <b>{ debtorName }</b> - <b>{mc ? `MC: ${mc}` : `DOT: ${dot}`}</b>
        </h3>
        <div className='btn-group'>
          <button
            type='button'
            className={isPaymentsForm ? selectedFormButtonClass : formButtonClass}
            style={{ cursor: 'pointer' }}
            onClick={() => setFormType('payments')}
            >
            Add Payments
          </button>
          <button
            type='button'
            className={isNotesForm ? selectedFormButtonClass : formButtonClass}
            style={{ cursor: 'pointer' }}
            onClick={() => {
              setFormType('notes');
            }}
            >
            Add Notes
          </button>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='col-md-6' style={!isPaymentsForm ? { display: 'none' } : {}}>
          <Field
            name='category'
            component={LabeledDropdown}
            label='Type *'
            data={[
              {
                text: 'Check',
                value: 'check',
              },
              {
                text: 'ACH',
                value: 'ach',
              },
              {
                text: 'Wire',
                value: 'wire',
              },
            ]}
          />
          <Field name='sch' component={LabeledInput} label='Ref Number *' placeholder='xxxx' />
          <div className={Number.isFinite(Number(amountValue)) && disableSubmit ? 'has-warning' : !disableSubmit ? 'has-success' : ''}>
            <Field name='amount' component={PennyInput} label='Amount *' />
          </div>
          {Number.isFinite(Number(amountValue)) &&
            disableSubmit && <p className='help-block text-warning'>Warning: amount should be equal to the SUM of selected invoices!</p>}
        </div>
        <div className='col-md-6' style={!isNotesForm ? { display: 'none' } : {}}>
          <Field
            name='response_category'
            component={LabeledDropdown}
            label='Followup Status'
            data={FOLLOWUP_STATUS_OPTIONS}
          />
          <Field name='followup_date' component={DateTimeInput} pickerOptions={{ time: false }} label='Next Followup Date' />
        </div>
        <div className='col-md-6'>
          <Field
            name='notes'
            label='Notes/Details'
            component={GrowableInput}
            props={{
              Component: LabeledInput,
              ComponentClass: 'textarea',
              placeholder: 'Enter Payment Details (optional).',
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '5%',
        }}
        >
        <h3 style={{ color: '#3ca8ff' }}>
          <b>Invoices</b>
        </h3>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          >
          <SearchBox
            onSearch={query => {
              clearPayments();
              setSearchQuery(query);
              fetch({
                receivable_status: statusFilter,
                id: debtorId,
                ...query,
              });
            }}
            getQueryParams={query => {
              setSearchQuery(query);
            }}
          />
          <StatusFilter
            onChange={({ value }) => {
              setStatusFilter(value);
              clearPayments();
              fetch({
                receivable_status: value,
                id: debtorId,
                ...searchQuery,
              });
            }}
          />
          <div
            style={{
              marginLeft: '12px',
            }}
            >
            <button className=' pull-right btn btn-orange' type='submit' disabled={submitting || disableSubmit}>
              Submit
            </button>
          </div>
          <div
            style={{
              marginLeft: '12px',
              marginRight: '12px',
            }}
            >
            <button
              className=' pull-right btn btn-green' onClick={event => {
                event.preventDefault();
                openLineItemModal(selectedInvoices, setSelectedInvoices, async () => {
                  clearPayments();
                  setSelectedInvoices([]);
                  await fetch({ id: debtorId, receivable_status: statusFilter, ...searchQuery });
                });
              }}
                                                    >
              Settle
            </button>
          </div>
          <button
            style={{ backgroundColor: '#26a69a', marginLeft: 5, color: 'white' }}
            className='btn'
            type='button'
            onClick={async e => {
              e.preventDefault();
              e.stopPropagation();
              try {
                const res = await generateReport({ debtor_id: debtorId, receivable_status: statusFilter, ...searchQuery });
                window.open(res.download_url);
              }
              catch (err) {
                console.warn(err);
                openErrorModal('Failed to fetch CSV data :(');
              }
            }}
            >
            Export
            <MaterialIcon name='attach_file' />
          </button>
        </div>
      </div>
      <div style={{ marginTop: '10px' }}>
        <ResponsiveTable
          containerProps={{
            className: 'col-fill scrollable',
          }}
          isFetching={isFetching}
          fetchNext={fetchNext}
          sortable={true}
          selectable={true}
          idSelector={PAYMENT_ITEM_ID_SELECTOR}
          onItemsSelect={item => {
            if (item.length > selectedInvoices.length) {
              setSelectedInvoices(uniqBy(concat(item, selectedInvoices), item => item.data.id));
            }
            else {
              setSelectedInvoices(item);
            }
          }}
          currentOrder={currentOrder}
          sort={async (...args) => {
            await fetch({ id: debtorId, receivable_status: statusFilter, ...searchQuery });
            await sort(...args);
          }}
          headers={[
            { text: 'Invoice #', ordering: 'invoice_number' },
            { text: 'Invoice Amt.', ordering: 'amount' },
            { text: 'Amount to Pay', ordering: 'outstanding_balance' },
            { text: 'Client Name', ordering: false },
            { text: 'Load #', ordering: false },
            { text: 'Response Category', ordering: 'response_category' },
            { text: 'Next Followup', ordering: 'followup_date' },
            { text: 'Age', ordering: '-receivable_age' },
            { text: 'Status', ordering: false },
            { text: 'Funding Request', ordering: false },
            { text: 'Notes', ordering: false },
          ]}
          placeholder='No results'
          TableItem={PaymentsTableComponents.TableItem}
          BlockTableItem={PaymentsTableComponents.BlockTableItem}
          items={uniqBy(concat(selectedInvoices, items), item => item.data.id).map(item => {
            return ({
              ...item,
              openModal,
              editableOutstandingBalance: selectedInvoices.map(invoice => invoice.data.id).includes(item.data.id),
            });
          })}
        />
      </div>
    </form>
  );
};

export default compose(
  withState('selectedInvoices', 'setSelectedInvoices', []),
  withState('formType', 'setFormType', 'payments'),
  withState('statusFilter', 'setStatusFilter', null),
  withState('searchQuery', 'setSearchQuery', {}),
  mapProps(ownProps => ({
    ...ownProps,
  })),
  connect(
    (state, ownProps) => ({
      ...ownProps,
      isFetching: state.admin.financespayments.isFetching,
      items: state.admin.financespayments.ids.map(id => state.resource.financespayment[id]),
      currentOrder: state.admin.financespayments.ordering,
      debtorId: ownProps.id,
      amountValue: valueSelector(state, 'amount'),
      outstandingBalancesTotal: ownProps.selectedInvoices.reduce((agg, invoice) => {
        return agg + get(invoice, 'data.outstanding_balance', 0);
      }, 0),
      selectedInvoiceBalanceValues: ownProps.selectedInvoices.reduce((agg, invoice) => {
        agg[get(invoice, 'data.id')] = valueSelector(state, getInvoiceBalanceInputName(invoice.data)) || 0;
        return agg;
      }, {}),
      outstandingBalancesValuesTotal: ownProps.selectedInvoices.reduce((agg, invoice) => {
        return agg + (valueSelector(state, getInvoiceBalanceInputName(invoice.data)) || 0);
      }, 0),
      initialValues: concat(ownProps.selectedInvoices.map(invoice => invoice.data.id), state.admin.financespayments.ids).reduce((agg, id) => {
        const invoice = state.resource.financespayment[id];
        agg[getInvoiceBalanceInputName(invoice.data)] = get(invoice, 'data.outstanding_balance');
        return agg;
      }, {
        amount: 0.0,
        category: 'check',
      }),
    }),
    (dispatch, props) => ({
      openModal: message => dispatch(openModal('notes', { message })),
      clearPayments: () => dispatch(financespaymentsPaginatedResource.clear()),
      fetch: (...args) => dispatch(financespaymentsPaginatedResource.fetch(...args)),
      fetchNext: (...args) => dispatch(financespaymentsPaginatedResource.fetchNext(...args)),
      sort: arg => {
        return dispatch(financespaymentsPaginatedResource.sort(arg));
      },
      openErrorModal: message => dispatch(openModal('error', {
        message,
      })),
      generateReport: props => dispatch(generateReport(props)),
      openLineItemModal: (invoices, setInvoices, done) => dispatch(openModal('debtorpaymentlineitems', { invoices, done, debtorId: props.id })),
    })
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    async onSubmit(
      fields,
      dispatch,
      {
        fetch,
        statusFilter,
        searchQuery,
        formType,
        selectedInvoices,
        selectedInvoiceBalanceValues,
        debtorId,
        reset,
        clearPayments,
        setSelectedInvoices,
      }
    ) {
      let requestData = {};
      if (formType === 'payments') {
        requestData = {
          notes: fields.notes,
          amount: fields.amount,
          category: fields.category,
          sch: fields.sch,
          funding_requests: selectedInvoices.map(invoice => ({
            id: invoice.data.id,
            amount_applied: selectedInvoiceBalanceValues[invoice.data.id],
          })),
        };
      }
      else {
        requestData = {
          notes: fields.notes,
          followup_date: fields.followup_date ? fields.followup_date.format() : undefined,
          response_category: fields.response_category,
          funding_requests: selectedInvoices.map(invoice => ({
            id: invoice.data.id,
            amount_applied: invoice.data.outstanding_balance,
          })),
        };
      }

      try {
        await dispatch(create(requestData, debtorId));
        dispatch(openModal('success', { message: 'Submitted Successfully' }));
      }
      catch (err) {
        if (err instanceof PermissionsError) {
          dispatch(openModal('error', { message: `${err.message.details.params[0].reason}` }));
        }
        else {
          dispatch(openModal('error', { message: 'Error submitting.' }));
        }
      }
      finally {
        reset();
        clearPayments();
        setSelectedInvoices([]);
        fetch({ id: debtorId, receivable_status: statusFilter, ...searchQuery });
      }
    },
    validate(fields, { formType }) {
      if (formType === 'notes') {
        return validate(fields, {
          response_category: {
            presence: true,
          },
        });
      }
      return validate(fields, {
        category: {
          presence: true,
        },
        sch: {
          presence: true,
          format: {
            pattern: /^[0-9A-Za-z\-]+$/,
          },
        },
        amount: {
          presence: true,
          numericality: {
            onlyInteger: true,
            strict: true,
            greaterThanOrEqualTo: 1,
          },
        },
      });
    },
  }),
  Resource('factoringdebtor', {
    idPropName: ['debtorId'],
    resourcePropName: 'debtor',
  }),
  lifecycle({
    componentDidMount() {
      this.props.fetch({ id: this.props.debtorId, receivable_status: this.props.statusFilter, ...this.props.searchQuery });
    },
  }),
)(DebtorPaymentsForms);
