import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';

import states from 'helpers/states';
import formatPennies from 'helpers/formatPennies';

import docTitle from 'components/hoc/docTitle';
import Resource from 'components/hoc/Resource';
import ResourceDetails from 'components/hoc/ResourceDetails';
import Header from 'components/pure/Header';
import ResponsiveContainer from 'components/pure/ResponsiveContainer';
import UserTable from 'components/pure/PureUserTable';
import SocialMediaShareButtons from 'components/pure/SocialMediaShareButtons';
import TruckMobile from 'components/container/TruckMobile';
import TruckDetailsTable from 'components/container/TruckDetailsTable';
import { TruckQaCollection } from 'components/container/QaCollection';
import TruckDetailsMap from 'components/container/TruckDetailsMap';
import SignUpButtonLink from 'components/container/SignUpButtonLink';
import UpgradeToViewButton from 'components/container/UpgradeToViewButton';
import ButtonSaveTruck from 'components/container/ButtonSaveTruck';
import ButtonRefresh from 'components/container/ButtonRefresh';
import CenteredColumn from 'components/pure/CenteredColumn';
import getSelf from 'selectors/getSelf';

export default compose(
  mapProps(({ routeParams: { id } }) => ({ id })),
  docTitle(props => `Truck Details - ${props.id}`),
  connect(
    state => ({
      self: getSelf(state),
    }),
    {},
  ),
  Resource('truck'),
  ResourceDetails,
)(({ item, self }) =>
  <CenteredColumn xs={12} sm={10}>
    <Header className='clearfix' style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ marginRight: '1em' }}>Truck Details</div>
      <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
        <div>
          {self && item.data.user_id === self.data.id ? null : <ButtonSaveTruck id={item.data.id} />}
          {self && item.data.user_id === self.data.id ? <ButtonRefresh type='truck' id={item.data.id}>Refresh Age</ButtonRefresh> : null}
        </div>
        <SocialMediaShareButtons
          title={`Find Available Trucks in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state})`}
          description={`Find Available Trucks or Empty Trucking Capacity in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state})`}
        />
      </div>
    </Header>
    <SignUpButtonLink style={{ marginBottom: '1em' }} />
    <ResponsiveContainer
      mobile={<TruckMobile id={item.data.id} />}
      desktop={
        <div>
          <TruckDetailsTable ids={[item.data.id]} />
          <UserTable
            items={[{
              item: item.data.user,
              truck_id: item.data.id,
            }]}
            isShipperOnly={item.data.user_type === 'shipper'}
            isTruckPost={true}
          />
        </div>
      }
    />
    <div>
      <Header>
        Target Rate:
        {' '}
        {
          get(self, ['data', 'permissions', 'is_subscribed']) || get(self, ['data', 'permissions', 'is_trialing']) ?
            item.data.targetRate ?
              `${formatPennies(item.data.targetRate)} Per Mile`
              :
              'None'
            :
              <UpgradeToViewButton />
        }
      </Header>
    </div>
    <TruckQaCollection id={item.data.id} />
    <Header>Map:</Header>
    <TruckDetailsMap id={item.data.id} />
  </CenteredColumn>
);

