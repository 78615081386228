import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';

import { inferTruckRouteType, isWestOf } from 'helpers';

import { setViewed } from 'actions/resource/truck';
import { fetchNext } from 'actions/search/truck';

import consolidatePoints from 'components/hoc/consolidatePoints';
import PaginatedGoogleMap from 'components/pure/PaginatedGoogleMap';
import { TruckIcon, TruckIconLeft } from 'components/pure/GoogleMap';


const placeholder = () =>
  <div style={{ textAlign: 'center' }}>
    <div style={{ marginTop: '10%' }} className='help-block'>Too many results to display on Map View. Please refine your search.</div>
  </div>
  ;

export default compose(
  connect(
    state => ({
      items: state.search.truck.ids.map(id => state.resource.trucksearch[id]),
      amount: state.search.truck.ids.length,
      hasNext: Boolean(state.search.truck.next),
      isFetching: state.search.truck.isFetching,
    }),
    {
      setViewed,
      fetchNext,
    },
  ),
  branch(
    ({ items }) => items.length > 500,
    renderComponent(placeholder),
    Component => Component,
  ),
  shouldUpdate((props, nextProps) => (
    props.amount !== nextProps.amount ||
    props.isFetching !== nextProps.isFetching ||
    !shallowEqual(props.items, nextProps.items)
  )),
  consolidatePoints({
    createPointFromItem: (item, props) => ({
      position: item.data.origin_coords.toLatLng(),
      icon: item.data.destination_coords && isWestOf(item.data.origin_coords.toLatLng(), item.data.destination_coords.toLatLng()) ? TruckIconLeft : TruckIcon,
      onClick: () => {
        browserHistory.push(`/truck/${item.data.id}`);
        props.setViewed(item.data.id);
      },
    }),
    createListItemFromItem: item => {
      const { destination } = inferTruckRouteType(item.data);
      return `Going to ${destination}`;
    },
  }),
)(({ items, push, setViewed, ids, ...rest }) =>
  <PaginatedGoogleMap
    {...rest}
    circles={items.map(item => ({
      ...item.data.origin_coords.toLatLng(),
      radius: item.data.origin_radius,
    }))}
  />
);
