import React from 'react';
import { compose } from 'redux-v3';
import { closeModal, openModal } from 'actions/ui';
import { connect } from 'react-redux-v5';

import colors from 'styles/colors.json';
import MaterialIcon from 'components/pure/MaterialIcon';

export default compose(
    connect(null, { openModal, closeModal })
)(({ closeModal, openModal, invoices, action, closeAction }) => (
    <div>
        <div className='modal-header' style={{ backgroundColor: colors.LIGHT_ORANGE }}>
            <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
            <h4 className='modal-title'>
                <div>
                    <MaterialIcon name='warning' style={{ verticalAlign: 'middle' }} />
                    {'\u00a0'}
                    <span>Warning: Duplicate PO#</span>
                </div>
            </h4>
        </div>
        <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
            <p>the PO# you are attempting to submit matches the PO# for other invoices on your account.</p>
            <p> If this was unintentional you can click View Invoices below to see which of your other invoices have this PO# associtaed with them.</p>
            <p>Otherwise click Proceed.</p>
        </div>
        <div className='modal-footer'>
            <button
                className='btn btn-default'
                onClick={() => {
                    closeAction();
                    setTimeout(() => closeModal(), 200);
                    openModal('PONumberInvoiceModal', { poRelatedInvoice: invoices })
                }}
            >
                View Invoices
            </button>
            <button style={{ marginLeft: '8px' }} className='btn btn-default' onClick={action}>Proceed</button>
        </div>
    </div>
));
