/**
 * Component for a generic warning modal
 * @module components/pure/modals/Warning
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Generic
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalGeneric component
 */
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Modal from 'components/hoc/Modal';
import ModalGeneric from './Generic';

export const ModalWarning = compose(
  mapProps(props => ({
    headerStyle: {
      backgroundColor: '#f0ad4e',
      color: 'white',
    },
    headerIcon: 'warning',
    headerChildren: 'Warning',
    ...props,
  })),
  Modal,
)(ModalGeneric);

export default ModalWarning;
