import React from 'react';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';


const AlertFrequencyDropdown = props => (
  <LabeledDropdown
    {...props}
    label='Alert Frequency'
    data={[
      { value: 0, text: 'Real Time' },
      { value: 1, text: 'Every 2 hours (default)' },
      { value: 2, text: 'Every 4 hours' },
    ]}
  />
);

export default AlertFrequencyDropdown;
