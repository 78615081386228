import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import getSelfType from 'selectors/getSelfType';

export default compose(
  connect(
    state => {
      const userType = getSelfType(state);
      return {
        userType,
      };
    },
    {},
  ),
);
