import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringclientauditlog;

import factoringclientauditlogResource from 'actions/resource/factoringclientauditlog';


export default function factoringclientauditlogResourceReducer(state = initialState, action) {
  state = factoringclientauditlogResource.reduce(state, action);
  return state;
}
