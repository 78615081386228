import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import MaterialIcon from 'components/pure/MaterialIcon';
import colors from 'styles/colors.json';
import { connect } from 'react-redux-v5';
import { openModal } from 'actions/ui';

const styles = {
    labelStyle: {
        fontSize: 14,
        textTransform: 'capitalize !important',
    },
}

function AddMoneyButton({ openModal, fetchWalletPendingTransaction }) {
    return (
        <RaisedButton
            label='Add Money'
            icon={<MaterialIcon name='account_balance' size={15} style={{ color: colors.WHITE }} />}
            labelPosition='after'
            labelColor={colors.WHITE}
            backgroundColor={colors.ORANGE}
            labelStyle={styles.labelStyle}
            onClick={() => openModal('WalletDepositReceivable', { isStripeDeposit: false, fetchWalletPendingTransaction })}
        />
    )
}

export default connect(
    () => {},
    { openModal }
)(AddMoneyButton)