import React from 'react';
import cs from 'classnames';
import get from 'lodash/get';

const MaterialIcon = ({
  name,
  size,
  spin = false,
  flipped = false,
  ...rest
}) => (
  <i
    {...rest}
    className={cs('MaterialIcon', rest.className, {
      'MaterialIcon-spin': spin,
    })}
    style={{
      ...rest.style,
      fontSize: size || (rest.style && rest.style.fontSize ? rest.style.fontSize : 'inherit'),
      transform: flipped ? 'scaleX(-1)' : get(rest, 'style.transform', undefined),
    }}
    >
    {name}
  </i>
);

export default MaterialIcon;
