import React from 'react';
import { compose } from 'redux-v3';

import docTitle from 'components/hoc/docTitle';


export default compose(
  docTitle('Settings'),
)(({ children }) =>
  <div className='full-height'>{children}</div>
);
