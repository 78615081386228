import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';

import { USER_TYPE } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import validateAddress from 'helpers/validateAddress';
import { openModal } from 'actions/ui';
import { create } from 'actions/resource/factoringpayment';
import SubmissionError from 'datatypes/error/SubmissionError';

import nothingIf from 'components/hoc/nothingIf';
import Header from 'components/pure/Header';
import ProTip from 'components/container/ProTip';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import LengthInput from 'components/pure/form/inputs/LengthInput';
import TrailerInput from 'components/pure/form/inputs/TrailerInput';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import Spinner from 'components/pure/Spinner';
import formatError from 'helpers/formatError';
import _ from 'lodash';
import convertToString from 'helpers/formatCategoriesAttachments';
import getSelf from 'selectors/getSelf';

const FORM_NAME = 'FactoringRequestPaymentForm';
const selector = formValueSelector(FORM_NAME);
export default compose(
  connect(
    state => {
      let amount_to_be_paid = '$0.00';
      const amount = selector(state, 'amount');
      const use_wire_transfer = selector(state, 'use_wire_transfer');
      const wire_fee = use_wire_transfer ? 2500 : 0;
      const factoring_discount_rate = selector(state, 'factoring_discount_rate');
      if (amount) {
        let fee = amount * (factoring_discount_rate / 100);
        if (fee < 2000) {
          fee = 2000;
        }
        amount_to_be_paid = formatPennies(amount - fee - wire_fee);
      }
      return {
        considering_creating_invoice: selector(state, 'considering_creating_invoice'),
        use_wire_transfer,
        factoring_discount_rate,
        amount_to_be_paid,
        self: getSelf(state),
        amount
      };
    },
    (dispatch, { factoring }) => ({
      openMoreDetailsModal() {
        dispatch(change(FORM_NAME, 'considering_creating_invoice', true));
        dispatch(openModal('factoringpaymentrequestmoredetails'));
      },
      openAddCustomerModal: () => dispatch(openModal('factoringclientadddebtor', {
        factoring,
        shouldUpdateFactoringRequestPaymentForm: true,
        parent: FORM_NAME,
      })),
      openHelpModal: () => dispatch(openModal('requestpaymentformhelp')),
    }),
  ),
  nothingIf(({ considering_creating_invoice }) => considering_creating_invoice),
  mapProps(({ self, initialValues, ...rest }) => ({
    ...rest,
    self,
    initialValues: {
      ...initialValues,
      attachments: [],
      first_origin_location: {},
      final_destination_location: {},
      load_length: self.data.factoring_last_used_load_length,
      load_trailer_type: self.data.factoring_last_used_load_trailer_type,
    },
    user_type: self.data.type,
  })),
  withState('require_debtor_address', 'set_require_debtor_address', false),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, { factoring, user_type, initialValues, initialize, reset, set_require_debtor_address }) {
      if (_.isEmpty(fields.attachments)) {
        return dispatch(openModal('error', {
          message: 'Please add an attachment.',
        }));
      }
      let categories = [];
      if (fields.attachments.length) {
        fields.attachments.forEach(element => {
          if (element.category) {
            categories = [...categories, ...element.category];
          }
        });
      }
      const tonu = Boolean(_.filter(categories, item => item === 'Truck Order Not Used').length);
      const rate = Boolean(_.filter(categories, item => item === 'Rate Confirmation').length);
      const signedDeliveredBill = Boolean(_.filter(categories, item => item === 'Signed Bill of Lading (Delivered)').length);
      const signedPickUpBill = Boolean(_.filter(categories, item => item === 'Signed Bill of Lading (Pick-Up)').length);
      if (!tonu && (!rate || !(signedDeliveredBill || signedPickUpBill))){
        let text = 'Please make sure to add the following document: ';
        text += !rate ? '\n - Rate confirmation ' : '';
        if (!(signedDeliveredBill || signedPickUpBill)) {
          text += !signedDeliveredBill ? '\n - Signed Bill of Lading (Delivered)' : '';
          text += !signedDeliveredBill && !signedPickUpBill ? '\n Or' : '';
          text += !signedPickUpBill ? '\n - Signed Bill of Lading (Pick-Up)' : '';
        }
        return dispatch(openModal('error', {
          message: text,
        }));
      }
      const data = {
        amount: fields.amount,
        debtor: fields.bill_to.id,
        bill_to_company_phone: fields.bill_to_company_phone,
        bill_to_company_email: fields.bill_to_company_email,
        first_origin_location: typeof fields.first_origin_location === 'string' ? {
          street_one: fields.first_origin_location,
          city: '',
          state: '',
          zip: '',
          country: '',
        } : {
          street_one: fields.first_origin_location.street_one,
          city: fields.first_origin_location.city,
          state: fields.first_origin_location.state,
          zip: fields.first_origin_location.zip,
          country: fields.first_origin_location.country,
        },
        final_destination_location: typeof fields.final_destination_location === 'string' ? {
          street_one: fields.final_destination_location,
          city: '',
          state: '',
          zip: '',
          country: '',
        } : {
          street_one: fields.final_destination_location.street_one,
          city: fields.final_destination_location.city,
          state: fields.final_destination_location.state,
          zip: fields.final_destination_location.zip,
          country: fields.final_destination_location.country,
        },
        load_length: fields.load_length,
        load_trailer_type: fields.load_trailer_type,
        attachments: convertToString(fields.attachments),
        user_load_number: fields.user_load_number,
        load: fields.source_load,
        user_notes: fields.user_notes,
        user_reference_number: fields.user_reference_number,
        payment_method: fields.use_wire_transfer ? 'wire' : undefined,
        contract_type: 'STD_CARRIER',
      };
      // add "factoring" (representing the the factoring client ID) into the request payload if it exists as a field
      if (factoring) {
        data.factoring = factoring;
      }
      try {
        //Add Bank/Card info modal on Client side on Funding request.
        // await dispatch(openModal('paymentinformation', { fields }));
        await dispatch(create(data));
        if (!USER_TYPE.IS_ADMIN(user_type) || !USER_TYPE.IS_ALADDIN_ADMIN(user_type)) {
          if (fields.bill_to.credit_approved !== 'approved') {
            dispatch(openModal('warning', { message: 'This customer is not yet approved for credit terms. We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
          }
          if (fields.bill_to.originals_required) {
            dispatch(openModal('warning', {
              message: (
                <div>
                  <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please contact your broker directly to get the correct address to mail your original documents to.</p>
                  {/*<p>*/}
                  {/*  ComFreight Financial<br />*/}
                  {/*  1201 W. Russel St.,<br />*/}
                  {/*  Sioux Falls, SD 57104*/}
                  {/*</p>*/}
                  <p>
                    Provide tracking information for your mailed originals to
                    <a href='mailto:invoice@haulpay.io' className='space'>invoice@haulpay.io</a>
                    so that we can process your transaction as quickly as possible, by knowing when your documents will arrive.
                  </p>
                  <p>If you have more questions please call us <a href='tel:888-633-5558'>888-633-5558</a>.</p>
                  Thank you.
                </div>
              ),
            }));
          }
        }
        dispatch(openModal('success', { message: 'Successfully made payment request.' }));
        initialize({
          ...initialValues,
          considering_creating_invoice: false,
          should_create_invoice: false,
          attachments: [],
          first_origin_location: {},
          final_destination_location: {},
          load_length: fields.load_length,
          load_trailer_type: fields.load_trailer_type,
        });
        reset();
        set_require_debtor_address(false);
      }
      catch (err) {
        if (err instanceof SubmissionError) {
          if (/You have already submitted this load number./.test(formatError(err))) {
            return dispatch(openModal('warning', {
              message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
            }));
          }
          if (/Debtor has been declined./.get(err, ['message', 'non_field_errors', '0'])) {
            return dispatch(openModal('error', {
              headerChildren: 'Customer Declined on Credit',
              message: 'Credit for this customer has recently been declined. We will check this customer\'s credit again in regular intervals but a payment request cannot be made for this customer at this time. Please email invoice@haulpay.io if you have more questions. Thank you.',
            }));
          }
        }
        dispatch(openModal('error', { message: 'Failed to send payment request.' }));
      }
    },
    validate(fields, { self, require_debtor_address }) {
      const errs = validate(fields, {
        attachments: {
          presence: {
            message: 'Please Upload BOL & Rate Con.',
          },
          length: {
            minimum: 1,
            tooShort: '^At least one attachment required.',
          },
        },
        amount: {
          presence: true,
        },
        bill_to: {
          presence: true,
          debtorDeclined: true,
        },
        load_length: {
          ...(() => USER_TYPE.IS_ADMIN(self.data.type) || USER_TYPE.IS_ALADDIN_ADMIN(self.data.type) ? {} : {
            presence: true,
          })(),
          numericality: {
            onlyInteger: true,
            greaterThan: 1,
            lessThan: 1000,
            notGreaterThan: '^Must be greater than 1 foot',
          },
        },
        load_trailer_type: {
          ...(() => USER_TYPE.IS_ADMIN(self.data.type) || USER_TYPE.IS_ALADDIN_ADMIN(self.data.type) ? {} : {
            presence: true,
          })(),
        },
        user_load_number: {
          presence: true,
        },
      });
      ['first_origin_location', 'final_destination_location'].forEach(key => {
        const err = validateAddress(fields[key], 'Please enter address or city, state');
        if (err) {
          errs[key] = err;
        }
      });
      return errs;
    },
  }),
)(({
  factoring_discount_rate,
  amount_to_be_paid,
  use_wire_transfer,
  submitting,
  handleSubmit,
  openMoreDetailsModal,
  openAddCustomerModal,
  openHelpModal,
  change,
  user_type,
  require_debtor_address,
  set_require_debtor_address,
  factoring,
}) =>
  <div className='light-placeholder'>
    <div className='clearfix' style={{ marginTop: 12.5, paddingLeft: '4%' }}>
      <Header style={{ display: 'inline-block', marginTop: 0 }}>
        Get Paid
        <span style={{ fontSize: '0.7em' }}> Upload Documents</span>
      </Header>
      <ProTip
        className='pull-right'
        name='Upload to Get Paid'
        message={'Be sure to upload your Rate Confirmation (or a form of one), and signed Bill of Lading. If you don\'t have an invoice you can create one with the Create Invoice button. If you don\'t have a formal rate confirmation you can use a copy of an email confirming the rate or awarded bid from the company you hauled for as well.'}
        youtubeEmbed='Aykn5XgennU'
      />
    </div>
    <div style={{ paddingLeft: '4%' }}>
      <div className='text-danger'>Required Documents: Signed Rate Confirmation & Signed Bill of Lading.</div>
      <small>Cutoff time is 12PM CT for next day payment. </small>
    </div>
    <hr />
    <form onSubmit={handleSubmit}>
      <div>
        <div className='row' style={{ paddingLeft: '4.5%', paddingRight: '3%' }}>
          <div className='col-xs-12'>
            <Field
              name='attachments'
              multiple={true}
              component={MultiImageInput}
              limit={25}
              buttonText='Upload Documents'
              buttonClassName='btn btn-default'
              confirmBeforeDeleting={true}
              shouldCrop={true}
              convertAnyway={true}
              categories={[
                'Signed Bill of Lading (Delivered)',
                'Signed Bill of Lading (Pick-Up)',
                'Rate Confirmation',
                'Truck Order Not Used',
                'Invoice',
                'Freight Bill',
                'Lumper Receipt',
                'Detention Receipt',
                'Late Fee Receipt',
                'Other',
              ]}
            />
          </div>
        </div>
        <div className='row' >
          <div className='col-xs-6' style={{ paddingLeft: '5%', paddingRight: '10%' }}>
            <Field name='amount' component={PennyInput} label='Total Invoice Amount' />
            <Field
              name='bill_to'
              component={DebtorAutocompleteInput}
              client_id={factoring}
              labelProps={{
                style: { display: 'block' },
              }}
              label={
                <div>
                  Bill To Company
                  <button className='btn-payment pull-right' type='button' onClick={openAddCustomerModal} style={{ padding: '0 12px', marginBottom: 3, height: '20px' }}>
                      Add New Cust.
                  </button>
                </div>
              }
              onSelect={val => {
                if (!val.address || !val.address.street_one) {
                  set_require_debtor_address(true);
                }
                else {
                  set_require_debtor_address(false);
                }
                // fall back to empty string here because `bill_to_company_phone` can't be `null` on this endpoint
                change('bill_to_company_phone', val.phone_number || '');
                change('bill_to_company_email', val.email);
                change('factoring_discount_rate', val.discount_rate);
              }}
            />
            <div>
              <a role='button' onClick={openMoreDetailsModal}>Add More Details Here</a>
            </div>
            <button className='btn btn-default' type='button' onClick={openHelpModal}>Help</button>
          </div>
          <div className='col-xs-6' style={{ paddingRight: '15%' }}>
            <Field
              name='first_origin_location'
              component={GoogleLocationInput}
              placeholder=''
              specificity={GoogleLocationInput.specificity.ANY}
              label='First Pick Up'
              setValueWithoutConfirming={true}
            />
            <Field
              name='final_destination_location'
              component={GoogleLocationInput}
              placeholder=''
              specificity={GoogleLocationInput.specificity.ANY}
              label='Final Delivery'
              setValueWithoutConfirming={true}
            />
            <div className='row'>
              <div className='col-xs-6' >
                <Field
                  name='load_trailer_type'
                  component={TrailerInput}
                  label='Equipment Type'
                  placeholder='Select Type'
                  extraTrailerTypes={[{
                    value: 'Other',
                    text: 'Other',
                  }]}
                />
              </div>
              <div className='col-xs-6'>
                <Field name='load_length' component={LengthInput} label='Load Length' specific={true} />
              </div>
            </div>
            <Field
              name='user_load_number'
              component={LabeledInput}
              label='Load Number'
            />
            <div>
              <Field
                name='use_wire_transfer'
                component={CheckboxInput}
                label={<span>Wire Transfer Needed <span className='text-danger'>($25)</span></span>}
              />
            </div>
            {
              use_wire_transfer ?
                <p className='text-danger'><b>Wire cutoff is 12PM CT.</b></p>
                : null
            }
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
              }}
              >
              <div className='text-right'>
                <span>Your Rate: {factoring_discount_rate}%</span>
                {'\u00a0\u00a0\u00a0\u00a0\u00a0'}
                <span><u>Total To Be Paid:</u> <span className='text-success'><strong>{amount_to_be_paid}</strong></span></span>
              </div>
            </div>
            <button className='btn btn-orange pull-right' type='submit' disabled={submitting}>
              {
                submitting ?
                  <span>
                    <Spinner />
              PROCESSING
                  </span>
                  :
                  'REQUEST PAYMENT'
              }
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
);
