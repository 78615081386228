import React from 'react';
import PropTypes from 'prop-types';

import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';


const getStatusProps = status => {
  switch (status) {
    case USER_FACTORING_STATUS.NEEDS_REVIEW:
      return {
        className: 'text-orange',
        children: 'Being Reviewed',
      };
    case USER_FACTORING_STATUS.APPROVED:
      return {
        className: 'text-success',
        children: 'Approved',
      };
    case USER_FACTORING_STATUS.DECLINED:
    case USER_FACTORING_STATUS.DECLINED_3_MONTHS:
    case USER_FACTORING_STATUS.DECLINED_6_MONTHS:
      return {
        className: 'text-danger',
        children: 'Declined',
      };
    case USER_FACTORING_STATUS.PENDING:
      return {
        className: 'text-warning',
        children: 'Pending',
      };
    case USER_FACTORING_STATUS.DO_NOT_CONTACT:
      return {
        className: 'text-primary',
        children: 'Do Not Contact',
      };
  }
  return {
    className: 'text-primary',
    children: 'N/A',
  };
};

const FactoringClientCreditApprovalStatus = ({ status, ...rest }) => {
  const statusProps = getStatusProps(status);
  return (
    <span
      {...statusProps}
      {...rest}
      style={{
        ...statusProps.style,
        ...rest.style,
      }}
    />
  );
};

FactoringClientCreditApprovalStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(USER_FACTORING_STATUS)).isRequired,
};

export default FactoringClientCreditApprovalStatus;
