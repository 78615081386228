/*global google */
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import setDisplayName from 'recompose/setDisplayName';


const DEFAULT_OPTIONS = {
  createPointFromItem: i => i,
  createListItemFromItem: item => `Going to ${item.data.destination_city}, ${item.data.destination_state}`,
  itemsProp: 'items',
};

export default options => {
  if (typeof options === 'function') {
    options = {
      createPointFromItem: options,
    };
  }
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  return compose(
    setDisplayName('consolidatePoints'),
    mapProps(props => {
      const items = props[options.itemsProp];
      const pointsToItems = new Map;
      const points = items.map(item => {
        const point = options.createPointFromItem(item, props);
        pointsToItems.set(point, item);
        return point;
      });
      const buckets = points
        .map(point => {
          if (point.position) {
            point = point.position;
          }
          const { lat, lng } = point;
          // cantor pairing function
          return ((lat + lng) * (lat + lng + 1) / 2) + lng;
        })
        .reduce((acc, n, i) => {
          let bucket = acc.get(n);
          if (bucket === undefined) {
            bucket = [];
            acc.set(n, bucket);
          }
          bucket.push(points[i]);
          return acc;
        }, new Map);

      const _points = [];
      for (const bucket of buckets.values()) {
        let point = bucket[0];
        // mangle this point if it's going to be repesenting multiple points
        if (bucket.length > 1) {
          const label = {
            text: bucket.length > 9 ? '+' : String(bucket.length), // google yells at you if this isn't explicitly a string
            fontWeight: 'bold',
            color: 'white',
          };
          // if we're in the nested form
          if (point.position) {
            point.label = label;
          }
          // else we're in ({ lat, lng }) form
          else {
            point = {
              position: point,
              label: label,
            };
          }
          const content = bucket
            .map(point => {
              const item = pointsToItems.get(point);
              const el = document.createElement('div');
              el.innerHTML = options.createListItemFromItem(item);
              el.className = 'hover-underline';
              el.setAttribute('role', 'button');
              el.setAttribute('style', 'font-size: 14px;');
              if (point.onClick) {
                const { onClick } = point;
                el.addEventListener('click', onClick);
              }
              return el;
            })
            .reduce((acc, curr) => {
              acc.appendChild(curr);
              return acc;
            }, document.createElement('div'))
            ;
          const infoWindow = new google.maps.InfoWindow({ content });
          point.onClick = (marker, GoogleMapComponent) => infoWindow.open(GoogleMapComponent._map.google, marker);
        }
        _points.push(point);
      }

      return {
        ...props,
        points: _points,
      };
    }),
  );
};
