/**
 * Actions relating to the factoringdebtor by factoringclient resource
 * @module actions/resourceBy/factoringclient/factoringdebtor
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/factoringdebtor
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import factoringlineitem from 'actions/resource/factoringlineitem';


export const factoringlineitemByfactoringclient = new ControlledPaginatedResourceCollection('factoringpayment', 'factoringlineitem', {
  url: new UrlAssembler('funding_requests/:funding_request_id/items/:id'),
  paginatedResourceOptions: {
    baseResource: factoringlineitem,
    append_to_top_on_create: true,
  },
});

export default factoringlineitemByfactoringclient;
export const {
  fetch,
  fetchNext,
  create,
  doDelete,
} = factoringlineitemByfactoringclient;

// user-version endpoint
export const factoringlineitemByfactoringclientUSER = new ControlledPaginatedResourceCollection('factoringclient', 'factoringlineitem', {
  url: new UrlAssembler('factoring/lineitem/relationship'),
  paginatedResourceOptions: {
    // this will overwrite the above one without a fucked-up id in the formatting
    url: new UrlAssembler('factoring/lineitem/relationship'),
    baseResource: factoringlineitem,
    append_to_top_on_create: true,
  },
});
