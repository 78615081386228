import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import { closeModal, openModal } from 'actions/ui';
import { create } from 'actions/resource/GetCardInfo';
import Header from 'components/pure/Header';
import DebitCardInput from 'components/pure/form/inputs/DebitCardInput';
import ExpirationDateInput from 'components/pure/form/inputs/ExpirationDateInput';
import ZipcodeInput from 'components/pure/form/inputs/ZipcodeInput';
import formatError from 'helpers/formatError';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';

const formatExpiration = exp_date => {
  // swap the date format from MM/YYYY to YYYY-MM
  // this is the format sent directly to Checkbook.IO
  // since this goes to VGS and never hits our backend.
  return exp_date.replace(/(\d{2})\/(\d{4})/, '$2-$1');
};

export default compose(
  connect(
    state => {
      return {
        user_id: state.user.id,
      };
    },
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
      openPaymentSummaryModal: fields => {
        dispatch(closeModal());
        dispatch(openModal('paymentsummary', { fields }));
      },
    }),
  ),
  reduxForm({
    form: 'AddCardInfo',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, props) {
      const data = {
        type: 'DEBIT',
        method: 'DEBIT',
        user: props.fields.data.user,
        card_number: fields.card_number.replace(/\s/g, ''),
        expiration_date: formatExpiration(fields.expiration_date),
        zip_code: fields.zip_code,
        account_number_redacted: fields.card_number.replace(/.(?=.{4})/g, 'x'),
      };
      try {
        await dispatch(create(data));
        dispatch(openModal('success', { message: 'Successfully Added Card Details.' }));
        dispatch(closeModal());
      }
      catch (err) {
        dispatch(closeModal());
        console.warn(err);
        dispatch(openModal('error', { message: formatError(err, 'Unable to save your card info') }));
      }
    },
    validate(fields) {
      return validate(fields, {
        card_number: {
          presence: true,
          format: {
            pattern: /^(34|37|4|5[1-5]).*$/,
            message: 'This is not a valid credit card number',
          },
        },
        zip_code: {
          presence: true,
          format: {
            pattern: /\d{5}/,
            message: 'is invalidly formatted. Please use the following formats: XXXXX or XXXXX-XXXX',
          },
        },
        expiration_date: {
          presence: true,
          format: {
            pattern: /(\d{2})[/](\d{4})/,
            message: 'This is not a valid expiration date',
          },
        },
      });
    },
  }),
)(({ submitting, handleSubmit, closeModal }) =>
  <form onSubmit={handleSubmit}>
    <ModalBody closeModal={closeModal}>
      <Header>Add Your Debit/Credit Card Info</Header>
      <p className='text-red'>You will receive a payment in less than a minute.</p>
      <hr />
      <Field
        name='card_number' component={DebitCardInput}
        label='Card Number *'
        placeholder='xxxx xxxx xxxx xxxx'
      />
      <Field
        name='expiration_date' component={ExpirationDateInput}
        label='Expiration *'
        placeholder='MM/YYYY'
      />
      <Field
        name='zip_code' component={ZipcodeInput}
        label='Zipcode *'
        placeholder='xxxxx'
      />
    </ModalBody>
    <ModalFooter>
      <div className='help-block pull-left'>* Required Fields</div>
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
      <BigOrangeSubmitButton submitting={submitting} message='Add Card' />
    </ModalFooter>
  </form>
);
