import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.financespayment;

import financespaymentResource from 'actions/resource/financespayment';
import financespaymentsPaginatedResource from 'actions/admin/financespayments';


export default function financespaymentResourceReducer(state = initialState, action) {
  state = financespaymentResource.reduce(state, action);
  switch (action.type) {
    case financespaymentsPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...financespaymentResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
