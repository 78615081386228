const telephoneToString = phoneString => {
  if (typeof phoneString === 'string') {

    const parts = phoneString.split(';');

    if (parts[0].length === 10) {
      parts[0] = parts[0].replace(/\D+/g, '')
        .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
    }

    let phone = `${parts[0]}`;

    if (parts.length > 1) {
      phone = `${phone} ext: ${parts[1]}`;
    }

    return phone;
  }
  return '';
};

export default telephoneToString;
