/**
 * Actions relating to the hiding posts and users
 * @module actions/hide
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/SavedUserSettings
 */
/*global API */
import { push } from 'react-router-redux';
import APIFetch from '../datatypes/APIFetch';
import FetchError from '../datatypes/FetchError';

import SavedUserSettings from '../datatypes/SavedUserSettings';

/**
 * SavedUserSettings instance for hidden posts, which are saved client-side.
 * Hidden posts are saved in LocalStorage in the form [ID, ID, ...etc], and in memory in the form { [ID]: true, [ID]: true, ...etc }
 */
export const savedHiddenPosts = SavedUserSettings('SavedHiddenPosts', {
  defaults: {
    posts: {},
  },
  hooks: {
    postRetrieve(data) {
      if (data.posts) {
        data.posts = data.posts.reduce((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {});
      }
      return data;
    },
    preSave(data) {
      if (data.posts) {
        return { posts: Object.keys(data.posts) };
      }
      return { posts: [] };
    },
  },
});

/**
 * Hide post action
 * @event HIDE_POST
 * @property {symbol} type - Symbol(HIDE_POST)
 * @property {object} payload - the ID of the post to hide
 */
export const HIDE_POST = Symbol('HIDE_POST');
/**
 * Thunk for hiding posts. Adds the ID of the post to LocalStorage, and redirects the user to the last-saved tool (if any)
 * @param {string} id - the ID of the post
 * @fires HIDE_POST
 * @fires LOCATION_CHANGE
 */
export function hidePost(id) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.user.id) {
      return;
    }
    const hiddenPosts = savedHiddenPosts.get(state.user.id);
    hiddenPosts.posts[id] = true;
    savedHiddenPosts.set(hiddenPosts);
    dispatch({
      type: HIDE_POST,
      payload: id,
    });
    const { lastTool } = getState().ui;
    if (lastTool === undefined) {
      return;
    }
    dispatch(push(lastTool));
  };
}

/**
 * Unhide post action
 * @event UNHIDE_POST
 * @property {symbol} type - Symbol(UNHIDE_POST)
 * @property {object} payload - the ID of the post to unhide
 */
export const UNHIDE_POST = Symbol('UNHIDE_POST');
/**
* Thunk for unhiding posts. Removes the ID of the post from LocalStorage
* @param {string} id - the ID of the post
* @fires UNHIDE_POST
*/
export function unHidePost(id) {
  return (dispatch, getState) => {
    const state = getState();
    if (!state.user.id) {
      return;
    }
    const hiddenPosts = savedHiddenPosts.get(state.user.id);
    delete hiddenPosts.posts[id];
    savedHiddenPosts.set(hiddenPosts);
    dispatch({
      type: UNHIDE_POST,
      payload: id,
    });
  };
}

/**
 * Hide user action. Marks that a user has been hidden successfully
 * @event HIDE_USER
 * @property {symbol} type - Symbol(HIDE_USER)
 * @property {object} payload - the ID of the hidden user
 */
export const HIDE_USER = Symbol('HIDE_USER');
/**
 * Thunk to hide a user. Makes a request to the server to hide a user given their ID. Reloads the page in order to purge those users loads/trucks/etc from everywhere, because filtering is done server-side
 * @todo Find a way to not have to reload the page in order to get rid of this user's resources from the app
 * @param {string} id - the ID of the user to hide
 * @returns {Promise<void>} - a promise that resolves when the request has finished, or an error
 * @fires HIDE_USER
 */
export function hideUser(id) {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch(APIFetch(`${API.host}/user/relationships/`, {
      method: 'POST',
      body: JSON.stringify({
        status: 'Blocked',
        to_user: id,
      }),
      headers: {
        Authorization: `Bearer ${state.user.token}`,
        'Content-Type': 'application/json',
      },
    }))
      .then(res => res.status !== 201 ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.text())
      .then(() => dispatch({
        type: HIDE_USER,
        payload: id,
      }))
      .then(() => {
        let { lastTool } = state.ui;
        if (lastTool === undefined) {
          lastTool = '/';
        }
        dispatch(push(lastTool));
        window.location.reload();
      })
      ; // eslint-disable-line indent
  };
}

/**
 * Fetch request action. Marks that a request to fetch this user's hidden users has been made
 * @event HIDDEN_USERS_REQUEST
 * @property {symbol} type - Symbol(HIDDEN_USERS_REQUEST)
 * @property {object} payload - the parameters specified for the PaginatedResource
 */
export const HIDDEN_USERS_REQUEST = Symbol('HIDDEN_USERS_REQUEST');
/**
 * Fetch request success action. Marks that a request to fetch this user's hidden users has been made successfully
 * @event HIDDEN_USERS_REQUEST_SUCCESS
 * @property {symbol} type - Symbol(HIDDEN_USERS_REQUEST_SUCCESS)
 * @property {object} payload - the data fetched from the server
 */
export const HIDDEN_USERS_REQUEST_SUCCESS = Symbol('HIDDEN_USERS_REQUEST_SUCCESS');
/**
 * Fetch request failure action. Marks that a request to fetch this user's hidden users has failed
 * @event HIDDEN_USERS_REQUEST_FAILURE
 * @property {symbol} type - Symbol(HIDDEN_USERS_REQUEST_FAILURE)
 * @property {any} payload - the error that occurred
 */
export const HIDDEN_USERS_REQUEST_FAILURE = Symbol('HIDDEN_USERS_REQUEST_FAILURE');
/**
 * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching the hidden users, or an error
 * @fires HIDDEN_USERS_REQUEST
 * @fires HIDDEN_USERS_REQUEST_SUCCESS
 * @fires HIDDEN_USERS_REQUEST_FAILURE
 */
export function fetchHiddenUsers() {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: HIDDEN_USERS_REQUEST,
      payload: undefined,
    });
    return dispatch(APIFetch(`${API.host}/user/relationships/`, {
      headers: {
        Authorization: `Bearer ${state.user.token}`,
        'Content-Type': 'application/json',
      },
    }))
      .then(res => res.status !== 200 ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
      .then(json => dispatch({
        type: HIDDEN_USERS_REQUEST_SUCCESS,
        payload: json,
      }))
      .catch(err => dispatch({
        type: HIDDEN_USERS_REQUEST_FAILURE,
        payload: err,
      }))
      ; // eslint-disable-line indent
  };
}

/**
 * Unhide user action. Marks that a request to hide a user with ID has been made successfully
 * @event UNHIDE_USER
 * @property {symbol} type - Symbol(UNHIDE_USER)
 * @property {any} payload - the ID of the hidden user
 */
export const UNHIDE_USER = Symbol('UNHIDE_USER');
/**
 * Thunk to unhide a user.
 * @param {string} id - the ID of the user to unhide
 * @returns {Promise<void>} - a promise that resolves when the request has finished, or an error
 * @fires UNHIDE_USER
 */
export function unHideUser(id) {
  return (dispatch, getState) => {
    const state = getState();
    return dispatch(APIFetch(`${API.host}/user/relationships/${id}/`, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${state.user.token}`,
        'Content-Type': 'application/json',
      },
    }))
      .then(res => res.status !== 204 ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.text())
      .then(() => dispatch({
        type: UNHIDE_USER,
        payload: id,
      }))
      ; // eslint-disable-line indent
  };
}
