import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';

import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import { closeModal } from 'actions/ui';
import ReleaseFundsForm from 'components/pure/form/ReleaseFundsForm';

const Button = ({ children, onClick }) => (
  <button className='btn btn-default' type='button' onClick={onClick}>{children}</button>
);
const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-blue'>{title}</h4>
);

const factoringRealeaseFunds = ({ closeModal, ...props }) =>
  <div>
    <ModalBody closeModal={closeModal}>
      <ModalTitle title='Release Funds' />
      <ReleaseFundsForm />
    </ModalBody>
    <ModalFooter>
      <Button onClick={closeModal}>
      Cancel
      </Button>
      <BigOrangeSubmitButton message='Submit' />
    </ModalFooter>
  </div>;

export default compose(
  connect(
    null,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    })
  ),
)(factoringRealeaseFunds);
