/* global MOBILE_BREAKPOINT */
import { compose } from 'redux-v3';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';

import rafDebounce from 'helpers/rafDebounce';


const DEFAULT_OPTIONS = {
  breakpoint: MOBILE_BREAKPOINT,
};

const registerResizeEvent = (self, func) => {
  const handler = rafDebounce(func);
  window.addEventListener('resize', handler);
  return () => window.removeEventListener('resize', handler);
};

export default options => {
  options = { ...DEFAULT_OPTIONS, ...options };

  return compose(
    withState('isMobile', 'setIsMobile', () => window.innerWidth <= options.breakpoint),
    lifecycle({
      componentWillMount() {
        this.unregisterResize = registerResizeEvent(this, () => {
          const isMobile = window.innerWidth <= options.breakpoint;
          if (isMobile !== this.props.isMobile) {
            this.props.setIsMobile(isMobile);
          }
        });
      },
      componentWillUnMount() {
        this.unregisterResize();
        this.unregisterResize = null;
      },
    }),
    mapProps(({ setIsMobile, ...rest }) => rest),
  );
};
