import assign from 'lodash/assign';
import isPlainObject from 'lodash/isPlainObject';


const convertToDotNotation = (obj, prefix = '') => {
  const is_object = isPlainObject(obj);
  const is_array = Array.isArray(obj);
  if (!is_object && !is_array) {
    if (obj === undefined) {
      return undefined;
    }
    return {
      [prefix]: obj,
    };
  }

  const ret = {};

  if (is_object) {
    for (const key in obj) {
      const new_prefix = prefix ? `${prefix}.${key}` : key;
      const value = convertToDotNotation(obj[key], new_prefix);
      assign(ret, value);
    }
    return ret;
  }

  if (is_array) {
    for (let i = 0; i < obj.length; i++) {
      const value = convertToDotNotation(obj[i], `${prefix}[${i}]`);
      assign(ret, value);
    }
    return ret;
  }
};

export default convertToDotNotation;
