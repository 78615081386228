/**
 * Component for searching for factoring payments
 * @module components/container/FactoringPaymentsSearch
 * @since 3.1.0
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import { assign } from 'lodash';
import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';
import { openModal } from 'actions/ui';

import commaQuery from 'helpers/commaQuery';
import Header from 'components/pure/Header';
import { PaymentsTable } from 'components/container/FactoringClientPayments';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';

const FactoringPaymentsSearch = compose(
  reduxForm({
    form: 'FactoringPaymentsSearchForm',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch) {
      const query = assign({}, fields, {
        user_load_number: commaQuery(fields.user_load_number),
        invoice_number: commaQuery(fields.invoice_number),
      });

      try {
        dispatch(factoringpaymentPaginatedResource.clear());
        await dispatch(factoringpaymentPaginatedResource.fetch(query));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Factoring payments search failed.' }));
      }
    },
  }),
)(({ handleSubmit, submitting, reset }) =>
  <div className='clearfix' style={{ height: '100%', maxWidth: '100%' }}>
    <div className='col-xs-12 col-sm-3'>
      <Header>Transaction Search</Header>
      <form onSubmit={handleSubmit}>
        <div>
          <Field name='user_load_number' component={LabeledInput} label='Load Number' />
          <Field name='invoice_number' component={LabeledInput} label='Invoice Number' />
          <Field name='client_company_name' component={LabeledInput} label='Client Company Name' />
          <Field name='bill_to_company_name' component={LabeledInput} label='Bill to Company Name' />
          <Field name='first_origin_location' component={LabeledInput} label='Pick Up Location' />
          <Field name='final_destination_location' component={LabeledInput} label='Final Destination' />
        </div>
        <div className='text-right'>
          <button className='btn btn-default pull-left' type='button' onClick={reset}>Reset</button>
          <button className='btn btn-orange' disabled={submitting} type='submit'>SEARCH</button>
        </div>
      </form>
    </div>
    <div className='col-xs-12 col-sm-9 col-fill'>
      <PaymentsTable />
    </div>
  </div>
);

export default FactoringPaymentsSearch;
