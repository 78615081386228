import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { openModal } from 'actions/ui';


export default compose(
  connect(
    null,
    {
      onClick() {
        return openModal('searchfmcsa');
      },
    },
  ),
)(props =>
  <a role='button' {...props}>
    {props.children || 'Lookup my MC/DOT'}
  </a>
);
