import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, shallowEqual } from 'recompose';
import { Field, formValueSelector, reduxForm, change } from 'redux-form';
import formatPennies from 'helpers/formatPennies';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import validate from 'validate.js';
import { get, find, isEmpty, map } from 'lodash';

export const FORM_NAME = 'CreateFuelAdvanceForm';
const styles = {
  labelText: {
    color: '#498504',
    fontSize: 10,
  },
  feeStyleBox: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  textStyle: {
    fontSize: 13,
    fontWeight: 'bold',
    color: '#000000',
  },
};
const PaymentMethod = ({ paymentMethods }) => {
  const data = [{
    text: 'ACH',
    fee: '',
    value: 'ach',
  }];

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}<span className='text-danger'>{fee}</span></div>
  );

  return (
    <Field
      name='payment_method'
      component={LabeledDropdown}
      label='Payment Method'
      data={!isEmpty(paymentMethods) ? paymentMethods : data}
      itemComponent={ListItem}
    />
  );
};

const BrokerRate = props => {
  const data = [{
    text: '0%',
    value: 0,
  },
  {
    text: '50%',
    value: 50,
  },
  {
    text: '100%',
    value: 100,
  }];

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}</div>
  );

  return (
    <Field
      name='broker_rate'
      component={LabeledDropdown}
      label='Broker’s % of Carrier QP Fee '
      data={data}
      itemComponent={ListItem}
      {...props}
    />
  );
};

const CreateFuelAdvanceForm = (({ FuelAdvanceFee, totalFee, wireFee, carrierFee, totalCarrierPay, paymentMethods, brokerFee, fee = 0 }) =>

  <div className='light-placeholder'>
    <Field
      name='payment_profile_amount'
      component={PennyInput}
      label={<p>Carrier Invoice Amount</p>}
      placeholder=''
    />
    <Field
      name='fuel_advance_amount'
      component={PennyInput}
      label={<p>Fuel Advance Amount</p>}
      placeholder=''
    />
    <Field
      label={<p>Load #</p>}
      disabled={true}
      name='user_load_number'
      component={LabeledInput}
      labelProps={{
        style: { display: 'block' },
      }}
      placeholder=''
    />
    <PaymentMethod paymentMethods={paymentMethods} />
    <BrokerRate />
    <div className='row'>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          {wireFee ?
            <p>Wire Fee: <span
              className='text-danger'
            >{formatPennies(wireFee)}</span></p>
            : null}
        </div>
        <div className='col-xs-6' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
          <p>Total Fee: <span className='text-danger'>{formatPennies(totalFee)}</span></p>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <p style={styles.textStyle}>Your Carrier's Fee: <span
            className='text-danger'
            >{formatPennies(carrierFee)}</span></p>
        </div>
        <div className='col-xs-6' style={styles.feeStyleBox}>
          <p style={styles.textStyle}>Your Fee: <span className='text-danger'>{formatPennies(brokerFee)}</span></p>
        </div>
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-12' style={{ height: 1, backgroundColor: '#979797' }} />
      </div>
      <div className='col-xs-12'>
        <div className='col-xs-6'>
          <p style={styles.textStyle}>Your Carrier's Pay: <span
            className='text-success'
            >{formatPennies(totalCarrierPay)}</span></p>
        </div>
      </div>
    </div>
  </div>
);

const selectorForm = formValueSelector(FORM_NAME);
export default compose(
  connect(
    (state, ownProps) => {
      const wireFeeAmount = get(ownProps, 'factoring.wire_fee', 2000);
      const brokerPortion = selectorForm(state, 'broker_rate');
      const accounts = get(ownProps, 'initialValues.carrier.payment_profile.accounts', []);
      const paymentMethods = map(accounts, object => {
        if (object.method === 'CHECK') {
          return {
            value: 'check',
            text: 'Pay via Check',
            fee: '',
          }
        }
        return {
          value: object.method === 'ACH' ? 'ach' : 'wire',
          text: `${object.method} (*****${object.account_number_redacted})`,
          fee: object.method === 'ACH' ? '' : ` ${formatPennies(wireFeeAmount)}`,
        }
      });
      const FuelAdvanceFee = get(ownProps, 'factoring.fuel_advance_fee', 1500);
      const wireFee = selectorForm(state, 'payment_method') === 'wire' ? wireFeeAmount : 0;
      const totalFee = FuelAdvanceFee + wireFee;
      const brokerFee = brokerPortion > 0 ? totalFee * (brokerPortion / 100) : 0;
      const carrierFee = totalFee - brokerFee;
      const totalCarrierPay = (selectorForm(state, 'fuel_advance_amount') || 0) - carrierFee;
      return {
        FuelAdvanceFee,
        user: get(state, `resource.user[${state.user.id}].data`, {}),
        wireFee,
        carrierFee,
        brokerFee,
        totalFee,
        totalCarrierPay,
        initialValues: {
          ...ownProps.initialValues,
          payment_method: get(state, `resource.user[${state.user.id}].data.enable_pay_via_check`, false) ? 'check' : 'ach',
          broker_rate: 0,
        },
        paymentMethods,
      };
    },
    dispatch => ({
      change: (...data) => dispatch(change(FORM_NAME, ...data)),
    }),
    null,
    { withRef: true },
  ),
  reduxForm({
    form: FORM_NAME,
    validate: fields => validate(fields, {
      amount: {
        numericality: {
          greaterThan: 1,
        },
        presence: true,
      },
      carrier_amount: (value, options, key, attributes, globalOptions) => ({
        presence: true,
        numericality: {
          greaterThan: 1,
          notGreaterThan: 'must be greater than 1$',
          lessThanOrEqualTo: options.amount,
          notLessThanOrEqualTo: `must be less or equal to ${formatPennies(options.amount)}`,
        },
      }),
      fuel_advance_amount: (value, attributes, attributeName, options, constraints) => ({
        presence: true,
        numericality: {
          greaterThan: 1,
          notGreaterThan: 'must be greater than 1$',
          lessThanOrEqualTo: attributes.payment_profile_amount / 2,
          notLessThanOrEqualTo: `must be less or equal to ${formatPennies(attributes.payment_profile_amount / 2)}`,
        },
      }),
      bill_to: {
        presence: true,
      },
      carrier: {
        presence: true,
      },
      load_number: {
        presence: true,
      },
      broker_rate: {
        presence: true,
      },
      payment_method: {
        presence: true,
      },
    }),
  }),
)(CreateFuelAdvanceForm);
