import React from 'react';
import { connect } from 'react-redux-v5';
import { compose, lifecycle, withState } from 'recompose';
import EventLog from 'components/pure/EventLog';
import { getLineItemAuditLog } from 'actions/lineitems';
import formatPennies from 'helpers/formatPennies';

export default compose(
  connect(
    null,
    dispatch => ({
      getLineItemAuditLog: (fr_id, li_id) => dispatch(getLineItemAuditLog(fr_id, li_id)),
    })
  ),
  withState('history', 'setHistory', []),
  lifecycle({
    async componentDidMount() {
      const history = await this.props.getLineItemAuditLog(this.props.frid, this.props.id);
      this.props.setHistory(history);
    },
  })
)(({ history }) => (
  <EventLog
    events={history}
    transform={data => {
      if (data.original_key === 'amount_requested') {
        data.old_value = formatPennies(data.old_value);
        data.new_value = formatPennies(data.new_value);
      }
      return data;
    }}
  />
));
