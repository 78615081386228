import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';


const lengthTypes = [
  ['', 'Any'],
  ['full', 'Full (40ft+)'],
  ['partial', 'Less than 40ft'],
].map(([value, text]) => ({ value, text }));

const LengthInput = props => {
  const { input, meta, label, placeholder, specific } = props;
  if (specific) {
    return (
      <div className={cs('form-group', { 'has-error': meta.touched && meta.error })}>
        <label className='control-label'>{label || 'Length'}</label>
        <span className='input-group'>
          <input
            className='form-control'
            type='number'
            placeholder={placeholder === undefined ? '53' : placeholder}
            {...input}
          />
          <span className='input-group-addon'>ft</span>
        </span>
        {meta.touched && meta.error && <div className='help-block'>{meta.error}</div>}
      </div>
    );
  }
  return (
    <LabeledDropdown
      {...props}
      data={lengthTypes}
      label={label || 'Length'}
      placeholder={placeholder === undefined ? 'Any' : placeholder}
      textField='text'
      valueField='value'
    />
  );
};

LengthInput.propTypes = {
  label: PropTypes.string,
  specific: PropTypes.bool,
};

export default LengthInput;
