/* global API */
import qs from 'querystring';

export const fetchDashboardStats = assigned_admin => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/admin/factoring/applications/stats/?${assigned_admin ? `assigned_admin=${assigned_admin}` : ''}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const fetch = ({
  datetime_start,
  datetime_end,
  admin,
  state: us_state,
}) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/stats/?${qs.stringify({
    datetime_start,
    datetime_end,
    admin,
    state: us_state,
  })}`, {
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
  });
  if (res.status !== 200) {
    if (res.status === 403) {
      const json = await res.json();
      throw json;
    }
    throw res;
  }
  return await res.json();
};

/**
 * @param {Date} datetime_start
 * @param {Date} datetime_end
 */
export const fetchFinanceStats = ({ datetime_start, datetime_end } = {}) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/finance/?${qs.stringify({
    datetime_start,
    datetime_end,
  })}`, {
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
  });
  if (res.status !== 200) {
    throw res;
  }
  return await res.json();
};

/**
 * @param {File} file
 */
export const uploadFinanceStats = file => async (dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  formData.append('file', file);
  const res = await global.fetch(`${API.host}/admin/finance/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
    body: formData,
  });
  if (res.status >= 500 && res.status < 600) {
    throw res;
  }
  const json = await res.json();
  if (res.status !== 201) {
    throw json;
  }
  return json;
};
