/**
 * Modal component to display [FullAccessBenefits]{@link module:components/pure/FullAccessBenefits}
 * @module components/pure/modals/FullAccessBenefits
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Generic
 * @requires components/pure/FullAccessBenefits
 * @property {object} props
 * @property {any} [props....rest] - props passed to FullAccessBenefits component
 */
import React from 'react';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Modal from '../../hoc/Modal';
import ModalGeneric from './Generic';
import FullAccessBenefits from '../FullAccessBenefits';

export const FullAccessBenefitsModal = compose(
  mapProps(props => ({
    message: <FullAccessBenefits {...props} />,
  })),
  Modal,
)(ModalGeneric);

export default FullAccessBenefitsModal;
