import 'styles/InfiniteScroll';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import wrapDisplayName from 'recompose/wrapDisplayName';
import cs from 'classnames';

import getScrollBottom from 'helpers/getScrollBottom';
import Spinner from 'components/pure/Spinner';


const defaultOptions = {
  loadUntilFull: false,
};

// TODO - decorate this when they fix decorators
// const disableIf = cond => (target, name, descriptor) => {
//   if (typeof cond === 'function') {
//     if (cond()) {
//       descriptor.value = undefined;
//       return;
//     }
//   }
//   if (cond) {
//     descriptor.value = undefined;
//   }
// };

export default options => {
  options = {
    ...defaultOptions,
    ...options,
  };
  return WrappedComponent => class extends Component {
    constructor(props) {
      super(props);
      if (!props.withPagination) {
        this.onScroll = this.onScroll.bind(this);
        this.raf_waiting = false;
      }
    }

    // @disableIf(!options.loadUntilFull)
    componentDidUpdate() {
      const { fetchNext, withPagination } = this.props;
      if (!withPagination && options.loadUntilFull && fetchNext) {
        const scrollBottom = getScrollBottom(this.container);
        if (scrollBottom <= 1) {
          fetchNext();
        }
      }
    }

    static displayName = wrapDisplayName(WrappedComponent, 'infiniteScroll');

    static propTypes = {
      ...WrappedComponent.propTypes,
      fetchNext: PropTypes.func,
      isFetching: PropTypes.bool,
      containerProps: PropTypes.object,
    };

    onScroll() {
      const { fetchNext } = this.props;
      if (!this.raf_waiting) {
        requestAnimationFrame(() => {
          if (this.container) {
            const scrollBottom = getScrollBottom(this.container);
            if (scrollBottom <= 1) {
              fetchNext();
            }
          }
          this.raf_waiting = false;
        });
      }
      this.raf_waiting = true;
    }

    render() {
      const { withPagination, fetchNext, isFetching, containerProps = {}, ...rest } = this.props;
      return (
        <div
          {...containerProps}
          className={cs('InfiniteScroll', containerProps.className)}
          onScroll={(fetchNext === undefined || withPagination) ? undefined : this.onScroll}
          ref={ref => this.container = ref}
          >
          <WrappedComponent isFetching={isFetching} withPagination={withPagination} {...rest} />
          {isFetching && !withPagination && <div><Spinner /></div>}
        </div>
      );
    }
  };
};
