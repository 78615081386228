import { compose } from 'redux-v3';
import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';

const factoringGroupUser = compose(
  Singleton('factoringGroupUser'),
  Fetchable({
    url: id => `groups/${id}/`,
    expirationTime: -Infinity,
  })
)();

factoringGroupUser.parseMany = function parseMany(oldValues, newValues) {
  const values = newValues.results.map(r => r.user).reduce((acc, curr) => {
    acc[curr.id] = this.parse(oldValues[curr.id], curr);
    return acc;
  }, {});

  return values;
};

export const {
  selector,
  fetch,
  fetchIfNeeded,
  edit,
  doDelete,
} = factoringGroupUser;

export default factoringGroupUser;
