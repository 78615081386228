/**
 * Reducer for handling the admin factoring debtor actions
 * @module reducers/admin/debtor
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/factoring/debtor
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoring.debtor;

import factoringdebtorPaginatedResource from 'actions/admin/factoring/debtor';


export default function factoringdebtorReducer(state = initialState, action) {
  state = factoringdebtorPaginatedResource.reduce(state, action);
  return state;
}
