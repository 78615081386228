import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import lifecycle from 'recompose/lifecycle';
import moment from 'moment';

import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import RateStars from 'components/pure/RateStars';
import LoadMoreContainer from 'components/pure/LoadMoreContainer';


const UserProfileRating = compose(
  Resource('rating'),
  connect(
    (state, { item }) => {
      let is_self = false;
      if (state.user.id && item && item.data && item.data.from_user_id) {
        is_self = state.user.id === item.data.from_user_id;
      }
      return { is_self };
    },
    {
      openModal,
    },
  )
)(({ item, is_self, openModal }) =>
  <div>
    <RateStars value={item.data.rating} style={{ display: 'inline-block', fontSize: 14, paddingLeft: 0, color: colors.ORANGE }} />
    <span style={{ display: 'inline-block' }}>{moment(item.data.time_posted).format('l LTS')}</span>
    {' '}
    {
      item.data.from_user_id ?
        <Link to={`/user/${item.data.from_user_id}`}>View Reviewer's Profile</Link>
        : null
    }
    {
      is_self ?
        <p>
          <button
            type='button'
            className='btn btn-orange'
            onClick={() => openModal('userrate', {
              user_id: item.data.to_user_id,
              edit_id: item.data.id,
            })}
            >
          Edit My Review
          </button>
        </p>
        : null
    }
    <p style={{ wordWrap: 'break-word' }}>{item.data.comment}</p>
  </div>
);

export default compose(
  ResourceBy('user', 'rating'),
  lifecycle({
    componentDidMount() {
      this.props.actions.clear();
      this.props.actions.fetch();
    },
  }),
)(({ item = {}, actions }) =>
  <LoadMoreContainer onLoadMoreClick={item.next ? actions.fetchNext : null} isFetching={item.isFetching || item.isFetching === undefined}>
    {(item.ids || []).map(id => <UserProfileRating key={id} id={id} />)}
  </LoadMoreContainer>
);
