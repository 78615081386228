import React from 'react';

const ExtraInfoCard = ({
  children,
  ...rest
}) =>
  <div {...rest}>
    {children}
  </div>;
export default ExtraInfoCard;
