/**
 * A map between state ISO2 codes and their full names
 */

const states = {
  AB: 'Alberta',
  AG: 'Aguascalientes',
  AK: 'Alaska',
  AL: 'Alabama',
  AR: 'Arkansas',
  AZ: 'Arizona',
  BC: 'British Columbia',
  BN: 'Baja California Norte',
  BS: 'Baja California Sur',
  CA: 'California',
  CH: 'Coahuila',
  CI: 'Chihuahua',
  CL: 'Colima',
  CO: 'Colorado',
  CP: 'Campeche',
  CS: 'Chiapas',
  CT: 'Connecticut',
  DC: 'District of Columbia',
  DE: 'Delaware',
  DF: 'Districto Federal',
  DG: 'Durango',
  FL: 'Florida',
  GA: 'Georgia',
  GE: 'Guerrero',
  GJ: 'Guanajuato',
  HD: 'Hidalgo',
  HI: 'Hawaii',
  IA: 'Iowa',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  JA: 'Jalisco',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  MA: 'Massachusetts',
  MB: 'Manitoba',
  MC: 'Michoacan',
  MD: 'Maryland',
  ME: 'Maine',
  MI: 'Michigan',
  MN: 'Minnesota',
  MO: 'Missouri',
  MR: 'Morelos',
  MS: 'Mississippi',
  MT: 'Montana',
  MX: 'Mexico',
  NA: 'Nayarit',
  NB: 'New Brunswick',
  NC: 'North Carolina',
  ND: 'North Dakota',
  NE: 'Nebraska',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NL: 'Newfoundland',
  NL: 'Nuevo Leon',
  NM: 'New Mexico',
  NS: 'Nova Scotia',
  NT: 'Northwest Territories',
  NU: 'Nunavut',
  NV: 'Nevada',
  NY: 'New York',
  OA: 'Oaxaca',
  OH: 'Ohio',
  OK: 'Oklahoma',
  ON: 'Ontario',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  PE: 'Prince Edward Island',
  PU: 'Puebla',
  QC: 'Quebec',
  QE: 'Queretaro',
  QI: 'Quintana Roo',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  SI: 'Sinaloa',
  SK: 'Saskatchewan',
  SL: 'San Luis Potosi',
  SO: 'Sonora',
  TA: 'Tamaulipas',
  TB: 'Tabasco',
  TL: 'Tlaxcala',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VA: 'Virginia',
  VC: 'Veracruz',
  VT: 'Vermont',
  WA: 'Washington',
  WI: 'Wisconsin',
  WV: 'West Virginia',
  WY: 'Wyoming',
  YT: 'Yukon Territory',
  YU: 'Yucatan',
  ZA: 'Zacatecas',
};

export const states_inverted = Object.keys(states).reduce((acc, code) => {
  const state = states[code];
  acc[state] = code;
  return acc;
}, Object.create(null));

/**
 * A map of url-ified state/province names to their ISO codes
 */
export const stateNameToCode = {};
for (const code in states) {
  const state = states[code];
  stateNameToCode[state.toLowerCase().replace(/\W+/g, '-')] = code;
}

export default states;
