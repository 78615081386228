import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';

import { setViewed } from 'actions/resource/load';
import { fetchNext } from 'actions/search/load';

import consolidatePoints from 'components/hoc/consolidatePoints';
import PaginatedGoogleMap from 'components/pure/PaginatedGoogleMap';
import { LoadIcon } from 'components/pure/GoogleMap';


const placeholder = () =>
  <div style={{ textAlign: 'center' }}>
    <div style={{ marginTop: '10%' }} className='help-block'>Too many results to display on Map View. Please refine your search.</div>
  </div>
  ;

export default compose(
  connect(
    state => ({
      items: state.search.load.ids.map(id => state.resource.loadsearch[id]),
      amount: state.search.load.ids.length,
      hasNext: Boolean(state.search.load.next),
      isFetching: state.search.load.isFetching,
    }),
    {
      setViewed,
      fetchNext,
    },
  ),
  branch(
    ({ items }) => items.length > 500,
    renderComponent(placeholder),
    Component => Component,
  ),
  shouldUpdate((props, nextProps) => (
    props.amount !== nextProps.amount ||
    props.isFetching !== nextProps.isFetching ||
    !shallowEqual(props.items, nextProps.items)
  )),
  consolidatePoints((item, props) => ({
    position: item.data.origin_coords.toLatLng(),
    icon: LoadIcon,
    onClick: () => {
      browserHistory.push(`/load/${item.data.id}`);
      props.setViewed(item.data.id);
    },
  })),
)(({ ids, items, push, setViewed, ...rest }) =>
  <PaginatedGoogleMap {...rest} />
);
