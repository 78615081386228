import React from 'react';
import { compose } from 'redux-v3';
import { lifecycle } from 'recompose';

import { USER_TYPE } from 'helpers';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import IDRegex from 'helpers/IDRegex';
import statusRegex from 'helpers/statusRegex';

import getFactoringStatus from 'components/hoc/getFactoringStatus';
import getSelf from 'components/hoc/getSelf';
import MenuItem from 'components/container/MenuItem';
import TieredMenuItem from 'components/pure/TieredMenuItem';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import { connect } from 'react-redux-v5';
import groups from 'actions/admin/factoring/groups';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isAnyAdmin from 'selectors/isAnyAdmin';
import isInviteCarrier from 'selectors/isInviteCarrier';
import isFactoringInviteCarrier from 'selectors/isFactoringInviteCarrier';
import { compact, get } from 'lodash';

const CarrierMenuItem = ({ stripeEnabled, allowBulkImport }) => {
  const subItem = [
    ['Get Paid', '/haul-pay/getpaid/request', '/haul-pay/getpaid/history'],
    ['Credit', '/haul-pay/profile/creditcheck', '/haul-pay/profile/customers', '/haul-pay/profile/client'],
    stripeEnabled ? ['my Cards', '/haul-pay/mycards'] : undefined,
    allowBulkImport ? ['Bulk Upload', '/haul-pay/bulk_upload'] : undefined,
  ]
  return <TieredMenuItem
    name='haulpay_menu'
    defaultHref='/haul-pay/getpaid/request'
    subItems={compact(subItem)}
    extraMatchingRoutes={[
      new RegExp(`/haul-pay/getpaid/history/${IDRegex}`),
      new RegExp(`/haul-pay/getpaid/history/customer/${IDRegex}`),
    ]}
    >
    Haul Pay
  </TieredMenuItem>
};
const CarrierInviteMenuItem = () => (
  <TieredMenuItem
    name='haulpay_menu'
    defaultHref='/haul-pay/getpaid/assigned-loads'
    subItems={[
      ['Assigned Loads', '/haul-pay/getpaid/assigned-loads', '/haul-pay/getpaid/assigned-loads'],
      ['FACTORING', '/haul-pay/getpaid/request', '/haul-pay/getpaid/history'],
    ]}
    extraMatchingRoutes={[
      new RegExp(`/haul-pay/getpaid/history/${IDRegex}`),
      new RegExp(`/haul-pay/getpaid/history/customer/${IDRegex}`),
    ]}
    >
    Haul Pay
  </TieredMenuItem>
);
const CarrierFactoringInviteMenuItem = ({ stripeEnabled, allowBulkImport }) => {
  const subItem = [
    ['Assigned Loads', '/haul-pay/getpaid/assigned-loads', '/haul-pay/getpaid/assigned-loads'],
    ['Get Paid', '/haul-pay/getpaid/request', '/haul-pay/getpaid/history'],
    ['Credit', '/haul-pay/profile/creditcheck', '/haul-pay/profile/customers', '/haul-pay/profile/client'],
    stripeEnabled ? ['my Cards', '/haul-pay/mycards'] : undefined,
    allowBulkImport ? ['Bulk Upload', '/haul-pay/bulk_upload'] : undefined,
  ];
  return (
    <TieredMenuItem
      name='haulpay_menu'
      defaultHref='/haul-pay/getpaid/request'
      subItems={compact(subItem)}
      extraMatchingRoutes={[
        new RegExp(`/haul-pay/getpaid/history/${IDRegex}`),
        new RegExp(`/haul-pay/getpaid/history/customer/${IDRegex}`),
      ]}
      >
      Haul Pay
    </TieredMenuItem>
  )
  };

const BrokerMenuItem = ({ allowSelFFinanceFr, allowBulkImport }) => {
  const subItemsMenu = [
    ['Manage Pay', '/haul-pay/transaction', '/haul-pay/transaction', new RegExp(`/haul-pay/transaction/${statusRegex}`)],
    ['My Carriers', '/haul-pay/carriers', '/haul-pay/carriers/invited'],
    allowSelFFinanceFr ? ['my Wallet', '/haul-pay/mywallet'] : undefined,
    allowBulkImport ? ['Bulk Uploads', '/haul-pay/bulk-uploads'] : undefined,
    // ['Payment Request', '/haul-pay/getpaid/request', '/haul-pay/getpaid/history'],
    ['Credit', '/haul-pay/profile/creditcheck', '/haul-pay/profile/customers', '/haul-pay/profile/client'],
  ];
  return (
    <TieredMenuItem
      defaultHref='/haul-pay/transaction'
      subItems={compact(subItemsMenu)}
      extraMatchingRoutes={[
        '/haul-pay/addclients',
        new RegExp(`/haul-pay/getpaid/history/${IDRegex}`),
        new RegExp(`/haul-pay/getpaid/history/customer/${IDRegex}`),
      ]}
      >
      Haul Pay
    </TieredMenuItem>
)};

const DispatchBrokerMenuItem = () => (
  <TieredMenuItem
    defaultHref='/haul-pay/transaction'
    subItems={[
      ['Transactions', '/haul-pay/transaction', new RegExp(`/haul-pay/transaction/${statusRegex}`)],
      ['Search', '/admin/factoring/transaction/search'],
    ]}
    >
    Haul Pay
  </TieredMenuItem>
);

const mapStateToProps = state => ({
  isDispatchBroker: isDispatchBroker(state),
  isAnyAdmin: isAnyAdmin(state),
  groups: state.resource.factoringGroups,
  brokerGroups: selectBrokerGroups(state),
  loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
  isInviteCarrier: isInviteCarrier(state),
  isFactoringInviteCarrier: isFactoringInviteCarrier(state),
  allowSelFFinanceFr: get(state.resource.user, `${state.user.id}.data.allow_self_finance_fr`, false),
  stripeEnabled: get(state.resource.user, `${state.user.id}.data.stripe_enabled`, false),
  allowBulkImport: get(state.resource.user, `${state.user.id}.data.allow_bulk_import`, false),
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(groups.fetch()),
});

export default compose(
  getFactoringStatus,
  getSelf,
  connect(mapStateToProps, mapDispatchToProps),
  lifecycle({
    async componentDidMount() {
      await this.props.getGroups();
    },
  })
)(({ allowBulkImport, allowSelFFinanceFr, status, item, brokerGroups, isDispatchBroker, isAnyAdmin, isInviteCarrier, isFactoringInviteCarrier, stripeEnabled }) => {
  const userType = item.data.type;
  if (isDispatchBroker && !isAnyAdmin) {
    return <DispatchBrokerMenuItem />;
  }
  else if ([USER_TYPE.CARRIER, USER_TYPE.BROKER].includes(userType)) {
    if (isInviteCarrier) {
      return (<CarrierInviteMenuItem />);
    }
    if (status !== USER_FACTORING_STATUS.APPROVED && status !== USER_FACTORING_STATUS.DEAD) {
      return <MenuItem href='/haul-pay'>Haul Pay</MenuItem>;
    }
    if (brokerGroups.length > 0 && USER_TYPE.BROKER === userType) {
      return (<BrokerMenuItem 
                allowSelFFinanceFr={allowSelFFinanceFr} 
                allowBulkImport={allowBulkImport} 
             />);
    }
    if (isFactoringInviteCarrier) {
      return (<CarrierFactoringInviteMenuItem stripeEnabled={stripeEnabled} allowBulkImport={allowBulkImport} />);
    }
    return (<CarrierMenuItem stripeEnabled={stripeEnabled} allowBulkImport={allowBulkImport} />);
  }
  return null;

});
