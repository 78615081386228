import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import setDisplayName from 'recompose/setDisplayName';
import get from 'lodash/get';

import { savedHiddenPosts } from 'actions/hide';

import nothingIf from 'components/hoc/nothingIf';


export default ({ idProp = 'id' }) => compose(
  setDisplayName('hiddenPost'),
  nothingIf(props => get(props, idProp) === undefined),
  connect((state, ownProps) => {
    let isHidden = false;
    if (state.user.id) {
      const hiddenPosts = savedHiddenPosts.get(state.user.id);
      if (hiddenPosts.posts[get(ownProps, idProp)]) {
        isHidden = true;
      }
    }
    return { isHidden };
  }),
  nothingIf(({ isHidden }) => isHidden),
  mapProps(({ isHidden, ...rest }) => ({ ...rest })),
);
