import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset } from 'redux-form';
import moment from 'moment';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import getSelf from 'selectors/getSelf';
import { USER_TYPE } from 'helpers';

import debtorcombinednotes from 'actions/admin/debtorcombinednotes';
import { edit, getAttachment } from 'actions/resource/usernote';
import { create } from 'actions/resourceBy/factoringdebtor/factoringdebtornote';
import { edit as editDebtorNote, getAttachment as getDebtorNoteAttachment } from 'actions/resource/factoringdebtornote';
import { openModal } from 'actions/ui';

import Header from 'components/pure/Header';
import LineItemHorizontal from 'components/pure/LineItemHorizontal';
import NoteForm from 'components/pure/form/NoteForm';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';


const FactoringDebtorNoteEditForm = compose(
  mapProps(({ item, cancelEdit }) => ({
    form: `FactoringDebtorNoteEditForm#${item.data.id}`,
    initialValues: {
      note: item.data.note,
    },
    cancel: cancelEdit,
    onSubmit(fields, dispatch) {
      const editFn = item.data.user_id ? edit : editDebtorNote;
      const data = {
        debtor: item.data.debtor,
        note: fields.note,
        attachments: fields.attachments,
      };
      if (item.data.user_id) {
        data.user = item.data.user_id;
      }
      if (item.data.debtor_id) {
        data.debtor = item.data.debtor_id;
      }
      return dispatch(editFn(item.data.id, data))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully edited note' }));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Failed to edit note' }));
        })
        .then(cancelEdit)
      ;
    },
  })),
)(NoteForm);

const FactoringDebtorNote = compose(
  withState('isEditing', 'setIsEditing', false),
  connect(
    state => {
      const self = getSelf(state);
      return {
        userType: self.data.type,
      };
    },
    dispatch => ({
      openAttachment(id, attachment, getAttachment) {
        return dispatch(getAttachment(id, attachment))
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Failed to fetch attachment' }));
          })
        ;
      },
      deleteNote(id, type) {
        return dispatch(openModal('confirm', { id, type }));
      },
    }),
  ),
)(({ item, openAttachment, deleteNote, isEditing, setIsEditing, userType }) =>
  <LineItemHorizontal
    key={item.data.id}
    label={
      <div style={{ fontSize: '0.8em' }}>
        <div>{item.data.posted_by_name}</div>
        <div>{item.data.created.format('l')}</div>
        <div>{item.data.created.format('LT')}</div>
        {
          (userType !== USER_TYPE.ADMIN && item.data.created.isBefore(moment().subtract(2, 'hours'))) ||
          ((userType === USER_TYPE.FACTORING_ADMIN || userType === USER_TYPE.ALADDIN_ADMIN) && item.data.user_id)
            ? null :
            <div>
              <div className='text-red' role='button' onClick={() => deleteNote(item.data.id, item.data.user_id ? 'usernote_delete' : 'factoringdebtornote_delete')}>Delete Note</div>
              <div className='text-blue' role='button' onClick={() => setIsEditing(true)}>Edit Note</div>
            </div>
        }
      </div>
    }
    >
    {
      isEditing ?
        <FactoringDebtorNoteEditForm item={item} cancelEdit={() => setIsEditing(false)} />
        :
        <div>
          {item.data.note}
          {item.data.attachments.map((attachment, i) =>
            <LoadAttachmentButton key={i} title={attachment} getURL={() => openAttachment(item.data.id, attachment, item.data.user ? getAttachment : getDebtorNoteAttachment)} />
          )}
        </div>
    }
  </LineItemHorizontal>
);

export default compose(
  connect(
    (state, { id }) => {
      if (state.admin.debtorcombinednotes.parameters.debtor !== id) {
        return { items: [] };
      }
      return {
        items: state.admin.debtorcombinednotes.ids.map(id => state.resource.factoringdebtornote[id] || state.resource.usernote[id]),
      };
    },
    {
      fetch: debtorcombinednotes.fetch,
      clear: debtorcombinednotes.clear,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch({
        debtor: this.props.id,
      });
    },
  }),
)(({ id, items }) =>
  <div>
    <Header hr={true}>Notes</Header>
    <NoteForm
      form='FactoringDebtorNoteForm'
      onSubmit={(fields, dispatch) => {
        return dispatch(create(id, {
          debtor: id,
          note: fields.note,
          attachments: fields.attachments,
        }))
          .then(() => {
            dispatch(openModal('success', { message: 'Successfully posted note' }));
            dispatch(reset('FactoringDebtorNoteForm'));
          })
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Failed to post note' }));
          })
        ;
      }}
    />
    <div style={{ marginTop: '1em' }}>
      {items.map(item => <FactoringDebtorNote item={item} />)}
    </div>
  </div>
);
