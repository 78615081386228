import React from 'react';
import { connect } from 'react-redux-v5';
import groupsAdmin from 'actions/admin/factoring/groups';
import GroupUser, { deleteFactoringGroupUser } from 'actions/admin/factoringGroupUsers';
import { get, includes, isEmpty, pickBy } from 'lodash';
import createTableItems from 'helpers/createTableItems';
import { compose } from 'redux-v3';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import { Link } from 'react-router-v4';
import { openModal } from 'actions/ui';
import validate from 'validate.js';
import { inviteToGroup } from 'actions/invites';
import telephoneToString from 'helpers/telephoneToString';
import DropdownList from 'react-widgets/lib/DropdownList';
import getSelfType from 'selectors/getSelfType';

const FactoringGroupsTableComponents = createTableItems(
  ({ data, remove }) => {
    return [
      (() => {
        switch (get(data, 'factoring_approved', undefined)) {
          case 'approved':
            return <b><span className='text-success'>Approved</span></b>;
          case 'declined':
            return <b><span className='text-danger'>Declined</span></b>;
          case 'pending':
          default:
            return <b><span className='text-orange'>Pending</span></b>;
        }
      })(),
      data.owner_profile.email,
      data.company_profile.name,
      telephoneToString(data.owner_profile.phone_number_cell),
      data.company_profile.mc,
      data.company_profile.dot,
      data.company_profile.tax_id,
      <div
        style={{
          width: '64px',
        }}
        >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
          >
          <Link to={`/admin/factoring/client/${data.id}`}>
            <MaterialIcon name='visibility' style={{ color: '#4A90E2', fontSize: '24px' }} />
          </Link>
          <div style={{ cursor: 'pointer' }} onClick={() => remove(data.user)}>
            <MaterialIcon name='highlight_off' style={{ color: '#d9534f', fontSize: '24px' }} />
          </div>
        </div>
      </div>,
    ];
  }
);

const FactoringGroupsTable = compose(
  connect(
    state => ({
      ids: state.admin.factoringGroupUsers.ids,
      users: Object.values(pickBy(state.resource.factoringGroupUsers, user => includes(state.admin.factoringGroupUsers.ids, user.data.id))),
      isFetching: state.admin.factoring.groups.isFetching,
      user_type: getSelfType(state),
      currentOrder: state.admin.factoring.groups.ordering,
    }),
    (dispatch, ownProps) => {
      return {
        fetchNext: (...args) => dispatch(groupsAdmin.fetchNext(...args)),
        sort: (...args) => {
          console.log(args); return dispatch(groupsAdmin.sort(...args));
        },
        removeFromGroup: async user => {
          try {
            await dispatch(deleteFactoringGroupUser(ownProps.group, user));
            ownProps.fetchUsers();
            dispatch(openModal('success', { message: 'You removed the user from the group.' }));
          }
          catch (err) {
            dispatch(openModal('error', { message: 'There was an error removing the user from the group.' }));
          }
        },
      };
    },
  ),
)(({ removeFromGroup, users, isFetching, fetchNext, apps, sort, currentOrder }) => (
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    sort={sort}
    currentOrder={currentOrder}
    headers={[
      { text: 'Status', ordering: false },
      { text: 'Email', ordering: false },
      { text: 'Carrier Name', ordering: false },
      { text: 'Phone #', ordering: false },
      { text: 'MC #', ordering: false },
      { text: 'DOT #', ordering: false },
      { text: 'TAX ID #', ordering: false },
      { text: 'Action', ordering: false },
    ]}
    placeholder='No results'
    TableItem={FactoringGroupsTableComponents.TableItem}
    BlockTableItem={FactoringGroupsTableComponents.BlockTableItem}
    items={users.map(user => {
      user.remove = removeFromGroup;
      return user;
    })}
  />
));

class FactoringClientGroupEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group: '',
      searchField: '',
      inviteField: '',
      dropdown: 'email',
    };
  }

  async componentDidMount() {
    this.props.clearUsers();
    const res = await this.props.fetchGroups(this.props.userId);
    const groups = get(res, 'payload.results', []).filter(group => group.type === 'haulpay_broker');

    if (groups.length) {
      const group = groups[0].id;
      await this.props.fetchGroupUser({ id: group });
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        group,
      });
    }
  }

  render() {
    const { userId, inviteToGroup, invalidEmail } = this.props;
    const { group } = this.state;
    return (
      <div>
        <div
          style={{
            display: 'flex',
            padding: '16px 16px 16px 0',
            float: 'right',
          }}
          >
          <div
            style={{
              display: 'flex',
            }}
            >
            <input
              className='form-control'
              type='text'
              placeholder='Search for'
              value={this.state.searchField}
              onChange={event => {
                this.setState({
                  searchField: event.target.value,
                });
              }}
            />
            <div>
              <DropdownList
                style={{
                  minWidth: '11em',
                  marginLeft: '12px',
                }}
                data={[
                  { text: 'Status', value: 'status' },
                  { text: 'Email', value: 'email' },
                  { text: 'Carrier Name', value: 'name' },
                  { text: 'Phone #', value: 'phone_number' },
                  { text: 'MC #', value: 'mc' },
                  { text: 'DOT #', value: 'dot' },
                ]}
                defaultValue='email'
                valueField='value'
                textField='text'
                onChange={event => {
                  this.setState({
                    dropdown: event.value,
                  });
                }}
                value={this.state.dropdown}
              />
            </div>
            <div>
              <button
                style={{
                  marginLeft: '12px',
                }}
                className='btn btn-orange'
                type='button'
                onClick={() => {
                  this.props.clearUsers();
                  if (this.state.searchField) {
                    this.props.fetchGroupUser({
                      id: group,
                      [this.state.dropdown]: this.state.searchField,
                    });
                  }
                  else {
                    this.props.fetchGroupUser({
                      id: group,
                    });
                  }
                }}
                >
                Search
              </button>
            </div>
          </div>
          <input
            style={{
              maxWidth: '200px',
              marginLeft: '12px',
            }}
            className='form-control'
            type='text'
            placeholder='Carrier Email'
            value={this.state.inviteField}
            onChange={event => {
              this.setState({
                inviteField: event.target.value,
              });
            }}
          />
          <div>
            <button
              style={{
                marginLeft: '12px',
              }}
              className='btn btn-green'
              onClick={async () => {
                if (isEmpty(validate.single(this.state.inviteField, { presence: true, email: true }))) {
                  await inviteToGroup(group, [this.state.inviteField]);
                }
                else {
                  await invalidEmail(this.state.inviteField);
                }
                this.setState({
                  inviteField: '',
                });
              }}
              >
              Invite
            </button>
          </div>
        </div>
        <FactoringGroupsTable
          id={userId}
          group={group}
          fetchUsers={() => {
            this.props.clearUsers();
            this.props.fetchGroupUser({
              id: group,
              email: this.state.searchField,
            });
          }}
        />
      </div>
    );
  }
}

const mapStatetoProp = (state, ownProps) => ({
  userId: get(state, `resource.factoringclient.${ownProps.id}.data.user`, ''),
});

const mapDispatchToProps = dispatch => ({
  fetchGroups: id => dispatch(groupsAdmin.fetch({ user: id })),
  fetchGroupUser: query => dispatch(GroupUser.fetch(query)),
  clearUsers: () => dispatch(GroupUser.clear()),
  inviteToGroup: (groupId, emails) => {
    dispatch(inviteToGroup(groupId, emails));
    dispatch(openModal('success', { message: `Invited ${emails[0]} to the group.` }));
  },
  invalidEmail: email => dispatch(openModal('error', { message: `${email} is an invalid email address.` })),
});

export default compose(
  connect(mapStatetoProp, mapDispatchToProps)
)(FactoringClientGroupEdit);

