/**
 * Actions relating to factoringclient search
 * @module actions/search/factoringclient
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringclient
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import factoringclientResource from 'actions/resource/factoringclient';


/**
 * Our factoringclient search [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const factoringclientSearchPaginatedResource = new PaginatedResource('search_factoringclient', {
  mountPoint: ['search', 'factoringclient'],
  url: new UrlAssembler('admin/factoring/applications'),
  baseResource: factoringclientResource,
});

export default factoringclientSearchPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = factoringclientSearchPaginatedResource;
