import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import factoringdebtor from 'actions/resource/factoringdebtor';


export const childrenfactoringdebtorByFactoringdebtorResource = new PaginatedResourceCollection('childrenfactoringdebtor', 'factoringdebtor', {
  url: new UrlAssembler('debtor/:id/related'),
  paginatedResourceOptions: {
    baseResource: factoringdebtor,
  },
});

export default childrenfactoringdebtorByFactoringdebtorResource;
export const {
  fetch,
  fetchNext,
} = childrenfactoringdebtorByFactoringdebtorResource;
