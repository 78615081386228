import { stripeWalletBalance } from 'actions/ledger';
import React, { useState, useEffect } from 'react';
import formatDollars from 'helpers/formatDollars';
import AmountCard from './AmountCard';
import onboardingStatus from 'helpers/walletOnboardingStatus';

export default function WalletBalanceCard (props) {
    const [walletBalance, setWalletBalance] = useState(0);
    const [pendingTransactionAmount, setPendingTransactionAmount] = useState(0);
    const [unavailableWallet, setUnavailableWallet] = useState(false);
    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        async function asyncFunction() {
            try {
                const { balance, pending } = await stripeWalletBalance();
                setWalletBalance(balance);
                setPendingTransactionAmount(pending);
            } catch (err) {
                setUnavailableWallet(true);
            }
            setIsFetching(false);
        }
        props.onboardingStatus === onboardingStatus.ACTIVE && asyncFunction();
    }, [])

    if(isFetching) {
        return <div />
    }

    return <div>
        {unavailableWallet ? 
            <div className='alert alert-warning' style={{ maxWidth: 300, textAlign: 'center', justifyContent: 'center' }}>
                <p>We cannot contact the transaction system right now, please check back later.</p>
            </div>
            : <AmountCard
                title="My Wallet"
                amount={formatDollars(walletBalance)}
                pendingAmount={formatDollars(parseFloat(pendingTransactionAmount) || 0)}
                subHeader="Current Balance"
            />
        }     
    </div>
}
