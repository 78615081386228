import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import FactoringLineItemcreateForm from 'components/container/FactoringLineItemcreate';

export default compose(
  connect(
    null,
    {
      closeModal,
    }
  )
)(({ data, closeModal, refresh }) =>
  <div>
    <div className='modal-header'>
      <button
        type='button' className='close' aria-label='Close' onClick={() => {
          closeModal(); refresh();
        }}
                                                           ><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
      Create Accessorial Charge
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      <FactoringLineItemcreateForm id={data.id} client={data.factoring} debtor={data.debtor} refresh={refresh} />
    </div>
  </div>
);
