export * from './validate.js';
export * from './multipresence';
export * from './numericality';
export * from './filesize';
export * from './phone';
export * from './isEmptyField';

export const compose = (...validators) => val => {
  for (const validator of validators) {
    const err = validator(val);
    if (err) {
      return err;
    }
  }
};
