/**
 * Root reducer for the resource by truck paginated resource collection reducers
 * @module reducers/resourceBy/truck
 * @since 3.0.0
 * @requires reducers/resourceBy/truck/qa
 * @requires reducers/resourceBy/truck/match
 */
import { combineReducers } from 'redux-v3';

import qa from './qa';
import match from './match';


export default combineReducers({
  qa,
  match,
});
