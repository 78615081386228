// /**
//  * Connected modal component to prompt a user to create a quick factoring invoice
//  * @module components/pure/modals/QuickInvoice
//  * @since 3.0.0
//  * @requires actions/ui
//  * @property {object} props
//  * @property {any} [props....rest] - props passed to containing ModalWarning component
//  */
// import React from '../../../../../../../../../../Library/Caches/typescript/2.9/node_modules/@types/react';
// import { compose } from 'redux-v3';
// import { connect } from '../../../../../../../../../../Library/Caches/typescript/2.9/node_modules/@types/react-redux';
// import { USER_TYPE } from 'helpers';
// import get from '../../../../../../../../../../Library/Caches/typescript/2.9/node_modules/@types/lodash/get';
// import { create } from 'actions/resource/factoringpayment';
// import { reduxForm, formValueSelector } from '../../../../../../../../../../Library/Caches/typescript/2.9/node_modules/@types/redux-form';
// import { openModal, closeModal } from 'actions/ui';
// import SubmissionError from 'datatypes/error/SubmissionError';
// import Header from 'components/pure/Header';
// import Spinner from 'components/pure/Spinner';


// const FORM_NAME = 'PaymentSummary';
// const selector = formValueSelector(FORM_NAME);


// export default compose(
//   connect(
//     state => ({
//       accountType: selector(state, 'accountType'),
//     }),
//     (dispatch, { setShouldShowPreview }) => ({
//       closeModal() {
//         dispatch(closeModal());
//       },
//     }),
//   ),
//   reduxForm({
//     form: FORM_NAME,
//     destroyOnUnmount: false,
//     async onSubmit(props, dispatch, { user_type, fields, initialValues, initialize, reset, set_require_debtor_address }) {
//       const data = {
//         fields: fields,
//         debtor: fields.bill_to.id,
//       };
//       // add "factoring" (representing the the factoring client ID) into the request payload if it exists as a field
//       try {
//         await dispatch(create(data));
//         dispatch(closeModal());
//         if (!USER_TYPE.IS_ADMIN(user_type) || !USER_TYPE.IS_ALADDIN_ADMIN(user_type)) {
//           if (fields.bill_to.credit_approved !== 'approved') {
//             dispatch(openModal('warning', { message: 'This customer is not yet approved for credit terms. We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
//           }
//           if (fields.bill_to.originals_required) {
//             dispatch(openModal('warning', {
//               message: (
//                 <div>
//                   <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please mail the physical paperwork for this haul to the following address as soon as possible:</p>
//                   <p>
//                     ComFreight Financial<br />
//                     1201 W. Russel St.,<br />
//                     Sioux Falls, SD 57104
//                   </p>
//                   <p>If you have more questions please call us <a href='tel:888-633-5558'>888-633-5558</a> or email <a href='mailto:invoice@comfreight.com'>invoice@comfreight.com</a>.</p>
//                   Thank you.
//                 </div>
//               ),
//             }));
//           }
//         }
//         initialize({
//           ...initialValues,
//           considering_creating_invoice: false,
//           should_create_invoice: false,
//           attachments: [],
//           bill_to_address: {},
//           first_origin_location: {},
//           final_destination_location: {},
//           load_length: fields.load_length,
//           load_trailer_type: fields.load_trailer_type,
//         });
//         reset();
//         set_require_debtor_address(false);
//       }
//       catch (err) {
//         console.warn(err);
//         if (err instanceof SubmissionError) {
//           if (get(err, ['message', 'non_field_errors', '0']) === 'You have already submitted this load number.') {
//             return dispatch(openModal('warning', {
//               message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
//             }));
//           }
//           if (get(err, ['message', 'non_field_errors', '0']) === 'Debtor has been declined.') {
//             return dispatch(openModal('error', {
//               headerChildren: 'Customer Declined on Credit',
//               message: 'Credit for this customer has recently been declined. We will check this customer\'s credit again in regular intervals but a payment request cannot be made for this customer at this time. Please email invoice@comfreight.com if you have more questions. Thank you.',
//             }));
//           }
//         }
//         dispatch(openModal('error', { message: 'Failed to send payment request.' }));
//       }
//     },
//   }),
// )(({ fields, submitting, handleSubmit, closeModal }) =>
//   <div>
//     <form onSubmit={handleSubmit}>
//     <div className='modal-body'>
//       <div className='light-placeholder'>
//         <Header>Your Payment Summary</Header>
//         <p> Thank you for requesting a payment of <b>${fields.amount}</b>. This will be billed to <b>{fields.bill_to.company_name}</b></p>
//         <hr />
//         <div>
//         <p className='text-red'> Please confirm details before submitting</p>
//         </div>
//         <div
//           style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           marginBottom: 15,
//         }}>
//         <div>
//         <span><b>First Pick-Up</b></span>
//         </div>
//         <div className='text-right'>
//         <span>{fields.first_origin_location.formatted_address}</span>
//         </div>
//         </div>
//         <div
//           style={{
//           display: 'flex',
//           justifyContent: 'space-between',
//           marginBottom: 15,
//         }}>
//         <div>
//         <span><b>Final Delivery</b></span>
//         </div>
//         <div className='text-right'>
//         <span>{fields.final_destination_location.formatted_address}</span>
//         </div>
//         </div>
//         <div>
//         <p><b>Load #</b>{fields.user_load_number}</p>
//         <p><b>Load Length</b>{fields.load_length}</p>
//         <p><b>Equipment Type</b>{fields.load_trailer_type}</p>
//         <p><b>Total to be paid</b>{fields.bill_to.discount_rate}%</p>
//         </div>
//       </div>
//     </div>
//     <div className='modal-footer'>
//       <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
//       <button className='btn btn-orange' type='submit' disabled={submitting}>
//           {
//             submitting ?
//             <span>
//               <Spinner />
//               PROCESSING
//             </span>
//             :
//             'Confirm'
//           }
//         </button>
//     </div>
//     </form>
//   </div>
// );
