/**
 * Button that prompts a user to display the Quick Pay Approved button, which goes to the haul-pay page
 * @module components/container/ButtonQuickPayApproved
 * @since 3.0.1
 * @requires actions/ui
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { initialize } from 'redux-form';
import cs from 'classnames';

import { USER_TYPE } from 'helpers';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { openModal } from 'actions/ui';
import { factoringdebtorResourceUSER } from 'actions/resource/factoringdebtor';
import getSelf from 'selectors/getSelf';

import wrapPromisePending from 'components/hoc/wrapPromisePending';


export default compose(
  connect(
    (state, { load_id }) => {
      const ret = {};
      const load = state.resource.load[load_id] || state.resource.loadsearch[load_id];
      if (load_id && load && load.data) {
        ret.load = load;
        ret.load_owner_credit_approved = load.data.user.data.credit_approved;
      }
      ret.self = getSelf(state);
      return ret;
    },
    dispatch => {
      return {
        initializeForm: data => dispatch(initialize('FactoringRequestPaymentForm', data)),
        openModal: () => dispatch(openModal('tafsregister')),
        fetchDebtor: id => dispatch(factoringdebtorResourceUSER.fetch(id)),
      };
    },
    ({ load, status, self, load_owner_credit_approved }, dispatchProps, ownProps) => {
      const ret = {
        ...ownProps,
        load_owner_credit_approved,
        openModal: dispatchProps.openModal,
      };
      if (self) {
        ret.self = self;
      }
      if (load) {
        ret.onClick = async () => {
          let debtor = null;
          let factoring_discount_rate = null;
          try {
            const action = await dispatchProps.fetchDebtor(load.data.debtor);
            debtor = {
              id: action.payload.json.id,
              dba: action.payload.json.dba,
              credit_approved: action.payload.json.credit_approved,
              originals_required: action.payload.json.originals_required,
            };
            factoring_discount_rate = action.payload.json.discount_rate;
          }
          catch (err) {
            console.warn(err);
          }
          dispatchProps.initializeForm({
            bill_to: debtor,
            factoring_discount_rate,
            bill_to_address: {
              street_one: load.data.user.data.address,
              city: load.data.user.data.city,
              state: load.data.user.data.state,
              zip: load.data.user.data.zip,
              country: load.data.user.data.country,
            },
            first_origin_location: {
              city: load.data.origin_city,
              state: load.data.origin_state,
            },
            final_destination_location: {
              city: load.data.destination_city,
              state: load.data.destination_state,
            },
            load_length: load.data.length,
            load_trailer_type: load.data.trailerType,
            bill_to_company_phone: load.data.user.data.contact_phone,
            bill_to_company_email: typeof load.data.user.data.external === 'string' && load.data.user.data.external.startsWith('posteverywhere') ? load.data.user.data.contact_email : load.data.user.data.email,
            user_load_number: load.data.user_load_number,
            source_load: load.data.id,
          });
        };
      }
      return ret;
    },
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
    isPendingProp: 'disabled',
  }),
)(({ self, openModal, load_id, load_owner_credit_approved, ...rest }) => {
  if (!load_id || !load_owner_credit_approved) {
    return null;
  }
  const buttonText = 'HAUL PAY APPROVED';
  if (
    self.data.factoring_approved === USER_FACTORING_STATUS.APPROVED ||
    [USER_TYPE.CARRIER, USER_TYPE.DISPATCHER].includes(self.data.type) ||
    self.data.fmcsa_type === USER_TYPE.CARRIER
  ) {
    return (
      <Link to='/haul-pay'>
        <button
          type='button'
          {...rest}
          className={cs('btn btn-success', rest.className)}
          >
          {rest.children || buttonText}
        </button>
      </Link>
    );
  }
  return (
    <button
      type='button'
      {...rest}
      onClick={openModal}
      className={cs('btn btn-success', rest.className)}
      >
      {rest.children || buttonText}
    </button>
  );
});
