/**
 * Root reducer for the paginated resource collection reducers
 * @module reducers/resourceBy
 * @since 3.0.0
 * @requires reducers/resourceBy/load
 * @requires reducers/resourceBy/truck
 * @requires reducers/resourceBy/user
 * @requires reducers/resourceBy/factoringclient
 * @requires reducers/resourceBy/factoringdebtor
 */
import { combineReducers } from 'redux-v3';

import load from './load';
import truck from './truck';
import user from './user';
import factoringclient from './factoringclient';
import factoringdebtor from './factoringdebtor';
import factoringpayment from './factoringpayment';

export default combineReducers({
  load,
  truck,
  user,
  factoringclient,
  factoringdebtor,
  factoringpayment,
});
