/* global fbq process*/
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import cs from 'classnames';
import getSelfType from 'selectors/getSelfType';

import { USER_TYPE } from 'helpers';
import { openModal } from 'actions/ui';
import savedloadByUserResource from 'actions/resourceBy/user/savedload';
import APIError from 'datatypes/error/APIError';
import segmentEvents from '../../helpers/segmentEvents';


export default compose(
  connect(
    state => {
      return {
        user_type: getSelfType(state),
        user_id: state.user.id,
        logged_in: state.user.logged_in,
      };
    },
    {
      openModal,
      create: savedloadByUserResource.create,
    },
    ({ user_type, user_id, logged_in }, { create, openModal }, { id, ...rest }) => {
      if (logged_in) {
        return {
          ...rest,
          user_type,
          onClick() {
            return create(user_id, { load: id })
              .then(() => {
                window.analytics.track(segmentEvents.USER_SAVED_LOAD, { user_id, load_id: id });
                openModal('success', { message: 'Successfully saved load.' });
              })
              .catch(err => {
                console.warn(err);
                let message = 'Failed to save load :(';
                if (err instanceof APIError) {
                  if (err.message.status === APIError.type.VALIDATION_ERROR) {
                    message = 'This load was already saved.';
                  }
                }
                openModal('error', { message });
              })
              ;
          },
        };
      }
      return {
        ...rest,
        user_type,
        onClick() {
          browserHistory.push('/signup');
        },
      };
    },
  ),
)(({ user_type, ...rest }) =>
  USER_TYPE.IS_ADMIN(user_type) || USER_TYPE.IS_ALADDIN_ADMIN(user_type) ? null :
    <button
      type='button'
      {...rest}
      className={cs('btn btn-default', rest.className)}
    >
      Save this Load
    </button>
);
