import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import moment from 'moment';
import formatPennies from 'helpers/formatPennies';
import has from 'lodash/has';
import { fetchFactoringPaymentSum } from 'actions/factoring';
import * as factoringpaymentByUser from 'actions/resourceBy/user/factoringpayment';

import DateRangePicker from 'components/pure/form/inputs/DateRangeInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import { CenteredSpinner } from 'components/pure/Spinner';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonFactoringClientExportPayments from 'components/container/ButtonFactoringClientExportPayments';
import DateSelectButton from './DateSelectButton';

const FactoringPaymentsTotalRevenue = compose(
  connect(
    state => {
      return {
        id: state.user.id,
      };
    },
    {
      fetchFactoringPaymentSum,
      clear: factoringpaymentByUser.clear,
      fetch: factoringpaymentByUser.fetch,
    },
  ),
)(class FactoringPaymentsTotalRevenue extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickingRange: false,
      hasLoaded: true,
      totalRevenue: null,
      range: null,
    };
    this.calculateTotalRevenueForPeriod = this.calculateTotalRevenueForPeriod.bind(this);
    this.setStart = this.setStart.bind(this);
    this.setEnd = this.setEnd.bind(this);
  }

  componentDidMount() {
    this.calculateTotalRevenueForPeriod(false);
  }

  async calculateTotalRevenueForPeriod(reload_list = true) {
    const { range } = this.state;
    let totalRevenue = null;
    try {
      if (reload_list) {
        this.props.clear(this.props.id);
        await this.props.fetch(this.props.id, {
          debtor_id: this.props.debtor,
          datetime_start: range && range.start ? range.start.format() : undefined,
          datetime_end: range && range.end ? range.end.format() : undefined,
        });
      }
      const { amount_total } = await this.props.fetchFactoringPaymentSum(range);
      totalRevenue = amount_total;
    }
    catch (err) {
      console.warn(err);
    }
    finally {
      this.setState({
        totalRevenue,
        pickingRange: false,
      });
    }
  }

  setStart(start) {
    if (!start.isValid || !start.isValid()) {
      return;
    }
    let { range } = this.state;
    const end = range ? range.end : null;
    range = moment.range(start, end);
    range.end = end;
    this.setState({ range });
  }

  setEnd(end) {
    if (!end.isValid || !end.isValid()) {
      return;
    }
    let { range } = this.state;
    const start = range ? range.start : null;
    range = moment.range(start, end);
    range.start = start;
    this.setState({ range });
  }

  render() {
    const {
      hasLoaded,
      pickingRange,
      range,
      totalRevenue,
    } = this.state;
    const label = (has(range, 'start') && has(range, 'end')) ? `${moment(range.start).format('l')} -> ${moment(range.end).format('l')}` : 'Beginning of Time';

    if (!hasLoaded) {
      return <CenteredSpinner />;
    }

    if (pickingRange) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            width: '100%',
            height: '100%',
            zIndex: '10000',
          }}
          >
          <div
            style={{
              borderRadius: 5,
              padding: '2em 0',
              maxHeight: '100vh',
              maxWidth: '100vw',
              backgroundColor: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              >
              <MaterialIcon size={30} name='close' onClick={() => this.setState({ pickingRange: false })} role='button' />
            </div>
            <div
              style={{
                margin: '0 20px',
                width: 275,
              }}
              >
              <DateTimeInput
                label='Start'
                pickerOptions={{ time: false }}
                input={{
                  value: range ? range.start : null,
                  onChange: this.setStart,
                  onFocus: this.setStart,
                  onBlur: this.setStart,
                }}
                meta={{}}
              />
              <DateTimeInput
                label='End'
                pickerOptions={{ time: false }}
                input={{
                  value: range ? range.end : null,
                  onChange: this.setEnd,
                  onFocus: this.setEnd,
                  onBlur: this.setEnd,
                }}
                meta={{}}
              />
            </div>
            <DateRangePicker
              initialFromValue={true}
              value={range && range.start && range.end ? range : null}
              onSelect={range => this.setState({ range })}
            />
            <div
              style={{
                margin: '0 20px',
              }}
              className='text-right'
              >
              <button
                type='button'
                className='btn btn-orange'
                onClick={() => this.calculateTotalRevenueForPeriod()}
                disabled={!range || !range.start || !range.end}
                >
                Filter
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: 12.5,
        }}
        >
        <div>
          <div
            style={{
              display: 'inline-block',
              padding: '6px 0',
            }}
            >
            Total Revenue ({label}): {formatPennies(totalRevenue)}
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <ButtonFactoringClientExportPayments
            start={range && range.start ? range.start : undefined}
            end={range && range.end ? range.end : undefined}
            debtor={this.props.debtor}
          />
          <div style={{ paddingLeft: 12 }}>
            <DateSelectButton
              onClick={() => this.setState({ pickingRange: true })}
              range={range}
            />
          </div>
        </div>
      </div>
    );
  }
});

export default FactoringPaymentsTotalRevenue;
