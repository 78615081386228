import React from 'react';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import MaterialIcon from 'components/pure/MaterialIcon';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import Header from 'components/pure/Header';

const style = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    item: {
        justifyContent: 'space-around',
        margin: '1%',
    },
    text: {
        fontSize: 15
    }
}

const PaymentFundingRequestError = ({ closeModal }) => {
    return <div>
        <ModalBody
            closeModal={closeModal} 
        >
            <div style={style.container}>
                <div style={style.item}>
                    <MaterialIcon name='warning' className='text-warning' size='100'/>
                </div>
                <div style={style.item}>
                    <p style={style.text}>
                        A payment has already been made on this funding request and cannot be moved from this status.
                    </p>
                </div>
                <div style={style.item}>
                    <p style={style.text}>
                        Please contact support for further assistance.
                    </p>
                </div>
                <div style={{
                    ...style.item, display: "flex", flex: 1, flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <button
                        style={{ width: "100%" }}
                        className='btn btn-default'
                        type='submit'
                        onClick={closeModal}>
                        Ok
                    </button>
                </div>
            </div>
        </ModalBody>
    </div>
};
export default PaymentFundingRequestError;
