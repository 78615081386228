import axios from 'axios';

export const frCarrierBulkUpload = async (attachment, isCsv = false, retries = 0) => {
    let attempt = 0;
    const url = `/funding_requests/bulk_upload/`;
    const data = new FormData();
    data.append('attachment', new Blob([attachment], { type: isCsv ? 'text/csv' : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), 'chunk.' + (isCsv ? 'csv' : 'xlsx'));
    const options = {
        url,
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data',
        },
        data
    };
    while (attempt < retries) {
        try {
            const response = await axios(options);
            return response.data;
        } catch (error) {
            attempt++;
            if (attempt >= retries) {
                throw error;
            }
        }
    }
};

export const ascendBulkUpload = async ({ loads, customers, carriers }) => {
    const url = `/integrations/ascend/csv_import/`;
    const data = new FormData();
    data.append('loads', loads);
    data.append('customers', customers);
    data.append('carriers', carriers);
    const options = {
        url,
        method: 'POST',
        headers: {
            'content-type': 'multipart/form-data',
        },
        data
    };
    const response = await axios(options);
    return response.data;
};
