/* global StripeCheckout STRIPE_KEY */
import React from 'react';
import { USER_TYPE } from 'helpers';
import { memoize } from 'lodash';

const create = options => {
  try {
    return StripeCheckout.configure({
      key: STRIPE_KEY,
      image: '/public/image/icon/ComFreightLogo.png',
      locale: 'en',
      name: 'ComFreight',
      description: 'Freight Marketplace',
      zipCode: true,
      allowRememberMe: false,
      token(token) {
        // eslint-disable-next-line no-console
        console.log(token);
      },
      ...options,
    })
  } catch (err) {
  }
};

export default memoize(create);

// To update prices/etc:
// - edit price here
// - make sure your price and the stripe plan_name correspond
// - make sure the corresponding upgrades_to_plan is correct
// - make sure the initialValues in the subscription form are correct
// - done

export const packages = {
  [USER_TYPE.BROKER]: [
    {
      title: 'Annually',
      price: 1875,
      stripePrice: 22500,
      note: '$225.00 Billed yearly',
      balloon: <span>Save<br />$123</span>,
      period: 'yearly',
      details: [
        '1 user login',
        '$225.00/year/user',
      ],
      plan_name: 'broker-yearly',
    },
    {
      title: 'Monthly',
      price: 2900,
      stripePrice: 2900,
      note: 'Billed monthly.',
      period: 'monthly',
      details: [
        '1 user login',
        '$348.00/year/user',
      ],
      plan_name: 'broker-monthly2',
      upgrades_to_plan: 'broker-yearly',
    },
  ],
  [USER_TYPE.DISPATCHER]: [
    {
      title: 'Annually',
      price: 1875,
      stripePrice: 22500,
      note: '$225.00 Billed yearly',
      balloon: <span>Save<br />$123</span>,
      period: 'yearly',
      details: [
        '1 user login',
        '$225.00/year/user',
      ],
      plan_name: 'dispatcher-yearly',
    },
    {
      title: 'Monthly',
      price: 2900,
      stripePrice: 2900,
      note: 'Billed monthly.',
      period: 'monthly',
      details: [
        '1 user login',
        '$348.00/year/user',
      ],
      plan_name: 'dispatcher-monthly',
      upgrades_to_plan: 'dispatcher-yearly',
    },
  ],
  [USER_TYPE.CARRIER]: [
    {
      title: 'Annually',
      price: 1458,
      stripePrice: 17500,
      note: '$175.00 billed yearly',
      balloon: <span>Save<br />$125</span>,
      period: 'yearly',
      details: [
        '1 user login',
        '$175.00/year/user',
      ],
      plan_name: 'carrier-yearly',
    },
    {
      title: 'Monthly',
      price: 2500,
      stripePrice: 2500,
      note: 'Billed monthly.',
      period: 'monthly',
      details: [
        '1 user login',
        '$300.00/year/user',
      ],
      plan_name: 'carrier-monthly2',
      upgrades_to_plan: 'carrier-yearly',
    },
  ],
  [USER_TYPE.SHIPPER]: [
    {
      title: 'Annually',
      price: 1875,
      stripePrice: 22500,
      note: '$225.00 billed yearly',
      balloon: <span>Save<br />$123</span>,
      period: 'yearly',
      details: [
        '1 user login',
        '$225.00/year/user',
      ],
      plan_name: 'shipper-yearly',
    },
    {
      title: 'Monthly',
      price: 2900,
      stripePrice: 2900,
      note: 'Billed monthly.',
      period: 'monthly',
      details: [
        '1 user login',
        '$348.00/year/user',
      ],
      plan_name: 'shipper-monthly',
      upgrades_to_plan: 'shipper-yearly',
    },
  ],
};


export const all_packages_by_name = {};

for (const user_type in packages) {
  const packages_for_user_type = packages[user_type];
  for (const pack of packages_for_user_type) {
    all_packages_by_name[pack.plan_name] = pack;
  }
}

// include retired packages here

all_packages_by_name['broker-monthly'] = {
  title: 'Monthly',
  price: 2500,
  stripePrice: 2500,
  note: 'Billed monthly.',
  period: 'monthly',
  details: [
    '1 user login',
    '$300.00/year/user',
  ],
  plan_name: 'broker-monthly',
  upgrades_to_plan: 'broker-yearly',
};

all_packages_by_name['broker-monthly3'] = {
  title: 'Monthly',
  price: 3500,
  stripePrice: 3500,
  note: 'Billed monthly.',
  period: 'monthly',
  details: [
    '1 user login',
    '$420.00/year/user',
  ],
  plan_name: 'broker-monthly3',
  upgrades_to_plan: 'broker-yearly',
};

all_packages_by_name['carrier-monthly'] = {
  title: 'Monthly',
  price: 1900,
  stripePrice: 1900,
  note: 'Billed monthly.',
  period: 'monthly',
  details: [
    '1 user login',
    '$228.00/year/user',
  ],
  plan_name: 'carrier-monthly',
  upgrades_to_plan: 'carrier-yearly',
};
