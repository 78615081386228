/**
 * Component to display marketing information related to subscribing
 * @module components/pure/FullAccessBenefits
 * @since 3.0.0
 * @requires components/pure/BackgroundHeader
 * @requires components/pure/YoutubeEmbed
 * @property {object} props
 * @property {string} props.type - the user type benefits to display
 * @property {any} [props....rest] - props passed to FullAccessBenefits component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';

import { USER_TYPE } from 'helpers';

import BackgroundHeader from 'components/pure/BackgroundHeader';
import YoutubeEmbed from 'components/pure/YoutubeEmbed';


const benefits = {
  [USER_TYPE.CARRIER]: [
    'Full access to bid and/or message all direct shipper loads',
    'Full access to bid and/or message all broker posted loads',
    'Unlimited load searching, truck posting and load matching',
    'Get unrestricted full access to new shipper load post alerts',
    'Store and send important business documents via the cloud',
    'Market trending rates on loads and unlimited use of rate trends',
  ],
  [USER_TYPE.BROKER]: [
    'Full access to bid and/or message all direct shipper loads',
    'Full access to bid and/or message all broker posted loads',
    'Full access to viewing all info on trucks in truck search',
    'Get unrestricted full access to new shipper load post alerts',
    'Full access to calling or messaging all carrier posted trucks',
    'Store and send important business documents via the cloud',
    'Market trending rates on loads and unlimited use of rate trends',
  ],
  [USER_TYPE.SHIPPER]: [
    'Full access to viewing all info on trucks in Trucks search',
    'Get unlimited access to all bids and bid alerts for new rates',
    'Full access to calling or messaging all carrier posted trucks',
    'Store and send important business documents via the cloud',
    'Market trending rates on loads and unlimited use of rate trends',
  ],
};
benefits[USER_TYPE.DISPATCHER] = benefits[USER_TYPE.BROKER];

const video = {
  [USER_TYPE.CARRIER]: 'g2U1zIStXdM',
  [USER_TYPE.BROKER]: '-0V-tioGPmM',
  [USER_TYPE.DISPATCHER]: '-0V-tioGPmM',
  [USER_TYPE.SHIPPER]: undefined,
};

export const FullAccessBenefits = ({ type }) =>
  <div>
    <BackgroundHeader>Full Access Benefits</BackgroundHeader>
    <YoutubeEmbed video_id={video[type]} />
    {(benefits[type] || []).map((benefit, i) =>
      <p
        key={i}
        style={{
          padding: '0.25em 0',
          paddingLeft: 15,
          margin: 0,
          borderBottom: `solid 1px ${colors.BORDER_GRAY}`,
        }}
        >
        {benefit}
      </p>
    )}
  </div>
  ;

FullAccessBenefits.propTypes = {
  type: PropTypes.oneOf([USER_TYPE.CARRIER, USER_TYPE.BROKER, USER_TYPE.SHIPPER, USER_TYPE.DISPATCHER]).isRequired,
};

export default FullAccessBenefits;
