/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, initialize, reduxForm, reset } from 'redux-form';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import Toggle from 'material-ui/Toggle';
import { Link } from 'react-router-v4';
import { USER_TYPE } from 'helpers';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import * as validate from 'helpers/validate';
import { searchFactoringDebtors } from 'actions/factoring';
import { closeModal, openModal, unshiftModal } from 'actions/ui';
import { userCreateDebtor } from 'actions/resource/factoringdebtor';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import MCorDOTorEINInputs from 'components/pure/form/inputs/MCorDOTorEINInputs';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import ModalWarning from './Warning';
import getSelf from 'selectors/getSelf';
import { getDataFromDOT, getDataFromMC } from '../../../actions/user';
import has from 'lodash/has';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import formatPennies from 'helpers/formatPennies';
import { factoringdebtorByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringdebtor';
import { create } from 'actions/resourceBy/factoringdebtor/factoringdebtornote';
import segmentEvents from '../../../helpers/segmentEvents';


const FORM_NAME = 'FactoringClientAddDebtorForm';
const NoPermissionModal = () =>
  <ModalWarning
    headerChildren='You are not yet approved.'
    message="You're currently not yet fully approved for Haul Pay. Please check with support@haulpay.io or call 888-633-5558 to check on the status of your application. Once your application is fully approved you'll be able to add new customers and request credit checks. Thank you."
  />
  ;

const getFMCSAInfo = (promise, fields) => dispatch => {
  promise(fields)
    .then(data => {
      if (data && !has(data, 'search_query')) {
        dispatch(change(FORM_NAME, 'dba', data.dba || data.name));
        dispatch(change(FORM_NAME, 'phone', data.phone));
        dispatch(change(FORM_NAME, 'address', {
          street: data.street_one,
          city: data.city,
          state: data.state,
          zip: data.zip,
          country: data.country,
        }));
      }
    })
    .catch(err => console.warn(err));
};
const selector = formValueSelector(FORM_NAME)
export default compose(
  connect(
    state => {
      const self = getSelf(state);
      if (!self) {
        return {};
      }
      const defaultDoNotContractDebtors = get(state.user, `factoring.data.default_do_not_contact_debtors`, false);
      const allowSelfFinanceFr = get(state.user, `factoring.data.allow_self_finance_fr`, false);
      const allowDoNotContractDebtors = get(state.user, `factoring.data.allow_do_not_contact_debtors`, false);
      return {
        self,
        // have to include this so we don't overwrite any previously filled-in information when we initialize below
        FactoringRequestPaymentFormState: formValueSelector('FactoringRequestPaymentForm')(state,
          'attachments',
          'amount',
          'first_origin_location',
          'final_destination_location',
          'load_length',
          'load_trailer_type',
          'user_load_number',
          'should_create_invoice',
          'bill_to_company_phone',
          'bill_to_company_email',
          'user_notes',
          'user_reference_number',
          'should_create_invoice',
        ),
        debtorCreditApproved: selector(state, 'credit_approved'),
        factoring_id: get(state.user, ['factoring', 'data', 'id']),
        factoringProfile: get(state.user, ['factoring', 'data'], {}),
        allowSelfFinanceFr,
        allowDoNotContractDebtors,
        defaultDoNotContractDebtors,
        initialValues: {
            selected_number: MCorDOTorEINInputs.MC,
            ...(() => (allowSelfFinanceFr && allowDoNotContractDebtors) && { credit_approved: defaultDoNotContractDebtors })()
        }
      };
    },
    dispatch => ({
      handleChange: (...args) => dispatch(change(...args)),
      openSendNOAModal: props => dispatch(openModal('SendNOA', props)),
      closeModal: (...args) => dispatch(closeModal(...args)),
      addCustomer: (factoringId, factoringProfile) => async (debtorId, autoSendNoa = false, extraParams = {}, shouldUpdateFactoringPaymentEditForm, shouldUpdateFactoringRequestPaymentForm, FactoringRequestPaymentFormState) => {
        return dispatch(factoringdebtorByfactoringclientUSER.create(factoringId, {
          debtor: debtorId,
          auto_send_noa: autoSendNoa,
          ...extraParams,
        }))
          .then(({ payload }) => {
            if (factoringProfile.discount_rate < payload.discount_rate) {
              dispatch(openModal('warning', { message: 'This customer\'s rate is higher than yours, the future funding requests will inherit this customers rate.' }));
            }
            dispatch(reset(FORM_NAME));
            dispatch(openModal('success', { message: 'Successfully added customer.' }));
            if (shouldUpdateFactoringPaymentEditForm) {
              dispatch(change('FactoringPaymentEditForm', 'debtor', {
                id: payload.id,
                dba: payload.dba,
              }));
              dispatch(change('FactoringPaymentEditForm', 'bill_to_address', typeof data.address === 'string' ? {
                street_one: payload.address,
              } : {
                street_one: payload.address.street_one,
                city: payload.address.city,
                state: payload.address.state,
                zip: payload.address.zip,
                country: payload.address.country,
              }));
            }
            if (shouldUpdateFactoringRequestPaymentForm) {
              dispatch(initialize('FactoringRequestPaymentForm', {
                ...FactoringRequestPaymentFormState,
                bill_to: {
                  id: payload.id,
                  dba: payload.company_name,
                  credit_approved: payload.credit_approved,
                },
                bill_to_address: typeof payload.address === 'string' ? {
                  street_one: payload.address,
                } : {
                  street_one: payload.address.street_one,
                  city: payload.address.city,
                  state: payload.address.state,
                  zip: payload.address.zip,
                  country: payload.address.country,
                },
              }));
            }
            if (autoSendNoa &&
              !shouldUpdateFactoringPaymentEditForm &&
              !shouldUpdateFactoringRequestPaymentForm) {
              window.location.reload();
            }
          })
          .catch(err => {
            console.warn(err);
            if (err instanceof APIError && err.status === 400 && get(err, ['message', 'non_field_errors', '0']) === 'The fields factoring, debtor must make a unique set.') {
              dispatch(openModal('error', { message: 'You\'re already associated with that customer.' }));
            }
            else {
              dispatch(openModal('error', { message: 'Failed to add customer :(' }));
            }
          })
          ;
      },
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
      addCustomer: dispatchProps.addCustomer(stateProps.factoring_id, stateProps.factoringProfile),
    })
  ),
  branch(
    ({ self }) => (
      self.data.factoring_approved !== USER_FACTORING_STATUS.APPROVED &&
      (!USER_TYPE.IS_ADMIN(self.data.type) && !USER_TYPE.IS_ALADDIN_ADMIN(self.data.type))
    ),
    renderComponent(NoPermissionModal),
    i => i,
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate(fields) {
      const mc = fields.mc;
      const dot = fields.dot;
      const tax_id = fields.tax_id;
      const duns = fields.duns;
      const creditApproved = fields.credit_approved
      const errs = {};
      if (fields.selected_number === "tax_id" || creditApproved) {
        return;
      }
      if (!mc && !dot && !duns) {
        errs.mc = 'Please include a MC, DOT or DUNS Number.';
        errs.dot = 'Please include a MC, DOT or DUNS Number.';
        errs.duns = 'Please include a MC, DOT or DUNS Number.';
      }
      if (!isEmpty(fields.attachments) && !fields.notes) {
        errs.notes = 'Please add notes.';
      }
      return errs;
    },
    async onSubmit(fields, dispatch, { reset: resetSelf, factoring, shouldUpdateFactoringRequestPaymentForm, shouldUpdateFactoringPaymentEditForm, FactoringRequestPaymentFormState }) {
      try {
        const { id, credit_approved, company_name } = await dispatch(userCreateDebtor({
          factoring, // the ID of the factoring client (if admin is editing)
          ...(() => fields.credit_approved && {credit_approved: 'do_not_contact'})(),
          email: fields.email,
          name: fields.contact_name,
          company_name: fields.dba,
          mc: fields.mc,
          dot: fields.dot,
          duns_number: fields.duns,
          tax_id: fields.tax_id,
          noa_contact_email: fields.noa_contact_email,
          payment_status_email: fields.payment_status_email,
          address: typeof fields.address === 'string' ? {
            street_one: fields.address,
            street_two: fields.street_2,
          } : {
            street_one: fields.address?.street_one,
            street_two: fields.street_2,
            city: fields.address?.city,
            state: fields.address?.state,
            zip: fields.address?.zip,
            country: fields.address?.country,
          },
          phone_number: fields.phone,
        }));
        if(fields.notes) {
          const notes = `${fields.notes} \n ${fields.requested_credit_limit && `Requested Credit Limit : ${formatPennies(fields.requested_credit_limit)}`}`;
          await dispatch(create(id, {
            debtor: id,
            note: notes,
            attachments: fields.attachments,
          }));
        }
        window.analytics.track(segmentEvents.USER_CREATED_NEW_CUSTOMER, { company_name, customer_id: id });
        dispatch(openModal('success', { message: 'Successfully added customer!' }));
        if (!['do_not_contact', 'approved'].includes(credit_approved) ) {
          dispatch(openModal('warning', { message: 'This customer is not yet approved for credit terms. We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
        }
        resetSelf();
        if (shouldUpdateFactoringPaymentEditForm) {
          dispatch(change('FactoringPaymentEditForm', 'debtor', {
            id,
            dba: fields.dba,
          }));
          dispatch(change('FactoringPaymentEditForm', 'bill_to_address', typeof fields.address === 'string' ? {
            street_one: fields.address,
          } : {
            street_one: fields.address.street_one,
            city: fields.address.city,
            state: fields.address.state,
            zip: fields.address.zip,
            country: fields.address.country,
          }));
        }
        if (shouldUpdateFactoringRequestPaymentForm) {
          dispatch(initialize('FactoringRequestPaymentForm', {
            ...FactoringRequestPaymentFormState,
            bill_to: {
              id,
              credit_approved,
              dba: company_name,
            },
            bill_to_address: typeof fields.address === 'string' ? {
              street_one: fields.address,
            } : {
              street_one: fields.address.street_one,
              city: fields.address.city,
              state: fields.address.state,
              zip: fields.address.zip,
              country: fields.address.country,
            },
          }));
        }
        dispatch(closeModal());
      }
      catch (err) {
        console.warn(err);
        dispatch(unshiftModal('error', { message: 'Error creating customer :(' }));
      }
    },
    async asyncValidate(fields, dispatch, { addCustomer, openSendNOAModal, shouldUpdateFactoringPaymentEditForm, shouldUpdateFactoringRequestPaymentForm, FactoringRequestPaymentFormState }, blurredField) {
      const mc = fields.mc;
      const dot = fields.dot;
      const tax_id = fields.tax_id;
      const credit_approved = fields.credit_approved
      const connectDebtor = data => {
        dispatch(closeModal());
        switch (data.credit_approved) {
          case 'declined':
            return addCustomer(data.id);
          case 'needs_review':
          case 'approved':
            return openSendNOAModal({
              debtor: data,
              cancel: () => addCustomer(data.id, false, {}, shouldUpdateFactoringPaymentEditForm, shouldUpdateFactoringRequestPaymentForm, FactoringRequestPaymentFormState),
              confirm: () => addCustomer(data.id, true, {}, shouldUpdateFactoringPaymentEditForm, shouldUpdateFactoringRequestPaymentForm, FactoringRequestPaymentFormState),
            });
          case 'pending':
          default:
            return addCustomer(data.id, false, { adjucate_debtor: false }, shouldUpdateFactoringPaymentEditForm, shouldUpdateFactoringRequestPaymentForm, FactoringRequestPaymentFormState);
        }
      }
      if(credit_approved) return;
      switch (blurredField) {
        case 'dot':
          if (!dot) {
            return;
          }
          return dispatch(searchFactoringDebtors({ dot }))
            .catch(err => console.warn(err))
            .then(data => {
              if (data && data.length > 0) {
                throw { // eslint-disable-line no-throw-literal
                  dot: <div>This dot# already exists with debtor name {data[0].dba}, Please add a different dot# or {' '}
                    <a
                      role='button' onClick={() => connectDebtor(data[0])}
                    >
                      Use this instead.
                    </a>
                  </div>,
                };
              }
              else {
                dispatch(getFMCSAInfo(getDataFromDOT, fields.dot));
              }
            })
            ;
        case 'mc':
          if (!mc) {
            return;
          }
          return dispatch(searchFactoringDebtors({ mc }))
            .catch(err => console.warn(err))
            .then(data => {
              if (data && data.length > 0) {
                throw { // eslint-disable-line no-throw-literal
                  mc: <div>This mc# already exists with debtor name {data[0].dba}, Please add a different mc# or {' '}
                    <a
                      role='button' onClick={() => connectDebtor(data[0])}
                    >
                      Use this instead.
                    </a>
                  </div>,
                };
              }
              else {
                dispatch(getFMCSAInfo(getDataFromMC, fields.mc));
              }
            });
        case 'tax_id':
          if (!tax_id) {
            return;
          }
          return dispatch(searchFactoringDebtors({ tax_id }))
            .catch(err => console.warn(err))
            .then(data => {
              if (data && data.length > 0) {
                throw { // eslint-disable-line no-throw-literal
                  tax_id: <div>This TAX ID# already exists with debtor name {data[0].dba}, Please add a different TAX ID# or {' '}
                    <a
                      role='button' onClick={() => connectDebtor(data[0])}
                    >
                      Use this instead.
                    </a>
                  </div>,
                };
              }
            });
      }
    },
  }),
)(({ handleSubmit,
     submitting,
     closeModal,
     allowSelfFinanceFr,
     allowDoNotContractDebtors,
     defaultDoNotContractDebtors,
     handleChange,
     debtorCreditApproved
}) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Add New Customer Profile</h4>
      <p>Add new customer profile so you can select them for payment requests.</p>
      <p>If they are new to our database, we will run credit and update you by 1 business day.</p>
    </div>
      <div className="modal-body">
          {allowSelfFinanceFr && allowDoNotContractDebtors &&
              <Field
                  label="Create as Do Not Contact"
                  name="credit_approved"
                  component={Toggle}
                  defaultToggled={defaultDoNotContractDebtors}
                  onToggle={(value, isInputChecked) => {
                      handleChange(FORM_NAME, 'credit_approved', isInputChecked);
                  }}
              />
          }
          <div style={{ height: 25 }} />
          <MCorDOTorEINInputs />
          <Field
              name="dba"
              component={LabeledInput}
              validate={validate.compose(
                  validate.vjs.presence()
              )}
              label="Bill To Company Name *"
          />
          <Field
              name="address"
              component={GoogleLocationInput}
              validate={!debtorCreditApproved && validate.compose(
                  validate.vjs.presence()
                  // validate.vjs.googleAddress(['street_one', 'zip', 'state'])
              )}
              label="Bill To Company Address *"
              setValueWithoutConfirming={true}
          />
          <Field
              name="street_2"
              component={LabeledInput}
              label="Street 2"
              setValueWithoutConfirming={true}
          />
          <Field
              name="phone"
              component={PhoneInput}
              validate={!debtorCreditApproved && validate.compose(
                  validate.vjs.presence(),
                  validate.phone()
              )}
              label="Bill To Company Phone *"
          />
          <Field
              name="email"
              component={LabeledInput}
              validate={validate.compose(
                  validate.vjs.presence(),
                  validate.vjs.multipleEmail()
              )}
              label="Invoicing Email*"
          />
          <Field
              name="noa_contact_email"
              component={LabeledInput}
              validate={validate.compose(
                  validate.vjs.email()
              )}
              label="NOA Email"
          />
          <Field
              name="payment_status_email"
              component={LabeledInput}
              validate={validate.compose(
                  validate.vjs.email()
              )}
              label="Payment Status Email"
          />
          <Field name="contact_name" component={LabeledInput} label="Contact Name at Company" />
          <Field name="requested_credit_limit" component={PennyInput} label="Requested Credit Limit" />
          <Field
              name="notes"
              component={GrowableInput}
              props={{
                  Component: LabeledInput,
                  ComponentClass: 'textarea',
                  placeholder: 'Notes'
              }}
              label="Notes"
          />
          <Field
              name="attachments"
              component={MultiImageInput}
              limit={25}
              buttonText="Upload Documents"
              buttonClassName="btn btn-default"
              shouldCrop={false}
              convertAnyway={true}
              buttonStyle={{
                  float: 'right'
              }}
          />
          <div className="help-block">* Required Field</div>
      </div>
      <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal} type='button' disabled={submitting}>Cancel</button>
      <button className='btn btn-orange' type='submit' disabled={submitting}>Add New Customer</button>
    </div>
  </form>
);
