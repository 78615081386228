import React, { useCallback } from 'react';
import cs from 'classnames';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import isString from 'lodash/isString';

const DropZoneField = ({
    accept,
    input: {
        onChange,
        value,
        onDrop,
        ...input
    },
    name,
    label,
    meta: { error, touched },
    dropZoneProps= {}
}) => {
    const hasError = touched && error && isString(error);
    const onDropHandler = useCallback(acceptedFiles => {
        let newFiles = acceptedFiles;
        if (Array.isArray(value)) {
            newFiles = compact(concat(value, newFiles));
        }
        onChange(newFiles);
    }, [value]);
    return (
        <div>
            {label && <h5>{label}</h5>}
            <Dropzone name={name} onDrop={onDropHandler} {...dropZoneProps}>
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps({ className: 'dropzone' })}>
                        <input {...getInputProps({ accept, ...input })} />
                        <div
                            style={{
                                cursor: "pointer",
                                alignItems: 'center',
                                borderStyle: 'dashed',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                minHeight: '300px',
                                borderColor: !hasError ? 'rgba(0, 0, 0, 0.85)' : '#a94442',
                                backgroundColor: '#F9F9F9',
                            }}
                            className={cs('form-group', {
                                'has-error': hasError,
                            })}
                        >
                            <FontAwesomeIcon
                                style={{
                                    color: '#74B4FA',
                                    fontSize: '5em',
                                    margin: '10px',
                                }}
                                icon={faCloudUploadAlt}
                            />

                            <p
                                style={{
                                    margin: '10px',
                                }}
                                className='help-block'
                            >Choose files to upload or drag and drop them here</p>
                            {hasError && <div className='help-block'>{error}</div>}
                        </div>
                    </div>
                )}
            </Dropzone>
        </div>
    )
};

export default DropZoneField;
