import 'styles/CompanyDetailsTable';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import withContext from 'recompose/withContext';

import { UserBlockItem, UserItem } from './UserTableItem';
import ResponsiveTable from './ResponsiveTable';


const UserTable = compose(
  withContext({
    dontShowBidding: PropTypes.bool,
  }, ({ dontShowBidding }) => ({ dontShowBidding })),
)(({ items, isFetching = false, isShipperOnly = false, isTruckPost = false, dontShowBidding, ...rest }) =>
  <ResponsiveTable
    containerProps={{
      className: 'user-table',
    }}
    mobileContainerProps={{
      containerProps: {
        style: {
          overflowX: 'hidden',
        },
      },
    }}
    headers={['Company', 'Company Information', isShipperOnly ? 'Bid on Load' : isTruckPost ? 'Contact' : 'Contact or Bid', 'Reporting']}
    isFetching={isFetching}
    items={items}
    TableItem={UserItem}
    BlockTableItem={UserBlockItem}
    {...rest}
  />
);

UserTable.propTypes = {
  isFetching: PropTypes.bool,
  isShipperOnly: PropTypes.bool,
  isTruckPost: PropTypes.bool,
  items: PropTypes.arrayOf(PropTypes.object),
};

export default UserTable;
