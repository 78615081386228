// different types of segment tracking types.
export const trackType = {
  TRACK: 'track',
  PAGE: 'page',
  IDENTIFY: 'identify',
  GROUP: 'group',
};

export const resetTraits = () => window.analytics.reset();
// get anonymous userId
export const getAnonymousId = () => window.analytics.user().anonymousId();
export const isDebug = value => window.analytics.debug(value);
