import React from 'react';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';

import AttachmentsTable from './AttachmentsTable';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';

const mapAttachmentToCategory = (type, options = {}) => attachment => ({ type, ...attachment, ...options });

export default compose(
  ResourceBy('factoringdebtor', 'lineitemsdebtor'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return (
        get(props, 'attachments', []).some(attachment => !attachment.uploaded) ||
        get(props, 'attachments', []).length !== get(nextProps, 'attachments', []).length ||
        props.item.isFetching !== nextProps.item.isFetching
      );
    },
  }),
  mapProps(({ id, items, ...rest }) => ({
    form: `LineItemDisplay#${id}`,
    funding_request_id: `${id}`,
    lineItems: items.map(item => item.data),
    ...rest,
  }))
)(({ lineItems = [], attachments = [], lastDocIssueDate, fetchCollated, fetchInvoice, onDeleteClick, onEditClick }) => {
  const allAttachments = attachments.map(mapAttachmentToCategory('FR_attachment')).concat(
    lineItems.reduce((agg, lineItem) => {
      return agg.concat(
        lineItem.attachments.map(
          mapAttachmentToCategory('Accessorial', { category: lineItem.category_name, disableEdit: true, disableDelete: true })
        )
      );
    }, [])
  );

  return (
    <div className='text-left'>
      <h5 style={{ color: 'rgb(33, 150, 243)' }}>
        <b>Attachments List</b>
      </h5>
      <AttachmentsTable
        attachments={allAttachments}
        lastDocIssueDate={lastDocIssueDate}
        onDeleteClick={onDeleteClick}
        onEditClick={onEditClick}
      />
      <div style={{ display: 'flex', alignItems: 'center', fontSize: 'smaller' }}>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Collated Billing PDF
          </label>
          <LoadAttachmentButton getURL={fetchCollated} />
        </div>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Invoice
          </label>
          <LoadAttachmentButton getURL={fetchInvoice} />
        </div>
        <label className='control-label' style={{ marginLeft: 'auto' }}>
          Total attachments: {allAttachments.length}
        </label>
      </div>
    </div>
  );
});
