import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringtransactionpayment;

import factoringtransactionpaymentResource from 'actions/resource/factoringtransactionpayment';
import factoringtransactionpaymentsPaginatedResource from 'actions/admin/factoringtransactionpayments';


export default function factoringtransactionpaymentResourceReducer(state = initialState, action) {
  state = factoringtransactionpaymentResource.reduce(state, action);
  switch (action.type) {
    case factoringtransactionpaymentsPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringtransactionpaymentResource.parseMany(state, action.payload),
      };
  }
  return state;
}
