/**
 * Root reducer for the resource by load paginated resource collection reducers
 * @module reducers/resourceBy/load
 * @since 3.0.0
 * @requires reducers/resourceBy/load/qa
 * @requires reducers/resourceBy/load/bid
 * @requires reducers/resourceBy/load/loadreverse
 */
import { combineReducers } from 'redux-v3';

import qa from './qa';
import bid from './bid';
import loadreverse from './loadreverse';


export default combineReducers({
  qa,
  bid,
  loadreverse,
});
