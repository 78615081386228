import factoringGroupUserPaginatedResource from 'actions/admin/factoringGroupUsers';
import factoringGroupUserResource from 'actions/resource/factoringGroupUsers';
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringGroupUsers;

export default function factoringGroupUsersResourceReducer(state = initialState, action) {
  switch (action.type) {
    case factoringGroupUserPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringGroupUserResource.parseMany(state, action.payload),
      };
  }
  return factoringGroupUserResource.reduce(state, action);
}
