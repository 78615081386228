/**
 * Component that displays the Profile section of Settings
 * @module components/pure/SettingsProfile
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/ProfileForm
 */
import React from 'react';

import Header from './Header';
import ProfileForm from './form/ProfileForm';

export const SettingsProfile = () =>
  <div>
    <div className='col-xs-12'>
      <Header>Profile</Header>
    </div>
    <ProfileForm />
  </div>
  ;

export default SettingsProfile;
