/**
 * Point class, helps with the various shapes that coordinates need to be in throughout the app (eg google maps, responses from ComFreight API are different in some locations, and their shape in the store)
 * @module datatypes/Point
 * @since 3.0.0
 * @requires helpers
 */


/**
 * Point Class
 */
class Point {

  /**
   * @param {number} [lat] - the latitude coordinate
   * @param {number} [lng] - the longitude coordinate
   */
  constructor(lat = 0, lng = 0) {
    this.type = 'Point';
    this.coordinates = [lat, lng];
  }

  /**
   * @returns {object} the Point in the form `{ lat: number, lng: number }`
   */
  toLatLng() {
    const [lat, lng] = this.coordinates;
    return { lat, lng };
  }

  /**
   * @returns {object} the Point in the form `{ latitude: number, longitude: number }`
   */
  toLong() {
    const [latitude, longitude] = this.coordinates;
    return { latitude, longitude };
  }

  /**
   * @returns {string} the Point in its string form
   */
  toStr() {
    const [latitude, longitude] = this.coordinates;
    return `${latitude},${longitude}`;
  }

  /**
   * @param {object} point - a Point in the form `{ lat: number, lng: numbr }`
   * @returns Point
   */
  static fromLatLng(point) {
    const { lat, lng } = point;
    return new Point(lat, lng);
  }

  /**
   * @param {object} point - a Point in the form `{ coordinates: [number, number] }`
   * @param {string} [fallback] - a state iso2 code to use as coordinates if parsing the point fails for some reason
   * @returns Point
   */
  static fromGeoPoint(point, fallback) {
    if (!point) {
      return Point.getDefault(fallback);
    }
    const [lng, lat] = point.coordinates;
    return new Point(lat, lng);
  }

  /**
   * @param {string} str - a string similar to `SRID=12345;POINT {LAT} {LNG}`
   * @param {string} [fallback] - a state iso2 code to use as coordinates if parsing the point fails for some reason
   * @returns Point
   */
  static fromString(str, fallback) {
    if (str === '' || str === undefined || str === null) {
      return Point.getDefault(fallback);
    }
    const re = str.match(/^(?:SRID=\d{1,5};)?POINT \((-?\d+.\d+) (-?\d+.\d+)\)$/);
    if (re === null) {
      console.warn(`Failed to parse Point from string: ${str}`);
      return Point.getDefault();
    }
    return new Point(parseFloat(re[2]), parseFloat(re[1]));
  }

  /**
   * A map between state ISO2 codes and their coordinates
   */
  static states_coords = {
    // US
    'AL': new Point(32.806671, -86.791130),
    'AK': new Point(61.370716, -152.404419),
    'AZ': new Point(33.729759, -111.431221),
    'AR': new Point(34.969704, -92.373123),
    'CA': new Point(36.116203, -119.681564),
    'CO': new Point(39.059811, -105.311104),
    'CT': new Point(41.597782, -72.755371),
    'DE': new Point(39.318523, -75.507141),
    'DC': new Point(38.897438, -77.026817),
    'FL': new Point(27.766279, -81.686783),
    'GA': new Point(33.040619, -83.643074),
    'HI': new Point(21.094318, -157.498337),
    'ID': new Point(44.240459, -114.478828),
    'IL': new Point(40.349457, -88.986137),
    'IN': new Point(39.849426, -86.258278),
    'IA': new Point(42.011539, -93.210526),
    'KS': new Point(38.526600, -96.726486),
    'KY': new Point(37.668140, -84.670067),
    'LA': new Point(31.169546, -91.867805),
    'ME': new Point(44.693947, -69.381927),
    'MD': new Point(39.063946, -76.802101),
    'MA': new Point(42.230171, -71.530106),
    'MI': new Point(43.326618, -84.536095),
    'MN': new Point(45.694454, -93.900192),
    'MS': new Point(32.741646, -89.678696),
    'MO': new Point(38.456085, -92.288368),
    'MT': new Point(46.921925, -110.454353),
    'NE': new Point(41.125370, -98.268082),
    'NV': new Point(38.313515, -117.055374),
    'NH': new Point(43.452492, -71.563896),
    'NJ': new Point(40.298904, -74.521011),
    'NM': new Point(34.840515, -106.248482),
    'NY': new Point(42.165726, -74.948051),
    'NC': new Point(35.630066, -79.806419),
    'ND': new Point(47.528912, -99.784012),
    'OH': new Point(40.388783, -82.764915),
    'OK': new Point(35.565342, -96.928917),
    'OR': new Point(44.572021, -122.070938),
    'PA': new Point(40.590752, -77.209755),
    'RI': new Point(41.680893, -71.511780),
    'SC': new Point(33.856892, -80.945007),
    'SD': new Point(44.299782, -99.438828),
    'TN': new Point(35.747845, -86.692345),
    'TX': new Point(31.054487, -97.563461),
    'UT': new Point(40.150032, -111.862434),
    'VT': new Point(44.045876, -72.710686),
    'VA': new Point(37.769337, -78.169968),
    'WA': new Point(47.400902, -121.490494),
    'WV': new Point(38.491226, -80.954453),
    'WI': new Point(44.268543, -89.616508),
    'WY': new Point(42.755966, -107.302490),
    // Canada
    'AB': new Point(53.933271, -116.576504),
    'BC': new Point(53.726668, -127.647621),
    'MB': new Point(53.760861, -98.813876),
    'NB': new Point(46.565316, -66.461916),
    'NL': new Point(53.135509, -57.660436),
    'NS': new Point(44.681987, -63.744311),
    'NT': new Point(64.825544, -124.845733),
    'NU': new Point(70.299771, -83.107577),
    'ON': new Point(51.253775, -85.323214),
    'PE': new Point(46.510712, -63.416814),
    'QC': new Point(46.813878, -71.207981),
    'SK': new Point(52.939916, -106.450864),
    'YT': new Point(64.282327, -135.000000),
  };
  static BajaCalifornia = new Point(29.969395, -115.189135);
  static getDefault(fallback) {
    const state_coords = Point.states_coords[fallback];
    if (state_coords) {
      return state_coords;
    }
    return Point.BajaCalifornia;
  }
  /**
   * Checks if the given Point is the default Point
   */
  static isDefault(point) {
    return point === Point.BajaCalifornia;
  }
}

export default Point;
