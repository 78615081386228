import React from 'react';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';


const RadiusInput = props =>
  <LabeledDropdown
    label='Radius'
    data={[
      50,
      100,
      200,
      300,
      400,
      500,
    ]}
    {...props}
  />
  ;

export default RadiusInput;
