/**
 * Component that displays the Rate Index page
 * @module components/pure/RateIndex
 * @since 3.0.0
 * @requires components/hoc/docTitle
 * @requires components/pure/Header
 * @requires components/pure/form/RateIndexForm
 * @requires components/container/RateIndexToolBlockTable
 * @requires components/container/RateIndexHistory
 */
import 'styles/RateIndex';
import React from 'react';
import { compose } from 'redux-v3';

import docTitle from 'components/hoc/docTitle';
import Header from 'components/pure/Header';
import RateIndexForm from 'components/pure/form/RateIndexForm';
import RateIndexToolBlockTable from 'components/container/RateIndexToolBlockTable';
import RateIndexHistory from 'components/container/RateIndexHistory';


export default compose(
  docTitle('Rate Trend'),
)(() =>
  <div className='RateIndex' style={{ height: '100%', width: '100%' }}>
    <div className='col-xs-12 col-sm-4'>
      <Header>Rate Trend</Header>
      <p>
        Spot Market Rate Trend
        <div>
          <em>Based on truckload bid trend for this lane.</em>
        </div>
      </p>
      <RateIndexForm />
    </div>
    <div className='col-fill col-xs-12 col-sm-8'>
      <div style={{ flexGrow: 0 }}>
        <RateIndexToolBlockTable />
      </div>
      <RateIndexHistory />
    </div>
  </div>
);
