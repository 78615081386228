/**
 * Reducer for handling the factoringdebtor resource actions
 * @module reducers/resource/factoringdebtor
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringdebtor
 * @requires actions/admin/factoring
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringdebtor;

import factoringdebtorResource, { factoringdebtorResourceUSER } from 'actions/resource/factoringdebtor';
import factoringdebtorPaginatedResource from 'actions/admin/factoring/debtor';
import factoringdebtorSearchResource from 'actions/search/factoringdebtor';
import factoringdebtorByfactoringclient, { factoringdebtorByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringdebtor';
import similarfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/similarfactoringdebtor';
import childrenfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/childrenfactoringdebtor';
import lineitemsdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/lineitemsdebtor';

export default function factoringdebtorResourceReducer(state = initialState, action) {
  switch (action.type) {
    case factoringdebtorPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringdebtorResource.parseMany(state, action.payload.results),
      };
    case factoringdebtorSearchResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringdebtorResource.parseMany(state, action.payload.results),
      };
  }
  if (
    childrenfactoringdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    lineitemsdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      ...factoringdebtorResource.parseMany(state, action.payload.results),
    };
  }
  if (
    similarfactoringdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    factoringdebtorByfactoringclient.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    factoringdebtorByfactoringclientUSER.matchesAction(action, 'FETCH_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      ...factoringdebtorResource.parseMany(state, action.payload.results),
    };
  }
  if (
    factoringdebtorByfactoringclient.matchesAction(action, 'CREATE_REQUEST_SUCCESS') ||
    factoringdebtorByfactoringclientUSER.matchesAction(action, 'CREATE_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      [action.payload.id]: factoringdebtorResource.parse(state[action.payload.id], action.payload),
    };
  }
  state = factoringdebtorResource.reduce(state, action);
  state = factoringdebtorResourceUSER.reduce(state, action);
  return state;
}
