import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { closeModal } from 'actions/ui';

import LineItemAuditLog from 'components/container/LineItemAuditLog';

const LineItemAuditLogModal = compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
)(({ closeModal, lineItem }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        Line Item Audit Log
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      <LineItemAuditLog id={lineItem.id} frid={lineItem.funding_request} />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
    </div>
  </div>
);

export default LineItemAuditLogModal;
