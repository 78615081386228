import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';

import PaymentsTable from 'components/container/FactoringPaymentsAdmin/PaymentsTable';
import CenteredColumn from 'components/pure/CenteredColumn';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';

const checkStatus = (status, isDispatchBroker) => {
  if (!isDispatchBroker) {
    return { text: status, value: status };
  }
  switch (status) {
    case USER_FACTORING_STATUS.REMOTE_APPROVED:
      return { text: 'Approved', value: status };
    case USER_FACTORING_STATUS.SPECIAL_PENDING:
      return { text: 'Pending', value: USER_FACTORING_STATUS.SPECIAL_PENDING };
    case USER_FACTORING_STATUS.PENDING:
      return { text: 'Pending', value: USER_FACTORING_STATUS.SPECIAL_PENDING };
    default:
      return { text: status, value: status };
  }
};

const FactoringDebtorPayments = compose(
  connect(
    null,
    (dispatch, { params: { id }, path, isDispatchBroker, isHistoryView, isSearch }) => ({
      update() {
        dispatch(factoringpaymentPaginatedResource.clear());
        return dispatch(factoringpaymentPaginatedResource.fetch({
          debtor_id: id,
          ...(() => (path && !isHistoryView && !isSearch) ? { status: checkStatus(path, isDispatchBroker).value } : {})(),
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(props =>
  <CenteredColumn
    xs={12}
    style={{ marginTop: 20, height: '100vh' }}
    columnProps={{
      className: 'col-fill',
    }}
    >
    <PaymentsTable {...props} />
  </CenteredColumn>
);

export default FactoringDebtorPayments;
