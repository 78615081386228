/**
 * Error that represents errors while fetching.
 * @module datatypes/APIError
 * @since 3.0.0
 */
import FetchError from '../FetchError';


export class APIError {
  constructor(status, message) {
    if (status >= 400 && status < 500) {
      this.status = status;
      this.message = JSON.parse(message);
    }
    else {
      return new FetchError(status, message);
    }
  }

  static type = [
    'VALIDATION_ERROR',
  ].reduce((acc, curr) => {
    acc[curr] = curr;
    return acc;
  }, {});
}

export default APIError;
