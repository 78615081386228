/**
 * Actions relating to the Factoringclientnote by factoringclient resource
 * @module actions/resourceBy/factoringclient/factoringclientnote
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires datatypes/ControlledPaginatedResource
 * @requires actions/resource/factoringclientnote
 */
/* global API */
import UrlAssembler from 'url-assembler';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import ControlledPaginatedResource from 'datatypes/ControlledPaginatedResource';
import factoringclientnoteResource from 'actions/resource/factoringclientnote';


class FactoringClientNotesControlledPaginatedResource extends ControlledPaginatedResource {
  /**
   * @param {object} data
   * @param {string} data.factoring - the ID of the factoring client
   * @param {string} data.note - the note contents
   * @param {File|File[]} data.attachments - an array-like containing the File objects to attach to this note
   * @returns {Promise<void>}
   */
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isCreating) {
        return Promise.resolve();
      }
      dispatch(this.createRequest());

      const formData = new FormData();
      formData.append('factoring', data.factoring);
      formData.append('note', data.note);
      if (data.attachments instanceof File) {
        formData.append('attachments', data.attachments);
      }
      else if (data.attachments) {
        Array.from(data.attachments).map(attachment => {
          formData.append('attachments', attachment);
        });
      }

      try {
        const res = await dispatch(APIFetch(`${API.host}/admin/factoring/notes/`, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }));
        if (res.status !== this.options.acceptableCreateStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        dispatch(this.createRequestSuccess(json));
      }
      catch (err) {
        dispatch(this.createRequestFailure(err));
        throw err;
      }
    };
  }
}

class FactoringClientNoteByFactoringClientControlledPaginatedResourceCollection extends ControlledPaginatedResourceCollection {
  getResourceType() {
    return FactoringClientNotesControlledPaginatedResource;
  }
}

export const factoringapplcationnoteByFactoringclientResource = new FactoringClientNoteByFactoringClientControlledPaginatedResourceCollection('factoringclient', 'factoringclientnote', {
  url: new UrlAssembler('admin/factoring/notes'),
  paginatedResourceOptions: {
    baseResource: factoringclientnoteResource,
  },
});

export default factoringapplcationnoteByFactoringclientResource;
export const {
  fetch,
  fetchNext,
  create,
} = factoringapplcationnoteByFactoringclientResource;
