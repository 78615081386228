import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal } from 'actions/ui';
import { Field, Form, reduxForm } from 'redux-form';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';



const ResendInvite = ({ id, closeModal, handleSubmit, sendInvites, origin, resendSendInvites }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      {/*<Header className='text-danger'>Fuel Advance Settings</Header>*/}
    </div>
    <div className='modal-body'>
      <Form onSubmit={handleSubmit}>
        {
          origin === 'Phone' ?
            <Field
              name='email'
              component={LabeledInput}
              placeholder='Enter Email Address for Carrier'
              label='Would you like to send the invite via email this time?'
              type='email'
              // horizontal
            />
            : <Field
              name='phone'
              type='phone'
              component={PhoneInput}
              placeholder='Enter Phone for Carrier'
              label='Would you like to send the invite via phone this time?'
              // horizontal
            />
        }
      </Form>
    </div>
    <div className='modal-footer'>
      <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
        <button
          className='btn btn-default' type='button' onClick={() =>
            closeModal()
          }
                                                    >
          Cancel
        </button>
        <div style={{ flex: 'auto', justifyContent: 'flex-end' }}>
          <button
            className='btn btn-default' style={{ alignSelf: 'flex-end' }} type='button' onClick={() => {
              resendSendInvites();
              closeModal();
            }}
                                                                                        >
            {`No, Send Via ${origin === 'Phone' ? 'Text' : 'Email'} Again`}
          </button>
          <button
            className='btn btn-orange'
            type='button'
            style={{ alignSelf: 'flex-end' }}
            onClick={() => {
              handleSubmit();
              closeModal();
            }}
            >
            {`Yes Send Via ${origin === 'Phone' ? 'Email' : 'Text'} Now`}
          </button>
        </div>
      </div>
    </div>
  </div>;

const mapStateToProps = (state, ownProps) => ({});

export default compose(
  connect(
    mapStateToProps,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    })
  ),
  reduxForm({
    form: 'RESEND_INVITE',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
    onSubmit: (field, dispatch, { sendInvites, origin }) => {
      sendInvites(field.email ? 'email' : 'phone', [field.email ? field.email : field.phone]);
    },
  })
)(ResendInvite);
