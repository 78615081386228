import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import toClass from 'recompose/toClass';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';
import omit from 'lodash/omit';

import scrollToOnMount from 'components/hoc/scrollToOnMount';


export default ({
  scrollToItemPropName = ['scroll_to_item'],
  idPropName = ['item', 'data', 'id'],
} = {}) => compose(
  branch(
    props => get(props, scrollToItemPropName) === get(props, idPropName),
    compose(
      scrollToOnMount,
      toClass,
    ),
    Component => Component
  ),
  mapProps(props => omit(props, scrollToItemPropName[0])),
);
