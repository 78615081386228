/* global API */


export const fetchFactoringStats = factoring_id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/admin/factoring/${factoring_id}/stats/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};
