/**
 * Reducer for handling the load resource actions
 * @module reducers/resource/load
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/load
 * @requires actions/resourceBy/truck/match
 * @requires actions/resourceBy/user/load
 * @requires actions/resourceBy/user/loadhistory
 * @requires actions/resourceBy/load/loadreverse
 * @requires actions/resourceBy/load/bid
 * @listens LOAD_SET_VIEWED
 * @listens LOAD_TOGGLE_OPEN
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 */
import moment from 'moment';
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.load;

import loadResource, {
  LOAD_SET_VIEWED,
  LOAD_TOGGLE_OPEN,
  LOAD_UPLOAD_EXCEL,
  LOAD_REFRESH,
  UPDATE_LOAD_WITH_PAYMENT_REQUEST,
} from 'actions/resource/load';
import { BIDS_ACCEPT_SUCCESS } from 'actions/resource/bid';
import matchByTruckResource from 'actions/resourceBy/truck/match';
import loadByUserResource from 'actions/resourceBy/user/load';
import haulPayLoadByUserResource from 'actions/resourceBy/user/haulPayLoad';
import loadHistoryByUserResource from 'actions/resourceBy/user/loadhistory';
import loadreverseByLoadResource from 'actions/resourceBy/load/loadreverse';
import bidByLoadResource from 'actions/resourceBy/load/bid';
import savedloadByUserResource from 'actions/resourceBy/user/savedload';
import dispatchedloadByUserResource from 'actions/resourceBy/user/dispatchedload';
import { DISPATCH_LOAD_CONFIRM } from 'actions/admin/dispatch';


export default function loadResourceReducer(state = initialState, action) {
  state = loadResource.reduce(state, action);
  if (savedloadByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  if (bidByLoadResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    const load = state[action.payload.load_id];
    if (load) {
      return {
        ...state,
        [action.payload.load_id]: {
          ...load,
          data: {
            ...load.data,
            recentBid: action.payload.amount,
          },
        },
      };
    }
    return state;
  }
  if (matchByTruckResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  if (loadByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  if (loadHistoryByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  if (loadreverseByLoadResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  if (dispatchedloadByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...loadResource.parseMany(state, action.payload.results),
    };
  }
  switch (action.type) {
    case LOAD_UPLOAD_EXCEL:
      return {
        ...state,
        ...loadResource.parseMany(state, action.payload),
      };
    case LOAD_SET_VIEWED:
      if (state[action.payload] === undefined) {
        return state;
      }
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            viewed: true,
          },
        },
      };
    case LOAD_TOGGLE_OPEN:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            open: !state[action.payload].globals.open,
            viewed: true,
          },
        },
      };
    case LOAD_REFRESH:
      // doing this manually because the PATCH that triggers this doesn't send back the full object with nested things that we need
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: {
            ...state[action.payload.id].data,
            time_posted: moment(),
          },
        },
      };
    case UPDATE_LOAD_WITH_PAYMENT_REQUEST:
      return {
        ...state,
        [action.payload.load_id]: {
          ...state[action.payload.load_id],
          data: {
            ...state[action.payload.load_id].data,
            funding_request: action.payload,
          },
        },
      };
    case BIDS_ACCEPT_SUCCESS: {
      const load = state[action.payload.load_id];
      const bid_index = load.data.bids.findIndex(bid => bid.id === action.payload.id);
      const bid = load.data.bids[bid_index];
      return {
        ...state,
        [action.payload.load_id]: {
          ...load,
          data: {
            ...load.data,
            bids: [
              ...load.data.bids.slice(0, bid_index),
              {
                ...bid,
                bid_status: 'accepted',
              },
              ...load.data.bids.slice(bid_index + 1),
            ],
          },
        },
      };
    }
    case DISPATCH_LOAD_CONFIRM:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: {
            ...state[action.payload.id].data,
            dispatched_load: {
              ...state[action.payload.id].data.dispatched_load,
              amount: action.payload.amount,
            },
          },
        },
      };
  }
  return state;
}
