import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-v4';

import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';

import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';

const getStatusView = approvalStatus => {
  if (approvalStatus === 'approved') {
    return <div className='text-success'>{approvalStatus.toUpperCase()}</div>;
  }
  if (approvalStatus === 'declined') {
    return <div className='text-error'>{approvalStatus.toUpperCase()}</div>;
  }
  return <div className='text-warning'>{String(approvalStatus).toUpperCase()}</div>;
};

const TableItems = createTableItems(({ data, onDeleteClick, onEditClick, onHistoryClick }) => {
  const fields = [
    data.hasOwnProperty('amount_requested') ? formatPennies(data.amount_requested) : '-',
    data.hasOwnProperty('amount_approved') ? formatPennies(data.amount_approved) : '-',
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {data.category_name}
      {Array.isArray(data.attachments) &&
      data.attachments.map(attachment => (
        <Link to={attachment.preview_url} target='_blank' style={{ textDecoration: 'none' }}>
          <MaterialIcon
            style={{ display: 'inline-block', cursor: 'pointer', fontSize: 20, marginLeft: '0.25em', transform: 'rotate(-45deg)' }}
            name='attachment'
          />
        </Link>
      ))}
    </div>,
    data.hasOwnProperty('created') ? moment(data.created).format('l LTS') : '-',
    data.hasOwnProperty('approval_status') ? getStatusView(data.approval_status) : '-',
    data.hasOwnProperty('payment_status') ? getStatusView(data.payment_status) : '-',
    data.description,
    data.bill_with_funding_request ? 'Yes' : 'No',
  ];

  if (onDeleteClick && onEditClick && onHistoryClick) {
    fields.push(
      <div>
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: 'blue', marginRight: '1em' }} name='visibility' onClick={() => onHistoryClick(data)} />
        <MaterialIcon
          style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', marginRight: '1em' }}
          name='edit'
          onClick={() => onEditClick(data)}
        />
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#d9534f' }} name='delete' onClick={() => onDeleteClick(data)} />
      </div>
    );
  }
  return fields;
});

const LineItemsTable = ({ lineItems, onDeleteClick, onEditClick, onHistoryClick }) => (
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={false}
    headers={onDeleteClick && onEditClick ? [
      'Amount',
      'Amount Calculated',
      'Category',
      'Requested At',
      'Approval Status',
      'Payment Status',
      'Desc',
      'Bill With Funding Request?',
      'Actions',
    ] : [
      'Amount',
      'Amount Calculated',
      'Category',
      'Requested At',
      'Approval Status',
      'Payment Status',
      'Desc',
      'Bill With Funding Request?',
    ]}
    placeholder='No Line Items'
    TableItem={TableItems.TableItem}
    BlockTableItem={TableItems.BlockTableItem}
    items={lineItems.map(lineItem => ({
      data: lineItem,
      onDeleteClick,
      onEditClick,
      onHistoryClick,
    }))}
  />
);

export default LineItemsTable;
