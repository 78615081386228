/* global API */
import omit from 'lodash/omit';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';
import Resource from '.';

const DEFAULT_OPTIONS = {
  acceptableStatusCode: 201,
  url: () => {
    throw new Error('unspecified URL in CreateableResource');
  },
  transformHeaders: i => i,
  transformBody: data => JSON.stringify(data),
};

export const CREATE_KEY = Symbol('CREATE');

const Createable = options => (Super = Resource) => {
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  return class Createable extends Super {
    constructor() {
      super(...arguments);
      this.actions.CREATE_REQUEST = Symbol(`${this.upperName}_CREATE_REQUEST`);
      this.actions.CREATE_REQUEST_SUCCESS = Symbol(`${this.upperName}_CREATE_REQUEST_SUCCESS`);
      this.actions.CREATE_REQUEST_FAILURE = Symbol(`${this.upperName}_CREATE_REQUEST_FAILURE`);
      this.create = this.create.bind(this);
    }
    create(data) {
      return async (dispatch, getState) => {
        const state = getState();
        const item = this.selector(state, CREATE_KEY);

        if (item && item.isFetching) {
          return;
        }
        dispatch({
          type: this.actions.CREATE_REQUEST,
          payload: undefined,
        });

        try {
          const res = await dispatch(APIFetch(`${API.host}/${options.url(...arguments)}`, {
            method: 'POST',
            headers: options.transformHeaders({
              authorization: `bearer ${state.user.token}`,
              'content-type': 'application/json',
            }),
            body: options.transformBody(data),
          }));
          if (res.status !== options.acceptableStatusCode) {
            if (res.status >= 400 && res.status < 500) {
              const json = await res.json();
              if (res.status === 400) {
                throw new SubmissionError(json);
              }
              throw new PermissionsError(json);
            }
            const text = await res.text();
            throw new FetchError(res.status, text);
          }
          const json = await res.json();
          return dispatch({
            type: this.actions.CREATE_REQUEST_SUCCESS,
            payload: json,
          });
        }
        catch (err) {
          dispatch({
            type: this.actions.CREATE_REQUEST_FAILURE,
            payload: err,
          });
          throw err;
        }
      };
    }
    reduce(state, action) {
      switch (action.type) {
        case this.actions.CREATE_REQUEST:
          return {
            ...state,
            [CREATE_KEY]: {
              isFetching: true,
            },
          };
        case this.actions.CREATE_REQUEST_SUCCESS:
          return {
            ...omit(state, CREATE_KEY),
            [action.payload.id]: {
              ...this.parse(state[CREATE_KEY], action.payload),
              isFetching: false,
              err: null,
            },
          };
        case this.actions.CREATE_REQUEST_FAILURE:
          return {
            ...omit(state, CREATE_KEY),
          };
      }
      return super.reduce(...arguments);
    }
  };
};

export default Createable;
