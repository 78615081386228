/**
 * Component that manages the display of elements based on media queries
 * @module components/pure/MediaQuery
 * @since 3.0.0
 * @property {object} props
 * @property {object} props.mobile - the node to display if the view is at mobile sizes
 * @property {object} props.desktop - the node to display if the view is at desktop sizes
 * @property {object} [props.breakpoint] - the pixels at which the view changes from mobile to desktop
 * @property {any} [props....rest] - props passed to containing component
 */
/*global MOBILE_BREAKPOINT */
import React from 'react';
import PropTypes from 'prop-types';
import MQ from 'react-responsive';


const MediaQuery = ({ mobile, desktop, breakpoint, ...rest }) =>
  <div {...rest}>
    <MQ maxWidth={breakpoint || MOBILE_BREAKPOINT}>
      {mobile}
    </MQ>
    <MQ minWidth={(breakpoint || MOBILE_BREAKPOINT) + 1}>
      {desktop}
    </MQ>
  </div>
  ;

MediaQuery.propTypes = {
  mobile: PropTypes.node,
  desktop: PropTypes.node,
  breakpoint: PropTypes.number,
};

export default MediaQuery;
