import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';

import { openModal } from 'actions/ui';


export default compose(
  connect(
    state => ({ self: state.user.id }),
    {
      hideUser: id => openModal('confirmhideuser', { id }),
    },
  ),
)(({ id, self, hideUser, ...rest }) =>
  self === id ?
    null :
    <button
      {...rest}
      onClick={() => hideUser(id)}
      className={cs('btn btn-orange btn-danger-gray', rest.className)}
      style={{
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: 13,
        ...rest.style,
      }}
      >
    Block User
    </button>
);
