import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import CenteredColumn from 'components/pure/CenteredColumn';
import RouteTabs from 'components/pure/RouteTabs';
import getSelf from 'selectors/getSelf';

export default compose(
  connect(
    state => ({
      is_dispatch_enabled: Boolean(getSelf(state).data.groups.find(group => group.name === 'Admin Dispatch')),
    }),
    {},
  ),
)(({ is_dispatch_enabled, children }) => {
  if (is_dispatch_enabled) {
    return (
      <CenteredColumn xs={12} md={9}>
        <div>
          <RouteTabs
            routes={[
              ['../subscription', 'Subscription'],
              ['../dispatch', 'Dispatching'],
            ]}
          />
        </div>
        {children}
      </CenteredColumn>
    );
  }
  return (
    <CenteredColumn xs={12} md={9}>
      {children}
    </CenteredColumn>
  );
});
