/**
 * Initial state for the redux store
 * @module INITIAL_STATE
 * @since 3.0.0
 */
/* global TOKEN_STORAGE_KEY MOBILE_BREAKPOINT */
import url from 'url';
import qs from 'querystring';
import storage from 'datatypes/storage';
import cookies from 'browser-cookies';

const url_on_load = url.parse(location.href);
const query_params_on_load = qs.parse(url_on_load.query);
let tracking = null;
try {
  tracking = JSON.parse(cookies.get('_tracking'));
}
catch (err) {
  console.warn(err);
}

export default {
  url: {
    ...url_on_load,
    query: query_params_on_load,
  },
  cookies: {
    tracking,
  },
  upload: {
    files: [],
  },
  user: {
    id: undefined,
    token: storage.getItem(TOKEN_STORAGE_KEY),
    loading: true,
    logged_in: false,
    isLoggingIn: false,
    subscription: {},
    factoring: {},
    dispatching: {},
  },
  ui: {
    menu_open: window.innerWidth > MOBILE_BREAKPOINT,
    modal: [],
    scroll_to_item: undefined,
    dont_show_loggedoutmenu: false,
  },
  rateindex: {
    history: undefined,
  },
  hide: {
    posts: [],
    users: {
      isFetching: false,
      err: undefined,
      data: [],
    },
  },
  post: {
    truck: {
      editId: undefined,
      copyId: undefined,
    },
    load: {
      editId: undefined,
      copyId: undefined,
      history: false,
      status: false,
      current: false,
    },
  },
  resourceBy: {
    truck: {
      qa: {},
      match: {},
    },
    user: {
      truck: {},
      truckhistory: {},
      load: {},
      haulPayLoad: {},
      loadhistory: {},
      rating: {},
      note: {},
      factoringpayment: {},
      savedload: {},
      savedtruck: {},
      dispatchedload: {},
      onboarddebtor: {},
      factoringTerms: {
        loading: false,
        data: [],
      },
    },
    load: {
      qa: {},
      bid: {},
      loadreverse: {},
    },
    factoringclient: {
      factoringclientnote: {},
      factoringdebtor: {},
    },
    factoringdebtor: {
      factoringdebtornote: {},
      factoringclient: {},
      similarfactoringdebtor: {},
      childrenfactoringdebtor: {},
      lineitemsdebtor: {},
    },
    factoringpayment: {
      factoringpaymentnote: {},
    },
  },
  resource: {
    user: {},
    load: {},
    haulPayLoad: {},
    truck: {},
    qa: {},
    bid: {},
    factoringGroups: {},
    factoringGroupUsers: {},
    trucksearch: {},
    loadsearch: {},
    map: {},
    geocode: {},
    activity: {},
    rating: {},
    usersearch: {},
    lead: {},
    factoringclient: {},
    factoringclientnote: {},
    usernote: {},
    factoringdebtor: {},
    factoringdebtornote: {},
    factoringpayment: {},
    onboarddebtor: {},
    factoringpaymentnote: {},
    factoringdebtorauditlog: {},
    factoringclientauditlog: {},
    financespayment: {},
    financespurchase: {},
    financeslineitem: {},
    factoringclientdebtorauditlog: {},
    factoringtransactionpayment: {},
    factoringtransactionpurchase: {},
    factoringlineitem: {},
    getbankinfo: {},
    getcardinfo: {},
    defaultbank: {},
    factoringpaymentrelation: {},
    factoringclientattachments: {},
    adminuser: {},
    financesPurchasesItems: {},
    plaidaccount: {},
  },
  admin: {
    leads: undefined,
    factoring: {
      client: undefined,
      debtor: undefined,
      payment: undefined,
      lineitem: undefined,
      bank: undefined,
      group: undefined,
    },
    factoringGroupUsers: undefined,
    clientcombinednotes: undefined,
    debtorcombinednotes: undefined,
    financespayments: undefined,
    financesPurchasesItems: undefined,
    purchasesfundingrequestitems: undefined,
    financeslineitems: undefined,
    factoringtransactionpayments: undefined,
    factoringtransactionpurchases: undefined,
  },
  notification: false,
  activity: undefined,
  settings: {
    activity: {},
    loadalerts: {},
    accountspayable: {},
    accountsreceivable: {},
  },
  search: {
    load: undefined,
    truck: undefined,
    user: undefined,
    loadmap: undefined,
    factoringclient: undefined,
  },
};
