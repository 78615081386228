import React, { useEffect, useState } from 'react';
import { Col, Row, Table } from 'antd';
import { connect } from 'react-redux-v5';
import { Button, Header } from '@haulpay/ui';
import { get, head } from 'lodash';
import { searchFactoringCarriers } from 'actions/factoring';
import { getGroupInvited } from 'actions/groups';
import { deleteInvitedUser } from 'actions/admin/factoringGroupUsers';
import groups from 'actions/admin/factoring/groups';
import { openModal } from 'actions/ui';
import { inviteToGroup } from 'actions/invites';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import columns from './columns';
import { PlusOutlined } from '@ant-design/icons';
import Filters from './Components/Filters';

function InvitedCarriers(props) {
    const [count, setCount] = useState(0);
    const [InvitedCarriers, setInvitedCarriers] = useState([]);
    const [filters, setFilters] = useState({});
    const [groupId, setGroupId] = useState(null);

    useEffect(() => {
        async function fetchData() {
            await props.getCarriers();
            await props.getGroups();

            const group = head(props.brokerGroups);
            const groupId = get(group, 'data.id');
            if (groupId) {
                const invited = await props.getGroupInvited(groupId, { deleted: true });
                setCount(invited.length);
                setInvitedCarriers(invited);
                setGroupId(groupId);
            }
        }
        fetchData();
    }, []);

    return (
        <div style={{ margin: 25 }}>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Header size="xxlarge" weight="semibold">
                        Invited Carriers
                    </Header>
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Button
                            icon={<PlusOutlined />}
                        >
                            Add New
                        </Button>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Filters />
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        pagination={false}
                        bordered
                        striped
                        loading={props.isFetching}
                        size="small"
                        columns={columns}
                        dataSource={InvitedCarriers}
                        infinityScroll={true}
                        rowKey={data => data.id}
                        filters={filters}
                    />
                    </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = state => ({
    groups: state.resource.factoringGroups,
    brokerGroups: selectBrokerGroups(state),
    isFetching: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
});

const mapDispatchToProps = dispatch => ({
    getGroupInvited: (id, filters) => dispatch(getGroupInvited(id, filters)),
    getCarriers: filters => dispatch(searchFactoringCarriers(filters)),
    getGroups: () => dispatch(groups.fetch()),
    inviteToGroup: async (...args) => {
        try {
            await dispatch(inviteToGroup(...args));
            dispatch(openModal('success', { message: 'Invite(s) successfully sent.' }));
        }
        catch (err) {
            if (has(err, 'already_exists')) {
                return dispatch(openModal('error', { message: `The carrier ${!isEmpty(err.already_exists) ? err.already_exists[0] : err.invites_rate_limited[0]} has already been invited , would you like to resend the HaulPay invitation link?` }));
            }
            return dispatch(openModal('error', { message: err.message || 'Error sending invite(s).' }));
        }
    },
    confirmDelete(id) {
        return new Promise((resolve, reject) => {
            const onConfirm = async () => {
                try {
                    const res = await dispatch(deleteInvitedUser(id));
                    resolve(res);
                }
                catch (e) {
                    reject(e);
                }
            };
            dispatch(openModal('confirm', {
                type: 'inviteduser_delete',
                args: [{
                    onConfirm,
                }],
            }));
        });
    },
    openResendInviteModal: (...args) => dispatch(openModal(...args)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(InvitedCarriers);