import React from 'react';
import PropTypes from 'prop-types';

import SortableTable from './SortableTable';
import TruckSearchTableItem from '../container/TruckSearchTableItem';

export const TruckSortableTable = ({ ids, ...rest }) =>
  <SortableTable
    {...rest}
    headers={[
      { text: 'Age', ordering: 'time_posted' },
      { text: 'Type', ordering: 'trailerType' },
      { text: 'Origin', ordering: 'origin_city' },
      { text: 'Range', ordering: 'origin_radius' },
      { text: 'Destination', ordering: 'destination_city' },
      { text: 'Range', ordering: 'destination_radius' },
      { text: 'Weight', ordering: 'weight' },
      { text: 'Length', ordering: 'length' },
      { text: 'Ready', ordering: 'time_start' },
      { text: 'Close', ordering: 'time_end' },
      { text: 'More', ordering: false },
    ]}
    items={ids.map(id => ({ id }))}
    ListItem={TruckSearchTableItem}
  />
  ;

TruckSortableTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TruckSortableTable;
