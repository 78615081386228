import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import FactoringLineItemEditForm from 'components/container/FactoringLineItemEdit';

export default compose(
  connect(
    null,
    {
      closeModal,
    }
  )
)(({ LineItem, closeModal, refresh }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        Edit Line Item
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      <FactoringLineItemEditForm initialValues={LineItem} LineItem={LineItem} refresh={refresh} />
    </div>
  </div>
);
