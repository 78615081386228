/* global API */
import { compose } from 'redux-v3';

import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import Deleteable from 'datatypes/compose/resource/Deleteable';
import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';

class FactoringTransactionPurchaseResource extends compose(
  Fetchable(),
  Editable(),
  Createable(),
  Deleteable(),
)() {
  create(data, frid) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, CREATE_KEY);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${frid}/purchases/`, {
          method: 'POST',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 201) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
  fetch(id, frid) {
    return async (dispatch, getState) => {
      const state = getState();
      // const item = this.selector(state, id);

      // if (item && item.isFetching) {
      //   return;
      // }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: id,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${frid}/purchases/${id}/`, {
          method: 'GET',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: {
            id,
            json,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.FETCH_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
  edit(data, frid, id) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, id);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${frid}/purchases/${id}/`, {
          method: 'PATCH',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: {
            id,
            json,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
  doDelete(frid, id) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, id);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.DELETE_REQUEST,
        payload: id,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${frid}/purchases/${id}/`, {
          method: 'DELETE',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== 204) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        return dispatch({
          type: this.actions.DELETE_REQUEST_SUCCESS,
          payload: {
            id,
            item,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.DELETE_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
}



const factoringtransactionpurchaseResource = new FactoringTransactionPurchaseResource('factoringtransactionpurchase');

export const {
  selector,
  fetch,
  edit,
  create,
} = factoringtransactionpurchaseResource;

export default factoringtransactionpurchaseResource;
