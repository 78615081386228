import React from 'react';

import formatPennies from 'helpers/formatPennies';

import HorizontalScrollingBarGraph from 'components/pure/HorizontalScrollingBarGraph';


const SourceGraphFactoringPaymentSum = ({ data }) => {
  const factoring_payment_invoice_sum_data = data
    .map(({ source, total_invoice_amount }) => {
      if (!total_invoice_amount) {
        return null;
      }
      return {
        x: source || 'No source',
        y: total_invoice_amount,
      };
    })
    .filter(Boolean)
  ;
  const total = data.reduce((acc, curr) => acc + curr.total_invoice_amount, 0);

  return (
    <div>
      <h4>Factoring Payment Invoice Sum</h4>
      {
        factoring_payment_invoice_sum_data && factoring_payment_invoice_sum_data.length ?
          <div>
            <HorizontalScrollingBarGraph
              chartProps={{
                data: [{
                  label: 'data',
                  values: factoring_payment_invoice_sum_data,
                }],
                tooltipHtml: (_x, _y0, y) => formatPennies(y),
              }}
            />
            <table className='table table-condensed table-striped table-hover'>
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Sum</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(source => source.total_invoice_amount)
                  .map(({ source, total_invoice_amount }, i) =>
                    <tr key={i}>
                      <th>{source || 'No source'}</th>
                      <td>{formatPennies(total_invoice_amount)} {total_invoice_amount ? `(${(total_invoice_amount / total * 100).toFixed(2)}%)` : '(0%)'}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          :
          <h5>No data.</h5>
      }
    </div>
  );
};

export default SourceGraphFactoringPaymentSum;
