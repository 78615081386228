import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import Stripe from '../../stripe';
import { updateDispatching } from 'actions/admin/dispatch';
import { openModal } from 'actions/ui';

import Header from 'components/pure/Header';


const stripeHander = Stripe({
  description: 'Freight Dispatching',
  panelLabel: 'Add/Update Dispatching Card',
});

export default compose(
  connect(
    null,
    (dispatch, { params: { id } }) => ({
      onClick() {
        return stripeHander.open({
          token(token) {
            return dispatch(updateDispatching(token.id, id))
              .then(() => {
                dispatch(openModal('success', { message: 'Successfully updated card.' }));
              })
              .catch(err => {
                console.warn(err);
                dispatch(openModal('error'), { message: 'We had a problem updating your card. Please try again later.' });
              })
            ;
          },
        });
      },
    }),
  ),
)(({ onClick, ...rest }) =>
  <div
    {...rest}
    style={{
      margin: '1em',
      ...rest.style,
    }}
    >
    <Header>Update Card for User</Header>
    <button className='btn btn-orange' style={{ margin: '0.5em 0' }} onClick={onClick}>
      Update Dispatching Card
    </button>
  </div>
);
