/**
 * Connected modal component to prompt a user to upgrade their subscription
 * @module components/pure/modals/UpgradeAccount
 * @since 3.0.0
 * @requires actions/ui
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import { Link } from 'react-router-v4';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { closeModal } from 'actions/ui';

import ModalWarning from './Warning';


const UpgradeAccount = compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
  mapProps(({ subscribeButtonText, ...rest }) => ({
    ...rest,
    message: rest.message ?
      rest.message.split('\n').reduce((acc, curr, i, arr) => {
        acc.push(curr);
        if (!(i % 2) && i !== arr.length - 1) {
          acc.push(<br />);
        }
        return acc;
      }, [])
      :
      'Please upgrade your account to use this feature.',
    buttonChildren: 'Cancel',
    extraButtons: [
      <Link to='/settings/billing/subscription'><button className='btn btn-primary' onClick={rest.closeModal}>{subscribeButtonText || 'Subscribe'}</button></Link>,
    ],
  }))
)(ModalWarning);

export default UpgradeAccount;
