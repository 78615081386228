import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import financespaymentsPaginatedResource from 'actions/admin/financespayments';

import CenteredColumn from 'components/pure/CenteredColumn';
import DebtorPayments from './DebtorPayments';


const FactoringInvoicesPayments = compose(
  connect(
    null,
    (dispatch, { params: { id } }) => ({
      update() {
        dispatch(financespaymentsPaginatedResource.clear());
        return dispatch(financespaymentsPaginatedResource.fetch({
          id: id,
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(({ params, update }) =>
  <CenteredColumn
    xs={12}
    sm={10}
    className='col-fill'
    columnProps={{
      className: 'col-fill',
    }}
    >
    <DebtorPayments refetchInvoices={update} id={params.id} />
  </CenteredColumn>
);

export default FactoringInvoicesPayments;
