import React from 'react';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CleaveInput from 'components/pure/form/inputs/CleaveInput';

const DebitCardInput = props =>
  <LabeledInput
    ComponentClass={CleaveInput}
    type='tel'
    options={{
      creditCard: true,
    }}
    {...props}
  />
  ;

export default DebitCardInput;
