import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';

import { getDistance } from 'helpers';

import { toggleOpen } from 'actions/resource/load';

import Resource from 'components/hoc/Resource';
import hiddenPost from 'components/hoc/hiddenPost';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import {
  LoadReverseBlockTableItem as PureLoadReverseBlockTableItem,
  LoadReverseTableItem as PureLoadReverseTableItem,
} from 'components/pure/LoadReverseTableItem';


const loadreverseItemEnhancer = compose(
  Resource('load'),
  spinnerIfFetching(),
  hiddenPost({ idProp: 'item.data.id' }),
  connect(null, { toggleOpen }),
  Resource('load', {
    idPropName: 'parent_id',
    resourcePropName: 'parent',
  }),
  mapProps(({ parent, parent_id, dispatch, ...rest }) => {
    const origin = get(parent, 'data.destination_coords');
    const destination = get(rest, 'item.data.origin_coords');
    if (origin && destination) {
      const deadhead = getDistance(origin, destination) | 0;
      rest.item.data.deadhead = deadhead;
    }
    return rest;
  }),
);

export const LoadReverseTableItem = compose(
  loadreverseItemEnhancer,
)(PureLoadReverseTableItem);

export const LoadReverseBlockTableItem = compose(
  loadreverseItemEnhancer,
)(PureLoadReverseBlockTableItem);
