/* global EMPTY_PLACEHOLDER */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';
import getSelfType from 'selectors/getSelfType';
import { get } from 'lodash';
import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';
import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import isDispatchBroker from 'selectors/isDispatchBroker';

import ResponsiveTable from 'components/pure/ResponsiveTable';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import formatDays from 'helpers/formatDays';

const PaymentsTableComponents = isDispatchBroker => createTableItems(
  ({ data }) => {
    let status = data.status;
    if (status === 'special_pending' && isDispatchBroker) {
      status = 'pending';
    }
    return [
      data.factoring.company_profile.name,
      data.debtor.dba,
      formatPennies(data.net_worth),
      data.invoice_number,
      data.user_load_number,
      data.created.format('l LTS'),
      data.discount_rate,
      data.payment_method === 'wire' ? <MaterialIcon name='check' className='text-danger' style={{ fontSize: '2em' }} /> : '',
      get(data, 'assigned_admin.name', 'Not Assigned'),
      formatPennies(data.amount_funded) || EMPTY_PLACEHOLDER,
      <b
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid'].includes(data.status),
          'text-error': data.status === 'declined',
        })}
        >
        {
          data.attachments.length > 0 ?
            data.attachments[data.attachments.length - 1].after_issue === true ? <div className='text-success'><MaterialIcon name='attach_file' />New!</div> : null : null}
        {' '}
        {status}
        {' '}
        {data.status_timeline ? data.status_timeline[data.status_timeline.length - 1] ? moment(data.status_timeline[data.status_timeline.length - 1].status_set_datetime).format('l LT') : null : null}
      </b>,
      data.days_to_pay ? formatDays(data.days_to_pay) : null,
      <Link to={`/admin/factoring/transaction/${data.id}`}>
        <button className='btn btn-orange'>More</button>
      </Link>,
    ];
  },
);

const PaymentsTableComponentsForRemote = createTableItems(
  ({ data }) => [
    data.factoring.company_profile.name,
    data.debtor.dba,
    formatPennies(data.amount),
    data.invoice_number,
    data.user_load_number,
    data.created.format('l LTS'),
    data.discount_rate,
    data.payment_method === 'wire' ? <MaterialIcon name='check' className='text-danger' style={{ fontSize: '2em' }} /> : '',
    get(data, 'assigned_admin.name', 'Not Assigned'),
    formatPennies(data.amount_funded) || EMPTY_PLACEHOLDER,
    <b
      className={cs('text-uppercase', {
        'text-success': ['approved', 'paid'].includes(data.status),
        'text-error': data.status === 'declined',
      })}
      >
      {
        data.attachments.length > 0 ?
          data.attachments[data.attachments.length - 1].after_issue === true ? <div className='text-success'><MaterialIcon name='attach_file' />New!</div> : null : null}
      {' '}
      {data.status}
      {' '}
      {data.status_timeline ? data.status_timeline[data.status_timeline.length - 1] ? moment(data.status_timeline[data.status_timeline.length - 1].status_set_datetime).format('l LT') : null : null}
    </b>,
    data.days_to_pay ? formatDays(data.days_to_pay) : null,
    <Link to={`/admin/factoring/transaction/${data.id}`}>
      <button className='btn btn-orange'>More</button>
    </Link>,
  ],
);


export const PaymentsTable = compose(
  connect(
    state => ({
      ids: state.admin.factoring.payment.ids,
      isFetching: state.admin.factoring.payment.isFetching,
      payments: state.resource.factoringpayment,
      currentOrder: state.admin.factoring.payment.ordering,
      type: getSelfType(state),
      isDispatchBroker: isDispatchBroker(state)
    }),
    {
      fetchNext: factoringpaymentPaginatedResource.fetchNext,
      sort: factoringpaymentPaginatedResource.sort,
    },
  ),
)(({ ids, isFetching, fetchNext, payments, handleSubmit, sort, currentOrder, type, isDispatchBroker }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    currentOrder={currentOrder}
    sort={sort}
    headers={[
      { text: 'Client Business', ordering: 'factoring' },
      { text: 'Debtor', ordering: 'debtor' },
      { text: 'Invoice Amt.', ordering: 'net_worth' },
      { text: 'Invoice #', ordering: 'invoice_number' },
      { text: 'Load #', ordering: 'user_load_number' },
      { text: 'Requested At', ordering: 'created' },
      { text: 'Discount %', ordering: 'discount_rate' },
      { text: 'Wire', ordering: 'payment_method' },
      { text: 'Transaction Rep', ordering: 'assigned_admin' },
      { text: 'Amt. Funded', ordering: 'amount_funded' },
      /*type === USER_TYPE.FACTORING_REMOTE_ADMIN ? { 'text': '', ordering: false } :*/ { text: 'Status', ordering: false },
      { text: 'Aging', ordering: 'days_to_pay' },
      { text: 'More', ordering: false },
    ]}
    placeholder='No results'
    TableItem={PaymentsTableComponents(isDispatchBroker).TableItem}
    BlockTableItem={PaymentsTableComponents(isDispatchBroker).BlockTableItem}
    items={ids.map(id => payments[id])}
  />
);

const FactoringClientPayments = compose(
  connect(null, (dispatch, { params: { id } }) => ({
    update({ debtor, load, date } = {}) {
      dispatch(factoringpaymentPaginatedResource.clear());
      const options = {
        factoring_id: id,
      };

      if (debtor && debtor.id) {
        options.debtor_id = debtor.id;
      }

      if (load) {
        options.user_load_number = load;
      }

      if (date) {
        options.created = date;
      }

      return dispatch(
        factoringpaymentPaginatedResource.fetch(options)
      );
    },
  })),
  withState('options', 'setOptions', {}),
  lifecycle({
    componentDidMount() {
      this.props.update(this.props.options);
    },
    componentDidUpdate() {
      this.props.update(this.props.options);
    },
  }),
  reduxForm({
    form: 'FactoringClientPaymentsSearch',
    destroyOnUnmount: false,
    onSubmit(fields, dispatch, props) {
      props.setOptions({
        debtor: fields.debtor,
        load: fields.load,
        date: fields.date,
      });
    },
  })
)(({ submitting, handleSubmit }) => (
  <div className='col-fill' style={{ padding: '0 1em' }}>
    <div className='row'>
      <div className='col-lg-12'>
        <form
          onSubmit={handleSubmit}
          className='form-inline'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1em 0' }}
          >
          <Field name='debtor' component={DebtorAutocompleteInput} placeholder='Debtor' />
          <Field name='load' component={LabeledInput} placeholder='Load #' style={{ margin: '0 1em' }} />
          <button type='submit' className='btn btn-primary' style={{ margin: '0px 0px 0px 1em' }}>
            Search
          </button>
        </form>
      </div>
    </div>
    <PaymentsTable />
  </div>
));

export default FactoringClientPayments;
