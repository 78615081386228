/**
 * Displays a spinner.
 * @module components/pure/Spinner
 * @since 3.0.0
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing div component
 */
import 'styles/Spinner';
import React from 'react';
import cs from 'classnames';

/**
 * The default Spinner
 */
export const Spinner = props => <div className={cs('Spinner', props.className)} style={props.style} />;

/**
 * A spinner centered using inline text centering
 */
export const CenteredSpinner = props => <div className='text-center'><Spinner {...props} /></div>;

/**
 * A hacky spinner centered in a table
 */
export const TableSpinner = () =>
  <tbody>
    <tr>
      <td colSpan={100}>
        <Spinner />
      </td>
    </tr>
  </tbody>
  ;

export default Spinner;
