/**
 * Actions relating to the various global or almost-global ui changes
 * @module actions/ui
 * @since 3.0.0
 */
/*global google */

/**
 * Toggle menu action. Toggles the left-hand side menu open (true) or closed (false)
 * @event UI_TOGGLE_MENU
 * @property {symbol} type - Symbol(UI_TOGGLE_MENU)
 * @property {boolean} payload - whether to toggle the menu open (true) or closed (false)
 * @property {any} meta
 */
export const UI_TOGGLE_MENU = Symbol('UI_TOGGLE_MENU');
/**
 * Thunk for setting sidebar state. Also triggers a resize on all mounted google maps (if any), because of the 'gray bar' problem (they don't resize/redraw automatically if their containing element resizes)
 * @fires UI_TOGGLE_MENU
 */
export const setSidebarOpen = isOpen => dispatch => {
  dispatch({
    type: UI_TOGGLE_MENU,
    payload: isOpen,
    meta: ['global', 'hamburger'],
  });
  // hack to make google maps not show gray space when the menu opens and closes
  if (google.__CACHED_MAPS__ && google.__CACHED_MAPS__.length) {
    setTimeout(
      () => google.__CACHED_MAPS__.forEach(map =>
        google.maps.event.trigger(map.google, 'resize'),
      ),
      1000,
    );
  }
};

/**
 * Open modal action. Adds a modal to the modal queue
 * @event UI_OPEN_MODAL
 * @property {symbol} type - Symbol(UI_OPEN_MODAL)
 * @property {object} payload
 * @property {string} payload.type - the unique identifier for the type of modal to be opened
 * @property {object} payload.props - any props to pass to this modal. Keep these serializable
 * @property {any} meta
 */
export const UI_OPEN_MODAL = Symbol('UI_OPEN_MODAL');
/**
 * Open modal action
 * @param {string} type - the unique identifier for the type of modal to be opened
 * @param {object} props - any props to pass to this modal. Keep these serializable
 * @param {any} meta
 */
export function openModal(type, props, meta) {
  return {
    type: UI_OPEN_MODAL,
    payload: { type, props },
    meta,
  };
}

export const UI_UNSHIFT_MODAL = Symbol('UI_UNSHIFT_MODAL');
export const unshiftModal = (type, props, meta) => {
  return {
    type: UI_UNSHIFT_MODAL,
    payload: { type, props },
    meta,
  };
};

/**
 * Close modal action. Shifts the first modal (if any) off the modal queue
 * @event UI_CLOSE_MODAL
 * @property {symbol} type - Symbol(UI_CLOSE_MODAL)
 */
export const UI_CLOSE_MODAL = Symbol('UI_CLOSE_MODAL');
/**
 * Close modal action
 */
export function closeModal() {
  return {
    type: UI_CLOSE_MODAL,
    payload: undefined,
  };
}

/**
 * Set latest tool action. Saves a url as the latest tool. Used when a user hides a post on that post's details page in order to return them to the latest tool that they used's url
 * @event SET_LAST_TOOL
 * @property {symbol} type - Symbol(SET_LAST_TOOL)
 * @property {string} payload - the URL of the last-used tool
 */
export const SET_LAST_TOOL = Symbol('SET_LAST_TOOL');
/**
 * Set last tool action
 * @param {string} pathname - the url of the tool to save in state
 */
export function setLastTool(pathname) {
  return {
    type: SET_LAST_TOOL,
    payload: pathname,
  };
}

/**
 * Set the ID of the item to remember to scroll to later
 * @event SET_SCROLL_TO_ITEM
 * @property {symbol} type - Symbol(SET_SCROLL_TO_ITEM)
 * @property {string} payload - the ID of the item to scroll to
 */
export const SET_SCROLL_TO_ITEM = Symbol('SET_SCROLL_TO_ITEM');
/**
 * Set last scroll to item
 * @param {string} id - the ID of the item to scroll to
 */
export const setScrollToItem = id => ({
  type: SET_SCROLL_TO_ITEM,
  payload: id,
});

export const SET_DONT_SHOW_LOGGEDOUTMENU = Symbol('SET_DONT_SHOW_LOGGEDOUTMENU');
export const setDontShowLoggedoutmenu = val => {
  return {
    type: SET_DONT_SHOW_LOGGEDOUTMENU,
    payload: val,
  };
};
