import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import scrollToItem from 'components/hoc/scrollToItem';
import TimelineItem from 'components/pure/TimelineItem';


export default compose(
  connect(
    state => {
      const scroll_to_item = state.ui.scroll_to_item;
      return {
        scroll_to_item,
      };
    },
    {},
  ),
  scrollToItem({
    idPropName: ['id'],
  }),
)(TimelineItem);
