import React from 'react';

const DateSelectButton = ({ onClick, range }) => {
  return (
    <button type='button' className='btn btn-default' onClick={onClick}>
      {
        range ?
          `${range.start.format('l')} -> ${range.end.format('l')}`
          :
          'Beginning of Time'
      }
    </button>
  );
};

export default DateSelectButton;
