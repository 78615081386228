import React from 'react';
import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import setDisplayName from 'recompose/setDisplayName';

const defaultOptions = {
  breakpoint: 1,
  itemsProp: 'items',
  placeholderProp: 'placeholder',
  placeholder: <div className='help-block'>Nothing here yet</div>,
  componentProps: {},
};

export default (cond, options) => {
  if (typeof cond === 'object') {
    options = cond;
    cond = () => true;
  }

  options = {
    ...defaultOptions,
    ...options,
  };

  return compose(
    setDisplayName('placeholder'),
    branch(
      props => ((props[options.itemsProp] === undefined || props[options.itemsProp].length < options.breakpoint) && cond(props)),
      renderComponent(props =>
        <div {...options.componentProps} style={{ textAlign: 'center', ...options.componentProps.style }}>
          {props[options.placeholderProp] ||
            <div>
              {
                typeof options.placeholder === 'string' ?
                  options.placeholder.split('\n').join((line, i) => <p key={i}>{line}</p>)
                  :
                  typeof options.placeholder === 'function' ?
                    React.createElement(options.placeholder, props)
                    :
                    options.placeholder
              }
            </div>
          }
        </div>
      ),
      Component => Component,
    ),
  );
};
