import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoringGroupUsers;

import factoringGroupUserPaginatedResource from 'actions/admin/factoringGroupUsers';


export default function factoringGroupsReducer(state = initialState, action) {

  switch (action.type) {
    default:
      return factoringGroupUserPaginatedResource.reduce(state, action);
  }
}
