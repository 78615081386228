import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringpaymentnote;

import factoringpaymentnoteResource from 'actions/resource/factoringpaymentnote';
import factoringpaymentnoteByFactoringpaymentResource from 'actions/resourceBy/factoringpayment/factoringpaymentnote';


export default function factoringpaymentnoteResourceReducer(state = initialState, action) {
  state = factoringpaymentnoteResource.reduce(state, action);
  if (factoringpaymentnoteByFactoringpaymentResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...factoringpaymentnoteResource.parseMany(state, action.payload),
    };
  }
  if (factoringpaymentnoteByFactoringpaymentResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: factoringpaymentnoteResource.parse(state[action.payload.id], action.payload),
    };
  }
  if (factoringpaymentnoteByFactoringpaymentResource.matchesAction(action, 'EDIT_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: factoringpaymentnoteResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
