import React from 'react';
import { Link } from 'react-router-v4';
import get from 'lodash/get';
import { compose } from 'redux-v3';
import { reduxForm } from 'redux-form';
import withState from 'recompose/withState';

import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import CircleSpinner from 'components/pure/CircleSpinner';

import createTableItems from 'helpers/createTableItems';
import formatAddress from 'helpers/formatAddress';

import { closeModal, openModal } from 'actions/ui';
import { edit, transfer } from 'actions/resource/factoringdebtor';


const FORM_NAME = 'SimilarDebtorsTable';
const DEBTOR_ITEM_ID_SELECTOR = 'data.id';

const getStatusView = approvalStatus => {
  if (approvalStatus === 'approved') {
    return (
      <div className='text-success'>
        <b>HAUL PAY APPROVED</b>
      </div>
    );
  }
  if (approvalStatus === 'declined') {
    return <div className='text-error'>{approvalStatus.toUpperCase()}</div>;
  }
  return <div className='text-warning'>{String(approvalStatus).toUpperCase()}</div>;
};

const TableItems = createTableItems(({ items, data, onDeleteClick }) => {
  const name = data.dba || get(data, 'user.dba') || data.company_name;
  const getRecomendedView = name => {
    if (items[0].id === data.id) {
      return (
        <div >
          {name}
          <br />
          {
            data.master === true ?
              <b className='text-success'><i>Recomended</i></b> : null
          }
        </div>
      );
    }
    return name;
  };
  const hasNoasData =
    data.hasOwnProperty('noa_active_count') && data.hasOwnProperty('noa_placed_count') && data.hasOwnProperty('noa_sent_count');

  return [
    getRecomendedView(name),
    data.address ? formatAddress(data.address) : '-',
    data.phone_number ? data.phone_number : '-',
    data.mc ? data.mc : '-',
    data.dot ? data.dot : '-',
    data.tax_id ? data.tax_id : '-',
    getStatusView(data.credit_approved),
    hasNoasData ? (
      <div>
        Sent: {data.noa_sent_count} Placed: {data.noa_placed_count} Active: {data.noa_active_count}
      </div>
    ) : (
      '-'
    ),
    data.hasOwnProperty('factoring_client_count') ? data.factoring_client_count : '-',
    data.hasOwnProperty('funding_request_count') ? data.funding_request_count : '-',
    data.hasOwnProperty('transferred_debtor_count') ? data.transferred_debtor_count : '-',
    <div>
      <Link target='_blank' to={`/admin/factoring/debtor/${data.id}`} title='View debtor profile'>
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', marginRight: '0.5em' }} name='account_circle' />
      </Link>
      <MaterialIcon title='Delete debtor' style={{ cursor: 'pointer', fontSize: 20, color: '#d9534f' }} name='delete' onClick={() => onDeleteClick(data)} />
    </div>,
  ];
});

const SimilarDebtorsTable = compose(
  withState('actionSubmitting', 'setActionSubmitting', null),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: (requestData, dispatch, meta, setActionSubmitting) => async () => {
      if (!requestData.itemIds.length) {
        return;
      }

      const fields = requestData.itemIds.reduce((agg, itemId) => {
        agg[itemId] = true;
        return agg;
      }, {});
      const formId = String(meta.form).split('#').slice(1).join('');

      if (requestData.action === 'delete'){
        try {
          setActionSubmitting('delete');
          await dispatch(openModal('confirm', {
            type: 'factoringdebtorbulk_delete',
            args: [{
              fields,
            }],
          }));
          // dispatch(openModal('success', { message: 'Successfully Debtors Deleted' }));
          // window.location.reload();
        }
        catch (err) {
          dispatch(closeModal());
          dispatch(openModal('error', { message: 'Error Deleting debtors.' }));
        }
        setActionSubmitting(null);
        return;
      }
      if (requestData.action === 'transfer') {
        const data = {
          master: true,
        };
        try {
          setActionSubmitting('transfer');
          await dispatch(edit(formId, data));
          try {
            for (const id in fields) {
              if (fields.hasOwnProperty(id)) {
                if (fields[id] === true) {
                  await dispatch(transfer(id, {
                    transferred_to: formId,
                  }));
                }
              }
            }
            dispatch(closeModal());
            dispatch(openModal('success', { message: 'Successfully Debtors Transferred' }));
            window.location.reload();
          }
          catch (err) {
            dispatch(closeModal());
            dispatch(openModal('error', { message: 'Error transferring debtors.' }));
          }
        }
        catch (err) {
          dispatch(closeModal());
          dispatch(openModal('error', { message: 'Error setting this debtor as master.' }));
        }
        setActionSubmitting(null);
      }
    },
  })
)(({ actionSubmitting, items, handleSubmit, submitting, onDeleteClick, onTransferClick, meta, dispatch, setActionSubmitting, onSubmit }) => {
  return (
    <div>
      <form>
        <ResponsiveTable
          desktopContainerProps={{
            className: 'Table scrollable',
          }}
          actions={[
            <button
              type='button'
              className='btn btn-danger'
              disabled={submitting || actionSubmitting}
              onClick={selectedItems => handleSubmit(onSubmit({
                itemIds: selectedItems.map(item => get(item, DEBTOR_ITEM_ID_SELECTOR)),
                action: 'delete',
              }, dispatch, meta, setActionSubmitting))()}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >Delete
              {actionSubmitting === 'delete' && <CircleSpinner size='20px' style={{ marginLeft: '1em' }} />}
            </button>,
            <button
              type='button'
              className='btn btn-default'
              disabled={submitting || actionSubmitting}
              onClick={selectedItems => handleSubmit(onSubmit({
                itemIds: selectedItems.map(item => get(item, DEBTOR_ITEM_ID_SELECTOR)),
                action: 'transfer',
              }, dispatch, meta, setActionSubmitting))()}
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
              >
              <MaterialIcon
                title='This will merge the debtor and transfer their funding requests.'
                style={{ cursor: 'pointer', fontSize: 20, color: '#e86900', marginRight: '0.5em' }}
                name='arrow_forward'
              />
          Transfer To Master
              {actionSubmitting === 'transfer' && <CircleSpinner size='20px' style={{ marginLeft: '1em' }} />}
            </button>,
          ]}
          isFetching={false}
          withPagination={true}
          idSelector={DEBTOR_ITEM_ID_SELECTOR}
          selectable={true}
          limit={10}
          headers={[
            'Name',
            'Address',
            'Phone',
            'MC#',
            'DOT#',
            'Tax ID#',
            'HP Status',
            'NOA\'s',
            'Connected Clients',
            'Funding Requests',
            'Transferred Debtors',
            'Actions',
          ]}
          placeholder='No Line Items'
          TableItem={TableItems.TableItem}
          BlockTableItem={TableItems.BlockTableItem}
          items={items.map(debtor => ({
            items: items,
            data: debtor,
            onDeleteClick,
            onTransferClick,
            meta,
          }))}
          style={{
            fontSize: '7px',
          }}
        />
      </form>
    </div>
  );
});

export default SimilarDebtorsTable;
