/**
 * Component to display the the Truck Load Matches page
 * @module components/pure/Matches
 * @since 3.0.0
 * @property {object} props
 * @property {node} props.itemView - the view for the truck
 * @property {node} props.matchesView - the view for the matched loads
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const Matches = ({ itemView, matchesView, ...rest }) =>
  <div {...rest} className={cs('Matches col-xs-12', rest.className)}>
    <div className='hidden-xs'>
      {itemView}
    </div>
    <div>
      {matchesView}
    </div>
  </div>
  ;

Matches.propTypes = {
  itemView: PropTypes.node.isRequired,
  matchesView: PropTypes.node.isRequired,
};

export default Matches;
