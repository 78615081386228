/**
 * Root reducer for the admin factoring reducers
 * @module reducers/admin/factoring
 * @since 3.0.0
 * @requires reducers/admin/factoring/debtor
 * @requires reducers/admin/factoring/client
 * @requires reducers/admin/factoring/payment
 */
import { combineReducers } from 'redux-v3';

import client from './client';
import debtor from './debtor';
import payment from './payment';
import lineitem from './lineitem';
import bank from './bank';
import groups from './group';

export default combineReducers({
  client,
  debtor,
  payment,
  lineitem,
  bank,
  groups,
});
