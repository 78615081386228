/**
 * Reducer for handling the admin factoring client actions
 * @module reducers/admin/factoring
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/factoring/client
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoring.group;

import factoringGroupsPaginatedResource from 'actions/admin/factoring/groups';


export default function factoringGroupsReducer(state = initialState, action) {
  state = factoringGroupsPaginatedResource.reduce(state, action);
  return state;
}
