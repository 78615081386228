import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.user.factoringTerms;

import { FETCH_TERMS_FAILURE, FETCH_TERMS_REQUEST, FETCH_TERMS_SUCCESS } from '../../../actions/factoring';


export default function factoringTerms(state = initialState, action) {
  switch (action.type) {
    case FETCH_TERMS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TERMS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case FETCH_TERMS_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
  }
  return state;
}
