import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import branch from 'recompose/branch';

import { fetchNext } from 'actions/resourceBy/truck/match';

import docTitle from 'components/hoc/docTitle';
import ResourceBy from 'components/hoc/ResourceBy';
import spinnerIf from 'components/hoc/spinnerIf';
import setLastTool from 'components/hoc/setLastTool';
import infiniteScroll from 'components/hoc/infiniteScroll';
import TruckLoadMatchMobile from 'components/container/TruckLoadMatchMobile';
import TruckLoadMatchMap from 'components/container/TruckLoadMatchMap';
import TruckLoadMatchTable from 'components/pure/TruckLoadMatchTable';
import TruckPostTable from 'components/pure/TruckPostTable';
import SearchResults from 'components/pure/SearchResults';
import Matches from 'components/pure/Matches';


export const TruckLoadMatchesResults = compose(
  ResourceBy('truck', 'match'),
  lifecycle({
    componentWillUnmount() {
      this.props.actions.clear();
    },
  }),
  spinnerIf(({ item }) => item === undefined),
)(({ id, item, actions }) =>
  <SearchResults
    id={id}
    {...item}
    {...actions}
    refresh={() => {
      actions.clear(id);
      return actions.fetch(id);
    }}
    MobileComponent={TruckLoadMatchMobile}
    DesktopComponent={TruckLoadMatchTable}
    GoogleMap={TruckLoadMatchMap}
    view={item.globals.view}
    breakpoint={825}
    orderingOptions={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerType',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Deadhead',
        ordering: 'deadhead',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
      {
        displayName: 'Target Rate',
        ordering: 'targetRate',
        adjectives: ['Min', 'Max'],
      },
    ]}
  />
);

export default compose(
  setLastTool(props => props.location.pathname),
  mapProps(({ routeParams: { id } }) => ({ id })),
  docTitle(props => `Load Matches - ${props.id}`),
  connect(
    (state, { id }) => state.resourceBy.truck.match[id] || {},
    (dispatch, { id }) => ({
      fetchNext() {
        return dispatch(fetchNext(id));
      },
    }),
  ),
  branch(
    ({ globals: { view } = {} }) => view === 'map',
    i => i,
    infiniteScroll({
      loadUntilFull: true,
    }),
  ),
)(({ id }) =>
  <Matches
    itemView={<TruckPostTable ids={[id]} />}
    matchesView={<TruckLoadMatchesResults id={id} />}
  />
);
