import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { setViewed } from 'actions/resource/loadsearch';

import hiddenPost from 'components/hoc/hiddenPost';
import scrollToItem from 'components/hoc/scrollToItem';
import LoadMobile2 from 'components/pure/LoadMobile2';


export default compose(
  connect(
    (state, ownProps) => {
      const load = state.resource.loadsearch[ownProps.id];
      const scroll_to_item = state.ui.scroll_to_item;
      return {
        load,
        scroll_to_item,
      };
    },
    {
      setViewed,
    },
  ),
  hiddenPost({ idProp: 'load.data.id' }),
  scrollToItem({
    idPropName: ['load', 'data', 'id'],
  }),
)(({ dispatch, setViewed, ...rest }) =>
  <LoadMobile2
    {...rest}
    onClick={() => setViewed(rest.load.data.id)}
  />
);
