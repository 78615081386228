import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import moment from 'moment';
import createClass from 'create-react-class';

import formatPennies from 'helpers/formatPennies';
import { Field, reduxForm, submit } from 'redux-form';
import { CartesianGrid, Legend, Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { fetchFactoringStats } from 'actions/admin/factoring/stats';
import { openModal } from 'actions/ui';
import getSelf from 'selectors/getSelf';

const PaymentsPurchasesDetails = createClass({
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className='panel panel_chart' >
          <div className='panel-body'><p>{`Week ending at ${moment(label).format('MM/DD/YY')} :`}</p>
            <p><font color='#4286f4'>{`Amount of Approved Invoices: ${formatPennies(payload[2].value)}`}</font></p>
            <p><font color='#e86b00'>{`Count of Approved Invoices: ${payload[1].value}`}</font></p>
            <p><font color='#e80000'>{`Count of Document Issue Invoices: ${payload[0].value}`}</font></p>
          </div>
        </div>
      );
    }
    return null;
  },
});

export default compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
  ),
  withState('stats', 'setStats', null),
  reduxForm({
    form: 'AdminDashboardFinance',
    async onSubmit(fields, dispatch, { setStats, id }) {
      try {
        const stats = await dispatch(fetchFactoringStats(id));
        setStats(stats);
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to fetch stats' }));
      }
    },
    formatDateTicks(tickItem) {
      let formattedString = moment(tickItem).format('MM/DD/YY');
      formattedString += ` to  ${moment(tickItem).add(6, 'd').format('MM/DD/YY')}`;
      return formattedString;
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.submit();
    },
  }),
)(({ handleSubmit, stats, formatDateTicks }) =>
  <div>
    {
      stats && stats.factoring_stats && stats.factoring_stats.length > 0 ?
        <div>
          <center>
            <h5><b><u> Client Pulse (From Onboarding) </u></b></h5>
            <LineChart width={730} height={250} data={stats.factoring_stats} margin={{ left: 50 }}>
              <CartesianGrid strokeDasharray='3 3' />
              <XAxis dataKey='week_start' tick={false} />
              <YAxis yAxisId='amount'v={true} orientation='left' tickFormatter={formatPennies} />
              <YAxis yAxisId='count' orientation='right' />
              <Tooltip content={<PaymentsPurchasesDetails />} />
              <Legend />
              <Line name='Count of Document Issue Invoices'dot={false} dataKey='amount_declined' stroke='#e80000' yAxisId='count' />
              <Line name='Count of Invoices Factored' dot={false} dataKey='amount_count' stroke='#e86b00' yAxisId='count' />
              <Line name='Value of Approved Invoices Factored $' dataKey='amount_approved' fill='#4286f4' stroke='#4286f4' yAxisId='amount' />
            </LineChart>
          </center>
        </div>
        :
        <div> <center> <h5><b><u> No Funding Request Data Found </u></b></h5> </center></div>
    }
  </div>
);
