/**
 * Actions relating to the Load by User resource
 * @module actions/resourceBy/user/load
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/load
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import haulPayLoadResourcefrom from 'actions/resource/haulPayLoad';


/**
 * Our Load by User ControlledPaginatedResourceCollection instance
 */
export const HaulPayloadByUserResource = new ControlledPaginatedResourceCollection('user', 'haulPayLoad', {
  url: new UrlAssembler('user/:id/loads'),
  paginatedResourceOptions: {
    baseResource: haulPayLoadResourcefrom,
    belongsInCollection: id => data => data.user_id === id,
    append_to_top_on_create: true,
  },
});

export default HaulPayloadByUserResource;
export const {
  fetch,
  fetchNext,
  create,
  clear,
} = HaulPayloadByUserResource;
