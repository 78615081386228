import React from 'react';
import { Link } from 'react-router-v4';
import moment from 'moment';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import MaterialIcon from 'components/pure/MaterialIcon';
import LoadAttachmentButton from './LoadAttachmentButton';

class Event extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  render() {
    const { data, transform, sort } = this.props;
    const { open } = this.state;

    let diff = null;
    let { responsible_user } = data;
    if (data.diff) {
      diff = Object.keys(data.diff.values_changed)
        .map(key => {
          const value = data.diff.values_changed[key];
          const property_name = key
            .slice(6, -2)
            .split('\'][\'')
            .map(key => key.split('_').map(capitalize).join(' '))
            .join(' : ');

          const diff_data = {
            original_key: key.slice(6, -2).replace(/'\]\['/g, '.'),
            key: property_name,
            old_value: value.old_value,
            new_value: value.new_value,
          };
          return diff_data;
        })
        .reduce((acc, diff_data, i, arr) => {
          let data = null;
          if (typeof transform === 'function') {
            data = transform(diff_data, i, arr);
          }
          else {
            data = diff_data;
          }
          if (!data) {
            return acc;
          }
          acc.push(data);
          return acc;
        }, []);
      if (data.diff.files) {
        if (data.diff.files.length && data.diff.files[0].responsible_user) {
          responsible_user = data.diff.files[0].responsible_user;
        }
        const files = data.diff.files.map(file => {
          const name = `${file.category ? `[${file.category}] ` : ''}${file.filename}`;
          const value = (
            <div>
              {name}
              {' '}
              <LoadAttachmentButton
                download_url={file.download_url}
                preview_url={file.preview_url}
              />
            </div>
          );
          return {
            original_key: 'attachments',
            key: 'Attachments',
            new_value: file.changed === 'added' ? value : '',
            old_value: file.changed === 'added' ? '' : value,
          };
        });
        diff.push(...files);
      }
      if (!diff.length) {
        diff = null;
      }
    }

    if (data.diff && !diff) {
      return null;
    }

    if (diff && typeof sort === 'function') {
      diff.sort(sort);
    }

    return (
      <div className='EventLog__Event'>
        <MaterialIcon name={diff && open ? 'remove' : 'add'} />
        <div
          className='EventLog__Event_text'
          role={diff ? 'button' : undefined}
          onClick={() => this.setState({ open: !open })}
          >
          {' '}
    [{moment(data.current_row.created).format('MMM DD, YYYY - hh:mm A')}]
          {' '}
          {
            responsible_user && responsible_user.name ?
              !data.diff && !['admin', 'sub_admin', 'factoring_admin', 'factoring_sub_admin'].includes(responsible_user.user_type) ?
                <Link to={`/admin/user/search/${responsible_user.id}`}>{responsible_user.name}</Link>
                :
                responsible_user.name
              :
              'HaulPay Dashboard'
          }
          {' '}
          {diff ? 'modified these fields: ' : 'created'}
          {
            diff ?
              diff
              // dedupe nested edits
                .reduce((acc, curr) => {
                  const has_nested = curr.original_key.indexOf('.');
                  if (has_nested > -1) {
                    const root_key = curr.original_key.slice(0, has_nested);
                    const has_root_key_already = acc.find(({ original_key }) => {
                      const original_root_key = original_key.slice(0, original_key.indexOf('.'));
                      return original_root_key === root_key;
                    });
                    if (!has_root_key_already) {
                      acc.push({
                        ...curr,
                        key: root_key.split('_').map(capitalize).join(' ').replace('Funding Request ', ''),
                      });
                    }
                  }
                  else {
                    const has_key_already = acc.find(({ original_key }) => curr.original_key === original_key);
                    if (!has_key_already) {
                      acc.push(curr);
                    }
                  }
                  return acc;
                }, [])
                .reduce((acc, curr, i, arr) => {
                  acc.push(<b>{curr.key}</b>);
                  if (i !== arr.length - 1) {
                    acc.push(', ');
                  }
                  return acc;
                }, [])
              : null
          }
        </div>
        {
          diff && open ?
            <div>
              {
                data && data.responsible_user && data.responsible_user.name && !['admin', 'sub_admin', 'factoring_admin', 'factoring_sub_admin'].includes(data.responsible_user.user_type) ?
                  <Link to={`/admin/user/search/${data.responsible_user.id}`} style={{ padding: 5 }}>{data.responsible_user.name}'s User Profile</Link>
                  : null
              }
              <table className='table table-striped table-condensed table-hover'>
                <thead>
                  <tr>
                    <th>Field Name</th>
                    <th>Old Value</th>
                    <th>New Value</th>
                  </tr>
                </thead>
                <tbody>
                  {diff.map(({ key, old_value, new_value }) =>
                    <tr>
                      <td>{key}</td>
                      <td>{old_value}</td>
                      <td>{new_value}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            : null
        }
      </div>
    );
  }
}

const EventLog = ({
  events,
  transform,
  sort,
}) =>
  <div className='EventLog'>
    {
      events && events.length ?
        events.map((event, i) => <Event key={i} data={event} transform={transform} sort={sort} />)
        :
        <h4>No events.</h4>
    }
  </div>
  ;

// const StyledEventLog = styled(EventLog)`
//   .EventLog {
//     &__Event {
//       & > .MaterialIcon {
//         line-height: 1.42857143;
//         width: 1.42857143em;
//         height: 1.42857143em;
//         position: absolute;
//       }
//
//       &_text {
//         margin: 0.5em 0;
//         position: relative;
//         left: 1.42857143em;
//
//         &[role='button']:hover {
//           text-decoration: underline;
//         }
//       }
//     }
//   }
// `;

export default EventLog;
