import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.financeslineitem;

import financeslineitemResource from 'actions/resource/financeslineitem';
import financeslineitemsPaginatedResource from 'actions/admin/financeslineitems';


export default function financeslineitemResourceReducer(state = initialState, action) {
  state = financeslineitemResource.reduce(state, action);
  switch (action.type) {
    case financeslineitemsPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...financeslineitemResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
