import React from 'react';
import { Card, CardTitle, CardText } from 'material-ui/Card';
import colors from 'styles/colors.json';

export default function AmountCard({  amount, title, subHeader }) {
    const colorMapping = {
        RED: colors.RED,
        GREEN: colors.GREEN
    }
    return(
        <Card style={{ borderRadius: 10 }} containerStyle={{ minWidth: 300, textAlign: 'center', justifyContent: 'center'  }}>
            <CardTitle title={title} />
            <CardText color={colorMapping.GREEN} style={{ fontSize: 30 }}>
                {amount}
            </CardText>
            <p style={{ fontSize: 14, color: colors.GRAY_TEXT }}>
                {subHeader}
            </p>
        </Card>
    )
}