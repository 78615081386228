import validatejs from 'validate.js';


export const vjs = [
  'date',
  'datetime',
  'email',
  'equality',
  'exclusion',
  'format',
  'inclusion',
  'length',
  'numericality',
  'presence',
  'url',
  'googleAddress',
  'multipleEmail'
].reduce((vjs, fname) => {
  vjs[fname] = (args = true) => val => {
    const errs = validatejs.single(val, {
      [fname]: args,
    });
    const err = errs[0];
    return err;
  };
  return vjs;
}, Object.create(null));

// overwrite `presence` default message
validatejs.validators.presence.message = 'required';

/**
 * Overwrite numericality validator to allow empty values
 */
const oldNumericality = validatejs.validators.numericality;
validatejs.validators.numericality = function numericality(value) {
  if (validatejs.isEmpty(value)) {
    return;
  }
  return oldNumericality.apply(validatejs.validators, arguments); // eslint-disable-line prefer-rest-params
};


/**
 * Overwrite url validator to allow schemeless urls
 */
/* eslint-disable */
validatejs.validators.url = function url(value, options) {
  if (validatejs.isEmpty(value)) {
    return;
  }

  options = validatejs.extend({}, this.options, options);

  const message = options.message || this.message || 'is not a valid url';
  const schemes = options.schemes || this.schemes || ['http', 'https'];
  const allowLocal = options.allowLocal || this.allowLocal || false;

  if (!validatejs.isString(value)) {
    return message;
  }

  // https://gist.github.com/dperini/729294
  let regex =
    '^' +
    // protocol identifier
    '(?:(?:' + schemes.join('|') + ')://)?' + // ONE-LINE CHANGE HERE
    // user:pass authentication
    '(?:\\S+(?::\\S*)?@)?' +
    '(?:';

  let tld = '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))';

  if (allowLocal) {
    tld += '?';
  }
  else {
    regex +=
      // IP address exclusion
      // private & local networks
      '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
      '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
      '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})';
  }

  regex +=
      // IP address dotted notation octets
      // excludes loopback network 0.0.0.0
      // excludes reserved space >= 224.0.0.0
      // excludes network & broacast addresses
      // (first & last IP address of each class)
      '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
      '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
      '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
    '|' +
      // host name
      '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
      // domain name
      '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
      tld +
    ')' +
    // port number
    '(?::\\d{2,5})?' +
    // resource path
    '(?:[/?#]\\S*)?' +
  '$';

  const PATTERN = new RegExp(regex, 'i');
  if (!PATTERN.exec(value)) {
    return message;
  }
};
/**
 * Multiple nested presence checker. Checks an object for present keys
 * @param {string[] | object} options - either the array of required field names, or the options object
 * @param {string[]} [options.required] - the array of required field names
 * @param {function} [options.formatKey = key => key] - a function to format the missing key before being added to the error message
 * @param {string} [options.message = '^Must include %{missing}'] - the error message template
 */
validatejs.validators.googleAddress = (...args) => {
  const [ value, options ] = args;
  const message = '^This field Must include %{missing}';
  const missingKeys = [];
  options.forEach(subkey => {
    if (!value || validatejs.isEmpty(value[subkey])) {
      missingKeys.push(subkey = (subkey === 'zip' ? 'Zip Code' : 'Address'));
    }
  });
  if (missingKeys.length) {
    return validatejs.format(message, { missing: missingKeys.join(', ') });
  }
};
/* eslint-enable */
validatejs.validators.multipleEmail = value => {
  if (validatejs.isEmpty(value)) {
    return;
  }
  const regex = validatejs.validators.email.PATTERN;
  const results = value.split(",");
  for (const email of results) {
    if(!regex.test(email.replace(" ", ""))) {
      return "is not a valid email";
    }   
  }   
  return;
}
