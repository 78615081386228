import React from 'react';
import Header from 'components/pure/Header';

const FAQ = ({ children }) => <div style={{ paddingBottom: '1em' }}>{children}</div>;

FAQ.Q = ({ children }) =>
    <div>
        <span className='h4'>Q. </span>
        <span><em>{children}</em></span>
    </div>
    ;

FAQ.A = ({ children }) =>
    <div>
        <span className='h4'>A. </span>
        <span>{children}</span>
    </div>
    ;

const EmailLink = ({ email }) => <a href={`mailto:${email}`}>{` ${email} `}</a>
const Link = ({ link, children }) => <a href={link} target="_blank">{children}</a>

const NOAInvoiceQA = [
    {
        q: 'How do I submit a Funding Request?',
        a: [<span>
            The fastest and easiest way to submit a funding request for funding is to download the HaulPay app on your mobile device. If you prefer to use a computer or laptop, you can use the HaulPay feature in the <Link link="https://app.haulpay.io/login">HaulPay website</Link>. If you have any issues with our website or mobile app please contact us at <EmailLink email="support@haulpay.io" />.
    </span>,
        <span style={{ display: 'inline-block', marginTop: 5 }}>
            You can watch this <Link link="https://youtu.be/c1yo4d3rdZY">video</Link> to learn how to submit a funding request via the HaulPay app.
            This video <Link link="https://youtu.be/CWMeRiqV_Qs">here</Link> will show you how to use the HaulPay website to submit a funding request.
    </span>
        ]
    },
    {
        q: 'What’s a Debtor?',
        a: 'A Debtor is your customer and is the party that we are monitoring credit approval for and extending credit for to enable you to receive payment advances for invoices for loads.'
    },
    {
        q: 'What is an NOA?',
        a: "A Notice of Assignment (NOA) is a document assigning your invoices to your factoring company and also includes payment remittance information. NOAs are emailed to your existing or potential clients to ensure that payments remitted in the future are made to us, as your factoring company. We need to confirm the NOA is in place with your customer, or debtor, before advancing funds on subsequent invoices. Once they verify they have received it and have updated invoice payment remittance information on their side, we can begin advancing funds to you."
    },
    {
        q: 'What does the “Pending NOA” status mean?',
        a: 'A “Pending NOA” status means we are waiting for the debtor, your customer, to confirm that they have received the NOA. We do this to make sure there is no confusion in regards to routing final payments and to eliminate the potential for future disputes over settling the invoice.'
    },
    {
        q: 'How do I send an NOA?',
        a: <span>
            The best way to send the NOA is via the HaulPay app or the HaulPay website. Login to your account, add the customer to your customer list by going to the Credit or Credit Check menu item, input the customer name to find the debtor, once the customer appears in your search results, click on the orange button “+Add Customer & Review Credit” button. You will then be asked if you would like to send the NOA to speed up payment. When you click “Confirm”, the NOA will be sent from our support email to your customer’s email address we have on file. You can also request a copy of your NOA and email it to your shipper/customer.
            You can check out these videos that will show you how to send an NOA using the <Link link="https://youtu.be/sdozNal-1FM">HaulPay app</Link> and the <Link link="https://youtu.be/6o4f4Xjtjg8">HaulPay website</Link>.
        </span>
    },
    {
        q: 'My Funding Request is still Pending, why?',
        a: 'We may be trying to confirm with the debtor’s accounting department to confirm either the rate or BOL documents are satisfactory to allow for future payment. We do this to avoid potential chargebacks or issues in future settlement of the invoices, and to protect you from unexpected costs. You can keep track of all payment status via the HaulPay app or HaulPay website.'
    },
    {
        q: 'When will I receive my funds?',
        a: 'Our quick pay is one business day unless we need to verify the NOA (notice of assignment) which can take 2-5 business days depending on the debtor. The sooner we’re able to place the NOA the faster we can begin sending funding.'
    },
    {
        q: 'How do I request a fuel advance?',
        a: 'Typically, after 30-60 days of consistent factoring without issues with billing. Please contact your account representative for further details and assistance.'
    },
    {
        q: 'I didn’t receive the correct amount, why?',
        a: 'Make sure the correct rate is reflected on the invoice amount. We fund the total of what is itemized on the rate confirmation. We will bill out any additional accessorials as requested. '
    },
    {
        q: 'What is a chargeback and why did I get one?',
        a: <span>
            A chargeback is a fee against the carrier for reasons such as late fees, lumper advances or debtor advances,etc. Your rate confirmation is key to getting more information about your brokers specific fees. 
            If you have any questions please email <EmailLink email="payments@haulpay.io" /> with the load# in the subject line for reference, 
            and we can answer any specific questions you may have about a chargeback.
        </span>
    },
    {
        q: 'How much is a wire and how do I request one?',
        a: 'Wire cost can vary from carrier to carrier, client to client and can be requested as long as you have a wire payment method for your account. Check with your account representative if you’re not sure if you have a wire option setup for your account.'
    },
    {
        q: 'What is the WIRE cutoff time?',
        a: 'Our last wire cutoff time is 1:30 PM Pacific Standard Time (PST). We may still need to verify information or may need better supporting documents so the earlier you submit the funding request the better.'
    },
    {
        q: 'Why is my funding request in Document Issue?',
        a: 'We typically send an email and text message with a message indicating what the issue is. It could be due to missing pages of required documents or illegible documents that we’ll need for invoicing. If you have any questions about the document issue, you can reply to the original email message we sent or you can give us a call at (888) 633-5558.'
    },
    {
        q: 'Why is my Funding Request “Incomplete?”',
        a: [<span>
            Please make sure you upload and label all documents uploaded as either a rate confirmation, Signed BOL, TONU, etc. If you do not label your documents properly, the system will think your funding request is incomplete. If your request is in an “Incomplete” status by error, 
            then please call or email us at <EmailLink email="invoice@haulpay.io" />.
        </span>,
        <span style={{ display: 'inline-block', marginTop: 5 }}>
            You can also use the incomplete status to create funding requests for loads that you have not delivered yet ahead of time to give us more time to confirm NOA placement. For any incomplete funding request we require the signed BOL from the receiver and a Rate Confirmation to move the funding request out of this status for payment processing. 
        </span>
        ]
    },
    {
        q: 'How do I bill for detention, lumpers and extra charges?',
        a: ['Please note - we only fund accessorials itemized on a Rate Confirmation Sheet. If you have any lumpers or extra accessorials you need billed, please add a note with your request.',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
                If you would like to bill out an accessorial, 
                but your line haul has already been paid out, 
                then please create an additional funding request for this pay out via the HaulPay app or the HaulPay website.
                You can do this by uploading all documents pertaining to the load (rate con, BOLs, etc.) and adding the load number with the words  - LUMPER or -  DETENTION. Example “LOAD#-LUMPER.” You can watch a video tutorial here for the <Link link="https://f.hubspotusercontent00.net/hubfs/4789603/Lumper%20FR%20HaulPay%20Submission.mp4">HaulPay app</Link> and here for the <Link link="https://f.hubspotusercontent00.net/hubfs/4789603/Lumper%20Submission%20How%20to.wmv">HaulPay website</Link>.
            </span>
        ]
    },
]

const fuelAdvanceQA = [
    {
        q: 'How much is a fuel advance?',
        a: 'After 60 days of consistent factoring, please contact your account manager for further details and assistance. A Fuel Advance cost is $20, and requires a signed BOL from the linehaul origin.'
    },
    {
        q: 'Why is this broker or shipper declined?',
        a: ['We use three different credit sources in order to make an accurate assessment of the businesses ability to pay invoices. In the credit decision process, we are confirming the following; payment history, credit score, and any reports filed against the company with regards to non-payment/double brokering/payment delays. Credit checks are rerun every 3-6 months in order to confirm the credit decision is based on the most recent payment and credit data.',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
                Certain debtors may have a higher discount rate accessed on the account due to the factors previously listed. If you have more questions about a credit approval for a specific debtor or have questions regarding the rate accessed, please do not hesitate to reach out to our credit department via <EmailLink email="payments@haulpay.io" />.
        </span>
        ]
    },
    {
        q: 'Why is this broker or shipper declined?',
        a: <span>
            We use three different credit sources in order to make an accurate assessment of the businesses ability to pay invoices. In the credit decision process, we are confirming the following: payment history, credit score and any reports filed against the company with regards to non-payment/fraud/payment delays. Credit checks are re-run every 3 months in order to confirm that credit decisions are based on the most recent payment and credit data. Certain debtors may have a higher discount rate accessed on the account due to the factors previously listed. If you feel that a credit decision was performed incorrectly or have questions regarding the rate accessed,
    please do not hesitate to reach out to our credit department via <EmailLink email="payments@haulpay.io" />
        </span>
    },
    {
        q: 'How do I check a customer’s credit?',
        a: <span>
            You can check a customer’s credit via the HaulPay app or HaulPay website.
            You can schedule a demo with your account manager and you can also watch this <Link link="https://www.youtube.com/watch?v=LXlYgdwQQGM">video</Link> for the HaulPay app and 
            <Link link="https://www.youtube.com/watch?v=B4z-NE8WONE"> this one</Link> for the HaulPay website.
        </span>
    },
     {
        q: 'My customer does not show up when I run a credit check. What do I do?',
        a: ['When a customer does not show up on our credit check tool, it simply means we don’t have them in our system yet. Please click on the green “+ Add New Customer” button and input as much information as possible.',
            <span style={{ textDecoration: 'underline' }}>
                Please make sure to include a Tax ID number or EIN as well as a valid phone number and or email so our billing team can get in touch to make sure we have all the information we need to invoice them.
        </span>
        ]
    },
    {
        q: 'Why is this Debtor “Pending?”',
        a: [
            'Please make sure you add the debtor to your debtor list. The credit will not be triggered until you add the debtor to your debtor list. You can add them to your list by clicking on the “Add Debtor” button.',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
             A debtor could also be in the pending status because they have not had any active funding requests in more than 6 months. If you are already connected to a debtor in your customer list but they are showing as pending you can click the Review Credit text to have them reviewed.   
            </span>

        ]
    },
    {
        q: 'What\'s credit limit?',
        a: <span>
            The credit limit is the maximum amount of credit we are extending to any specific customer. You can do this via the HaulPay app or you can email <EmailLink email="payments@haulpay.io" />.
        </span>
    },
];

const generalQuestionQA = [
    {
        q: 'What if the app or the website aren’t working?',
        a: [<span>
            Take a screenshot of any error message you are receiving and attach it to an email to <EmailLink email="support@haulpay.io" />. Please include a description of the error and what device you are using (i.e. laptop, android cell, iphone cell, etc.) Once we have this information, 
            then we can properly assess a solution to your issue.
        </span>,
        <span style={{ display: 'inline-block', marginTop: 5 }}>
            You can also try uninstalling and reinstalling the app on your device. If you are still experiencing issues after doing this, please contact our support line 888.633.5558 ext 1, 2 for general support.
        </span>
        ]
    },
    {
        q: 'How do I schedule another demo or tutorial?',
        a: <span>
            To schedule a demo or tutorial of HaulPay or the load board, reach out to your account manager, email <EmailLink email="support@haulpay.io" /> You can also give us a call 888-633-5558.
</span>
    },
     {
        q: 'Where can I get my w-2 or tax report?',
        a: <span>
            Please email <EmailLink email="support@haulpay.io" /> for any tax report requests.
</span>
    },
    {
        q: 'What are your operating hours and mailing address?',
        a: [
            'Our phone lines are open from 7AM until 5 PM Pacific Standard Time (PST), Monday through Friday. (888) 633-5558. ',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
                You can send mail to:
    </span>,
            <p style={{ marginTop: 10, textAlign: 'center', verticalAlign: "middle", fontWeight: "bold" }}>
                Comfreight
            </p>,
            <p style={{ marginTop: 5, textAlign: 'center', verticalAlign: "middle" }} >
                65 Pine Ave STE 853
                Long Beach CA 90802
 </p>
        ]
    },
]

const CarrierFAQ = () =>
    <div>
        <Header>Common Help Questions</Header>
        <h4>NOAs and Invoices:</h4>
        {
            NOAInvoiceQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
        <h4>Fuel Advances and Credit Checks</h4>
        {
            fuelAdvanceQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
        <h4>General Questions</h4>
        {
            generalQuestionQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
    </div>


export default CarrierFAQ;