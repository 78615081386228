/**
 * Reducer for handling the admin factoring payment actions
 * @module reducers/admin/payment
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/factoring/payment
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoring.payment;

import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';


export default function factoringpaymentReducer(state = initialState, action) {
  state = factoringpaymentPaginatedResource.reduce(state, action);
  return state;
}
