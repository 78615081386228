import React from 'react';
import { compose } from 'redux-v3';
import { closeModal, openModal } from 'actions/ui';
import { connect } from 'react-redux-v5';
import FundingRequestInvoices from '../FundingRequestInvoices';

export default compose(
    connect(null, { closeModal })
)(({ poRelatedInvoice, closeModal }) => (
    <div>
        <div className='modal-header'>
            <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
            <h4 className='text-orange'>Invoices with matching PO#</h4>
        </div>
        <div className='modal-body'>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FundingRequestInvoices items={poRelatedInvoice} />
            </div>
        </div>
    </div>
));
