/* global EMPTY_PLACEHOLDER */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import withState from 'recompose/withState';
import { Link } from 'react-router-v4';
import createTableItems from 'helpers/createTableItems';

import { openModal } from 'actions/ui';
import { searchFactoringDebtors, setFactoringDebtorStatus } from 'actions/factoring';

import wrapPromisePending from 'components/hoc/wrapPromisePending';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const FactoringCreditCheckListItems = createTableItems(
  ({ data, setFactoringDebtorStatus, submitting, has_been_set }) => [
    data.company_name,
    (() => {
      switch (data.credit_approved) {
        case 'needs_review':
          return <b><span className='text-orange'>Being Reviewed</span></b>;
        case 'declined':
          return <b><span className='text-danger'>Declined</span></b>;
        case 'approved':
          return <b><span className='text-success'>Approved</span></b>;
        case 'pending':
        default:
          return <b><span className='text-danger'>Needs Review</span></b>;
      }
    })(),
    data.phone_number,
    data.email,
    data.address.street_one,
    data.address.state,
    data.address.zip,
    data.credit_approved === 'pending' && !has_been_set
      ? <button type='button' className='btn btn-orange' onClick={setFactoringDebtorStatus} disabled={submitting}>Request Review</button>
      : EMPTY_PLACEHOLDER,
    <Link to={`/admin/factoring/debtor/${data.id}/payments`}>
      <button type='button' className='btn btn-orange' disabled={submitting}> Select </button>
    </Link>,
  ],
  compose(
    withState('has_been_set', 'set_has_been_set', false),
    connect(
      null,
      (dispatch, { data, set_has_been_set }) => ({
        async setFactoringDebtorStatus() {
          try {
            await dispatch(setFactoringDebtorStatus({
              id: data.id,
              credit_approved: 'needs_review',
            }));
            dispatch(openModal('warning', { message: 'We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
            set_has_been_set(true);
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'There was an error processing your request.' }));
          }
        },
      }),
    ),
    wrapPromisePending({
      promiseProp: 'setFactoringDebtorStatus',
    }),
  ),
);

export default compose(
  connect(
    null,
    {
      openAddCustomerModal: () => openModal('factoringclientadddebtor'),
    },
  ),
  reduxForm({
    form: 'FactoringCreditCheckSearchForm',
  }),
)(class FactoringPaymentsDebtorSearchForm extends React.Component {
  state = {
    debtors: [],
  };
  onSubmit = this.onSubmit.bind(this);

  async onSubmit(fields, dispatch) {
    try {
      const debtors = await dispatch(searchFactoringDebtors(fields));
      this.setState({ debtors });
    }
    catch (err) {
      console.warn(err);
      dispatch(openModal('error', { message: 'There was an error while searching.' }));
    }
  }

  render() {
    const { handleSubmit, submitting, openAddCustomerModal } = this.props;
    const { debtors } = this.state;

    return (
      <form onSubmit={handleSubmit(this.onSubmit)}>
        <h3 style={{ color: '#2196f3', marginBottom: '3%' }}><b>Search Debtor To Create a Receivable</b></h3>
        <div className='row'>
          <div className='col-xs-12 col-lg-3'>
            <Field
              name='dba'
              component={LabeledInput}
              label='Company Name'
            />
          </div>
          <div className='col-xs-12 col-lg-3'>
            <Field
              name='mc'
              component={LabeledInput}
              label='MC Number (Broker Search)'
            />
          </div>
          <div className='col-xs-6 col-lg-3'>
            <button className='btn btn-orange' type='submit' style={{ marginTop: '25' }} disabled={submitting}>Search Debtor</button>
          </div>
        </div>
        <Header hr={true}><b> Debtors </b></Header>
        <div style={{ marginRight: '10%' }}>
          <ResponsiveTable
            isFetching={submitting}
            items={debtors.map(debtor => ({
              data: debtor,
            }))}
            placeholder='No results.'
            headers={['DBA', 'Credit', 'Phone', 'Email', 'Address', 'State', 'Zip', 'Review', 'Select']}
            {...FactoringCreditCheckListItems}
          />
        </div>
      </form>
    );
  }
});
