import React from "react";
import tw, { styled } from "twin.macro";
import moment from 'moment';
import get from 'lodash/get';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import formatPennies from "../../../helpers/formatPennies";
import USER_FACTORING_STATUS from '../../../helpers/USER_FACTORING_STATUS';
import { Button, Tooltip } from "@haulpay/ui";

const NOAStatus = ({ data }) => {
    const P = styled.p(() => [
        tw`text-red-600`,
        data.noa_active && tw`text-green-500`,
        data.noa_sent && tw`text-blue-300`,
        data.noa_requested && tw`text-yellow-400`,
    ]);
    if (data.noa_active) {
        return <P>Placed (Active)</P>;
    }

    if (data.noa_sent) {
        return <P>Sent (Pending)</P>;
    }

    if (data.noa_requested) {
        return <P>Request Sent</P>;
    }

    return (
        <P>{`Not Sent ${data.credit_approved === 'approved' ? '(Send?)' : ''
            }`}</P>
    );
};

export const checkStatus = (status) => {
    switch (status) {
        case USER_FACTORING_STATUS.PENDING_DELIVERY:
            return { text: 'Incomplete', value: status };
        case USER_FACTORING_STATUS.REMOTE_APPROVED:
            return { text: 'Approved', value: status };
        case USER_FACTORING_STATUS.INCOMPLETE:
            return { text: 'pending_delivery', value: 'pending_delivery' };
        case USER_FACTORING_STATUS.SPECIAL_PENDING:
            return {
                text: 'Pending',
                value: USER_FACTORING_STATUS.SPECIAL_PENDING,
            };
        case USER_FACTORING_STATUS.PENDING:
            return {
                text: 'Pending',
                value: USER_FACTORING_STATUS.SPECIAL_PENDING,
            };
        case USER_FACTORING_STATUS.CARRIER_PAID:
            return {
                text: 'Carrier Paid',
                value: USER_FACTORING_STATUS.CARRIER_PAID,
            };
        case USER_FACTORING_STATUS.INVOICE_PAID:
            return {
                text: 'Invoice Paid',
                value: USER_FACTORING_STATUS.INVOICE_PAID,
            };
        default:
            return { text: status, value: status };
    }
};

const columns = (statuses) => [
    {
        title: 'Carrier',
        render: ({ data, ...rest }) => {
           return data.payment_profile?.company_name
        },
        key: 'company_name',
        ellipsis: true,
    },
    {
        title: 'Debtor',
        render: ({ data }) => data.debtor?.dba,
        key: 'dba',
        ellipsis: true,
    },
    {
        title: 'NOA Status',
        render: ({ data }) => <NOAStatus data={data.factoring_debtor_relation} />,
        key: 'noa',
        ellipsis: true,
    },
    {
        title: 'Invoice Amnt.',
        render: ({ data }) => formatPennies(data.amount),
        key: 'amount',
        ellipsis: true,
    },
    {
        title: 'Invoice #',
        dataIndex: 'invoice_number',
        key: 'invoice_number',
        ellipsis: true,
    },
    {
        title: 'NOA Status',
        render: ({ data }) => {
            const invoiceSent = `Invoice Sent On: \n
          ${moment(data.invoice_date).format('l LTS')}
        `;
            const P = styled.p(() => [
                tw`text-red-600`,
                data.invoice_date && tw`text-green-500`,
            ]);

            return data.invoice_date ? (
                <div>
                    <Tooltip title={invoiceSent} placement="top">
                        <P>YES</P>
                    </Tooltip>
                </div>
            ) : (
                <p>NO</p>
            );
        },
        key: 'noa',
        ellipsis: true,
    },
    {
        title: 'Load #',
        dataIndex: 'user_load_number',
        key: 'user_load_number',
    },
    {
        title: 'Requested',
        render: ({ data }) => moment(data.created).format('l LTS'),
        key: 'requested',
        ellipsis: true,
    },
    {
        title: 'Debtor Rate',
        render: ({ data }) => data.factoring_debtor_relation?.discount_rate || '-',
        key: 'discount_rate',
        ellipsis: true,
    },
    {
        title: 'Carrier %',
        render: ({ data }) =>
            `${(data.discount_rate * (100 - data.factoring_fee_split_rate)) /
            100
            }%` || '-',
        key: 'carrier_discount_rate',
        ellipsis: true,
    },
    {
        title: 'Carrier Pay',
        render: ({ data }) =>
            data.payment_profile_amount
                ? formatPennies(data.payment_profile_amount)
                : '-',
        key: 'payment_profile_amount',
        ellipsis: true,
    },
    {
        title: 'Amnt. Funded.',
        render: ({ data }) =>
            data.amount_funded ? formatPennies(data.amount_funded) : '-',
        key: 'payment_profile_amount',
        ellipsis: true,
    },
    {
        title: 'Status',
        render: ({ data }) => {
            const status = data.status;
            const nonFactoredValueMap = {
                bill_out: 'Bill Out Only',
                standard: 'Pay Carrier',
            };
            const B = styled.b(() => [
                tw`uppercase`,
                tw`text-base`,
                tw`block`,
                [
                    'declined',
                    'incomplete',
                    'pending_delivery',
                    'document_issue',
                ].includes(status) && tw`text-red-600`,
                [
                    'approved',
                    'paid',
                    'remote_approved',
                    'invoice_paid',
                    'carrier_paid',
                ].includes(status) && tw`text-green-500`,
                ['new'].includes(status) && tw`text-blue-300`,
                ['pending', 'special_pending', 'fuel_advance'].includes(
                    status
                ) && tw`text-yellow-400`,
            ]);
            return (
                <div>
                    {data.attachments.length > 0 &&
                        data.attachments[data.attachments.length - 1]
                            .after_issue === true && (
                            <div className="text-success">
                                <FontAwesomeIcon icon={faPaperclip} size="xs" />
                                New!
                                <br />
                                <span>
                                    {moment(
                                        data.attachments[
                                        data.attachments.length - 1
                                        ].uploaded
                                        ).format('MM/DD, hh:mm a')
                                    }
                                </span>
                            </div>
                        )}
                    <B>{checkStatus(status).text}</B><br />
                    {data.non_factored_type && (
                        <B>{nonFactoredValueMap[data.non_factored_type]}</B>
                    )}
                </div>
            );
        },
        filters: statuses,
        key: 'status',
        filterMultiple: false,
        ellipsis: true,
    },
    {
        title: 'More',
        render: () => <Button type="primary">More</Button>,
        ellipsis: true,
    },
];
export default columns;