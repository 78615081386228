import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { submit } from 'redux-form';
import getContext from 'recompose/getContext';
import mapProps from 'recompose/mapProps';

import { openModal } from 'actions/ui';
import * as loadSearchActions from 'actions/search/load';

import Search from 'components/pure/Search';
import LoadSearchForm from 'components/pure/form/LoadSearchForm';
import SearchResults from 'components/pure/SearchResults';
import LoadSearchMap from 'components/container/LoadSearchMap';
import LoadSearchTableItem from 'components/container/LoadSearchTableItem';
import LoadSearchMobile from 'components/container/LoadSearchMobile';
import LoadSearchTable from 'components/pure/LoadSearchTable';


const FORM_NAME_PREFIX = 'DispatchLoadSearchForm#';

const enhance = compose(
  getContext({
    params: PropTypes.object,
  }),
  connect(
    null,
    (dispatch, { id, params }) => ({
      dispatchLoad(e) {
        e.stopPropagation();
        dispatch(openModal('dispatchload', {
          load_id: id,
          user_id: params.id,
        }));
      },
    }),
  ),
  mapProps(({ id, params, ...rest }) => ({
    ...rest,
    id,
    params,
    detailsLocation: `/admin/dispatch/${params.id}/load/${id}`,
  })),
);

const DispatchLoadSearchTableItem = enhance(LoadSearchTableItem);
const DispatchLoadSearchMobileTableItem = enhance(LoadSearchMobile);

const DispatchLoadSearchTable = compose(
)(props =>
  <LoadSearchTable
    ListItem={DispatchLoadSearchTableItem}
    {...props}
  />
);

const DispatchLoadSearchResults = compose(
  getContext({
    params: PropTypes.object,
  }),
  connect(
    state => ({
      ids: state.search.load.ids,
      isFetching: state.search.load.isFetching,
      ordering: state.search.load.ordering,
      view: state.search.load.globals.view,
    }),
    (dispatch, { params: { id } }) => ({
      fetchNext: loadSearchActions.fetchNext,
      sort: loadSearchActions.sort,
      setView: loadSearchActions.setView,
      refresh: () => submit(`${FORM_NAME_PREFIX}${id}`),
    }),
  ),
)(props =>
  <SearchResults
    {...props}
    MobileComponent={DispatchLoadSearchMobileTableItem}
    DesktopComponent={DispatchLoadSearchTable}
    GoogleMap={LoadSearchMap}
    breakpoint={830}
    orderingOptions={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerType',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Deadhead',
        ordering: 'deadhead',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
      {
        displayName: 'Target Rate',
        ordering: 'targetRate',
        adjectives: ['Min', 'Max'],
      },
    ]}
  />
);

const DispatchLoadSearchForm = compose(
)(class extends React.Component {
  getWrappedInstance() {
    return this._ref.getWrappedInstance();
  }
  static contextTypes = {
    params: PropTypes.object,
  };
  render() {
    const { params: { id } } = this.context;
    return (
      <LoadSearchForm
        ref={ref => this._ref = ref}
        form={`${FORM_NAME_PREFIX}${id}`}
        {...this.props}
      />
    );
  }
});

export default compose(
  connect(
    null,
    (dispatch, { params: { id } }) => ({
      swapOriginAndDestination: loadSearchActions.swapOriginAndDestination,
      refresh: () => submit(`${FORM_NAME_PREFIX}${id}`),
    }),
  ),
)(({ swapOriginAndDestination, refresh }) =>
  <Search
    mountPoint={['search', 'load']}
    resource='load'
    Form={DispatchLoadSearchForm}
    SearchResults={DispatchLoadSearchResults}
    basePath='/loadsearch'
    swapOriginAndDestination={swapOriginAndDestination}
    refresh={refresh}
  />
);
