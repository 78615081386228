/* global APP_HAUL_PAY_URL fbq process */
import 'styles/FactoringSignUp';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm, submit } from 'redux-form';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import get from 'lodash/get';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import * as validate from 'helpers/validate';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';
import { openModal } from 'actions/ui';
import {
  getDataFromDOT,
  getDataFromMC,
  submitFactoringApprovalRequest,
} from 'actions/user';

import redirectIf from 'components/hoc/redirectIf';
import spinnerIf from 'components/hoc/spinnerIf';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import getSelf from 'selectors/getSelf';
import { CenteredSpinner } from 'components/pure/Spinner';
import ButtonOpenSearchFMCSA from 'components/container/ButtonOpenSearchFMCSA';
import IframeComm from 'react-iframe-comm';

const form_name = 'FactoringSignUpForm';
const selector = formValueSelector(form_name);

const populate_fields_given_mc_or_dot = get_data_fn => (mc_or_dot, dispatch) => get_data_fn(mc_or_dot)
  .then(data => {
    dispatch(change(form_name, 'dba', data.dba || data.name));
    dispatch(change(form_name, 'address', {
      street_one: data.street_one,
      city: data.city,
      state: data.state,
      zip: data.zip,
      country: data.country,
    }));
    dispatch(change(form_name, 'phone', data.phone));
    if (data.power_units) {
      dispatch(change(form_name, 'number_of_trucks', data.power_units.toString(10)));
    }
    /* Not using today, update when FMCSA backend call outputs this data pycomfreight#2184 */
    /*
    if (data.ein) {
      data.ein = data.ein.toString(10);
      dispatch(change(form_name, 'tax_id', `${data.ein.slice(0, 2)}-${data.ein.slice(2)}`));
    }
    */
    // dispatch(change(form_name, 'has_contract_authority_status', data.contractAuthorityStatus === 'A' || data.contractAuthorityStatus === 'ACTIVE'));
    // dispatch(change(form_name, 'has_common_authority_status', data.commonAuthorityStatus === 'A' || data.commonAuthorityStatus === 'ACTIVE'));
    // dispatch(change(form_name, 'has_broker_authority_status', data.brokerAuthorityStatus === 'A' || data.brokerAuthorityStatus === 'ACTIVE'));
    // dispatch(change(form_name, 'is_allowed_to_operate', data.allowedToOperate === 'Y'));
    // dispatch(change(form_name, 'state_incorporated', data.phyState));
  })
  .catch(err => console.warn(err))
  ;

const populate_fields = {
  mc: populate_fields_given_mc_or_dot(getDataFromMC),
  dot: populate_fields_given_mc_or_dot(getDataFromDOT),
};

const step_form_enhancer = compose(
  connect(
    state => {
      return {
        self: getSelf(state),
        has_factoring_company: selector(state, 'has_factoring_company'),
      };
    },
    {
      openModal,
    },
  ),
  mapProps(({ self, ...rest }) => {
    if (self) {
      let owners_name_first = '';
      let owners_name_last = '';
      const name_split = self.data.contact_name ? self.data.contact_name.split(/\W+/) : null;
      if (name_split) {
        owners_name_first = name_split[0];
        owners_name_last = name_split.slice(1).join(' ');
      }
      return {
        ...rest,
        type: self.data.type,
        mc: self.data.mc,
        dot: self.data.dot,
        initialValues: {
          mc: self.data.mc,
          dot: self.data.dot,
          address: {},
          owners_name_first,
          owners_name_last,
          owners_address: {
            street_one: self.data.address,
            city: self.data.city,
            state: self.data.state,
            zip: self.data.zip,
            country: self.data.country,
          },
          owners_phone_mobile: getPhoneParts(self.data.contact_phone).phone,
          owners_phone_mobile_ext: getPhoneParts(self.data.contact_phone).ext,
          owners_email: self.data.email,
          selected_number: !self.data.mc && self.data.dot ? MCorDOTInputs.DOT : MCorDOTInputs.MC,
        },
      };
    }
    return rest;
  }),
  connect(
    null,
    (dispatch, { mc, dot }) => {
      let auto_populate = null;
      if (mc) {
        auto_populate = () => populate_fields.mc(mc, dispatch);
      }
      else if (dot) {
        auto_populate = () => populate_fields.dot(dot, dispatch);
      }
      return { auto_populate };
    },
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.auto_populate) {
        this.props.auto_populate();
      }
    },
  }),
  reduxForm({
    form: form_name,
    destroyOnUnmount: false,
    validate(fields) {
      const errs = {};
      if (!fields.mc && !fields.dot && fields.selected_number === MCorDOTInputs.MC) {
        if (fields.selected_number === MCorDOTInputs.MC) {
          errs.mc = 'Please enter an MC# or DOT#';
        }
        else if (fields.selected_number === MCorDOTInputs.DOT) {
          errs.dot = 'Please enter an MC# or DOT#';
        }
      }
      if (!fields.owners_name_first) {
        errs.owners_name_first = 'Please enter first name';
      }
      if (!fields.owners_name_last) {
        errs.owners_name_last = 'Please enter last name';
      }
      if (!fields.owners_phone_mobile) {
        errs.owners_phone_mobile = 'Please enter Phone number';
      }
      if (!fields.owners_email) {
        errs.owners_email = 'Please enter email';
      }
      return errs;
    },
    asyncBlurFields: ['email', 'dot', 'mc'],
    async asyncValidate(fields, dispatch, props, blurredField) {
      switch (blurredField) {
        case 'dot':
          if (!fields.dot) {
            return;
          }
          return populate_fields.dot(fields.dot, dispatch);
        case 'mc':
          if (!fields.mc) {
            return;
          }
          return populate_fields.mc(fields.mc, dispatch);
      }
    },
  }),
);
const step_forms = [];
step_forms[0] = compose(
  step_form_enhancer,
)(({ handleSubmit }) =>
  <form onSubmit={handleSubmit}>
    <p className='text-danger'><b>Please make sure all info below is correct</b></p>
    <div>
      <ButtonOpenSearchFMCSA />
    </div>
    <MCorDOTInputs />
    <Field
      name='owners_name_first'
      component={LabeledInput}
      validate={validate.compose(
        validate.vjs.presence(),
      )}
      label='First Name *'
    />
    <Field
      name='owners_name_last'
      component={LabeledInput}
      validate={validate.compose(
        validate.vjs.presence(),
      )}
      label='Last Name *'
    />
    <div className='row'>
      <div className='col-md-12'>
        <Field
          name='owners_phone_mobile'
          component={PhoneInput}
          validate={validate.compose(validate.vjs.presence(), validate.phone())}
          label='Direct Phone *'
        />
      </div>
    </div>
    <Field
      name='owners_email'
      component={LabeledInput}
      validate={validate.compose(
        validate.vjs.presence(),
        validate.vjs.email(),
      )}
      label='Email Address *'
    />
    <div className='text-right'>
      <button className='btn btn-orange' type='submit'>Next</button>
    </div>
  </form>
);

step_forms[1] = compose(
  step_form_enhancer,
)(({ dispatch, prevPage, submitting, handleSubmit, has_factoring_company, type }) => {
  const imageCategories = type === 'broker' ? [
    'Proof of Bond',
    'Proof of Broker Authority',
    'Driver\'s License / ID of Owner',
    'Other',
  ] : undefined;

  return (
    <form onSubmit={handleSubmit}>
      <b>
        <p><u>Driver's License and Insurance</u></p>
        <p><span className='text-danger'>REQUIRED:</span> Please include a photo or file of your <u>driver's
          license</u> and a copy of <u>insurance.</u></p>
        <p><span className='text-success'>BONUS:</span> Please include a void check from your business bank account so we
          know where to send your payments!</p>
      </b>
      <Field
        name='attachments'
        component={MultiImageInput}
        limit={10}
        shouldCrop={true}
        buttonText='Add Documents'
        buttonClassName='btn btn-default'
        confirmBeforeDeleting={true}
        categories={imageCategories}
      />
      {/* Add Bank/Card modal on Client side on Sign up. */}
      {/* <button className='btn btn-default' type='button' onClick={() => dispatch(openModal('paymentinformation'))}> Add Bank Information </button> */}
      <p>You can take a photo with your phone or upload files from a computer.</p>
      <Field
        name='company_type'
        component={LabeledDropdown}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='Business Type'
        data={[
          'Corporation',
          'LLC',
          'Sole Proprietorship',
          'Partnership',
        ]}
      />
      <div>
        <Field
          name='has_factoring_company'
          component={CheckboxInput}
          label='I currently have a factoring company'
        />
      </div>
      {
        has_factoring_company ?
          <Field
            name='current_factoring_company'
            component={LabeledInput}
            validate={validate.compose(
              validate.vjs.presence(),
            )}
            label={'What\'s the name of your current factoring company?'}
          />
          : null
      }
      <hr />
      {
        submitting ?
          <CenteredSpinner />
          : null
      }
      <div className='text-right clearfix'>
        <button onClick={prevPage} className='btn btn-default pull-left' type='button'>Previous</button>
        {'\u00a0'}
        <button disabled={submitting} className='btn btn-orange btn-square' type='submit'>SUBMIT</button>
      </div>
      <div className='text-center' style={{ marginTop: '3em' }}>
        <b>Questions? (888) 633-5558</b>
      </div>
    </form>
  );
}
);

const steps = ['Pay Profile', 'Verification'];
let step = 0;
export const setStep = newStep => step = newStep;


const FactoringSignUp = compose(
  withState('step', 'setStep', () => step),
  lifecycle({
    componentWillUnmount() {
      step = 0;
    },
  }),
  connect(
    state => ({
      self: getSelf(state),
      token: state.user.token,
    }),
    dispatch => ({
      onReceiveMessage: message => {
        if (get(message, 'data', '') === 'reload') {
          window.location.reload();
        }
      },
      triggerSubmit: () => dispatch(submit(form_name)),
      submit: fields => {
        return dispatch(submitFactoringApprovalRequest({
          company_profile: {
            name: fields.dba,
            address: {
              street_one: fields.address.street_one,
              city: fields.address.city,
              state: fields.address.state,
              zip: fields.address.zip,
              country: fields.address.country,
            },
            phone_number: fields.phone,
            tax_id: fields.tax_id,
            mc: fields.mc,
            dot: fields.dot,
            state_incorporated: fields.state_incorporated,
            number_of_trucks: Number.parseInt(fields.number_of_trucks, 10) || null,
            current_factoring_company: fields.current_factoring_company,
            type: fields.company_type,
          },
          owner_profile: {
            name_first: fields.owners_name_first,
            name_last: fields.owners_name_last,
            address: {
              street_one: fields.owners_address.street_one,
              city: fields.owners_address.city,
              state: fields.owners_address.state,
              zip: fields.owners_address.zip,
              country: fields.owners_address.country,
            },
            phone_number_cell: normalizeFormPhone(fields, 'owners_phone_mobile'),
            email: fields.owners_email,
            tax_lien_status: fields.has_federal_tax_liens ? fields.has_tax_liens_payment_plan ? 'has_tax_lien_payment_plan' : 'has_tax_lien' : 'none',
          },
          signature: fields.signature,
          attachments: fields.attachments,
          has_contract_authority_status: fields.has_contract_authority_status,
          has_common_authority_status: fields.has_common_authority_status,
          has_broker_authority_status: fields.has_broker_authority_status,
          is_allowed_to_operate: fields.is_allowed_to_operate,
        }))
          .then(() => {
            dispatch(openModal('success', { message: 'Thank you for joining HaulPay Factoring.' }));
            window.analytics.track('SubmitHaulPayApp', { content_name: 'haul-pay' });
          })
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Error submitting for haul pay :(' }));
          })
          ;
      },
    }),
  ),
)(({ step, setStep, submit, triggerSubmit, onReceiveMessage, self: { data: { type = 'carrier', ...data }, ...self }, token }) =>
  <IframeComm
    attributes={{
      id: 'haulpay',
      width: '100%',
      height: '100%',
      marginHeight: '0',
      marginWidth: '0',
      frameBorder: '0',
      seamless: true,
      allowFullScreen: true,
      allowTransparency: true,
      src: `${APP_HAUL_PAY_URL}/signup/${type}/${token}`,
    }}
    handleReceiveMessage={onReceiveMessage}
  />
);

export default compose(
  connect(
    state => {
      const ret = {
        logged_in: state.user.logged_in,
        loading: state.user.isLoggingIn,
        fetching: state.user.factoring.isFetching,
      };
      const self = getSelf(state);
      if (self) {
        ret.status = self.data.factoring_approved;
      }
      return ret;
    },
    {},
  ),
  redirectIf([
    {
      if: ({ status, logged_in }) => (status && status !== USER_FACTORING_STATUS.UNAPPLIED) || !logged_in,
      to: '/haul-pay',
    },
  ]),
  spinnerIf(
    ({ fetching, loading }) => fetching || loading,
    {
      spinner: CenteredSpinner,
    },
  ),
)(FactoringSignUp);
