/**
 * Reducer for handling the qa by truck resource actions
 * @module reducers/resourceBy/truck/qa
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/truck/qa
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.truck.qa;

import qaByTruckResource from '../../../actions/resourceBy/truck/qa';


export default function qaByTruckResourceReducer(state = initialState, action) {
  state = qaByTruckResource.reduce(state, action);
  return state;
}
