import * as XLSX from 'xlsx'
import Papa from 'papaparse';

export const splitExcelFile = async (file, chunkSize = 10) => {
    const arrayBuffer = await file.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    const binaryString = String.fromCharCode(...data);
    const workbook = XLSX.read(binaryString, { type: 'binary' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

    const header = jsonData[0]; // Extract the header
    const dataRows = jsonData.slice(1).filter(row => !isEmptyExcelRow(row)); // Exclude the header and filter out empty rows

    const chunks = [];
    for (let i = 0; i < dataRows.length; i += chunkSize) {
        const chunk = [header, ...dataRows.slice(i, i + chunkSize)]; // Prepend the header to each chunk
        const newWorkbook = XLSX.utils.book_new();
        const newSheet = XLSX.utils.aoa_to_sheet(chunk);
        XLSX.utils.book_append_sheet(newWorkbook, newSheet, sheetName);
        const newFile = XLSX.write(newWorkbook, { type: 'array', bookType: 'xlsx' });
        chunks.push(new Blob([newFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
    }
    return chunks;
};

const isEmptyExcelRow = (row) => {
    return Object.values(row).every(cell => cell === null || cell === undefined || cell === '');
};

export const splitCsvFile = (file, chunkSize = 10) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const csvData = Papa.parse(reader.result, { header: false }).data;
            const header = csvData[0]; // Extract the header
            const dataRows = csvData.slice(1).filter(row => !isEmptyRow(row)); // Exclude the header and filter out empty rows

            const chunks = [];
            for (let i = 0; i < dataRows.length; i += chunkSize) {
                const chunk = [header, ...dataRows.slice(i, i + chunkSize)]; // Prepend the header to each chunk
                const csvChunk = Papa.unparse(chunk);
                chunks.push(new Blob([csvChunk], { type: 'text/csv' }));
            }
            resolve(chunks);
        };
        reader.onerror = (error) => {
            reject(error);
        };
        reader.readAsText(file);
    });
};

const isEmptyRow = (row) => {
    return row.every(cell => cell === null || cell === undefined || cell === '');
};

export const splitFile = async (file, chunkSize = 10) => {
    const fileType = file.type;

    if (fileType.includes('excel') || fileType.includes('spreadsheetml')) {
        return await splitExcelFile(file, chunkSize);
    } else if (fileType.includes('csv') || fileType.includes('text/csv')) {
        return await splitCsvFile(file, chunkSize);
    } else {
        throw new Error('Unsupported file type');
    }
};


