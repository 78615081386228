import React from 'react';
import PropTypes from 'prop-types';

import SortableTable from 'components/pure/SortableTable';
import TruckLoadMatchTableItem from 'components/container/TruckLoadMatchTableItem';


const TruckLoadMatchTable = ({ ids, ...rest }) =>
  <SortableTable
    {...rest}
    headers={[
      { text: 'Age', ordering: 'time_posted' },
      { text: 'D.H.', ordering: 'deadhead' },
      { text: 'Type', ordering: 'trailerType' },
      { text: 'Origin', ordering: 'origin_city' },
      { text: 'Destination', ordering: 'destination_city' },
      { text: 'Weight', ordering: 'weight' },
      { text: 'Length', ordering: 'length' },
      { text: 'Ready', ordering: 'time_start' },
      { text: 'Close', ordering: 'time_end' },
      { text: 'Deliver', ordering: 'time_delivery' },
      { text: 'More', ordering: false },
    ]}
    items={ids.map(id => ({ id }))}
    ListItem={TruckLoadMatchTableItem}
  />
  ;

TruckLoadMatchTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default TruckLoadMatchTable;
