import React from 'react';
import { ContextProvider } from './context/CardContext';
import OnboardingStripeCarrier from './OnboardingStripeCarrier';

function StripeCarrierCard(props) {
    return (
        <ContextProvider>
            <OnboardingStripeCarrier {...props} />
        </ContextProvider>
    )
}

export default StripeCarrierCard;