import React from 'react';
import { Card, CardTitle, CardText } from 'material-ui/Card';
import colors from 'styles/colors.json';

export default function AmountCard({  amount, title, subHeader, pendingAmount }) {
    const colorMapping = {
        RED: colors.RED,
        GREEN: colors.GREEN
    }
    return(
        <Card style={{ borderRadius: 10 }} containerStyle={{ minWidth: 300, textAlign: 'center', justifyContent: 'center'  }}>
            <CardTitle title={title} style={{ margin: 0, padding: 0 }}></CardTitle>/>
            <CardText color={colorMapping.GREEN} style={{ fontSize: 30, margin: 0, padding: 0 }}>
                {amount}
            </CardText>
            <p style={{ fontSize: 14, color: colors.GRAY_TEXT, margin: 0 }}>
                {subHeader}
            </p>
            {pendingAmount &&
                <div style={{ justifyContent: 'flex-end', display: 'flex', margin: 0}}>
                    <p style={{ fontSize: 14, color: colors.GRAY_TEXT, margin: 5 }}>
                        {`Pending: ${pendingAmount}`}
                    </p>
            </div>
            }
        </Card>
    )
}