import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import Header from 'components/pure/Header';
import CenteredColumn from 'components/pure/CenteredColumn';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import TruckMobile from 'components/pure/TruckMobile4';


const SavedTruckTableItem = compose(
  Resource('truck', { resourcePropName: 'truck' }),
)(TruckMobile);


export default compose(
  connect(
    state => ({ user_id: state.user.id }),
    {
      openModal,
    },
  ),
  ResourceBy('user', 'savedtruck', { idPropName: ['user_id'] }),
)(({ user_id, openModal, item: { isFetching, ids } = {}, actions: { fetchNext } }) =>
  <CenteredColumn
    xs={12}
    sm={10}
    className='col-fill'
    columnProps={{
      className: 'col-fill',
    }}
    >
    <Header style={{ flexGrow: 0 }}>Saved Trucks</Header>
    <NormalInfiniteScroll
      isFetching={isFetching}
      onScrollBottom={fetchNext}
      placeholder={<div className='help-block text-center'>No trucks saved (yet)</div>}
      >
      {!ids ? null : ids.map(id =>
        <SavedTruckTableItem
          key={id}
          id={id}
          unSave={() => openModal('confirm', {
            type: 'savedtruck_delete',
            args: [user_id, id, { truck: id }],
          })}
        />
      )}
    </NormalInfiniteScroll>
  </CenteredColumn>
);
