import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import validate from 'validate.js';
import get from 'lodash/get';

import formatPennies from 'helpers/formatPennies';
import { closeModal, openModal, unshiftModal } from 'actions/ui';
import { dispatchLoadConfirm } from 'actions/admin/dispatch';

import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import DispatchPricingTable from 'components/pure/DispatchPricingTable';


const FORM_NAME = 'DISPATCH_LOAD_FORM';

export default compose(
  Resource('user', {
    idPropName: ['user_id'],
  }),
  spinnerIfFetching(),
  connect(
    (state, { load_id }) => ({
      load: state.resource.load[load_id],
      amount: formValueSelector(FORM_NAME)(state, 'amount'),
    }),
    {
      closeModal,
    },
  ),
  Resource('map', {
    resourcePropName: 'map',
    idPropName: ({ load }) => ({
      origin: [load.data.origin_address, load.data.origin_city, load.data.origin_state, load.data.origin_country].filter(Boolean).join(' '),
      destination: [load.data.destination_address, load.data.destination_city, load.data.destination_state, load.data.destination_country].filter(Boolean).join(' '),
    }),
  }),
  mapProps(({ item, map, load_id, ...rest }) => {

    const ret = {
      ...rest,
      map,
      user_name: get(item, ['data', 'dba']),
      initialValues: {
        amount: 2500,
        confirm: false,
      },
      async onSubmit(fields, dispatch) {
        try {
          await dispatch(dispatchLoadConfirm(load_id, fields.amount));
          dispatch(openModal('success', { message: 'Dispatched load for user.' }));
          dispatch(closeModal());
        }
        catch (err) {
          console.warn(err);
          dispatch(unshiftModal('error', { message: `Error dispatching load: ${err}` }));
        }
      },
    };

    const distance = get(map, ['data', 'routes', '0', 'legs', '0', 'distance']);
    if (distance) {
      ret.initialValues = {
        ...ret.initialValues,
        amount: DispatchPricingTable.getPricingForDistance(distance),
      };
    }

    return ret;
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate(fields) {
      return validate(fields, {
        amount: {
          numericality: {
            onlyInteger: true,
            greaterThanOrEqualTo: 0,
          },
        },
        confirm: {
          isTrue: true,
        },
      });
    },
  }),
)(class DispatchLoadModal extends React.Component {
  state = {
    confirmed_amount: null,
    is_confirming: false,
  };
  submit = this.submit.bind(this);

  submit(e) {
    const { is_confirming } = this.state;
    const { handleSubmit, amount } = this.props;

    if (!is_confirming) {
      this.setState({
        is_confirming: true,
        confirmed_amount: amount,
      });
      return;
    }
    return handleSubmit(e);
  }

  render() {
    const { map, user_name, submitting, closeModal } = this.props;
    const { is_confirming, confirmed_amount } = this.state;

    const distance = get(map, ['data', 'routes', '0', 'legs', '0', 'distance']);

    return (
      <div>
        <div className='modal-header'>
          <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
          <h4 className='modal-title'>Dispatch Load for {user_name}</h4>
        </div>
        <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
          {
            distance ?
              <div>
                <DispatchPricingTable />
                <p>Trip distance: <b>{distance.text}</b></p>
                <p>Suggested Charge: <b>{formatPennies(DispatchPricingTable.getPricingForDistance(distance))}</b></p>
              </div>
              :
              <p>Loading trip distance...</p>
          }
          <Field name='amount' component={PennyInput} label='Charge amount' />
          {
            is_confirming ?
              <div className='alert alert-warning'>
                <Field name='confirm' component={CheckboxInput} /> Dispatch this load and charge {formatPennies(confirmed_amount)} to this user.
              </div>
              : null
          }
        </div>
        <div className='modal-footer'>
          <button className='btn btn-default' onClick={closeModal}>Cancel</button>
          <button className='btn btn-orange' disabled={submitting} onClick={this.submit}>Submit</button>
        </div>
      </div>
    );
  }
});
