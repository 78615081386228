import React from 'react';
import { Link } from 'react-router-v4';

import formatPennies from 'helpers/formatPennies';
import get from 'lodash/get';


export default ({ items }) =>
    <div>
        <table className="table" style={{ fontSize: 'smaller' }}>
            <thead>
                <tr>
                    <th>Invoice#</th>
                    <th>Carrier</th>
                    <th>Debtor</th>
                    <th>Invoice Amnt.</th>
                    <th>Link to Invoice</th>
                </tr>
            </thead>
            {items.map((item, index) => {
                return (
                    <tbody key={`row_${index}`}>
                        <tr>
                            {[
                                item.invoice_number,
                                get(item, 'payment_profile.company_name', '-'),
                                get(item, 'debtor.dba', '-'),
                                formatPennies(item.amount),
                                <Link target="_blank" to={`/haul-pay/${item.id}/transaction/`}>
                                    <button className='btn btn-orange'>More</button>
                                </Link>,
                            ].map((columnData, j) => (
                                <td key={`col_${index}_${j}`}>{columnData}</td>
                            ))}
                        </tr>
                    </tbody>
                );
            })}
        </table>
    </div>

