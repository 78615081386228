/**
 * Actions relating to factoringdebtor search
 * @module actions/search/factoringdebtor
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringdebtor
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import factoringdebtorResource from 'actions/resource/factoringdebtor';


/**
 * Our factoringdebtor search [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const factoringdebtorSearchPaginatedResource = new PaginatedResource('search_factoringdebtor', {
  mountPoint: ['search', 'factoringdebtor'],
  url: new UrlAssembler('admin/debtors'),
  baseResource: factoringdebtorResource,
});

export default factoringdebtorSearchPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = factoringdebtorSearchPaginatedResource;
