/**
 * Reducer for handling the savedload by user resource actions
 * @module reducers/resourceBy/user/savedload
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/user/load
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.user.savedload;

import savedloadByUserResource from 'actions/resourceBy/user/savedload';


export default function savedloadByUserResourceReducer(state = initialState, action) {
  state = savedloadByUserResource.reduce(state, action);
  return state;
}
