/* global google */
import parseGooglePlace from './parseGooglePlace';


const geocoder = new google.maps.Geocoder();

/**
  @function
  @param {number} lat - the latitude of the location to reverse geocode
  @param {number} lng - the longitude of the location to reverse geocode
  @returns {[GeocoderResult]{@link https://developers.google.com/maps/documentation/javascript/3.exp/reference#GeocoderResult}}
 */
const getReverseGeocode = (lat, lng) => {
  return new Promise((resolve, reject) => {
    try {
      geocoder.geocode(
        {
          location: { lat, lng },
        },
        (results, status) => {
          if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
            return resolve([]);
          }
          if (status !== google.maps.GeocoderStatus.OK) {
            return reject(new Error(status));
          }
          resolve(results.map(parseGooglePlace));
        },
      );
    }
    catch (err) {
      console.warn(err);
      reject(err);
    }
  });
};

export default getReverseGeocode;
