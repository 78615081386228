import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';
import validate from 'validate.js';
import { memoize } from 'lodash';

import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import Resource from 'components/hoc/Resource';

import FetchError from 'datatypes/FetchError';
import { openModal } from 'actions/ui';
import { updateDebtorClientRelationship } from 'actions/admin/factoring/client';

const FORM_NAME = 'ClientDebtorNOAForm';

const Wrapper = props => <div style={{ border: '1px solid #ddd', borderRadius: 4, padding: 6 }} {...props} />;

const momentTime = memoize(time => moment(time));

const ClientDebtorNOAForm = ({ currentValues: { noa_placed, noa_active }, relation, submitting, handleSendNOAClick, handleSubmit }) => {
  const inputDatesInvalid = noa_placed && noa_active && moment(noa_placed).isAfter(noa_active);
  const noaDatesInvalid = relation.noa_placed && relation.noa_active && moment(relation.noa_placed).isAfter(relation.noa_active);
  return (
    <Wrapper>
      <h4>NOA Summary</h4>
      <form>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>{relation.noa_sent ? `Sent ${moment(relation.noa_sent).format('l LTS')}` : 'NOA not sent'}</div>
          <div style={{ marginLeft: 'auto' }}>
            {!relation.noa_sent && (
              <button
                type='button'
                className='btn btn-orange'
                onClick={handleSendNOAClick}
                disabled={inputDatesInvalid || submitting}
                style={{
                  marginTop: 8,
                  marginLeft: 8,
                }}
                >
                Send NOA
              </button>
            )}
            <button
              type='button'
              className='btn btn-orange'
              disabled={inputDatesInvalid || submitting}
              onClick={handleSubmit}
              style={{
                marginTop: 8,
                marginLeft: 8,
              }}
              >
              Update NOA dates
            </button>
          </div>
        </div>
        <div className='row' style={{ marginTop: '1em' }}>
          <div className='col-md-6'>
            <Field name='noa_placed' component={DateTimeInput} pickerOptions={{ time: false }} label='NOA Placed' />
          </div>
          <div className='col-md-6'>
            <Field
              name='noa_active'
              component={DateTimeInput}
              pickerOptions={{ time: false, min: noa_placed ? moment(noa_placed).toDate() : undefined }}
              label='NOA Active'
            />
          </div>
        </div>
      </form>
      {inputDatesInvalid && <p className='text-danger'>NOA Active must be later than NOA Placed</p>}
      {noaDatesInvalid && <p className='text-warning'>WARNING: NOA Active must be later than NOA Placed</p>}
    </Wrapper>
  );
};

const ClientDebtorNOAConnectedForm = compose(
  connect((state, ownProps) => ({
    ...ownProps,
    currentValues: {
      noa_placed: formValueSelector(FORM_NAME)(state, 'noa_placed'),
      noa_active: formValueSelector(FORM_NAME)(state, 'noa_active'),
    },
  }), (dispatch, { relation, refetch, fundingRequestId }) => ({
    handleSendNOAClick: () => {
      dispatch(openModal('placenoa', {
        debtor_id: relation.debtor,
        client_id: relation.factoring,
        funding_request: fundingRequestId,
        onSent: refetch,
      }));
    },
  })),
  mapProps(({ relation, ...rest }) => {
    return {
      ...rest,
      relation,
      initialValues: {
        noa_placed: relation.noa_placed ? momentTime(relation.noa_placed) : undefined,
        noa_active: relation.noa_active ? momentTime(relation.noa_active) : undefined,
      },
    };
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate(fields) {
      return validate(fields, {
        noa_active: {
          creldatetime: {
            earliest: 'noa_placed',
          },
        },
      });
    },
    async onSubmit(fields, dispatch, { relation }) {
      const diff = {};
      Object.keys(fields).forEach(key => {
        const value = fields[key];
        const dataValue = relation[key];
        if (value !== dataValue) {
          diff[key] = value;
        }
      });
      try {
        await dispatch(updateDebtorClientRelationship({
          debtor_id: relation.debtor,
          client_id: relation.factoring,
          ...diff,
        }));
        dispatch(openModal('success', { message: 'Updated NOA dates.' }));
      }
      catch (err) {
        console.warn(err);
        if (err instanceof FetchError && err.status === 400) {
          try {
            const json = JSON.parse(err.message);
            if (json && json[0]) {
              dispatch(openModal('error', { message: json[0] }));
              return;
            }
          }
          catch (e) {}
        }
        if (err.message && err.minimum_fee) {
          const message = err.message.minimum_fee[0];
          dispatch(openModal('warning', { message: `${message}\n Contact Super Admin for any other values.` }));
        }
        else {
          dispatch(openModal('error', { message: 'Failed to update NOA dates.' }));
        }
      }
    },
  })
)(ClientDebtorNOAForm);

const ClientDebtorNoaContainer = Resource('factoringpaymentrelation', {
  idPropName: ({ debtorId, factoringId }) => ({
    debtorId,
    factoringId,
  }),
})(({ item, refetchResource, fundingRequestId }) => {
  if (!get(item, 'data.id')) {
    return null;
  }
  if (get(item, 'isFetching')) {
    return (
      <Wrapper>
        <h4>NOA Summary</h4>
        <p>Loading...</p>
      </Wrapper>
    );
  }
  if (!get(item, 'isFetching') && !get(item, 'data.id') && get(item, 'fetchedAt') || !get(item, 'data.relationship', true)) {
    return (
      <Wrapper>
        <h4>NOA Summary</h4>
        <p className='text-warning'>Warning: Client-Debtor relation doesn't exist</p>
      </Wrapper>
    );
  }
  return <ClientDebtorNOAConnectedForm refetch={refetchResource} relation={item.data} fundingRequestId={fundingRequestId} />;
});

ClientDebtorNoaContainer.propTypes = {
  debtorId: PropTypes.string.isRequired,
  factoringId: PropTypes.string.isRequired,
};

export default ClientDebtorNoaContainer;
