import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import LoadPost from 'components/container/LoadPost';
import LoadPostHistory from 'components/container/LoadPostHistory';

const LoadsContainer = props => {
  if (props.history) {
    return <LoadPostHistory {...props} displayHistoryButton={true} />;
  }
  return <LoadPost {...props} />;
};

export default compose(connect(
  state => ({ history: get(state, 'post.load.history', false) }),
  null)
)(LoadsContainer);
