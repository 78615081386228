import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reduxForm, formValueSelector, isSubmitting } from 'redux-form';
import { openModal, closeModal } from 'actions/ui';
import ModalBody from 'components/pure/ModalBody';
import { values, isEmpty } from 'lodash';
import bankinfoPaginatedResource from 'actions/admin/factoring/bank';
import { deletePaymentMethod } from 'actions/paymentmethods';
import PaymentMethodsForm from '../form/PaymentMethodsForm';
import PaymentInformationForm from '../form/PaymentInformationForm';
import lifecycle from 'recompose/lifecycle';
import ModalFooter from 'components/pure/ModalFooter';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';

const Button = ({ children, onClick }) => (
  <button className='btn btn-default' type='button' onClick={onClick}>{children}</button>
);

const selector = formValueSelector('PaymentInformation');

const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-blue'>{title}</h4>
);

const Row = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    }}
    >
    { children }
  </div>
);

const PaymentOption = ({ name, time, onClick, submitting }) => (
  <div>
    <Row>
      <div>
        <span className='text-success'>{name}</span>
      </div>
      <div className='text-right'>
        <span>{time}</span>
      </div>
    </Row>
    <Row>
      <button className='btn btn-orange pull-right' type='submit' disabled={submitting} onClick={onClick}>Choose this method</button>
    </Row>
  </div>
);

const paymentMethodsType = {
  DEBIT: 'debit',
  ACH: 'ach',
  WIRE: 'wire',
};

export default compose(
  connect(
    state => ({
      accountType: selector(state, 'accountType'),
      ids: state.admin.factoring.bank.ids,
      isFetching: state.admin.factoring.bank.isFetching,
      banks: state.resource.getbankinfo,
      currentOrder: state.admin.factoring.bank.ordering,
      submitting: isSubmitting('PaymentMethods')(state),
      initialValues: (() => {
        const defaultBank = values(state.resource.getbankinfo).filter(bank => bank.data.default === true);
        const initial_values = {};

        defaultBank.map(({ data: bank }) => {
          initial_values[`${paymentMethodsType[bank.method]}_account_id`] = bank.id;
        });
        return initial_values;
      })(),
    }),
    (dispatch, { item }) => ({
      closeModal() {
        dispatch(closeModal());
      },
      openAddCardInfoModal: fields => {
        dispatch(closeModal());
        dispatch(openModal('addcardinfo', { fields }));
      },
      openAddBankInfoModal: fields => {
        dispatch(closeModal());
        dispatch(openModal('addbankinfo', { fields }));
      },
      openAddWireInfoModal: fields => {
        dispatch(closeModal());
        dispatch(openModal('addwireinfo', { fields }));
      },
      update() {
        dispatch(bankinfoPaginatedResource.clear());
        dispatch(bankinfoPaginatedResource.fetch({
          id: item.data.user
        }));
      },
      fetchNext: (...args) => dispatch(bankinfoPaginatedResource.fetchNext(...args)),
      sort: (...args) => dispatch(bankinfoPaginatedResource.sort(...args)),
      deletePaymentMethod: (...args) => dispatch(deletePaymentMethod(...args)),
    }),
  ),
  lifecycle({
    async componentDidMount() {
      await this.props.update();
    },
  }),
)(({
  item,
  closeModal,
  openAddCardInfoModal,
  openAddBankInfoModal,
  openAddWireInfoModal,
  banks,
  ids,
  isFetching,
  fetchNext,
  currentOrder,
  sort,
  deletePaymentMethod,
  update,
  paymentRefForm = {},
  submitting,
  initialValues,
}) =>
  <ModalBody closeModal={closeModal}>
    <ModalTitle title='Payment Information' />
    <hr />
    <PaymentInformationForm
      item={item}
      openAddCardInfoModal={openAddCardInfoModal}
      openAddBankInfoModal={openAddBankInfoModal}
      openAddWireInfoModal={openAddWireInfoModal}
    />
    {!isEmpty(banks) &&
      <PaymentMethodsForm
        item={item}
        banks={banks}
        ids={ids}
        isFetching={isFetching}
        fetchNext={fetchNext}
        currentOrder={currentOrder}
        sort={sort}
        deletePaymentMethod={deletePaymentMethod}
        update={update}
        closeModal={closeModal}
        ref={ref => paymentRefForm = ref}
        initialValues={initialValues}
        accountsValue={initialValues}
        >
        <ModalFooter>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <BigOrangeSubmitButton submitting={submitting} message='Set As Default' />
        </ModalFooter>
      </PaymentMethodsForm>
    }
  </ModalBody>
);
