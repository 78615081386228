/**
 * Component to display the top navigation menu while logged out. Also used on the marketing pages
 * @module components/pure/LoggedOutMenu
 * @since 3.0.0
 * @requires components/pure/Hamburger
 * @requires components/pure/FallbackLink
 * @property {object} props
 * @property {string} [props.brand_href]
 * @property {string} [props.marketing_base_href]
 * @property {string} [props.login_href]
 * @property {string} [props.signup_href]
 */
/* global MARKETING_BASE_HREF */
import 'styles/LoggedOutMenu';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import Hamburger from 'components/pure/Hamburger';
import FallbackLink from 'components/pure/FallbackLink';
// import Dropdown from '@comfreight/app/src/js/components/pure/Dropdown';
import { Appbar, Row, Col } from 'muicss/react';
import FlatButton from 'material-ui/FlatButton';
import Drawer from 'material-ui/Drawer';

class LoggedOutMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  static propTypes = {
    brand_href: PropTypes.string,
    marketing_base_href: PropTypes.string,
    login_href: PropTypes.string,
    signup_href: PropTypes.string,
  };

  toggleOpen(e) {
    this.setState({ open: !this.state.open });
  }
  Menu({ marketing_base_href, blog_href }) {
    return (
      <ul>
        <li ><FallbackLink to={`${marketing_base_href}load-board`}>LoadBoard</FallbackLink></li>
        <li ><FallbackLink to={'https://haulpay.co'}>Payments</FallbackLink></li>
        <li ><FallbackLink to={`${marketing_base_href}integration`}>Integration</FallbackLink></li>
        <li ><FallbackLink to={`${marketing_base_href}about-us`}>About Us</FallbackLink></li>
        <li ><FallbackLink to={`${blog_href}`}>Blog</FallbackLink></li>
      </ul>
    );
  }
  render() {
    const {
      brand_href = '/public/image/logo_medium.png',
      marketing_base_href = MARKETING_BASE_HREF,
      login_href = '/login',
      signup_href = '/signup',
      blog_href = '//blog.comfreight.com',
    } = this.props;
    const { open } = this.state;
    return (
      <div>
        <Drawer open={open} openSecondary={true} width='100%' >
          <div className='VerticalMenu' style={{ paddingTop: 70 }}>
            <Row>
              {/*<this.Menu marketing_base_href={marketing_base_href} blog_href={blog_href} />*/}
            </Row>
            <Row>
              <Col xs={10} xs-offset={1} className='mui--text-right' style={{ paddingBottom: 30, paddingTop: 30 }} >
                <FlatButton
                  style={{
                    marginRight: 20,
                    borderRadius: '24px !important',
                    border: '1px solid !important',
                  }}
                  labelStyle={{
                    textTransform: 'capitalize',
                    fontSize: 'calc(18px + (22 - 18) * ((100vw - 332px) / (1920 - 332)))',
                    fontWeight: 'bold',
                    padding: '0 20px',
                  }}
                  fullWidth={true}
                  className='roundButton'
                  label='Login'
                  secondary={true}
                  color='accent'
                  disableTouchRipple={true}
                  href={login_href}
                />
              </Col>
              <Col xs={10} xs-offset={1} className='mui--text-right'>
                <FlatButton
                  style={{
                    borderRadius: '24px !important',
                    border: '1px solid !important',
                  }}
                  fullWidth={true}
                  labelStyle={{
                    textTransform: 'capitalize',
                    fontSize: 'calc(18px + (22 - 18) * ((100vw - 332px) / (1920 - 332)))',
                    fontWeight: 'bold',
                    padding: '0 20px',
                  }}
                  className='roundButton'
                  primary={true}
                  color='primary'
                  label='Sign Up'
                  disableTouchRipple={true}
                  href={signup_href}
                />
              </Col>
            </Row>
          </div>
        </Drawer>
        <Appbar className='AppBar'>
          <div className='desktopHeader'>
            <Row >
              <Col xs={3} className='mui--appbar-height'>
                <div className='mui--appbar-height' style={{ width: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <FallbackLink to={`${marketing_base_href}`}>
                    <img className='logo' src={brand_href} width={200}/>
                  </FallbackLink>
                </div>
              </Col>
              <Col xs={6} className='mui--text-right'>
                {/*<div className='mui--appbar-height menu' style={{ width: '100%', display: 'flex', flex: 1, flexBasis: 'auto', justifyContent: 'flex-end', alignItems: 'center' }} >*/}
                {/*  <this.Menu marketing_base_href={marketing_base_href} blog_href={blog_href} />*/}
                {/*</div>*/}
              </Col>
              <Col md={2} lg={3} className='mui--text-right' >
                <div className='mui--appbar-height' style={{ paddingRight: 20, display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <FlatButton
                    style={{
                      marginRight: 20,
                      borderRadius: '24px !important',
                      border: '1px solid !important',
                    }}
                    labelStyle={{
                      textTransform: 'capitalize',
                      fontSize: 'calc(10px + (22 - 10) * ((100vw - 960px) / (1920 - 960)))',
                      fontWeight: 'bold',
                      padding: '0 20px',
                    }}
                    className='roundButton'
                    label='Login'
                    size='small'
                    secondary={true}
                    color='accent'
                    disableTouchRipple={true}
                    href={login_href}
                  />
                  <FlatButton
                    style={{
                      borderRadius: '24px !important',
                      border: '1px solid !important',
                    }}
                    size='small'
                    labelStyle={{
                      textTransform: 'capitalize',
                      fontSize: 'calc(10px + (22 - 12) * ((100vw - 960px) / (1920 - 960)))',
                      fontWeight: 'bold',
                      padding: '0 20px',
                    }}
                    className='roundButton'
                    primary={true}
                    color='primary'
                    label='Sign Up'
                    disableTouchRipple={true}
                    href={signup_href}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className='mobileHeader'>
            <Row className='mui--appbar-height'>
              <Col xs={8} xs-offset={1} className='mui--appbar-height'>
                <div className='mui--appbar-height' style={{ width: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <FallbackLink to={`${marketing_base_href}`}>
                    <img className='logo' src={brand_href} width={200} />
                  </FallbackLink>
                </div>
              </Col>
              <Col xs={3} className='mui--appbar-height'>
                <div className='mui--appbar-height' style={{ width: '100%', display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                  <Hamburger onClick={this.toggleOpen} active={open} />
                </div>
              </Col>
            </Row>
          </div>
          {/*<div className='container'>*/}
          {/*  <div className='navbar-header'>*/}
          {/*    <div className='LoggedOutMenu__hamburger'>*/}
          {/*      <Hamburger onClick={this.toggleOpen} active={open} />*/}
          {/*    </div>*/}
          {/*    <FallbackLink to={marketing_base_href}>*/}
          {/*      <img className='LoggedOutMenu__logo navbar-brand' src={brand_href} />*/}
          {/*    </FallbackLink>*/}
          {/*    <FallbackLink className='LoggedOutMenu__login visible-xs-inline-block' to={login_href}>*/}
          {/*      <button className='btn btn-default login-btn'>Login</button>*/}
          {/*    </FallbackLink>*/}
          {/*  </div>*/}
          {/*  <div className={cs('collapse navbar-collapse', { in: open })}>*/}
          {/*    <div className='navbar-right'>*/}
          {/*      <ul className='nav navbar-nav'>*/}
          {/*        <Dropdown*/}
          {/*          items={[*/}
          {/*            <FallbackLink className='text-uppercase' to={`${marketing_base_href}shippers`}>For Shippers</FallbackLink>,*/}
          {/*            <FallbackLink className='text-uppercase' to={`${marketing_base_href}carriers`}>For Carriers</FallbackLink>,*/}
          {/*            <FallbackLink className='text-uppercase' to={`${marketing_base_href}brokers`}>For Brokers</FallbackLink>,*/}
          {/*            <FallbackLink className='text-uppercase' to={`${marketing_base_href}freight-api`}>Integrate</FallbackLink>,*/}
          {/*            <FallbackLink className='text-uppercase' to={`https://haulpay.co`}>Haul Pay</FallbackLink>*/}
          {/*          ]}*/}
          {/*          >*/}
          {/*          <a role='button' className='text-uppercase'>Learn More +</a>*/}
          {/*        </Dropdown>*/}
          {/*        <li onClick={this.toggleOpen}><FallbackLink className='text-uppercase' to={login_href}>Login</FallbackLink></li>*/}
          {/*        <li onClick={this.toggleOpen}><FallbackLink className='text-uppercase' to={signup_href} style={{ padding: 0 }}><div className={/haul-pay\/?$/.test(location.pathname) ? undefined : 'btn-orange'} style={{ padding: '0 15px' }}>Sign Up</div></FallbackLink></li>*/}
          {/*      </ul>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </Appbar>
      </div>
    );
  }
}

export default LoggedOutMenu;
