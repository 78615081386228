import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';

import Resource from 'components/hoc/Resource';


export default compose(
  Resource('usersearch'),
)(({ item }) => {

  const is_dispatch_enabled = item.data.groups ? item.data.groups.find(group => group.name === 'Admin Dispatch') : null;

  if (!is_dispatch_enabled) {
    return null;
  }

  return (
    <Link to={`/admin/dispatch/${item.data.id}`}><button className='btn btn-info pull-right'>Dispatch</button></Link>
  );
});
