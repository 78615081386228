/* global fbq process*/
import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { get, concat, map, compact, isEmpty } from 'lodash';
import { reduxForm, Field, formValueSelector, change, destroy } from 'redux-form';
import moment from 'moment';
import DropdownList from 'react-widgets/lib/DropdownList';
import { openModal } from 'actions/ui';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import { getGroupInvited, getBrokerGroupCarriers, getNextBrokerGroupCarriers, updatePaymentsProfile } from 'actions/groups';
import { fetchTerms, updateFactoringCarrier } from 'actions/factoring';
import { edit, fetch } from 'actions/resource/factoringclient';
import createTableItems from 'helpers/createTableItems';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import Header from 'components/pure/Header';
import groups from 'actions/admin/factoring/groups';
import { inviteToGroup } from 'actions/invites';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import getSelf from 'selectors/getSelf';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import cs from 'classnames';
import Toggle from 'material-ui/Toggle';
import FMCSALink from '../pure/FMCSALink';
import getBrokerTerms from '../../selectors/getBrokerTerms';
import find from 'lodash/find';
import MaterialIcon from '../pure/MaterialIcon';
import CircleSpinner from '../pure/CircleSpinner';
import Tooltip from 'components/pure/Tooltip';
import segmentEvents from '../../helpers/segmentEvents';

const BrokerPortion = props => {
  const data = [{
    text: '0%',
    value: 0,
  },
  {
    text: '50%',
    value: 50,
  },
  {
    text: '100%',
    value: 100,
  }];

  const ListItem = ({ item: { text } }) => (
    <div><span>{text}</span></div>
  );
  return (
    <Field
      component={LabeledDropdown}
      style={{
        minWidth: '4em',
        marginLeft: '5px',
      }}
      data={data}
      textField='text'
      valueField='value'
      itemComponent={ListItem}
      {...props}
    />
  );
};

const PaymentSpeed = props => {

  return (
    <Field
      component={LabeledDropdown}
      style={{
        minWidth: '4em',
        marginLeft: '5px',
      }}
      data={props.items}
      textField='label'
      valueField='value'
      {...props}
    />
  );
};

const options = [
  {
    text: 'Carrier Name',
    value: 'carrier_dba',
  },
  {
    text: 'Status',
    value: 'status',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'MC #',
    value: 'mc',
  },
  {
    text: 'DOT #',
    value: 'dot',
  },
  // {
  //   text: 'Tax ID #',
  //   value: 'tax_id',
  // },
  {
    text: 'Phone',
    value: 'phone',
  },
];

const FactoringCarriersItems = createTableItems(
  ({ data, isAljex, navigateTo, updateDiscountRate, setDiscountRate, openModal, carrierDiscountRate, submitting, enableDiscount, handleSubmit, change, formattedItems, setUpdatedCarrierRate, setPaymentSpeed, factoringCompanyPaymentTerm }) => {
    const email = get(data, 'payment_profile.owner_email', undefined);
    const status_date = get(data, 'status_date', undefined);
    const companyName = get(data, 'payment_profile.company_name', undefined);
    const phoneNumber = get(data, 'payment_profile.owner_phone_number_cell',
      get(data, 'payment_profile.owner_phone_number_home', get(data, 'payment_profile.company_phone_number', undefined)));
    const mc = get(data, 'payment_profile.company_mc', undefined);
    const dot = get(data, 'payment_profile.company_dot', undefined);
    const tax = get(data, 'payment_profile.company_tax_id', undefined);
    const status = get(data, 'payment_profile.status', undefined);
    const factoring_company_name = get(data, 'payment_profile.factoring_company.name', undefined);
    const isRequiredCoBrokeringAgreement = ['BROKER_AUTH'].includes(get(data, 'payment_profile.authority', ''));
    const isApprovedCoBrokering = get(data, 'co_brokering_approval', '') === 'APPROVED';
    const isInReviewCoBrokering = get(data, 'co_brokering_approval', '') === 'NEEDS_REVIEW';
    const isDeclinedCoBrokering = get(data, 'co_brokering_approval', '') === 'DECLINED';
    const attachments = get(data, 'attachments', []);
    const isRequiredCoBrokerDocs = isEmpty(find(attachments,
                                      ['category',"Signed co-broker agreement"]));
    const pendingTask = paymentProfile => {
      const pendingTaskArray = [
        { value: "needs_account_info", text: "Need Acct. info" },
        { value: "needs_factor_info", text: "Need Factor Info" },
        { value: "needs_noa", text: "Need NOA" },

        { value: "needs_verified_identity", text: "Verifying Bank Acct or NOA" }

      ];
      const pendingTaskObject = compact(map(pendingTaskArray, item => {
        if (paymentProfile[item.value]) {
          return item;
        }
      }));
      if (!pendingTaskObject.length) {
        return <div>
          <p>No Pending Approval Tasks</p>
        </div>
      }
      return (
        <div>
          <p>Pending Approval Tasks</p>
          {pendingTaskObject.map(item => <div>
            <span>{`- ${get(item, "text")}`}</span>
          </div>)}
        </div>
      )
    }
    return [
      // (() => {
      //   switch (status) {
      //     case 'approved':
      //       return <b><span className='text-success'>Approved</span></b>;
      //     case 'declined':
      //       return <b><span className='text-danger'>Declined</span></b>;
      //     case 'pending':
      //     default:
      //       return <b><span className='text-orange'>Pending</span></b>;
      //   }
      // })(),
      email,
      <div style={{ display: 'flex', gap: 5, flexDirection: 'column' }}>
        <a onClick={() => openModal('PaymentProfile', { paymentProfile: data.payment_profile })}>{companyName}</a>
        {factoring_company_name &&
          <p className='text-orange'>{factoring_company_name.toUpperCase()}</p>
        }
        {(isRequiredCoBrokeringAgreement && isRequiredCoBrokerDocs && !isApprovedCoBrokering) &&
            <Tooltip
                placement="top"
                text="This IC is a broker, a co-brokering agreement & shipper acknowledgment is required in order to approve a FR"
            >
                <a onClick={() => openModal('CoBrokeringUploadDocs', { attachments: data.attachments, relationshipId: data.id })}>
                    <span className="text-info">Docs Required</span>
                </a>
            </Tooltip>
        }
        {isApprovedCoBrokering &&
            <a onClick={() => openModal('CoBrokeringUploadDocs', { attachments: data.attachments, relationshipId: data.id, canUploadNew: false })}>
                <span className="text-success">Approved Co-Broker</span>
            </a>
        }
        {isInReviewCoBrokering &&
            <a onClick={() => openModal('CoBrokeringUploadDocs', { attachments: data.attachments, relationshipId: data.id })}>
                <span className="text-warning">Co-Broker Agreement Under Review</span>
            </a>
        }
        {isDeclinedCoBrokering &&
            <a onClick={() => openModal('CoBrokeringUploadDocs', {
              attachments: data.attachments,
              relationshipId: data.id
            })}>
                <span className="text-danger">Co-Broker Declined</span>
            </a>
        }
      </div>,
        phoneNumber,
        <FMCSALink mc={mc}>
            <p className={'text-blue'}>{mc}</p>
        </FMCSALink>,
        <FMCSALink dot={dot}>
            <p className={'text-blue'}>{dot}</p>
        </FMCSALink>,
      <PaymentSpeed
        name='payout_days'
        items={formattedItems}
        onChange={(e, value) =>
          setPaymentSpeed(data.id, value)
        }
      />,
      <BrokerPortion
        name='broker_percentage_rate'
        onChange={(e, value) =>
          setDiscountRate(data.id, value)
        }
        disabled={Boolean(factoring_company_name)}
      />,
      <input
        className='form-control'
        type='text'
        disabled={true}
        min='0'
        value={`${carrierDiscountRate}%`}
      />,
      ...(() => isAljex ? [<Field
        component={LabeledInput}
        name='external_code'
      />] : [])(),
      /***
      <Field
        name='enable_discount'
        component={Toggle}
        defaultToggled={enableDiscount}
        onToggle={(value, isInputChecked) => {
          change('enable_discount', isInputChecked);
        }}
      />,
       ***/
      <Tooltip placement="top" text={pendingTask(data.payment_profile)}>
        <div>
          <p
            className={cs('text-uppercase', {
              'text-success': ['approved'].includes(status),
              'text-warning': status === 'pending',
              'text-error': status === 'declined',
              'text-blue': status === 'needs_review',
            })}
          >
            {`${status}`.replace(/[^a-zA-Z ]/g, ' ')}
          </p>
          <p
            className={cs('text-uppercase', {
              'text-success': ['approved'].includes(status),
              'text-warning': status === 'pending',
              'text-error': status === 'declined',
              'text-blue': status === 'needs_review',
            })}
          >
            {moment(status_date).format('l LT')}
          </p>
        </div>
      </Tooltip>,
      <div
        style={{
          display: 'flex',
        }}
      >
        <div>
          <button
            style={{
              marginLeft: '8px',
            }}
            className='btn btn-orange'
            onClick={() => {
              // navigateTo(`/haul-pay/getpaid/request?id=${data.id}`);
              handleSubmit();
            }}
          >
            Save
          </button>
          {/*<button*/}
          {/*  style={{*/}
          {/*    marginLeft: '8px',*/}
          {/*  }}*/}
          {/*  className='btn btn-primary'*/}
          {/*  onClick={() => {*/}
          {/*    updateDiscountRate(data.id, data.discount_rate);*/}
          {/*  }}*/}
          {/*  >*/}
          {/*  Update*/}
          {/*</button>*/}
        </div>
      </div>,
    ];
  },
  compose(
    withState('updatedCarrierRate', 'setCarrierUpdatedRate', undefined),
    connect((state, ownProp) => {
      const FORM_NAME = `UPDATE_PAYMENT_PROFILE_ID_${ownProp.data.id}`;
      const selector = formValueSelector(FORM_NAME);
      const brokerPercentageFieldRate = selector(state, 'broker_percentage_rate');
      const payoutDays = selector(state, 'payout_days');
      return {
        FORM_NAME,
        brokerPercentageFieldRate,
        payoutDays,
      };
    }, {
      change,
      openModal,
    }),
    mapProps(({ data, FORM_NAME, brokerPercentageFieldRate, updatedCarrierRate, formattedItems, payoutDays, factoringCompanyPaymentTerm = {}, ...props }) => {
      const discountRate = (find(formattedItems, ['value', payoutDays]) || {}).discountRate || 3.5;
      const brokerPercentageRate = (brokerPercentageFieldRate !== undefined && brokerPercentageFieldRate !== null) ? brokerPercentageFieldRate : parseInt(get(data, 'factoring_fee_split_rate', 0), 0);
      const enableDiscount = get(data, 'enable_discount', undefined);
      const factoring_company_name = get(data, 'payment_profile.factoring_company.name', undefined);
      const carrierDiscountRate = discountRate - (discountRate * (brokerPercentageRate / 100));

      return {
        form: FORM_NAME,
        initialValues: {
          broker_percentage_rate: factoring_company_name ? parseInt(get(factoringCompanyPaymentTerm, 'factoring_fee_split_rate', data.factoring_fee_split_rate), 0) : brokerPercentageRate,
          enable_discount: enableDiscount,
          payout_days: get(data, 'payout_days'),
          external_code: data.external_code || '',
        },
        carrierDiscountRate,
        discountRate,
        brokerPercentageRate,
        enableDiscount,
        formattedItems,
        payoutDays,
        data,
        factoringCompanyPaymentTerm,
        ...props,
      };
    }),
    reduxForm({
      destroyOnUnmount: true,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      async onSubmit(fields, dispatch, { data, initialValues, oldData, updateCarrierResults }) {
        try {
          const res = await dispatch(updatePaymentsProfile(
            get(data, 'factoring.id', null),
            data.id,
            {
              factoring_fee_split_rate: fields.broker_percentage_rate,
              enable_discount: fields.enable_discount,
              payout_days: fields.payout_days,
              external_code: fields.external_code,
            },
            {
              send_update_email: true
            }));
          dispatch(openModal('success', { message: 'Carrier has been updated.' }));
          if (fields.payout_days !== oldData.payout_days) {
            window.analytics.track(segmentEvents.BROKER_UPDATED_CARRIERS_PAYMENTS_SPEED);
          }
          if (fields.broker_percentage_rate !== oldData.factoring_fee_split_rate) {
            window.analytics.track(segmentEvents.BROKER_UPDATED_CARRIERS_BROKER_PORTION_PERCENT);
          }
          updateCarrierResults();
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t update carrier rate. Please try again later.' }));
        }
      },
    }),
  )
);

const mapStateToProps = state => {
  const self = getSelf(state);
  const factoring = get(state, `resource.factoringclient[${self.data.factoring_id}].data`, {});
  return {
    factoring,
    groups: state.resource.factoringGroups,
    brokerGroups: selectBrokerGroups(state),
    loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
    self,
    terms: getBrokerTerms(state),
  };
};

const mapDispatchToProps = dispatch => ({
  updateFactoringProfile: (...data) => dispatch(edit(...data)),
  fetchFactoringProfile: (...data) => dispatch(fetch(...data)),
  getGroupInvited: id => dispatch(getGroupInvited(id)),
  getCarriers: (factoring_id, filters = {}) => dispatch(getBrokerGroupCarriers(factoring_id, filters)),
  nextCarriers: url => dispatch(getNextBrokerGroupCarriers(url)),
  getGroups: () => dispatch(groups.fetch()),
  openBrokerSettings: () => dispatch(openModal('BrokerDefaultSettings', { modalSize: 'large' })),
  fetchTerms: (...args) => dispatch(fetchTerms(...args)),
  updateDiscountRate: async (id, discountRate) => {
    try {
      if (String(discountRate).trim() === '') {
        throw Error('Invalid discount rate');
      }
      await dispatch(updateFactoringCarrier(id, { discount_rate: discountRate }));
      dispatch(openModal('success', { message: 'Successfully updated discount rate.' }));
    }
    catch (err) {
      dispatch(openModal('error', { message: err.message || 'Error updating discount rate.' }));
    }
  },
  inviteToGroup: async (groupId, emails) => {
    try {
      await dispatch(inviteToGroup(groupId, emails));
      dispatch(openModal('success', { message: 'Invite(s) successfully sent.' }));
    }
    catch (err) {
      dispatch(openModal('error', { message: 'Error sending invite(s).' }));
    }
  },
  destroy: (...args) => dispatch(destroy(...args)),
});

const mergeProps = propsFromState => ({
  ...propsFromState,
  formattedItems: (propsFromState.terms || []).filter(term => term.enable_factoring).map(term => {
    if (term.payee === 'factoring_company') {
      return;
    }
    let label = `${term.payout_days}`;
    let value = String(term.payout_days);

    if (value === '0') {
      value = 'ach_1_day';
    }
    else {
      value = `ach_${term.payout_days}_day`;
    }

    if (label === '0') {
      label = '1 Day';
    }
    else {
      label += ' Days';
    }

    if ((term.discount_rate || term.carrier_rate) === 0) {
      label += ' (Free)';
    }
    else {
      label += ` (${term.discount_rate || term.carrier_rate}% Quick Pay Fee)`;
    }

    return {
      id: term.id,
      value,
      text: label,
      label,
      is_default: term.is_default,
      rate: term.carrier_rate,
      splitFee: term.factoring_fee_split_rate,
      discountRate: term.discount_rate,
    };
  }),
  factoringCompanyPaymentTerm: find(propsFromState.terms, term => term.enable_factoring && (term.payee === 'factoring_company')),
});

export default compose(connect(
  mapStateToProps,
  mapDispatchToProps
),
  mapProps(mergeProps))(
    class FactoringCarriers extends Component {
      constructor(props) {
        super(props);

        this.state = {
          searchField: '',
          searchType: 'carrier_dba',
          carriers: [],
          count: 0,
          searching: false,
          discountRates: [],
          fetch: false,
          next: null,
          oldNext: undefined,
          filtering: false,
          results: []
        };

        this.onTextChange = this.onTextChange.bind(this);
        this.onDropdownChange = this.onDropdownChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.clearSearch = this.clearSearch.bind(this);
        this.fetchPage = this.fetchPage.bind(this);
        this.setDiscountRate = this.setDiscountRate.bind(this);
        this.setPaymentSpeed = this.setPaymentSpeed.bind(this);
        this.updateCarrierResults = this.updateCarrierResults.bind(this);
      }

      async componentDidMount() {
        const { self: { data = {} }, getCarriers, getGroups, fetchTerms, fetchFactoringProfile } = this.props;
        try {
          await fetchFactoringProfile(data.factoring_id);
          await fetchTerms(data.factoring_id);
          const res = await getCarriers(data.factoring_id);
          await getGroups();

          this.setState({
            count: res.count,
            carriers: res.results,
            next: res.next,
            results: res.results
          });
        }
        catch (e) {
          console.error(e);
        }
      }

      onDropdownChange(event) {
        this.setState({
          searchType: event.value,
        });
        this.setState({
          searchField: "",
        });
      }

      onTextChange(event) {
        this.setState({
          searchField: get(event, "target.value", undefined) || get(event, "value", undefined),
        });
      }

      sleep(milliseconds) {
        const date = Date.now();
        let currentDate = null;
        do {
          currentDate = Date.now();
        } while (currentDate - date < milliseconds);
      }

      async clearSearch() {
        this.setState({ searching: true, filtering: false });
        const { self: { data = {} }, getCarriers, destroy } = this.props;
        const { carriers } = this.state;
        setTimeout(async () => {
          map(carriers, carrier => destroy(`UPDATE_PAYMENT_PROFILE_ID_${carrier.id}`));
          const res = await getCarriers(data.factoring_id);
          this.setState({
            carriers: res.results,
            count: res.count,
            next: res.next,
            searching: false,
            results: res.results
          });
        }, 500);
      }
      async onSearch() {
        this.setState({ searching: true, filtering: true });
        const query = {};
        const { self: { data = {} }, getCarriers, destroy } = this.props;
        const { searchField, searchType, carriers } = this.state;
        query[searchType] = searchField;
        setTimeout(async () => {
          map(carriers, carrier => destroy(`UPDATE_PAYMENT_PROFILE_ID_${carrier.id}`));
          const res = await getCarriers(data.factoring_id, query);
          this.setState({
            carriers: res.results,
            count: res.count,
            next: res.next,
            searching: false,
            results: res.results
          });
        }, 500);
      }

      async fetchPage(page) {
        this.setState({ fetch: false });
        const { nextCarriers } = this.props;
        const { next, oldNext, carriers, results } = this.state;
        if (!next) {
          return;
        }
        this.setState({ fetch: true });
        try {
          const res = await nextCarriers(next);
          this.setState({
            carriers: concat(carriers, res.results),
            count: res.count,
            next: res.next,
            oldNext: next,
            results: concat(results, res.results)
          });
        }
        catch (e) {

        }
        this.setState({ fetch: false });
      }

      setDiscountRate(id, rate) {
        const { carriers } = this.state;
        this.setState({
          carriers: map(carriers, carrier => carrier.id === id ? {
            ...carrier,
            factoring_fee_split_rate: 100 - rate,
          } : carrier),
        });
      }

      setPaymentSpeed(id, payout_days) {
        const { carriers } = this.state;
        this.setState({
          carriers: map(carriers, carrier => carrier.id === id ? {
            ...carrier,
            payout_days,
          } : carrier),
        });
      }

      updateCarrierResults() {
        const { carriers } = this.state;
        this.setState({ results: carriers })
      }

      render() {
        const { results, carriers, count, fetch, searching, next, searchType, filtering } = this.state;
        const isAljex = get(this.props, 'factoring.datasync_option', false);
        return (
          <div id="factoring-my-carriers-header">
            <style
              dangerouslySetInnerHTML={{
                __html: `
            .Table { height: 98% !important }
          ` }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: '12px',
                marginBottom: '12px',
              }}
            >
              <Header>My Carriers ({count})</Header>
              <div
                style={{
                  display: 'inline-flex',
                  marginTop: '10px',
                  marginBottom: '5px',
                }}
              >
                {filtering &&
                  <button
                    style={{
                      marginLeft: '12px',
                      marginRight: '12px',
                    }}
                    className='btn btn-danger'
                    onClick={this.clearSearch}
                    id="cancel-button"
                  >
                    <MaterialIcon name='clear' size={20} />
                  </button>
                }
                {searchType === 'status' ?
                  <DropdownList
                    id="status-drop-down"
                    style={{
                      minWidth: '11em',
                      marginLeft: '12px',
                    }}
                    value={this.state.searchField}
                    data={[
                      {
                        text: 'Pending',
                        value: 'pending',
                      },
                      {
                        text: 'Needs Review',
                        value: 'needs_review',
                      },
                      {
                        text: 'Approved',
                        value: 'approved',
                      },
                      {
                        text: 'Declined',
                        value: 'declined',
                      },
                    ]}
                    valueField='value'
                    textField='text'
                    onChange={this.onTextChange}
                  /> :
                  <input
                    className='form-control'
                    type='text'
                    placeholder='Search for'
                    value={this.state.searchField}
                    onChange={this.onTextChange}
                  />
                }
                <div>
                  <DropdownList
                    style={{
                      minWidth: '11em',
                      marginLeft: '12px',
                    }}
                    data={options}
                    defaultValue='carrier_dba'
                    valueField='value'
                    textField='text'
                    onChange={this.onDropdownChange}
                  />
                </div>
                <div>
                  <button
                    style={{
                      marginLeft: '12px',
                    }}
                    className='btn btn-orange'
                    onClick={this.onSearch}
                    id="search-button"
                  >
                    Search
                  </button>
                </div>
                <Link to='/haul-pay/addclients'>
                  <button
                    style={{
                      marginLeft: '12px',
                    }}
                    className='btn btn-green'
                  >
                    + Add New
                  </button>
                </Link>
                <div>
                  <button
                    style={{
                      marginLeft: '12px',
                    }}
                    className='btn btn-blue'
                    onClick={get(this.props, 'openBrokerSettings')}
                  >
                    Defaults
                  </button>
                </div>
              </div>
            </div>
            <div>
              <Toggle
                style={{
                  width: 'auto',
                }}
                defaultToggled={get(this.props, 'factoring.send_rate_confirmation_pdf')}
                label={<p
                  style={{
                    width: '100%',
                    color: '#333333',
                    fontWeight: 'normal',
                    fontSize: 15,
                  }}
                >Generate a carrier rate confirmation from HaulPay, email to carrier.</p>}
                onToggle={async (value, isInputChecked) => {
                  await get(this.props, 'updateFactoringProfile')(get(this.props, 'factoring.id'), { send_rate_confirmation_pdf: isInputChecked });
                  if (isInputChecked) {
                    window.analytics.track(segmentEvents.BROKER_GENERATED_CARRIER_RATE_CONFIRMATION);
                  }
                }}
                labelPosition='right'
              />
            </div>
            {!searching ?
              <div>
                <ResponsiveTable
                  containerProps={{
                    className: 'col-fill',
                  }}
                  fetchNext={this.fetchPage}
                  isFetching={fetch}
                  placeholder='No results.'
                  // headers={['Email', 'Carrier Name', 'Phone #', 'MC #', 'DOT #', 'Payment Speed', 'Broker Portion', 'Carrier Rate', 'Show Discount', 'Status', 'Action']}
                  headers={['Email', 'Carrier Name', 'Phone #', 'MC #', 'DOT #', 'Payment Speed', 'Broker Portion', 'Carrier Rate', ...(() => isAljex ? ['External Code'] : [])(), 'Status', 'Action']}
                  items={carriers.map((c, index) => ({
                    data: c,
                    navigateTo: get(this.props, 'history.push'),
                    setDiscountRate: this.setDiscountRate,
                    updateDiscountRate: get(this.props, 'updateDiscountRate'),
                    formattedItems: get(this.props, 'formattedItems'),
                    setPaymentSpeed: this.setPaymentSpeed,
                    factoringCompanyPaymentTerm: get(this.props, 'factoringCompanyPaymentTerm'),
                    isAljex,
                    oldData: results[index],
                    updateCarrierResults: this.updateCarrierResults
                  }))}
                  striped={true}
                  {...FactoringCarriersItems}
                />
                <style jsx global>{`
                  .Table {
                    overflow: visible !important;
                  }
                  `}
                </style>
                {
                  next ?
                    <div style={{ padding: 20 }}>
                      <button type='button' className='btn btn-orange' onClick={this.fetchPage} disabled={fetch}>Load More</button>
                    </div>
                    : null
                }
              </div>
              : <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignContent: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircleSpinner size='3em' style={{ color: '#E86C00' }} />
              </div>
            }
          </div>
        );
      }
    });
