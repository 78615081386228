/* global API */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, withState } from 'recompose';
import map from 'lodash/map';
import debounce from 'lodash/debounce';
import { edit } from 'actions/resource/factoringpayment';
import { fetchPaymentTerms } from 'actions/factoring';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import CircleSpinner from '../../CircleSpinner';
import Spinner from '../../Spinner';


export const formattedData = data => {
  return data.map(term => {
    if (term.payee === 'factoring_company') {
      return;
    }
    let label = `${term.payout_days}`;
    let value = String(term.payout_days);

    if (value === '0') {
      value = 'ach_1_day';
    }
    else {
      value = `ach_${term.payout_days}_day`;
    }

    if (label === '0') {
      label = '1 Day';
    }
    else {
      label += ' Days';
    }

    if ((term.discount_rate || term.carrier_rate) === 0) {
      label += ' (Free)';
    }
    else {
      label += ` (${term.discount_rate || term.carrier_rate}% Quick Pay Fee)`;
    }

    return {
      id: term.id,
      value,
      text: label,
      label,
      is_default: term.is_default,
      rate: term.carrier_rate,
      splitFee: term.factoring_fee_split_rate,
      discountRate: term.discount_rate,
    };
  });
};

const PaymentSpeedDropDown = ({
  payments,
  meta = {},
  label = '',
  updateTerms,
  inputValue,
  setInputValue,
  brokerFactoringId,
  paymentRequestId,
  edit,
  loading,
  input = {
    onChange: () => ({}),
    value: inputValue,
  },
  ...rest }) => {
  if (loading) {
    return (<div className='text-center'>
      <CircleSpinner />
    </div>);
  }
  return (
    <LabeledDropdown
      meta={meta}
      input={input}
      label={label}
      valueField='value'
      textField='text'
      data={formattedData(payments)}
      onObjectChange={async objects => {
        setInputValue(objects.value);
        edit(paymentRequestId, { payout_days: objects.value, id: paymentRequestId });
      }}
      {...rest}
      style={{
        minWidth: 200,
        ...rest.style,
      }}
    />
  );
};

export default compose(
  connect(null, { fetchPaymentTerms, edit }),
  withState('payments', 'setPayments', []),
  withState('inputValue', 'setInputValue', ''),
  withState('loading', 'setLoading', false),
  lifecycle({
    async componentDidMount() {
      const { brokerFactoringId, fetchPaymentTerms, setInputValue, value, setPayments, setLoading } = this.props;
      setLoading(true);
      setInputValue(value);
      await debounce(async (...args) => {
        const res = await fetchPaymentTerms(...args);
        setPayments(res);
        setLoading(false);
      }, 500)(brokerFactoringId, true);
    },
  })
)(PaymentSpeedDropDown);
