import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';

import { openModal } from 'actions/ui';
import FetchError from 'datatypes/FetchError';

import Resource from 'components/hoc/Resource';
import Error from 'components/pure/Error';
import docTitle from 'components/hoc/docTitle';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import { CenteredSpinner } from 'components/pure/Spinner';
import User from 'components/pure/User';


export default compose(
  mapProps(props => ({ id: props.params.id })),
  Resource('user', { resourcePropName: 'user' }),
  spinnerIfFetching('user', CenteredSpinner),
  branch(
    ({ user }) => user.err instanceof FetchError && user.err.status === 404,
    renderComponent(Error),
    Component => Component,
  ),
  docTitle(props => `User Details - ${props.user.data.dba}`),
  connect(
    null,
    (dispatch, ownProps) => ({
      openRateModal() {
        dispatch(openModal('userrate', {
          user_id: ownProps.id,
        }));
      },
    })),
)(User);
