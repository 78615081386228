/**
 * Reducer for handling the lead resource actions
 * @module reducers/resource/lead
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/lead
 * @requires actions/admin/lead
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.lead;

import leadResource from 'actions/resource/lead';
import leadPaginatedResource from 'actions/admin/leads';


export default function leadResourceReducer(state = initialState, action) {
  state = leadResource.reduce(state, action);
  switch (action.type) {
    case leadPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...leadResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
