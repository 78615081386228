// get user's factoring status here, display Pre-Approval page if not yet approved and Post-Approval page if already approved
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { USER_TYPE } from 'helpers';

import { openModal } from 'actions/ui';
import getSelf from 'selectors/getSelf';
import redirectIf from 'components/hoc/redirectIf';
import CenteredColumn from 'components/pure/CenteredColumn';
import FactoringSignUpMarketing from 'components/container/FactoringSignUpMarketing';
import Header from 'components/pure/Header';
import FactoringSignUpAccount from 'components/container/FactoringSignUpAccount';
import FactoringClientUnacceptedEdit from 'components/container/FactoringClientUnacceptedEdit';

const renderChildren = ({ children }) => children;

const FactoringWaiting = compose(
  connect(
    null,
    {
      openHaulPayDemoModal: () => openModal('generic', {
        headerStyle: {
          backgroundColor: '#5cb85c',
          color: 'white',
        },
        headerIcon: 'done',
        headerChildren: 'Haul Pay Demo Video',
        youtubeEmbed: 'Aykn5XgennU',
      }),
    },
  ),
)(({ status = '', message, openHaulPayDemoModal }) =>
  <CenteredColumn xs={12} sm={10} md={8} style={{ width: '100%', paddingBottom: '3em' }}>
    <Header>Haul Pay Application Status</Header>
    <p>Thank you for your application for our Haul Pay Factoring services. We will update your here as well as via email once your application is processed.</p>
    <p>Please allow 1-2 business days to process your application.</p>
    <p>Once you're approved, you'll be able to get paid on your invoices for all loads you move from credit qualified shippers and brokers within only a few hours of confirmed deliveries!</p>
    <p style={{ fontSize: '2em' }}>
      <b>STATUS: </b>
      {
        status === USER_FACTORING_STATUS.PENDING ||
        status === USER_FACTORING_STATUS.NEW ?
          <span className='text-warning'>PENDING APPROVAL</span>
          :
          <span className='text-danger'>APPLICATION DECLINED</span>
      }
    </p>
    <p>Once you're approved you will have free access to our load board and our invoice management tools here!</p>
    {
      status === USER_FACTORING_STATUS.DECLINED ?
        <div>
          { message ?
            <p>
              <b>Response: </b>
              {message}
            </p>
            : null
          }
          <p>Please email <a href='mailto:help@haulpay.io'>help@haulpay.io</a> to send the correct information required so that we can update your application information and try to get you approved again. Thank you!</p>
        </div>
        : null
    }
    <div><img src='/public/image/money_sign.png' style={{ maxWidth: '100%' }} /></div>
    <div>
      <button className='btn btn-orange' type='button' onClick={openHaulPayDemoModal}>Watch Haul Pay Demo</button>
    </div>
    {
      status === USER_FACTORING_STATUS.PENDING ||
      status === USER_FACTORING_STATUS.NEW ?
        <FactoringClientUnacceptedEdit />
        :
        null
    }
  </CenteredColumn>
);

const Factoring = (WrappedComponent = renderChildren) => compose(
  connect(
    state => {
      const self = getSelf(state);
      if (!self) {
        return {};
      }
      return {
        status: self.data.factoring_approved,
        message: self.data.factoring_message,
        logged_in: state.user.logged_in,
        type: self.data.type,
      };
    },
    {},
  ),
  redirectIf([
    {
      if: ({ type }) => /\/haul-pay\/getpaid\/?.+$/.test(location.pathname) && type === USER_TYPE.BROKER,
      to: '/haul-pay/carriers',
    },
    {
      if: ({ location, status }) => /\/haul-pay(?:\/join)?\/?$/.test(location.pathname) && (status === USER_FACTORING_STATUS.APPROVED || status === USER_FACTORING_STATUS.DEAD),
      to: '/haul-pay/getpaid/request',
    },
    {
      if: ({ location, status }) => /\/haul-pay\/?.+$/.test(location.pathname) && (status === USER_FACTORING_STATUS.DECLINED || status === USER_FACTORING_STATUS.PENDING || status === USER_FACTORING_STATUS.NEW),
      to: '/haul-pay',
    },
  ]),
)(({ status, message, logged_in, type, ...rest }) => {
  switch (status) {
    case USER_FACTORING_STATUS.NEW:
    case USER_FACTORING_STATUS.PENDING:
    case USER_FACTORING_STATUS.DECLINED:
      return <FactoringWaiting status={status} message={message} />;
    case USER_FACTORING_STATUS.APPROVED:
    case USER_FACTORING_STATUS.DEAD:
      return (
        <CenteredColumn
          style={{ width: '100%' }} xs={12} md={12} lg={12}
                                                    >
          <div style={{ paddingLeft: '10px', paddingRight: '10px' }}>
            <WrappedComponent {...rest} />
          </div>
        </CenteredColumn>
      );
    case USER_FACTORING_STATUS.UNAPPLIED:
    default:
      return (
        <FactoringSignUpMarketing
          {...rest}
          userType={type}
          form={<FactoringSignUpAccount />}
          showForm={!logged_in}
          logged_in={logged_in}
        />
      );
  }
});

export default Factoring;
