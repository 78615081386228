import React from 'react';
import PropTypes from 'prop-types';

import MaterialIcon from 'components/pure/MaterialIcon';


const Checkbox = ({ value, onClick, disabled, ...rest }) =>
  <MaterialIcon
    role='button'
    {...rest}
    style={{
      verticalAlign: 'middle',
      ...rest.style,
      cursor: disabled ? 'not-allowed' : 'pointer',
      color: disabled ? 'rgb(153, 153, 153)' : value ? '#E86C00' : 'inherit',
    }}
    onClick={disabled ? undefined : onClick ? () => onClick(!value) : undefined}
    name={value ? 'check_box' : 'check_box_outline_blank'}
  />
  ;

Checkbox.propTypes = {
  value: PropTypes.bool,
  onClick: PropTypes.func,
};

export default Checkbox;
