import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { formValueSelector } from 'redux-form';

import { fetchFactoringProfile } from 'actions/user';

import { CenteredSpinner } from 'components/pure/Spinner';
import FactoringPaymentInvoice from 'components/pure/FactoringPaymentInvoice';


const FORM_NAME = 'FactoringRequestPaymentForm';
const FactoringPaymentRequestInvoicePreview = compose(
  connect(
    state => {
      const { isFetching, data } = state.user.factoring;
      if (isFetching || !data) {
        return {
          noData: true,
        };
      }
      return {
        ...formValueSelector(FORM_NAME)(state,
          'bill_to',
          'bill_to_address',
          'first_origin_location',
          'final_destination_location',
          'amount',
          'load_length',
          'user_reference_number',
          'user_notes',
          'bill_to_company_phone',
          'bill_to_company_email',
        ),
        dba: state.user.factoring.data.company_profile.name,
        name: state.user.factoring.data.owner_profile.name,
        address: {
          street_one: state.user.factoring.data.company_profile.address.street_one,
          city: state.user.factoring.data.company_profile.address.city,
          state: state.user.factoring.data.company_profile.address.state,
          zip: state.user.factoring.data.company_profile.address.zip,
          country: state.user.factoring.data.company_profile.address.country,
        },
      };
    },
    {
      fetchFactoringProfile,
    },
  ),
)(class extends React.Component {
  componentDidMount() {
    if (this.props.noData) {
      this.props.fetchFactoringProfile();
    }
  }
  render() {
    if (this.props.noData) {
      return <CenteredSpinner />;
    }
    return <FactoringPaymentInvoice {...this.props} />;
  }
});

export default FactoringPaymentRequestInvoicePreview;
