const awsImagesUpload = attachments =>
  Promise.all(attachments.map(({ file, upload_url: { url, fields } }) => {
    const formData = new FormData();
    for (const key in fields) {
      if (fields.hasOwnProperty(key)) {
        formData.append(key, fields[key]);
      }
    }
    formData.append('file', file);
    return global.fetch(url, {
      method: 'POST',
      body: formData,
    });
  }));

export default awsImagesUpload;
