/**
 * Component for tiered MenuItems. Represents MenuItems that link to multiple paths under the same base path
 * @module components/pure/TieredMenuItem
 * @since 3.0.0
 * @requires helpers
 * @requires components/container/MenuItem
 * @property {object} props
 * @property {boolean} [props.index] - whether the header MenuItem is an index link
 * @property {string} props.defaultHref - the href for the header MenuItem
 * @property {string} props.baseHref - the base href for MenuItems on this route
 * @property {node} props.children - the content for the header MenuItem
 * @property {'[string, node][]'} [props.subItems] - [href, content] pairs for MenuItems that appear underneath the header MenuItem
 */
/* global */
import 'styles/TieredMenuItem';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import cs from 'classnames';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import { isMobile } from 'helpers';
import hrefMatchesCurrentLocation from 'helpers/hrefMatchesCurrentLocation';

import MenuItem from 'components/container/MenuItem';


let TieredMenuItem = null;

const locationMatchesSubItems = subItems => {
  return subItems.some(([child, ...paths]) => {
    if (child.type === TieredMenuItem) {
      return locationMatchesSubItems(child.props.subItems);
    }
    return paths.some(hrefMatchesCurrentLocation);
  });
};

const locationMatchesExtras = extras => Array.isArray(extras) && extras.length && extras.some(hrefMatchesCurrentLocation);

TieredMenuItem = compose(
  connect(
    // force the component to update on route change
    state => ({
      location: state.routing.locationBeforeTransitions,
    }),
    {},
  ),
  withState('active', 'setActive', false),
  withHandlers({
    onClick: ({ active, setActive, defaultHref, onClick }) => e => {
      if (typeof onClick === 'function') {
        onClick();
      }
      e.preventDefault();
      if (isMobile()) {
        setActive(!active);
      }
      else {
        browserHistory.push(defaultHref);
      }
    },
  }),
)(({ defaultHref, children, subItems, active, setActive, onClick, extraMatchingRoutes, location, ...rest }) =>
  <RootCloseWrapper onRootClose={() => setActive(false)}>
    <div
      {...rest}
      className={cs('tiered-menu-item', {
        'tiered-menu-item-active': locationMatchesSubItems(subItems) || locationMatchesExtras(extraMatchingRoutes),
        'tiered-menu-item-active-mobile': active,
      })}
      >
      <MenuItem
        href={defaultHref}
        closeOnClick={false}
        onClick={onClick}
        >
        {children}
      </MenuItem>
      <div className='tiered-menu-item-subitems'>
        {subItems.map(([child, href, ...otherHrefs], i) => (
          typeof child === 'string' ?
            <MenuItem key={i} href={href} active={otherHrefs.some(hrefMatchesCurrentLocation)}>
              {child}
            </MenuItem>
            :
            <div key={i}>{child}</div>
        ))}
      </div>
    </div>
  </RootCloseWrapper>
);

TieredMenuItem.propTypes = {
  defaultHref: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  subItems: PropTypes.arrayOf(PropTypes.array).isRequired,
  extraMatchingRoutes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string])),
};

export default TieredMenuItem;
