import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import capitalize from 'lodash/capitalize';


const FactoringStatusInfo = ({ status_timeline = [] }) => {
  const latest_status = status_timeline[status_timeline.length - 1];
  if (!latest_status) {
    return (
      <p>No status history for this client yet.</p>
    );
  }
  const { status, status_set_by_name, status_set_datetime } = latest_status;
  return (
    <p>[{moment(status_set_datetime).format('MMM DD, YYYY - hh:mm A')}] <b>{status_set_by_name || 'HaulPay Dashboard'}</b> modified Status to <b>{status.split('_').map(capitalize).join(' ')}</b></p>
  );
};

FactoringStatusInfo.propTypes = {
  status_timeline: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.node.isRequired,
    status_set_by_id: PropTypes.string.isRequired,
    status_set_by_name: PropTypes.string.isRequired,
    status_set_datetime: PropTypes.string.isRequired,
  })),
};

export default FactoringStatusInfo;
