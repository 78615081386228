import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.financespayment;

import financespurchaseResource from 'actions/resource/financespurchase';
import financespurchasesPaginatedResource, { UPDATE_STATUS_PURCHASE_RETRY } from 'actions/admin/financespurchases';
import { cloneDeep } from 'lodash';


export default function financespaymentResourceReducer(state = initialState, action) {
  state = financespurchaseResource.reduce(state, action);
  let newState = [];
  switch (action.type) {
    case UPDATE_STATUS_PURCHASE_RETRY:
      newState = cloneDeep(state);
      newState[action.payload.id].data = action.payload.data;
      return newState;
    case financespurchasesPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...financespurchaseResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
