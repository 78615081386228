import { format as formatURL, parse as parseURL } from 'url';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';
import getSelf from 'selectors/getSelf';

import { editProfile } from 'actions/resource/user';
import { openModal } from 'actions/ui';

import { ImageInput } from 'components/pure/form/inputs/ImageInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import NumberInput from 'components/pure/form/inputs/NumberInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import RadioInput from './inputs/RadioInput';


export default compose(
  connect(state => getSelf(state)),
  mapProps(({ data }) => ({
    user_id: data.id,
    initialValues: {
      image: data.image,
      phone: data.contact_phone,
      fax: data.fax_number,
      contact_name: data.contact_name,
      website: data.url,
      details: data.about,
      dba: data.dba,
      mc: data.mc,
      dot: data.dot,
      cargo_insurance_value: data.cargo_insurance_value,
      cargo_insurance_provider: data.cargo_insurance_provider,
      language: data.language,
      place: {
        address: data.address,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country,
      },
    },
    validate(fields) {
      const errs = validate(fields, {
        image: {
          filesize: {
            maximum: 1e8,
          },
        },
        phone: {
          presence: true,
          phone: true,
        },
        fax: {
          phone: '^Not a valid fax number',
        },
        website: {
          url: true,
        },
        mc: {
          numericality: {
            notValid: '^Please enter numbers',
          },
        },
        dot: {
          numericality: {
            notValid: '^Please enter numbers',
          },
        },
        cargo_insurance_value: {
          numericality: {
            notValid: '^Please enter numbers',
          },
        },
        dba: {
          presence: true,
        },
      });
      if (data.type === 'broker') {
        if (!fields.mc && !fields.dot) {
          errs.mc = errs.dot = 'Please enter an MC# or DOT#';
        }
      }
      return errs;
    },
    onSubmit(fields, dispatch) {
      // their previous url might be null, so check for that here, because URL.parse errors when passed `null`
      let url = null;
      if (fields.website) {
        url = parseURL(fields.website);
        if (!url.protocol) {
          url.protocol = 'http:';
          url.slashes = true;
          url = formatURL(url).replace('///', '//');
        }
        else {
          url = formatURL(url);
        }
      }
      return dispatch(editProfile(data.id, {
        // spread like this instead of assign default because we send this as FormData, which will send "undefined" if we pass undefined
        ...(() => fields.image instanceof FileList && fields.image[0] ? { image: fields.image[0] } : undefined)(),
        contact_phone: fields.phone,
        fax_number: fields.fax,
        contact_name: fields.contact_name,
        url,
        about: fields.details,
        dba: fields.dba,
        mc: fields.mc,
        dot: fields.dot,
        cargo_insurance_value: fields.cargo_insurance_value,
        cargo_insurance_provider: fields.cargo_insurance_provider,
        language: fields.language,
        ...(() => typeof fields.place === 'string' ? ({
          address: fields.place,
          city: '',
          state: '',
          zip: '',
          country: '',
        }) : ({
          address: fields.place.address,
          city: fields.place.city,
          state: fields.place.state,
          zip: fields.place.zip,
          country: fields.place.country,
        }))(),
      }))
        .then(() => dispatch(openModal('success', { message: 'Successfully updated profile' })))
        .catch(err => {
          if (err && err.status === 400) {
            let json;
            if (typeof err.message === 'string') {
              try {
                json = JSON.parse(err.message);
              }
              catch (e) {
                json = {};
              }
            }
            else if (err.message !== null && typeof err.message === 'object') {
              json = err.message;
            }
            else {
              json = {};
            }
            err = Object.values(json).map(err_arr => err_arr.join('\n')).join('\n');
          }
          dispatch(openModal('error', { message: `Failed to update profile: ${err}` }));
        })
      ;
    },
  })),
  reduxForm({
    form: 'ProfileForm',
    destroyOnUnmount: false,
  }),
)(({ user_id, handleSubmit }) =>
  <div>
    <div className='col-xs-12 col-sm-6'>
      <Field name='image' component={ImageInput} placeholder={'/public/image/preview_profile_pic.png'} maxHeight={275} />
      <Field name='phone' component={LabeledInput} label='Phone' />
      <Field name='fax' component={LabeledInput} label='Fax' />
      <Field name='contact_name' component={LabeledInput} label='Contact Name' />
      <Field name='website' component={LabeledInput} label='Website' />
      <Field
        name='details'
        component={LabeledInput}
        label='More Info'
        ComponentClass='textarea'
        className='grow'
      />
    </div>
    <div className='col-xs-12 col-sm-6'>
      <Field name='dba' component={LabeledInput} label='Business Name' />
      <Field name='mc' component={LabeledInput} ComponentClass={NumberInput} label='MC #' />
      <Field name='dot' component={LabeledInput} ComponentClass={NumberInput} label='DOT #' />
      <Field name='cargo_insurance_provider' component={LabeledInput} label='Cargo Insurance Provider' />
      <Field name='cargo_insurance_value' component={LabeledInput} label='Cargo Insurance Value' />
      <Field
        name='place'
        component={GoogleLocationInput}
        label='Address'
        specificity={GoogleLocationInput.specificity.ADDRESS}
        setValueWithoutConfirming={true}
      />
      <RadioInput
        inline={true}
        values={[{ value: 'en', label: 'EN' }, { value: 'es', label: 'ES' }]}
        label='Language preference?'
        placeholder='Contact Name'
        name='language'
      />
      <div className='text-right'>
        <a href={`/user/${user_id}`} target='_blank'><button className='btn btn-default'>View Profile</button></a>
        {'\u00a0\u00a0\u00a0'}
        <button className='btn btn-orange' onClick={handleSubmit}>SAVE</button>
      </div>
    </div>
  </div>
);
