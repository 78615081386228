/**
 * Container component for ModalContainer, which handles the modals
 * @module components/container/Modals
 * @since 3.0.0
 * @requires components/pure/Modals
 */
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import ModalContainer from 'components/pure/Modals';


export default compose(
  connect(state => ({ modals: state.ui.modal })),
)(ModalContainer);
