/**
 * Component that displays the ComBoard tool
 * @module components/pure/Timeline
 * @since 3.0.0
 * @requires components/pure/CenteredColumn
 * @requires components/container/TimelineFilter
 * @requires components/container/TimelineList
 */
 import colors from 'styles/colors.json';
 import React from 'react';
 import { Link } from 'react-router-v4';
 
 import CenteredColumn from './CenteredColumn';
 import TimelineFilter from '../container/TimelineFilter';
 import TimelineList from '../container/TimelineList';
 
 
 const Timeline = () => {
   return (
     <div style={{ height: '100%', display: 'flex', flexDirection: 'column', backgroundColor: colors.NOT_AS_LIGHT_GRAY }}>
       <div style={{ backgroundColor: 'white' }}>
         <CenteredColumn xs={12} sm={10} md={8} columnProps={{ className: 'clearfix', style: { padding: 0 } }}>
           <Link to='/settings/notifications' style={{ float: 'left', padding: '8px 15px' }}>Manage Notifications</Link>
           <TimelineFilter style={{ display: 'inline-block', float: 'right', flexShrink: 0 }} />
         </CenteredColumn>
       </div>
       <TimelineList style={{ paddingTop: '0.5em', flexGrow: 1 }} />
     </div>
   )
 }
 
 export default Timeline;
