import React from 'react';
import { connect } from 'react-redux-v5';

import { deleteLineItemCategory, getLineItemCategories } from 'actions/lineitemcategories';
import LineItemCategoryEdit from 'components/pure/form/LineItemCategoryEdit';
import { openModal } from 'actions/ui';
import { compose } from 'redux-v3';
import { lifecycle, withState } from 'recompose';

const LineItemCategories = ({ onEdit, onDelete, onAdd, categories }) => (
  <div>
    <LineItemCategoryEdit categories={categories} onEdit={onEdit} onDelete={onDelete} onAdd={onAdd} />
  </div>
);

export default compose(
  withState('categories', 'setCategories', []),
  connect(
    null,
    (dispatch, ownProps) => {
      return {
        getCategories: () => dispatch(getLineItemCategories()),
        onEdit: data => dispatch(openModal('lineitemcategorycreate', {
          type: 'edit',
          data,
          onClose: async () => {
            const categories = await dispatch(getLineItemCategories());
            ownProps.setCategories(categories);
          },
        })),
        onDelete: async id => {
          await dispatch(deleteLineItemCategory(id));
          const categories = await dispatch(getLineItemCategories());
          ownProps.setCategories(categories);
        },
        onAdd: () => dispatch(openModal('lineitemcategorycreate', {
          onClose: async () => {
            const categories = await dispatch(getLineItemCategories());
            ownProps.setCategories(categories);
          },
        })),
      };
    }
  ),
  lifecycle({
    async componentDidMount() {
      const categories = await this.props.getCategories();
      this.props.setCategories(categories);
    },
  })
)(LineItemCategories);
