/**
 * Actions relating to the QA by Load resource
 * @module actions/resourceBy/load/qa
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/qa
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import qaResource from 'actions/resource/qa';


/**
 * Our QA by Load ControlledPaginatedResourceCollection instance
 */
export const qaByLoadResource = new ControlledPaginatedResourceCollection('load', 'qa', {
  url: new UrlAssembler('load/:id/question'),
  paginatedResourceOptions: {
    baseResource: qaResource,
  },
});

export default qaByLoadResource;
export const {
  fetch,
  fetchNext,
  create,
} = qaByLoadResource;
