import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import APIError from 'datatypes/error/APIError';
import { closeModal, openModal, unshiftModal } from 'actions/ui';
import { uploadExcel } from 'actions/resource/load';

import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';


export default compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
  reduxForm({
    form: 'LoadUploadExcel',
    onSubmit(fields, dispatch) {
      return dispatch(uploadExcel(fields.file[0]))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully uploaded loads!' }));
          dispatch(closeModal());
        })
        .catch(err => {
          console.warn(err);
          let message = 'Failed to upload loads :(';
          if (err && err.message && err.message.detail) {
            message = err.message.detail;
          }
          else if (err instanceof APIError) {
            if (err.message.status === APIError.type.VALIDATION_ERROR) {
              message = `There was a problem with your file. Please correct the following issues and resubmit:\n\n${err.message.details.params.map(({ param, reason, received_value }) =>
                `Invalid: ${param}\n${reason}\n${received_value ? `We received: ${received_value}\n` : ''}`
              ).join('\n')}`;
            }
          }
          dispatch(unshiftModal('error', { message }));
        })
      ;
    },
    validate: fields => validate(fields, {
      file: {
        length: {
          is: 1,
        },
      },
    }),
  }),
)(({ closeModal, handleSubmit, submitting }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Bulk Load Posting via File</h4>
    </div>
    <div className='modal-body'>
      <p>Please download the template file shown below and then enter the load details in the associated columns to be able to reupload the file here to bulk post loads.</p>
      <p><b>We are now using the same format for uploading loads as LoadBoardNetwork!</b></p>
      <p><a href='https://s3-us-west-2.amazonaws.com/comfreight-static/pdfs/HaulPay%20-%20LBN%20Template.csv' target='_blank' rel='noreferrer noopener'>Click here to download bulk upload template (same as PostEverywhere)</a></p>
      <p>
        If you require bulk posting on a regular basis and are using another 3rd party software system please email us at <a href='mailto:support@haulpay.io'>support@haulpay.io</a> to inquire about API integration.
      </p>
      <Field name='file' component={MultiImageInput} limit={1} buttonText='Upload' />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal} type='button'>Cancel</button>
      <button className='btn btn-orange' onClick={handleSubmit} disabled={submitting} type='submit'>Post Loads</button>
    </div>
  </div>
);
