import { combineReducers } from 'redux-v3';

import user from './user';
import load from './load';
import truck from './truck';
import qa from './qa';
import bid from './bid';
import trucksearch from './trucksearch';
import loadsearch from './loadsearch';
import map from './map';
import activity from './activity';
import geocode from './geocode';
import rating from './rating';
import usersearch from './usersearch';
import rateindexhistory from './rateindexhistory';
import lead from './lead';
import factoringclient from './factoringclient';
import factoringclientnote from './factoringclientnote';
import usernote from './usernote';
import factoringdebtor from './factoringdebtor';
import factoringdebtornote from './factoringdebtornote';
import factoringpayment from './factoringpayment';
import onboarddebtor from './onboarddebtor';
import factoringpaymentnote from './factoringpaymentnote';
import factoringdebtorauditlog from './factoringdebtorauditlog';
import factoringclientauditlog from './factoringclientauditlog';
import financespayment from './financespayment';
import financespurchase from './financespurchase';
import financeslineitem from './financeslineitem';
import factoringclientdebtorauditlog from './factoringclientdebtorauditlog';
import factoringtransactionpayment from './factoringtransactionpayment';
import factoringtransactionpurchase from './factoringtransactionpurchase';
import factoringlineitem from './factoringlineitem';
import getbankinfo from './getbankinfo';
import getcardinfo from './getcardinfo';
import defaultbank from './defaultbank';
import factoringpaymentrelation from './factoringpaymentrelation';
import factoringGroups from './factoringGroups';
import factoringGroupUsers from './factoringGroupUsers';
import factoringclientattachments from './factoringclientattachments';
import adminuser from './adminuser';
import plaidaccount from './plaidaccount';
import financesPurchasesItems from './financesPurchasesItems';
import haulPayLoad from './haulPayLoad';
export default combineReducers({
  user,
  load,
  truck,
  qa,
  bid,
  trucksearch,
  loadsearch,
  haulPayLoad,
  map,
  activity,
  geocode,
  rating,
  usersearch,
  rateindexhistory,
  lead,
  factoringclient,
  factoringclientnote,
  usernote,
  factoringdebtor,
  factoringlineitem,
  factoringdebtornote,
  factoringpayment,
  onboarddebtor,
  factoringpaymentnote,
  factoringdebtorauditlog,
  factoringclientauditlog,
  financespayment,
  financespurchase,
  financeslineitem,
  factoringclientdebtorauditlog,
  factoringclientattachments,
  factoringtransactionpayment,
  factoringtransactionpurchase,
  getbankinfo,
  getcardinfo,
  defaultbank,
  factoringpaymentrelation,
  factoringGroups,
  factoringGroupUsers,
  adminuser,
  plaidaccount,
  financesPurchasesItems,
});
