/**
 * Component that displays the email update form
 * @module components/pure/form/EmailUpdateForm
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/user
 * @requires actions/resource/user
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import validate from 'validate.js';

import { openModal } from 'actions/ui';
import { validateEmail } from 'actions/user';
import userResource from 'actions/resource/user';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import SubmitButton from 'components/pure/form/SubmitButton';
import getSelf from 'selectors/getSelf';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isFactoringBroker from 'selectors/isFactoringBroker';
import isFactoringCarrier from 'selectors/isFactoringCarrier';
import isInviteCarrier from 'selectors/isInviteCarrier';
import isFactoringInviteCarrier from 'selectors/isFactoringInviteCarrier';

export default compose(
  connect(
    state => ({
      id: state.user.id,
      current_email: getSelf(state).data.email,
      isBroker: isDispatchBroker(state) || isFactoringBroker(state),
      isFactoringCarrier: isFactoringCarrier(state),
      isInviteCarrier: isInviteCarrier(state),
      isFactoringInviteCarrier: isFactoringInviteCarrier(state),
    }),
    {},
  ),
  mapProps(({ id, current_email, isBroker, isFactoringCarrier, isInviteCarrier, isFactoringInviteCarrier }) => ({
    initialValues: {
      email: current_email,
    },
    disabledEmailInput: isBroker || isFactoringCarrier || isInviteCarrier || isFactoringInviteCarrier,
    onSubmit(data, dispatch) {
      return dispatch(userResource.edit(id, {
        email: data.email,
      }))
        .then(() => dispatch(openModal('success', { message: 'Successfully changed email' })))
        .catch(err => dispatch(openModal('error', { message: `Failed to change email: ${err.message}` })))
      ;
    },
    asyncValidate(fields) {
      if (fields.email === current_email) {
        return Promise.resolve();
      }
      return validateEmail(fields.email)
        .catch(err => {
          console.warn(err);
        })
        .then(json => {
          if (json && json.status === 'failure') {
            throw { email: 'This email already exists, please update using a different email address.' }; // eslint-disable-line no-throw-literal
          }
        })
      ;
    },
  })),
  reduxForm({
    form: 'EmailUpdateForm',
    destroyOnUnmount: false,
    validate: fields => validate(fields, {
      email: {
        presence: true,
        email: true,
      },
      confirm: {
        presence: true,
        equality: 'email',
      },
    }),
    asyncBlurFields: ['email'],
  }),
)(({ handleSubmit, submitting, disabledEmailInput }) =>
  <div>
    <Field name='email' disabled={disabledEmailInput} component={LabeledInput} label='Email' type='email' hasAsyncValidate={true} />
    <Field name='confirm' disabled={disabledEmailInput} component={LabeledInput} label='Confirm Email' type='email' />
    {disabledEmailInput && <p>For security, you cannot change your email here.  Please contact support (<a href="mailto:support@haulpay.io">support@haulpay.io</a>)</p>}
    <SubmitButton onClick={handleSubmit} disabled={submitting || disabledEmailInput}>Update Email</SubmitButton>
  </div>
);
