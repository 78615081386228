import 'styles/TableItemAddonBids';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';
import withState from 'recompose/withState';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import getSelf from 'selectors/getSelf';
import { openModal, closeModal } from 'actions/ui';
import { reset, isSubmitting, submit } from 'redux-form';
import isFactoringBroker from 'selectors/isFactoringBroker';

import { USER_TYPE } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import formatTel from 'helpers/formatTel';
import createTableItems from 'helpers/createTableItems';
import PermissionsError from 'datatypes/PermissionsError';

import Resource from 'components/hoc/Resource';
import scrollToAnchor from 'components/hoc/scrollToAnchor';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import FMCSALink from 'components/pure/FMCSALink';
import ColoredCircle from 'components/pure/ColoredCircle';
import SignUpButtonLink from 'components/container/SignUpButtonLink';
import UpgradeToViewButton from 'components/container/UpgradeToViewButton';
import LoadStatusForm from 'components/pure/form/LoadStatusForm';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import ButtonHaulPayVerified from 'components/pure/ButtonHaulPayVerified';
import UserRating2 from 'components/container/UserRating2';
import intersectionBy from 'lodash/intersectionBy';
import get from 'lodash/get';
import has from 'lodash/has';

import { Button } from 'react-bootstrap';
import isEmpty from 'lodash/isEmpty';
import DebtorCarrierPaymentForm, { FORM_NAME as FORM_CARRIER_PAYMENT } from '../pure/form/DebtorCarrierPaymentForm';
import { onSubmitPaymentRequest, openFundingRequestPaymentModal, ValidationPopUpView } from './LoadPost';
import trucksearchResourceReducer from '../../reducers/resource/trucksearch';

const placeholder = () => <div className='TableItemAddonBids' style={{ textAlign: 'center', fontWeight: 'bold' }}><span>{'\u00a0'}</span></div>;
const HASH_ID_PREFIX = 'bid-';

const TableComponents = createTableItems(
  ({ bid, acceptedBid, should_show_upgrade_to_view_button, openModal }) => [
    <div>
      <div>{bid.user.dba}</div>
      {
        bid.user.err instanceof PermissionsError ?
          <UpgradeToViewButton />
          :
          <div><Link to={`/user/${bid.user.id}`}>View Profile</Link></div>
      }
    </div>,
    <div>
      {bid.user.mc ? <div>MC#: {bid.user.mc}</div> : undefined}
      {bid.user.dot ? <div>DOT#: {bid.user.dot}</div> : undefined}
      <div>{[bid.user.address, bid.user.city, bid.user.state, bid.user.zip].filter(i => i !== undefined).join(', ')}</div>
      {bid.user.contact_name ? <div>Contact: {bid.user.contact_name}</div> : undefined}
      {bid.user.contact_phone ? <a href={`tel:${formatTel(bid.user.contact_phone)}`}>{bid.user.contact_phone}</a> : undefined}
    </div>,
    <div>
      <div>Submitted {moment(bid.time_posted).format('L LT')}</div>
      {bid.bidder_notes ? <div style={{ wordWrap: 'wrap' }}>Note: {bid.bidder_notes}</div> : null}
      <div>
        {
          should_show_upgrade_to_view_button ?
            <UpgradeToViewButton />
            :
            bid.bid_status === 'accepted' ?
              <Link to={`/user/${bid.user_id}`}><button className='btn btn-success'>You accepted this bid of {formatPennies(bid.amount)}</button></Link>
              :
              bid.bid_status === 'retracted' ?
                <button className='btn btn-danger'>This bid of {formatPennies(bid.amount)} was retracted</button>
                :
                acceptedBid ?
                  `Bid of ${formatPennies(bid.amount)}`
                  :
                  <button
                    className='btn btn-orange'
                    onClick={() => openModal('confirm', {
                      type: 'accept_bid',
                      args: [{
                        id: bid.id,
                        load_id: bid.load_id,
                      }],
                    })}
                    >
                    Accept Bid {formatPennies(bid.amount)}
                  </button>
        }
      </div>
    </div>,
    <div>
      <UserRating2 load_id={bid.load_id} user_id={bid.user_id} useBid={bid.id} />
      {
        bid.user.avgpay ?
          <div>
            <span>AVG DAYS TO PAY: {bid.user.avgpay}</span>
            <ColoredCircle
              color={(() =>
                bid.user.avgpay >= 60 ? '#ED1C24' :
                  bid.user.avgpay >= 45 ? '#E86C00' :
                    bid.user.avgpay >= 35 ? '#0395FE' : '#00BC48'
              )()}
            />
          </div>
          : null
      }
      <FMCSALink mc={bid.user.mc} dot={bid.user.dot} />
      {
        bid.user.factoring_approved === 'approved' ?
          <ButtonHaulPayVerified />
          : null
      }
      <SignUpButtonLink />
    </div>,
  ],
  undefined,
  {
    desktop: ({ bid }) => ({
      id: `${HASH_ID_PREFIX}${bid.id}`,
      style: {
        textAlign: 'left',
      },
    }),
    mobile: ({ bid }) => ({
      id: `${HASH_ID_PREFIX}${bid.id}`,
    }),
  },
);

export default compose(
  Resource('load', {
    idPropName: 'load_id',
  }),
  connect(
    state => {
      const self = getSelf(state);
      return { self, isSubmittingPayment: isSubmitting(FORM_CARRIER_PAYMENT)(state), isFactoringBroker: isFactoringBroker(state) };
    },
    {
      openModal,
      closeModal,
      submitPaymentForm: submit,
    },
  ),
  branch(
    ({ item, self }) => (
      !item ||
      !item.data ||
      item.isFetching ||
      !self ||
      !self.data ||
      (item.data.user_id !== self.data.id && !intersectionBy(get(item.data, 'user.data.groups', []), self.data.groups, 'id').length)
    ),
    renderComponent(placeholder),
    i => i,
  ),
  withState('toggled', 'setToggled', ({ defaultOpen, item: { data: { bids } } }) => defaultOpen && bids && bids.length),
  scrollToAnchor(),
)(({ load_id, item, self, openModal, toggled, setToggled, isSubmittingPayment, submitPaymentForm, isFactoringBroker, closeModal }) => {

  let { bids, funding_request = {}, id, user_load_number, targetRate } = item.data;
  if (!bids) {
    bids = [];
  }
  const load_length = get(item, 'data.length', 0);
  const acceptedBid = bids.find(bid => bid.bid_status === 'accepted');
  const should_show_upgrade_to_view_button = !(get(self, ['data', 'permissions', 'is_subscribed']) || get(self, ['data', 'permissions', 'is_trailing'])) && [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(self.data.type);
  return (
    <div className='TableItemAddonBids'>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          flexDirection: 'row',
          justifyContent: 'space-between',
          fontWeight: 'bold',
        }}
        >
        <div>
          <LoadStatusForm id={load_id} />
        </div>
        {
          bids.length ?
            <div className='text-center' style={{ flexGrow: 1 }}>
              <a role='button' onClick={() => setToggled(!toggled)}>
                {
                  acceptedBid ?
                    <span className='text-success'>Accepted Bid of {formatPennies(acceptedBid.amount)}</span>
                    :
                    <span>{toggled ? 'Hide' : 'View'} ({bids.filter(bid => bid.status !== 'pending').length}) Pending Bids</span>
                }
              </a>
            </div>
            : null
        }
        {
          funding_request && get(funding_request, 'attachments_links', []).length ?
            <div>
              <span className='text-danger text-uppercase'><b>Invoice Pending: </b></span>
              {funding_request.attachments_links.map(({ filename, url, download_url }, i) =>
                <LoadAttachmentButton
                  key={i}
                  title={filename}
                  getURL={() => Promise.resolve({
                    preview_url: url,
                    download_url,
                  })}
                />
              )}
            </div>
            : null
        }
        { isEmpty(funding_request) && Boolean(user_load_number) && isFactoringBroker &&
          <Button
            size='small'
            className='btn btn-green'
            style={{ flex: 0.1, justifySelf: 'center' }}
          onClick={() => openFundingRequestPaymentModal({
                    user_load_number,
                    load_length,
                    carrier_amount: targetRate,
                    load_id: id,
                  }, 
                  closeModal, 
                  openModal, 
                  onSubmitPaymentRequest,
                  isSubmittingPayment,
                  submitPaymentForm
              )}
            >
            $ Request Payment
          </Button>
        }
        {(!isEmpty(funding_request) && has(funding_request, 'id')) && isFactoringBroker &&
        <Link to={`/haul-pay/${funding_request.id}/transaction/`}>
          <Button
            size='small'
            className='btn btn-green'
            style={{ flex: 0.1, justifySelf: 'center' }}
            >
          View Payment Request
          </Button>
        </Link>
        }
      </div>
      {
        toggled ?
          <ResponsiveTable
            items={bids.map(bid => ({
              load_id,
              bid,
              acceptedBid,
              should_show_upgrade_to_view_button,
              openModal,
            }))}
            headers={['Bidding Company', 'Company Info', 'Current Bid', 'Reporting']}
            {...TableComponents}
          />
          : null
      }
    </div>
  );
}
);
