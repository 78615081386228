/**
 * Reducer for handling the loadalert settings actions
 * @module reducers/settings/loadalerts
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/settings/loadalerts
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.settings.loadalerts;

import loadalertsSettingsResource from '../../actions/settings/loadalerts';


export default function loadalertsSettingsReducer(state = initialState, action) {
  state = loadalertsSettingsResource.reduce(state, action);
  return state;
}
