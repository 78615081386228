import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { mapProps } from 'recompose';
import cs from 'classnames';

import Autocomplete from 'components/pure/Autocomplete';
import LabeledInput from './LabeledInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import { get } from 'lodash';
import getSelf from 'selectors/getSelf';
import withState from 'recompose/withState';
import { getPaymentProfile } from '../../../../actions/groups';

const MCDOTAutoCompleteSmall = ({ carrier, icon, ...rest }) => {
    const status = get(carrier, 'status', "");
    const is_connected = get(carrier, 'is_connected', false);
    const isRequiredCoBrokeringAgreement = ['BROKER_AUTH'].includes(get(carrier, 'authority', ''));
    return (
        <div {...rest} >
            {icon}
            <div className='pac-item-query' style={{ display: 'inline-block', paddingLeft: 6, width: 'calc(100% - 22px)', lineHeight: 'normal', marginTop: 3, whiteSpace: 'normal' }}>
                <div><p>{`${get(carrier, 'owner_name_first', null)} ${get(carrier, 'owner_name_last', null)}`}</p></div>
                <div><p>{get(carrier, 'company_name', null)}</p></div>
                <div style={{ marginTop: 5, marginBottom: 5 }}><span className={cs('text-uppercase', {
                    'text-success': ['approved', 'paid', 'remote_approved', 'invoice_paid', 'carrier_paid'].includes(status),
                    'text-warning': ['pending', 'special_pending', 'fuel_advance', 'needs_review'].includes(status),
                    'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
                    'text-blue': ['new'].includes(status),
                })}>{`${status} ${is_connected ? "| connected" : "| not connected"} `}</span>
                    {isRequiredCoBrokeringAgreement &&
                        <span className='text-red'>Co-Broker Agreement Required</span>
                    }
                </div>
                <div style={{ fontSize: '0.9em' }}>
                    {[
                        [
                            get(carrier, 'company_mc', null) ? `MC# ${get(carrier, 'company_mc', null)}` : null,
                            get(carrier, 'company_dot', null) ? `DOT# ${get(carrier, 'company_dot', null)}` : null,
                        ].filter(Boolean).join(', '),
                    ].filter(Boolean).map((val, i) =>
                        <div key={i}>{val}</div>
                    )}
                </div>
                <div><p>{get(carrier, 'owner_email', null) ? get(carrier, 'owner_email', null) : null}</p></div>
            </div>
        </div>
    )
};

export const MCDOTAutoCompleteItem = ({ value, ...rest }) => {
    return (
        <MCDOTAutoCompleteSmall
            role='button'
            className='pac-item'
            icon={
                <MaterialIcon size={20} name='account_box' className='pac-icon' style={{ verticalAlign: 'top', color: '#498504' }} />
            }
            carrier={value}
            {...rest}
        />
    );
};

const FIELD_NAME_MAP = {
    MC: 'mc',
    DOT: 'dot'
}

const MCDOTAutoCompleteInput = compose(
    withState("fieldName", "setFieldName", FIELD_NAME_MAP.MC),
    connect(
        state => ({
            self: getSelf(state),
        }),
        (dispatch, { input, onSelect, client_id, ...props }) => {
            if (onSelect) {
                const originalOnChange = input.onChange;
                input = {
                    ...input,
                    onChange(val) {
                        onSelect(val);
                    },
                };
            }
            return {
                input,
                formatValue: val => {
                    return val;
                },
                dispatch,
            };
        },
    ),
    mapProps(props => {
        return {
            getSuggestions: async (value, type) => {
                if (!value) {
                    return [];
                }
                try{
                    const { results } = await props.dispatch(getPaymentProfile({
                        limit: 20,
                        [type]: value,
                        status: "approved,needs_review,pending"
                    }));
                return results;
                } catch (e) {
                    console.log("ERROR", e);
                }
                return [];
            },
            MCorDOTInput: ({ onChange, onFocus, value, onBlur, onKeyDown, ...rest }) =>
                <div
                    className={cs('input-group', {
                        'input-group-lg': /input-lg/.test(props.className),
                        'input-group-sm': /input-sm/.test(rest.className),
                    })}
                >
                    <input
                        {...rest}
                        onChange={onChange}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        onKeyDown={onKeyDown}
                        className={cs('form-control', rest.className)}
                        value={value}
                    />
                    <span className='input-group-btn'>
                        <button
                            className={cs('btn btn-default', { active: props.fieldName === FIELD_NAME_MAP.MC })}
                            type='button'
                            onClick={() => props.setFieldName('mc')}
                        >
                            MC
                        </button>
                        <button
                            className={cs('btn btn-default', { active: props.fieldName === FIELD_NAME_MAP.DOT })}
                            type='button'
                            onClick={() => props.setFieldName('dot')}
                        >
                            DOT
                        </button>
                    </span>
                </div>,
            ...props
        };
    })
)(props =>
    <LabeledInput
        {...props}
        getSuggestions={value => props.getSuggestions(value, props.fieldName)}
        onFocus={() => props.input.onFocus(props.input.value)}
        onBlur={() => props.input.onBlur(props.input.value)}
        onChange={undefined}
        onSelect={props.input.onChange}
        ComponentClass={Autocomplete}
        customInput={props.MCorDOTInput}
        DropdownItem={MCDOTAutoCompleteItem}
        HistoryDropdownItem={MCDOTAutoCompleteItem}
        formatValue={val => {
            const isRequiredCoBrokeringAgreement = ['BROKER_AUTH'].includes(get(val, 'authority', ''));
            return get(val, 'company_name', '').concat(isRequiredCoBrokeringAgreement ? ' (Co-Broker Agreement Required)' : '')
        }}
        displayHistory={false}
    />
);

export default MCDOTAutoCompleteInput;
