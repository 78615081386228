import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import factoringdebtor from 'actions/resource/factoringdebtor';


export const factoringclientByfactoringdebtor = new ControlledPaginatedResourceCollection('factoringdebtor', 'factoringclient', {
  url: new UrlAssembler('debtor/:id/clients'),
  paginatedResourceOptions: {
    baseResource: factoringdebtor,
    append_to_top_on_create: true,
  },
});

export default factoringclientByfactoringdebtor;
export const {
  fetch,
  fetchNext,
  create,
  doDelete,
} = factoringclientByfactoringdebtor;
