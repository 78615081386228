import moment from 'moment';

import ControlledResource from 'datatypes/ControlledResource';


class FactoringPaymentNoteResource extends ControlledResource {

  parse(oldValue, json) {
    return super.parse(oldValue, {
      ...json,
      created: moment(json.created),
      modified: moment(json.modified),
    });
  }
}

export const factoringpaymentnoteResource = new FactoringPaymentNoteResource('factoringpaymentnote', {
  // still need to specify this
  url: id => `admin/factoring/notes/${id}`,
});

export default factoringpaymentnoteResource;
