/**
 * Component to display a different versino of a mobile-friendly truck view
 * @module components/pure/TruckMobile2
 * @since 3.0.1
 * @requires helpers
 * @requires datatypes/PermissionsError
 * @requires components/hoc/spinnerIfFetching
 * @requires components/container/ButtonCall
 * @requires components/pure/RouteFormat
 * @requires components/pure/TitledGrid
 * @property {object} props
 * @property {object} props.truck
 * @property {object} props.truck.data
 * @property {object} props.truck.data.origin_city
 * @property {object} props.truck.data.origin_state
 * @property {object} props.truck.data.destination_city
 * @property {object} props.truck.data.destination_state
 * @property {object} props.truck.data.targetRate
 * @property {object} props.truck.data.trailerType
 * @property {object} props.truck.data.length
 * @property {object} props.truck.data.weight
 * @property {object} props.truck.data.time_start
 * @property {object} props.truck.data.time_end
 * @property {object} [props.truck.data.time_delivery]
 * @property {object} props.truck.data.user
 * @property {object} props.truck.data.user.data
 * @property {object} props.truck.data.user.data.dba
 * @property {object} props.truck.data.id
 * @property {any} [props....rest] - props passed to containing component
 */
import '../../../styles/TruckMobile2.scss';
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import PermissionsError from 'datatypes/PermissionsError';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import RouteFormat from 'components/pure/RouteFormat';
import TitledGrid from 'components/pure/TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonCall from 'components/container/ButtonCall';


const TruckMobile2 = compose(
  spinnerIfFetching('truck'),
)(({ truck, unSave, ...rest }) =>
  <div
    {...rest}
    className={cs('truck-mobile-2 orange-truck', rest.className, {
      'truck-mobile-2-viewed': truck.globals.viewed,
    })}
    >
    <RouteFormat.Truck truck={truck} />
    {unSave ? <div className='pull-right'><button type='button' className='btn btn-danger' onClick={unSave}>X</button></div> : null}
    {truck.data.targetRate ? <div className='truck-mobile-2-target-rate'>{formatPennies(truck.data.targetRate)} / mi</div> : null}
    <div className='truck-mobile-truck-physical-info'>
      {truck.data.trailerTypes && truck.data.trailerTypes.length > 2 ? truck.data.trailerTypes.join(', ') : truck.data.trailerTypes.map(tt => trailerTypeMap[tt]).join(', ') || 'Any'}
      {' / '}
      {truck.data.length ? `${truck.data.length} ft` : '—'}
      {' / '}
      {truck.data.weight ? `${formatWeight(truck.data.weight)} lbs` : '—'}
    </div>
    <TitledGrid cols={3} justified={true}>
      <TitledGrid.Section title='ready'>
        {truck.data.time_start.format('D MMM')}
      </TitledGrid.Section>
      <TitledGrid.Section title='close'>
        {truck.data.time_end.format(`D MMM${truck.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}
      </TitledGrid.Section>
      <TitledGrid.Section title='truck age'>
        <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(truck.data.time_posted)}
      </TitledGrid.Section>
    </TitledGrid>
    <div className='truck-mobile-2-bottom'>
      <div className='truck-mobile-2-bottom-left'>
        <div>{truck.data.user.data.dba}</div>
      </div>
      {
        truck.data.user_type !== USER_TYPE.SHIPPER && (truck.data.user.data.contact_phone || truck.data.user.err instanceof PermissionsError) ?
          <div className='truck-mobile-2-bottom-middle'>
            <ButtonCall tel={truck.data.user.data.contact_phone} />
          </div>
          : null
      }
      <div>
        <Link to={`/truck/${truck.data.id}`}><button className='btn btn-orange'>DETAILS</button></Link>
      </div>
    </div>
  </div>
);

export default TruckMobile2;
