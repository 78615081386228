/**
 * Reducer for handling the notification icon actions
 * @module reducers/notification
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/notification
 * @listens NOTIFICATION_SET
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.notification;

import { NOTIFICATION_SET } from '../actions/notification';
import { activityPaginatedResource } from '../actions/resource/activity';


export default function notificationReducer(state = initialState, action) {
  switch (action.type) {
    case NOTIFICATION_SET:
      return action.payload;
    // don't show the red bit anymore if we just fetched the activities
    case activityPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return false;
  }
  return state;
}
