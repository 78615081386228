/**
 * Root reducer for the resource by user paginated resource collection reducers
 * @module reducers/resourceBy/user
 * @since 3.0.0
 * @requires reducers/resourceBy/user/truck
 * @requires reducers/resourceBy/user/truckhistory
 * @requires reducers/resourceBy/user/load
 * @requires reducers/resourceBy/user/loadhistory
 * @requires reducers/resourceBy/user/rating
 * @requires reducers/resourceBy/user/note
 * @requires reducers/resourceBy/user/factoringpayment
 */
import { combineReducers } from 'redux-v3';

import truck from './truck';
import truckhistory from './truckhistory';
import load from './load';
import haulPayLoad from './haulPayLoad';
import loadhistory from './loadhistory';
import rating from './rating';
import note from './note';
import factoringpayment from './factoringpayment';
import savedload from './savedload';
import savedtruck from './savedtruck';
import dispatchedload from './dispatchedload';
import onboarddebtor from './onboarddebtor';
import factoringTerms from './factoringTerms';

export default combineReducers({
  truck,
  truckhistory,
  load,
  haulPayLoad,
  loadhistory,
  rating,
  note,
  factoringpayment,
  savedload,
  savedtruck,
  dispatchedload,
  onboarddebtor,
  factoringTerms,
});
