/**
 * Connected form component to display the form that registers a user with TAFS
 * @module components/pure/form/TAFSForm
 * @since 3.0.0
 * @todo Actually send the user data to TAFS instead of pretending to
 * @requires actions/ui
 * @requires components/form/inputs/LabeledInput
 * @requires components/form/inputs/CleaveInput
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {function} [closeModal] - pass the function to close the modal if this component is being used in a modal
 */
/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import { browserHistory } from 'react-router-v4';

import { USER_TYPE } from 'helpers';
import { closeModal, openModal } from 'actions/ui';
import { sendFactoringData } from 'actions/user';
import normalizeFormPhone from 'helpers/normalizeFormPhone';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';
import Spinner from 'components/pure/Spinner';
import getSelf from 'selectors/getSelf';

export default compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
    {},
  ),
  reduxForm({
    form: 'TAFSForm',
    destroyOnUnmount: false,
    validate(fields) {
      const errs = validate(fields, {
        name: {
          presence: true,
        },
        company: {
          presence: true,
        },
        email: {
          presence: true,
          email: true,
        },
        phone: {
          presence: true,
          phone: true,
        },
        mc: {
          numericality: {
            onlyInteger: true,
          },
        },
        dot: {
          numericality: {
            onlyInteger: true,
          },
        },
      });
      if (!fields.mc && !fields.dot) {
        errs.mc = errs.dot = 'Please enter an MC# or DOT#';
      }
      return errs;
    },
    async onSubmit(fields, dispatch, { showAsCarrier, self: { data: { type, fmcsa_type } = {} } = {} }) {
      try {
        await dispatch(sendFactoringData({
          ...fields,
          phone: normalizeFormPhone(fields, 'phone'),
        }));
        window.analytics.track('HaulPayInterest', { type: type, content_name: 'haul-pay' });
        if (showAsCarrier || fmcsa_type === USER_TYPE.CARRIER || type === USER_TYPE.DISPATCHER) {
          browserHistory.push('/haul-pay/join');
        }
        else if (type === USER_TYPE.BROKER || fmcsa_type === USER_TYPE.BROKER) {
          dispatch(openModal('success', { message: 'Thank you for your interest in factoring and haul pay.' }));
        }
        dispatch(closeModal()); // close ourselves
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error'));
      }
    },
  }),
)(({ closeModal, submitting, handleSubmit, showAsCarrier, self: { data: { fmcsa_type } = {} } }) =>
  <div>
    <Field name='name' component={LabeledInput} label='Name' className='input-lg' />
    <Field name='company' component={LabeledInput} label='Company' className='input-lg' />
    <Field name='email' component={LabeledInput} label='Email' className='input-lg' />
    <div className='row'>
      <div className='col-md-6'>
        <Field name='phone' component={PhoneInput} label='Phone' className='input-lg' />
      </div>
      <div className='col-md-6'>
        <Field name='phone_ext' component={LabeledInput} label='Phone Ext.' type='number' />
      </div>
    </div>
    <MCorDOTInputs fieldProps={{ className: 'input-lg' }} />
    {submitting ? <Spinner /> : null}
    <div className='form-group text-right'>
      <button type='submit' disabled={submitting} onClick={handleSubmit} className='btn btn-orange'>
        {
          showAsCarrier || fmcsa_type === USER_TYPE.CARRIER ?
            'Get Started'
            :
            'Learn More'
        }
      </button>
      {closeModal && <button name='nothanks' className='btn btn-default pull-left' onClick={closeModal}>No Thanks</button>}
    </div>
  </div>
);
