/**
 * Component for viewing a list of users given their user type and whether they're subscribed
 * @module components/container/UserTypeList
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/user
 * @requires components/pure/CenteredColumn
 * @requires components/pure/Header
 * @requires components/pure/RouteTabs
 * @requires components/pure/ResponsiveTable
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';
import getSelfType from 'selectors/getSelfType';
import getSelf from 'selectors/getSelf';
import { USER_TYPE } from 'helpers';
import createTableItems from 'helpers/createTableItems';

import { clear, fetch, fetchNext, sort } from 'actions/search/user';

import Header from 'components/pure/Header';
import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import AdminDropdownInput, { noneSelect } from 'components/pure/form/inputs/AdminDropdownInput';

const UserTypeListTableComponents = createTableItems(
  ({ data }) => [
    <Link to={`/admin/user/search/${data.id}`}>{data.email}</Link>,
    <Link to={`/admin/user/search/${data.id}`}>{data.contact_email}</Link>,
    data.factoring ? data.factoring.factoring_approved : '',
    data.user_type,
    moment(data.created).format('l LT'),
    moment(data.last_login).format('l LT'),
    data.state,
    data.zip,
    <span style={{ maxWidth: 200, display: 'inline-block', wordWrap: 'break-word' }}>
      {data.source || 'Unknown'}
    </span>,
    <Link to={`/admin/user/search/${data.id}`}>
      <button className='btn btn-orange'>More</button>
    </Link>,
  ],
  i => i,
  {
    mobile: () => ({
      style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
    }),
  },
);

const UserTypeListTable = compose(
  connect(
    state => ({
      ids: state.search.user.ids,
      users: state.resource.usersearch,
      isFetching: state.search.user.isFetching,
      currentOrder: state.search.user.ordering,
    }),
    {
      sort,
      fetchNext,
    },
  ),
)(({ ids, users, isFetching, fetchNext, sort, currentOrder }) =>
  <ResponsiveTable
    isFetching={isFetching}
    fetchNext={fetchNext}
    sort={sort}
    currentOrder={currentOrder}
    containerProps={{
      className: 'col-fill',
    }}
    desktopContainerProps={{
      className: 'table-condensed',
    }}
    headers={[
      { text: 'Email', ordering: false },
      { text: 'Contact Email', ordering: false },
      { text: 'HP Status', ordering: 'hp_status' },
      { text: 'User Type', ordering: false },
      { text: 'Created At', ordering: 'created' },
      { text: 'Last Login', ordering: false },
      { text: 'State', ordering: false },
      { text: 'Zip Code', ordering: false },
      { text: 'Source', ordering: false },
      { text: 'More', ordering: false },
    ]}
    items={ids.map(id => users[id])}
    placeholder='No results'
    sortable={true}
    TableItem={UserTypeListTableComponents.TableItem}
    BlockTableItem={UserTypeListTableComponents.BlockTableItem}
  />
);

const LoadMoreButton = compose(
  connect(
    state => state.search.user,
    {
      fetchNext,
    },
  ),
)(({ next, isFetching, fetchNext }) => next && !isFetching ? <button className='btn btn-orange' onClick={fetchNext}>Load More</button> : null);

const fetchUserTypes = function fetchUserTypes(nextProps = this.props, nextState = this.state) {
  const { clear, fetch, contact_status, assigned_admin } = nextProps;
  const data = {
    assigned_admin: nextState && nextState.selectedAdmin && nextState.selectedAdmin.id ? nextState.selectedAdmin.id : assigned_admin,
    contact_status,
  };
  clear();
  fetch(data);
};

let saved_selectedAdmin = noneSelect;

const UserTypeList = compose(
  connect(
    state => ({
      self_user_type: getSelfType(state),
      assigned_admin: getSelf(state).data.id,
    }),
    {
      clear,
      fetch,
    },
  ),
)(class MyUserHaulPayInterestedList extends React.Component {
  state = {
    selectedAdmin: saved_selectedAdmin,
  };

  componentWillMount() {
    return fetchUserTypes.apply(this, arguments);
  }

  componentWillUpdate() {
    return fetchUserTypes.apply(this, arguments);
  }

  render() {
    const { user_type, self_user_type } = this.props;
    const { selectedAdmin } = this.state;

    return (
      <div style={{ padding: '0 1em' }}>
        <RouteTabs
          routes={[
            ['../no_contact', 'No Contact'],
            ['../first', 'First Contact'],
            ['../second', 'Second Contact'],
            ['../third', 'Third Contact'],
          ]}
          extras={
            self_user_type === USER_TYPE.ADMIN ?
              <div className='pull-right'>
                <AdminDropdownInput
                  label={null}
                  input={{
                    value: selectedAdmin,
                    onChange: admin => {
                      saved_selectedAdmin = admin;
                      this.setState({ selectedAdmin: admin });
                    },
                  }}
                />
              </div>
              : null
          }
        />
        <Header>My Assigned Users</Header>
        <UserTypeListTable user_type={user_type} />
        <LoadMoreButton />
      </div>
    );
  }
});

export default UserTypeList;
