/**
 * Reducer for handling the truckhistory by user resource actions
 * @module reducers/resourceBy/user/truckhistory
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/user/truckhistory
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.user.truckhistory;

import truckhistoryByUserResource from '../../../actions/resourceBy/user/truckhistory';


export default function truckhistoryByUserResourceReducer(state = initialState, action) {
  state = truckhistoryByUserResource.reduce(state, action);
  return state;
}
