/**
 * Higher order component for modals. Automatically calls `props.closeModal` after `props.timeout` milliseconds
 * @module components/hoc/ModalTimeout
 * @since 3.0.0
 * @property {object} props
 * @property {function} props.closeModal - function to close the modal
 * @property {number} [props.timeout] - millisecond timeout to close the modal
 * @property {any} [props....rest] - props passed to wrapped component
 */
import { compose } from 'redux-v3';
import lifecycle from 'recompose/lifecycle';
import setDisplayName from 'recompose/setDisplayName';


export default compose(
  setDisplayName('ModalTimeout'),
  lifecycle({
    componentDidMount() {
      const { closeModal, timeout = 9999999 } = this.props;
      this.timeout = setTimeout(() => {
        closeModal();
        this.timeout = undefined;
      }, timeout);
    },
    componentWillUnmount() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
  }),
);
