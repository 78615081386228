import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const ButtonToggleInput = ({ input, data }) =>
  <div className='form-group'>
    <div className='btn-group btn-group-justified'>
      {data.map(data =>
        <a
          key={data.value}
          onClick={() => input.onChange(data.value)}
          className={cs('btn btn-default', { active: input.value === data.value })}
          >
          {data.text}
        </a>
      )}
    </div>
  </div>
  ;

ButtonToggleInput.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired,
    value: PropTypes.any.isRequired,
  })).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
  }),
};

export default ButtonToggleInput;
