import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, withState } from 'recompose';
import moment from 'moment';
import { openModal } from 'actions/ui';
import { getReceivable } from 'actions/receivable';
import EventLog from 'components/pure/EventLog';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import { flatten, get } from 'lodash';

const StatusMapping = {
  'open': 'Open',
  'payment_successful': 'Payment Successful',
  'needs_review': 'Needs Review',
};

const tableItems = createTableItems(
  props => {
    return [
      formatPennies(props.amount_paid),
      formatPennies(props.amount_balance),
      props.sch ? props.sch : null,
      moment(props.created).format('MM/DD/YY hh:mm:ss a'),
      StatusMapping[props.status],
      props.assigned_admin ? props.assigned_admin.name : null,
      props.history ?
        <button
          className='btn btn-orange'
          type='button'
          onClick={() => props.onNoteClick(
            <EventLog
              events={props.history.filter(hist => {
                const adminNotes = get(hist, 'diff.values_changed["root[\'admin_notes\']"]', {});
                const keys = Object.keys(adminNotes);
                return keys.length;
              })}
            />
          )
          }
          >
          Notes
        </button> : null,
    ];
  }
);

export default compose(
  connect(
    null,
    dispatch => ({
      getReceivable: id => dispatch(getReceivable(id)),
      clickNotes: message => dispatch(openModal('notes', { message })),
    }),
  ),
  withState('receivable', 'setReceivable'),
  lifecycle({
    async componentDidMount() {
      const receivable = await this.props.getReceivable(this.props.id);
      this.props.setReceivable(receivable);
    },
  }),
)(({ receivable, clickNotes }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Amount', 'Balance', 'ACH #', 'Paid At', 'Status', 'Assigned Admin', 'Admin Notes']}
    isFetching={false}
    fetchNext={console.log}
    items={receivable ? [receivable].map(r => {
      r.onNoteClick = clickNotes;
      return r;
    }) : []}
    TableItem={tableItems.TableItem}
    BlockTableItem={tableItems.BlockTableItem}
  />
);
