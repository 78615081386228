/**
 * Component to display a different version of a mobile-friendly load view. Used on Saved Loads
 * @module components/pure/LoadMobile4
 * @since 3.0.1
 */
/* global EMPTY_PLACEHOLDER */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import PermissionsError from 'datatypes/PermissionsError';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import RouteFormat from 'components/pure/RouteFormat';
import TitledGrid from 'components/pure/TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonCall from 'components/container/ButtonCall';
import ButtonQuickPayApproved from 'components/container/ButtonQuickPayApproved';
import LoadStatusForm from 'components/pure/form/LoadStatusForm';


const LoadMobile2 = compose(
  spinnerIfFetching('load'),
)(({ load, unSave, ...rest }) =>
  <div
    {...rest}
    className={cs('load-mobile-2', rest.className, {
      'load-mobile-2-viewed': load.globals.viewed,
      'yellow-shipper': load.data.user_type === USER_TYPE.SHIPPER,
      'blue-broker': [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(load.data.user_type),
    })}
    >
    <RouteFormat origin={load.data.origin_locations} destination={load.data.destination_locations} />
    {unSave ? <div className='pull-right'><button type='button' className='btn btn-danger' onClick={unSave}>X</button></div> : null}
    <div className='load-mobile-2-physical-info'>{trailerTypeMap[load.data.trailerType]} / {load.data.length ? `${load.data.length} ft` : '—'} / {load.data.weight ? `${formatWeight(load.data.weight)} lbs` : '—'}</div>
    <TitledGrid cols={4} justified={true}>
      <TitledGrid.Section title='ready' bottom={true}>
        <div>{load.data.time_start.format('D MMM')}</div>
        <div style={{ fontSize: '0.7em' }} >
          {load.data.time_start.format('LT')}
        </div>
      </TitledGrid.Section>
      <TitledGrid.Section title='close' bottom={true}>
        <div>{load.data.time_end.format(`D MMM${load.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}</div>
        <div style={{ fontSize: '0.7em' }}>
          {load.data.time_end.format('LT')}
        </div>
      </TitledGrid.Section>
      <TitledGrid.Section title='deliver by' bottom={true}>
        {load.data.time_delivery ? load.data.time_delivery.format('D MMM') : EMPTY_PLACEHOLDER}
      </TitledGrid.Section>
      <TitledGrid.Section title='load age' bottom={true}>
        <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(load.data.time_posted)}
      </TitledGrid.Section>
    </TitledGrid>
    <div className='load-mobile-2-bottom'>
      <div className='load-mobile-2-bottom-left'>
        <div>
          {load.data.user.data.dba}
          {load.data.user_type === USER_TYPE.SHIPPER ? <img className='load-mobile-2-shippericon' src='/public/image/shipper_load.png' /> : null}
        </div>
      </div>
      {
        load.data.user_type !== USER_TYPE.SHIPPER && (load.data.user.data.contact_phone || load.data.user.err instanceof PermissionsError) ?
          <div className='load-mobile-2-bottom-middle'>
            <ButtonCall tel={load.data.user.data.contact_phone} />
          </div>
          : null
      }
      <div>
        <Link to={`/load/${load.data.id}`}><button className='btn btn-orange'>DETAILS</button></Link>
      </div>
    </div>
    <div
      style={{
        marginTop: '0.5em',
        display: 'flex',
      }}
      >
      <ButtonQuickPayApproved style={{ marginRight: '0.5em' }} load_id={load.data.id}>Get Paid Now</ButtonQuickPayApproved>
      <LoadStatusForm
        id={load.data.id}
        style={{
          flexGrow: 1,
          maxWidth: 200,
        }}
      />
    </div>
  </div>
);

export default LoadMobile2;
