/**
 * Actions relating to the factoringpayment resource
 * @module actions/resource/factoringpayment
 * @since 3.0.1
 * @requires datatypes/compose/resource
 * @requires datatypes/compose/resource/Createable
 * @requires datatypes/compose/resource/Fetchable
 * @requires datatypes/compose/resource/Editable
 * @requires datatypes/FetchError
 * @requires datatypes/DotNotationFormData
 */
/* global API */
import { compose } from 'redux-v3';
import moment from 'moment';

import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';
import { isEmpty, compact } from 'lodash';
import querystring from 'querystring';
import axios from 'axios';

const validateCroppedAttachments = croppedAttachments => {
  if (!croppedAttachments || !Array.isArray(croppedAttachments)) {
    return false;
  }

  const areAttachmentsValid = croppedAttachments.every(attachment => {
    return (attachment instanceof Blob) && attachment.filename && attachment.name && attachment.size && attachment.type;
  });

  if (!areAttachmentsValid) {
    return false;
  }

  return true;
};

class FactoringPaymentResource extends compose(
  Editable(),
  /**
   * Function for submitting a factoring payment request request
   * @param {object} data
   * @param {number} data.should_create_invoice
   * @param {number} data.amount
   * @param {string} data.debtor
   * @param {string} data.bill_to_address
   * @param {string} data.bill_to_address.street_one
   * @param {string} data.bill_to_address.city
   * @param {string} data.bill_to_address.state
   * @param {string} data.bill_to_address.zip
   * @param {string} data.bill_to_address.country
   * @param {object} data.first_origin_location
   * @param {string} data.first_origin_location.street_one
   * @param {string} data.first_origin_location.city
   * @param {string} data.first_origin_location.state
   * @param {string} data.first_origin_location.zip
   * @param {string} data.first_origin_location.country
   * @param {object} data.final_destination_location
   * @param {string} data.final_destination_location.street_one
   * @param {string} data.final_destination_location.city
   * @param {string} data.final_destination_location.state
   * @param {string} data.final_destination_location.zip
   * @param {string} data.final_destination_location.country
   * @param {number} data.load_length
   * @param {string} data.load_trailer_type
   * @param {(File|string)[]} data.attachments
   */
  Createable(),
  Fetchable({
    url: id => `factoring/funding/request/${id}/?details=extended`,
  }),
)() {
  // overwrite the create function injected earlier by `Createable`
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, CREATE_KEY);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });

      try {
        const { attachments } = data;
        data.attachments = attachments.map(({ name = '', category = '' }) => {
          if (name) {
            return {
              filename: name,
              category: category,
            };
          }
          return;
        });
        const url = (data.isBillOut || data.nonFactored) ? `${API.host}/factoring/funding/request/non_factored/` : `${API.host}/factoring/funding/request/`;
        const res = await dispatch(APIFetch(url, {
          method: 'POST',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 201) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();

        await Promise.all(json.attachments.map(({ upload_url: { url, fields } }, i) => {
          const formData = new FormData();
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              formData.append(key, fields[key]);
            }
          }
          formData.append('file', attachments[i]);
          return global.fetch(url, {
            method: 'POST',
            body: formData,
          });
        }));

        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
  // overwrite the edit function injected earlier by `Editable`
  edit(id, data, params) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, id);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      try {
        const { attachments = [], cropped_attachments = [] } = data;

        const validatedCroppedAttachments = validateCroppedAttachments(cropped_attachments) ? cropped_attachments : [];

        const allAttachments = compact(attachments.concat(validatedCroppedAttachments));
        data.attachments = allAttachments.map(({ name = '', category = '', visible_to = [] }) => {
          if (name) {
            return {
              filename: name,
              category: category,
              visible_to,
            };
          }
          return;
        });
        const queryParams = params => {
          return Object.keys(params)
            .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
            .join('&');
        };

        const res = await dispatch(APIFetch(`${API.host}/factoring/funding/request/${id}/?details=extended${!isEmpty(params) ? `&${queryParams(params)}` : ''}`, {
          method: 'PATCH',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();

        await Promise.all(json.attachments.slice(item.data.attachments.length).map(({ upload_url: { url, fields } }, i) => {
          const formData = new FormData();
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              formData.append(key, fields[key]);
            }
          }
          formData.append('file', allAttachments[i]);
          return global.fetch(url, {
            method: 'POST',
            body: formData,
          });
        }));

        return dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: {
            id,
            json,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
}

const factoringpayment = new FactoringPaymentResource('factoringpayment', {
  parse(oldValue, newData) {
    return Object.getPrototypeOf(this).parse(oldValue, newData ? {
      ...newData,
      created: moment(newData.created),
      modified: moment(newData.modified),
      funded_at: newData.funded_at ? moment(newData.funded_at) : newData.funded_at,
    } : undefined);
  },
});

export const {
  selector,
  fetch,
  fetchIfNeeded,
  create,
  edit,
  abort,
} = factoringpayment;
export default factoringpayment;

export const fetchInvoice = (payment_id, params = { document: 'invoice' }) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/funding/request/${payment_id}/files/?${querystring.stringify(params)}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });

  if (res.status !== 200) {
    throw res;
  }
  return await res.json();
};
export const fetchCollated = (payment_id, params = { document: 'collated' }) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/funding/request/${payment_id}/files/?${querystring.stringify(params)}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });

  if (res.status !== 200) {
    throw res;
  }
  return await res.json();
};

export const FACTORING_PAYMENT_ATTACHMENT_DELETE = Symbol('FACTORING_PAYMENT_ATTACHMENT_DELETE');
export const deleteAttachment = (id, attachment) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/factoring/funding/request/${id}/?file_id=${attachment}`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  if (res.status !== 204) {
    const text = await res.text();
    throw new FetchError(res.status, text);
  }
  dispatch({
    type: FACTORING_PAYMENT_ATTACHMENT_DELETE,
    payload: {
      id,
      attachment,
    },
  });
};

export const FACTORING_PAYMENT_DELETE = Symbol('FACTORING_PAYMENT_DELETE');
export const deleteFactoringPayment = id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/funding/request/${id}/`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status !== 204) {
    throw res;
  }
  dispatch({
    type: FACTORING_PAYMENT_DELETE,
    payload: id,
  });
};
export const FACTORING_PAYMENT_ATTACHMENTS = Symbol('FACTORING_PAYMENT_ATTACHMENTS');
export const fetchPaymentRequestAttachments = id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${id}/attachments/`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status !== 200) {
    throw res;
  }
  const attachments = await res.json();
  dispatch({
    type: FACTORING_PAYMENT_ATTACHMENTS,
    payload: {
      attachments,
      id,
    },
  });
};

export const updateAttachment = (requestId, attachment) => async (
  dispatch,
  getState
) => {
  const url = `/funding_requests/${requestId}/attachments/${attachment.id}/`;
  const options = {
    url,
    method: 'PATCH',
    data: attachment,
  };
  return axios(options);
};
