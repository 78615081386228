import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.user.dispatchedload;

import dispatchedloadByUserResource from 'actions/resourceBy/user/dispatchedload';
import { DISPATCH_LOAD_CANCEL } from 'actions/admin/dispatch';


export default function dispatchedloadByUserResourceReducer(state = initialState, action) {
  state = dispatchedloadByUserResource.reduce(state, action);
  switch (action.type) {
    case DISPATCH_LOAD_CANCEL: {
      const index = state[action.payload.user_id].ids.indexOf(action.payload.load_id);
      return {
        ...state,
        [action.payload.user_id]: {
          ...state[action.payload.user_id],
          ids: [
            ...state[action.payload.user_id].ids.slice(0, index),
            ...state[action.payload.user_id].ids.slice(index + 1),
          ],
        },
      };
    }
  }
  return state;
}
