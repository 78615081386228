/**
 * Component that displays results for a search. Wraps PaginatedResults
 * @module components/pure/SearchResults
 * @since 3.0.0
 * @requires components/pure/PaginatedResults
 * @requires components/pure/ResponsiveContainer
 * @requires components/pure/NormalInfiniteScroll
 * @property {object} props
 * @property {string} props.id - if this searchresult is a subresource of another resource, this prop is passed to the GoogleMap in order for it to connect to that resource in state. See [TruckLoadMatchMap]{@link module:components/container/TruckLoadMatchMap}
 * @property {string[]} props.ids - a list of IDs of resources to display
 * @property {boolean} props.isFetching - whether we're currently fetching
 * @property {string} props.ordering - the current ordering
 * @property {string} props.view - the current view
 * @property {element} props.MobileComponent - the component to display on mobile sizes
 * @property {element} props.DesktopComponent - the component to display on desktop sizes
 * @property {element} props.GoogleMap - a component that wraps GoogleMap to display the search results for this resource on a map
 * @property {function} props.fetchNext - a function to fetch the next chunk
 * @property {function} props.sort - a function to sort
 * @property {function} props.setView - a function to change the view
 * @property {node|node[]} [props.placeholder] - an element to display in the case where there are no ids to display
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';

import PaginatedResults from 'components/pure/PaginatedResults';
import ResponsiveContainer from 'components/pure/ResponsiveContainer';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';


const SearchResults = ({
  id,
  ids,
  isFetching,
  ordering,
  view,
  breakpoint,

  MobileComponent,
  DesktopComponent,
  GoogleMap,

  refresh,
  fetchNext,
  sort,
  setView,

  placeholder = <div className='help-block' style={{ textAlign: 'center', marginTop: '10%' }}>No results yet.<br />Try searching for something else.</div>,
  ...rest
}) =>
  <PaginatedResults
    refresh={refresh}
    view={view}
    setView={setView}
    viewOptions={{
      list: {
        text: 'List',
        component: (
          <ResponsiveContainer
            style={{ height: '100%', overflow: 'auto' }}
            className='col-fill'
            breakpoint={breakpoint}
            mobile={
              <NormalInfiniteScroll
                isFetching={isFetching}
                onScrollBottom={fetchNext}
                placeholder={placeholder}
                >
                {ids.map(id => <MobileComponent key={id} id={id} />)}
              </NormalInfiniteScroll>
            }
            desktop={
              <DesktopComponent
                ids={ids}
                isFetching={isFetching}
                sort={sort}
                fetchNext={fetchNext}
                placeholder={placeholder}
                currentOrder={ordering}
              />
            }
          />
        ),
      },
      map: {
        text: 'Map',
        component: <GoogleMap ids={ids} id={id} />,
      },
    }}
    ordering={ordering}
    sort={sort}
    {...rest}
  />
  ;

SearchResults.propTypes = {
  view: PropTypes.oneOf(['list', 'map']).isRequired,
  isFetching: PropTypes.bool.isRequired,

  ids: PropTypes.arrayOf(PropTypes.string).isRequired,

  MobileComponent: PropTypes.func.isRequired,
  DesktopComponent: PropTypes.func.isRequired,

  sort: PropTypes.func.isRequired,
  setView: PropTypes.func.isRequired,
  fetchNext: PropTypes.func.isRequired,
};

export default SearchResults;
