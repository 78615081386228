import React from 'react';
import PropTypes from 'prop-types';

import BillingPackageSelection from 'components/pure/BillingPackageSelection';


const BillingPackageSelect = ({ input, meta, selects, ...rest }) =>
  <div {...rest}>
    {selects.map((select, i) =>
      <div
        key={i}
        style={{
          width: i === selects.length - 1 ? `calc(${1 / selects.length * 100}% + 2px)` : `${1 / selects.length * 100}%`,
          marginLeft: i > 0 ? -2 : undefined,
          display: 'inline-block',
        }}
        >
        <BillingPackageSelection {...select} role='button' active={input.value === select} onClick={() => input.onChange(select)} />
      </div>
    )}
  </div>
  ;

BillingPackageSelect.propTypes = {
  selects: PropTypes.arrayOf(PropTypes.shape({
    ...BillingPackageSelection.propTypes,
  })).isRequired,
};


export default BillingPackageSelect;
