import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';
import moment from 'moment';

import { ageAbbr, formatWeight, inferTruckRouteType, trailerTypeMap } from '../../helpers';

import ExpandableLineItem from './ExpandableLineItem';
import UserTable from './PureUserTable';


const TruckSearchTableItem = ({ open, viewed, data, toggleOpen, setViewed }) =>
  <ExpandableLineItem
    className='orange-truck'
    open={open}
    style={{ fontWeight: viewed ? undefined : 'bold' }}
    toggleOpen={() => toggleOpen(data.id)}
    collapsedChild={
      <tr>
        <td colSpan={11} style={{ padding: 0 }}>
          <UserTable
            items={[{
              item: data.user,
              truck_id: data.id,
            }]}
            isShipperOnly={data.user_type === 'shipper'}
            isTruckPost={true}
          />
        </td>
      </tr>
    }
    >
    {data ? (() => {
      const { origin, origin_radius, destination, destination_radius } = inferTruckRouteType(data);
      return (
        <tr>
          <td>{ageAbbr(data.time_posted)}</td>
          <td>
            {
              (!data.trailerTypes || data.trailerTypes.length === 0) ?
                'Any' :
                data.trailerTypes.length > 1 ?
                  data.trailerTypes.join(', ') :
                  data.trailerTypes.map(tt => trailerTypeMap[tt]).join(', ')
            }
          </td>
          <td style={{ maxWidth: 150 }}>{origin}</td>
          <td>{origin_radius}</td>
          <td style={{ maxWidth: 150 }}>{destination}</td>
          <td>{destination_radius}</td>
          <td>{data.weight === 0 ? '—' : formatWeight(data.weight)}</td>
          <td>{data.length === 0 ? '—' : data.length}</td>
          <td>{data.time_start.format('l')}</td>
          <td>{data.time_end.format('l')}</td>
          <td>
            <Link to={`/truck/${data.id}`}>
              <button onClick={e => setViewed(data.id)} className='btn btn-orange'>Details</button>
            </Link>
          </td>
        </tr>
      );
    })() : null}
  </ExpandableLineItem>
  ;

TruckSearchTableItem.propTypes = {
  open: PropTypes.bool.isRequired,
  viewed: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    trailerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    origin_city: PropTypes.string,
    origin_state: PropTypes.string,
    origin_radius: PropTypes.number,
    destination_city: PropTypes.string,
    destination_state: PropTypes.string,
    destination_radius: PropTypes.number,
    origin_region: PropTypes.arrayOf(PropTypes.string),
    destination_region: PropTypes.arrayOf(PropTypes.string),
    weight: PropTypes.number,
    length: PropTypes.number,
    time_start: PropTypes.instanceOf(moment).isRequired,
    time_end: PropTypes.instanceOf(moment).isRequired,
    time_posted: PropTypes.instanceOf(moment).isRequired,
    user: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
  toggleOpen: PropTypes.func.isRequired,
  setViewed: PropTypes.func.isRequired,
};

export default TruckSearchTableItem;
