import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Link } from 'react-router-v4';
import { ledgerWalletHistory } from 'actions/ledger';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import formatDollars from 'helpers/formatDollars';
import moment from 'moment';

const WalletHistoryItems = createTableItems(
    ({ funding_request_id, created, description, invoice_number, credit_amount = undefined, debit_amount = undefined, wallet_balance = '0', transaction_category  }) => {
        return [
            moment(created).format('l'),
            description,
            transaction_category === 'LEDGER_WALLET_DEPOSIT' ? invoice_number : <Link to={`/haul-pay/${funding_request_id}/transaction`} key={funding_request_id}>{invoice_number}</Link>,
            credit_amount ? formatDollars(credit_amount) : '',
            debit_amount ? formatDollars(debit_amount) : '',
            wallet_balance ? formatDollars(wallet_balance) : '',
        ];
    });

export default function WalletHistoryTable(props) {
    const [walletHistory, setWalletHistory] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    const [nextPage, setNextPage] = useState("");

    useEffect(() => {
        async function asyncFunction() {
            try {
                setIsFetching(true);
                const { results, next } = await ledgerWalletHistory();
                setWalletHistory(results);
                setNextPage(next);
            } catch (err) {}
            setIsFetching(false);
        }
        asyncFunction();
    }, []);

    const fetchNext = useCallback(async () => {
        try {
            setIsFetching(true);
            const res = await axios(nextPage);
            const { results, next } = res.data;
            setWalletHistory(walletHistory.concat(results));
            setNextPage(next);
         } catch (err) {}
        setIsFetching(false);
    }, [nextPage])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ResponsiveTable
                containerProps={{
                    className: 'col-fill'
                }}
                placeholder="No results."
                headers={['Date', 'Description', 'Invoice', 'Credit', 'Debit', 'Balance']}
                items={walletHistory}
                isFetching={isFetching}
                {...WalletHistoryItems}
            />
            {(nextPage && Boolean(walletHistory.length)) &&
                <button
                    style={{
                        margin: 20,
                        width: 150,
                        alignSelf: 'end'
                    }}
                    className="btn btn-orange"
                    onClick={fetchNext}
                >
                    Load More
                </button>
            }
        </div>
    )
}
