import 'styles/FactoringPaymentInvoice';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import formatPennies from 'helpers/formatPennies';


const FactoringPaymentInvoice = ({
  dba,
  name,
  address = {},
  bill_to,
  bill_to_address = {},
  bill_to_company_phone,
  bill_to_company_email,
  first_origin_location = {},
  final_destination_location = {},
  load_length,
  amount,
  user_reference_number,
  user_notes,
}) =>
  <div className='factoring-payment-invoice'>
    <div>
      <div className='factoring-payment-invoice-header'>Invoice</div>
      <div className='factoring-payment-invoice-user clearfix'>
        <div className='factoring-payment-invoice-user-header'>{dba}</div>
        <div>{name}</div>
        <div>{address.street_one}</div>
        <div>{address.city} {address.state} {address.zip}</div>
        <div>{address.country}</div>
      </div>
    </div>
    <div>
      <div className='factoring-payment-invoice-bill_to'>
        <p><b>Bill To:</b></p>
        <div>{bill_to ? bill_to.dba : ''}</div>
        <div>{bill_to_address.street_one}</div>
        <div>{bill_to_address.city} {bill_to_address.state} {bill_to_address.zip}</div>
        <div>{bill_to_address.country}</div>
        <div>{bill_to_company_phone}</div>
        <div>{bill_to_company_email}</div>
      </div>
      <div className='factoring-payment-invoice-details'>
        <table>
          <tbody>
            <tr>
              <td>
                <b>Invoice #</b>
              </td>
              <td>INV-0000001</td>
            </tr>
            <tr>
              <td>
                <b>Reference #</b>
              </td>
              <td>{user_reference_number}</td>
            </tr>
            <tr>
              <td>
                <b>Invoice Date</b>
              </td>
              <td>{moment().format('l LTS')}</td>
            </tr>
            <tr>
              <td>
                <b>Due Date</b>
              </td>
              <td>{moment().add(30, 'days').format('l LTS')}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div>
      <table className='factoring-payment-invoice-summary table'>
        <thead>
          <tr>
            <th />
            <th>Qty</th>
            <th>Rate</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>
                Linehaul (All-in)
                <br />
                {first_origin_location.address} {first_origin_location.city}, {first_origin_location.state} {first_origin_location.zip}
                <br />
                to
                <br />
                {final_destination_location.address} {final_destination_location.city}, {final_destination_location.state} {final_destination_location.zip}
                <br />
                {load_length} feet
              </b>
            </td>
            <td>1</td>
            <td>{formatPennies(amount)}</td>
            <td>{formatPennies(amount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>{user_notes}</p>
    <div>
      Total: <span className='pull-right'>{formatPennies(amount)}</span>
    </div>
  </div>
  ;

FactoringPaymentInvoice.propTypes = {
  dba: PropTypes.node,
  name: PropTypes.node,
  address: PropTypes.shape({
    street_one: PropTypes.node,
    city: PropTypes.node,
    state: PropTypes.node,
    zip: PropTypes.node,
    country: PropTypes.node,
  }),
  first_origin_location: PropTypes.shape({
    city: PropTypes.node,
    state: PropTypes.node,
  }),
  final_destination_location: PropTypes.shape({
    city: PropTypes.node,
    state: PropTypes.node,
  }),
  load_length: PropTypes.node,
  amount: PropTypes.number,
  user_reference_number: PropTypes.node,
  user_notes: PropTypes.node,
  bill_to_company_phone: PropTypes.node,
  bill_to_company_email: PropTypes.node,
};

export default FactoringPaymentInvoice;
