/* global API */
import { compose } from 'redux-v3';
import moment from 'moment';
import { get } from 'lodash';

import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import APIFetch from '../../datatypes/APIFetch';

class FactoringClientAttachmentsResource extends compose(
  Createable(),
  Fetchable({
    url: id => `factoring/${id}/attachments/`,
  }),
)() {
  parse(oldValue = {}, newData) {
    return {
      isFetching: false,
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: get(newData, 'results', {}),
    };
  }
  create(id, attachment, category = '') {
    return async (dispatch, getState) => {
      const state = getState();

      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });
      try {
        const requestUpload = await dispatch(APIFetch(`${API.host}/factoring/${id}/attachments/`, {
          method: 'POST',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            'filename': attachment.name,
            'category': category,
          }),
        }));
        const { upload_url } = await requestUpload.json();

        const formData = new FormData();
        Object.keys(upload_url.fields).map(key => formData.append(key, upload_url.fields[key]));

        formData.append('file', attachment);

        const fileUpload = await dispatch(APIFetch(upload_url.url, {
          method: 'POST',
          body: formData,
        }));

        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: attachment.name,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
}

const factoringclientattachmentsResource = new FactoringClientAttachmentsResource('factoringclientattachments');

export const {
  fetch,
  selector,
  create,
} = factoringclientattachmentsResource;

export default factoringclientattachmentsResource;
