/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import { reduce, isEqual } from 'lodash';
import activitySettingsResource from 'actions/settings/activity';
import { openModal } from 'actions/ui';
import { USER_TYPE } from 'helpers';

import getSelf from 'selectors/getSelf';
import spinnerIf from 'components/hoc/spinnerIf';
import getSelfType from 'components/hoc/getSelfType';
import CheckmarkTable from 'components/pure/form/CheckmarkTable';
import SubmitButton from 'components/pure/form/SubmitButton';
import isInviteCarrier from 'selectors/isInviteCarrier';
import isFactoringInviteCarrier from 'selectors/isFactoringInviteCarrier';
import segmentEvents from '../../../helpers/segmentEvents';


export default compose(
  connect(
    state => ({
      ...state.settings.activity,
      has_verified_email: getSelf(state).data.has_verified_email,
      isInviteCarrier: isInviteCarrier(state),
      isFactoringInviteCarrier: isFactoringInviteCarrier(state),
    }),
    activitySettingsResource,
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ data }) => data === undefined || Object.keys(data).length === 0),
  mapProps(({ data, has_verified_email, isInviteCarrier, isFactoringInviteCarrier }) => ({
    initialValues: data,
    has_verified_email,
    isInviteCarrier,
    isFactoringInviteCarrier,
  })),
  getSelfType,
  reduxForm({
    form: 'SettingsNotificationsActivityAlertsForm',
    destroyOnUnmount: false,
    onSubmit: async (data, dispatch, { initialValues }) => {
      try {
        const updatedData = reduce(initialValues, (result, value, key) => isEqual(value, data[key]) ?
          result : { ...result, [key]: data[key] }, {});
        await dispatch(activitySettingsResource.edit({
          ...data,
          unit: typeof data.unit === 'string' ? data.unit.toLowerCase() : undefined,
        }));
        window.analytics.track(segmentEvents.USER_UPDATED_NOTIFICATION_SETTINGS, updatedData)
        dispatch(openModal('success', { message: 'Successfully updated settings.' }))
      } catch (err) {
        dispatch(openModal('error', { message: err.message || 'Failed to update settings.' }))
      }
    }
  }),
)(({ userType, handleSubmit, submitting, has_verified_email, isInviteCarrier, isFactoringInviteCarrier }) =>
  <div>
    <CheckmarkTable
      disabled={!has_verified_email}
      header='Activity Notifications'
      types={[
        { text: 'email', icon: 'email' },
        { text: 'push', icon: 'notifications_active' },
      ]}
      rows={[
        ...(() => [USER_TYPE.CARRIER, USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(userType) ? [
          ['Load Match to Your Truck', 'load_match_to_truck', 'notify_load_match_to_truck'],
        ] : [])(),
        ...(() => [USER_TYPE.SHIPPER, USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(userType) ? [
          ['Truck Match to Your Load', 'truck_match_to_load', 'notify_truck_match_to_load'],
          ['New Bid on Your Load', 'new_bid_on_load', 'notify_new_bid_on_load'],
          ['Bid Has Been Retracted', 'bid_has_been_retracted', 'notify_bid_has_been_retracted'],
        ] : [])(),
        ...(() => [USER_TYPE.CARRIER, USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(userType) ? [
          ['You Have Been Under-Bid', 'you_have_been_under', 'notify_you_have_been_out'],
        ] : [])(),
        ['New Question on Your Post', 'new_question_for_post', 'notify_new_question_for_post'],
        ['New Response to Your Question', 'new_answer_to_question', 'notify_new_answer_to_question'],
        ...(() => [USER_TYPE.BROKER, USER_TYPE.DISPATCHER, USER_TYPE.SHIPPER].includes(userType) ? [
          ['Load Post Trip Status Updates', 'load_post_trip_updates', 'notify_load_post_trip_updates'],
        ] : [])(),
        ...(() => [USER_TYPE.BROKER, USER_TYPE.DISPATCHER, USER_TYPE.CARRIER].includes(userType) ? [
          ['Haul Pay Alerts', 'haul_pay_alerts', 'notify_haul_pay_alerts'],
        ] : [])(),
        ...(() => isInviteCarrier || isFactoringInviteCarrier ? [
          ['Assigned Load Alerts', 'assigned_to_load', 'notify_assigned_to_load'],
        ] : [])(),
      ]}
    />
    <SubmitButton onClick={handleSubmit} disabled={submitting || !has_verified_email}>SAVE</SubmitButton>
  </div>
);
