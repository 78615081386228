import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';

import { userEditClient } from 'actions/resource/factoringclient';
import { openModal } from 'actions/ui';

import * as validate from 'helpers/validate';

import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';


export default compose(
  reduxForm({
    form: 'FactoringClientUnacceptedEditForm',
    async onSubmit(fields, dispatch, { reset }) {
      try {
        await dispatch(userEditClient(fields));
        reset();
        dispatch(openModal('success', { message: 'Successfully uploaded attachments' }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to upload documents. Please try again later.' }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <form onSubmit={handleSubmit}>
    <Field
      name='attachments'
      component={MultiImageInput}
      validate={validate.compose(
        validate.vjs.presence({
          message: 'Please upload at least one file.',
        }),
        validate.numericality({
          minimum: 1,
          tooSmall: () => 'At least one attachment required',
          tokenizer: ({ length }) => length,
        }),
      )}
      limit={3}
      shouldCrop={true}
      buttonText='Add Documents'
      buttonClassName='btn btn-default'
    />
    <button className='btn btn-orange' type='submit' disabled={submitting}>Upload</button>
  </form>
);
