/**
 * Actions relating to the Rating resource
 * @module actions/resource/rating
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/ControlledResource
 */
import ControlledResource from '../../datatypes/ControlledResource';


/**
 * The ControlledResource that handles Bid parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
/**
 * Singleton for our RatingResource
 */
export const ratingResource = new ControlledResource('rating', {
  // we never want to re-fetch this resource
  expirationTime: Infinity,
});
export const {
  fetchIfNeeded,
  getItem,
} = ratingResource;

export default ratingResource;
