import React from 'react';
import { compose } from 'redux-v3';
import { lifecycle, withState } from 'recompose';
import nothingIf from 'components/hoc/nothingIf';

import AttachmentsTable from './AttachmentsTable';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';

export default compose(
  nothingIf(({ item }) => !item),
  withState("refresh", "setRefresh", false),
  lifecycle({
    componentDidUpdate(PrevProps) {
      const { attachments, setRefresh, refresh, refreshDocs, setRefreshDocs } = this.props;
        if(attachments.length !== PrevProps.attachments.length && PrevProps.attachments.length && !refresh) {
            setTimeout(() => setRefresh(true), 2000);
        }
        if(refreshDocs !== PrevProps.refreshDocs && refreshDocs) {
            setTimeout(() => setRefresh(true), 500);
            setRefreshDocs(false);
            setTimeout(() => setRefresh(false), 100);
        }
    }
  })
)(({ refresh, attachments = [], fetchCollated, fetchInvoice, fetchCarrierInvoice, item, invoiceSentCount = undefined, ...props }) => {

  return (
    <div className='text-left'>
      <h5 style={{ color: 'rgb(33, 150, 243)' }}>
        <b>Attachments List</b>
      </h5>
      <AttachmentsTable
        attachments={attachments}
        {...props}
      />
      {
        invoiceSentCount >= 0 ?
          <span className='text-danger'>Times Invoice Sent: {invoiceSentCount}</span>
          : null
      }
      <div style={{ display: 'flex', alignItems: 'center', fontSize: 'smaller' }}>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Collated Billing PDF
          </label>
          <LoadAttachmentButton getURL={fetchCollated} refresh={refresh} />
        </div>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Invoice
          </label>
          <LoadAttachmentButton getURL={fetchInvoice} refresh={refresh} id="loading-invoice" />
        </div>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Carrier’s Invoice
          </label>
          <LoadAttachmentButton getURL={fetchCarrierInvoice} refresh={refresh} />
        </div>
        <label className='control-label' style={{ marginLeft: 'auto' }}>
          Total attachments: {attachments.length}
        </label>
      </div>
    </div>
  );
});
