const formatPennies = (num, decimals = 2) => {
  if ((Number).isNaN(num)) {
    return '?';
  }
  else if (typeof num === 'number') {
    if (num >= 0) {
      return `$${(num / 100).toLocaleString(undefined, {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals,
      })}`;
    }
    return `-$${Math.abs((num / 100)).toLocaleString(undefined, {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })}`;

  }

  return null;
};

export default formatPennies;
