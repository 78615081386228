import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import FetchError from 'datatypes/FetchError';
import userResource from 'actions/resource/user';
import { openModal } from 'actions/ui';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import SubmitButton from 'components/pure/form/SubmitButton';


export default compose(
  connect(state => state.user),
  reduxForm({
    form: 'PasswordUpdateForm',
    destroyOnUnmount: false,
    async onSubmit(data, dispatch, { id }) {
      try {
        await dispatch(userResource.edit(id, {
          password: data.password,
        }));
        dispatch(openModal('success', { message: 'Successfully changed password' }));
      }
      catch (err) {
        console.warn(err);
        if (err instanceof FetchError) {
          const message = JSON.parse(err.message);
          dispatch(openModal('error', {
            message: (
              <div>
                <p>{message.error}</p>
                <ul>
                  {message.suggestions.map((suggestion, i) =>
                    <li key={i}>{suggestion}</li>
                  )}
                </ul>
              </div>
            ),
          }));
          return;
        }
        dispatch(openModal('error', { message: `Failed to change password: ${err}` }));
      }
    },
    validate(values) {
      return validate(values, {
        password: {
          presence: true,
          length: {
            minimum: 6,
          },
        },
        confirm: {
          presence: true,
          equality: 'password',
        },
      });
    },
  }),
)(({ handleSubmit, submitting }) =>
  <div>
    <Field name='password' component={LabeledInput} label='New Password' type='password' />
    <Field name='confirm' component={LabeledInput} label='Confirm Password' type='password' />
    <SubmitButton onClick={handleSubmit} disabled={submitting}>Save Password</SubmitButton>
  </div>
);
