import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';

import { USER_TYPE } from 'helpers';

import getSelfType from 'components/hoc/getSelfType';
import MaterialIcon from 'components/pure/MaterialIcon';

export default compose(
  getSelfType,
)(({ id, userType, ...rest }) => {
  if (userType === USER_TYPE.FACTORING_SUB_ADMIN) {
    return null;
  }
  return (
    <Link to={`/admin/user/search/${id}`}>
      <button className='btnProfile' type='button' {...rest}>
        <MaterialIcon name='account_circle' />
        Profile
      </button>
    </Link>
  );
});
