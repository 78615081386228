/**
 * Component to display a container with a "Load More" button at the bottom
 * @module components/pure/LoadMoreContainer
 * @since 3.0.0
 * @property {object} props
 * @property {function} props.onLoadMoreClick - function to load more
 * @property {node|node[]} props.children - node to display with the Load More button at the bottom
 * @property {object} props.buttonProps - props to pass to the Load More button
 * @property {node|node[]} props.buttonChildren - children node for the Load More button
 * @property {object} props.isFetching - whether to display a spinner indicating that more items are being fetched
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const LoadMoreContainer = ({ onLoadMoreClick, children, buttonProps = {}, buttonChildren = 'Load More', isFetching = false, ...rest }) =>
  <div {...rest}>
    <div>{children}</div>
    {
      onLoadMoreClick && typeof onLoadMoreClick === 'function' ?
        <div className='text-center'>
          <button {...buttonProps} disabled={isFetching} onClick={onLoadMoreClick} className={cs('btn btn-orange', buttonProps.className)}>{buttonChildren}</button>
        </div>
        : null
    }
  </div>
  ;

LoadMoreContainer.propTypes = {
  onLoadMoreClick: PropTypes.func,
  children: PropTypes.node,
  buttonProps: PropTypes.object,
  buttonChilren: PropTypes.node,
  isFetching: PropTypes.bool,
};

export default LoadMoreContainer;
