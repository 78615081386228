import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import getSelfType from 'selectors/getSelfType';

import Stripe from '../../stripe';
import { USER_TYPE } from 'helpers';
import { upgradeSubscription } from 'actions/user';
import { openModal } from 'actions/ui';

import nothingIf from 'components/hoc/nothingIf';


const stripeHander = Stripe({
  // to interpolate variables, use double braces
  panelLabel: 'Once Per Year {{amount}}',
});

export default compose(
  connect(
    state => ({
      ...state.user.subscription,
      user_type: getSelfType(state),
    }),
    (dispatch, ownProps) => ({
      onClick() {
        return stripeHander.open({
          amount: ownProps.to.stripePrice,
          token(token) {
            return dispatch(upgradeSubscription(token.id))
              .then(() => {
                dispatch(openModal('success', { message: 'Successfully upgraded subscription.' }));
              })
              .catch(err => {
                console.warn(err);
                dispatch(openModal('error'), { message: 'We had a problem updating your billing info. Please try again later.' });
              })
            ;
          },
        });
      },
    }),
  ),
  nothingIf(({ isFetching, data }) => isFetching || data === undefined || data.plan === 'broker-yearly' || data.plan === 'carrier-yearly'),
)(({ isFetching, to, data, user_type, ...rest }) =>
  <button
    {...rest}
    className={'btn btn-green btn-block'}
    style={{ margin: '0.5em 0', ...rest.style }}
    >
    {[USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(user_type) ? 'Switch to Annual - Get 4 Months Free!' : 'Switch to Annual - Get 5 Months Free!'}
  </button>
);
