/**
 * Component for searching for users
 * @module components/container/UserEdit
 * @since 3.1.0
 * @requires helpers/createTableItems
 */
import React from 'react';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import uniq from 'lodash/uniq';

import docTitle from 'components/hoc/docTitle';
import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import { CenteredSpinner } from 'components/pure/Spinner';
import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import UserEditForm from 'components/pure/form/UserEditForm';
import UserNotes from 'components/container/UserNotes';
import ButtonUserAdminDispatch from 'components/container/ButtonUserAdminDispatch';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import { MultiStateInput } from 'components/pure/form/inputs/StateInput';

import getPhoneParts from 'helpers/getPhoneParts';

const UserEdit = compose(
  mapProps(({ routeParams: { id } }) => ({ id })),
  docTitle(props => `Edit User - ${props.id}`),
  Resource('usersearch', {
    expirationTime: 0,
  }),
  spinnerIfFetching('item', CenteredSpinner),
)(({ item }) =>
  <CenteredColumn
    style={{ maxWidth: '100%' }}
    xs={10}
    >
    <Header className='clearfix'>
      <h3 style={{ color: '#2196f3' }}><b> User Account Info</b></h3>
      <br />
      <ButtonUserAdminDispatch id={item.data.id} />
    </Header>
    <UserEditForm
      item={item}
      initialValues={{
        email: item.data.email,
        contact_email: item.data.contact_email,
        contact_name: item.data.contact_name,
        dba: item.data.dba,
        address: {
          street_one: item.data.address,
          city: item.data.city,
          state: item.data.state,
          zip: item.data.zip,
          country: item.data.country,
        },
        contact_phone: getPhoneParts(item.data.contact_phone).phone,
        contact_phone_ext: getPhoneParts(item.data.contact_phone).ext,
        fax_number: getPhoneParts(item.data.fax_number).phone,
        fax_number_ext: getPhoneParts(item.data.fax_number).ext,
        mc: item.data.mc,
        dot: item.data.dot,
        stripe_email: item.data.stripe_email,
        avgpay: item.data.avgpay,
        credit_approved: item.data.credit_approved,
        cargo_insurance_value: item.data.cargo_insurance_value,
        cargo_insurance_provider: item.data.cargo_insurance_provider,
        contact_status: item.data.contact_status,
        admin: {
          id: item.data.assigned_admin,
          name: item.data.assigned_admin_name,
        },
        haulpay_interest: item.data.haulpay_interest || false,
        dispatching_interest: item.data.groups ? Boolean(item.data.groups.find(group => group.name === 'Admin Dispatch')) : false,
        files_insurance: [],
        files_authority: [],
        equipment_types: item.data.equipment_types.map(et => MultiTrailerInput.trailerTypes.find(({ value }) => et === value)),
        full_truck_loads_per_month: item.data.full_truck_loads_per_month,
        ltl_loads_per_month: item.data.ltl_loads_per_month,
        commodities_shipped: item.data.commodities_shipped,
        source: item.data.source,
        ...(() => {
          const ret = {
            lane_origin: [],
            lane_destination: [],
            lane_origin_states: [],
            lane_destination_states: [],
          };
          for (const { origin, destination } of item.data.lanes) {
            for (const [key, location] of [['origin', origin], ['destination', destination]]) {
              if (!location) {
                ret[`lane_${key}`].push(null);
                continue;
              }
              const state = MultiStateInput.data.find(({ code, fullName }) => code === location.state || fullName === location.state);
              if (state && location.state && !location.city) {
                ret[`lane_${key}_states`].push(state);
              }
              else {
                ret[`lane_${key}`].push(location);
              }
            }
          }
          ret.lane_origin_states = uniq(ret.lane_origin_states);
          ret.lane_destination_states = uniq(ret.lane_destination_states);
          return ret;
        })(),
      }}
    />
    <UserNotes id={item.data.id} />
  </CenteredColumn>
);

export default UserEdit;
