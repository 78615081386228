/**
 * Component for a generic modal
 * @module components/pure/modals/Generic
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @property {object} props
 * @property {object} [props.headerStyle] - an inline styles object for the header of this modals
 * @property {node|node[]} [props.headerChildren] - child node(s) for the header
 * @property {node|node[]} [props.message] - child node(s) for the body
 * @property {function} props.closeModal - the function to shift the modal off the front of the queue
 * @property {node|node[]} [props.buttonChildren] - child node(s) for the close button
 * @property {node[]} [props.extraButtons] - extra buttons to display
 * @property {any} [props....rest] - props passed to containing div component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';

import MaterialIcon from 'components/pure/MaterialIcon';
import YoutubeEmbed from 'components/pure/YoutubeEmbed';
import Modal from 'components/hoc/Modal';


const ModalGeneric = ({ headerStyle, headerIcon, headerChildren, message, closeModal, buttonChildren, extraButtons, youtubeEmbed, customCloseModal, displayCloseModal, customButtonStyle = '', ...rest }) =>
  <div {...rest}>
    <div className='modal-header' style={headerStyle}>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        <div>
          {headerIcon && <MaterialIcon name={headerIcon} style={{ verticalAlign: 'middle' }} />}
          {'\u00a0'}
          <span>{headerChildren}</span>
        </div>
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      {
        React.isValidElement(message) ?
          message
          : <div dangerouslySetInnerHTML={{ __html: message }} />
      }
      {youtubeEmbed ? <YoutubeEmbed video_id={youtubeEmbed} /> : null}
    </div>
    {React.createElement('div', {
      className: 'modal-footer',
    }, ...[...(() => displayCloseModal ? [<button className='btn btn-default' onClick={closeModal}>{'Cancel'}</button>] : [])(), <button className={`btn btn-default ${customButtonStyle}`} onClick={customCloseModal || closeModal}>{buttonChildren || 'Ok'}</button>, ...(extraButtons || [])])}
  </div>
  ;

ModalGeneric.propTypes = {
  headerStyle: PropTypes.object,
  headerIcon: PropTypes.string,
  headerChildren: PropTypes.node,
  message: PropTypes.node,
  closeModal: PropTypes.func.isRequired,
  buttonChildren: PropTypes.node,
  extraButtons: PropTypes.arrayOf(PropTypes.element),
};

export default ModalGeneric;

export const Generic = compose(
  Modal,
)(ModalGeneric);
