import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { get } from 'lodash';
import formatPennies from 'helpers/formatPennies';
const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 15,
    color: '#5c6979',
    textAlign: 'center',
  },
  icon: {
    //fontSize: 50,
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    color: '#979797',
  },
};

const DebtorPastCredit = ({ closeModal, amount, availableCredit, action, ...props }) => (
  <div>
    <ModalBody closeModal={closeModal}>
      <div
        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
        >
        <MaterialIcon
          name='error'
          size={40}
          style={styles.icon}
        />
      </div>
      <div >
        <p style={styles.text}>
          {`This transaction will put the debtor past the credit limit of ${formatPennies(availableCredit)} by ${formatPennies(amount - availableCredit)}. Please have a super admin update the credit limit or approve this transaction for you to proceed.`}
        </p>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, justifyContent: 'flex-end', margin: '0px 5px 5px 5px' }}>
      <Button
        style={styles.button}
        onClick={() => {
          //action();
          closeModal();
        }} size='sm'
           >
        Got it!
      </Button>
    </div>
  </div>
);

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ))(DebtorPastCredit);
