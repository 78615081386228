import React from 'react';
import { lifecycle, withState, compose } from 'recompose';
import get from 'lodash/get';
import map from 'lodash/map';
import find from 'lodash/find';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import PaymentProfileForm from '../form/PaymentProfileForm';
import FMCSALink from '../FMCSALink';
import { connect } from 'react-redux-v5';
import { closeModal } from '../../../actions/ui';
import PureUploadFile from '../PureUploadFile';
import API from '../../../actions/upload/API';

const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-orange'>{title}</h4>
);

const categories = [
  {
    text: 'Licence',
    value: 'driver_license',
  },
  {
    text: 'Insurance',
    value: 'insurance',
  },
  {
    text: 'Void Check',
    value: 'voided_check',
  },
  {
    text: 'NOA',
    value: 'comfreight_noa',
  },
  {
    text: 'Other',
    value: '',
  },
];

const mappedCategories = {
  'driver_license': 'Licence',
  'insurance': 'Insurance',
  'voided_check': 'Void Check',
  'comfreight_noa': 'NOA',
};

const PaymentProfile = ({ closeModal, paymentProfile, attachments }) =>
  <div>
    <ModalBody closeModal={closeModal}>
      <ModalTitle title={'Carrier\'s info'} />
      {
        paymentProfile.company_mc ?
          <FMCSALink mc={paymentProfile.company_mc} >
            <p className={'text-blue'} >FMCSA Profile</p>
          </FMCSALink>
          :
          <FMCSALink mc={paymentProfile.company_dot} >
            <p className={'text-blue'} >FMCSA Profile</p>
          </FMCSALink>
      }
      <hr />
      <ModalBody >
        <PaymentProfileForm
          initialValues={{
            carrier_name: `${paymentProfile.owner_name_first} ${paymentProfile.owner_name_last}`,
            factoring_company_name: get(paymentProfile, 'factoring_company.name', '').toUpperCase(),
            ...paymentProfile,
          }}
          hasMC={Boolean(paymentProfile.company_mc)}
          hasDOT={Boolean(paymentProfile.company_dot)}
          hasFactoringProfile={Boolean(get(paymentProfile, 'factoring_company.name', undefined))}
          urlNOA={get(paymentProfile, 'factoring_company.noa_attachment.preview_url', undefined)}
        />
        <PureUploadFile
          categoriesProps={{
            abbreviationKey: 'value',
            fulltextKey: 'text',
            breakPoint: 2,
          }}
          categories={categories}
          requestId={paymentProfile.id}
          type='payment'
          attachments={attachments}
        />
      </ModalBody>
    </ModalBody>
    <ModalFooter >
      <button className='btn btn-default' type='button' onClick={closeModal}>Done</button>
    </ModalFooter>
  </div>;

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
      fetchPaymentProfileAttachments(id) {
        return dispatch(API.fetchPaymentProfileAttachments(id));
      },
    }),
  ),
  withState('attachments', 'setAttachments', []),
  lifecycle({
    async componentDidMount() {
      const { fetchPaymentProfileAttachments, paymentProfile, setAttachments } = this.props;
      try {
        const res = await fetchPaymentProfileAttachments(paymentProfile.id);
        const attachments = map(res.results, file => ({
          ...file,
          category: mappedCategories[file.category],
        }));
        setAttachments(attachments);
      }
      catch (e) { }

    },
  }))(PaymentProfile);
