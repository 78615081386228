import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import FileInput from 'components/pure/form/inputs/FileInput';
import MaterialIcon from 'components/pure/MaterialIcon';


export default compose(
  reduxForm({
    form: 'NoteForm',
    validate(fields) {
      return validate(fields, {
        note: {
          presence: true,
          length: 1,
        },
        attachments: {
          filesize: {
            maximum: 5.243e+6,
          },
        },
      });
    },
  }),
)(({ submitting, handleSubmit }) =>
  <form onSubmit={handleSubmit}>
    <Field
      name='note'
      component={GrowableInput}
      props={{
        Component: LabeledInput,
        ComponentClass: 'textarea',
        placeholder: 'Details',
      }}
    />
    <Field name='attachments' component={FileInput} />
    <div className='text-right'>
      <button style={{ backgroundColor: '#e86900', height: '35px', color: 'white' }} type='submit' disabled={submitting}>
        <MaterialIcon name='save' style={{ marginBottom: '1px' }} />
        {' '}
       Save Notes
      </button>
    </div>
  </form>
);
