/**
 * Component that displays a series of forms for uploading documents to be sent to other users on the behalf of the current user. Currently unused
 * @module components/pure/SettingsDocuments
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/DocumentsSendForm
 * @requires components/pure/form/DocumentsUploadForm
 */
import React from 'react';

import Header from 'components/pure/Header';
import CenteredColumn from 'components/pure/CenteredColumn';
import DocumentsSendForm from 'components/pure/form/DocumentsSendForm';
import DocumentsUploadForm from 'components/pure/form/DocumentsUploadForm';
import DocumentsRecommendedUploadForm from 'components/pure/form/DocumentsRecommendedUploadForm';


const SettingsDocuments = () =>
  <CenteredColumn xs={12} sm={7}>
    <Header>Documents</Header>
    <div className='help-block'>Use this to host important documents and send them to any other party easily while logged in.</div>
    <DocumentsSendForm />
    <hr />
    <DocumentsUploadForm />
    <hr />
    <DocumentsRecommendedUploadForm />
    <hr />
  </CenteredColumn>
  ;

export default SettingsDocuments;
