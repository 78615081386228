import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import moment from 'moment';
import get from 'lodash/get';

import { USER_TYPE } from 'helpers';
import states from 'helpers/states';
import SavedUserSettings from 'datatypes/SavedUserSettings';
import { openModal } from 'actions/ui';
import * as truckSearchActions from 'actions/search/truck';

import Header from 'components/pure/Header';
import ProTip from 'components/container/ProTip';
import ButtonCurrentLocation from 'components/pure/ButtonCurrentLocation';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import MaterialIcon from 'components/pure/MaterialIcon';
import Tooltip from 'components/pure/Tooltip';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import SetValueInput from 'components/pure/form/inputs/SetValueInput';
import getSelfType from 'selectors/getSelfType';
import segmentEvents from '../../../helpers/segmentEvents';

const savedInitialValues = SavedUserSettings('SavedTruckSearch', {
  defaults: {
    origin_region: [],
    destination_region: [],
    trailerTypes: [],
    time_start: moment(),
    time_end: moment(),
  },
  hooks: {
    postRetrieve(data) {
      data.time_start = moment(data.time_start);
      data.time_end = moment(data.time_end);
      if (data.time_start.isBefore(moment(), 'day')) {
        data.time_start = moment();
        data.time_end = moment().add(5, 'days');
      }
      return data;
    },
  },
});
const rolloverTimes = () => {
  savedInitialValues.set({
    time_start: moment(),
    time_end: moment().add(5, 'days'),
    haulpay_verified: false,
  });
  setTimeout(rolloverTimes, moment().endOf('day').diff(moment()));
};
rolloverTimes();

const FORM_NAME = 'TruckSearch';
const selector = formValueSelector(FORM_NAME);
export default compose(
  connect(
    (state, ownProps) => {
      return {
        search_by_date_range: selector(state, 'search_by_date_range'),
        initialValues: ownProps.initialValues || savedInitialValues.get(state.user.id),
        onSubmit(fields, dispatch) {
          const is_subscribed = (
            get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_subscribed']) ||
            get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_trialing']) ||
            USER_TYPE.IS_ADMIN(getSelfType(state)) ||
            USER_TYPE.IS_ALADDIN_ADMIN(getSelfType(state))
          );
          const data = {
            search_origin_region: fields.origin_region.map(state => state.code).join(','),
            search_destination_region: fields.destination_region.map(state => state.code).join(','),
            trailerTypes: fields.trailerTypes.map(tt => tt.value).join(','),
            time_start__lte: undefined,
            time_end__gte: undefined,
            haulpay_verified: fields.haulpay_verified,
          };
          if (fields.search_by_date_range) {
            data.time_start__lte = fields.time_end.endOf('day').format();
            data.time_end__gte = fields.time_start.startOf('day').format();
          }
          else {
            data.time_start__lte = moment(fields.time_start, 'l').endOf('day').format();
            data.time_end__gte = moment(fields.time_start, 'l').startOf('day').format();
          }
          savedInitialValues.set(fields);
          if (!is_subscribed) {
            dispatch(openModal('upgradeaccount', {
              headerChildren: 'Full Access Needed',
              subscribeButtonText: 'Upgrade',
              message: 'Your truck search access is limited due to your full access trial period having ended. You can still post loads but you will not be able to contact matches. You will need to upgrade to full access to use the full features of search in the web app or contact support.',
            }));
            return;
          }
          dispatch(truckSearchActions.clear());
          return dispatch(truckSearchActions.fetch(data))
            .then(() => window.analytics.track(segmentEvents.USER_SEARCHED_TRUCK))
            .catch(err => dispatch(openModal('error', err)))
            ;
        },
      };
    },
    null,
    null,
    { withRef: true },
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate(values) {
      return validate(values, {
        time_start: {
          presence: true,
        },
      });
    },
  }),
)(({ search_by_date_range, change }) =>
  <div className='light-placeholder'>
    <div className='clearfix' style={{ marginTop: '0.5em' }}>
      <Header style={{ display: 'inline-block', marginTop: 0 }}>Truck Search</Header>
      <ProTip className='pull-right' name='Truck Search' message={<div><p>Enter multiple states on pick up and delivery based on your ongoing logistics needs to find trucks posted or interested in hauling in larger regions so you get more results.</p><p>You can also extend out the end time for the search to reveal even more potential carrier matches for current and upcoming lanes.</p></div>} />
    </div>
    <Field
      name='origin_region'
      component={MultiStateInput}
      label={
        <span>
          {'Pick Up State(s)\u00a0\u00a0'}
          <ButtonCurrentLocation
            component='a'
            role='button'
            onClick={place => {
              change('origin_region', [{
                fullName: states[place.state],
                code: place.state,
              }]);
            }}
          >
            Current State
          </ButtonCurrentLocation>
        </span>
      }
    />
    <Field name='destination_region' component={MultiStateInput} label='Delivery State(s)' />
    <Field name='trailerTypes' component={MultiTrailerInput} label='Equipment Type' />
    <Field
      name='haulpay_verified'
      component={LabeledDropdown}
      data={[
        { text: 'Any', value: 'false' },
        { text: 'HaulPay Verified Only', value: 'true' },
      ]}
      label={
        <Tooltip
          text='This carrier has been verified for liability insurance, business legitimacy and authority compliance. We still recommend verifying cargo insurance and other requirements with the carrier as needed.'
        >
          <div
            style={{
              display: 'inline-block',
            }}
          >
            Filter Carriers
            <MaterialIcon name='verified_user' />
          </div>
        </Tooltip>}
    />
    {
      search_by_date_range ?
        <div>
          <Field
            name='time_start'
            component={DateTimeInput}
            label={
              <div>
                Start
                <Field name='search_by_date_range' component={SetValueInput} value_to_set={false}>
                  <a role='button' className='pull-right'>By Avail. Date</a>
                </Field>
              </div>
            }
            labelProps={{
              style: {
                display: 'block',
              },
            }}
            pickerOptions={{ time: false }}
          />
          <Field
            name='time_end'
            component={DateTimeInput}
            label='End'
            pickerOptions={{ time: false }}
          />
        </div>
        :
        <Field
          name='time_start'
          component={DateTimeInput}
          label={
            <div>
              Date Available
              <Field name='search_by_date_range' component={SetValueInput} value_to_set={true}>
                <a role='button' className='pull-right'>By Date Range</a>
              </Field>
            </div>
          }
          labelProps={{
            style: {
              display: 'block',
            },
          }}
          pickerOptions={{ time: false }}
        />
    }
  </div>
);
