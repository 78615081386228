import React from 'react';
import { connect } from 'react-redux-v5';
import { compose } from 'recompose';
import { closeModal } from 'actions/ui';
import MaterialIcon from 'components/pure/MaterialIcon';
import ModalBody from 'components/pure/ModalBody';

const style = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexDirection: 'column'
    },
    item: {
        justifyContent: 'space-around',
        margin: '1%',
    },
    text: {
        fontSize: 15,
        textAlign: 'center'
    }
}

const FileTypeConfirm = ({ closeModal }) =>
    <div>
        <ModalBody
            closeModal={closeModal}
        >
            <div style={style.container}>
                <div style={style.item}>
                    <MaterialIcon name='warning' className='text-warning' size='100' />
                </div>
                <div style={style.item}>
                    <p style={style.text}>
                        We only support PDFs, JPGs, JPEGs, PNGs, and HEICs.
                    </p>
                </div>
                <div style={style.item}>
                    <p style={style.text}>
                        Please check that the file extension of the file you're uploading matches these document types and try again.
                    </p>
                </div>
                <div style={{
                    ...style.item, display: "flex", flex: 1, flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'center', width: '100%'
                }}>
                    <button
                        style={{ width: "100%" }}
                        className='btn btn-default'
                        type='submit'
                        onClick={closeModal}>
                        ok
                    </button>
                </div>
            </div>
        </ModalBody>
    </div>;
export default compose(
    connect(
        state => ({}),
        {
            closeModal
        }
    )
)(FileTypeConfirm);
