/**
 * Connected modal component for unhiding a user
 * @module components/pure/modals/ConfirmUnhideUser
 * @since 3.0.0
 * @requires actions/hide
 * @requires actions/ui
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @requires components/pure/PromiseButton
 * @property {object} props
 * @property {string} props.id - the ID of the user to hide
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { unHideUser } from 'actions/hide';
import { openModal } from 'actions/ui';

import Modal from 'components/hoc/Modal';
import PromiseButton from 'components/pure/PromiseButton';
import ModalWarning from './Warning';


const ConfirmUnhideUser = compose(
  Modal,
  connect(
    null,
    (dispatch, ownProps) => ({
      unHideUser() {
        return dispatch(unHideUser(ownProps.id))
          .then(() => ownProps.closeModal())
          .catch(err => {
            let message = 'Error unhiding user';
            if (err) {
              if (err.message) {
                message = err.message;
              }
            }
            ownProps.closeModal();
            return dispatch(openModal('error', { message }));
          })
        ;
      },
    }),
  ),
  mapProps(({ unHideUser, ...rest }) => ({
    ...rest,
    headerChildren: 'Confirm',
    message: 'Are you sure you\'d like to unblock this user and all of their posts?',
    buttonChildren: 'Cancel',
    extraButtons: [
      <PromiseButton className='btn btn-danger' onClick={unHideUser}>Unblock</PromiseButton>,
    ],
  })),
)(ModalWarning);

ConfirmUnhideUser.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmUnhideUser;
