import validate from 'validate.js';


const normalizeNumber = (value, previousValue) => {
  if (value === '') {
    return null;
  }
  const isValid = (
    !(validate({ value }, {
      value: {
        numericality: {
          greaterThanOrEqualTo: 0.01,
          lessThanOrEqualTo: 9.99,
        },
      },
    }).value) &&
    /^\d+(?:\.\d{0,2})?$/.test(value)
  );
  if (!isValid) {
    return previousValue;
  }
  return value;
};

export default normalizeNumber;
