import Point from 'datatypes/Point';
import { get } from 'lodash';

class Priority {
  constructor(property, p) {
    this.property = property;
    this.p = p;
    this.value = null;
  }
  toString() {
    return this.property;
  }
  valueOf() {
    return this.p;
  }
  hold(value) {
    const p = new Priority(this.property, this.p);
    p.value = value;
    return p;
  }
}

const typingMap = {
  subpremise: null,
  street_number: 'street_number',
  route: new Priority('street', Infinity),
  bus_station: new Priority('street', 50),
  establishment: new Priority('street', 50),
  point_of_interest: new Priority('street', 50),
  transit_station: new Priority('street', 50),
  political: new Priority('city', 30),
  neighborhood: new Priority('city', 10), // some neighborhoods are considered cities
  postal_code: 'zip',
  postal_code_suffix: 'zip_suffix',
  locality: new Priority('city', Infinity),
  sublocality_level_1: new Priority('city', 20), // some cities are considered sublocality_level_1
  sublocality: new Priority('city', 30), // some cities are considered sublocality
  administrative_area_level_3: new Priority('city', 5),
  administrative_area_level_2: 'county',
  administrative_area_level_1: 'state',
  country: 'country',
  intersection: 'intersection',
  plus_code: null
};

const parseGooglePlace = place => {
  const parsedPlace = place.address_components.reduce(
    (acc, curr) => {
      const curr_type = curr.types[0];
      let curr_value = curr.short_name;
      let mapped_type = typingMap[curr_type];
      // if we don't have a mapping for this type, check the rest of the `types` array for one we do recognize
      if (!mapped_type) {
        curr.types.some(type => {
          mapped_type = typingMap[type];
          if (mapped_type) {
            return true;
          }
        });
        // we don't care about this type
        if (mapped_type === null) {
          return acc;
        }
        // should 100% have a type mapping for this now
        if (!mapped_type) {
          throw new Error(
            `Failed to parse google place: Missing type for ${JSON.stringify(
              curr
            )}: ${JSON.stringify(curr.types)}`
          );
        }
      }
      const prev_value = acc[mapped_type];
      if (mapped_type instanceof Priority) {
        curr_value = mapped_type.hold(curr_value);
        if (!prev_value || (prev_value && mapped_type > prev_value)) {
          acc[mapped_type] = curr_value;
        }
      } else {
        acc[mapped_type] = curr_value;
      }
      return acc;
    },
    {
      place_id: place.place_id,
      coords: Point.fromLatLng(
        place.geometry.location.toJSON
          ? place.geometry.location.toJSON()
          : place.geometry.location
      ),
      formatted_address: place.formatted_address
    }
  );
  for (const key in parsedPlace) {
    const value = parsedPlace[key];
    if (value instanceof Priority) {
      parsedPlace[key] = value.value;
    }
  }
  parsedPlace.address = parsedPlace.street_one = [
    parsedPlace.street_number,
    parsedPlace.street
  ]
    .filter(Boolean)
    .join(' ');

  if (
    parsedPlace.street_one === '' &&
    get(parsedPlace, 'intersection', '').length > 0
  ) {
    parsedPlace.street_one = parsedPlace.intersection;
  }

  if (!parsedPlace.city && parsedPlace.county) {
    parsedPlace.city = parsedPlace.county;
  }

  return parsedPlace;
};

export default parseGooglePlace;
