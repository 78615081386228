import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { fetchPaymentRequestAttachments } from 'actions/resource/factoringpayment';

export default () =>
  WrappedComponent => {
    class AutoUpdatesAttachments extends Component {
      constructor() {
        super();
        this.intervalID = '';
      }
      async componentDidMount() {
        const { fetchPaymentRequestAttachments, ownProps: { params: { id } } } = this.props;
        try {
          await fetchPaymentRequestAttachments(id);
          if (this.intervalID) {
            clearInterval(this.intervalID);
          }
          this.intervalID = setInterval(async () => {
            await fetchPaymentRequestAttachments(id);
          }
            , 10000);
        }
        catch (e) {
          console.log('ERROR', e);
        }
      }

      componentWillUnmount() {
        /*
          stop this.fetch from continuing to run even
          after unmounting this component
        */
        clearInterval(this.intervalID);
      }
      render() {
        const { ownProps = {} } = this.props;
        return React.createElement(WrappedComponent, ownProps);
      }
    }
    return compose(
      connect(null,
        { fetchPaymentRequestAttachments },
        (stateProps, dispatchProps, ownProps) => ({
          ...stateProps,
          ...dispatchProps,
          ownProps,
        })),
    )(AutoUpdatesAttachments);
  };
