import states, { stateNameToCode } from 'helpers/states';


const isValidState = state => {
  for (const code in states) {
    if (states[code] === state) {
      return true;
    }
  }
  return false;
};


isValidState.code = code => code in states;

isValidState.url = url => url in stateNameToCode;


export default isValidState;
