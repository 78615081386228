import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import shallowEqual from 'recompose/shallowEqual';
import setDisplayName from 'recompose/setDisplayName';

import { fetchIfNeeded as fetchTruckIfNeeded, getItem as getTruck } from 'actions/resource/truck';
import { fetchIfNeeded as fetchLoadIfNeeded, getItem as getLoad } from 'actions/resource/load';
import { fetchIfNeeded as fetchUserIfNeeded, getItem as getUser } from 'actions/resource/user';
import { fetchIfNeeded as fetchMapIfNeeded, getItem as getMap } from 'actions/resource/map';

import wrapDisplayName from 'components/hoc/wrapDisplayName';


const AVAILABLE_RESOURCES = {
  truck: fetchTruckIfNeeded,
  load: fetchLoadIfNeeded,
  user: fetchUserIfNeeded,
  map: fetchMapIfNeeded,
};
const getItem = {
  truck: getTruck,
  load: getLoad,
  user: getUser,
  map: getMap,
};

const DEFAULT_OPTIONS = {
  idPropName: 'ids',
  resourcePropName: 'items',
  transformId: i => i,
};


export default (resource, options) => {

  const CLASSNAME = `Resources(${resource})`;
  if (AVAILABLE_RESOURCES[resource] === undefined) {
    throw new TypeError(`Unimplemented resource "${resource}" passed to ${CLASSNAME}`);
  }

  options = { ...DEFAULT_OPTIONS, ...options };

  return WrappedComponent => {
    class Resources extends Component {

      componentDidMount() {
        const { ids, dispatch } = this.props;
        for (const id of ids) {
          dispatch(AVAILABLE_RESOURCES[resource](id));
        }
      }

      // TODO - this always rerenders, don't
      shouldComponentUpdate(nextProps) {
        const { props } = this;
        if (!shallowEqual(props.items, nextProps.items)) {
          return true;
        }
        if (!shallowEqual(props.ownProps, nextProps.ownProps)) {
          return true;
        }
        return false;
      }

      static propTypes = {
        ids: PropTypes.arrayOf(PropTypes.string).isRequired,
        items: PropTypes.arrayOf(PropTypes.object),
        dispatch: PropTypes.func.isRequired,
      };

      render() {
        const { ids, items, dispatch, ownProps } = this.props;
        return React.createElement(WrappedComponent, {
          ...ownProps,
          [options.resourcePropName]: items,
        });
      }
    }

    const mapStateToProps = (state, ownProps) => {
      const ids = typeof options.idPropName === 'function' ? options.idPropName(ownProps) : get(ownProps, options.idPropName);
      const items = ids.map(id => getItem[resource](state, id));
      return { ids, items };
    };

    const mergeProps = (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ownProps,
    });

    return compose(
      setDisplayName(CLASSNAME),
      connect(
        mapStateToProps,
        dispatch => ({ dispatch }),
        mergeProps,
      ),
      wrapDisplayName(CLASSNAME),
    )(Resources);
  };
};
