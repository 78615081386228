import { useState, useEffect, useCallback } from 'react';
import { postStripeOnboarding, fetchStripeAccount } from 'actions/ledger';

import colors from 'styles/colors.json';
import onboardingStatus from 'helpers/walletOnboardingStatus';

const useStripeOnboarding = reload => {
    const [accountSettings, setAccountSettings] = useState({});
    const [isGenerateLink, setIsGenerateLink] = useState(false);

    useEffect(() => {
        async function asyncFunction() {
            try {
                const {
                    deposit_enabled,
                    withdrawal_enabled,
                    bank_account_linked,
                    onboarding_required,
                    onboarding_status,
                    id,
                } = await fetchStripeAccount();
                setAccountSettings({
                    onboardingRequired: onboarding_required,
                    depositEnabled: deposit_enabled,
                    withdrawalEnabled: withdrawal_enabled,
                    bankAccountLinked: bank_account_linked,
                    onboardingStatus: onboarding_status,
                    accountId: id,
                });
            } catch (err) {
            }
        }
        asyncFunction()
    }, [reload]);

    const handleContinue = useCallback(
        async () => {
            try {
                setIsGenerateLink(true);
                const res = await postStripeOnboarding();
                window.location.href = res.url
            } catch (err) {
            }
            setTimeout(() => setIsGenerateLink(false), 200);
        },
        [],
    )

    const statusMapping = {
        [onboardingStatus.ONBOARDING_REVIEW]: {
            text: 'Onboarding process still in review.',
            alertType: 'info',
            icon: 'find_in_page',
            color: colors.INFO
        },
        [onboardingStatus.PENDING]: {
            text: 'Onboarding process still pending.',
            alertType: 'warning',
            icon: 'settings_applications',
            color: colors.WARNING
        },
        [onboardingStatus.DELETED]: {
            text: 'You cannot use this feature please contact support@haulpay.io.',
            alertType: 'danger',
            icon: 'error',
            color: colors.DANGER
        },
    }

    return { accountSettings, handleContinue, statusMapping, isGenerateLink };
}

export default useStripeOnboarding;
