import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { unHidePost } from 'actions/hide';

import HiddenPostLineItem from 'components/pure/HiddenPostLineItem';


export default compose(
  connect(
    null,
    { unHide: unHidePost }
  ),
)(HiddenPostLineItem);
