import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
// import PieChart from 'react-minimal-pie-chart';

import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { fetchDashboardStats } from 'actions/admin/stats';

import Header from 'components/pure/Header';
import CenteredColumn from 'components/pure/CenteredColumn';
import { CenteredSpinner } from 'components/pure/Spinner';
import ColoredCircle from 'components/pure/ColoredCircle';


function getColorForStatus(status) {
  switch (status) {
    case 'paid':
    case USER_FACTORING_STATUS.APPROVED: return '#3c763d';
    case USER_FACTORING_STATUS.DECLINED: return '#a94442';
    case USER_FACTORING_STATUS.PENDING: return '#E38627';
    case USER_FACTORING_STATUS.UNAPPLIED:
    default:
      return '#777';
  }
}

export default compose(
  connect(
    null,
    {
      update: fetchDashboardStats,
    },
  ),
)(class AdminDashboardStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = { data: null };
  }

  componentDidMount() {
    if (this.props.selectedAdmin) {
      this.props.update(this.props.selectedAdmin.id).then(data => this.setState({ data }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.selectAdmin !== nextProps.selectedAdmin) {
      this.props.update(nextProps.selectedAdmin ? nextProps.selectedAdmin.id : undefined).then(data => this.setState({ data }));
    }
  }

  render() {
    const { data } = this.state;

    return (
      <CenteredColumn xs={12} sm={8} lg={6} className='clearfix'>
        {
          !data ?
            <CenteredSpinner />
            :
            <div className='text-center'>
              <div className='col-xs-12 col-sm-6' style={{ paddingLeft: 0 }}>
                <Header><span style={{ fontSize: '0.85em' }}>Factoring Client Applications by Status</span></Header>
                {
                  data.factoring_stats && data.factoring_stats.length ?
                    <div>
                      {/* <PieChart
                        data={data.factoring_stats.map(({ total_count, factoring_approved }, i) => ({
                          value: total_count,
                          key: i,
                          color: getColorForStatus(factoring_approved),
                        }))}
                      /> */}
                      <div
                        style={{
                          display: 'flex',
                          flexWrap: 'wrap',
                        }}
                        >
                        {data.factoring_stats.sort((a, b) => b.total_count - a.total_count).map(({ total_count, factoring_approved }) =>
                          <p
                            key={factoring_approved}
                            className='text-capitalize'
                            style={{
                              maxWidth: '100%',
                              minWidth: '50%',
                            }}
                            >
                            <ColoredCircle size={12} color={getColorForStatus(factoring_approved)} />
                            {factoring_approved}: {total_count}
                          </p>
                        )}
                      </div>
                    </div>
                    :
                    <p>No factoring clients assigned to this rep.</p>
                }
              </div>
              <div className='col-xs-12 col-sm-6' style={{ paddingRight: 0 }}>
                <Header><span style={{ fontSize: '0.85em' }}>Funding Request Volume by Status</span></Header>
                {
                  data.funding_request_stats && data.funding_request_stats.length ?
                    <div>
                      {/* <PieChart
                        data={data.funding_request_stats.map(({ status, total_count }, i) => ({
                          value: total_count,
                          key: i,
                          color: getColorForStatus(status),
                        }))}
                      /> */}
                      <div>
                        {data.funding_request_stats.sort((a, b) => b.total_count - a.total_count).map(({ total_count, status }) =>
                          <p key={status} className='text-capitalize'>
                            <ColoredCircle size={12} color={getColorForStatus(status)} />
                            {status}: {total_count}
                          </p>
                        )}
                      </div>
                    </div>
                    :
                    <p>No funding request stats for this rep.</p>
                }
              </div>
            </div>
        }
      </CenteredColumn>
    );
  }
});
