/**
 * Component to display a react-router link that falls back to a normal link in the absense of a react-router router in context. Used in the marketing pages
 * @module components/pure/FallbackLink
 * @since 3.0.0
 * @property {object} props - see [Link]{@link https://github.com/ReactTraining/react-router/blob/master/docs/API.md#link}
 */
import url from 'url';
import React from 'react';
import PropTypes from 'prop-types';
import { Link, locationShape, routerShape } from 'react-router-v4';


const FallbackLink = (props, { router }) => {
  const { to } = props;
  let href;
  if (typeof to === 'object') {
    href = to.location;
  }
  else {
    href = to;
  }
  const parsedUrl = url.parse(href, false, true);
  if (router === undefined || (parsedUrl.host !== null && parsedUrl.host !== location.host)) {
    const {
      to,
      query,
      hash,
      state,
      activeStyle,
      activeClassName,
      onlyActiveOnIndex,
      ...anchorProps
    } = props;
    let href;
    if (typeof to === 'object') {
      href = `${to.location}${to.query}${to.hash}`;
    }
    else {
      href = to;
    }
    return <a href={href} {...anchorProps} />;
  }
  return <Link {...props} />;
};

FallbackLink.propTypes = {
  ...Link.propTypes,
  onlyActiveOnIndex: PropTypes.any,
};

FallbackLink.contextTypes = {
  router: routerShape,
  location: locationShape,
};

export default FallbackLink;
