import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import getSelfType from 'selectors/getSelfType';
import { packages } from '../../../stripe';
import formatPennies from 'helpers/formatPennies';
import { closeModal, openModal } from 'actions/ui';
import { cancelSubscription, sendFeedback } from 'actions/user';

import ModalError from './Error';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export const ConfirmCancelAccount = compose(
  connect(
    state => ({
      user_type: getSelfType(state),
    }),
    {
      closeModal,
    },
  ),
  reduxForm({
    form: 'SubscriptionCancelForm',
    validate(fields) {
      return validate(fields, {
        message: {
          presence: true,
        },
      });
    },
    async onSubmit(fields, dispatch) {
      try {
        await dispatch(sendFeedback(fields.message));
        await dispatch(cancelSubscription());
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Successfully deactivated your subscription.' }));
      }
      catch (err) {
        let message = 'Error deactivating subscription';
        if (err) {
          if (err.message) {
            message = err.message;
          }
        }
        dispatch(closeModal());
        dispatch(openModal('error', { message }));
      }
    },
  }),
)(({ handleSubmit, submitting, closeModal, user_type }) => {
  const latest_subscription = packages[user_type][1];
  let subscription_price_message;
  if (latest_subscription) {
    subscription_price_message = `is now ${formatPennies(latest_subscription.price)} per month`;
  }
  else {
    subscription_price_message = 'may have increased';
  }
  return (
    <ModalError
      message={
        <div>
          <p>
            Are you sure you want to deactivate the subscription on your account? Please note that the monthly subscription price {subscription_price_message}. Cancelling now means you might lose your current pricing in the future.
          </p>
          <Field name='message' component={LabeledInput} label={'Please enter a reason for why you\'re cancelling'} />
        </div>
      }
      closeModal={closeModal}
      headerChildren='Confirm'
      buttonChildren='Cancel'
      extraButtons={[
        <button className='btn btn-danger' disabled={submitting} onClick={handleSubmit}>Deactivate Account</button>,
      ]}
    />
  );
});

export default ConfirmCancelAccount;
