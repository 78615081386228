/**
 * Connected component displaying Timeline Notification status
 * @module components/container/TimelineNotificationIcon
 * @since 3.0.0
 * @requires helpers
 * @requires actions/notification
 * @requires actions/resource/activity
 * @requires components/hoc/getSelfType
 * @requires components/pure/NotificationIcon
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';

import { USER_TYPE } from 'helpers';
import { setNotification } from 'actions/notification';
import { activityPaginatedResource, setFilter } from 'actions/resource/activity';
import { setScrollToItem } from 'actions/ui';

import getSelfType from 'components/hoc/getSelfType';
import MaterialIcon from 'components/pure/MaterialIcon';
import NotificationIcon from 'components/pure/NotificationIcon';


export default compose(
  getSelfType,
  branch(
    ({ userType }) => [USER_TYPE.CARRIER, USER_TYPE.SHIPPER, USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(userType),
    Component => Component,
    renderNothing,
  ),
  connect(
    state => ({
      count: state.notification,
    }),
    {
      setNotification,
      setFilter,
      setScrollToItem,
      clear: activityPaginatedResource.clear,
      fetch: activityPaginatedResource.fetch,
    },
  ),
)(({ userType, setNotification, clear, fetch, setFilter, setScrollToItem, dispatch, ...rest }) =>
  <NotificationIcon
    {...rest}
    onClick={() => {
      setFilter('all');
      clear();
      fetch();
      setNotification(false);
      setScrollToItem(null);
      // scroll to the top of the timeline if we're on the timeline
      if (location.pathname === '/') {
        const el = document.querySelector('.InfiniteScroll');
        if (el) {
          el.scrollTop = 0;
        }
      }
    }}
    href={'/'}
    size={40}
    style={{ opacity: rest.count ? undefined : '0.5' }}
    children={<MaterialIcon name='feedback' style={{ verticalAlign: 'baseline' }} />}
  />
);
