/**
 * Displays a button that has a pointed end on the left- or right-hand side
 * @module components/pure/Arrow
 * @since 3.0.0
 * @property {object} props
 * @property {boolean} [props.rounded = false] - whether to display the tail as rounded
 * @property {boolean} [props.tailless = false] - whether to display the the arrow without a tail
 * @property {boolean} [props.color] - the color to display the arrow as, defaults to orange
 * @property {boolean} [props.small = false] - whether to display short/small
 * @property {string} [props.direction = Arrow.LEFT] - the direction to head the arrow at
 * @property {node|node[]} [props.children] - child node(s) for this button
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/Arrow';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const Arrow = ({ children, rounded, tailless, color, small, direction, ...rest }) => (
  <div
    {...rest}
    className={cs(
      'arrow',
      direction !== Arrow.LEFT ? 'arrow-right' : 'arrow-left',
      rest.className,
      {
        'arrow-roundedtail': rounded,
        'arrow-tailless': tailless,
        'arrow-blue': color === Arrow.colors.BLUE,
        'arrow-lblue': color === Arrow.colors.LBLUE,
        'arrow-gray': color === Arrow.colors.GRAY,
        'arrow-small': small,
      }
    )}
    >
    <div className='arrow-head' />
    <div className='arrow-tail' />
    <div className='arrow-body'>{children}</div>
  </div>
);

Arrow.RIGHT = Symbol();
Arrow.LEFT = Symbol();

Arrow.colors = {
  BLUE: Symbol(),
  LBLUE: Symbol(),
  GRAY: Symbol(),
};

Arrow.propTypes = {
  children: PropTypes.node,
  direction: PropTypes.oneOf([Arrow.RIGHT, Arrow.LEFT]),
  rounded: PropTypes.bool,
  tailless: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(Arrow.colors)),
};

export default Arrow;
