/**
 * Button that prompts a user to call another user given a phone number and qualifying permissions
 * @module components/container/ButtonCall
 * @since 3.0.1
 * @requires actions/ui
 * @requires components/hoc/getSelf
 * @property {object} props
 * @property {object} props.tel - the phone number
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { USER_TYPE } from 'helpers';
import formatTel from 'helpers/formatTel';
import { openModal } from 'actions/ui';
import { get } from 'lodash';
import getSelf from 'components/hoc/getSelf';


export default compose(
  getSelf,
  connect(
    null,
    {
      openModal,
    },
  ),
)(({ tel, item, openModal, children = 'CALL', dispatch, ...rest }) => {
  let onClick = rest.onClick;
  const permissions = item.data.permissions || {};
  if ((!USER_TYPE.IS_ADMIN(item.data.type) || !USER_TYPE.IS_ALADDIN_ADMIN(item.data.type)) && !get(permissions, 'is_trialing') && !get(permissions, 'is_subscribed')) {
    onClick = e => {
      e.stopPropagation();
      openModal('upgradeaccount');
    };
  }
  if (!tel) {
    tel = '#';
  }
  else {
    tel = formatTel(tel);
  }
  return (
    <a
      href={`tel:${tel}`}
      {...rest}
      onClick={onClick}
      >
      <button className='btn btn-green'>{children}</button>
    </a>
  );
});
