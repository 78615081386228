import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import moment from 'moment';

import { fetchBrokerPaymentHistoryCSV } from 'actions/factoring';
import { openModal } from 'actions/ui';
import { stripeWalletHistory, ledgerWalletHistory } from 'actions/ledger';

import wrapPromisePending from 'components/hoc/wrapPromisePending';
import get from 'lodash/get';


export default compose(
  connect(
    null,
    (dispatch, { factoringId, reportValue, reportDateRange, stripeEnabled }) => ({
      async onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        try {
          if (reportValue === 'wallet_transactions_report') {
            const res = stripeEnabled ? await stripeWalletHistory({ download: true, ...reportDateRange })
              : await ledgerWalletHistory({ download: true, ...reportDateRange });
            const url = window.URL.createObjectURL(res);
            const link = document.createElement('a');
            link.href = url;
            const date = moment();
            link.download = `wallet_last_500_transactions_${date.format('DD_MM_Y')}`;
            return link.click();
          }
          const { download_url } = await dispatch(fetchBrokerPaymentHistoryCSV(factoringId, reportValue, reportDateRange));
          window.open(download_url);
        }
        catch (err) {
          console.warn(err);
          if(get(err, "funding_requests", undefined) === "No funding requests found with the filter options. Please adjust the filters") {
            return dispatch(openModal('error', {
              message: 'No Data Available',
            }));
          }
          dispatch(openModal('error', {
            message: 'Failed to fetch CSV data :(',
          }));
        }
      },
    }),
    (stateProps, dispatchProps, rest) => ({
      ...rest,
      ...stateProps,
      ...dispatchProps,
    }),
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
    isPendingProp: 'disabled',
  }),
)(props =>
  <img
    src='/public/image/icon/csv.png'
    role='button'
    title='Download CSV'
    {...props}
    style={{
      height: '2em',
      width: '2em',
      ...props.style,
    }}
  />
);

