import React from 'react';
import { browserHistory } from 'react-router-v4';
import { Card } from 'material-ui/Card';

import colors from 'styles/colors.json';
import MaterialIcon from 'components/pure/MaterialIcon';
import { connect } from 'react-redux-v5';
import isCarrier from 'selectors/isCarrier';

const styles = {
    icon: {
        color: colors.WHITE,
        textAlign: 'center',
        alignSelf: 'center',
    },
}

function StripeSuccessOnboarding({ isCarrier }) {  

    return (
        <div style={{ display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <div style={{ display: 'flex', flex: 1, maxHeight: 400 }}>
                <Card style={{ borderRadius: 10 }} containerStyle={{ maxWidth: 500, minHeight: '30%', textAlign: 'center', justifyContent: 'center' }}>
                    <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'center', backgroundColor: '#498504', flexDirection: 'column', borderTopRightRadius: 10, borderTopLeftRadius: 10, padding: 20 }}>
                        <MaterialIcon
                            name='check_circle_outline'
                            size={70}
                            style={styles.icon}
                        />
                        <p style={{ fontSize: 25, color: colors.WHITE }}>SUCCESS</p>
                    </div>
                    <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'center', flexDirection: 'column', padding: 20 }}>
                        <p style={{ fontSize: 20, color: colors.DARK_GRAY, textAlign: 'center' }}>Thank you for completing the  onboarding process, it may take some time for your account to be verified.</p>
                        <p style={{ fontSize: 17, color: colors.GRAY_TEXT, textAlign: 'center' }}>
                            <button
                                className='btn btn-orange'
                                onClick={() => browserHistory.push(isCarrier ? `/haul-pay/mycards` :`/haul-pay/mywallet`)}
                            >
                                {`Return to ${isCarrier ? 'My Card' : 'My Wallet'}`}
                            </button>
                        </p>
                    </div>
                </Card>
            </div>
        </div>
    )
}
export default connect(
    state => ({
        isCarrier: isCarrier(state)
    }), null
)(StripeSuccessOnboarding)