import React from 'react';
import PropTypes from 'prop-types';

import { USER_TYPE, checkPermissions } from 'helpers';
import formatTel from 'helpers/formatTel';
import emailIsInvalid from 'helpers/emailIsInvalid';
import createTableItems from 'helpers/createTableItems';

import FMCSALink from 'components/pure/FMCSALink';
import UserTableHideButtons from 'components/container/UserTableHideButtons';
import UserTableRecentBid from 'components/container/UserTableRecentBid';
import UserTableTargetRate from 'components/container/UserTableTargetRate';
import SignUpButtonLink from 'components/container/SignUpButtonLink';
import UserTableBidding from 'components/container/UserTableBidding';
import UpgradeToViewButton from 'components/container/UpgradeToViewButton';
import ButtonQuickPayApproved from 'components/container/ButtonQuickPayApproved';
import ButtonCall from 'components/container/ButtonCall';
import ButtonSaveLoad from 'components/container/ButtonSaveLoad';
import ButtonSaveTruck from 'components/container/ButtonSaveTruck';
import ButtonHaulPayVerified from 'components/pure/ButtonHaulPayVerified';
import UserRating2 from 'components/container/UserRating2';
import AverageDaysToPay from 'components/pure/AverageDaysToPay';


const showIfNotShipper = (item, element) => item && item.data && item.data.type !== USER_TYPE.SHIPPER ? element : null;
const showIfPresent = (item, element) => item ? element : null;

const UserTableItem = createTableItems(({ item, load_id, truck_id }) => [
  <div>
    <SignUpButtonLink />
    {checkPermissions(item, null, <UpgradeToViewButton />)}
    {checkPermissions(item, <div><strong>{item.data.dba}</strong></div>, null)}
    {showIfNotShipper(item, showIfPresent(item.data.user_id, <div><a href={`/user/${item.data.user_id}`} target='_blank'>View Profile</a></div>))}
    {item.data.id && <UserTableHideButtons id={load_id || truck_id} user_id={item.data.id} />}
    {load_id ? <ButtonSaveLoad style={{ display: 'block', marginTop: '0.5em' }} id={load_id} /> : null}
    {truck_id ? <ButtonSaveTruck style={{ display: 'block', marginTop: '0.5em' }} id={truck_id} /> : null}
  </div>,
  <div>
    {item.data.mc ? <div>MC#: {item.data.mc}</div> : null}
    {item.data.dot ? <div>DOT#: {item.data.dot}</div> : null}
    <div>{checkPermissions(item, item.data.address, <div><div>Need Full Access</div><div>To View This Data</div></div>)}</div>
    <div>{checkPermissions(item, [item.data.city, item.data.state, item.data.country].filter(i => i !== undefined).join(', '), null)}</div>
  </div>,
  <div style={{ wordBreak: 'break-all' }}>
    {showIfNotShipper(item, showIfPresent(item.data.contact_name, <div>Contact: {item.data.contact_name}</div>))}
    {(load_id || truck_id) && item.data.contact_phone && item.data.type !== USER_TYPE.SHIPPER ? <ButtonCall tel={item.data.contact_phone}>CALL NOW</ButtonCall> : null}
    {showIfNotShipper(item, showIfPresent(item.data.contact_phone, <div>Phone: <a href={`tel:${formatTel(item.data.contact_phone)}`}>{item.data.contact_phone}</a></div>))}
    {showIfNotShipper(item, showIfPresent(item.data.email, emailIsInvalid(item.data.email) ? null : <div>Email: <a href={`mailto:${item.data.email}`}>{item.data.email}</a></div>))}
    {showIfNotShipper(item, showIfPresent(item.data.contact_email, emailIsInvalid(item.data.contact_email) ? null : <div>Email: <a href={`mailto:${item.data.contact_email}`}>{item.data.contact_email}</a></div>))}
    {load_id ? <UserTableRecentBid id={load_id} /> : null}
    {load_id ? <UserTableTargetRate id={load_id} /> : null}
    {load_id ? <UserTableBidding id={load_id} /> : null}
    {truck_id && checkPermissions(item, null, <UpgradeToViewButton />)}
  </div>,
  <div>
    <UserRating2 load_id={load_id} truck_id={truck_id} user_id={item.data.id} openRateModal={item.data.type === USER_TYPE.SHIPPER} />
    {checkPermissions(item, null, <div><div>Need Full Access</div><div>To View This Data</div></div>)}
    <ButtonQuickPayApproved load_id={load_id} />
    <AverageDaysToPay avgpay={item.data.avgpay} />
    <FMCSALink mc={item.data.mc} dot={item.data.dot} />
    {
      truck_id && item.data.factoring_approved === 'approved' ?
        <ButtonHaulPayVerified />
        : null
    }
    <SignUpButtonLink />
  </div>,
]);

export const UserItem = UserTableItem.TableItem;
export const UserBlockItem = UserTableItem.BlockTableItem;

UserItem.propTypes = UserBlockItem.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string,
      dba: PropTypes.string,
      mc: PropTypes.number,
      dot: PropTypes.number,
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      country: PropTypes.string,
      contact_name: PropTypes.string,
      contact_phone: PropTypes.string,
      contact_email: PropTypes.string,
      credit_approved: PropTypes.bool,
      avgpay: PropTypes.number,
    }),
  }),
};
