/**
 * Connected stateful sinful component for displaying a form that lets the user bid by the amount of miles for a given route
 * @module components/pure/modals/BidCreatePerMile
 * @since 3.0.0
 * @property {object} props
 * @property {string} id - the ID of the load on which to create bids
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm, reset } from 'redux-form';
import validate from 'validate.js';
import convert from 'convert-units';
import get from 'lodash/get';
import mapProps from 'recompose/mapProps';

import formatPennies from 'helpers/formatPennies';
import normalizeNumber from 'helpers/normalizeNumber';
import PermissionsError from 'datatypes/PermissionsError';

import { closeModal, openModal } from 'actions/ui';
import { create } from 'actions/resourceBy/load/bid';

import Resource from 'components/hoc/Resource';
import nothingIfFetching from 'components/hoc/nothingIfFetching';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GenericHeader from './GenericHeader';


const BidCreatePerMile = compose(
  Resource('load'),
  nothingIfFetching(),
  Resource('map', {
    resourcePropName: 'map',
    idPropName: ({ item }) => ({
      origin: item.data.origin_coords,
      destination: item.data.destination_coords,
    }),
  }),
  nothingIfFetching('map'),
  mapProps(({ item, map, ...rest }) => {
    let distance = get(map, ['data', 'routes', '0', 'legs', '0', 'distance', 'value']);
    if (distance) {
      distance = Math.round(convert(distance).from('m').to('mi'));
      return {
        initialValues: {
          mileage: distance,
        },
        ...rest,
      };
    }
  }),
  connect(
    state => {
      const selector = formValueSelector('BidCreatePerMile');
      return {
        logged_in: state.user.logged_in,
        total: selector(state, 'amount') * selector(state, 'mileage') * 100,
      };
    },
    {
      openModal,
      closeModal,
      create,
    },
  ),
  mapProps(({ id, logged_in, openModal, closeModal, create, total, initialValues }) => ({
    initialValues,
    total,
    closeModal,
    onSubmit(fields) {
      return create(id, {
        amount: fields.amount * fields.mileage * 100,
        bidder_notes: fields.note,
      })
        .then(() => {
          closeModal();
          reset('BidCreateForm');
          reset('BidCreatePerMile');
          openModal('success', { message: 'Successfully posted bid' });
        })
        .catch(err => {
          if (err instanceof PermissionsError) {
            if (logged_in) {
              openModal('upgradeaccount', { message: 'Please upgrade your account to bid on this shipper load now.' });
            }
            else {
              openModal('loginprompt');
            }
            closeModal();
            return;
          }
          let message = 'Error posting bid.';
          if (err) {
            if (err.status === 400) {
              try {
                const json = JSON.parse(err.message);
                if (json.amount && json.amount[0]) {
                  message = json.amount;
                }
              }
              catch (e) {}
            }
            else if (err.amount && err.amount[0]) {
              message = err.amount;
            }
            else if (err.message) {
              message = err.message;
            }
          }
          return openModal('error', { message });
        })
      ;
    },
  })),
  reduxForm({
    form: 'BidCreatePerMile',
    validate: values => validate(values, {
      mileage: {
        presence: true,
        numericality: {
          strict: true,
        },
      },
      amount: {
        presence: true,
        numericality: {
          strict: true,
        },
      },
      note: {
        length: {
          maximum: 250,
        },
      },
    }),
  }),
)(({ closeModal, total, handleSubmit, submitting }) =>
  <div>
    <GenericHeader closeModal={closeModal}>PER MILE RATE</GenericHeader>
    <div className='modal-body'>
      <Field name='mileage' component={LabeledInput} label='Route Mileage' type='number' placeholder='miles' />
      <Field name='amount' component={LabeledInput} label='Rate Per Mile' type='number' placeholder='$/mile' normalize={normalizeNumber} />
      <Field name='note' component={LabeledInput} label='Add Note w/ Bid' placeholder='Note' containerProps={{ style: { marginTop: '1em' } }} />
    </div>
    <div className='modal-footer'>
      <div className='pull-left' style={{ fontSize: '1.1em', fontWeight: 'bold' }}>Total: {Number.isNaN(total) ? 'n/a' : formatPennies(total)}</div>
      <button className='btn btn-default' disabled={submitting} onClick={closeModal}>Cancel</button>
      <button className='btn btn-orange' disabled={submitting} onClick={handleSubmit}>Submit Bid</button>
    </div>
  </div>
);

BidCreatePerMile.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BidCreatePerMile;
