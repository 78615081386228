import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';


/**
 * Our Load by User ControlledPaginatedResourceCollection instance
 */
export const onboarddebtorByUserResource = new ControlledPaginatedResourceCollection('user', 'onboarddebtor', {
  url: new UrlAssembler('dispatch/onboard'),
  paginatedResourceOptions: {
    url: new UrlAssembler('dispatch/onboard'),
    append_to_top_on_create: true,
  },
});

export default onboarddebtorByUserResource;
export const {
  fetch,
  fetchNext,
  create,
} = onboarddebtorByUserResource;
