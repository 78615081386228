import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import MaterialIcon from 'components/pure/MaterialIcon';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  buttonClose: {
    backgroundColor: 'transparent',
    color: '#000000',
    marginRight: 10,
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 22,
    color: '#5c6979',
    textAlign: 'center',
    width: '90%',
    fontWeight: '400',
  },
  icon: {
    //fontSize: 50,
    color: '#498504',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    color: '#979797',
  },
};

const DraftSaved = ({ closeModal, action, ...props }) => (
  <div>
    <ModalBody closeModal={closeModal}>
      <div
        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
        >
        <MaterialIcon
          name='check_circle_outline'
          size={90}
          style={styles.icon}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
        <p style={styles.text}>
          Thank you for adding a draft request, we will not process this until you finish it.
        </p>
      </div>
    </ModalBody>
  </div>
);

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ))(DraftSaved);
