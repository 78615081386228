

const invalidEmailTests = [
  '',
  'donotsendemail@posteverywhere.com',
  /@posteverywhere\.com$/,
];

const emailIsInvalid = email => {
  if (!email || typeof email !== 'string') {
    return true;
  }
  email = email.toLowerCase();
  for (const test of invalidEmailTests) {
    if (typeof test === 'string') {
      if (email === test) {
        return true;
      }
    }
    else {
      if (test.test(email)) {
        return true;
      }
    }
  }
  return false;
};

export default emailIsInvalid;
