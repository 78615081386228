/**
 * Component to display a sortable table for the Load History page
 * @module components/pure/LoadPostHistoryTable
 * @since 3.0.0
 * @requires components/container/LoadPostHistoryTableItem
 * @requires components/pure/ResponsiveTable
 * @property {object} props
 * @property {string[]} props.ids
 * @property {boolean} [props.isFetching = false]
 * @property {function} props.fetchNext
 * @property {string} props.currentOrder
 * @property {function} props.sort
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { initiateCopy } from 'actions/post';

import Resource from 'components/hoc/Resource';
import scrollToItem from 'components/hoc/scrollToItem';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import LoadMobile3 from 'components/pure/LoadMobile3';
import TableItemAddonBids from 'components/container/TableItemAddonBids';


const LoadPostTableItem = compose(
  Resource('load', { resourcePropName: 'load' }),
  connect(
    state => ({
      scroll_to_item: state.ui.scroll_to_item,
    }),
    {
      initiateCopy: (...args) => initiateCopy('load', ...args),
    },
  ),
  scrollToItem(({
    idPropName: ['load', 'data', 'id'],
  })),
)(LoadMobile3);

const LoadPostHistoryTable = ({ ids, isFetching = false, fetchNext }) =>
  <NormalInfiniteScroll
    isFetching={isFetching}
    onScrollBottom={fetchNext}
    placeholder={<div className='help-block text-center'>No loads (yet)<br />Try posting some</div>}
    >
    {!ids ? null : ids.map(id =>
      <div key={id}>
        <LoadPostTableItem id={id} style={{ marginBottom: 0 }} />
        <TableItemAddonBids load_id={id} />
      </div>
    )}
  </NormalInfiniteScroll>
  ;

export default LoadPostHistoryTable;
