const formatError = (err, retention) => {
  let err_msg = err.message;
  try {
    // sometimes err message is a stringified object
    err_msg = JSON.parse(err.message);
  }
  catch (errception) {
    // pass
  }
  let message = '';

  if (typeof err_msg === 'string'){
    return err_msg;
  }
  try {
    for (const error in err_msg) {
      if (err_msg.hasOwnProperty(error)){
        if (err_msg[error].constructor === Array){
          const key = error
            .split('_')
            .map(s => s.charAt(0).toUpperCase() + s.substring(1, s.length))
            .join(' ');
          message += `${key}: ${err_msg[error][0]}`;
        }
        else {
          message += err_msg[error];
        }
      }
    }
    return message;
  }
  catch (errception) {
    console.warn(errception);
    return retention;
  }
};

export default formatError;
