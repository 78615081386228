/**
 * Component to display an icon using an image file
 * @module components/pure/ImageIcon
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.src - the link to the image file to display
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/ImageIcon';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const ImageIcon = ({ src, ...rest }) =>
  <span {...rest} className={cs('ImageIcon', rest.className)} style={{ ...rest.style, backgroundImage: `url(${src})` }} />
  ;

ImageIcon.propTypes = {
  src: PropTypes.string.isRequired,
};

export default ImageIcon;
