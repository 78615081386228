import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import setDisplayName from 'recompose/setDisplayName';

import { setLastTool } from 'actions/ui';


export default name => compose(
  setDisplayName('setLastTool'),
  connect(null, { setLastTool }),
  lifecycle({
    componentDidMount() {
      const { setLastTool } = this.props;
      if (typeof name === 'function') {
        name = name(this.props);
      }
      setLastTool(name);
    },
  }),
  mapProps(({ setLastTool, ...rest }) => rest),
);
