import React from 'react';
import RouteTabs from 'components/pure/RouteTabs';

const PostLoadContainer = ({ children, ...props }) =>
  <div className='row' style={{ padding: '1.06em', width: '100%' }}>
    <RouteTabs
      routes={[
        ['/postload/all-loads', 'All Loads'],
        ['/postload/haulpay', 'HaulPay Loads'],
      ]}
    />
    {children}
  </div>
;
export default PostLoadContainer;
