import React from 'react';

const ColoredMoney = ({ value, children }) => {
  if (Number(value) > 0) {
    return <div style={{ color: 'green' }}>{children}</div>;
  }
  else if (Number(value) < 0) {
    return <div style={{ color: 'red' }}>{children}</div>;
  }
  return <div style={{ color: 'black' }}>{children}</div>;

};

export default ColoredMoney;
