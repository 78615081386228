import React from 'react';
import { compose } from 'redux-v3';
import PropTypes from 'prop-types';
import withContext from 'recompose/withContext';

import RouteTabs from 'components/pure/RouteTabs';
import ButtonFactoringClientExportPayments from 'components/container/ButtonFactoringClientExportPayments';

const FactoringClient = compose(
  withContext(
    {
      params: PropTypes.object,
    },
    ({ params }) => ({ params }),
  ),
)(({ children, params }) => {
  const { id } = params;
  const base_path = `/admin/factoring/client/${id}`;
  return (
    <div className='col-fill'>
      <RouteTabs
        className='nav-justified'
        style={{ flexGrow: 0 }}
        routes={[
          [base_path, 'Factoring Profile'],
          [
            `${base_path}/payments`,
            <span>
              Funding Requests
              {' '}
              <ButtonFactoringClientExportPayments
                client={id}
                className='pull-right'
              />
            </span>,
          ],
          [`${base_path}/debtors`, 'Connected Debtors'],
          [`${base_path}/brokerscarriers`, 'Connected Brokers / Carriers'],
          [`${base_path}/debtoradd`, 'Add Debtors'],
        ]}
      />
      {children}
    </div>
  );
});

export default FactoringClient;
