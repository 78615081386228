/**
 * Actions relating to the factoringpayment resource
 * @module actions/resource/factoringpayment
 * @since 3.0.1
 * @requires datatypes/compose/resource
 * @requires datatypes/compose/resource/Editable
 * @requires datatypes/FetchError
 * @requires datatypes/DotNotationFormData
 */
/* global API */
import { compose } from 'redux-v3';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import ControlledResource from 'datatypes/ControlledResource';

class ReleaseFundsResource extends ControlledResource {

  constructor(name, options) {
    super(name, options);

    const upperName = this.name.toUpperCase();
    this.actions = {
      ...this.actions,
      CREATE_FUNDS_REQUEST: Symbol(`${upperName}_CREATE_FUNDS_REQUEST`),
      CREATE_FUNDS_REQUEST_SUCCESS: Symbol(`${upperName}_CREATE_FUNDS_REQUEST`),
      CREATE_FUNDS_REQUEST_FAILURE: Symbol(`${upperName}_CREATE_FUNDS_REQUEST`),
    };

    this.createBulk = this.createBulk.bind(this);
  }
  createBulk = (data, user_id) => async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: this.actions.CREATE_FUNDS_REQUEST,
      payload: undefined,
    });

    const payload = {
      reserves: data,
    };
    try {
      const res = await dispatch(APIFetch(`${API.host}/reserve/${user_id}/settle/`, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
          authorization: `Bearer ${state.user.token}`,
          'content-type': 'application/json',
        },
      }));
      if (res.status !== this.options.acceptableCreateStatusCode) {
        const text = await res.text();
        throw new FetchError(res.status, text);
      }
      const json = await res.json();
      dispatch({
        type: this.actions.CREATE_FUNDS_REQUEST_SUCCESS,
        payload: json,
      });
    }
    catch (err) {
      dispatch({
        type: this.actions.CREATE_FUNDS_REQUEST_FAILURE,
        payload: err,
      });
      throw err;
    }
  };
}
const releaseFunds = new ReleaseFundsResource('releasefunds');

export const {
  createBulk,
} = releaseFunds;
export default releaseFunds;
