

const getCurrentLocation = () => {
  if (!getCurrentLocation.isAvailable) {
    return Promise.reject(new Error('geolocation unavailable'));
  }
  return new Promise((resolve, reject) => {
    try {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    }
    catch (err) {
      reject(err);
    }
  });
};

getCurrentLocation.isAvailable = 'geolocation' in navigator;

export default getCurrentLocation;
