/* global fbq process */
import React from 'react';
import { lifecycle, withState, compose } from 'recompose';
import { connect } from 'react-redux-v5';
import { submit, isSubmitting, reduxForm, Field, SubmissionError } from 'redux-form';
import get from 'lodash/get';
import validate from 'validate.js';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import CreatePaymentProfileForm from '../form/CreatePaymentProfileForm';
import { closeModal, openModal } from '../../../actions/ui';
import PureUploadFile from '../PureUploadFile';
import { fetchTerms } from 'actions/factoring';
import { updatePaymentsProfile, createFactorCompanyRelation, sendFactorCompanyInvite } from 'actions/groups';
import getBrokerTerms from '../../../selectors/getBrokerTerms';
import { createPaymentProfileInvitedUser, createBrokerCarrierConnection } from '../../../actions/factoring';
import Spinner from '../Spinner';
import FactorCompanyAutoComplete from '../form/inputs/FactorCompanyAutoComplete';
import segmentEvents from '../../../helpers/segmentEvents';

export const CREATE_PAYMENT_PROFILE_FORM_NAME = 'create_payment_profile';

const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-orange'>{title}</h4>
);

export const categories = [
  {
    text: 'Licence',
    value: 'driver_license',
  },
  {
    text: 'Insurance',
    value: 'insurance',
  },
  {
    text: 'Void Check',
    value: 'voided_check',
  },
  {
    text: 'NOA',
    value: 'noa',
  },
  {
    text: 'Other',
    value: '',
  },
];

const mappedCategories = {
  'driver_license': 'Licence',
  'insurance': 'Insurance',
  'voided_check': 'Void Check',
};

export const SetPaymentSpeed = reduxForm({
  form: CREATE_PAYMENT_PROFILE_FORM_NAME,
  enableReinitialize: true,
  destroyOnUnmount: false,
  initialValues: {
    payout_days: 'ach_1_day'
  },
  validate(fields) {
    return validate(fields, {
      payout_days: {
        presence: true,
      },
    });
  }
})(props => <div className="light-placeholder">
  <Field
    component={LabeledDropdown}
    name='payout_days'
    style={{
      minWidth: '4em',
      marginLeft: '5px',
    }}
    data={props.terms}
    textField='label'
    valueField='value'
  />
  <a onClick={() => props.setStep('factorCompany')}>Select a factoring company</a>
</div>)

export const SetFactorCompany = reduxForm({
  form: CREATE_PAYMENT_PROFILE_FORM_NAME,
  destroyOnUnmount: false,
  initialValues: {
    factor_company: ''
  },
  validate(fields) {
    return validate(fields, {
      factor_company: {
        presence: true,
      },
    });
  }
})(props => <div className="light-placeholder">
  <Field
    component={FactorCompanyAutoComplete}
    name='factor_company'
    style={{
      minWidth: '4em',
      marginLeft: '5px',
    }}
    textField='name'
    valueField='id'
  />
  {props.error && <p className='text-danger'>{props.error}</p>}
  <a onClick={() => props.setStep('paymentSpeed')}>Select a Payment Speed</a>
</div>)

export const mappingSubmitButtonMapping = {
  form: 'Next',
  paymentSpeed: 'Next',
  factorCompany: 'Next',
  upload: 'Submit'
}

const CreatePaymentProfile = ({ relationshipId, setRelationshipId, terms, closeModal, email, phoneNumber, handleSubmit, paymentId, setPaymentId, step, setStep, submitting, openModal, factoringId, createBrokerCarrierConnection, invitedUserId }) => {

  const renderMappingComponent = () => {
    switch (step) {
      case 'form':
        return <CreatePaymentProfileForm
          initialValues={{
            owner_email: email,
            owner_phone_number_cell: phoneNumber,
          }}
          invitedUserId={invitedUserId}
          factoringId={factoringId}
          email={email}
          phoneNumber={phoneNumber}
          closeModal={closeModal}
          setPaymentId={setPaymentId}
          setRelationshipId={setRelationshipId}
          setStep={setStep}
          openModal={openModal}
          createBrokerCarrierConnection={createBrokerCarrierConnection}
          onSubmit={async (fields, dispatch) => {
            try {
              const data = {
                company_mc: fields.mc,
                company_dot: fields.dot,
                owner_address_city: get(fields, "address.city"),
                owner_address_country: get(fields, "address.country"),
                owner_address_state: get(fields, "address.state"),
                owner_address_street_one: get(fields, "address.street_one"),
                owner_address_zip: get(fields, "address.zip"),
                company_address_street_one: get(fields, "address.street_one"),
                company_address_city: get(fields, "address.city"),
                company_address_state: get(fields, "address.state"),
                company_address_zip: get(fields, "address.zip"),
                ...fields,
              };
              const res = await dispatch(createPaymentProfileInvitedUser(data));
              setRelationshipId(res.relationship_id);
              setPaymentId(res.id);
              setStep('paymentSpeed');
              window.analytics.track(segmentEvents.BROKER_CREATED_PAYMENT_PROFILE_THEMSELVES);
            }
            catch (e) {
              closeModal();
              openModal('error', { message: 'Error while creating payment profile.' });
            }
          }}
        />
      case 'paymentSpeed':
        return <SetPaymentSpeed
          terms={terms}
          setStep={setStep}
          onSubmit={async (fields, dispatch) => {
            try {
              const res = await dispatch(updatePaymentsProfile(
                factoringId,
                relationshipId,
                {
                  payout_days: fields.payout_days,
                }));
              setStep('upload');
            }
            catch (err) {
              console.warn(err);
            }
          }}
        />
      case 'factorCompany':
        return <SetFactorCompany
          setStep={setStep}
          onSubmit={async (fields, dispatch) => {
            try {
              const companyId = get(fields, 'factor_company.id', '');
              await dispatch(createFactorCompanyRelation(
                companyId,
                factoringId,
                paymentId
              ));
              await dispatch(sendFactorCompanyInvite(companyId, paymentId));
            }
            catch (err) {
              if (err?.payment_profile === 'No relation with the factoring company found') {
                throw new SubmissionError({ _error: 'Carrier is currently linked to another factoring company. If you believe this is an error, please reach out to our support team at support@haulpay.io for assistance.' })
              }
            }
            setStep('upload');
          }}
        />
      case 'upload':
        return <PureUploadFile
          categoriesProps={{
            abbreviationKey: 'value',
            fulltextKey: 'text',
            breakPoint: 2,
          }}
          categories={categories}
          requestId={paymentId}
          type='payment'
          extraText={
            <p style={{ color: 'green', textAlign: 'center', width: '95%' }}>Upload an NOA or voided check to expedite the payment approval process for your carrier.</p>
          }
        />
      default:
        break;
    }
  }
  return (<div>
    <ModalBody closeModal={closeModal}>
      <ModalTitle title={'Create Carrier Payment Profile'} />
      <p className='text-grey' >If the carrier has not been able to complete the boarding themselves this enables you to create their payment profile for them to be reviewed. </p>
      <hr />
      <ModalBody >
        {renderMappingComponent()}
      </ModalBody>
    </ModalBody>
    <ModalFooter >
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
      <button
        disabled={submitting}
        className='btn btn-orange'
        type='button'
        onClick={() => {
          if (step !== 'upload') {
            return handleSubmit();
          }
          closeModal();
          openModal('success', { message: 'Payment Profile successfully has been created.' });
          window.location.reload();
        }}
      >
        <span>
          {
            submitting ?
              <div>
                {mappingSubmitButtonMapping[step]}
                <Spinner style={{ fontSize: 3 }} />
              </div>
              : mappingSubmitButtonMapping[step]
          }
        </span>
      </button>
    </ModalFooter>
  </div>)
};

export const formattedPaymentTerms = terms => (terms || []).filter(term => term.enable_factoring).map(term => {
  if (term.payee === 'factoring_company') {
    return;
  }
  let label = `${term.payout_days}`;
  let value = String(term.payout_days);

  if (value === '0') {
    value = 'ach_1_day';
  }
  else {
    value = `ach_${term.payout_days}_day`;
  }

  if (label === '0') {
    label = '1 Day';
  }
  else {
    label += ' Days';
  }

  if ((term.discount_rate || term.carrier_rate) === 0) {
    label += ' (Free)';
  }
  else {
    label += ` (${term.discount_rate || term.carrier_rate}% Quick Pay Fee)`;
  }

  return {
    id: term.id,
    value,
    text: label,
    label,
    is_default: term.is_default,
    rate: term.carrier_rate,
    splitFee: term.factoring_fee_split_rate,
    discountRate: term.discount_rate,
  };
});

export default compose(
  withState('paymentId', 'setPaymentId', null),
  withState('step', 'setStep', 'form'),
  withState('relationshipId', 'setRelationshipId', ''),
  connect(state => ({
    submitting: isSubmitting(CREATE_PAYMENT_PROFILE_FORM_NAME)(state),
    factoringId: get(state.resource.user, [state.user.id, 'data', 'factoring_id']),
    terms: formattedPaymentTerms(getBrokerTerms(state)),
  }),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
      createBrokerCarrierConnection: (...args) =>
        dispatch(createBrokerCarrierConnection(...args)),
      handleSubmit: () =>
        dispatch(submit(CREATE_PAYMENT_PROFILE_FORM_NAME)),
      openModal: (...args) =>
        dispatch(openModal(...args)),
      fetchTerms: (...args) => dispatch(fetchTerms(...args)),
    }),
  ),
  lifecycle({
    async componentDidMount() {
      const { fetchTerms, factoringId } = this.props;
      await fetchTerms(factoringId);
    }
  })
)(CreatePaymentProfile);
