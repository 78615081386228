import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';

import { USER_TYPE } from 'helpers';
import * as validate from 'helpers/validate';

import { closeModal, openModal } from 'actions/ui';
import { editProfile2 } from 'actions/resource/user';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import getSelf from 'selectors/getSelf';

export default compose(
  withState('lanes', 'setLanes', 1),
  connect(
    state => ({
      id: state.user.id,
      self: getSelf(state),
    }),
    {
      closeModal,
    },
  ),
  mapProps(({ self, ...rest }) => ({
    ...rest,
    self,
    initialValues: {
      full_truck_loads_per_month: self.data.full_truck_loads_per_month,
      ltl_loads_per_month: self.data.ltl_loads_per_month,
      equipment_types: self.data.equipment_types.map(et => MultiTrailerInput.trailerTypes.find(({ value }) => et === value)),
      commodities_shipped: self.data.commodities_shipped,
      lane_origin: [],
      lane_destination: [],
    },
  })),
  reduxForm({
    form: 'ShipperSignUpExtraForm',
    async onSubmit(fields, dispatch, { id, lanes }) {
      try {
        await dispatch(editProfile2(id, {
          full_truck_loads_per_month: fields.full_truck_loads_per_month,
          ltl_loads_per_month: fields.ltl_loads_per_month,
          commodities_shipped: fields.commodities_shipped,
          equipment_types: fields.equipment_types.map(({ value }) => value),
          lanes: fields.lane_origin.slice(0, lanes).reduce((acc, curr, i) => {
            const origin = curr;
            const destination = fields.lane_destination[i];
            const ret = { origin };
            if (destination && destination.state) {
              ret.destination = destination;
            }
            acc.push(ret);
            return acc;
          }, []),
        }));
        dispatch(closeModal());
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'There was an error updating your information. :(' }));
      }
    },
  }),
)(({ lanes, setLanes, self, handleSubmit, submitting, closeModal }) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Complete {self.data.type === USER_TYPE.SHIPPER ? 'Shipper' : 'Broker'} Sign Up</h4>
    </div>
    <div className='modal-body'>
      <p>The info entered below will help us match you to the best carriers who can move your loads for the best rates.</p>
      <Field
        name='full_truck_loads_per_month'
        component={LabeledInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='How many full truck loads do you pay for per month?'
        type='number'
      />
      <Field
        name='ltl_loads_per_month'
        component={LabeledInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='How many LTL loads do you pay for per month?'
        type='number'
      />
      <Field
        name='commodities_shipped'
        component={LabeledInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='What are the commodities you ship?'
      />
      <Field
        name='equipment_types'
        component={MultiTrailerInput}
        validate={validate.compose(
          validate.vjs.presence(),
          validate.numericality({
            minimim: 1,
            tooSmall: ({ minimum }) => `Please enter at least ${minimum} equipment type`,
            tokenizer: ({ length }) => length,
          }),
        )}
        label='What equipment types do you need for your loads?'
      />
      <label style={{ display: 'block' }}>What are your common lanes?</label>
      {(() => {
        const ret = [];
        for (let i = 0; i < lanes; i++) {
          if ((i > 0)) {
            ret.push(<hr />);
          }
          ret.push(
            <div>
              <Field
                name={`lane_origin[${i}]`}
                component={GoogleLocationInput}
                specificity={GoogleLocationInput.specificity.CITY}
              />
              <Field
                name={`lane_destination[${i}]`}
                component={GoogleLocationInput}
                specificity={GoogleLocationInput.specificity.CITY}
              />
            </div>
          );
        }
        return ret;
      })()}
      <p>
        <a
          role='button'
          onClick={e => {
            e.preventDefault();
            setLanes(lanes + 1);
          }}
          >
          Add another lane
        </a>
      </p>
      {
        lanes > 1 ?
          <p>
            <a
              role='button'
              onClick={e => {
                e.preventDefault();
                setLanes(lanes - 1);
              }}
              >
            Remove last lane
            </a>
          </p>
          : null
      }
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal} type='button'>Skip</button>
      <button className='btn btn-orange' type='submit' disabled={submitting}>Finish</button>
    </div>
  </form>
);
