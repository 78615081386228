/* global google */

const AUTOCOMPLETE = new google.maps.places.AutocompleteService();

const cache = (() => {
  const cache = Object.create(null);
  const getKey = (input, types, country) => `${input}/${country}/${types ? types[0] : ''}`;
  return {
    get(input, types, country) {
      return cache[getKey(input, types, country)];
    },
    set(input, types, country, value) {
      return cache[getKey(input, types, country)] = value;
    },
  };
})();

const getPlacePredictions = (input, types, sessionToken, country = 'US') => new Promise((resolve, reject) => {
  const cached = cache.get(input, types, country);
  if (cached) {
    return resolve(cached);
  }
  AUTOCOMPLETE.getPlacePredictions({
    bounds: {
      north: 15.901418,
      east: -51.150238,
      south: 70.208070,
      west: -166.340002,
    },
    input,
    types,
    sessionToken,
    componentRestrictions: {
      country,
    },
  }, (res, status) => {
    if (status === google.maps.places.PlacesServiceStatus.ZERO_RESULTS) {
      cache.set(input, types, country, []);
      return resolve([]);
    }
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      cache.set(input, types, country, res);
      return resolve(res);
    }
    reject(status);
  });
});

// get multiple countries simultaneously, because the autocomplete service doesn't have this feature itself
export default (input, types, sessionToken) => Promise.all([
  getPlacePredictions(input, types, sessionToken), // default 'us'
  getPlacePredictions(input, types, sessionToken, 'CA'),
  getPlacePredictions(input, types, sessionToken, 'MX'),
]).then(suggestions => {
  // chop off suggestions equally
  const limit = 10;
  const total = suggestions.reduce((acc, curr) => acc + curr.length, 0);
  const difference = total - limit;
  if (difference > 0) {
    const chunks = Math.floor(limit / suggestions.length);
    const remainder = limit % suggestions.length;
    for (let i = 0, len = suggestions.length; i < len; i++) {
      let curr = suggestions[i];
      curr = curr.slice(0, chunks + (i < remainder));
      suggestions[i] = curr;
    }
  }
  // flatten
  return suggestions.reduce((acc, curr) => acc.concat(curr), []);
});
