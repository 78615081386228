/**
 * Component that displays the user search form
 * @module components/pure/form/UserSearchForm
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/search/user
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/LabeledDropdown
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';

import { openModal } from 'actions/ui';
import { clear, fetch } from 'actions/search/user';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import ButtonToggleInput from 'components/pure/form/inputs/ButtonToggleInput';

const FORM_NAME = 'UserSearch';

export default compose(
  connect(
    (state, { form = FORM_NAME }) => {
      const selector = formValueSelector(form);
      return {
        searchBy: selector(state, 'searchBy'),
      };
    },
  ),
  reduxForm({
    form: 'UserSearch',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch) {
      try {
        dispatch(clear());
        await dispatch(fetch({
          ...fields,
          ...(() => {
            if (fields.has_active_subscription === 'true') {
              return {
                has_active_subscription: true,
              };
            }
            if (fields.has_active_subscription === 'false') {
              return {
                has_active_subscription: false,
              };
            }
            if (fields.searchBy !== 'address' && fields.state) {
              return {
                state: fields.state ? fields.state.map(state => state.code).join(',') : undefined,
              };
            }
            if (fields.equipment_types) {
              return {
                equipment_types: fields.equipment_types.map(equipment => equipment.value).join(','),
              };
            }
          })(),
        }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: err }));
      }
    },
  }),
)(({ handleSubmit, submitting, reset, searchBy }) =>
  <form onSubmit={handleSubmit}>
    <Field
      name='user_type'
      component={LabeledDropdown}
      label='User Type'
      data={[
        { text: 'Any', value: '' },
        { text: 'Shipper', value: 'shipper' },
        { text: 'Carrier', value: 'carrier' },
        { text: 'Broker', value: 'broker' },
        { text: 'Dispatcher', value: 'dispatcher' },
      ]}
      valueField='value'
      textField='text'
    />
    <Field
      name='has_active_subscription'
      component={LabeledDropdown}
      label='Is Subscribed'
      data={[
        {
          text: 'Yes',
          value: 'true',
        },
        {
          text: 'No',
          value: 'false',
        },
        {
          text: 'Either',
          value: 'null',
        },
      ]}
      valueField='value'
      textField='text'
    />
    <Field name='email' component={LabeledInput} label='Email' />
    <Field name='stripe_email' component={LabeledInput} label='Stripe Email' />
    <Field name='contact_email' component={LabeledInput} label='Contact Email (PE Only)' />
    <Field name='dba' component={LabeledInput} label='Business Name' />
    <Field name='contact_phone' component={LabeledInput} label='Phone' />
    <Field name='contact_name' component={LabeledInput} label='Contact Name' />
    <Field name='equipment_types' component={MultiTrailerInput} label='Equipment Types' />
    <Field name='mc' component={LabeledInput} label='MC #' type='number' />
    <Field name='dot' component={LabeledInput} label='DOT #' type='number' />
    <div className='inline-form-control-block'>
      {
        <Field name='searchBy' component={ButtonToggleInput} data={[{ text: 'By Address', value: 'address' }, { text: 'By State', value: 'state' }]} />
      }
      {
        searchBy === 'address' ?
          <Field name='address' component={LabeledInput} label='Address' />
          :
          <Field name='state' component={MultiStateInput} label='State' />
      }
    </div>
    <div className='text-right'>
      <button className='btn btn-default pull-left' type='button' onClick={reset}>Reset</button>
      <button className='btn btn-orange' disabled={submitting} type='submit'>SEARCH</button>
    </div>
  </form>
);
