import React from 'react';
import colors from 'styles/colors.json';
import { withState } from 'recompose';
import { Field } from 'redux-form';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';

const LoadInstructions = ({ collapse, setCollapse, name }) => {
  return (
    <div>
      <p style={{ color: !collapse ? colors.BLUE : colors.ORANGE }} onClick={() => setCollapse(!collapse)}>{`${
        collapse ? '- Hide' : '+ Add'
      } Instructions`}</p>
      {collapse && (
        <div>
          <Field name={`${name}.instructions`} component={LabeledInput} />
        </div>
      )}
    </div>
  );
};
export default withState('collapse', 'setCollapse', false)(LoadInstructions);
