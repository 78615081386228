import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import cs from 'classnames';


const RadioInput = ({ name, label, values, inline = false, ...rest }) =>
  <div {...rest} className={cs('form-group', rest.className)} >
    {label ? <label>{label}</label> : null}
    <div>
      {values.map(({ label, value }) =>
        <div key={value} className={inline ? 'radio-inline' : 'radio'}>
          <label>
            <Field
              name={name}
              component='input'
              type='radio'
              value={value}
            />
            {label}
          </label>
        </div>
      )}
    </div>
  </div>
  ;

RadioInput.propTypes = {
  inline: PropTypes.bool,
  label: PropTypes.node,
  values: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.node,
    value: PropTypes.any.isRequired,
  })).isRequired,
};

export default RadioInput;
