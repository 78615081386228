import React from 'react';
import Spinner, { CenteredSpinner } from './Spinner';

const BackgroundSpinner = () =>
  <div
    className='cover-spin'
  >
    <div
      style={{
        margin: 0,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Spinner />
    </div>
  </div>;

export default BackgroundSpinner;
