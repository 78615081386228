import React from 'react';
import wrapDisplayName from 'recompose/wrapDisplayName';
import getDisplayName from 'recompose/getDisplayName';


const DEFAULT_OPTIONS = {
  promiseProp: 'onSubmit',
  isPendingProp: 'submitting',
  promiseFn: null,
};

export default options => {
  if (typeof options === 'function') {
    options = {
      ...DEFAULT_OPTIONS,
      promiseFn: options,
    };
  }
  else {
    options = {
      ...DEFAULT_OPTIONS,
      ...options,
    };
  }
  return WrappedComponent => class extends React.Component {
    constructor(props) {
      super(props);
      this.state = { [options.isPendingProp]: false };
      this.wrappedPromiseFn = this.wrappedPromiseFn.bind(this);
    }

    static displayName = wrapDisplayName(getDisplayName(WrappedComponent), 'wrapPromisePending');

    wrappedPromiseFn(...args) {
      this.setState({ [options.isPendingProp]: true });
      const promiseFn = options.promiseFn ? options.promiseFn(this.props) : this.props[options.promiseProp];
      return promiseFn(...args)
        .then(() => this.setState({ [options.isPendingProp]: false }))
        .catch(err => {
          this.setState({ [options.isPendingProp]: false });
          throw err;
        })
      ;
    }

    render() {
      return React.createElement(WrappedComponent, {
        ...this.props,
        [options.promiseProp]: this.wrappedPromiseFn,
        [options.isPendingProp]: this.state[options.isPendingProp],
      });
    }
  };
};
