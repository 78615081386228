import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringclientdebtorauditlog;

import factoringclientdebtorauditlogResource from 'actions/resource/factoringclientdebtorauditlog';


export default function factoringclientdebtorauditlogResourceReducer(state = initialState, action) {
  state = factoringclientdebtorauditlogResource.reduce(state, action);
  return state;
}
