/**
 * Component that displays a table with infinite scrolling built in
 * @module components/pure/Table
 * @since 3.0.0
 * @property {object} props
 * @property {string[]} props.headers - the list of headers
 * @property {object[]} props.items - the list of items
 * @property {function} props.fetchNext - a function that will fetch the next chunk of items
 * @property {function} props.onHeaderItemClick - a function to call when you click on a table header, gets passed the index of the clicked header
 * @property {function} props.onItemClick - a function to call when you click a row in the table, gets passed the item
 * @property {node|node[]} props.placeholder - node to display if items array is empty
 * @property {boolean} [props.isFetching] - whether to display the loading spinner
 * @property {function} [props.mapItemToCells] - function that is passed each item in `props.items`, and is expected to return an array of nodes representing table cells for this item's row
 * @property {any} [props....rest] - props passed to [Table]{@link module:components/pure/Table} component
 */
import colors from 'styles/colors.json';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import difference from 'lodash/difference';
import get from 'lodash/get';

import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import getScrollBottom from 'helpers/getScrollBottom';
import Spinner from 'components/pure/Spinner';

const LoadingOverlay = ({ children }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    }}
    >
    {children}
  </div>
);

class Table extends Component {
  constructor(props) {
    super(props);
    this.raf_waiting = false;
    this.still_loading = true;

    this.state = {
      page: 1,
      selectedItemIds: props.selected || [],
    };

    this.triggerOnItemsSelect = this.triggerOnItemsSelect.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidUpdate() {
    const { fetchNext } = this.props;
    if (fetchNext && this.still_loading) {
      this.still_loading = false;
      const scrollBottom = getScrollBottom(this.container);
      if (scrollBottom <= 1) {
        fetchNext();
      }
    }
    const currentItemIds = this.props.items.map(item => get(item, this.props.idSelector));

    if (difference(this.state.selectedItemIds, currentItemIds).length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedItemIds: this.state.selectedItemIds.filter(id => this.props.items.find(item => get(item, this.props.idSelector) === id)),
      });
    }
  }

  componentDidMount() {
    this.handleScroll();
  }

  getItemsToRender() {
    const { items, limit } = this.props;
    const { page } = this.state;

    return !this.isClientPagination() ? items : items.filter((_, i) => (page - 1) * limit <= i && i < page * limit);
  }

  getSelectedItems() {
    const { items, idSelector } = this.props;
    const { selectedItemIds } = this.state;
    return selectedItemIds.map(id =>
      items.find(item => get(item, idSelector) === id)
    );
  }

  isClientPagination() {
    const { withPagination, fetchPage, limit } = this.props;

    return withPagination && typeof fetchPage !== 'function' && limit > 0;
  }

  isAllSelected = () => difference(this.props.items.map(item => get(item, this.props.idSelector)), this.state.selectedItemIds).length === 0;

  isItemSelected = item => this.state.selectedItemIds.includes(get(item, this.props.idSelector));

  handleScroll() {
    const { fetchNext } = this.props;
    if (fetchNext === undefined) {
      return;
    }
    if (!this.raf_waiting) {
      requestAnimationFrame(() => {
        if (this.container) {
          const scrollBottom = getScrollBottom(this.container);
          if (scrollBottom <= 1) {
            fetchNext();
          }
        }
        this.raf_waiting = false;
      });
    }
    this.raf_waiting = true;
  }

  handlePageSelect = page => () => {
    const { fetchPage } = this.props;

    this.setState({
      page,
    }, this.triggerOnItemsSelect);
    if (!this.isClientPagination()) {
      fetchPage(page - 1);
    }
  };

  handleSelectAllChange = shouldSelectAll => {
    this.setState({
      selectedItemIds: shouldSelectAll ? this.props.items.map(item => get(item, this.props.idSelector)) : [],
    }, this.triggerOnItemsSelect);
  }

  handleItemSelect = item => shouldSelect => {
    const { idSelector } = this.props;
    const { selectedItemIds } = this.state;
    if (shouldSelect) {
      return this.setState({
        selectedItemIds: [get(item, idSelector), ...selectedItemIds],
      }, this.triggerOnItemsSelect);
    }

    this.setState({
      selectedItemIds: selectedItemIds.filter(selectedItemId => selectedItemId !== get(item, idSelector)),
    }, this.triggerOnItemsSelect);
  }

  triggerOnItemsSelect = () => {
    const { onItemsSelect } = this.props;

    if (typeof onItemsSelect === 'function') {
      onItemsSelect(this.getSelectedItems());
    }
  }

  renderPagination() {
    const { page } = this.state;
    const { limit, isFetching, items } = this.props;
    let { count } = this.props;

    if (this.isClientPagination()) {
      count = items.length;
    }

    const totalPages = Number.isFinite(limit) && limit > 0 ? Math.ceil(count / limit) : 1;

    if (totalPages === 1) {
      return null;
    }

    return (
      <ul className='pagination'>
        <li className={(isFetching || page === 1) ? 'disabled' : ''} onClick={page !== 1 && !isFetching && this.handlePageSelect(1)}>
          <span>First</span>
        </li>
        <li className={(isFetching || page === 1) ? 'disabled' : ''} onClick={page !== 1 && !isFetching && this.handlePageSelect(page - 1)}>
          <span>
            <span aria-hidden='true'>&laquo;</span>
          </span>
        </li>
        {page - 3 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 3)}>
            <span>...</span>
          </li>
        )}
        {page - 2 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 2)}>
            <span>{page - 2}</span>
          </li>
        )}
        {page - 1 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 1)}>
            <span>{page - 1}</span>
          </li>
        )}
        <li className={isFetching ? 'active disabled' : 'active'}>
          <span>
            {page} <span className='sr-only'>(current)</span>
          </span>
        </li>
        {page + 1 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 1)}>
            <span>{page + 1}</span>
          </li>
        )}
        {page + 2 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 2)}>
            <span>{page + 2}</span>
          </li>
        )}
        {page + 3 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 3)}>
            <span>...</span>
          </li>
        )}
        <li className={(page === totalPages || isFetching) ? 'disabled' : ''} onClick={page !== totalPages && !isFetching && this.handlePageSelect(page + 1)}>
          <span>
            <span aria-hidden='true'>&raquo;</span>
          </span>
        </li>
        <li className={(page === totalPages || isFetching) ? 'disabled' : ''} onClick={page !== totalPages && !isFetching && this.handlePageSelect(totalPages)}>
          <span>Last</span>
        </li>
      </ul>
    );
  }

  renderActions() {
    const { actions } = this.props;
    const { selectedItemIds } = this.state;
    return (
      <div style={{ alignSelf: 'flex-end', alignItems: 'center', display: 'flex', marginBottom: '1em' }}>
        <div className='text-default' style={{ marginRight: '1em', marginTop: '4px' }}>
          Selected <b>{selectedItemIds.length}</b> item(s)
        </div>
        {actions.map((action, i) => (
          React.cloneElement(action, {
            key: i,
            onClick: action.props.onClick ? () => action.props.onClick(this.getSelectedItems()) : null,
          })
        ))}
      </div>
    );
  }

  render() {
    const {
      actions,
      headers,
      onHeaderItemClick,
      items,
      onItemClick,
      ListItem,
      mapItemToCells,
      placeholder,
      isFetching = false,
      fetchNext,
      hideHeadersIfEmpty = false,
      limit,
      withPagination = false,
      striped = false,
      selectable = false,
      idSelector,
      onItemsSelect,
      ...rest
    } = this.props;
    const paginatedItems = this.getItemsToRender();
    const filter = withPagination && isFetching ? {
      filter: 'blur(1px)',
    } : {};
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {actions.length > 0 && this.renderActions()}
        <div
          className={cs('Table', { striped }, rest.className)}
          onScroll={fetchNext ? this.handleScroll : undefined}
          ref={ref => (this.container = ref)}
          {...rest}
          style={{ ...filter, ...rest.style }}
          >
          <table className='table'>
            {(!hideHeadersIfEmpty || Boolean(items.length)) && (
              <thead>
                <tr className='Table__header'>
                  {selectable && (
                    <th style={{ whiteSpace: 'nowrap' }}>
                      <CheckboxInput input={{ value: this.isAllSelected(), onChange: this.handleSelectAllChange }} />
                    </th>
                  )}
                  {headers.map((header, i) => {
                    let children;
                    let props = [];
                    if (header.component) {
                      children = header.component;
                    }
                    else if (typeof header === 'string') {
                      children = header;
                    }
                    else {
                      ({ children, ...props } = header);
                    }
                    // return (
                    //   <div>Hello</div>
                    // )
                    return (
                      <th 
                        key={i} 
                        onClick={onHeaderItemClick ? () => onHeaderItemClick(i) : undefined} 
                        // {...props}
                      >
                        {children}
                      </th>
                    );
                  })}
                </tr>
              </thead>
            )}
            {paginatedItems.filter(item => item !== undefined).map(
              (item, i) =>
                ListItem === undefined ? (
                  <tbody key={i}>
                    <tr>
                      {selectable && (
                        <td>
                          <CheckboxInput input={{ value: this.isItemSelected(item), onChange: this.handleItemSelect(item) }} />
                        </td>
                      )}
                      {mapItemToCells(item).map((cell, i) => <td key={i}>{cell}</td>)}
                    </tr>
                  </tbody>
                ) : (
                  <ListItem
                    key={i}
                    onClick={onItemClick ? () => onItemClick(item) : undefined}
                    {...item}
                    selected={this.isItemSelected(item)}
                    selectable={selectable}
                    onSelect={this.handleItemSelect(item)}
                  />
                )
            )}
            {isFetching &&
              (!withPagination || !items.length) && (
              <tbody className='nobg'>
                <tr>
                  <td colSpan={headers.length}>
                    <Spinner />
                  </td>
                </tr>
              </tbody>
            )}
            {isFetching &&
              withPagination && Boolean(items.length) && (
              <LoadingOverlay>
                <Spinner style={{ margin: 'initial' }} />
              </LoadingOverlay>
            )}
            {placeholder &&
              !paginatedItems.length &&
              !isFetching && (
              <tbody style={{ textAlign: 'center' }}>
                <tr>
                  <td colSpan={headers.length}>{placeholder}</td>
                </tr>
              </tbody>
            )}
          </table>
          <style jsx global>{`
            .Table {
              overflow: auto;
            }
            .Table > .table > .help-block {
              margin-top: 10%;
            }
            .Table .table {
              margin-bottom: auto;
            }
            .Table .nobg {
              background: transparent;
            }
            .Table thead {
              background: ${colors.DARK_GRAY};
              color: ${colors.WHITE};
            }

            .Table .table > tbody > tr.table-item-addon > td {
              background-color: transparent;
              padding: 0;
            }

            .Table tbody.open {
              background-color: ${colors.WHITE};
            }
          `}</style>
        </div>
        {(!hideHeadersIfEmpty || Boolean(items.length)) && withPagination && this.renderPagination()}
      </div>
    );
  }
}

Table.propTypes = {
  striped: PropTypes.bool,
  headers: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.shape({
        children: PropTypes.node.isRequired,
      }),
      PropTypes.shape({
        component: PropTypes.node.isRequired,
        tableOnly: PropTypes.bool,
      }),
    ])
  ).isRequired,
  items: PropTypes.array.isRequired,
  fetchNext: PropTypes.func,
  fetchPage: PropTypes.func,
  withPagination: PropTypes.bool,
  hideHeadersIfEmpty: PropTypes.bool,
  count: PropTypes.number,
  limit: PropTypes.number,
  onHeaderItemClick: PropTypes.func,
  onItemClick: PropTypes.func,
  placeholder: PropTypes.node,
  isFetching: PropTypes.bool,
  mapItemToCells: PropTypes.func,
  selectable: PropTypes.bool,
  idSelector: PropTypes.string,
  className: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
  selected: PropTypes.array
};

Table.defaultProps = {
  selectable: false,
  idSelector: 'id',
  actions: [],
};

export default Table;
