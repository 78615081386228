/**
 * Component for viewing a list of users given their user type and whether they're subscribed
 * @module components/container/MyUserHaulPayInterestedList
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/user
 * @requires components/pure/CenteredColumn
 * @requires components/pure/Header
 * @requires components/pure/RouteTabs
 * @requires components/pure/ResponsiveTable
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-v4';
import moment from 'moment';
import getSelfType from 'selectors/getSelfType';

import { USER_TYPE } from 'helpers';
import createTableItems from 'helpers/createTableItems';

import { clear, fetch, fetchNext, sort } from 'actions/search/user';

import Header from 'components/pure/Header';
import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import AdminDropdownInput, { noneSelect } from 'components/pure/form/inputs/AdminDropdownInput';


const MyUserHaulPayInterestedListTableComponents = createTableItems(
  ({ data }) => [
    <Link to={`/admin/user/search/${data.id}`}>{data.email}</Link>,
    <Link to={`/admin/user/search/${data.id}`}>{data.contact_email}</Link>,
    data.factoring ? data.factoring.factoring_approved : '',
    data.user_type,
    moment(data.created).format('l LT'),
    moment(data.last_login).format('l LT'),
    data.state,
    data.zip,
    <span style={{ maxWidth: 200, display: 'inline-block', wordWrap: 'break-word' }}>
      {data.source || 'Unknown'}
    </span>,
    <Link to={`/admin/user/search/${data.id}`}>
      <button className='btn btn-orange'>More</button>
    </Link>,
  ],
  i => i,
  {
    mobile: () => ({
      style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
    }),
  },
);

const MyUserHaulPayInterestedListTable = compose(
  connect(
    state => ({
      ids: state.search.user.ids,
      users: state.resource.usersearch,
      isFetching: state.search.user.isFetching,
      currentOrder: state.search.user.ordering,
    }),
    {
      sort,
      fetchNext,
    },
  ),
)(({ ids, users, isFetching, fetchNext, sort, currentOrder }) =>
  <ResponsiveTable
    isFetching={isFetching}
    fetchNext={fetchNext}
    sort={sort}
    currentOrder={currentOrder}
    sortable={true}
    containerProps={{
      className: 'col-fill',
    }}
    desktopContainerProps={{
      className: 'table-condensed',
    }}
    headers={[
      { text: 'Email', ordering: false },
      { text: 'Contact Email', ordering: false },
      { text: 'HP Status', ordering: 'hp_status' },
      { text: 'User Type', ordering: false },
      { text: 'Created At', ordering: false },
      { text: 'Last Login', ordering: false },
      { text: 'State', ordering: false },
      { text: 'Zip Code', ordering: false },
      { text: 'Source', ordering: false },
      { text: 'More', ordering: false },
    ]}
    items={ids.map(id => users[id])}
    placeholder='No results'
    TableItem={MyUserHaulPayInterestedListTableComponents.TableItem}
    BlockTableItem={MyUserHaulPayInterestedListTableComponents.BlockTableItem}
  />
);

const LoadMoreButton = compose(
  connect(
    state => state.search.user,
    {
      fetchNext,
    },
  ),
)(({ next, isFetching, fetchNext }) => next && !isFetching ? <button className='btn btn-orange' onClick={fetchNext}>Load More</button> : null);


export default compose(
  connect(
    state => ({
      self_user_type: getSelfType(state),
    }),
  ),
  reduxForm({
    form: 'MyMyUserHaulPayInterestedList',
    destroyOnUnmount: false,
    initialValues: {
      selected_admin: noneSelect,
      user_type: 'broker',
    },
    async onSubmit(fields, dispatch, { contact_status }) {
      dispatch(clear());
      await dispatch(fetch({
        contact_status,
        assigned_admin: fields.selected_admin && fields.selected_admin.id ? fields.selected_admin.id : undefined,
        haulpay_interest: true,
        user_type: fields.user_type,
      }));
    },
  }),
)(class MyUserHaulPayInterestedList extends React.Component {

  componentWillMount() {
    this.props.submit();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.contact_status !== this.props.contact_status) {
      this.props.submit();
    }
  }

  render() {
    const { handleSubmit, self_user_type } = this.props;

    return (
      <div style={{ padding: '0 1em' }}>
        <RouteTabs
          routes={[
            ['../no_contact', 'No Contact'],
            ['../first', 'First Contact'],
            ['../second', 'Second Contact'],
            ['../third', 'Third Contact'],
          ]}
          extras={
            self_user_type === USER_TYPE.ADMIN ?
              <form>
                <div className='pull-right'>
                  <Field
                    name='selected_admin'
                    component={AdminDropdownInput}
                    label={null}
                    submitOnChange={handleSubmit}
                  />
                </div>
                <div className='pull-right' style={{ marginRight: '1em' }}>
                  <Field
                    name='user_type'
                    component={LabeledDropdown}
                    data={[
                      { text: 'Broker', value: 'broker' },
                      { text: 'Carrier', value: 'carrier' },
                    ]}
                    submitOnChange={handleSubmit}
                  />
                </div>
              </form>
              : null
          }
        />
        <Header>My Haul Pay Interested Users</Header>
        <MyUserHaulPayInterestedListTable />
        <LoadMoreButton />
      </div>
    );
  }
});
