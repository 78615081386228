/**
 * Reducer for handling the bid resource actions
 * @module reducers/resource/bid
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/bid
 * @requires actions/resourceBy/load/bid
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.bid;

import bidResource from 'actions/resource/bid';
import bidByLoadResource from 'actions/resourceBy/load/bid';


export default function bidResourceReducer(state = initialState, action) {
  state = bidResource.reduce(state, action);
  if (bidByLoadResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...bidResource.parseMany(state, action.payload.results),
    };
  }
  if (bidByLoadResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: bidResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
