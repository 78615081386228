import React from 'react';
import Header from 'components/pure/Header';

const FAQ = ({ children }) => <div style={{ paddingBottom: '1em' }}>{children}</div>;

FAQ.Q = ({ children }) =>
    <div>
        <span className='h4'>Q. </span>
        <span><em>{children}</em></span>
    </div>
    ;

FAQ.A = ({ children }) =>
    <div>
        <span className='h4'>A. </span>
        <span>{children}</span>
    </div>
    ;

const EmailLink = ({ email }) => <a href={`mailto:${email}`}>{` ${email} `}</a>
const Link = ({ link, children }) => <a href={link} target="_blank">{children}</a>

const generalQuestionQA = [
    {
        q: 'How do I sign up for HaulPay?',
        a:
            <span>
                Your broker will be sending you a link via text message or email. This link will allow you to input your banking information for payment. If you are having trouble,
            please contact your broker or HaulPay at <EmailLink email="support@haulpay.io" />.
    </span>
    },
    {
        q: 'When will I receive my funds?',
        a: <span>
            Your broker will present you with different quick pay options for you to choose from. 
            You’ll also receive email updates for your payment status. If you don’t receive the email updates ask your broker or email support to check your payment profile email to make sure it’s correct. You can also check payment status by downloading and logging into the HaulPay app. If you do not know your login information, please contact your broker. 
            If you have not yet received your funds, please contact your broker.
        </span>
    },
    {
        q: 'I’m having trouble using the link to create a profile for payment. What do I do?',
        a: <span>
            If you are having trouble creating a HaulPay payment profile,
            then please contact your broker or you can also give us a call.
            If possible, take a screenshot of any error message you are receiving and email it to <EmailLink email="support@haulpay.io" />. Please include a description of the error and what device you are using i.e. laptop, android cell, iphone cell, etc. Once we have this information,
            we can properly assess a solution to your issue.
        </span>
    },
    {
        q: 'I am set up with my factoring company but I want to get a direct deposit. How do I start that process?',
        a: 'Please reach out to your factoring company for a letter of release. Once we have that we can change your payment preference to ACH and to a preferred payment speed option.'
    },
    {
        q: 'I want to change my quick pay preference.',
        a: <span>
            You can request that your broker change this or you can login <Link link="https://app.haulpay.co/login">here</Link> to make an update.
        </span>
    },
    {
        q: 'I have not received the invite. What do I do?',
        a: 'Call your broker so that they can resend the invite or share a link to complete boarding. Please make sure to check your “JUNK” or “SPAM” folder, in case it made its way there. If you still have not received it, please let your broker know.'
    },
    {
        q: 'I don’t want to use HaulPay. Can I still get paid?',
        a: 'We are the payment service for your broker and we provide payment services for all carrier payments. If you do not want to use HaulPay, please contact your broker. It is their discretion whether or not they want to pay directly through another means.'
    },
    {
        q: 'I want to receive a paper check instead of an ACH. How do I request that?',
        a: 'Unfortunately we do not offer paper checks at this time. Please contact your broker. It is at their discretion whether or not they want to pay you with a check.'
    },
    {
        q: 'How do I check for payment status?',
        a: 'The HaulPay app will allow you to check for your invoice’s payment status, accept assigned loads from your broker, as well as upload paperwork for payment. Please login to your account using the email address you used to sign up with HaulPay. If you are not sure what email address we have on file, please contact your broker.'
    },
    {
        q: 'How do I submit documents for payment?',
        a: <span>
            Please use the HaulPay app to upload documents for payment. You can also email the documents to your broker.
           This <Link link="https://www.youtube.com/watch?v=SUnpW6F5Ll8">video</Link> is helpful in learning how to accept a load via the HaulPay from your broker and how to mark it “Complete”.
        </span>
    },
    {
        q: 'I still have not received my payment. What do I do?',
        a: 'Please contact your broker for immediate assistance.'
    },
    {
        q: 'My factoring company declined/rejected working with this broker, how can I get paid?',
        a: 'If your factoring company has declined your broker due to credit, you can choose the broker’s quickpay options with us. We will send you an ACH directly to your account depending on the payment speed you select.'
    },
]

const InvitedCarrierFAQ = () =>
    <div>
        <Header>Common Help Questions</Header>
        {
            generalQuestionQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
    </div>


export default InvitedCarrierFAQ;