/**
 * Reducer for editing hiding and unhiding posts and users, and for fetching previously hidden users
 * @module reducers/hide
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/hide
 * @listens HIDE_POST
 * @listens UNHIDE_POST
 * @listens HIDDEN_USERS_REQUEST
 * @listens HIDDEN_USERS_REQUEST_SUCCESS
 * @listens HIDDEN_USERS_REQUEST_FAILURE
 * @listens UNHIDE_USER
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.hide;

import {
  HIDDEN_USERS_REQUEST,
  HIDDEN_USERS_REQUEST_FAILURE,
  HIDDEN_USERS_REQUEST_SUCCESS,
  HIDE_POST,
  UNHIDE_POST,
  UNHIDE_USER,
} from '../actions/hide';


export default function hideReducer(state = initialState, action) {
  switch (action.type) {
    case HIDE_POST:
      return {
        ...state,
        posts: [...state.posts, action.payload],
      };
    case UNHIDE_POST: {
      const index = state.posts.indexOf(action.payload);
      return {
        ...state,
        posts: [
          ...state.posts.slice(0, index),
          ...state.posts.slice(index + 1),
        ],
      };
    }
    case HIDDEN_USERS_REQUEST:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: true,
        },
      };
    case HIDDEN_USERS_REQUEST_SUCCESS:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: false,
          err: undefined,
          data: action.payload,
        },
      };
    case HIDDEN_USERS_REQUEST_FAILURE:
      return {
        ...state,
        users: {
          ...state.users,
          isFetching: false,
          err: action.payload,
          data: [],
        },
      };
    case UNHIDE_USER: {
      const index = state.users.data.findIndex(user => user.id === action.payload);
      return {
        ...state,
        users: {
          ...state.users,
          data: [
            ...state.users.data.slice(0, index),
            ...state.users.data.slice(index + 1),
          ],
        },
      };
    }
  }
  return state;
}
