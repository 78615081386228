import getMuiTheme from 'material-ui/styles/getMuiTheme';

const muiTheme = getMuiTheme({
  fontFamily: 'Lato, sans-serif',
  palette: {
    primary1Color: '#E86C00',
    accent1Color: '#0275d8',
    accent2Color: '#2196f3',
    secondary1Color: '#2196f3',
  },
});

export default muiTheme;
