/**
 * Settings resource for the `/usersettings` endpoint. Named "Activity" because at one point the form that edited properties on this object was labeled "Activity Settings Form"
 * @module actions/settings/activity
 * @since 3.0.0
 * @requires datatypes/SettingsResource
 */
import SettingsResource from '../../datatypes/SettingsResource';

/**
 * Our `/usersettings` Settings Resource
 */
const activitySettingsResource = new SettingsResource('activity', {
  url: () => 'usersettings',
  mountPoint: 'settings.activity',
});

export default activitySettingsResource;
export const {
  fetch,
  edit,
} = activitySettingsResource;
