import isEmpty from 'lodash/isEmpty';

const formatCategoriesAttachments = attachments => attachments.map(element => {
  const file = new File([element], element.name);
  if (isEmpty(element.category)) {
    return;
  }
  file.category = element.category.join();
  return file;
});
export default formatCategoriesAttachments;
