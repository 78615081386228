import React from 'react';
import styled from 'styled-components';
import MaterialIcon from 'components/pure/MaterialIcon';
import Tooltip from './Tooltip';
import AttachmentIcon from 'components/pure/AttachmentIcon';

const FactoringPaymentAttachment = ({ attachment, lastDocIssueDate, onCropClick, deleteAttachment }) => {
  const filename = (
    <span>
      { (lastDocIssueDate && lastDocIssueDate < new Date(attachment.uploaded)) &&
      <span style={{ color: '#0FAF44' }}>NEW !!</span>
      }
      <span
        title={attachment.filename}
        style={{
          display: 'inline-block',
          color: 'black',
          maxWidth: '13em',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          verticalAlign: 'middle',
        }}
        >
        {attachment.filename}
      </span>
    </span>
  );

  const doDelete = (
    deleteAttachment ?
      <button
        className='btn btn-danger'
        type='button'
        onClick={() => {
          // because typescript can't see the above check
          if (typeof deleteAttachment === 'function') {
            deleteAttachment(attachment.id);
          }
        }}
        >
        X
      </button>
      : null
  );

  if (attachment.failed) {
    return (
      <div className='FactoringPaymentAttachment'>
        <MaterialIcon name='priority_high' style={{ color: 'red', margin: '0.5em' }} />
        {filename}
        {' — Failed to upload '}
        {doDelete}
      </div>
    );
  }

  if (!attachment.uploaded) {
    return (
      <div className='FactoringPaymentAttachment'>
        <div
          style={{
            maxHeight: '1em',
            padding: '20px 0px',
          }}
        />
        {filename}
        <Tooltip text='Uploads may take up to 15 minutes to process.'>
          <span>
            {' — Processing '}
            <MaterialIcon name='sync' spin={true} />
          </span>
        </Tooltip>
      </div>
    );
  }

  return (
    <div className='FactoringPaymentAttachment'>
      {
        attachment.category ?
          `[${attachment.category}] `
          :
          undefined
      }
      {filename}
      {' '}
      {onCropClick &&
      <button
        className='btn btn-primary'
        type='button'
        onClick={() => onCropClick(attachment)}
        >
        Edit
      </button>
      }
      {' '}
      <a href={attachment.preview_url} target='_blank' rel='noopener noreferrer'>
        <button className='btn btn-default' type='button'>Open</button>
      </a>
      {' '}
      <a href={attachment.download_url} target='_blank' rel='noopener noreferrer'>
        <button className='btn btn-default' type='button'>
          <MaterialIcon name='file_download' size={18} />
        </button>
      </a>
      {' '}
      {doDelete}
    </div>
  );
};

const StyledFactoringPaymentAttachment = styled(FactoringPaymentAttachment)`
  .FactoringPaymentAttachment {
    margin-bottom: 5px;
  
    input[type='file'] {
       -webkit-appearance: none;
       text-align: left;
       -webkit-rtl-ordering: left;
       margin-left: auto;
    }
    input[type='file']::-webkit-file-upload-button {
       -webkit-appearance: none;
       float: right;
       margin: 0 0 0 10px;
       border: 1px solid #aaaaaa;
       border-radius: 4px;
       background-image: -webkit-gradient(linear, left bottom, left top, from(#d2d0d0), to(#f0f0f0));
       background-image: -moz-linear-gradient(90deg, #d2d0d0 0%, #f0f0f0 100%);
    }
  
    .form-group button {
      margin-top: 0.5em;
    }
  }
`;

export default StyledFactoringPaymentAttachment;
