/* global API */

const fetchCommodities = () => async (
  dispatch,
  getState
) => {
  const res = await global.fetch(`${API.host}/funding_requests/commodities/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  const keys = Object.keys(json);
  const values = Object.values(json);

  const commodities = [];

  for (let i = 0; i < keys.length; i++) {
    commodities.push({
      ...values[i],
      id: keys[i],
    });
  }
  return commodities;
};

export default fetchCommodities;
