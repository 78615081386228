import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';
import get from 'lodash/get';
import getSelfType from 'selectors/getSelfType';

import factoringclientPaginatedResource from 'actions/admin/factoring/client';

import createTableItems from 'helpers/createTableItems';

import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';

const FactoringClientComponents = createTableItems(
  ({ data }) => {
    let status_set_datetime = get(data.status_timeline, [data.status_timeline.length - 1, 'status_set_datetime']);
    if (status_set_datetime) {
      status_set_datetime = moment(status_set_datetime).format('l LTS');
    }
    else {
      status_set_datetime = 'Never';
    }
    const difference = (data.this_period_fr - data.last_period_fr);
    let color = 'text-warning';
    let materialClass = 'menu';
    if (difference > 0){
      color = 'text-success';
      materialClass = 'arrow_upward';
    }
    else if (difference < 0) {
      color = 'text-danger';
      materialClass = 'arrow_downward';
    }
    return [
      data.company_profile.name,
      data.user.assigned_admin_name,
      `${data.owner_profile.name_first} ${data.owner_profile.name_last}`,
      data.owner_profile.phone_number_cell,
      data.owner_profile.email,
      data.user.user_type,
      moment(data.time_posted).format('l LTS'),
      data.company_profile.address.state,
      data.company_profile.address.zip,
      <div className={`${color}`}><strong>{data.this_period_fr} ({difference}) <MaterialIcon name={materialClass} className={color} /></strong></div>,
      status_set_datetime,
      <Link to={`/admin/factoring/client/${data.id}`}><button className='btn btn-orange'>More</button></Link>,
    ];
  },
);

export const FactoringClientTable = compose(
  connect(
    state => ({
      ids: state.admin.factoring.client.ids,
      isFetching: state.admin.factoring.client.isFetching,
      user_type: getSelfType(state),
      apps: state.resource.factoringclient,
      currentOrder: state.admin.factoring.client.ordering,
    }),
    {
      fetchNext: factoringclientPaginatedResource.fetchNext,
      sort: factoringclientPaginatedResource.sort,
    },
  ),
)(({ ids, isFetching, fetchNext, apps, sort, currentOrder }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    sort={sort}
    currentOrder={currentOrder}
    headers={[
      { text: 'DBA', ordering: false },
      { text: 'Assigned Admin', ordering: 'assigned_admin_name' },
      { text: 'Owner Name', ordering: false },
      { text: 'Owner Phone#', ordering: false },
      { text: 'Contact Email', ordering: false },
      { text: 'User Type', ordering: false },
      { text: 'Created At', ordering: 'time_posted' },
      { text: 'State', ordering: false },
      { text: 'ZIP', ordering: false },
      { text: 'Stats', ordering: 'stats' },
      { text: 'Status Time', ordering: 'approved_datetime' },
      { text: 'More', ordering: false },
    ]}
    placeholder='No results'
    TableItem={FactoringClientComponents.TableItem}
    BlockTableItem={FactoringClientComponents.BlockTableItem}
    items={ids.map(id => apps[id])}
  />
);
class FactoringClientDashboard extends React.Component {
  componentDidMount() {
    this.props.update();
  }
  componentDidUpdate() {
    this.props.update();
  }
  render(){
    return (
      <div className='col-fill' style={{ padding: '0 1em' }}>
        <RouteTabs
          style={{ flexGrow: 0 }}
          routes={[
            ['/admin/factoring/broker-invited/pending', 'Pending Clients'],
            ['/admin/factoring/broker-invited/approved', 'Approved Clients'],
            ['/admin/factoring/broker-invited/declined', 'Declined Clients'],
          ]}
        />
        <FactoringClientTable />
      </div>
    );
  }
}
const FactoringBrokerInvitedLists = compose(
  connect(
    state => ({
      user_type: getSelfType(state),
    }),
    dispatch => ({
      update(data) {
        dispatch(factoringclientPaginatedResource.clear());
        return dispatch(factoringclientPaginatedResource.fetch({
          broker_approved: location.pathname.slice(location.pathname.lastIndexOf('/') + 1),
          ...data }));
      },
    }),
  )
)(FactoringClientDashboard);
export default FactoringBrokerInvitedLists;
