import _ from 'lodash';

function capitalize(s) {
  return s && s[0].toUpperCase() + s.slice(1);
}

function getMessage(data, keyName = '') {
  let message = [];
  message = _.reduce(
    data,
    (obj, param, key) => {
      if (typeof param === 'object') {
        const result = getMessage(param, key);
        message = message.concat(result);
        return message;
      }
      if (typeof param === 'string') {
        obj.push(
          param === 'This field is required.' && keyName
            ? `${capitalize(keyName)}: ${param}`
            : param
        );
      }
      return obj;
    },
    []
  );
  return message;
}

const errorHandler = (err, retention = 'Error') => {
  let message = [];
  try {
    // sometimes err message is a stringified object
    message.push(JSON.parse(err.message));
  }
  catch (errception) {
    // pass
  }

  if (typeof err === 'string') {
    return message.push(err);
  }
  try {
    if (typeof err === 'object') {
      message = message.concat(getMessage(err));
    }
    else {
      message.push('Error Server.');
    }
    return message;
  }
  catch (errception) {
    return message.push(retention);
  }
};
export default errorHandler;
