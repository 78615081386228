/**
 * Settings resource for the `/user/ainfo` endpoint
 * @module actions/settings/accountsreceivable
 * @since 3.0.0
 * @requires datatypes/SettingsResource
 */
import SettingsResource from '../../datatypes/SettingsResource';
import accountspayableSettingsResource from './accountspayable';


/**
 * Our `/user/ainfo` Settings Resource
 */
const accountsreceivableSettingsResource = new SettingsResource('accountsreceivable', {
  url: () => 'user/ainfo',
  mountPoint: 'settings.accountsreceivable',
  hooks: {
    preDispatchCreateSuccess: data => data,
  },
});

export default accountsreceivableSettingsResource;
export const {
  fetch,
  edit,
} = accountsreceivableSettingsResource;


/**
 * Copy accounts payable object into accounts receivable
 * @event COPY_AP
 * @property {symbol} type - Symbol(COPY_AP)
 * @property {object} payload - the copied accountspayable data
 */
export const COPY_AP = Symbol('ACCOUNTSRECEIVABLE_SETTINGS_COPY_AP');
/**
 * Copy accounts payable object into accounts receivable
 * @fires COPY_AP
 */
export function copyAP() {
  return (dispatch, getState) => {
    const { accountspayable } = getState().settings;
    let promise = Promise.resolve();
    if (!accountspayable.data) {
      promise = promise.then(() => accountspayableSettingsResource.fetch());
    }
    return promise
      .then(() => {
        const { accountspayable } = getState().settings;
        return dispatch({
          type: COPY_AP,
          payload: {
            ...accountspayable.data,
            id: undefined,
          },
        });
      })
      ; // eslint-disable-line indent
  };
}
