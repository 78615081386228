/**
 * Component to display a table for the Load Search page
 * @module components/pure/LoadSearchTable
 * @since 3.0.0
 * @requires components/container/LoadSearchTableItem
 * @requires components/pure/SortableTable
 * @property {object} props
 * @property {string[]} props.ids
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';

import SortableTable from 'components/pure/SortableTable';
import LoadSearchTableItem from 'components/container/LoadSearchTableItem';


const LoadSearchTable = ({ ids, ...rest }) =>
  <SortableTable
    headers={[
      { text: 'Age', ordering: 'time_posted' },
      { text: 'D.H.', ordering: 'deadhead' },
      { text: 'Type', ordering: 'trailerType' },
      { text: 'Origin', ordering: 'origin_city' },
      { text: 'Destination', ordering: 'destination_city' },
      { text: 'Weight', ordering: 'weight' },
      { text: 'Length', ordering: 'length' },
      { text: 'Ready', ordering: 'time_start' },
      { text: 'Close', ordering: 'time_end' },
      { text: 'Deliver', ordering: 'time_delivery' },
      { text: 'More', ordering: false },
    ]}
    items={ids.map(id => ({ id }))}
    ListItem={LoadSearchTableItem}
    {...rest}
  />
  ;

LoadSearchTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default LoadSearchTable;
