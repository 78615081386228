import React from 'react';
import { connect } from 'react-redux-v5';
import { closeModal, openModal } from 'actions/ui';
import { fetchCollated } from 'actions/resource/factoringpayment';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import Header from 'components/pure/Header';
import UploadDocs from '../form/UploadDocs';
import { getFiles } from '../../../actions/upload';

const AddDocsNonFactored = ({
  headerStyle,
  closeModal,
  requestId,
  files = [],
  action,
  fetchCollated,
  openModal,
}) =>
  <div>
    <ModalBody closeModal={closeModal} >
      <Header style={headerStyle}>Create Non-Factored Request</Header>
      <UploadDocs requestId={requestId} />
      <div style={{ display: 'flex', alignItems: 'center', fontSize: 'smaller', paddingTop: 20 }}>
        <div>
          <label className='control-label' style={{ marginRight: '0.5em' }}>
            Collated Billing PDF
          </label>
          <LoadAttachmentButton
            getURL={() => {
              try {
                return fetchCollated(requestId);
              }
              catch (err) {
                console.warn(err);
                openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' });
              }
            }}
          />
        </div>
      </div>
      {files.length ?
        <div style={{ display: 'flex', alignItems: 'flex-end', fontSize: 'smaller', paddingTop: 30 }}>
          You can add, edit and delete documents from the non-Factored tab
        </div>
        :
        <div className='text-danger' style={{ display: 'flex', alignItems: 'flex-end', fontSize: 'smaller', paddingTop: 30 }}>
        Please attach at least 1 document to complete creating this Non-Factored transaction.
        </div>
      }
    </ModalBody>
    <ModalFooter >
      <button
        className='btn btn-orange'
        onClick={() => {
          closeModal();
          action();
        }}
        disabled={!files.length}
        >
        Finish
      </button>
    </ModalFooter>
  </div>;

export default connect(state => ({ files: getFiles(state) }), { closeModal, fetchCollated, openModal })(AddDocsNonFactored);
