/**
 * Component that displays the load search form
 * @module components/pure/form/LoadSearchForm
 * @since 3.0.0
 * @requires helpers
 * @requires datatypes/SavedUserSettings
 * @requires actions/ui
 * @requires actions/search/load
 * @requires components/pure/Header
 * @requires components/pure/form/inputs/LoadTypeInput
 * @requires components/pure/form/inputs/ButtonToggleInput
 * @requires components/pure/form/inputs/GoogleLocationInput
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/StateInput
 * @requires components/pure/form/inputs/TrailerInput
 * @requires components/pure/form/inputs/WeightInput
 * @requires components/pure/form/inputs/LengthInput
 * @requires components/pure/form/inputs/DateTimeInput
 * @requires components/container/Protip
 * @requires components/container/ReduxFormFieldErrors
 */
/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';
import validate from 'validate.js';
import get from 'lodash/get';

import { USER_TYPE } from 'helpers';
import states from 'helpers/states';

import SavedUserSettings from 'datatypes/SavedUserSettings';
import { openModal } from 'actions/ui';
import * as loadSearchActions from 'actions/search/load';

import Header from 'components/pure/Header';
import LoadTypeInput from 'components/pure/form/inputs/LoadTypeInput';
import ButtonToggleInput from 'components/pure/form/inputs/ButtonToggleInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import WeightInput from 'components/pure/form/inputs/WeightInput';
import LengthInput from 'components/pure/form/inputs/LengthInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import SetValueInput from 'components/pure/form/inputs/SetValueInput';
import RadiusInput from 'components/pure/form/inputs/RadiusInput';
import ProTip from 'components/container/ProTip';
import ReduxFormFieldErrors from 'components/container/ReduxFormFieldErrors';
import ButtonCurrentLocation from 'components/pure/ButtonCurrentLocation';
import getSelfType from 'selectors/getSelfType';
import getSelf from 'selectors/getSelf';
import segmentEvents from '../../../helpers/segmentEvents';

export const FORM_NAME = 'LoadSearch';

/**
 * Instance of SavedUserSettings for load search, for saved search paramters
 */
const savedInitialValues = SavedUserSettings('SavedLoadSearch', {
  defaults: {
    searchBy: 'city',
    origin_location: {},
    origin_region: [],
    origin_radius: 200,
    destination_location: {},
    destination_region: [],
    destination_radius: 200,
    trailerTypes: [],
    time_start: moment(),
    time_end: moment().add(5, 'days'),
  },
  hooks: {
    postRetrieve(data) {
      data.time_start = moment(data.time_start);
      data.time_end = moment(data.time_end);
      if (data.time_start.isBefore(moment(), 'day')) {
        data.time_start = moment();
        data.time_end = moment().add(5, 'days');
      }
      return data;
    },
  },
});

/**
 * Automatically push the start time and end time forward when it happens
 */
const rolloverTimes = () => {
  savedInitialValues.set({
    time_start: moment(),
    time_end: moment().add(5, 'days'),
  });
  setTimeout(rolloverTimes, moment().endOf('day').diff(moment()));
};
rolloverTimes();


export default compose(
  connect(
    (state, { form = FORM_NAME }) => {
      const savedValues = savedInitialValues.get(state.user.id);
      const selector = formValueSelector(form);
      return {
        user: getSelf(state),
        loadType: selector(state, 'loadType'),
        searchBy: selector(state, 'searchBy'),
        search_by_date_range: selector(state, 'search_by_date_range'),
        initialValues: {
          ...savedValues,
          loadType: savedValues.hasOwnProperty('loadType') ? savedValues.loadType : [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(getSelfType(state)) ? 'shipper' : '',
        },
        async onSubmit(fields, dispatch) {
          const is_subscribed = (
            get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_subscribed']) ||
            get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_trialing']) ||
            USER_TYPE.IS_ADMIN(getSelfType(state)) ||
            USER_TYPE.IS_ALADDIN_ADMIN(getSelfType(state))
          );
          if (typeof fields.loadType !== 'string') {
            fields.loadType = fields.loadType.value;
          }
          const data = {
            ...(() =>
              fields.searchBy === 'city' && fields.loadType !== USER_TYPE.SHIPPER ?
                {
                  search_origin_city: fields.origin_location.city,
                  search_origin_state: fields.origin_location.state,
                  origin_radius: fields.origin_radius,
                  search_destination_city: fields.destination_location.city,
                  search_destination_state: fields.destination_location.state,
                  destination_radius: fields.destination_radius,
                } : {
                  search_origin_state: fields.origin_region.map(state => state.code).join(','),
                  ...(() => fields.loadType === USER_TYPE.SHIPPER ? undefined : ({
                    search_destination_state: fields.destination_region.map(state => state.code).join(','),
                  }))(),
                }
            )(),

            trailerType__in: fields.trailerTypes.map(tt => tt.value).join(','),
            weight__lte: fields.loadType === USER_TYPE.SHIPPER ? undefined : fields.weight,
            ...(() => {
              if (fields.loadType === USER_TYPE.SHIPPER) {
                return;
              }
              switch (fields.length) {
                case 'full':
                  return { length__gte: 40 };
                case 'partial':
                  return { length__lte: 40 };
              }
            })(),

            ...(() =>
              fields.loadType === USER_TYPE.SHIPPER ||
                fields.search_by_date_range ?
                // only account for time start and time end if searching by shipper, or if searching by date range
                {
                  time_start__lte: fields.time_end.endOf('day').format(),
                  time_end__gte: fields.time_start.startOf('day').format(),
                } : {
                  time_start__lte: fields.time_start.endOf('day').format(),
                  time_end__gte: fields.time_start.startOf('day').format(),
                }
            )(),

            user_type: fields.loadType,
          };
          savedInitialValues.set(fields);
          if (!is_subscribed) {
            dispatch(openModal('upgradeaccount', {
              headerChildren: 'Full Access Needed',
              subscribeButtonText: 'Upgrade',
              message: 'Your load search access is limited due to your full access trial period having ended. You can still post trucks but you will not be able to contact matches. You will need to upgrade to full access to use the full features of search in the web app or contact support.',
            }));
            return;
          }
          dispatch(loadSearchActions.clear());
          try {
            await dispatch(loadSearchActions.fetch(data))
            window.analytics.track(segmentEvents.USER_SEARCHED_LOAD);
          } catch (err) {
            dispatch(openModal('error', err));
          }
        },
      };
    },
    null,
    null,
    { withRef: true },
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    validate: fields => validate(fields, {
      origin_location: {
        multipresenceifdefined: ['city', 'state'],
      },
      destination_location: {
        multipresenceifdefined: ['city', 'state'],
      },
      weight: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 1000,
          notGreaterThanOrEqualTo: 'Weight must be greater than or equal to 1000 (lbs) pounds.',
        },
      },
      time_start: {
        presence: true,
      },
      time_end: {
        presence: true,
        creldatetime: {
          earliest: 'time_start',
        },
      },
      origin_radius: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 500,
        },
      },
      destination_radius: {
        numericality: {
          onlyInteger: true,
          greaterThanOrEqualTo: 5,
          lessThanOrEqualTo: 500,
        },
      },
    }),
  }),
)(({ loadType, searchBy, search_by_date_range, change, form, user }) =>
  <div className='light-placeholder'>
    <div className='clearfix' style={{ marginTop: '0.5em' }}>
      <Header style={{ display: 'inline-block', marginTop: 0 }}>Load Search</Header>
      <ProTip
        className='pull-right'
        name='Load Search'
        message={
          <div>
            <p>The default radius for city to city search is 200 miles. Enter a larger radius on either Origin or Destination that you prefer to check loads on lanes against to pull in more matches.</p>
            <p>Be sure to set the end date on the search out far enough to match up to the window that you're looking for loads. The default is only set for one day ahead.</p>
            <p>Check for Return Loads by scrolling to the bottom of any load details page you view to see loads coming up in the future that are returning back on the same lane as well.</p>
            <p>You can also block users who you don't want to see in your results by clicking the block user button in the line items or on the load details pages.</p>
          </div>
        }
        youtubeEmbed='fe2KowqyEII'
      />
    </div>
    {/*<Field name='loadType' component={LoadTypeInput} />*/}
    <div>
      {
        loadType !== USER_TYPE.SHIPPER ?
          <Field name='searchBy' component={ButtonToggleInput} data={[{ text: 'By City', value: 'city' }, { text: 'By State', value: 'state' }]} />
          : null
      }
      {
        searchBy === 'city' && loadType !== USER_TYPE.SHIPPER ?
          <div>
            <div className='inline-form-control-block'>
              <div className='row-2col spacer-xs clearfix external-error-message'>
                <div className='col-xs-10' style={{ maxWidth: 'calc(100% - 5em)', minWidth: 'calc(100% - 8em)' }}>
                  <Field
                    name='origin_location'
                    component={GoogleLocationInput}
                    specificity={GoogleLocationInput.specificity.ANY}
                    label={
                      <span>
                        {'Origin\u00a0\u00a0'}
                        <ButtonCurrentLocation
                          component='a'
                          role='button'
                          onClick={place => {
                            change('origin_location', {
                              city: place.city,
                              state: place.state,
                              formatted_address: `${place.city}, ${place.state}`,
                            });
                          }}
                        >
                          Current Location
                        </ButtonCurrentLocation>
                      </span>
                    }
                    style={{ marginBottom: 0 }}
                  />
                </div>
                <div className='col-xs-2' style={{ maxWidth: '8em', minWidth: '5em' }}>
                  <Field name='origin_radius' component={RadiusInput} />
                </div>
              </div>
              <ReduxFormFieldErrors form={form} fields={['origin_location', 'origin_radius']} />
            </div>
            <div className='inline-form-control-block'>
              <div className='row-2col spacer-xs clearfix external-error-message'>
                <div className='col-xs-10' style={{ maxWidth: 'calc(100% - 5em)', minWidth: 'calc(100% - 8em)' }}>
                  <Field
                    name='destination_location'
                    component={GoogleLocationInput}
                    specificity={GoogleLocationInput.specificity.ANY}
                    label='Destination'
                    style={{ marginBottom: 0 }}
                  />
                </div>
                <div className='col-xs-2' style={{ maxWidth: '8em', minWidth: '5em' }}>
                  <Field name='destination_radius' component={RadiusInput} />
                </div>
              </div>
              <ReduxFormFieldErrors form={form} fields={['destination_location', 'destination_radius']} />
            </div>
          </div>
          :
          <div className='inline-form-control-block'>
            <Field
              name='origin_region'
              component={MultiStateInput}
              label={
                <span>
                  {'Origin\u00a0\u00a0'}
                  <ButtonCurrentLocation
                    component='a'
                    role='button'
                    onClick={place => {
                      change('origin_region', [{
                        fullName: states[place.state],
                        code: place.state,
                      }]);
                    }}
                  >
                    Current State
                  </ButtonCurrentLocation>
                </span>
              }
            />
            {
              loadType === USER_TYPE.SHIPPER ? null :
                <Field name='destination_region' component={MultiStateInput} label='Destination' style={{ marginBottom: 0 }} />
            }
          </div>
      }
      <Field name='trailerTypes' component={MultiTrailerInput} label='Equipment Type' />
      {
        loadType !== USER_TYPE.SHIPPER ?
          <div className='row-2col spacer-xs clearfix'>
            <div className='col-xs-6'>
              <Field name='weight' component={WeightInput} label='Load Weight (Max)' placeholder='Any' />
            </div>
            <div className='col-xs-6'>
              <Field name='length' component={LengthInput} label='Load Length' />
            </div>
          </div>
          : null
      }
      {
        loadType === USER_TYPE.SHIPPER ?
          <div>
            <Field
              name='time_start'
              component={DateTimeInput}
              label='Start'
              pickerOptions={{ time: false }}
            />
            <Field
              name='time_end'
              component={DateTimeInput}
              label='End'
              pickerOptions={{ time: false }}
            />
          </div>
          :
          search_by_date_range ?
            <div>
              <Field
                name='time_start'
                component={DateTimeInput}
                label={
                  <div>
                    Start
                    <Field name='search_by_date_range' component={SetValueInput} value_to_set={false}>
                      <a role='button' className='pull-right'>By Pick Up Date</a>
                    </Field>
                  </div>
                }
                labelProps={{
                  style: {
                    display: 'block',
                  },
                }}
                pickerOptions={{ time: false }}
              />
              <Field
                name='time_end'
                component={DateTimeInput}
                label='End'
                pickerOptions={{ time: false }}
              />
            </div>
            :
            <Field
              name='time_start'
              component={DateTimeInput}
              label={
                <div>
                  Pick Up Date
                  <Field name='search_by_date_range' component={SetValueInput} value_to_set={true}>
                    <a role='button' className='pull-right'>By Date Range</a>
                  </Field>
                </div>
              }
              labelProps={{
                style: {
                  display: 'block',
                },
              }}
              pickerOptions={{ time: false }}
            />
      }
    </div>
  </div>
);
