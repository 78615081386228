/**
 * Component that displays Timeline Notification status. Used in {@link components/container/TimelineNotificationIcon}
 * @module components/pure/NotificationIcon
 * @since 3.0.0
 * @requires styles/NotificationIcon
 * @property {object} props
 * @property {number} [props.count] - the number to display on the red circle
 * @property {string} [props.href] - the href to link to
 * @property {number} [props.size] - the size (in pixels) of the icon
 * @property {node} [props.children] - the Icon (eg, FontIcon or ImageIcon) to display
 * @property {any} [props...rest] - props passed to containing div component
 */
import 'styles/NotificationIcon';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';
import cs from 'classnames';


const NotificationIcon = ({ count, href, size, children, ...rest }) =>
  <div {...rest} className={cs('NotificationIcon', rest.className)} style={{ ...rest.style, fontSize: size }}>
    <Link to={href}>
      {
        count === true ?
          <span className='NotificationIcon__circle' />
          :
          count > 0 ?
            <span className='NotificationIcon__count'>{count > 50 ? '50+' : count}</span>
            : null
      }
      {children}
    </Link>
  </div>
  ;

NotificationIcon.propTypes = {
  count: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  size: PropTypes.number,
  href: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

NotificationIcon.defaultProps = {
  size: 40,
  count: 0,
};

export default NotificationIcon;
