/**
 * Reducer for handling the factoringclient resource actions
 * @module reducers/resource/factoringclient
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringclient
 * @requires actions/admin/factoring
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringclient;

import factoringclientResource, {
  FACTORING_CLIENT_ATTACHMENT_DELETE,
  FACTORING_CLIENT_VOIDED_CHECK_DELETE,
  FACTORING_CLIENT_NOA_ATTACHMENT_DELETE,
  USER_EDIT_FACTORING_CLIENT,
  FACTORING_ATTACHMENT_COLLATED_ERROR,
  FACTORING_ATTACHMENT_COLLATED_SUCCESS,
  FACTORING_ATTACHMENT_COLLATED_FETCH,
} from 'actions/resource/factoringclient';
import { get, merge } from 'lodash';
import factoringclientPaginatedResource from 'actions/admin/factoring/client';
import factoringclientSearchResource from 'actions/search/factoringclient';
import factoringclientByfactoringdebtorResource from 'actions/resourceBy/factoringdebtor/factoringclient';

export default function factoringclientResourceReducer(state = initialState, action) {
  let item = {};
  switch (action.type) {
    case USER_EDIT_FACTORING_CLIENT:
      return {
        ...state,
        [action.payload.id]: factoringclientResource.parse(state[action.payload.id], action.payload),
      };
    case FACTORING_ATTACHMENT_COLLATED_FETCH:
      item = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: merge({}, item, {
          collated: {
            isFetching: true,
          },
        }),
      };
    case FACTORING_ATTACHMENT_COLLATED_SUCCESS:
      item = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: merge({}, item, {
          collated: {
            isFetching: false,
            ...action.payload.data,
          },
        }),
      };
    case FACTORING_ATTACHMENT_COLLATED_ERROR:
      item = state[action.payload.id];
      return {
        ...state,
        [action.payload.id]: merge({}, item, {
          collated: {
            isFetching: false,
            error: action.payload.error,
          },
        }),
      };
    case FACTORING_CLIENT_VOIDED_CHECK_DELETE: {
      item = state[action.payload.id];
      const index = item.data.voided_checks.findIndex(voided_check => voided_check.filename === action.payload.voided_check);
      return {
        ...state,
        [action.payload.id]: {
          ...item,
          data: {
            ...item.data,
            voided_checks: [
              ...item.data.voided_checks.slice(0, index),
              ...item.data.voided_checks.slice(index + 1),
            ],
          },
        },
      };
    }
    case factoringclientPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringclientResource.parseMany(state, action.payload.results),
      };
    case factoringclientSearchResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringclientResource.parseMany(state, action.payload.results),
      };
  }
  if (factoringclientByfactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...factoringclientResource.parseMany(state, action.payload.results),
    };
  }
  return factoringclientResource.reduce(state, action);
}
