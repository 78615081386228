/**
 * Connected modal component for confirming an activity deletion
 * @module components/pure/modals/ConfirmActivityDelete
 * @since 3.0.0
 * @requires actions/ui
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {string} props.id - the ID of the activity to hide
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import factoringclientResource from 'actions/resource/factoringclient';

import ModalWarning from './Warning';
import { closeModal, openModal } from 'actions/ui';


const ApprovalConfirm = compose(
  connect(
    null,
    (dispatch, props) => ({
      async doSubmit() {
        try {
          await dispatch(factoringclientResource.edit(props.id, {
            factoring_approved: 'pending',
          }));
          dispatch(closeModal());
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: err.message || err }));
        }
        dispatch(closeModal());
      },
    }),
  ),
)(({ doSubmit, ...rest }) =>
  <ModalWarning
    headerChildren='Confirm'
    message='Please make sure the company name is correct as payments/checks will go to that company name.'
    buttonChildren='Cancel'
    {...rest}
    extraButtons={[
      <button className='btn btn-danger' onClick={doSubmit}>Confirm</button>,
    ]}
  />
);

ApprovalConfirm.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ApprovalConfirm;
