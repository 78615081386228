import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link, browserHistory } from 'react-router-v4';
import { submit } from 'redux-form';
import moment from 'moment';
import NavItem from 'react-bootstrap/lib/NavItem';

import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';

import { edit } from 'actions/resource/factoringdebtor';
import { openModal } from 'actions/ui';
import mapProps from 'recompose/mapProps';
import Resource from 'components/hoc/Resource';
import wrapPromisePending from 'components/hoc/wrapPromisePending';
import FactoringDebtorForm from 'components/pure/form/FactoringDebtorForm';
import FactoringDebtorNotes from 'components/container/FactoringDebtorNotes';
import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import { CenteredSpinner } from 'components/pure/Spinner';
import ButtonUserAdminProfile from 'components/container/ButtonUserAdminProfile';
import DebtorTransfers from 'components/container/DebtorTransfers';
import TransferredDebtor from 'components/container/TransferredDebtor';
import StatefulTabbed from 'components/stateful/StatefulTabbed';
import FactoringDebtorAuditLog from 'components/container/FactoringDebtorAuditLog';
import MaterialIcon from 'components/pure/MaterialIcon';
import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import { USER_TYPE } from '../../helpers';

export default compose(
  Resource('factoringdebtor', {
    idPropName: ['params', 'id'],
    resourcePropName: 'debtor',
  }),
  ResourceBy('factoringdebtor', 'similarfactoringdebtor', {
    idPropName: ['params', 'id'],
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
  connect(
    null,
    (dispatch, props) => ({
      triggerDelete() {
        return dispatch(openModal('confirm', {
          type: 'factoringdebtor_delete',
          args: [{
            id: props.params.id,
            cb: () => browserHistory.push('/admin/factoring/debtor/pending'),
          }],
        }));
      },
      triggerEdit() {
        return dispatch(submit('FactoringDebtorForm'));
      },
      openSetAsMasterModal(id, debtors) {
        return dispatch(openModal('setasmaster', { id, debtors }));
      },
    }),
  ),
  mapProps(({ debtor, items, params: { id }, triggerDelete, triggerEdit, openSetAsMasterModal, userType }) => ({
    debtor,
    debtors: items.map(item => item.data),
    id,
    triggerDelete,
    triggerEdit,
    openSetAsMasterModal,
    userType,
  })),
  wrapPromisePending(({ debtor }) => (fields, dispatch) => {
    return dispatch(edit(debtor.data.id, {
      email: fields.email,
      contact_email: fields.contact_email,
      name: fields.contact_name,
      company_name: fields.dba,
      address: Object.keys(fields.address).length === 0 && fields.address.constructor === Object ? {
        street_one: fields.street_one,
        street_two: fields.street_two,
        city: fields.city,
        state: fields.state,
        zip: fields.zip,
      } :
        typeof fields.address.street_one === 'undefined' ? {
          street_one: fields.address,
        } :
          {
            street_one: fields.address.street_one,
            street_two: fields.street_two,
            city: fields.address.city,
            state: fields.address.state,
            zip: fields.address.zip,
            country: fields.address.country,
          },
      phone_number: normalizeFormPhone(fields, 'contact_phone'),
      fax_number: normalizeFormPhone(fields, 'fax_number'),
      payment_status_email: fields.payment_status_email,
      mc: fields.mc,
      dot: fields.dot,
      tax_id: fields.tax_id,
      discount_rate: fields.discount_rate || null,
      avgpay: fields.avgpay || null,
      credit_approved: fields.credit_approved,
      dtp_allowance: fields.dtp_allowance,
      credit_approved_note: fields.credit_approved_note,
      noa_contact_email: fields.noa_contact_email,
      credit_limit: fields.credit_limit,
      assigned_admin: fields.admin.id,
      factor_dba: fields.factor_dba,
      aladdin_id: fields.aladdin_id,
      originals_required: fields.originals_required === 'true',
      requires_scanned_copy: fields.requires_scanned_copy,
    }))
      .then(() => {
        dispatch(openModal('success', { message: 'Successfully edited debtor' }));
      })
      .catch(err => {
        console.warn(err);
        const message = JSON.parse(err.message);
        if (message.non_field_errors) {
          dispatch(openModal('error', { message: `${message.non_field_errors[0]}` }));
        }
        else if (message.debtor && message.related_debtor) {
          dispatch(openModal('similardebtors', { id: debtor.data.id }));
        }
        else {
          dispatch(openModal('error', { message: 'Error Updating Debtor' }));
        }
      })
    ;
  }),
)(({ debtors, debtor, onSubmit, submitting, id, triggerEdit, triggerDelete, openSetAsMasterModal, userType }) =>
  <CenteredColumn
    xs={12}
    md={9}
    >
    <div className='row'>
      <div className='col-xs-3'>
        <Header><h3 style={{ color: '#2196f3' }}><b>Debtor Profile Edit</b></h3> { debtor.data.master === true ? <i className='text-success'> Master </i> : null }</Header>
      </div>
      <div className='col-xs-9'>
        <h5 style={{ marginTop: '3%' }} className='text-danger'> {debtors.length} Similar Debtors Found!</h5>
      </div>
    </div>
    <br />
    {
      debtor && debtor.data ?
        <FactoringDebtorForm
          onSubmit={onSubmit}
          accessFields={{
            email: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            admin: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            contact_email: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            contact_name: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            dba: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            address: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            contact_phone: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            contact_phone_ext: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            fax_number: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            fax_number_ext: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            mc: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            dot: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            tax_id: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            discount_rate: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            avgpay: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            credit_approved: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            credit_approved_note: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            noa_contact_email: false,
            dtp_allowance: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            credit_limit: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            credit_available: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            aladdin_id: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            source: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            factor_dba: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
            originals_required: [USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(userType),
          }}
          initialValues={{
            created: moment(debtor.data.created).format('l LTS'),
            email: debtor.data.email,
            contact_email: debtor.data.contact_email,
            contact_name: debtor.data.name,
            dba: debtor.data.company_name,
            address: debtor.data.address ? {
              street_one: debtor.data.address.street_one,
              street_two: debtor.data.address.street_two,
              city: debtor.data.address.city,
              state: debtor.data.address.state,
              zip: debtor.data.address.zip,
              country: debtor.data.address.country,
            } : {},
            contact_phone: getPhoneParts(debtor.data.phone_number).phone,
            contact_phone_ext: getPhoneParts(debtor.data.phone_number).ext,
            fax_number: getPhoneParts(debtor.data.fax_number).phone,
            fax_number_ext: getPhoneParts(debtor.data.fax_number).ext,
            payment_status_email: debtor.data.payment_status_email,
            mc: debtor.data.mc,
            dot: debtor.data.dot,
            tax_id: debtor.data.tax_id,
            discount_rate: debtor.data.discount_rate,
            avgpay: debtor.data.avgpay,
            credit_approved: debtor.data.credit_approved,
            credit_approved_note: debtor.data.credit_approved_note,
            noa_contact_email: debtor.data.noa_contact_email,
            dtp_allowance: debtor.data.dtp_allowance,
            credit_limit: debtor.data.credit_limit,
            credit_available: debtor.data.credit_available,
            aladdin_id: debtor.data.aladdin_id,
            admin: {
              id: debtor.data.assigned_admin,
              name: debtor.data.assigned_admin_name,
            },
            source: debtor.data.source ? debtor.data.source.type : undefined,
            factor_dba: debtor.data.factor_dba,
            originals_required: String(debtor.data.originals_required),
            requires_scanned_copy: debtor.data.requires_scanned_copy,
          }}
        />
        : <CenteredSpinner />
    }
    <div className='text-right'>
      { !debtor.data.transferred_to && !debtor.data.master ?
        <button style={{ backgroundColor: '#e86b00', height: '35px', color: 'white' }} onClick={() => openSetAsMasterModal(id, debtors)} type='button'>
        Set As Master
        </button> :
        null
      }
      {' '}
      <button style={{ backgroundColor: '#ff100e', height: '35px', color: 'white' }} onClick={triggerDelete} type='button'>
        <MaterialIcon name='delete' />
        Delete
      </button>
      {' '}
      {debtor && debtor.data && debtor.data.user && debtor.data.user.id ? <ButtonUserAdminProfile id={debtor.data.user.id} /> : null}
      {' '}
      <button style={{ backgroundColor: '#e86b00', height: '35px', color: 'white' }} onClick={triggerEdit} disabled={submitting} type='button'>
        <MaterialIcon name='save' />
        {' '}
        Update
      </button>
    </div>
    {
      debtor && debtor.data && debtor.data.transferred_to ?
        <div>
          <h4>AP Office</h4>
          <Link to={`/admin/factoring/debtor/${debtor.data.transferred_to}`}>
            <TransferredDebtor id={debtor.data.transferred_to} />
          </Link>
        </div>
        : null
    }
    <DebtorTransfers id={id} />
    {
      debtor && debtor.data ?
        <div style={{ margin: '2em 0' }}>
          <StatefulTabbed
            activeKey={0}
            navItems={[
              <NavItem>Internal Debtor Notes</NavItem>,
              <NavItem>Audit Trail</NavItem>,
            ]}
            items={[
              <FactoringDebtorNotes id={id} />,
              <FactoringDebtorAuditLog id={id} />,
            ]}
          />
        </div>
        : null
    }
  </CenteredColumn>
);
