/**
 * Component for searching for factoring payments
 * @module components/container/DispatchOnboardingSearch
 * @since 3.1.0
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import moment from 'moment';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';

import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import { openModal } from 'actions/ui';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';


const OnboardDebtorComponents = createTableItems(
  ({ data }) => [
    data.company_name,
    moment(data.created).format('l LTS'),
    formatPennies(data.amount),
  ],
);

const OnboardDebtorTable = compose(
  mapProps(({ id }) => ({
    id,
    fetchArgs: {
      id,
    },
  })),
  ResourceBy('user', 'onboarddebtor'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'onboarddebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
)(({ actions, item = {}, items }) =>
  <ResponsiveTable
    items={items}
    isFetching={item.isFetching}
    fetchNext={actions.fetchNext}
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Company Name', 'Time Stamp', 'Amount Billed']}
    placeholder='No results'
    TableItem={OnboardDebtorComponents.TableItem}
    BlockTableItem={OnboardDebtorComponents.BlockTableItem}
  />
);


const DispatchOnboarding = compose(
  mapProps(({ params: { id } }) => ({
    id,
    onSubmit(fields, dispatch) {
      dispatch(openModal('confirm', {
        type: 'dispatchonboarding',
        args: [{
          user_id: id,
          company_name: fields.company_name,
          amount: fields.amount,
        }],
      }));
    },
  })),
  reduxForm({
    form: 'DispatchOnboardingForm',
    initialValues: {
      amount: 4000,
    },
    validate(fields) {
      return validate(fields, {
        company_name: {
          presence: true,
        },
        amount: {
          presence: true,
          numericality: {
            onlyInteger: true,
            strict: true,
            greaterThanOrEqualTo: 0,
          },
        },
      });
    },
  }),
)(({ id, handleSubmit }) =>
  <div className='col-fill clearfix' style={{ flexDirection: 'row', maxWidth: '100%' }}>
    <div className='col-xs-12 col-sm-3'>
      <form onSubmit={handleSubmit}>
        <div>
          <Field name='company_name' component={LabeledInput} label='Company Name' />
          <Field name='amount' component={PennyInput} label='Charge Amount' />
        </div>
        <div className='text-right'>
          <button className='btn btn-orange' type='submit'>Bill for Onboarding</button>
        </div>
      </form>
    </div>
    <div className='col-xs-12 col-sm-9 col-fill'>
      <OnboardDebtorTable id={id} />
    </div>
  </div>
);

export default DispatchOnboarding;
