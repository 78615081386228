import React, { useState } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import AbbreviatedMultiSelect from 'components/pure/form/inputs/AbbreviatedMultiSelect';
import responsiveWindow from 'components/hoc/responsiveWindow';

const fundingRequestTypes = [
  { value: 'Factored', text: 'Factored' },
  { value: 'non_factored_pay_carrier', text: 'Non-Factored Pay Carrier' },
  { value: 'non_factored_pay_bol', text: 'Non-Factored Bill Out Only' },
  { value: 'self_finance_no_invoice', text: 'Self-Finance No Invoice' },
  { value: 'self_finance_with_invoice', text: 'Self-Finance With Invoice' },
];

const MultiFundingRequestTypeInput = ({ placeholder = 'Filter By Funding Request Type', label, value, onChange, disabled }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleChange = (values) => {
    onChange(values);
  };

  return (
    <div className={cs('form-group', 'funding_request_types')}>
      {label && <label className='control-label'>{label}</label>}
      <div className={cs('multi-funding-request-type-input-container')}>
        {!value.length && <div className='multi-funding-request-type-input-placeholder'>{placeholder}</div>}
        <AbbreviatedMultiSelect
          disabled={disabled}
          searchTerm={searchTerm}
          onSearch={setSearchTerm}
          data={fundingRequestTypes}
          placeholder={placeholder}
          abbreviationKey='value'
          fulltextKey='text'
          breakPoint={2}
          onChange={handleChange}
          value={value}
          style={{ minWidth: 300 }}
          multiple
        />
      </div>
      <style jsx>{`
        .multi-funding-request-type-input-container {
          position: relative;
        }
        .multi-funding-request-type-input-placeholder {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 1;
          pointer-events: none;
          color: #555;
          height: 2.286em;
          padding: .429em .857em;
        }
        @media (min-width: ${MOBILE_BREAKPOINT}px) {
          .multi-funding-request-type-input-placeholder {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

MultiFundingRequestTypeInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default responsiveWindow()(MultiFundingRequestTypeInput);
