import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';

import { edit, transfer } from 'actions/resource/factoringdebtor';
import { closeModal, openModal } from 'actions/ui';

import SimilarDebtorsTable from 'components/pure/SimilarDebtorsTable';
import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';


export default compose(
  ResourceBy('factoringdebtor', 'similarfactoringdebtor'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
  withState('isCollapsed', 'setIsCollapsed', true),
  mapProps(({ id, items, isCollapsed, setIsCollapsed, actions }) => ({
    form: `DebtorSimilarityForm#${id}`,
    debtors: items.map(item => item.data),
    actions,
    isCollapsed,
    setIsCollapsed,
    async onSubmit(fields, dispatch, { reset }) {
      try {
        await dispatch(edit(id, {
          transferred_to: fields.to_debtor,
        }));
        dispatch(openModal('success', { message: 'Successfully transferred debtor.' }));
        setIsCollapsed(true);
        reset();
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Error transferring debtor.' }));
      }
    },
  })),
  reduxForm({
    validate(fields) {
      if (!fields.to_debtor) {
        return {
          to_debtor: 'Required',
        };
      }
    },
  }),
)(({ debtors, isCollapsed, setIsCollapsed, dispatch, actions }) =>
  !debtors || !debtors.length ?
    null
    :
    <Field
      name='to_debtor'
      component={SimilarDebtorsTable}
      items={debtors}
      onDeleteClick={({ id }) => {
        dispatch(closeModal());
        dispatch(openModal('confirm', {
          type: 'factoringdebtor_delete',
          args: [{
            id,
            cb: () => actions.fetch(),
          }],
        }));
      }}
      onTransferClick={(debtor_id, id) => {
        dispatch(transfer(debtor_id, {
          transferred_to: id,
        }))
          .then(() => {
            dispatch(closeModal());
            dispatch(openModal('success', { message: 'Successfully transferred debtor' }));
          })
          .catch(err => {
            console.warn(err);
            const message = JSON.parse(err.message);
            if (message.non_field_errors) {
              dispatch(openModal('error', { message: `${message.non_field_errors[0]}` }));
            }
            else if (message.debtor && message.related_debtor) {
              dispatch(closeModal());
              dispatch(openModal('error', { message: `A similar debtor found! ${message.related_debtor_name[0]}.\n Please scroll down and click on "+ View Debtors". \n Go to the name "${message.related_debtor_name[0]}" from the list and click on the Transfer Icon next to it.` }));
            }
            else {
              dispatch(closeModal());
              dispatch(openModal('error', { message: 'Error Updating Debtor' }));
            }
          })
        ;
      }}
    />
);
