/**
 * Component that displays an iframe to a youtube video
 * @module components/pure/YoutubeEmbed
 * @since 3.0.0
 * @property {object} props
 * @property {number} props.video_id - the id of the video to embed
 * @property {any} [props....rest] - props passed to containing component
 */
import qs from 'querystring';
import React from 'react';
import PropTypes from 'prop-types';


const YoutubeEmbed = ({
  video_id,
  autoplay = false,
  controls = true,
  disablekb = false,
  loop = false,
  mute = true,
  ...rest
}) =>
  !video_id ? null :
  <div
    style={{
        marginTop: '1em',
        position: 'relative',
        paddingBottom: '56.25%',
      }}
    >
    <iframe
      width='100%'
      height='100%'
      marginHeight='0'
      marginWidth='0'
      frameBorder='0'
      scrolling='no'
      seamless={true}
      allowFullScreen={true}
      allowTransparency={true}
      src={`https://www.youtube.com/embed/${video_id}?${qs.stringify({
          mute: mute ? '1' : '0',
          autoplay: autoplay ? '1' : '0',
          controls: controls ? '1' : '0',
          disablekb: disablekb ? '1' : '0',
          loop: loop ? '1' : '0',
          modestbranding: '1',
          showinfo: '0',
          rel: '0',
        })}`}
      {...rest}
      style={{
          width: '100%',
          position: 'absolute',
          left: 0,
          ...rest.style,
        }}
    />
  </div>
  ;

YoutubeEmbed.propTypes = {
  video_id: PropTypes.string.isRequired,
};

export default YoutubeEmbed;
