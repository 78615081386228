import { compose } from 'redux-v3';
import moment from 'moment';

import Fetchable from 'datatypes/compose/resource/Fetchable';
import Singleton from 'datatypes/compose/Singleton';

export const adminuserResource = compose(
  Singleton('adminuser', {
    parse(oldValue = {}, newData) {
      return {
        isFetching: false,
        err: null,
        ...oldValue,
        fetchedAt: moment(),
        data: newData,
      };
    },
  }),
  Fetchable({
    url: id => `admin/user/${id}/`,
  }),
)();

export const {
  fetch,
  selector,
} = adminuserResource;

export default adminuserResource;
