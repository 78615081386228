import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { get } from 'lodash';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
    marginRight: 10,
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 15,
    color: '#5c6979',
    textAlign: 'center',
  },
  icon: {
    //fontSize: 50,
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    color: '#979797',
  },
};

const DebtorAmountLimit = ({ closeModal, action, cancel, isApprovedDebtorsOutCredit, updateFundingRequest, ...props }) => (
  <div>
    <ModalBody
      closeModal={() => {
        if (cancel) {
          cancel();
        }
        closeModal();
      }}
      >
      <div
        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
        >
        <MaterialIcon
          name='error'
          size={40}
          style={styles.icon}
        />
      </div>
      <div >
        <p style={styles.text}>
          The amount requested is more than the credit limit set for this customer.
        </p>
        {isApprovedDebtorsOutCredit &&
          <p style={{ ...styles.text, padding: 20}}>
            To speed up payment to your carrier, you can update this Funding Request to a Self-Finance Funding Request by updating its Funding Request Type.
          </p>
        }
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, justifyContent: 'flex-end', margin: '0px 5px 5px 5px' }}>
      {isApprovedDebtorsOutCredit  &&
        <Button
          style={styles.button}
          onClick={() => {
            closeModal();
            updateFundingRequest();
          }} size='sm'
        >
          Update Funding Request
        </Button>       
      }
      <Button
        style={styles.button}
        onClick={() => {
          action();
          closeModal();
        }} size='sm'
      >
        Send Anyways
      </Button>
    </div>
  </div>
);

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ))(DebtorAmountLimit);
