import React from 'react';

const ModalBody = ({ children, closeModal, style = {} }) => {
  return (
    <div className='modal-body' style={style}>
      <div className='light-placeholder'>
        {closeModal && (
          <button type='button' className='close' aria-label='Close' onClick={closeModal}>
            <span aria-hidden='true'>×</span>
          </button>
        )}
        { children }
      </div>
    </div>
  );
};

export default ModalBody;
