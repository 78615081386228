import React from 'react';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import Header from 'components/pure/Header';

const EmptyModal = ({ children, title, control, headerStyle, closeModal, displayCloseIcon = true, customCloseModal }) =>
  <div>
    {
      displayCloseIcon ?
        <ModalBody 
        closeModal={() => {
          if(customCloseModal) {
            customCloseModal();
          }
           closeModal();
           }} >
          {
            title ?
              <Header style={headerStyle}>{title}</Header>
              : null
          }
          {children}
        </ModalBody>
        :
        <ModalBody >
          {
            title ?
              <Header style={headerStyle}>{title}</Header>
              : null
          }
          {children}
        </ModalBody>
    }

    {
      control ?
        <ModalFooter>
          {control}
        </ModalFooter>
        : null
    }
  </div>;
export default connect(null, { closeModal })(EmptyModal);
