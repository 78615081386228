/**
 * Connected modal component to prompt a user to create a quick factoring invoice
 * @module components/pure/modals/QuickInvoice
 * @since 3.0.0
 * @requires actions/ui
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { formValueSelector, reduxForm } from 'redux-form';
import { closeModal, openModal } from 'actions/ui';


const FORM_NAME = 'DefaultBankInfo';
const selector = formValueSelector(FORM_NAME);

export default compose(
  connect(
    state => ({
      accountType: selector(state, 'accountType'),
    }),
    (dispatch, { setShouldShowPreview }) => ({
      closeModal() {
        dispatch(closeModal());
      },
      openDebitAccountsModal: item => {
        dispatch(closeModal());
        dispatch(openModal('debitaccounts', { item }));
      },
      openBanksModal: item => {
        dispatch(closeModal());
        dispatch(openModal('banks', { item }));
      },
    }),
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
  }),
)(({ dispatch, item, submitting, handleSubmit, closeModal, openDebitAccountsModal, openBanksModal }) =>
  <div>
    <div className='modal-body'>
      <div className='light-placeholder'>
        <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
        <h4 className='modal-title text-blue'>Payment Types</h4>
        <hr />
        <form onSubmit={handleSubmit}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
            >
            <button className='btn btn-orange pull-right' type='submit' disabled={submitting} onClick={() => openDebitAccountsModal(item)}>Debit</button>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
            >
            <div>
              <button className='btn btn-orange pull-right' type='submit' disabled={submitting} onClick={() => openBanksModal(item)}>ACH</button>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 15,
            }}
          />
        </form>
      </div>
    </div>
  </div>
);
