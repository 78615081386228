import React from 'react';
import PropTypes from 'prop-types';


const DEFAULT_OPTIONS = {
  srcProp: 'src',
};

export default options => WrappedComponent => {
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };

  return class ObjectURLSrc extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        [options.srcProp]: props[options.srcProp],
      };
      this.didCreateObjectURL = false;
      if (!(typeof props[options.srcProp] === 'string')) {
        this.didCreateObjectURL = true;
        this.state.src = URL.createObjectURL(props.src); // eslint-disable-line react/no-direct-mutation-state
      }
    }

    componentWillUnmount() {
      if (this.didCreateObjectURL) {
        URL.revokeObjectURL(this.state[options.srcProp]);
      }
    }

    static propTypes = {
      src: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.instanceOf(File),
        PropTypes.instanceOf(Blob),
      ]).isRequired,
    };

    render() {
      const { src } = this.state;

      return React.createElement(WrappedComponent, {
        ...this.props,
        [options.srcProp]: src,
      });
    }
  };
};
