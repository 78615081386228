/**
 * Thunk for dispatching API fetches
 * @module datatypes/APIFetch
 * @since 3.0.0
 * @requires datatypes/AuthenticationError
 * @requires datatypes/PermissionsError
 */
import { push } from 'react-router-redux';

import AuthenticationError from './AuthenticationError';
import PermissionsError from './PermissionsError';
import { refreshUserToken } from '../actions/user';


/**
 * Unauthorized logout action. Signifies that a resource that was fetched was expected to be fetched successfully, but failed due to a permissions error.
 * @event UNAUTHORIZED_LOGOUT
 * @property {symbol} type - Symbol(UNAUTHORIZED_LOGOUT)
 */
export const UNAUTHORIZED_LOGOUT = Symbol('UNAUTHORIZED_LOGOUT');

/**
 * Wraps [fetch]{@link https://github.github.io/fetch/}
 * @function APIFetch
 * @param {string} url - the destintion URL to be fetched
 * @param {object} [options]
 * @param {string} [options.method] - the HTTP method to use
 * @param {object} [options.headers] - HTTP headers to use
 * @param {string|blob} [options.body] - the HTTP request body
 * @returns {Promise<Response>} an object representing the HTTP response, see [whatwg docs]{@link https://fetch.spec.whatwg.org/#response-class}
 */
export default function APIFetch(...args) {
  return (dispatch, getState) => {
    const state = getState();
    const isLoggedIn = Boolean(state.user.token);
    return global.fetch(...args)
      .then(res => {
        if (res.status === 401) {
          return res.json()
            .then(json => {
              if (!isLoggedIn && json.detail === 'Authentication credentials were not provided.') {
                return Promise.reject(new PermissionsError(json.detail));
              }
              //refreshUserToken()
              dispatch({
                type: UNAUTHORIZED_LOGOUT,
                payload: undefined,
              });
              dispatch(push('/login'));
              return Promise.reject(new AuthenticationError(json.detail));
            })
            ; // eslint-disable-line indent
        }
        if (res.status === 403) {
          const clonedRes = res.clone();
          return res.json()
            .then(json => {
              if (isLoggedIn && json.detail === 'You do not have permission to perform this action.' || json.detail === 'Permission denied.') {
                return Promise.reject(new PermissionsError(json.detail));
              }
              return clonedRes;
            })
            ; // eslint-disable-line indent
        }
        return res;
      })
      ; // eslint-disable-line indent
  };
}
