/* global MOBILE_BREAKPOINT */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import cs from 'classnames';
import withState from 'recompose/withState';

import { trailerTypeMap } from 'helpers';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import AbbreviatedMultiSelect from 'components/pure/form/inputs/AbbreviatedMultiSelect';
import responsiveWindow from 'components/hoc/responsiveWindow';


export const trailerTypes = [];
for (const key in trailerTypeMap) {
  trailerTypes.push({
    value: key,
    text: trailerTypeMap[key],
  });
}


export const TrailerInput = compose(
  responsiveWindow(),
)(({ isMobile, extraTrailerTypes, label = 'Equipment', ...rest }) =>
  <LabeledDropdown
    {...rest}
    value={rest.input && rest.input.value ? rest.input.value : []}
    data={extraTrailerTypes ? [
      ...trailerTypes,
      ...extraTrailerTypes,
    ] : trailerTypes}
    label={label}
    filter={isMobile ? null : (item, val) =>
      item[rest.textField || 'text'].toLowerCase().startsWith(val.toLowerCase()) ||
      item[rest.valueField || 'value'].toLowerCase().startsWith(val.toLowerCase())
    }
  />
);

TrailerInput.trailerTypes = trailerTypes;

TrailerInput.propTypes = {
  label: PropTypes.string,
  extraTrailerTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};

export default TrailerInput;

const CategoryTrailerTypes = {
  'Any': [],
  'Dry Van (V)': [
    'V',
    'VV',
    'WLK',
    'VZ',
    'VC',
    'CV',
    'ST',
  ],
  'Reefer (R)': [
    'R',
    'RZ',
    'T',
  ],
  'Flatbed (F)': [
    'AC',
    'F',
    'FZ',
    'MX',
    'HS',
    'LB',
    'SD',
    'DT',
    'DD',
    'BT',
  ],
};
const CategoryTrailerInputData = Object.keys(CategoryTrailerTypes);
export const CategoryTrailerInput = props =>
  <LabeledDropdown
    {...props}
    data={CategoryTrailerInputData}
    label={props.label || 'Equipment'}
  />
  ;
CategoryTrailerInput.trailerTypes = CategoryTrailerTypes;

export const MultiTrailerInput = compose(
  withState('searchTerm', 'setSearchTerm', ''),
  responsiveWindow(),
)(({
  input,
  meta,
  placeholder = 'Any',
  label,
  searchTerm,
  setSearchTerm,
  isMobile,
  extraTrailerTypes,
  disabled,
  hasError = (meta = {}) => meta.touched && meta.error,
  ...rest
}) =>
  <div
    className={cs('form-group', 'equipment_types', {
      'has-error': (
        (!input.value || !input.value.length) && searchTerm.length) ||
        hasError(meta) || meta.visited && !meta.touched && meta.error,
    })}
    >
    {label && <label className='control-label'>{label}</label>}
    <div
      {...rest}
      className={cs('multi-trailer-input-container', rest.className)}
      >
      {
        !input.value || !input.value.length ?
          <div className='multi-trailer-input-placeholder'>{placeholder}</div>
          : null
      }
      <AbbreviatedMultiSelect
        disabled={disabled}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
        data={extraTrailerTypes ? [
          ...trailerTypes,
          ...extraTrailerTypes,
        ] : trailerTypes}
        placeholder={placeholder}
        abbreviationKey='value'
        fulltextKey='text'
        breakPoint={2}
        onChange={input.onChange}
        onFocus={input.onFocus}
        value={input.value}
        inputProps={isMobile ? {
          type: 'button',
        } : undefined}
      />
    </div>
    {(!input.value || !input.value.length) && searchTerm.length ? <div className='help-block'>Please select type(s) from list</div> : null}
    {
      hasError(meta) || meta.visited && !meta.touched && meta.error ?
        <div className='help-block' style={{ color: '#a94442' }}>{meta.error}</div>
        : null
    }
    <style jsx>{`
      .multi-trailer-input-container {
        position: relative;
      }
      .multi-trailer-input-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        color: #555;
        height: 2.286em;
        padding: .429em .857em;
      }
      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        .multi-trailer-input-placeholder {
          display: none;
        }
      }
    `}</style>
  </div>
);

MultiTrailerInput.trailerTypes = trailerTypes;

MultiTrailerInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  extraTrailerTypes: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
  })),
};
