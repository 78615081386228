/* global EMPTY_PLACEHOLDER */
import 'styles/RateIndexMobile';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import random from 'lodash/random';
import moment from 'moment';

import { formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import { rateIndexHistoryPaginatedResource } from 'actions/rateindex';

import ResponsiveContainer from 'components/pure/ResponsiveContainer';
import Table from 'components/pure/Table';
import TableItem from 'components/pure/TableItem';
import Header from 'components/pure/Header';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import MaterialIcon from 'components/pure/MaterialIcon';



const RateIndexMobile = compose(
  connect(
    (state, { id }) => ({
      item: state.resource.rateindexhistory[id],
    }),
  ),
)(({ item }) => {
  const created = item.data.created ? moment(item.data.created).format('MMM D') : moment().subtract(3, 'months').format('MMM');
  return (
    <div className='RateIndexMobile tableitem-striped' style={{ display: 'flex', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div>{item.data.origin_location} <MaterialIcon name='play_arrow' size={20} /> {item.data.destination_location}</div>
        <div>
          <div>{item.data.amount ? formatPennies(item.data.amount) : formatPennies(random(50000, 1200000)).replace(/\d/g, 'X')}</div>
          <div>{trailerTypeMap[item.data.trailerType]}</div>
        </div>
      </div>
      <div className='text-right' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
        <div>{item.data.length && item.data.length >= 5 ? `${item.data.length} ft` : EMPTY_PLACEHOLDER}</div>
        <div>{item.data.weight && item.data.weight >= 5 ? `${formatWeight(item.data.weight)} lbs` : EMPTY_PLACEHOLDER}</div>
        <div>{created}</div>
      </div>
    </div>
  );
});

const RateIndexHistoryTableItem = compose(
  connect(
    (state, { id }) => ({
      item: state.resource.rateindexhistory[id],
    }),
  ),
)(({ item }) => {
  const created = item.data.created ? moment(item.data.created).format('MMM D') : moment().subtract(3, 'months').format('MMM');
  return (
    <TableItem
      className='tableitem-striped'
      rows={[[
        item.data.trailerType,
        created,
        item.data.origin_location,
        item.data.destination_location,
        item.data.weight && item.data.weight >= 5 ? `${formatWeight(item.data.weight)} lbs` : EMPTY_PLACEHOLDER,
        item.data.length && item.data.length >= 5 ? `${item.data.length} ft` : EMPTY_PLACEHOLDER,
        item.data.amount ? formatPennies(item.data.amount) : formatPennies(random(50000, 1200000)).replace(/\d/g, 'X'),
      ]]}
    />
  );
});

const RateIndexHistory = compose(
  connect(
    state => state.rateindex.history,
    {
      fetchNext: rateIndexHistoryPaginatedResource.fetchNext,
    },
  ),
)(({ ids, next, isFetching, fetchNext }) =>
  <div
    style={{
      height: 'auto',
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
    }}
    >
    <Header style={{ flexGrow: 0, display: 'flex', flex: 'auto' }}>Lane Trends</Header>
    <ResponsiveContainer
      desktop={
        <div>
          <Table
            containerProps={{
              className: 'col-fill',
            }}
            items={ids ? ids.map(id => ({ id })) : []}
            isFetching={isFetching}
            headers={['Type', 'Date', 'Origin', 'Destination', 'Weight', 'Length', 'Rate']}
            ListItem={RateIndexHistoryTableItem}
            striped={true}
          />
          {
            next && !isFetching ?
              <button className='btn btn-orange' style={{ margin: '1em 0' }} onClick={fetchNext}>Load More</button>
              : null
          }
        </div>
      }
      mobile={
        <div
          style={{
            height: 'auto',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
          >
          <NormalInfiniteScroll
            onScrollBottom={fetchNext}
            isFetching={isFetching}
            >
            {ids.map(id => <RateIndexMobile key={id} id={id} />)}
          </NormalInfiniteScroll>
          {
            next && !isFetching ?
              <button className='btn btn-orange' style={{ margin: '1em 0' }} onClick={fetchNext}>Load More</button>
              : null
          }
        </div>
      }
    />
  </div>
);

export default RateIndexHistory;
