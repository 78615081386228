/* global API */

export const generateAPIToken = () => async (dispatch, getState) => {
    const res = await global.fetch(`${API.host}/auth/access_tokens/`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            'content-type': 'application/json',
        },
    });
    const json = await res.json();
    return json;
};

export const getAPIToken = () => async (dispatch, getState) => {
    const res = await global.fetch(`${API.host}/auth/access_tokens/`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${getState().user.token}`,
            'content-type': 'application/json',
        },
    });
    const json = await res.json();
    return json;
};