/**
 * Component to display a mobile-friendly load view
 * Used in LoadSearch mobile
 * @module components/pure/LoadMobile
 * @since 3.0.0
 * @requires helpers
 * @requires components/hoc/spinnerIfFetching
 * @requires components/pure/DistanceTitledGridSection
 * @requires components/pure/PureUserTable
 * @requires components/pure/TitledGrid
 * @requires components/pure/RouteFormat
 * @property {object} props
 * @property {object} props.is_self
 * @property {object} props.load
 * @property {object} props.load.data
 * @property {string} props.load.data.id
 * @property {string} props.load.data.origin_city
 * @property {string} props.load.data.origin_state
 * @property {string} props.load.data.destination_city
 * @property {string} props.load.data.destination_state
 * @property {moment} props.load.data.time_start
 * @property {moment} props.load.data.time_end
 * @property {moment} props.load.data.time_delivery
 * @property {number} props.load.data.length
 * @property {number} props.load.data.weight
 * @property {string} props.load.data.trailerType
 * @property {number} props.load.data.deadhead
 * @property {string} props.load.user_type
 * @property {object} props.load.data.user
 * @property {object} props.load.data.user.data
 * @property {string} props.load.data.user.data.dba
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import moment from 'moment';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import DistanceTiltedGridSection from 'components/container/DistanceTiltedGridSection';
import MaterialIcon from 'components/pure/MaterialIcon';
import UserTable from 'components/pure/PureUserTable';
import TitledGrid from 'components/pure/TitledGrid';
import RouteFormat from 'components/pure/RouteFormat';


const LoadMobile = compose(
  spinnerIfFetching('load'),
)(({ load, is_self, ...rest }) =>
  <div {...rest}>
    <div style={{ fontWeight: 'bold' }}>
      <div
        style={{
          textAlign: 'center',
          borderBottom: `solid 1px ${colors.BORDER_GRAY}`,
          margin: '0 0.75em',
          padding: '0.75em 0',
        }}
        >
        <RouteFormat
          style={{
            fontSize: '1.3em',
          }}
          origin={load.data.origin_locations}
          destination={load.data.destination_locations}
          tooltip_location_separator={[<br />]}
          tooltip_location_prefix={[(location, i) => `${i + 2}. `]}
        />
        <div style={{ paddingTop: '0.5em' }}>
          {trailerTypeMap[load.data.trailerType]} / {load.data.length ? `${load.data.length} ft` : '—'} / {load.data.weight ? `${formatWeight(load.data.weight)} lbs` : '—'}
        </div>
      </div>
      <TitledGrid cols={3}>
        <TitledGrid.Section title='company type' className='text-capitalize'>
          {load.data.user_type}
        </TitledGrid.Section>
        <DistanceTiltedGridSection item={load} />
        <TitledGrid.Section title='load age'>
          <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(load.data.time_posted)}
        </TitledGrid.Section>
        <TitledGrid.Section title='ready'>
          <div>{load.data.time_start.format('D MMM')}</div>
          <div style={{ fontSize: '0.7em' }}>
            {load.data.time_start.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='close'>
          <div>{load.data.time_end.format(`D MMM${load.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}</div>
          <div style={{ fontSize: '0.7em' }}>
            {load.data.time_end.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='delivers'>
          {load.data.time_delivery ? load.data.time_delivery.format('D MMM') : '—'}
        </TitledGrid.Section>
      </TitledGrid>
    </div>
    {
      is_self ? null :
      <UserTable
        items={[{
            item: load.data.user,
            load_id: load.data.id,
          }]}
        dontShowBidding={[USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(load.data.user_type)}
        isShipperOnly={load.data.user_type === USER_TYPE.SHIPPER}
      />
    }
  </div>
);

LoadMobile.propTypes = {
  is_self: PropTypes.bool,
  load: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.shape({
      id: PropTypes.string,
      origin_city: PropTypes.string,
      origin_state: PropTypes.string,
      destination_city: PropTypes.string,
      destination_state: PropTypes.string,
      time_start: PropTypes.instanceOf(moment),
      time_end: PropTypes.instanceOf(moment),
      time_delivery: PropTypes.instanceOf(moment),
      length: PropTypes.number,
      weight: PropTypes.number,
      trailerType: PropTypes.string,
      deadhead: PropTypes.number,
      user_type: PropTypes.oneOf([USER_TYPE.SHIPPER, 'carrier', 'broker']),
      user: PropTypes.shape({
        err: PropTypes.any,
        data: PropTypes.shape({
          dba: PropTypes.string,
        }),
      }),
    }),
  }),
};

export default LoadMobile;
