/* global API */
import moment from 'moment';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';
import Resource from '.';


const DEFAULT_OPTIONS = {
  acceptableStatusCode: 200,
  url: () => {
    throw new Error('unspecified URL in FetchableResource');
  },
  transformHeaders: i => i,
  transformBody: data => JSON.stringify(data),
};


const Fetchable = options => (Super = Resource) => {
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  return class Fetchable extends Super {
    constructor() {
      super(...arguments);
      this.actions.FETCH_REQUEST = Symbol(`${this.upperName}_FETCH_REQUEST`);
      this.actions.FETCH_REQUEST_SUCCESS = Symbol(`${this.upperName}_FETCH_REQUEST_SUCCESS`);
      this.actions.FETCH_REQUEST_FAILURE = Symbol(`${this.upperName}_FETCH_REQUEST_FAILURE`);
      this.fetch = this.fetch.bind(this);
      this.fetchIfNeeded = this.fetchIfNeeded.bind(this);
      this.controller = new AbortController();
      this.abort = this.abort.bind(this);
    }

    fetch(id) {
      return async (dispatch, getState) => {
        const state = getState();
        const item = this.selector(state, id);

        if (item && item.isFetching) {
          return;
        }
        dispatch({
          type: this.actions.FETCH_REQUEST,
          payload: id,
        });

        try {
          const { signal } = this.controller;
          const res = await dispatch(APIFetch(`${API.host}/${options.url(...arguments)}`, {
            method: 'GET',
            headers: options.transformHeaders({
              authorization: `bearer ${state.user.token}`,
              'content-type': 'application/json',
            },
            signal
            ),
          }));
          if (res.status !== options.acceptableStatusCode) {
            if (res.status >= 400 && res.status < 500) {
              const json = await res.json();
              if (res.status === 400) {
                throw new SubmissionError(json);
              }
              throw new PermissionsError(json);
            }
            const text = await res.text();
            throw new FetchError(res.status, text);
          }
          const json = await res.json();
          return dispatch({
            type: this.actions.FETCH_REQUEST_SUCCESS,
            payload: {
              id,
              json,
            },
          });
        }
        catch (err) {
          dispatch({
            type: this.actions.FETCH_REQUEST_FAILURE,
            payload: {
              id,
              err,
            },
          });
          throw err;
        }
      };
    }

    fetchIfNeeded(id, ...restOpts) {
      let opts = {
        ...options,
      };
      restOpts.forEach(opt => {
        if (opt instanceof Object && !Array.isArray(opt)) {
          opts = {
            ...opts,
            ...opt,
          };
        }
      });
      return (dispatch, getState) => {
        const state = getState();
        const item = this.selector(state, id);
        if (
          item === undefined ||
          (item.err !== null && !(item.err instanceof PermissionsError)) ||
          moment().diff(item.fetchedAt, opts.expirationDenomination) > opts.expirationTime
        ) {
          return dispatch(this.fetch(id));
        }
        return Promise.resolve();
      };
    }

    abort() {
      return controller.abort();
    }

    reduce(state, action) {
      switch (action.type) {
        case this.actions.FETCH_REQUEST:
          return {
            ...state,
            [action.payload]: {
              ...this.parse(state[action.payload]),
              isFetching: true,
            },
          };
        case this.actions.FETCH_REQUEST_SUCCESS:
          return {
            ...state,
            [action.payload.id]: {
              ...this.parse(state[action.payload.id], action.payload.json),
              isFetching: false,
              fetchedAt: moment(),
              err: null,
            },
          };
        case this.actions.FETCH_REQUEST_FAILURE:
          return {
            ...state,
            [action.payload.id]: {
              ...this.parse(state[action.payload.id]),
              isFetching: false,
              err: action.payload.err,
            },
          };
      }
      return super.reduce(...arguments);
    }
  };
};

export default Fetchable;
