import 'styles/CheckmarkTable';
import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';

import MaterialIcon from 'components/pure/MaterialIcon';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';


const CheckmarkTable = ({ header, types, rows, disabled }) =>
  <table className='table CheckmarkTable'>
    <thead>
      <tr>
        <th className='CheckmarkTable__header'>{header || ''}</th>
        {types.map((type, i) => (
          <th key={i} className='CheckmarkTable__type'>
            <MaterialIcon name={type.icon} size={30} />
            <div>{type.text}</div>
          </th>
        ))}
      </tr>
    </thead>
    {rows.map(([text, ...fieldNames], i) =>
      <tbody key={i} className='MultiCheckmarkInput'>
        <tr>
          <td>{text}</td>
          {fieldNames.map((name, i) =>
            <td key={i}>
              <Field name={name} component={CheckboxInput} disabled={disabled} />
            </td>
          )}
        </tr>
      </tbody>
    )}
  </table>
  ;

CheckmarkTable.propTypes = {
  header: PropTypes.string,
  types: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.node.isRequired,
    icon: PropTypes.string.isRequired,
  })),
  rows: PropTypes.array.isRequired,
};

export default CheckmarkTable;
