/*global API */

export const deletePaymentMethod = (userid, id) => async (dispatch, getState) => {
  await global.fetch(`${API.host}/accounts/${userid}/${id}/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
    method: 'delete',
  });
};
