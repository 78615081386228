/**
 * Actions relating to the User resource
 * @module actions/resource/user
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/ControlledResource
 */
/*global API */
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import ControlledResource from 'datatypes/ControlledResource';

import { FACTORING_PROFILE_REQUEST_SUCCESS } from '../user';


/**
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class UserResource extends ControlledResource {
  editProfile = this.editProfile.bind(this);
  editProfile2 = this.editProfile2.bind(this);

  /**
   * Thunk to edit properties on the nested [UserType]{@link module:actions/user~UserType}
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
   */
  editProfile(id, data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.editRequest(id));

      const formData = new FormData();
      for (const key in data) {
        let value = data[key];
        if (value === null || value === undefined) {
          value = '';
        }
        if (Array.isArray(value)) {
          for (const val of value) {
            formData.append(key, val);
          }
        }
        else {
          formData.append(key, value);
        }
      }

      return dispatch(APIFetch(`${API.host}/${item.data.type}s/${item.data.user_id}/`, {
        method: 'PATCH',
        body: formData,
        headers: {
          Authorization: `Bearer ${state.user.token}`,
        },
      }))
        .then(res => res.status !== this.options.acceptableEditStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch(this.editRequestFailure(id, err));
          return Promise.reject(err);
        })
        .then(json => {
          json[item.data.type] = json.id;
          json.id = item.data.id;
          json.type = item.data.type;
          return json;
        })
        .then(json => dispatch(this.editRequestSuccess(id, json)))
        ; // eslint-disable-line indent
    };
  }

  editProfile2(id, data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.editRequest(id));

      try {
        const res = await dispatch(APIFetch(`${API.host}/${item.data.type}s/${item.data.user_id}/`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableEditStatusCode) {
          throw new FetchError(res.status, await res.text());
        }

        const json = await res.json();
        json[item.data.type] = json.id;
        json.id = item.data.id;
        json.type = item.data.type;
        dispatch(this.editRequestSuccess(id, json));
      }
      catch (err) {
        dispatch(this.editRequestFailure(id, err));
        throw err;
      }
    };
  }

  reduce(state, action) {
    state = super.reduce(state, action);
    switch (action.type) {
      case FACTORING_PROFILE_REQUEST_SUCCESS:
        return {
          ...state,
          [action.payload.user]: {
            ...state[action.payload.user],
            data: {
              ...state[action.payload.user].data,
              factoring_approved: action.payload.factoring_approved,
              factoring_discount_rate: action.payload.discount_rate,
              factoring_message: action.payload.factoring_message,
            },
          },
        };
    }
    return state;
  }
}

/**
 * Singleton for our UserResource
 */
const userResource = new UserResource('user', {
  url: id => `users/${id}`,
});

export default userResource;
export const {
  fetch,
  fetchIfNeeded,
  getItem,
  edit,
  editProfile,
  editProfile2,
} = userResource;
