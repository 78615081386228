import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import branch from 'recompose/branch';

import Resource from 'components/hoc/Resource';
import TruckMobile from 'components/pure/TruckMobile';


export default compose(
  Resource('truck', { resourcePropName: 'truck' }),
  branch(
    ({ truck }) => truck === undefined || truck.isFetching,
    Component => Component,
    compose(
      mapProps(({ truck, ...rest }) => ({
        ...rest,
        truck,
        id: truck.data.user_id,
      })),
      Resource('user', { resourcePropName: 'user' }),
    ),
  ),
)(TruckMobile);
