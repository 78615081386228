import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';

import Modal from '../../hoc/Modal';
import GenericHeader from './GenericHeader';
import LabeledInput from '../form/inputs/LabeledInput';
import GoogleLocationInput from '../form/inputs/GoogleLocationInput';


const TriggerEmailToCompanyForm = compose(
  reduxForm({
    form: 'TriggerEmailToCompanyForm',
  }),
)(({ closeModal, handleSubmit }) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-body'>
      <Field name='dba' component={LabeledInput} label='Corporate Name of Bill To' />
      <Field name='contact_name' component={LabeledInput} label='Contact Name (Billing)' />
      <Field name='contact_email' component={LabeledInput} label='Contact Email (Corporate or Billing)' />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
      <button className='btn btn-orange' type='submit'>Send Credit Invite</button>
    </div>
  </form>
);

const EnterCompanyInfoForm = compose(
  reduxForm({
    form: 'EnterCompanyInfoForm',
    initialValues: {
      address: {},
    },
  }),
)(({ closeModal, handleSubmit }) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-body'>
      <Field name='dba' component={LabeledInput} label='Corporate Name of Bill To' />
      <Field name='address' component={GoogleLocationInput} label='Billing or Corporate Address' />
      <Field name='mc' component={LabeledInput} label='MC# if Freight Broker *' />
      <Field name='ein_or_taxid' component={LabeledInput} label='EIN or or Business Tax ID# *' />
      <Field name='contact_name' component={LabeledInput} label='Contact Name (Billing)' />
      <Field name='contact_phone' component={LabeledInput} label='Contact Phone (Billing)' />
      <Field name='contact_email' component={LabeledInput} label='Contact Email (Corporate or Billing)' />
      <Field name='website' component={LabeledInput} label='Website Address *' />
    </div>
    <div className='modal-footer'>
      <i className='help-block pull-left'>{'* Optional'}</i>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
      <button className='btn btn-orange' type='submit'>Request Approval</button>
    </div>
  </form>
);

class ShipperApprovalRequestModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      view: 0,
    };
    this.toggleView = this.toggleView.bind(this);
  }

  static numViews = 2;

  toggleView() {
    let { view } = this.state;
    this.setState({ view: ++view >= ShipperApprovalRequestModal.numViews ? 0 : view });
  }

  render() {
    const { closeModal } = this.props;
    const { view } = this.state;
    return (
      <div>
        <GenericHeader
          closeModal={closeModal}
          style={{
            backgroundColor: '#f0ad4e',
            color: 'white',
          }}
          icon='warning'
          >
          New Bill to Approval Request
        </GenericHeader>
        {
          view === 0 ?
            <div>
              <div className='modal-body'>
                <p>Enter in details of the new company bill to below for accelerated approval for advances. We cannot guarantee approval of this company for terms. Approval may take up to 48 hours.</p>
                <a className='pull-right' role='button' onClick={this.toggleView}>Invite Shipper to Fill Out via Email</a>
              </div>
              <EnterCompanyInfoForm closeModal={closeModal} />
            </div>
            :
            <div>
              <div className='modal-body'>
                <p>We will email a link to this new bill to and invite them to enter their billing information so that we can expedite approval. Approval may take up to 48 hours.</p>
                <a className='pull-right' role='button' onClick={this.toggleView}>Go Back to Form</a>
              </div>
              <TriggerEmailToCompanyForm closeModal={closeModal} />
            </div>
        }
      </div>
    );
  }
}

ShipperApprovalRequestModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default compose(
  Modal,
)(ShipperApprovalRequestModal);
