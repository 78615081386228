import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal, openModal } from 'actions/ui';
import { Field, Form, reduxForm } from 'redux-form';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';
import { requestStripeCard } from 'actions/stripeCards';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import errorHandler from 'helpers/errorHandler';

const StripeCardForm = ({ closeModal, handleSubmit, isSubmitting }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <Header className='orange-text'>Comfreight Credit Card Form</Header>
    </div>
    <div className='modal-body'>
      <Form onSubmit={handleSubmit}>
         <Field
            name='first_name'
            component={LabeledInput}
            placeholder='Enter Your First Name'
            label='First Name'
            // horizontal
        />
         <Field
            name='last_name'
            component={LabeledInput}
            placeholder='Enter Your Last Name'
            label='Last Name'
          // horizontal
        />
        <Field
            name='date_of_birth'
            component={DateTimeInput}
            label='Date of Birth'
            pickerOptions={{
                time: false, 
            }}
            inputProps={{
                placeholder: 'Enter Your Date of Birth' 
            }}
        />
        <Field
            name='billing_address'
            component={GoogleLocationInput}
            label='Billing Address'
            placeholder='Enter Your Billing Address'
            setValueWithoutConfirming={true}
        />
        <Field
            name='shipping'
            component={GoogleLocationInput}
            label='Shipping Address'
            placeholder='Enter Your Shipping Address'
            setValueWithoutConfirming={true}
        />
      </Form>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
      <BigOrangeSubmitButton
          className='btn btn-orange'
          type='submit'
          onClick={handleSubmit}
          submitting={isSubmitting}
          message='Save'
      />
    </div>
  </div>;

const mapStateToProps = (state, ownProps) => ({
  form: 'COMFREIGHT_CARD_FORM',
});

export default compose(
  connect(
    mapStateToProps,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    })
  ),
  reduxForm({
    destroyOnUnmount: true,
    forceUnregisterOnUnmount: true,
      onSubmit: async (fields, dispatch, { onConfirm, accountId, closeModal } ) => {
        try {
            const billingAddress = {
                line1: fields.billing_address?.address,
                city: fields.billing_address?.city,
                state: fields.billing_address?.state,
                'postal_code': fields.billing_address?.zip,
                country: fields.billing_address?.country
            };
            const shippingAddress = {
                address: {
                    line1: fields.shipping?.address,
                    city: fields.shipping?.city,
                    state: fields.shipping?.state,
                    'postal_code': fields.shipping?.zip,
                    country: fields.shipping?.country
                },
                recipient_name: `${fields.first_name} ${fields.last_name}`,
                require_signature: false
            };
            const dateOfBirth = {
                day: fields.date_of_birth.format('D'),
                month: fields.date_of_birth.format('M'),
                year: fields.date_of_birth.format('YYYY'),
            };
            await requestStripeCard(accountId, {
                first_name: fields.first_name,
                last_name: fields.last_name,
                date_of_birth: dateOfBirth,
                billing_address: billingAddress,
                shipping: shippingAddress
            });
            await onConfirm();
            closeModal();
        } catch (err) {
            closeModal();
            dispatch(openModal('error', { message: errorHandler(err).join('\n') }));
        };
    }
  })
)(StripeCardForm);
