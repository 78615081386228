/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset } from 'redux-form';

import { send } from 'actions/settings/documents';
import { openModal } from 'actions/ui';
import PermissionsError from 'datatypes/PermissionsError';
import * as validate from 'helpers/validate';

import getSelf from 'selectors/getSelf';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PoolMultiSelect from 'components/pure/form/inputs/PoolMultiSelect';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import segmentEvents from '../../../helpers/segmentEvents';


const FORM_NAME = 'DocumentsSendForm';

const DocumentsSendForm = compose(
  connect(
    state => {
      const self = getSelf(state);
      return {
        data: self.data.files,
      };
    },
    {
      doDelete: item => openModal('confirm', {
        type: 'document_delete',
        args: [item.id, item.user_label],
      }),
    },
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    initialValues: {
      documents: [],
    },
    async onSubmit(data, dispatch) {
      try {
        await dispatch(send({
          recipient_email: data.email,
          ids: data.documents.map(doc => doc.id),
          user_message: data.message,
        }));
        window.analytics.track(segmentEvents.USER_SENT_DOCUMENTS);
        dispatch(openModal('success', { message: 'Documents sent successfully' }));
        dispatch(reset(FORM_NAME));
      }
      catch (err) {
        console.warn(err);
        if (err instanceof PermissionsError) {
          return dispatch(openModal('upgradeaccount', { message: 'You can only send one email per day as a free user. Please upgrade for full access.' }));
        }
        dispatch(openModal('error', { message: 'Error sending documents' }));
      }
    },
  }),
)(({ data = [], doDelete, handleSubmit, submitting }) =>
  <div>
    <Field
      name='email'
      component={LabeledInput}
      label='Send All Selected Documents To This Email Address?'
      validate={validate.compose(
        validate.vjs.presence(),
        validate.vjs.email(),
      )}
    />
    <div className='help-block'>Click or tap on documents you'd like to send.</div>
    <Field
      name='documents'
      component={PoolMultiSelect}
      validate={validate.compose(
        validate.numericality({
          tokenizer: value => value.length,
          minimum: 1,
          tooSmall: ({ minimum }) => `Must include at least ${minimum} document.`,
        }),
      )}
      onCloseClick={i => doDelete(data[i])}
      pool={data.map(doc => {
        if (doc.category === 'documents') {
          return {
            id: doc.id,
            label: doc.user_label,
          };
        }
        if (doc.category === 'documents_insurance') {
          return {
            id: doc.id,
            label: 'Insurance',
            onCloseClick: false,
          };
        }
        if (doc.category === 'documents_authority') {
          return {
            id: doc.id,
            label: 'FMCSA Authority',
            onCloseClick: false,
          };
        }
      })}
      label='Your Uploaded Documents'
      selectedLabel='Documents to Send'
    />
    <Field
      name='message'
      component={GrowableInput}
      validate={validate.compose(
        validate.numericality({
          tokenizer: value => value.length,
          maximum: 250,
          tooBig: ({ maximum }) => `Must be shorter than ${maximum} characters`,
        }),
      )}
      props={{
        Component: LabeledInput,
        ComponentClass: 'textarea',
        placeholder: 'Enter a short message to go along with your document (optional).',
      }}
    />
    <div className='text-right clearfix'>
      <button className='btn btn-orange' type='submit' disabled={submitting} onClick={handleSubmit}>SEND</button>
    </div>
  </div>
);

export default DocumentsSendForm;
