import React from 'react';
import { compose } from 'redux-v3';

import Resource from 'components/hoc/Resource';
import DebtorCardSmall from 'components/pure/DebtorCardSmall';


export default compose(
  Resource('factoringdebtor'),
)(({ item }) =>
  item && item.data ?
    <DebtorCardSmall debtor={item.data} />
    : null
);
