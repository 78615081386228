/**
 * Actions relating to the QA resource
 * @module actions/resource/qa
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/ControlledResource
 */
/*global API */
import moment from 'moment';

import APIFetch from '../../datatypes/APIFetch';
import ControlledResource from '../../datatypes/ControlledResource';


/**
 * The Resource that handles QA parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class QaResource extends ControlledResource {

  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return super.parse(oldValue, {
      ...json,
      time_posted: moment(json.time_posted),
      answer_set: json.answer_set.map(answer => ({
        ...answer,
        time_posted: moment(answer.time_posted),
      })),
    });
  }
}


/**
 * Singleton for our QaResource
 */
const qaResource = new QaResource('QA', {});

export default qaResource;


/**
 * QA create answer success action. Marks that an answer to a question has been created successfully
 * @event QA_CREATE_A_SUCCESS
 * @property {symbol} type - Symbol(QA_CREATE_A_SUCCESS)
 * @property {object} payload
 * @property {string} payload.id - the ID of the question being answered
 * @property {object} payload.json - the json object for the answer returned from the server
 */
export const QA_CREATE_A_SUCCESS = Symbol('QA_CREATE_A_SUCCESS');
/**
 * QA create answer success action
 * @param {string} id - the ID of the question being answered
 * @param {object} json - the json object for the answer returned from the server
 * @fires QA_CREATE_A_SUCCESS
 */
export function qaCreateASuccess(id, json) {
  return {
    type: QA_CREATE_A_SUCCESS,
    payload: { id, json },
  };
}
/**
 * Thunk to create an answer for a question
 * @param {string} resource - 'load' or 'truck', the type of resource that the question that is being answered belongs to
 * @param {string} resource_id - the ID of the load or truck whose question is being answered
 * @param {string} question_id - the ID of the question being answered
 * @param {object} data - the answer data
 * @param {string} data.answer - the answer
 * @fires QA_CREATE_A_SUCCESS
 */
export function createAnswer(resource, resource_id, question_id, data) {
  return (dispatch, getState) => {
    return dispatch(APIFetch(`${API.host}/${resource}/${resource_id}/answer/${question_id}/`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${getState().user.token}`,
        'Content-Type': 'application/json',
      },
    }))
      .then(res => res.status !== 201 ? res.json().then(json => Promise.reject(json)) : res.json())
      .then(json => dispatch(qaCreateASuccess(question_id, json)))
      ; // eslint-disable-line indent
  };
}
