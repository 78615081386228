import React from 'react';
import Header from 'components/pure/Header';

const FAQ = ({ children }) => <div style={{ paddingBottom: '1em' }}>{children}</div>;

FAQ.Q = ({ children }) =>
    <div>
        <span className='h4'>Q. </span>
        <span><em>{children}</em></span>
    </div>
    ;

FAQ.A = ({ children }) =>
    <div>
        <span className='h4'>A. </span>
        <span>{children}</span>
    </div>
    ;

const EmailLink = ({ email }) => <a href={`mailto:${email}`}>{` ${email} `}</a>
const Link = ({ link, children }) => <a href={link} target="_blank">{children}</a>

const invitedCarriersQA = [{
    q: 'How do I invite a driver/carrier to HaulPay for payment?',
    a: <span>You can invite a carrier or carriers to HaulPay via text message or email by logging into your HaulPay account, going to HaulPay on the left hand side, then My Carriers then click on the green “Add New” button. Watch this <a href="https://www.youtube.com/watch?v=A_owQeCtNBA&feature=youtu.be">video</a> for a quick tutorial on how to send a HaulPay invite. <span style={{ display: 'inline-block', marginTop: 5 }}>We also set up a custom URL for you that you can share to any carrier directly via email, text or even link to it from your website or other communication. This is actually the preferred method of getting a carrier boarded to HaulPay. If you do not have a static link for sharing to carriers for HaulPay yet please ask your rep to create one today.</span></span>
},
{
    q: 'How do I know if the carrier has been approved to use HaulPay?',
    a: 'You can check the status of your carrier invite by logging into your HaulPay account, navigate to “HaulPay” on the left bar menu and select “My Carriers.” On this page, you will see which carriers have been approved and which need review. You can only approve a funding request to schedule a payment to the carrier if the carrier has been approved.'
},
{
    q: 'The status for my invited carrier says “Needs Review.” What does that mean?',
    a: 'A carrier in a needs review status means we are missing some information. Our representatives will check it out and let you and your carrier know what else is needed. You can also upload the required information by clicking on their name from the My Carriers section, which will open up their profile info in a modal you can interact with.'
},
{
    q: 'The carrier/driver indicates not receiving the invite. What do I do?',
    a: 'Resend the invite by logging into your HaulPay account and make sure to tell them to check their JUNK or SPAM folder, in case it made its way there. If they still have not received it, please reach out to us to let us know and create the profile manually. You can manually create the carrier’s profile by sending the carrier your invitation link by email or text. Once the invitation has been sent out, you will see a big green button giving you the option to “Create a Payment Profile” on the “Invited Carriers” under the My Carrier menu option under HaulPay.'
},
{
    q: 'The carrier does not want to go through the HaulPay link. What do I do?',
    a: 'You can manually create the carrier’s profile by sending the carrier your invitation link by email or text. Once the invitation has been sent out, you will see a big green button giving you the option to “Create a Payment Profile” on the “Invited Carriers” under the My Carrier menu option under HaulPay.'
},
{
    q: 'My Carrier boarded with a Factoring Company but now they want to receive quick pay directly from me via HualPay:',
    a: <span>
        If we currently have the carrier’s factoring company on file for payment, we will need a letter of release from the factor in order to proceed with switching the carrier to direct payment at their preferred quick pay speed option. Please email
        <a href="mailto:support@haulpay.io">{' support@haulpay.io '}</a>
        or your account manager if your carrier wants to switch from factoring company to direct payment via a quick pay speed that you’re offering.
    </span>
},
{
    q: 'My carrier does not want to use HaulPay. What do I do?',
    a: <span>
        Let your carrier know this is the new way you will be issuing payment and will not be able to receive their funds if they chose not to create an account for HaulPay. If they still refuse, it is at your discretion whether or not you want to pay the carrier out of pocket.
        <span style={{ display: 'inline-block', marginTop: 5 }}>Ask your representative about enabling Non-Factored Bill-Out Only funding requests so that you can still use us for your invoicing and settlement from the debtor and record the invoice and so that you can pay a carrier off-platform if needed.</span>
    </span>
}]

const NOAInvoiceQA = [
    {
        q: 'Who do I contact for billing questions or concerns?:',
        a: <span>
            Invoices and documents are processed in the order they are received. If you have questions about an unpaid invoice, you can email <EmailLink email='invoice@haulpay.io' /> and if it’s about an invoice that has already been paid you can email  <EmailLink email='payments@haulpay.io' />. Please include the load number in the subject line for reference.
    </span>
    },
    {
        q: 'How do I access reports for funding or invoices?',
        a: 'Login to your account, navigate to “HaulPay” in the left bar menu and select “Manage Pay.” On the top right hand you will see the TRANSACTIONS then you will see options to download different types of reports. You will also have the option of specifying a date filter for the reports available.'
    },
    {
        q: 'What is an NOA?',
        a: "A NOA is a Notice of Assignment and it is a document that assigns the payments from your customer/debtor to us to settle invoices you’re factoring. Until this is verified by your customer via email or phone, we will not be able to disburse your funds or funds to the carrier. We do our best in contacting your customer twice a day as well as send emails with you CC'd on them for transparency. In order to avoid a payment delay due to the NOA not being verified by your customer, please use our new feature via the HaulPay website to send the NOA as soon as you know you will be working with the customer."
    },
    {
        q: 'How do I send an NOA?',
        a: [
            'The best way to send the NOA is via the HaulPay website. Login to your account, add the customer to your customer list by going to Credit Check, inputting the customer name. Once the customer appears in your search results, click on the orange button “+Add Customer & Review Credit”. You will then see a pop up asking if you would like to send the NOA to speed up payment. When you click “Confirm”, the NOA will be sent from our support email to your customer’s email address we have on file. You can also request a copy of your NOA and email it to your shipper/customer.',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
                Here’s a <Link link="https://www.youtube.com/watch?v=6o4f4Xjtjg8"> quick video </Link> on sending an NOA yourself from the Credit section.
        </span>,

        ]
    },
    {
        q: 'The funding request has been Approved, how do I make a change or add a document?',
        a: <span>If you need to make a change or add a document please contact your account manager and email the billing team at <EmailLink email="invoice@haulpay.io" />.
</span>
    },
    {
        q: 'How do I change the payment speed options and rates?',
        a: <span>
            You can change your payment speed options and rates by going to HaulPay and navigating to “My Carriers” on the left bar menu,
            and clicking on the blue “Defaults” button on the top right corner.
    Watch this <Link link="https://www.youtube.com/watch?v=5ntrk-3oZmo">video</Link> to learn more about how to change the payment speed options.
</span>
    },
    {
        q: 'Why is my funding request incomplete?',
        a: 'Please make sure you upload and label all documents uploaded as either a rate confirmation, Signed BOL, TONU, etc. If you do not label your documents properly, our system will read the funding request as “Incomplete.” Once you’ve added the required documents to the Funding Request you can move it to the status that best reflects next steps. If you move it to Pending for example, you can use this status for internal review. If you move it to Approved the Funding Request will be queued up for payments to be sent at the scheduled time based on the payment speed used on the Funding Request.'
    },
    {
        q: 'My loads are older than 30 days, can I still factor them?',
        a: 'We do not fund any requests that have been aging 30 days or longer. We can, however, invoice your load as a Non-Factored request, minus a $5 flat fee. Funds are sent to the bank account saved on file.'
    },
    {
        q: 'How do I request a WIRE for same day funding?',
        a: 'Please make sure we have your wire routing number in order to make this an option when you are creating a funding request. If your carrier would like a WIRE, we will need the wire routing number as well. Wire costs depend on the setting for your account.'
    },
    {
        q: 'What is the WIRE cutoff time?',
        a: 'We send out wires before 1:30 PM Pacific Time so as long as you are able to send your request with enough time, we can process your request as a wire.'
    },
]

const fuelAdvanceQA = [
    {
        q: 'How do I request a fuel advance?',
        a: 'After 60 days of consistent factoring, please contact your account manager for further details and assistance.'
    },
    {
        q: 'My customer does not show up when I run a credit check. What do I do?',
        a: ['When a customer does not show up on our credit check tool, it simply means we don’t have them in our system yet. Please click on the green “+ Add New Customer” button and input as much information as possible.',
            <span style={{ textDecoration: 'underline' }}>
                Please make sure to include a Tax ID number or EIN as well as a valid phone number and or email so our billing team can get in touch to make sure we have all the information we need to invoice them.
        </span>
        ]
    },
    {
        q: 'Why is this broker or shipper declined?',
        a: <span>
            We use three different credit sources in order to make an accurate assessment of the businesses ability to pay invoices. In the credit decision process, we are confirming the following: payment history, credit score and any reports filed against the company with regards to non-payment/fraud/payment delays. Credit checks are re-run every 3 months in order to confirm that credit decisions are based on the most recent payment and credit data. Certain debtors may have a higher discount rate accessed on the account due to the factors previously listed. If you feel that a credit decision was performed incorrectly or have questions regarding the rate accessed,
    please do not hesitate to reach out to our credit department via <EmailLink email="payments@haulpay.io" />
        </span>
    },
    {
        q: 'Why is this customer “Pending?”',
        a: 'Please make sure you add the customer to your customer list. The credit will not be triggered until you add the customer to your customer list. You can add the customer to your list by clicking on the “Add Customer” button. You can watch this video for clarification.'
    },
];

const generalQuestionQA = [
    {
        q: 'I am receiving an error message. What do I do?',
        a: 'Take a screenshot of the error message you are receiving and shoot us an email with the screenshot, a description of the error and what device you are using i.e. laptop, android cell, iphone cell, etc. Once we have this information, we can properly assess a solution to your issue.'
    },
    {
        q: 'How do I schedule another demo or tutorial?',
        a: <span>
            To schedule a demo or tutorial of HaulPay or the load board, reach out to your account manager, email <EmailLink email="support@haulpay.io" /> You can also give us a call 888-633-5558.
</span>
    },
    {
        q: 'Where can I get my w-2 or tax report?',
        a: <span>
            Please email <EmailLink email="support@haulpay.io" /> for any tax report requests.
</span>
    },
    {
        q: 'What are your operating hours and mailing address?',
        a: [
            'Our phone lines are open from 7AM until 5 PM Pacific Standard Time (PST), Monday through Friday. (888) 633-5558. ',
            <span style={{ display: 'inline-block', marginTop: 5 }}>
                You can send mail to:
    </span>,
            <p style={{ marginTop: 10, textAlign: 'center', verticalAlign: "middle", fontWeight: "bold" }}>
                Comfreight
            </p>,
            <p style={{ marginTop: 5, textAlign: 'center', verticalAlign: "middle" }} >
                65 Pine Ave STE 853
                Long Beach CA 90802
 </p>
        ]
    },
]

const BrokerFAQ = () =>
    <div>
        <Header>Common Help Questions</Header>
        <h4>Invited Carriers</h4>
        {
            invitedCarriersQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
        <h4>NOAs and Invoices:</h4>
        {
            NOAInvoiceQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
        <h4>Fuel Advances and Credit Checks</h4>
        {
            fuelAdvanceQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
        <h4>General Questions</h4>
        {
            generalQuestionQA.map(QA => <FAQ>
                <FAQ.Q>{QA.q}</FAQ.Q>
                <FAQ.A>{QA.a}</FAQ.A>
            </FAQ>)
        }
    </div>


export default BrokerFAQ;