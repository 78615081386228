import React from 'react';
import PropTypes from 'prop-types';

const ImageGrid = ({ maxHeight, children, ...rest }) =>
  <div
    {...rest}
    style={{
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    }}
    >
    {children.map((element, i) =>
      <div
        key={i}
        >
        {typeof element === 'string' ? <img src={element} maxHeight={maxHeight} /> : element}
      </div>
    )}
  </div>
  ;

ImageGrid.propTypes = {
  maxHeight: PropTypes.number.isRequired,
  children: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
  ])).isRequired,
};

export default ImageGrid;
