import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle } from 'recompose';

import { USER_TYPE } from '../../helpers';
import Timeline from '../../components/pure/Timeline';
import UserSearch from '../../components/container/UserSearch';
import FactoringClientLists from '../../components/container/FactoringClientLists';
import FactoringPaymentsAdmin from '../../components/container/FactoringPaymentsAdmin';
import getSelfType from '../../selectors/getSelfType';

export default compose(
  connect(
    state => {
      return {
        is_loading: state.user.loading,
        user_type: getSelfType(state),
      };
    }
  ),
  lifecycle({
    componentDidMount() {
      const { user_type, router: { push } } = this.props;
      if (user_type === USER_TYPE.FACTORING_REMOTE_COORDINATOR) {
        push('/admin/factoring/transaction/new');
      }
    },
    componentDidUpdate(prevProps) {
      const { user_type, router: { push } } = this.props;
      if (user_type !== prevProps.user_type) {
        if (user_type === USER_TYPE.FACTORING_REMOTE_COORDINATOR) {
          push('/admin/factoring/transaction/new');
        }
      }
    },
  })
)(({ is_loading, user_type, ...rest }) => {
  if (is_loading) {
    return null;
  }
  switch (user_type) {
    case USER_TYPE.ADMIN:
    case USER_TYPE.SUB_ADMIN:
    case USER_TYPE.FACTORING_ADMIN:
      return <UserSearch {...rest} />;
    case USER_TYPE.ALADDIN_ADMIN:
      return <FactoringClientLists {...rest} />;
    case USER_TYPE.FACTORING_SUB_ADMIN:
      return <FactoringClientLists {...rest} />;
    case USER_TYPE.FACTORING_REMOTE_ADMIN:
      return <FactoringPaymentsAdmin {...rest} />;
    case USER_TYPE.FACTORING_REMOTE_COORDINATOR:
      return null;
  }
  return <Timeline {...rest} />;
});
