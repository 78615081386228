/* global API */

export const placeNOA = ({
  client_id,
  debtor_id,
  attachment,
  funding_request,
}) => async (dispatch, getState) => {
  const fundingRequest = funding_request ? { funding_request } : {};
  const res = await global.fetch(`${API.host}/factoring/debtors/relationship/noa/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      factoring: client_id,
      debtor: debtor_id,
      attachment,
      funding_request
    }),
  });
  if (res.status !== 201) {
    throw res;
  }
};
