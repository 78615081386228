/**
 * Reducer for handling the trucksearch resource actions
 * @module reducers/resource/trucksearch
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/trucksearch
 * @requires actions/search/truck
 * @listens SEARCH_TRUCK_SET_VIEWED
 * @listens SEARCH_TRUCK_TOGGLE_OPEN
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.trucksearch;

import truckSearchResource, { SEARCH_TRUCK_SET_VIEWED, SEARCH_TRUCK_TOGGLE_OPEN } from '../../actions/resource/trucksearch';
import truckSearchPaginatedResource from '../../actions/search/truck';


export default function trucksearchResourceReducer(state = initialState, action) {
  switch (action.type) {
    case truckSearchPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...truckSearchResource.parseMany(state, action.payload.results),
      };
    case SEARCH_TRUCK_SET_VIEWED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            viewed: true,
          },
        },
      };
    case SEARCH_TRUCK_TOGGLE_OPEN:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            open: !state[action.payload].globals.open,
            viewed: true,
          },
        },
      };
  }
  return state;
}
