/**
 * Connected modal component that prompts a legacy user to either use the current v3 version or the legacy version of the app
 * @module components/pure/modals/LegacyPrompt
 * @since 3.0.0
 * @requires components/pure/modals/Success
 */
import React from 'react';
import { compose } from 'redux-v3';

import Modal from '../../hoc/Modal';
import GenericHeader from './GenericHeader';

export const LegacyPrompt = compose(
  Modal,
)(({ closeModal }) =>
  <div>
    <GenericHeader
      closeModal={closeModal}
      style={{
        backgroundColor: '#5cb85c',
        color: 'white',
      }}
      >
      Welcome to New HaulPay!
    </GenericHeader>
    <div className='modal-body'>
      We're giving you the option to continue using a version of the old interface, or you can start using the new HaulPay right now!
    </div>
    <div className='modal-footer'>
      <a href='//legacy.comfreight.com'><button className='btn btn-default'>Use old interface.</button></a>
      <button className='btn btn-orange' onClick={closeModal}>Use new interface.</button>
    </div>
  </div>
);


export default LegacyPrompt;
