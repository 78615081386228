/* global API */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import getSelf from 'components/hoc/getSelf';


// I'm sorry
let hasLoaded = false;
let admins = [];
export const noneSelect = {
  id: null,
  name: 'Everyone',
};

const getAdmins = () => async (dispatch, getState) => {
  if (hasLoaded) {
    return admins;
  }
  const res = await global.fetch(`${API.host}/admin/search/admin/`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  admins = [
    ...await res.json(),
    noneSelect,
  ];
  hasLoaded = true;
  return admins;
};

export default compose(
  getSelf,
  connect(
    state => ({
      self: getSelf(state),
    }),
    {
      getAdmins,
    },
  ),
)(class AdminDropdownInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasLoaded,
    };
  }

  componentDidMount() {
    if (!this.state.hasLoaded) {
      this.props.getAdmins(this.props.token)
        .then(() => {
          this.setState({ hasLoaded });
          if (this.props.selectSelfOnLoad) {
            const self = admins.find(({ id }) => this.props.self.data.id === id);
            if (self) {
              this.props.input.onChange(self);
            }
          }
        });
    }
  }

  render() {
    const {
      item,
      input,
      meta = {},
      label = 'Sales Rep',
      selectSelfOnLoad,
      self,
      getAdmins,
      ...rest
    } = this.props;
    const { hasLoaded } = this.state;

    const transformedInput = {
      ...input,
      value: input.value && input.value.id ? input.value : noneSelect,
    };

    return (
      <LabeledDropdown
        textField={val => val.name || val.id}
        meta={meta}
        input={transformedInput}
        label={label}
        data={admins}
        {...rest}
        style={{
          minWidth: 200,
          ...rest.style,
        }}
      />
    );
  }
});
