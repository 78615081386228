/* global MOBILE_BREAKPOINT */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';
import omit from 'lodash/omit';
import shallowEqual from 'recompose/shallowEqual';
import wrapDisplayName from 'recompose/wrapDisplayName';

const DEFAULT_OPTIONS = {
  breakpoint: MOBILE_BREAKPOINT,
};

export default function Responsive(options) {
  options = { ...DEFAULT_OPTIONS, ...options };

  return WrappedComponent => {
    class Responsive extends Component {

      shouldComponentUpdate(nextProps) {
        const { props } = this;
        const { breakpoint } = options;
        const isMobile = props.containerWidth < breakpoint;
        const nextIsMobile = nextProps.containerWidth < breakpoint;
        return (
          !shallowEqual(omit(props, ['containerHeight', 'containerWidth']), omit(nextProps, ['containerHeight', 'containerWidth'])) ||
          isMobile !== nextIsMobile
        );
      }

      static displayName = wrapDisplayName(WrappedComponent, 'Responsive');

      static propTypes = {
        containerHeight: PropTypes.number.isRequired,
        containerWidth: PropTypes.number.isRequired,
        updateDimensions: PropTypes.func.isRequired,
      };

      render() {
        const { containerWidth, containerHeight, updateDimensions, ...ownProps } = this.props;
        const isMobile = containerWidth < options.breakpoint;

        return React.createElement(WrappedComponent, {
          isMobile,
          ...ownProps,
        });
      }
    }

    return Dimensions({
      elementResize: true,
      ...options.dimensionsProps,
    })(Responsive);
  };
}
