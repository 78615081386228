import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringpayment.factoringpaymentnote;

import factoringpaymentnoteByFactoringpaymentResource from 'actions/resourceBy/factoringpayment/factoringpaymentnote';


export default function factoringpaymentnoteByFactoringpaymentResourceReducer(state = initialState, action) {
  state = factoringpaymentnoteByFactoringpaymentResource.reduce(state, action);
  return state;
}
