import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset } from 'redux-form';
import { closeModal, openModal } from 'actions/ui';
import bankinfoPaginatedResource from 'actions/admin/factoring/bank';
import { deletePaymentMethod } from 'actions/paymentmethods';
import lifecycle from 'recompose/lifecycle';
import Header from 'components/pure/Header';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import { values } from 'lodash';
import { edit } from 'actions/resource/defaultbank';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';

const formatAccountBank = account => `xxxx - ${account}`;
const formatAccountDebit = account => `xxxx - xxxx - xxxx - ${account}`;

const TableComponents = createTableItems(
  ({ data, deletePaymentMethod }) => {
    return [
      data.type === 'DEBIT' ? formatAccountDebit(data.account_number_redacted) : formatAccountBank(data.account_number_redacted),
      <Field
        name={`${data.type === 'DEBIT' ? 'debit' : 'credit'}_account_id`}
        component='input'
        type='radio'
        value={data.id}
      />,
      data.type,
      <button
        className='btn btn-danger'
        type='button'
        onClick={() => deletePaymentMethod(data.user, data.id)}
        >
        Delete
      </button>,
    ];
  },
);

const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-blue'>{title}</h4>
);

const Button = ({ children, onClick }) => (
  <button className='btn btn-default' type='button' onClick={onClick}>{children}</button>
);

export default compose(
  connect(
    state => ({
      ids: state.admin.factoring.bank.ids,
      isFetching: state.admin.factoring.bank.isFetching,
      banks: state.resource.getbankinfo,
      currentOrder: state.admin.factoring.bank.ordering,
      initialValues: (() => {
        const defaultBank = values(state.resource.getbankinfo).filter(bank => bank.data.default === true);
        const initial_values = {};

        defaultBank.map(bank => {
          if (bank.data.type === 'DEBIT') {
            initial_values.debit_account_id = bank.data.id;
          }
          else {
            initial_values.credit_account_id = bank.data.id;
          }
        });

        return initial_values;
      })(),
    }),
    (dispatch, { item }) => ({
      closeModal() {
        dispatch(closeModal());
      },
      update() {
        dispatch(bankinfoPaginatedResource.clear());
        dispatch(bankinfoPaginatedResource.fetch({
          id: item.data.user,
          method: 'ACH',
        }));
        dispatch(bankinfoPaginatedResource.fetch({
          id: item.data.user,
          method: 'DEBIT',
        }));
      },
      fetchNext: (...args) => dispatch(bankinfoPaginatedResource.fetchNext(...args)),
      sort: (...args) => dispatch(bankinfoPaginatedResource.sort(...args)),
      deletePaymentMethod: (...args) => dispatch(deletePaymentMethod(...args)),
    }),
  ),
  reduxForm({
    form: 'PaymentMethods',
    enableReinitialize: true,
    async onSubmit(fields, dispatch, props) {
      const data = {
        default: true,
      };
      try {
        if (fields.debit_account_id) {
          await dispatch(edit(data, props.item.data.user, fields.debit_account_id));
        }
        if (fields.credit_account_id) {
          await dispatch(edit(data, props.item.data.user, fields.credit_account_id));
        }
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Succesfully set default account.' }));
        reset();
      }
      catch (err) {
        dispatch(openModal('error', { message: `${err.message.detail}.` }));
        dispatch(closeModal());
        reset();
      }
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
  }),
)(({ banks, ids, handleSubmit, closeModal, isFetching, fetchNext, currentOrder, sort, submitting, deletePaymentMethod, update }) => {
  return (
    <ModalBody closeModal={closeModal}>
      <ModalTitle title='Payment Information' />
      <Header>Select a Default Account</Header>
      <hr />
      <form onSubmit={handleSubmit}>
        <ModalBody>
          <ResponsiveTable
            containerProps={{
              className: 'col-fill',
            }}
            isFetching={isFetching}
            fetchNext={fetchNext}
            sortable={true}
            currentOrder={currentOrder}
            sort={sort}
            headers={[
              { text: 'Account # (Last 4 digits)', ordering: false },
              { text: 'Default', ordering: false },
              { text: 'Type', ordering: false },
              { text: 'Actions', ordering: false },
            ]}
            placeholder='No results'
            TableItem={TableComponents.TableItem}
            BlockTableItem={TableComponents.BlockTableItem}
            items={ids.map(id => {
              const bank = banks[id];
              bank.deletePaymentMethod = async (userid, id) => {
                await deletePaymentMethod(userid, id);
                await update();
              };
              return banks[id];
            })}
          />
        </ModalBody>
        <ModalFooter>
          <Button onClick={closeModal}>
            Cancel
          </Button>
          <BigOrangeSubmitButton submitting={submitting} message='Set As Default' />
        </ModalFooter>
      </form>
    </ModalBody>
  );
});
