/**
 * Reducer for handling the loadsearch resource actions
 * @module reducers/resource/loadsearch
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/loadsearch
 * @requires actions/search/load
 * @requires actions/search/loadmap
 * @listens SEARCH_LOAD_SET_VIEWED
 * @listens SEARCH_LOAD_TOGGLE_OPEN
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.loadsearch;

import loadSearchResource, { SEARCH_LOAD_SET_VIEWED, SEARCH_LOAD_TOGGLE_OPEN } from '../../actions/resource/loadsearch';
import loadSearchPaginatedResource from '../../actions/search/load';
import loadMapPaginatedResource from '../../actions/search/loadmap';


export default function loadsearchResourceReducer(state = initialState, action) {
  switch (action.type) {
    case loadSearchPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...loadSearchResource.parseMany(state, action.payload.results),
      };
    case loadMapPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...loadSearchResource.parseMany(state, action.payload.results),
      };
    case SEARCH_LOAD_SET_VIEWED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            viewed: true,
          },
        },
      };
    case SEARCH_LOAD_TOGGLE_OPEN:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            open: !state[action.payload].globals.open,
            viewed: true,
          },
        },
      };
  }
  return state;
}
