/* global API */
import querystring from 'querystring';

/**
 * @typedef FactoringDebtorSearchResponse
 * @param {object} data
 * @param {string} data.next
 * @param {number} data.limit
 * @param {number} data.offset
 * @param {FactoringDebtorSearchResponseDebtor[]} data.results
 */

/**
 * @typedef FactoringDebtorSearchResponseDebtor
 * @param {object} data
 * @param {string} data.dot
 * @param {string} data.id
 */

export const searchFactoringCarriers = ({
  factoring_client_id,
  carrier_dba,
  status,
  tax_id,
  mc,
  dot,
  phone,
  email,
  limit = 20,
  offset,
} = {}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/profiles/connected/?${querystring.stringify({
    factoring_client_id,
    carrier_dba,
    status,
    mc,
    dot,
    tax_id,
    phone,
    email,
    limit,
    offset,
  })}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const getFactoringCarrier = id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/profiles/connected/${id}/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const updateFactoringCarrier = (id, body) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/profiles/connected/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(body),
  });
  if (res.status >= 300) {
    throw new Error('Error updating the factoring profile');
  }
  const json = await res.json();
  return json;
};

/**
 * Function to get a full debtor DBA and ID given the first few characters of said DBA
 * @param {bool} connected
 * @param {string} dba
 * @param {string} mc
 * @param {string} dot
 * @param {string} tax_id
 * @param {string} client_id
 * @param {string | number} [limit = 5]
 * @returns {FactoringDebtorSearchResponseDebtor[]}
 */
export const searchFactoringDebtors = ({
  connected = false,
  dba,
  mc,
  dot,
  tax_id,
  client_id,
  limit = 20,
  no_debtor,
  filter = {}
}) => async (dispatch, getState) => {
  const connectedDebtor = connected ? 'relationship/' : '';
  const res = await global.fetch(`${API.host}/factoring/debtors/${connectedDebtor}?${querystring.stringify({
    dba: connected ? '' : dba,
    mc,
    dot,
    tax_id,
    factoring: client_id,
    limit,
    company_name: connected ? dba : '',
    ...filter,
  })}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return [
    ...no_debtor ? [{
      dba: 'No debtor',
      id: 'debdebde-debd-debd-debd-111111111111',
      company_name: "N/A",
      mc: "N/A"
    }] : [],
    ...json.results
  ];
};

/**
 * @param {string} credit_approved
 * @param {string} id
 */
export const setFactoringDebtorStatus = ({
  credit_approved,
  id,
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/debtors/add/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      id,
      credit_approved,
    }),
  });
  if (!res.ok) {
    throw res;
  }
};

export const fetchFactoringPaymentSum = range => async (dispatch, getState) => {
  let query = '';
  if (range) {
    query = querystring.stringify({
      datetime_start: range.start.format(),
      datetime_end: range.end.format(),
    });
  }
  const res = await global.fetch(`${API.host}/factoring/funding/request/calculations/?${query}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const fetchPaymentHistoryCSV = ({ start, end, debtor, client, status }) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/funding/request/reports/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      datetime_start: start,
      datetime_end: end,
      debtor_id: debtor,
      factoring_id: client,
      status,
    }),
  });
  const json = await res.json();
  if (res.status >= 300) {
    throw new Error(json);
  }
  return json;
};

export const fetchBrokerPaymentHistoryCSV = (factoringId, reportValue, reportDateRange = {}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/reports/broker/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      report_type: reportValue,
      factoring_id: factoringId,
      ...reportDateRange,
    }),
  });
  const json = await res.json();
  if (res.status >= 300) {
    throw json;
  }
  return json;
};

export const getCadenceAladdin = ({ status }) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/export_cadence/?${querystring.stringify({ status })}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const json = await res.json();
  return json;
};

export const FETCH_TERMS_REQUEST = 'FETCH_TERMS_REQUEST';
export const FETCH_TERMS_SUCCESS = 'FETCH_TERMS_SUCCESS';
export const FETCH_TERMS_FAILURE = 'FETCH_TERMS_FAILURE';

export const fetchTerms = factoringId => async (dispatch, getState) => {
  dispatch({
    type: FETCH_TERMS_REQUEST,
    payload: undefined,
  });
  try {
    const res = await global.fetch(`${API.host}/factoring/${factoringId}/terms/`, {
      method: 'GET',
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json',
      },
    });
    const json = await res.json();
    dispatch({
      type: FETCH_TERMS_SUCCESS,
      payload: json,
    });
    return json;
  }
  catch (e) {
    dispatch({
      type: FETCH_TERMS_FAILURE,
      payload: undefined,
    });
  }
};

export const updateTerms = (factoringId, termsId, data) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/${factoringId}/terms/${termsId}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  return json;
};

export const createPurchase = (fundingRequestId, data) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${fundingRequestId}/purchases/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  return json;
};

export const fetchPaymentTerms = (factoringId, carrier = false) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/${factoringId}/payment_terms/`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const json = await res.json();
  if (!carrier) {
    dispatch({
      type: FETCH_TERMS_SUCCESS,
      payload: json,
    });
  }
  return json;
};

export const sendInvoice = fundingRequestId => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${fundingRequestId}/send_invoice/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const json = await res.json();
  if (res.status >= 300) {
    throw json;
  }
  return json;
};

export const fundingRequestCalculations = data => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/calculations/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  return json;
};

export const getDebtorById = deborId => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/debtor/${deborId}/`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const json = await res.json();
  return json;
};

export const requestCreditIncrease = data => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/debtors/relationship/${data.id}/credit_increase/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  return json;
};

export const updateRelationship = data => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/factoring/debtors/relationship/${data.id}/retrieve/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!res.ok) {
    const json = await res.json();
    throw json
  };
  const json = await res.json();
  return json;
};

export const createPaymentProfileInvitedUser = data => async (dispatch, getState) => {
  const response = await global.fetch(`${API.host}/factoring/payment_profiles/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  return res;
};

export const createBrokerCarrierConnection = data => async (dispatch, getState) => {
  const response = await global.fetch(`${API.host}/factoring/${data.factoring_id}/payment_profiles/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const res = await response.json();
  return res;
};

export const PurchaseOrderNumberRelatedInvoices = (purchaseOrderNumber, fundingRequestId = '') => async (dispatch, getState) => {
  const response = await global.fetch(`${API.host}/factoring/purchase_order_number/check/?${querystring.stringify({ purchase_order_number: purchaseOrderNumber, funding_request_id: fundingRequestId })}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const res = await response.json();
  return res;
};