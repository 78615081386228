/**
 * Reducer for handling the load resource actions
 * @module reducers/resource/load
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/haulPayLoad
 * @requires actions/resourceBy/user/haulPayLoad
 */
import INITIAL_STATE from '../../INITIAL_STATE';
import haulPayLoadResource from 'actions/resource/haulPayLoad';


const initialState = INITIAL_STATE.resource.haulPayLoad;

export default function haulPayLoadResourceReducer(state = initialState, action) {
  state = haulPayLoadResource.reduce(state, action);
  return state;
}
