import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoringtransactionpurchases;

import factoringtransactionpurchasesPaginatedResource from 'actions/admin/factoringtransactionpurchases';


export default function financespurchasesPaginatedReducer(state = initialState, action) {
  state = factoringtransactionpurchasesPaginatedResource.reduce(state, action);
  return state;
}
