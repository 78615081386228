/**
 * Component that displays the factoring debtor search form
 * @module components/pure/form/FactoringDebtorSearchForm
 * @since 3.0.1
 * @requires actions/ui
 * @requires actions/search/user
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/LabeledDropdown
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import { openModal } from 'actions/ui';
import { clear, fetch } from 'actions/search/factoringdebtor';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';


export default compose(
  reduxForm({
    form: 'DebtorSearch',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch) {
      try {
        dispatch(clear());
        await dispatch(fetch({
          email: fields.email,
          contact_email: fields.contact_email,
          company_name: fields.company_name,
          phone_number: fields.phone_number,
          dot: fields.dot,
          mc: fields.mc,
          tax_id: fields.tax_id,
          address: fields.address,
          exclude_client_relation: fields.exclude_client_relation,
          exclued_debtor_relation: fields.exclued_debtor_relation,
        }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to search.' }));
      }
    },
    validate: fields => validate(fields, {
    }),
  }),
)(({ handleSubmit, submitting, reset }) =>
  <form onSubmit={handleSubmit}>
    <Field name='email' component={LabeledInput} label='Email' />
    <Field name='contact_email' component={LabeledInput} label='Contact Email (PE Only)' />
    <Field name='company_name' component={LabeledInput} label='Business Name' />
    <Field name='phone_number' component={PhoneInput} label='Phone' />
    <Field name='contact_name' component={LabeledInput} label='Contact Name' />
    <Field name='mc' component={LabeledInput} label='MC #' type='number' />
    <Field name='dot' component={LabeledInput} label='DOT #' type='number' />
    <Field name='tax_id' component={LabeledInput} label='Tax ID #' type='number' />
    <Field name='address' component={LabeledInput} label='Address' />
    <div className='text-right'>
      <button className='btn btn-default pull-left' type='button' onClick={reset}>Reset</button>
      <button className='btn btn-orange' disabled={submitting} type='submit'>SEARCH</button>
    </div>
  </form>
);
