import React, { createContext, useState, useCallback } from 'react';
import { stripeWalletPendingTransactions, ledgerWalletPendingTransactions } from 'actions/ledger';
import axios from 'axios';

const WalletContext = createContext();

export const ContextProvider = ({ children }) => {
    const [walletPendingTransaction, setWalletPendingTransaction] = useState([]);
    const [brokerWalletPendingTransaction, setBrokerWalletPendingTransaction] = useState([]);
    const [reload, setReload] = useState(false);
    const [nextPage, setNextPage] = useState("");
    const setReloadPage = () => {
        setReload(prevReload => !prevReload);
    }

    const fetchWalletPendingTransaction = async () => {
        try {
            const { results, next } = await stripeWalletPendingTransactions();
            setWalletPendingTransaction(results);
            setNextPage(next)
        } catch (error) {
            throw error;
        }
    };
    const fetchBrokerWalletPendingTransaction = async () => {
        try {
            const { results, next } = await ledgerWalletPendingTransactions();
            setBrokerWalletPendingTransaction(results);
            setNextPage(next)
        } catch (error) {
            throw error;
        }
    };

    const fetchNextBrokerWalletPendingTransaction = useCallback(async() => {
        try {
            const res = await axios(nextPage);
            const { results, next } = res.data;
            setBrokerWalletPendingTransaction(brokerWalletPendingTransaction.concat(results));
            setNextPage(next);
        } catch (error) {
            throw error;
        }
    }, [nextPage])


    const fetchNextWalletPendingTransaction = useCallback(async() => {
        try {
            const res = await axios(nextPage);
            const { results, next } = res.data;
            setBrokerWalletPendingTransaction(walletPendingTransaction.concat(results));
            setNextPage(next);
        } catch (error) {
            throw error;
        }
        }, [nextPage])

    return (
        <WalletContext.Provider value={{
            walletPendingTransaction,
            fetchWalletPendingTransaction,
            fetchBrokerWalletPendingTransaction,
            brokerWalletPendingTransaction,
            reload,
            setReloadPage,
            fetchNextWalletPendingTransaction,
            fetchNextBrokerWalletPendingTransaction,
            nextPage,
            }}>
            {children}
        </WalletContext.Provider>
    );
};

export default WalletContext;
