import React, { useCallback, useState } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { push } from 'react-router-redux';
import get from 'lodash/get';
import { Col, Row } from 'antd';
import {
    Button,
    Header,
    Input,
    Select,
    DateRangePicker,
    Table,
    CollapsedFilterPanel,
} from '@haulpay/ui';
import {
    FileExcelOutlined,
    SearchOutlined,
    CloseOutlined,
} from '@ant-design/icons';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import ResourceBy from 'components/hoc/ResourceBy';
import nothingIf from 'components/hoc/nothingIf';
import isDispatchBroker from 'selectors/isDispatchBroker';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import { edit } from 'actions/resource/factoringpayment';
import { fetchFactoringProfile } from 'actions/user';
import { fetchBrokerPaymentHistoryCSV } from 'actions/factoring';
import { openModal } from 'actions/ui';
import formatError from 'helpers/formatError';
import columns, { checkStatus } from './columns';
import USER_FACTORING_STATUS from '../../../helpers/USER_FACTORING_STATUS';

const statuses = (isDispatchBroker, filter) => [
    {
        title: 'Incomplete',
        text: 'Incomplete',
        value: isDispatchBroker ? 'incomplete' : 'pending_delivery',
    },
    {
        title: 'Pending',
        text: 'Pending',
        value: 'special_pending',
    },
    {
        title: 'Declined',
        text: 'Declined',
        value: 'declined',
    },
    {
        title: 'Document Issue',
        text: 'Document Issue',
        value: 'document_issue',
    },
    {
        title: 'Approved',
        text: 'Approved',
        value: 'remote_approved',
    },
    ...(() => !filter ? [] : [
    {
        title: 'Non Factored',
        text: 'Non Factored',
        value: 'non_factored',
    },
    {
        title: 'Fuel Advance',
        text: 'Fuel Advance',
        value: 'fuel_advance',
    },
    {
        title: 'Carrier Paid',
        text: 'Carrier Paid',
        value: 'carrier_paid',
    },
    {
        title: 'Invoice Paid',
        text: 'Invoice Paid',
        value: 'invoice_paid',
    }])(),
];

const brokerTransactionData = (allowPendingReserveAccess) => [
    {
        title: 'Funding Report',
        value: 'funding_report',
    },
    {
        title: 'Carrier Funding Report',
        value: 'carrier_funding_report',
    },
    ...(() =>
        allowPendingReserveAccess
            ? [
                  {
                      value: 'pending_reserve_report',
                      title: 'Pending Chargeback/Giveback Report',
                  },
              ]
            : [])(),
    {
        title: 'Broker Funding Report',
        value: 'broker_funding_report',
    },
    {
        title: 'AR Report',
        value: 'ar_report',
    },
    {
        title: 'Open AR Report',
        value: 'open_ar_report',
    },
    {
        title: 'Debtor Aging',
        value: 'debtor_aging_by_bucket',
    },
    {
        title: 'Invoice Settlement Report',
        value: 'settlement_report',
    },
    {
        title: 'Chargeback / Giveback Report',
        value: 'chargeback_giveback_report',
    },
    {
        title: 'Self-Finance Funding Report',
        value: 'self_finance_report',
    }
];

const statusMapping = {
    pending_delivery: 'Pending Delivery',
    incomplete: 'Incomplete',
    special_pending: 'Special Pending',
    declined: 'Declined',
    document_issue: 'Document Issue',
    remote_approved: 'Remote Approved',
}

function BrokerTransaction(props) {
    const { items, item, actions, factoringId, fetchBrokerPaymentHistoryCSV, edit, openModal, push } =
        props;
    const [filters, setFilters] = useState(props.filter);
    const [search, setSearch] = useState(false);
    const [reportValue, setReportValue] = useState('funding_report');
    const [reportDateRange, setReportDateRange] = useState([]);
    const [searchFilter, setFilterSearch] = useState('');
    const [statusFilter, setStatusFilter] = useState('special_pending');
    const [statusAction, setStatusAction] = useState('special_pending');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [updateStatusLoading, setUpdateStatusLoading] = useState(false);
    const clearAndFetch = useCallback((...args) => {
        actions.clear();
        actions.fetch(...args);
    }, []);
    const clearAndFetchNext = useCallback(() => {
        actions.fetchNext();
    }, []);
    const handleSetStatus = useCallback(async () => {
        try {
            setUpdateStatusLoading(true);
            if (selectedRowKeys.length > 0) {
                for await (let id of selectedRowKeys) {
                    await edit(id, { status: statusAction }, { bulk: true });
                }
                openModal('success', {
                    message: `Successfully set status to ${USER_FACTORING_STATUS[statusMapping(statusAction)]}` });
            }
        }
        catch (e) {
            openModal('error', { message: formatError(e, 'Unable to Submit Status Change') });
        }
        finally {
            setUpdateStatusLoading(false);
        }
    }, [selectedRowKeys, statusAction]);
    const onSelectRowKeysChange = useCallback(
        (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
        [selectedRowKeys]
    );
    const handleStatusFilter = useCallback((status) => {
        setFilters({
            ...props.filters,
            status,
        });
    }, []);
    const handleSearchByFilter = useCallback((e) => {
        setFilters({
            ...filters,
            load_or_invoice_or_debtor_or_status: e.currentTarget.value,
        });
    }, []);
    const handleResetFilter = useCallback(() => {
        setFilters({
            assigned_load: true,
        });
        setFilterSearch('');
        setStatusFilter('special_pending');
        actions.clear();
        actions.fetch({
            assigned_load: true,
        });
        setSearch(false);
    }, []);
    const handleSubmitFilter = useCallback(() => {
        setSearch(true);
        actions.clear();
        actions.fetch(filters);
    }, [filters]);
    const handleReportDownload = useCallback(
        async (e) => {
            e.preventDefault();
            e.stopPropagation();
            try {
                const { download_url } = await fetchBrokerPaymentHistoryCSV(
                    factoringId,
                    reportValue,
                    reportDateRange
                );
                window.open(download_url);
            } catch (err) {
                console.warn(err);
                if (
                    get(err, 'funding_requests', undefined) ===
                    'No funding requests found with the filter options. Please adjust the filters'
                ) {
                    return openModal('error', {
                        message: 'No Data Available',
                    });
                }
                openModal('error', {
                    message: 'Failed to fetch CSV data :(',
                });
            }
        },
        [reportDateRange, reportValue]
    );

    return (
        <div style={{ margin: 25 }}>
            <Row>
                <Col span={24}>
                    <Header size="xxlarge" weight="semibold">
                        Transactions
                    </Header>
                </Col>
                <Col span={12}>
                    <Col span={12}>
                        <Row align="middle" style={{ minWidth: 300 }}>
                            <Button
                                icon={
                                    <FileExcelOutlined
                                        style={{ fontSize: '30px' }}
                                    />
                                }
                                type="link"
                                onClick={handleReportDownload}
                                style={{ minWidth: '40px' }}
                            />
                            <Select
                                defaultValue={reportValue}
                                style={{ minWidth: 240 }}
                                optionProps={brokerTransactionData(
                                    props.allowPendingReserveAccess
                                )}
                                onChange={(e) => setReportValue(e)}
                            />
                        </Row>
                    </Col>
                    <Col span={12}>
                        <div style={{ minWidth: 300, paddingTop: 5 }}>
                            <DateRangePicker
                                style={{ minWidth: 280 }}
                                button={{
                                    text: 'Filter',
                                }}
                                onChange={(dates) => setReportDateRange(dates)}
                            />
                        </div>
                    </Col>
                </Col>
                <Col span={12}>
                    <Row justify="end" gutter={[8, 8]}>
                        <Col span={24}>
                            <Row justify="end">
                                <Button style={{ minWidth: '150px' }} onClick={() => push({ pathname: '/postload/all-loads', state: { postLoad: true } })}>
                                    Create Load
                                </Button>
                            </Row>
                        </Col>
                        <Col span={24}>
                            <Row justify="end" gutter={[8, 8]}>
                                <Col>
                                    <Select
                                        defaultValue="special_pending"
                                        style={{ minWidth: 220 }}
                                        optionProps={statuses(
                                            props.isDispatchBroker
                                        )}
                                        onChange={(e) => setStatusAction(e)}
                                    />
                                </Col>
                                <Col>
                                    <Button style={{ minWidth: '150px' }} onClick={handleSetStatus} loading={updateStatusLoading}>
                                        Set Status
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ paddingTop: 20 }}>
                <Col span={24}>
                    <CollapsedFilterPanel>
                        <Row justify="end" gutter={[8, 8]}>
                            <Col>
                                <Select
                                    defaultValue={statusFilter}
                                    style={{ minWidth: 220 }}
                                    optionProps={statuses(
                                        props.isDispatchBroker,
                                        true
                                    )}
                                    onChange={handleStatusFilter}
                                />
                            </Col>
                            <Col>
                                <Input
                                    defaultValue={searchFilter}
                                    allowClear
                                    placeholder="Search by load number or invoice number"
                                    onChange={handleSearchByFilter}
                                />
                            </Col>
                            <Col>
                                <Button
                                    shape="circle"
                                    icon={
                                        !search ? (
                                            <SearchOutlined />
                                        ) : (
                                            <CloseOutlined />
                                        )
                                    }
                                    onClick={
                                        !search
                                            ? handleSubmitFilter
                                            : handleResetFilter
                                    }
                                    loading={item.isFetching && search}
                                />
                            </Col>
                        </Row>
                    </CollapsedFilterPanel>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        bordered
                        striped
                        loading={item.isFetching}
                        size="small"
                        columns={columns(statuses(props.isDispatchBroker, true))}
                        dataSource={items}
                        infinityScroll={true}
                        rowKey={({ data }) => data.id}
                        filters={props.filters}
                        fetch={clearAndFetch}
                        rowSelection={{
                            selectedRowKeys,
                            onChange: onSelectRowKeysChange,
                        }}
                        fetchNext={clearAndFetchNext}
                        next={item.next}
                    />
                </Col>
            </Row>
        </div>
    );
}

export default compose(
    connect(
        (state) => ({
            isDispatchBroker: isDispatchBroker(state),
            id: state.user.id,
            factoringId: get(state, [
                'resource',
                'user',
                state.user.id,
                'data',
                'factoring_id',
            ]),
            allowPendingReserveAccess: get(state, [
                'user',
                'factoring',
                'data',
                'allow_pending_reserve_access',
            ]),
        }),
        {
            fetchFactoringProfile,
            fetchBrokerPaymentHistoryCSV,
            openModal,
            edit,
            push
        }
    ),
    mapProps(
        ({
            id,
            debtorID,
            path = null,
            setSelectedItems,
            isHistoryView,
            filters = { assigned_load: true, limit: 30 },
            isSearch,
            ...rest
        }) => ({
            id,
            fetchArgs: {
                debtor_id: debtorID,
                ...filters,
                status: USER_FACTORING_STATUS.SPECIAL_PENDING,
                load_or_invoice_or_debtor_or_status: '',
            },
            setSelectedItems,
            filters,
            ...rest,
        })
    ),
    ResourceBy('user', 'factoringpayment', {
        shouldClearBeforeFetch: true,
        abortRequest: true,
    }),
    nothingIf(({ item }) => !item),
    ResourceByIDs({
        resource: 'factoringpayment',
        shouldUpdate: (props, nextProps) => {
            return props.item.isFetching !== nextProps.item.isFetching;
        },
    }),
    lifecycle({
        componentDidMount() {
            this.props.fetchFactoringProfile();
        },
    })
)(BrokerTransaction);
