import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const LineItemHorizontal = ({ label, children, ...rest }) =>
  <div
    {...rest}
    className={cs('line-item-horizontal', rest.className)}
    >
    <div className='line-item-horizontal-label'>
      {label}
    </div>
    <div className='line-item-horizontal-children'>
      {children}
    </div>
    <style jsx>{`
      .line-item-horizontal {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
      }
      .line-item-horizontal-label {
        text-align: center;center;
        padding-right: 0.5em;
        width: 100px;
        flex-grow: 0;
        flex-shrink: 0;
      }
      .line-item-horizontal-children {
        flex-grow: 1;
        padding-left: 0.5em;
        word-break: break-word;
      }
    `}</style>
  </div>
  ;

LineItemHorizontal.propTypes = {
  label: PropTypes.node,
  children: PropTypes.node,
};

export default LineItemHorizontal;
