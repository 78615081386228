import React from 'react';
import { reduxForm } from 'redux-form';

const Row = ({ children }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
      marginBottom: 15,
    }}
    >
    { children }
  </div>
);

const PaymentOption = ({ name, time, onClick, submitting }) => (
  <div>
    <Row>
      <div>
        <span className='text-success'>{name}</span>
      </div>
      <div className='text-right'>
        <span>{time}</span>
      </div>
    </Row>
    <Row>
      <button className='btn btn-orange pull-right' type='submit' disabled={submitting} onClick={onClick}>Choose this method</button>
    </Row>
  </div>
);

const PaymentInformationForm = ({
  item,
  submitting,
  handleSubmit,
  openAddCardInfoModal,
  openAddBankInfoModal,
  openAddWireInfoModal,
}) =>
  <div>
    <form onSubmit={handleSubmit}>
      <PaymentOption
        name='Debit/Credit'
        time='Instant ✔'
        onClick={() => openAddCardInfoModal(item)}
        submitting={submitting}
        fields={item}
      />
      <PaymentOption
        name='Bank Information'
        time='8 hours ⏱'
        onClick={() => openAddBankInfoModal(item)}
        submitting={submitting}
        fields={item}
      />
      <PaymentOption
        name='Wire Information'
        time='12PM CT ⏱'
        onClick={() => openAddWireInfoModal(item)}
        submitting={submitting}
        fields={item}
      />
    </form>
  </div>;

export default reduxForm({
  form: 'PaymentInformation',
  destroyOnUnmount: false,
})(PaymentInformationForm);
