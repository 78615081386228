import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { mapProps } from 'recompose';
import cs from "classnames";

import Autocomplete from 'components/pure/Autocomplete';
import LabeledInput from './LabeledInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import { get } from 'lodash';
import getSelf from 'selectors/getSelf';
import { fetchFactorCompany } from '../../../../actions/groups';
import formatAddress from '../../../../helpers/formatAddress';

const FactorCompanyCardSmall = ({ carrier, icon, ...rest }) => {
  const status = get(carrier, 'status', "");
  return (
    <div {...rest} >
      {icon}
      <div className='pac-item-query' style={{ display: 'inline-block', paddingLeft: 6, width: 'calc(100% - 22px)', lineHeight: 'normal', marginTop: 3, whiteSpace: 'normal' }}>
        <div><p>{formatAddress(carrier)}</p></div>
        <div><p>{get(carrier, 'email', "")}</p></div>
        <div><p className={cs('text-uppercase', {
          'text-success': ['approved', 'paid', 'remote_approved', 'invoice_paid', 'carrier_paid'].includes(status),
          'text-warning': ['pending', 'special_pending', 'fuel_advance', 'needs_review'].includes(status),
          'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
          'text-blue': ['new'].includes(status),
        })}>{status}</p></div>
      </div>
    </div>
  )
};

const FactorCompanyAutoCompleteItem = ({ value, ...rest }) => {
  return (
    <FactorCompanyCardSmall
      role='button'
      className='pac-item'
      icon={
        <MaterialIcon size={20} name='account_box' className='pac-icon' style={{ verticalAlign: 'top', color: '#498504' }} />
      }
      carrier={value}
      {...rest}
    />
  );
};

const FactorCompanyAutoComplete = compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
    (dispatch, { input, onSelect, client_id, ...props }) => {
      if (onSelect) {
        const originalOnChange = input.onChange;
        input = {
          ...input,
          onChange(val) {
            onSelect(val);
          },
        };
      }
      return {
        input,
        formatValue: val => {
          return val;
        },
        dispatch,
      };
    },
  ),
  mapProps(props => {
    return {
      getSuggestions: async dba => {
        if (!dba) {
          return;
        }
        const { results } = await props.dispatch(fetchFactorCompany({ name: dba, status: "approved, pending" }));
        return results;
      },
      ...props
    };
  })
)(props =>
  <LabeledInput
    {...props}
    onFocus={() => props.input.onFocus(props.input.value)}
    onBlur={() => props.input.onBlur(props.input.value)}
    onChange={undefined}
    onSelect={props.input.onChange}
    ComponentClass={Autocomplete}
    DropdownItem={FactorCompanyAutoCompleteItem}
    HistoryDropdownItem={FactorCompanyAutoCompleteItem}
    formatValue={val => get(val, 'name', '')}
    icon="search"
  />
);

export default FactorCompanyAutoComplete;
