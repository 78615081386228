/**
 * Connected modal component to prompt a user to update their match alert settings
 * @module components/pure/modals/FirstTruckPostAlertSettings.jsx
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/settings/activity
 * @requires components/hoc/spinnerIf
 * @requires components/pure/form/CheckmarkTable
 * @requires components/pure/modals/GenericHeader
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';

import { closeModal, openModal } from '../../../actions/ui';
import activitySettingsResource from '../../../actions/settings/activity';

import spinnerIf from '../../hoc/spinnerIf';
import CheckmarkTable from '../form/CheckmarkTable';
import GenericHeader from './GenericHeader';


export default compose(
  connect(
    state => state.settings.activity,
    {
      fetch: activitySettingsResource.fetch,
      edit: activitySettingsResource.edit,
      openModal,
      closeModal,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ data }) => !data),
  mapProps(({ data, edit, openModal, closeModal }) => ({
    closeModal,
    initialValues: data,
    async onSubmit(data) {
      try {
        await edit(data);
        openModal('success', { message: 'Successfully updated settings.' });
      }
      catch (err) {
        console.warn(err);
        openModal('error', { message: err.message || 'Failed to update settings.' });
      }
      closeModal();
    },
  })),
  reduxForm({
    form: 'FirstTruckPostAlertSettings',
  }),
)(({ handleSubmit, submitting, closeModal }) =>
  <div>
    <GenericHeader closeModal={closeModal}>
      Email Load Match Alert Option
    </GenericHeader>
    <div className='modal-body'>
      <p>Enable email load match alerts to make sure you get alerted for new load matches to your truck. You may want to disable email match alerts for broader posts, like state to anywhere -type posts, to avoid having your inbox overflow with email matches, however.</p>
      <CheckmarkTable
        header='Activity Notifications'
        types={[
          { text: 'email', icon: 'email' },
        ]}
        rows={[
          ['Load Match to Your Truck', 'load_match_to_truck'],
        ]}
      />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Not Right Now</button>
      <button className='btn btn-orange' onClick={handleSubmit} disabled={submitting}>Save Settings</button>
    </div>
  </div>
);
