import React from 'react';
import { compose } from 'redux-v3';

import SettingsContainer from 'components/pure/SettingsContainer';
import getSelfType from 'components/hoc/getSelfType';


export default compose(
  getSelfType,
)(({ userType, ...rest }) =>
  <SettingsContainer
    {...rest}
    subRoutes={[
      ['user', 'User Profile'],
      ['accounting', userType === 'shipper' ? 'Accounts Payable' : userType === 'carrier' ? 'Accounts Receivable' : 'AR & AP'],
    ]}
  />
);
