/* global EMPTY_PLACEHOLDER */

import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';
import mapProps from 'recompose/mapProps';
import shallowEqual from 'recompose/shallowEqual';
import { get, values } from 'lodash';
import getSelf from 'selectors/getSelf';
import { fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';
import { openModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import Header from 'components/pure/Header';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import FactoringPaymentsTotalRevenue from './FactoringPaymentsTotalRevenue';
import FactoringPaymentAttachments from 'components/pure/FactoringPaymentAttachments';
import Spinner from 'components/pure/Spinner';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import selectBrokerGroups from 'selectors/selectBrokerGroups';

import groups from 'actions/admin/factoring/groups';
import groupUser from 'actions/admin/factoringGroupUsers';
import { withState } from 'recompose';

const mappingStatus = key => ({
  [key]: key === 'new_noa' ? 'pending_noa' : key,
});

const PaymentsTableComponents = createTableItems(
  ({ data, fetchInvoice, fetchCollated }, is_mobile) => {
    const status = mappingStatus(data.status)[data.status];
    return [
      data.debtor.dba ? data.debtor.dba : data.debtor_company_name,
      formatPennies(data.invoiced_amount),
      data.invoice_number,
      data.user_load_number || EMPTY_PLACEHOLDER,
      data.created ? moment(data.created).format('l LTS') : EMPTY_PLACEHOLDER,
      data.invoice_date ? moment(data.invoice_date).format('l LTS') : EMPTY_PLACEHOLDER,
      data.funded_at ? moment(data.funded_at).format('l LTS') : EMPTY_PLACEHOLDER,
      data.discount_rate ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
      data.amount_funded ? formatPennies(data.amount_funded) : EMPTY_PLACEHOLDER,
      <b
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid'].includes(data.status),
          'text-warning': ['pending', 'pending_originals', 'pending_noa'].includes(data.status),
          'text-red': ['declined', 'incomplete'].includes(data.status),
          'text-blue': ['new', 'fuel_advance', 'new_noa', 'document_review'].includes(data.status),
        })}
        >
        {typeof status === 'string' ? status.replace(/[^a-zA-Z ]/g, ' ') : status}
      </b>,
      is_mobile ?
        <div className='text-right'>
          <FactoringPaymentAttachments
            attachments={data.attachments}
            fetchInvoice={() => fetchInvoice(data.id)}
            fetchCollated={() => fetchCollated(data.id)}
          />
        </div>
        : null,
      <Link to={`/haul-pay/getpaid/history/${data.id}`}><button className='btn btn-orange' type='button'>More</button></Link>,
    ];
  },
  connect(
    null,
    dispatch => ({
      fetchInvoice(...args) {
        try {
          return dispatch(fetchInvoice(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
        }
      },
      fetchCollated(...args) {
        try {
          return dispatch(fetchCollated(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
        }
      },
    }),
  )
);

const PaymentsTableBroker = compose(
  connect(
    state => {
      return {
        id: state.user.id,
      };
    },
    {},
  ),
  mapProps(({ id, debtor, pathname, filter }) => {
    const status = () => pathname === '/haul-pay/getpaid/incomplete' ? ({ status: 'incomplete' }) : {};
    return ({
      id,
      fetchArgs: {
        debtor_id: debtor,
        ...filter,
        ...status(),
      },
      filter,
      pathname,
    });
  }),
  ResourceBy('user', 'factoringpayment', {
    shouldClearBeforeFetch: true,
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpayment',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching || !shallowEqual(props.filter !== nextProps.filter) || props.pathname !== nextProps.pathname;
    },
  }),
)(({ actions, item, items }) =>
  <div>
    <ResponsiveTable
      items={items}
      isFetching={item.isFetching}
      fetchNext={actions.fetchNext}
      placeholder='No payments.'
      headers={['Shipper', 'Shipper Invoice Amnt.', 'Invoice #', 'Load #', 'Requested At', 'Invoiced At', 'Funded At', 'Rate', 'Payment Amnt.', 'Status', '', 'Details']}
      TableItem={PaymentsTableComponents.TableItem}
      BlockTableItem={PaymentsTableComponents.BlockTableItem}
      striped={true}
    />
    {
      item.next ?
        <div>
          <button type='button' className='btn btn-orange' onClick={actions.fetchNext} disabled={item.isFetching}>Load More</button>
        </div>
        : null
    }
  </div>
);

const PaymentsTableClient = compose(
  connect(
    state => {
      return {
        id: state.user.id,
      };
    },
    {},
  ),
  mapProps(({ id, debtor, pathname, filter }) => {
    const status = () => pathname === '/haul-pay/getpaid/incomplete' ? ({ status: 'incomplete' }) : {};
    return ({
      id,
      fetchArgs: {
        debtor_id: debtor,
        ...filter,
        ...status(),
      },
      pathname,
      filter,
    });
  }),
  ResourceBy('user', 'factoringpayment', {
    shouldClearBeforeFetch: true,
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpayment',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching || !shallowEqual(nextProps.item.data, props.item.data) || props.pathname !== nextProps.pathname || !shallowEqual(props.filter !== nextProps.filter);
    },
  }),
)(({ actions, item, items }) => (
  <div>
    <ResponsiveTable
      items={items}
      isFetching={item.isFetching}
      fetchNext={actions.fetchNext}
      placeholder='No payments.'
      headers={['Bill To', 'Invoice Amnt.', 'Invoice #', 'Load #', 'Requested At', 'Invoice At', 'Funded At', 'Rate', 'Payment Amnt.', 'Status', '', 'Details']}
      TableItem={PaymentsTableComponents.TableItem}
      BlockTableItem={PaymentsTableComponents.BlockTableItem}
      striped={true}
    />
    {
      item.next ?
        <div>
          <button type='button' className='btn btn-orange' onClick={actions.fetchNext} disabled={item.isFetching}>Load More</button>
        </div>
        : null
    }
  </div>
));

const FactoringPayments = compose(
  withState('filter', 'setFilter', {}),
  connect(
    state => {
      const factoring_discount_rate = getSelf(state).data.factoring_discount_rate;
      return {
        factoring_discount_rate,
        groups: state.resource.factoringGroups,
        brokerGroups: selectBrokerGroups(state),
        loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
      };
    },
    dispatch => ({
      getGroups: () => dispatch(groups.fetch()),
      getGroupsUsers: id => dispatch(groupUser.fetch({ id })),
    }),
  ),
)(class FactoringPayments extends Component {
  async componentDidMount() {
    const groups = await this.props.getGroups();

    const brokerGroup = get(groups, ['payload', 'results'], [])
      .filter(group => (group.type === 'haulpay_broker'));
  }

  render() {
    const { factoring_discount_rate, params: { id }, location: { pathname } } = this.props;
    const { brokerGroups, loading, setFilter, filter } = this.props;

    return (
      <div>
        <div className='clearfix' style={{ marginTop: 12.5 }}>
          <Header style={{ display: 'inline-block', marginTop: 0 }}>Payments</Header>
          <div className='pull-right'>
            {
              pathname !== '/haul-pay/getpaid/incomplete' &&
              <LabeledInput
                style={{ marginLeft: 10, minWidth: 350 }}
                placeholder='Search by load number or invoice number or Po number'
                onChange={event => setFilter({ load_or_invoice_or_debtor_or_status: event.target.value })}
              />
            }
          </div>
          <div >
            Your QP Rate: <span className='text-success'><b>{factoring_discount_rate}%</b></span>
          </div>
        </div>
        {
          pathname !== '/haul-pay/getpaid/incomplete' &&
          <FactoringPaymentsTotalRevenue debtor={id} />
        }
        {!loading && brokerGroups.length > 0 ? <PaymentsTableBroker debtor={id} pathname={pathname} filter={filter} /> : React.createElement(PaymentsTableClient, { debtor: id, pathname: pathname, filter })}
      </div>
    );
  }
});

export default FactoringPayments;
