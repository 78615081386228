import INITIAL_STATE from '../../INITIAL_STATE';
import financesPurchasesItemsPaginatedResource, { financesPurchaseItemsResource } from 'actions/admin/financepurchasesitems';

const initialState = INITIAL_STATE.resource.financesPurchasesItems;



export default function financesPurchasesItemsResourceReducer(state = initialState, action) {
  state = financesPurchaseItemsResource.reduce(state, action);
  switch (action.type) {
    case financesPurchasesItemsPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...financesPurchaseItemsResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
