import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Resource from 'components/hoc/Resource';
import nothingIf from 'components/hoc/nothingIf';


export default (resource, property) => compose(
  Resource(resource),
  nothingIf(({ item }) => item === undefined || item.isFetching || !item.data[property]),
  mapProps(({ item, ...rest }) => ({
    ...rest,
    [property]: item.data[property],
  })),
);
