import React from 'react';
import BrokerStripeWallet from './BrokerStripeWallet';
import { VisibilityProvider } from '../context/VisibilityContext';

export default function StripeWallet(props) {

    return (
        <VisibilityProvider>
            <BrokerStripeWallet {...props} />
        </VisibilityProvider >
    )
}