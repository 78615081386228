/**
 * Component that displays a series of nav items and a component and the corresponding component
 * @module components/stateful/StatefulTabbed
 * @since 3.0.0
 * @requires components/pure/SelectedComponent
 * @property {object} props
 * @property {string|number} props.activeKey - the key of the active component
 * @property {'tabs'|'pills'} [props.navStyle] - the style of the nav buttons
 * @property {node[]} props.navItems - the list of items to display in the nav bar
 * @property {node[]} props.items - the list of items to potentially display given the active key
 */
import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/lib/Nav';

import SelectedComponent from 'components/pure/SelectedComponent';

class StatefulTabbed extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeKey: this.props.activeKey === undefined ? 0 : this.props.activeKey };
    this.setActive = this.setActive.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const activeKey = nextProps.activeKey === undefined ? 0 : nextProps.activeKey;
    this.setState({ activeKey });
  }

  static propTypes = {
    activeKey: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    navStyle: PropTypes.oneOf(['tabs', 'pills']),
    navItems: PropTypes.arrayOf(PropTypes.object).isRequired,
    items: PropTypes.arrayOf(PropTypes.object).isRequired,
  };

  static defaultProps = {
    navStyle: 'tabs',
    activeKey: 0,
  };

  setActive(activeKey) {
    this.setState({ activeKey });
  }

  render() {
    const { activeKey } = this.state;
    const { navStyle, navItems, items } = this.props;

    return (
      <div>
        <Nav bsStyle={navStyle} activeKey={activeKey} onSelect={this.setActive}>
          {navItems.map((navItem, i) => React.cloneElement(navItem, {
            key: i,
            eventKey: navItem.props.eventKey === undefined ? i : navItem.props.eventKey,
          }))}
        </Nav>
        <SelectedComponent activeKey={activeKey}>
          {items.map((item, i) => React.cloneElement(item, { key: i }))}
        </SelectedComponent>
      </div>
    );
  }
}

export default StatefulTabbed;
