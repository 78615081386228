import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';

import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';

import * as accountspayableSettingsResource from 'actions/settings/accountspayable';
import { openModal } from 'actions/ui';

import spinnerIf from 'components/hoc/spinnerIf';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import SubmitButton from 'components/pure/form/SubmitButton';


export default compose(
  connect(
    state => state.settings.accountspayable,
    {
      openModal,
      ...accountspayableSettingsResource,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ isFetching }) => isFetching === undefined || isFetching),
  mapProps(({ data, edit, openModal, ...rest }) => ({
    initialValues: {
      ...data,
      phone: getPhoneParts(data.phone).phone,
      phone_ext: getPhoneParts(data.phone).ext,
      fax: getPhoneParts(data.fax).phone,
      fax_ext: getPhoneParts(data.fax).ext,
    },
    onSubmit(data) {
      return edit({
        ...data,
        phone: normalizeFormPhone(data, 'phone'),
        fax: normalizeFormPhone(data, 'fax'),
        address: {
          street_one: data.address.street_one,
          city: data.address.city,
          state: data.address.state,
          zip: data.address.zip,
          country: data.address.country,
        },
      })
        .then(() => openModal('success', { message: 'Successfully updated info.' }))
        .catch(err => {
          console.warn(err);
          openModal('error', err);
        })
      ;
    },
    ...rest,
  })),
  reduxForm({
    form: 'AccountsPayableForm',
    validate: fields => {
      const errs = validate(fields, {
        address: {
          multipresence: ['street_one', 'city', 'state', 'zip', 'country'],
        },
        contact_name: {
          presence: true,
        },
        email: {
          presence: true,
          email: true,
        },
        phone: {
          presence: true,
          phone: true,
        },
        fax: {
          phone: '^Not a valid fax number',
        },
      });
      return errs;
    },
  }),
)(({ submitting, handleSubmit, noSubmitButton = false, error }) =>
  <div>
    <Header>Accounts Payable Info</Header>
    <div className='help-block'>Contact Responsible for Paying Freight Bills</div>
    <Field name='contact_name' component={LabeledInput} label='Contact Name' />
    <Field name='email' component={LabeledInput} label='Email' />
    <Field name='address' component={GoogleLocationInput} label='Address' />
    <div className='row'>
      <div className='col-md-12'>
        <Field name='phone' component={PhoneInput} label='Phone' />
      </div>
      {/*<div className='col-md-6'>
        <Field name='phone_ext' component={LabeledInput} label='Phone Ext.' type='number' />
      </div>*/}
    </div>
    <div className='row'>
      <div className='col-md-12'>
        <Field name='fax' component={PhoneInput} label='Fax' />
      </div>
      {/*<div className='col-md-6'>
        <Field name='fax_ext' component={LabeledInput} label='Fax Ext.' type='number' />
      </div>*/}
    </div>
    <Field name='ein' component={LabeledInput} label='EIN' />
    <Field name='more_info' component={LabeledInput} className='grow' ComponentClass='textarea' label='More Info' />
    {noSubmitButton ? null : <SubmitButton disabled={submitting} onClick={handleSubmit}>SAVE</SubmitButton>}
    {error && <div className='alert alert-danger' role='alert'>{`Error: ${error}`}</div>}
  </div>
);
