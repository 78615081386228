/**
 * Our redux store is created here
 * @module store
 * @since 3.0.0
 * @requires reducers
 * @requires middleware/logger
 * @requires middleware/raven
 * @requires middleware/googleanalytics
 * @requires middleware/segmentanalytics
 */

/* global process */
import { createStore, applyMiddleware, compose } from 'redux-v3';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { browserHistory } from 'react-router-v4';
import { identity } from 'lodash';

import reducer from './reducers';
import INITIAL_STATE from './INITIAL_STATE';
import logger from './middleware/logger';
import sentry from './middleware/sentry';
import segmentanalytics from './middleware/segmentanalytics';

const store = createStore(
  reducer,
  INITIAL_STATE,
  compose(
    applyMiddleware(
      thunk,
      routerMiddleware(browserHistory),
      logger,
      sentry,
      segmentanalytics,
    ),
    process.env.NODE_ENV !== 'production' ?
      window.__REDUX_DEVTOOLS_EXTENSION__ ?
        window.__REDUX_DEVTOOLS_EXTENSION__() :
        identity :
      identity,
  )
);

export default store;
