import { connect } from 'react-redux-v5';
import getSelf from 'selectors/getSelf';

const getFactoringStatus = connect(
  state => {
    const self = getSelf(state);
    if (!self) {
      return {};
    }
    return {
      status: self.data.factoring_approved,
      message: self.data.factoring_message,
    };
  },
  {},
);

export default getFactoringStatus;
