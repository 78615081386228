/**
 * Reducer for editing Truck/Load post editing/copying/posting state
 * @module reducers/post
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/post
 * @listens POST_INIT_EDIT
 * @listens POST_CANCEL_EDIT
 * @listens POST_INIT_COPY
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.post;

import {
  POST_CANCEL_EDIT,
  POST_INIT_COPY,
  POST_INIT_EDIT,
  POST_DISPLAY_HISTORY,
  POST_INIT_ADD, POST_DISPLAY_CURRENT_LOADS,
} from '../actions/post';


export default function postsReducer(state = initialState, action) {
  switch (action.type) {
    case POST_INIT_EDIT:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          editId: action.payload.editId,
        },
      };
    case POST_CANCEL_EDIT:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          editId: undefined,
          copyId: undefined,
        },
      };
    case POST_INIT_COPY:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          copyId: action.payload.copyId,
        },
      };
    case POST_DISPLAY_HISTORY:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          history: action.payload.history,
        },
      };
    case POST_DISPLAY_CURRENT_LOADS:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          current: action.payload.current,
        },
      };
    case POST_INIT_ADD:
      return {
        ...state,
        [action.payload.resource]: {
          ...state[action.payload.resource],
          addStatus: action.payload.addStatus,
        },
      };
  }
  return state;
}
