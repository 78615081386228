/* global API */
import { compose } from 'redux-v3';
import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';

class DebtorPaymentsResource extends compose(
  Createable(),
)() {
  create(data, id) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, CREATE_KEY);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });
    try {
      const res = await dispatch(APIFetch(`${API.host}/payments/debtor/${id}/funding_requests/`, {
        method: 'POST',
        headers: {
          authorization: `bearer ${state.user.token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }));
      if (res.status !== 201) {
        if (res.status >= 400 && res.status < 500) {
          const json = await res.json();
          if (res.status === 400) {
            throw new SubmissionError(json);
          }
          throw new PermissionsError(json);
        }
        const text = await res.text();
        throw new FetchError(res.status, text);
      }
      return dispatch({
        type: this.actions.CREATE_REQUEST_SUCCESS,
        payload: data,
      });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
}

const debtorpaymentsresource = new DebtorPaymentsResource('debtorpayments');

export const {
  selector,
  fetch,
  fetchIfNeeded,
  edit,
  create,
} = debtorpaymentsresource;

export default debtorpaymentsresource;
