import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import bankinfoPaginatedResource from 'actions/admin/factoring/bank';
import CenteredColumn from 'components/pure/CenteredColumn';
import FetchWireAccounts from 'components/container/FetchWireAccounts';


const WireAccounts = compose(
  connect(
    null,
    (dispatch, { item }) => ({
      update() {
        dispatch(bankinfoPaginatedResource.clear());
        return dispatch(bankinfoPaginatedResource.fetch({
          id: item.data.user,
          method: 'WIRE',
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(id =>
  <CenteredColumn
    xs={12}
    sm={10}
    className='col-fill'
    columnProps={{
      className: 'col-fill',
    }}
    >
    <FetchWireAccounts id={id} />
  </CenteredColumn>
);

export default WireAccounts;
