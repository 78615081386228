/**
 * Reducer for handling the load map search actions
 * @module reducers/search/loadmap
 * @since 3.0.1
 * @requires INITIAL_STATE
 * @requires actions/search/loadmap
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 * @listens module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST
 * @listens module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS
 * @listens module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE
 */
import loadMapResource from '../../actions/search/loadmap';


export default function searchUserReducer(state, action) {
  return loadMapResource.reduce(state, action);
}
