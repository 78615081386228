import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { get } from 'lodash';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 16,
    color: '#000000',
    textAlign: 'center',
    fontWeight: '100',
  },
  icon: {
    color: '#4a90e2',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
};
const AssignCarrierInfoModal = ({
  closeModal,
  amount,
  action,
  ...props
}) => (
  <div >
    <ModalBody closeModal={closeModal}>
      <div style={{ width: '100%' }}>
        <div
          style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
          >
          <MaterialIcon
            name='info'
            size={70}
            style={styles.icon}
          />
        </div>
        <div style={{ ...styles.flexRow, justifyContent: 'center' }}>
          <p style={styles.text}>
            Would you like to assign a carrier from your group to this load now and create a pending carrier payment?
          </p>
        </div>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, margin: '-5px 5px 5px 5px' }}>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
        <Button
          bsSize='small'
          style={{
            borderColor: '#FF7800',
            color: '#FF7800',
          }}
          onClick={() => {
            closeModal();
          }}
          >
          No Thanks!
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        >
        <Button
          style={styles.button}
          bsSize='small'
          onClick={() => {
            get(action, 'confirm', () => ({}))();
          }}
          >
          Yes
        </Button>
      </div>
    </div>
  </div>
);

export default connect(null,
  dispatch => ({
    closeModal() {
      dispatch(closeModal());
    },
  }),
)(AssignCarrierInfoModal);
