/**
 * Component that connects the BidsOtherUser component to state
 * @module components/connected/BidsOtherUser
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/resourceBy/load/bid
 * @requires datatypes/PermissionsError
 * @requires components/hoc/Resource
 * @requires components/hoc/ResourceBy
 * @requires components/hoc/spinnerIf
 * @requires components/hoc/spinnerIfFetching
 * @requires components/hoc/nothingIf
 * @requires components/pure/BidsOtherUser
 * @property {object} props
 * @property {string} props.id - the ID of the load to display a bid table for
 * @property {any} [props....rest] - props passed to containing component
 */
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';
import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import spinnerIf from 'components/hoc/spinnerIf';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import nothingIf from 'components/hoc/nothingIf';

import PermissionsError from 'datatypes/PermissionsError';
import emailIsInvalid from 'helpers/emailIsInvalid';
import { get } from 'lodash';
import { reset } from 'redux-form';
import { create } from 'actions/resourceBy/load/bid';
import { openModal } from 'actions/ui';
import getSelf from 'selectors/getSelf';

import BidsOtherUser from './BidsOtherUser';


export default compose(
  Resource('load', { resourcePropName: 'load' }),
  spinnerIfFetching('load'),
  // dont display if this is our load
  connect(state => ({ user_id: state.user.id })),
  nothingIf(({ user_id, load }) => load.data.user_id === user_id),
  // dont display if this load has a user with an invalid email
  Resource('user', {
    resourcePropName: 'user',
    idPropName: ['load', 'data', 'user_id'],
  }),
  spinnerIfFetching('user'),
  nothingIf(({ user }) =>
    !(user.err instanceof PermissionsError) &&
    (
      emailIsInvalid(user.data.email) &&
      emailIsInvalid(user.data.contact_email)
    )
  ),
  ResourceBy('load', 'bid'),
  spinnerIf(({ item }) => item === undefined),
  connect(
    (state, ownProps) => {
      const user = getSelf(state);
      return {
        has_permissions: get(user, ['data', 'permissions', 'is_subscribed']) || get(user, ['data', 'permissions', 'is_trialing']),
        bids: state.resourceBy.load.bid[ownProps.id].ids.map(id => state.resource.bid[id]),
      };
    },
    (dispatch, ownProps) => ({
      openBidCreatePerMileModal: () => dispatch(openModal('bidcreatepermile', { id: ownProps.id })),
      onSubmit: fields => dispatch(create(ownProps.id, {
        amount: Number(fields.amount) * 100,
        bidder_notes: fields.note,
      }))
        .then(() => {
          dispatch(reset('BidCreateForm'));
          dispatch(openModal('success', { message: 'Successfully posted bid' }));
        })
        .catch(err => {
          if (err instanceof PermissionsError) {
            return dispatch(openModal('upgradeaccount', { message: 'Please upgrade your account to bid on this shipper load now.' }));
          }
          let message = 'Error posting bid.';
          if (err) {
            if (err.status === 400) {
              try {
                const json = JSON.parse(err.message);
                if (json.amount && json.amount[0]) {
                  message = json.amount;
                }
              }
              catch (e) {}
            }
            else if (err.amount && err.amount[0]) {
              message = err.amount;
            }
            else if (err.message) {
              message = err.message;
            }
          }
          return dispatch(openModal('error', { message }));
        }),
      retractBid: id => dispatch(openModal('confirmBidRetract', { id })),
    }),
  ),
  shouldUpdate((props, nextProps) => !shallowEqual(props.bids, nextProps.bids)),
)(BidsOtherUser);
