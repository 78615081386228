import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { initiateCopy } from 'actions/post';

import Resource from 'components/hoc/Resource';
import scrollToItem from 'components/hoc/scrollToItem';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import TruckMobile3 from 'components/pure/TruckMobile3';


const TruckPostHistoryTableItem = compose(
  Resource('truck', { resourcePropName: 'truck' }),
  connect(
    state => ({
      scroll_to_item: state.ui.scroll_to_item,
    }),
    {
      initiateCopy: (...args) => initiateCopy('truck', ...args),
    },
  ),
  scrollToItem(({
    idPropName: ['truck', 'data', 'id'],
  })),
)(TruckMobile3);

const TruckPostHistoryTable = ({ ids, isFetching = false, fetchNext }) =>
  <NormalInfiniteScroll
    isFetching={isFetching}
    onScrollBottom={fetchNext}
    placeholder={<div className='help-block text-center'>No trucks (yet)<br />Try posting some</div>}
    >
    {ids.map(id => <TruckPostHistoryTableItem key={id} id={id} />)}
  </NormalInfiniteScroll>
  ;

export default TruckPostHistoryTable;
