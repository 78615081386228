import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import { openModal } from 'actions/ui';
import LineItemsTable from 'components/pure/LineItemsTable';
import { getLineItemsByFundingRequest } from 'actions/lineitems';
import { lifecycle, withState } from 'recompose';

export default compose(
  connect(
    null,
    dispatch => {
      return {
        getLineItemsByFundingRequest: id => dispatch(getLineItemsByFundingRequest(id)),
      };
    }
  ),
  reduxForm({
    destroyOnUnmount: true,
    validate(fields) {
      if (!fields.to_debtor) {
        return {
          to_debtor: 'Required',
        };
      }
    },
  }),
  withState('lineItems', 'setLineItems', []),
  lifecycle({
    async componentDidMount() {
      const lineItems = await this.props.getLineItemsByFundingRequest(this.props.id);
      this.props.setLineItems(lineItems);
    },
  })
)(props => (
  <div id='lineItemTable'>
    <h4>Line Items</h4>
    <Field
      name='to_LineItem'
      component={LineItemsTable}
      lineItems={props.lineItems}
      onEditClick={lineItem => {
        props.dispatch(
          openModal('lineitemsedit', {
            LineItem: {
              ...lineItem,
              funding_request_id: lineItem.funding_request,
            },
            refresh: props.refresh,
          })
        );
      }}
      onDeleteClick={({ funding_request: funding_request_id, id }) => {
        props.dispatch(
          openModal('confirm', {
            type: 'factoringLineItem_delete',
            args: [
              {
                funding_request_id,
                id,
                cb: async () => {
                  const lineItems = await props.getLineItemsByFundingRequest(props.id);
                  props.setLineItems(lineItems);
                },
              },
            ],
          })
        );
      }}
      onHistoryClick={lineItem => {
        props.dispatch(
          openModal('lineitemaudit', {
            lineItem,
          })
        );
      }}
    />
  </div>
));
