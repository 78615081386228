import React from 'react';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import path from 'path-browserify'

const RouteTabs = ({ routes, extras, ...rest }) =>
  <ul
    {...rest}
    className={cs('nav nav-tabs', rest.className)}
    style={{
      marginTop: '1em',
      ...rest.style,
    }}
  >
    {routes.map(([route, title, matches, onClick]) => {
      const routepath = path.resolve(location.pathname, route);
      return (
        <li key={route} className={cs({ active: location.pathname === routepath || (matches && matches.exec(location.pathname)) })} onClick={onClick}>
          <Link to={routepath}>{title}</Link>
        </li>
      );
    })}
    {extras}
  </ul>
  ;

export default RouteTabs;
