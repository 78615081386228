import { compose } from 'redux-v3';

import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';


export const financespaymentsResource = compose(
  Singleton('financespayment'),
  Fetchable({
    url: id => `payments/${id}/`,
  }),
  Editable({
    url: id => `payments/${id}/`,
  }),
)();

export const {
  selector,
  fetch,
  edit,
} = financespaymentsResource;

export default financespaymentsResource;
