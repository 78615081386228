import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, submit } from 'redux-form';
import { Link } from 'react-router-v4';
import { Bar, BarChart, CartesianGrid, ComposedChart, Legend, Scatter, ScatterChart, Tooltip, XAxis, YAxis } from 'recharts';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import PropTypes from 'prop-types';
import createClass from 'create-react-class';

import formatPennies from 'helpers/formatPennies';

import { fetchFinanceStats, uploadFinanceStats } from 'actions/admin/stats';
import { openModal } from 'actions/ui';
import getSelf from 'selectors/getSelf';
import Header from 'components/pure/Header';
import FileInput from 'components/pure/form/inputs/FileInput';
import PopupCalendarInput from 'components/pure/form/inputs/PopupCalendarInput';


const AgingDetails = createClass({
  propTypes: {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
  },
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className='panel panel-default'> <center>Amount At Risk</center>
          <div className='panel-body'><p><font color='#186791'>{`${label} Days : ${formatPennies(payload[0].value)}`}</font></p></div>
        </div>
      );
    }

    return null;
  },
});

const ConcentrationDetails = createClass({
  propTypes: {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
  },
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className='panel panel-default'>
          <div className='panel-body'>
            <p>{`${label} : `}</p>
            <p><font color='#186791'>{`Percentage at risk: ${payload[0].value.toFixed(3)}%`}</font></p>
            <p><font color='#fe832e'>{`Amount at Risk: ${formatPennies(payload[1].value)}`}</font></p>
          </div>
        </div>
      );
    }

    return null;
  },
});

const PaymentsPurchasesDetails = createClass({
  propTypes: {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.string,
  },
  render() {
    const { active } = this.props;

    if (active) {
      const { payload, label } = this.props;
      return (
        <div className='panel panel-default'> <center>Weekly Details</center>
          <div className='panel-body'><p>{`Week starting at ${moment(label).format('MM/DD/YY')} :`}</p>
            <p><font color='#186791'>{`Amount of Payments: ${formatPennies(payload[0].value)}`}</font></p>
            <p><font color='#fe832e'>{`Amount of Purchases: ${formatPennies(payload[1].value)}`}</font></p>
          </div>
        </div>
      );
    }

    return null;
  },
});


const PercentageLabel = createClass({
  render() {
    const { x, y, fill, value } = this.props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize='12'
        fontFamily='sans-serif'
        fill='#186791'
        textAnchor='middle'
        >
        {value.toFixed(1)}%</text>
    );
  },
});


const FinanceStatsUpload = compose(
  reduxForm({
    form: 'AdminDashboardFinanceUpload',
    async onSubmit(fields, dispatch) {
      try {
        await dispatch(uploadFinanceStats(fields.file));
        dispatch(submit('AdminDashboardFinance'));
      }
      catch (err) {
        console.warn(err);
        if (err && err.detail) {
          dispatch(openModal('error', { message: err.detail }));
        }
        else if (err && err.factoring && err.debtor) {
          let message = 'Could not find the following:\n';
          for (const key of ['debtor', 'factoring']) {
            message += `${key} users: ${err[key].join(', ')}\n`;
          }
          dispatch(openModal('error', { message }));
        }
        else {
          dispatch(openModal('error', { message: 'Failed to upload data.' }));
        }
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <div>
    <Field
      name='file'
      component={FileInput}
      label='Select File'
    />
    <button className='btn btn-orange' disabled={submitting} type='submit' onClick={handleSubmit}>Upload</button>
  </div>
);

export default compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
  ),
  withState('stats', 'setStats', null),
  reduxForm({
    form: 'AdminDashboardFinance',
    initialValues: {
      range: moment.range(
        moment().subtract(1, 'month'),
        moment(),
      ),
    },
    async onSubmit(fields, dispatch, { setStats }) {
      try {
        const stats = await dispatch(fetchFinanceStats({
          datetime_start: fields.range.start.format(),
          datetime_end: fields.range.end.format(),
        }));
        setStats(stats);
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to fetch stats' }));
      }
    },
    convertdata(object_array) {
      const retarray = [];
      object_array.forEach(element => {
        const obj = {};
        obj.name = element.days_bucket;
        obj.at_risk_amount = element.at_risk_amount;
        retarray.push(obj);
      });
      return retarray;
    },
    formatDateTicks(tickItem) {
      let formattedString = moment(tickItem).format('MM/DD/YY');
      formattedString += ` to  ${moment(tickItem).add(6, 'd').format('MM/DD/YY')}`;
      return formattedString;
    },
  }),
  lifecycle({
    componentDidMount() {
      this.props.submit();
    },
  }),
)(({ handleSubmit, submitting, stats, formatDateTicks }) =>
  <div>
    <Header>Finance & Commissions</Header>
    <Field
      name='range'
      component={PopupCalendarInput}
      onConfirm={handleSubmit}
      disabled={submitting}
    />
    <FinanceStatsUpload />
    <hr />
    {
      stats ?
        <div>
          <h5><b><u>Factoring Data</u></b></h5>
          <p>Invoices Purchased: {formatPennies(stats.factoring_data.total_purchased)}</p>
          <p>Invoices Collected: {formatPennies(stats.factoring_data.total_collected)}</p>
          <p>Total Fee Share Amount: {formatPennies(stats.factoring_data.total_fee_share)}</p>
          <br />
          <p>Average Aging (All Accounts): {stats.factoring_data.debtor_average_pay} days ({stats.factoring_data.debtor_average_transactions} funding requests)</p>
          <p>At Risk Amount: {formatPennies(stats.factoring_data.at_risk_amount)}</p>
          <p>At Risk Funding Requests: {stats.factoring_data.at_risk_fr}</p>
          <br />
          <h5><b><u>Slow Paying Debtors (45+ days)</u></b></h5>
          {
            !stats.factoring_data.debtor_slow_pay.length ?
              <p>No slow debtors!</p>
              : null
          }
          {stats.factoring_data.debtor_slow_pay.map(({ company_name, id, days_to_pay_average }) =>
            <Link to={`/admin/factoring/client/${id}`} key={id}>
              <p>{company_name}: <span className='text-danger'>{days_to_pay_average} days</span></p>
            </Link>
          )}
          <br />
          <h5><b><u>Factoring Commission</u></b></h5>
          {
            !stats.commission.factoring.length ?
              <p>No factoring commission yet.</p>
              : null
          }
          {stats.commission.factoring.map(({ id, name, total_commission }) =>
            <p key={id}>{name}: {formatPennies(total_commission)}</p>
          )}
          <br />
          <h5><b><u>Subscription Commission</u></b></h5>
          {
            !stats.commission.factoring.length ?
              <p>No subscription commission yet.</p>
              : null
          }
          {stats.commission.subscription.map(({ id, name, total_commission }) =>
            <p key={id}>{name}: {formatPennies(total_commission)}</p>
          )}
          <br />
          <h5><b><u>Dispatch Commission</u></b></h5>
          {
            !stats.commission.factoring.length ?
              <p>No dispatching commission yet.</p>
              : null
          }
          {stats.commission.dispatching.map(({ id, name, total_commission }) =>
            <p key={id}>{name}: {formatPennies(total_commission)}</p>
          )
          }
          {
            stats && stats.reporting.aging ?
              <div className='text-left'>
                <h5><b><u>Aging Report</u></b></h5>
                <BarChart width={730} height={250} data={stats.reporting.aging} margin={{ left: 50 }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='days_bucket' />
                  <YAxis tickFormatter={formatPennies} />
                  <Tooltip content={<AgingDetails />} />
                  <Legend />
                  <Bar name='Amount at Risk in $' dataKey='at_risk_amount' fill='#186791' />
                </BarChart>
              </div>
              :
              <div> Aging Report unavailable </div>
          }
          {
            stats && stats.reporting.concentration ?
              <div className='text-left'>
                <h5><b><u>Concentration Report</u></b></h5>
                <BarChart width={730} height={250} data={stats.reporting.concentration.slice(0, 10)} margin={{ left: 50, right: 50 }}>
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='debtor' />
                  <YAxis yAxisId='percentage' orientation='left' tickerFormatter={value => `${value}%`} />
                  <YAxis yAxisId='at_risk_amount'v={true} orientation='right' tickFormatter={formatPennies} />
                  <Tooltip content={<ConcentrationDetails />} />
                  <Legend />
                  <Bar
                    yAxisId='percentage'
                    name='Percentage of Amount At Risk'
                    dataKey='percentage'
                    fill='#186791'
                    label={<PercentageLabel />}
                  />
                  <Bar
                    yAxisId='at_risk_amount'
                    name='Amount at Risk'
                    dataKey='at_risk_amount'
                    fill='#fe832e'
                  />
                </BarChart>
              </div>
              :
              null
          }
          {
            stats ?
              <div className='text-left' >
                <h5><b><u>Payments v/s Purchases</u></b></h5>
                <ComposedChart width={730} height={250} data={stats.reporting.payments_purchases} margin={{ left: 50 }} >
                  <CartesianGrid strokeDasharray='3 3' />
                  <XAxis dataKey='week_start' tickFormatter={formatDateTicks} />
                  <YAxis tickFormatter={formatPennies} />
                  <Tooltip content={<PaymentsPurchasesDetails />} />
                  <Legend />
                  <Bar name='Amount of Payments' dataKey='payments_amount' fill='#186791' />
                  <Bar name='Amount of Purchases' dataKey='purchases_amount' fill='#fe832e' />
                  <Scatter name='Payments Fit Line' type='basis' lineType='fitting' dataKey='payments_amount' fill='#186791' line={true} />
                  <Scatter name='Purchases Fit Line' type='basis' lineType='fitting' dataKey='purchases_amount' fill='#fe832e' line={true} />
                </ComposedChart>
              </div>
              :
              null
          }
        </div>
        : null
    }
  </div>
);
