import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { stripeWalletHistory, ledgerWalletHistory } from 'actions/ledger';
import { openModal } from 'actions/ui';

import wrapPromisePending from 'components/hoc/wrapPromisePending';
import moment from 'moment';


export default compose(
    connect(
        null,
        (dispatch, { walletType }) => ({
            async onClick(e) {
                e.preventDefault();
                e.stopPropagation();
                try {
                    const res = (walletType === 'STRIPE') ? await stripeWalletHistory({ download: true }) 
                                                        : await ledgerWalletHistory({ download: true });
                    const url = window.URL.createObjectURL(res);
                    const link = document.createElement('a');
                    link.href = url;
                    const date = moment();
                    link.download = `wallet_transaction_${date.format('DD_MM_Y')}`;
                    link.click();
                }
                catch (err) {
                    console.error(err);
                    dispatch(openModal('error', {
                        message: 'Failed to fetch CSV data :(',
                    }));
                }
            },
        }),
        (stateProps, dispatchProps, rest) => ({
            ...rest,
            ...stateProps,
            ...dispatchProps,
        }),
    ),
    wrapPromisePending({
        promiseProp: 'onClick',
        isPendingProp: 'disabled',
    }),
)(props =>
    <img
        src='/public/image/icon/csv.png'
        role='button'
        title='Download CSV'
        {...props}
        style={{
            height: '2em',
            width: '2em',
            ...props.style,
        }}
    />
);

