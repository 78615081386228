/* global API */
import Resource from 'datatypes/Resource';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';

class FactoringPaymentRelationResource extends Resource {
  constructor() {
    super('factoringpaymentrelation');
  }

  getRelationId(relation) {
    const { debtorId, factoringId } = relation;
    return [debtorId, factoringId].join('|');
  }

  getItem(state, relation) {
    return state.resource[this.name][this.getRelationId(relation)];
  }

  fetch(relation) {
    return (dispatch, getState) => {
      const { debtorId, factoringId } = relation;
      const state = getState();
      const item = this.getItem(state, relation);
      const relationId = this.getRelationId(relation);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.fetchRequest(relationId));

      return dispatch(
        APIFetch(`${API.host}/factoring/debtors/relationship/${debtorId}/retrieve/?factoring_id=${factoringId}`, {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            'Content-Type': 'application/json',
          },
        })
      )
        .then(
          res =>
            res.status !== this.options.acceptableStatusCode
              ? res.text().then(text => Promise.reject(new FetchError(res.status, text)))
              : res.json()
        )
        .then(json => dispatch(this.fetchRequestSuccess(relationId, json)))
        .catch(err => {
          dispatch(this.fetchRequestFailure(relationId, err));
          return Promise.reject(err);
        });
    };
  }

  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return super.parse(oldValue, {
      ...json,
    });
  }
}

/**
 * Singleton for our MapResource
 */
export const factoringPaymentRelationResource = new FactoringPaymentRelationResource();

export default factoringPaymentRelationResource;
export const { fetch, fetchIfNeeded, getItem } = factoringPaymentRelationResource;
