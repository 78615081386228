/**
 * Component for searching for factoring debtors
 * @module components/container/FactoringDebtorSearch
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/factoringdebtor
 * @requires components/hoc/requireAdmin
 * @requires components/pure/Header
 * @requires components/pure/form/FactoringDebtorSearchForm
 * @requires components/pure/ResponsiveTable
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import createTableItems from 'helpers/createTableItems';

import { fetchNext } from 'actions/search/factoringdebtor';

import Header from 'components/pure/Header';
import FactoringDebtorSearchForm from 'components/pure/form/FactoringDebtorSearchForm';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const FactoringDebtorSearchTableComponents = createTableItems(
  ({ item }) => [
    item.data.credit_approved,
    <Link to={`/admin/factoring/debtor/${item.data.id}`}>{item.data.email}</Link>,
    item.data.name,
    item.data.master === true ? <div>{item.data.company_name} <p className='text-success'> <i> Master </i> </p> </div> : item.data.company_name,
    item.data.phone_number,
    item.data.mc,
    item.data.dot,
    item.data.tax_id,
    <Link to={`/admin/factoring/debtor/${item.data.id}`}><button className='btn btn-orange'>More</button></Link>,
  ],
  compose(
    connect(
      (state, { id }) => ({
        item: state.resource.factoringdebtor[id],
      }),
    ),
  ),
  () => ({
    style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
  }),
);

const FactoringDebtorSearchTable = compose(
  connect(
    state => ({
      items: state.search.factoringdebtor.ids.map(id => ({ id })),
      isFetching: state.search.factoringdebtor.isFetching,
    }),
    {
      fetchNext,
    },
  ),
)(props =>
  <ResponsiveTable
    {...props}
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Status', 'Email', 'Contact', 'DBA', 'Phone#', 'MC#', 'DOT#', 'Tax ID#', 'More']}
    placeholder='No results'
    TableItem={FactoringDebtorSearchTableComponents.TableItem}
    BlockTableItem={FactoringDebtorSearchTableComponents.BlockTableItem}
  />
);

const FactoringDebtorSearch = compose(
)(() =>
  <div
    className='clearfix'
    style={{
      height: '100%',
      maxWidth: '100%',
    }}
    >
    <div className='col-xs-12 col-sm-3'>
      <Header>Debtor Search</Header>
      <FactoringDebtorSearchForm />
    </div>
    <div className='col-xs-12 col-sm-9' style={{ height: '100%' }}>
      <FactoringDebtorSearchTable />
    </div>
  </div>
);

export default FactoringDebtorSearch;
