import { compose } from 'redux-v3';
import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';

const factoringGroups = compose(
  Singleton('factoringGroups'),
  Fetchable({
    url: id => `groups/${id}/`,
    expirationTime: -Infinity,
  })
)();

export const {
  selector,
  fetch,
  fetchIfNeeded,
  edit,
  doDelete,
} = factoringGroups;

export default factoringGroups;
