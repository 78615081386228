/**
 * Actions relating to the saved truck by user resource
 * @module actions/resourceBy/user/savedtruck
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/truck
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import truckResource from 'actions/resource/truck';


/**
 * Our truck by User ControlledPaginatedResourceCollection instance
 */
export const savedtruckByUserResource = new ControlledPaginatedResourceCollection('user', 'savedtruck', {
  url: new UrlAssembler('truck/save'),
  paginatedResourceOptions: {
    url: new UrlAssembler('truck/save'),
    baseResource: truckResource,
  },
});

export default savedtruckByUserResource;
export const {
  fetch,
  fetchNext,
  create,
  doDelete,
} = savedtruckByUserResource;
