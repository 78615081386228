import React from 'react';

import RouteTabs from 'components/pure/RouteTabs';


const FactoringAPProfile = ({ children }) =>
  <div
    style={{ width: '100%' }}
    >
    <RouteTabs
      routes={[
        ['/haul-pay/profile/creditcheck', 'Credit Check'],
        ['/haul-pay/profile/client', 'HP Profile'],
      ]}
    />
    {children}
  </div>
  ;

export default FactoringAPProfile;
