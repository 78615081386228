import React from 'react';

import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';


export default ({ data: { files = [] } }) =>
  <div>
    {[
      {
        label: 'Authority Document',
        key: 'documents_authority',
      },
      {
        label: 'Insurance Document',
        key: 'documents_insurance',
      },
    ].map(({ label, key }, i) =>
      <p key={i}>
        <span>{label}: </span>
        {files.filter(file => file.category === key).map(file =>
          <LoadAttachmentButton
            title={label}
            getURL={() => Promise.resolve(file)}
          />
        )}
      </p>
    )}
  </div>
;
