import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';

import getSelf from 'selectors/getSelf';
import { USER_TYPE } from 'helpers';
import formatPennies from 'helpers/formatPennies';

import { fetch } from 'actions/admin/stats';
import { openModal } from 'actions/ui';

import Header from 'components/pure/Header';
import PopupCalendarInput from 'components/pure/form/inputs/PopupCalendarInput';
import AdminDashboardStats from 'components/container/AdminDashboardStats';
import SourceGraphFactoringClient from 'components/pure/SourceGraphFactoringClient';
import SourceGraphFactoringPaymentSum from 'components/pure/SourceGraphFactoringPaymentSum';
// import SourceGraphUnitedStatesMap from 'components/pure/SourceGraphUnitedStatesMap';


const FORM_NAME = 'AdminDashboardStats2';
const selector = formValueSelector(FORM_NAME);

export default compose(
  connect(
    state => ({
      self: getSelf(state),
      state: selector(state, 'state'),
    }),
  ),
  reduxForm({
    form: 'AdminDashboardStats2',
    initialValues: {
      range: moment.range(
        moment().subtract(1, 'month'),
        moment(),
      ),
    },
  }),
)(class AdminDashboardStats2 extends React.Component {
  state = {
    stats: null,
    show_pie_charts: false,
  }
  onSubmit = this.onSubmit.bind(this);

  async onSubmit(fields, dispatch, { selectedAdmin }) {
    try {
      const stats = await dispatch(fetch({
        datetime_start: fields.range.start.startOf('day').format(),
        datetime_end: fields.range.end.endOf('day').format(),
        admin: selectedAdmin ? selectedAdmin.id : undefined,
        state: fields.state,
      }));
      this.setState({ stats });
    }
    catch (err) {
      console.warn(err);
      let message = 'Failed to fetch stats';
      if (err && err.detail) {
        message = err.detail;
      }
      dispatch(openModal('error', { message }));
    }
  }

  componentDidMount() {
    if (this.props.self.data.type === USER_TYPE.ADMIN) {
      this.props.handleSubmit(this.onSubmit)();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.selectedAdmin !== this.props.selectedAdmin) {
      this.props.handleSubmit(this.onSubmit)();
    }
  }

  render() {
    const {
      handleSubmit,
      submitting,
      change,
      state,
      self,
      selectedAdmin,
    } = this.props;
    const {
      stats,
      show_pie_charts,
    } = this.state;

    const submit = handleSubmit(this.onSubmit);

    return (
      <div>
        <Header>
          {
            [
              'Business Statistics',
              selectedAdmin.id === null ? '' : `: ${selectedAdmin.name || selectedAdmin.email || selectedAdmin.id}`,
              state ? ` - ${state}` : '',
            ].reduce((acc, curr) => acc + curr, '')
          }
        </Header>
        <Field
          name='range'
          component={PopupCalendarInput}
          onConfirm={submit}
          disabled={submitting}
        />
        {
          stats && (self.data.type === USER_TYPE.ADMIN || self.data.type === USER_TYPE.SUB_ADMIN) ?
            <div>
              <p><b><u>Haul Pay Stats</u></b></p>
              <table className='table table-condensed table-striped table-hover'>
                <tbody>
                  <tr>
                    <th>Applications in Process</th>
                    <td className='text-right'>{stats.factoring_in_process}</td>
                  </tr>
                  <tr>
                    <th>Applications Pending</th>
                    <td className='text-right'>{stats.factoring_pending}</td>
                  </tr>
                  <tr>
                    <th>Applications Approved</th>
                    <td className='text-right'>{stats.factoring_approved}</td>
                  </tr>
                  <tr>
                    <th>Total Applications Approved</th>
                    <td className='text-right'>{stats.total_approved_applications}</td>
                  </tr>
                  <tr>
                    <th>Total Haulpay Applications</th>
                    <td className='text-right'>{stats.total_haulpay_applications}</td>
                  </tr>
                  <tr>
                    <th>Active Factoring Clients</th>
                    <td className='text-right'>{stats.active_client_counts}</td>
                  </tr>
                  <tr>
                    <th>Inactive Factoring Clients</th>
                    <td className='text-right'>{stats.inactive_factoring}</td>
                  </tr>
                  <tr>
                    <th>Invoices Factored</th>
                    <td className='text-right'>{stats.total_invoice_count}</td>
                  </tr>
                  <tr>
                    <th>Value of Invoices Factored</th>
                    <td className='text-right'>{formatPennies(stats.total_invoice_value)}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
            : null
        }
        {
          stats && stats.commission ?
            <div>
              <p><b><u>Commission</u></b></p>
              <table className='table table-condensed table-striped table-hover'>
                <tbody>
                  <tr>
                    <th>Dispatching</th>
                    <td className='text-right'>{formatPennies(stats.commission.dispatching)}</td>
                  </tr>
                  <tr>
                    <th>Factoring</th>
                    <td className='text-right'>{formatPennies(stats.commission.factoring)}</td>
                  </tr>
                  <tr>
                    <th>Subscription</th>
                    <td className='text-right'>{formatPennies(stats.commission.subscription)}</td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </div>
            : null
        }
        {
          stats && self.data.type === USER_TYPE.ADMIN ?
            <div>
              <p><b><u>User and Load Stats</u></b></p>
              <table className='table table-condensed table-striped table-hover'>
                <tbody>
                  <tr>
                    <th>Paid Carriers</th>
                    <td className='text-right'>{stats.paid_carriers}</td>
                  </tr>
                  <tr>
                    <th>Paid Brokers</th>
                    <td className='text-right'>{stats.paid_brokers}</td>
                  </tr>
                  <tr>
                    <th>Paid Shippers</th>
                    <td className='text-right'>{stats.paid_shippers}</td>
                  </tr>
                  <tr>
                    <th>Free Carriers</th>
                    <td className='text-right'>{stats.free_carriers}</td>
                  </tr>
                  <tr>
                    <th>Free Brokers</th>
                    <td className='text-right'>{stats.free_brokers}</td>
                  </tr>
                  <tr>
                    <th>Free Shippers</th>
                    <td className='text-right'>{stats.free_shippers}</td>
                  </tr>
                  <tr>
                    <th>Shipper Loads Awarded</th>
                    <td className='text-right'>{stats.shipper_awarded_bids}</td>
                  </tr>
                  <tr>
                    <th>Broker Loads Awarded</th>
                    <td className='text-right'>{stats.broker_awarded_bids}</td>
                  </tr>
                  <tr>
                    <th>Shipper Loads Posted</th>
                    <td className='text-right'>{stats.total_shipper_loads}</td>
                  </tr>
                  <tr>
                    <th>Broker Loads Posted</th>
                    <td className='text-right'>{stats.total_broker_loads}</td>
                  </tr>
                  <tr>
                    <th>Total Loads Dispatched</th>
                    <td className='text-right'>{stats.total_dispatched_count}</td>
                  </tr>
                  <tr>
                    <th>Total Dispatch Revenue</th>
                    <td className='text-right'>{stats.total_dispatched_amount ? formatPennies(stats.total_dispatched_amount) : '$0'}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            : null
        }
        {
          show_pie_charts ?
            <button type='button' className='btn btn-default' onClick={() => this.setState({ show_pie_charts: false })}>Hide Pie Charts</button>
            :
            <button type='button' className='btn btn-default' onClick={() => this.setState({ show_pie_charts: true })}>Show Pie Charts</button>
        }
        {
          // only start to show this for lower-level admins after the AdminDropdownInput has selected ourselves
          !show_pie_charts || self.data.type !== USER_TYPE.ADMIN && (!selectedAdmin || !selectedAdmin.id) ? null :
          <AdminDashboardStats selectedAdmin={selectedAdmin} />
        }
        {
          stats && stats.factoring_state_source_results && self.data.type === USER_TYPE.ADMIN ?
            <div>
              <h4>
                {
                  [
                    'Factoring Clients by State',
                    state ? ` - ${state}` : '',
                  ].reduce((acc, curr) => acc + curr, '')
                }
              </h4>
              {
                state ?
                  <button type='button' className='btn btn-default' onClick={() => { change('state', ''); setTimeout(submit); }}>Clear State</button> // eslint-disable-line brace-style
                  : null
              }
              {/* <SourceGraphUnitedStatesMap
                data={stats.factoring_state_source_results}
                onClickState={state => {
                  change('state', state);
                  submit();
                }}
              /> */}
            </div>
            : null
        }
        {
          stats && stats.user_source_results && self.data.type === USER_TYPE.ADMIN ?
            <div>
              <SourceGraphFactoringClient data={stats.user_source_results} />
              <SourceGraphFactoringPaymentSum data={stats.funding_request_source_results} />
            </div>
            : null
        }
      </div>
    );
  }
});
