/**
 * Reducer for handling the timeline's paginated activity resource
 * @module reducers/activity
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/activity
 * @listens ACTIVITY_SET_FILTER
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.activity;

import {
  ACTIVITY_SET_FILTER,
  activityPaginatedResource,
} from '../actions/resource/activity';


export default function activityPaginatedReducer(state = initialState, action) {
  state = activityPaginatedResource.reduce(state, action);
  switch (action.type) {
    case ACTIVITY_SET_FILTER:
      return {
        ...state,
        globals: {
          ...state.globals,
          filter: action.payload,
        },
      };
  }
  return state;
}
