import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { closeModal, openModal } from 'actions/ui';
import { connect } from 'react-redux-v5';
import { reSubmitPurchase } from 'actions/admin/financespurchases';
import formatError from 'helpers/formatError';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 20,
    color: '#5c6979',
    textAlign: 'center',
    fontWeight: '100',
  },
  icon: {
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
};
const FailedPayment = ({
  closeModal,
  reSubmitPurchase,
  id,
  openModal,
  ...props
}) => (
  <div >
    <ModalBody closeModal={closeModal}>
      <div >
        <div
          style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
          >
          <MaterialIcon
            name='error'
            size={60}
            style={styles.icon}
          />
        </div>
        <div style={{ ...styles.flexRow, justifyContent: 'center', paddingBottom: '15px' }}>
          <p style={styles.text}>
            Please verify that the client's account information has been corrected.
          </p>
        </div>
        <div style={{ ...styles.flexRow, justifyContent: 'center', paddingBottom: '15px' }}>
          <p style={styles.text}>
            Are you sure you want to re-send this purchase?
          </p>
        </div>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, margin: '-5px 5px 5px 5px' }}>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
        <Button
          style={{
            borderColor: '#FF7800',
            color: '#FF7800',
          }}
          onClick={() => {
            closeModal();
          }}
          >
          Cancel
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        >
        <Button
          style={styles.button}
          onClick={async () => {
            try {
              await reSubmitPurchase({ id });
              closeModal();
              openModal('success', 'Purchase submitted.');
            }
            catch (err) {
              closeModal();
              openModal('error', formatError(err, 'Error while re-submitting purchase'));
            }
          }}
          >
          Confirm
        </Button>
      </div>
    </div>
  </div>
);

export default
connect(() => ({}),
  dispatch => ({
    closeModal: () => dispatch(closeModal()),
    openModal: (type, message) => dispatch(openModal(type, { message })),
    reSubmitPurchase: data => dispatch(reSubmitPurchase(data)),
  }),
)(FailedPayment);
