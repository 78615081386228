import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import Modal from 'components/hoc/Modal';
import getSelf from 'components/hoc/getSelf';
import ModalWarning from './Warning';


export const ConfirmFactoringApplyForm = compose(
  reduxForm({
    form: 'ConfirmFactoringApplyForm',
    destroyOnUnmount: false,
    onSubmit(fields) {
      console.log(fields);
    },
  }),
)(({ handleSubmit }) =>
  <ModalWarning
    headerChildren='Warning'
    message={
      <div>
        <p>
          <div>{'We noticed that you do not have an MC number on your user profile. That\'s ok!'}</div>
          <div>Please enter your MC# below to continue. We need this to process your application.</div>
        </p>
        <Field name='mc' component={LabeledInput} label='MC#' />
      </div>
    }
    buttonChildren='Cancel'
    extraButtons={[
      <button className='btn btn-orange' onClick={handleSubmit}>Apply Now</button>,
    ]}
  />
);

export const ConfirmFactoringApply = compose(
  Modal,
  getSelf,
)(({ item, dispatch, id, ...rest }) => {
  if (!item || !item.data || !item.data.mc) {
    return <ConfirmFactoringApplyForm />;
  }
  return (
    <ModalWarning
      {...rest}
      headerChildren='Warning'
      message={'Is your profile information correct? Please make sure your company\'s profile information is accurate and correct before applying for access.'}
      buttonChildren='Cancel'
      extraButtons={[
        <button className='btn btn-orange' onClick={undefined}>Apply Now</button>,
      ]}
    />
  );
});

ConfirmFactoringApply.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmFactoringApply;
