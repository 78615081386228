import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import { Link } from 'react-router-v4';

import factoringtransactionpaymentsPaginatedResource from 'actions/admin/factoringtransactionpayments';

import ResourceByIDs from 'components/hoc/ResourceByIDs';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';


const tableItems = createTableItems(
  props => [
    props.data.category,
    props.data.funding_request,
    props.data.amount,
    props.data.source,
    props.data.status,
    props.data.assigned_admin ? props.data.assigned_admin.name : null,
    <Link to={`/admin/finances/payments/${props.data.id}`}>
      <button className='btn btn-orange' href='payments/'> More </button>
    </Link>,
  ],
);

export default compose(
  connect(
    state => {
      return {
        ids: state.admin.factoringtransactionpayments.ids,
        isFetching: state.admin.factoringtransactionpayments.isFetching,
      };
    },
    {
      clear: factoringtransactionpaymentsPaginatedResource.clear,
      fetch: factoringtransactionpaymentsPaginatedResource.fetch,
      fetchNext: factoringtransactionpaymentsPaginatedResource.fetchNext,
    },
  ),
  ResourceByIDs({
    resource: 'factoringtransactionpayment',
    idPropName: ['ids'],
  }),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch();
    },
  }),
)(({ items, fetchNext, isFetching }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Category', 'Amount', 'Source', 'Status', 'Assigned Admin', '']}
    isFetching={isFetching}
    fetchNext={fetchNext}
    items={items}
    TableItem={tableItems.TableItem}
    BlockTableItem={tableItems.BlockTableItem}
  />
);
