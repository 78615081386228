import 'styles/Thumbnail';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';

import { openModal } from 'actions/ui';

import objectURLSrc from 'components/hoc/objectURLsrc';


const Thumbnail = compose(
  connect(
    null,
    (dispatch, { src }) => ({
      openModal: () => dispatch(openModal('image_preview', { src })),
    }),
  ),
  objectURLSrc(),
)(class Thumbnail extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      hasErrored: false,
    };
    this.onClick = this.onClick.bind(this);
  }

  componentDidMount() {
    this.image.onerror = () => {
      this.setState({ hasErrored: true });
    };
  }

  static propTypes = {
    src: PropTypes.string.isRequired,
  };

  onClick() {
    if (this.state.hasErrored) {
      window.open(this.props.src);
    }
    else {
      this.props.openModal();
    }
  }

  render() {
    const { openModal, ...rest } = this.props;
    const { hasErrored } = this.state;

    if (hasErrored) {
      return (
        <div
          onClick={this.onClick}
          role='button'
          className={cs('thumbnail thumbnail-error', rest.className)}
        />
      );
    }

    return (
      <img
        role='button'
        onClick={this.onClick}
        {...rest}
        ref={ref => this.image = ref}
        className={cs('thumbnail', rest.className)}
      />
    );
  }
});

export default Thumbnail;
