import React from 'react';
import PropTypes from 'prop-types';

import formatAddress from 'helpers/formatAddress';

import Tooltip from 'components/pure/Tooltip';


const interpolateSeparators = (arr, separators, prefix) => {
  return arr.reduce((acc, location, i, arr) => {
    if (prefix) {
      acc.push(...prefix.map(prefix => {
        if (typeof prefix === 'function') {
          return prefix(location, i, arr);
        }
        return prefix;
      }));
    }
    acc.push(location);
    if (i !== arr.length - 1) {
      acc.push(...separators.map(separator => {
        if (typeof separator === 'function') {
          return separator(location, i, arr);
        }
        return separator;
      }));
    }
    return acc;
  }, []);
};

const AddressesFormat = ({
  addresses,
  truncation_limit = 1,
  displayed_location_separator = ['; '],
  tooltip_location_separator = [' +', <br />],
  ignore,
  tooltip_location_prefix,
  displayed_location_prefix,
}) => {
  if (!addresses) {
    return <span />;
  }

  const formatted_addresses = addresses.map(location => formatAddress(location, ignore));
  if (formatted_addresses.length > truncation_limit) {
    const displayed_formatted_addresses = interpolateSeparators(formatted_addresses.slice(0, truncation_limit), displayed_location_separator, displayed_location_prefix);
    const tooltip_formatted_addresses = interpolateSeparators(formatted_addresses.slice(truncation_limit), tooltip_location_separator, tooltip_location_prefix);
    return (
      <span>
        {displayed_formatted_addresses}
        {' '}
        <Tooltip text={tooltip_formatted_addresses}>
          <span>(+{formatted_addresses.length - truncation_limit})</span>
        </Tooltip>
      </span>
    );
  }
  return (
    <span>
      {interpolateSeparators(formatted_addresses, displayed_location_separator, displayed_location_prefix)}
    </span>
  );
};

AddressesFormat.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default AddressesFormat;
