/**
 * Reducer for user actions
 * @module reducers/user
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/user
 * @requires datatypes/APIFetch
 * @listens USER_ANON_LOGIN
 * @listens USER_LOGIN_REQUEST
 * @listens USER_LOGIN_SUCCESS
 * @listens USER_LOGIN_FAILURE
 * @listens USER_LOGOUT
 * @listens USER_AUTO_LOGIN_FAILURE
 * @listens USER_TOKEN_SUCCESS
 * @listens USER_SUBSCRIBE_REQUEST
 * @listens USER_SUBSCRIBE_REQUEST_SUCCESS
 * @listens SUBSCRIPTION_STATUS_REQUEST
 * @listens SUBSCRIPTION_STATUS_REQUEST_SUCCESS
 * @listens SUBSCRIPTION_CANCEL_REQUEST
 * @listens SUBSCRIPTION_CANCEL_REQUEST_SUCCESS
 * @listens UNAUTHORIZED_LOGOUT
 */
import moment from 'moment';
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.user;

import {
  DISPATCHING_STATUS_REQUEST,
  DISPATCHING_STATUS_REQUEST_SUCCESS,
  FACTORING_PROFILE_REQUEST,
  FACTORING_PROFILE_REQUEST_FAILURE,
  FACTORING_PROFILE_REQUEST_SUCCESS,
  SUBSCRIPTION_CANCEL_REQUEST,
  SUBSCRIPTION_CANCEL_REQUEST_SUCCESS,

  SUBSCRIPTION_STATUS_REQUEST,
  SUBSCRIPTION_STATUS_REQUEST_SUCCESS,

  USER_ANON_LOGIN,
  USER_AUTO_LOGIN_FAILURE,
  USER_LOGIN_FAILURE,
  USER_LOGIN_REQUEST,

  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_SUBSCRIBE_REQUEST,

  USER_SUBSCRIBE_REQUEST_SUCCESS,
  USER_TOKEN_SUCCESS,
} from 'actions/user';
import { UNAUTHORIZED_LOGOUT } from 'datatypes/APIFetch';


export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_TOKEN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        refreshToken: action.payload.refreshToken,
      };
    case USER_AUTO_LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        logged_in: false,
        isLoggingIn: false,
      };
    case UNAUTHORIZED_LOGOUT:
    case USER_LOGOUT:
      return {
        ...initialState,
        token: null,
        loading: false,
        logged_in: false,
      };
    case USER_LOGIN_REQUEST:
      return {
        ...state,
        isLoggingIn: true,
      };
    case USER_ANON_LOGIN:
      return {
        ...state,
        loading: false,
        isLoggingIn: false,
      };
    case USER_LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        logged_in: true,
        isLoggingIn: false,
        token: action.payload.token,
        id: action.payload.data.id,
      };
    case USER_LOGIN_FAILURE:
      return {
        ...state,
        token: null,
        loading: false,
        logged_in: false,
        isLoggingIn: false,
      };

    case USER_SUBSCRIBE_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isFetching: true,
        },
      };
    case USER_SUBSCRIBE_REQUEST_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isFetching: false,
        },
      };

    case SUBSCRIPTION_STATUS_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isFetching: true,
        },
      };
    case SUBSCRIPTION_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isFetching: false,
          data: action.payload,
        },
      };
    case SUBSCRIPTION_CANCEL_REQUEST:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isDeleting: true,
        },
      };
    case SUBSCRIPTION_CANCEL_REQUEST_SUCCESS:
      return {
        ...state,
        subscription: {
          ...state.subscription,
          isDeleting: false,
          data: {
            ...state.subscription.data,
            canceled_at: moment.utc().format(),
            cancel_at_period_end: true,
          },
        },
      };
    case FACTORING_PROFILE_REQUEST:
      return {
        ...state,
        factoring: {
          ...state.factoring,
          isFetching: true,
          err: null,
        },
      };
    case FACTORING_PROFILE_REQUEST_SUCCESS:
      return {
        ...state,
        factoring: {
          ...state.factoring,
          isFetching: false,
          data: action.payload,
          err: null,
        },
      };
    case FACTORING_PROFILE_REQUEST_FAILURE:
      return {
        ...state,
        factoring: {
          ...state.factoring,
          isFetching: false,
          data: null,
          err: action.payload,
        },
      };

    case DISPATCHING_STATUS_REQUEST:
      return {
        ...state,
        dispatching: {
          ...state.dispatching,
          isFetching: true,
        },
      };
    case DISPATCHING_STATUS_REQUEST_SUCCESS:
      return {
        ...state,
        dispatching: {
          ...state.dispatching,
          isFetching: false,
          ...action.payload,
        },
      };
  }
  return state;
}
