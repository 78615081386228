import React, { useState, useCallback } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import Paper from 'material-ui/Paper';
import Menu from 'material-ui/Menu';
import MenuItem from 'material-ui/MenuItem';
import Divider from 'material-ui/Divider';

import errorHandler from 'helpers/errorHandler';

import { activationStripeCard } from 'actions/stripeCards';
import { openModal } from 'actions/ui';


function SettingsMenu({ cardId, status, openModal, accountId }) {
    const [active, setActive] = useState(status);

    const updateCardStatus = useCallback(async status => {
        try {
            const res = await activationStripeCard(accountId, { status, id: cardId });
            const message = status === 'active' ? 'You card has been activated' : 'You card has been has been deactivated';
            openModal('success', { message });
            setActive(status);
        } catch (e) {
            openModal('error', { message: errorHandler(err).join('\n') });
        }
    }, [cardId, status]);

    return (
        <Menu desktop={true}>
            {active !== 'active' ?
                <MenuItem primaryText='Activate' onClick={() => updateCardStatus('active')} />
                : <MenuItem primaryText='Inactive' onClick={() => updateCardStatus('inactive')} />
            }
        </Menu>
    )
}

export default compose(
    connect(null,
        {
            openModal,
        })
)(SettingsMenu);