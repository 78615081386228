import React from 'react';
import { USER_TYPE } from 'helpers';
import { Field } from 'redux-form';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';

const disable_set_status = status => {
  return ['paid'].includes(status);
};

const StatusField = ({ userType, path, isDispatchBroker = false, isAnyAdmin = false }) => {
  if (isDispatchBroker && !isAnyAdmin) {
    return (
      <Field
        name='status'
        className='text-left'
        component={LabeledDropdown}
        data={[
          { text: 'Pending', value: 'pending' },
          { text: 'Document Issue', value: 'document_issue' },
          { text: 'Incomplete', value: 'incomplete' },
          { text: 'Remote Approved', value: 'remote_approved' },
          { text: 'Declined', value: 'declined' },
          { text: 'Paid', value: 'paid' },
        ]}
        label='Status'
        horizontal={true}
      />
    );
  }
  else if (userType === USER_TYPE.ALADDIN_ADMIN) {
    return (
      <Field
        name='status'
        className='text-left'
        component={LabeledDropdown}
        data={[
          { text: 'New (NOA)', value: 'new_noa' },
          { text: 'New', value: 'new' },
          { text: 'Fuel Advance', value: 'fuel_advance' },
          { text: 'Pending', value: 'pending' },
          { text: 'Pending (NOA)', value: 'pending_noa' },
          { text: 'Pending Originals', value: 'pending_originals' },
          { text: 'Document Review', value: 'document_review' },
          { text: 'Approved', value: 'approved' },
          { text: 'Remote Approved', value: 'remote_approved' },
          { text: 'Declined', value: 'declined' },
          { text: 'Paid', value: 'paid' },
        ]}
        label='Status'
        horizontal={true}
      />
    );
  }
  if (userType === USER_TYPE.FACTORING_REMOTE_ADMIN || disable_set_status(path)) {
    return null;
  }
  return (
    <Field
      name='status'
      className='text-left'
      component={LabeledDropdown}
      data={[
        { text: 'New (NOA)', value: 'new_noa' },
        { text: 'New', value: 'new' },
        { text: 'Fuel Advance', value: 'fuel_advance' },
        { text: 'Pending', value: 'pending' },
        { text: 'Pending (NOA)', value: 'pending_noa' },
        { text: 'Pending Originals', value: 'pending_originals' },
        { text: 'Special Pending', value: 'special_pending' },
        { text: 'Document Review', value: 'document_review' },
        { text: 'CF Review', value: 'haulpay_review' },
        { text: 'Special Pending', value: 'special_pending' },
        { text: 'Incomplete', value: 'incomplete' },
        { text: 'Approved', value: 'approved' },
        { text: 'Remote Approved', value: 'remote_approved' },
        { text: 'Declined', value: 'declined' },
        { text: 'Paid', value: 'paid' },
      ]}
      label='Status'
      horizontal={true}
    />
  );


};

export default StatusField;
