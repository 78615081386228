import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import Resource from 'components/hoc/Resource';
import nothingIfFetching from 'components/hoc/nothingIfFetching';


export default compose(
  connect(
    state => ({
      id: state.user.id,
    }),
    {},
    null,
    { withRef: true }
  ),
  Resource('user'),
  nothingIfFetching(),
);
