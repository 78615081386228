/**
 * Actions relating to the factoringpayment resource
 * @module actions/resource/factoringpayment
 * @since 3.0.1
 * @requires datatypes/compose/resource
 * @requires datatypes/compose/resource/Editable
 * @requires datatypes/FetchError
 * @requires datatypes/DotNotationFormData
 */
/* global API */
import { compose } from 'redux-v3';

import Editable from 'datatypes/compose/resource/Editable';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';


class DefaultBankResource extends compose(
  Editable(),
)() {
  edit(data, user_id, id) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, id);
      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });
      try {
        const res = await dispatch(APIFetch(`${API.host}/user/${user_id}/accounts/${id}/`, {
          method: 'PATCH',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: {
            id,
            json,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
  }
const defaultbank = new DefaultBankResource('defaultbank');

export const {
  selector,
  fetchIfNeeded,
  create,
  edit,
  fetch,
} = defaultbank;
export default defaultbank;
