import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';
import get from 'lodash/get';

import 'styles/Tables';

import financePurchasesItems from 'actions/admin/financepurchasesitems';

import MaterialIcon from 'components/pure/MaterialIcon';
import ResponsiveTable from 'components/pure/ResponsiveTable';

import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import CATEGORIES from 'helpers/FACTORING_ITEMS_CATEGORIES';
import STATUS from 'helpers/PAYMENTS_STATUS';

const constructTableCells = ({ data }) => [
  data.invoice_number,
  data.user_load_number,
  data.assigned_admin.name,
  formatPennies(data.amount),
  CATEGORIES[data.category],
  data.admin_notes,
  STATUS[data.status],
  moment(data.created).format('l LTS'),
  <Link to={`/admin/factoring/transaction/${data.funding_request}`} target='_blank' rel='noopener noreferrer'>
    <button className='btn btn-primary'>
      <MaterialIcon name='remove_red_eye' />
    </button>
  </Link>,
];

const TableComponents = createTableItems(constructTableCells);

const PurchasesFundingRequestsTable = compose(
  connect(
    state => ({
      ids: state.admin.financesPurchasesItems.ids,
      payments: state.resource.financesPurchasesItems,
      isFetching: state.admin.financesPurchasesItems.isFetching,
      count: state.admin.financesPurchasesItems.count,
      limit: state.admin.financesPurchasesItems.limit,
      currentOrder: state.admin.financesPurchasesItems.ordering,
    }),
    {
      fetchPage: financePurchasesItems.fetchPage,
    }
  )
)(({ ids, isFetching, fetchPage, payments, count, limit }) => (
  <ResponsiveTable
    isFetching={isFetching}
    fetchPage={fetchPage}
    count={count}
    limit={limit}
    hideHeadersIfEmpty={true}
    headers={['Invoice#', 'Load', 'Admin', 'Amount', 'Category', 'Notes', 'Status', 'Requested At', 'Details']}
    placeholder='No transactions found'
    items={ids.map(id => payments[id])}
    TableItem={TableComponents.TableItem}
    BlockTableItem={TableComponents.BlockTableItem}
    desktopContainerProps={{
      className: 'Table_Inverse',
    }}
    mobileContainerProps={{
      className: 'Table_Inverse',
    }}
  />
));

const ConnectedPurchasesFundingRequestsTable = compose(
  connect(null, (dispatch, { purchaseId }) => {
    return {
      update() {
        dispatch(financePurchasesItems.clear());
        return dispatch(
          financePurchasesItems.fetch({
            id: purchaseId,
          })
        );
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  })
)(PurchasesFundingRequestsTable);

export default ConnectedPurchasesFundingRequestsTable;
