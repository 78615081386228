import 'styles/LoadDetails';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import cs from 'classnames';

import {
  USER_TYPE,
  ageAbbr,
  checkPermissions,
  formatWeight,
  trailerTypeMap,
} from 'helpers';
import states from 'helpers/states';
import formatPennies from 'helpers/formatPennies';
import emailIsInvalid from 'helpers/emailIsInvalid';
import PermissionsError from 'datatypes/PermissionsError';

import docTitle from 'components/hoc/docTitle';
import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import { CenteredSpinner } from 'components/pure/Spinner';
import Error from 'components/pure/Error';

import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import ResponsiveContainer from 'components/pure/ResponsiveContainer';
import UserTable from 'components/pure/PureUserTable';
import SocialMediaShareButtons from 'components/pure/SocialMediaShareButtons';
import LoadMobile from 'components/container/LoadMobile';
import BidsOtherUser from './BidsOtherUser';
import LoadReverseTable from './LoadReverseTable';
import { LoadQaCollection } from 'components/container/QaCollection';
import TableItemAddonBids from 'components/container/TableItemAddonBids';
import RateIndexBlockTable from 'components/container/RateIndexBlockTable';
import UserTableHideButtons from 'components/container/UserTableHideButtons';
import LoadDetailsMap from 'components/container/LoadDetailsMap';
import SignUpButtonLink from 'components/container/SignUpButtonLink';
import UpgradeToViewButton from 'components/container/UpgradeToViewButton';
import ButtonQuickPayApproved from 'components/container/ButtonQuickPayApproved';
import LoadDetailsLaneTrends from 'components/container/LoadDetailsLaneTrends';
import ButtonSaveLoad from 'components/container/ButtonSaveLoad';
import ButtonRefresh from 'components/container/ButtonRefresh';
import LoadStatusForm from 'components/pure/form/LoadStatusForm';
import Table from 'components/pure/Table';
import UserRating2 from 'components/container/UserRating2';
import AddressesFormat from 'components/pure/AddressesFormat';
import AverageDaysToPay from 'components/pure/AverageDaysToPay';
import intersectionBy from 'lodash/intersectionBy';
import get from 'lodash/get';


const LoadDetailsTableItem = ({ item, is_self }) => {
  return (
    item && item.data ?
      <tbody>
        <tr>
          <td>{ageAbbr(item.data.time_posted)}</td>
          <td>{trailerTypeMap[item.data.trailerType] || item.data.trailerType}</td>
          <td>
            <AddressesFormat addresses={item.data.origin_locations} />
          </td>
          <td>
            <AddressesFormat addresses={item.data.destination_locations} />
          </td>
          <td>{item.data.weight === 0 ? '—' : `${formatWeight(item.data.weight)} lbs`}</td>
          <td>{item.data.length === 0 ? '—' : `${item.data.length} ft`}</td>
          <td>{item.data.time_start.format('MM/DD/YYYY h:mm A')}</td>
          <td>{item.data.time_end.format('MM/DD/YYYY h:mm A')}</td>
          <td>{item.data.time_delivery ? item.data.time_delivery.format('MM/DD/YYYY') : '—'}</td>
        </tr>
        {
          is_self ?
            <tr>
              <td colSpan={9} style={{ padding: 0 }}>
                <TableItemAddonBids defaultOpen={true} load_id={item.data.id} />
              </td>
            </tr>
            : null
        }
      </tbody>
      : null
  );
};

export default compose(
  mapProps(({ routeParams: { id } }) => ({ id })),
  docTitle(props => `Load Details - ${props.id}`),
  Resource('load', {
    expirationTime: -Infinity,
  }),
  // spin while loading load
  spinnerIfFetching('item', CenteredSpinner),
  // display error if we errored
  branch(
    ({ item }) => item.err !== null,
    compose(
      mapProps(({ item }) => ({ ...item.err })),
      renderComponent(Error),
    ),
    Component => Component,
  ),
  connect(
    (state, { item }) => {
      const userGroup = get(state, `resource.user[${state.user.id}].data.groups`, []);
      return {
        logged_in: state.user.logged_in,
        is_self: item.data.user_id === state.user.id,
        isLoadGroup: intersectionBy(get(item.data, 'user.data.groups', []), userGroup, 'id').length,
      };
    },
  ),
)(({ item, is_self, isLoadGroup, logged_in }) => {
  return (
    <CenteredColumn
      className={cs('load-details', {
        'load-details-shipper': item.data.user_type === USER_TYPE.SHIPPER,
      })}
      xs={12}
      sm={9}
      >
      <Header className='clearfix' style={{ display: 'flex', flexWrap: 'wrap' }}>
        {
          is_self ?
            <div style={{ marginRight: '1em' }}>My Load Details</div>
            :
            item.data.user_type === USER_TYPE.SHIPPER ?
              <div style={{ marginRight: '1em' }}>
                <span className='visible-xs-inline-block'>Load Details</span>
                <span className='hidden-xs'>Shipper Name: {checkPermissions(item.data.user, <strong>{item.data.user.data.dba}</strong>, <UpgradeToViewButton />)}</span>
              </div>
              :
              <div style={{ marginRight: '1em' }}>Load Details</div>
        }
        <div style={{ display: 'flex', flexGrow: 1, justifyContent: 'space-between' }}>
          <div>
            { item.data.user_load_number ? ` -- Load #${item.data.user_load_number}` : null }
            {
              is_self ?
                <ButtonRefresh style={{ marginLeft: '16px' }} type='load' id={item.data.id}>Refresh Age</ButtonRefresh>
                :
                <ButtonSaveLoad style={{ marginLeft: '16px' }} id={item.data.id} />
            }
          </div>
          {
            item.data.user_type === USER_TYPE.SHIPPER ?
              <SocialMediaShareButtons
                title={`Find Shipper Loads in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state})`}
                description={`Shipper Load Available For Pick Up in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state}). Login or sign up to match your truck to this load or bid on this load now.`}
              />
              :
              <SocialMediaShareButtons
                title={`Find Freight Loads in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state})`}
                description={`Freight Load Available For Pick Up in ${item.data.origin_city ? `${item.data.origin_city}, ` : ''}${states[item.data.origin_state]} (${item.data.origin_state}). Login or sign up to match your truck to this load or bid on this load now.`}
              />
          }
        </div>
      </Header>
      <SignUpButtonLink style={{ marginBottom: '1em' }} />
      <ResponsiveContainer
        mobile={
          <div>
            <LoadMobile id={item.data.id} is_self={is_self} />
            {is_self || isLoadGroup ? <TableItemAddonBids defaultOpen={true} load_id={item.data.id} /> : null}
          </div>
        }
        desktop={
          <div>
            <Table
              style={{
                textAlign: 'left',
                overflow: 'visible',
              }}
              headers={['Age', 'Type', 'Origin', 'Destination', 'Weight', 'Length', 'Ready', 'Close', 'Deliver']}
              items={[{ item, is_self }]}
              ListItem={LoadDetailsTableItem}
            />
            {
              (item.data.user_type === USER_TYPE.SHIPPER) || is_self ? null :
              <UserTable
                items={[{
                    item: item.data.user,
                    load_id: item.data.id,
                  }]}
                dontShowBidding={[USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(item.data.user_type)}
                isShipperOnly={item.data.user_type === USER_TYPE.SHIPPER}
              />
            }
            {item.data.user_type === USER_TYPE.SHIPPER ? <UserRating2 load_id={item.data.id} user_id={item.data.user.data.id} openRateModal={true} /> : null}
            {item.data.user_type === USER_TYPE.SHIPPER ? <AverageDaysToPay avgpay={item.data.user.data.avgpay} /> : null}
            {item.data.user_type === USER_TYPE.SHIPPER && item.data.user.data.credit_approved ? <ButtonQuickPayApproved load_id={item.data.id} /> : null}
            {item.data.user_type === USER_TYPE.SHIPPER ? <UserTableHideButtons id={item.data.id} user_id={item.data.user_id} className='hidden-xs' style={{ margin: '1em 0' }} /> : null}
          </div>
        }
      />
      {
        is_self || !logged_in ? null :
        <div style={{ margin: '0.5em' }}>
          Status: <LoadStatusForm id={item.data.id} />
        </div>
      }
      <div>
        <Header>Notes:</Header>
        <p>{checkPermissions(item.data.user, item.data.details || 'No details specified', <UpgradeToViewButton />)}</p>
      </div>
      <div>
        <Header>Target Rate: {item.data.targetRate ? formatPennies(item.data.targetRate) : 'None'}</Header>
      </div>
      {
        // don't show bid and qa sections of broker loads
        [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(item.data.user_type) &&
        !(item.data.user.err instanceof PermissionsError) &&
        (
          emailIsInvalid(item.data.user.data.contact_email) &&
          emailIsInvalid(item.data.user.data.email)
        )
          ? null :
          <div>
            <BidsOtherUser id={item.data.id} />
            <LoadQaCollection id={item.data.id} />
          </div>
      }
      {
        (item.data.origin_city === item.data.destination_city && item.data.origin_state === item.data.destination_state) ? null :
        <div>
          <Header>Market Average:</Header>
          <p><em>Based on truckload bid trend for this lane.</em></p>
          <RateIndexBlockTable id={item.data.id} />
          <LoadDetailsLaneTrends id={item.data.id} />
        </div>
      }
      <Header>Map:</Header>
      <LoadDetailsMap id={item.data.id} />
      <div>
        <Header style={{ marginBottom: 0 }}>Directions:</Header>
        <p className='text-warning' style={{ margin: 0 }}>Please note that directions may be different from truck route specific GPS directions.</p>
        <a
          href={`https://maps.google.com/maps?saddr=${item.data.origin_address},${item.data.origin_city},${item.data.origin_state}&daddr=${item.data.destination_address},${item.data.destination_city},${item.data.destination_state}&dirflg=t`}
          target='_blank'
          rel='noopener noreferrer'
          >
          <Header className='text-success' role='button' style={{ marginTop: 0 }}><b>+ Open Directions</b></Header>
        </a>
      </div>
      <Header>Return Loads:</Header>
      <LoadReverseTable id={item.data.id} />
    </CenteredColumn>
  );


});
