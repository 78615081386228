import React from 'react';


const DispatchPricingTable = () =>
  <table className='table'>
    <thead>
      <tr>
        <th>Miles</th>
        <th>Charge</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td>1 - 500</td>
        <td>$40</td>
      </tr>
      <tr>
        <td>500 - 999</td>
        <td>$60</td>
      </tr>
      <tr>
        <td>1000+</td>
        <td>$100</td>
      </tr>
    </tbody>
  </table>
  ;

DispatchPricingTable.getPricingForDistance = distance => {
  return distance.value >= 1.609e+6 ? 10000 : distance.value > 804672 ? 6000 : 4000;
};

export default DispatchPricingTable;
