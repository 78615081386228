import React from 'react';
import { compose } from 'redux-v3';
import { Field, SubmissionError, reduxForm } from 'redux-form';
import { Link } from 'react-router-v4';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';
import { get } from 'lodash';

import { useHubspotTracking } from '@haulpay/hooks';
import { login } from '../../../actions/user';

import storage from '../../../datatypes/storage';

import Header from '../../../components/pure/Header';
import LabeledInput from '../../../components/pure/form/inputs/LabeledInput';
import CheckboxInput from '../../../components/pure/form/inputs/CheckboxInput';


const remember_me_key = 'remember_me';

export default compose(
  mapProps(props => {
    const remember_me = storage.getItem(remember_me_key);
    const { setTrackEvent } = useHubspotTracking();
    if (remember_me) {
      return {
        initialValues: {
          email: remember_me,
          remember_me: true,
        },
        setTrackEvent,
        ...props,
      };
    }
    return {
      ...props,
      setTrackEvent
    };
  }),
  reduxForm({
    form: 'LoginForm',
    async onSubmit(fields, dispatch, { setTrackEvent }) {
      try {
        const user = await dispatch(login({
          username: fields.email,
          password: fields.password,
        }));
        if (fields.remember_me) {
          storage.setItem(remember_me_key, fields.email);
        }
        setTrackEvent('login', user);
      }
      catch (err) {
        console.warn(err);
        let _error;
        if (err.error_description || err.message.error_description) {
          _error = err.error_description || err.message.error_description;
          if (_error === 'Invalid credentials given.') {
            _error = 'Login Credentials Incorrect.';
          }
        }
        else if (err.error) {
          _error = err.error;
        }
        else if (err.message) {
          _error = err.message;
        }
        else {
          _error = 'Failed to log in.';
        }
        throw new SubmissionError({ _error });
      }
    },
    validate(values) {
      return validate(values, {
        email: {
          presence: true,
          email: true,
        },
        password: {
          presence: true,
          length: {
            minimum: 4,
            maximum: 30,
          },
        },
      });
    },
  }),
)(class LoginForm extends React.Component {
  state = {
    should_show_password_field: false,
  };

  componentDidMount() {
    const token = get(this.props, ['location', 'query', 'as']);
    console.error(token);
    if (token) {
      this.props.dispatch(login(token));
    }
  }

  render() {
    const { handleSubmit, submitting, error } = this.props;
    const { should_show_password_field } = this.state;

    return (
      <form name='login' onSubmit={handleSubmit} className='login login_responsive'>
        <Header>Login</Header>
        <Field
          name='email'
          component={LabeledInput}
          label='Email'
          type='email'
          normalize={value => value.toLowerCase()}
          style={{
            height: 42,
            fontSize: '1.25em',
          }}
        />
        <Field
          name='password'
          component={LabeledInput}
          label='Password'
          type={should_show_password_field ? undefined : 'password'}
          style={{
            height: 42,
            fontSize: '1.25em',
          }}
        />
        <Field
          name='remember_me'
          component={CheckboxInput}
          label='Remember me'
          style={{
            height: 42,
            fontSize: '1.25em',
          }}
        />
        {' '}
        <CheckboxInput
          label='Show Password'
          input={{
            value: should_show_password_field,
            onChange: val => this.setState({ should_show_password_field: Boolean(val) }),
          }}
        />
        <div className='help-block'>Not signed up? <Link to='/signup'>Sign up here</Link></div>
        {error && <div className='alert alert-danger' role='alert'>Error: {error}</div>}
        <div>
          <button name='login' className='btn-transaction' type='submit' disabled={submitting} style={{ fontSize: '1.3em' }}>Login</button>
          {' | '}
          <Link to='/resetpassword'>Forgot password?</Link>
        </div>
      </form>
    );
  }
});
