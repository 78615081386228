import React from 'react';
import { browserHistory } from 'react-router-v4';


/**
 * @typedef options
 * @param {string} options.to - the url to redirect to
 * @param {function} options.if - the function determining whether to redirect
 */
export default options => {
  function redirectHandler(nextProps = this.props) {
    if (Array.isArray(options)) {
      options.some(options => {
        if (options.if(nextProps)) {
          browserHistory.replace(options.to);
          return true;
        }
      });
    }
    else {
      if (options.if(nextProps)) {
        browserHistory.replace(options.to);
      }
    }
  }
  return WrappedComponent => class redirectIf extends React.Component {
    componentWillMount() {
      return redirectHandler.apply(this, arguments);
    }
    componentWillUpdate() {
      return redirectHandler.apply(this, arguments);
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  };
};
