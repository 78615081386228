import React from 'react';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CleaveInput from 'components/pure/form/inputs/CleaveInput';

const ZipcodeInput = props =>
  <LabeledInput
    ComponentClass={CleaveInput}
    type='tel'
    options={{
      blocks: [5],
    }}
    {...props}
  />
  ;

export default ZipcodeInput;
