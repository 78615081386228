/**
 * Actions for the documents section in settings
 * @module actions/settings/documents
 * @since 3.0.1
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/PermissionsError
 */
/* global API */
import moment from 'moment';

import APIFetch from '../../datatypes/APIFetch';
import FetchError from '../../datatypes/FetchError';
import PermissionsError from '../../datatypes/PermissionsError';

/**
 * @typedef Document
 * @param {string} id
 * @param {string} user_label
 * @param {string} user
 * @param {moment} time_posted
 * @param {moment} modfied
 * @param {string} filename
 */

/**
 * Upload request success action. Marks that a request to upload a document has been made successfully
 * @event module:actions/settings/documents~SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS
 * @property {symbol} type - Symbol(SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS)
 * @property {object} payload - the data returned from the server
 */
export const SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS = Symbol('SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS');

/**
 * Upload a document
 * @param {object} data
 * @param {string} [data.user_label] - the label for this document
 * @param {string} [data.category] - 'document', 'documents_insurance', or 'documents_authority'
 * @param {string} [data.user] - the ID of the user whose document this is (admin only)
 * @param {File} data.file - the file for this document
 * @returns {Promise<Document>} A promise that resolves in the user's documents
 * @fires module:actions/settings/documents~SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS
 */
export const upload = data => async (dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  formData.append('filename', data.file);
  ['user_label', 'category', 'user'].forEach(key => {
    if (data[key]) {
      formData.append(key, data[key]);
    }
  });
  const res = await dispatch(APIFetch(`${API.host}/user/profile/documents/upload/`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${state.user.token}`,
      // don't include `content-type: 'multipart/form-data'` here, fetch will do that automatically
    },
    body: formData,
  }));
  if (res.status !== 201) {
    if (res.status >= 500 || res.status === 403) {
      const text = await res.text();
      if (res.status === 403) {
        throw new PermissionsError(text);
      }
      throw new FetchError(res.status, text);
    }
    const json = await res.json();
    throw new FetchError(res.status, json);
  }
  const doc = await res.json();
  doc.modified = moment(doc.modified);
  doc.time_posted = moment(doc.time_posted);
  dispatch({
    payload: {
      doc,
      id: data.user || state.user.id,
    },
    type: SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS,
  });
  return doc;
};


/**
 * Send a document
 * @param {object} data
 * @param {File} data.recipient_email - the email to send the group of documents to
 * @param {string} data.labels - the labels of the documents to be sent
 * @param {string} data.user_message - a message to be sent along with the documents
 * @returns {Promise<void>} A promise that resolves when the request has completed
 */
export const send = data => async (dispatch, getState) => {
  const res = await dispatch(APIFetch(`${API.host}/user/profile/documents/send/`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  }));
  if (res.status !== 200) {
    const text = await res.text();
    if (res.status === 403) {
      throw new PermissionsError(text);
    }
    throw new FetchError(text);
  }
};


/**
 * Delete request success action. Marks that a request to delete a document has been made successfully
 * @event module:actions/settings/documents~SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS
 * @property {symbol} type - Symbol(SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS)
 * @property {object} payload - the data returned from the server
 */
export const SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS = Symbol('SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS');

/**
 * Delete a document
 * @param {string} id - the id of the document to delete
 * @returns {Promise<void>} A promise that resolves when the request has completed
 * @fires module:actions/settings/documents~SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS
 */
export const doDelete = id => async (dispatch, getState) => {
  const state = getState();
  const res = await dispatch(APIFetch(`${API.host}/user/profile/documents/upload/${id}/`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
  }));
  if (res.status !== 204) {
    const text = await res.text();
    throw new FetchError(text);
  }
  dispatch({
    payload: {
      id,
      user: state.user.id,
    },
    type: SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS,
  });
};
