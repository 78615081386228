import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { ageAbbr, formatWeight, inferTruckRouteType, trailerTypeMap } from '../../helpers';
import Table from './Table';


const TruckDetailsTable = ({ ids, ...rest }) =>
  <Table
    style={{ textAlign: 'left' }}
    headers={['Age', 'Type', 'Origin', 'Range', 'Destination', 'Range', 'Weight', 'Length', 'Ready', 'Close']}
    mapItemToCells={item => {
      if (!item || !item.data) {
        return [];
      }
      const { origin, origin_radius, destination, destination_radius } = inferTruckRouteType(item.data);
      return [
        ageAbbr(item.data.time_posted),
        (!item.data.trailerTypes || item.data.trailerTypes.length === 0) ?
          'Any' :
          item.data.trailerTypes.length > 1 ?
            item.data.trailerTypes.join(', ') :
            item.data.trailerTypes.map(tt => trailerTypeMap[tt]).join(', '),
        origin,
        origin_radius,
        destination,
        destination_radius,
        item.data.weight ? formatWeight(item.data.weight) : '—',
        item.data.length ? item.data.length : '—',
        item.data.time_start.format('l'),
        item.data.time_end.format('l'),
      ];
    }}
    {...rest}
  />
  ;

TruckDetailsTable.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      trailerTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
      origin_city: PropTypes.string,
      origin_state: PropTypes.string,
      origin_radius: PropTypes.number,
      destination_city: PropTypes.string,
      destination_state: PropTypes.string,
      destination_radius: PropTypes.number,
      origin_region: PropTypes.arrayOf(PropTypes.string),
      destination_region: PropTypes.arrayOf(PropTypes.string),
      weight: PropTypes.number,
      length: PropTypes.number,
      time_start: PropTypes.instanceOf(moment).isRequired,
      time_end: PropTypes.instanceOf(moment).isRequired,
      time_posted: PropTypes.instanceOf(moment).isRequired,
    }),
  })),
  isFetching: PropTypes.bool,
  fetchNext: PropTypes.func,
};

export default TruckDetailsTable;
