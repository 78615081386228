import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringdebtorauditlog;

import factoringdebtorauditlogResource from 'actions/resource/factoringdebtorauditlog';


export default function factoringdebtorauditlogResourceReducer(state = initialState, action) {
  state = factoringdebtorauditlogResource.reduce(state, action);
  return state;
}
