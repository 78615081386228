import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import getContext from 'recompose/getContext';
import branch from 'recompose/branch';

import { USER_TYPE } from 'helpers';
import emailIsInvalid from 'helpers/emailIsInvalid';
import PermissionsError from 'datatypes/PermissionsError';

import Resource from 'components/hoc/Resource';
import nothingIf from 'components/hoc/nothingIf';
import nothingIfFetching from 'components/hoc/nothingIfFetching';
import BidCreateFormUserTable from 'components/pure/form/BidCreateFormUserTable';
import Countdown from 'components/pure/Countdown';


export default compose(
  getContext({
    dontShowBidding: PropTypes.bool,
  }),
  nothingIf(({ dontShowBidding }) => dontShowBidding),
  connect(
    (state, { id }) => {
      const item = state.resource.loadsearch[id];
      return { item };
    },
    {},
  ),
  branch(
    ({ item }) => item,
    Resource('loadsearch'),
    Resource('load'),
  ),
  nothingIfFetching(),
  nothingIf(({ item }) =>
    !(item.data.user.err instanceof PermissionsError) &&
    (
      emailIsInvalid(item.data.user.data.email) &&
      emailIsInvalid(item.data.user.data.contact_email)
    )
  ),
  mapProps(({ item, ...rest }) => ({
    isOver: item.data.is_deleted || item.data.is_bidding_closed,
    item,
    ...rest,
  })),
  connect(state => ({ current_user_id: state.user.id })),
  nothingIf(({ item, current_user_id }) => item.data.user_id === current_user_id),
)(({ id, isOver, item }) => {
  if (isOver) {
    return (
      <div className='text-danger'>
        {[USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(item.data.user_type) ? 'Load has expired.' : 'Bidding has ended'}
      </div>
    );
  }
  return (
    <div>
      {item.data.user_type === USER_TYPE.SHIPPER ? <div>Time Left to Bid: {<Countdown time={item.data.time_end} />}</div> : null}
      <span style={{ verticalAlign: 'top' }}>Enter Bid: </span>
      <BidCreateFormUserTable id={id} />
    </div>
  );
});
