import 'styles/ImageInput';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import cs from 'classnames';
import getContext from 'recompose/getContext';

import Thumbnail from 'components/container/Thumbnail';
import ImageGrid from 'components/pure/ImageGrid';

export default compose(
  getContext({
    _reduxForm: PropTypes.object,
  })
)(
  class CroppedImagesInput extends React.Component {
    // eslint-disable-line react/no-multi-comp
    static propTypes = {
      confirmBeforeDeleting: PropTypes.bool,
    };

    onDelete = i => () => {
      const {
        input: { value, onChange },
      } = this.props;
      const new_value = [...value.slice(0, i), ...value.slice(i + 1)];
      return onChange(new_value);
    };

    render() {
      let {
        input: { value },
        ...rest
      } = this.props;
      if (!Array.isArray(value)) {
        value = [];
      }

      if (!value.length) {
        return null;
      }

      return (
        <div className={cs('multi-image-input', rest.className)}>
          <label className='control-label'>Attachments created after Crop</label>
          <ImageGrid maxHeight={400} columns={{ xs: 1, sm: 1, md: 2, lg: 2 }}>
            {value.map((croppedAttachment, idx) => (
              <div key={idx} className='text-center'>
                <div className='multi-image-input-image' role='button' title={croppedAttachment.filename}>
                  <Thumbnail src={croppedAttachment} />
                </div>
                <span
                  title={croppedAttachment.filename}
                  style={{
                    display: 'block',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    verticalAlign: 'middle',
                    width: '100%',
                  }}
                  >
                  {croppedAttachment.filename}
                </span>{' '}
                <button className='btn btn-danger' type='button' onClick={this.onDelete(idx)}>
                  X
                </button>
              </div>
            ))}
          </ImageGrid>
        </div>
      );
    }
  }
);
