/**
 * Component that wraps react-dimentions, which displays different components based on the size of the element itself in the dom
 * @module components/pure/ResponsiveContainer
 * @since 3.0.0
 * @requires components/pure/MediaQuery
 * @property {object} props
 * @property {object} props.item
 * @property {object} props.item.data
 * @property {number} props.item.data.linehaul_cpg
 * @property {number} props.item.data.linehaul_average_rate
 * @property {object} props.map
 * @property {object} props.map.data
 * @property {object[]} props.map.data.routes[]
 * @property {object[]} props.map.data.routes[].legs[]
 * @property {object} props.map.data.routes[].legs[].distance
 * @property {number} props.map.data.routes[].legs[].distance.value
 * @property {any} [props....rest] - props passed to MediaQuery component
 */
/* global MOBILE_BREAKPOINT */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dimensions from 'react-dimensions';

import MediaQuery from './MediaQuery';


class ResponsiveContainer extends Component {

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    const breakpoint = props.breakpoint || MOBILE_BREAKPOINT;
    return props.containerWidth < breakpoint && nextProps.containerWidth >= breakpoint ||
      props.containerWidth >= breakpoint && nextProps.containerWidth < breakpoint ||
      props.mobile !== nextProps.mobile ||
      props.desktop !== nextProps.desktop ||
      props.children !== nextProps.children
    ;
  }

  static propTypes = {
    containerWidth: PropTypes.number.isRequired,
    mobile: PropTypes.element,
    desktop: PropTypes.element,
    children: PropTypes.func,
  };

  render() {
    const { containerWidth, containerHeight, updateDimensions, mobile, desktop, breakpoint, children, ...rest } = this.props;
    const isMobile = containerWidth < (breakpoint || MOBILE_BREAKPOINT);

    if (children) {
      return React.createElement(children, { isMobile });
    }

    return (
      <MediaQuery
        {...rest}
        mobile={mobile}
        desktop={isMobile ? mobile : desktop}
        breakpoint={breakpoint}
      />
    );
  }
}


export default Dimensions({
  elementResize: true,
  className: 'col-fill',
})(ResponsiveContainer);
