import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal } from 'actions/ui';
import { Field, Form, reduxForm } from 'redux-form';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import PennyInput from 'components/pure/form/inputs/PennyInput';



const FuelAdvanceModal = ({ id, closeModal, onSubmit, handleSubmit }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <Header className='text-danger'>Fuel Advance Settings</Header>
    </div>
    <div className='modal-body'>
      <Form onSubmit={handleSubmit}>
        <Field
          name='fuel_advance'
          component={LabeledDropdown}
          data={[
            { text: 'True', value: 'true' },
            { text: 'False', value: 'false' },
          ]}
          label='Fuel Advance?'
          // horizontal
        />
        <Field
          name='fuel_advance_fee'
          component={PennyInput}
          placeholder='Enter Fuel Advance Fee'
          label='Fuel Advance Fee'
          // horizontal
        />
        <Field
          name='fuel_advance_rate'
          component={LabeledInput}
          type='number'
          placeholder='Enter Fuel Advance Rate'
          label='Fuel Advance Rate'
          // horizontal
        />
      </Form>
    </div>
    <div className='modal-footer'>
      <button
        className='btn btn-orange'
        type='button'
        onClick={() => {
          closeModal();
        }}
        >
        Save
      </button>
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
    </div>
  </div>;

const mapStateToProps = (state, ownProps) => ({
  form: ownProps.formName,
});

export default compose(
  connect(
    mapStateToProps,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    })
  ),
  reduxForm({
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  })
)(FuelAdvanceModal);
