import React from 'react';
import { connect } from 'react-redux-v5';
import { compose, withState } from 'recompose';
import { Field, reduxForm, reset } from 'redux-form';

import { get } from 'lodash';
import SortableTable from 'components/pure/SortableTable';
import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import { createLinkedLineItemByFundingRequest } from 'actions/lineitems';
import { openModal } from 'actions/ui';

const Card = ({ children }) => (
  <div
    style={{
      marginTop: '16px',
      marginBottom: '16px',
      width: '100%',
      boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
      backgroundColor: '#ffffff',
    }}
    >
    {children}
  </div>
);

const LineItemTable = ({ handleSubmit, lineItems, setSelected }) => (
  <Card>
    <div
      style={{
        height: '265px',
      }}
      >
      <SortableTable
        className='table-condensed'
        selectable={true}
        placeholder='No Line Items'
        idSelector={'id'}
        currentOrder={'line_item'}
        headers={[
          { text: 'Line Item', ordering: false },
          { text: 'Amount', ordering: false },
          { text: 'Amount Applied', ordering: false },
          { text: 'Balance', ordering: false },
          { text: 'Invoice #', ordering: false },
          { text: 'Age', ordering: true },
        ]}
        items={lineItems}
        sort={console.log}
        ListItem={createTableItems(props => {
          const { id, category_name, category, amount, pending, balance_amount, invoice_number, age, selected, funding_request } = props;
          return [
            category_name,
            <div
              style={{
                color: category === 'negative' ? 'red' : 'green',
              }}
              >
              {formatPennies(Number(amount))}
            </div>,
            <div style={{ minWidth: '170px' }}>
              { selected ?
                <Field
                  name={id}
                  component={PennyInput}
                />
                :
                formatPennies(Number(pending))
              }
            </div>,
            <div>
              {formatPennies(Number(balance_amount))}
            </div>,
            <a href={`/admin/factoring/transaction/${funding_request}`}>{invoice_number}</a>,
            age,
          ];
        }).TableItem}
        onItemsSelect={setSelected}
      />
    </div>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row-reverse',
      }}
      >
      <button
        style={{
          margin: '10px',
          width: '114px',
        }}
        className='btn btn-green'
        onClick={e => {
          e.preventDefault();
          handleSubmit();
        }}
        >
        Submit
      </button>
    </div>
  </Card>
);

const FORM = 'LineItemGiveBack';

export default compose(
  connect(
    null,
    dispatch => ({
      createLinkedLineItemByFundingRequest: id => args => dispatch(createLinkedLineItemByFundingRequest(id)(args)),
    })
  ),
  withState('selected', 'setSelected', []),
  reduxForm({
    form: FORM,
    destroyOnUnmount: true,
    onSubmit: async (values, dispatch, props) => {
      await Promise.all(props.selected.map(select => {
        const amountRequested = values[select.id];
        return props.createLinkedLineItemByFundingRequest(props.fundingRequestId)({
          related_line_item: get(select, 'line_item.id'),
          amount_requested: amountRequested,
        });
      }));
    },
    onSubmitSuccess: async (result, dispatch, props) => {
      dispatch(reset(FORM));
      props.clearLineItems();
      props.updateLineItems();
      props.setSelected([]);
      dispatch(openModal('success', { message: 'Successfully submitted the line items.' }));
    },
    onSubmitFail: async (errors, dispatch, props) => {
      dispatch(openModal('error', { message: 'Error submitting the line items.' }));
    },
  }),
)(LineItemTable);
