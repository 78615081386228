/**
 * Reducer for handling the factoringclient search actions
 * @module reducers/search/factoringclient
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/search/factoringclient
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.search.factoringclient;

import factoringclientSearchResource from 'actions/search/factoringclient';


export default function factoringclientSearchReducer(state = initialState, action) {
  state = factoringclientSearchResource.reduce(state, action);
  return state;
}
