/**
 * Actions relating to the user (who is using the app)
 * @module actions/user
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/resource/user
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires helpers
 * @requires helpers/convertToDotNotation
 */
/* global API APP_TOKEN TOKEN_STORAGE_KEY REFRESH_TOKEN_STORAGE_KEY goog_report_conversion process fbq */
import qs from 'querystring';
import { browserHistory } from 'react-router-v4';
import moment from 'moment';
import { get } from 'lodash';
import * as Sentry from '@sentry/browser';

import { USER_TYPE } from 'helpers';
import convertToDotNotation from 'helpers/convertToDotNotation';
import { resetTraits, trackType } from '../helpers/segmentAnalytics';
import storage from 'datatypes/storage';

import { openModal } from 'actions/ui';

import { fetch } from 'actions/resource/user';
import APIFetch from 'datatypes/APIFetch';
import APIError from 'datatypes/error/APIError';
import FetchError from 'datatypes/FetchError';
import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';

import * as firebase from '../firebase';
import * as stripe from '../stripe';
import groupsAdmin from './admin/factoring/groups';
import { axiosInterceptors } from '../middleware/axiosProvider';


/**
 * Function to get carrier data given a DOT number
 * @param {number | string} dot
 * @returns {object} [FMCSA data]{@link https://mobile.fmcsa.dot.gov/developer/qcapidescription.page?cid=268975}
 */
export const getDataFromDOT = async dot => {
  const res = await global.fetch(`${API.host}/factoring/fmcsa/?dot=${dot}`);
  if (res.status !== 200) {
    return null;
  }
  return await res.json();
};


/**
 * Function to get carrier data given a MC number
 * @param {number | string} mc
 * @returns {object} [FMCSA data]{@link https://mobile.fmcsa.dot.gov/developer/qcapidescription.page?cid=268975}
 */
export const getDataFromMC = async mc => {
  const res = await global.fetch(`${API.host}/factoring/fmcsa/?mc=${mc}`);
  if (res.status !== 200) {
    return null;
  }
  return await res.json();
};


/**
 * Function to check the existence of a DOT number
 * @param {number | string} dot
 * @returns {boolean} whether the given DOT number is a registered DOT number
 */
export const checkExistsDOT = async dot => {
  const res = await global.fetch(`${API.host}?dot=${dot}`);
  return res.status === 200 || res.status >= 500;
};


/**
 * Function to check the existence of a MC number
 * @param {number | string} mc
 * @returns {boolean} whether the given MC number is a registered MC number
 */
export const checkExistsMC = async mc => {
  const res = await global.fetch(`${API.host}?mc=${mc}`);
  return res.status === 200 || res.status >= 500;
};

export const validateEmailToken = async token => {
  const res = await global.fetch(`${API.host}/user/verify_email_token/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      token,
    }),
  });
  if (res.status !== 200) {
    const json = await res.json();
    throw new Error(json.message);
  }
};

export const resendEmailVerificationEmail = () => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/user/verify_email_token/?generate_new_token=true`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
  });
  if (res.status !== 200) {
    throw new APIError(res.status, await res.text());
  }
};


/**
 * User logout action.
 * @event USER_LOGOUT
 * @property USER_LOGOUT
 */
export const USER_LOGOUT = '@@comfreight/USER_LOGOUT';
/**
 * Thunk to logout. Updates Sentry and Google Analytics with the current user (no one, anymore)
 * @fires USER_LOGOUT
 */
export const logout = () => dispatch => {
  storage.removeItem(TOKEN_STORAGE_KEY);
  Sentry.configureScope(scope => {
    scope.setUser({ 'id': undefined });
  });
  resetTraits();
  firebase.uninitialize();
  return dispatch({
    type: USER_LOGOUT,
    payload: undefined,
  });
};

/**
 * User autologin failure action. Marks that the user failed to be automatically logged in, either because the token saved in LocalStorage is expired or doesn't exist
 * @event USER_AUTO_LOGIN_FAILURE
 * @property USER_AUTO_LOGIN_FAILURE
 */
export const USER_AUTO_LOGIN_FAILURE = '@@comfreight/USER_AUTO_LOGIN_FAILURE';
/**
 * User autologin failure action
 * @fires USER_AUTO_LOGIN_FAILURE
 */
export function autoLoginFailure() {
  return {
    type: USER_AUTO_LOGIN_FAILURE,
    payload: undefined,
  };
}

/**
 * User login request action. Marks that a request to login has been made
 * @event USER_LOGIN_REQUEST
 * @property USER_LOGIN_REQUEST
 */
export const USER_LOGIN_REQUEST = '@@comfreight/USER_LOGIN_REQUEST';
/**
 * User login request action
 * @fires USER_LOGIN_REQUEST
 */
export function loginRequest() {
  return {
    type: USER_LOGIN_REQUEST,
    payload: undefined,
  };
}

/**
 * User anonymous login action. Marks that a user has 'logged in' anonymously (the automatic login sequence has ended successfully, and the user isn't logged in)
 * @event USER_ANON_LOGIN
 * @property USER_ANON_LOGIN
 */
export const USER_ANON_LOGIN = '@@comfreight/USER_ANON_LOGIN';
/**
 * User anonymous login action
 * @fires USER_ANON_LOGIN
 */
export function anonLogin() {
  return {
    type: USER_ANON_LOGIN,
    payload: undefined,
  };
}

/**
 * User login success action. Marks that a user has logged in successfully
 * @event USER_LOGIN_SUCCESS
 * @property USER_LOGIN_SUCCESS
 * @property {object} payload - the user's data returned from the server
 */
export const USER_LOGIN_SUCCESS = '@@comfreight/USER_LOGIN_SUCCESS';
/**
 * User login success action
 * @fires USER_LOGIN_SUCCESS
 */
export function loginSuccess(json) {
  return {
    type: USER_LOGIN_SUCCESS,
    payload: json,
    meta: {
      analytics: {
        type: trackType.IDENTIFY,
        payload: {
          userId: json.data.id,
          traits: {
            email: json.data.json.email,
            firstName: json.data.json.contact_name,
          },
        },
      },
    },
  };
}

/**
 * User login failure action. Marks that a request to log in has failed
 * @event USER_LOGIN_FAILURE
 * @property USER_LOGIN_FAILURE
 * @property {any} err - the error that occurred
 */
export const USER_LOGIN_FAILURE = '@@comfreight/USER_LOGIN_FAILURE';
/**
 * User login failure action
 * @fires USER_LOGIN_FAILURE
 */
export function loginFailure(err) {
  return {
    type: USER_LOGIN_FAILURE,
    payload: err,
  };
}

/**
 * User token success action. Marks that a request to get an access token has been made successfully
 * @event USER_TOKEN_SUCCESS
 * @property USER_TOKEN_SUCCESS
 * @property {string} payload - the access token
 */
export const USER_TOKEN_SUCCESS = '@@comfreight/USER_TOKEN_SUCCESS';
/**
 * User token success action
 * @fires USER_TOKEN_SUCCESS
 */
export function tokenSuccess(token) {
  return {
    type: USER_TOKEN_SUCCESS,
    payload: token,
  };
}

/**
 * Thunk that does the series of actions required to log in
 * @param {module:actions/user~TokenRequestData} data
 * @returns {Promise<void>} A promise that resolves when the user has finished logging in, or an error
 */
export const login = data => async (dispatch, getState) => {
  const state = getState();
  const location = state.routing.locationBeforeTransitions;

  if (state.user.logged_in || state.user.isLoggingIn) {
    return;
  }
  dispatch(loginRequest());

  const { verified_email_token } = location.query;
  if (verified_email_token) {
    try {
      await validateEmailToken(verified_email_token);
      dispatch(openModal('success', { message: 'Thank you for confirming your email address' }));
      browserHistory.replace(`${location.pathname}${location.search.replace(new RegExp(`[\?&]{1}verified_email_token=${verified_email_token}`), '')}`);
    }
    catch (err) {
      console.warn(err);
      if (err.message) {
        dispatch(openModal('warning', { message: `Couldn't confirm your email address :( reason: ${err.message}` }));
      }
    }
  }

  let token, refreshToken;
  // get our login token
  try {
    // data is our login info, get token first
    if (typeof data === 'object') {
      // check if we're a legacy user that needs a password reset
      const res = await global.fetch(`${API.host}/user/legacy_redirect?email=${data.username}`);
      const json = await res.json();
      if (json.redirect) {
        dispatch(openModal('warning', { message: 'It\'s time for you to update your password. We take your security seriously at HaulPay. Thank you' }));
        browserHistory.push(`/resetpasswordconfirm?token=${json.reset_password_token}`);
      }
      else {
        const res = await global.fetch(`${API.host}/o/token/`, {
          method: 'POST',
          headers: {
            'content-type': 'application/x-www-form-urlencoded',
          },
          body: qs.stringify({
            username: data.username,
            password: data.password,
            grant_type: 'password',
            client_id: APP_TOKEN,
          }),
        });
        if (res.status !== 200) {
          throw new APIError(res.status, await res.text());
        }
        const json = await res.json();
        token = get(json, 'access_token');
        refreshToken = get(json, 'refresh_token');
        storage.setItem(TOKEN_STORAGE_KEY, token);
        storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
        dispatch(tokenSuccess({ token, refreshToken }));
      }
    }
    // data is our token
    else if (typeof data === 'string') {
      token = data;
      storage.setItem(TOKEN_STORAGE_KEY, token);
    }
    // our token is in state
    else {
      token = state.user.token;
    }

    // if we don't have a login token at this point, we're logging in anonymously
    if (!token) {
      return dispatch(anonLogin());
    }
    axiosInterceptors(token);
    // get ourselves
    const res = await global.fetch(`${API.host}/user/current`, {
      headers: { Authorization: `Bearer ${token}` },
    });
    if (res.status === 401) {
      storage.removeItem(TOKEN_STORAGE_KEY);
      throw new Error('expired token');
    }
    if (res.status !== 200) {
      throw new APIError(res.status, await res.text());
    }
    const json = await res.json();

    // get the user for ourselves
    const action = await dispatch(fetch(json.id, token));

    // set up analytics
    Sentry.configureScope(scope => {
      scope.setUser({ 'id': action.payload.id });
    });

    // What Factoring Groups are we in
    await dispatch(groupsAdmin.fetch({ user: action.payload.id, token }));

    // dispatch success
    dispatch(loginSuccess({
      token,
      data: action.payload,
    }));


    // redirect as necessary
    const { pathname, query: { next }, hash } = location;
    if (next) {
      browserHistory.push(`${next}${hash}`);
    }
    else if (pathname === '/login' || pathname === '/signup' || pathname === '/resetpassword') {
      browserHistory.push(`/${hash}`);
    }

    // open modals as necessary
    if (action.payload.json && action.payload.json.flags && action.payload.json.flags.shipper_alerts) {
      // show shipper load alert form to brokers and dispatchers when they first log in
      if (action.payload.json.type === USER_TYPE.BROKER || action.payload.json.type === USER_TYPE.DISPATCHER) {
        dispatch(openModal('shipperloadalertmodalform'));
      }
      // show optional carrier information form to carriers when they first log in
      if (action.payload.json.type === USER_TYPE.CARRIER) {
        dispatch(openModal('carriersignupextraform'));
      }
    }

    // initialize firebase
    try {
      const firebaseToken = await firebase.initialize();
      if (firebaseToken) {
        await global.fetch(`${API.host}/activity/device/`, {
          method: 'POST',
          headers: {
            authorization: `bearer ${token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            token: firebaseToken,
          }),
        });
      }
      return action.payload
    }
    catch (err) {
      console.warn('failed to initialize firebase', err);
    }

  }
  catch (err) {
    const { pathname, hash } = location;
    if (!(pathname === '/login' || pathname === '/signup' || pathname === '/resetpassword')) {
      browserHistory.replace(`/login?next=${pathname}${hash}`);
    }
    dispatch(loginFailure(err));
    throw err;
  }
};

const triggerGoogleConversionTrack = () => {
  goog_report_conversion();
};

export const getFMCSAuserType = async data => {
  if (data.dot || data.mc) {
    let fmcsa_data;
    if (data.dot) {
      fmcsa_data = await getDataFromDOT(data.dot);
    }
    else {
      fmcsa_data = await getDataFromMC(data.mc);
    }
    if (fmcsa_data) {
      if (fmcsa_data.brokerAuthorityStatus === 'A' || fmcsa_data.brokerAuthorityStatus === 'ACTIVE') {
        return USER_TYPE.BROKER;
      }
      else if (
        fmcsa_data.commonAuthorityStatus === 'A' ||
        fmcsa_data.contractAuthorityStatus === 'A' ||
        fmcsa_data.commonAuthorityStatus === 'ACTIVE' ||
        fmcsa_data.contractAuthorityStatus === 'ACTIVE'
      ) {
        return USER_TYPE.CARRIER;
      }
    }
  }
  return 'unknown';
};


/**
 * User signup request action. Marks that a request to create a user has been made
 * @event USER_SIGNUP_REQUEST
 * @property USER_SIGNUP_REQUEST
 */
export const USER_SIGNUP_REQUEST = '@@comfreight/USER_SIGNUP_REQUEST';
/**
 * User signup request success action. Marks that a request to create a user has been made successfully
 * @event USER_SIGNUP_SUCCESS
 * @property USER_SIGNUP_SUCCESS
 * @property {object} payload - the data fetched from the server
 */
export const USER_SIGNUP_SUCCESS = '@@comfreight/USER_SIGNUP_SUCCESS';
/**
 * User signup request failure action. Marks that a request to create a user has failed
 * @event USER_SIGNUP_FAILURE
 * @property create USER_SIGNUP_FAILURE
 * @property {any} payload - the error that occurred
 */
export const USER_SIGNUP_FAILURE = '@@comfreight/USER_SIGNUP_FAILURE';
/**
 * @typedef UserSignupData
 * @property {string} email - the user's email
 * @property {string} password - the user's password
 * @property {module:actions/user~UserType} type - the type of user being created
 * @property {module:actions/user~UserTypeData} [broker]
 * @property {module:actions/user~UserTypeData} [carrier]
 * @property {module:actions/user~UserTypeData} [shipper]
 */
/**
 * @typedef UserTypeData
 * @property {string} [dba] - 'doing business as' name
 * @property {string} [contact_name]
 * @property {string} [contact_phone]
 * @property {number} [mc]
 * @property {number} [dot]
 * @property {string} [address]
 * @property {string} [city]
 * @property {string} [state]
 * @property {string} [zip]
 * @property {string} [country]
 */
/**
 * @typedef {'broker' | 'carrier' | 'shipper'} UserType
 */
/**
 * Thunk for user signup.
 * @param {UserSignupData} data - the user data and nested user type data, see [SignupForm]{@link components/pure/form/SignupForm} for example data in use
 * @param {object} opts - additional signup options
 * @param {object} opts.dont_do_misc_stuff - signifies that normal miscellaneous post-signup actions should not happen
 * @returns {Promise<void>} A promise that resolves when the user is created
 */
export const signup = (data, opts = {}) => async (dispatch, getState) => {
  const state = getState();
  if (!data || state.signing_up) {
    return Promise.resolve();
  }
  dispatch({
    type: USER_SIGNUP_REQUEST,
    payload: undefined,
  });
  let source;
  if (state.url.query && state.url.query.utm_source) {
    source = state.url.query.utm_source;
  }
  else if (state.cookies.tracking && state.cookies.tracking.utm_source) {
    source = state.cookies.tracking.utm_source;
  }
  else if (document.referrer) {
    source = document.referrer;
  }
  else {
    source = 'direct';
  }
  try {
    const res = await global.fetch(`${API.host}/users/?${qs.stringify({ source })}`, {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        Authorization: `Bearer ${APP_TOKEN}`,
        'Content-Type': 'application/json',
      },
    });
    if (res.status !== 201) {
      const text = await res.text();
      throw new FetchError(res.status, text);
    }
    await dispatch(login({
      username: data.email,
      password: data.password,
    }));

    if (opts.dont_do_misc_stuff) {
      return;
    }

    if (data.type === USER_TYPE.SHIPPER) {
      dispatch(openModal('shippersignupextraform'));
    }

    // check with FMCSA before showing the user the factoring register modal
    if ([USER_TYPE.BROKER, USER_TYPE.CARRIER, USER_TYPE.DISPATCHER].includes(data.type)) {
      let user_type;
      try {
        user_type = await getFMCSAuserType(data[data.type]);
      }
      // just fail (semi-) silently if this doesn't work
      catch (err) {
        console.warn(err);
      }
      dispatch(openModal('tafsregister', {
        showAsCarrier: data.type === USER_TYPE.CARRIER || user_type === USER_TYPE.CARRIER,
        initialValues: {
          company: data[data.type].dba,
          email: data.email,
          dot: data[data.type].dot,
          mc: data[data.type].mc,
          selected_number: !data[data.type].mc && data[data.type].dot ? MCorDOTInputs.DOT : MCorDOTInputs.MC,
        },
      }));
    }
  }
  catch (err) {
    dispatch({
      type: USER_SIGNUP_FAILURE,
      payload: err,
    });
    throw err;
  }
  dispatch({
    type: USER_SIGNUP_SUCCESS,
    payload: undefined,
    meta: {
      analytics: [
        {
          type: trackType.TRACK,
          payload: {
            event: 'CompleteRegistration',
            properties: {
              content_name: 'signup',
            },
          },
        },
      ],
    },
  });
  if (process.env.NODE_ENV === 'production') {
    triggerGoogleConversionTrack();
    fbq('track', 'CompleteRegistration', { content_name: 'signup' });
  }
};


/**
 * Subscription status request action. Marks that a request to get the current user's subscription status has been made
 * @event SUBSCRIPTION_STATUS_REQUEST
 * @property SUBSCRIPTION_STATUS_REQUEST
 */
export const SUBSCRIPTION_STATUS_REQUEST = '@@comfreight/SUBSCRIPTION_STATUS_REQUEST';
/**
 * Subscription status request action. Marks that a request to get the current user's subscription status has been made successfully
 * @event SUBSCRIPTION_STATUS_REQUEST_SUCCESS
 * @property SUBSCRIPTION_STATUS_REQUEST_SUCCESS
 * @property {object} payload - the data fetched from the server, or undefined if the request fails (because there is no subscription)
 */
export const SUBSCRIPTION_STATUS_REQUEST_SUCCESS = '@@comfreight/SUBSCRIPTION_STATUS_REQUEST_SUCCESS';
/**
 * Thunk for getting getting the current user's subscription status
 * @returns {Promise<void>} A promise that resolves when the request has finished
 */
export const getSubscriptionStatus = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.subscription.isFetching) {
    return Promise.resolve();
  }
  dispatch({
    type: SUBSCRIPTION_STATUS_REQUEST,
    payload: undefined,
  });
  const fetchOptions = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${state.user.token}`,
    },
  };
  // combine subscription status and payment history into the same 'resource' by fetching them both here sequentially
  try {
    const subscriptionRes = await dispatch(APIFetch(`${API.host}/stripe/subscription/`, fetchOptions));
    if (subscriptionRes.status !== 200) {
      dispatch({
        type: SUBSCRIPTION_STATUS_REQUEST_SUCCESS,
        payload: undefined,
      });
      return;
    }
    const status = await subscriptionRes.json();
    const historyRes = await dispatch(APIFetch(`${API.host}/payment/history/?subscription=true`, fetchOptions));
    const history = await historyRes.json();
    status.history = history && history.map ? history.map(item => ({
      ...item,
      created: moment(item.created, 'X'),
    })) : [];
    dispatch({
      type: SUBSCRIPTION_STATUS_REQUEST_SUCCESS,
      payload: status,
    });
  }
  catch (err) {
    console.warn(err);
    dispatch({
      type: SUBSCRIPTION_STATUS_REQUEST_SUCCESS,
      payload: undefined,
    });
  }
};

/**
 * Subscription create request action. Marks that a request to subscribe the current user has been made
 * @event USER_SUBSCRIBE_REQUEST
 * @property USER_SUBSCRIBE_REQUEST
 */
export const USER_SUBSCRIBE_REQUEST = '@@comfreight/USER_SUBSCRIBE_REQUEST';
/**
 * Subscription create request action. Marks that a request to subscribe the current user has been made successfully
 * @event USER_SUBSCRIBE_REQUEST_SUCCESS
 * @property USER_SUBSCRIBE_REQUEST_SUCCESS
 * @property {object} payload - the data fetched from the server, or undefined if the request fails (because there is no subscription)
 */
export const USER_SUBSCRIBE_REQUEST_SUCCESS = '@@comfreight/USER_SUBSCRIBE_REQUEST_SUCCESS';
/**
 * Thunk for subscribing the current user
 * @param {string} token - the token returned by [Stripe]{@link module:stripe}'s checkout callback
 * @param {string} plan - the name of the plan to subscribe the user to. see [Stripe]{@link module:stripe}
 * @param {string} type - the subscribe event type for tracking purposes
 * @param {boolean} is_update_billing_info - whether or not the user is updating their billing info
 * @param {number} amount - the amount applied to the subscription mainly for tracking purposes.
 * @returns {Promise<void>} A promise that resolves when the request has finished
 */
export function subscribe({ token, plan, type, amount , is_update_billing_info = false}) {
  return (dispatch, getState) => {
    const state = getState();
    dispatch({
      type: USER_SUBSCRIBE_REQUEST,
      payload: undefined,
    });
    return dispatch(APIFetch(`${API.host}/stripe/subscription/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${state.user.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        stripe_token: token,
        plan: plan, is_update_billing_info:is_update_billing_info,
      }),
    }))
      .then(res => {
        if (res.status !== 201) {
          throw new Error('Something went wrong processing the payment.');
        }
        dispatch({
          type: USER_SUBSCRIBE_REQUEST_SUCCESS,
          payload: undefined,
          meta: {
            analytics: {
              type: trackType.TRACK,
              payload: {
                event: type,
                properties: {
                  revenue: amount,
                  plan,
                },
              },
            },
          },
        });
        return dispatch(getSubscriptionStatus());
      })
      ; // eslint-disable-line indent
  };
}

/**
 * Thunk for changing the current user's subscription plan
 * @param {string} token - the token returned by [Stripe]{@link module:stripe}'s checkout callback
 * @returns {Promise<void>} see [subscribe]{@link module:actions/user~subscribe}
 */
export function upgradeSubscription(token) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.user.subscription.isFetching) {
      return Promise.resolve();
    }
    const { plan } = state.user.subscription.data;
    const stripe_plan = stripe.all_packages_by_name[plan];
    if (!stripe_plan || !stripe_plan.upgrades_to_plan) {
      return Promise.reject(`failed to upgrade subscription: don't know what to upgrade to from plan "${plan}"`);
    }

    // get the upgrade-to price in dollars
    const amount = stripe.all_packages_by_name[stripe_plan.upgrades_to_plan].stripePrice / 100;
    return dispatch(subscribe({
      token,
      plan: stripe_plan.upgrades_to_plan,
      type: 'Subscription Upgraded',
      amount,
    }));
  };
}

/**
 * Thunk for updating the current user's subscription infomation
 * @param {string} token - the token returned by [Stripe]{@link module:stripe}'s checkout callback
 * @returns {Promise<void>} see [subscribe]{@link module:actions/user~subscribe}
 */
export function updateSubscription(token) {
  return (dispatch, getState) => {
    const state = getState();
    const { plan, amount } = state.user.subscription.data;
    return dispatch(subscribe({
      token,
      plan,
      type: 'Subscription Updated',
      amount,
      is_update_billing_info: true,
    }));
  };
}

/**
 * Subscription cancel request action. Marks that a request to cancel the current user's subscription has been made
 * @event SUBSCRIPTION_CANCEL_REQUEST
 * @property SUBSCRIPTION_CANCEL_REQUEST
 */
export const SUBSCRIPTION_CANCEL_REQUEST = '@@comfreight/SUBSCRIPTION_CANCEL_REQUEST';
/**
 * Subscription cancel request action. Marks that a request to cancel the current user's subscription has been made successfully
 * @event SUBSCRIPTION_CANCEL_REQUEST_SUCCESS
 * @property SUBSCRIPTION_CANCEL_REQUEST_SUCCESS
 */
export const SUBSCRIPTION_CANCEL_REQUEST_SUCCESS = '@@comfreight/SUBSCRIPTION_CANCEL_REQUEST_SUCCESS';
/**
 * Thunk for cancelling the current user's subscription
 * @returns {Promise<void>} A promise that resolves when the request has finished
 */
export function cancelSubscription() {
  return (dispatch, getState) => {
    const state = getState();
    if (state.user.subscription.isDeleting) {
      return Promise.resolve();
    }
    dispatch({
      type: SUBSCRIPTION_CANCEL_REQUEST,
      payload: undefined,
    });
    return dispatch(APIFetch(`${API.host}/stripe/subscription/`, {
      method: 'DELETE',
      headers: {
        authorization: `bearer ${state.user.token}`,
      },
    }))
      .then(() => {
        dispatch({
          type: SUBSCRIPTION_CANCEL_REQUEST_SUCCESS,
          payload: undefined,
          meta: {
            analytics: {
              type: trackType.TRACK,
              payload: {
                event: 'Subscription Canceled',
              },
            },
          },
        });
      })
      ; // eslint-disable-line indent
  };
}

/**
 * Dispatching status request action. Marks that a request to get the current user's dispatching status has been made
 * @event DISPATCHING_STATUS_REQUEST
 * @property DISPATCHING_STATUS_REQUEST
 */
export const DISPATCHING_STATUS_REQUEST = '@@comfreight/DISPATCHING_STATUS_REQUEST';
/**
 * Dispatching status request action. Marks that a request to get the current user's dispatching status has been made successfully
 * @event DISPATCHING_STATUS_REQUEST_SUCCESS
 * @property DISPATCHING_STATUS_REQUEST_SUCCESS
 * @property {object} payload - the data fetched from the server, or undefined if the request fails (because there is no dispatching)
 */
export const DISPATCHING_STATUS_REQUEST_SUCCESS = '@@comfreight/DISPATCHING_STATUS_REQUEST_SUCCESS';
/**
 * Thunk for getting getting the current user's dispatching status
 * @returns {Promise<void>} A promise that resolves when the request has finished
 */
export const getDispatchingStatus = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.dispatching.isFetching) {
    return Promise.resolve();
  }
  dispatch({
    type: DISPATCHING_STATUS_REQUEST,
    payload: undefined,
  });
  try {
    const [
      statusRes,
      historyRes,
    ] = await Promise.all([
      dispatch(APIFetch(`${API.host}/cmftstripe/cards/`, {
        headers: {
          'content-type': 'application/json',
          authorization: `bearer ${state.user.token}`,
        },
      })),
      dispatch(APIFetch(`${API.host}/payment/history/?dispatch=true`, {
        headers: {
          'content-type': 'application/json',
          authorization: `bearer ${state.user.token}`,
        },
      })),
    ]);
    dispatch({
      type: DISPATCHING_STATUS_REQUEST_SUCCESS,
      payload: {
        data: statusRes.status === 200 ? await statusRes.json() : undefined,
        history: historyRes.status === 200 ? await historyRes.json() : undefined,
      },
    });
  }
  catch (err) {
    console.warn(err);
    dispatch({
      type: SUBSCRIPTION_STATUS_REQUEST_SUCCESS,
      payload: undefined,
    });
  }
};

export const updateDispatching = token => async (dispatch, getState) => {
  const state = getState();
  if (state.user.dispatching.isFetching) {
    return Promise.resolve();
  }
  dispatch({
    type: DISPATCHING_STATUS_REQUEST,
    payload: undefined,
  });
  const res = await dispatch(APIFetch(`${API.host}/cmftstripe/cards/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${state.user.token}`,
    },
    body: JSON.stringify({ token }),
  }));
  if (res.status !== 201) {
    throw res;
  }
  dispatch({
    type: DISPATCHING_STATUS_REQUEST_SUCCESS,
    payload: {
      data: await res.json(),
    },
  });
};

/**
 * @typedef {object} ValidateEmailResponse
 * @property {string} message - status message
 * @property {string} status - `success` if the email is currently unused, `failure` if the email is currently used
 */
/**
 * A function that makes a request to check if a user with a given email exists
 * @param {string} email - the email to check if exists
 * @returns {Promise<module:actions/user~ValidateEmailResponse>} The response json object from the server
 */
export function validateEmail(email) {
  return global.fetch(`${API.host}/user/check_exists?email=${email}`)
    .then(res => res.json())
    ; // eslint-disable-line indent
}

/**
 * @typedef {object} ResetPasswordResponse
 * @property {string} message - status message
 * @property {string} status - the status of the request to reset the password (typically `success`)
 */
/**
 * A function that makes a request to reset a user's password
 * @param {object} data
 * @param {string} data.email - the email of the user to send a password reset email to
 * @returns {Promise<module:actions/user~ResetPasswordResponse>} The response json object from the server
 */
export function resetPassword(data) {
  return global.fetch(`${API.host}/user/password/reset/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    ; // eslint-disable-line indent
}

/**
 * @typedef {object} ResetPasswordConfirmResponse
 * @property {string} message - status message
 * @property {'success' | 'error' | 'failure'} status - status of the reset password confirmation request
 */
/**
 * A function that sends a request to set a new password given that new password and the reset password token sent to the user's email
 * @param {object} data
 * @param {string} data.new_password - the new password to be set
 * @param {string} data.reset_password_token - the reset password that was sent to the user's email, retrieved in the app via the URL query parameter `token`
 * @returns {Promise<module:actions/user~ResetPasswordConfirmResponse>} The response json object from the server
 */
export function resetPasswordConfirm(data) {
  return global.fetch(`${API.host}/user/password/reset/confirm/`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  })
    .then(res => res.json())
    ; // eslint-disable-line indent
}

/**
 * Thunk for creating a help ticket
 */
export function createHelpTicket(data) {
  return (dispatch, getState) => {
    const state = getState();
    // conditionally include the current user from state since this function may be called from the marketing pages where no such state exists
    const headers = {
      'content-type': 'application/json',
    };
    if (state && state.user && state.user.token) {
      headers.authorization = `bearer ${state.user.token}`;
    }
    return global.fetch(`${API.host}/help/`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
    })
      .then(res => {
        if (res.status !== 201) {
          return res.json().then(json => {
            throw json;
          });
        }
        return res.json();
      })
      ; // eslint-disable-line indent
  };
}


/**
 * Function for creating a factoring submission
 * @param {object} data
 * @param {string} data.name
 * @param {string} data.company
 * @param {string} data.email
 * @param {string} data.phone
 * @param {string} data.mc
 * @param {number} [data.active_units]
 */
export const sendFactoringData = data => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/user/factoring_form/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      ...(() => state && state.user && state.user.token ? { authorization: `bearer ${state.user.token}` } : {})(),
    },
    body: JSON.stringify(data),
  });
  if (res.status !== 200) {
    throw new FetchError('Failed to send factoring request');
  }
};


/**
 * Thunk for getting a login token for a user
 * @param {string} id - the id of the user
 */
export const getLoginToken = id => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/user/${id}/access_token`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${state.user.token}`,
    },
  });
  let json;
  try {
    json = await res.json();
  }
  catch (err) {
    throw new FetchError('error decoding json response');
  }
  if (res.status !== 200) {
    throw new FetchError(json.message);
  }
  return json;
};


export const FACTORING_PROFILE_REQUEST = '@@comfreight/FACTORING_PROFILE_REQUEST';
export const FACTORING_PROFILE_REQUEST_SUCCESS = '@@comfreight/FACTORING_PROFILE_REQUEST_SUCCESS';
export const FACTORING_PROFILE_REQUEST_FAILURE = '@@comfreight/FACTORING_PROFILE_REQUEST_FAILURE';
/**
 * Function for submitting a factoring approval request
 * @param {object} data
 * @param {object} data.company_profile
 * @param {string} data.company_profile.name
 * @param {object} data.company_profile.address
 * @param {string} data.company_profile.address.street_one
 * @param {string} data.company_profile.address.city
 * @param {string} data.company_profile.address.state
 * @param {string} data.company_profile.address.zip
 * @param {string} data.company_profile.address.country
 * @param {string} data.company_profile.phone_number
 * @param {string} data.company_profile.type
 * @param {string} data.company_profile.tax_id
 * @param {string} data.company_profile.mc
 * @param {string} data.company_profile.dot
 * @param {string} data.company_profile.state_incorporated
 * @param {number} data.company_profile.number_of_trucks
 * @param {string} data.company_profile.current_factoring_company
 * @param {object} data.owner_profile
 * @param {string} data.owner_profile.name
 * @param {object} data.owner_profile.address
 * @param {string} data.owner_profile.address.street_one
 * @param {string} data.owner_profile.address.city
 * @param {string} data.owner_profile.address.state
 * @param {string} data.owner_profile.address.zip
 * @param {string} data.owner_profile.address.country
 * @param {string} data.owner_profile.phone_number_cell
 * @param {string} data.owner_profile.phone_number_home
 * @param {string} data.owner_profile.email
 * @param {string} data.owner_profile.date_of_birth
 * @param {string} data.owner_profile.social_number
 * @param {string} data.owner_profile.tax_lien_status
 * @param {string} data.signature
 * @param {File[]} data.attachments
 * @fires module:actions/factoring~FACTORING_PROFILE_REQUEST
 * @fires module:actions/factoring~FACTORING_PROFILE_REQUEST_SUCCESS
 * @fires module:actions/factoring~FACTORING_PROFILE_REQUEST_FAILURE
 */
export const submitFactoringApprovalRequest = data => async (dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  data = convertToDotNotation(data);
  for (const key in data) {
    formData.append(key, data[key]);
  }
  dispatch({
    type: FACTORING_PROFILE_REQUEST,
    payload: undefined,
  });
  try {
    const res = await global.fetch(`${API.host}/factoring/apply/`, {
      method: 'POST',
      headers: {
        'authorization': `bearer ${state.user.token}`,
      },
      body: formData,
    });
    if (res.status !== 201) {
      throw new FetchError(res.status, await res.text());
    }
    dispatch({
      type: FACTORING_PROFILE_REQUEST_SUCCESS,
      payload: await res.json(),
    });
  }
  catch (err) {
    dispatch({
      type: FACTORING_PROFILE_REQUEST_FAILURE,
      payload: err,
    });
    throw err;
  }
};

export const fetchFactoringProfile = () => async (dispatch, getState) => {
  const state = getState();
  if (state.user.factoring.isFetching) {
    return;
  }
  dispatch({
    type: FACTORING_PROFILE_REQUEST,
    payload: undefined,
  });
  try {
    const res = await dispatch(APIFetch(`${API.host}/factoring/profiles/`, {
      method: 'GET',
      headers: {
        'content-type': 'application/json',
        'authorization': `bearer ${state.user.token}`,
      },
    }));
    if (res.status !== 200) {
      throw new FetchError(res.status, await res.text());
    }
    const json = await res.json();
    dispatch({
      type: FACTORING_PROFILE_REQUEST_SUCCESS,
      payload: json,
    });
    return json;
  }
  catch (err) {
    dispatch({
      type: FACTORING_PROFILE_REQUEST_FAILURE,
      payload: err,
    });
    throw err;
  }
};

export const sendFactoringProfileVoidedCheck = file => async (dispatch, getState) => {
  const state = getState();
  const files = Array.isArray(file) ? file : [file];
  const formData = new FormData();
  files.forEach(file => formData.append('voided_checks', file));
  const res = await dispatch(APIFetch(`${API.host}/factoring/profiles/`, {
    method: 'PATCH',
    headers: {
      'authorization': `bearer ${state.user.token}`,
    },
    body: formData,
  }));
  if (res.status !== 200) {
    const text = await res.text();
    throw new FetchError(res.status, text);
  }
};

/**
 * @param {string} message
 */
export const sendFeedback = message => async (dispatch, getState) => {
  const res = await dispatch(APIFetch(`${API.host}/help/feedback/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      message,
    }),
  }));
  if (res.status !== 201) {
    throw new FetchError(res.status, await res.text());
  }
};

/**
 * Thunk for getting a login token for a user
 * @param {string} id - the id of the user
 */
export const refreshUserToken = id => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/user/${id}/access_token`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${state.user.token}`,
    },
    body: JSON.stringify({
      grant_type: 'refresh_token',
      refresh_token: get(state, 'user.refreshToken'),
      client_id: id,
    }),
  });
  let json, token, refreshToken;
  try {
    json = await res.json();
    token = get(json, 'access_token');
    refreshToken = get(json, 'refresh_token');
    storage.setItem(TOKEN_STORAGE_KEY, token);
    storage.setItem(REFRESH_TOKEN_STORAGE_KEY, refreshToken);
    dispatch(tokenSuccess({ token, refreshToken }));
  }
  catch (err) {
    throw new FetchError('error decoding json response');
  }
  if (res.status !== 200) {
    throw new FetchError(json.message);
  }
  return json;
};

export const currentUser = () => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/user/current/`, {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${state.user.token}`,
    }
  });

  const json = await res.json();

  return json.email;
};
