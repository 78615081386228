import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import validate from 'validate.js';

import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';

import * as accountsreceivableSettingsResource from 'actions/settings/accountsreceivable';
import { openModal } from 'actions/ui';

import spinnerIf from 'components/hoc/spinnerIf';
import getSelfType from 'components/hoc/getSelfType';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';


export default compose(
  getSelfType,
  connect(
    state => state.settings.accountsreceivable,
    {
      openModal,
      ...accountsreceivableSettingsResource,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ isFetching }) => isFetching === undefined || isFetching),
  mapProps(({ data, edit, openModal, ...rest }) => ({
    ...rest,
    initialValues: {
      ...data,
      phone: getPhoneParts(data.phone).phone,
      phone_ext: getPhoneParts(data.phone).ext,
      fax: getPhoneParts(data.fax).phone,
      fax_ext: getPhoneParts(data.fax).ext,
    },
    onSubmit(data) {
      return edit({
        ...data,
        phone: normalizeFormPhone(data, 'phone'),
        fax: normalizeFormPhone(data, 'fax'),
        address: {
          street_one: data.address.street_one,
          city: data.address.city,
          state: data.address.state,
          zip: data.address.zip,
          country: data.address.country,
        },
      })
        .then(() => openModal('success', { message: 'Successfully updated info.' }))
        .catch(err => {
          console.warn(err);
          openModal('error', err);
        })
      ;
    },
  })),
  reduxForm({
    form: 'AccountsReceivableForm',
    enableReinitialize: true,
    destroyOnUnmount: false,
    validate: fields => {
      const errs = validate(fields, {
        address: {
          multipresence: ['street_one', 'city', 'state', 'zip', 'country'],
        },
        contact_name: {
          presence: true,
        },
        email: {
          presence: true,
          email: true,
        },
        phone: {
          presence: true,
          phone: true,
        },
        fax: {
          phone: '^Not a valid fax number',
        },
      });
      return errs;
    },
  }),
)(({ dispatch, submitting, handleSubmit, copyAP, userType, openPaymentInformationModal }) =>
  <div>
    <Header>Accounts Receivable Info</Header>
    <div className='help-block'>Contact Responsible for Receiving Payments</div>
    {
      userType === 'broker' ?
        <div className='checkbox'>
          <label>
            <input
              type='checkbox'
              onChange={e => {
                if (e.target.checked === true) {
                  copyAP();
                }
              }}
            />
          Same as AP contact info
          </label>
        </div>
        : null
    }
    <Field name='contact_name' component={LabeledInput} label='Contact Name' />
    <Field name='email' component={LabeledInput} label='Email' />
    <Field name='address' component={GoogleLocationInput} label='Address' />
    <div className='row'>
      <div className='col-md-12'>
        <Field name='phone' component={PhoneInput} label='Phone' />
      </div>
      {/*<div className='col-md-6'>
        <Field name='phone_ext' component={LabeledInput} label='Phone Ext.' type='number' />
      </div>*/}
    </div>
    <div className='row'>
      <div className='col-md-12'>
        <Field name='fax' component={PhoneInput} label='Fax' />
      </div>
      {/*<div className='col-md-6'>
        <Field name='fax_ext' component={LabeledInput} label='Fax Ext.' type='number' />
      </div>*/}
    </div>
    <Field name='ein' component={LabeledInput} label='EIN' />
    <Field name='more_info' component={LabeledInput} className='grow' ComponentClass='textarea' label='More Info' />
    {/* Add Bank/Card modal on Settings/Profile/Account receivables on client side */}
    {/* <button className='btn btn-orange pull-right' type='button' onClick={() => dispatch(openModal('paymentinformation'))}> Request Payment </button> */}
    <button className='btn btn-orange' disabled={submitting} onClick={handleSubmit}>SAVE</button>
  </div>
);
