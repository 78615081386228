/**
 * Actions relating to the rate index
 * @module actions/rateindex
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/PermissionsError
 * @requires datatypes/Resource
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/map
 */
/* global API */
import querystring from 'querystring';
import UrlAssembler from 'url-assembler';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import Resource from 'datatypes/Resource';
import PaginatedResource from 'datatypes/PaginatedResource';
import mapResource from 'actions/resource/map';


export const rateIndexResource = new Resource('rateindex');

export const rateIndexHistoryPaginatedResource = new PaginatedResource('rate_index_history', {
  mountPoint: ['rateindex', 'history'],
  url: new UrlAssembler('ratematrix/details'),
  baseResource: rateIndexResource,
});

/**
 * Fetch request action. Marks that a request to fetch a rate index has been made
 * @event RATE_INDEX_FETCH
 * @property {symbol} type - Symbol(RATE_INDEX_FETCH)
 */
export const RATE_INDEX_FETCH = Symbol('RATE_INDEX_FETCH');
/**
 * Fetch request action
 * @fires RATE_INDEX_FETCH
 */
export function fetchRequest() {
  return {
    type: RATE_INDEX_FETCH,
    payload: undefined,
  };
}

/**
 * Fetch request success action. Marks that a request to fetch a rate index has been made successfully
 * @event RATE_INDEX_FETCH_SUCCESS
 * @property {symbol} type - Symbol(RATE_INDEX_FETCH_SUCCESS)
 * @property {object} json - the json object returned from the server
 */
export const RATE_INDEX_FETCH_SUCCESS = Symbol('RATE_INDEX_FETCH_SUCCESS');
/**
 * Fetch request success action
 * @param {object} json - the json object returned from the server
 * @fires RATE_INDEX_FETCH_SUCCESS
 */
export function fetchRequestSuccess(json) {
  return {
    type: RATE_INDEX_FETCH_SUCCESS,
    payload: json,
  };
}

/**
 * Fetch request failure action. Marks that a request to fetch a rate index has failed
 * @event RATE_INDEX_FETCH_FAILURE
 * @property {symbol} type - Symbol(RATE_INDEX_FETCH_FAILURE)
 * @property {any} err - the error that occurred
 */
export const RATE_INDEX_FETCH_FAILURE = Symbol('RATE_INDEX_FETCH_FAILURE');
/**
 * Fetch request failure action
 * @param {any} err - the error that occurred
 * @fires RATE_INDEX_FETCH_FAILURE
 */
export function fetchRequestFailure(json) {
  return {
    type: RATE_INDEX_FETCH_FAILURE,
    payload: json,
  };
}

/**
 * Thunk to fetch a rate index with the given parameters. Also fetches the route information for the given origin/destination point pair
 * @param {object} data
 * @param {string} origin_city - the city at the origin point
 * @param {string} origin_state - the state at the origin point
 * @param {string} destination_city - the city at the destination point
 * @param {string} destination_state - the state at the destination point
 * @returns {Promise<Action>} - a promise that resolves when the request has finished, or an error
 * @fires RATE_INDEX_FETCH
 * @fires RATE_INDEX_FETCH_SUCCESS
 * @fires RATE_INDEX_FETCH_FAILURE
 */
export const fetch = data => async (dispatch, getState) => {
  const state = getState();
  dispatch(fetchRequest());
  const {
    origin_city,
    origin_state,
    destination_city,
    destination_state,
  } = data;
  const route = {
    origin: [origin_city, origin_state].join(', '),
    destination: [destination_city, destination_state].join(', '),
  };
  try {
    const [
      json,
      map,
    ] = await Promise.all([
      (async () => {
        const res = await dispatch(APIFetch(`${API.host}/ratematrix/?${querystring.stringify(data)}`, {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            'Content-Type': 'application/json',
          },
        }));
        if (res.status !== 200) {
          const text = await res.text();
          if (res.status === 429) {
            throw new PermissionsError(text);
          }
          throw new FetchError(res.status, text);
        }
        return await res.json();
      })(),
      dispatch(mapResource.fetch(route)),
      (() => {
        dispatch(rateIndexHistoryPaginatedResource.clear());
        return dispatch(rateIndexHistoryPaginatedResource.fetch(data));
      })(),
    ]);
    return dispatch(fetchRequestSuccess({
      ...json,
      map: map.payload.json,
    }));
  }
  catch (err) {
    dispatch(fetchRequestFailure(err));
    throw err;
  }
};
