import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import moment from 'moment';

import formatPennies from 'helpers/formatPennies';

import Header from 'components/pure/Header';
import MaterialIcon from 'components/pure/MaterialIcon';
import Receipt from 'components/pure/Receipt';


export default compose(
  connect(
    state => ({
      items: get(state.user, ['dispatching', 'history']),
    }),
    {},
  ),
)(({ items = [] }) =>
  <div style={{ marginTop: '1em' }}>
    <Header>Past Billing History</Header>
    <table className='table'>
      <thead>
        <tr>
          <th>Date</th>
          <th>Amount</th>
          <th>Receipt</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, i) =>
          <tr key={i}>
            <td>{moment(item.created, 'X').format('l')}</td>
            <td>{formatPennies(item.amount)}</td>
            <td>
              <a
                role='button'
                onClick={() => {
                  const markup = renderToStaticMarkup(<Receipt {...item} />);
                  if (Blob) {
                    const blob = new Blob([markup], {
                      type: 'text/html',
                    });
                    const url = URL.createObjectURL(blob);
                    const w = window.open(url);
                    w.addEventListener('load', () => {
                      URL.revokeObjectURL(url);
                    }, false);
                  }
                  else {
                    window.open(`data:text/html;charset=utf-8,${encodeURIComponent(markup)}`);
                  }
                }}
                >
                <MaterialIcon name='open_in_new' /> View Receipt
              </a>
            </td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);
