/**
 * Component to display row in the Load Search Table
 * @module components/pure/LoadSearchTableItem
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/ExpandableLineItem
 * @requires components/pure/UserTable
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {object} props.open - whether this item is in its 'open' state
 * @property {object} props.viewed - whether this item has been marked as viewed
 * @property {object} props.data
 * @property {string} props.data.id
 * @property {string} props.data.user_type
 * @property {moment} props.data.time_posted
 * @property {string} props.data.trailerType
 * @property {string} props.data.origin_city
 * @property {string} props.data.origin_state
 * @property {string} props.data.destination_city
 * @property {string} props.data.destination_state
 * @property {number} props.data.weight
 * @property {number} props.data.length
 * @property {moment} props.data.time_start
 * @property {moment} props.data.time_end
 * @property {moment} props.data.time_delivery
 * @property {object} props.user
 * @property {object} props.user.data
 * @property {string} props.user.data.mc
 * @property {function} props.toggleOpen - toggles the open state of this item
 * @property {function} props.setViewed - marks this item as viewed
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';
import moment from 'moment';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';

import AddressesFormat from 'components/pure/AddressesFormat';
import ExpandableLineItem from 'components/pure/ExpandableLineItem';
import UserTable from 'components/pure/PureUserTable';


const LoadSearchTableItem = ({ open, viewed, data, toggleOpen, setViewed, detailsLocation, dispatchLoad }) =>
  <ExpandableLineItem
    className={[USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(data.user_type) ? 'blue-broker' : 'yellow-shipper'}
    open={open}
    style={{ fontWeight: viewed ? undefined : 'bold' }}
    toggleOpen={() => toggleOpen(data.id)}
    collapsedChild={
      <tr>
        <td colSpan={100} style={{ padding: 0 }}>
          <UserTable
            items={[{
              item: data.user,
              load_id: data.id,
            }]}
            isShipperOnly={data.user_type === USER_TYPE.SHIPPER}
          />
        </td>
      </tr>
    }
    >
    {data ?
      <tr>
        <td>
          {ageAbbr(data.time_posted)}
          {'\u00a0\u00a0\u00a0'}
          {data.user_type === 'shipper' ? <img src='/public/image/shipper_load.png' /> : null}
        </td>
        <td>{data.deadhead ? data.deadhead : '–'}</td>
        <td>{trailerTypeMap[data.trailerType] || data.trailerType}</td>
        <td style={{ maxWidth: 150 }}>
          <AddressesFormat
            addresses={data.origin_locations}
            ignore={{
              address: false,
              zip: false,
              country: false,
            }}
          />
        </td>
        <td style={{ maxWidth: 150 }}>
          <AddressesFormat
            addresses={data.destination_locations}
            ignore={{
              address: false,
              zip: false,
              country: false,
            }}
          />
        </td>
        <td>{data.weight === 0 ? '—' : formatWeight(data.weight)}</td>
        <td>{data.length === 0 ? '—' : data.length}</td>
        <td>{data.time_start.format('MM/DD/YYYY h:mm A')}</td>
        <td>{data.time_end.format('MM/DD/YYYY h:mm A')}</td>
        <td>{data.time_delivery ? data.time_delivery.format('MM/DD/YYYY') : '—'}</td>
        <td>
          <Link onClick={() => setViewed(data.id)} to={detailsLocation || `/load/${data.id}`}><button className='btn btn-orange'>Details</button></Link>
          {dispatchLoad ? <button className='btn btn-success' onClick={dispatchLoad}>Dispatch</button> : null}
        </td>
      </tr>
      : undefined}
  </ExpandableLineItem>
  ;

LoadSearchTableItem.propTypes = {
  open: PropTypes.bool.isRequired,
  viewed: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string.isRequired,
    trailerType: PropTypes.string.isRequired,
    deadhead: PropTypes.number,
    weight: PropTypes.number.isRequired,
    length: PropTypes.number.isRequired,
    time_start: PropTypes.instanceOf(moment).isRequired,
    time_end: PropTypes.instanceOf(moment).isRequired,
    time_delivery: PropTypes.instanceOf(moment),
    time_posted: PropTypes.instanceOf(moment).isRequired,
    user: PropTypes.shape({
      data: PropTypes.shape({
        type: PropTypes.string,
      }),
    }),
  }).isRequired,
  toggleOpen: PropTypes.func.isRequired,
  setViewed: PropTypes.func.isRequired,
  dispatchLoad: PropTypes.func,
};

export default LoadSearchTableItem;
