import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import noop from 'lodash/noop';
import has from 'lodash/has';

import { mapColumnsToBlockRows } from 'helpers';
import TableItem from 'components/pure/TableItem';


/**
 * Helper for creating a table item (for normal tables) and a block table item (like legacy comfreight) given an function that returns an array of columns for a row (this item)
 * @function
 * @param {function} makeColumns - a function that is passed the props for the component and expects an array of columns to be displayed in the row in the table
 * @param {function} [enhancer] - an enhancer to apply to this component
 * @param {object} [makeProps] - a function that is passed the props for the component and expects props to be passed to the resulting component
 * @param {function} [makeProps.desktop] - ^ for desktop
 * @param {function} [makeProps.mobile] - ^ for mobile
 * @returns {string} the formatted weight
 */
const createTableItems = (makeColumns, enhancer = i => i, makeProps) => {
  if (!makeProps) {
    makeProps = {};
  }
  else if (typeof makeProps === 'function') {
    makeProps = {
      desktop: makeProps,
      mobile: makeProps,
    };
  }
  if (!makeProps.desktop) {
    makeProps.desktop = noop;
  }
  if (!makeProps.mobile) {
    makeProps.mobile = noop;
  }
  return {
    TableItem: compose(
      enhancer,
      mapProps(props => {
        const ret = makeProps.desktop(props) || {};
        ret.rows = [makeColumns(props)];
        if (has(props, 'selectable')) {
          ret.selectable = props.selectable;
        }
        if (has(props, 'selected')) {
          ret.selected = props.selected;
        }
        if (has(props, 'onSelect')) {
          ret.onSelect = props.onSelect;
        }
        if (has(props, 'style')) {
          ret.style = props.style;
        }
        return ret;
      }),
    )(TableItem),
    BlockTableItem: compose(
      enhancer,
      mapProps(props => {
        const ret = makeProps.mobile(props) || {};
        ret.rows = mapColumnsToBlockRows(props.headers, makeColumns(props, true));
        if (has(props, 'selectable')) {
          ret.selectable = props.selectable;
        }
        if (has(props, 'selected')) {
          ret.selected = props.selected;
        }
        if (has(props, 'onSelect')) {
          ret.onSelect = props.onSelect;
        }
        if (has(props, 'style')) {
          ret.style = props.style;
        }
        ret.isBlockItem = true;
        return ret;
      }),
    )(TableItem),
  };
};

export default createTableItems;
