import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import mapProps from 'recompose/mapProps';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';

import { setViewed } from 'actions/resource/load';
import { fetchNext } from 'actions/resourceBy/truck/match';

import consolidatePoints from 'components/hoc/consolidatePoints';
import PaginatedGoogleMap from 'components/pure/PaginatedGoogleMap';
import { LoadIcon } from 'components/pure/GoogleMap';


export default compose(
  connect(
    (state, ownProps) => ({
      items: state.resourceBy.truck.match[ownProps.id].ids.map(id => state.resource.load[id]),
      amount: state.resourceBy.truck.match[ownProps.id].ids.length,
      hasNext: Boolean(state.resourceBy.truck.match[ownProps.id]),
      isFetching: state.resourceBy.truck.match[ownProps.id].isFetching,
    }),
    (dispatch, ownProps) => ({
      setViewed: (...args) => dispatch(setViewed(...args)),
      fetchNext: () => dispatch(fetchNext(ownProps.id)),
    }),
  ),
  shouldUpdate((props, nextProps) => (
    props.amount !== nextProps.amount ||
    props.isFetching !== nextProps.isFetching ||
    !shallowEqual(props.items, nextProps.items)
  )),
  consolidatePoints((item, props) => ({
    position: item.data.origin_coords.toLatLng(),
    icon: LoadIcon,
    onClick: () => {
      browserHistory.push(`/load/${item.data.id}`);
      props.setViewed(item.data.id);
    },
  })),
  mapProps(({ items, push, setViewed, id, ids, ...rest }) => ({
    ...rest,
    style: { height: 500 },
  })),
)(PaginatedGoogleMap);
