import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import withContext from 'recompose/withContext';

import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import Header from 'components/pure/Header';
import RouteTabs from 'components/pure/RouteTabs';


export default compose(
  Resource('user', {
    idPropName: ['params', 'id'],
  }),
  withContext(
    {
      params: PropTypes.object,
    },
    ({ params }) => ({ params }),
  ),
  connect(
    null,
    (dispatch, { params: { id, load } }) => {
      if (id && load) {
        return {
          dispatchLoad() {
            dispatch(openModal('dispatchload', {
              load_id: load,
              user_id: id,
            }));
          },
        };
      }
    },
  ),
)(({ children, item, params: { id }, dispatchLoad }) =>
  <div className='col-fill'>
    {
      item && item.data ?
        <Header
          style={{
            marginLeft: '1em',
            flexGrow: 0,
            marginTop: 0,
          }}
          >
          {item.data.dba} - MC#{item.data.mc}
        </Header>
        : null
    }
    <RouteTabs
      style={{
        flexGrow: 0,
        marginTop: 0,
      }}
      routes={[
        [`/admin/dispatch/${id}/search`, 'Search Loads'],
        [`/admin/dispatch/${id}/list`, 'Dispatched Loads'],
        [`/admin/dispatch/${id}/card`, 'Update Card'],
        [`/admin/dispatch/${id}/onboarding`, 'Onboarding'],
      ]}
      extras={
        dispatchLoad ?
          <div className='pull-right'>
            <button className='btn btn-success' style={{ marginTop: '0.5em', marginRight: '0.5em' }} onClick={dispatchLoad}>Dispatch Load</button>
          </div>
          : null
      }
    />
    {children}
  </div>
);
