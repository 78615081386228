/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset } from 'redux-form';
import get from 'lodash/get';

import { create as createTruckQA } from 'actions/resourceBy/truck/qa';
import { create as createLoadQA } from 'actions/resourceBy/load/qa';

import ResourceBy from 'components/hoc/ResourceBy';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import QaCollection from 'components/pure/QaCollection';
import segmentEvents from '../../helpers/segmentEvents';


export const TruckQaCollection = compose(
  ResourceBy('truck', 'qa'),
  spinnerIfFetching(),
  connect(
    (state, ownProps) => ({
      isOwner: get(state.resource.truck, [ownProps.id, 'data', 'user_id']) === state.user.id,
    }),
    (dispatch, ownProps) => ({
      handleQuestionSubmit(fields) {
        return dispatch(createTruckQA(ownProps.id, fields))
          .then(() => {
            window.analytics.track(segmentEvents.USER_ASKED_QUESTION_TRUCK);
            dispatch(reset(`QAQForm-${ownProps.id}`))
          });
      },
    }),
  ),
)(({ id, item, isOwner, handleQuestionSubmit }) =>
  <QaCollection
    id={id}
    isOwner={isOwner}
    handleQuestionSubmit={handleQuestionSubmit}
    resource='truck'
    ids={item.ids}
  />
);

export const LoadQaCollection = compose(
  ResourceBy('load', 'qa'),
  spinnerIfFetching(),
  connect(
    (state, ownProps) => ({
      isOwner: get(state.resource.load, [ownProps.id, 'data', 'user_id']) === state.user.id,
    }),
    (dispatch, ownProps) => ({
      handleQuestionSubmit(fields) {
        return dispatch(createLoadQA(ownProps.id, fields))
          .then(() => {
            window.analytics.track(segmentEvents.USER_ASKED_QUESTION_LOAD);
            dispatch(reset(`QAQForm-${ownProps.id}`))
          });
      },
    }),
  ),
)(({ id, item, isOwner, handleQuestionSubmit }) =>
  <QaCollection
    id={id}
    isOwner={isOwner}
    handleQuestionSubmit={handleQuestionSubmit}
    resource='load'
    ids={item.ids}
  />
);
