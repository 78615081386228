/**
 * Root reducer for truck and load search
 * @module reducers/search
 * @since 3.0.0
 * @requires reducers/search/load
 * @requires reducers/search/truck
 * @requires reducers/search/user
 * @requires reducers/search/loadmap
 * @requires reducers/search/factoringclient
 */
import { combineReducers } from 'redux-v3';

import load from './load';
import truck from './truck';
import user from './user';
import loadmap from './loadmap';
import factoringclient from './factoringclient';
import factoringdebtor from './factoringdebtor';


export default combineReducers({
  load,
  truck,
  user,
  loadmap,
  factoringclient,
  factoringdebtor,
});
