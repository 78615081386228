/* global EMPTY_PLACEHOLDER */

import React from 'react';
import { connect } from 'react-redux-v5';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import cs from 'classnames';
import { Link } from 'react-router-v4';

import { fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';
import { openModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';
import ResourceBy from 'components/hoc/ResourceBy';
import nothingIf from 'components/hoc/nothingIf';
import ResourceByIDs from 'components/hoc/ResourceByIDs';

import FactoringPaymentAttachments from 'components/pure/FactoringPaymentAttachments';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';

const PaymentsTableComponents = createTableItems(
  ({ data, fetchInvoice, fetchCollated }, is_mobile) => {
    return [
      data.debtor.dba ? data.debtor.dba : data.debtor_company_name,
      formatPennies(data.net_worth),
      data.invoice_number,
      data.user_load_number || EMPTY_PLACEHOLDER,
      data.created.format('l LTS'),
      data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
      data.discount_rate ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
      data.amount_funded ? formatPennies(data.amount_funded) : EMPTY_PLACEHOLDER,
      <b
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid'].includes(data.status),
          'text-warning': ['pending', 'pending_originals'].includes(data.status),
          'text-red': ['declined', 'incomplete'].includes(data.status),
          'text-blue': ['new', 'fuel_advance'].includes(data.status),
        })}
        >
        {data.status}
      </b>,
      is_mobile ?
        <div className='text-right'>
          <FactoringPaymentAttachments
            attachments={data.attachments}
            fetchInvoice={() => fetchInvoice(data.id)}
            fetchCollated={() => fetchCollated(data.id)}
          />
        </div>
        : null,
      <Link to={`/haul-pay/getpaid/history/${data.id}`}><button className='btn btn-orange' type='button'>More</button></Link>,
    ];
  },
  connect(
    null,
    dispatch => ({
      fetchInvoice(...args) {
        try {
          return dispatch(fetchInvoice(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
        }
      },
      fetchCollated(...args) {
        try {
          return dispatch(fetchCollated(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
        }
      },
    }),
  )
);

const PaymentsTableClient = compose(
  connect(
    state => {
      return {
        id: state.user.id,
      };
    },
    {},
  ),
  mapProps(({ id, debtor }) => ({
    id,
    fetchArgs: {
      debtor_id: debtor,
      status: 'incomplete',
    },
  })),
  ResourceBy('user', 'factoringpayment', {
    shouldClearBeforeFetch: true,
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpayment',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
)(({ actions, item, items }) => {
  // if (items) {
  //   return <Spinner />;
  // }
  return (
    <div>
      <ResponsiveTable
        items={items}
        isFetching={item.isFetching}
        fetchNext={actions.fetchNext}
        placeholder='No payments.'
        headers={['Bill To', 'Invoice Amnt.', 'Invoice #', 'Load #', 'Requested At', 'Funded At', 'Rate', 'Payment Amnt.', 'Status', '', 'Details']}
        TableItem={PaymentsTableComponents.TableItem}
        BlockTableItem={PaymentsTableComponents.BlockTableItem}
        striped={true}
      />
      {
        item.next ?
          <div>
            <button
              type='button'
              className='btn btn-orange'
              onClick={actions.fetchNext}
              disabled={item.isFetching}
              >Load More
            </button>
          </div>
          : null
      }
    </div>
  );
});

const IncompleteFactoringPayments = ({ loading, params: { id } }) =>
  <div>
    <div className='clearfix' style={{ marginTop: '1.65em', marginBottom: '1.65em' }}>
      <Header style={{ display: 'inline-block', marginTop: 0 }}>Incomplete Payments</Header>
    </div>
    <PaymentsTableClient debtor={id} />
  </div>;

export default IncompleteFactoringPayments;
