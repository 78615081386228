/**
 * Component that displays an iframe to a Vimeo video
 * @module components/pure/YoutubeEmbed
 * @since 3.0.0
 * @property {object} props
 * @property {number} props.video_id - the id of the video to embed
 * @property {any} [props....rest] - props passed to containing component
 */
import qs from 'querystring';
import React from 'react';
import PropTypes from 'prop-types';


const VimeoEmbed = ({
  video_id,
  autoplay = false,
  ...rest
}) =>

  <div
    style={{
      marginTop: '1em',
      position: 'relative',
      paddingBottom: '56.25%',
    }}
    >
    <iframe
      width='100%'
      height='100%'
      marginHeight='0'
      marginWidth='0'
      frameBorder='0'
      scrolling='no'
      seamless={true}
      allowFullScreen={true}
      allowTransparency={true}
      src={`https://player.vimeo.com/video/${video_id}?${qs.stringify({
        autoplay: autoplay ? '1' : '0',
        background: '0',
        loop: '1',
        byline: '1',
      })}`}
      {...rest}
      style={{
        width: '100%',
        position: 'absolute',
        left: 0,
        ...rest.style,
      }}
    />
  </div>
;

VimeoEmbed.propTypes = {
  video_id: PropTypes.string.isRequired,
};

export default VimeoEmbed;
