import React from "react";
import ImageIcon from 'components/pure/ImageIcon';

const SelfFinanceIcon = ({ contractType = '', width, height }) => {
    const src = () => {
        switch (contractType) {
            case 'SELF_FINANCE_NO_INVOICE':
                return '/public/image/icon/sf-no-invoice.png';
            case 'SELF_FINANCE_WITH_INVOICE':
                return '/public/image/icon/sf-with-invoice.png';
            case 'SELF_FINANCE_LEDGER_DEPOSIT':
                return '/public/image/icon/sf-ledger-deposit.png';;
            case 'SELF_FINANCE_RECEIVABLE':
                return '/public/image/icon/sf-receivables.png';;
        }}
    return ['SELF_FINANCE_NO_INVOICE', 'SELF_FINANCE_WITH_INVOICE', 'SELF_FINANCE_LEDGER_DEPOSIT', 'SELF_FINANCE_RECEIVABLE'].includes(contractType) ?
        <div>
            <ImageIcon
                src={src()}
                style={{ width: width || 30, height: height || 30, filter: "invert(42%) sepia(29%) saturate(904%) hue-rotate(71deg) brightness(93%) contrast(95%)" }}
            />
        </div> : null;
};
export default SelfFinanceIcon;
