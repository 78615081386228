import React from 'react';
import { Link } from 'react-router-v4';

import RateIndexBlockTable from './RateIndexBlockTable';


const RateIndexPermissionsErrorTableItem = () =>
  <tbody>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Avg. Linehaul Rate:</td>
      <td><Link to='/settings/billing/subscription'>Upgrade To View</Link></td>
    </tr>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Avg. Rate Per Mile:</td>
      <td><Link to='/settings/billing/subscription'>Upgrade To View</Link></td>
    </tr>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Estimated Fuel Cost:</td>
      <td><Link to='/settings/billing/subscription'>Upgrade To View</Link></td>
    </tr>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Estimated Revenue:</td>
      <td><Link to='/settings/billing/subscription'>Upgrade To View</Link></td>
    </tr>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Distance:</td>
      <td><Link to='/settings/billing/subscription'>Upgrade To View</Link></td>
    </tr>
  </tbody>
  ;

const RateIndexPermissionsErrorTable = () =>
  <RateIndexBlockTable
    blockTableProps={{
      // display one item in this table, our placeholder above
      items: [undefined],
      ListItem: RateIndexPermissionsErrorTableItem,
    }}
  />
  ;

export default RateIndexPermissionsErrorTable;
