/**
 * Settings resource for the `/user/pinfo` endpoint
 * @module actions/settings/accountspayable
 * @since 3.0.0
 * @requires datatypes/SettingsResource
 */
import SettingsResource from '../../datatypes/SettingsResource';


/**
 * Our `/user/pinfo` Settings Resource
 */
const accountspayableSettingsResource = new SettingsResource('accountspayable', {
  url: () => 'user/pinfo',
  mountPoint: 'settings.accountspayable',
  hooks: {
    preDispatchCreateSuccess: data => data,
  },
});

export default accountspayableSettingsResource;
export const {
  fetch,
  edit,
} = accountspayableSettingsResource;
