/**
 * Error that represents Authenticaion errors.
 * @module datatypes/AuthenticationError
 * @since 3.0.0
 */

export class AuthenticationError extends Error {
  constructor(message) {
    super(message);
    this.name = 'AuthenticationError';
  }
}

export default AuthenticationError;
