import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import getSelfType from 'selectors/getSelfType';

const renderChildren = ({ children }) => children;

const mapStateToProps = selectors => state => ({
  allowed: selectors.map(selector => selector(state)).reduce((acc, curr) => acc || curr, false),
  userType: getSelfType(state),
});


const requireSelectors = (selectors = [], redirect_to = '/') => (WrappedComponent = renderChildren) => compose(
  connect(
    mapStateToProps(selectors)
  )(class requireSelectors extends Component {
    componentWillMount() {
      const { allowed, userType } = this.props;
      if (!allowed) {
        if (!userType) {
          const { location: { pathname } } = this.props;
          if (!(pathname === '/login' || pathname === '/signup' || pathname === '/resetpassword')) {
            browserHistory.replace(`/login?next=${pathname}`);
          }
        }
        else {
          browserHistory.replace(redirect_to);
        }
      }
    }
    render() {
      const { allowed, ...rest } = this.props;
      if (!allowed) {
        return null;
      }
      return <WrappedComponent {...rest} />;
    }
  })
);

export default requireSelectors;
