/**
 * Error that represents submission errors.
 * @module datatypes/SubmissionError
 * @since 3.0.0
 */

function SubmissionError(message) {
    this.name = this.constructor.name;
    this.message = message;
    if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
}

SubmissionError.prototype = Object.create(Error.prototype);
SubmissionError.prototype.constructor = SubmissionError;

export default SubmissionError;
