import validate from 'validate.js';


const DEFAULT_OPTIONS = {
  message: ({ missing }) => `Must include ${missing.join(', ')}`,
  formatKey: i => i,
};

export const multipresence = options => val => {
  if (Array.isArray(options)) {
    options = {
      ...DEFAULT_OPTIONS,
      required: options,
    };
  }
  else {
    options = {
      ...DEFAULT_OPTIONS,
      ...options,
    };
  }

  const missing = [];
  options.required.forEach(subkey => {
    if (!val || validate.isEmpty(val[subkey])) {
      missing.push(options.formatKey(subkey));
    }
  });
  if (missing.length) {
    return options.message({ missing });
  }
};
