/**
 * Component to display a table for the Load Post page
 * @module components/pure/LoadPostTable
 * @since 3.0.0
 * @property {object} props
 * @property {string[]} props.ids
 * @property {boolean} [props.isFetching = false]
 * @property {function} props.fetchNext
 * @property {any} [props....rest] - props passed to containing component
 */
import React, { useEffect, useState } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import { initiateEdit } from 'actions/post';
import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import TableItemAddonBids from 'components/container/TableItemAddonBids';
import LoadMobile3 from 'components/pure/LoadMobile3';


const LoadPostTableItem = compose(
  Resource('load', { resourcePropName: 'load' }),
  connect(
    null,
    {
      initiateEdit: (...args) => initiateEdit('load', ...args),
      initiateDelete: id => openModal('delete', { id, resource: 'load' }),
    },
  )
)(LoadMobile3);

const LoadPostTable = ({ ids, isFetching = false, fetchNext, openLoadModalPost }) => {
  const [display, setDisplay] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => setDisplay(true), 2000);
    return () => clearTimeout(timer);}
    , []);
    
  return (
    <div className='col-fill' style={{ minHeight: 600 }}>
      {/*<div className='visible-xs-block' style={{ flexGrow: 0, margin: '5px 3px 0px 4px' }}>*/}
      {/*  <Link to='/postload/history' className='pull-right'><button className='btn btn-default' style={{ fontSize: '0.85em' }}>My History</button></Link>*/}
      {/*</div>*/}
      <NormalInfiniteScroll
        isFetching={isFetching}
        onScrollBottom={fetchNext}
        placeholder={display && <div className='help-block text-center'>No loads (yet)<br />Try posting some<br />Old posts are in History</div>}
      >
        {!ids ? null : ids.map(id =>
          <div>
            <LoadPostTableItem id={id} style={{ marginBottom: 0 }} openLoadModalPost={openLoadModalPost} />
            <TableItemAddonBids load_id={id} />
          </div>
        )}
      </NormalInfiniteScroll>
    </div>
  );
};

export default LoadPostTable;
