import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import moment from 'moment';
import { Link } from 'react-router-v4';

import createTableItems from 'helpers/createTableItems';
import leadsActions from 'actions/admin/leads';

import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const LeadsTableComponents = createTableItems(
  ({ item }) => [
    moment(item.data.time_posted).format(),
    item.data.name,
    item.data.company,
    item.data.email,
    item.data.phone,
    item.data.mc,
    item.data.dot,
    <span className='text-capitalize'>
      {item.data.user_type}
      {' '}
      {
        item.data.user ?
          <Link to={`/admin/user/search/${item.data.user}`}>
            <button className='btn btn-orange' type='button'>More</button>
          </Link>
          : null
      }
    </span>,
    item.data.source,
    item.data.active_units,
  ],
  compose(
    connect(
      (state, { id }) => ({
        item: state.resource.lead[id],
      }),
    ),
  ),
);

const LeadsTable = compose(
  connect(
    state => ({
      items: state.admin.leads.ids.map(id => ({ id })),
      isFetching: state.admin.leads.isFetching,
    }),
    {
      fetchNext: leadsActions.fetchNext,
    },
  ),
)(({ fetchNext, isFetching, items = [] }) =>
  <ResponsiveTable
    fetchNext={fetchNext}
    isFetching={isFetching}
    items={items}
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Submitted', 'Name', 'Company', 'Email', 'Phone', 'MC', 'DOT', 'User Type', 'Source', 'Active Units']}
    placeholder='No results'
    TableItem={LeadsTableComponents.TableItem}
    BlockTableItem={LeadsTableComponents.BlockTableItem}
  />
);

export default compose(
  connect(
    null,
    dispatch => ({
      update() {
        dispatch(leadsActions.clear());
        return dispatch(leadsActions.fetch({
          user_type: location.pathname.slice(location.pathname.lastIndexOf('/') + 1),
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(() =>
  <div className='col-fill' style={{ padding: '0 1em' }}>
    <RouteTabs
      style={{ flexGrow: 0 }}
      routes={[
        ['/admin/factoring/client/leads/carrier', 'Carriers'],
        ['/admin/factoring/client/leads/broker', 'Brokers'],
        ['/admin/factoring/client/leads/dispatcher', 'Dispatchers'],
      ]}
    />
    <LeadsTable />
  </div>
);
