import React from 'react';
import { submit, isSubmitting, SubmissionError } from 'redux-form';
import { withStateHandlers, compose } from 'recompose';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { connect } from 'react-redux-v5';
import { closeModal, openModal } from 'actions/ui';
import { MCDOTAutoCompleteItem } from '../form/inputs/MCDOTAutocomplete';
import {
    createPaymentsProfiles,
    updatePaymentsProfile,
    createFactorCompanyRelation,
    sendFactorCompanyInvite,
} from 'actions/groups';
import { ValidationPopUpView } from '../../container/FactoringAddClients';
import segmentEvents from '../../../helpers/segmentEvents';
import {
    CREATE_PAYMENT_PROFILE_FORM_NAME,
    SetFactorCompany,
    SetPaymentSpeed,
    categories,
    mappingSubmitButtonMapping,
} from './CreatePaymentProfile';
import PureUploadFile from '../PureUploadFile';
import Spinner from '../Spinner';
import { get } from 'lodash';

const styles = {
    button: {
        backgroundColor: '#FF7800',
        color: '#fff',
    },
    flexColumn: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
    },
    text: {
        fontSize: 12,
        color: '#000000',
        fontWeight: '400',
    },
    icon: {
        color: '#4a90e2',
    },
    customBackgroundDialog: {
        backgroundColor: 'rgba(92, 105, 121, 0.8)',
    },
    timeIcon: {
        //fontSize: 16,
        color: '#979797',
    },
};
const ModalTitle = ({ title }) => (
    <h4 className="modal-title text-orange">{title}</h4>
);
const MatchedInvitedCarrier = ({
    closeModal,
    openModal,
    invitedCarrier = [],
    createPaymentsProfiles,
    setStep,
    step,
    terms,
    relationshipId,
    paymentId,
    submitting,
    handleSubmit,
    factoringId,
    carrier,
    carrierIsConnected,
    searchType = "EMAIL",
    ...props
}) => {
    const renderMappingComponent = () => {
        switch (step) {
            case 'form':
                return invitedCarrier.map((carrier) => (
                    <MCDOTAutoCompleteItem
                        value={carrier}
                        onClick={async () => {
                            await createPaymentsProfiles(carrier);
                            setStep('paymentSpeed');
                        }}
                    />
                ));
            case 'paymentSpeed':
                return (
                    <SetPaymentSpeed
                        terms={terms}
                        setStep={setStep}
                        onSubmit={async (fields, dispatch) => {
                            try {
                                const res = await dispatch(
                                    updatePaymentsProfile(
                                        factoringId,
                                        relationshipId,
                                        {
                                            payout_days: fields.payout_days,
                                        }
                                    )
                                );
                                setStep('upload');
                            } catch (err) {
                                console.warn(err);
                            }
                        }}
                    />
                );
            case 'factorCompany':
                return (
                    <SetFactorCompany
                        setStep={setStep}
                        onSubmit={async (fields, dispatch) => {
                            try {
                                const companyId = get(
                                    fields,
                                    'factor_company.id',
                                    ''
                                );
                                await dispatch(
                                    createFactorCompanyRelation(
                                        companyId,
                                        factoringId,
                                        paymentId
                                    )
                                );
                                await dispatch(
                                    sendFactorCompanyInvite(
                                        companyId,
                                        paymentId
                                    )
                                );
                            } catch (err) {
                                if (err.payment_profile === 'No relation with the factoring company found') {
                                    throw new SubmissionError({ _error: 'Carrier is currently linked to another factoring company. If you believe this is an error, please reach out to our support team at support@haulpay.io for assistance.' })
                                }
                            }
                            setStep('upload');
                        }}
                    />
                );
            case 'upload':
                return (
                    <PureUploadFile
                        categoriesProps={{
                            abbreviationKey: 'value',
                            fulltextKey: 'text',
                            breakPoint: 2,
                        }}
                        categories={categories}
                        requestId={paymentId}
                        type="payment"
                        extraText={
                            <p
                                style={{
                                    color: 'green',
                                    textAlign: 'center',
                                    width: '95%',
                                }}
                            >
                                Upload an NOA or voided check to expedite the
                                payment approval process for your carrier.
                            </p>
                        }
                    />
                );
            default:
                break;
        }
    };

    const MCDOTDescriptionText = ({ searchType, step }) => (
        <div style={{ paddingTop: 10 }}>
            {searchType === 'MC/DOT' ?
                <p style={styles.text}>
                    An existing profile matches the MC# for the carrier you searched for.
                </p> :
                <p style={styles.text}>
                    An existing profile matches the email address you've entered.
                </p>
            }
            {['paymentSpeed', 'factorCompany'].includes(step) &&
                <div>
                    <p style={styles.text}>
                        <b>If you want to pay the carrier directly</b>, please select a payment speed below and upload a voided check or acceptable banking docs to ensure we have the most recent info on file.
                    </p>
                    <ul>
                        <li>
                            <p style={styles.text}>
                                <b>Please note</b> if the carrier does not have direct payment info on file AND is set up with a factoring company, we will attempt to gather & confirm direct pay details with the carrier
                            </p>
                            <ul style={{ listStyleType: 'circle' }}>
                                <li>
                                    <p style={styles.text}>
                                        <b>HOWEVER</b> if banking details are not provided before you approve the Funding Request their factor will be paid at your default factoring company payment speed and broker portion.
                                    </p>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <p style={styles.text}>
                        <b>If you want to pay the carrier's factoring company</b>, please choose “Select a Factoring Company” and upload the carrier’s NOA. 
                        <ul>
                            <li>
                                <p style={styles.text}>
                                    <b>If you can’t find the correct factoring company</b>, please enter and select the factor called “NOT FOUND” and upload the NOA and our team will take it from there.
                                </p>
                            </li>
                            <li>
                                <p style={styles.text}>
                                    <b>If you choose “NOT FOUND”</b> and don’t have the NOA, Or Get an error stating the carrier is connected to another factor
                                </p>
                                <ul style={{ listStyleType: 'circle' }}>
                                    <li>
                                        <p style={styles.text}>
                                            Please email us at <a
                                                href="mailto:invitedcarriers@haulpay.io"
                                                className="space"
                                            >invitedcarriers@haulpay.io</a> with the Carrier’s Name, Email, MC#, NOA (if available), and the name of the factor you want to pay
                                        </p>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </p>
                    <p style={styles.text}>
                        <b>WARNING</b> - IF YOU DON’T COMPLETE THIS PROCESS - this carrier will be added to your carriers section at 1 day quick pay with your default broker portion.  
                    </p>
                </div>
            }
            <p style={styles.text}>
                Any questions or concerns please email us at <a
                    href="mailto:invitedcarriers@haulpay.io"
                    className="space"
                >invitedcarriers@haulpay.io</a>
            </p>
        </div>

    )
    return (
        <div>
            <ModalBody closeModal={closeModal}>
                <ModalTitle title={'Add a Carrier'} />
                <div style={{ width: '100%', ...styles.flexColumn }}>
                    <div
                        style={{
                            ...styles.flexColumn,
                            justifyContent: 'center',
                        }}
                    >
                        <MCDOTDescriptionText searchType={searchType} step={step} />
                    </div>
                    <div
                        style={{
                            ...styles.flexColumn,
                            margin: '-5px 5px 5px 5px',
                            paddingTop: 20,
                        }}
                    >
                        {renderMappingComponent()}
                    </div>
                    {step !== 'form' && (
                        <button
                            disabled={submitting}
                            className="btn btn-orange"
                            type="button"
                            onClick={() => {
                                if (step !== 'upload') {
                                    return handleSubmit();
                                }
                                closeModal();
                                setTimeout(
                                    () =>
                                        openModal('EmptyModal', {
                                            children: (
                                                <ValidationPopUpView
                                                    closeModal={closeModal}
                                                    isConnected={
                                                        carrier.is_connected
                                                    }
                                                    number={
                                                        carrier.company_mc ||
                                                        carrier.company_dot
                                                    }
                                                />
                                            ),
                                        }),
                                    500
                                );
                                // window.location.reload();
                            }}
                        >
                            <span>
                                {submitting ? (
                                    <div>
                                        {mappingSubmitButtonMapping[step]}
                                        <Spinner style={{ fontSize: 3 }} />
                                    </div>
                                ) : (
                                    mappingSubmitButtonMapping[step]
                                )}
                            </span>
                        </button>
                    )}
                </div>
            </ModalBody>
        </div>
    );
};

export default compose(
    withStateHandlers(
        ({
            step = 'form',
            relationshipId = '',
            paymentId = '',
            carrierIsConnected = false,
            carrier = {},
        }) => ({
            step,
            relationshipId,
            paymentId,
            carrierIsConnected,
            carrier,
        }),
        {
            setStep: (props) => (step) => ({
                step,
            }),
            setRelationshipId: (props) => (relationshipId) => ({
                relationshipId,
            }),
            setPaymentId: (props) => (paymentId) => ({
                paymentId,
            }),
            setCarrierIsConnected: (props) => (carrierIsConnected) => ({
                carrierIsConnected,
            }),
            setCarrier: (props) => (carrier) => ({
                carrier,
            }),
        }
    ),
    connect(
        (state) => ({
            submitting: isSubmitting(CREATE_PAYMENT_PROFILE_FORM_NAME)(state),
        }),
        (
            dispatch,
            {
                factoringId,
                invitedUserId,
                setRelationshipId,
                setPaymentId,
                setCarrierIsConnected,
                setCarrier,
            }
        ) => ({
            closeModal() {
                dispatch(closeModal());
            },
            openModal(...args) {
                dispatch(openModal(...args));
            },
            handleSubmit() {
                dispatch(submit(CREATE_PAYMENT_PROFILE_FORM_NAME));
            },
            async createPaymentsProfiles(carrier) {
                const res = await dispatch(
                    createPaymentsProfiles({
                        factoring_id: factoringId,
                        payment_profile_id: carrier.id,
                        invited_user_id: invitedUserId,
                    })
                );
                setRelationshipId(
                    get(res, 'payment_profile.relationship_id', '')
                );
                setPaymentId(carrier.id);
                setCarrierIsConnected(carrier.is_connected);
                setCarrier(carrier);
                if (carrier.is_connected) {
                    dispatch(closeModal());
                    setTimeout(
                        () =>
                            dispatch(
                                openModal('EmptyModal', {
                                    children: (
                                        <ValidationPopUpView
                                            closeModal={() =>
                                                dispatch(closeModal())
                                            }
                                            isConnected={carrier.is_connected}
                                            number={
                                                carrier.company_mc ||
                                                carrier.company_dot
                                            }
                                        />
                                    ),
                                })
                            ),
                        500
                    );
                }
                window.analytics.track(
                    segmentEvents.BROKER_INVITED_CARRIER_BY_EMAIL
                );
            },
        })
    )
)(MatchedInvitedCarrier);
