/**
 * Higher order component for modals. Passes the a function that will close the latest modal under the name `closeModal`
 * @module components/hoc/Modal
 * @since 3.0.0
 * @requires actions/ui
 */
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import setDisplayName from 'recompose/setDisplayName';

import { closeModal } from 'actions/ui';


export default compose(
  setDisplayName('Modal'),
  connect(null, { closeModal }),
);
