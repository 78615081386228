/**
 * Connected modal component to prompt a user to create a quick factoring invoice
 * @module components/pure/modals/QuickInvoice
 * @since 3.0.0
 * @requires actions/ui
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset } from 'redux-form';
import { closeModal, openModal } from 'actions/ui';
import validate from 'validate.js';
import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';
import createTableItems from 'helpers/createTableItems';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import RadioInput from 'components/pure/form/inputs/RadioInput';
import bankinfoPaginatedResource from 'actions/admin/factoring/bank';
import { edit } from 'actions/resource/defaultbank';


const FORM_NAME = 'FetchWireAccounts';
const formatAccountNumberRedacted = account => {
  return `xxxx - ${account}`;
};
const BanksTableComponents = createTableItems(
  ({ data }) => [
    formatAccountNumberRedacted(data.account_number_redacted),
    data.default === true ?
      <Field
        name='account_id'
        component='input'
        type='radio'
        value={data.id}
        checked={true}
      /> :
      <Field
        name='account_id'
        component='input'
        type='radio'
        value={data.id}
      />,
  ],
);

export default compose(
  connect(
    state => ({
      ids: state.admin.factoring.bank.ids,
      isFetching: state.admin.factoring.bank.isFetching,
      banks: state.resource.getbankinfo,
      currentOrder: state.admin.factoring.bank.ordering,
    }),
    {
      fetchNext: bankinfoPaginatedResource.fetchNext,
      sort: bankinfoPaginatedResource.sort,
    },
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, props) {
      const data = {
        default: true,
      };
      try {
        if (!fields.account_id) {
          dispatch(closeModal());
          dispatch(openModal('warning', { message: 'Its already Set Default or You must Select One Wire Account.' }));
          reset();
        }
        else {
          await dispatch(edit(data, props.id.item.data.user, fields.account_id));
          dispatch(closeModal());
          dispatch(openModal('success', { message: 'Succesfully Set Default Wire Account.' }));
          reset();
        }
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: `${err.message.detail}.` }));
        dispatch(closeModal());
        reset();
      }
    },
  }),
)(({ id, submitting, dispatch, handleSubmit, ids, isFetching, fetchNext, banks, sort, currentOrder }) =>
  <div>
    <form onSubmit={handleSubmit}>
      <div className='modal-body'>
        <div className='light-placeholder'>
          <button type='button' className='close' aria-label='Close' onClick={() => dispatch(closeModal())}><span aria-hidden='true'>×</span></button>
          <Header>Select a Wire Account to Make it Default</Header>
          <hr />
          <ResponsiveTable
            containerProps={{
              className: 'col-fill',
            }}
            isFetching={isFetching}
            fetchNext={fetchNext}
            sortable={true}
            currentOrder={currentOrder}
            sort={sort}
            headers={[
              { text: 'Account # (Last 4 digits)', ordering: false },
              { text: 'Default', ordering: false },
            ]}
            placeholder='No results'
            TableItem={BanksTableComponents.TableItem}
            BlockTableItem={BanksTableComponents.BlockTableItem}
            items={ids.map(id => banks[id])}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-default' type='button' onClick={() => dispatch(closeModal())}>Cancel</button>
        <button
          className='btn btn-orange'
          type='submit'
          disabled={submitting}
          >
          {
            submitting ?
              <span>
                <Spinner />
              PROCESSING
              </span>
              :
              'Set As Default'
          }
        </button>
      </div>
    </form>
  </div>
);
