/**
 * Actions for the factoring payment actions
 * @module actions/admin/factoring/payment
 * @since 3.0.1
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringapplication
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import factoringpaymentResource from 'actions/resource/factoringpayment';


class FactoringPaymentsAdminPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }
}

const factoringpaymentPaginatedResource = new FactoringPaymentsAdminPaginatedResource('admin_factoring_payment', {
  mountPoint: ['admin', 'factoring', 'payment'],
  url: new UrlAssembler('factoring/funding/request?line_item_stats=true&client_reserve_stats=true'),
  baseResource: factoringpaymentResource,
});

export default factoringpaymentPaginatedResource;
