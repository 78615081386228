/**
 * Higher order component to augment the ResourceBy HOC. Transforms the IDs given by ResourceBy into the resources for those ids.
 * WILL EAT ALL PROP UPDATES
 * @module components/hoc/ResourceByIDs,
 * @since 3.0.1
 */
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';
import get from 'lodash/get';


const DEFAULT_OPTIONS = {
  resource: undefined, // required
  getResourceFromState: (state, id, resource) => state.resource[resource][id],
  idPropName: ['item', 'ids'],
  itemsPropName: 'items',
  shouldUpdate: () => false,
};

const ResourceByIDs = options => {
  if (typeof options === 'string') {
    options = {
      resource: options,
    };
  }
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  return compose(
    connect(
      (state, ownProps) => {
        const ids = get(ownProps, options.idPropName);
        if (!ids || !ids.length || !Array.isArray(ids)) {
          return {
            [options.itemsPropName]: [],
          };
        }
        const items = ids.map(id => options.getResourceFromState(state, id, options.resource));
        return {
          [options.itemsPropName]: items,
        };
      },
      {},
    ),
    shouldUpdate(
      (props, nextProps) => {
        return !shallowEqual(props[options.itemsPropName], nextProps[options.itemsPropName]) || options.shouldUpdate(props, nextProps);
      },
    ),
  );
};

export default ResourceByIDs;
