import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { setViewed, toggleOpen } from 'actions/resource/load';

import HiddenPostLineItem from 'components/container/HiddenPostLineItem';
import hiddenPostBranch from 'components/hoc/hiddenPostBranch';
import scrollToItem from 'components/hoc/scrollToItem';
import LoadSearchTableItem from 'components/pure/LoadSearchTableItem';


export default compose(
  connect(
    (state, ownProps) => {
      const item = state.resource.load[ownProps.id];
      const scroll_to_item = state.ui.scroll_to_item;
      return {
        scroll_to_item,
        open: item.globals.open,
        viewed: item.globals.viewed,
        data: item.data,
        user_id: item.data.user_id,
      };
    },
    {
      toggleOpen,
      setViewed,
    },
  ),
  hiddenPostBranch(HiddenPostLineItem, { idProp: 'data.id' }),
  scrollToItem({
    idPropName: ['data', 'id'],
  }),
)(LoadSearchTableItem);
