export default (attachments = []) => {
  if (attachments) {
    if (attachments.length) {
      attachments.forEach(element => {
        if (Array.isArray(element.category)) {
          element.category = element.category.join(',');
        }
      });
    }
  }
  return attachments;
};
