import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import mapProps from 'recompose/mapProps';
import getContext from 'recompose/getContext';
import get from 'lodash/get';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import createTableItems from 'helpers/createTableItems';
import { openModal } from 'actions/ui';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import AddressesFormat from 'components/pure/AddressesFormat';


const DispatchLoadListTableItems = createTableItems(
  ({ data, params, dispatchLoadConfirm, dispatchLoadCancel }) => [
    <span>
      {ageAbbr(data.time_posted)}
      {'\u00a0\u00a0\u00a0'}
      {data.user_type === 'shipper' ? <img src='/public/image/shipper_load.png' /> : null}
    </span>,
    trailerTypeMap[data.trailerType] || data.trailerType,
    <AddressesFormat
      addresses={data.origin_locations}
      ignore={{
        address: false,
        zip: false,
        country: false,
      }}
    />,
    <AddressesFormat
      addresses={data.destination_locations}
      ignore={{
        address: false,
        zip: false,
        country: false,
      }}
    />,
    data.weight === 0 ? '—' : formatWeight(data.weight),
    data.length === 0 ? '—' : data.length,
    data.time_start.format('MM/DD/YYYY h:mm A'),
    data.time_end.format('MM/DD/YYYY h:mm A'),
    data.time_delivery ? data.time_delivery.format('MM/DD/YYYY') : '—',
    data.dispatched_load.amount !== null ? formatPennies(get(data, ['dispatched_load', 'amount'], null)) :
    <div>
      <button className='btn btn-info' onClick={dispatchLoadConfirm}>Bill Dispatch</button>
      {' '}
      <button className='btn btn-danger' onClick={dispatchLoadCancel}>X</button>
    </div>,
    <Link to={`/admin/dispatch/${params.id}/load/${data.id}`}><button className='btn btn-orange'>Details</button></Link>,
  ],
  compose(
    getContext({
      params: PropTypes.object,
    }),
    connect(
      null,
      (dispatch, { data, params }) => ({
        dispatchLoadConfirm() {
          return dispatch(openModal('confirmdispatchload', {
            load_id: data.id,
            user_id: params.id,
            tx_id: data.dispatched_load.id,
          }));
        },
        dispatchLoadCancel() {
          return dispatch(openModal('confirm', {
            type: 'dispatchloadcancel',
            args: [{
              user_id: params.id,
              load_id: data.id,
            }],
          }));
        },
      }),
    ),
  ),
  ({ data, ...rest }) => ({
    ...rest,
    className: [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(data.user_type) ? 'blue-broker' : 'yellow-shipper',
  }),
);

export default compose(
  mapProps(({ params: { id } }) => ({
    id,
    fetchArgs: {
      assigned_user: id,
    },
  })),
  ResourceBy('user', 'dispatchedload'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'load',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
)(({ actions, item = {}, items }) =>
  <div>
    <ResponsiveTable
      items={items}
      isFetching={item.isFetching}
      fetchNext={actions.fetchNext}
      placeholder='No payments.'
      headers={['Age', 'Type', 'Origin', 'Destination', 'Weight', 'Length', 'Ready', 'Close', 'Deliver', 'Amount Charged', 'More']}
      TableItem={DispatchLoadListTableItems.TableItem}
      BlockTableItem={DispatchLoadListTableItems.BlockTableItem}
      striped={true}
    />
    {
      item.next ?
        <div>
          <button type='button' className='btn btn-orange' onClick={actions.fetchNext} disabled={item.isFetching}>Load More</button>
        </div>
        : null
    }
  </div>
);
