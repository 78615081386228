import 'styles/PoolMultiSelect';
import React from 'react';
import PropTypes from 'prop-types';
import without from 'lodash/without';
import cs from 'classnames';

import MaterialIcon from 'components/pure/MaterialIcon';

const PoolMultiSelect = ({
  input: {
    value = [],
    onChange,
  },
  meta,
  pool,
  poolProps,
  label,
  selectedLabel,
  onCloseClick,
  ...rest
}) =>
  <div
    {...rest}
    className={cs({
      'form-group': true,
      'has-error': meta.submitFailed && meta.error,
      'has-warning': meta.submitFailed && meta.warning && !meta.error,
    }, rest.className)}
    >
    <div className='PoolMultiSelect clearfix'>
      <div className='col-xs-12 col-sm-6' {...poolProps}>
        {label && <label>{label}</label>}
        <div className='PoolMultiSelect__pool'>
          {
            pool
              .filter(item => !value.find(val => val.id === item.id))
              .map((item, i) =>
                <div key={item.id} role='button' onClick={() => onChange([...value, item])}>
                  <span>{item.label}</span>
                  {
                    onCloseClick && (item.hasOwnProperty('onCloseClick') ? item.onCloseClick : true) ?
                      <MaterialIcon
                        name='close'
                        className='text-danger'
                        onClick={e => {
                          e.stopPropagation();
                          onCloseClick(i);
                        }}
                      />
                      : null
                  }
                </div>
              )
          }
        </div>
      </div>
      <div className='col-xs-12 col-sm-6' {...poolProps}>
        {selectedLabel && <label>{selectedLabel}</label>}
        <div className='PoolMultiSelect__pool'>
          {!value.length ? <span>Nothing selected</span> : null}
          {value.map(item =>
            <div key={item.id} role='button' onClick={() => onChange(without(value, item))}>
              <span>{item.label}</span>
            </div>
          )}
        </div>
      </div>
    </div>
    {meta.submitFailed && meta.error || meta.warning ? <div className='help-block'>{meta.error || meta.warning}</div> : null}
  </div>
  ;

PoolMultiSelect.propTypes = {
  pool: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any.isRequired,
    }).isRequired,
    PropTypes.node.isRequired,
  ])).isRequired,
};

export default PoolMultiSelect;
