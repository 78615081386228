import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.onboarddebtor;

import onboarddebtorResource from 'actions/resource/onboarddebtor';
import onboarddebtorByUserResource from 'actions/resourceBy/user/onboarddebtor';


export default function onboarddebtorResourceReducer(state = initialState, action) {
  state = onboarddebtorResource.reduce(state, action);
  if (onboarddebtorByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...onboarddebtorResource.parseMany(state, action.payload.results),
    };
  }
  if (onboarddebtorByUserResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: onboarddebtorResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
