const validateAddress = (address, message = 'required') => {
  if (!address) {
    return message;
  }
  if (typeof address === 'string') {
    return;
  }
  if (address.street_one) {
    return;
  }
  if (address.city && address.state) {
    return;
  }
  return message;
};

export default validateAddress;
