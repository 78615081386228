import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import withState from 'recompose/withState';
import lifecycle from 'recompose/lifecycle';
import get from 'lodash/get';

import { rateIndexHistoryPaginatedResource } from 'actions/rateindex';
import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import MaterialIcon from 'components/pure/MaterialIcon';
import RateIndexHistory from 'components/container/RateIndexHistory';


export default compose(
  Resource('load'),
  connect(
    state => {
      return {
        hasPermission: get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_subscribed'], false),
      };
    },
    {
      fetch: rateIndexHistoryPaginatedResource.fetch,
      clear: rateIndexHistoryPaginatedResource.clear,
      openUpgradeAccountModal: () => openModal('upgradeaccount'),
    },
  ),
  withState('open', 'setOpen', false),
  lifecycle({
    componentWillReceiveProps(nextProps) {
      const { item, clear, fetch } = this.props;
      if (item.data) {
        if (nextProps.open && !this.has_fetched) {
          clear();
          fetch({
            origin_city: item.data.origin_city,
            origin_state: item.data.origin_state,
            destination_city: item.data.destination_city,
            destination_state: item.data.destination_state,
            rate_type: '',
          });
          this.has_fetched = true;
        }
      }
    },
  }),
)(({ open, setOpen, hasPermission, openUpgradeAccountModal }) => {
  if (open) {
    return (
      <div>
        <div role='button' onClick={() => setOpen(false)}>
          <MaterialIcon name='expand_less' size={30} />
          <span className='text-danger' style={{ fontSize: 20 }}>Close Lane Trends View</span>
        </div>
        <RateIndexHistory />
      </div>
    );
  }
  return (
    <div>
      <div role='button' onClick={hasPermission ? () => setOpen(true) : openUpgradeAccountModal}>
        <MaterialIcon name='expand_more' size={30} />
        <span className='text-success' style={{ fontSize: 20, fontWeight: 'bold' }}>View Lane Rate Trends</span>
      </div>
    </div>
  );
});
