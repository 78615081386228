import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.financeslineitems;

import financeslineitemsPaginatedResource from 'actions/admin/financeslineitems';


export default function financeslineitemsPaginatedReducer(state = initialState, action) {
  state = financeslineitemsPaginatedResource.reduce(state, action);
  return state;
}
