import React from 'react';

const RangeSlider = ({ input, min, max, step }) => {
  return (
    <input
      type='range'
      value={input.value}
      onChange={input.onChange}
      min={min}
      max={max}
      step={step}
    />
  );
};

export default RangeSlider;
