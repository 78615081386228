import React, { Component } from 'react';
import Dropzone from 'react-dropzone-v7';
import { Glyphicon } from 'react-bootstrap';

export default props => (
  <Dropzone
    style={{
      alignItems: 'center',
      borderStyle: 'dashed',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '300px',
      backgroundColor: '#F9F9F9',
    }}
    accept={`application/msword, text/plain, application/pdf, .xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv, ${props.accept}`}
    onDrop={props.onDrop}
    >
    <Glyphicon
      style={{
        color: '#74B4FA',
        fontSize: '5em',
        margin: '10px',
      }}
      glyph='glyphicon glyphicon-cloud-upload'
    />
    <button
      className='btn btn-green'
      style={{
        margin: '10px',
        fontSize: '1.2em',
        fontWeight: 'bold',
        padding: '10px',
      }}
      >
          Choose files to upload
    </button>
    <p
      style={{
        margin: '10px',
      }}
      >or drag and drop them here</p>
  </Dropzone>
);
