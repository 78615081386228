/* global EMPTY_PLACEHOLDER */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import get from 'lodash/get';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';

import factoringdebtorPaginatedResource from 'actions/admin/factoring/debtor';

import createTableItems from 'helpers/createTableItems';

import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const DebtorComponents = createTableItems(
  ({ data }) => [
    data.master === true ? <div> {data.company_name} <p className='text-success'> <i> Master </i> </p></div> : data.company_name,
    data.email,
    data.contact_email,
    get(data.user, ['type'], EMPTY_PLACEHOLDER),
    moment(data.created).format('l LTS'),
    data.address.state,
    data.address.zip,
    <Link to={`/admin/factoring/debtor/${data.id}`}><button className='btn btn-orange'>More</button></Link>,
  ],
);

const DebtorTable = compose(
  connect(
    state => ({
      ids: state.admin.factoring.debtor.ids,
      isFetching: state.admin.factoring.debtor.isFetching,
      debtors: state.resource.factoringdebtor,
      currentOrder: state.admin.factoring.debtor.ordering,
    }),
    {
      fetchNext: factoringdebtorPaginatedResource.fetchNext,
      sort: factoringdebtorPaginatedResource.sort,
    },
  ),
)(({ ids, isFetching, fetchNext, sort, currentOrder, debtors }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    sort={sort}
    currentOrder={currentOrder}
    headers={[
      { text: 'DBA', ordering: false },
      { text: 'Email', ordering: false },
      { text: 'PE Contact Email', ordering: false },
      { text: 'User Type', ordering: false },
      { text: 'Created At', ordering: 'created' },
      { text: 'State', ordering: false },
      { text: 'ZIP', ordering: false },
      { text: 'More', ordering: false },
    ]}
    placeholder='No results'
    TableItem={DebtorComponents.TableItem}
    BlockTableItem={DebtorComponents.BlockTableItem}
    items={ids.map(id => debtors[id])}
  />
);

const DebtorClient = compose(
  connect(
    null,
    dispatch => ({
      update() {
        dispatch(factoringdebtorPaginatedResource.clear());
        return dispatch(factoringdebtorPaginatedResource.fetch({
          status: location.pathname.slice(location.pathname.lastIndexOf('/') + 1),
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(() =>
  <div className='col-fill' style={{ padding: '0 1em' }}>
    <RouteTabs
      style={{ flexGrow: 0 }}
      routes={[
        ['../pending', 'Pending Debtors'],
        ['../verify_duplicate', 'Verify Duplicate'],
        ['../approved', 'Approved Debtors'],
        ['../declined', 'Declined Debtors'],
        ['../needs_review', <span className='text-danger'>Needs Review</span>],
      ]}
    />
    <DebtorTable />
  </div>
);

export default DebtorClient;
