import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import withState from 'recompose/withState';
import { Field, reduxForm } from 'redux-form';
import get from 'lodash/get';
import moment from 'moment';

import formatPennies from 'helpers/formatPennies';
import financespurchasesPaginatedResource from 'actions/admin/financespurchases';
import ResourceByIDs from 'components/hoc/ResourceByIDs';

import { closeModal, openModal } from 'actions/ui';
import PurchasesFundingRequestsTable from 'components/container/PurchasesFundingRequestsTable';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import MaterialIcon from '../pure/MaterialIcon';

const mapStatus = (status, id, openModal, closeModal) => {
  switch (status) {
    case 'PAID':
      return <font color='#079100'> Paid </font>;
    case 'IN_PROCESS':
      return <font color='#FFD800'> In Process </font>;
    case 'UNPAID':
      return <font color='black'> Unpaid </font>;
    case 'VOID':
      return <font color='grey'> Void </font>;
    case 'EXPIRED':
      return <font color='#FF0000'> Expired </font>;
    case 'PRINTED':
      return <font color='#00A4FF'> Printed </font>;
    case 'MAILED':
      return <font color='#00A4FF'> Mailed </font>;
    case 'FAILED':
      return (<div>
        <font color='#FF0000'> Failed </font>
        <a
          style={{
            fontSize: 12,
            color: '#d0021b',
          }}
          onClick={() => openModal(id)}
          >
            Resend
          <MaterialIcon name='autorenew' size={10} />
        </a>
      </div>
      );
    case 'RETURNED':
      return <font color='#3F00FF'> Returned </font>;
    case 'REQUESTED':
      return <font color='#FF8C03'> Requested </font>;
    case 'REBATCHED':
      return <font color='#FF8C03'> Rebatched </font>;
    default:
      return status;
  }
};

const constructTableCells = ({ data }, openModal, closeModal) => {
  return [
    data.reference_number ? data.reference_number : undefined,
    data.company_name,
    formatPennies(data.amount),
    mapStatus(data.status, data.id, openModal, closeModal),
    data.created_at ? moment(data.created_at).format('l LTS') : '-',
  ];
};

const TableRow = ({ item, selectedRefNumber = null, onViewHideClick, isBlock = false, openModal, closeModal }) => {
  const purchasesId = get(item, 'data.id', 0);
  const isSelected = selectedRefNumber === purchasesId;
  return (
    <tbody>
      {!isBlock && (
        <tr>
          {constructTableCells(item, openModal, closeModal).map((cell, i) => <td key={i}>{cell}</td>)}
          <td>
            <button className='btn btn-orange' onClick={() => onViewHideClick(isSelected ? null : purchasesId)}>
              {!isSelected ? 'View' : 'Hide'}
            </button>
          </td>
        </tr>
      )}
      {isBlock &&
        constructTableCells(item, openModal, closeModal).map((cell, i) => (
          <tr key={i}>
            <td style={{ width: '50%' }}>{item.headers[i]}</td>
            <td style={{ width: '50%' }}>{cell}</td>
          </tr>
        ))}
      {isBlock && (
        <tr>
          <td style={{ width: '50%' }}>Actions</td>
          <td style={{ width: '50%' }}>
            <button className='btn btn-orange' onClick={() => onViewHideClick(isSelected ? null : purchasesId)}>
              {!isSelected ? 'View' : 'Hide'}
            </button>
          </td>
        </tr>
      )}

      {isSelected &&
      purchasesId && (
        <tr>
          <td colSpan={constructTableCells(item, openModal, closeModal).length + 1}>
            <div style={{ maxHeight: 300, overflowY: 'auto' }}>
              <PurchasesFundingRequestsTable purchaseId={purchasesId} />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const FinancesPurchasesTable = compose(
  withState('selectedRefNumber', 'setSelectedRefNumber', null),
  connect(
    state => {
      return {
        ids: state.admin.financespurchases.ids,
        items: state.resource.financespurchase,
        isFetching: state.admin.financespurchases.isFetching,
        count: state.admin.financespurchases.count,
        limit: state.admin.financespurchases.limit,
      };
    },
    dispatch => ({
      clear: financespurchasesPaginatedResource.clear,
      fetch: financespurchasesPaginatedResource.fetch,
      fetchNext: financespurchasesPaginatedResource.fetchNext,
      openModal: id => dispatch(openModal('FailedPayment', { id })),
      closeModal: () => dispatch(closeModal()),
    })
  ),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch();
    },
  })
)(({ ids, items, isFetching, fetchNext, selectedRefNumber, setSelectedRefNumber, closeModal, openModal }) => (
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Reference #', 'Company Name', 'Amount', 'Status', 'Date', 'Details']}
    isFetching={isFetching}
    fetchNext={fetchNext}
    items={ids.map(id => items[id])}
    TableItem={item => (
      <TableRow openModal={openModal} closeModal={closeModal} item={item} onViewHideClick={refNumber => setSelectedRefNumber(refNumber)} selectedRefNumber={selectedRefNumber} />
    )}
    BlockTableItem={item => (
      <TableRow openModal={openModal} closeModal={closeModal} isBlock={true} item={item} onViewHideClick={refNumber => setSelectedRefNumber(refNumber)} selectedRefNumber={selectedRefNumber} />
    )}
  />
));

const FactoringPurchases = compose(
  connect(null, dispatch => ({
    update({ refNumber, status } = {}) {
      dispatch(financespurchasesPaginatedResource.clear());
      const options = {};
      if (refNumber) {
        options.reference_number = refNumber;
      }

      if (status) {
        options.status = status;
      }

      return dispatch(financespurchasesPaginatedResource.fetch(options));
    },
  })),
  withState('options', 'setOptions', {}),
  lifecycle({
    componentDidMount() {
      this.props.update(this.props.options);
    },
    componentDidUpdate() {
      this.props.update(this.props.options);
    },
  }),
  reduxForm({
    form: 'FactoringClientPaymentsSearch',
    destroyOnUnmount: false,
    onSubmit(fields, dispatch, props) {
      props.setOptions({
        refNumber: fields.refNumber,
        status: fields.status,
      });
    },
  })
)(({ submitting, handleSubmit }) => (
  <div className='col-fill' style={{ padding: '0 1em' }}>
    <div className='row'>
      <div className='col-lg-12'>
        <form
          onSubmit={handleSubmit}
          className='form-inline'
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', padding: '1em 0' }}
          >
          <Field name='refNumber' component={LabeledInput} placeholder='Reference #' style={{ margin: '0 1em' }} />
          <Field
            name='status'
            component={LabeledDropdown}
            placeholder='Status'
            style={{ margin: '0 1em', minWidth: '10em' }}
            data={[
              { text: 'Paid', value: 'PAID' },
              { text: 'In Process', value: 'IN_PROCESS' },
              { text: 'Unpaid', value: 'UNPAID' },
              { text: 'Void', value: 'VOID' },
              { text: 'Expired', value: 'EXPIRED' },
              { text: 'Printed', value: 'PRINTED' },
              { text: 'Mailed', value: 'MAILED' },
              { text: 'Failed', value: 'FAILED' },
              { text: 'Returned', value: 'RETURNED' },
              { text: 'Requested', value: 'REQUESTED' },
            ]}
          />
          <button type='submit' className='btn btn-primary' style={{ margin: '0px 0px 0px 1em' }}>
            Search
          </button>
        </form>
      </div>
    </div>
    <FinancesPurchasesTable />
  </div>
));

export default FactoringPurchases;
