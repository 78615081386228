import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-v4';
import { get } from 'lodash';

import { closeModal, openModal, unshiftModal } from 'actions/ui';
import { placeNOA } from 'actions/admin/factoring/noa';

import Resource from 'components/hoc/Resource';
import nothingIf from 'components/hoc/nothingIf';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';


const PlaceNOA = compose(
  Resource('factoringclient', {
    idPropName: 'client_id',
    resourcePropName: 'client',
  }),
  Resource('factoringclientattachments', {
    idPropName: 'client_id',
    resourcePropName: 'attachments',
  }),
  Resource('factoringdebtor', {
    idPropName: 'debtor_id',
    resourcePropName: 'debtor',
  }),
  nothingIf(({ client, debtor }) => !client || !client.data || !debtor || !debtor.data),
  connect(
    null,
    {
      closeModal,
    },
  ),
  reduxForm({
    form: 'PlaceNOAForm',
    async onSubmit(fields, dispatch, { client_id, debtor_id, funding_request, onSent }) {
      try {
        await dispatch(placeNOA({
          client_id,
          debtor_id,
          attachment: fields.noa_attachment,
          funding_request,
        }));
        dispatch(openModal('success', { message: 'Successfully placed NOA.' }));
        dispatch(closeModal());
        if (typeof onSent === 'function') {
          onSent();
        }
      }
      catch (err) {
        console.warn(err);
        dispatch(unshiftModal('error', { message: 'Failed to send NOA.' }));
      }
    },
    validate(fields, { client }) {
      const noaAttachments = get(client, 'data.noa_attachments', []);
      if (noaAttachments.length > 1 && !fields.noa_attachment) {
        return {
          noa_attachment: 'Required',
        };
      }
    },
  }),
)(({ handleSubmit, submitting, closeModal, client, debtor, attachments }) => {
  let noaAttachments = [];
  if (attachments.data && Array.isArray(attachments.data)) {
    noaAttachments = attachments.data.filter(({ category }) => ['noa', 'comfreight_noa'].includes(category));
  }
  return (
    <div>
      <div className='modal-header'>
        <button type='button' className='close' aria-label='Close' onClick={closeModal} disabled={submitting}><span aria-hidden='true'>×</span></button>
        <h4 className='modal-title'>
          <div>
            <span>Place NOA</span>
          </div>
        </h4>
      </div>
      <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
        <h4>Client: {client.data.company_profile.name}</h4>
        <h4>Debtor: {debtor.data.company_name}</h4>
        {
          !noaAttachments.length ?
            <p>No NOA Attachment file. Please add one <Link to={`/admin/factoring/client/${client.data.id}`} onClick={closeModal}>here.</Link></p>
            :
            noaAttachments.length === 1 ?
              <p>
                <label>NOA to be sent:</label>
                {' '}
                <LoadAttachmentButton
                  download_url={noaAttachments[0].download_url}
                  preview_url={noaAttachments[0].url}
                />
              </p>
              :
              <p>
                <div>
                  {noaAttachments.map(attachment =>
                    <div key={attachment.filename}>
                      <label>{attachment.filename}</label>
                      {' '}
                      <LoadAttachmentButton
                        download_url={attachment.download_url}
                        preview_url={attachment.url}
                      />
                    </div>
                  )}
                </div>
                <Field
                  name='noa_attachment'
                  component={LabeledDropdown}
                  data={noaAttachments.map(attachment => ({
                    text: attachment.filename,
                    value: attachment.filename,
                  }))}
                  label='NOA to be sent'
                />
              </p>
        }
      </div>
      <div className='modal-footer'>
        <button className='btn btn-default' onClick={closeModal} disabled={submitting}>Cancel</button>
        <button className='btn btn-orange' onClick={handleSubmit} disabled={submitting || !noaAttachments.length}>Place NOA</button>
      </div>
    </div>
  );
});

export default PlaceNOA;
