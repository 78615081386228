/**
 * Reducer for handling the usernote resource actions
 * @module reducers/resource/usernote
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/usernote
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.usernote;

import usernoteResource from 'actions/resource/usernote';
import usernoteByUserResource from 'actions/resourceBy/user/note';
import clientcombinednotesResource from 'actions/admin/clientcombinednotes';
import debtorcombinednotesResource from 'actions/admin/debtorcombinednotes';


export default function usernoteResourceReducer(state = initialState, action) {
  state = usernoteResource.reduce(state, action);
  switch (action.type) {
    case debtorcombinednotesResource.actions.FETCH_REQUEST_SUCCESS:
    case clientcombinednotesResource.actions.FETCH_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload.filter(({ user }) => user).reduce((acc, curr) => {
          acc[curr.id] = usernoteResource.parse(state[curr.id], curr);
          return acc;
        }, {}),
      };
    }
  }
  if (usernoteByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...usernoteResource.parseMany(state, action.payload.results),
    };
  }
  if (usernoteByUserResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: usernoteResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
