/*global API */

import { flatten } from 'lodash';

export const getLineItemAuditLog = (funding_request_id, line_item_id) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${funding_request_id}/items/${line_item_id}/history/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status >= 300) {
    throw new Error('Error getting the line item history!');
  }

  const json = await res.json();
  return json;
};

export const getLineItemsByFundingRequest = id => async (dispatch, getState) => {
  let exausted = false;
  const lineItems = [];

  let next;

  do {
    const res = await global.fetch(next ? next : `${API.host}/funding_requests/${id}/items/`, {
      headers: {
        authorization: `bearer ${getState().user.token}`,
      },
    });
    const json = await res.json();

    if (!json.next) {
      exausted = true;
    }
    else {
      next = json.next;
    }

    lineItems.push(json.results);
  } while (!exausted);

  return flatten(lineItems);
};

export const createLineItemByFundingRequest = id => ({
  amount_requested,
  discount_rate,
  category,
  description,
  approval_status,
  related_line_item,
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${id}/items/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      amount_requested,
      discount_rate,
      category,
      description,
      approval_status,
      related_line_item,
    }),
  });

  if (res.status >= 300) {
    throw new Error('Error creating the line item!');
  }

  const json = await res.json();
  return json;
};


export const createLinkedLineItemByFundingRequest = id => ({
  related_line_item,
  amount_requested,
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${id}/items/link_related/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      funding_request: id,
      amount_requested,
      related_line_item,
    }),
  });

  if (res.status >= 300) {
    throw new Error('Error creating the line item!');
  }

  const json = await res.json();
  return json;
};
