/**
 * Component that displays a series of forms related to alert notification settings for the Notifications section of Settings
 * @module components/container/SettingsNotifications
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/ShipperLoadAlertsForm
 * @requires components/pure/form/ActivityAlertsForm
 * @requires components/container/ProTip
 * @property {object} props
 * @property {string} props.userType - the type of the current user's account
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import { USER_TYPE } from 'helpers';

import Header from 'components/pure/Header';
import ShipperLoadAlertsForm from 'components/pure/form/ShipperLoadAlertsForm';
import ActivityAlertsForm from 'components/pure/form/ActivityAlertsForm';
import ProTip from 'components/container/ProTip';
import ButtonResendEmailVerificationEmail from 'components/container/ButtonResendEmailVerificationEmail';
import getSelf from 'selectors/getSelf';
import SMSAlertForm from 'components/pure/form/SMSAlertsForm';

export default compose(
  connect(
    state => {
      const self = getSelf(state);
      if (self) {
        return {
          userType: self.data.type,
          has_verified_email: self.data.has_verified_email,
        };
      }
      return {};
    },
    {},
  ),
)(({ userType, has_verified_email }) =>
  <div style={{ textAlign: 'center' }}>
    <div className='col-xs-12 col-sm-7' style={{ textAlign: 'left', float: 'none', display: 'inline-block' }}>
      <div className='clearfix' style={{ marginTop: 12.5 }}>
        <Header style={{ display: 'inline-block', marginTop: 0 }}>Notifications</Header>
        {
          userType === USER_TYPE.SHIPPER ?
            <ProTip
              className='pull-right'
              name='Manage Email Notifications'
              message='Manage your email notifications for activity here.'
              youtubeEmbed='JfBupYngPHk'
            />
            :
            <ProTip
              className='pull-right'
              name='Shipper Load Alerts'
              message="We recommend including as many states as possible in order to get the full spectrum of new available verified direct shipper loads so you don't miss any potential new customers!"
              youtubeEmbed='JfBupYngPHk'
            />
        }
      </div>
      {
        has_verified_email ? null :
        <div className='alert alert-warning' style={{ margin: '12.5px 0' }}>
          Please verify your email address by clicking the link in the Account Verification email that we sent to you. Once you verify your email address, you will be able to manage your push and email notifications here. You may need to check your spam folder. If you haven't received it, please make sure your account email address is correct <Link to='/settings/account'>here</Link> or email <a href='mailto:support@haulpay.io'>support@haulpay.io</a>. You can <ButtonResendEmailVerificationEmail>click here</ButtonResendEmailVerificationEmail> to request another verification email.
        </div>
      }
      {
        [USER_TYPE.BROKER, USER_TYPE.CARRIER, USER_TYPE.DISPATCHER].includes(userType) ?
          <ShipperLoadAlertsForm />
          : null
      }
      <ActivityAlertsForm />
      <SMSAlertForm />
    </div>
  </div>
);
