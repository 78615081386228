import React from 'react';
import { Field, reduxForm, reset } from 'redux-form';
import { closeModal, openModal } from 'actions/ui';
import Header from '../Header';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import { edit } from 'actions/resource/defaultbank';
import { withStateHandlers } from 'recompose';
import { get } from 'lodash';
import formatError from 'helpers/formatError';
const formatAccountBank = account => `xxxx - ${account}`;
const formatAccountDebit = account => `xxxx - xxxx - xxxx - ${account}`;

const paymentMethodsType = {
  DEBIT: 'debit',
  ACH: 'ach',
  WIRE: 'wire',
};
const TableComponents = createTableItems(
  ({ data, deletePaymentMethod, props }) => {
    return [
      data.type === 'DEBIT' ? formatAccountDebit(data.account_number_redacted) : formatAccountBank(data.account_number_redacted),
      <Field
        name={`${paymentMethodsType[data.method]}_account_id`}
        id={`${paymentMethodsType[data.method]}_account_id`}
        component='input'
        type='radio'
        onFocus={event => {
          props.updateField({ method: [`${paymentMethodsType[data.method]}_account_id`], value: event.target.value });
          props.change(`${paymentMethodsType[data.method]}_account_id`, event.event.value);
        }}
        onBlur={event => {
          props.updateField({ method: [`${paymentMethodsType[data.method]}_account_id`], value: event.target.value });
          //onChange(`${paymentMethodsType[data.method]}_account_id`, event.event.value);
        }}
        //checked={props[`${paymentMethodsType[data.method]}_account_id`]}
        value={data.id}
      />,
      data.type === 'DEBIT' ? data.type : `${data.type} (${data.method})`,
      <button
        className='btn btn-danger'
        type='button'
        onClick={() => deletePaymentMethod(data.user, data.id)}
        >
        Delete
      </button>,
    ];
  },
);

const PaymentMethodsForm = ({ children, item, banks, ids, handleSubmit, isFetching, fetchNext, currentOrder, sort, submitting, deletePaymentMethod, update, closeModal, dispatch, ...props }) =>
  <div>
    <Header>Select a Default Account</Header>
    <hr />
    <form onSubmit={handleSubmit}>
      <ResponsiveTable
        containerProps={{
          className: 'col-fill',
        }}
        isFetching={isFetching}
        fetchNext={fetchNext}
        sortable={true}
        currentOrder={currentOrder}
        sort={sort}
        headers={[
          { text: 'Account # (Last 4 digits)', ordering: false },
          { text: 'Default', ordering: false },
          { text: 'Type', ordering: false },
          { text: 'Actions', ordering: false },
        ]}
        placeholder='No results'
        TableItem={TableComponents.TableItem}
        BlockTableItem={TableComponents.BlockTableItem}
        items={ids.map(id => {
          const bank = banks[id];
          bank.props = props;
          bank.deletePaymentMethod = (userid, id) => {
            setTimeout(() => {
              dispatch(openModal('confirm', {
                type: 'paymentmethods_delete',
                args: [{
                  onConfirm: async () => {
                    await deletePaymentMethod(userid, id);
                    await update();
                    return item;
                  },
                  onExit: async () => {
                    await dispatch(openModal('paymentInformation', { item }));
                  },
                }],
              }));
              dispatch(closeModal());
            }, 200);
          };
          return bank;
        })}
      />
      {children}
    </form>
  </div>;

const PaymentForm = reduxForm({
  form: 'PaymentMethods',
  enableReinitialize: true,
  async onSubmit(fields, dispatch, props) {
    const data = {
      default: true,
    };
    try {
      if (fields.debit_account_id) {
        await dispatch(edit(data, props.item.data.user, fields.debit_account_id));
      }
      if (fields.ach_account_id) {
        await dispatch(edit(data, props.item.data.user, fields.ach_account_id));
      }
      if (fields.wire_account_id) {
        await dispatch(edit(data, props.item.data.user, fields.wire_account_id));
      }
      dispatch(closeModal());
      dispatch(openModal('success', { message: 'Successfully set default account.' }));
      reset();
    }
    catch (err) {
      dispatch(openModal('error', { message: formatError(err, 'Error updating payments method') }));
      dispatch(closeModal());
      reset();
    }
  },
})(PaymentMethodsForm);

export default withStateHandlers(
  ({ accountsValue, ...props }) => {
    return {
      ach_account_id: get(accountsValue, 'ach_account_id', ''),
      debit_account_id: get(accountsValue, 'debit_account_id', ''),
      wire_account_id: get(accountsValue, 'wire_account_id', ''),
    };
  },
  {
    updateField: props => ({ method, value }) => ({ [method]: value }),
  }
)(PaymentForm);
