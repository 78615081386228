/**
 * Actions relating to the factoringdebtor by factoringclient resource
 * @module actions/resourceBy/factoringclient/factoringdebtor
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/factoringdebtor
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import factoringdebtor from 'actions/resource/factoringdebtor';


export const factoringdebtorByfactoringclient = new ControlledPaginatedResourceCollection('factoringclient', 'factoringdebtor', {
  url: new UrlAssembler('admin/factoring/debtors/:id'),
  paginatedResourceOptions: {
    baseResource: factoringdebtor,
    append_to_top_on_create: true,
  },
});

export default factoringdebtorByfactoringclient;
export const {
  fetch,
  fetchNext,
  create,
  doDelete,
  clear
} = factoringdebtorByfactoringclient;

// user-version endpoint
export const factoringdebtorByfactoringclientUSER = new ControlledPaginatedResourceCollection('factoringclient', 'factoringdebtor', {
  url: new UrlAssembler('factoring/debtors/relationship'),
  paginatedResourceOptions: {
    // this will overwrite the above one without a fucked-up id in the formatting
    url: new UrlAssembler('factoring/debtors/relationship'),
    baseResource: factoringdebtor,
    append_to_top_on_create: true,
  },
});
