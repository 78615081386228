/**
 * Generic class for a collection of paginated-resource-related fetching and serialization
 * @module datatypes/PaginatedResourceCollection
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/user
 */
import PaginatedResource from './PaginatedResource';
import { USER_LOGOUT } from '../actions/user';


/**
 * PaginatedResourceCollection class. Creates PaginatedResources 'on-demand' when an ID for that resource has been requested
 */
class PaginatedResourceCollection {

  /**
   * Create a PaginatedResourceCollection
   * @param {string} name - the unique identifier for this PaginatedResourceCollection
   * @param {string} resource - the sub-unique sub-identifier for this PaginatedResourceCollection
   * @param {object} options
   * @param {string} options.mountPoint - where in the overall state to find this PaginatedResourceCollection's state
   * @param {UrlAssembler} options.url - the UrlAssembler object to pass to the underlying PaginatedResource objects
   * @param {object} [options.paginatedResourceOptions] - options object to be passed to the underlying PaginatedResource objects
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, resource, options) {

    this.name = name;
    this.resource = resource;
    this.options = {
      mountPoint: ['resourceBy', this.name, this.resource],
      ...options,
    };

    if (!Array.isArray(this.options.mountPoint) && typeof this.options.mountPoint !== 'string') {
      throw new Error(`${this.constructor.name} ${name} requires a mount point, got: ${this.options.mountPoint}`);
    }

    this.resources = Object.create(null);

    this.sort = this.sort.bind(this);
    this.fetchNext = this.fetchNext.bind(this);
    this.fetchAll = this.fetchAll.bind(this);
    this.fetch = this.fetch.bind(this);
    this.clear = this.clear.bind(this);
    this.abortRequest = this.abortRequest.bind(this);
  }

  /**
   * Function to be overridden in children classes to change the type of underlying PaginatedResource objects to create
   */
  getResourceType() {
    return PaginatedResource;
  }

  /**
   * Creates (if needed) and returns a PaginatedResource from this PaginatedResourceCollection's memory
   * @param {string} id - the ID of the PaginatedResource to return
   * @returns {PaginatedResource}
   */
  getResources(id) {
    let resource = this.resources[id];
    if (!resource) {
      resource = new (this.getResourceType())(`${this.resource}_BY_${this.name}#${id}`, {
        mountPoint: [...this.options.mountPoint, id],
        url: this.options.url.param('id', id),
        ...this.options.paginatedResourceOptions,
        belongsInCollection: this.options.paginatedResourceOptions && typeof this.options.paginatedResourceOptions.belongsInCollection === 'function'
          ? this.options.paginatedResourceOptions.belongsInCollection(id)
          : () => true,
      });
      this.resources[id] = resource;
    }
    return resource;
  }

  /**
   * Call the `sort` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#sort}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `sort` function
   */
  sort(id, ...rest) {
    const resource = this.getResources(id);
    return resource.sort(...rest);
  }

  /**
   * Call the `fetchNext` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#fetchNext}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `fetchNext` function
   */
  fetchNext(id) {
    return this.getResources(id).fetchNext();
  }

  /**
   * Call the `fetchAll` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#fetchAll}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `fetchAll` function
   */
  fetchAll(id) {
    return this.getResources(id).fetchAll();
  }

  /**
   * Call the `fetch` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#fetch}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `sort` function
   */
  fetch(id, ...rest) {
    const resource = this.getResources(id);
    return resource.fetch(...rest);
  }

  /**
   * Call the `clear` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#clear}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `clear` function
   */
  clear(id, ...rest) {
    const resource = this.getResources(id);
    return resource.clear(...rest);
  }

  /**
   * Call the `clear` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#clear}
   * @param {string} id - the ID of the underlying PaginatedResource
   */
  abortRequest(id) {
    let resource = this.resources[id];
    return resource.abort();
  }

  /**
   * Call the `clearAll` function on an underlying PaginatedResource with the given ID. See {@link module:datatypes/PaginatedResource~PaginatedResource#clearAll}
   * @param {string} id - the ID of the underlying PaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying PaginatedResource's `clearAll` function
   */
  clearAll(...rest) {
    for (const id in this.resources) {
      this.resources[id].clear(...rest);
    }
    return this;
  }

  /**
   * Helper function that determines if the given action is an action of the given type for one of this PaginatedResourceCollection's PaginatedResource's actions
   * @param {Action} action - the action to match against
   * @param {string} type - the type of action to look for
   * @returns {boolean} whether the passed action is an action for at least one of this PaginatedResourceCollection's PaginatedResource's actions
   */
  matchesAction(action, type) {
    const { resources } = this;
    for (const id in resources) {
      if (resources[id].actions[type] === action.type) {
        return true;
      }
    }
    return false;
  }

  /**
   * Similar to Redux's `combineReducers`, this applies all of the reducers of its underlying PaginatedResources
   * @param {object} state - the current state of this PaginatedResourceCollection
   * @param {object} action - the dispatched action
   * @returns The new state for this PaginatedResourceCollection
   */
  reduce(state, action) {
    if (action.type === USER_LOGOUT) {
      this.resources = Object.create(null);
    }
    let hasChanged = false;
    const nextState = {};
    for (const id in this.resources) {
      const resource = this.resources[id];
      const prevStateForId = state[id];
      const nextStateForId = resource.reduce(prevStateForId, action);
      nextState[id] = nextStateForId;
      hasChanged = hasChanged || nextStateForId !== prevStateForId;
    }
    return hasChanged ? nextState : state;
  }
}

export default PaginatedResourceCollection;
