export default function formatDollar(num) {
    try {
        let p = num;
        if(typeof num !== 'string' || !num instanceof String) {
            p = num.toFixed(2)
        } 
        p = p.split('.');
        return (
            '$' +
            p[0]
                .split('')
                .reverse()
                .reduce(function (acc, num, i, orig) {
                    return num + (num != '-' && i && !(i % 3) ? ',' : '') + acc;
                }, '') +
            '.' +
            p[1]
        );
    } catch(e) {}
}
