import React from 'react';

const DebtorCardSmall = ({
  debtor,
  icon,
  ...rest
}) =>
  <div {...rest}>
    {icon}
    <div className='pac-item-query' style={{ display: 'inline-block', padding: 0, width: 'calc(100% - 22px)', lineHeight: 'normal', marginTop: 6, whiteSpace: 'normal' }}>
      <div>{debtor.dba ? debtor.dba : debtor.user ? debtor.user.dba : debtor.company_name ? debtor.company_name : null}</div>
      <div style={{ fontSize: '0.9em' }}>
        {[
          // formatAddress(debtor.address),
          // debtor.phone_number,
          [debtor.mc ? `MC# ${debtor.mc}` : null, debtor.dot ? `DOT# ${debtor.dot}` : null].filter(Boolean).join(', '),
          // debtor.email,
        ].filter(Boolean).map((val, i) =>
          <div key={i}>{val}</div>
        )}
        <div>{debtor.email}</div>
        {debtor.credit_approved === 'approved' ? <div className='text-success'><b>HAUL PAY APPROVED</b></div> : null}
        {
          debtor.hasOwnProperty('noa_active_count') && debtor.hasOwnProperty('noa_placed_count') && debtor.hasOwnProperty('noa_sent_count') ?
            <div>
              {'NOAs - '}
              Sent: {debtor.noa_sent_count}
              {' '}
              Placed: {debtor.noa_placed_count}
              {' '}
              Active: {debtor.noa_active_count}
            </div>
            : null
        }
        {
          debtor.hasOwnProperty('funding_request_count') ?
            <div>Funding Requests - {debtor.funding_request_count}</div>
            : null
        }
        {
          debtor.hasOwnProperty('transferred_debtor_count') ?
            <div>Transferred Debtors - {debtor.transferred_debtor_count}</div>
            : null
        }
      </div>
    </div>
  </div>
;

export default DebtorCardSmall;
