import * as React from "react";
import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';

function AntSearchInput(props: SearchProps) {
    return (
        <Input.Search
            {...props}
        />
    )
}
export default AntSearchInput;