/**
 * Connected component for confirming a bid retraction
 * @module components/pure/modals/ConfirmBidRetract
 * @since 3.0.0
 * @requires helpers
 * @requires actions/ui
 * @requires actions/resource/bid
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {string} id - the ID of the bid to retract
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import formatPennies from 'helpers/formatPennies';
import { openModal } from 'actions/ui';
import { doDelete } from 'actions/resource/bid';

import Modal from 'components/hoc/Modal';
import ModalWarning from './Warning';

export const ConfirmBidRetract = compose(
  Modal,
  connect(
    (state, ownProps) => ({
      submitting: state.resource.bid[ownProps.id] !== undefined && state.resource.bid[ownProps.id].isFetching,
      amount: state.resource.bid[ownProps.id] && state.resource.bid[ownProps.id].data ? state.resource.bid[ownProps.id].data.amount : undefined,
    }),
    (dispatch, ownProps) => ({
      doDelete() {
        return dispatch(doDelete(ownProps.id))
          .then(() => ownProps.closeModal())
          .catch(err => {
            let message = 'Error retracting bid';
            if (err) {
              if (err.message) {
                message = err.message;
              }
            }
            ownProps.closeModal();
            return dispatch(openModal('error', { message }));
          })
        ;
      },
    }),
  ),
  mapProps(({ amount, submitting, doDelete, ...rest }) => ({
    ...rest,
    headerChildren: 'Confirm',
    message: `Are you sure you want to retract this bid${amount ? ` of ${formatPennies(amount)}` : ''}?`,
    buttonChildren: 'Cancel',
    extraButtons: [
      <button className='btn btn-danger' disabled={submitting} onClick={doDelete}>Retract</button>,
    ],
  })),
)(ModalWarning);

ConfirmBidRetract.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmBidRetract;
