/**
 * Component that displays a Truck or Load Post history page
 * @module components/pure/PostHistory
 * @since 3.0.0
 * @requires components/pure/MediaQuery
 * @requires components/stateful/SortButton
 * @property {object} props
 * @property {object} props.currentOrder
 * @property {object} props.oprions
 * @property {object} props.sort
 * @property {object} props.Table
 * @property {object} props.ids
 * @property {object} props.isFetching
 * @property {object} props.fetchNext
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/PostHistory';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import SortButton from 'components/stateful/SortButton';
import Toggle from 'material-ui/Toggle';


const PostHistory = ({ currentOrder, options, sort, Table, ids, isFetching, fetchNext, displayHistory, displayHistoryButton = false, history = false, ...rest }) =>
  <div className='full-height' style={{ width: '100%' }}>
    <div className='container' style={{ width: '100%', padding: '1.065em' }}>
      {
        displayHistoryButton &&
        <div className='col-xs-6' style={{ marginTop: '1.265em', display: 'flex', flexDirection: 'row' }}>
          <p style={{ display: 'flex', fontSize: '16', fontWeight: 'normal', color: '#5c6979' }}>
            Only Current Loads
          </p>
          <div className='col-xs-1'>
            <Toggle
              defaultToggled={history}
              onToggle={(value, isInputChecked) => displayHistory('load', isInputChecked)}
            />
          </div>
        </div>
      }
      <div {...rest} className={cs('PostHistory col-xs-12', rest.className)}>
        <SortButton className='visible-xs-block' currentOrder={currentOrder} options={options} sort={sort} />
        <Table ids={ids} isFetching={isFetching} fetchNext={fetchNext} currentOrder={currentOrder} sort={sort} />
      </div>
    </div>
  </div>
  ;

PostHistory.propTypes = {
  ...SortButton.propTypes,
  Table: PropTypes.func.isRequired,
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  isFetching: PropTypes.bool.isRequired,
  fetchNext: PropTypes.func.isRequired,
};

export default PostHistory;
