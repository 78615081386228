import React from 'react';
import { lifecycle, mapProps, withState } from 'recompose';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { fundingRequestCalculations } from '../../actions/factoring';
import filter from "lodash/filter";

const getCalculation = async (props, data) => {
    props.setLoadingCalculation(true);
    try {
      const res = await props.fundingRequestCalculations({
        payment_profile_amount: props[data.payment_profile_amount] || 0,
        payment_profile_invoice_amount: props[data.payment_profile_amount] || 0,
        invoice_amount: props[data.amount],
        debtor: props[data.bill_to].id,
        factored: data.new ? !props[data.non_factored] : ![USER_FACTORING_STATUS.NON_FACTORED, USER_FACTORING_STATUS.NON_FACTORED_CARRIER].includes(data.status),
        bill_out_only: props[data.bill_out_only],
        create_rates_revision: false,
        ...(() => {
          if (!props[data.non_factored]) {
            return {
              payment_profile_invoice_amount: props[data.carrier_amount],
              payment_speed: props[data.payout_days],
              // payment_profile: props[data.carrier].id,
              shipper_off_fee: props[data.shipper_offset],
              factoring_fee_split_rate: props[data.broker_rate],
              offset_fee_to_carrier: props[data.pass_debtor_rate_to_carrier]
            };
          }
          if (props[data.non_factored] && !props[data.bill_out_only] && data.isPaymentProfile) {
            return {
              payment_profile_invoice_amount: props[data.carrier_amount],
              payment_profile: props[data.carrier].payment_profile_id,
            };
          }
        })(),
      });
      props.setCalculationData(res);
      setInterval(() => {
        props.setLoadingCalculation(false);
      }, 1000);
    }
    catch (e) {
        props.setLoadingCalculation(false);
    }
  };

export default (options, data = {}) => compose(
    connect(() => ({}), (dispatch) => ({ fundingRequestCalculations: data => dispatch(fundingRequestCalculations(data)) })),
    withState('calculationData', 'setCalculationData', {}),
    withState('loadingCalculation', 'setLoadingCalculation', false),
    lifecycle({
        async componentWillUpdate(nextProps) {
            if(filter(options.map(value => this.props[value] !== nextProps[value]), value => value === true).length) {
                await getCalculation(nextProps, data)
            }
        }
    }),
    mapProps(props => props),
)
