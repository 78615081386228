/**
 * Actions relating to the Loadreverse by Load resource
 * @module actions/resourceBy/load/loadreverse
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires actions/resource/load
 */
import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import loadResource from 'actions/resource/load';


/**
 * Our Loadreverse by Load PaginatedResourceCollection instance
 */
export const loadreverseByLoadResource = new PaginatedResourceCollection('load', 'loadreverse', {
  url: new UrlAssembler('load/:id/loads/reverse'),
  paginatedResourceOptions: {
    baseResource: loadResource,
  },
});

export default loadreverseByLoadResource;
export const {
  fetch,
  fetchNext,
} = loadreverseByLoadResource;
