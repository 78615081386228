import { Button } from '@haulpay/ui';
import get from 'lodash/get';
import React from 'react';

const columns = [
    {
        title: 'Email',
        key: 'email',
        render: (data) => get(data, 'payment_profile.owner_email', undefined),
        ellipsis: true,
    },
    {
        title: 'Carrier Name',
        key: 'carrier_name',
        render: (data) => {
            const companyName = get(data, 'payment_profile.company_name', undefined);
            const factoringCompanyName = get(data, 'payment_profile.factoring_company.name', undefined);
            return <div>
                <a >{companyName}</a>
                {
                    factoringCompanyName &&
                    <p className='text-orange'>{factoringCompanyName.toUpperCase()}</p>
                }
            </div>
        },
        ellipsis: true,
    },
    {
        title: 'Phone #',
        key: 'phone_number',
        render: (data) => get(data, 'payment_profile.owner_phone_number_cell',
            get(data, 'payment_profile.owner_phone_number_home', get(data, 'payment_profile.company_phone_number', undefined))),
        ellipsis: true,
    },
    {
        title: 'Mc #',
        key: 'mc',
        render: (data) => get(data, 'payment_profile.company_mc', undefined),
        ellipsis: true,
    },
    {
        title: 'DOT #',
        key: 'dot',
        render: (data) => get(data, 'payment_profile.company_dot', undefined),
        ellipsis: true,
    },
    {
        title: 'Payment Speed #',
        key: 'payment_speed',
        // render: (data) => data?.payment_speed,
        ellipsis: true,
    },
    {
        title: 'Carrier Rate',
        key: 'carrier_rate',
        // render: (data) => data?.dot,
        ellipsis: true,
    },
    {
        title: 'Status',
        key: 'status',
        render: (data) => get(data, 'payment_profile.status', undefined),
        ellipsis: true,
    },
    {
        render: () => (
            <Button type="primary">
                Save
            </Button>
        ),
        key: 'actions',
    },
];
export default columns;
