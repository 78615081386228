/**
 * Component that displays a Truck or Load Post page
 * @module components/pure/Post
 * @since 3.0.0
 * @requires components/pure/MediaQuery
 * @requires components/pure/Header
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {string} [props.editId] - the ID of a truck or load that is being edited, if any
 * @property {function} [props.cancelEdit] - a function that cancels 'copy' or 'edit' mode
 * @property {node} props.headerText - the node to be placed in the header
 * @property {node} [props.proTip] - the node to be placed in a ProTip
 * @property {string} props.myResourceUrl - the url for the "My Xs" button
 * @property {node} props.myResourceButtonText - the child node for the "My Xs" button
 * @property {node} props.submitButtonText - the child node for the submit button
 * @property {boolean} props.isSubmitting - whether the form is currently being submitted
 * @property {element} props.Form - the component to use for the form
 * @property {function} props.submitHandler - the function to call when the form is submitted
 * @property {function} [props.validator] - the validator to be used in the form
 * @property {element} props.Results - the component to display in the results section of the page
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/Post';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';

import MediaQuery from 'components/pure/MediaQuery';
import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';


export class PostTruck extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      props.editId !== nextProps.editId ||
      props.isSubmitting !== nextProps.isSubmitting ||
      // if cancelEdit is undefined, we're creating a new thing
      // if it's not undefiend, we're editing and need to change
      props.cancelEdit !== nextProps.cancelEdit
    );
  }

  componentWillUnmount() {
    const { cancelEdit } = this.props;
    this.form = null;
    if (cancelEdit) {
      cancelEdit();
    }
  }

  static propTypes = {
    // left-hand side
    headerText: PropTypes.string.isRequired,
    proTip: PropTypes.node,
    myResourceUrl: PropTypes.string.isRequired,
    myResourceButtonText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cancelEdit: PropTypes.func,
    // form
    Form: PropTypes.func.isRequired,
    submitHandler: PropTypes.func.isRequired,
    validator: PropTypes.func,
    // right-hand side
    Results: PropTypes.func.isRequired,
    resultsHeaderText: PropTypes.node.isRequired,
  };

  submit() {
    const { form } = this;
    if (form.submit) {
      form.submit();
    }
    else if (form.getWrappedInstance().submit) {
      form.getWrappedInstance().submit();
    }
    else {
      console.warn(`Tried to submit on a form that doesn't exist yet: ${this}`);
    }
  }

  render() {
    const {
      headerText,
      proTip,
      myResourceUrl,
      myResourceButtonText,
      submitButtonText,
      isSubmitting,
      cancelEdit,
      uploadExcel,
      setReverse,
      Form,
      submitHandler,
      validator,
      Results,
      resultsHeaderText,
    } = this.props;

    const form = <Form onSubmit={submitHandler} validate={validator} ref={ref => this.form = ref || this.form} />;
    let uploadExcelButton = null;
    let cancelButton = null;
    let myResourceButton = null;
    let myResourceButtonMobile = null;
    let myResourceButtonDesktop = null;
    if (typeof cancelEdit === 'function') {
      cancelButton = <button className='btn btn-default' onClick={cancelEdit}>Cancel</button>;
    }
    else {
      if (uploadExcel) {
        uploadExcelButton = <button className='btn btn-default pull-left' onClick={uploadExcel}>Upload File</button>;
      }
      myResourceButton = <Link to={myResourceUrl} style={{ marginRight: 5 }}><button className='btn btn-default'>{myResourceButtonText}</button></Link>;
      myResourceButtonMobile = <Link to={`${location.pathname}/history`} style={{ marginRight: 5 }}><button className='btn btn-default' style={{ fontSize: '0.85em' }}>My History</button></Link>;
      myResourceButtonDesktop = <Link to={`${location.pathname}/history`} style={{ marginRight: 5 }}><button className='btn btn-default'>View History</button></Link>;
    }
    const reverseButton = typeof setReverse === 'function' ? <button className='btn btn-default' onClick={setReverse}>Post Reverse</button> : null;
    const submitButton = <button className='btn btn-orange' onClick={this.submit} disabled={isSubmitting}>{submitButtonText}</button>;
    const spinner = isSubmitting ? <div><Spinner /></div> : null;

    return (
      <MediaQuery
        mobile={
          <div className='Post col-xs-12' style={{ marginBottom: '1em' }}>
            <div className='clearfix' style={{ marginTop: '0.5em' }}>
              <Header style={{ display: 'inline-block', marginTop: 0 }}>{headerText}</Header>
              {proTip}
              <div className='pull-right'>{myResourceButtonMobile}</div>
            </div>
            {form}
            <div className='modal-footer clearfix' style={{ paddingLeft: 0, paddingRight: 0 }}>
              {myResourceButton}
              {uploadExcelButton}
              {cancelButton ? <Link to={myResourceUrl} style={{ marginRight: 5 }}>{cancelButton}</Link> : null}
              {reverseButton}
              {submitButton}
            </div>
            {spinner}
          </div>
        }
        desktop={
          <div className='full-height' style={{ width: '100%' }}>
            <div className='col-sm-5 col-md-4 col-lg-3'>
              <div className='clearfix' style={{ marginTop: '0.5em' }}>
                <Header style={{ display: 'inline-block', marginTop: 0 }}>{headerText}</Header>
                {proTip}
              </div>
              {form}
              <div className='modal-footer clearfix' style={{ paddingLeft: 0, paddingRight: 0 }}>
                {cancelButton}
                {uploadExcelButton}
                {reverseButton}
                {submitButton}
              </div>
              {spinner}
            </div>
            <div className='col-sm-7 col-md-8 col-lg-9 col-fill'>
              <div style={{ marginTop: '0.5em' }}>
                <Header style={{ display: 'inline-block', marginTop: 0 }}>{resultsHeaderText}</Header>
                {'\u00a0\u00a0\u00a0'}
                {myResourceButtonDesktop}
              </div>
              <Results />
            </div>
          </div>
        }
      />
    );
  }
}

export default PostTruck;
