import React, { useState } from 'react';
import withState from 'recompose/withState';
import { Field, reduxForm, getFormValues } from 'redux-form';
import PennyInput from '../form/inputs/PennyInput';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal, openModal } from 'actions/ui';
import { get } from 'lodash';
import { depositReceivables, stripeDepositMoney } from 'actions/ledger';
import ModalFooter from 'components/pure/ModalFooter';
import ModalBody from 'components/pure/ModalBody';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';;
import validate from 'validate.js';

const styles = {
    button: {
        backgroundColor: '#FF7800',
        color: '#fff',
    },
    flexRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
};

const ModalTitle = ({ title }) => (
    <h4 className='modal-title text-orange'>{title}</h4>
);

function WalletDepositReceivable({
    closeModal,
    handleSubmit,
    submitting,
    errorDeposit,
    setErrorDeposit,
    formValues = {},
    isStripeDeposit,
}) {
    return (
        <div>
            <ModalBody closeModal={closeModal}>
                <ModalTitle title={'Make a Deposit'} />
                <div >
                    {
                        errorDeposit ?
                            <div style={{ margin: '5px 5px 5px 5px' }} className="alert alert-danger">
                                <div
                                    style={{ ...styles.flexRow, justifyContent: 'flex-end' }}
                                >
                                    <button type='button' className='close' aria-label='Close' onClick={() => setErrorDeposit(null)}>
                                        <span aria-hidden='true'>×</span>
                                    </button>
                                </div>
                                {errorDeposit}
                            </div> : null
                    }
                    <div
                        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
                    >
                        <div style={{ padding: '5px 5px 5px 5px' }}>
                            {!isStripeDeposit && <Field
                                name='deposit_type'
                                placeholder='Select Deposit Type'
                                component={LabeledDropdown}
                                data={[
                                    { text: 'Wire Deposit', value: 'self_finance_wire' },
                                    { text: 'ACH DEBIT', value: 'self_finance_debit' },
                                    { text: 'ACH Deposit', value: 'self_finance_ach' },
                                ]}
                                label='Deposit Type'
                            />
                            }
                            <Field
                                name='deposit_amount'
                                component={PennyInput}
                                label='Amount'
                            />
                            {formValues.deposit_type === 'self_finance_wire' &&
                                <Field
                                    name='purchase_order_number'
                                    component={LabeledInput}
                                    label='Reference Number (Not Required)'
                                />
                            }
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
                <BigOrangeSubmitButton submitting={submitting} onClick={handleSubmit} message='Deposit' />
            </ModalFooter>
        </div>
    )
};
const FORM_NAME = 'WALLET_DEPOSIT_RECEIVABLES';

export default compose(
    withState('errorDeposit', 'setErrorDeposit', null),
    connect(
        state => ({
            factoringId: get(state, ['resource', 'user', state.user.id, 'data', 'factoring_id'], ''),
            formValues: getFormValues(FORM_NAME)(state),
        }),
        { closeModal, openModal },
    ),
    reduxForm({
        form: FORM_NAME,
        initialValues: {
            deposit_type: 'self_finance_debit',
            deposit_amount: 0,
        },
        validate: fields => validate(fields, {
            deposit_amount: {
                presence: true,
                numericality: {
                    greaterThan: 0,
                }
            }
        }),
        async onSubmit(fields, dispatch, { closeModal, openModal, factoringId, setErrorDeposit, fetchWalletPendingTransaction, isStripeDeposit }) {
            try {
                const data = {
                    ...fields,
                    deposit_amount: parseInt(fields.deposit_amount) / 100,
                    company_id: factoringId,
                }
                isStripeDeposit ? await stripeDepositMoney(data) : await depositReceivables(data);
                fetchWalletPendingTransaction();
                closeModal();
                setTimeout(() => openModal('success', { message: 'Deposit has been created successfully.' }), 300);
            } catch (e) {
                const errorMessage = get(e, 'response.data');
                if (typeof errorMessage === 'object') {
                    if (errorMessage.deposit_amount) {
                        return setErrorDeposit(errorMessage.deposit_amount);
                    }
                    if (errorMessage.account) {
                        return setErrorDeposit(errorMessage.account);
                    }
                    if (errorMessage.company_id) {
                        return setErrorDeposit(errorMessage.company_id);
                    }
                }
                setErrorDeposit(e);
            }
        },
    }))(WalletDepositReceivable);
