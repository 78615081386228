/**
 * Component to display the header of a modal.
 * @module components/pure/modals/ShipperLoadAlertModalForm
 * @since 3.0.0
 * @property {object} props
 * @property {string} [props.icon] - the icon name to pass to [FontIcon]{@link module/components/pure/FontIcon}
 * @property {node|node[]} [props.children] - header children
 * @property {function} [props.closeModal] - function to close the modal with the X in the top-right corner, which is omitted if not present
 * @property {any} [props....rest] - props passed to containing div component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import MaterialIcon from 'components/pure/MaterialIcon';

const GenericHeader = ({ icon, children, closeModal, ...rest }) =>
  <div {...rest} className={cs('modal-header', rest.className)}>
    {closeModal ? <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button> : null}
    <h4 className='modal-title'>
      <div>
        {icon && <MaterialIcon name={icon} style={{ verticalAlign: 'middle' }} />}
        {icon && '\u00a0'}
        <span>{children}</span>
      </div>
    </h4>
  </div>
  ;

GenericHeader.propTypes = {
  icon: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeModal: PropTypes.func.isRequired,
};

export default GenericHeader;
