import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import moment from 'moment';

import formatPennies from 'helpers/formatPennies';
import Resource from 'components/hoc/Resource';
import EventLog from 'components/pure/EventLog';

export default compose(
  Resource('factoringclientdebtorauditlog'),
)(({ item }) => {
  if (!item || !item.data) {
    return null;
  }
  return (
    <EventLog
      events={item.data}
      transform={(data, i, arr) => {
        const key_is_invalid = (
          [
            'fdr_modified',
            'fdr_last_edited_by_name',
            'fdr_last_edited_by_id',
            'fdr_assigned_admin_id',
            'fdr_assigned_admin_user_type',
            'fdr_last_edited_by_user_type',
          ].includes(data.original_key) ||
          !data.original_key.startsWith('fdr_')
        );
        if (key_is_invalid) {
          return null;
        }
        const label_map = {
          noa_sent: 'NOA Sent',
          noa_active: 'NOA Active',
          noa_placed: 'NOA Placed',
          should_fasttrack: 'Should Fasttrack?',
          minimum_fee: 'Minimum Fee',
          discount_rate: 'Discount Rate',
        };
        const key = data.original_key.replace('fdr_', '');
        let { old_value, new_value } = data;
        const format_value_map = {
          noa_placed(data){
            data.old_value = data.old_value ? moment(data.old_value).format('MMM DD, YYYY') : '';
            data.new_value = moment(data.new_value).format('MMM DD, YYYY');
            return data;
          },
          noa_active(data){
            data.old_value = data.old_value ? moment(data.old_value).format('MMM DD, YYYY') : '';
            data.new_value = moment(data.new_value).format('MMM DD, YYYY');
            return data;
          },
          noa_sent(data){
            data.old_value = data.old_value ? moment(data.old_value).format('MMM DD, YYYY') : '';
            data.new_value = moment(data.new_value).format('MMM DD, YYYY');
            return data;
          },
          should_fasttrack(data) {
            data.old_value = data.old_value ? 'Yes' : 'No';
            data.new_value = data.new_value ? 'Yes' : 'No';
            return data;
          },
          discount_rate(data) {
            data.old_value = data.old_value ? (`${data.old_value}%`) : 'Default (Factoring Client)';
            data.new_value += '%';
            return data;
          },
          minimum_fee(data) {
            data.old_value = data.old_value ? formatPennies(parseInt(data.old_value, 10)) : 'Default (Factoring Client)';
            data.new_value = formatPennies(parseInt(data.new_value, 10));
            return data;
          },
        };
        const formatter = format_value_map[key];
        if (formatter) {
          ({ old_value, new_value } = formatter(data));
        }
        return {
          ...data,
          old_value,
          new_value,
          key: label_map[key] || data.key.replace('Relationship ', ''),
        };
      }}
      sort={(a, b) => {
        const order = [
          'discount_rate',
          'should_fasttrack',
          'noa_sent',
          'noa_placed',
          'noa_active',
          'minimum_fee',
        ];
        // get root key, slicing off `debtor_`
        let root_key_a, root_key_b;
        const a_has_nested = a.original_key.indexOf('.');
        if (a_has_nested !== -1) {
          root_key_a = a.original_key.slice(7, a_has_nested);
        }
        else {
          root_key_a = a.original_key.slice(7);
        }
        const b_has_nested = b.original_key.indexOf('.');
        if (b_has_nested !== -1) {
          root_key_b = b.original_key.slice(7, b_has_nested);
        }
        else {
          root_key_b = b.original_key.slice(7);
        }
        const index_a = order.indexOf(root_key_a);
        const index_b = order.indexOf(root_key_b);
        return (index_a - index_b);
      }}
    />
  );
});
