/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { reset } from 'redux-form';
import { create } from 'actions/resourceBy/load/bid';
import { openModal } from 'actions/ui';

import PermissionsError from 'datatypes/PermissionsError';

import BidCreateForm from 'components/pure/form/BidCreateForm';
import segmentEvents from '../../../helpers/segmentEvents';


export const BidCreateFormUserTable = compose(
  connect(null, (dispatch, ownProps) => ({
    onSubmit: fields => dispatch(create(ownProps.id, {
      amount: fields.amount ? (typeof fields.amount === 'string' ? Number(fields.amount.replace(/[^\d.]/g, '')) : fields.amount) * 100 : undefined,
      bidder_notes: fields.note,
    }))
      .then(() => {
        const bidEvent = fields.amount && fields.note ? segmentEvents.USER_BID_LOAD_WITH_NOTES : segmentEvents.USER_BID_LOAD;
        window.analytics.track(bidEvent, { amount: fields.amount, notes: fields.note });
        dispatch(reset(`BidCreateForm#${ownProps.id}`));
        dispatch(openModal('success', { message: 'Successfully posted bid' }));
      })
      .catch(err => {
        if (err instanceof PermissionsError) {
          return dispatch(openModal('upgradeaccount'));
        }
        console.warn(err);
        return dispatch(openModal('error', { message: 'An error occured while trying to post your bid.' }));
      })
    ,
  })),
)(({ id, ...rest }) =>
  <BidCreateForm
    {...rest}
    form={`BidCreateForm#${id}`}
    className='form-inline'
    style={{ display: 'inline-block' }}
    fieldProps={{
      inputProps: {
        style: {
          width: '100px',
        },
      },
      buttonText: undefined,
    }}
  />
);

export default BidCreateFormUserTable;
