import mapProps from 'recompose/mapProps';

import Modal from 'components/hoc/Modal';
import ModalTimeout from 'components/hoc/ModalTimeout';
import ModalGeneric from './Generic';
import { compose } from 'redux-v3';

const Notes = compose(
  mapProps(props => ({
    ...props,
    headerStyle: {
      backgroundColor: '#349EFF',
      color: 'white',
    },
    headerIcon: 'note',
    headerChildren: 'Notes',
  })),
  Modal,
  ModalTimeout,
)(ModalGeneric);

export default Notes;
