import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.financespurchases;

import { cloneDeep } from 'lodash';
import financespurchasesPaginatedResource from 'actions/admin/financespurchases';

export default function financespurchasesPaginatedReducer(state = initialState, action) {
  state = financespurchasesPaginatedResource.reduce(state, action);
  return state;
}
