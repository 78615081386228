/* global Stripe STRIPE_KEY STRIPE_API_VERSION*/
let instance;
class stripeInstance {
    constructor(stripeAccount) {
        this.stripe = Stripe(STRIPE_KEY, { stripeAccount, apiVersion: STRIPE_API_VERSION });
    }

    collectBankAccountForSetup(clientSecret, params, options) {
        return this.stripe.collectBankAccountForSetup({ clientSecret, params, ...options });
    }

    confirmUsBankAccountSetup(clientSecret, params) {
        return this.stripe.confirmUsBankAccountSetup(clientSecret, params);
    }

    collectBankAccountToken(clientSecret) {
        return this.stripe.collectBankAccountToken({ clientSecret });
    }

}
export default {
    getInstance(stripeAccount) {
        if (!instance) {
            instance = new stripeInstance(stripeAccount);
        }
        return instance;
    }
};