import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import { initiateEdit } from 'actions/post';
import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import TruckMobile3 from 'components/pure/TruckMobile3';


const TruckPostTableItem = compose(
  Resource('truck', { resourcePropName: 'truck' }),
  connect(
    null,
    {
      initiateEdit: (...args) => initiateEdit('truck', ...args),
      initiateDelete: id => openModal('delete', { id, resource: 'truck' }),
    },
  )
)(TruckMobile3);

const TruckPostTable = ({ ids, isFetching = false, fetchNext }) =>
  <div className='col-fill'>
    <div className='visible-xs-block' style={{ flexGrow: 0, margin: '5px 3px 0px 4px' }}>
      <Link to='/posttruck'><button className='btn btn-default' style={{ fontSize: '0.85em' }}>Post New Truck</button></Link>
      <Link to='/posttruck/history' className='pull-right'><button className='btn btn-default' style={{ fontSize: '0.85em' }}>My History</button></Link>
    </div>
    <NormalInfiniteScroll
      isFetching={isFetching}
      onScrollBottom={fetchNext}
      placeholder={<div className='help-block text-center'>No trucks (yet)<br />Try posting some<br />Old posts are in History</div>}
      >
      {!ids ? null : ids.map(id => <TruckPostTableItem key={id} id={id} />)}
    </NormalInfiniteScroll>
  </div>
  ;

TruckPostTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  isFetching: PropTypes.bool,
  fetchNext: PropTypes.func,
};

export default TruckPostTable;
