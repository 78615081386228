/**
 * Actions relating to the Truck resource
 * @module actions/resource/truck
 * @since 3.0.0
 * @requires actions/ui
 * @requires datatypes/ControlledResource
 * @requires datatypes/Point
 * @requires datatypes/PermissionsError
 * @requires datatypes/SavedSetting
 */
/* global API */
import moment from 'moment';

import { setScrollToItem } from 'actions/ui';
import ControlledResource from 'datatypes/ControlledResource';
import Point from 'datatypes/Point';
import PermissionsError from 'datatypes/PermissionsError';
import SavedSetting from 'datatypes/SavedSetting';
import APIError from 'datatypes/error/APIError';


export const truckViewedHistory = SavedSetting.Set('TRUCK_VIEWED_HISTORY');
export const truckOpenHistory = SavedSetting.Set('TRUCK_OPEN_HISTORY');


/**
 * The Resource that handles Truck parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class TruckResource extends ControlledResource {
  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return {
      isFetching: false,
      globals: {
        ...this.options.globals,
        viewed: truckViewedHistory.has(json.id),
        open: truckOpenHistory.has(json.id),
      },
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: {
        ...(oldValue ? oldValue.data : undefined),
        ...json,
        weight: !json.weight || json.weight === 1 ? 0 : json.weight,
        length: !json.length || json.length === 1 ? 0 : json.length,
        origin_state: json.origin_region[0],
        destination_state: json.destination_region[0],
        origin_coords: Point.fromGeoPoint(json.origin_coords, json.origin_region[0]),
        destination_coords: Point.fromGeoPoint(json.destination_coords, json.destination_region[0]),
        time_start: moment(json.time_start),
        time_end: moment(json.time_end),
        time_posted: moment(json.time_posted),
        ...(() => {
          const user = {
            data: json.user,
            err: null,
          };
          if (!json.user) {
            user.data = {};
            user.err = new PermissionsError('not allowed');
          }
          return { user };
        })(),
      },
    };
  }
}

/**
 * Singleton for our LoadResource
 */
export const truckResource = new TruckResource('truck', {
  url: id => `trucks/${id}`,
});

export default truckResource;
export const {
  fetch,
  fetchIfNeeded,
  create,
  edit,
  doDelete,
  getItem,
} = truckResource;

/**
 * Set truck viewed action. Marks the truck with `id` as viewed. Not saved anywhere but in memory, is ephemeral
 * @event TRUCK_SET_VIEWED
 * @property {symbol} type - Symbol(TRUCK_SET_VIEWED)
 * @property {string} paytruck - the id of the truck to be marked as viewed
 */
export const TRUCK_SET_VIEWED = Symbol('TRUCK_SET_VIEWED');
/**
 * Set truck viewed action
 * @param {string} id - the id of the truck to be marked as viewed
 * @fires TRUCK_SET_VIEWED
 */
export const setViewed = id => dispatch => {
  truckViewedHistory.set(id);
  dispatch(setScrollToItem(id));
  return dispatch({
    type: TRUCK_SET_VIEWED,
    payload: id,
  });
};

/**
 * Toggle truck 'open' action. Toggles the truck with `id` as open or not open. Some different views use this. Saved only in memory
 * @event TRUCK_TOGGLE_OPEN
 * @property {symbol} type - Symbol(TRUCK_TOGGLE_OPEN)
 * @property {string} paytruck - the id of the truck to be toggled
 */
export const TRUCK_TOGGLE_OPEN = Symbol('TRUCK_TOGGLE_OPEN');
/**
* Toggle truck 'open' action.
* @property {string} id - the id of the truck to be toggled
* @fires TRUCK_TOGGLE_OPEN
*/
export const toggleOpen = id => dispatch => {
  truckViewedHistory.set(id);
  truckOpenHistory.toggle(id);
  return dispatch({
    type: TRUCK_TOGGLE_OPEN,
    payload: id,
  });
};

export const TRUCK_REFRESH = Symbol('TRUCK_REFRESH');
export const refreshTruck = id => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/trucks/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      refresh_post: true,
    }),
  });
  if (res.status !== 200) {
    throw new APIError(res.status, await res.text());
  }
  const json = await res.json();
  dispatch({
    type: TRUCK_REFRESH,
    payload: {
      id,
      json,
    },
  });
};
