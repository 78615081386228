/**
 * Actions relating to truck search
 * @module actions/search/truck
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/trucksearch
 */
import UrlAssembler from 'url-assembler';
import { formValueSelector, change, submit } from 'redux-form';

import PaginatedResource from '../../datatypes/PaginatedResource';
import truckSearchResource from '../resource/trucksearch';


/**
 * Our truck search [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const truckSearchPaginatedResource = new PaginatedResource('search_truck', {
  mountPoint: 'search.truck',
  url: new UrlAssembler('truck/search'),
  baseResource: truckSearchResource,
  globals: {
    view: 'list',
  },
});

export default truckSearchPaginatedResource;
export const {
  fetch,
  fetchNext,
  sort,
  clear,
} = truckSearchPaginatedResource;

/**
 * Truck search set view action. Changes the view on truck search between map view and list view
 * @event SEARCH_TRUCK_SET_VIEW
 * @property {symbol} type - Symbol(SEARCH_TRUCK_SET_VIEW)
 * @property {string} payload - `'list' | 'map'`
 * @property {any} meta
 */
export const SEARCH_TRUCK_SET_VIEW = Symbol('SEARCH_TRUCK_SET_VIEW');
/**
 * Truck search set view action
 * @property {string} view_type - `'list' | 'map'`
 * @fires SEARCH_TRUCK_SET_VIEW
 */
export function setView(view_type) {
  return {
    type: SEARCH_TRUCK_SET_VIEW,
    payload: view_type,
    meta: ['loadsearch', 'setview', view_type],
  };
}

/**
 * Thunk to swap the origin and destination fields in the truck search form
 */
export function swapOriginAndDestination() {
  const FORM = 'TruckSearch';
  return (dispatch, getState) => {
    const selector = formValueSelector(FORM);
    const { origin_region, destination_region } = selector(getState(), 'origin_region', 'destination_region');
    dispatch(change(FORM, 'origin_region', destination_region));
    dispatch(change(FORM, 'destination_region', origin_region));
    setTimeout(() => dispatch(submit(FORM)), 0);
  };
}
