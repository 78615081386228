/**
 * Root reducer for the admin reducers
 * @module reducers/admin
 * @since 3.0.0
 * @requires reducers/admin/leads
 * @requires reducers/admin/factoring
 * @requires reducers/admin/clientcombinednotes
 * @requires reducers/admin/debtorcombinednotes
 */
import { combineReducers } from 'redux-v3';

import leads from './leads';
import factoring from './factoring';
import financespayments from './financespayments';
import financespurchases from './financespurchases';
import financeslineitems from './financeslineitems';
import clientcombinednotes from './clientcombinednotes';
import debtorcombinednotes from './debtorcombinednotes';
import factoringtransactionpayments from './factoringtransactionpayments';
import factoringtransactionpurchases from './factoringtransactionpurchases';
import factoringGroupUsers from './factoringGroupUsers';
import financesPurchasesItems from './financesPurchasesItems';

export default combineReducers({
  leads,
  factoring,
  clientcombinednotes,
  debtorcombinednotes,
  financespayments,
  financespurchases,
  financeslineitems,
  factoringtransactionpayments,
  factoringtransactionpurchases,
  factoringGroupUsers,
  financesPurchasesItems,
});
