import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import cs from 'classnames';
import validate from 'validate.js';

import { openModal } from 'actions/ui';
import PermissionsError from 'datatypes/PermissionsError';

import ExpandingInput from 'components/pure/form/inputs/ExpandingInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import segmentEvents from '../../../helpers/segmentEvents';
export default compose(
  connect((state, ownProps) => ({
    onSubmit(fields, dispatch) {
      const { logged_in } = state.user;
      if (!logged_in) {
        return dispatch(openModal('loginprompt'));
      }
      return ownProps.onSubmit(fields, dispatch)
        .then((response) => {
          console.log("FIELDS", fields)
          const bidEvent = fields.amount && fields.note ? segmentEvents.USER_BID_LOAD_WITH_NOTES : segmentEvents.USER_BID_LOAD;
          window.analytics.track(bidEvent, { amount: fields.amount, notes: fields.note });
        })
        .catch(err => {
          if (err instanceof PermissionsError) {
            dispatch(openModal('upgradeaccount'));
          }
          else {
            throw err;
          }
        })
        ;
    },
  })),
  reduxForm({
    form: 'BidCreateForm',
    destroyOnUnmount: true,
    validate(values) {
      return validate({
        amount: values.amount ? (typeof values.amount === 'string' ? Number(values.amount.replace(/[^\d.]/g, '')) : values.amount) * 100 : undefined,
        note: values.note,
      }, {
        amount: {
          presence: true,
          numericality: {
            strict: true,
            greaterThanOrEqualTo: 1000,
            notGreaterThanOrEqualTo: '^Please enter an all in rate greater than $9.99.',
          },
        },
        note: {
          length: {
            maximum: 250,
          },
        },
      });
    },
  }),
)(({
  submitSucceeded,
  warning,
  pure,
  triggerSubmit,
  autofill,
  clearSubmit,
  clearAsyncError,
  submit,
  anyTouched,
  asyncValidate,
  asyncValidating,
  destroy,
  dirty,
  error,
  form,
  handleSubmit,
  initialize,
  invalid,
  pristine,
  reset,
  submitting,
  submitFailed,
  touch,
  untouch,
  valid,
  dispatch,
  initialValues,
  shouldAsyncValidate,
  validate,
  initialized,
  registeredFields,
  arrayMove,
  arrayRemoveAll,
  startAsyncValidation,
  startSubmit,
  stopAsyncValidation,
  stopSubmit,
  setSubmitFailed,
  updateSyncErrors,
  blur,
  change,
  array,
  focus,
  fieldProps = {},
  ...rest
}) =>
  <form {...rest} className={cs('form', rest.className)} onSubmit={handleSubmit}>
    <Field
      name='amount'
      onlyValidateAfterSubmit={true}
      component={ExpandingInput}
      ComponentClass='input'
      buttonText='Submit Bid'
      {...fieldProps}
      inputProps={{
        type: 'number',
        placeholder: 'Enter a bid...(numbers only)',
        autoComplete: 'off',
        ...fieldProps.inputProps,
      }}
      buttonProps={{
        disabled: submitting,
        ...fieldProps.buttonProps,
      }}
    />
    <Field name='note' component={LabeledInput} placeholder='Include note with bid (optional)' style={{ marginTop: '0.5em' }} />
    {error && <div className='alert alert-danger' role='alert'>{`${error}`}</div>}
  </form>
);
