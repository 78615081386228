/**
 * Generic class for a collection of controlled paginated-resource-related fetching, editing, creating, deleting, serialization and deserialization
 * @module datatypes/ControlledPaginatedResourceCollection
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires datatypes/ControlledPaginatedResource
 */
import PaginatedResourceCollection from './PaginatedResourceCollection';
import ControlledPaginatedResource from './ControlledPaginatedResource';


/**
 * ControlledPaginatedResourceCollection class
 * @extends module:datatypes/PaginatedResourceCollection~PaginatedResourceCollection
 */
class ControlledPaginatedResourceCollection extends PaginatedResourceCollection {
  /**
   * Create a ControlledResourcePaginatedResourceCollection. See [PaginatedResourceCollection]{@link module:datatypes/PaginatedResourceCollection~PaginatedResourceCollection} constructor
   */
  constructor(name, resource, options) {
    super(name, resource, options);

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  /**
   * Overwritten PaginatedResourceCollection#getResourceType in order to use ControlledPaginatedResources instead of PaginatedResources
   */
  getResourceType() {
    return ControlledPaginatedResource;
  }

  /**
   * Call the `create` function on an underlying ControlledPaginatedResource with the given ID. See {@link module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#create}
   * @param {string} id - the ID of the underlying ControlledPaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying ControlledPaginatedResource's `create` function
   */
  create(id, ...rest) {
    return this.getResources(id).create(...rest);
  }

  /**
   * Call the `edit` function on an underlying ControlledPaginatedResource with the given ID. See {@link module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#edit}
   * @param {string} id - the ID of the underlying ControlledPaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying ControlledPaginatedResource's `edit` function
   */
  edit(id, ...rest) {
    return this.getResources(id).edit(...rest);
  }

  /**
   * Call the `doDelete` function on an underlying ControlledPaginatedResource with the given ID. See {@link module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#doDelete}
   * @param {string} id - the ID of the underlying ControlledPaginatedResource
   * @param {any} [...rest] - arguments to be passed to the underlying ControlledPaginatedResource's `doDelete` function
   */
  doDelete(id, ...rest) {
    return this.getResources(id).doDelete(...rest);
  }
}

export default ControlledPaginatedResourceCollection;
