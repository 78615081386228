/**
 * Container component for routes viewed while logged-out.
 * @module components/pure/LoggedOutContainer
 * @since 3.0.0
 * @requires styles/LoggedOutContainer
 * @property {object} props
 * @property {(node|node[])} [props.children] - child(ren) node(s) to render
 */
import 'styles/LoggedOutContainer';
import React from 'react';
import PropTypes from 'prop-types';


export const LoggedOutContainer = ({ children }) =>
  <div className='LoggedOutContainer'>
    <div className='LoggedOutContainer__container'>
      {children}
    </div>
  </div>
  ;

LoggedOutContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default LoggedOutContainer;
