import 'styles/ExpandingInput';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const ExpandingInput = ({ input, meta, buttonText, ComponentClass = 'textarea', inputProps = {}, buttonProps = {}, onlyValidateAfterSubmit = false, ...rest }) => {
  const showError = meta.error && ((onlyValidateAfterSubmit && meta.submitFailed && meta.touched) || (!onlyValidateAfterSubmit && meta.touched));
  return (
    <div
      {...rest}
      className={cs({
        'has-error': showError,
      }, rest.className)}
      >
      <div className='ExpandingInput'>
        <ComponentClass className='form-control' {...input} {...inputProps} />
        <button className={cs('btn btn-orange', buttonProps.className)} style={{ verticalAlign: 'top' }} type='submit' {...buttonProps}>{buttonText || 'Submit'}</button>
      </div>
      {showError ? <div className='help-block' style={{ margin: 0 }}>{meta.error}</div> : null}
    </div>
  );
};

ExpandingInput.propTypes = {
  ComponentClass: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
  ]),
  inputProps: PropTypes.object,
  buttonProps: PropTypes.object,
  buttonText: PropTypes.string,
  disabled: PropTypes.bool,
};

export default ExpandingInput;
