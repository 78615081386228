/* global fbq process*/
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';

import { openModal } from 'actions/ui';
import savedtruckByUserResource from 'actions/resourceBy/user/savedtruck';
import APIError from 'datatypes/error/APIError';
import segmentEvents from '../../helpers/segmentEvents';

export default compose(
  connect(
    state => {
      return {
        user_id: state.user.id,
      };
    },
    {
      openModal,
      create: savedtruckByUserResource.create,
    },
    ({ user_id }, { create, openModal }, { id, ...rest }) => {
      return {
        ...rest,
        onClick() {
          return create(user_id, { truck: id })
            .then(() => {
              window.analytics.track(segmentEvents.USER_SAVED_TRUCK, { user_id, truck: id });
              openModal('success', { message: 'Successfully saved truck.' });
            })
            .catch(err => {
              console.warn(err);
              let message = 'Failed to save truck :(';
              if (err instanceof APIError) {
                if (err.status === APIError.type.VALIDATION_ERROR) {
                  message = 'This truck was already saved.';
                }
              }
              openModal('error', { message });
            })
            ;
        },
      };
    },
  ),
)(props =>
  <button
    type='button'
    {...props}
    className={cs('btn btn-default', props.className)}
  >
    Save this Truck
  </button>
);
