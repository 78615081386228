import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { get } from 'lodash';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
    marginLeft: 20,
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 15,
    color: '#5c6979',
    textAlign: 'center',
  },
  icon: {
    //fontSize: 50,
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    color: '#979797',
  },
};

const SendNOAModal = ({ closeModal, confirm, cancel, debtor, ...props }) => (
  <div>
    <ModalBody
      closeModal={() => closeModal()}
      >
      <div
        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
        >
        <p style={{ ...styles.text, color: '#FF7800', fontSize: 14, paddingVertical: 10 }}>
          {debtor.credit_approved === 'needs_review' ?
            `Your customer ${debtor.dba} is being reviewed` :
            `Your customer ${debtor.dba} has been approved`
          }
        </p>
      </div>
      <div >
        <p style={styles.text}>
          {debtor.credit_approved === 'needs_review' ?
            `The amount requested is more than the credit limit set for this
          customer.`
            : ' Would you like to send the NOA ahead of time to speed up payment?'
          }
        </p>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, justifyContent: 'flex-end', margin: '0px 5px 5px 5px' }}>

      <Button
        //style={styles.button}
        onClick={() => {
          cancel();
          closeModal();
        }} size='sm'
           >
          Do not send NOA now
      </Button>
      <Button
        style={styles.button}
        onClick={() => {
          confirm();
          closeModal();
        }} size='sm'
           >
          Send NOA now
      </Button>
    </div>
  </div>
);

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ))(SendNOAModal);
