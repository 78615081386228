/**
 * Component to display a different versino of a mobile-friendly load view
 * @module components/pure/LoadMobile3
 * @since 3.0.1
 */
/* global EMPTY_PLACEHOLDER */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import RouteFormat from 'components/pure/RouteFormat';
import TitledGrid from 'components/pure/TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonRefresh from 'components/container/ButtonRefresh';


const LoadMobile3 = compose(
  spinnerIfFetching('load'),
)(({ load, initiateEdit, initiateDelete, initiateCopy, openLoadModalPost, ...rest }) =>
  <div
    {...rest}
    className={cs('load-mobile-2', rest.className, {
      'load-mobile-2-viewed': load.globals.viewed,
      'yellow-shipper': load.data.user_type === USER_TYPE.SHIPPER,
      'blue-broker': [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(load.data.user_type),
    })}
    >
    <Link to={`/load/${load.data.id}`}>
      <RouteFormat origin={load.data.origin_locations} destination={load.data.destination_locations} />
      {load.data.targetRate ? <div className='load-mobile-2-target-rate'>{formatPennies(load.data.targetRate)}</div> : null}
      <div className='load-mobile-2-physical-info'>{trailerTypeMap[load.data.trailerType]} / {load.data.length ? `${load.data.length} ft` : '—'} / {load.data.weight ? `${formatWeight(load.data.weight)} lbs` : '—'}</div>
      <div style={{ color: '#c62828' }}>Load #: {load.data.user_load_number ? load.data.user_load_number : '--'
      }</div>
      {
        !isEmpty(load.data.funding_request) &&
          <div>
            <div>Carrier : {get(load, 'data.funding_request.payment_profile_name') ? get(load, 'data.funding_request.payment_profile_name') : '--'
            }</div>
            <div>Customer : {get(load, 'data.funding_request.debtor.dba') ? get(load, 'data.funding_request.debtor.dba') : '--'
            }</div>
          </div>
      }
      <TitledGrid cols={4} justified={true}>
        <TitledGrid.Section title='ready' bottom={true}>
          <div>{load.data.time_start.format('D MMM')}</div>
          <div style={{ fontSize: '0.7em' }} >
            {load.data.time_start.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='close' bottom={true}>
          <div>{load.data.time_end.format(`D MMM${load.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}</div>
          <div style={{ fontSize: '0.7em' }}>
            {load.data.time_end.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='deliver by' bottom={true}>
          {load.data.time_delivery ? load.data.time_delivery.format('D MMM') : EMPTY_PLACEHOLDER}
        </TitledGrid.Section>
        <TitledGrid.Section title='load age' bottom={true}>
          <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(load.data.time_posted)}
        </TitledGrid.Section>
      </TitledGrid>
    </Link>
    <div className='load-mobile-2-bottom'>
      <div className='load-mobile-2-bottom-left'>
        <div>
          {
            initiateCopy ?
              <div style={{ display: 'inline-block' }}>
                <button className='btn btn-orange' onClick={() => initiateCopy(load.data.id)}>Copy</button>
                {'\u00a0\u00a0\u00a0'}
              </div>
              : null
          }
          {
            initiateEdit &&
            load.data.status !== 'completed' ?
              <div style={{ display: 'inline-block' }}>
                <button
                  className='btn btn-orange' onClick={async () => {
                    await initiateEdit(load.data.id);
                  }}
                                             >Edit</button>
                {'\u00a0\u00a0\u00a0'}
              </div>
              : null
          }
          {
            initiateDelete ?
              <button className='btn btn-danger' onClick={() => initiateDelete(load.data.id)}>X</button>
              : null
          }
        </div>
      </div>
      <div>
        {
          load.data.status === 'completed' ||
          load.data.is_deleted ?
            null :
            <ButtonRefresh type='load' id={load.data.id}>Refresh Age</ButtonRefresh>
        }
        {'\u00a0\u00a0\u00a0'}
        <Link to={`/load/${load.data.id}`}><button className='btn btn-orange'>DETAILS</button></Link>
      </div>
    </div>
  </div>
);

export default LoadMobile3;
