/**
 * Component for displaying a multiselect input whose items abbreviate themselves after a given amount have been selected
 * @module components/pure/form/inputs/GrowableInput
 * @since 3.0.1
 * @property {object} props
 * @property {object} props.Component - the component to grow
 * @property {any} [props....rest] - props passed to the Component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


class GrowableInput extends Component {
  constructor(props) {
    super(props);
    this.state = { grow: false };
    this.onFocus = this.onFocus.bind(this);
  }

  static propTypes = {
    Component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  };

  onFocus(e) {
    this.setState({ grow: true });
    if (typeof this.props.onFocus === 'function') {
      this.props.onFocus(e);
    }
  }

  render() {
    const { Component = 'textarea', ...rest } = this.props;
    const { grow } = this.state;
    return (
      <div className={cs('growable-input', { grow })}>
        <Component
          {...rest}
          onFocus={this.onFocus}
        />
        <style jsx>{`
          .growable-input :global(textarea) {
            height: 34px;
            resize: none;
            transition-property: height;
            transition-duration: 200ms;
          }
          .growable-input.grow :global(textarea) {
            height: 7em;
          }
        `}</style>
      </div>
    );
  }
}

export default GrowableInput;
