import React, { useRef } from 'react';
import tw, { styled, TwStyle } from 'twin.macro';
import 'rc-tooltip/assets/bootstrap_white.css';

export interface TooltipProps {
  children: React.ReactNode;
  tooltipText: string;
  placement: string;
}

export default function ({ children, tooltipText, placement }: TooltipProps) {
  const tipRef = useRef<HTMLDivElement>(null);
  function handleMouseEnter() {
    if (tipRef.current) {
      tipRef.current.style.opacity = '1';
      tipRef.current.style.marginLeft = '20px';
    }
  }
  function handleMouseLeave() {
    if (tipRef.current) {
      tipRef.current.style.opacity = '0';
      tipRef.current.style.marginLeft = '10px';
    }
  }

  const placementStyle: { [key: string]: TwStyle } = {
    left: tw`left-full`,
    right: tw`right-full`,
    bottom: tw`top-full`,
    top: tw`bottom-full`,
  };
  const StyledTooltipDiv = styled.div((): TwStyle[] => [
    placementStyle[placement],
    tw`absolute bg-gray-700 text-white px-4 py-2 rounded flex items-center transition-all duration-150`,
  ]);
  const ArrowDirectionMap: { [key: string]: string } = {
    left: 'left',
    right: 'right',
    bottom: 'top',
    top: 'bottom',
  };
  return (
    <div
      tw="relative flex items-center"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <StyledTooltipDiv style={{ opacity: 0 }} ref={tipRef}>
        <div
          tw="bg-gray-700 h-3 w-3 absolute"
          style={{
            [ArrowDirectionMap[placement]]: '-6px',
            transform: 'rotate(45deg)',
          }}
        />
        <p tw="break-words w-80 max-w-sm h-auto">{tooltipText}</p>
      </StyledTooltipDiv>
      {children}
    </div>
  );
}
