/* global MOBILE_BREAKPOINT */
import 'styles/ImageInput';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';
import getContext from 'recompose/getContext';

import { openModal } from 'actions/ui';

import MaterialIcon from 'components/pure/MaterialIcon';
import ImageGrid from 'components/pure/ImageGrid';
import Thumbnail from 'components/container/Thumbnail';
import ImageCropper from 'components/pure/ImageCropper';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import AbbreviatedMultiSelect from './AbbreviatedMultiSelect';

export class ImageInput extends React.Component {
  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  static propTypes = {
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    children: PropTypes.element,
    label: PropTypes.string,
  };

  onClick() {
    this.input.click();
  }

  render() {
    const { input, meta, label, maxHeight, placeholder, children, ...rest } = this.props;

    return (
      <div {...rest} className={cs('ImageInput', rest.className)}>
        <div className='ImageInput__image' onClick={this.onClick}>
          {
            children ?
              children :
              typeof input.value === 'string' && input.value.length ?
                <Thumbnail src={input.value} style={{ maxHeight }} /> :
                input.value instanceof FileList && input.value[0] ?
                  <Thumbnail src={input.value[0]} style={{ maxHeight }} /> :
                  typeof placeholder === 'string' ?
                    <Thumbnail src={placeholder} data-src={placeholder} style={{ maxHeight }} /> :
                    placeholder ?
                      placeholder :
                      null
          }
        </div>
        <div className={cs('form-group', { 'has-error': meta.error })}>
          {label ? <label className='control-label'>{label}</label> : null}
          <input
            accept='image/*'
            type='file'
            ref={ref => this.input = ref}
            onChange={input.onChange}
            value={undefined}
          />
          {meta.error ? <div className='help-block'>{meta.error}</div> : null}
        </div>
      </div>
    );
  }
}

const VisibilityMapping = vis => {
  if (Array.isArray(vis)) {
    if (vis.includes('carrier') && vis.includes('shipper') && vis.includes('debtor')) {
      return 'Carrier & Shipper/Debtor';
    }

    if (vis.includes('shipper') && vis.includes('debtor')) {
      return 'Shipper/Debtor Only';
    }

    if (vis.includes('carrier')) {
      return 'Carrier Only';
    }
  }
};

export const MultiImageInput = compose(
  getContext({
    _reduxForm: PropTypes.object,
  }),
  connect(
    null,
    (dispatch, { _reduxForm, input }) => ({
      openModal: (...args) => {
        return dispatch(openModal(...args));
      },
      confirmDelete(new_value) {
        return dispatch(openModal('confirm', {
          type: 'form_change_field',
          args: [_reduxForm.form, input.name, new_value],
          message: 'Are you sure you want to remove?',
        }));
      },
    }),
  ),
)(class MultiImageInput extends React.Component { // eslint-disable-line react/no-multi-comp
  state = {
    cropping: false,
    multiselectValue: [],
    searchTerm: '',
  };
  onClick = this.onClick.bind(this);
  onChange = this.onChange.bind(this);
  onCropCancel = this.onCropCancel.bind(this);

  static propTypes = {
    limit: PropTypes.number,
    label: PropTypes.node,
    helpText: PropTypes.node,
    shouldCrop: PropTypes.bool,
    buttonText: PropTypes.node,
    buttonClassName: PropTypes.string,
    extraButtons: PropTypes.node,
    extraThumbnails: PropTypes.node,
    confirmBeforeDeleting: PropTypes.bool,
    convertAnyway: PropTypes.bool,
  };

  onClick() {
    const { input } = this;
    input.click();
  }

  onChange(e) {
    const { input: { value, onChange }, shouldCrop, openModal } = this.props;
    const file = e.target.files[0];
    if (
      file.type &&
      file.type.match('image.*|application/pdf') ===
      null
    ) {
      openModal('FileTypeConfirm', { modalSize: 'small' });
    }
    if (shouldCrop && /image\//.test(file.type)) {
      this.setState({
        cropping: e.target.files[0],
        onCrop: newValue => {
          let image = newValue;
          if (newValue instanceof Blob && !(newValue instanceof File)) {
            image = new File([newValue], newValue.name);
          }
          this.setState({ cropping: false });
          onChange([
            ...value,
            image,
          ]);
        },
      });
    }
    else {
      return onChange([
        ...value,
        file,
      ]);
    }
  }

  onDelete(i) {
    const { input: { value, onChange }, confirmBeforeDeleting, confirmDelete } = this.props;
    const new_value = [
      ...value.slice(0, i),
      ...value.slice(i + 1),
    ];
    if (confirmBeforeDeleting) {
      return confirmDelete(new_value);
    }
    return onChange(new_value);
  }

  onCropCancel() {
    this.setState({
      cropping: false,
    });
  }
  formatMultiSelectCategories(array) {
    const returnArray = [];
    array.map((object, i) => {
      returnArray.push({ value: object, text: object });
    });
    return returnArray;
  }
  render() {
    const {
      input: { value: formInputValue, onChange, onBlur },
      meta,
      label,
      noButton = false,
      buttonText = 'Add Image',
      buttonClassName = 'btn btn-orange',
      helpText,
      extraButtons,
      extraThumbnails,
      limit: formLimit = 3,
      inputProps = {},
      imageGridProps = {},
      convertAnyway,
      disabled = false,
      shouldCrop,
      _reduxForm,
      confirmDelete,
      confirmBeforeDeleting,
      categories,
      multiple = false,
      broker = false,
      openModal,
      onCancel,
      buttonStyle,
      ...rest
    } = this.props;
    let limit = formLimit;
    let value = formInputValue;
    const { cropping, onCrop, searchTerm, multiselectValue } = this.state;
    if (!Array.isArray(value)) {
      value = [];
    }

    (formInputValue || []).map(value => {
      if (typeof value.category === 'string') {
        value.category = value.category.split(',');
      }
      return value;
    });

    const thumbnails = value.map((file, i) => {
      return (
        <div
          key={`${file.name}-${file.size}-${file.lastModified}`}
          className='text-center'
          style={{
            width: 250,
            marginLeft: 20,
          }}
        >
          <label
            style={{
              marginBottom: 0,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {file.name}
          </label>
          <div
            className='multi-image-input-image'
            role='button'
            title={file.name}
          >
            <Thumbnail src={file} />
          </div>
          {
            categories ?
              multiple ?
                <div className='multi-trailer-input-container'>
                  {
                    !file.hasOwnProperty('category') ?
                      <div className='multi-trailer-input-placeholder'>Please select a category</div>
                      : null
                  }
                  <AbbreviatedMultiSelect
                    placeholder='categories'
                    style={{
                      display: 'flex',
                      minHeight: 30,
                      width: 250,
                      textOverflow: 'ellipsis',
                      alignItems: 'center',
                      fontSize: 12,
                    }}
                    data={categories}
                    onChange={category => {
                      file.category = category;
                      this.setState({
                        multiselectValue: value,
                      });
                      onChange(value);
                    }}
                    value={file.hasOwnProperty('category') ? file.category : []}
                  />
                  <style jsx>{`
                .multi-trailer-input-container {
                  position: relative;
                }
                .multi-trailer-input-placeholder {
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                  z-index: 1;
                  pointer-events: none;
                  color: #555;
                  height: 2.286em;
                  padding: .429em .857em;
                }
              `}
                  </style>
                </div>
                :
                <LabeledDropdown
                  input={{
                    onChange: category => {
                      file.category = category;
                      onChange(value);
                    },
                    value: file.category,
                  }}
                  data={categories}
                  placeholder='Category'
                  style={{ margin: '0 0.5em' }}
                  containerProps={{
                    style: {
                      marginBottom: 0,
                    },
                  }}
                />
              : null
          }
          {broker && (
            <LabeledDropdown
              input={{
                onChange: visibility => {
                  if (visibility === 'Carrier Only') {
                    file.visible_to = ['carrier'];
                  } else if (visibility === 'Shipper/Debtor Only') {
                    file.visible_to = ['shipper', 'debtor'];
                  } else if (visibility === 'Carrier & Shipper/Debtor') {
                    file.visible_to = ['carrier', 'shipper', 'debtor'];
                  }
                  onChange(value);
                },
                onBlur: onBlur,
                value: VisibilityMapping(file.visible_to),
              }}
              data={[
                'Carrier Only',
                'Shipper/Debtor Only',
                'Carrier & Shipper/Debtor',
              ]}
              placeholder='Visible To'
              style={{ margin: '0 0.5em' }}
              containerProps={{
                style: {
                  marginBottom: 0,
                },
              }}
            />
          )}
          <MaterialIcon
            name='clear'
            size={24}
            role='button'
            onClick={() => {
              this.onDelete(i)
              onCancel && onCancel(file)
            }}
          />
        </div>
      );
    }
    );
    if (Array.isArray(extraThumbnails)) {
      limit -= extraThumbnails.length;
      thumbnails.push(...extraThumbnails);
    }

    return (
      <div {...rest} className={cs('multi-image-input', rest.className)}>
        {
          cropping ?
            <ImageCropper
              file={cropping}
              onCrop={onCrop}
              onCancel={this.onCropCancel}
              convertAnyway={convertAnyway}
            />
            : null
        }
        <div
          className={cs('form-group', {
            'has-error': meta.touched && meta.error,
            'has-warning': meta.touched && meta.warning && !meta.error,
          })}
        >
          {label && <label className='control-label'>{label}</label>}
          {helpText && <div className='help-block multi-image-input-help-block'>{helpText}</div>}
          <ImageGrid
            maxHeight={275}
            {...imageGridProps}
          >
            {thumbnails}
          </ImageGrid>
          {
            !noButton && value.length < limit &&
            <button
              className={buttonClassName}
              onClick={this.onClick}
              style={{
                backgroundColor: '#2196f3',
                fontSize: '14px',
                color: 'white',
                border: 0,
                ...buttonStyle
              }}
              type='button'
              disabled={disabled}
            >
              {buttonText}
              {' '}
              <MaterialIcon className='mdi-24px' name='backup' />
            </button>
          }
          {extraButtons}
          <input
            {...inputProps}
            key={Math.random()}
            type='file'
            className='hidden'
            ref={ref => this.input = ref}
            onChange={this.onChange}
          />
          {meta.touched && meta.error || meta.warning ? <div className='help-block'>{meta.error || meta.warning}</div> : null}
        </div>
      </div>
    );
  }
});
