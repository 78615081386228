import React from 'react';
import moment from 'moment';

import DateRangePicker from 'components/pure/form/inputs/DateRangeInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import MaterialIcon from 'components/pure/MaterialIcon';


class PopupCalendarInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pickingRange: false,
      range: props.input.value || null,
    };
    this.setStart = this.setStart.bind(this);
    this.setEnd = this.setEnd.bind(this);
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.range !== this.state.range) {
      this.props.input.onChange(nextState.range);
    }
  }

  setStart(start) {
    if (!start.isValid || !start.isValid()) {
      return;
    }
    let { range } = this.state;
    const end = range ? range.end : null;
    range = moment.range(start, end);
    range.end = end;
    this.setState({ range });
  }

  setEnd(end) {
    if (!end.isValid || !end.isValid()) {
      return;
    }
    let { range } = this.state;
    const start = range ? range.start : null;
    range = moment.range(start, end);
    range.start = start;
    this.setState({ range });
  }

  render() {
    const { pickingRange, range } = this.state;
    const { input, meta, onConfirm, buttonText, ...rest } = this.props;

    if (pickingRange) {
      return (
        <div
          style={{
            position: 'fixed',
            top: '0',
            left: '0',
            backgroundColor: 'rgba(0, 0, 0, 0.6)',
            width: '100%',
            height: '100%',
            zIndex: '10000',
          }}
          >
          <div
            style={{
              borderRadius: 5,
              padding: '2em 0',
              maxHeight: '100vh',
              maxWidth: '100vw',
              backgroundColor: 'white',
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
            >
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
              }}
              >
              <MaterialIcon size={30} name='close' onClick={() => this.setState({ pickingRange: false })} role='button' />
            </div>
            <div
              style={{
                margin: '0 20px',
                width: 275,
              }}
              >
              <DateTimeInput
                label='Start'
                pickerOptions={{ time: false }}
                input={{
                  value: range ? range.start : null,
                  onChange: this.setStart,
                  onFocus: this.setStart,
                  onBlur: this.setStart,
                }}
                meta={{}}
              />
              <DateTimeInput
                label='End'
                pickerOptions={{ time: false }}
                input={{
                  value: range ? range.end : null,
                  onChange: this.setEnd,
                  onFocus: this.setEnd,
                  onBlur: this.setEnd,
                }}
                meta={{}}
              />
            </div>
            <DateRangePicker
              initialFromValue={true}
              value={range && range.start && range.end ? range : null}
              onSelect={range => this.setState({ range })}
            />
            {
              onConfirm ?
                <div
                  style={{
                    margin: '0 20px',
                  }}
                  className='text-right'
                  >
                  <button
                    type='button'
                    className='btn btn-orange'
                    onClick={() => {
                      this.setState({ pickingRange: false });
                      onConfirm();
                    }}
                    disabled={!range || !range.start || !range.end}
                    >
                     { buttonText || "Confirm" }
                  </button>
                </div>
                : null
            }
          </div>
        </div>
      );
    }

    return (
      <div style={{ marginBottom: 12.5 }}>
        <button
          type='button'
          className='btn btn-default'
          onClick={() => this.setState({ pickingRange: true })}
          {...rest}
          >
          {
            range ?
              `${range.start.format('l')} > ${range.end.format('l')}`
              :
              'Beginning of Time'
          }
        </button>
      </div>
    );
  }
}

export default PopupCalendarInput;
