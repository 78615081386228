/**
 * Root reducer for the settings resources
 * @module reducers/settings
 * @since 3.0.0
 * @requires reducers/settings/activity
 * @requires reducers/settings/loadalerts
 * @requires reducers/settings/accountspayable
 * @requires reducers/settings/accountsreceivable
 */
import { combineReducers } from 'redux-v3';

import activity from './activity';
import loadalerts from './loadalerts';
import accountspayable from './accountspayable';
import accountsreceivable from './accountsreceivable';


export default combineReducers({
  activity,
  loadalerts,
  accountspayable,
  accountsreceivable,
});
