/* global API */
import PaginatedResource from 'datatypes/PaginatedResource';
import UrlAssembler from 'url-assembler';
import factoringGroupUsersResources from 'actions/resource/factoringGroupUsers';
import { get } from 'lodash';
import FetchError from 'datatypes/FetchError';

class FactoringGroupUsersPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }

  parse(oldValue = {}, newData) {
    return get(oldValue, 'user.id');
  }
}

const FactoringGroupUsersResource = new FactoringGroupUsersPaginatedResource('factoring_broker_group_user', {
  mountPoint: ['admin', 'factoringGroupUsers'],
  url: new UrlAssembler('groups/:id/users'),
  baseResource: factoringGroupUsersResources,
});

export const FACTORING_GROUP_USER_DELETE = 'FACTORING_GROUP_USER_DELETE';
export const deleteFactoringGroupUser = (groupId, userId) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/groups/${groupId}/users/${userId}/`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  if (res.status !== 204) {
    throw new FetchError(res.status, await res.text());
  }
  dispatch({
    type: FACTORING_GROUP_USER_DELETE,
    payload: {
      groupId,
      userId,
    },
  });
};

export const deleteInvitedUser = userId => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/groups/invited_user/${userId}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      'deleted': true,
    }),
  });
  if (res.status !== 200) {
    throw await res.text();
  }
  return res;
};

export default FactoringGroupUsersResource;
