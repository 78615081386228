const formatAddress = (address, ignore = {}) => {
  if (!address) {
    return '';
  }
  const addr = address.street_one;
  return [
    {
      text: address.name,
      separator: ': ',
      ignore: ignore.name,
    },
    {
      text: addr,
      separator: ', ',
      ignore: ignore.address,
    },
    {
      text: address.city,
      separator: ', ',
      ignore: ignore.city,
    },
    {
      text: address.state,
      separator: ' ',
      ignore: ignore.state,
    },
    {
      text: address.zip,
      separator: ' ',
      ignore: ignore.zip,
    },
    {
      text: address.country,
      separator: '',
      ignore: ignore.country,
    },
  ].reduce((ret, { text, separator, ignore }, i, arr) => {
    if (text && ignore !== false) {
      ret += text;
      let has_next = false;
      for (let j = i + 1; j < arr.length; j++) {
        const next = arr[j];
        if (next.text && next.ignore !== false) {
          has_next = true;
          break;
        }
      }
      if (has_next) {
        ret += separator;
      }
    }
    return ret;
  }, '');
};

export default formatAddress;
