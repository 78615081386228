/**
 * Actions relating to the notification icon (top right icon) that alerts the user that new [Activities]{@link module:actions/resource/activity} are on their timeline
 * @module actions/notification
 * @since 3.0.0
 * @requires actions/settings/activity
 */


/**
 * Set notification action. Sets the notification state for the notification icon
 * @event NOTIFICATION_SET
 * @property {symbol} type - Symbol(NOTIFICATION_SET)
 * @property {boolean} payload - whether to toggle the notification state on (true) or off (false)
 * @property {any} meta
 */
export const NOTIFICATION_SET = Symbol('NOTIFICATION_SET');
/**
* Call this action to set 'notification' state, where state is true (new notfiications are waiting to be seen) or false (none)
* @function
* @param {boolean} value - true = new notifications are waiting, false = none
* @returns {Action}
*/
export const setNotification = value => dispatch => {
  const favicon = document.getElementById('favicon');
  favicon.href = value ? '/public/image/icon/ComFreightLogo_notification.png' : '/public/image/icon/ComFreightLogo.png';
  return dispatch({
    type: NOTIFICATION_SET,
    payload: value,
    meta: ['global', 'notificationicon'],
  });
};
