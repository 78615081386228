/**
 * Reducer for handling the factoringclientnote resource actions
 * @module reducers/resource/factoringclientnote
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringclientnote
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringclientnote;

import factoringclientnoteResource from 'actions/resource/factoringclientnote';
import factoringclientnoteByFactoringclientResource from 'actions/resourceBy/factoringclient/factoringclientnote';
import clientcombinednotesResource from 'actions/admin/clientcombinednotes';


export default function factoringclientnoteResourceReducer(state = initialState, action) {
  state = factoringclientnoteResource.reduce(state, action);
  switch (action.type) {
    case clientcombinednotesResource.actions.FETCH_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload.filter(({ factoring }) => factoring).reduce((acc, curr) => {
          acc[curr.id] = factoringclientnoteResource.parse(state[curr.id], curr);
          return acc;
        }, {}),
      };
    }
  }
  if (factoringclientnoteByFactoringclientResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...factoringclientnoteResource.parseMany(state, action.payload.results),
    };
  }
  if (factoringclientnoteByFactoringclientResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: factoringclientnoteResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
