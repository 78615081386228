/* global fbq process*/
import React, { Component } from 'react';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { Breadcrumb, Button, Col, Glyphicon, Grid, Row } from 'react-bootstrap';
import validate from 'validate.js';
import { find, get, head, isEmpty, uniq, values, concat, has, filter } from 'lodash';

import Header from 'components/pure/Header';
import FileDropzone from 'components/pure/FileDropzone';
import Table from 'components/pure/Table';
import createTableItems from 'helpers/createTableItems';
import { fetchFactoringProfile } from 'actions/user';
import { inviteToGroup } from 'actions/invites';
import { openModal, closeModal } from 'actions/ui';
import groups from 'actions/admin/factoring/groups';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import XLSX from 'xlsx';
import MCDOTAutoCompleteInput from '../pure/form/inputs/MCDOTAutocomplete';
import { Field, reduxForm, reset } from 'redux-form';
import { createPaymentsProfiles, getBrokerGroupCarriers, getGroupInvited, getPaymentProfile } from '../../actions/groups';
import MaterialIcon from 'components/pure/MaterialIcon';
import segmentEvents from '../../helpers/segmentEvents';
import USER_FACTORING_STATUS from '../../helpers/USER_FACTORING_STATUS';
import getBrokerTerms from '../../selectors/getBrokerTerms';
import { fetchTerms } from 'actions/factoring';
import { formattedPaymentTerms } from '../pure/Modals/CreatePaymentProfile';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  flexCol: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  text: {
    fontSize: 18,
    color: '#000000',
    textAlign: 'center',
  },
  description: {
    fontSize: 16,
  },
  icon: {
    color: '#498504',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
};

const Location = () => (
  <Breadcrumb
    style={{
      backgroundColor: 'white',
      padding: '8px 0',
    }}
  >
    <Breadcrumb.Item>
      <Link to='/haul-pay/carriers'>
        Carrier
      </Link>
    </Breadcrumb.Item>
    <Breadcrumb.Item active={true}>Add New</Breadcrumb.Item>
  </Breadcrumb>
);

const EmailTableComponents = createTableItems(
  ({ data }) => [
    <div>{data.email}</div>,
  ],
);

class UploadClientList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      emails: [],
      selectedEmails: [],
    };

    this.onDrop = this.onDrop.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.setSelectedEmails = this.setSelectedEmails.bind(this);
  }

  onDrop(acceptedFiles) {
    const readFile = file => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const fileAsText = reader.result;
        const wb = XLSX.read(fileAsText, {
          type: 'binary',
        });
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        const data = XLSX.utils.sheet_to_csv(ws, {});
        const emails = String(data).match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi);
        resolve(emails);
      };
      reader.onabort = () => reject('file reading was aborted');
      reader.onerror = () => reject('file reading has failed');

      reader.readAsBinaryString(file);
    });
    acceptedFiles.reduce(async (acc, file) => {
      const emails = await readFile(file);
      return [...acc, ...emails];
    }, Promise.resolve([]))
      .then(emails => {
        this.setState({ emails: uniq(emails) });
      });
  }

  setSelectedEmails(emails) {
    this.setState({
      selectedEmails: emails.map(e => e.data.email),
    });
  }

  async onSubmit(emails) {
    try {
      if (emails.length === 0) {
        this.props.onFail('Select at least 1 email.');
      }
      else {
        await this.props.sendInvites('email', emails);
        this.props.onSuccess();
      }
    }
    catch (err) {
      this.props.onFail(err.message);
    }
    finally {
      this.setState({
        emails: [],
        selectedEmails: [],
      });
    }
  }

  render() {
    return (
      <div>
        {isEmpty(this.state.emails) ?
          <FileDropzone onDrop={this.onDrop} /> :
          <div>
            <Table
              headers={[
                'Owner\'s Email',
              ]}
              items={this.state.emails.map(email => ({ data: { email } }))}
              ListItem={EmailTableComponents.TableItem}
              withPagination={true}
              limit={10}
              count={this.state.emails.length}
              selectable={true}
              onItemsSelect={this.setSelectedEmails}
              idSelector='data.email'
            />
            <button
              style={{
                marginTop: '10px',
                float: 'left',
              }}
              className='btn btn-green'
              onClick={() => this.onSubmit(this.state.selectedEmails)}
            >
              Send Invite to all
            </button>
          </div>
        }
      </div>
    );
  }
}

class InviteSingle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      phone: '',
      carriers: [],
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  async onSubmit() {
    const { type, openConfirmModal, carriers = [], closeModal } = this.props;
    try {
      if (carriers.length && type === "email") {
        const checkEmail = () => find(carriers, item => {
          if (has(item, 'payment_profile')) {
            return get(item, 'payment_profile.owner_email', "") === this.state[type];
          }
          return item.email === this.state[type];
        });
        if (!isEmpty(checkEmail())) {
          await new Promise((resolve, reject) => openConfirmModal({
            type: "confirm_check_email",
            customCloseModal: () => {
              reject();
              closeModal();
            },
            args: [{
              onConfirm: () => {
                resolve();
                closeModal();
              }
            }]
          }))
        }
      }
      const existPaymentProfile = await this.props.sendInvites(type, [this.state[type]]);
      if (!existPaymentProfile)
        this.props.onSuccess();
    }
    catch (err) {
      console.log("ERROR", err);
      this.props.onFail();
    }
    this.setState({
      email: '',
      phone: '',
    });
  }

  onChange(event, type) {
    this.setState({
      [type]: event.target.value,
    });
  }

  render() {
    const { type, carriers } = this.props;
    return (
      <div>
        <input
          className='form-control'
          type={type}
          placeholder={`Enter ${type}`}
          value={this.state[type]}
          onChange={event => this.onChange(event, type)}
        />
        <button
          style={{
            marginTop: '10px',
            float: 'right',
          }}
          className='btn btn-green'
          onClick={() => this.onSubmit()}
        >
          Invite
        </button>
      </div>
    );
  }
}

export const ValidationPopUpView = ({ closeModal, isConnected, number }) =>
  <div style={{ ...styles.flexRow, justifyContent: 'center', flexDirection: 'column' }}>
    <div
      style={{ ...styles.flexCol, justifyContent: 'center', alignItems: 'center', padding: '10px' }}
    >
      <MaterialIcon
        name='check_circle_outline'
        size={70}
        style={styles.icon}
      />
      <p style={styles.text}>{isConnected ? 'Already Connected!' : 'Connection Successful!'}</p>
    </div>
    <div
      style={{ ...styles.flexCol, justifyContent: 'center', alignItems: 'center' }}
    >
      <p style={{ ...styles.text, width: "75%" }}>
        {!isConnected ?
          `You're now connected with this carrier. You should now be
    able to view the carrier and connection in the 'My carriers' section.`
          : `The carrier with ${number} is already connected to you and
        can be found in the Carriers section.`
        }
      </p>
      <button
        className='btn btn-default'
        style={{ maxWidth: '40%' }}
        onClick={() => {
          closeModal();
          setTimeout(() => window.location.reload(), 500);
        }
      }>
      Ok
      </button>
    </div>
  </div>;

const CoBrokeringCheckPopUpView = ({ closeModal }) =>
  <div style={{ ...styles.flexRow, justifyContent: 'center', flexDirection: 'column' }}>
    <div
      style={{ ...styles.flexCol, justifyContent: 'center', alignItems: 'center', padding: '10px' }}
    >
      <MaterialIcon
        name='warning_amber'
        size={70}
        className='text-warning'
      />
    </div>
      <div
          style={{ ...styles.flexCol, justifyContent: 'center', alignItems: 'center' }}
      >
          <p style={{ ...styles.description, width: '75%', fontWeight: 'bold' }}>
               This Carrier is a broker and 2 documents will be required for you to pay them on our platform as a Co-Broker.
          </p>
          <p style={{ ...styles.description, width: '75%', fontWeight: 'bold' }}>
              #1 - A signed Co-Brokering Agreement signed by both parties must be uploaded to the co-brokers invited carrier profile after you add them to your payment group.
          </p>
          <p style={{ ...styles.description, width: '75%', fontWeight: 'bold' }}>
              #2 - A Co-Brokering Acknowledgment from your Shipper will need to be uploaded to your Debtors profile in the Credit section.
          </p>
          <p style={{ ...styles.description, width: '65%', fontWeight: 'bold' }} className="text-danger">
             Two types of acknowledgment are acceptable.
          </p>
          <p style={{ ...styles.description, width: '65%' }} className="text-danger">
              Option #1 - A signed broker/shipper agreement that states you (the broker) has the
                        right  to utilize a co-brokering arrangement on freight you move for them.
          </p>
          <p style={{ ...styles.description, width: '65%' }} className="text-danger">
              Option #2 - An email from an authorized staff member (typically an AP manager)
                          stating you (the broker) have the right to utilize a co-brokering arrangement
                          on freight you move for them.
          </p>

              <button
                  className="btn btn-default"
                  style={{ maxWidth: '40%' }}
                  onClick={closeModal}>
                  Continue
              </button>
      </div>
  </div>
;

const InviteViaMCDOT = reduxForm({
  form: 'MC_DOT_INVITE_FORM',
  onSubmit: async (values, dispatch, { factoringId, terms }) => {
    try {
      if (isEmpty(values.carrier)) {
        throw 'Please select carrier';
      }
      const isRequiredCoBrokeringAgreement = ['BROKER_AUTH'].includes(get(values.carrier, 'authority', ''));
      if(isRequiredCoBrokeringAgreement) {
            await new Promise(resolve => {
                dispatch(openModal('EmptyModal', {
                    children: <CoBrokeringCheckPopUpView
                      closeModal={() => {
                          dispatch(closeModal());
                          resolve();
                      }}
                      />,
                  }));
            })
      }
      const res = await dispatch(createPaymentsProfiles({
        factoring_id: factoringId,
        payment_profile_id: values.carrier.id
      }))
      if (!values.carrier?.is_connected) {
        return dispatch(openModal('MatchedInvitedCarrier', {
          carrier: values.carrier,
          factoringId: factoringId,
          terms: terms,
          step: 'paymentSpeed',
          relationshipId: get(res, 'payment_profile.relationship_id', ''),
          paymentId: values.carrier?.id,
          carrierIsConnected: values.carrier?.isConnected,
          searchType: 'MC/DOT'
        }));
      }
      dispatch(openModal('EmptyModal', {
        children: <ValidationPopUpView
          closeModal={() => dispatch(closeModal())}
          isConnected={get(values, 'carrier.is_connected')}
          number={get(values, 'carrier.company_mc', get(values, 'carrier.company_dot'))}
        />,
      }));
      window.analytics.track(segmentEvents.BROKER_CONNECT_INVITED_CARRIER_MC_DOT_SEARCH);
      dispatch(reset('MC_DOT_INVITE_FORM'));
    } catch (e) {
      dispatch(openModal('error', { message: e }));
    }
  }
})(({ change, handleSubmit, isSubmitting, ...props }) => <div>
  <Field
    component={MCDOTAutoCompleteInput}
    name="carrier"
    setFieldValue={change}
    placeholder="Enter MC/DOT #"
  />
  <button
    style={{
      marginTop: '10px',
      float: 'right',
    }}
    disabled={isSubmitting}
    className='btn btn-green'
    onClick={handleSubmit}
  >
    Connect
  </button>
</div>)



const mapStateToProps = state => ({
  groups: state.resource.factoringGroups,
  brokerGroups: selectBrokerGroups(state),
  loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
  factoringId: get(state.resource, ['user', state.user.id , 'data', 'factoring_id']),
  terms: formattedPaymentTerms(getBrokerTerms(state)),
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(groups.fetch()),
  inviteToGroup: (...args) => dispatch(inviteToGroup(...args)),
  onSuccess: () => dispatch(openModal('success', { message: 'Invite(s) successfully sent.' })),
  onFail: message => dispatch(openModal('error', { message: message || 'Error sending invite(s).' })),
  fetchFactoringProfile: () => dispatch(fetchFactoringProfile()),
  getCarriers: factoringId => dispatch(getBrokerGroupCarriers(factoringId, { limit: 999 })),
  openConfirmModal: args => dispatch(openModal('confirm', args)),
  getGroupInvited: id => dispatch(getGroupInvited(id)),
  closeModal: () => dispatch(closeModal()),
  getPaymentProfile: (...args) => dispatch(getPaymentProfile(...args)),
  openMatchedInvitedCarrierModal: args => dispatch(openModal('MatchedInvitedCarrier', args)),
  fetchTerms: (...args) => dispatch(fetchTerms(...args)),
});

export const sortPaymentProfilesByStatus = carriers => {
  let results = [];
  results = filter(carriers, ['status', USER_FACTORING_STATUS.APPROVED]);
  if(isEmpty(results)) {
    results = filter(carriers, ['status', USER_FACTORING_STATUS.NEEDS_REVIEW]);
  }
  if (isEmpty(results)) {
    results = filter(carriers, ['status', USER_FACTORING_STATUS.PENDING]);
  }
  if (isEmpty(results)) {
    results = filter(carriers, ['status', USER_FACTORING_STATUS.DECLINED]);
  }
  return results;
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(class FactoringAddClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carriers: []
    }
    this.sendInvites = this.sendInvites.bind(this);
  }

  async componentDidMount() {
    try {
      await this.props.fetchFactoringProfile();
      const res = await this.props.getGroups();
      await this.props.fetchTerms(this.props.factoringId)
      const group = head(get(res, "payload.results"));
      const groupId = get(group, 'id');
      let invited = [];
      if (groupId) {
        invited = await this.props.getGroupInvited(groupId, { deleted: true });
      }
      const { results = [] } = await this.props.getCarriers(this.props.factoringId);
      this.setState({
        carriers: concat(results, invited)
      })
    } catch (e) {
      console.log("ERROR", e);
    }
  }

  async sendInvites(type, array = []) {
    if(type === "email") {
      try {
        const { results = [] } = await this.props.getPaymentProfile({
          limit: 20,
          matched_email: array
        });
        if(results.length) {
          this.props.openMatchedInvitedCarrierModal({
            invitedCarrier: sortPaymentProfilesByStatus(results),
            factoringId: this.props.factoringId,
            terms: this.props.terms,
          })
          return true;
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    }
    const group = head(this.props.brokerGroups);
    const id = get(group, 'data.id');
    await this.props.inviteToGroup({ groupId: id, ...(() => type === 'email' ? { emails: array, phoneNumbers: [] } : { phoneNumbers: array, emails: [] })() });
    window.analytics.track(type === 'email' ? segmentEvents.BROKER_INVITED_CARRIER_BY_EMAIL : segmentEvents.BROKER_INVITED_CARRIER_BY_SMS);
    return false;
  }

  render() {
    return (
      <Grid>
        <Row>
          <Location />
        </Row>
        <Row>
          <Col md={5}>
            <Row>
              <Header>HaulPay Carriers</Header>
              <small>See if your carrier has an account already setup
                search here using MC or dot #.</small>
            </Row>
            <Row
              style={{
                height: '50px',
              }}
            />
            <Row>
              <h4>Connect Via MC/DOT #</h4>
              <InviteViaMCDOT factoringId={this.props.factoringId} terms={this.props.terms} />
            </Row>
            <Row>
              <hr style={{ marginTop: 50 }} />
            </Row>
            <Row>
              <Header>Add a Carrier</Header>
              <p>Add a single carrier using the form below.</p>
            </Row>
            <Row
              style={{
                height: '50px',
              }}
            />
            <Row>
              <h4>Invite via Email address</h4>
              <InviteSingle
                type='email'
                sendInvites={this.sendInvites}
                onSuccess={this.props.onSuccess}
                onFail={this.props.onFail}
                carriers={this.state.carriers}
                openConfirmModal={this.props.openConfirmModal}
                closeModal={this.props.closeModal}
              />
            </Row>
            {false &&
              <Row>
                <h4>Invite via Text Message</h4>
                <InviteSingle
                  type='phone'
                  sendInvites={this.sendInvites}
                  onSuccess={this.props.onSuccess}
                  onFail={this.props.onFail}
                />
              </Row>
            }
          </Col>
          {/* <Col md={6} mdOffset={1}>
            <Row>
              <Header>Bulk Add</Header>
              <p>Add multiple carriers using the csv template below.</p>
            </Row>
            <Row
              style={{
                height: '100px',
              }}
            >
              <a href='/public/other/BulkAddCarrier.csv'>
                <Button
                  style={{
                    marginTop: '40px',
                  }}
                >
                  <Glyphicon glyph='glyphicon glyphicon-cloud-download' /> CSV template
                </Button>
              </a>
            </Row>
            <Row>
              <UploadClientList
                sendInvites={this.sendInvites}
                onSuccess={this.props.onSuccess}
                onFail={this.props.onFail}
              />
            </Row>
          </Col> */}
        </Row>
      </Grid>
    );
  }
});
