import React from 'react';
import styled from 'styled-components';
import Spinner from 'components/pure/Spinner';

export default styled(({ download_url = null, failed = false, preview_url = null, filename = null, isNew = false, loading, ...props }) => {
  if (!loading){
    return (
      <div>
        <img
          src={(download_url || preview_url) ? '/public/image/paperclip.png' : '/public/image/failed_upload.png'}
          role='button'
          {...props}
        />
        {(isNew && (download_url || preview_url)) && <span className='text-success' >New!</span>}
        {
          !(download_url || preview_url) && failed ? <div className='text-danger'>{filename} (Failed to upload!) </div>
            : <div>{filename}</div>
        }
      </div>
    );
  }
  return <Spinner style={{ fontSize: 5 }} />;
}
)`
  max-height: 2em;
  margin: 0.5em;
`;
