import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import Stripe from '../../stripe';
import { updateSubscription } from 'actions/user';
import { openModal } from 'actions/ui';


const stripeHander = Stripe({
  panelLabel: 'Update Billing Info',
});

export default compose(
  connect(
    null,
    dispatch => ({
      onClick() {
        return stripeHander.open({
          token(token) {
            return dispatch(updateSubscription(token.id))
              .then(() => {
                dispatch(openModal('success', { message: 'Successfully updated billing info.' }));
              })
              .catch(err => {
                console.warn(err);
                dispatch(openModal('error'), { message: 'We had a problem updating your billing info. Please try again later.' });
              })
            ;
          },
        });
      },
    }),
  ),
)(props =>
  <button
    {...props}
    className='btn btn-orange btn-block'
    style={{
      margin: '0.5em 0',
      ...props.style,
    }}
    >
    Update Billing Info
  </button>
);
