/* global EMPTY_PLACEHOLDER fbq process */
import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, reduxForm, formValueSelector } from 'redux-form';
import cs from 'classnames';
import { mapProps, withState } from 'recompose';
import { assign, cloneDeep, find, get, groupBy, includes, isNumber, isObject, map, pick, reduce } from 'lodash';
import { USER_TYPE, mapColumnsToBlockRows } from 'helpers';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';
import getSelfType from 'selectors/getSelfType';
import selectBrokerGroups from 'selectors/selectBrokerGroups';

import formatPennies from 'helpers/formatPennies';
import formatAddress from 'helpers/formatAddress';
import { openModal } from 'actions/ui';
import { edit, fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';

import groups from 'actions/admin/factoring/groups';

import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import TableItem from 'components/pure/TableItem';
import BlockTable from 'components/pure/BlockTable';
import Header from 'components/pure/Header';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import CarrierNameAutocompleteInput from 'components/pure/form/inputs/CarrierNameAutocompleteInput';
import TrailerInput from 'components/pure/form/inputs/TrailerInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import FactoringPaymentAttachments from 'components/pure/FactoringPaymentAttachments';
import Spinner from 'components/pure/Spinner';
import { getLineItemsByFundingRequest } from 'actions/lineitems';
import { closeModal } from 'actions/ui';
import moment from 'moment';
import Drawer from 'components/pure/Drawer';
import formatAddressFields from 'helpers/formatAddressFields';
import RaisedButton from 'material-ui/RaisedButton';
import MaterialIcon from 'components/pure/MaterialIcon';
import formatCategoriesAttachments from 'helpers/formatCategoriesAttachments';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import segmentEvents from '../../helpers/segmentEvents';

const styles = {
  flexContainer: {
    display: 'flex',
    marginTop: 10,
    marginBottom: 20,
  },
  alignRight: {
    justifyContent: 'flex-end',
  },
  labelStyle: {
    fontSize: '20',
    fontTransform: 'capitalize !important',
  },
};

const PlainInput = ({ input, meta = {}, ...rest }) =>
  <div
    className={cs('form-group', {
      'has-error': meta.touched && meta.error,
      'has-warning': meta.touched && meta.warning && !meta.error,
    })}
  >
    <input
      {...input}
      {...rest}
      className={cs('form-control', rest.className)}
    />
    {meta.touched && meta.error || meta.warning ? <div className='help-block'>{meta.error || meta.warning}</div> : null}
  </div>
  ;

const formatStatus = status => typeof status === 'string' ? status.replace(/_/g, ' ') : status;

export const categories = {
  FUEL_ADVANCES: 'Fuel Advance Purchase',
  FUEL_ADVANCE_FEE: 'Fuel Advance Fee',
  FUEL_ADVANCE_RATE: 'Fuel Advance Discount',
  DELIVERY_ADVANCE: 'Delivery Advance',
  CHARGES: 'Charges',
  CHARGESANDGIVESBACK: 'Givebacks/Chargesbacks',
  PAYMENT_FEE: 'Payment Fee',
  DISCOUNT_RATE: 'Discount Rate',
};
export const categoryAppliedBy = {
  CLIENT_PURCHASE: 'client_purchase',
  CLIENT: 'client',
  DEBTOR: 'debtor',
  ORGANIZATION: 'organization',
  CLIENT_RESERVE_NEGATIVE: 'client_reserve_negative',
  CLIENT_RESERVE_POSITIVE: 'client_reserve_positive',
};
export const categoryAppliedAgainst = {
  CLIENT: 'client',
  DEBTOR: 'debtor',
  ORGANIZATION: 'organization',
};

export const groupLineItems = data => {
  return groupBy(data, object => {
    if (!object) {
      return '';
    }

    const {
      category_applied_by = null,
      category_applied_against = null,
      category_name = null,
      related_line_item_data = {}
    } = object;

    if (
      includes(
        [categories.FUEL_ADVANCES, categories.FUEL_ADVANCE_FEE, categories.FUEL_ADVANCE_RATE],
        category_name
      ) ||
      (category_name === categories.PAYMENT_FEE &&
        get(related_line_item_data, 'category_name', '') === 'Fuel Advance Purchase')
    ) {
      return categories.FUEL_ADVANCES;
    } else if (category_applied_by === categoryAppliedBy.CLIENT_PURCHASE || category_name === categories.DISCOUNT_RATE) {
      return categories.DELIVERY_ADVANCE;
    } else if (
      includes([categoryAppliedBy.CLIENT, categoryAppliedBy.DEBTOR, categoryAppliedBy.ORGANIZATION], category_applied_by) &&
      includes([categoryAppliedAgainst.CLIENT, categoryAppliedAgainst.DEBTOR, categoryAppliedAgainst.ORGANIZATION], category_applied_against)
    ) {
      return categories.CHARGES;
    } else if (
      includes([categoryAppliedBy.CLIENT_RESERVE_NEGATIVE, categoryAppliedBy.CLIENT_RESERVE_POSITIVE], category_applied_by)
    ) {
      return categories.CHARGESANDGIVESBACK;
    }

    return category_name || '';
  });
};


export const groupLineItemsByCategories = data => {
  const groupByArray = groupLineItems(data);
  const fuelAdvanceGroup = groupByArray[categories.FUEL_ADVANCES];
  const deliveryAdvanceGroup = groupByArray[categories.DELIVERY_ADVANCE];
  if (isObject(groupByArray) && fuelAdvanceGroup) {
    const fuelAdvanceArray = [];
    let index = 0;
    map(groupByArray[categories.FUEL_ADVANCES], object => {
      if (object.category_name === categories.FUEL_ADVANCES) {
        // const fuel_advance_rate_object = find(fuelAdvanceGroup, values => values.related_line_item === object.id && values.category_name === categories.FUEL_ADVANCE_RATE);
        const fuel_advance_fee_object = find(fuelAdvanceGroup, values => values.related_line_item === object.id && values.category_name === categories.FUEL_ADVANCE_FEE);
        const paymentFee = find(fuelAdvanceGroup, values => values.related_line_item === object.id && values.category_name === categories.PAYMENT_FEE) || {};
        const fuel_advance_object = {
          // amount_approved_rate: fuel_advance_rate_object.amount_approved,
          // amount_request_rate: fuel_advance_rate_object.amount_requested,
          amount_approved_fee: fuel_advance_fee_object.amount_approved,
          amount_request_fee: fuel_advance_fee_object.amount_requested,
          payment_fuel_advance_fee: paymentFee.amount_approved,
          ...object,
        };
        fuelAdvanceArray[index] = fuel_advance_object;
        index += 1;
      }
    });
    groupByArray[categories.FUEL_ADVANCES] = fuelAdvanceArray;
  }
  groupByArray[categories.DELIVERY_ADVANCE] = [reduce(deliveryAdvanceGroup, (result, value) => {
    if (value.category_name === categories.PAYMENT_FEE) {
      result = {
        payment_fee_amount_requested: value.amount_requested,
        payment_fee_amount_amount_approved: value.amount_approved,
        ...result,
      };
    }
    if (value.category_name === categories.DISCOUNT_RATE) {
      result = {
        payment_discount_fee_amount_requested: value.amount_requested,
        payment_discount_fee_amount_approved: value.amount_approved,
        ...result,
      };
    }
    if (value.category_name === 'Delivery Advance') {
      result = {
        delivery_advance_amount_requested: value.amount_requested,
        delivery_advance_amount_approved: value.amount_approved,
        ...result,
      };
    }
    return {
      ...result,
      amount_requested: get(result, 'payment_discount_fee_amount_requested', 0) + get(result, 'payment_fee_amount_requested', 0) + get(result, 'delivery_advance_amount_requested', 0),
      amount_approved: get(result, 'delivery_advance_amount_requested', 0),
    };
  }, {})];
  return groupByArray;
};

const mappingStatus = key => ({
  [key]: key === 'new noa' ? 'pending noa' : key,
});
const TotalPayTitle = ({ status, amount, amount_funded, color }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <div
      style={{
        display: 'flex',
      }}
    >
      <h3>Total Pay: {amount_funded}</h3>
      <h3
        style={{
          // color: color,
          marginLeft: '16px',
          textTransform: 'capitalize',
        }}
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid'].includes(status),
          'text-warning': ['pending', 'pending_originals', 'pending originals', 'pending_noa'].includes(status),
          'text-red': ['declined', 'incomplete', 'document_issue'].includes(status),
          'text-blue': ['new', 'fuel_advance', 'fuel advance', 'new_noa', 'new noa'].includes(status),
        })}
      >
        {mappingStatus(status)[status]}
      </h3>
    </div>
    <div
      style={{
        minWidth: '150px',
        paddingLeft: 10,
      }}
    >
      <h3>{amount}</h3>
    </div>
  </div>
);

export const Title = ({ name, amount, amount_color }) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'space-between',
    }}
  >
    <h3>{name}</h3>
    <div
      style={{
        minWidth: '150px',
      }}
    >
      <h3
        style={{
          color: amount_color,
        }}
      >
        {amount}
      </h3>
    </div>
  </div>
);

export const DetailRow = ({ name, name_value, description, value, value_color, categoryName }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingTop: description ? '19px' : '13px',
      paddingBottom: description ? '19px' : '13px',
      paddingLeft: '64px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            minWidth: '150px',
          }}
        >
          <h4>{name}</h4>
        </div>
        <h4>{name_value}</h4>
      </div>
      <h4
        style={{
          minWidth: '200px',
          color: value_color || 'black',
        }}
      >
        {value}
      </h4>
    </div>
    {
      categoryName && (
        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
          }}
        >
          <div
            style={{
              minWidth: '150px',
            }}
          >
            <h4>Category Name</h4>
          </div>
          <h4>{categoryName}</h4>
        </div>
      )
    }
    {
      description && (
        <div
          style={{
            display: 'flex',
            paddingTop: '10px',
          }}
        >
          <div
            style={{
              minWidth: '150px',
            }}
          >
            <h4>Description</h4>
          </div>
          <h4>{description}</h4>
        </div>
      )
    }
  </div>
);


export const SmallDetailRow = ({ name, value, value_color }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      paddingLeft: '64px',
    }}
  >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <div
          style={{
            minWidth: '150px',
          }}
        >
          <h4>{name}</h4>
        </div>
      </div>
      <h4
        style={{
          minWidth: '200px',
          color: value_color || 'black',
        }}
      >
        {value}
      </h4>
    </div>
  </div>
);

const statusColor = status => {
  switch (status) {
    case 'new':
      return 'green';
    case 'paid':
      return 'green';
    default:
      return 'black';
  }
};

const FactoringPaymentTableClient = ({
  disableAll,
  item,
  isEditing,
  setIsEditing,
  handleSubmit,
  submitting,
  fetchInvoice,
  fetchCollated,
  fueladvance,
  givebackchargeback,
  otherfees,
  deliveryadvance,
  table,
  updateFactoringPayment,
}) => {
  return (
    <div
      style={{
        marginBottom: '160px',
      }}
    >
      {
        item.data.status === 'pending_delivery' &&
        <div className='col-xs-12' style={{ ...styles.flexContainer, ...styles.alignRight }}>
          <RaisedButton
            label='Mark As Complete '
            icon={<MaterialIcon name='check' size={25} style={{ color: '#ffffff' }} />}
            labelPosition='before'
            labelColor='#ffffff'
            backgroundColor='#4dc527'
            labelStyle={styles.labelStyle}
            onClick={() => updateFactoringPayment(item.data.id, { status: 'special_pending' })}
          />
        </div>
      }
      <Drawer
        title={
          <div>
            <div className='col-xs-12' style={styles.flexContainer}>
              <TotalPayTitle
                status={formatStatus(item.data.status)}
                amount={formatPennies(item.data.amount)}
                amount_funded={formatPennies(item.data.amount_funded)}
                color={statusColor(item.data.status)}
              />
            </div>
          </div>
        }
      >
        <Header>Payment Details</Header>
        {table}
      </Drawer>
      {givebackchargeback && (<Drawer title={<Title name='Givebacks/Chargebacks' amount={formatPennies(givebackchargeback.reduce((acc, curr) => acc + curr.amount_approved, 0))} amount_color={givebackchargeback.reduce((acc, curr) => acc + curr.amount_approved, 0) > 0 ? 'green' : 'red'} />}>
        <div>
          {givebackchargeback.map(charge => (
            <DetailRow
              name='Invoice Number'
              name_value={get(charge, 'related_line_item_data.funding_request_invoice_number', '') ? get(charge, 'related_line_item_data.funding_request_invoice_number', {}) : charge.funding_request_invoice_number}
              value={formatPennies(charge.amount_approved)}
              description={get(charge, 'related_line_item_data.description', '')}
              value_color={charge.amount_approved >= 0 ? 'green' : 'red'}
              categoryName={get(charge, 'related_line_item_data.category_name', '')}
            />
          ))}
        </div>
      </Drawer>)}
      {otherfees && (<Drawer
        title={
          <Title name='Other Fees' amount={formatPennies(otherfees.reduce((acc, curr) => acc + curr.amount_approved, 0))} amount_color={otherfees.reduce((acc, curr) => acc + curr.amount_approved, 0) >= 0 ? 'green' : 'red'} />
        }
      >
        <div>
          {otherfees.map(fee => (
            <DetailRow
              name={fee.category_name}
              value={formatPennies(fee.amount_approved)}
              value_color={fee.amount_approved >= 0 ? 'green' : 'red'}
            />
          ))}
        </div>
      </Drawer>)}
      <Drawer
        title={
          <Title name='Total Pay' amount={formatPennies(item.data.amount_funded)} amount_color={'black'} />
        }
      >
        <div>
          {fueladvance && (
            <div>
              <div
                style={{
                  marginLeft: '64px',
                  marginRight: '64px',
                }}
              >
                <Title name='Fuel Advance Details' amount={fueladvance.amount_requested} amount_color='black' />
              </div>
              {fueladvance.map(advance => (
                <div>
                  <DetailRow
                    name='Amount Requested'
                    value={formatPennies(advance.amount_requested)}
                    value_color='green'
                  />
                  {/*<DetailRow*/}
                  {/*  name='Fuel Advance Fee'*/}
                  {/*  value={`-${formatPennies(advance.amount_approved_fee)}`}*/}
                  {/*  value_color='red'*/}
                  {/*/>*/}
                  {advance.payment_fuel_advance_fee &&
                    <DetailRow
                      name='Payment Fee'
                      value={`${formatPennies(advance.payment_fuel_advance_fee)}`}
                      value_color='red'
                    />
                  }
                  <DetailRow
                    name='Paid At'
                    value={moment(advance.created).format('YYYY/MM/DD HH:SS a')}
                  />
                </div>
              ))}
            </div>
          )}
          {deliveryadvance && (
            <div>
              <div
                style={{
                  marginLeft: '64px',
                  marginRight: '64px',
                }}
              >
                <Title
                  name='Delivery Payment' amount={''} amount_color={''}
                />
              </div>
              <div>
                {deliveryadvance.map(advance => (
                  <div>
                    <SmallDetailRow
                      name='Amount Requested'
                      value={formatPennies(advance.amount_requested)}
                      value_color={advance.amount_requested >= 0 ? 'green' : 'red'}
                    />
                    <SmallDetailRow
                      name='Discount Rate Fee'
                      value={`-${formatPennies(advance.payment_discount_fee_amount_requested || 0)}`}
                      value_color='red'
                    />
                    {advance.payment_fee_amount_requested &&
                      <SmallDetailRow
                        name='Payment Fee'
                        value={`-${formatPennies(advance.payment_fee_amount_requested)}`}
                        value_color='red'
                      />
                    }
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Drawer>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingTop: '32px',
        }}
      >
        <div
          style={{
            color: '#0079b4',
          }}
        >
          <h4>
            Uploaded Documents
          </h4>
        </div>
        <div className='text-right'>
          {!isEditing ? (
            <FactoringPaymentAttachments
              label=''
              attachments={item.data.attachments}
              fetchInvoice={() => fetchInvoice(item.data.id)}
              fetchCollated={() => fetchCollated(item.data.id)}
            />
          ) : (
            <div>
              <FactoringPaymentAttachments
                label=''
                attachments={item.data.attachments}
                fetchInvoice={() => fetchInvoice(item.data.id)}
                fetchCollated={() => fetchCollated(item.data.id)}
              />
              <Field
                name='attachments'
                component={MultiImageInput}
                limit={Infinity}
                multiple={true}
                buttonText='Upload Documents'
                buttonClassName='btn btn-default'
                disabled={disableAll}
                shouldCrop={true}
                categories={[
                  'Signed Bill of Lading (Delivered)',
                  'Signed Bill of Lading (Pick-Up)',
                  'Rate Confirmation',
                  'Truck Order Not Used',
                  'Invoice',
                  'Freight Bill',
                  'Fuel Advance',
                  'Lumper Receipt',
                  'Detention Receipt',
                  'Late Fee Receipt',
                  'Other',
                ]}
                imageGridProps={{
                  columns: {
                    sm: 2,
                    md: 2,
                    lg: 2,
                  },
                }}
              />
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          borderTop: '1px solid #979797',
          paddingTop: '16px',
        }}
      >
        {
          isEditing ?
            <div className='clearfix'>
              <button className='btn btn-default' type='button' onClick={() => setIsEditing(false)}>Cancel</button>
              <button className='btn btn-orange pull-right' type='submit' onClick={handleSubmit} disabled={submitting}>Update</button>
            </div>
            :
            <div>
              <button className='btn btn-orange' type='button' onClick={() => setIsEditing(true)}>Edit</button>
            </div>
        }
      </div>
    </div>
  );
};

const FactoringPaymentBlockTableItemClient = ({ isEditing, disableAll, disablePayment, selectedDebtor }) => (({ headers, data }) => {
  return (
    <TableItem
      rows={mapColumnsToBlockRows(headers,
        isEditing ?
          [
            <Field name='invoiced_amount' component={PennyInput} disabled={disableAll} />,
            <Field name='invoice_number' component={PlainInput} disabled={true} />,
            <Field name='user_load_number' component={PlainInput} disabled={disableAll} />,
            <Field
              name='first_origin_location'
              component={GoogleLocationInput}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <Field
              name='final_destination_location'
              component={GoogleLocationInput}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <Field name='load_length' component={PlainInput} disabled={disableAll} />,
            <Field
              name='load_trailer_type'
              component={TrailerInput}
              label={null}
              extraTrailerTypes={[{
                value: 'Other',
                text: 'Other',
              }]}
              disabled={disableAll}
            />,
            <div>
              <Field name='debtor' component={DebtorAutocompleteInput} disabled={disableAll} />
              {[USER_FACTORING_STATUS.DECLINED,
                USER_FACTORING_STATUS.DECLINED_3_MONTHS,
                USER_FACTORING_STATUS.DECLINED_6_MONTHS].includes(selectedDebtor.credit_approved) &&
                  <span className='text-warning'>
                    Please note that this shipper/debtor has been declined on credit. As a result, only the attachments associated with Funding Request will be updated.
                  </span>
              }
            </div>,
            <Field
              name='bill_to_address'
              component={GoogleLocationInput}
              specificity={GoogleLocationInput.specificity.ADDRESS}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <div className='row'>
              <div className='col-md-12'>
                <Field name='bill_to_company_phone' component={PhoneInput} label='Phone' disabled={disableAll} />
              </div>
            </div>,
            data.created.format('l LTS'),
            data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
            data.invoice_date ? moment(data.invoice_date).format('l LTS') : EMPTY_PLACEHOLDER,
            data.discount_rate ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
            data.status_note ? data.status_note : EMPTY_PLACEHOLDER,
            <Field
              name='payment_method'
              component={LabeledDropdown}
              data={[
                { text: 'ACH', value: 'ach' },
                { text: 'Wire', value: 'wire' },
              ]}
              label={null}
              disabled={disablePayment}
            />,
          ]
          :
          [
            formatPennies(data.amount),
            data.invoice_number,
            data.user_load_number,
            formatAddress(data.first_origin_location),
            formatAddress(data.final_destination_location),
            data.load_length,
            data.load_trailer_type,
            data.debtor.dba,
            formatAddress(data.bill_to_address),
            <a href={`tel:${data.bill_to_company_phone}`}>{data.bill_to_company_phone}</a>,
            data.created.format('l LTS'),
            data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
            data.invoice_date ? moment(data.invoice_date).format('l LTS') : EMPTY_PLACEHOLDER,
            data.discount_rate ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
            data.status === 'pending_originals' ?
              <div>
                <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please contact your broker directly to get the correct address to mail your original documents to.</p>
                {/*<p>*/}
                {/*  ComFreight Financial<br />*/}
                {/*  1201 W. Russel St.,<br />*/}
                {/*  Sioux Falls, SD 57104*/}
                {/*</p>*/}
                <p>
                  Provide tracking information for your mailed originals to
                  <a href='mailto:invoice@haulpay.io' className='space'>invoice@haulpay.io</a>
                  so that we can process your transaction as quickly as possible, by knowing when your documents will arrive.
                </p>
                <p>{data.status_note}</p>
              </div>
              :
              data.status_note,
            data.payment_method ? data.payment_method.toUpperCase() : EMPTY_PLACEHOLDER,
          ]
      )}
    />
  );
});

const FactoringPaymentBlockTableItemBroker = ({ isEditing, disableAll, disablePayment }) => (({ form, setForm, headers, data, setIsEditing, handleSubmit, submitting, fetchInvoice, fetchCollated }) => {
  return (
    <TableItem
      rows={mapColumnsToBlockRows(headers,
        isEditing ?
          [
            <Field name='amount' component={PennyInput} disabled={disableAll} />,
            <Field name='carrier_amount' component={PennyInput} disabled={disableAll} />,
            <Field name='invoice_number' component={PlainInput} disabled={true} />,
            <Field name='user_load_number' component={PlainInput} type='number' disabled={disableAll} />,
            <Field
              name='first_origin_location'
              component={GoogleLocationInput}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <Field
              name='final_destination_location'
              component={GoogleLocationInput}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <Field name='load_length' component={PlainInput} disabled={disableAll} />,
            <Field
              name='load_trailer_type'
              component={TrailerInput}
              label={null}
              extraTrailerTypes={[{
                value: 'Other',
                text: 'Other',
              }]}
              disabled={disableAll}
            />,
            <Field name='carrier_name' component={CarrierNameAutocompleteInput} onSelect={value => setForm('factoring_id', value.carrier)} disabled={disableAll} />,
            <Field name='debtor_company_name' component={DebtorAutocompleteInput} onSelect={value => setForm('debtor', value.id)} disabled={disableAll} />,
            <Field
              name='bill_to_address'
              component={GoogleLocationInput}
              specificity={GoogleLocationInput.specificity.ADDRESS}
              inputProps={{ disabled: disableAll }}
              setValueWithoutConfirming={true}
            />,
            <div className='row'>
              <div className='col-md-12'>
                <Field name='bill_to_company_phone' component={PhoneInput} label='Phone' disabled={disableAll} />
              </div>
            </div>,
            data.created.format('l LTS'),
            data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
            isNumber(data.discount_broker) ? `${data.discount_broker}%` : EMPTY_PLACEHOLDER,
            isNumber(data.discount_carrier) ? `${data.discount_carrier}%` : EMPTY_PLACEHOLDER,
            isNumber(data.discount_rate) ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
            data.amount_funded ? formatPennies(data.amount_funded) : EMPTY_PLACEHOLDER,
            <b
              className={cs('text-uppercase', {
                'text-success': ['approved', 'paid'].includes(data.status),
                'text-warning': data.status === 'pending',
                'text-error': data.status === 'declined',
              })}
            >
              {data.status}
            </b>,
            data.status_note ? data.status_note : EMPTY_PLACEHOLDER,
            <Field
              name='payment_method'
              component={LabeledDropdown}
              data={[
                { text: 'ACH', value: 'ach' },
                { text: 'Wire', value: 'wire' },
              ]}
              label={null}
              disabled={disablePayment}
            />,
            <div className='text-right'>
              <FactoringPaymentAttachments
                label={null}
                attachments={data.attachments}
                fetchInvoice={() => fetchInvoice(data.id)}
                fetchCollated={() => fetchCollated(data.id)}
              />
              <Field
                name='attachments'
                component={MultiImageInput}
                limit={Infinity}
                multiple={true}
                buttonText='Upload Documents'
                buttonClassName='btn btn-default'
                disabled={disableAll}
                shouldCrop={true}
                categories={[
                  'Signed Bill of Lading (Delivered)',
                  'Signed Bill of Lading (Pick-Up)',
                  'Rate Confirmation',
                  'Truck Order Not Used',
                  'Invoice',
                  'Freight Bill',
                  'Fuel Advance',
                  'Lumper Receipt',
                  'Detention Receipt',
                  'Late Fee Receipt',
                  'Other',
                ]}
                imageGridProps={{
                  columns: {
                    sm: 2,
                    md: 2,
                    lg: 2,
                  },
                }}
              />
            </div>,
          ]
          :
          [
            formatPennies(data.amount),
            formatPennies(data.carrier_amount || get(data, 'client_invoice_amount', null)),
            data.invoice_number,
            data.user_load_number,
            formatAddress(data.first_origin_location),
            formatAddress(data.final_destination_location),
            data.load_length,
            data.load_trailer_type,
            get(data, 'factoring_company_profile.name', null) || get(data, 'payment_profile.company_name', null),
            data.debtor_company_name || get(data, 'debtor.dba', null),
            formatAddress(data.bill_to_address),
            <a href={`tel:${data.bill_to_company_phone}`}>{data.bill_to_company_phone}</a>,
            data.created.format('l LTS'),
            data.funded_at ? data.funded_at.format('l LTS') : EMPTY_PLACEHOLDER,
            isNumber(data.discount_broker) ? `${data.discount_broker}%` : EMPTY_PLACEHOLDER,
            isNumber(data.discount_carrier) ? `${data.discount_carrier}%` : EMPTY_PLACEHOLDER,
            isNumber(data.discount_rate) ? `${data.discount_rate}%` : EMPTY_PLACEHOLDER,
            data.amount_funded ? formatPennies(data.amount_funded) : EMPTY_PLACEHOLDER,
            <b
              className={cs('text-uppercase', {
                'text-success': ['approved', 'paid'].includes(data.status),
                'text-warning': data.status === 'pending',
                'text-error': data.status === 'declined',
              })}
            >
              {typeof data.status === 'string' ? data.status.replace(/_/g, ' ') : data.status}
            </b>,
            data.status === 'pending_originals' ?
              <div>
                <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please contact your broker directly to get the correct address to mail your original documents to.</p>
                {/*<p>*/}
                {/*  ComFreight Financial<br />*/}
                {/*  1201 W. Russel St.,<br />*/}
                {/*  Sioux Falls, SD 57104*/}
                {/*</p>*/}
                <p>
                  Provide tracking information for your mailed originals to
                  <a href='mailto:invoice@haulpay.io' className='space'>invoice@haulpay.io</a>
                  so that we can process your transaction as quickly as possible, by knowing when your documents will arrive.
                </p>
                <p>{data.status_note}</p>
              </div>
              :
              data.status_note,
            data.payment_method ? data.payment_method.toUpperCase() : EMPTY_PLACEHOLDER,
            <div className='text-right'>
              <FactoringPaymentAttachments
                label={null}
                attachments={data.attachments}
                fetchInvoice={() => fetchInvoice(data.id)}
                fetchCollated={() => fetchCollated(data.id)}
              />
            </div>,
          ]
      )}
      addons={[
        isEditing ?
          <div className='clearfix'>
            <button className='btn btn-default' type='button' onClick={() => setIsEditing(false)}>Cancel</button>
            <button
              className='btn btn-orange pull-right' type='submit' onClick={handleSubmit}
              disabled={submitting}
            >Update
            </button>
          </div>
          :
          <div>
            <button className='btn btn-orange' type='button' onClick={() => setIsEditing(true)}>Edit</button>
          </div>,
      ]}
    />
  );
});
export default compose(
  Resource('factoringpayment', {
    idPropName: ['params', 'id'],
  }),
  spinnerIfFetching(),
  mapProps(props => {
    const data = get(props, 'item.data', {});
    const status = get(data, 'status', '');
    return {
      ...props,
      disableAll: status === 'paid' || status === 'approved',
      disablePayment: !(status === 'new' || status === 'pending'),
      form: `FactoringPaymentEdit#${data.id}`,
      initialValues: {
        ...pick(data, [
          'invoiced_amount',
          'amount',
          'invoice_number',
          'user_load_number',
          'first_origin_location',
          'final_destination_location',
          'load_length',
          'load_trailer_type',
          'debtor',
          'bill_to_address',
          'payment_method',
          'carrier_amount',
          'bill_to_company_name',
        ]),
        bill_to_company_phone: getPhoneParts(data.bill_to_company_phone).phone,
        bill_to_company_phone_ext: getPhoneParts(data.bill_to_company_phone).ext,
        factoring: data.factoring_id,
        carrier_name: get(data, 'factoring_company_profile.name', ''),
        debtor_company_name: { dba: data.debtor_company_name },
      },
    };
  }),
  connect(
   (state, { form })=> {
      const selector = formValueSelector(form);
      return {
        groups: state.resource.factoringGroups,
        brokerGroups: selectBrokerGroups(state),
        loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
        userType: getSelfType(state),
        selectedDebtor: selector(state, 'debtor'),
    }
  },
    (dispatch, { form, item: { data: { id } } }) => ({
      fetchInvoice(...args) {
        try {
          return dispatch(fetchInvoice(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
        }
      },
      fetchCollated(...args) {
        try {
          return dispatch(fetchCollated(...args));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
        }
      },
      updateFactoringPayment: (id, data) => dispatch(edit(id, data)),
      getLineItemsByFundingRequest: id => dispatch(getLineItemsByFundingRequest(id)),
      getGroups: () => dispatch(groups.fetch()),
      setForm: (name, value) => dispatch(change(form, name, value)),
      async onSubmit(fields, dispatch, { initialValues }) {
        let newFields = cloneDeep(fields);
        if ((fields.attachments || []).length) {
          newFields = assign(newFields, { attachments: formatCategoriesAttachments(newFields.attachments) });
          window.analytics.track(segmentEvents.USER_UPLOAD_NEW_DOCUMENTS_FUNDING_REQUEST);
        }
        let data = {
          ...pick(newFields, [
            'invoiced_amount',
            'amount',
            'carrier_amount',
            'invoice_number',
            'user_reference_number',
            'bill_to_company_email',
            'load_length',
            'load_trailer_type',
            'amount_funded',
            'status',
            'status_note',
            'payment_method',
            'attachments',
            'user_load_number',
          ]),
          debtor: fields.debtor.id,
          // debtor: fields.debtor,
          first_origin_location: formatAddressFields(fields.first_origin_location),
          final_destination_location: formatAddressFields(fields.final_destination_location),
          bill_to_address: formatAddressFields(fields.bill_to_address),
          bill_to_company_phone: normalizeFormPhone(fields, 'bill_to_company_phone'),
        };
        const declinedDebtor = [USER_FACTORING_STATUS.DECLINED,
          USER_FACTORING_STATUS.DECLINED_3_MONTHS,
          USER_FACTORING_STATUS.DECLINED_6_MONTHS];
          if (declinedDebtor.includes(fields.debtor.credit_approved) && (fields.attachments || []).length) {
            await new Promise((resolve, reject) =>
                dispatch(
                  openModal('warning', {
                    message:
                      'Please note that this shipper/debtor has been declined on credit. As a result, only the attachments associated with Funding Request will be updated.',
                    customCloseModal: () => {
                      dispatch(closeModal());
                      resolve(true);
                    }
                  })
                )
              );
            data = pick(data, [
              'attachments',
            ])
          }

        return dispatch(edit(id, data))
          .then(() => {
            if (fields.invoiced_amount !== initialValues.invoiced_amount) {
              window.analytics.track(segmentEvents.BROKER_EDIT_FUNDING_REQUEST_INVOICE_AMOUNT);
            }
            dispatch(openModal('success', { message: 'Successfully edited transaction' }));
          })
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Error editing transaction' }));
          })
          ;
      },
    })
  ),
  reduxForm({}),
  withState('lineItems', 'setLineItems', []),
  withState('isEditing', 'setIsEditing', false),
)(class FactoringPayment extends Component {
  async componentDidMount() {
    const lineItems = await this.props.getLineItemsByFundingRequest(this.props.params.id);
    this.props.setLineItems(lineItems);
    const groups = await this.props.getGroups();
    const brokerGroup = get(groups, ['payload', 'results'], [])
      .filter(group => (group.type === 'haulpay_broker'));
  }

  render() {
    const { disablePayment, disableAll, item, lineItems, brokerGroups, loading, isEditing, setIsEditing, handleSubmit, submitting, fetchInvoice, fetchCollated, userType, updateFactoringPayment, selectedDebtor = {}} = this.props;
    console.log("DECLINED SELECTED DEBTOR", selectedDebtor)

    const grouped = groupLineItemsByCategories(lineItems);
    const fueladvance = grouped['Fuel Advance Purchase'];
    const givebackchargeback = grouped['Givebacks/Chargesbacks'];
    const otherFees = grouped.Charges;
    const deliveryadvance = grouped['Delivery Advance'];
    if (!loading) {
      if (USER_TYPE.BROKER === userType && brokerGroups.length > 0) {
        return (
          <FactoringPaymentTableClient
            disableAll={disableAll}
            item={item}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleSubmit={handleSubmit}
            submitting={submitting}
            fetchInvoice={fetchInvoice}
            fetchCollated={fetchCollated}
            fueladvance={fueladvance}
            givebackchargeback={givebackchargeback}
            otherfees={otherFees}
            deliveryadvance={deliveryadvance}
            updateFactoringPayment={updateFactoringPayment}
            table={<BlockTable
              headers={[
                'Shipper Invoice Amt.',
                'Carrier Invoice Amt.',
                'Your Invoice #',
                'Your Load #',
                'First Pick Up',
                'Final Delivery',
                'Length',
                'Type',
                'Carrier',
                'Shipper',
                'Shipper Address',
                'Shipper Phone',
                'Requested At',
                'Funded At',
                'Your Rate',
                'Carrier Rate',
                'Shipper Rate',
                'Amount Funded',
                'Funding Request Status',
                'Funding Request Status Notes',
                'Carrier Payment Method',
                'Uploaded Attachments',
              ]}
              items={[item]}
              ListItem={FactoringPaymentBlockTableItemBroker({ isEditing, disableAll, disablePayment })}
            />}
          />
        );
      }

      return (
        <FactoringPaymentTableClient
          disableAll={disableAll}
          item={item}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleSubmit={handleSubmit}
          submitting={submitting}
          fetchInvoice={fetchInvoice}
          fetchCollated={fetchCollated}
          fueladvance={fueladvance}
          givebackchargeback={givebackchargeback}
          otherfees={otherFees}
          deliveryadvance={deliveryadvance}
          updateFactoringPayment={updateFactoringPayment}
          table={<BlockTable
            headers={[
              'Invoice Amt.',
              'Your Invoice #',
              'Your Load #',
              'First Pick Up',
              'Final Delivery',
              'Length',
              'Type',
              'Bill To',
              'Bill To Company Address',
              'Bill to Company Phone',
              'Requested At',
              'Invoice At',
              'Funded At',
              'Rate',
              'Funding Request Status Notes',
              'Payment Method',
            ]}
            items={[item]}
            ListItem={FactoringPaymentBlockTableItemClient({ isEditing, disableAll, disablePayment, selectedDebtor })}
          />}
        />
      );
    }
    return <Spinner />;
  }
});
