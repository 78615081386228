import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm, reset } from 'redux-form';

import { create } from 'actions/resource/factoringdebtor';
import { openModal } from 'actions/ui';

import normalizeFormPhone from 'helpers/normalizeFormPhone';

import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import * as validate from 'helpers/validate';

export default compose(
  reduxForm({
    form: 'DebtorCreateForm',
    initialValues: {
      address: {},
    },
    validate(fields){
      const mc = fields.mc;
      const dot = fields.dot;
      const tax_id = fields.tax_id;
      const errs = {};
      if (!mc && !dot && !tax_id && !duns) {
        errs.mc = 'Please include a MC or DOT or Tax ID Number.';
        errs.dot = 'Please include a MC or DOT or Tax ID Number.';
        errs.tax_id = 'Please include a MC or DOT or Tax ID Number.';
        errs.duns = 'Please include a MC or DOT or Tax ID or DUNS Number.';
      }
      return errs;
    },
    onSubmit(fields, dispatch) {
      return dispatch(create({
        email: fields.email,
        name: fields.contact_name,
        company_name: fields.dba,
        address: typeof fields.address === 'string' ? { street_one: fields.address, street_two: fields.street_2 } : {
          street_one: fields.address.street_one,
          street_two: fields.street_2,
          city: fields.address.city,
          state: fields.address.state,
          zip: fields.address.zip,
          country: fields.address.country,
        },
        payment_status_email: fields.payment_status_email,
        phone_number: normalizeFormPhone(fields, 'contact_phone'),
        fax_number: normalizeFormPhone(fields, 'fax_number'),
        mc: fields.mc,
        dot: fields.dot,
        tax_id: fields.tax_id,
        discount_rate: fields.discount_rate,
        avgpay: fields.avgpay,
        credit_approved: fields.credit_approved,
        dtp_allowance: fields.dtp_allowance,
        requires_scanned_copy: fields.requires_scanned_copy,
      }))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully created debtor' }));
          dispatch(reset('DebtorCreateForm'));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Error creating debtor' }));
        })
      ;
    },
  }),
)(({ handleSubmit, submitting }) =>
  <CenteredColumn
    xs={12}
    md={9}
    >
    <Header>Debtor Profile Create</Header>
    <form onSubmit={handleSubmit} className='form-horizontal'>
      <Field name='email' component={LabeledInput} label='Email' horizontal={true} />
      <Field name='contact_name' component={LabeledInput} label='Contact Name' horizontal={true} />
      <Field name='dba' component={LabeledInput} label='Company' horizontal={true} />
      <Field
        name='address'
        component={GoogleLocationInput}
        placeholder=''
        label='Address'
        errorStyle={{ marginLeft: '17%' }}
        validate={validate.compose(
          validate.vjs.presence(),
          // validate.vjs.googleAddress(['street_one', 'zip', 'state'])
        )}
        horizontal={true}
        setValueWithoutConfirming={true}
      />
      <Field
        name='street_2'
        component={LabeledInput}
        placeholder=''
        label='Street Two'
        horizontal={true}
        setValueWithoutConfirming={true}
      />
      <div className='row'>
        <div className='col-xs-2' style={{ textAlign: 'right' }}>
          <label className='control-label'>Phone</label>
        </div>
        <div className='col-xs-4'>
          <Field name='contact_phone' component={PhoneInput} horizontal={true} />
        </div>
        <div className='col-xs-6'>
          <Field name='contact_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' horizontal={true} />
        </div>
      </div>
      <div className='row'>
        <div className='col-xs-2' style={{ textAlign: 'right' }}>
          <label className='control-label'>Fax</label>
        </div>
        <div className='col-xs-4'>
          <Field name='fax_number' component={PhoneInput} horizontal={true} />
        </div>
        <div className='col-xs-6'>
          <Field name='fax_number_ext' component={LabeledInput} label='Fax Ext.' type='number' horizontal={true} />
        </div>
      </div>
      <Field
        name='payment_status_email'
        component={LabeledInput}
        label='Payment Status Email'
        horizontal={true}
      />
      <Field
        name='mc' component={LabeledInput} errorStyle={{ marginLeft: '17%' }} validate={validate.compose(
          validate.vjs.length({
            maximum: 7,
          }),
        )} label='MC #' type='number' horizontal={true}
      />
      <Field name='dot' component={LabeledInput} errorStyle={{ marginLeft: '17%' }} label='DOT #' type='number' horizontal={true} />
      <Field
        name='tax_id'
        component={LabeledInput}
        errorStyle={{ marginLeft: '17%' }}
        validate={validate.compose(
          validate.vjs.length({
            tokenizer: value => {
              return value.replace('-', '');
            },
            is: 9,
          }),
        )}
        label='Tax ID #'
        horizontal={true}
      />
      <div className='row'>
        <div className='col-xs-2' style={{ textAlign: 'right' }}>
          <label className='control-label'>Aladdin ID</label>
        </div>
        <div className='col-xs-4'>
          <Field name='aladdin_id' component={LabeledInput} type='text' horizontal={true} />
        </div>
        <div className='col-xs-6'>
          <Field name='discount_rate' component={LabeledInput} label='Discount Rate' type='number' horizontal={true} />
        </div>
      </div>
      <Field name='avgpay' component={LabeledInput} type='text' label='Average Days To Pay' horizontal={true} />
      <Field
        name='credit_approved'
        component={LabeledDropdown}
        data={[
          { text: 'Pending', value: 'pending' },
          { text: 'Approved', value: 'approved' },
          { text: 'Declined', value: 'declined' },
        ]}
        label='Credit Approved Emblem'
        horizontal={true}
      />
      <Field
        name='dtp_allowance'
        component={LabeledDropdown}
        data={[
          { text: '15 Days', value: '15' },
          { text: '30 Days', value: '30' },
          { text: '45 Days', value: '45' },
          { text: '60 Days', value: '60' },
        ]}
        label='DTP Terms'
        horizontal={true}
      />
      <Field
        name='requires_scanned_copy'
        component={CheckboxInput}
        label='Requires Scanned Copy'
      />
      <div className='text-right'>
        <button className='btn btn-orange' disabled={submitting}>Create Debtor</button>
      </div>
    </form>
  </CenteredColumn>
);
