import React, { useEffect, useRef, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import { fundingRequestCalculations } from '../../actions/factoring';


export default options => {

    return WrappedComponent => function SelfFinanceCalculation(props) {
        const data = get(props, 'item.data', {});
        const [selfFinanceCalculationData, setSelfFinanceCalculationData] = useState({});
        function usePrevious(value, defaultValue) {
            const ref = useRef();
            useEffect(() => {
                ref.current = value;
            });
            return ref.current || defaultValue;
        }
        const prevData = usePrevious(data, {});
        useEffect(() => {
            const asyncFunction = async () => {
                try {
                    if (!isEmpty(data) && data.id !== prevData.id) {
                        if (data.self_finance_requested) {
                            const res = await props.dispatch(fundingRequestCalculations({
                                invoice_amount: data.amount,
                                debtor: data.debtor.id,
                                factoring_fee_split_rate: data.factoring_fee_split_rate,
                                payment_speed: parseInt(
                                    (data.payout_days.match(/\d+/g) || [])[0],
                                    0
                                ),
                                self_finance_requested: data.self_finance_requested,
                                payment_profile_invoice_amount: data.payment_profile_amount,
                                payment_profile_amount: data.payment_profile_amount,
                                payment_profile: data.payment_profile_id,
                                create_rates_revision: false
                            }));
                            setSelfFinanceCalculationData(res);
                        }
                    }
                } catch (e) {
                    console.log("SELF FINANCE ERROR", e)
                }
            }
            asyncFunction();
        }, [data])
        return (
            <WrappedComponent  {...props} selfFinanceCalculationData={selfFinanceCalculationData} />
        );
    }
};
