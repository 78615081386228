import { createAction } from 'redux-starter-kit';
import * as TYPES from '../types';
import API from '../API';

const updateVisibleTo = payload => async (
  dispatch,
  getState
) => {
  const { files } = getState().upload;
  const newFiles = files.slice(0);
  files[payload.position].visible_to = payload.visible_to;

  const file = files[payload.position];

  let response = {};
    response = await dispatch(API.updateAttachmentCategory(
      file.requestId,
      file.file_id,
      file.categories,
      payload.visible_to
    ));
  
  dispatch(
    createAction(TYPES.UPDATE_VISIBLE_TO)({
      ...payload,
      files: newFiles,
    })
  );
  return response;
};

export default updateVisibleTo;
