
const IMAGE_OUTPUT_TYPE = 'image/jpeg';

const generateName = () => Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 12);

const rotateImage = (file, direction) => new Promise((resolve, reject) => {
  const img = new Image;
  const objectURL = (file instanceof File || file instanceof Blob) ? URL.createObjectURL(file) : String(file);
  // img.crossOrigin = 'Anonymous'

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) {
      return reject('Error generating canvas context');
    }

    canvas.width = img.height;
    canvas.height = img.width;

    ctx.translate(canvas.width / 2, canvas.height / 2);
    if (direction === 'left') {
      ctx.rotate(270 * Math.PI / 180);
    }
    else {
      ctx.rotate(90 * Math.PI / 180);
    }
    ctx.drawImage(img, -img.width / 2, -img.height / 2);

    if (canvas.toBlob) {
      canvas.toBlob(blob => {
        URL.revokeObjectURL(objectURL);
        if (!blob) {
          return reject('Failed to convert image');
        }
        if (file.name) {
          (blob).name = file.name.replace(/(?:\.[^\.]+)?$/, '.jpg');
        }
        else {
          (blob).name = `${generateName()}.jpg`;
        }
        resolve(blob);
      }, IMAGE_OUTPUT_TYPE);
    }
    else {
      const imageData = canvas.toDataURL(IMAGE_OUTPUT_TYPE);
      URL.revokeObjectURL(objectURL);
      resolve(imageData);
    }
  };
  img.src = objectURL;
});

export default rotateImage;
