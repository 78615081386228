/**
 * Actions relating to the Load resource
 * @module actions/resource/load
 * @since 3.0.0
 * @requires actions/ui
 * @requires datatypes/ControlledResource
 * @requires datatypes/Point
 * @requires datatypes/PermissionsError
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/SavedSetting
 * @requires datatypes/APIError
 */
/* global API */
import moment from 'moment';
import omit from 'lodash/omit';

import { setScrollToItem } from 'actions/ui';
import ControlledResource from 'datatypes/ControlledResource';
import Point from 'datatypes/Point';
import PermissionsError from 'datatypes/PermissionsError';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import SavedSetting from 'datatypes/SavedSetting';
import APIError from 'datatypes/error/APIError';


export const loadViewedHistory = SavedSetting.Set('LOAD_VIEWED_HISTORY');
export const loadOpenHistory = SavedSetting.Set('LOAD_OPEN_HISTORY');

/**
 * The Resource that handles Load parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class LoadResource extends ControlledResource {

  constructor(name, options) {
    super(name, options);

    const upperName = this.name.toUpperCase();
    this.actions = {
      ...this.actions,
      CREATE_BULK_REQUEST: Symbol(`${upperName}_CREATE_BULK_REQUEST`),
      CREATE_BULK_REQUEST_SUCCESS: Symbol(`${upperName}_CREATE_BULK_REQUEST_SUCCESS`),
      CREATE_BULK_REQUEST_FAILURE: Symbol(`${upperName}_CREATE_BULK_REQUEST_FAILURE`),
    };

    this.createBulk = this.createBulk.bind(this);
  }

  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return {
      isFetching: false,
      globals: {
        ...this.options.globals,
        viewed: loadViewedHistory.has(json.id),
        open: loadOpenHistory.has(json.id),
      },
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: {
        ...(oldValue ? oldValue.data : undefined),
        ...json,
        deadhead: typeof json.deadhead === 'number' ? json.deadhead | 0 : null,
        weight: !json.weight || json.weight === 1 ? 0 : json.weight,
        length: !json.length || json.length === 1 ? 0 : json.length,
        origin_coords: Point.fromGeoPoint(json.origin_coords),
        destination_coords: Point.fromGeoPoint(json.destination_coords),
        time_start: moment(json.time_start),
        time_end: moment(json.time_end),
        time_delivery: json.time_delivery ? moment(json.time_delivery) : null,
        time_posted: moment(json.time_posted),
        ...(() => {
          const user = {
            data: json.user,
            err: null,
          };
          if (!json.user) {
            user.data = {};
            user.err = new PermissionsError('not allowed');
          }
          return { user };
        })(),
      },
    };
  }

  createBulk(data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, null);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.CREATE_BULK_REQUEST,
        payload: undefined,
      });

      return dispatch(APIFetch(`${API.host}/loads/bulk/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== 201 ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.CREATE_BULK_REQUEST_FAILURE,
            payload: err,
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.CREATE_BULK_REQUEST_SUCCESS,
          payload: json,
        }))
        ; // eslint-disable-line indent
    };
  }

  reduce(state, action) {
    state = super.reduce(state, action);
    switch (action.type) {
      case this.actions.CREATE_BULK_REQUEST:
        return {
          ...state,
          [null]: {
            ...this.parse(),
            isFetching: true,
          },
        };
      case this.actions.CREATE_BULK_REQUEST_SUCCESS:
        return {
          ...omit(state, 'null'),
          ...action.payload.map(item => ({
            ...this.parse(state.null, item),
            isFetching: false,
            err: null,
          })).reduce((acc, curr) => {
            acc[curr.data.id] = curr;
            return acc;
          }, {}),
        };
      case this.actions.CREATE_BULK_REQUEST_FAILURE:
        return {
          ...state,
          [null]: {
            ...this.parse(),
            isFetching: false,
            err: action.payload,
          },
        };
    }
    return state;
  }

}


/**
 * Singleton for our LoadResource
 */
export const loadResource = new LoadResource('load', {
  url: id => `loads/${id}`,
  globals: {
    open: false,
    viewed: false,
  },
});

export default loadResource;
export const {
  fetch,
  fetchIfNeeded,
  create,
  createBulk,
  edit,
  doDelete,
  getItem,
  clear,
} = loadResource;


/**
 * Set load viewed action. Marks the load with `id` as viewed. Not saved anywhere but in memory, is ephemeral
 * @event LOAD_SET_VIEWED
 * @property {symbol} type - Symbol(LOAD_SET_VIEWED)
 * @property {string} payload - the id of the load to be marked as viewed
 */
export const LOAD_SET_VIEWED = Symbol('LOAD_SET_VIEWED');
/**
 * Set load viewed action
 * @param {string} id - the id of the load to be marked as viewed
 * @fires LOAD_SET_VIEWED
 */
export const setViewed = id => dispatch => {
  loadViewedHistory.set(id);
  dispatch(setScrollToItem(id));
  return dispatch({
    type: LOAD_SET_VIEWED,
    payload: id,
  });
};
/**
 * Toggle load 'open' action. Toggles the load with `id` as open or not open. Some different views use this. Saved only in memory
 * @event LOAD_TOGGLE_OPEN
 * @property {symbol} type - Symbol(LOAD_TOGGLE_OPEN)
 * @property {string} payload - the id of the load to be toggled
 */
export const LOAD_TOGGLE_OPEN = Symbol('LOAD_TOGGLE_OPEN');
/**
 * Toggle load 'open' action.
 * @property {string} id - the id of the load to be toggled
 * @fires LOAD_TOGGLE_OPEN
 */
export const toggleOpen = id => dispatch => {
  loadOpenHistory.toggle(id);
  loadViewedHistory.set(id);
  return dispatch({
    type: LOAD_TOGGLE_OPEN,
    payload: id,
  });
};

export const LOAD_UPLOAD_EXCEL = Symbol('LOAD_UPLOAD_EXCEL');
export const uploadExcel = file => async (dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  formData.append('file', file);
  try {
    const res = await global.fetch(`${API.host}/load/post/upload/`, {
      headers: {
        authorization: `bearer ${state.user.token}`,
      },
      method: 'POST',
      body: formData,
    });
    if (res.status !== 201) {
      throw new APIError(res.status, await res.text());
    }
    const json = await res.json();
    dispatch({
      type: LOAD_UPLOAD_EXCEL,
      payload: json,
    });
  }
  catch (err) {
    throw err;
  }
};

export const LOAD_REFRESH = Symbol('LOAD_REFRESH');
export const refreshLoad = id => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/loads/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      refresh_post: true,
    }),
  });
  if (res.status !== 200) {
    throw new APIError(res.status, await res.text());
  }
  const json = await res.json();
  dispatch({
    type: LOAD_REFRESH,
    payload: {
      id,
      json,
    },
  });
};
export const UPDATE_LOAD_WITH_PAYMENT_REQUEST = 'UPDATE_LOAD_WITH_PAYMENT_REQUEST';
export const updateLoadWithPaymentRequest = data => async dispatch => {
  dispatch({
    type: UPDATE_LOAD_WITH_PAYMENT_REQUEST,
    payload: data,
  });
};
