/**
 * Reducer for handling the geocode resource actions
 * @module reducers/resource/geocode
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/geocode
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.geocode;

import geocodeResource from '../../actions/resource/geocode';


export default function geocodeResourceReducer(state = initialState, action) {
  state = geocodeResource.reduce(state, action);
  return state;
}
