/**
 * Component that displays a centered column topped with navigation tabs
 * @module components/pure/SettingsContainer
 * @since 3.0.0
 * @property {object} props
 * @property {object} props.location
 * @property {object} props.location.pathname
 * @property {node|node[]} props.children
 * @property {string[][]} props.subRoutes
 */
import path from 'path-browserify';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';
import cs from 'classnames';

import CenteredColumn from 'components/pure/CenteredColumn';


const SettingsContainer = ({ location, children, subRoutes, columnProps }) =>
  <CenteredColumn
    xs={12}
    md={9}
    {...columnProps}
    >
    <ul className='nav nav-tabs' style={{ marginTop: '1em' }}>
      {subRoutes.map(([route, title]) =>
        <li key={route} className={cs({ active: location.pathname.endsWith(route) })}>
          <Link to={path.resolve(location.pathname, `../${route}`)}>{title}</Link>
        </li>
      )}
    </ul>
    <div style={{ height: 'auto' }}>
      {children}
    </div>
  </CenteredColumn>
  ;

SettingsContainer.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  children: PropTypes.element.isRequired,
  subRoutes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default SettingsContainer;
