/**
 * Component that wraps GoogleMap and adds a button to load more items. Not actually paginated
 * @module components/pure/PaginatedGoogleMap
 * @since 3.0.0
 * @requires components/pure/GoogleMap
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {object} props.amount - the amount of items already fetched
 * @property {function} props.fetchNext
 * @property {boolean} [props.isFetching]
 * @property {boolean} [props.hasNext] - whether this resource has more to load or not
 * @property {object} props.containerProps
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import GoogleMap from 'components/pure/GoogleMap';
import Spinner from 'components/pure/Spinner';


const PaginatedGoogleMap = ({ amount, hasNext, fetchNext, isFetching, containerProps = {}, ...rest }) =>
  <div {...containerProps} className={cs('col-fill', containerProps.className)}>
    <div style={{ flexGrow: 0 }}>
      <span style={{ display: 'inline-block', margin: 0, verticalAlign: 'middle' }} className='help-block'>Viewing {amount}</span>
      <button style={{ margin: '0.5em' }} className='btn btn-default' disabled={isFetching || !hasNext} onClick={fetchNext}>Show More</button>
      {isFetching ?
        <Spinner
          style={{
            display: 'inline-block',
            marginLeft: '5em',
            marginBottom: '0',
            verticalAlign: 'middle',
            transform: 'translateY(-100%)',
          }}
        />
        : null
      }
    </div>
    <GoogleMap {...rest} />
  </div>
  ;

PaginatedGoogleMap.propTypes = {
  amount: PropTypes.number,
  total: PropTypes.number,
  fetchNext: PropTypes.func,
  isFetching: PropTypes.bool,
  containerProps: PropTypes.object,
};

export default PaginatedGoogleMap;
