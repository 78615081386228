/**
 * Reducer for handling the truck resource actions
 * @module reducers/resource/truck
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/truck
 * @requires actions/resourceBy/user/truck
 * @requires actions/resourceBy/user/truckhistory
 * @listens QA_CREATE_A_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.qa;

import moment from 'moment';

import qaResource, { QA_CREATE_A_SUCCESS } from '../../actions/resource/qa';
import qaByTruckResource from '../../actions/resourceBy/truck/qa';
import qaByLoadResource from '../../actions/resourceBy/load/qa';


export default function qaResourceReducer(state = initialState, action) {
  state = qaResource.reduce(state, action);
  if (qaByTruckResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...qaResource.parseMany(state, action.payload.results),
    };
  }
  if (qaByTruckResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: qaResource.parse(state[action.payload.id], action.payload),
    };
  }
  if (qaByLoadResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...qaResource.parseMany(state, action.payload.results),
    };
  }
  if (qaByLoadResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: qaResource.parse(state[action.payload.id], action.payload),
    };
  }
  switch (action.type) {
    case QA_CREATE_A_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: {
            ...state[action.payload.id].data,
            answer_set: [
              ...state[action.payload.id].data.answer_set,
              {
                ...action.payload.json,
                time_posted: moment(action.payload.json.time_posted),
              },
            ],
          },
        },
      };
  }
  return state;
}
