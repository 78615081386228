import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import mapProps from 'recompose/mapProps';

import nothingIf from 'components/hoc/nothingIf';


export default compose(
  connect(state => ({ logged_in: state.user.logged_in })),
  nothingIf(({ logged_in }) => !logged_in),
  mapProps(({ logged_in, dispatch, ...rest }) => rest),
)(props =>
  <Link to='/settings/billing/subscription'>
    <button className='btn btn-orange' {...props}>Upgrade to View</button>
  </Link>
);
