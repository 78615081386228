import React from 'react';

import FactoringPaymentAttachment from './FactoringPaymentAttachment';
import LoadAttachmentButton from './LoadAttachmentButton';

const FactoringPaymentAttachments = ({
  label = 'Uploaded Attachments',
  attachments,
  lastDocIssueDate,
  deleteAttachment,
  fetchInvoice,
  fetchCollated,
  onCropClick,
}) =>
  <div>
    {
      label ?
        <label className='control-label'>{label}</label>
        : null
    }
    {attachments.map(attachment =>
      <FactoringPaymentAttachment
        attachment={attachment}
        lastDocIssueDate={lastDocIssueDate}
        deleteAttachment={typeof deleteAttachment === 'function' ? () => deleteAttachment(attachment.id) : undefined}
        onCropClick={onCropClick}
      />
    )}
    {
      fetchCollated &&
      <div>
        <label className='control-label'>Collated Billing PDF</label>
        <div>
          <LoadAttachmentButton getURL={fetchCollated} />
        </div>
      </div>
    }

    <div>
      <label className='control-label'>Invoice</label>
      <div>
        <LoadAttachmentButton getURL={fetchInvoice} />
      </div>
    </div>
  </div>
;

export default FactoringPaymentAttachments;
