import React from 'react';
import { Col, Row } from 'antd';
import { Select, Input, Button, CollapsedFilterPanel } from '@haulpay/ui';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';

const searchBy = [
    {
        text: 'Phone number',
        value: 'phone_number',
        title: 'Phone number',
    },
    {
        text: 'Email',
        value: 'email',
        title: 'Email',
    },
];
function Filters(props) {
  return (
      <Col span={24}>
          <CollapsedFilterPanel>
              <Row justify="end" gutter={[8, 8]}>
                  <Col>
                      <Input
                          allowClear
                          placeholder="Search For"
                          onChange={props.handleSearchByFilter}
                      />
                  </Col>
                  <Col>
                      <Select
                          defaultValue={searchBy[1].value}
                          style={{ minWidth: 220 }}
                          optionProps={searchBy}
                          onChange={props.handleStatusFilter}
                      />
                  </Col>
                  <Col>
                      <Button
                          icon={
                              !props.search ? (
                                  <SearchOutlined />
                              ) : (
                                  <CloseOutlined />
                              )
                          }
                          onClick={
                              !props.search
                                  ? props.handleSubmitFilter
                                  : props.handleResetFilter
                          }
                          loading={props.loading}>
                          Search
                          </Button>
                  </Col>
              </Row>
          </CollapsedFilterPanel>
      </Col>
  );
}
export default Filters;