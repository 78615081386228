import 'styles/TimelineItem';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Link } from 'react-router-v4';
import cs from 'classnames';

import MaterialIcon from 'components/pure/MaterialIcon';
import ImageIcon from 'components/pure/ImageIcon';
import { USER_TYPE } from '../../helpers';


// the headers for the timeline item
const  alert_type_map = {
  my_load_bid_received: 'New Bid on Your Load',
  my_load_truck_match: 'Truck Match to Your Load',
  my_load_bid_awarded: 'You Awarded a Bid on Your Load',
  my_truck_load_match: 'Load Match to Your Truck',
  shipper_alert: 'New Shipper Load Posted',
  my_bid_awarded: 'You Have Been Awarded Your Bid',
  bid_placed: 'You Placed a Bid',
  bid_retracted: 'You Retracted a Bid',
  my_load_bid_retracted: 'A Bid on Your Load Has Been Retracted',
  underbid_alert: 'You Have Been Under-Bid',
  my_load_question: 'New Question on Your Load',
  my_truck_question: 'New Question on Your Truck',
  my_question_answer_load: 'New Response to Your Question',
  my_question_answer_truck: 'New Response to Your Question',
  welcome_to_comfreight: 'Welcome to HaulPay!',

  factoring_welcome: 'Want the fastest payments on all of your hauls?',
  factoring_application_pending: 'Haul Pay Factoring Application: SUCCESS',
  factoring_application_approved: 'APPROVED for Haul Pay Factoring!',
  factoring_application_denied: 'Haul Pay Factoring Application Not Approved',

  factoring_linked_debtor_approved: 'New Customer Credit Approval',
  factoring_linked_debtor_declined: 'New Customer Credit Declined',
  factoring_linked_debtor_needs_review: 'New Customer Credit Needs Review',

  load_post_trip_update_rate_confirmed: 'New Status For Load Set to Rate Confirmed',
  load_post_trip_update_dispatched: 'New Status For Load Set to Dispatched',
  load_post_trip_update_picking_up: 'New Status For Load Set to Picking Up',
  load_post_trip_update_in_transit: 'New Status For Load Set to In Transit',
  load_post_trip_update_delivering: 'New Status For Load Set to Delivering',
  load_post_trip_update_completed: 'New Status For Load Set to Completed',
  load_post_trip_update_cancelled: 'New Status For Load Set to Cancelled',

  factoring_funding_request_paid: 'Invoice Payment Request Status: PAID',
  factoring_funding_request_declined: 'Invoice Payment Request DECLINED',
  factoring_funding_request_approved: 'Your Payment Request is APPROVED',
  factoring_funding_request_load_delivered: 'Load Delivered: Invoicing Pending',
  factoring_funding_request_pending_originals: 'Payment Status: Pending Originals',
  factoring_funding_request_document_issue: 'ATTENTION: Document Issue on Payment Request',
  factoring_funding_request_created: 'Your Payment Request is Received',
  factoring_funding_request_incomplete: 'Your recent funding request is incomplete. Tap here to finish it',
  factoring_funding_request_carrier_paid: 'Invoice Payment Request Status: Carrier Paid',
  factoring_funding_request_debtor_paid: 'Invoice payment request Status: Invoice Paid',
  assigned_to_load: 'New load has been assigned',
  assigned_load_paid: 'Assigned Load Status: PAID',
  assigned_load_declined: 'Assigned Load DECLINED',
};

const get_icon_for_alert_type = alert_type => {
  switch (alert_type) {
    // truck icon
    case 'my_load_bid_received':
    case 'my_load_truck_match':
    case 'welcome_to_comfreight':
      return <ImageIcon src='/public/image/icon/truck.png' />;
    // smoke stacks
    case 'shipper_alert':
      return <ImageIcon src='/public/image/icon/shipper_smokestacks.png' />;
    // load icon
    case 'my_load_bid_awarded':
    case 'my_truck_load_match':
    case 'my_bid_awarded':
      return <ImageIcon src='/public/image/icon/palleticon.png' />;
    // message box
    case 'bid_placed':
    case 'bid_retracted':
    case 'my_load_bid_retracted':
    case 'underbid_alert':
    case 'my_load_question':
    case 'my_truck_question':
    case 'my_question_answer_truck':
    case 'my_question_answer_load':
      return <MaterialIcon name='comment' />;
    // money sign
    case 'factoring_welcome':
      return <MaterialIcon name='attach_money' />;
    // yellow money sign
    case 'factoring_application_pending':
    case 'factoring_linked_debtor_needs_review':
      return <MaterialIcon name='attach_money' className='text-warning' />;
    // green money sign
    case 'factoring_application_approved':
    case 'factoring_linked_debtor_approved':
    case 'factoring_funding_request_paid':
    case 'factoring_funding_request_debtor_paid':
    case 'factoring_funding_request_carrier_paid':
    case 'factoring_funding_request_approved':
      return <MaterialIcon name='attach_money' className='text-success' />;
    // red crossed-through money sign
    case 'factoring_application_denied':
    case 'factoring_linked_debtor_declined':
    case 'factoring_funding_request_declined':
      return <MaterialIcon name='money_off' className='text-danger' />;
    // yellow crossed-through money sign
    case 'factoring_funding_request_document_issue':
      return <MaterialIcon name='money_off' className='text-warning' />;
    // clipboard
    case 'load_post_trip_update_rate_confirmed':
      return <MaterialIcon name='assignment' />;
    // clipboard with check
    case 'load_post_trip_update_delivering':
      return <MaterialIcon name='assignment_turned_in' />;
    // compass
    case 'load_post_trip_update_dispatched':
      return <MaterialIcon name='explore' />;
    // mailbox
    case 'load_post_trip_update_picking_up':
      return <MaterialIcon name='markunread_mailbox' />;
    // box with arrow entering
    case 'load_post_trip_update_in_transit':
      return <MaterialIcon name='input' />;
    // 2 check marks
    case 'factoring_funding_request_load_delivered':
    case 'load_post_trip_update_completed':
      return <MaterialIcon name='done_all' />;
    // X in grey circle
    case 'load_post_trip_update_cancelled':
      return <MaterialIcon name='cancel' />;
    // a file
    case 'factoring_funding_request_pending_originals':
      return <MaterialIcon name='description' />;
    // an exclamation mark
    case 'factoring_funding_request_created':
    case 'assigned_to_load':
      return <MaterialIcon name='create_new_folder' className='text-warning' />;
    case 'factoring_funding_request_incomplete':
      return <MaterialIcon name='report' className='text-warning' />;
    case 'assigned_load_paid':
      return <MaterialIcon name='create_new_folder' className='text-success' />;
    case 'assigned_load_declined':
      return <MaterialIcon name='create_new_folder' className='text-danger' />;
    // PHOTO PLUS
    default:
      return <MaterialIcon name='priority_high' />;
  }
};


const TimelineItem = ({
  id,
  time_posted,
  message,
  alert_type,
  item_type,
  item_id,
  sub_item_type,
  sub_item_id,
  user,
  viewed = false,
  icon_size = 50,
  onDeleteClick,
  modified,
  viewed_on,
  load_id,
  factoring_id,
  user_id,
  funding_request_id,
  bid_id,
  created_at,
  modified_at,
  _id,
  userType,
  ...rest
}) =>
  <Link
    to={(() => {
      switch (alert_type) {
        case 'welcome_to_comfreight':
          return '/';
        case 'factoring_welcome':
        case 'factoring_application_pending':
        case 'factoring_application_denied':
          return '/haul-pay';
        case 'factoring_linked_debtor_approved':
        case 'factoring_linked_debtor_declined':
        case 'factoring_linked_debtor_needs_review':
          return '/haul-pay/profile/creditcheck';
        case 'factoring_funding_request_paid':
        case 'factoring_funding_request_declined':
        case 'factoring_funding_request_approved':
        case 'factoring_funding_request_pending_originals':
        case 'factoring_funding_request_document_issue':
        case 'factoring_funding_request_created':
        case 'factoring_funding_request_incomplete':
        case 'factoring_funding_request_debtor_paid':
          return userType === USER_TYPE.BROKER ? `/haul-pay/${funding_request_id}/transaction/` : `/haul-pay/getpaid/history/${funding_request_id}`;
        case 'factoring_funding_request_load_delivered':
          return `/load/${load_id}`;
        case 'factoring_application_approved':
          return '/haul-pay/getpaid/request';
        case 'load_post_trip_update_rate_confirmed':
        case 'load_post_trip_update_dispatched':
        case 'load_post_trip_update_picking_up':
        case 'load_post_trip_update_in_transit':
        case 'load_post_trip_update_delivering':
        case 'load_post_trip_update_completed':
        case 'load_post_trip_update_cancelled':
          return `/load/${load_id}`;
        case 'assigned_to_load':
        case 'assigned_load_paid':
        case 'assigned_load_declined':
          return `/haul-pay/getpaid/${funding_request_id}/assigned-loads`;
        default:
          return `/${item_type}/${item_id}${sub_item_type ? `#${sub_item_type}-${sub_item_id}` : ''}`;
      }
    })()}
    >
    <div className={cs('TimelineItem', { viewed })} {...rest}>
      <div>
        <div className='pull-right'>
          <MaterialIcon name='close' onClick={onDeleteClick} size={24} />
        </div>
        <div className='TimelineItem__date'>
          {
            moment().subtract(2, 'day').isAfter(time_posted)
              ?
              time_posted.format('dddd l [at] LT')
              :
              moment().subtract(1, 'day').isAfter(time_posted)
                ?
                time_posted.format('[Yesterday at] LT')
                :
                time_posted.fromNow()
          }
        </div>
        <div className='pull-left' style={{ height: icon_size, width: icon_size, fontSize: icon_size }}>
          {get_icon_for_alert_type(alert_type)}
        </div>
        <div className='TimelineItem__message' style={{ marginLeft: icon_size }}>
          <div>
            {alert_type_map[alert_type] || 'New Alert'}
          </div>
          {message}
        </div>
      </div>
    </div>
  </Link>
  ;

TimelineItem.propTypes = {
  time_posted: PropTypes.instanceOf(moment).isRequired,
  message: PropTypes.node.isRequired,
  alert_type: PropTypes.oneOf(Object.keys(alert_type_map)),
  item_type: PropTypes.oneOf(['truck', 'load']),
  item_id: PropTypes.string,
  icon_size: PropTypes.number,
};

export default TimelineItem;
