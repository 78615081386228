import React from 'react';


const replaceNonDigits = str => str.replace(/[^\d\.]/g, '');

export default ({ onChange, onBlur, onFocus, ...rest }) =>
  <input
    {...rest}
    type='text'
    onChange={e => {
      e.target.value = replaceNonDigits(e.target.value);
      return onChange(e);
    }}
    onBlur={e => {
      e.target.value = replaceNonDigits(e.target.value);
      return onBlur(e);
    }}
    onFocus={e => {
      e.target.value = replaceNonDigits(e.target.value);
      return onFocus(e);
    }}
  />
;
