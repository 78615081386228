import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import moment from 'moment';
import validate from 'validate.js';

import states from 'helpers/states';
import normalizeNumber from 'helpers/normalizeNumber';
import spinnerIf from '../../hoc/spinnerIf';

import ButtonToggleInput from './inputs/ButtonToggleInput';
import GoogleLocationInput from './inputs/GoogleLocationInput';
import LabeledInput from './inputs/LabeledInput';
import MultiStateInput from './inputs/StateInput';
import { MultiTrailerInput, trailerTypes } from './inputs/TrailerInput';
import DateTimeInput from './inputs/DateTimeInput';
import ReduxFormFieldErrors from '../../container/ReduxFormFieldErrors';
import GrowableInput from './inputs/GrowableInput';
import ButtonCurrentLocation from '../../pure/ButtonCurrentLocation';


export const FORM_NAME = 'TruckPost';

export const TruckPostForm = ({ searchBy, change }) =>
  <div className='light-placeholder'>
    <Field name='searchBy' component={ButtonToggleInput} data={[{ text: 'By City', value: 'city' }, { text: 'By State', value: 'state' }]} />
    <div>
      {
        searchBy === 'city' ?
          <div>
            <div className='inline-form-control-block'>
              <div className='row-2col spacer-xs clearfix external-error-message'>
                <div className='col-xs-10' style={{ maxWidth: 'calc(100% - 4em)', minWidth: 'calc(100% - 8em)' }}>
                  <Field
                    name='origin_location'
                    component={GoogleLocationInput}
                    specificity={GoogleLocationInput.specificity.REGION}
                    label={
                      <span>
                        {'Origin\u00a0\u00a0'}
                        <ButtonCurrentLocation
                          component='a'
                          role='button'
                          onClick={place => {
                            change('origin_location', {
                              city: place.city,
                              state: place.state,
                              formatted_address: `${place.city}, ${place.state}`,
                            });
                          }}
                          >
                        Current Location
                        </ButtonCurrentLocation>
                      </span>
                    }
                    placeholder='Origin'
                    style={{ marginBottom: 0 }}
                  />
                </div>
                <div className='col-xs-2' style={{ maxWidth: '8em', minWidth: '4em' }}>
                  <Field name='origin_radius' component={LabeledInput} label='Radius' placeholder='200' type='number' />
                </div>
              </div>
              <ReduxFormFieldErrors form={FORM_NAME} fields={['origin_location', 'origin_radius']} />
            </div>
            <div className='inline-form-control-block'>
              <div className='row-2col spacer-xs clearfix external-error-message'>
                <div className='col-xs-10' style={{ maxWidth: 'calc(100% - 4em)', minWidth: 'calc(100% - 8em)' }}>
                  <Field
                    name='destination_location'
                    component={GoogleLocationInput}
                    specificity={GoogleLocationInput.specificity.REGION}
                    label='Destination'
                    style={{ marginBottom: 0 }}
                  />
                </div>
                <div className='col-xs-2' style={{ maxWidth: '8em', minWidth: '4em' }}>
                  <Field
                    name='destination_radius'
                    component={LabeledInput}
                    label='Radius'
                    style={{ marginBottom: 0 }}
                    placeholder='200'
                    type='number'
                    normalize={val => {
                      if (val === '') {
                        return null;
                      }
                      return val;
                    }}
                  />
                </div>
              </div>
              <ReduxFormFieldErrors form={FORM_NAME} fields={['destination_location', 'destination_radius']} />
            </div>
          </div>
          :
          <div className='inline-form-control-block'>
            <Field
              name='origin_region'
              component={MultiStateInput}
              label={
                <span>
                  {'Origin\u00a0\u00a0'}
                  <ButtonCurrentLocation
                    component='a'
                    role='button'
                    onClick={place => {
                      change('origin_region', [{
                        fullName: states[place.state],
                        code: place.state,
                      }]);
                    }}
                    >
                  Current State
                  </ButtonCurrentLocation>
                </span>
              }
              style={{ marginBottom: '1em' }}
            />
            <Field name='destination_region' component={MultiStateInput} label='Destination' />
          </div>
      }
    </div>
    <Field name='trailerTypes' component={MultiTrailerInput} label='Equipment Type' />
    <div className='row-2col spacer-xs clearfix'>
      <div className='col-xs-4 col-md-4'>
        <Field name='weight' component={LabeledInput} type='number' placeholder='45000' label='Weight (lbs.)' />
      </div>
      <div className='col-xs-4 col-md-4'>
        <Field name='length' component={LabeledInput} type='number' placeholder='53' label='Length (ft.)' />
      </div>
      <div className='col-xs-4 col-md-4'>
        <Field
          name='targetRate'
          component={LabeledInput}
          label='Rate Per Mile'
          type='number'
          normalize={normalizeNumber}
        />
      </div>
    </div>
    <Field
      name='time_start'
      component={DateTimeInput}
      label='Ready Start'
      pickerOptions={{ time: false }}
    />
    <Field
      name='time_end'
      component={DateTimeInput}
      label='Ready End'
      pickerOptions={{ time: false }}
    />
    <Field
      name='details'
      component={GrowableInput}
      props={{
        Component: LabeledInput,
        ComponentClass: 'textarea',
        placeholder: 'Details',
      }}
    />
  </div>
  ;

TruckPostForm.propTypes = {
  searchBy: PropTypes.string,
};

const DEFAULT_INITIAL_VALUES = {
  searchBy: 'city',
  origin_location: {},
  origin_region: [],
  destination_location: {},
  destination_region: [],
  trailerTypes: [],
  time_start: moment(),
  time_end: moment().add(1, 'days'),
};
export const setDefaultInitialValues = obj => {
  Object.assign(DEFAULT_INITIAL_VALUES, obj);
};
const rolloverTimes = () => {
  DEFAULT_INITIAL_VALUES.time_start = moment();
  DEFAULT_INITIAL_VALUES.time_end = moment().add(1, 'days');
  setTimeout(rolloverTimes, moment().endOf('day').diff(moment()));
};
rolloverTimes();

const selector = formValueSelector(FORM_NAME);
const mapStateToProps = state => ({
  searchBy: selector(state, 'searchBy'),
  editId: state.post.truck.editId,
  initialValues: (() => {
    const { editId, copyId } = state.post.truck;
    let item;
    if (editId === undefined) {
      item = state.resource.truck[copyId];
    }
    else if (copyId === undefined) {
      item = state.resource.truck[editId];
    }
    if (item === undefined || item.data === undefined || item.err !== null) {
      return DEFAULT_INITIAL_VALUES;
    }
    return {
      ...(() => item.data.origin_city ? {
        searchBy: 'city',
      } : {
        searchBy: 'state',
      })(),
      origin_radius: item.data.origin_radius,
      destination_radius: item.data.destination_radius,
      origin_region: item.data.origin_region.map(code => MultiStateInput.data.find(state => state.code === code)),
      destination_region: item.data.destination_region.map(code => MultiStateInput.data.find(state => state.code === code)),
      origin_location: {
        city: item.data.origin_city,
        state: item.data.origin_region[0],
      },
      destination_location: {
        city: item.data.destination_city,
        state: item.data.destination_region[0],
      },
      trailerTypes: item.data.trailerTypes.map(tt => trailerTypes.find(t => t.value === tt)).filter(tt => tt !== undefined),
      weight: item.data.weight,
      length: item.data.length,
      targetRate: item.data.targetRate ? item.data.targetRate / 100 : undefined,
      time_start: item.data.time_start,
      time_end: item.data.time_end,
      details: item.data.details,
    };
  })(),
});

const commonValidateConstraints = {
  targetRate: {
    numericality: {
      greaterThanOrEqualTo: 0.01,
      lessThanOrEqualTo: 9.99,
    },
  },
  weight: {
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1000,
      notGreaterThanOrEqualTo: 'Weight must be greater than or equal to 1000 (lbs) pounds.',
    },
  },
  time_end: {
    presence: true,
    creldatetime: {
      earliest: 'time_start',
    },
  },
};

const typeValidateConstraints = {
  state: {
    origin_region: {
      alength: {
        minimum: 1,
        tooFew: '^Must include at least 1 state',
      },
    },
  },
  city: {
    origin_location: {
      multipresence: ['city', 'state'],
    },
    destination_location: {
      multipresenceifdefined: ['city', 'state'],
    },
    origin_radius: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 5,
        lessThanOrEqualTo: 500,
      },
    },
    destination_radius: {
      numericality: {
        onlyInteger: true,
        greaterThanOrEqualTo: 5,
        lessThanOrEqualTo: 500,
      },
    },
  },
};

export const createValidateHandler = fields => {
  const errs = validate(fields, {
    ...typeValidateConstraints[fields.searchBy],
    ...commonValidateConstraints,
    time_start: {
      presence: true,
      cdatetime: {
        earliest: moment().startOf('day'),
      },
    },
  });
  return errs;
};

export const editValidateHandler = fields => validate(fields, {
  ...typeValidateConstraints[fields.searchBy],
  ...commonValidateConstraints,
});

export default compose(
  connect(mapStateToProps, null, null, { withRef: true }),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    validate: createValidateHandler,
  }),
  spinnerIf(props => props.editId === 'loading'),
)(TruckPostForm);
