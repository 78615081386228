/**
 * Component that displays the order summary on the subscription page
 * @module components/pure/OrderSummary
 * @since 3.0.0
 * @requires helpers
 * @property {object} props
 * @property {object} props.title
 * @property {node|node[]} props.details
 * @property {number} props.stripePrice - actual price that will be sent to price, to be formatted and displayed here
 * @property {node} props.period
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';

import formatPennies from 'helpers/formatPennies';


const OrderSummary = ({ title, details, price, stripePrice, period, note, plan_name, balloon, dispatch, ...rest }) =>
  <div {...rest}>
    <ul className='list-group'>
      <li className='list-group-item'>Order Summary</li>
      <li className='list-group-item'>
        <div className='clearfix'>
          <div style={{ display: 'inline-block' }}>
            <div>{title}</div>
            {Array.isArray(details) ?
              details.map((details, i) => <div key={i} className='help-block' style={{ marginBottom: 0 }}>{details}</div>)
              :
              <div className='help-block' style={{ marginBottom: 0 }}>{details}</div>
            }
          </div>
          <div className='pull-right' style={{ textAlign: 'right' }}>
            <div>{formatPennies(stripePrice)}</div>
            <div>{period}</div>
          </div>
        </div>
        <div className='divider' />
        <div className='clearfix'>
          Today's Charge
          <div className='pull-right'>
            {formatPennies(stripePrice)}
          </div>
        </div>
        <div className='divider' />
        <div style={{ textAlign: 'center' }}>
          <div>Charges auto-renew</div>
          <div className='help-block'>(unless cancelled in settings before the next renewal date)</div>
          <div className='text-danger'>You can cancel your subscription in settings at any time.</div>
          {
            plan_name.endsWith('yearly') ?
              <div>We'll remind you before the period ends.</div>
              : null
          }
          <img src='/public/image/icon/cc icons.png' />
          <div>All amounts shown in <b>U.S. dollars</b></div>
        </div>
      </li>
    </ul>
  </div>
  ;

export default OrderSummary;
