import React, { useCallback } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import mapProps from 'recompose/mapProps';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Header, Table } from '@haulpay/ui';
import { Col, Row } from 'antd';
import Filters from './Components/Filters';
import {
    getGroupInvited,
    getBrokerGroupCarriers,
    getNextBrokerGroupCarriers,
    updatePaymentsProfile,
} from 'actions/groups';
import groups from 'actions/admin/factoring/groups';
import { fetchTerms, updateFactoringCarrier } from 'actions/factoring';
import { edit, fetch } from 'actions/resource/factoringclient';
import { inviteToGroup } from 'actions/invites';
import { openModal } from 'actions/ui';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import getSelf from 'selectors/getSelf';
import getBrokerTerms from 'selectors/getBrokerTerms';
import columns from './columns';
import useDidMountHook from '../../../hoc/useDidMountHook';
import { concat } from 'lodash';

function MyCarriers(props) {

    const [carriers, setCarriers] = React.useState({
        count: 0,
        next: null,
        data: [],
        results: [],
    });
    const [isFetching, setIsFetching] = React.useState(false);

    useDidMountHook(async function asyncFetch() {
        setIsFetching(true);
        const { self: { data = {} }, getCarriers, getGroups, fetchTerms, fetchFactoringProfile } = props;
        try {
            await fetchFactoringProfile(data?.factoring_id);
            await fetchTerms(data?.factoring_id);
            const res = await getCarriers(data?.factoring_id);
            await getGroups();

            setCarriers({
                count: res.count,
                data: res.results,
                next: res.next,
                results: res.results,
            });
        }
        catch (e) {
            console.error(e);
        }
        setIsFetching(false);
    });

    const fetchNext = useCallback(async () => {
        const { next, data, results } = carriers;
        if (!next) {
            return ;
        }
        const { nextCarriers } = props;
        setIsFetching(true);
        try {
            const res = await nextCarriers(next);
            setCarriers({
                data: concat(data, res.results),
                count: res.count,
                next: res.next,
                results: concat(results, res.results)
            });
        }
        catch (e) {

        }
        setIsFetching(false);
    }, [carriers]);
    return (
        <div style={{ margin: 25 }}>
            <Row gutter={[8, 8]}>
                <Col span={12}>
                    <Header size="xxlarge" weight="semibold">
                        {`My Carriers (${carriers.count})`}
                    </Header>
                </Col>
                <Col span={12}>
                    <Row justify="end">
                        <Button icon={<PlusOutlined />}>Add New</Button>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Filters />
            </Row>
            <Row>
                <Col span={24}>
                    <Table
                        pagination={false}
                        bordered
                        striped
                        loading={isFetching}
                        size="small"
                        columns={columns}
                        dataSource={carriers?.data}
                        infinityScroll={true}
                        rowKey={(data) => data.id}
                        fetchNext={fetchNext}
                        next={carriers?.next}
                    />
                </Col>
            </Row>
        </div>
    );
}

const mapStateToProps = (state) => {
    const self = getSelf(state);
    const factoring = get(
        state,
        `resource.factoringclient[${self.data.factoring_id}].data`,
        {}
    );
    return {
        factoring,
        groups: state.resource.factoringGroups,
        brokerGroups: selectBrokerGroups(state),
        loading:
            state.admin.factoring.groups.isFetching ||
            state.admin.factoringGroupUsers.isFetching,
        self,
        terms: getBrokerTerms(state),
    };
};

const mapDispatchToProps = (dispatch) => ({
    updateFactoringProfile: (...data) => dispatch(edit(...data)),
    fetchFactoringProfile: (...data) => dispatch(fetch(...data)),
    getGroupInvited: (id) => dispatch(getGroupInvited(id)),
    getCarriers: (factoring_id, filters = {}) =>
        dispatch(getBrokerGroupCarriers(factoring_id, filters)),
    nextCarriers: (url) => dispatch(getNextBrokerGroupCarriers(url)),
    getGroups: () => dispatch(groups.fetch()),
    openBrokerSettings: () =>
        dispatch(openModal('BrokerDefaultSettings', { modalSize: 'large' })),
    fetchTerms: (...args) => dispatch(fetchTerms(...args)),
    updateDiscountRate: async (id, discountRate) => {
        try {
            if (String(discountRate).trim() === '') {
                throw Error('Invalid discount rate');
            }
            await dispatch(
                updateFactoringCarrier(id, { discount_rate: discountRate })
            );
            dispatch(
                openModal('success', {
                    message: 'Successfully updated discount rate.',
                })
            );
        } catch (err) {
            dispatch(
                openModal('error', {
                    message: err.message || 'Error updating discount rate.',
                })
            );
        }
    },
    inviteToGroup: async (groupId, emails) => {
        try {
            await dispatch(inviteToGroup(groupId, emails));
            dispatch(
                openModal('success', {
                    message: 'Invite(s) successfully sent.',
                })
            );
        } catch (err) {
            dispatch(
                openModal('error', { message: 'Error sending invite(s).' })
            );
        }
    },
    destroy: (...args) => dispatch(destroy(...args)),
});

const mergeProps = propsFromState => ({
    ...propsFromState,
    formattedItems: (propsFromState.terms || []).filter(term => term.enable_factoring).map(term => {
        if (term.payee === 'factoring_company') {
            return;
        }
        let label = `${term.payout_days}`;
        let value = String(term.payout_days);

        if (value === '0') {
            value = 'ach_1_day';
        }
        else {
            value = `ach_${term.payout_days}_day`;
        }

        if (label === '0') {
            label = '1 Day';
        }
        else {
            label += ' Days';
        }

        if ((term.discount_rate || term.carrier_rate) === 0) {
            label += ' (Free)';
        }
        else {
            label += ` (${term.discount_rate || term.carrier_rate}% Quick Pay Fee)`;
        }

        return {
            id: term.id,
            value,
            text: label,
            label,
            is_default: term.is_default,
            rate: term.carrier_rate,
            splitFee: term.factoring_fee_split_rate,
            discountRate: term.discount_rate,
        };
    }),
    factoringCompanyPaymentTerm: find(propsFromState.terms, term => term.enable_factoring && (term.payee === 'factoring_company')),
});

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    mapProps(mergeProps)
)(MyCarriers);
