/**
 * Component that wraps ResponsiveContainer for tables, displaying a BlockTable on mobile sizes and a normal Table or SortableTable on desktop sizes
 * @module components/pure/ResponsiveTable
 * @since 3.0.0
 * @requires components/pure/ResponsiveContainer
 * @requires components/pure/Table
 * @requires components/pure/SortableTable
 * @requires components/pure/BlockTable
 * @property {object} props
 * @property {object} props.containerProps - props to apply to the ResponsiveContainer
 * @property {object} props.mobileContainerProps - props to apply to the BlockTable
 * @property {object} props.desktopContainerProps - props to apply to the SortableTable/Table
 * @property {boolean} [props.sortable = false] - whether to use a SortableTable (true) or a Table (false)
 * @property {element} props.TableItem - the ListItem to pass to the SortableTable/Table
 * @property {element} props.BlockTableItem - the ListItem to pass to the BlockTable
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';

import ResponsiveContainer from 'components/pure/ResponsiveContainer';
import Table from 'components/pure/Table';
import SortableTable from 'components/pure/SortableTable';
import BlockTable from 'components/pure/BlockTable';


const ResponsiveTable = ({ containerProps, mobileContainerProps, desktopContainerProps = {}, sortable = false, TableItem, BlockTableItem, ...rest }) => {
  let DesktopTable, blockTableHeaders;
  if (sortable) {
    blockTableHeaders = rest.headers.map(header => header.text);
    DesktopTable = SortableTable;
  }
  else {
    blockTableHeaders = rest.headers;
    DesktopTable = Table;
  }

  return (
    <ResponsiveContainer
      {...containerProps}
      mobile={
        <BlockTable
          {...mobileContainerProps}
          {...rest}
          headers={blockTableHeaders}
          ListItem={BlockTableItem}
        />
      }
      desktop={
        <DesktopTable
          {...desktopContainerProps}
          style={{ height: '100%', ...desktopContainerProps.style }}
          {...rest}
          ListItem={TableItem}
        />
      }
    />
  );
};

ResponsiveTable.propTypes = {
  containerProps: PropTypes.object,
  mobileContainerProps: PropTypes.object,
  desktopContainerProps: PropTypes.object,
};

export default ResponsiveTable;
