import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import branch from 'recompose/branch';

import formatPennies from 'helpers/formatPennies';
import resourceProperty from 'components/hoc/resourceProperty';
import nothingIf from 'components/hoc/nothingIf';


const UserTableTargetRate = compose(
  connect(
    (state, { id }) => {
      const item = state.resource.loadsearch[id];
      return { item };
    },
    {},
  ),
  branch(
    ({ item }) => item,
    resourceProperty('loadsearch', 'targetRate'),
    resourceProperty('load', 'targetRate'),
  ),
  nothingIf(({ targetRate }) => !targetRate),
)(({ targetRate, ...rest }) =>
  <div {...rest}>
    Target Rate: {formatPennies(targetRate)}
  </div>
);

export default UserTableTargetRate;
