import React from 'react';
import { compose } from 'redux-v3';
import withState from 'recompose/withState';
import { Field, reduxForm, change, touch } from 'redux-form';
import validateJS from 'validate.js';
import has from 'lodash/has';
import get from 'lodash/get';
import { getDataFromDOT, getDataFromMC } from 'actions/user';
import { checkExistingPaymentProfile } from 'actions/groups';
import DebtorCardSmall from 'components/pure/DebtorCardSmall';
import MaterialIcon from 'components/pure/MaterialIcon';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import LabeledInput from './inputs/LabeledInput';
import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';
import GoogleLocationInput from './inputs/GoogleLocationInput';
import { isEmpty } from 'lodash';

const CarrierCardSmall = ({ carrier, icon, ...rest }) => (
  <div {...rest}>
    {icon}
    <div
      className="pac-item-query"
      style={{
        display: 'inline-block',
        paddingLeft: 6,
        width: 'calc(100% - 22px)',
        lineHeight: 'normal',
        marginTop: 3,
        whiteSpace: 'normal'
      }}
    >
      <div>
        <p>{`${get(carrier, 'owner_name_first', null)} ${get(
          carrier,
          'owner_name_last',
          null
        )}`}</p>
      </div>
      <div>
        <p>{get(carrier, 'company_name', null)}</p>
      </div>
      <div style={{ fontSize: '0.9em' }}>
        {[
          [
            get(carrier, 'company_mc', null)
              ? `MC# ${get(carrier, 'company_mc', null)}`
              : null,
            get(carrier, 'company_dot', null)
              ? `DOT# ${get(carrier, 'company_dot', null)}`
              : null
          ]
            .filter(Boolean)
            .join(', ')
        ]
          .filter(Boolean)
          .map((val, i) => (
            <div key={i}>{val}</div>
          ))}
      </div>
      <div><p>{get(carrier, 'owner_email', null) ? get(carrier, 'owner_email', null) : null}</p></div>
    </div>
  </div>
);

const CreatePaymentProfileForm = ({
  phoneNumber,
  email,
  paymentProfiles,
  factoringId,
  closeModal,
  setPaymentId,
  setStep,
  openModal,
  createBrokerCarrierConnection,
  invitedUserId,
  setRelationshipId,
  ...props
}) => (
  <div className="light-placeholder">
    <MCorDOTInputs
      containerProps={{ style: { marginTop: '1em' } }}
      placeholder=""
    />
    {paymentProfiles.length > 0 &&
      <div>
        <p className="text-danger text-center">
          A carrier payment profile with this MC or DOT already exists - Please select to connect
         </p>
        {paymentProfiles.map(paymentProfile => (
          <CarrierCardSmall
            role="button"
            className="pac-item"
            icon={
              <MaterialIcon
                size={20}
                name="account_circle"
                style={{ verticalAlign: 'top', color: '#21bb00' }}
              />
            }
            carrier={paymentProfile}
            style={{ paddingBottom: 10, paddingTop: 10 }}
            onClick={async () => {
              try {
                const res = await createBrokerCarrierConnection({
                  factoring_id: factoringId,
                  payment_profile_id: paymentProfile.id,
                  invited_user_id: invitedUserId
                });
                setRelationshipId(get(res, 'payment_profile.relationship_id'));
                setPaymentId(paymentProfile.id);
                setStep('paymentSpeed');
              } catch (e) {
                console.log("ERROR", e);
                closeModal();
                openModal('error', {
                  message: 'Error while creating payment profile.'
                });
              }
            }}
          />
        ))}
      </div>
    }
    <Field
      name="owner_email"
      component={LabeledInput}
      label={<p>Carrier Email</p>}
      placeholder=""
      disabled={email}
    />
    <Field
      name="owner_name_first"
      component={LabeledInput}
      label={<p>First Name</p>}
      placeholder=""
    />
    <Field
      name="owner_name_last"
      component={LabeledInput}
      label={<p>Last Name</p>}
      placeholder=""
    />
    <Field
      name="owner_phone_number_cell"
      component={PhoneInput}
      label={<p>Carrier Phone</p>}
      placeholder=""
      disabled={phoneNumber}
    />
    <Field
      name="company_name"
      component={LabeledInput}
      label={<p>Company Name</p>}
      placeholder=""
    />
    <Field
      name="address"
      component={GoogleLocationInput}
      label={<p>Company Address</p>}
      setValueWithoutConfirming={true}
      specificity={GoogleLocationInput.specificity.ADDRESS}
      placeholder=""
    />
  </div>
);

const FORM_NAME = 'create_payment_profile';
export default compose(
  withState('paymentProfiles', 'setPaymentProfiles', []),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    enableReinitialize: true,
    asyncBlurFields: ['dot', 'mc'],
    async asyncValidate(
      fields,
      dispatch,
      { setPaymentProfiles },
      blurredField
    ) {
      const fillForm = (data = {}) => {
        if (!has(data, 'search_query')) {
          dispatch(change(FORM_NAME, 'company_name', data.dba || data.name));
          dispatch(change(FORM_NAME, 'owner_phone_number_cell', data.phone));
          dispatch(
            change(FORM_NAME, 'address', {
              street_one: data.street_one,
              city: data.city,
              state: data.state,
              zip: data.zip,
              country: data.country
            })
          );
          dispatch(touch(FORM_NAME, 'address'));
        }
      };
      switch (blurredField) {
        case 'dot':
          setPaymentProfiles([]);
          if (!fields.dot) {
            return;
          }
          try {
            const res = await dispatch(
              checkExistingPaymentProfile(fields.mc, fields.dot, {
                details: 'extended'
              })
            );
            if (res.exists) {
              return setPaymentProfiles(res.payment_profiles);
            }
            const data = await getDataFromDOT(fields.dot);
            fillForm(data);
          } catch (e) {
            console.warn(e);
          }
          return;
        case 'mc':
          setPaymentProfiles([]);
          if (!fields.mc) {
            return;
          }
          try {
            const res = await dispatch(
              checkExistingPaymentProfile(fields.mc, fields.dot, {
                details: 'extended'
              })
            );
            if (res.exists) {
              return setPaymentProfiles(res.payment_profiles);
            }
            const data = await getDataFromMC(fields.mc);
            fillForm(data);
          } catch (e) {
            console.warn(e);
          }
          return;
      }
    },
    validate: fields => {
      const errs = validateJS(fields, {
        owner_name_first: {
          presence: true,
        },
        owner_name_last: {
          presence: true,
        },
        owner_email: {
          presence: true,
          email: true,
        },
        company_name: {
          presence: true,
        },
        address: {
          presence: true,
        },
        owner_phone_number_cell: {
          presence: true,
          phone: true,
        }
      });
      if (!fields.mc && !fields.dot) {
        errs.mc = errs.dot = 'Please enter an MC# or DOT#';
      }
      if (isEmpty(fields.address) || isEmpty(fields.owner_phone_number_cell)) {
        errs.address = 'This field is required'; 
      }
      return errs;
    },
  })
)(CreatePaymentProfileForm);
