import React from 'react';
import { compose } from 'redux-v3';

import getSelfType from 'components/hoc/getSelfType';
import AccountsPayableForm from 'components/pure/form/AccountsPayableForm';
import AccountsReceivableForm from 'components/pure/form/AccountsReceivableForm';


const floatCenterStyle = { textAlign: 'left', float: 'none', display: 'inline-block', verticalAlign: 'top' };

export default compose(
  getSelfType,
)(({ userType }) =>
  <div className='text-center'>
    {
      userType === 'broker' || userType === 'shipper' ?
        <div className='col-xs-12 col-sm-6' style={floatCenterStyle}>
          <AccountsPayableForm />
        </div>
        : null
    }
    {
      userType === 'broker' || userType === 'carrier' ?
        <div className='col-xs-12 col-sm-6' style={floatCenterStyle}>
          <AccountsReceivableForm />
        </div>
        : null
    }
  </div>
);
