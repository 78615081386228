/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset } from 'redux-form';
import { browserHistory } from 'react-router-v4';
import moment from 'moment';

import { isMobile } from 'helpers';
import formatError from 'helpers/formatError';

import { create, edit } from 'actions/resource/truck';
import { cancelEdit, setDefaultInitialValues as setDefaultIVs } from 'actions/post';
import { openModal } from 'actions/ui';

import docTitle from 'components/hoc/docTitle';
import ResourceBy from 'components/hoc/ResourceBy';
import TruckPostForm, { FORM_NAME, editValidateHandler, setDefaultInitialValues } from 'components/pure/form/TruckPostForm';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import TruckPostTable from 'components/pure/TruckPostTable';
import PostTruck from 'components/pure/PostTruck';
import ProTip from 'components/container/ProTip';
import segmentEvents from '../../helpers/segmentEvents';


export const TruckPostResults = compose(
  connect(state => ({ id: state.user.id })),
  ResourceBy('user', 'truck'),
)(({ item, actions }) =>
  <TruckPostTable
    {...item}
    fetchNext={actions.fetchNext}
  />
);

const mapFieldsToData = fields => ({
  ...(() => fields.searchBy === 'city' ? {
    origin_city: fields.origin_location.city || null,
    origin_region: fields.origin_location.state ? [fields.origin_location.state] : [],
    origin_radius: Number(fields.origin_radius) || undefined,
    destination_city: fields.destination_location.city || null,
    destination_region: fields.destination_location.state ? [fields.destination_location.state] : [],
    destination_radius: Number(fields.destination_radius) || undefined,
  } : {
    origin_city: '',
    origin_region: fields.origin_region.map(state => state.code),
    destination_city: '',
    destination_region: fields.destination_region.map(state => state.code),
  })(),
  trailerTypes: fields.trailerTypes.map(tt => tt.value),
  length: fields.length,
  weight: fields.weight,
  targetRate: fields.targetRate ? Math.floor(fields.targetRate * 100) : null,
  time_start: moment(fields.time_start, 'l').startOf('day').toDate(),
  time_end: moment(fields.time_end, 'l').endOf('day').toDate(),
  details: fields.details,
});

export default compose(
  docTitle('Post Truck'),
  connect(
    state => ({
      editId: state.post.truck.editId,
      copyId: state.post.truck.copyId,
      userId: state.user.id,
      isSubmitting: state.post.truck.editId && state.resource.truck[state.post.truck.editId] ?
        state.resource.truck[state.post.truck.editId].isFetching :
        state.resource.truck.null ?
          state.resource.truck.null.isFetching : false,
    }),
    {
      submitHandler: create,
      editHandler: edit,
      cancelEdit: () => cancelEdit('truck'),
      openModal,
      reset,
      setReverse: id => setDefaultIVs(item => {
        setDefaultInitialValues({
          ...(() => item.data.origin_region.length > 1 ? ({
            // region
            origin_region: item.data.destination_region.map(code => MultiStateInput.data.find(state => state.code === code)),
            destination_region: item.data.origin_region.map(code => MultiStateInput.data.find(state => state.code === code)),
          }) : ({
            // city
            origin_location: {
              city: item.data.destination_city,
              state: item.data.destination_region[0],
            },
            destination_location: {
              city: item.data.origin_city,
              state: item.data.origin_region[0],
            },
          }))(),
          time_start: moment(item.data.time_end).format('l'),
          time_end: moment(item.data.time_end).add(2, 'days').format('l'),
        });
      }, 'truck', id),
    },
  ),
)(({ userId, editId, copyId, cancelEdit, editHandler, submitHandler, openModal, reset, setReverse, ...rest }) =>
  <PostTruck
    editId={editId}
    {...(() => editId === undefined ? {
      headerText: 'Post Truck',
      submitButtonText: 'Post Truck',
      cancelEdit: copyId === undefined ? undefined : cancelEdit,
      // needs to not be included or else it overwrites the default handler specified when reduxForm creates the HOC
      // validator: createValidateHandler,
      submitHandler: fields =>
        submitHandler(mapFieldsToData(fields))
          .then(() => {
            window.analytics.track(segmentEvents.USER_POSTED_TRUCK);
            cancelEdit();
            openModal('success', { message: 'Successfuly posted truck' });
            reset(FORM_NAME);
            setDefaultInitialValues({
              searchBy: fields.searchBy,
            });
            if (isMobile()) {
              browserHistory.replace(`/user/${userId}/truck`);
            }
          })
          .catch(err => {
            console.warn(err);
            openModal('error', { message: formatError(err, 'Error while posting truck') });
          }),
      proTip: (
        <ProTip
          className='pull-right'
          name='Post Truck'
          message={
            <div>
              <p>You can use Post Truck to match your current and future upcoming empty trucks based on lanes you prefer to haul on or that your company regularly services. The more posted trucks (or lanes) using Post Truck means more chances that a shipper or broker will match their posted loads to you and inquire about doing business with you directly!</p>
              <p>Also be sure to always set the end date as far out in the future as you think you will be covering or be able to cover a lane or region for hauling so you don't miss any matches</p>
              <p>To manage alerts for new load matches for posted trucks navigate to the Notifications section of Settings. You may want to disable Email Notifications for State to State Truck Posts or posts that cover much larger areas or time windows so your inbox doesn't overflow.</p>
            </div>
          }
          youtubeEmbed='bogX6Q8VsrI'
        />
      ),
    } : {
      headerText: 'Edit Truck',
      submitButtonText: 'REPOST',
      cancelEdit,
      validator: editValidateHandler,
      setReverse: () => setReverse(editId),
      submitHandler: fields =>
        editHandler(editId, mapFieldsToData(fields))
          .then(() => {
            cancelEdit();
            openModal('success', { message: 'Successfuly edited truck' });
            reset(FORM_NAME);
            if (isMobile()) {
              browserHistory.replace(`/user/${userId}/truck`);
            }
          })
          .catch(err => {
            console.warn(err);
            openModal('error', { message: formatError(err, 'Error while editing truck') });
          }),
    })()}
    myResourceUrl={`/user/${userId}/truck`}
    myResourceButtonText='My Posted Trucks'
    resultsHeaderText='My Trucks'
    Form={TruckPostForm}
    Results={TruckPostResults}
    {...rest}
  />
);
