/**
 * Component that displays a form and makes a request for rate index
 * @module components/pure/form/RateIndexForm
 * @since 3.0.0
 * @requires datatypes/PermissionsError
 * @requires datatypes/FetchError
 * @requires actions/ui
 * @requires actions/rateindex
 * @requires components/pure/form/inputs/GoogleLocationInput
 * @requires components/pure/form/inputs/LabeledDropdown
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import lifecycle from 'recompose/lifecycle';

import PermissionsError from 'datatypes/PermissionsError';
import FetchError from 'datatypes/FetchError';
import storage from 'datatypes/storage';

import { openModal } from 'actions/ui';
import { fetch } from 'actions/rateindex';

import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import SubmitButton from './SubmitButton';
import segmentEvents from '../../../helpers/segmentEvents';


const trailerTypesData = [
  {
    text: 'Any',
    value: '',
  },
  {
    text: 'Dry Van',
    value: 'V,VC,VZ,WLK,VV',
  },
  {
    text: 'Reefer',
    value: 'R,RZ',
  },
  {
    text: 'Flatbed',
    value: 'F,FZ,MX,HS,LB,RGN,SD',
  },
];

// because this will only ever be a singleton, save some data here
let savedInitialValues;
const localStorageKey = 'SavedRateIndex';
let hasAutoSubmitted = false;
let shouldAutoSubmit = false;
const defaultInitialValues = {
  origin: {},
  destination: {},
  trailerTypes: trailerTypesData[0].value,
};
savedInitialValues = storage.getItem(localStorageKey);
if (savedInitialValues === null) {
  savedInitialValues = defaultInitialValues;
}
else {
  try {
    savedInitialValues = {
      ...defaultInitialValues,
      ...JSON.parse(savedInitialValues),
    };
    shouldAutoSubmit = true;
  }
  catch (e) {
    console.warn(e);
    savedInitialValues = defaultInitialValues;
  }
}

export default compose(
  reduxForm({
    form: 'RateIndex',
    async onSubmit(fields, dispatch) {
      const data = {
        origin_city: fields.origin.city,
        origin_state: fields.origin.state,
        destination_city: fields.destination.city,
        destination_state: fields.destination.state,
      };
      if (fields.trailerTypes) {
        data.trailerTypes = fields.trailerTypes;
      }
      storage.setItem(localStorageKey, JSON.stringify({
        ...fields,
      }));
      try {
        await Promise.all([
          dispatch(fetch(data)),
          // Promise.delay(1000), // force this promise to last at least 1 second
        ]);
        window.analytics.track(segmentEvents.USER_VIEWED_RATE_TREND);
        return;
      }
      catch (err) {
        console.warn(err);
        if (err instanceof PermissionsError) {
          dispatch(openModal('upgradeaccount', {
            message: 'You must have an active subscription to use the Rate Trends, please subscribe to start using the Rate Trends now.',
          }));
        }
        else if (err instanceof FetchError && err.status === 429) {
          dispatch(openModal('upgradeaccount', {
            message: 'You have reached your free limit of rate trend checks.\nPlease upgrade to full access for unlimited rate trend use.',
          }));
        }
        else {
          dispatch(openModal('error', {
            message: err.message || 'Failed to fetch Rate Trends data.',
          }));
        }
      }
    },
    initialValues: savedInitialValues,
    validate: fields => validate(fields, {
      origin: {
        multipresence: ['city', 'state'],
      },
      destination: {
        multipresence: ['city', 'state'],
      },
    }),
  }),
  lifecycle({
    componentDidMount() {
      if (hasAutoSubmitted) {
        return;
      }
      if (shouldAutoSubmit) {
        hasAutoSubmitted = true;
        this.props.handleSubmit();
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <div>
    <Field
      name='origin'
      component={GoogleLocationInput}
      label='Origin'
      placeholder=''
      specificity={GoogleLocationInput.specificity.CITY}
    />
    <Field
      name='destination'
      component={GoogleLocationInput}
      label='Destination'
      placeholder=''
      specificity={GoogleLocationInput.specificity.CITY}
    />
    <Field
      name='trailerTypes'
      component={LabeledDropdown}
      data={trailerTypesData}
      label='Equipment'
    />
    <SubmitButton disabled={submitting} onClick={handleSubmit} />
  </div>
);
