/**
 * Component to display a different versino of a mobile-friendly truck view
 * @module components/pure/TruckMobile3
 * @since 3.0.1
 * @requires helpers
 * @requires components/hoc/spinnerIfFetching
 * @requires components/pure/RouteFormat
 * @requires components/pure/TitledGrid
 * @property {object} props
 * @property {object} props.truck
 * @property {object} props.truck.data
 * @property {object} props.truck.data.origin_city
 * @property {object} props.truck.data.origin_state
 * @property {object} props.truck.data.destination_city
 * @property {object} props.truck.data.destination_state
 * @property {object} props.truck.data.targetRate
 * @property {object} props.truck.data.trailerType
 * @property {object} props.truck.data.length
 * @property {object} props.truck.data.weight
 * @property {object} props.truck.data.time_start
 * @property {object} props.truck.data.time_end
 * @property {object} [props.truck.data.time_delivery]
 * @property {object} props.truck.data.user
 * @property {object} props.truck.data.user.data
 * @property {object} props.truck.data.user.data.dba
 * @property {object} props.truck.data.id
 * @property {object} props.truck.data.matches
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';

import { ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import RouteFormat from 'components/pure/RouteFormat';
import TitledGrid from 'components/pure/TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonRefresh from 'components/container/ButtonRefresh';
import segmentEvents from '../../helpers/segmentEvents';


const TruckMobile3 = compose(
  spinnerIfFetching('truck'),
)(({ truck, initiateEdit, initiateDelete, initiateCopy, ...rest }) =>
  <div
    {...rest}
    className={cs('truck-mobile-2 orange-truck', rest.className, {
      'truck-mobile-2-viewed': truck.globals.viewed,
    })}
  >
    <Link to={`/truck/${truck.data.id}`}>
      <RouteFormat.Truck truck={truck} color='black' />
      {truck.data.targetRate ? <div className='truck-mobile-2-target-rate'>{formatPennies(truck.data.targetRate)} / mi</div> : null}
      <div className='truck-mobile-truck-physical-info'>
        {truck.data.trailerTypes && truck.data.trailerTypes.length > 2 ? truck.data.trailerTypes.join(', ') : truck.data.trailerTypes.map(tt => trailerTypeMap[tt]).join(', ') || 'Any'}
        {' / '}
        {truck.data.length ? `${truck.data.length} ft` : '—'}
        {' / '}
        {truck.data.weight ? `${formatWeight(truck.data.weight)} lbs` : '—'}
      </div>
      <TitledGrid cols={3} justified={true}>
        <TitledGrid.Section title='ready'>
          {truck.data.time_start.format('D MMM')}
        </TitledGrid.Section>
        <TitledGrid.Section title='close'>
          {truck.data.time_end.format(`D MMM${truck.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}
        </TitledGrid.Section>
        <TitledGrid.Section title='truck age'>
          <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(truck.data.time_posted)}
        </TitledGrid.Section>
      </TitledGrid>
    </Link>
    <div className='truck-mobile-2-bottom'>
      <div className='truck-mobile-2-bottom-left'>
        <div>
          {
            initiateCopy ?
              <button className='btn btn-orange' onClick={() => initiateCopy(truck.data.id)}>Copy</button>
              : null
          }
          {
            initiateEdit ?
              <button className='btn btn-orange' onClick={() => initiateEdit(truck.data.id)}>Edit</button>
              : null
          }
          {
            initiateDelete ?
              <button className='btn btn-danger' onClick={() => initiateDelete(truck.data.id)}>X</button>
              : null
          }
        </div>
      </div>
      <div className='truck-mobile-2-bottom-right'>
        {
          truck.data.matches ?
            <Link className='hidden-xs' to={`/truck/${truck.data.id}/matches`} onClick={() => window.analytics.track(segmentEvents.USER_VIEWED_TRUCKLOAD_MATCHES)}><button style={{ marginTop: '0.5em' }} className='btn btn-success'>{truck.data.matches} Match{truck.data.matches > 1 ? 'es' : ''}</button></Link>
            :
            <span className='hidden-xs'>No Matches Yet</span>
        }
        <ButtonRefresh type='truck' id={truck.data.id}>Refresh Age</ButtonRefresh>
        <Link to={`/truck/${truck.data.id}`}><button className='btn btn-orange'>DETAILS</button></Link>
      </div>
    </div>
    <div className='text-center visible-xs-block' style={{ marginTop: '0.5em' }}>
      {
        truck.data.matches ?
          <Link to={`/truck/${truck.data.id}/matches`}><button style={{ marginTop: '0.5em' }} className='btn btn-success'>{truck.data.matches} Match{truck.data.matches > 1 ? 'es' : ''}</button></Link>
          :
          <span>No Matches Yet</span>
      }
    </div>
  </div>
);

export default TruckMobile3;
