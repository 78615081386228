import React from 'react';
import { compose } from 'redux-v3';

import formatPennies from 'helpers/formatPennies';

import Resource from 'components/hoc/Resource';
import EventLog from 'components/pure/EventLog';


export default compose(
  Resource('factoringclientauditlog'),
)(({ item }) => {
  if (!item || !item.data) {
    return null;
  }
  return (
    <EventLog
      events={item.data}
      transform={data => {
        const key_is_invalid = (
          [
            'factoring_modified',
            'factoring_last_edited_by_name',
            'factoring_last_edited_by_id',
            'factoring_last_edited_by_user_type',
            'factoring_assigned_admin_id',
            'factoring_assigned_admin_user_type',
            'factoring_deleted_by_id',
            'factoring_deleted_reason',
          ].includes(data.original_key) ||
          !data.original_key.startsWith('factoring_')
        );
        if (key_is_invalid) {
          return null;
        }
        const label_map = {
          assigned_admin_name: 'Assigned Admin',
          deleted_by_name: 'Deleted By',
          'company_profile.mc': 'Company Profile : MC',
          'company_profile.dot': 'Company Profile : DOT',
          'company_profile.tax_id': 'Company Profile: Tax ID',
        };
        const key = data.original_key.replace('factoring_', '');
        let { old_value, new_value } = data;
        const format_value_map = {
          has_contract_authority_status(data) {
            data.old_value = data.old_value ? 'Yes' : 'No';
            data.new_value = data.new_value ? 'Yes' : 'No';
            return data;
          },
          minimum_fee(data) {
            data.old_value = data.old_value ? formatPennies(parseInt(data.old_value, 10)) : 'Default Fee';
            data.new_value = data.new_value ? formatPennies(parseInt(data.new_value, 10)) : 'Default Fee';
            return data;
          },
          discount_rate(data) {
            data.old_value = data.old_value ? data.old_value += '%' : 'Default Rate';
            data.new_value += '%';
            return data;
          },
        };
        format_value_map.has_common_authority_status = format_value_map.has_contract_authority_status;
        format_value_map.has_broker_authority_status = format_value_map.has_contract_authority_status;
        format_value_map.is_allowed_to_operate = format_value_map.has_contract_authority_status;
        const formatter = format_value_map[key];
        if (formatter) {
          ({ old_value, new_value } = formatter(data));
        }
        return {
          ...data,
          old_value,
          new_value,
          key: label_map[key] || data.key.replace('Factoring ', ''),
        };
      }}
      sort={(a, b) => {
        const order = [
          'assigned_admin',
          'assigned_admin_name',
          'user.user_type',
          'source',
          'user.email',
          'company_name',
          'user.address',
          'user.city',
          'user.state',
          'user.zip',
          'contact_phone',
          'user.fax_number',
          'user.stripe_email',
          'user.avgpay',
          'user.credit_approved',
          'user.cargo_insurance_value',
          'user.cargo_insurance_provider',
          'company_profile.name',
          'company_profile.address.street_one',
          'company_profile.address.city',
          'company_profile.address.state',
          'company_profile.address.zip',
          'company_profile.address.country',
          'company_profile.type',
          'company_profile.mc',
          'company_profile.dot',
          'company_profile.years_in_business',
          'company_profile.number_of_trucks',
          'company_profile.number_of_trailers',
          'company_profile.types_of_trailers',
          'company_profile.number_of_owner_operators',
          'company_profile.state_incorporated',
          'company_profile.tax_id',
          'company_profile.current_factoring_company',
          'owner_profile.name_first',
          'owner_profile.name_last',
          'owner_profile.address.street_one',
          'owner_profile.address.city',
          'owner_profile.address.state',
          'owner_profile.address.zip',
          'owner_profile.address.country',
          'owner_profile.phone_number_cell',
          'owner_profile.phone_number_home',
          'owner_profile.email',
          'owner_profile.date_of_birth',
          'owner_profile.social_number',
          'owner_profile.tax_lien_status',
          'has_contract_authority_status',
          'has_common_authority_status',
          'has_broker_authority_status',
          'is_allowed_to_operate',
          'attachments',
          'voided_checks',
          'noa_attachments',
          'factoring_approved',
          'factoring_message',
          'program_type',
          'discount_rate',
          'minimum_fee',
        ];
        const root_key_a = a.original_key.slice(10);
        const root_key_b = b.original_key.slice(10);
        const index_a = order.indexOf(root_key_a);
        const index_b = order.indexOf(root_key_b);
        return index_a > index_b ? 1 : index_b > index_a ? -1 : 0;
      }}
    />
  );
});
