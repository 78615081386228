/**
 * Actions relating to the Rating by User resource
 * @module actions/resourceBy/user/rating
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/rating
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import ratingResource from 'actions/resource/rating';


/**
 * Our Rating by User ControlledPaginatedResourceCollection instance
 */
const ratingByUserResource = new ControlledPaginatedResourceCollection('user', 'rating', {
  url: new UrlAssembler('user/:id/ratings'),
  paginatedResourceOptions: {
    baseResource: ratingResource,
    belongsInCollection: id => data => data.user_id === id,
  },
});

export default ratingByUserResource;
export const {
  fetch,
  fetchNext,
  fetchAll,
  create,
  edit,
  clear,
} = ratingByUserResource;
