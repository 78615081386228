/**
 * Component to display in place of a hidden post on a mobile view
 * @module components/pure/HiddenPostLineItem
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.id - the ID of the resource to unhide
 * @property {function} props.unHide - the function to call to unhide the resources
 */
import React from 'react';
import PropTypes from 'prop-types';


const HiddenPostMobile = ({ id, unHide }) =>
  <div style={{ padding: '0.5em 0' }}>
    You previously hid this post. <button onClick={() => unHide(id)}>Unhide</button>
  </div>
  ;

HiddenPostMobile.propTypes = {
  id: PropTypes.string.isRequired,
  unHide: PropTypes.func.isRequired,
};

export default HiddenPostMobile;
