/**
 * Root reducer for the redux store
 * @module reducers
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/user
 * @requires reducers/ui
 * @requires reducers/user
 * @requires reducers/post
 * @requires reducers/hide
 * @requires reducers/search
 * @requires reducers/settings
 * @requires reducers/activity
 * @requires reducers/rateindex
 * @requires reducers/notification
 * @requires reducers/resource
 * @requires reducers/resourceBy
 */
import { combineReducers } from 'redux-v3';
import { routerReducer } from 'react-router-redux';
import { reducer as formReducer } from 'redux-form';

import INITIAL_STATE from '../INITIAL_STATE';
import { USER_LOGOUT } from '../actions/user';

import ui from './ui';
import user from './user';
import post from './post';
import hide from './hide';
import search from './search';
import settings from './settings';
import activity from './activity';
import rateindex from './rateindex';
import notification from './notification';
import admin from './admin';

import resource from './resource';
import resourceBy from './resourceBy';
import upload from '../actions/upload';
import { filter, get, isEmpty, isNull, isUndefined, reduce } from 'lodash';

const UPDATED_ACTION = {
  BLUR: '@@redux-form/BLUR',
  REMOVE_ARRAY: '@@redux-form/ARRAY_REMOVE'
}
const updateAmount = (state, fieldName, action) => {
  try {
    if (/^line_items/i.test(fieldName) && (action === UPDATED_ACTION.REMOVE_ARRAY || /amount$/i.test(fieldName))) {
      const lineItems = get(state, `values.line_items`, []);
      const nonEmptyLineItem = filter(lineItems, lineItem => !isEmpty(lineItem));
      const amount = reduce(nonEmptyLineItem, (result, line_item) => {
        const { amount = 0 } = line_item;
        return result + amount;
      }, 0);
      return {
        ...state,
        values: {
          ...state.values,
          amount
        }
      }
    }
  } catch (e) {
    console.log("ERROR", e)
  }
  
  return state;
}
const rootReducer = combineReducers({
  ui,
  user,
  post,
  hide,
  search,
  settings,
  activity,
  rateindex,
  notification,
  resource,
  resourceBy,
  admin,
  url: (i = INITIAL_STATE.url) => i,
  cookies: (i = INITIAL_STATE.cookies) => i,
  upload,
  form: formReducer.plugin({
    DebtorCarrierPayment: (state, action) => {
      if (isEmpty(state)) return;
      switch (action.type) {
        case UPDATED_ACTION.BLUR:
          return updateAmount(state, get(action, 'meta.field', ''), UPDATED_ACTION.BLUR);
        case UPDATED_ACTION.REMOVE_ARRAY:
          return updateAmount(state, get(action, 'meta.field', ''), UPDATED_ACTION.REMOVE_ARRAY);
        default:
          return state
      }
    },
    FactoringPaymentEditFormBroker: (state, action) => {
      if (isEmpty(state)) return;
      switch (action.type) {
        case UPDATED_ACTION.BLUR:
          return updateAmount(state, get(action, 'meta.field', ''), UPDATED_ACTION.BLUR);
        case UPDATED_ACTION.REMOVE_ARRAY:
          return updateAmount(state, get(action, 'meta.field', ''), UPDATED_ACTION.REMOVE_ARRAY);
        default:
          return state
      }
    }
  }),
  routing: routerReducer,
});

export default (state = INITIAL_STATE, action) => {
  if (action.type === USER_LOGOUT) {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
};
