/**
 * Component to display in place of a hidden post
 * @module components/pure/HiddenPostLineItem
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.id - the ID of the resource to unhide
 * @property {function} props.unHide - the function to call to unhide the resource
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';


const HiddenPostLineItem = ({ id, unHide }) =>
  <tbody style={{ backgroundColor: colors.LIGHT_GRAY }}>
    <tr>
      <td colSpan='999'>
        You previously hid this post. <button onClick={() => unHide(id)}>Unhide</button>
      </td>
    </tr>
  </tbody>
  ;

HiddenPostLineItem.propTypes = {
  id: PropTypes.string.isRequired,
  unHide: PropTypes.func.isRequired,
};

export default HiddenPostLineItem;
