/**
 * Connected modal component for hiding a post
 * @module components/pure/modals/ConfirmHidePost
 * @since 3.0.0
 * @requires actions/hide
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {string} props.id - the ID of the load or truck post to hide
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { hidePost } from 'actions/hide';

import Modal from 'components/hoc/Modal';
import ModalWarning from './Warning';


const ConfirmHidePost = compose(
  Modal,
  connect(
    null,
    (dispatch, ownProps) => ({
      hidePost() {
        dispatch(hidePost(ownProps.id));
        ownProps.closeModal();
      },
    }),
  ),
  mapProps(({ hidePost, id, ...rest }) => ({
    ...rest,
    headerChildren: 'Confirm',
    message: 'Are you sure you want to block this post?',
    buttonChildren: 'Cancel',
    extraButtons: [
      <button className='btn btn-danger' onClick={hidePost}>Hide</button>,
    ],
  })),
)(ModalWarning);

ConfirmHidePost.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmHidePost;
