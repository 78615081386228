/* global API */

export const getReserve = (user_id, status) => async (dispatch, getState) => {
  let query = '';
  if (status) {
    switch (status) {
      case 'pending':
        query = 'pending=true';
        break;
      case 'active':
        query = 'pending_chargebacks=true';
        break;
      case 'closed':
        query = 'closed=true';
        break;
    }
  }
  const res = await global.fetch(`${API.host}/reserve/${user_id}/?${query}`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const getAggregate = user_id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/reserve/${user_id}/aggregate/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export const getGiveBackReserve = user_id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/reserve/${user_id}/?line_item=true&available=true&pending_chargebacks=true`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  return json;
};

export default {
  getAggregate,
  getGiveBackReserve,
};
