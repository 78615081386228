import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset } from 'redux-form';
import moment from 'moment';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import { USER_TYPE } from 'helpers';

import { create, edit, fetch } from 'actions/resourceBy/factoringpayment/factoringpaymentnote';
import { openModal } from 'actions/ui';

import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import Header from 'components/pure/Header';
import LineItemHorizontal from 'components/pure/LineItemHorizontal';
import NoteForm from 'components/pure/form/NoteForm';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import MaterialIcon from 'components/pure/MaterialIcon';


const FactoringPaymentNotesForm = compose(
  mapProps(({ id, ...rest }) => ({
    form: 'FactoringPaymentNotesForm',
    onSubmit(fields, dispatch) {
      return dispatch(create(id, {
        factoring_payment_id: id,
        note: fields.note,
        attachments: fields.attachments,
      }))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully posted note' }));
          dispatch(reset('FactoringPaymentNotesForm'));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Failed to post note' }));
        })
      ;
    },
    ...rest,
  })),
)(NoteForm);

const FactoringPaymentNoteEditForm = compose(
  mapProps(({ item, cancelEdit }) => ({
    form: `FactoringPaymentNoteEditForm#${item.data.id}`,
    initialValues: {
      note: item.data.note,
    },
    cancel: cancelEdit,
    onSubmit(fields, dispatch) {
      const data = {
        id: item.data.id,
        note: fields.note,
        attachments: fields.attachments,
      };
      return dispatch(edit(item.data.funding_request, data))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully edited note' }));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Failed to edit note' }));
        })
        .then(cancelEdit)
      ;
    },
  })),
)(FactoringPaymentNotesForm);

const FactoringPaymentNote = compose(
  withState('isEditing', 'setIsEditing', false),
  connect(
    state => {
      const self = state.resource.user[state.user.id];
      return {
        userType: self.data.type,
      };
    },
    dispatch => ({
      deleteNote(id, note_id) {
        return dispatch(openModal('confirm', {
          type: 'factoringpaymentnote_delete',
          args: [id, note_id],
        }));
      },
    }),
  ),
)(({ item, deleteNote, isEditing, setIsEditing, userType }) =>
  <LineItemHorizontal
    key={item.data.id}
    label={
      <div style={{ fontSize: '0.8em' }}>
        <div>{item.data.posted_by.name}</div>
        <div>{item.data.created.format('l')}</div>
        <div>{item.data.created.format('LT')}</div>
        {
          (userType !== USER_TYPE.ADMIN && item.data.created.isBefore(moment().subtract(2, 'hours'))) ||
          ((userType === USER_TYPE.FACTORING_ADMIN || userType === USER_TYPE.ALADDIN_ADMIN) && item.data.user)
            ? null :
            <div>
              <div className='text-red' role='button' onClick={() => deleteNote(item.data.funding_request, item.data.id)}>Delete Note</div>
              <div className='text-blue' role='button' onClick={() => setIsEditing(true)}>Edit Note</div>
            </div>
        }
      </div>
    }
    >
    {
      isEditing ?
        <FactoringPaymentNoteEditForm item={item} cancelEdit={() => setIsEditing(false)} />
        :
        <div>
          <div dangerouslySetInnerHTML={{ __html: item.data.note ? item.data.note.replace(/(\r\n|\n|\r)/gm, '<br />') : null }} />
          {item.data.attachments.map((attachment, i) =>
            attachment.uploaded ?
              <LoadAttachmentButton
                key={i}
                title={attachment}
                getURL={() => Promise.resolve(attachment)}
              />
              :
              <MaterialIcon name='sync' spin={true} />
          )}
        </div>
    }
  </LineItemHorizontal>
);

export default compose(
  connect(
    null,
    {
      fetch: fetch,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch(this.props.id);
    },
  }),
  ResourceBy('factoringpayment', 'factoringpaymentnote'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringpaymentnote',
  }),
)(({ id, items }) =>
  <div>
    <Header hr={true}>Notes</Header>
    <FactoringPaymentNotesForm id={id} />
    <div style={{ marginTop: '1em' }}>
      {items.map(item => <FactoringPaymentNote item={item} />)}
    </div>
  </div>
);
