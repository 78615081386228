import React from 'react';
import Spinner from 'components/pure/Spinner';

const BigOrangeSubmitButton = ({ submitting, message, ...props }) => (
  <button
    className='btn btn-orange'
    type='submit'
    disabled={submitting}
    {...props}
    >
    {
      submitting ?
        <span>
          <Spinner style={{ fontSize: 2 }}/>
          PROCESSING
        </span>
        :
        <span>{message}</span>
    }
  </button>
);

export default BigOrangeSubmitButton;
