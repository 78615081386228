/**
 * Component that displays the load post form
 * @module components/pure/form/LoadPostForm
 * @since 3.0.0
 * @requires components/hoc/spinnerIf
 * @requires components/pure/form/inputs/GoogleLocationInput
 * @requires components/pure/form/inputs/TrailerInput
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/WeightInput
 * @requires components/pure/form/inputs/LengthInput
 * @requires components/pure/form/inputs/DateTimeInput
 * @requires components/pure/form/inputs/GrowableInput
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { get, some } from 'lodash';
import validate from 'validate.js';
import moment from 'moment';
import getSelf from 'selectors/getSelf';
import isFactoringBroker from 'selectors/isFactoringBroker';
import { USER_TYPE } from 'helpers';
import convertFromDotNotation from 'helpers/convertFromDotNotation';

import spinnerIf from 'components/hoc/spinnerIf';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import { TrailerInput, trailerTypes } from 'components/pure/form/inputs/TrailerInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import WeightInput from 'components/pure/form/inputs/WeightInput';
import LengthInput from 'components/pure/form/inputs/LengthInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import MultiGoogleLocationInput from 'components/pure/form/inputs/MultiGoogleLocationInput';
import Resource from '../../hoc/Resource';
import getSelfHOC from 'components/hoc/getSelf';

export const FORM_NAME = 'LoadPost';

const DEFAULT_INITIAL_VALUES = {
  origin_location: [{}],
  destination_location: [{}],
  count: 1,
  groups_post_to: 'private',
};

/**
 * Automatically push the start time and end time forward when it happens
 */
const rolloverTimes = () => {
  DEFAULT_INITIAL_VALUES.time_start = moment();
  DEFAULT_INITIAL_VALUES.time_end = moment().add(1, 'days');
  DEFAULT_INITIAL_VALUES.time_delivery = moment().add(4, 'days');
  setTimeout(rolloverTimes, moment().endOf('day').diff(moment()));
};
rolloverTimes();

const commonValidateConstraints = {
  trailerType: {
    presence: true,
  },
  weight: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 100,
      notGreaterThanOrEqualTo: '^Must be greater than or equal to 100 pounds',
    },
  },
  length: {
    presence: true,
    numericality: {
      onlyInteger: true,
      greaterThan: 1,
      lessThan: 1000,
      notGreaterThan: '^Must be greater than 1 foot',
    },
  },
  targetRate: {
  },
  count: {
    numericality: {
      onlyInteger: true,
      greaterThanOrEqualTo: 1,
      lessThanOrEqualTo: 9,
    },
  },
  time_end: {
    presence: true,
    creldatetime: {
      earliest: 'time_start',
      message: '^Cannot be earlier than the ready time',
    },
    // creldatetime_duration: {
    //   othertime: 'time_start',
    //   amount: 1000 * 60 * 60, // 1 hour
    //   parseThis: 'l HH:mm',
    //   parseOther: 'l HH:mm',
    //   message: '^Start and close time cannot be the same.',
    // },
  },
  time_delivery: {
    presence: true,
    creldatetime: {
      earliest: 'time_start',
      message: '^Cannot be earlier than the Pick up time',
    },
  },
  user_load_number: {
    presence: true,
  },
  groups_post_to: {
    presence: true,
  },
};

export const editValidateHandler = fields => {
  return convertFromDotNotation(validate(fields, {
    ...commonValidateConstraints,
    ...['origin_location', 'destination_location'].map(location => fields[location].reduce((acc, curr, i) => {
      acc[`${location}.${i}`] = {
        multipresence: ['city', 'state'],
      };
      return acc;
    }, {})).reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {}),
  }));
};
export const validateHandler = fields => {
  return convertFromDotNotation(validate(fields, {
    ...commonValidateConstraints,
    ...['origin_location', 'destination_location'].map(location => fields[location].reduce((acc, curr, i) => {
      acc[`${location}.${i}`] = {
        multipresence: ['city', 'state'],
      };
      return acc;
    }, {})).reduce((acc, curr) => {
      return {
        ...acc,
        ...curr,
      };
    }, {}),
    time_start: {
      presence: true,
    },
  }));
};

export default compose(
  connect(
    state => ({
      editId: state.post.load.editId,
      copyId: state.post.load.copyId,
      groups: getSelf(state).data.groups,
      isFactoringBroker: isFactoringBroker(state),
      enableReinitialize: (() => {
        const { editId } = state.post.load;
        if (editId) {
          return true;
        }
        return false;
      })(),
      initialValues: (() => {
        const { editId, copyId } = state.post.load;
        let item;
        if (editId === undefined) {
          item = state.resource.load[copyId];
        }
        else if (copyId === undefined) {
          item = state.resource.load[editId];
        }
        let default_post_to = 'all';
        const self = getSelf(state);
        if (self && self.data.type === USER_TYPE.BROKER && self.data.external !== 'posteverywhere') {
          default_post_to = 'carriers';
        }
        if (item === undefined || item.data === undefined || item.err !== null) {
          return {
            ...DEFAULT_INITIAL_VALUES,
            post_to: default_post_to,
          };
        }
        return {
          names_origin_location: item.data.origin_locations.map(({ name }) => name),
          origin_location: item.data.origin_locations.map(({ name, ...rest }) => rest),
          names_destination_location: item.data.destination_locations.map(({ name }) => name),
          destination_location: item.data.destination_locations.map(({ name, ...rest }) => rest),
          trailerType: trailerTypes.find(tt => tt.value === item.data.trailerType),
          weight: item.data.weight,
          length: item.data.length,
          targetRate: (item.data.targetRate / 100) || undefined,
          time_start: item.data.time_start,
          time_end: item.data.time_end,
          time_delivery: (item.data.time_delivery || item.data.time_end.add(4, 'days').endOf('day')),
          post_to: item.data.post_to || default_post_to,
          details: item.data.details,
          user_load_number: item.data.user_load_number,
          destroyOnUnmount: editId ? false : true,
          groups_post_to: item.data.groups_post_to
        };
      })(),
    }),
    null,
    null,
    { withRef: true },
  ),
  reduxForm({
    form: FORM_NAME,
    warn(fields) {
      const { time_end, time_delivery } = fields;
      if (time_delivery.diff(time_end, 'days') < 1) {
        return {
          time_delivery: 'Please be sure your delivery date makes sense.',
        };
      }
      return {};
    },
    validate: validateHandler,
  }),
  getSelfHOC,
  Resource('factoringclient', {
    idPropName: ['item', 'data', 'factoring_id'],
    resourcePropName: 'factoringclient'
  }),
  spinnerIf(props => props.editId === 'loading'),
)(({ editId, groups, isFactoringBroker, ...rest }) => {
  const generateLoadNumber = get(rest, ['factoringclient', 'data', 'auto_generate_load_numbers'], false);
  return (
    <div className='light-placeholder'>
      <FieldArray
        name='origin_location'
        component={MultiGoogleLocationInput}
        label='Origin'
        placeholder=''
        specificity={GoogleLocationInput.specificity.ANY}
        instructions={true}
      />
      <FieldArray
        name='destination_location'
        component={MultiGoogleLocationInput}
        label='Destination'
        placeholder=''
        specificity={GoogleLocationInput.specificity.ANY}
        instructions={true}
      />
      {
        !generateLoadNumber && some(groups, group => {
          return ['dispatch_broker', 'haulpay_broker'].indexOf(group.type) >= 0;
        }) ?
          <Field
            name='user_load_number'
            component={LabeledInput}
            label='Load Number'
            type='text'
          /> :
          null
      }
      {
        editId ?
          <div className='row-2col spacer-xs clearfix'>
            <div className='col-xs-7'>
              <Field
                name='trailerType'
                component={TrailerInput}
                label='Equipment Type'
                itemComponent={item => {
                  if (item.value === 'V') {
                    return <div>Dry Van (V) most common</div>;
                  }
                  return <div>{item.text}</div>;
                }}
              />
            </div>
            <div className='col-xs-5'>
              <Field name='targetRate' component={LabeledInput} label='Target Rate' type='number' />
            </div>
          </div>
          :
          <div className='row-2col spacer-xs clearfix'>
            <div className='col-xs-6'>
              <Field
                name='trailerType'
                component={TrailerInput}
                label='Equipment Type'
                itemComponent={item => {
                  if (item.value === 'V') {
                    return <div>Dry Van (V) most common</div>;
                  }
                  return <div>{item.text}</div>;
                }}
              />
            </div>
            <div className='col-xs-2'>
              <Field name='count' component={LabeledInput} label='Count' type='number' min='1' max='5' />
            </div>
            <div className='col-xs-4'>
              <Field name='targetRate' component={LabeledInput} label='Target Rate' type='number' />
            </div>
          </div>
      }
      <div className='row-2col spacer-xs clearfix'>
        <div className='col-xs-6'>
          <Field name='weight' component={WeightInput} label='Load Weight' />
        </div>
        <div className='col-xs-6'>
          <Field name='length' component={LengthInput} label='Load Length' specific={true} />
        </div>
      </div>
      <Field name='time_start' component={DateTimeInput} props={{ format: 'l HH:mm' }} label='Pick Up Open' />
      <Field name='time_end' component={DateTimeInput} props={{ format: 'l HH:mm' }} label='Pick Up Close' />
      <Field name='time_delivery' component={DateTimeInput} props={{ format: 'l HH:mm' }} label='Deliver By' />
      <Field
        name='details'
        component={GrowableInput}
        props={{
          Component: LabeledInput,
          ComponentClass: 'textarea',
          placeholder: 'More details, commodity, or dimensions here',
        }}
      />
      {isFactoringBroker ?
        <Field
          name='groups_post_to'
          label='Post To'
          component={LabeledDropdown}
          data={[
            {
              text: 'Post To Group Only',
              value: 'private',
            },
            {
              text: 'Post To Public Only',
              value: 'public',
            },
            {
              text: 'Both Public and Group',
              value: 'all',
            },
          ]}
        /> :
        <Field
          name='post_to'
          label='Post To'
          component={LabeledDropdown}
          data={[
            {
              text: 'Post To Carriers',
              value: 'carriers',
            },
            {
              text: 'Post To Brokers',
              value: 'brokers',
            },
            {
              text: 'Post To Carriers and Brokers',
              value: 'all',
            },
          ]}
        />
      }
    </div>
  );
});
