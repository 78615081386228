import React from 'react';
import { compose } from 'redux-v3';

import Modal from 'components/hoc/Modal';
import objectURLSrc from 'components/hoc/objectURLsrc';
import ModalGeneric from './Generic';


export default compose(
  Modal,
  objectURLSrc(),
)(({ src, ...rest }) =>
  <ModalGeneric
    headerChildren='Image Preview'
    message={(
      <img src={src} style={{ width: '100%' }} />
    )}
    {...rest}
  />
);
