/**
 * Reducer for handling the loadreverse by load resource actions
 * @module reducers/resourceBy/load/loadreverse
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/load/loadreverse
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.load.loadreverse;

import loadreverseByLoadResource from '../../../actions/resourceBy/load/loadreverse';


export default function loadreverseByLoadResourceReducer(state = initialState, action) {
  state = loadreverseByLoadResource.reduce(state, action);
  return state;
}
