/**
 * Component for displaying factoring marketing information. Separated from [TAFSRegister]{@link module:components/pure/modals/TAFSRegister} for use on the marketing pages
 * @todo rename this module since it no longer advertises for tafs
 * @module components/pure/TAFS
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/TAFSForm
 * @property {object} props
 * @property {function} [closeModal] - function to close modal, passed to TAFSForm
 * @property {object} [initialValues] - initial values passed to TAFSForm
 * @property {any} [props....rest] - props passed to containing i component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { USER_TYPE } from 'helpers';
import TAFSForm from 'components/pure/form/TAFSForm';
import { browserHistory } from 'react-router-v4';
import getSelf from 'selectors/getSelf';

const TAFS = compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
  ),
)(({ showAsCarrier, self: { data: { fmcsa_type } = {} } = {}, closeModal, initialValues }) =>
  <div>
    <div className='text-center'>
      <img style={{ maxWidth: '100%', padding: '1em' }} src='/public/image/comfreight haul pay.png' />
    </div>
    {
      showAsCarrier || fmcsa_type === USER_TYPE.CARRIER ?
        <div>
          <h3>New Instant Pay Service</h3>
          <h4>Totally New Type of Factoring!</h4>
          <ul>
            <li>Get Instant Payments on Delivery</li>
            <li>Simplify all of your broker quick pays</li>
            <li>No recourse and no reserve holdbacks</li>
            <li>Simple flat rate with same day funding</li>
          </ul>
        </div>
        :
        <div>
          <h3>Want to offer quick pay to your carriers?</h3>
          <p>Join our interest list for Haul Pay for brokers. HaulPay's new program enables freight brokers to offer quick pay to their carriers free of charge!</p>
        </div>
    }
    <div className='form-group text-right'>
      <button
        type='submit'
        onClick={() => {
          browserHistory.push('/haul-pay'); closeModal();
        }}
        className='btn btn-orange'
        >
        {
          showAsCarrier || fmcsa_type === USER_TYPE.CARRIER ?
            'Get Started'
            :
            'Learn More'
        }
      </button>
      {closeModal && <button name='nothanks' className='btn btn-default pull-left' onClick={closeModal}>No Thanks</button>}
    </div>
  </div>
);

export default TAFS;
