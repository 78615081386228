/**
 * Component for a modal that shows a youtube embed
 * @module components/pure/modals/YoutubeModal
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/hoc/ModalTimeout
 * @requires components/pure/modals/Generic
 * @requires components/pure/YoutubeEmbed
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalGeneric component
 */
import React from 'react';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Modal from '../../hoc/Modal';
import ModalGeneric from './Generic';
import YoutubeEmbed from '../YoutubeEmbed';


const YoutubeModal = compose(
  mapProps(({ video_id, ...rest }) => ({
    ...rest,
    message: <YoutubeEmbed style={{ minHeight: 500 }} video_id={video_id} />,
  })),
  Modal,
)(ModalGeneric);

YoutubeModal.containerProps = {
  bsSize: 'large',
};

export default YoutubeModal;
