import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import nothingIf from 'components/hoc/nothingIf';
import SignUpButtonLink from 'components/pure/SignUpButtonLink';


export default compose(
  connect(state => ({ logged_in: state.user.logged_in })),
  nothingIf(({ logged_in }) => logged_in),
  mapProps(({ logged_in, dispatch, ...rest }) => rest),
)(SignUpButtonLink);
