/**
 * Component to display a different versino of a mobile-friendly load view
 * @module components/pure/LoadMobile2
 * @since 3.0.1
 * @requires helpers
 * @requires datatypes/PermissionsError
 * @requires components/hoc/spinnerIfFetching
 * @requires components/container/ButtonCall
 * @requires components/pure/RouteFormat
 * @requires components/pure/TitledGrid
 * @property {object} props
 * @property {object} props.load
 * @property {object} props.load.data
 * @property {object} props.load.data.origin_city
 * @property {object} props.load.data.origin_state
 * @property {object} props.load.data.destination_city
 * @property {object} props.load.data.destination_state
 * @property {object} props.load.data.targetRate
 * @property {object} props.load.data.trailerType
 * @property {object} props.load.data.length
 * @property {object} props.load.data.weight
 * @property {object} props.load.data.time_start
 * @property {object} props.load.data.time_end
 * @property {object} [props.load.data.time_delivery]
 * @property {object} props.load.data.user
 * @property {object} props.load.data.user.data
 * @property {object} props.load.data.user.data.dba
 * @property {object} props.load.data.user.data.credit_approved
 * @property {object} props.load.data.id
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/LoadMobile2';
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import cs from 'classnames';
import moment from 'moment';

import { USER_TYPE, ageAbbr, formatWeight, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import PermissionsError from 'datatypes/PermissionsError';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import RouteFormat from 'components/pure/RouteFormat';
import TitledGrid from 'components/pure/TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import ButtonCall from 'components/container/ButtonCall';


const LoadMobile2 = compose(
  spinnerIfFetching('load'),
)(({ load, detailsLocation, dispatchLoad, ...rest }) =>
  <div
    {...rest}
    className={cs('load-mobile-2', rest.className, {
      'load-mobile-2-viewed': load.globals.viewed,
      'yellow-shipper': load.data.user_type === USER_TYPE.SHIPPER,
      'blue-broker': [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(load.data.user_type),
    })}
    >
    <Link to={`/load/${load.data.id}`}>
      <RouteFormat origin={load.data.origin_locations} destination={load.data.destination_locations} />
      {load.data.targetRate ? <div className='load-mobile-2-target-rate'>{formatPennies(load.data.targetRate)}</div> : null}
      <div className='load-mobile-2-physical-info'>{trailerTypeMap[load.data.trailerType]} / {load.data.length ? `${load.data.length} ft` : '—'} / {load.data.weight ? `${formatWeight(load.data.weight)} lbs` : '—'}</div>
      <TitledGrid cols={4} justified={true}>
        <TitledGrid.Section title='ready' bottom={true}>
          <div>{load.data.time_start.format('D MMM')}</div>
          <div style={{ fontSize: '0.7em' }} >
            {load.data.time_start.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='close' bottom={true}>
          <div>{load.data.time_end.format(`D MMM${load.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}</div>
          <div style={{ fontSize: '0.7em' }}>
            {load.data.time_end.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='deadhead' bottom={true}>
          {load.data.deadhead ? `${load.data.deadhead} mi` : '—'}
        </TitledGrid.Section>
        <TitledGrid.Section title='load age' bottom={true}>
          <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(load.data.time_posted)}
        </TitledGrid.Section>
      </TitledGrid>
    </Link>
    <div className='load-mobile-2-bottom'>
      <div className='load-mobile-2-bottom-left'>
        <div>
          {load.data.user.data.dba}
          {load.data.user_type === USER_TYPE.SHIPPER ? <img className='load-mobile-2-shippericon' src='/public/image/shipper_load.png' /> : null}
        </div>
        {load.data.user.data.credit_approved ? <div className='load-mobile-2-credit_approved'>HAUL PAY APPROVED</div> : null}
      </div>
      {
        load.data.user_type !== USER_TYPE.SHIPPER && (load.data.user.data.contact_phone || load.data.user.err instanceof PermissionsError) ?
          <div className='load-mobile-2-bottom-middle'>
            <ButtonCall tel={load.data.user.data.contact_phone} />
          </div>
          : null
      }
      <div>
        <Link to={detailsLocation || `/load/${load.data.id}`}><button className='btn btn-orange'>DETAILS</button></Link>
      </div>
      {
        dispatchLoad ?
          <div>
            <button className='btn btn-success' style={{ marginLeft: '1em' }} onClick={dispatchLoad}>Dispatch</button>
          </div>
          : null
      }
    </div>
  </div>
);

export default LoadMobile2;
