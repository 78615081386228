/**
 * Reducer for handling the accountsreceivable settings actions
 * @module reducers/settings/accountsreceivable
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/settings/accountsreceivable
 * @listens COPY_AP
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.settings.accountsreceivable;

import accountsreceivableSettingsResource, { COPY_AP } from '../../actions/settings/accountsreceivable';


export default function accountsreceivableSettingsReducer(state = initialState, action) {
  state = accountsreceivableSettingsResource.reduce(state, action);
  switch (action.type) {
    case COPY_AP:
      return {
        ...state,
        data: action.payload,
      };
  }
  return state;
}
