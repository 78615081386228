export default {
    USER_CREATED_NEW_CUSTOMER: 'User Created a New Customer',
    CARRIER_CREATED_FUNDING_REQUEST: 'Carrier Created a Funding Request',
    BROKER_CREATED_FUNDING_REQUEST: 'Broker Created a Funding Request',
    CARRIER_CREATED_INCOMPLETE_FUNDING_REQUEST: 'Carrier Created an Incomplete Funding Request',
    CARRIER_CREATED_NON_FACTORED_FUNDING_REQUEST: 'Carrier Created an non Factored Funding Request',
    CARRIER_CREATED_FUNDING_REQUEST_WITH_FUEL_ADVANCE: 'Carrier Create a Funding Request With a Fuel Advance',
    USER_REQUESTED_CREDIT_INCREASE_CUSTOMER: 'User Requested a Credit Increase for a Customer',
    CARRIER_VIEWED_TRANSACTION_HISTORY: 'Carrier Viewed Their Transaction History',
    CARRIER_VIEWED_INCOMPLETE_TRANSACTIONS: 'Carrier Viewed Their incomplete transactions',
    USER_UPLOAD_NEW_DOCUMENTS_FUNDING_REQUEST: 'User Upload New Documents to a Funding Request',
    USER_REQUESTED_CUSTOMERS_CREDIT_REVIEWED: 'User Requested a Customers Credit to be Reviewed',
    USER_SEARCHED_SHIPPERS_CREDIT_CHECK: 'User Searched for Shippers in the Credit Check',
    USER_UPDATED_HAULPAY_PROFILE: 'User Updated Their HaulPay Profile',
    USER_SENT_NOA: 'User Sent an NOA',
    USER_SEARCHED_LOAD: 'User Searched for a Load',
    USER_SEARCHED_TRUCK: 'User Searched for a Truck',
    USER_SAVED_LOAD: 'User Saved a Load',
    USER_POSTED_TRUCK: 'User Posted a Truck',
    USER_POSTED_LOAD: 'User Posted a Load',
    USER_SAVED_TRUCK: 'User Saved a TRUCK',
    USER_REFRESHED_TRUCK_AGE: 'User Refreshed a Truck\'s Age',
    USER_REFRESHED_LOAD_AGE: 'User Refreshed a Load\'s Age',
    USER_BID_LOAD: 'User Bid\'s on a Load',
    USER_BID_LOAD_WITH_NOTES: 'User Bid\'s on a Load with Notes',
    USER_ASKED_QUESTION_TRUCK: 'User Asked a Question on a Truck',
    USER_ASKED_QUESTION_LOAD: 'User Asked a Question on a Load',
    USER_UPDATED_NOTIFICATION_SETTINGS: 'User Updated Notification Settings',
    USER_SENT_DOCUMENTS: 'User Sent Documents',
    USER_ADDED_NEW_DOCUMENT: 'User Added a New Document',
    BROKER_CONNECT_INVITED_CARRIER_MC_DOT_SEARCH: 'Broker Connects to an Invited Carrier by MC / DOT Search',
    BROKER_INVITED_CARRIER_BY_EMAIL: 'Broker Invited a Carrier By Email',
    BROKER_INVITED_CARRIER_BY_SMS: 'Broker Invited a Carrier By SMS',
    BROKER_UPDATED_THEIR_DEFAULTS: 'Broker Updates Their Defaults',
    BROKER_UPDATED_CARRIERS_PAYMENTS_SPEED: 'Broker Updated a Carriers Payment Speed',
    BROKER_UPDATED_CARRIERS_BROKER_PORTION_PERCENT: 'Broker Updates a Carriers Broker Portion Percent',
    BROKER_CREATED_PAYMENT_PROFILE_THEMSELVES: 'Broker Created a Payment Profile Themselves',
    BROKER_UPLOADED_DOCUMENTS_CARRIER: 'Broker Uploaded Documents to a Carrier',
    BROKER_EDIT_FUNDING_REQUEST_INVOICE_AMOUNT: "User Edit a Funding Request Invoice Amount",
    BROKER_GENERATED_CARRIER_RATE_CONFIRMATION: "Broker Generated a Carrier Rate Confirmation",
    USER_VIEWED_RATE_TREND: "User Viewed the Rate Trend",
    USER_VIEWED_TRUCKLOAD_MATCHES: "User Viewed Truckload Matches"
}