import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import { getDispatchingStatus } from 'actions/user';

import { CenteredSpinner } from 'components/pure/Spinner';
import Header from 'components/pure/Header';
import BackgroundHeader from 'components/pure/BackgroundHeader';
import SettingsBillingUpdateDispatchingCardButton from 'components/container/SettingsBillingUpdateDispatchingCardButton';
import SettingsBillingDispatchHistory from 'components/container/SettingsBillingDispatchHistory';
import DispatchPricingTable from 'components/pure/DispatchPricingTable';


export default compose(
  connect(
    state => {
      const { dispatching } = state.user;
      return {
        dispatching,
      };
    },
    {
      getDispatchingStatus,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.getDispatchingStatus();
    },
  }),
)(({ dispatching }) =>
  <div>
    <BackgroundHeader>Dispatching</BackgroundHeader>
    <p>
      ComFreght offers a dispatching service. We will help you by calling on loads that we can find available via our board and extended network and help to negotiate and place you on the load to secure a rate confirmation.
    </p>
    <p>
      <Header>Pricing</Header>
      <DispatchPricingTable />
    </p>
    <p>
      Current card used for dispatching charges: {dispatching.data ? `${dispatching.data.type} XXXX-XXXX-XXXX-${dispatching.data.last4}` : 'No card yet.'}
      <SettingsBillingUpdateDispatchingCardButton />
    </p>
    {
      dispatching.isFetching ?
        <CenteredSpinner />
        : null
    }
    <SettingsBillingDispatchHistory />
  </div>
);
