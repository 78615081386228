import { Tabs } from '@haulpay/ui';
import React from 'react';
import InvitedCarriers from './InvitedCarriers';
import MyCarriers from './MyCarriers';

const { TabPane } = Tabs;
export default function BrokerInvitedCarriers() {
  return (
    <div>
      <Tabs
      >
        <TabPane tab="Invited Carriers" key="0">
            <InvitedCarriers />
        </TabPane>
        <TabPane tab="My Carriers" key="1">
          <MyCarriers />
        </TabPane>
      </Tabs>
         
    </div>
  );
}