import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import MaterialIcon from 'components/pure/MaterialIcon';
import { getCadenceAladdin } from 'actions/factoring';
import { openModal } from 'actions/ui';

import wrapPromisePending from 'components/hoc/wrapPromisePending';

const ButtonCadenceReports = ({ onClick, ...props }) =>
  <button
    style={{ marginLeft: '8px', backgroundColor: '#26a69a', color: 'white' }}
    type='button'
    onClick={onClick}
    {...props}
    >
    <MaterialIcon name='attach_file' />
    Cadence
  </button>;
export default compose(
  connect(
    null,
    (dispatch, { status }) => ({
      async onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        try {
          const { download_url } = await dispatch(getCadenceAladdin({ status }));
          window.open(download_url);
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', {
            message: 'Failed to fetch CSV data :(',
          }));
        }
      },
    }),
    (stateProps, dispatchProps, { status, ...rest }) => ({
      ...rest,
      ...stateProps,
      ...dispatchProps,
    }),
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
    isPendingProp: 'disabled',
  }),
)(ButtonCadenceReports);
