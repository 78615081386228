import React from 'react';
import get from 'lodash/get';

import LabeledInput from './LabeledInput';


const MoneyInput = props =>
  <div className='input-group'>
    <span className='input-group-addon'>$</span>
    <input {...props} />
  </div>
  ;

class PennyInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: false,
      editedValue: props.input.value ? Number(props.input.value) / 100 : '',
    };
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    if (!get(prevProps, 'input') || !get(this.props, 'input')) {
      return;
    }

    if (prevProps.input.value !== this.props.input.value && prevState.editedValue !== this.state.editedValue) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        editedValue: this.props.input.value ? Number(this.props.input.value) / 100 : '',
      });
    }
    if (prevProps.input.value !== this.props.input.value && get(this.props, 'isUpdating', false)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        editedValue: this.props.input.value ? Number(this.props.input.value) / 100 : '',
      });
    }
  }

  getInputValue = () => {
    if (this.state.focused) {
      return this.state.editedValue;
    }

    const { input: { value } } = this.props;

    return typeof value === 'number' ? (value / 100).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) : '';
  }

  getPenniesFromString = s => {
    let value = s;
    let multiplier = 1;
    if (/k$/i.test(s)) {
      multiplier = 1000;
    }
    value = value.replace(/[^\d\.]/g, '');
    value = Number.parseFloat(value, 10);
    value = Math.round(value * 100 * multiplier) | 0;
    return value;
  }

  onFocus() {
    this.setState({
      focused: true,
    });
  }

  onBlur(e) {
    let { value } = e.target;
    let multiplier = 1;
    if (/k$/i.test(value)) {
      multiplier = 1000;
    }
    value = value.replace(/[^\d\.]/g, '');
    value = Number.parseFloat(value, 10);
    value = Math.round(value * 100 * multiplier) | 0;

    this.props.input.onBlur(value);
    this.setState({
      focused: false,
      editedValue: value / 100,
    });
  }

  onChange(e) {
    if (this.state.focused) {
      this.setState({ editedValue: e.target.value });
    }
  }

  render() {
    return (
      <LabeledInput
        {...this.props}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        onChange={this.onChange}
        value={this.getInputValue()}
        ComponentClass={MoneyInput}
      />
    );
  }
}

export default PennyInput;
