import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';

import { edit } from 'actions/resource/financespayment';
import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';

export default compose(
  Resource('financespayment', {
    idPropName: ['params', 'id'],
  }),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
        sch: props.item.data.sch,
        amount: props.item.data.amount,
        notes: props.item.data.notes,
      },
    };
  }),
  reduxForm({
    form: 'FinancesPaymentEdit',
    async onSubmit(fields, dispatch, props) {
      try {
        await dispatch(edit(props.item.data.id, fields));
        dispatch(openModal('success', { message: 'Payment Updated !' }));
      }
      catch (err) {
        dispatch(openModal('error', { message: 'Payment Update Failed :(' }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <CenteredColumn sm={4}>
    <form onSubmit={handleSubmit}>
      <Field
        name='sch'
        component={LabeledInput}
        label='ACH #'
      />
      <Field
        name='amount'
        component={PennyInput}
        label='Amount'
      />
      <Field
        name='notes'
        component={GrowableInput}
        label='Notes'
        props={{
          Component: LabeledInput,
          ComponentClass: 'textarea',
          placeholder: 'Enter details about the payment (optional).',
        }}
      />
      <div className='text-right'>
        <button className='btn btn-orange' type='submit' disabled={submitting}>Update Payment</button>
      </div>
    </form>
  </CenteredColumn>
);
