import React from 'react';

const GenerateTokenInput = ({ viewToken, setViewToken, generateAPIToken, token, setToken, openModal }) => {
    const onViewToken = e => {
        e.preventDefault();
        setViewToken(!viewToken);
    }
    const onGenerateApiToken = async () => {
        const { token } = await generateAPIToken();
        setToken(token);
    }
    const onCopy = e => {
        e.preventDefault();
        navigator.clipboard.writeText(token);
    }
    const onConfirmationModal = e => {
        e.preventDefault();
        openModal('confirm', {
            type: 'generateNewToken_save',
            args: [{
                onConfirm: onGenerateApiToken,
            }],
        });
    }
    return <div style={{ width: '100%' }}>
        <div class="input-group input_token" style={{ width: '100%' }}>
            <input
                type="text"
                class="form-control rounded-0"
                placeholder="***************************"
                value={viewToken ? token : '***************************'}
                disabled
            />
            <div class="input-group-prepend">
                <button
                    className="btn btn-primary btn-lg btn-outline btn-white rounded-0"
                    style={{ marginRight: 5 }}
                    onClick={onViewToken}
                >
                    {viewToken ? 'Hide' : 'view'}
                </button>
                <button
                    className="btn btn-primary btn-lg btn-outline btn-white rounded-0"
                    style={{ marginRight: 5 }}
                    onClick={onCopy}
                >
                    Copy
                </button>
            </div>
        </div>
        <div style={{ float: 'right', marginTop: 10 }}>
            <button
                className="btn btn-warning btn-lg btn-outline btn-white rounded-0"
                onClick={onConfirmationModal}
            >
                Change
            </button>
        </div>
    </div>
}

export default GenerateTokenInput;