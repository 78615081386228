/* global API */

const fetchFreightClasses = () => async (
  dispatch,
  getState
) => {
  const res = await global.fetch(`${API.host}/funding_requests/freight_classes/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });
  const json = await res.json();
  const keys = Object.keys(json);
  const values = Object.values(json);

  const classes = [];

  for (let i = 0; i < keys.length; i++) {
    classes.push({
      ...values[i],
      id: keys[i],
    });
  }

  return classes;
};

export default fetchFreightClasses;
