/* global API */
import { compose } from 'redux-v3';

import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import Deleteable from 'datatypes/compose/resource/Deleteable';
import FetchError from 'datatypes/FetchError';
import convertToDotNotation from 'helpers/convertToDotNotation';
import { get } from 'lodash';

const factoringclient = compose(
  Singleton('factoringclient'),
  Editable({
    url: id => `factoring/${id}/`,
  }),
  Deleteable({
    url: id => `factoring/${id}/`,
  }),
  Fetchable({
    url: id => `factoring/${id}/`,
    expirationTime: -Infinity,
  }),
)();

export const {
  selector,
  fetch,
  fetchIfNeeded,
  edit,
  doDelete,
} = factoringclient;

export default factoringclient;


export const USER_EDIT_FACTORING_CLIENT = Symbol('USER_EDIT_FACTORING_CLIENT');
export const userEditClient = data => async (dispatch, getState) => {
  const state = getState();
  const headers = {
    authorization: `bearer ${state.user.token}`,
  };
  let body = null;
  if (data.attachments) {
    body = new FormData();
    const { attachments, ...rest } = data;
    for (const attachment of attachments) {
      body.append('attachments', attachment);
    }
    const dot_notation_data = convertToDotNotation(rest);
    for (const key in dot_notation_data) {
      body.append(key, dot_notation_data[key]);
    }
  }
  else {
    headers['content-type'] = 'application/json';
    body = JSON.stringify(data);
  }
  const res = await global.fetch(`${API.host}/factoring/profiles/`, {
    method: 'PATCH',
    headers,
    body,
  });
  const json = await res.json();
  if (res.status !== 200) {
    throw new FetchError(res.status, json);
  }
  dispatch({
    type: USER_EDIT_FACTORING_CLIENT,
    payload: json,
  });
  return json;
};


/**
 * Function to get the URL for an attachment on a factoringclient
 * @param {string} id - the ID of the factoring client which this attachment is attached to
 * @param {string} attachment - the name of the attachment
 * @returns {Promise<string>} a promise that resolves with the given url
 */
export const getAttachment = (id, attachment) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/factoring/applications/${id}/?attachment=${attachment}`, {
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  const text = await res.text();
  if (res.status !== 200) {
    throw new FetchError(res.status, text);
  }
  return text.slice(1, -1);
};
export const FACTORING_ATTACHMENT_COLLATED_FETCH = Symbol('FACTORING_ATTACHMENT_COLLATED_FETCH');
export const FACTORING_ATTACHMENT_COLLATED_SUCCESS = Symbol('FACTORING_ATTACHMENT_COLLATED_SUCCESS');
export const FACTORING_ATTACHMENT_COLLATED_ERROR = Symbol('FACTORING_ATTACHMENT_COLLATED_ERROR');
/**
 * Function to get the URL for an attachment on a factoringclient
 * @param {string} id - the ID of the factoring client which this attachment is attached to
 * @returns {Promise<string>} a promise that resolves with the given url
 */
export const getCollatedAttachment = id => async (dispatch, getState) => {
  const state = getState();
  dispatch({
    type: FACTORING_ATTACHMENT_COLLATED_FETCH,
    payload: {
      id,
    },
  });
  try {
    const res = await global.fetch(`${API.host}/factoring/${id}/attachments/collated/`, {
      headers: {
        authorization: `bearer ${state.user.token}`,
      },
    });
    const json = await res.json();
    if (res.status !== 200) {
      throw new FetchError(res.status, json);
    }
    dispatch({
      type: FACTORING_ATTACHMENT_COLLATED_SUCCESS,
      payload: {
        id,
        data: json,
      },
    });
    return json;
  }
  catch (err) {
    dispatch({
      type: FACTORING_ATTACHMENT_COLLATED_ERROR,
      payload: {
        id,
        error: err,
      },
    });
    throw err;
  }

};
export const transferDocument = id => async (dispatch, getState) => {
  const state = getState();

  const res = await global.fetch(`${API.host}/factoring/${id}/send_transfer_packet/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  const json = await res.json();
  if (res.status !== 204) {
    throw new FetchError(res.status, json);
  }
  return json;
};
export const selectorCollatedAttachement = (state, id) => get(state, `resource.factoringclient[${id}].collated`, {});
export const FACTORING_CLIENT_ATTACHMENT_DELETE = Symbol('FACTORING_CLIENT_ATTACHMENT_DELETE');
export const deleteAttachment = (id, attachment) => async (dispatch, getState) => {
  const state = getState();

  const res = await global.fetch(`${API.host}/factoring/${id}/attachments/${attachment}/`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  if (res.status !== 204) {
    const text = await res.text();
    throw new FetchError(res.status, text);
  }
  dispatch({
    type: FACTORING_CLIENT_ATTACHMENT_DELETE,
    payload: {
      id,
      attachment,
    },
  });
};

export const getVoidedCheck = (id, attachment) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/factoring/applications/${id}/?voided_checks=${attachment}`, {
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  const text = await res.text();
  if (res.status !== 200) {
    throw new FetchError(res.status, text);
  }
  return text.slice(1, -1);
};

export const FACTORING_CLIENT_VOIDED_CHECK_DELETE = Symbol('FACTORING_CLIENT_VOIDED_CHECK_DELETE');
export const deleteVoidedCheck = (id, attachment) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/factoring/applications/${id}/?voided_checks=${attachment}`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  if (res.status !== 204) {
    const text = await res.text();
    throw new FetchError(res.status, text);
  }
  dispatch({
    type: FACTORING_CLIENT_VOIDED_CHECK_DELETE,
    payload: {
      id,
      voided_check: attachment,
    },
  });
};

