import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { formValueSelector } from 'redux-form';

import OrderSummary from 'components/pure/OrderSummary';

const selector = formValueSelector('SettingsBillingSubscriptionForm');

export default compose(
  connect(state => ({
    ...selector(state, 'package'),
  })),
)(OrderSummary);
