import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import renderNothing from 'recompose/renderNothing';
import setDisplayName from 'recompose/setDisplayName';


export default cond => compose(
  setDisplayName('nothingIf'),
  branch(
    cond,
    renderNothing,
    Component => Component,
  ),
);
