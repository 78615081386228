/**
 * Actions relating to the LoadSearch resource
 * @module actions/resource/loadsearch
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/resource/load
 * @requires datatypes/Resource
 * @requires datatypes/Point
 * @requires datatypes/PermissionsError
 */
import moment from 'moment';

import { setScrollToItem } from 'actions/ui';
import { loadViewedHistory, loadOpenHistory } from 'actions/resource/load';
import Resource from 'datatypes/Resource';
import Point from 'datatypes/Point';
import PermissionsError from 'datatypes/PermissionsError';


/**
 * The Resource that handles LoadSearch parsing and related actions
 * @extends module:datatypes/Resource~Resource
 */
class LoadSearchResource extends Resource {
  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return {
      isFetching: false,
      globals: {
        ...this.options.globals,
        viewed: loadViewedHistory.has(json.id),
        open: loadOpenHistory.has(json.id),
      },
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: {
        ...(oldValue ? oldValue.data : undefined),
        ...json,
        deadhead: typeof json.deadhead === 'number' ? json.deadhead | 0 : null,
        weight: !json.weight || json.weight === 1 ? 0 : json.weight,
        length: !json.length || json.length === 1 ? 0 : json.length,
        origin_coords: Point.fromGeoPoint(json.origin_coords),
        destination_coords: Point.fromGeoPoint(json.destination_coords),
        time_start: moment(json.time_start),
        time_end: moment(json.time_end),
        time_delivery: json.time_delivery ? moment(json.time_delivery) : null,
        time_posted: moment(json.time_posted),
        ...(() => {
          const user = {
            data: json.user,
            err: null,
          };
          if (!json.user) {
            user.data = {};
            user.err = new PermissionsError('not allowed');
          }
          return { user };
        })(),
      },
    };
  }
}

/**
 * Singleton for our LoadSearchResource
 */
export const loadSearchResource = new LoadSearchResource('loadsearch', {
  url: id => `loads/${id}`,
  expirationTime: Infinity,
  globals: {
    open: false,
    viewed: false,
  },
});

export const {
  fetchIfNeeded,
  getItem,
} = loadSearchResource;
export default loadSearchResource;

/**
 * Set load search viewed action. Marks the load with `id` as viewed
 * @event SEARCH_LOAD_SET_VIEWED
 * @property {symbol} type - Symbol(SEARCH_LOAD_SET_VIEWED)
 * @property {string} payload - the id of the load to be marked as viewed
 */
export const SEARCH_LOAD_SET_VIEWED = Symbol('SEARCH_LOAD_SET_VIEWED');
/**
 * Set load search viewed action
 * @param {string} id - the id of the load to be marked as viewed
 * @fires SEARCH_LOAD_SET_VIEWED
 */
export const setViewed = id => dispatch => {
  loadViewedHistory.set(id);
  dispatch(setScrollToItem(id));
  return dispatch({
    type: SEARCH_LOAD_SET_VIEWED,
    payload: id,
  });
};

/**
 * Toggle load search 'open' action. Toggles the load with `id` as open or not open
 * @event SEARCH_LOAD_TOGGLE_OPEN
 * @property {symbol} type - Symbol(SEARCH_LOAD_TOGGLE_OPEN)
 * @property {string} payload - the id of the load to be toggled
 * @property {any} meta
 */
export const SEARCH_LOAD_TOGGLE_OPEN = Symbol('SEARCH_LOAD_TOGGLE_OPEN');
/**
 * Toggle load search 'open' action.
 * @property {string} id - the id of the load to be toggled
 * @fires SEARCH_LOAD_TOGGLE_OPEN
 */
export const toggleOpen = id => dispatch => {
  loadOpenHistory.toggle(id);
  loadViewedHistory.set(id);
  return dispatch({
    type: SEARCH_LOAD_TOGGLE_OPEN,
    payload: id,
    meta: ['loadsearch', 'item', 'click'],
  });
};
