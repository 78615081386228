/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';

import * as validate from 'helpers/validate';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';

import { fetchFactoringProfile } from 'actions/user';
import { userEditClient } from 'actions/resource/factoringclient';
import { openModal } from 'actions/ui';

import spinnerIf from 'components/hoc/spinnerIf';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import segmentEvents from '../../helpers/segmentEvents';


const FactoringClient = compose(
  connect(
    state => {
      return {
        comfreight_email: get(state.resource.user, [state.user.id, 'data', 'email']),
        item: state.user.factoring,
      };
    },
    {
      fetchFactoringProfile,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchFactoringProfile();
    },
  }),
  spinnerIf(({ item }) => !item || !item.data || item.isFetching || !item.data.id),
  mapProps(({ item, comfreight_email }) => ({
    comfreight_email,
    initialValues: {
      company_name: item.data.company_profile.name,
      company_address: item.data.company_profile.address,
      company_phone: item.data.company_profile.phone_number,
      owner_name_first: item.data.owner_profile.name_first,
      owner_name_last: item.data.owner_profile.name_last,
      owner_address: item.data.owner_profile.address,
      owner_phone_number_cell: getPhoneParts(item.data.owner_profile.phone_number_cell).phone,
      owner_phone_number_cell_ext: getPhoneParts(item.data.owner_profile.phone_number_cell).ext,
      owner_email: item.data.owner_profile.email,
      should_email_user: item.data.should_email_user,
    },
  })),
  reduxForm({
    form: 'FactoringUserProfileForm',
    async onSubmit(fields, dispatch) {
      try {
        await dispatch(userEditClient({
          owner_profile: {
            name_first: fields.owner_name_first,
            name_last: fields.owner_name_last,
            address: typeof fields.owner_address === 'string' ? {
              street_one: fields.owner_address,
              city: '',
              state: '',
              zip: '',
            } : {
              street_one: fields.owner_address.street_one,
              city: fields.owner_address.city,
              state: fields.owner_address.state,
              zip: fields.owner_address.zip,
            },
            phone_number_cell: normalizeFormPhone(fields, 'owner_phone_number_cell'),
            email: fields.owner_email,
          },
          should_email_user: fields.should_email_user,
        }));
        window.analytics.track(segmentEvents.USER_UPDATED_HAULPAY_PROFILE);
        dispatch(openModal('success', { message: 'Successfully updated.' }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Error updating :(' }));
      }
    },
  }),
)(({ handleSubmit, submitting, comfreight_email }) =>
  <form onSubmit={handleSubmit} style={{ margin: '1em 0' }}>
    <div className='row'>
      <div className='col-xs-12 col-md-6'>
        <Field
          name='company_name'
          component={LabeledInput}
          label='Legal Business Name'
          disabled={true}
        />
        <Field
          name='company_address'
          component={GoogleLocationInput}
          label='Business Address'
          inputProps={{ disabled: true }}
        />
      </div>
      <div className='col-xs-12 col-md-6'>
        <Field
          name='owner_name_first'
          component={LabeledInput}
          validate={validate.compose(
            validate.vjs.presence(),
          )}
          label={'Owner\'s First Name'}
        />
        <Field
          name='owner_name_last'
          component={LabeledInput}
          validate={validate.compose(
            validate.vjs.presence(),
          )}
          label={'Owner\'s Last Name'}
        />
        <Field
          name='owner_address'
          component={GoogleLocationInput}
          label={'Owner\'s Address'}
          setValueWithoutConfirming={true}
        />
        <div className='row'>
          <div className='col-md-12'>
            <Field
              name='owner_phone_number_cell'
              component={PhoneInput}
              validate={validate.compose(validate.vjs.presence(), validate.phone())}
              label={'Owner\'s Cell Phone'}
            />
          </div>
          {/*<div className='col-md-6'>
            <Field name='owner_phone_number_cell_ext' component={LabeledInput} label='Phone Ext.' type='number' />
          </div>*/}
        </div>
        <Field
          name='owner_email'
          component={LabeledInput}
          validate={validate.compose(
            validate.vjs.presence(),
            validate.vjs.email(),
          )}
          label={'Owner\'s Email (for Haul Pay notifications)'}
        />
        <div>
          <Field
            name='should_email_user'
            component={CheckboxInput}
            label={`Email me as well (${comfreight_email})`}
          />
        </div>
      </div>
    </div>
    <div className='text-right'>
      <button className='btn btn-orange' type='submit' disabled={submitting}>Update</button>
    </div>
  </form>
);

export default () =>
  <div>
    <FactoringClient />
  </div>
  ;
