/**
 * Middleware for Sentry, the javascript error reporting SDK for {@link https://sentry.io/welcome/}
 * @module middleware/raven
 * @since 3.0.0
 * @requires datatypes/PermissionsError
 */
/*global RAVEN_KEY process GIT_HASH VERSION */
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { throttle } from 'lodash';

import PermissionsError from '../datatypes/PermissionsError';

let environment;

const hostname = window.location.hostname;

if (hostname.includes('staging')) {
  environment = 'Staging';
}
else if (process.env.NODE_ENV === 'production') {
  environment = 'Production';
}

Sentry.init({
  dsn: "https://473169d063c84572859f1cfef004c484@o56928.ingest.sentry.io/122065",
  environment: environment || process.env.NODE_ENV,
  release: VERSION,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.2,
  ignoreErrors: [
    "ResizeObserver loop limit exceeded"
  ],
});

// custom logger for errors logged by sentry
const logError = process.env.NODE_ENV === 'development' ? console.error.bind(console, '[Sentry] ') : f => f;

const crash = throttle(e => {
  // NOTE: e.preventDefault() must be manually called to prevent the default
  // action which is currently to log the stack trace to console.warn
  // e.preventDefault();
  const reason = e?.detail?.reason
  if (!(reason instanceof PermissionsError)) {
    logError(reason);
    Sentry.captureException(reason);
  }
}, 60000);

// capture unhandled rejections thrown by bluebird
window.addEventListener('unhandledrejection', crash);

export default store => next => action => {
  try {
    // Sentry.addBreadcrumb({
    //   category: 'redux-v3',
    //   message: action,
    //   level: 'info',
    // });
    return next(action);
  }
  catch (err) {
    if (!(err instanceof PermissionsError)) {
      logError(err);
      Sentry.captureException(err, {
        extra: {
          action,
          state: store.getState(),
        },
      });
    }
    // throw err;
  }
};
