/**
 * Actions relating to the Activity resource
 * @module actions/resource/activity
 * @since 3.0.0
 * @requires actions/ui
 * @requires datatypes/Resource
 * @requires datatypes/PaginatedResource
 * @requires datatypes/APIFetch
 */
/* global API */
import moment from 'moment';
import UrlAssembler from 'url-assembler';

import { setScrollToItem } from 'actions/ui';

import ControlledResource from 'datatypes/ControlledResource';
import PaginatedResource from 'datatypes/PaginatedResource';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';


/**
 * The ControlledResource that handles Activity parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class ActivityResource extends ControlledResource {

  parseMany(oldValues, newValues) {
    return newValues.reduce((acc, curr) => {
      acc[curr._id.$oid] = this.parse(oldValues[curr.id], curr);
      return acc;
    }, {});
  }

  parse(oldValue, json) {
    return super.parse(oldValue, {
      ...json,
      id: json._id.$oid,
      alert_type: json.alert_type,
      time_posted: moment.utc(json.created_at),
      item_type: json.item_type,
      item_id: json[`${json.item_type}_id`],
      sub_item_type: json.sub_item_type,
      sub_item_id: json.sub_item_type ? json[`${json.sub_item_type}_id`] : null,
      message: json.message,
      modified: moment.utc(json.modified_at),
      user: json.user_id,
      viewed_on: json.viewed_on ? moment(json.viewed_on) : null,
    });
  }
}

/**
 * The PaginatedResource that handles the timeline Activity pagination and related actions
 * @extends module:datatypes/PaginatedResource~PaginatedResource
 */
class ActivityPaginatedResource extends PaginatedResource {

  parse(item) {
    return item._id.$oid;
  }

  /**
   * Override default `fetch` in order to account for special filtering done on this endpoint
   * @inheritdoc
   */
  fetch(data = {}, next) {
    return (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isFetching) {
        return Promise.resolve();
      }

      let { filter } = state.activity.globals;
      if (filter === 'all') {
        // don't send backend a filter if we're not filtering
        filter = undefined;
      }
      data.alert_type = filter;

      const { ordering, limit } = mystate;
      if (next === undefined) {
        next = `${API.host}/${this.options.url.segment('/').param({
          limit,
          ordering,
          ...data,
        }).toString()}`;
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: data,
      });

      return dispatch(APIFetch(next, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.FETCH_REQUEST_FAILURE,
            payload: err,
          });
          return Promise.reject(err);
        })
        .then(json => {
          return dispatch({
            type: this.actions.FETCH_REQUEST_SUCCESS,
            payload: {
              ...json,
              next: json.offset + json.limit < json.count ? `${API.host}/${this.options.url.segment('/').param({
                limit,
                ordering,
                offset: json.offset + json.limit,
                ...data,
              }).toString()}` : null,
            },
          });
        })
        .then(json => {
          return json;
        })
        ; // eslint-disable-line indent
    };
  }
}

/**
 * Singleton for our ActivityResource
 */
export const activityResource = new ActivityResource('activity', {
  url: id => `user/activity/${id}`,
});

/**
 * Singleton for our ActivityPaginatedResource
 */
export const activityPaginatedResource = new ActivityPaginatedResource('activity', {
  url: new UrlAssembler('user/activity'),
  mountPoint: 'activity',
  baseResource: activityResource,
  globals: {
    filter: 'all',
  },
});

/**
 * Set filter action. Sets the filter for the activity timeline.
 * @event ACTIVITY_SET_FILTER
 * @property {symbol} type - Symbol(ACTIVITY_SET_FILTER)
 * @property {string} payload - the filter to be set
 * @property {any} meta
 */
export const ACTIVITY_SET_FILTER = Symbol('ACTIVITY_SET_FILTER');
/**
 * Thunk for setting the ActivityPaginatedResource filter.
 * @fires ACTIVITY_SET_FILTER
 * @fires module:actions/resource/activity~ActivityPaginatedResource#PAGINATED_CLEAR
 * @fires module:actions/resource/activity~ActivityPaginatedResource#PAGINATED_FETCH_REQUEST
 * @fires module:actions/resource/activity~ActivityPaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @fires module:actions/resource/activity~ActivityPaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 */
export function setFilter(filter, meta) {
  return (dispatch, getState) => {
    const state = getState();
    if (filter === state.activity.globals.filter) {
      return Promise.resolve();
    }
    dispatch({
      type: ACTIVITY_SET_FILTER,
      payload: filter,
      meta,
    });
    dispatch(activityPaginatedResource.clear());
    return dispatch(activityPaginatedResource.fetch());
  };
}

/**
 * Set activity viewed action. Marks that an Activity in state has been viewed
 * @event ACTIVITY_SET_VIEWED
 * @property {symbol} type - Symbol(ACTIVITY_SET_FILTER)
 * @property {string} payload - the ID of the activity to save as viewed
 */
export const ACTIVITY_SET_VIEWED = Symbol('ACTIVITY_SET_VIEWED');
/**
 * Thunk for setting the viewed status on an Activity. Also saves the viewed state to LocalStorage.
 * @param {string} id - the ID of the activity to mark as viewed
 * @returns {Promise<void>}
 * @fires ACTIVITY_SET_VIEWED
 */
export const setViewed = id => async (dispatch, getState) => {
  dispatch(setScrollToItem(id));
  try {
    const res = await global.fetch(`${API.host}/user/activity/${id}/`, {
      method: 'PUT',
      headers: {
        authorization: `bearer ${getState().user.token}`,
      },
    });
    if (res.status !== 200) {
      throw new FetchError();
    }
    dispatch({
      type: ACTIVITY_SET_VIEWED,
      payload: id,
    });
  }
  catch (err) {
    console.warn(err);
  }
};
