import { compose } from 'redux-v3';
import moment from 'moment';

import Fetchable from 'datatypes/compose/resource/Fetchable';
import Singleton from 'datatypes/compose/Singleton';

export const plaidaccountResource = compose(
  Singleton('plaidaccount', {
    parse(oldValue = {}, newData) {
      return {
        isFetching: false,
        err: null,
        ...oldValue,
        fetchedAt: moment(),
        data: newData,
      };
    },
  }),
  Fetchable({
    url: id => `plaid/exchange_token/?user_id=${id}`,
  }),
)();

export const {
  fetch,
  selector,
} = plaidaccountResource;

export default plaidaccountResource;
