import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';


const FetchingButton = compose(
  connect(
    (state, ownProps) => ({
      disabled: get(state, ownProps.mountPoint).isFetching,
    }),
    undefined,
    (stateProps, dispatchProps, ownProps) => {
      const { mountPoint, ...rest } = ownProps;
      return {
        ...stateProps,
        ...rest,
      };
    }
  ),
)(props => <button {...props} />);

FetchingButton.propTypes = {
  mountPoint: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]).isRequired,
};

export default FetchingButton;
