/**
 * Generic class for controlled paginated-resource-related fetching, creating, serialization and deserialization
 * @module datatypes/ControlledPaginatedResource
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires datatypes/APIFetch
 * @requires datatypes/APIError
 */
/* global API */
import PaginatedResource from './PaginatedResource';
import APIFetch from './APIFetch';
import APIError from './error/APIError';


/**
 * ControlledPaginatedResource class
 * @extends module:datatypes/PaginatedResource~PaginatedResource
 */
export class ControlledPaginatedResource extends PaginatedResource {
  /**
   * Create a ControlledPaginatedResource. Extends [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource} constructor
   * @param {string} name - the unique identifier for this ControlledPaginatedResource
   * @param {object} options
   * @param {number} [options.acceptableCreateStatusCode] - the HTTP status code that is used to confirm a successful create request
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, options) {
    super(name, options);

    this.options = {
      ...this.options,
      acceptableCreateStatusCode: 201,
      acceptableEditStatusCode: 200,
      acceptableDeleteStatusCode: 204,
      ...options,
    };

    const upperName = this.name.toUpperCase();
    this.actions = {
      ...this.actions,
      CREATE_REQUEST: Symbol(`${upperName}_PAGINATED_CREATE_REQUEST`),
      CREATE_REQUEST_SUCCESS: Symbol(`${upperName}_PAGINATED_CREATE_REQUEST_SUCCESS`),
      CREATE_REQUEST_FAILURE: Symbol(`${upperName}_PAGINATED_CREATE_REQUEST_FAILURE`),
      EDIT_REQUEST: Symbol(`${upperName}_PAGINATED_EDIT_REQUEST`),
      EDIT_REQUEST_SUCCESS: Symbol(`${upperName}_PAGINATED_EDIT_REQUEST_SUCCESS`),
      EDIT_REQUEST_FAILURE: Symbol(`${upperName}_PAGINATED_EDIT_REQUEST_FAILURE`),
      DELETE_REQUEST: Symbol(`${upperName}_PAGINATED_DELETE_REQUEST`),
      DELETE_REQUEST_SUCCESS: Symbol(`${upperName}_PAGINATED_DELETE_REQUEST_SUCCESS`),
      DELETE_REQUEST_FAILURE: Symbol(`${upperName}_PAGINATED_DELETE_REQUEST_FAILURE`),
    };

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  /**
   * Create request action. Marks that a request to create a ControlledPaginatedResource has been made
   * @event module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
   * @property {symbol} type - Symbol({NAME}_PAGINATED_CREATE_REQUEST)
   */
  createRequest() {
    return {
      type: this.actions.CREATE_REQUEST,
      payload: undefined,
    };
  }

  /**
   * Create request success action. Marks that a request to create a ControlledPaginatedResource has been made successfully
   * @event module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_PAGINATED_CREATE_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  createRequestSuccess(json) {
    return {
      type: this.actions.CREATE_REQUEST_SUCCESS,
      payload: json,
    };
  }

  /**
   * Create request failure action. Marks that a request to create a ControlledPaginatedResource has failed
   * @event module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_PAGINATED_CREATE_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  createRequestFailure(err) {
    return {
      type: this.actions.CREATE_REQUEST_FAILURE,
      payload: err,
    };
  }

  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully creating, or an error
   * @fires module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
   * @fires module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
   * @fires module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
   */
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isCreating) {
        return Promise.resolve();
      }
      dispatch(this.createRequest());

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url}/`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            'Content-Type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableCreateStatusCode) {
          throw new APIError(res.status, await res.text());
        }
        return dispatch(this.createRequestSuccess(await res.json()));
      }
      catch (err) {
        dispatch(this.createRequestFailure(err));
        throw err;
      }
    };
  }

  edit(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isEditing) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url}/`, {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableEditStatusCode) {
          throw new APIError(res.status, await res.text());
        }
        dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: await res.json(),
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  doDelete(id, data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isDeleting) {
        return;
      }
      dispatch({
        type: this.actions.DELETE_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url}/`, {
          method: 'DELETE',
          body: JSON.stringify(data),
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableDeleteStatusCode) {
          const text = await res.text();
          throw new APIError(res.status, text);
        }
        dispatch({
          type: this.actions.DELETE_REQUEST_SUCCESS,
          payload: id,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.DELETE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  /**
   * @returns The default initial state for this ControlledPaginatedResource
   */
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      isCreating: false,
    };
  }

  /**
   * The reducer for this ControlledPaginatedResource. Manages state changes triggered by actions specific to this ControlledPaginatedResource
   * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
   * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
   * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
   * @param {object} state - the current state of this ControlledPaginatedResource
   * @param {object} action - the dispatched action
   * @returns The new state for this ControlledPaginatedResource
   */
  reduce(state, action) {
    state = super.reduce(state, action);
    switch (action.type) {
      case this.actions.CREATE_REQUEST:
        return {
          ...this.getDefaultState(),
          ...state,
          isCreating: true,
        };
      case this.actions.CREATE_REQUEST_SUCCESS: {
        let new_items;
        if (this.options.append_to_top_on_create) {
          new_items = [
            this.parse(action.payload),
            ...(state[this.options.itemsKey] || []),
          ];
        }
        else {
          new_items = [
            ...(state[this.options.itemsKey] || []),
            this.parse(action.payload),
          ];
        }
        return {
          ...this.getDefaultState(),
          ...state,
          isCreating: false,
          [this.options.itemsKey]: new_items,
        };
      }
      case this.actions.CREATE_REQUEST_FAILURE:
        return {
          ...this.getDefaultState(),
          ...state,
          isCreating: false,
          err: action.payload,
        };
      case this.actions.EDIT_REQUEST:
        return {
          ...this.getDefaultState(),
          ...state,
          isEditing: true,
        };
      case this.actions.EDIT_REQUEST_SUCCESS:
      case this.actions.EDIT_REQUEST_FAILURE:
        return {
          ...this.getDefaultState(),
          ...state,
          isEditing: false,
        };
      case this.actions.DELETE_REQUEST:
        return {
          ...this.getDefaultState(),
          ...state,
          isDeleting: true,
        };
      case this.actions.DELETE_REQUEST_SUCCESS: {
        const index = state[this.options.itemsKey].indexOf(action.payload);
        return {
          ...this.getDefaultState(),
          ...state,
          isDeleting: false,
          [this.options.itemsKey]: [
            ...state[this.options.itemsKey].slice(0, index),
            ...state[this.options.itemsKey].slice(index + 1),
          ],
        };
      }
      case this.actions.DELETE_REQUEST_FAILURE:
        return {
          ...this.getDefaultState(),
          ...state,
          isDeleting: false,
        };
    }
    return state;
  }
}

export default ControlledPaginatedResource;
