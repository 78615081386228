import React, { useState, useEffect, useContext, useCallback  } from 'react';
import { Link } from 'react-router-v4';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import formatDollars from 'helpers/formatDollars';
import moment from 'moment';
import onboardingStatus from 'helpers/walletOnboardingStatus';
import WalletContext from '../../context/WalletContext';

const WalletPendingTransactionsItems = createTableItems(
    ({ funding_request_id, created, description, invoice_number, credit_amount = undefined, debit_amount = undefined, payments_due_date, transaction_category }) => {
        return [
            moment(payments_due_date || created).format('l'),
            description,
            transaction_category === 'STRIPE_WALLET_DEPOSIT' ? invoice_number : <Link to={`/haul-pay/${funding_request_id}/transaction`} key={funding_request_id}>{invoice_number}</Link>,
            credit_amount ? formatDollars(credit_amount) : '',
            debit_amount ? formatDollars(debit_amount) : '',
        ];
    });

export default function WalletPendingTransactionsTable(props) {
    const { walletPendingTransaction, fetchWalletPendingTransaction, fetchNextWalletPendingTransaction, nextPage } = useContext(WalletContext);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        async function asyncFunction() {
            try {
                setIsFetching(true);
                await fetchWalletPendingTransaction();
            } catch (err) { }
            setIsFetching(false);
        }
        (props.onboardingStatus === onboardingStatus.ACTIVE) && asyncFunction();
    }, []);

    const fetchNext = useCallback(async() => {
            try {
                setIsFetching(true);
                await fetchNextWalletPendingTransaction();
            } catch (err) { }
            setIsFetching(false);
    }, [nextPage]);

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <ResponsiveTable
                containerProps={{
                    className: 'col-fill'
                }}
                placeholder='No results.'
                headers={['Date', 'Description', 'Invoice', 'Credit', 'Debit']}
                items={walletPendingTransaction}
                isFetching={isFetching}
                {...WalletPendingTransactionsItems}
            />
            {(nextPage && Boolean(walletPendingTransaction.length)) &&
                <button
                    style={{
                        margin: 20,
                        width: 150,
                        alignSelf: 'end'
                    }}
                    className="btn btn-orange"
                    onClick={fetchNext}
                >
                    Load More
                </button>}
        </div>

    )
}
