import React, { useCallback } from 'react';
import { compose, withState } from 'recompose';
import { connect } from 'react-redux-v5';
import {
    Field,
    reduxForm,
    getFormValues,
    getFormSyncErrors,
    reset,
    SubmissionError
} from 'redux-form';
import { Button, Glyphicon } from 'react-bootstrap';
import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import colors from 'styles/colors.json';
import DropZoneField from '../../pure/form/inputs/DropZoneField';
import Tooltip from 'components/pure/Tooltip';
import * as validate from 'helpers/validate';
import { frCarrierBulkUpload } from 'actions/fileBulkUpload';
import { splitFile } from 'helpers/excelHelpers';
import { openModal } from 'actions/ui';
import Spinner from '../../pure/Spinner';

const MAX_RETRIES = 3; // Define max retries for upload
const CHUNK_SIZE = 10; // Define your chunk size

const styles = {
    mainContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 15,
        paddingTop: 20,
    },
};

function CarrierBulkUpload({ handleSubmit, submitting, values, submitFailed, errors, change, touch, onSubmitErrors, setOnSubmitErrors }) {

    const removeFile = useCallback((filesKey, index) => {
        const files = filter(values[filesKey], file => file !== values[filesKey][index]);
        change(filesKey, files);
        touch(filesKey, true);
    }, [values]);

    const FilesTable = ({ filesKey }) => {
        const error = get(errors, filesKey);
        return (
            <div className={(error && submitFailed) ? 'col-xs-10 has-error' : 'col-xs-10'} style={{ minHeight: 100 }}>
                <table style={{ width: '100%' }}>
                    <thead style={{ borderBottom: '1pt solid rgba(97, 99, 101, 0.25)' }}>
                        <tr>
                            <th className='control-label'>File Name</th>
                            <th className='control-label' style={{ textAlign: 'right' }}>Action</th>
                        </tr>
                    </thead>
                    {values[filesKey].map((file, index) => {
                        const uploadedAttachmentError = onSubmitErrors[index];
                        return (
                            <tbody>
                                <td style={{ color: colors.DARK_GRAY }}>{file.name}</td>
                                <td style={{ textAlign: 'right' }}>
                                    <Button
                                        bsStyle="link"
                                        style={{ border: 0, color: colors.YELLOW }}
                                        onClick={event => {
                                            removeFile(filesKey, index);
                                            setOnSubmitErrors(omit(onSubmitErrors, index));
                                        }}
                                    >
                                        <Glyphicon glyph='glyphicon glyphicon-minus-sign' style={{ fontSize: 20 }} />
                                    </Button>
                                    {uploadedAttachmentError &&
                                        <Tooltip placement="top" text={uploadedAttachmentError}>
                                            <Button bsStyle="link" style={{ border: 0, color: colors.RED }}>
                                                <Glyphicon glyph='glyphicon glyphicon-info-sign' style={{ fontSize: 20 }} />
                                            </Button>
                                        </Tooltip>
                                    }
                                </td>
                            </tbody>
                        );
                    })
                    }
                </table>
            </div>
    )};
    const spinner = submitting ? <div><Spinner style={{ fontSize: 2 }} /></div> : null;
    const SubmitButtonView = ({ link }) => (
        <div className="col-xs-10">
            <a
                href='/public/other/carrier_bulk_import_example.xlsx'
                download='template_carrier_bulk_import_example.xlsx'
                style={{ float: 'left', minWidth: 100 }}
            >
                <Button>
                    <Glyphicon glyph='glyphicon glyphicon-cloud-download' /> CSV template
                </Button>
            </a>
            <Button
                disabled={submitting}
                onClick={handleSubmit}
                className='btn btn-orange'
                style={{ float: 'right', minWidth: 100 }}
            >
                Next
                {spinner}
            </Button>
        </div>
    )

    const DropZoneInput = props => (
        <Field
            component={DropZoneField}
            accept='.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv'
            validate={validate.compose(
                validate.vjs.presence({
                    allowEmpty: false,
                    message: '^Please select at least one file',
                }),
                validate.vjs.length({
                    minimum: 1,
                    message: '^Please select at least one file',
                })
            )}
            dropZoneProps={{
                multiple: false
            }}
            {...props}
        />
    )


    return(
        <div className='container'>
            <div className="col-xs-12">
                <h4>Bulk Upload</h4>
            </div>
            <div className="col-xs-12"  style={styles.mainContainer}>
                <FilesTable filesKey='attachments' />
                <div className='col-xs-10'>
                    <DropZoneInput
                        name='attachments'
                    />
                </div>
                <SubmitButtonView  />
            </div>
        </div>
    )
}

const FORM_NAME = 'CARRIER_UPLOAD_FORM';

async function handleAttachmentUpload(attachment, isCsv, dispatch) {
    const chunks = await splitFile(attachment, CHUNK_SIZE); // Use chunk size of 10 rows
    const response = { success: [], fail: [] };

    for (const chunk of chunks) {
        try {
            const chunkResponse = await frCarrierBulkUpload(chunk, isCsv, MAX_RETRIES);
            response.success.push(...chunkResponse.success);
            response.fail.push(...chunkResponse.fail);
        } catch (error) {
            handleError(error, dispatch);
        }
    }

    return response;
}

function handleError(error, dispatch) {
    let uploadErrors;

    if (error.response) {
        if (error.response.status === 403) {
            dispatch(openModal('error', { message: error.response.data.detail }));
            return;
        }

        if (Array.isArray(error.response.data?.fail)) {
            if (Array.isArray(error.response.data.fail[0])) {
                uploadErrors = error.response.data.fail[0]
                    .filter(o => typeof o === 'string')
                    .map(errorMessage => {
                        if (errorMessage.includes('Debtor has been declined')) {
                            return `${errorMessage}\nUpdate Funding request type to Non-Factored to create this funding request`;
                        }
                        return errorMessage;
                    })
                    .join('\n\n');
            } else {
                uploadErrors = error.response.data.fail.join('-');
            }

            if (Array.isArray(error.response.data.success)) {
                uploadErrors += `\n ${error.response.data.success.join('-')}`;
            }
        } else {
            uploadErrors = error.response.data;
        }
    } else if (error.request) {
        uploadErrors = error.request;
    } else {
        uploadErrors = (error.message || '').replace(
            'Debtor has been declined',
            'Update Funding request type to Non-Factored to create this funding request'
        );
    }

    throw new SubmissionError({ standard_upload_files: uploadErrors });
}

async function processAttachments(attachments, dispatch) {
    let response = { success: [], fail: [] };

    for (let i = 0; i < attachments.length; i++) {
        const isCsv = attachments[i].type.includes('csv');
        try {
            const attachmentResponse = await handleAttachmentUpload(attachments[i], isCsv, dispatch);
            response.success.push(...attachmentResponse.success);
            response.fail.push(...attachmentResponse.fail);
        } catch (error) {
            throw new SubmissionError({ standard_upload_files: error });
        }
    }

    return response;
}

async function onSubmit({ attachments }, dispatch, { setOnSubmitErrors }) {
    try {
        const response = await processAttachments(attachments, dispatch);

        let message = '';
        let modalType = isEmpty(response.success) ? 'error' : 'success';

        if (Array.isArray(response.fail)) {
            message = response.fail
                .map(failedErrors => {
                    let errorMessage = `Load Number: ${failedErrors[0]}, ${failedErrors[1]}`;
                    if (failedErrors[1].includes('Debtor has been declined')) {
                        errorMessage += '\nUpdate Funding request type to Non-Factored to create this funding request';
                    }
                    return `${errorMessage} \n\n`;
                })
                .join('');

            if (Array.isArray(response.success)) {
                message += `\n ${response.success
                    .map(successArray => `\n Load Number: ${successArray[0]}, ${successArray[1]}`)
                    .join('')}`;
            }
        }

        dispatch(reset(FORM_NAME));
        return dispatch(openModal(modalType, { message }));
    } catch (error) {
        setOnSubmitErrors(error);
    }
}

export default compose(
    withState('onSubmitErrors', 'setOnSubmitErrors', {}),
    reduxForm({
        form: FORM_NAME,
        initialValues: {
            attachments: []
        },
        onSubmit
    }),
    connect(state => ({
        values: getFormValues(FORM_NAME)(state),
        errors: getFormSyncErrors(FORM_NAME)(state),
    }), null)
)(CarrierBulkUpload);
