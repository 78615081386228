import React, { useState } from 'react';

export default function Tabs({ tabs }) {
  const [activeTab, setActiveTab] = useState(tabs[0].title);

  return (
    <div>
      <ul className="nav nav-tabs nav-fill nav-justified">
        {tabs.map(tab => (
          <li className={`${activeTab === tab.title ? 'active' : ''}`} key={tab.title}>
            <a
              className="nav-link"
              href="#"
              onClick={(e) => {
                e.preventDefault();
                setActiveTab(tab.title);
              }}
            >
              {tab.title}
            </a>
          </li>
        ))}
      </ul>
      <div className="tab-content" style={{ margin: 10 }}>
        {tabs.map(tab => {
          if (tab.title !== activeTab) return null;
          return (
            <div key={tab.title} className="tab-pane active">
              {/* Render the component associated with the active tab */}
              {React.createElement(tab.component, tab?.props)}
            </div>
          );
        })}
      </div>
    </div>
  );
}
