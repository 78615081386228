/**
 * Component for displaying an input with automatic [Cleave.js]{@link https://github.com/nosir/cleave.js} formatting. Manually updates the cleave's saved internal value when it's changed externally (often by redux-form)
 * @module components/pure/form/inputs/CleaveInput
 * @since 3.0.0
 * @property {object} props
 * @property {any} [props....rest] - props passed to the Cleave input component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Cleave from 'cleave.js/react';
require('cleave.js/dist/addons/cleave-phone.us.js');


class CleaveInput extends Component {

  componentDidUpdate() {
    const { props } = this;
    if (props.value === '') {
      this.cleave.properties.result = props.value;
      this.cleave.updateValueState();
    }
  }

  static propTypes = {
    onBlur: PropTypes.func,
    onChange: PropTypes.func,
    onDrop: PropTypes.func,
    onFocus: PropTypes.func,
  };

  render() {
    const { props } = this;

    return (
      <Cleave {...props} ref={ref => this.cleave = ref} />
    );
  }
}

export default CleaveInput;
