
class Storage {

  constructor() {
    this.cache = Object.create(null);
    this.useCache = false;
    this.storage = localStorage;
  }

  getItem(key) {
    if (this.useCache) {
      return this.cache[key];
    }
    return this.storage.getItem(key);
  }

  setItem(key, value) {
    try {
      return this.storage.setItem(key, value);
    }
    catch (err) {
      this.useCache = true;
      this.cache[key] = value;
    }
  }

  removeItem(key) {
    return this.storage.removeItem(key);
  }
}

export default new Storage();
