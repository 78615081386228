import React, { Component } from 'react';
import { findDOMNode } from 'react-dom';
import wrapDisplayName from 'recompose/wrapDisplayName';


export default WrappedComponent => {
  class WrapperClass extends Component {
    componentDidMount() {
      const { ref } = this;
      try {
        // eslint-disable-next-line react/no-find-dom-node
        const el = findDOMNode(ref);
        el.scrollIntoView();
      }
      catch (e) {
        console.warn(e);
      }
    }

    static displayName = wrapDisplayName(WrappedComponent, 'scrollToOnMount');

    render() {
      return <WrappedComponent ref={ref => this.ref = ref} {...this.props} />;
    }
  }
  return WrapperClass;
};
