/**
 * Component for the Account section in Settings
 * @module components/container/SettingsAccount
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/EmailUpdateForm
 * @requires components/pure/form/PasswordUpdateForm
 * @requires components/stateful/StatefulTabbed
 * @requires components/container/HiddenUsers
 */
import React from 'react';
import { compose } from 'redux-v3';
import NavItem from 'react-bootstrap/lib/NavItem';

import { USER_TYPE } from 'helpers';

import getSelf from 'components/hoc/getSelf';
import Header from 'components/pure/Header';
import StatefulTabbed from 'components/stateful/StatefulTabbed';
import EmailUpdateForm from 'components/pure/form/EmailUpdateForm';
import PasswordUpdateForm from 'components/pure/form/PasswordUpdateForm';
import HelpForm from 'components/pure/form/HelpForm';
import HiddenUsers from 'components/container/HiddenUsers';


export default compose(
  getSelf,
)(({ item }) =>
  <div style={{ textAlign: 'center' }}>
    <div className='col-xs-12 col-sm-7' style={{ textAlign: 'left', float: 'none', display: 'inline-block' }}>
      <Header>Account</Header>
      {
        USER_TYPE.IS_ADMIN(item.data.type) || USER_TYPE.IS_ALADDIN_ADMIN(item.data.type) ?
          <PasswordUpdateForm form='SettingsAccountPasswordChange' />
          :
          <StatefulTabbed
            navItems={[
              <NavItem>User Settings</NavItem>,
              <NavItem>Blocked Users</NavItem>,
            ]}
            items={[
              typeof item.data.external === 'string' && item.data.external.startsWith('posteverywhere') ?
                <div>
                  <p>Your account was created with a link from Posteverywhere when it was originally created. Please contact us using the form below if you would like to update your credentials for this account. Thank you!</p>
                  <HelpForm />
                </div>
                :
                <div>
                  <EmailUpdateForm form='SettingsAccountEmailChange' />
                  <PasswordUpdateForm form='SettingsAccountPasswordChange' />
                </div>,
              <div>
                <HiddenUsers />
              </div>,
            ]}
          />
      }
    </div>
  </div>
);
