/**
 * Component that displays a countdown timer. If the passed time is after the current time, the timer will count down as the passed time approaches.
 * If the passed time is before the current time, the timer will count up as the passed time gets farther away
 * @module components/pure/Countdown
 * @since 3.0.0
 * @property {object} props
 * @property {moment} props.time - the time to count in reference to
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import padStart from 'lodash/padStart';


const UNITS = {
  DAYS: 86400000,
  HOURS: 3600000,
  MINUTES: 60000,
  SECONDS: 1000,
};

export class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timeUntil: this.getTimeUntil(this.props.time),
    };
    this.interval = undefined;
    this.tick = this.tick.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return (
      nextProps.time !== props.time ||
      nextState.timeUntil !== state.timeUntil
    );
  }

  componentDidMount() {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(this.tick, 1000);
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  static propTypes = {
    time: PropTypes.instanceOf(moment).isRequired,
  }

  tick() {
    const { time } = this.props;
    const timeUntil = this.getTimeUntil(time);
    this.setState({ timeUntil });
  }

  getTimeUntil(time) {
    let remainder = Math.abs(moment().diff(time));
    // days
    const d = Math.floor(remainder / UNITS.DAYS);
    remainder -= (d * UNITS.DAYS);
    // hours
    const h = Math.floor(remainder / UNITS.HOURS);
    remainder -= (h * UNITS.HOURS);
    // minutes
    const m = Math.floor(remainder / UNITS.MINUTES);
    remainder -= (m * UNITS.MINUTES);
    // seconds
    const s = Math.floor(remainder / UNITS.SECONDS);
    return `${d ? `${d} days, ` : ''}${h}:${padStart(m, 2, '0')}:${padStart(s, 2, '0')}`;
  }

  render() {
    const { timeUntil } = this.state;
    return (
      <span>{timeUntil}</span>
    );
  }
}

export default Countdown;
