import React from 'react';
import { compose } from 'redux-v3';
import { Field, change, reduxForm } from 'redux-form';
import { Link, browserHistory } from 'react-router-v4';
import validate from 'validate.js';
import lifecycle from 'recompose/lifecycle';

import { openModal } from 'actions/ui';
import {
  getDataFromDOT,
  getDataFromMC,
  signup,
  validateEmail,
} from 'actions/user';
import loadalertsSettingsResource from 'actions/settings/loadalerts';

import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';

import MCorDOTInputs from 'components/pure/form/inputs/MCorDOTInputs';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import ButtonOpenSearchFMCSA from 'components/container/ButtonOpenSearchFMCSA';
import MaterialIcon from 'components/pure/MaterialIcon';
import has from 'lodash/has';

const MarketingSignupForm_NAME = 'MarketingSignupForm';

const populate_marketing_signup_fields_given_mc_or_dot = get_data_fn => (mc_or_dot, dispatch) => get_data_fn(mc_or_dot)
  .then(data => {
    if (!has(data, 'search_query')) {
      dispatch(change(MarketingSignupForm_NAME, 'dba', data.dba || data.name));
      dispatch(change(MarketingSignupForm_NAME, 'location', {
        street_one: data.street_one,
        city: data.city,
        state: data.state,
        zip: data.zip,
        country: data.country || 'usa',
      }));
      dispatch(change(MarketingSignupForm_NAME, 'number_of_trucks', data.power_units));
      /* Not using today, update when FMCSA backend call outputs this data pycomfreight#2184 */
      /*
      if (data.ein) {
        data.ein = data.ein.toString(10);
        dispatch(change(MarketingSignupForm_NAME, 'tax_id', `${data.ein.slice(0, 2)}-${data.ein.slice(2)}`));
      }
      */
      // dispatch(change(MarketingSignupForm_NAME, 'has_contract_authority_status', data.contractAuthorityStatus === 'A' || data.contractAuthorityStatus === 'ACTIVE'));
      // dispatch(change(MarketingSignupForm_NAME, 'has_common_authority_status', data.commonAuthorityStatus === 'A' || data.commonAuthorityStatus === 'ACTIVE'));
      // dispatch(change(MarketingSignupForm_NAME, 'has_broker_authority_status', data.brokerAuthorityStatus === 'A' || data.brokerAuthorityStatus === 'ACTIVE'));
      // dispatch(change(MarketingSignupForm_NAME, 'is_allowed_to_operate', data.allowedToOperate === 'Y'));
      // dispatch(change(MarketingSignupForm_NAME, 'state_incorporated', data.phyState));
    }
  })
  .catch(err => console.warn(err))
  ;

const populate_marketing_fields = {
  mc: populate_marketing_signup_fields_given_mc_or_dot(getDataFromMC),
  dot: populate_marketing_signup_fields_given_mc_or_dot(getDataFromDOT),
};

const FactoringSignUpAccountForm = compose(
  reduxForm({
    form: MarketingSignupForm_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch) {
      try {
        await dispatch(signup({
          email: fields.email,
          password: fields.password,
          type: 'carrier',
          signup_url: window.location.href,
          referrer_url: document.referrer,
          carrier: {
            dba: fields.dba,
            contact_phone: normalizeFormPhone(fields, 'contact_phone'),
            contact_name: `${fields.owners_name_first} ${fields.owners_name_last}`,
            mc: fields.mc,
            dot: fields.dot,
            address: 'Please enter an address',
          },
        }, {
          dont_do_misc_stuff: true,
        }));
        await dispatch(loadalertsSettingsResource.edit({
          shipper_post_alerts: false,
          equipment_type: [],
          pickup_states: [],
        }));
        browserHistory.push('/haul-pay/join');
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Error submitting for haul pay :(' }));
      }
    },
    validate(fields) {
      const errors = validate(fields, {
        owners_name_first: {
          presence: true,
        },
        owners_name_last: {
          presence: true,
        },
        email: {
          presence: true,
          email: true,
        },
        password: {
          presence: true,
          length: {
            minimum: 4,
            maximum: 30,
          },
        },
        password_confirm: {
          presence: true,
          equality: {
            attribute: 'password',
            message: '^Must be equal to password',
          },
        },
        contact_phone: {
          presence: true,
          phone: true,
        },
        mc: {
          numericality: {
            notValid: '^Please enter numbers',
          },
        },
        dot: {
          numericality: {
            notValid: '^Please enter numbers',
          },
        },
      });
      if (!fields.mc && !fields.dot) {
        errors.mc = errors.dot = 'Please enter an MC# or DOT#';
      }
      return errors;
    },
    warn(fields) {
      if (fields.userType === 'shipper' && (/(trucking|logistics?)/i).test(fields.dba)) {
        return {
          dba: 'If you are a trucking or logistics company please select Carrier/Broker from Company Type above to sign up for correct account type.',
        };
      }
    },
    asyncBlurFields: ['email', 'dot', 'mc'],
    async asyncValidate(fields, dispatch, props, blurredField) {
      switch (blurredField) {
        case 'email':
          return validateEmail(fields.email)
            .catch(err => console.warn(err))
            .then(json => {
              if (json && json.status === 'failure') {
                throw { // eslint-disable-line no-throw-literal
                  email: <div>This email already exists, please use a different email address or <Link to='/login'>login here</Link> or reset your password for your account <Link to='/resetpassword'>here</Link>.</div>,
                };
              }
            })
          ;
        case 'dot':
          if (!fields.dot) {
            return;
          }
          return populate_marketing_fields.dot(fields.dot, dispatch).timeout(3000);
        case 'mc':
          if (!fields.mc) {
            return;
          }
          return populate_marketing_fields.mc(fields.mc, dispatch).timeout(3000);
      }
    },
  }),
)(({ handleSubmit, submitting, asyncValidating }) =>
  <form onSubmit={handleSubmit}>
    <div className='row'>
      <div className='col-xs-12 col-md-6'>
        <Field name='owners_name_first' component={LabeledInput} placeholder='First Name' className='input-lg' />
      </div>
      <div className='col-xs-12 col-md-6'>
        <Field name='owners_name_last' component={LabeledInput} placeholder='Last Name' className='input-lg' />
      </div>
    </div>
    <Field
      name='email'
      component={LabeledInput}
      placeholder='Email Address'
      type='email'
      className='input-lg'
      hasAsyncValidate={true}
      normalize={value => value.toLowerCase()}
    />
    <Field name='password' component={LabeledInput} placeholder='Create Password' type='password' className='input-lg' />
    <Field name='password_confirm' component={LabeledInput} placeholder='Confirm Password' type='password' className='input-lg' />
    <div>
      <ButtonOpenSearchFMCSA />
    </div>
    <MCorDOTInputs fieldProps={{ className: 'input-lg' }} />
    <div className='row'>
      <div className='col-md-6'>
        <Field name='contact_phone' component={PhoneInput} placeholder='My Phone Number' className='input-lg' />
      </div>
      <div className='col-md-6'>
        <Field name='contact_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' />
      </div>
    </div>
    <button className='btn btn-orange btn-block' disabled={submitting || asyncValidating} type='submit'>Try Haul Pay <MaterialIcon name='play_arrow' /></button>
  </form>
);

const MarketingSignupForm = compose(
  lifecycle({
    componentDidMount() {
      const hack = document.querySelector('.App__children');
      if (hack) {
        hack.scrollTop = 0;
      }
    },
  }),
)(({ id }) =>
  <div id={id}>
    <div className='align-left-mobile'>
      <img style={{ height: 'auto', maxWidth: '100%', padding: '1em' }} src='/public/image/comfreight haul pay.png' />
    </div>
    <FactoringSignUpAccountForm />
    <br />
    <div className='text-center' style={{ margin: '0.5em 0' }}>
      <Link to='/login?next=/haul-pay/join'>Already a HaulPay Carrier User? Go here</Link>
    </div>
  </div>
);

export default MarketingSignupForm;
