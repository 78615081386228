/**
 * Actions relating to the Load resource
 * @module actions/resource/load
 * @since 3.0.0
 * @requires actions/ui
 * @requires datatypes/ControlledResource
 * @requires datatypes/Point
 * @requires datatypes/PermissionsError
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/SavedSetting
 * @requires datatypes/APIError
 */
import ControlledResource from 'datatypes/ControlledResource';
import Point from 'datatypes/Point';
import PermissionsError from 'datatypes/PermissionsError';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import SavedSetting from 'datatypes/SavedSetting';
import APIError from 'datatypes/error/APIError';

/**
 * The Resource that handles Load parsing and related actions.
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class HaulPayLoadResource extends ControlledResource {
}
/**
 * Singleton for our HaulPayLoadResource
 */
export const haulPayLoadResource = new HaulPayLoadResource('haulPayLoad', {
  url: id => `loads/${id}`,
  expirationTime: Infinity,
  globals: {
    open: false,
    viewed: false,
  },
});

export default haulPayLoadResource;
export const {
  fetch,
  fetchIfNeeded,
  create,
  createBulk,
  edit,
  doDelete,
  getItem,
  clear,
} = haulPayLoadResource;
