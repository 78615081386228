/**
 * Component for a generic error modal
 * @module components/pure/modals/Error
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Generic
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalGeneric component
 */
import React from 'react';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Modal from '../../hoc/Modal';
import ModalGeneric from './Generic';

export const ModalError = compose(
  mapProps(props => ({
    headerStyle: {
      backgroundColor: '#d9534f',
      color: 'white',
    },
    headerIcon: 'warning',
    headerChildren: 'Error',
    message: <span>There was an error. Please email <a href='mailto:support@haulpay.io'>support@haulpay.io</a></span>,
    ...props,
  })),
  Modal,
)(ModalGeneric);

export default ModalError;
