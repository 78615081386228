/* global API */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import financespurchaseResource from '../resource/financespurchase';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';

const financespurchasesPaginatedResource = new PaginatedResource('financespurchases', {
  mountPoint: ['admin', 'finanacepurchases'],
  url: new UrlAssembler('purchases'),
  baseResource: financespurchaseResource,
});


export default financespurchasesPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = financespurchasesPaginatedResource;

export const UPDATE_STATUS_PURCHASE_RETRY = Symbol('UPDATE_STATUS_PURCHASE_RETRY');
export const reSubmitPurchase = data => {
  return async (dispatch, getState) => {
    const state = getState();
    try {
      const res = await dispatch(APIFetch(`${API.host}/purchases/${data.id}/retry/`, {
        method: 'PATCH',
        headers: {
          authorization: `bearer ${state.user.token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }));
      const json = await res.json();
      dispatch({
        type: UPDATE_STATUS_PURCHASE_RETRY,
        payload: {
          id: data.id,
          data: json,
        },
      });
      return json;
    }
    catch (err) {
      throw err;
    }
  };
};
