import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringtransactionpurchase;

import factoringtransactionpurchaseResource from 'actions/resource/factoringtransactionpurchase';
import factoringtransactionpurchasesPaginatedResource from 'actions/admin/factoringtransactionpurchases';


export default function factoringtransactionpurchaseResourceReducer(state = initialState, action) {
  state = factoringtransactionpurchaseResource.reduce(state, action);
  switch (action.type) {
    case factoringtransactionpurchasesPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringtransactionpurchaseResource.parseMany(state, action.payload),
      };
  }
  return state;
}
