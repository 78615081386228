/**
 * Actions relating to the Bid by Load resource
 * @module actions/resourceBy/load/bid
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/bid
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import bidResource from 'actions/resource/bid';


/**
 * Our Bid by Load ControlledPaginatedResourceCollection instance
 */
export const bidByLoadResource = new ControlledPaginatedResourceCollection('load', 'bid', {
  url: new UrlAssembler('load/:id/bid'),
  paginatedResourceOptions: {
    baseResource: bidResource,
  },
});

export default bidByLoadResource;
export const {
  fetch,
  fetchNext,
  create,
} = bidByLoadResource;
