import React from 'react';

export const VisibilityContext = React.createContext();

export const VisibilityProvider = ({ children }) => {
    const [isVisible, setIsVisible] = React.useState(true);

    return (
        <VisibilityContext.Provider value={{ isVisible, setIsVisible }}>
            {children}
        </VisibilityContext.Provider>
    );
}