const formatAddressFields = fields => {
  if (fields) {
    if (typeof fields === 'string') {
      return {
        street_one: fields,
        city: '',
        state: '',
        zip: '',
        country: '',
      };
    }
    return {
      street_one: fields.street_one,
      city: fields.city,
      state: fields.state,
      zip: fields.zip,
      country: fields.country,
    };

  }
  return {};

};

export default formatAddressFields;
