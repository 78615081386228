/**
 * Component that displays a collection of question and answer pairs as well as the form to create a new question for a given resource with a given ID
 * @module components/pure/QaCollection
 * @since 3.0.0
 * @requires components/container/Qa
 * @requires components/pure/form/QAForms
 * @property {object} props
 * @property {object} props.id - the ID of the resource
 * @property {object} props.resource - 'load' or 'truck', the resource that the ID refers to
 * @property {object} props.ids - a list of question IDs
 * @property {object} props.isOwner - whether the current user owns the load or truck for these questions
 * @property {object} props.handleQuestionSubmit - the submit handler for the question create form
 */
import React from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Qa from '../container/Qa';
import { RFQuestionForm } from '../pure/form/QAForms';


const QaCollection = ({ id, resource, ids, isOwner, handleQuestionSubmit }) =>
  <div>
    {isOwner ?
      <Header>Answer Question:</Header> :
      <Header>Ask Question:</Header>
    }
    {ids.length === 0 ?
      <p>No questions yet...</p>
      :
      ids.map(question_id =>
        <Qa
          key={question_id}
          id={question_id}
          resource={resource}
          resource_id={id}
          isOwner={isOwner}
        />
      )
    }
    {!isOwner ?
      <RFQuestionForm form={`QAQForm-${id}`} onSubmit={handleQuestionSubmit} />
      : null}
  </div>
  ;

QaCollection.propTypes = {
  id: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired, // needed for Answer submit
  ids: PropTypes.arrayOf(PropTypes.string).isRequired,
  isOwner: PropTypes.bool.isRequired,
  handleQuestionSubmit: PropTypes.func.isRequired,
};

export default QaCollection;
