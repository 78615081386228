import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset } from 'redux-form';
import { closeModal, openModal } from 'actions/ui';
import { create } from 'actions/resource/GetBankInfo';
import validate from 'validate.js';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import formatError from 'helpers/formatError';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';

export default compose(
  connect(
    state => {
      return {
        user_id: state.user.id,
      };
    },
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ),
  reduxForm({
    form: 'AddBankInfo',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, props) {
      const data = {
        account_name: fields.account_name,
        type: fields.type,
        user: props.fields.data.user,
        account_number: fields.account_number,
        routing_number: fields.routing_number,
        is_business: fields.is_business,
        account_number_redacted: fields.account_number.replace(/.(?=.{4})/g, 'x'),
      };
      try {
        await dispatch(create(data));
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Successfully Added Bank Information.' }));
        reset();
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: formatError(err, 'Unable to save Bank Details') }));
        dispatch(closeModal());
        reset();
      }
    },
    initialValues: {
      type: 'CHECKING',
      is_business: true,
    },
    validate(fields) {
      return validate(fields, {
        routing_number: {
          presence: true,
          length: {
            minimum: 9,
            maximum: 9,
            message: 'must be 9 digits',
          },
        },
        account_name: {
          presence: true,
        },
        type: {
          presence: true,
        },
        account_number: {
          presence: true,
          length: {
            minimum: 4,
            maximum: 17,
            message: 'must be between 4 to 17 digits',
          },
        },
      });
    },
  }),
)(({ values, submitting, handleSubmit, closeModal }) =>
  <div>
    <form onSubmit={handleSubmit}>
      <ModalBody closeModal={closeModal}>
        <Header>Add Your Bank Account</Header>
        <p className='text-red'>This payment may take up to 8 hours while we process your bank info.</p>
        <hr />
        <Field
          name='type' component={LabeledDropdown}
          data={[
            { text: 'Checking', value: 'CHECKING' },
            { text: 'Savings', value: 'SAVINGS' },
          ]}
          label='Account Type *'
        />
        <Field
          name='account_number' component={LabeledInput}
          label='Account Number *'
          placeholder='Enter valid Account Number'
        />
        <Field
          name='routing_number' component={LabeledInput}
          label='Routing Number *'
          placeholder='xxxxxxxxx'
        />
        <Field
          name='is_business'
          component={CheckboxInput}
          label={<span>Is this a Business Account?</span>}
        />
      </ModalBody>
      <ModalFooter>
        <div className='help-block pull-left'>* Required Fields</div>
        <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
        <BigOrangeSubmitButton submitting={submitting} message='Add Bank' />
      </ModalFooter>
    </form>
  </div>
);
