/**
 * Component to display a TitledGrid Section that displays the distance of an item given that item
 * @module components/container/DistanceTiltedGridSection
 * @since 3.0.1
 * @requires datatypes/Point
 * @requires components/hoc/Resource
 * @requires components/pure/TitledGrid
 * @property {object} props
 * @property {object} props.load
 * @property {object} props.load.data
 * @property {object} props.load.data.origin_coords
 * @property {object} props.load.data.destination_coords
 * @property {any} [props....rest] - props passed to containing component
 */
/* global EMPTY_PLACEHOLDER */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';
import convert from 'convert-units';

import Point from 'datatypes/Point';

import Resource from 'components/hoc/Resource';
import TitledGrid from 'components/pure/TitledGrid';


const DistanceSection = compose(
  branch(
    ({ item }) => !item || !item.data || !item.data.origin_coords || !item.data.destination_coords,
    Component => Component,
    compose(
      Resource('map', {
        resourcePropName: 'map',
        idPropName: ({ item }) => ({
          origin: item.data.origin_coords,
          destination: item.data.destination_coords,
        }),
      }),
      mapProps(({ map }) => ({
        distance: !map || map.isFetching ? '...' : convert(get(map, ['data', 'routes', '0', 'legs', '0', 'distance', 'value'])).from('m').to('mi').toLocaleString(undefined, { maximumFractionDigits: 0 }),
      })),
    ),
  ),
)(({ distance }) =>
  <TitledGrid.Section title='distance'>
    {distance ? `${distance} mi` : EMPTY_PLACEHOLDER}
  </TitledGrid.Section>
);

DistanceSection.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      origin_coords: PropTypes.instanceOf(Point),
      destination_coords: PropTypes.instanceOf(Point),
    }),
  }),
};

export default DistanceSection;
