import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringdebtor.similarfactoringdebtor;

import similarfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/similarfactoringdebtor';


export default function similarfactoringdebtorByFactoringdebtorResourceReducer(state = initialState, action) {
  state = similarfactoringdebtorByFactoringdebtorResource.reduce(state, action);
  return state;
}
