/**
 * Reducer for handling the match by truck resource actions
 * @module reducers/resourceBy/truck/match
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/truck/match
 * @listens TRUCK_MATCH_SET_VIEW
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.truck.match;

import matchByTruckResource, { TRUCK_MATCH_SET_VIEW } from '../../../actions/resourceBy/truck/match';


export default function matchByTruckResourceReducer(state = initialState, action) {
  state = matchByTruckResource.reduce(state, action);
  switch (action.type) {
    case TRUCK_MATCH_SET_VIEW:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          globals: {
            ...state[action.payload.id].globals,
            view: action.payload.view_type,
          },
        },
      };
  }
  return state;
}
