import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector } from 'redux-form';
import getContext from 'recompose/getContext';
import cs from 'classnames';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import SetValueInput from 'components/pure/form/inputs/SetValueInput';
import NumberInput from 'components/pure/form/inputs/NumberInput';


let MCorDOTInputs;

const MCorDOTInput = ({ selected_number, selected_number_prop, ...rest }) =>
  <div
    className={cs('input-group', {
      'input-group-lg': /input-lg/.test(rest.className),
      'input-group-sm': /input-sm/.test(rest.className),
    })}
    >
    <NumberInput
      {...rest}
      className={cs('form-control', rest.className)}
      placeholder={`${selected_number.toUpperCase()}:`}
    />
    <span className='input-group-btn'>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTInputs.MC}>
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTInputs.MC })} type='button'>MC</button>
      </Field>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTInputs.DOT}>
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTInputs.DOT })} type='button'>DOT</button>
      </Field>
    </span>
  </div>
  ;

MCorDOTInputs = compose(
  getContext({
    _reduxForm: PropTypes.object,
  }),
  connect(
    (state, { _reduxForm, selected_number_prop = 'selected_number' }) => {
      const selected_number = formValueSelector(_reduxForm.form)(state, selected_number_prop) || MCorDOTInputs.MC;
      return {
        selected_number,
        selected_number_prop,
      };
    },
    null,
    (stateProps, dispatchPropos, { _reduxForm, ...rest }) => {
      return {
        ...stateProps,
        ...rest,
      };
    },
  ),
)(({ selected_number, selected_number_prop, fieldProps, containerProps }) =>
  <div
    {...containerProps}
    >
    <Field
      name='dot'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTInputs.DOT },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTInput}
    />
    <Field
      name='mc'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTInputs.MC },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTInput}
    />
  </div>
);

MCorDOTInputs.MC = 'mc';
MCorDOTInputs.DOT = 'dot';

export default MCorDOTInputs;
