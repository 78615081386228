import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';

import { create } from 'actions/resource/factoringtransactionpayment';
import { closeModal, openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';

export default compose(
  Resource('factoringpayment'),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
        invoice_amount: props.item.data.amount,
        amount: 0.0,
        status: 'payment_successful',
      },
    };
  }),
  reduxForm({
    form: 'FactoringPaymentTransactionCreateForm',
    async onSubmit(fields, dispatch, id) {
      fields.funding_request = id.id;
      try {
        // replace the UUID below with the id
        await dispatch(create(fields, id.id));
        dispatch(closeModal('factoringtransactionpayment'));
        dispatch(openModal('success', { message: 'Payment Updated !' }));
      }
      catch (err) {
        dispatch(openModal('error', { message: 'Payment Update Failed :(' }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <CenteredColumn sm={4}>
    <form onSubmit={handleSubmit}>
      <Field
        name='status'
        component={LabeledDropdown}
        label='Status'
        data={[
          {
            text: 'Payment Successful',
            value: 'payment_successful',
          },
          {
            text: 'Need Review',
            value: 'needs_review',
          },
        ]}
      />
      <Field
        name='invoice_amount'
        component={PennyInput}
        label='Invoice Amount'
      />
      <Field
        name='amount'
        component={PennyInput}
        label='Amount'
      />
      <Field
        name='sch'
        component={LabeledInput}
        label='ACH #'
      />
      <Field
        name='admin_notes'
        label='Admin Notes'
        component={GrowableInput}
        props={{
          Component: LabeledInput,
          ComponentClass: 'textarea',
          placeholder: 'Enter details about the payment (optional).',
        }}
      />
      <div className='text-right'>
        <button className='btn btn-orange' type='submit' disabled={submitting}>Create Payment</button>
      </div>
    </form>
  </CenteredColumn>
);
