/**
 * Reducer for handling the rating resource actions
 * @module reducers/resource/rating
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/rating
 * @requires actions/resourceBy/user/rating
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.rating;

import ratingResource from '../../actions/resource/rating';
import ratingByUserResource from '../../actions/resourceBy/user/rating';


export default function ratingResourceReducer(state = initialState, action) {
  state = ratingResource.reduce(state, action);
  if (ratingByUserResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS') || ratingByUserResource.matchesAction(action, 'EDIT_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: ratingResource.parse(state[action.payload.id], action.payload),
    };
  }
  if (ratingByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...ratingResource.parseMany(state, action.payload.results),
    };
  }
  return state;
}
