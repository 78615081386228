import { compose } from 'redux-v3';
import moment from 'moment';

import Fetchable from 'datatypes/compose/resource/Fetchable';
import Singleton from 'datatypes/compose/Singleton';


export const factoringclientdebtorauditlogResource = compose(
  Singleton('factoringclientdebtorauditlog', {
    parse(oldValue = {}, newData) {
      return {
        isFetching: false,
        err: null,
        ...oldValue,
        fetchedAt: moment(),
        data: newData,
      };
    },
  }),
  Fetchable({
    url: id => `factoring/debtors/relationship/${id}/history/`,
  }),
)();

export const {
  selector,
  fetch,
} = factoringclientdebtorauditlogResource;

export default factoringclientdebtorauditlogResource;
