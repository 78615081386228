/**
 * Actions relating to the Loadhistory by User resource
 * @module actions/resourceBy/user/loadhistory
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires actions/resource/load
 */
import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import loadResource from 'actions/resource/load';


/**
 * Our Loadhistory by User PaginatedResourceCollection instance
 */
export const loadHistoryByUserResource = new PaginatedResourceCollection('user', 'loadhistory', {
  url: new UrlAssembler('user/:id/loads/history'),
  paginatedResourceOptions: {
    baseResource: loadResource,
  },
});

export default loadHistoryByUserResource;
export const {
  fetch,
  fetchNext,
  sort,
  clear,
} = loadHistoryByUserResource;
