/**
 * Reducer for handling the user search actions
 * @module reducers/search/user
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/search/user
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.search.user;

import userSearchResource from '../../actions/search/user';


export default function searchUserReducer(state = initialState, action) {
  state = userSearchResource.reduce(state, action);
  return state;
}
