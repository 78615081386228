
const hrefMatchesCurrentLocation = href => {
  const curr_location = location.pathname.replace(/\/$/, '');
  if (typeof href === 'string') {
    return curr_location === href.replace(/\/$/, '');
  }
  if (href instanceof RegExp) {
    return href.test(curr_location);
  }
  return false;
};

export default hrefMatchesCurrentLocation;
