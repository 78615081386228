/**
 * Actions relating to the Match by Truck resource
 * @module actions/resourceBy/truck/match
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires actions/resource/load
 */
import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import loadResource from 'actions/resource/load';


/**
 * Our Match by Truck PaginatedResourceCollection instance
 */
export const matchByTruckResource = new PaginatedResourceCollection('truck', 'match', {
  url: new UrlAssembler('truck/matches'),
  paginatedResourceOptions: {
    baseResource: loadResource,
    globals: {
      view: 'list',
    },
  },
});

export default matchByTruckResource;
export const {
  fetch,
  fetchNext,
  sort,
  clear,
} = matchByTruckResource;


/**
 * Truck match set view action. Changes the view on truck matches between map view and list view
 * @event TRUCK_MATCH_SET_VIEW
 * @property {symbol} type - Symbol(TRUCK_MATCH_SET_VIEW)
 * @property {string} payload - `'list' | 'map'`
 */
export const TRUCK_MATCH_SET_VIEW = Symbol('TRUCK_MATCH_SET_VIEW');
/**
 * Truck match set view action
 * @param {string} id - the ID of the truck whose view to set
 * @param {string} view_type - `'list' | 'map'`
 * @fires TRUCK_MATCH_SET_VIEW
 */
export function setView(id, view_type) {
  return {
    type: TRUCK_MATCH_SET_VIEW,
    payload: { id, view_type },
  };
}
