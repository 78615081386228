import React from 'react';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CleaveInput from 'components/pure/form/inputs/CleaveInput';

const PhoneInput = props =>
  <LabeledInput
    ComponentClass={CleaveInput}
    type='tel'
    options={{
      phone: true,
      phoneRegionCode: 'US',
      delimiter: '-',
    }}
    {...props}
  />
  ;

export default PhoneInput;
