/**
 * Component to display a map that displays a single load's route
 * @module components/container/LoadDetailsMap
 * @since 3.0.0
 * @requires components/hoc/Resource
 * @requires components/hoc/nothingIfFetching
 * @requires components/pure/GoogleMap
 * @property {object} props
 * @property {object} props.id - the ID of the load
 */
import React from 'react';
import { compose } from 'redux-v3';
import shouldUpdate from 'recompose/shouldUpdate';
import get from 'lodash/get';

import Resource from 'components/hoc/Resource';
import nothingIfFetching from 'components/hoc/nothingIfFetching';
import GoogleMap, { LoadIcon } from 'components/pure/GoogleMap';


export default compose(
  Resource('load'),
  nothingIfFetching(),
  Resource('map', {
    resourcePropName: 'map',
    idPropName: ({ item }) => ({
      origin: [item.data.origin_address, item.data.origin_city, item.data.origin_state, item.data.origin_country].filter(Boolean).join(' '),
      destination: [item.data.destination_address, item.data.destination_city, item.data.destination_state, item.data.destination_country].filter(Boolean).join(' '),
    }),
  }),
  nothingIfFetching('map'),
  shouldUpdate((props, nextProps) => !(
    get(props, ['map', 'data']) === get(nextProps, ['map', 'data']) &&
    get(props, ['item', 'data', 'origin_coords']) === get(nextProps, ['item', 'data', 'origin_coords']) &&
    get(props, ['item', 'data', 'destination_coords']) === get(nextProps, ['item', 'data', 'destination_coords'])
  )),
)(({ item, map }) => {
  const leg = get(map, ['data', 'routes', '0', 'legs', '0']);
  let route_points, origin_point, destination_point;
  if (leg) {
    route_points = leg.steps.map(step => step.polyline.points);
    origin_point = leg.start_location.toJSON();
    destination_point = leg.end_location.toJSON();
  }
  else {
    route_points = [];
    origin_point = item.data.origin_coords.toLatLng();
    destination_point = item.data.destination_coords.toLatLng();
  }

  return (
    <GoogleMap
      style={{
        height: 400,
        padding: '0 20px',
      }}
      points={[
        {
          position: origin_point,
          icon: LoadIcon,
          label: 'A',
        },
        {
          position: destination_point,
          label: 'B',
        },
      ]}
      routes={[route_points]}
    />
  );
});
