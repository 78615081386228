/**
 * Reducer for handling the truck search actions
 * @module reducers/search/truck
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/search/truck
 * @listens SEARCH_TRUCK_SET_VIEW
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.search.truck;

import truckSearchResource, { SEARCH_TRUCK_SET_VIEW } from '../../actions/search/truck';


export default function searchTruckReducer(state = initialState, action) {
  state = truckSearchResource.reduce(state, action);
  switch (action.type) {
    case SEARCH_TRUCK_SET_VIEW:
      return {
        ...state,
        globals: {
          ...state.globals,
          view: action.payload,
        },
      };
  }
  return state;
}
