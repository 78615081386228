import { createAction } from 'redux-starter-kit';
import * as TYPES from '../types';
import API from '../API';

const updateCategories = ({ type = 'factoring', ...payload }) => async (
  dispatch,
  getState
) => {
  const { files } = getState().upload;
  const newFiles = files.slice(0);
  files[payload.position].categories = payload.categories;

  const file = files[payload.position];
  let response = {};
  if (type === 'factoring') {
    response = await dispatch(API.updateAttachmentCategory(
      file.requestId,
      file.file_id,
      payload.categories,
      payload.visible_to
    ));
  }
  else if (type === "CO_BROKER_AGREEMENT_DOCS") {
    response = await dispatch(API.updateFactoringPaymentProfileAttachment(
      file.requestId,
      file.file_id,
      payload.categories
    ));
  }
  else if (type === "CO_BROKER_DEBTOR_AGREEMENT_DOCS") {
    response = await dispatch(API.updateFactoringDebtorAttachment(
      file.requestId,
      file.file_id,
      payload.categories
    ));
  }
  else {
    response = await dispatch(API.updateProfileAttachmentCategory(
      file.requestId,
      file.file_id,
      payload.categories,
      payload.visible_to
    ));
  }
  dispatch(
    createAction(TYPES.UPDATE_CATEGORIES)({
      ...payload,
      files: newFiles,
    })
  );
  return response;
};

export default updateCategories;
