/**
 * Actions relating to the factoringpayment resource
 * @module actions/resource/factoringpayment
 * @since 3.0.1
 * @requires datatypes/compose/resource
 * @requires datatypes/compose/resource/Createable
 * @requires datatypes/compose/resource/Fetchable
 * @requires datatypes/compose/resource/Editable
 * @requires datatypes/FetchError
 * @requires datatypes/DotNotationFormData
 */
/* global API */
import { compose } from 'redux-v3';

import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';


class GetCardInfoResource extends compose(
  Editable(),
  Createable(),
  Fetchable({
    url: id => `factoring/funding/request/${id}/?details=extended`,
  }),
)() {
  // overwrite the create function injected earlier by `Createable`
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, CREATE_KEY);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.vgs}/accounts/`, {
          method: 'POST',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 201) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();

        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
  // overwrite the edit function injected earlier by `Editable`
  edit(id, data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, id);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      try {
        const { attachments = [] } = data;
        data.attachments = attachments.map(file => {
          return {
            filename: file.name,
            category: file.category,
          };
        });
        const res = await dispatch(APIFetch(`${API.host}/factoring/funding/request/${id}/?details=extended`, {
          method: 'PATCH',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();

        await Promise.all(json.attachments.slice(item.data.attachments.length).map(({ upload_url: { url, fields } }, i) => {
          const formData = new FormData();
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              formData.append(key, fields[key]);
            }
          }
          formData.append('file', attachments[i]);
          return global.fetch(url, {
            method: 'POST',
            body: formData,
          });
        }));

        return dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: {
            id,
            json,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: {
            id,
            err,
          },
        });
        throw err;
      }
    };
  }
}

const getcardinfo = new GetCardInfoResource('getcardinfo');

export const {
  selector,
  fetch,
  fetchIfNeeded,
  create,
  edit,
} = getcardinfo;
export default getcardinfo;
