/* global API */
import axios from 'axios';
import isEmpty from 'lodash/isEmpty';

axios.defaults.baseURL = API.host;

const updateAttachmentCategory = (
  requestId,
  attachmentId,
  category,
  visibleTo = []
) => async (
  dispatch,
  getState
) => {
  const url = `/funding_requests/${requestId}/attachments/${attachmentId}/`;
  const options = {
    url,
    method: 'PATCH',
    data: {
      category,
      ...(() => !isEmpty(visibleTo) ? { visible_to: visibleTo } : {})()
    },
  };
  return axios(options);
};

const updateProfileAttachmentCategory = (
  factoringId,
  attachmentId,
  category
) => async (
  dispatch,
  getState
) => {
  const url = `/factoring/payment_profiles/${factoringId}/attachments/${attachmentId}/`;
  const options = {
    url,
    method: 'PATCH',
    data: {
      category,
    },
  };
  return axios(options);
};

const addAttachementToProfile = ({
  id,
  filename,
  category = '',
}) => async (
  dispatch,
  getState
) => {
  const options = {
    method: 'PATCH',
    data: {
      attachments: [{
        filename,
        category,
      }],
    },
    url: `/factoring/funding/request/${id}/`,
  };
  return axios(options);
};

const addFundingRequestAttachment = ({
  id,
  filename,
  category = ''
}) => async (
  dispatch,
  getState
) => {
  const url = `/factoring/funding/request/${id}/attachments/`;
  const options = {
    url,
    method: 'POST',
    data: {
      attachments: [
        {
          filename,
          category,
        }
      ]
    }
  };
  return axios(options);
};

const createPaymentProfileAttachments = ({
  id,
  filename,
  category = '',
}) => async (
  dispatch,
  getState
) => {
  const url = `/factoring/payment_profiles/${id}/attachments/`;
  const options = {
    url,
    method: 'POST',
    data: {
      filename,
      category,
    },
  };
  return axios(options);
};

const fetchPaymentProfileAttachments = id => async (
  dispatch,
  getState
) => async (
  dispatch,
  getState
) => {
  const url = `/factoring/payment_profiles/${id}/attachments/`;
  const options = {
    url,
    method: 'GET',
  };
  const response = await axios(options);
  return response.data;
};

const createFactoringPaymentProfileAttachment = ({
  id,
  filename,
  // category
}) => async (
  dispatch,
  getState
) => {
    const url = `/factoring/payment_profiles/relations/${id}/attachments/`;
    const options = {
        url,
        method: 'POST',
        data: {
            filename,
            // category
        }
    };
    return axios(options);
};

const createFactoringDebtorAttachment = ({
  id,
  filename,
  // category
}) => async (
  dispatch,
  getState
) => {
    const url = `/factoring/debtor/relations/${id}/attachments/`;
    const options = {
        url,
        method: 'POST',
        data: {
            filename,
            // category
        }
    };
    return axios(options);
};

const updateFactoringPaymentProfileAttachment = (
  id,
  attachmentId,
  category
) => async (
  dispatch,
  getState
) => {
    const url = `/factoring/payment_profiles/relations/${id}/attachments/${attachmentId}/`;
    const options = {
        url,
        method: 'PUT',
        data: {
            category
        }
    };
    const response = await axios(options);
    return response.data
};

const updateFactoringDebtorAttachment = (
  id,
  attachmentId,
  category
) => async (
  dispatch,
  getState
) => {
    const url = `/factoring/debtor/relations/${id}/attachments/${attachmentId}/`;
    const options = {
        url,
        method: 'PUT',
        data: {
            category
        }
    };
    const response = await axios(options);
    return response.data
};

export default {
  createPaymentProfileAttachments,
  addAttachementToProfile,
  updateAttachmentCategory,
  updateProfileAttachmentCategory,
  fetchPaymentProfileAttachments,
  addFundingRequestAttachment,
  createFactoringPaymentProfileAttachment,
  updateFactoringPaymentProfileAttachment,
  updateFactoringDebtorAttachment,
  createFactoringDebtorAttachment,
};
