import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { fetchPaymentHistoryCSV } from 'actions/factoring';
import { openModal } from 'actions/ui';

import Spinner from 'components/pure/Spinner';
import wrapPromisePending from 'components/hoc/wrapPromisePending';


export default compose(
  connect(
    null,
    (dispatch, { start, end, debtor, client, status }) => ({
      async onClick(e) {
        e.preventDefault();
        e.stopPropagation();
        try {
          const { download_url } = await dispatch(fetchPaymentHistoryCSV({ start, end, debtor, client, status }));
          window.open(download_url);
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', {
            message: 'Failed to fetch CSV data',
          }));
        }
      },
    }),
    (stateProps, dispatchProps, { start, end, debtor, client, status, ...rest }) => ({
      ...rest,
      ...stateProps,
      ...dispatchProps,
    }),
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
    isPendingProp: 'disabled',
  }),
)(props => {
  if (props.disabled) {
    return (
      <div
        style={{
          ...props.style,
          paddingRight: 20
        }}>
        <Spinner style={{ fontSize: 4 }} />
      </div>
    )
  }
  return (
    <img
      src='/public/image/icon/csv.png'
      role='button'
      title='Download CSV'
      {...props}
      style={{
        height: '2em',
        width: '2em',
        ...props.style,
      }}
    />
  )
}
);
