import React, { createContext, useState } from 'react';
import { fetchStripeCards } from 'actions/stripeCards';

const CardContext = createContext();

export const ContextProvider = ({ children }) => {
    const [cards, setCards] = useState([]);
    const [reload, setReload] = useState(false);

    const setReloadPage = () => {
        setReload(prevReload => !prevReload);
    }

    const fetchAllStripeCards = async (accountId) => {
        try {
            const results = await fetchStripeCards(accountId);
            setCards(results);
        } catch (error) {
            throw error;
        }
    };


    return (
        <CardContext.Provider value={{
            cards,
            fetchAllStripeCards,
            reload,
            setReloadPage
        }}>
            {children}
        </CardContext.Provider>
    );
};

export default CardContext;