/**
 * Actions relating to Post Load and Post Truck tools
 * @module actions/post
 * @since 3.0.0
 */
import { push, replace } from 'react-router-redux';


/**
 * Edit mode start action. Starts 'editing mode' on either Truck Post or Load Post
 * @event POST_INIT_EDIT
 * @property {symbol} type - Symbol(POST_INIT_EDIT)
 * @property {object} payload
 * @property {string} payload.resource - 'load' for Load Post or 'truck' for Truck Post
 * @property {string} payload.editId - the ID of the Truck or the Load with which to populate the form to edit
 */
export const POST_INIT_EDIT = Symbol('POST_INIT_EDIT');
/**
 * Edit mode start action
 * @property {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @property {string} editId - the ID of the Truck or the Load with which to populate the form to edit
 * @fires POST_INIT_EDIT
 */
export function initEdit(resource, editId) {
  return {
    type: POST_INIT_EDIT,
    payload: { resource, editId },
  };
}
/**
 * Edit mode start action. Starts 'editing mode' on either Truck Post or Load Post
 * @event POST_INIT_ADD
 * @property {symbol} type - Symbol(POST_INIT_ADD)
 * @property {object} payload
 */
export const POST_INIT_ADD = Symbol('POST_INIT_ADD');
/**
 * Edit mode start action
 * @property {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @fires POST_INIT_ADD
 */
export function initPostAdd(resource, addStatus) {
  return {
    type: POST_INIT_ADD,
    payload: { resource, addStatus },
  };
}
/**
 * Thunk to initiate editing mode. Does nothing if we're already in edit mode for the given `editId`. Simulates a 'loading' screen in order to give users time to process that a change in the interface has taken place.
 * @param {string} resource - 'load' or 'truck'
 * @param {bool} status - true to open model to add new load
 * @fires LOCATION_CHANGE
 * @fires POST_INIT_EDIT
 */
export function initiateAdd(resource, status, route = true) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.post[resource].addStatus === status) {
      return;
    }
    if (route) {
      dispatch(replace(`/post${resource}`));
    }
    dispatch(initPostAdd(resource, status));
  };
}
/**
 * Edit mode start action. Starts 'editing mode' on either Truck Post or Load Post
 * @event POST_DISPLAY_HISTORY
 * @property {symbol} type - Symbol(POST_INIT_EDIT)
 * @property {object} payload
 */
export const POST_DISPLAY_HISTORY = Symbol('POST_DISPLAY_HISTORY');
/**
 * Edit mode start action
 * @property {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @property {boolean} history - display history view
 * @fires POST_DISPLAY_HISTORY
 */
export function displayHistory(resource, history) {
  return {
    type: POST_DISPLAY_HISTORY,
    payload: { resource, history },
  };
}

/**
 * Edit mode start action. Starts 'editing mode' on either Truck Post or Load Post
 * @event POST_DISPLAY_HISTORY
 * @property {symbol} type - Symbol(POST_INIT_EDIT)
 * @property {object} payload
 */
export const POST_DISPLAY_CURRENT_LOADS = Symbol('POST_DISPLAY_CURRENT_LOADS');
/**
 * Edit mode start action
 * @property {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @property {boolean} history - display history view
 * @fires POST_DISPLAY_HISTORY
 */
export function displayCurrentLoad(resource, current) {
  return {
    type: POST_DISPLAY_CURRENT_LOADS,
    payload: { resource, current },
  };
}
/**
 * Thunk to initiate editing mode. Does nothing if we're already in edit mode for the given `editId`. Simulates a 'loading' screen in order to give users time to process that a change in the interface has taken place.
 * @param {string} resource - 'load' or 'truck'
 * @param {string} editId - the ID of the Truck or load with which to start editing
 * @fires LOCATION_CHANGE
 * @fires POST_INIT_EDIT
 */
export function initiateEdit(resource, editId) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.post[resource].editId === editId) {
      return;
    }
    dispatch(push(`/post${resource}`));
    // simulate 'loading'
    dispatch(initEdit(resource, 'loading'));
    setTimeout(() => dispatch(initEdit(resource, editId)), 2000);
  };
}

/**
 * Thunk to initiate editing mode. Does nothing if we're already in edit mode for the given `editId`. Simulates a 'loading' screen in order to give users time to process that a change in the interface has taken place.
 * @param {string} resource - 'load' or 'truck'
 * @param {string} history - the ID of the Truck or load with which to start editing
 * @fires POST_INIT_EDIT
 */
export function displayHistoryAction(resource, history) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.post[resource].history === history) {
      return;
    }
    // simulate 'loading'
    dispatch(displayHistory(resource, 'loading'));
    setTimeout(() => dispatch(displayHistory(resource, history)), 2000);
  };
}

/**
 * Cancel edit mode action.
 * @event POST_CANCEL_EDIT
 * @property {symbol} type - Symbol(POST_CANCEL_EDIT)
 * @property {string} payload - 'load' for Load Post or 'truck' for Truck Post
 */
export const POST_CANCEL_EDIT = Symbol('POST_CANCEL_EDIT');/**
 * Cancel edit mode action.
 * @param {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @fires POST_CANCEL_EDIT
 */
export function cancel(resource) {
  return {
    type: POST_CANCEL_EDIT,
    payload: resource,
  };
}

/**
 * Thunk for cancelling editing mode for a resource. Does nothing if neither resource is in editing mode
 * @param {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @fires POST_CANCEL_EDIT
 */
export function cancelEdit(resource) {
  return (dispatch, getState) => {
    const state = getState();
    if (state.post[resource].editId === undefined && state.post[resource].copyId === undefined) {
      return;
    }
    return dispatch(cancel(resource));
  };
}

/**
 * Initiate copy mode action. Starts 'copy mode' on either Truck Post or Load Post
 * @event POST_INIT_COPY
 * @property {symbol} type - Symbol(POST_INIT_COPY)
 * @property {object} payload
 * @property {string} payload.resource - 'load' for Load Post or 'truck' for Truck Post
 * @property {string} payload.copyId - the ID of the Truck or the Load with which to populate the form to copy
 */
export const POST_INIT_COPY = Symbol('POST_INIT_COPY');
/**
 * Initiate copy mode action.
 * @param {string} resource - 'load' for Load Post or 'truck' for Truck Post
 * @param {string} copyId - the ID of the Truck or the Load with which to populate the form to copy
 * @fires POST_INIT_COPY
 */
export function initCopy(resource, copyId) {
  return {
    type: POST_INIT_COPY,
    payload: { resource, copyId },
  };
}

/**
 * Thunk for initiating copy mode for a resource. Also redirects user to the appropriate page
 * @fires LOCATION_CHANGE
 * @fires POST_INIT_COPY
 */
export function initiateCopy(resource, copyId) {
  return dispatch => {
    dispatch(push(`/post${resource}`));
    dispatch(initCopy(resource, copyId));
  };
}

/**
 * Thunk that sets the default initial values of a form to the values of a resource given the id of that resource and a function that sets the default values. Also cancels "edit" mode, if active.
 * @fires POST_CANCEL_EDIT
 */
export function setDefaultInitialValues(setDefaultFunc, resource, id) {
  return (dispatch, getState) => {
    const item = getState().resource[resource][id];
    setDefaultFunc(item);
    dispatch(cancelEdit(resource));
  };
}
