import React from 'react';
import { compose } from 'redux-v3';

import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import Responsive from 'components/hoc/Responsive';
import LoadReverseTable from './LoadReverseTable';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';

import LoadMobile2 from 'components/pure/LoadMobile2';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import hiddenPost from 'components/hoc/hiddenPost';


const LoadReverseMobile = compose(
  Resource('load', { resourcePropName: 'load' }),
  spinnerIfFetching('load'),
  hiddenPost({ idProp: 'load.data.id' }),
)(LoadMobile2);


const MobileView = ({ isFetching, fetchNext, ids = [] }) =>
  <NormalInfiniteScroll isFetching={isFetching} onScrollBottom={fetchNext}>
    {ids.map(id => <LoadReverseMobile key={id} id={id} />)}
  </NormalInfiniteScroll>
  ;

export default compose(
  ResourceBy('load', 'loadreverse'),
  Responsive(),
)(({ id, item, actions, isMobile }) => {
  if (isMobile) {
    return (
      <MobileView {...item} fetchNext={actions.fetchNext} />
    );
  }
  return <LoadReverseTable parent_id={id} {...item} fetchNext={actions.fetchNext} />;
});
