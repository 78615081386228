import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { FieldArray, reduxForm, formValueSelector } from 'redux-form';
import formatPennies from 'helpers/formatPennies';
import LineItemsForm from '../form/LineItemsForm';
import colors from 'styles/colors.json';

const FORM_NAME = 'FactoringPaymentEditFormBroker';
const selectorForm = formValueSelector(FORM_NAME);

export default compose(
    reduxForm({
        form: FORM_NAME,
        destroyOnUnmount: false,
        enableReinitialize: false,
    }),
    connect(
        state => {
            const invoiceAmount = selectorForm(state, 'amount');
            return {
                invoiceAmount
            }
        },
        {
            closeModal,
        }
    )
)(({ closeModal, invoiceAmount }) =>
    <div>
        <div className='modal-header'>
            <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
            <h4 className='modal-title'>
                Funding Request Line Items
            </h4>
        </div>
        <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
            <FieldArray name="line_items" component={LineItemsForm} />
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', padding: 5, borderTop: '1px solid black' }}>
                <div style={{ display: 'flex', flex: 0.5 }}>
                    <p 
                    style={{
                        fontSize: 14,
                        fontWeight: 'bolder',
                    }}
                >Shipper Invoice Amount:</p>
                </div>
                <div style={{ display: 'flex', flex: 0.5, justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                    <p
                        style={{
                            fontSize: 14,
                            color: colors.GREEN,
                        }}
                    >{formatPennies(invoiceAmount)}</p>
                </div>
                    
            </div>
        </div>
        <div className='modal-footer'>
            <button type='button' className='btn btn-orange' onClick={closeModal}>Done</button>
        </div>
    </div>
);
