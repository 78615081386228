/**
 * Connected modal component for displaying a form for rating a user
 * @module components/pure/modals/UserRate
 * @since 3.0.0
 * @requires components/pure/modals/Generic
 * @requires components/pure/form/UserRateForm
 * @property {object} props
 * @property {string} props.id - the ID of the user to be rated
 * @property {any} [props....rest] - props passed to containing ModalGeneric component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import branch from 'recompose/branch';
import mapProps from 'recompose/mapProps';

import * as validate from 'helpers/validate';

import { closeModal, openModal } from 'actions/ui';
import { create, edit } from 'actions/resourceBy/user/rating';

import Resource from 'components/hoc/Resource';
import ModalGeneric from 'components/pure/Modals/Generic';
import RateStarsInput from 'components/pure/form/inputs/RateStarsInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export default compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
  branch(
    ({ edit_id }) => edit_id,
    compose(
      Resource('rating', {
        idPropName: ['edit_id'],
      }),
      mapProps(({ item, user_id, edit_id }) => ({
        user_id,
        edit_id,
        initialValues: item && item.data ? {
          comment: item.data.comment,
          rating: item.data.rating,
        } : undefined,
      }))
    ),
    i => i,
  ),
  reduxForm({
    form: 'UserRateForm',
    async onSubmit(fields, dispatch, { user_id, edit_id }) {
      try {
        if (edit_id) {
          await dispatch(edit(user_id, fields));
        }
        else {
          await dispatch(create(user_id, fields));
        }
        dispatch(openModal('success', { message: 'Successfully rated user.' }));
        dispatch(closeModal());
      }
      catch (err) {
        console.warn(err);
        let message = 'An error occurred while rating the user';
        if (err && err.message && err.message.non_field_errors && err.message.non_field_errors[0]) {
          message = err.message.non_field_errors[0];
        }
        dispatch(openModal('error', { message }));
        dispatch(closeModal());
      }
    },
  }),
)(({ submitting, handleSubmit, closeModal }) =>
  <ModalGeneric
    closeModal={closeModal}
    headerChildren='Rate This User'
    message={
      <form onSubmit={handleSubmit}>
        <Field
          name='rating'
          component={RateStarsInput}
          validate={validate.compose(
            validate.vjs.presence(),
            validate.vjs.numericality({
              onlyInteger: true,
              greaterThanOrEqualTo: 0,
              lessThanOrEqualTo: 100,
            }),
          )}
          style={{ textAlign: 'left' }}
        />
        <Field
          name='comment'
          component={LabeledInput}
          validate={validate.compose(
            validate.vjs.presence(),
            validate.vjs.length({
              minimum: 15,
              tooShort: '^Please provide a longer message',
              maximum: 1000,
              tooLong: '^Please provide a shorter message',
            }),
          )}
          ComponentClass='textarea'
          label=''
          style={{ display: 'block', resize: 'vertical', width: '100%' }}
        />
      </form>
    }
    extraButtons={[
      <button className='btn btn-orange' disabled={submitting} onClick={handleSubmit}>Submit</button>,
    ]}
    buttonChildren='Cancel'
  />
);
