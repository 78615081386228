import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';

import { PaymentsTable } from 'components/container/FactoringClientPayments';
import CenteredColumn from 'components/pure/CenteredColumn';


const FactoringDebtorPayments = compose(
  connect(
    null,
    (dispatch, { params: { id } }) => ({
      update() {
        dispatch(factoringpaymentPaginatedResource.clear());
        return dispatch(factoringpaymentPaginatedResource.fetch({
          debtor_id: id,
        }));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(() =>
  <CenteredColumn
    xs={12}
    sm={10}
    className='col-fill'
    columnProps={{
      className: 'col-fill',
    }}
    >
    <PaymentsTable />
  </CenteredColumn>
);

export default FactoringDebtorPayments;
