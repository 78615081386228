import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { USER_TYPE } from 'helpers';

import RouteTabs from 'components/pure/RouteTabs';
import AdminDropdownInput, { noneSelect } from 'components/pure/form/inputs/AdminDropdownInput';
import { UserTypeListTable } from 'components/container/UserTypeList';
import LabeledDropdown from '../pure/form/inputs/LabeledDropdown';
import getSelf from 'selectors/getSelf';

let selectedAdmin = noneSelect;

export default compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
  ),
)(class AdminDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAdmin,
      currentSelectedPeriod: 'week',
    };
    this.selectAdmin = this.selectAdmin.bind(this);
    this.selectedPeriod = this.selectedPeriod.bind(this);
  }

  selectAdmin(admin) {
    selectedAdmin = admin;
    this.setState({ selectedAdmin: admin });
  }

  selectedPeriod(period) {
    this.setState({ currentSelectedPeriod: period });
  }

  render() {
    const { children, self } = this.props;
    const { selectedAdmin } = this.state;
    const { currentSelectedPeriod } = this.state;

    return (
      <div className='col-fill' style={{ padding: '0 1em' }}>
        <RouteTabs
          className='clearfix'
          style={{ flexGrow: 0 }}
          routes={[
            ['/admin/dashboard', 'Biz Stats'],
            ['/admin/dashboard/users', 'Assigned Users'],
            ['/admin/dashboard/clients', 'Assigned Factoring Users'],
            ...(() => self.data.type === USER_TYPE.ADMIN ?
              [['/admin/dashboard/finance', 'Finance']]
              : [])(),
          ]}
          extras={
            <div>
              <AdminDropdownInput
                containerProps={{
                  className: 'pull-right',
                  style: {
                    marginBottom: 0,
                  },
                }}
                label={null}
                input={{
                  value: selectedAdmin,
                  onChange: this.selectAdmin,
                }}
                selectSelfOnLoad={self.data.type === USER_TYPE.SUB_ADMIN}
              />
              {
                location.pathname.slice(location.pathname.lastIndexOf('/') + 1) === 'clients' ?
                  <LabeledDropdown
                    containerProps={{
                      className: 'pull-right',
                      style: {
                        marginBottom: 0,
                      },
                    }}
                    label={null}
                    data={[
                      { text: 'This Week vs Last Week', value: 'week' },
                      { text: 'This Month vs Last Month', value: 'month' },
                    ]}
                    horizontal={true}
                    input={{
                      value: currentSelectedPeriod,
                      onChange: this.selectedPeriod,
                    }}
                  /> : undefined
              }
            </div>
          }
        />
        {
          children ?
            React.cloneElement(children, {
              ...children.props,
              selectedAdmin,
              currentSelectedPeriod,
            })
            :
            <UserTypeListTable
              containerProps={{ className: 'col-fill' }}
              headerText={
                selectedAdmin.id === null ?
                  <span>Unassigned Users</span>
                  :
                  <span>{selectedAdmin.name || selectedAdmin.email || selectedAdmin.id} Assigned Users</span>
              }
            />
        }
      </div>
    );
  }
});
