import React from 'react';
import { Link } from 'react-router-v4';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import moment from 'moment';

import factoringtransactionpurchasesPaginatedResource from 'actions/admin/factoringtransactionpurchases';

import ResourceByIDs from 'components/hoc/ResourceByIDs';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';
import { openModal } from 'actions/ui';

const CategoryMapping = {
  'delivery_advance': 'Delivery Advance',
  'payment_advance': 'Payment Advance',
  'fuel_advance': 'Fuel Advance',
};

const StatusMapping = {
  'pending_for_time_batch': 'Pending For Time Batch',
  'bank_transfer_requested': 'Bank Transfer Requested',
  'bank_approved': 'Bank Approved',
  'account_credited': 'Account Credited',
  'payment_reversed': 'Payment Reversed',
};


const tableItems = createTableItems(
  ({ data, openPurchaseEditModal }) => [
    CategoryMapping[data.category],
    formatPennies(data.amount),
    moment(data.created).format('MM/DD/YY hh:mm:ss a'),
    StatusMapping[data.status],
    <button className='btn btn-orange' type='button' onClick={() => openPurchaseEditModal(data.funding_request, data.id)}> View </button>,
  ],
  compose(
    connect(
      null,
      dispatch => ({
        openPurchaseEditModal: (frid, id) => dispatch(openModal('factoringpurchasetransactioneditform', { frid, id })),
      }),
    ),
  ),
);

export default compose(
  connect(
    state => {
      return {
        ids: state.admin.factoringtransactionpurchases.ids,
        isFetching: state.admin.factoringtransactionpurchases.isFetching,
      };
    },
    {
      clear: factoringtransactionpurchasesPaginatedResource.clear,
      fetch: factoringtransactionpurchasesPaginatedResource.fetch,
      fetchNext: factoringtransactionpurchasesPaginatedResource.fetchNext,
    },
  ),
  ResourceByIDs({
    resource: 'factoringtransactionpurchase',
    idPropName: ['ids'],
  }),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch(undefined, undefined, this.props.id);
    },
  }),
)(({ items, fetchNext, isFetching, dispatch }) =>
  // <div style={{ height: '100%' }}>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Category', 'Amount', 'Purchased At', 'Status', '']}
    isFetching={isFetching}
    fetchNext={fetchNext}
    items={items}
    TableItem={tableItems.TableItem}
    BlockTableItem={tableItems.BlockTableItem}
  />
  // </div>
);
