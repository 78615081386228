import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal } from 'actions/ui';
import DebtorSimilarityFormModal from 'components/pure/DebtorSimilarityFormModal';


const SimilarDebtors = compose(
  connect(
    null,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ),
)(({ id, closeModal }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <Header className='text-danger'> Similar debtor Found!</Header>
      <p className='text-danger'> Transfer To the Recommended Similar Debtor </p>
    </div>
    <div className='modal-body'>
      <DebtorSimilarityFormModal id={id} />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
    </div>
  </div>
);

export default SimilarDebtors;
