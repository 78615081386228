/**
 * Reducer for handling the admin factoring client actions
 * @module reducers/admin/factoring
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/factoring/client
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoring.client;

import factoringclientPaginatedResource from 'actions/admin/factoring/client';


export default function factoringclientReducer(state = initialState, action) {
  state = factoringclientPaginatedResource.reduce(state, action);
  return state;
}
