import axios from 'axios';

export const fetchStripeCards = async accountId => {
    const url = `/cmftstripe/accounts/${accountId}/cards/`;
    const options = {
        url,
        method: 'GET',
    };
    const response = await axios(options);
    return response.data;
};

export const requestStripeCard = async (accountId, data) => {
    const url = `/cmftstripe/accounts/${accountId}/cards/`;
    const options = {
        url,
        method: 'POST',
        data
    };
    const response = await axios(options);
    return response.data;
};

export const activationStripeCard = async (accountId, data) => {
    const url = `/cmftstripe/accounts/${accountId}/cards/activation/`;
    const options = {
        url,
        method: 'POST',
        data
    };
    const response = await axios(options);
    return response.data;
};