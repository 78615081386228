import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, withState } from 'recompose';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import { FR_TYPE, USER_TYPE } from 'helpers';
import CroppedImagesInput from 'components/pure/form/inputs/CroppedImagesInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import TrailerInput from 'components/pure/form/inputs/TrailerInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import FactoringStatusInfo from 'components/pure/FactoringStatusInfo';
import AdminDropdownInput from 'components/pure/form/inputs/AdminDropdownInput';
import { openModal } from 'actions/ui';
import CheckmarkTable from 'components/pure/form/CheckmarkTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import LineItemTable from './LineItemTable';
import getSelfType from 'selectors/getSelfType';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isAnyAdmin from 'selectors/isAnyAdmin';
import { get } from 'lodash';
import fetchFreightClass from 'actions/fetchFreightClass';
import fetchCommodities from 'actions/fetchCommodities';

const FORM_NAME = 'FactoringPaymentEditForm';
const selector = formValueSelector(FORM_NAME);
const shouldCheckBoxInputs = document_issue_status => [
  FR_TYPE.DocumentIssue,
  'document_issue',
].includes(document_issue_status);

export default compose(
  connect(
    state => ({
      status: selector(state, 'status'),
      type: getSelfType(state),
      isDispatchBroker: isDispatchBroker(state),
      isAnyAdmin: isAnyAdmin(state),
    }),
    (dispatch, props) => ({
      openLineItemCreateModal() {
        return dispatch(openModal('lineitemcreate', {
          data: props.item.data,
          refresh: props.refresh,
        }));
      },
      openLineItemCreateCategoryModal() {
        return dispatch(openModal('lineitemcreatecategory'));
      },
      openModal(type, props) {
        return dispatch(openModal(type, props));
      },
      fetchCommodities: () => dispatch(fetchCommodities()),
      fetchFreightClass: () => dispatch(fetchFreightClass()),
    }),
  ),
  withState('freightClasses', 'setFreightClasses', []),
  withState('commodities', 'setCommodities', []),
  lifecycle({
    async componentDidMount() {
      const { fetchCommodities, fetchFreightClass, setCommodities, setFreightClasses } = this.props;
      const commodities = await fetchCommodities();
      setCommodities(commodities);
      const FreightClass = await fetchFreightClass();
      setFreightClasses(FreightClass);
    },
  }),
  reduxForm({
    form: 'FactoringPaymentEditForm',
    enableReinitialize: true,
    initialValues: {
      bill_to_address: {},
      attachments: [],
    },
    validate(fields) {
      if (['pending_originals', 'declined'].includes(fields.status) && validate.isEmpty(fields.status_note)) {
        return {
          status_note: `Must not be blank for the given status: "${fields.status}"`,
        };
      }
      return {};
    },
  })
)(({
  userId,
  scrollLineItemsIntoView,
  pendingLineItems,
  billToCompanyAdditionalComponent,
  lineItems,
  accounts,
  status,
  type,
  openLineItemCreateModal,
  item,
  factoring,
  attachments,
  handleSubmit,
  handleChange,
  disableAll,
  change,
  refresh,
  reserveLineItems,
  clearReserveLineItems,
  isDispatchBroker,
  isAnyAdmin,
  openModal,
  freightClasses,
  commodities,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <div className='row'>
        <div className='col-xs-12 col-sm-6'>
          { isAnyAdmin && <Field name='assigned_admin' component={AdminDropdownInput} label='Transaction Rep' disabled={disableAll} />}
          <div className='row'>
            <div className='col-xs-12'>
              <Field name='amount' label='Invoice Amt.' disabled={disableAll} component={PennyInput} />
            </div>
          </div>
          { isAnyAdmin && (
            <div>
              <div className='row'>
                <div className='col-xs-12'>
                  <Field name='reserve_total' label=' Active Reserve' disabled={true} component={PennyInput} />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <Field name='negative_pending' label='Pending Negative Reserve' disabled={true} component={PennyInput} />
                </div>
              </div>
              <div className='row'>
                <div className='col-xs-12'>
                  <Field name='positive_pending' label='Pending Positive Reserve' disabled={true} component={PennyInput} />
                </div>
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-xs-12'>
              <Field name='net_worth' label='Debtor Invoiced Amount' disabled={true} component={PennyInput} />
            </div>
          </div>
          <div className='row'>
            <div className='col-xs-12'>
              <Field name='unpaid_purchase_amount' label='Amount to be Purchased' disabled={true} component={PennyInput} />
            </div>
          </div>
          {isAnyAdmin && (
            <div>
              <div className='row'>
                <div className='col-md-6'>
                  <button type='button' className='btn-transaction' style={{ width: '200px' }} onClick={openLineItemCreateModal} >+ New Line Item</button>
                </div>
                <div className='col-md-6'>
                  <a role='button' onClick={scrollLineItemsIntoView} style={{ color: 'red', float: 'right' }}>{`${lineItems} line items`} <MaterialIcon name='edit' /></a>
                  <br />
                  <a role='button' onClick={scrollLineItemsIntoView} style={{ color: 'red', float: 'right' }}>{`${pendingLineItems} pending line items`} <MaterialIcon name='edit' /></a>
                </div>
                <br />
              </div>
              <div>
                <LineItemTable fundingRequestId={item.data.id} lineItems={reserveLineItems} updateLineItems={refresh} clearLineItems={clearReserveLineItems} />
              </div>
            </div>
          )}
          <Field name='invoice_number' label='Our Invoice #.' disabled={true} component={LabeledInput} />
          <Field name='user_load_number' component={LabeledInput} label='Load #' disabled={disableAll} />
          <Field name='invoice_notes' component={LabeledInput} label='Invoice Notes' disabled={disableAll} />
          <Field name='user_reference_number' component={LabeledInput} label='Client Invoice #' disabled={disableAll} />
          <Field
            name='first_origin_location'
            component={GoogleLocationInput}
            placeholder=''
            specificity={GoogleLocationInput.specificity.ANY}
            label='First Pick Up'
            inputProps={{ disabled: disableAll }}
            setValueWithoutConfirming={true}
          />
          <Field
            name='final_destination_location'
            component={GoogleLocationInput}
            placeholder=''
            specificity={GoogleLocationInput.specificity.ANY}
            label='Final Delivery'
            inputProps={{ disabled: disableAll }}
            setValueWithoutConfirming={true}
          />
          <Field name='load_length' component={LabeledInput} label='Load Length' specific={true} disabled={disableAll} />
          <Field
            name='load_trailer_type'
            component={TrailerInput}
            label='Equipment Type'
            placeholder='Select Type'
            extraTrailerTypes={[{
              value: 'Other',
              text: 'Other',
            }]}
            disabled={disableAll}
          />
          {isAnyAdmin && (
            <div>
              <Field
                name='debtor'
                component={DebtorAutocompleteInput}
                labelProps={{
                  style: { display: 'block' },
                }}
                label='Bill To Company'
                onSelect={val => {
                  change('bill_to_address', val.address);
                  change('bill_to_company_email', val.email);
                  change('bill_to_company_phone', val.phone_number);
                }}
                disabled={disableAll}
              />
              <Field
                name='bill_to_address'
                component={GoogleLocationInput}
                specificity={GoogleLocationInput.specificity.ADDRESS}
                label='Bill To Company Address'
                inputProps={{ disabled: disableAll }}
                setValueWithoutConfirming={true}
              />
              <Field name='bill_to_company_email' component={LabeledInput} label='Bill To Company Email' disabled={disableAll} />
              <div className='row'>
                <div className='col-md-6'>
                  <Field name='bill_to_company_phone' component={PhoneInput} label='Bill To Company Phone' disabled={disableAll} />
                </div>
                <div className='col-md-6'>
                  <Field name='bill_to_company_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' disabled={disableAll} />
                </div>
              </div>
              <Field name='debtor_credit_approved' component={LabeledInput} label='Bill To Credit' disabled={true} />
              {billToCompanyAdditionalComponent}
              {item.data.days_to_pay ? <Field name='days_to_pay' component={LabeledInput} label='Aging' disabled={true} /> : undefined}
            </div>
          )}
        </div>
        <div className='col-xs-12 col-sm-6'>
          <Field name='created' component={LabeledInput} label='Requested At' disabled={true} />
          <Field name='funded_at' component={LabeledInput} label='Funded At' disabled={true} />
          <Field name='discount_rate' component={LabeledInput} label='Rate for this Transaction' disabled={true} />
          <Field name='source' component={LabeledInput} label='Funding Request Source' disabled={true} />
          {isAnyAdmin && (
            <div>
              <Field name='amount_funded' label='Amount Funded' disabled={disableAll} component={PennyInput} />
              <p style={{ color: 'red' }} hidden={item.data.factoring.aladdin_owned}>**Transferred to Comfreight**</p>
            </div>
          )}
          <LabeledInput value={factoring.company_profile.name} label='Factoring Client Business Name' disabled={true} />
          <LabeledInput value={factoring.owner_profile.name} label='Factoring Client Contact' disabled={true} />
          <LabeledInput value={factoring.company_profile.phone_number} label='Factoring Client Phone' disabled={true} />
          <LabeledInput value={factoring.owner_profile.email} label='Factoring Client Email' disabled={true} />
          <LabeledInput value={factoring.company_profile.mc} label='Factoring Client MC' disabled={true} />
          <LabeledInput value={factoring.company_profile.dot} label='Factoring Client DOT' disabled={true} />
          <LabeledInput value={factoring.company_profile.tax_id} label='Factoring Client Tax ID' disabled={true} />
          {
            (item.data.fuel_advance_amount || item.data.status === 'fuel_advance') ?
              <div>
                <h6 className='text-success'>**Fuel Advance**</h6>
                <Field name='fuel_advance_amount' component={PennyInput} label='Fuel Advance Amount' style={{ backgroundColor: 'rgba(65,117,5,0.3)' }} />
                <Field name='fuel_advance_rate' component={LabeledInput} label='Fuel Advance Rate' style={{ backgroundColor: 'rgba(65,117,5,0.3)' }} disabled={true} />
                <Field name='fuel_advance_fee' component={PennyInput} label='Fuel Advance Fee' style={{ backgroundColor: 'rgba(65,117,5,0.3)' }} disabled={true} />
              </div>
              : null
          }
          {
            (isDispatchBroker && !isAnyAdmin ? (
              <Field
                name='status'
                component={LabeledDropdown}
                data={[
                  { text: 'Pending', value: 'special_pending' },
                  { text: 'Doc Issue', value: 'document_issue' },
                  { text: 'Incomplete', value: 'incomplete' },
                  { text: 'Remote Approved', value: 'remote_approved' },
                  { text: 'Declined', value: 'declined' },
                ]}
                label='Funding Request Status'

                disabled={disableAll}
              />) :
              ((type !== USER_TYPE.FACTORING_REMOTE_ADMIN && type !== USER_TYPE.FACTORING_REMOTE_COORDINATOR) ?
                <Field
                  name='status'
                  component={LabeledDropdown}
                  data={[
                    { text: 'New (NOA)', value: 'new_noa' },
                    { text: 'New', value: 'new' },
                    { text: 'Fuel Advance', value: 'fuel_advance' },
                    { text: 'Pending', value: 'pending' },
                    { text: 'Pending (NOA)', value: 'pending_noa' },
                    { text: 'Pending Originals', value: 'pending_originals' },
                    { text: 'Special Pending', value: 'special_pending' },
                    { text: 'Doc Issue', value: 'document_issue' },
                    { text: 'CF Review', value: 'haulpay_review' },
                    { text: 'Doc Review', value: 'document_review' },
                    { text: 'Approved', value: 'approved' },
                    { text: 'Remote Approved', value: 'remote_approved' },
                    { text: 'Declined', value: 'declined' },
                    { text: 'Paid', value: 'paid' },
                    { text: 'Deleted', value: 'deleted' },
                  ]}
                  label='Funding Request Status'
                  onValueChange={value => {
                    if (get(item, 'data.debtor.credit_approved', false)) {
                      if (value === 'approved') {
                        if (get(item, 'data.debtor.requires_originals', false)) {
                          openModal('ConfirmDebtorOriginals', {
                            loadNumber: get(item, 'data.user_load_number', 'not found'),
                            invoiceNumber: get(item, 'data.invoice_number', 'not found'),
                          });
                        }
                      }
                    }
                  }}
                  disabled={disableAll}
                /> :
                type === USER_TYPE.FACTORING_REMOTE_ADMIN ?
                  <Field
                    name='status'
                    component={LabeledDropdown}
                    data={[
                      { text: 'Document Issue', value: 'document_issue' },
                      { text: 'Document Review', value: 'document_review' },
                      { text: 'Correct', value: 'remote_approved' },
                    ]}
                    label='Funding Request Status'
                    disabled={disableAll}
                  />
                  :
                  <Field
                    name='status'
                    component={LabeledDropdown}
                    data={[
                      { text: 'New (NOA)', value: 'new_noa' },
                      { text: 'New', value: 'new' },
                      { text: 'Pending', value: 'pending' },
                      { text: 'Pending (NOA)', value: 'pending_noa' },
                      { text: 'Document Issue', value: 'document_issue' },
                      { text: 'Document Review', value: 'document_review' },
                      { text: 'Comfreight Review', value: 'haulpay_review' },
                      { text: 'Remote Approved', value: 'remote_approved' },
                    ]}
                    label='Funding Request Status'
                    disabled={disableAll}
                  />))
          }
          {
            shouldCheckBoxInputs(status) ?
              <CheckmarkTable
                onChange={handleChange}
                header='Document Issue'
                types={[
                  { text: 'BOL' },
                  { text: 'Rate Con' },
                  { text: 'Receipt' },
                ]}
                rows={[
                  ['Missing', 'bol_missing', 'rate_missing', 'receipt_missing'],
                  ['Blurry', 'bol_blurry', 'rate_blurry', 'receipt_blurry'],
                ]}
              /> : null
          }
          <Field
            name='status_note'
            component={GrowableInput}
            props={{
              Component: LabeledInput,
              label: 'STATUS Notes',
              ComponentClass: 'textarea',
            }}
            disabled={disableAll}
          />
          <Field
            name='user_notes'
            component={LabeledInput}
            label='Notes From Client'
            disabled={disableAll}
          />
          <Field
            name='commodity'
            component={LabeledDropdown}
            data={commodities.map(c => ({
              text: `${c.commodity}${c.trading_symbol ? ` (${c.trading_symbol})` : ''}`,
              value: Number(c.id),
            })).concat([{
              text: 'N/A',
              value: 0,
            }])}
            label='Commodity'
            disabled={disableAll}
          />
          <Field
            name='freight_class'
            component={LabeledDropdown}
            data={
              freightClasses.map(c => ({
                text: `${c.example} (${c.class})`,
                value: Number(c.id),
              })).concat([{
                text: 'N/A',
                value: 0,
              }])}
            label='Freight Class:'
            disabled={disableAll}
          />
          {
            !(isDispatchBroker && !isAnyAdmin) ?
              type !== USER_TYPE.ALADDIN_ADMIN && !item.data.factoring.aladdin_owned ?
                <Field
                  name='payment_method'
                  component={LabeledDropdown}
                  data={
                    accounts.map(({ method, account_number_redacted }) => ({ text: `${method} - ${account_number_redacted}`, value: `${method.toLowerCase()}` }))
                  }
                  label='Payment Method'
                  disabled={disableAll || type === USER_TYPE.FACTORING_REMOTE_ADMIN}
                />
                :
                <Field
                  name='payment_method'
                  component={LabeledDropdown}
                  data={[
                    { text: 'ACH', value: 'ach' },
                    { text: 'Wire', value: 'wire' },
                  ]}
                  label='Payment Method'
                  disabled={disableAll || type === USER_TYPE.FACTORING_REMOTE_ADMIN}
                />
              :
              null
          }

          <FactoringStatusInfo status_timeline={item.data.status_timeline} />
          <div className='text-right'>
            <Field
              name='attachments'
              component={MultiImageInput}
              limit={Infinity}
              multiple={true}
              buttonText='Upload Documents'
              buttonClassName='btn btn-default'
              disabled={disableAll}
              shouldCrop={true}
              categories={[
                'Signed Bill of Lading (Delivered)',
                'Signed Bill of Lading (Pick-Up)',
                'Rate Confirmation',
                'Truck Order Not Used',
                'Invoice',
                'Freight Bill',
                'Fuel Advance',
                'Lumper Receipt',
                'Detention Receipt',
                'Late Fee Receipt',
                'Other',
              ]}
              imageGridProps={{
                columns: {
                  sm: 2,
                  md: 2,
                  lg: 2,
                },
              }}
            />
          </div>
          {attachments}
          <div className='text-right'>
            <Field
              name='cropped_attachments'
              disabled={disableAll}
              component={MultiImageInput}
              noButton={true}
              multiple={true}
              categories={[
                'Signed Bill of Lading (Delivered)',
                'Signed Bill of Lading (Pick-Up)',
                'Rate Confirmation',
                'Truck Order Not Used',
                'Invoice',
                'Freight Bill',
                'Fuel Advance',
                'Lumper Receipt',
                'Detention Receipt',
                'Late Fee Receipt',
                'Other',
              ]}
            />
          </div>
        </div>
      </div>
    </form>
  );
});
