import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import shallowEqual from 'recompose/shallowEqual';

import { fetchIfNeeded as fetchTruckIfNeeded, getItem as getTruck } from 'actions/resource/truck';
import { fetchIfNeeded as fetchLoadIfNeeded, getItem as getLoad } from 'actions/resource/load';
import { fetchIfNeeded as fetchHaulPayLoadIfNeeded, getItem as getHaulPayLoad } from 'actions/resource/haulPayLoad';
import { fetchIfNeeded as fetchUserIfNeeded, getItem as getUser } from 'actions/resource/user';
import { fetchIfNeeded as fetchMapIfNeeded, getItem as getMap } from 'actions/resource/map';
import { fetchIfNeeded as fetchGeocodeIfNeeded, getItem as getGeocode } from 'actions/resource/geocode';
import { fetchIfNeeded as fetchRatingIfNeeded, getItem as getRating } from 'actions/resource/rating';
import { fetchIfNeeded as fetchUserSearchIfNeeded, selector as getUserSearch } from 'actions/resource/usersearch';
import { fetchIfNeeded as fetchFactoringClientIfNeeded, selector as getFactoringClient } from 'actions/resource/factoringclient';
import { fetchIfNeeded as fetchFactoringDebtorIfNeeded, getItem as getFactoringDebtor } from 'actions/resource/factoringdebtor';
import { fetchIfNeeded as fetchLoadSearchIfNeeded, getItem as getLoadSearch } from 'actions/resource/loadsearch';
import { fetchIfNeeded as fetchFactoringPaymentIfNeeded, selector as getFactoringPayment } from 'actions/resource/factoringpayment';
import { fetch as fetchFactoringDebtorAuditLogIfNeeded, selector as getFactoringDebtorAuditLog } from 'actions/resource/factoringdebtorauditlog';
import { fetch as fetchFactoringClientAuditLogIfNeeded, selector as getFactoringClientAuditLog } from 'actions/resource/factoringclientauditlog';
import { fetch as fetchDefaultBank, selector as getDefaultBank } from 'actions/resource/defaultbank';
import { fetch as fetchFinancesPayment, selector as getFinancesPayment } from 'actions/resource/financespayment';
import { fetch as fetchFinancesPurchase, selector as getFinancesPurchase } from 'actions/resource/financespurchase';
import { fetch as fetchFinancesLineItem, selector as getFinancesLineItem } from 'actions/resource/financeslineitem';
import { fetch as fetchFactoringTransactionPayment, selector as getFactoringTransactionPayment } from 'actions/resource/factoringtransactionpayment';
import { fetch as fetchFactoringTransactionPurchase, selector as getFactoringTransactionPurchase } from 'actions/resource/factoringtransactionpurchase';
import { fetch as fetchGetBankInfo, selector as getGetBankInfo } from 'actions/resource/GetBankInfo';
import { fetch as fetchFactoringClientDebtorAuditLogIfNeeded, selector as getFactoringClientDebtorAuditLog } from 'actions/resource/factoringclientdebtorauditlog';
import { fetch as fetchFactoringTransactionLineItem, selector as getFactoringTransactionLineItem } from 'actions/resource/factoringlineitemcreate';
import { fetch as factoringPaymentRelation, getItem as getFactoringPaymentRelation } from 'actions/resource/factoringpaymentrelation';
import { fetch as fetchFactoringClientAttachments, selector as getFactoringClientAttachments } from 'actions/resource/factoringclientattachments';
import { fetch as fetchAdminUser, selector as getAdminUser } from 'actions/resource/adminuser';
import { fetch as fetchPlaidAccount, selector as getPlaidAccount } from 'actions/resource/plaidaccount';

const AVAILABLE_RESOURCES = {
  truck: fetchTruckIfNeeded,
  load: fetchLoadIfNeeded,
  user: fetchUserIfNeeded,
  map: fetchMapIfNeeded,
  geocode: fetchGeocodeIfNeeded,
  rating: fetchRatingIfNeeded,
  usersearch: fetchUserSearchIfNeeded,
  factoringclient: fetchFactoringClientIfNeeded,
  factoringdebtor: fetchFactoringDebtorIfNeeded,
  loadsearch: fetchLoadSearchIfNeeded,
  factoringpayment: fetchFactoringPaymentIfNeeded,
  factoringdebtorauditlog: fetchFactoringDebtorAuditLogIfNeeded,
  factoringclientauditlog: fetchFactoringClientAuditLogIfNeeded,
  financespayment: fetchFinancesPayment,
  financespurchase: fetchFinancesPurchase,
  financeslineitem: fetchFinancesLineItem,
  factoringclientdebtorauditlog: fetchFactoringClientDebtorAuditLogIfNeeded,
  factoringtransactionpayment: fetchFactoringTransactionPayment,
  factoringtransactionpurchase: fetchFactoringTransactionPurchase,
  factoringtransactionlineitem: fetchFactoringTransactionLineItem,
  factoringpaymentrelation: factoringPaymentRelation,
  GetBankInfo: fetchGetBankInfo,
  defaultbank: fetchDefaultBank,
  factoringclientattachments: fetchFactoringClientAttachments,
  adminuser: fetchAdminUser,
  plaidaccount: fetchPlaidAccount,
  haulPayLoad: fetchHaulPayLoadIfNeeded,
};

const getItem = {
  truck: getTruck,
  load: getLoad,
  user: getUser,
  map: getMap,
  geocode: getGeocode,
  rating: getRating,
  usersearch: getUserSearch,
  factoringclient: getFactoringClient,
  factoringdebtor: getFactoringDebtor,
  loadsearch: getLoadSearch,
  factoringpayment: getFactoringPayment,
  factoringdebtorauditlog: getFactoringDebtorAuditLog,
  factoringclientauditlog: getFactoringClientAuditLog,
  financespayment: getFinancesPayment,
  financespurchase: getFinancesPurchase,
  financeslineitem: getFinancesLineItem,
  factoringclientdebtorauditlog: getFactoringClientDebtorAuditLog,
  factoringtransactionpayment: getFactoringTransactionPayment,
  factoringtransactionpurchase: getFactoringTransactionPurchase,
  factoringtransactionlineitem: getFactoringTransactionLineItem,
  factoringpaymentrelation: getFactoringPaymentRelation,
  GetBankInfo: getGetBankInfo,
  defaultbank: getDefaultBank,
  factoringclientattachments: getFactoringClientAttachments,
  adminuser: getAdminUser,
  plaidaccount: getPlaidAccount,
  haulPayLoad: getHaulPayLoad,
};
const DEFAULT_OPTIONS = {
  idPropName: 'id',
  resourcePropName: 'item',
};

export default (resource, options) => {
  options = { ...DEFAULT_OPTIONS, ...options };

  return WrappedComponent => {
    class Resource extends Component {

      componentDidMount() {
        this.fetch(this.props);
      }

      componentWillUpdate(nextProps) {
        let shouldReFetch = nextProps.id !== this.props.id;
        if (shouldReFetch && typeof nextProps.id === 'object' && typeof this.props.id === 'object') {
          shouldReFetch = !shallowEqual(nextProps.id, this.props.id);
        }
        if (shouldReFetch) {
          this.fetch(nextProps);
        }
      }

      fetch(props, customOptions = {}) {
        const optionsToUse = {
          ...options,
          ...customOptions,
        };
        const { id, frid, dispatch, ownProps } = props;
        const opts = {};
        if (optionsToUse.expirationTime) {
          opts.expirationTime = optionsToUse.expirationTime;
        }
        if (optionsToUse.expirationDenomination) {
          opts.expirationDenomination = optionsToUse.expirationDenomination;
        }
        dispatch(AVAILABLE_RESOURCES[resource || ownProps.resource](id, frid, opts));
      }

      static displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

      static propTypes = {
        id: PropTypes.any.isRequired,
        item: PropTypes.object,
        dispatch: PropTypes.func.isRequired,
      };

      render() {
        const { item, ownProps } = this.props;
        return React.createElement(WrappedComponent, {
          ...ownProps,
          refetchResource: () => {
            this.fetch(this.props, { expirationTime: -1 });
          },
          [options.resourcePropName]: item,
        });
      }
    }

    return compose(
      connect(
        (state, ownProps) => {
          const id = typeof options.idPropName === 'function' ? options.idPropName(ownProps) : get(ownProps, options.idPropName);
          let frid, item; //TODO : Change this eventually, make it only take on ID and then make another Resource.
          if (options.fridPropName) {
            frid = typeof options.fridPropName === 'function' ? options.fridPropName(ownProps) : get(ownProps, options.fridPropName);
          }
          const get_item = getItem[resource || ownProps.resource];
          if (get_item) {
            item = get_item(state, id);
          }
          return { id, frid, item };
        },
        dispatch => ({ dispatch }),
        (stateProps, dispatchProps, ownProps) => ({
          ...stateProps,
          ...dispatchProps,
          ownProps,
        }),
      ),
    )(Resource);
  };
};
