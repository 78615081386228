/* global FIREBASE_MESSAGING_SENDER_ID */
import { browserHistory } from 'react-router-v4';
// import firebase from 'firebase/app';
// import 'firebase/messaging';

import { setNotification } from 'actions/notification';
import store from './store';


// firebase.initializeApp({
//   // apiKey: '<your-api-key>',
//   // authDomain: '<your-auth-domain>',
//   // databaseURL: '<your-database-url>',
//   // projectId: '<your-cloud-firestore-project>',
//   // storageBucket: '<your-storage-bucket>',
//   messagingSenderId: FIREBASE_MESSAGING_SENDER_ID,
// });

// const messaging = firebase.messaging();
let swRegistration = null;
let cancelTokenRefresh = null;

const registrationScope = '/sw.js';

// eslint-disable-next-line no-console
const log = (...args) => console.log('firebase', ...args);

const handleServiceWorkerMessage = event => {
  log('got event from sw', event);
  if (event.data.type) {
    switch (event.data.type) {
      case 'history_push':
        store.dispatch(setNotification(false));
        return browserHistory.push(event.data.url);
      case 'set_notification_icon':
        store.dispatch(setNotification(true));
    }
  }
};


export const initialize = async () => {
  try {
    // log('initializing firebase');
    // if (!navigator.serviceWorker) {
    //   throw new Error('Service workers not supported in this browser');
    // }

    // let registration = await navigator.serviceWorker.getRegistration(registrationScope);

    // if (!registration) {
    //   registration = navigator.serviceWorker.register(registrationScope);
    // }

    // registration.update();

    // swRegistration = registration;
    // messaging.useServiceWorker(swRegistration);

    // log('registered sw', swRegistration);

    // await messaging.requestPermission();

    // log('got permission');
    // const token = await messaging.getToken();

    // log('got token', token);

    // if (cancelTokenRefresh) {
    //   cancelTokenRefresh();
    // }

    // cancelTokenRefresh = messaging.onTokenRefresh(initialize);
    // navigator.serviceWorker.addEventListener('message', handleServiceWorkerMessage);
    // log('initialized firebase');

    // return token;
  }
  catch (err) {
    switch (err.code) {
      case 'messaging/permission-blocked':
        break;
      case 'messaging/notifications-blocked':
        break;
      default:
        console.warn(err);
        throw err;
    }
  }
};

export const uninitialize = async () => {
  log('uninitializing firebase');
  try {
    // const token = await messaging.getToken();
    // if (token) {
    //   await messaging.deleteToken(token);
    // }
    // navigator.serviceWorker.removeEventListener('message', handleServiceWorkerMessage);
    log('uninitialized firebase');
  }
  catch (err) {
    console.warn(err);
  }
};

// messaging.onMessage(payload => {
//   log('Foreground message received.', payload);
//   if (!swRegistration) {
//     console.warn('Tried to send a notification to the service worker registration', swRegistration);
//     return;
//   }
//   const { notification = {} } = payload;
//   swRegistration.showNotification(notification.title || 'ComFreight', {
//     body: notification.body || 'You have new activity on your ComBoard',
//     icon: '/image/icon/ComFreightLogo.png',
//     badge: '/image/icon/ComFreightLogo.png',
//   });
//   store.dispatch(setNotification(true));
// });
