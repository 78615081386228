/* global fbq process */
import React from 'react';

import IDRegex from 'helpers/IDRegex';

import RouteTabs from 'components/pure/RouteTabs';
import segmentEvents from '../../helpers/segmentEvents';


const FactoringGetPaid = ({ children }) =>
  <div style={{ marginBottom: '5em' }}>
    <RouteTabs
      routes={[
        ['/haul-pay/getpaid/request', 'Upload Docs'],
        ['/haul-pay/getpaid/history', 'History', new RegExp(`/haul-pay/getpaid/history(?:/${IDRegex})?`), () => window.analytics.track(segmentEvents.CARRIER_VIEWED_TRANSACTION_HISTORY)],
        ['/haul-pay/getpaid/incomplete', 'Incomplete', undefined, () => window.analytics.track(segmentEvents.CARRIER_VIEWED_INCOMPLETE_TRANSACTIONS)],
      ]}
    />
    {children}
  </div>
  ;

export default FactoringGetPaid;
