/**
 * Generic class for single-resource-related fetching and serialization. Note that not all Resources implement all Resource methods (some endpoints don't exist).
 * @module datatypes/Resource
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/PermissionsError
 */
/*global API */
import moment from 'moment';

import APIFetch from './APIFetch';
import FetchError from './FetchError';
import PermissionsError from './PermissionsError';

/**
 * Resource Class
 */
class Resource {
  /**
   * Create a Resource
   * @param {string} name - the unique identifier for this Resource
   * @param {object} [options]
   * @param {number} [options.acceptableStatusCode] - the HTTP status code that the Resource uses to confirm a successful fetch request
   * @param {number} [options.expirationTime] - the time in which the fetched data is considered valid, and will not be re-fetched
   * @param {string} [options.expirationDenomination] - the time denomination for the previous parameter
   * @param {object} [options.globals] - any globals/meta/miscellaneous information to be tied to this Resource in state
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, options = {}) {

    if (typeof name !== 'string') {
      throw new Error(`${this.constructor.name} requires a name`);
    }

    this.name = name;
    this.options = {
      acceptableStatusCode: 200,
      expirationTime: 300000,
      expirationDenomination: 'milliseconds',
      globals: {},
      ...options,
    };

    const upperName = this.name.toUpperCase();
    this.actions = {
      FETCH_REQUEST: Symbol(`${upperName}_FETCH_REQUEST`),
      FETCH_REQUEST_SUCCESS: Symbol(`${upperName}_FETCH_REQUEST_SUCCESS`),
      FETCH_REQUEST_FAILURE: Symbol(`${upperName}_FETCH_REQUEST_FAILURE`),
    };

    this.fetch = this.fetch.bind(this);
    this.fetchIfNeeded = this.fetchIfNeeded.bind(this);
    this.getItem = this.getItem.bind(this);
  }

  // ACTIONS
  /**
   * Fetch request action. Marks that a request to fetch a Resource with `id` has been made
   * @event module:datatypes/Resource~Resource#FETCH_REQUEST
   * @property {symbol} type - Symbol({NAME}_FETCH_REQUEST)
   * @property {string} payload - the ID of the Resource being fetched
   */
  fetchRequest(id) {
    return {
      type: this.actions.FETCH_REQUEST,
      payload: id,
    };
  }
  /**
   * Fetch request success action. Marks that a request to fetch a Resource with `id` has been made successfully
   * @event module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_FETCH_REQUEST_SUCCESS)
   * @property {object} payload
   * @property {string} payload.id - the ID of the Resource being fetched
   * @property {object} payload.json - the data returned from the server
   */
  fetchRequestSuccess(id, json) {
    return {
      type: this.actions.FETCH_REQUEST_SUCCESS,
      payload: { id, json },
    };
  }
  /**
   * Fetch request failure action. Marks that a request to fetch a Resource with `id` has failed
   * @event module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_FETCH_REQUEST_FAILURE)
   * @property {object} payload
   * @property {id} payload.id - the ID of the Resource being fetched
   * @property {Error} payload.err - the error that occurred
   */
  fetchRequestFailure(id, err) {
    return {
      type: this.actions.FETCH_REQUEST_FAILURE,
      payload: { err, id },
    };
  }
  /**
   * @param {string} id - the ID for this Resource
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching this Resource, or an error
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
   */
  fetch(id, token = '') {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.fetchRequest(id));

      return dispatch(APIFetch(`${API.host}/${this.options.url(id)}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token || token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch(this.fetchRequestFailure(id, err));
          return Promise.reject(err);
        })
        .then(json => dispatch(this.fetchRequestSuccess(id, json)))
        ; // eslint-disable-line indent
    };
  }

  /**
   * @param {string} id - the ID for this Resource
   * @param {object} options - an options object with which to override this Resource's default options for this request
   * @returns {Promise<void | Action>} A promise that resolves in `undefined`, if this Resource was already fetched recently, or the action/error returned from module:datatypes/Resource~Resource#fetch
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
   */
  fetchIfNeeded(id, options) {
    options = {
      ...this.options,
      ...options,
    };
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);
      if (
        item === undefined ||
        (item.err !== null && !(item.err instanceof PermissionsError)) ||
        moment().diff(item.fetchedAt, options.expirationDenomination) > options.expirationTime
      ) {
        return dispatch(this.fetch(id));
      }
      return Promise.resolve();
    };
  }

  // REDUCER
  /**
  * @param {string} state - the overall state
  * @param {string} id - the ID for this Resource
  * @returns {any} the chunk of state relevant to this Resource
  */
  getItem(state, id) {
    return state.resource[this.name][id];
  }

  /**
   * @returns The default initial state for this Resource
   */
  getDefaultState() {
    return {
      isFetching: false,
      fetchedAt: null,
      globals: { ...this.options.globals },
      data: null,
      err: null,
    };
  }

  /**
   * @param {object} oldValue - the current data (if any) of this Resource, previous to fetching
   * @param {object} json - the data fetched from the server
   * @returns The parsed new value for this Resource
   */
  parse(oldValue = {}, json) {
    return {
      isFetching: false,
      globals: { ...this.options.globals },
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: {
        ...oldValue.data,
        ...json,
      },
    };
  }

  /**
   * @param {object[]} oldValues - the current set of data (if any) of this Resource, previous to fetching
   * @param {object[]} newValues - the new set of data fetched from the server
   * @returns The parsed new set of data for this Resource
   */
  parseMany(oldValues, newValues) {
    return newValues.reduce((acc, curr) => {
      acc[curr.id] = this.parse(oldValues[curr.id], curr);
      return acc;
    }, {});
  }

  /**
   * The reducer for this Resource. Manages state changes triggered by actions specific to this Resource.
   * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
   * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
   * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
   * @param {object} state - the current state of this Resource
   * @param {object} action - the dispatched action
   * @returns The new state for this Resource
   */
  reduce(state, action) {
    switch (action.type) {
      case this.actions.FETCH_REQUEST:
        return {
          ...state,
          [action.payload]: {
            ...this.parse(state[action.payload]),
            isFetching: true,
          },
        };
      case this.actions.FETCH_REQUEST_SUCCESS:
        return {
          ...state,
          [action.payload.id]: {
            ...this.parse(state[action.payload.id], action.payload.json),
            isFetching: false,
            fetchedAt: moment(),
            err: null,
          },
        };
      case this.actions.FETCH_REQUEST_FAILURE:
        return {
          ...state,
          [action.payload.id]: {
            ...this.parse(state[action.payload.id]),
            isFetching: false,
            err: action.payload.err,
          },
        };
    }
    return state;
  }
}

export default Resource;
