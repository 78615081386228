import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  buttonClose: {
    backgroundColor: 'transparent',
    color: '#000000',
    marginRight: 10,
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 22,
    color: '#5c6979',
    textAlign: 'center',
    width: '90%',
    fontWeight: '400',
  },
  icon: {
    //fontSize: 50,
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    color: '#979797',
  },
};

const MissingDocs = ({ closeModal, action, ...props }) => (
  <div>
    <ModalBody closeModal={closeModal}>
      <div
        style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
        >
        <MaterialIcon
          name='error'
          size={90}
          style={styles.icon}
        />
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
        <p style={styles.text}>
          This Request is missing some information, please make sure to add all the docs and fill out all the required form.
        </p>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, justifyContent: 'flex-end', margin: '0px 5px 5px 5px' }}>
      <Button
        style={styles.buttonClose}
        onClick={() => {
          closeModal();
        }}
        size='sm'
        >
        Close
      </Button>
      <Button
        style={styles.button}
        onClick={() => {
          action();
          closeModal();
        }}
        size='sm'
        >
        Complete Later
      </Button>
    </div>
  </div>
);

export default compose(
  connect(() => ({}),
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
    }),
  ))(MissingDocs);
