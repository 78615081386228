import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { Field, reduxForm } from 'redux-form';
import LabeledInput from '../form/inputs/LabeledInput';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import { confirmMicroDepositBankAccount } from 'actions/ledger';
import validate from 'validate.js';
import { openModal } from '../../../actions/ui';

const styles = {
    button: {
        backgroundColor: '#FF7800',
        color: '#fff',
    },
    flexRow: {
        display: 'flex',
        flex: 1,
        flexDirection: 'row',
    },
    text: {
        fontSize: 16,
        color: '#5c6979',
        textAlign: 'justify',
        fontWeight: '400',
    },
    icon: {
        color: '#f5a623',
    },
    customBackgroundDialog: {
        backgroundColor: 'rgba(92, 105, 121, 0.8)',
    },
    timeIcon: {
        //fontSize: 16,
        color: '#979797',
    },
};
const ConfirmMicroDepositModel = ({
    closeModal,
    handleSubmit,
    ...props
}) => (
    <div >
        <ModalBody closeModal={closeModal}>
            <div >
                <div
                    style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
                >
                    <MaterialIcon
                        name='error'
                        size={40}
                        style={styles.icon}
                    />
                </div>
                <div>
                    <p style={styles.text}>
                        Did you received a Micro Deposit? If
                        you did please enter the code of it below:
                    </p>
                    <div style={{ padding: '5px 5px 5px 5px' }}>
                        <Field
                            name='code'
                            component={LabeledInput}
                            label='Code'
                            placeholder='SM........'
                        />
                    </div>
                </div>
            </div>
        </ModalBody>
        <div style={{ display: 'flex', flexWrap: 'wrap', margin: '-5px 5px 5px 5px', justifyContent: 'flex-end', gap: 10 }}>
            <div>
                <Button
                    style={{
                        borderColor: '#FF7800',
                    }}
                    onClick={closeModal}
                >
                    No
                </Button>
            </div>
            <div>
                <Button
                    style={styles.button}
                    onClick={handleSubmit}
                >
                    Confirm
                </Button>
            </div>
        </div>
    </div>
);
const FORM_NAME = 'CONFIRM_MICRO_DEPOSIT';
export default compose(
    connect(state => ({
        code: undefined,
    }),
        dispatch => ({
            closeModal() {
                dispatch(closeModal());
            },
        }),
    ),
    reduxForm({
        form: FORM_NAME,
        async onSubmit(fields, dispatch, { closeModal, onSuccess }) {
            try {
                await confirmMicroDepositBankAccount(fields.code);
                closeModal();
                dispatch(openModal('success', { message: 'Bank account is verified.' }));
                setTimeout(() => window.location.reload(), 1000);
            } catch (e) {
                closeModal();
                dispatch(openModal('error', { message: e}));
            };
        },
        validate(fields) {
            return validate(fields, {
                code: {
                    presence: true,
                },
            });
        }
    }))(ConfirmMicroDepositModel);
