/**
 * Root reducer for the resource by factoringclient paginated resource collection reducers
 * @module reducers/resourceBy/factoringclient
 * @since 3.0.0
 * @requires reducers/resourceBy/factoringclient/factoringclientnote
 */
import { combineReducers } from 'redux-v3';

import factoringclientnote from './factoringclientnote';
import factoringdebtor from './factoringdebtor';


export default combineReducers({
  factoringclientnote,
  factoringdebtor,
});
