/**
 * Actions relating to the factoringpayment by user resource
 * @module actions/resourceBy/user/factoringpayment
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires actions/resource/load
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import factoringpaymentResource from 'actions/resource/factoringpayment';


class FactoringPaymentPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }
}

class FactoringPaymentByUserPaginatedResourceCollection extends PaginatedResourceCollection {
  getResourceType() {
    return FactoringPaymentPaginatedResource;
  }
}

export const factoringpaymentByUserResource = new FactoringPaymentByUserPaginatedResourceCollection('user', 'factoringpayment', {
  url: new UrlAssembler('factoring/funding/request'),
  paginatedResourceOptions: {
    url: new UrlAssembler('factoring/funding/request'),
    baseResource: factoringpaymentResource,
    append_to_top_on_create: true,
  },
});

export default factoringpaymentByUserResource;
export const {
  fetch,
  fetchNext,
  clear,
  abortRequest,
  controller
} = factoringpaymentByUserResource;
