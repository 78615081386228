/**
 * Component for displaying a series of stars in a form. Used to rate users. Wraps [RateStars]{@link components/pure/RateStars}
 * @module components/pure/form/inputs/RateStarsInput
 * @since 3.0.0
 * @requires components/pure/RateStars
 * @property {object} props
 * @property {object} props.input - input callbacks provided by redux-form
 * @property {object} props.meta - meta information provided by redux-form
 * @property {any} [props....rest] - props passed to [RateStars]{@link components/pure/RateStars} component
 */
import React from 'react';
import cs from 'classnames';

import RateStars from 'components/pure/RateStars';


const RateStarsInput = ({ input, meta, ...rest }) =>
  <div className={cs('form-group', { 'has-error': meta.touched && meta.error })}>
    <RateStars {...rest} value={Number(input.value)} setValue={input.onChange} />
    {meta.touched && meta.error && <div className='help-block'>{meta.error}</div>}
  </div>
  ;

export default RateStarsInput;
