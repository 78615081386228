import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import { setDontShowLoggedoutmenu } from 'actions/ui';

import FactoringSignUpMarketing from 'components/container/FactoringSignUpMarketing';
import FactoringSignUpAccount from 'components/container/FactoringSignUpAccount';


export default compose(
  connect(
    null,
    {
      setDontShowLoggedoutmenu,
    },
  ),
  lifecycle({
    componentWillMount() {
      this.props.setDontShowLoggedoutmenu(true);
    },
    componentWillUnmount() {
      this.props.setDontShowLoggedoutmenu(false);
    },
  }),
)(() =>
  <FactoringSignUpMarketing
    noVideos={true}
    showForm={true}
    form={<FactoringSignUpAccount />}
  />
);
