/**
 * Connected modal component to prompt a user to create a quick factoring invoice
 * @module components/pure/modals/QuickInvoice
 * @since 3.0.0
 * @requires actions/ui
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, reduxForm, submit } from 'redux-form';
import withState from 'recompose/withState';
import { closeModal } from 'actions/ui';

import FactoringPaymentRequestInvoicePreview from 'components/container/FactoringPaymentRequestInvoicePreview';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';


let triggering_preview = false;
const FORM_NAME = 'FactoringRequestPaymentForm';

export default compose(
  withState('shouldShowPreview', 'setShouldShowPreview', false),
  connect(
    null,
    (dispatch, { setShouldShowPreview }) => ({
      closeModal() {
        dispatch(change(FORM_NAME, 'considering_creating_invoice', false));
        dispatch(closeModal());
      },
      previewTrigger() {
        triggering_preview = true;
        dispatch(submit(FORM_NAME));
      },
      submitTrigger() {
        triggering_preview = false;
        dispatch(submit(FORM_NAME));
      },
      onSubmit() {
        if (triggering_preview) {
          setShouldShowPreview(true);
        }
        else {
          dispatch(change(FORM_NAME, 'considering_creating_invoice', false));
          dispatch(closeModal());
        }
      },
    }),
  ),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
  }),
)(({ shouldShowPreview, setShouldShowPreview, previewTrigger, submitTrigger, handleSubmit, closeModal }) =>
  shouldShowPreview ?
    <div>
      <div className='modal-body'>
        <FactoringPaymentRequestInvoicePreview />
      </div>
      <div className='modal-footer'>
        <button className='btn btn-default' onClick={() => setShouldShowPreview(false)}>Go Back</button>
        <button className='btn btn-orange' onClick={submitTrigger}>Add More Details</button>
      </div>
    </div>
    :
    <div>
      <div className='modal-body'>
        <div className='light-placeholder'>
          <Header>Add More Details</Header>
          <p>You can include more details for this payment request below.</p>
          <hr />
          <form onSubmit={handleSubmit}>
            {/* <Field
            name='bill_to_company_phone'
            component={PhoneInput}
            validate={validate.compose(
              validate.phone(),
            )}
            label='Bill To Company Phone'
          />
          <Field
            name='bill_to_company_email'
            component={LabeledInput}
            validate={validate.compose(
              validate.vjs.email(),
            )}
            label='Bill To Company Email'
          /> */}
            <Field
              name='user_reference_number'
              component={LabeledInput}
              label='Your Invoice Number'
            />
            <Field
              name='user_notes'
              component={GrowableInput}
              props={{
                Component: LabeledInput,
                ComponentClass: 'textarea',
                placeholder: '',
                label: 'Notes/Details',
              }}
            />
          </form>
        </div>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-default pull-left' type='button' onClick={previewTrigger}>Preview Invoice</button>
        <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
        <button className='btn btn-orange' type='button' onClick={submitTrigger}>Add More Details</button>
      </div>
    </div>
);
