/**
 * Component to display the help page
 * @module components/pure/Help
 * @since 3.0.0
 * @requires components/pure/Header
 * @requires components/pure/form/HelpForm
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import NavItem from 'react-bootstrap/lib/NavItem';

import { USER_TYPE } from 'helpers';
import getSelfType from 'components/hoc/getSelfType';
import StatefulTabbed from 'components/stateful/StatefulTabbed';
import Header from 'components/pure/Header';
import HelpForm from 'components/pure/form/HelpForm';
import YoutubeEmbed from 'components/pure/YoutubeEmbed';
import BrokerFAQ from './BrokerFAQ';
import CarrierFAQ from './CarrierFAQ';
import InvitedCarrierFAQ from './InvitedCarrierFAQ';
import isInviteCarrier from '../../selectors/isInviteCarrier';


const FAQ = ({ children }) => <div style={{ paddingBottom: '1em' }}>{children}</div>;

FAQ.Q = ({ children }) =>
  <div>
    <span className='h4'>Q. </span>
    <span><em>{children}</em></span>
  </div>
;

FAQ.A = ({ children }) =>
  <div>
    <span className='h4'>A. </span>
    <span>{children}</span>
  </div>
;

const Help = compose(
  getSelfType,
  connect(state => ({
    isInviteCarrier: isInviteCarrier(state)
  }))
)(({ userType, isInviteCarrier}) =>
  <div style={{ textAlign: 'center' }}>
    <div className='col-xs-12 col-sm-7' style={{ textAlign: 'left', float: 'none', display: 'inline-block' }}>
      <Header><b>Help</b> Request</Header>
      <div className='help-block'>
        Please include your full email address
        <br />
        Support tickets are handled the same day.
        <br />
        You can also call us M-F 8AM-5PM Pacific.
        <br />
        <a href='tel:(888) 633-5558'>(888) 633-5558</a>
      </div>
      <HelpForm />
      <StatefulTabbed
        navItems={[
          <NavItem>Help Videos</NavItem>,
          <NavItem>Help FAQ</NavItem>,
          ...(() => isInviteCarrier || 
          USER_TYPE.BROKER === userType || 
          USER_TYPE.CARRIER === userType ?
          [<NavItem>HaulPay FAQ</NavItem>] : [])(),
        ]}
        items={[
          (() => {
            switch (userType) {
              case USER_TYPE.BROKER:
              case USER_TYPE.DISPATCHER:
                return (
                  <div className='text-center'>
                    <div>
                      <Header>Timeline</Header>
                      <YoutubeEmbed video_id='JFFMWl0Vwg4' />
                    </div>
                    <div>
                      <Header>Block Users</Header>
                      <YoutubeEmbed video_id='Wn8Y3V_hHs8' />
                    </div>
                    <div>
                      <Header>Truck Search</Header>
                      <YoutubeEmbed video_id='sW74UI93Za4' />
                    </div>
                    <div>
                      <Header>Load Search</Header>
                      <YoutubeEmbed video_id='fe2KowqyEII' />
                    </div>
                    <div>
                      <Header>Post Load</Header>
                      <YoutubeEmbed video_id='et2sIzZNOUg' />
                    </div>
                    <div>
                      <Header>Refresh Post</Header>
                      <YoutubeEmbed video_id='Vup8fxrHNj0' />
                    </div>
                    <div>
                      <Header>Post Truck</Header>
                      <YoutubeEmbed video_id='bogX6Q8VsrI' />
                    </div>
                    <div>
                      <Header>Rate Trend</Header>
                      <YoutubeEmbed video_id='QzG7czQOFSw' />
                    </div>
                    <div>
                      <Header>Notifications</Header>
                      <YoutubeEmbed video_id='JfBupYngPHk' />
                    </div>
                    <div>
                      <Header>Documents</Header>
                      <YoutubeEmbed video_id='HMO1ucJSsfY' />
                    </div>
                  </div>
                );
              case USER_TYPE.CARRIER:
                return (
                  <div className='text-center'>
                    <div>
                      <Header>Timeline</Header>
                      <YoutubeEmbed video_id='JFFMWl0Vwg4' />
                    </div>
                    <div>
                      <Header>Block Users</Header>
                      <YoutubeEmbed video_id='Wn8Y3V_hHs8' />
                    </div>
                    <div>
                      <Header>Load Search</Header>
                      <YoutubeEmbed video_id='fe2KowqyEII' />
                    </div>
                    <div>
                      <Header>Post Truck</Header>
                      <YoutubeEmbed video_id='bogX6Q8VsrI' />
                    </div>
                    <div>
                      <Header>Rate Trend</Header>
                      <YoutubeEmbed video_id='QzG7czQOFSw' />
                    </div>
                    <div>
                      <Header>Notifications</Header>
                      <YoutubeEmbed video_id='JfBupYngPHk' />
                    </div>
                    <div>
                      <Header>Documents</Header>
                      <YoutubeEmbed video_id='HMO1ucJSsfY' />
                    </div>
                  </div>
                );
            }
            // shippers and the rest (no one)
            return (
              <div className='text-center'>
                <div>
                  <Header>Timeline</Header>
                  <YoutubeEmbed video_id='JFFMWl0Vwg4' />
                </div>
                <div>
                  <Header>Post Load</Header>
                  <YoutubeEmbed video_id='et2sIzZNOUg' />
                </div>
                <div>
                  <Header>Refresh Post</Header>
                  <YoutubeEmbed video_id='Vup8fxrHNj0' />
                </div>
                <div>
                  <Header>Notifications</Header>
                  <YoutubeEmbed video_id='JfBupYngPHk' />
                </div>
                <div>
                  <Header>Documents</Header>
                  <YoutubeEmbed video_id='HMO1ucJSsfY' />
                </div>
                <div>
                  <Header>Block Users</Header>
                  <YoutubeEmbed video_id='Wn8Y3V_hHs8' />
                </div>
              </div>
            );
          })(),
          <div>
            <Header>Common Help Questions</Header>
            <p><a href='https://blog.comfreight.com/category/tips-and-faq/' rel='noopener' target='_blank'>For tips on using tools in the app we also recommend our blog</a></p>
            <FAQ>
              <FAQ.Q>How do I cancel or deactivate a full access HaulPay Carrier or Broker account?</FAQ.Q>
              <FAQ.A>To cancel or deactivate your full access account simply navigate to the Billing section under Settings and click on the Deactivate button to close your account.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>What timezone are the dates and times displayed in?</FAQ.Q>
              <FAQ.A>All timezones are local to you. For example, if you are in PST, the time displayed is in PST.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>If I am a carrier or broker using the web app can I only bid on loads or can I call on loads directly?</FAQ.Q>
              <FAQ.A>Shipper loads posted by direct shippers require a bid submitted via the HaulPay web app when viewing a shipper load. Broker loads, however, can be called on directly, bid on via the web app and messaged using the question and answer fields on the load details page for any load you find.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>Is HaulPay a Freight Broker?</FAQ.Q>
              <FAQ.A>HaulPay is a true freight marketplace and logistics exchange and is NOT a broker or broker app. HaulPay facilitates matching, bidding, dispatching and invoicing of loads between Shippers, Carriers and Brokers. HaulPay is not to be listed as the bill to on any bill of lading for any loads found on or off of the platform.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>Is the any cost to using HaulPay?</FAQ.Q>
              <FAQ.A>HaulPay is free to direct shippers to post loads, get bids and dispatch loads. Shippers must pay their freight invoice after a load is confirmed delivered which is standard for any freight shipment. Carriers and Brokers get limited access to search loads, post loads, post trucks and match freight for free! Unlimited access to bid on direct shipper loads and analytics tools requires the payment of a small subscription that can be activated by users while logged in from the billing section under settings.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>How do I know if a Shipper, Carrier or Broker is safe to do business with on HaulPay?</FAQ.Q>
              <FAQ.A>We provide FMCSA data from the DOT for Carriers and Brokers as well as basic credit info on most of our load posters and shippers to help you make the most informed decision on execution of any bid or dispatch between other parties. We also have a community rating system for users which can be checked by viewing the user's profile and can be accessed via the 'view profile' link on any load or truck post you find on the platform. We also offer advances and invoice payment on select loads.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>How many users can I have for my company on HaulPay?</FAQ.Q>
              <FAQ.A>Each account on HaulPay is tied to a specific email address. You can create as many separate accounts as you need for unique emails you have on your team. If you have a need for more than 5 unique email logins (accounts) then please contact us and we will be able to provide you with enterprise level plans that may fit you better.</FAQ.A>
            </FAQ>
            <FAQ>
              <FAQ.Q>Do you help ensure that I will get paid for services I provide to haul a load for a load poster?</FAQ.Q>
              <FAQ.A>We provide basic information on loads to tell you if a load poster (company) is approved for payment advances, and whether or not the load poster has satisfactory or approved credit. We offer the option to factor invoices on loads you find on the platform as well.</FAQ.A>
            </FAQ>
            <p><a href='//comfreight.com/privacy-policy' target='_blank'>Privacy Policy</a> | <a href='//comfreight.com/tos' target='_blank'>Terms of Service</a></p>
          </div>,
          (() => {
            if (isInviteCarrier)
                return  <InvitedCarrierFAQ />;
            switch (userType) {
              case USER_TYPE.BROKER:
               return  <BrokerFAQ />;
              case USER_TYPE.IS_INVITE_CARRIER:
              return  <InvitedCarrierFAQ />;
              case USER_TYPE.CARRIER:
              return  <CarrierFAQ />;
          }
          })(),
        ]}
      />
    </div>
  </div>
);

export default Help;
