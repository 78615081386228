import { compose } from 'redux-v3';
import spinnerIf from './spinnerIf';
import setDisplayName from 'recompose/setDisplayName';

import Spinner from 'components/pure/Spinner';


export default (itemPropName = 'item', spinner = Spinner) => compose(
  setDisplayName('spinnerIfFetching'),
  spinnerIf(props => props[itemPropName] === undefined || props[itemPropName].isFetching, {
    spinner,
  }),
);
