import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import Stripe from '../../stripe';
import { updateDispatching } from 'actions/user';
import { openModal } from 'actions/ui';


const stripeHander = Stripe({
  description: 'Freight Dispatching',
  panelLabel: 'Add/Update Dispatching Card',
});

export default compose(
  connect(
    null,
    dispatch => ({
      onClick() {
        return stripeHander.open({
          token(token) {
            return dispatch(updateDispatching(token.id))
              .then(() => {
                dispatch(openModal('success', { message: 'Successfully updated card.' }));
              })
              .catch(err => {
                console.warn(err);
                dispatch(openModal('error'), { message: 'We had a problem updating your card. Please try again later.' });
              })
            ;
          },
        });
      },
    }),
  ),
)(props =>
  <button
    {...props}
    className='btn btn-orange btn-block'
    style={{
      margin: '0.5em 0',
      ...props.style,
    }}
    >
    Update Dispatching Card
  </button>
);
