import React from 'react';
import ModalBody from 'components/pure/ModalBody';
import { Button } from 'react-bootstrap';
import MaterialIcon from 'components/pure/MaterialIcon';
import { withStateHandlers } from 'recompose';
import { Field, reduxForm, formValueSelector } from 'redux-form';
import PennyInput from '../form/inputs/PennyInput';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';
import { get } from 'lodash';
import validate from 'validate.js';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 14,
    color: '#5c6979',
    textAlign: 'justify',
    fontWeight: '100',
  },
  icon: {
    color: '#f5a623',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
};
const DebtorFuelAdvanceDialog = ({
  closeModal,
  amount,
  fundingRequestAmount = 0,
  action,
  totalPaid,
  handleSubmit,
  pristine,
  ...props
}) => (
  <div >
    <ModalBody closeModal={closeModal}>
      <div >
        <div
          style={{ ...styles.flexRow, justifyContent: 'center', padding: '10px' }}
          >
          <MaterialIcon
            name='error'
            size={40}
            style={styles.icon}
          />
        </div>
        <div>
          <p style={styles.text}>
            Did you already take an advance from your customer on this load? If
            you did please enter the amount of this advance below:
          </p>
          <div style={{ padding: '5px 5px 5px 5px' }}>
            <Field
              name='amount'
              component={PennyInput}
              label='Advanced Amount'
            />
          </div>
          <p style={{ ...styles.text, fontSize: 12 }}>
            Total to be paid:{' '}
            <span
              style={{
                ...styles.text,
                fontSize: 12, color: '#2EBD00' }}
              >
              {formatPennies(totalPaid)}
            </span>
          </p>
        </div>
      </div>
    </ModalBody>
    <div style={{ ...styles.flexRow, margin: '-5px 5px 5px 5px' }}>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-start',
        }}
        >
        <Button
          style={{
            borderColor: '#FF7800',
          }}
          onClick={() => {
            get(action, 'no', () => ({}))(amount);
            closeModal();
          }}
          >
          No
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
        >
        <Button
          style={styles.button}
          onClick={async () => {
            await handleSubmit();
            if (!pristine && amount > 0) {
              get(action, 'confirm', () => ({}))(amount);
              closeModal();
            }
          }}
          >
          Confirm
        </Button>
      </div>
    </div>
  </div>
);
const FORM_NAME = 'advancedAmount';
const selector = formValueSelector(FORM_NAME);
const selectorValueFactoringForm = formValueSelector('FactoringRequestPaymentForm');
export default compose(
  connect(state => ({
    amount: selector(state, 'amount'),
    totalPaid: selectorValueFactoringForm(state, 'totalPaid'),
  }),
  dispatch => ({
    closeModal() {
      dispatch(closeModal());
    },
  }),
  ),
  reduxForm({ form: 'advancedAmount',
    onSubmit() {
      return;
    },
    validate(fields) {
      return validate(fields, {
        amount: {
          presence: true,
          numericality: {
            greaterThan: 0,
            notGreaterThan: 'must be greater than 0$',
          },
        },
      });
    } }))(DebtorFuelAdvanceDialog);
