import React from 'react';
import { Link } from 'react-router-v4';
import MaterialIcon from 'components/pure/MaterialIcon';
import ModalBody from 'components/pure/ModalBody';
import formatPennies from '../../../helpers/formatPennies';

const style = {
    container: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap'
    },
    item: {
        justifyContent: 'center',
        margin: '1%',
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%'
    },
    text: {
        fontSize: 15
    }
}

const ConfirmationFRPaymentSpeed = ({ 
    initialValue, 
    currentValue, 
    defaultValue, 
    update, 
    handleSubmit, 
    closeModal, 
    invoiceNumber,
    load,
    invoiceAmount,
    carrierName,
    fundingRequestId
}) => {
    const initialPayoutDays = (initialValue.payout_days || '').match(/\d+/g)[0];
    const currentPayoutDays = (currentValue.payout_days || '').match(/\d+/g)[0];
    const defaultPayoutDays = (defaultValue.payout_days || '').match(/\d+/g)[0];
    return <div id="confirmation-fr-payment-speed">
        <ModalBody
            closeModal={closeModal}
        >
            <div style={style.container}>
                <div style={style.item}>
                    <MaterialIcon name='warning' className='text-warning' style={{ fontSize: 50 }} />
                    <p style={style.text}>
                        Are you sure?
                    </p>
                </div>
                {
                    invoiceNumber &&
                    <div style={style.item}>
                        <p style={style.text}>
                                Invoice #: <Link target="_blank" to={`/haul-pay/${fundingRequestId}/transaction/`}>{invoiceNumber}</Link>
                        </p>
                    </div>
                }
                {
                    load &&
                    <div style={style.item}>
                        <p style={style.text}>
                            {`Load #: ${load}`}
                        </p>
                    </div>
                }
                {
                    invoiceAmount &&
                    <div style={style.item}>
                        <p style={style.text}>
                                {`Invoice Amount : ${formatPennies(invoiceAmount)}`}
                        </p>
                    </div>
                }
                {
                    carrierName &&
                    <div style={style.item}>
                        <p style={style.text}>
                            {`Carrier: ${carrierName}`}
                        </p>
                    </div>
                }
                <div style={style.item}>
                    <p style={style.text}>
                        {`This Funding request is currently set to a Payment Speed of ${initialPayoutDays} ${initialPayoutDays === '1' ? 'day' : 'days'} and a Broker Portion of ${initialValue.factoring_fee_split_rate}%.`}
                    </p>
                </div>
                {
                    update &&
                    <div style={style.item}>
                        <p style={style.text}>
                            {`You are updating this Funding request to a Payment Speed of ${currentPayoutDays} ${currentPayoutDays === '1' ? 'day' : 'days'} and a Broker Portion of ${currentValue.factoring_fee_split_rate}%.`}
                        </p>
                    </div>
                }
                <div style={style.item}>
                    <p style={style.text}>
                        {`This is different than the default Payment Speed of ${defaultPayoutDays} ${defaultPayoutDays === '1' ? 'day' : 'days'} and a Broker Portion of ${defaultValue.factoring_fee_split_rate}%.`}
                    </p>
                </div>
                <div style={{
                    ...style.item, display: "flex", flex: 1, flexDirection: 'row', alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <button
                        style={{ width: "47%" }}
                        className='btn btn-default'
                        type='submit'
                        onClick={closeModal}>
                        {invoiceNumber ? 'Skip' : 'Cancel'}
                    </button>
                    <div style={{ width: "6%" }} />
                    <button
                        style={{ width: "47%" }}
                        className='btn btn-orange'
                        type='submit'
                        onClick={handleSubmit}>
                        {invoiceNumber ? 'Confirm' : 'Continue'}
                    </button>
                </div>
            </div>
        </ModalBody>
    </div>
};
export default ConfirmationFRPaymentSpeed;
