/**
 * Component that displays the filter for mobile search
 * @module components/pure/MobileFilter
 * @since 3.0.0
 * @requires components/pure/Arrow
 * @property {object} props
 * @property {object} props.children
 * @property {function} props.onDoneClick - function to be called on click
 * @property {function} [props.swapOriginAndDestination] - if passed, adds a button to swap the origin and destination fields of the search
 * @property {string} [props.submitText] - the text to display on the submit button
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';

import Arrow from './Arrow';


const MobileFilter = ({ children, onDoneClick, swapOriginAndDestination, submitText = 'Done' }) =>
  <div className='mobile-filter'>
    <div>
      {children}
    </div>
    <div className='row-2col spacer-xs' style={{ display: 'flex' }}>
      {swapOriginAndDestination ? <button className='btn btn-default' onClick={swapOriginAndDestination}>Reverse Search</button> : null}
      <div style={{ flexGrow: 1 }}>
        <Arrow
          role='button'
          tailless={true}
          direction={Arrow.RIGHT}
          onClick={onDoneClick}
          className='text-capitalize'
          >
          {submitText}
        </Arrow>
      </div>
    </div>
    <style jsx>{`
      .mobile-filter {
        position: absolute;
        min-height: 100%;
        min-width: 100%;

        display: flex;
        flex-direction: column;

        padding: 1em;
      }
    `}</style>
  </div>
  ;

MobileFilter.propTypes = {
  children: PropTypes.element.isRequired,
  onDoneClick: PropTypes.func.isRequired,
};

export default MobileFilter;
