import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { reset } from 'redux-form';
import moment from 'moment';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';
import getSelf from 'selectors/getSelf';
import { USER_TYPE } from 'helpers';

import adminNotes from 'actions/admin/clientcombinednotes';
import { edit, getAttachment } from 'actions/resource/usernote';
import { create } from 'actions/resourceBy/factoringclient/factoringclientnote';
import { edit as editClientNote, getAttachment as getClientNoteAttachment } from 'actions/resource/factoringclientnote';
import { openModal } from 'actions/ui';

import Header from 'components/pure/Header';
import LineItemHorizontal from 'components/pure/LineItemHorizontal';
import NoteForm from 'components/pure/form/NoteForm';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';


const FactoringClientNotesForm = compose(
  mapProps(({ id, ...rest }) => ({
    form: 'FactoringClientNotesForm',
    onSubmit(fields, dispatch) {
      return dispatch(create(id, {
        factoring: id,
        note: fields.note,
        attachments: fields.attachments,
      }))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully posted note' }));
          dispatch(reset('FactoringClientNotesForm'));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Failed to post note' }));
        })
      ;
    },
    ...rest,
  })),
)(NoteForm);

const FactoringClientNoteEditForm = compose(
  mapProps(({ item, cancelEdit }) => ({
    form: `FactoringClientNoteEditForm#${item.data.id}`,
    initialValues: {
      note: item.data.note,
    },
    cancel: cancelEdit,
    onSubmit(fields, dispatch) {
      const data = {
        factoring: item.data.factoring,
        note: fields.note,
        attachments: fields.attachments,
      };
      let editFn;
      if (item.data.user) {
        editFn = edit;
        data.user = item.data.user;
      }
      if (item.data.factoring) {
        editFn = editClientNote;
        data.factoring = item.data.factoring;
      }
      return dispatch(editFn(item.data.id, data))
        .then(() => {
          dispatch(openModal('success', { message: 'Successfully edited note' }));
        })
        .catch(err => {
          console.warn(err);
          dispatch(openModal('error', { message: 'Failed to edit note' }));
        })
        .then(cancelEdit)
      ;
    },
  })),
)(FactoringClientNotesForm);

const FactoringClientNote = compose(
  withState('isEditing', 'setIsEditing', false),
  connect(
    state => {
      const self = getSelf(state);
      return {
        userType: self.data.type,
      };
    },
    dispatch => ({
      openAttachment(id, attachment, getAttachment) {
        return dispatch(getAttachment(id, attachment))
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Failed to fetch attachment' }));
          })
        ;
      },
      deleteNote(id, type) {
        return dispatch(openModal('confirm', { id, type }));
      },
    }),
  ),
)(({ item, openAttachment, deleteNote, isEditing, setIsEditing, userType }) =>
  <LineItemHorizontal
    key={item.data.id}
    label={
      <div style={{ fontSize: '0.8em' }}>
        <div>{item.data.posted_by_name}</div>
        <div>{item.data.created.format('l')}</div>
        <div>{item.data.created.format('LT')}</div>
        {
          (userType !== USER_TYPE.ADMIN && item.data.created.isBefore(moment().subtract(2, 'hours'))) ||
          ((userType === USER_TYPE.FACTORING_ADMIN || userType === USER_TYPE.ALADDIN_ADMIN) && item.data.user)
            ? null :
            <div>
              <div className='text-red' role='button' onClick={() => deleteNote(item.data.id, item.data.user ? 'usernote_delete' : 'factoringclientnote_delete')}>Delete Note</div>
              <div className='text-blue' role='button' onClick={() => setIsEditing(true)}>Edit Note</div>
            </div>
        }
      </div>
    }
    >
    {
      isEditing ?
        <FactoringClientNoteEditForm item={item} cancelEdit={() => setIsEditing(false)} />
        :
        <div>
          <div dangerouslySetInnerHTML={{ __html: item.data.note ? item.data.note.replace(/(\r\n|\n|\r)/gm, '<br />') : null }} />
          {item.data.attachments.map((attachment, i) =>
            <LoadAttachmentButton key={i} title={attachment} getURL={() => openAttachment(item.data.id, attachment, item.data.user ? getAttachment : getClientNoteAttachment)} />
          )}
        </div>
    }
  </LineItemHorizontal>
);

export default compose(
  connect(
    (state, { id }) => {
      if (state.admin.clientcombinednotes.parameters.factoring !== id) {
        return { items: [] };
      }
      return {
        items: state.admin.clientcombinednotes.ids.map(id => state.resource.factoringclientnote[id] || state.resource.usernote[id]),
      };
    },
    {
      fetch: adminNotes.fetch,
      clear: adminNotes.clear,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch({
        factoring: this.props.id,
      });
    },
  }),
)(({ id, items }) =>
  <div>
    <Header hr={true}>Notes</Header>
    <FactoringClientNotesForm id={id} />
    <div style={{ marginTop: '1em' }}>
      {items.map(item => <FactoringClientNote item={item} />)}
    </div>
  </div>
);
