import React from 'react';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import { doDelete, edit } from 'actions/resource/factoringtransactionpayment';
import { closeModal, openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import { connect } from 'react-redux-v5';

export default compose(
  Resource('factoringtransactionpayment', {
    idPropName: ['id'],
    fridPropName: ['frid'],
  }),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
        amount: props.item.data.amount,
        status: props.item.data.status,
        admin_notes: props.item.data.admin_notes,
      },
    };
  }),
  reduxForm({
    form: 'FactoringPaymentTransactionEditForm',
    async onSubmit(fields, dispatch, props) {
      fields.funding_request = props.frid;
      try {
        await dispatch(edit(fields, props.frid, props.id));
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Payment Updated' }));
      }
      catch (err) {
        dispatch(closeModal());
        dispatch(openModal('error', { message: 'Cannot Update Payment' }));
      }
    },
    async triggerDelete(dispatch, props) {
      try {
        await dispatch(doDelete(props.frid, props.id));
      }
      catch (err) {
        console.log(err);
      }
    },
  }),

)(({ handleSubmit, submitting }) =>
  <CenteredColumn sm={4}>
    <form onSubmit={handleSubmit}>
      <Field
        name='status'
        component={LabeledDropdown}
        label='Status'
        data={[
          {
            text: 'Payment Successful',
            value: 'payment_successful',
          },
          {
            text: 'Need Review',
            value: 'needs_review',
          },
        ]}
      />
      <Field
        name='amount'
        component={PennyInput}
        label='Amount'
      />
      <Field
        name='admin_notes'
        label='Admin Notes'
        component={GrowableInput}
        props={{
          Component: LabeledInput,
          ComponentClass: 'textarea',
          placeholder: 'Enter details about the payment (optional).',
        }}
      />
      <div className='text-right'>
        <button className='btn btn-orange' type='submit' disabled={submitting}>Edit Payment</button>
      </div>
    </form>
  </CenteredColumn>
);
