import React from 'react';
import PropTypes from 'prop-types';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import cs from 'classnames';
import styled from 'styled-components';

class Dropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasFocus: false
    };
    this.toggleFocus = this.toggleFocus.bind(this);
  }

  static propTypes = {
    children: PropTypes.element.isRequired,
    items: PropTypes.arrayOf(PropTypes.node).isRequired
  };

  toggleFocus(hasFocus = !this.state.hasFocus) {
    this.setState({ hasFocus });
  }

  render() {
    const {
      children,
      items,
      ContainerComponent = 'div',
      closeOnSelect = false,
      ...rest
    } = this.props;
    const { hasFocus } = this.state;

    return (
      <ContainerComponent
        {...rest}
        className={cs(rest.className, {
          open: hasFocus
        })}
      >
        <div
          style={{
            position: 'relative'
          }}
        >
          {React.cloneElement(children, {
            className: cs(
              children.props.className,
              'dropdown-toggle',
              'hover-background-gray'
            ),
            onClick: this.toggleFocus
          })}
          {hasFocus && (
            <RootCloseWrapper onRootClose={e => this.toggleFocus(false)}>
              <ul
                className="Dropdown-menu dropdown-menu"
                style={{
                  display: 'block',
                  left: 0
                }}
              >
                {items.map((item, i) => (
                  <li
                    key={i}
                    onClick={
                      closeOnSelect ? () => this.toggleFocus(false) : undefined
                    }
                  >
                    {item}
                  </li>
                ))}
              </ul>
            </RootCloseWrapper>
          )}
        </div>
      </ContainerComponent>
    );
  }
}

const StyledDropdown = styled(Dropdown)`
  .Dropdown {
    position: relative;

    &-menu {
      display: block;
      border-radius: 0;
      border: solid 1px #c3c3c3;
      background: white;
      border-top: 0;
      padding: 0;

      position: absolute;
      top: 100%;
      left: 0;
      z-index: 1000;
      display: none;
      min-width: 160px;
      font-size: 14px;
      text-align: left;
      list-style: none;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ccc;
      border: 1px solid rgba(0, 0, 0, 0.15);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);

      & > li > * {
        padding: 0.5em;
        &:hover {
          background: #eeeeee;
        }
      }
    }
  }
`;

export default StyledDropdown;
