/**
 * Actions relating to the Bid resource
 * @module actions/resource/bid
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 * @requires datatypes/ControlledResource
 */
/*global API */
import moment from 'moment';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import ControlledResource from 'datatypes/ControlledResource';


/**
 * The ControlledResource that handles Bid parsing and related actions
 * @extends module:datatypes/ControlledResource~ControlledResource
 */
class BidResource extends ControlledResource {
  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return super.parse(oldValue, {
      ...json,
      time_posted: moment(json.time_posted),
    });
  }
}

/**
 * Singleton for our BidResource
 */
export const bidResource = new BidResource('bid', {
  url: id => `bid/${id}`,
});

export default bidResource;
export const {
  doDelete,
} = bidResource;


/**
 * Accept bid success action
 * @event BIDS_ACCEPT_SUCCESS
 * @property {symbol} type - Symbol(BIDS_ACCEPT_SUCCESS)
 * @property {string} payload - the data returned by the query to the server
 */
export const BIDS_ACCEPT_SUCCESS = Symbol('BIDS_ACCEPT_SUCCESS');
/**
 * Thunk for making a request to the server to set a bid's status to `accepted`
 * @param {string} id - the ID of the bid to be accepted
 * @fires BIDS_ACCEPT_SUCCESS
 */
export const accept = ({ id, load_id }) => async (dispatch, getState) => {
  const state = getState();

  const res = await dispatch(APIFetch(`${API.host}/bid/${id}/accept/`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${state.user.token}`,
      'Content-Type': 'application/json',
    },
  }));

  if (res.status !== 200) {
    const text = await res.text();
    throw new FetchError(res.status, text);
  }

  dispatch({
    type: BIDS_ACCEPT_SUCCESS,
    payload: {
      id,
      load_id,
    },
  });
};
