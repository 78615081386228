/**
 * Actions for the factoring debtor actions
 * @module actions/admin/factoring/debtor
 * @since 3.0.1
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringapplication
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import bankinfoResource from 'actions/resource/GetBankInfo';

class BankinfoAdminPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }
}

const bankinfoPaginatedResource = new BankinfoAdminPaginatedResource('bankinfo', {
  mountPoint: ['user', 'accounts'],
  url: new UrlAssembler('user/:id/accounts'),
  baseResource: bankinfoResource,
});

export default bankinfoPaginatedResource;
