/**
 * Actions relating to the QA by Truck resource
 * @module actions/resourceBy/truck/qa
 * @since 3.0.0
 * @requires datatypes/ControlledPaginatedResourceCollection
 * @requires actions/resource/qa
 */
import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import qaResource from 'actions/resource/qa';


/**
 * Our QA by Truck ControlledPaginatedResourceCollection instance
 */
export const qaByTruckResource = new ControlledPaginatedResourceCollection('truck', 'qa', {
  url: new UrlAssembler('truck/:id/question'),
  paginatedResourceOptions: {
    baseResource: qaResource,
  },
});

export default qaByTruckResource;
export const {
  fetch,
  fetchNext,
  create,
} = qaByTruckResource;
