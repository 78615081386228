/**
 * Settings resource for the `/shippersettings` endpoint. Named "loadalerts" because at one point the form that edited properties on this object was labeled "Load Alert Settings Form"
 * @module actions/settings/loadalerts
 * @since 3.0.0
 * @requires datatypes/SettingsResource
 */
import SettingsResource from 'datatypes/SettingsResource';

/**
 * Our `/shippersettings` Settings Resource
 */
const loadalertsSettingsResource = new SettingsResource('loadalerts', {
  url: () => 'shippersettings',
  mountPoint: 'settings.loadalerts',
});

export default loadalertsSettingsResource;
export const {
  fetch,
  edit,
} = loadalertsSettingsResource;
