/**
 * Component that displays a series of buttons that will share the current route on social media
 * @module components/pure/SocialMediaShareButtons
 * @since 3.0.0
 * @property {object} props
 * @property {string} [props.title = 'ComFreight']
 * @property {string} [props.description = 'Load Board']
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import { ShareButtons, generateShareIcon } from 'react-share';


const {
  FacebookShareButton,
  GooglePlusShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} = ShareButtons;

const FacebookIcon = generateShareIcon('facebook');
const TwitterIcon = generateShareIcon('twitter');
const GooglePlusIcon = generateShareIcon('google');
const LinkedinIcon = generateShareIcon('linkedin');

const SocialMediaShareButtons = ({ title = 'HaulPay', description = 'Load Board', ...rest }) =>
  <div {...rest} className={cs('clearfix', rest.className)} style={{ marginBottom: '0.25em', ...rest.style }}>
    <div className='pull-right'>
      <TwitterShareButton
        role='button'
        style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '0.25em', cursor: 'pointer' }}
        url={location.href}
        title={title}
        >
        <TwitterIcon size={30} />
      </TwitterShareButton>
      <FacebookShareButton
        role='button'
        style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '0.25em', cursor: 'pointer' }}
        url={location.href}
        title={title}
        description={description}
        >
        <FacebookIcon size={30} />
      </FacebookShareButton>
      <LinkedinShareButton
        role='button'
        style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '0.25em', cursor: 'pointer' }}
        url={location.href}
        title={`${title} ${description}`}
        >
        <LinkedinIcon size={30} />
      </LinkedinShareButton>
      <GooglePlusShareButton
        role='button'
        style={{ display: 'inline-block', verticalAlign: 'middle', marginLeft: '0.25em', cursor: 'pointer' }}
        url={location.href}
        >
        <GooglePlusIcon size={30} />
      </GooglePlusShareButton>
    </div>
  </div>
  ;

SocialMediaShareButtons.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SocialMediaShareButtons;
