/**
 * Component to display a mobile-friendly truck view
 * @module components/pure/TruckMobile
 * @since 3.0.0
 * @requires helpers
 * @requires components/hoc/spinnerIfFetching
 * @requires components/pure/RouteFormat
 * @requires components/pure/TitledGrid
 * @requires components/pure/PureUserTable
 * @property {object} props
 * @property {object} props.truck
 * @property {object} props.truck.data
 * @property {object} props.truck.data.trailerTypes
 * @property {object} props.truck.data.length
 * @property {object} props.truck.data.weight
 * @property {object} props.truck.data.time_posted
 * @property {object} props.truck.data.time_start
 * @property {object} props.truck.data.time_end
 * @property {object} props.truck.data.user
 * @property {object} props.truck.data.id
 * @property {object} props.truck.data.user_type
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import cs from 'classnames';
import moment from 'moment';

import { ageAbbr, formatWeight, trailerTypeMap } from '../../helpers';

import spinnerIfFetching from '../hoc/spinnerIfFetching';
import RouteFormat from './RouteFormat';
import TitledGrid from './TitledGrid';
import MaterialIcon from 'components/pure/MaterialIcon';
import UserTable from './PureUserTable';


export const TruckMobile = compose(
  spinnerIfFetching('truck'),
)(({ truck, ...rest }) =>
  <div {...rest} className={cs('truck-mobile', rest.className)}>
    <div className='truck-mobile-truck'>
      <RouteFormat.Truck truck={truck} />
      <div className='truck-mobile-truck-physical-info'>
        {truck.data.trailerTypes && truck.data.trailerTypes.length > 2 ? truck.data.trailerTypes.join(', ') : truck.data.trailerTypes.map(tt => trailerTypeMap[tt]).join(', ') || 'Any'}
        {' / '}
        {truck.data.length ? `${truck.data.length} ft` : '—'}
        {' / '}
        {truck.data.weight ? `${formatWeight(truck.data.weight)} lbs` : '—'}
      </div>
      <TitledGrid cols={3}>
        <TitledGrid.Section title='ready'>
          <div>{truck.data.time_start.format('D MMM')}</div>
          <div style={{ fontSize: '0.7em' }} >
            {truck.data.time_start.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='close'>
          <div>{truck.data.time_end.format(`D MMM${truck.data.time_end.year() !== moment().year() ? ' YYYY' : ''}`)}</div>
          <div style={{ fontSize: '0.7em' }}>
            {truck.data.time_end.format('LT')}
          </div>
        </TitledGrid.Section>
        <TitledGrid.Section title='truck age'>
          <MaterialIcon name='access_time' style={{ color: colors.VERY_DARK_GRAY }} /> {ageAbbr(truck.data.time_posted)}
        </TitledGrid.Section>
      </TitledGrid>
    </div>
    <UserTable
      items={[{
        item: truck.data.user,
        truck_id: truck.data.id,
      }]}
      isShipperOnly={truck.data.user_type === 'shipper'}
    />
    <style jsx>{`
      .truck-mobile-truck {
        font-weight: bold;
        text-align: center;
        margin: 0 0.75em;
        padding: 0.75em 0;
      }
      .truck-mobile-truck-physical-info {
        padding: 0.5em 0;
        border-bottom: solid 1px ${colors.BORDER_GRAY};
      }
    `}</style>
  </div>
);

TruckMobile.propTypes = {
  truck: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.shape({
      trailerTypes: PropTypes.arrayOf(PropTypes.string),
      length: PropTypes.number,
      weight: PropTypes.number,
      time_posted: PropTypes.instanceOf(moment),
      time_start: PropTypes.instanceOf(moment),
      time_end: PropTypes.instanceOf(moment),
      id: PropTypes.string,
      user_type: PropTypes.oneOf(['shipper', 'carrier', 'broker']),
    }),
  }),
};

export default TruckMobile;
