import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';

import * as validate from 'helpers/validate';

import { closeModal, openModal } from 'actions/ui';
import { editProfile2 } from 'actions/resource/user';
import * as loadalertsettings from 'actions/settings/loadalerts';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import getSelf from 'selectors/getSelf';

export default compose(
  connect(
    state => ({
      id: state.user.id,
      self: getSelf(state),
    }),
    {
      closeModal,
    },
  ),
  mapProps(({ self, ...rest }) => ({
    ...rest,
    initialValues: {
      equipment_types: self.data.equipment_types.map(et => MultiTrailerInput.trailerTypes.find(({ value }) => et === value)),
      cargo_insurance_value: self.data.cargo_insurance_value,
      cargo_insurance_provider: self.data.cargo_insurance_provider,
      commodities_shipped: self.data.commodities_shipped,
      lane_origin_states: [],
      lane_destination_states: [],
    },
  })),
  reduxForm({
    form: 'CarrierSignUpExtraForm',
    async onSubmit(fields, dispatch, { id }) {
      try {
        await dispatch(editProfile2(id, {
          equipment_types: fields.equipment_types.map(({ value }) => value),
          cargo_insurance_value: fields.cargo_insurance_value,
          cargo_insurance_provider: fields.cargo_insurance_provider,
          commodities_shipped: fields.commodities_shipped,
          lanes: (() => {
            if (!fields.lane_origin_states.length) {
              return [];
            }
            // X states -> anywhere (0 states)
            if (!fields.lane_destination_states.length) {
              return fields.lane_origin_states.map(({ code }) => {
                return {
                  origin: { state: code },
                };
              });
            }
            return fields.lane_origin_states.reduce((acc, curr) => {
              const origin = curr;
              for (const destination of fields.lane_destination_states) {
                acc.push({
                  origin: { state: origin.code },
                  destination: { state: destination.code },
                });
              }
              return acc;
            }, []);
          })(),
        }));
        await dispatch(loadalertsettings.edit({
          equipment_type: fields.equipment_types.map(({ value }) => value),
          pickup_states: fields.lane_origin_states.map(({ code }) => code),
        }));
        dispatch(closeModal());
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'There was an error updating your information. :(' }));
      }
    },
  }),
)(({ handleSubmit, submitting, closeModal }) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Improve Your Matches</h4>
    </div>
    <div className='modal-body'>
      <p className='text-center text-danger'><b>This information will help us match you to the best loads!</b></p>
      <Field
        name='equipment_types'
        component={MultiTrailerInput}
        validate={validate.compose(
          validate.vjs.presence(),
          validate.numericality({
            minimim: 1,
            tooSmall: ({ minimum }) => `Please enter at least ${minimum} equipment type`,
            tokenizer: ({ length }) => length,
          }),
        )}
        label='What types of loads do you haul?'
      />
      <Field
        name='cargo_insurance_value'
        component={PennyInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label={'What\'s your cargo insurance value?'}
      />
      <Field
        name='cargo_insurance_provider'
        component={LabeledInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='Who is your cargo insurance provider?'
      />
      <Field
        name='commodities_shipped'
        component={LabeledInput}
        validate={validate.compose(
          validate.vjs.presence(),
        )}
        label='What are the commodities you ship?'
      />
      <label style={{ display: 'block' }}>What are your common lanes?</label>
      <label style={{ display: 'block' }}>From:</label>
      <Field
        name='lane_origin_states'
        component={MultiStateInput}
      />
      <label style={{ display: 'block' }}>To:</label>
      <Field
        name='lane_destination_states'
        component={MultiStateInput}
      />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal} type='button'>Skip</button>
      <button className='btn btn-orange' type='submit' disabled={submitting}>Done</button>
    </div>
  </form>
);
