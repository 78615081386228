import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { lifecycle, mapProps, withState } from 'recompose';
import { create } from 'actions/resource/factoringlineitemcreate';
import { closeModal, openModal } from 'actions/ui';
import Resource from 'components/hoc/Resource';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import formatError from '../../helpers/formatError';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import { fetch } from 'actions/resource/factoringpayment';
import { getLineItemCategories } from 'actions/lineitemcategories';

const FORM_NAME = 'FactoringLineItemCreateForm';
const selector = formValueSelector(FORM_NAME);

const shouldCheckBoxInput = (approval_status, againstWho) => {
  const status_check = [
    'approved',
  ].includes(approval_status) && againstWho === 'debtor';
  return status_check;
};

export default compose(
  connect(
    state => ({
      approval_status: selector(state, 'approval_status'),
      category: selector(state, 'category'),
      againstWho: selector(state, 'applied_against'),
    }),
    dispatch => {
      return {
        getCategories: () => dispatch(getLineItemCategories()),
        clearSelectedCategory: () => dispatch(change(FORM_NAME, 'category', null)),
      };
    }
  ),
  withState('categories', 'setCategories', []),
  lifecycle({
    async componentDidMount() {
      const categories = await this.props.getCategories();
      const values = categories
        .map(category => ({
          value: category.id,
          text: category.name,
          applied_against: category.applied_against,
        }));
      this.props.setCategories(values);
    },
  }),
  Resource('factoringtransactionlineitem', {
    idPropName: ['id'],
  }),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
      },
    };
  }),
  reduxForm({
    form: 'FactoringLineItemCreateForm',
    initialValues: {
      amount_requested: 0.0,
      // amount_funded: 0.0,
      approval_status: 'pending',
      // payment_status: 'pending',
      // source: '<<Select One>>',
      bill_with_funding_request: false,
    },
    async onSubmit(fields, dispatch, props) {
      try {
        // replace the 'id' with the real Funding Request ID
        await dispatch(create(fields, props.id));
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Line item created !' }));
        dispatch(fetch(props.id));
        props.refresh();
      }
      catch (err) {
        dispatch(closeModal());
        dispatch(openModal('error', { message: formatError(err, 'Unable to create line item') }));
      }
    },
  }),
)(({ approval_status, client, debtor, dispatch, handleSubmit, submitting, category, categories, againstWho, clearSelectedCategory }) =>
  <form onSubmit={handleSubmit} className='form-horizontal'>
    <CenteredColumn
      xs={12}
      md={9}
      >
      <Field name='amount_requested' component={PennyInput} label='Amount Requested' />
      <Field name='discount_rate' component={LabeledInput} label='Discount Rate' placeholder='<<Inherit from the Funding Request>>' />
      <Field
        name='applied_against'
        placeholder='Select'
        component={LabeledDropdown}
        data={[
          { text: 'Client', value: 'client' },
          { text: 'Debtor', value: 'debtor' },
          { text: 'ComFreight', value: 'organization' },
          { text: 'Proof', value: 'proof' },
        ]}
        label='Against Who?'
        onChange={clearSelectedCategory}
      />
      { againstWho && (
        <Field
          name='category'
          placeholder='Select Category'
          component={LabeledDropdown}
          data={categories.filter(category => category.applied_against === againstWho)}
          label='Category'
        />
      )}
      <Field name='description' ComponentClass='textarea' component={LabeledInput} label='Description' style={{ height: '100px' }} />
      <Field
        name='approval_status' component={LabeledDropdown}
        data={[
          { text: 'Yes', value: 'approved' },
          { text: 'No', value: 'pending' },
        ]}
        label='Approve?'
      />
      {
        shouldCheckBoxInput(approval_status, againstWho) ?
          <Field
            name='bill_with_funding_request'
            className='text-success'
            component={CheckboxInput}
            labelStyle={{ marginBottom: 5 }}
            label='Bill With Funding Request?'
            style={{ marginTop: 5, marginBottom: 5, marginLeft: -15 }}
          />
          :
          null
      }
      {' '}
      <Field
        name='attachments'
        component={MultiImageInput}
        limit={25}
        buttonText='Upload Documents'
        buttonClassName='btn btn-default'
        shouldCrop={true}
        convertAnyway={true}
      />
    </CenteredColumn>
    <div className='modal-footer'>
      <button style={{ marginLeft: '8px' }} className='btn btn-orange pull-right' disabled={submitting}>Create Line Item</button>
      <button className='btn btn-default' onClick={() => dispatch(closeModal())}>Cancel</button>
    </div>
  </form>
);
