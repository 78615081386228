/**
 * Reducer for handling the usersearch resource actions
 * @module reducers/resource/usersearch
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/usersearch
 * @requires actions/search/user
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.usersearch;

import userSearchResource from 'actions/resource/usersearch';
import userSearchPaginatedResource from 'actions/search/user';
import {
  SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS,
  SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS,
} from 'actions/settings/documents';


export default function usersearchResourceReducer(state = initialState, action) {
  state = userSearchResource.reduce(state, action);
  switch (action.type) {
    case userSearchPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...userSearchResource.parseMany(state, action.payload.results),
      };
    case SETTINGS_DOCUMENTS_UPLOAD_REQUEST_SUCCESS: {
      if (!state[action.payload.id]) {
        return state;
      }
      const { category } = action.payload.doc;
      const files = state[action.payload.id].data.files;
      const new_files = [];
      if (['documents_authority', 'documents_insurance'].includes(category)) {
        const index = files.findIndex(file => file.category === category);
        new_files.push(...files.slice(0, index), action.payload.doc, ...files.slice(index + 1));
      }
      else {
        new_files.push(...files, action.payload.doc);
      }
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          data: {
            ...state[action.payload.id].data,
            files: new_files,
          },
        },
      };
    }
    case SETTINGS_DOCUMENTS_DELETE_REQUEST_SUCCESS: {
      if (!state[action.payload.id]) {
        return state;
      }
      const index = state[action.payload.user].data.files.findIndex(file => file.id === action.payload.id);
      return {
        ...state,
        [action.payload.user]: {
          ...state[action.payload.user],
          data: {
            ...state[action.payload.user].data,
            files: [
              ...state[action.payload.user].data.files.slice(0, index),
              ...state[action.payload.user].data.files.slice(index + 1),
            ],
          },
        },
      };
    }
  }
  return state;
}
