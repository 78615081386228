/**
 * Reducer for UI actions
 * @module reducers/ui
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/ui
 * @listens UI_TOGGLE_MENU
 * @listens UI_OPEN_MODAL
 * @listens UI_CLOSE_MODAL
 * @listens SET_LAST_TOOL
 * @listens SET_SCROLL_TO_ITEM
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.ui;

import {
  SET_DONT_SHOW_LOGGEDOUTMENU,
  SET_LAST_TOOL,
  SET_SCROLL_TO_ITEM,
  UI_CLOSE_MODAL,
  UI_OPEN_MODAL,
  UI_TOGGLE_MENU,
  UI_UNSHIFT_MODAL,
} from '../actions/ui';


export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case UI_TOGGLE_MENU:
      return {
        ...state,
        menu_open: action.payload !== undefined ? action.payload : !state.menu_open,
      };
    case UI_OPEN_MODAL:
      return {
        ...state,
        modal: [
          ...state.modal,
          {
            type: action.payload.type,
            props: action.payload.props,
          },
        ],
      };
    case UI_CLOSE_MODAL:
      return {
        ...state,
        modal: state.modal.slice(1),
      };
    case UI_UNSHIFT_MODAL:
      return {
        ...state,
        modal: [
          {
            type: action.payload.type,
            props: action.payload.props,
          },
          ...state.modal,
        ],
      };
    case SET_LAST_TOOL:
      return {
        ...state,
        lastTool: action.payload,
      };
    case SET_SCROLL_TO_ITEM:
      return {
        ...state,
        scroll_to_item: action.payload,
      };
    case SET_DONT_SHOW_LOGGEDOUTMENU:
      return {
        ...state,
        dont_show_loggedoutmenu: action.payload,
      };
  }
  return state;
}
