import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';

import { dispatchLoad } from 'actions/admin/dispatch';
import { closeModal, openModal, unshiftModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import wrapPromisePending from 'components/hoc/wrapPromisePending';


export default compose(
  Resource('user', {
    idPropName: ['user_id'],
  }),
  connect(
    null,
    (dispatch, { user_id, load_id }) => ({
      closeModal: () => dispatch(closeModal()),
      async dispatchLoad() {
        try {
          await dispatch(dispatchLoad(load_id, user_id));
          dispatch(openModal('success', { message: 'Load dispatched.' }));
          dispatch(closeModal());
        }
        catch (err) {
          console.warn(err);
          dispatch(unshiftModal('error', { message: 'Failed to dispatch load.' }));
        }
      },
    }),
  ),
  wrapPromisePending({
    promiseProp: 'dispatchLoad',
  }),
)(({ item, dispatchLoad, submitting, closeModal }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Dispatch Load for {get(item, ['data', 'dba'])}</h4>
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
      <button className='btn btn-orange' disabled={submitting} onClick={dispatchLoad}>Submit</button>
    </div>
  </div>
);
