import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringdebtor.childrenfactoringdebtor;

import childrenfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/childrenfactoringdebtor';


export default function childrenfactoringdebtorByFactoringdebtorResourceReducer(state = initialState, action) {
  state = childrenfactoringdebtorByFactoringdebtorResource.reduce(state, action);
  return state;
}
