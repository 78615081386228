import React, { Component } from 'react';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { concat, get, uniqBy } from 'lodash';
import DropdownList from 'react-widgets/lib/DropdownList';
import { Button, Col, Grid, Pagination, Row } from 'react-bootstrap';

import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import Resource from 'components/hoc/Resource';
import { searchFactoringCarriers, updateFactoringCarrier } from 'actions/factoring';
import createTableItems from 'helpers/createTableItems';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import { openModal } from 'actions/ui';
import { compose } from 'redux-v3';

const options = [
  {
    text: 'Name',
    value: 'carrier_dba',
  },
  {
    text: 'Email',
    value: 'email',
  },
  {
    text: 'MC #',
    value: 'mc',
  },
  {
    text: 'DOT #',
    value: 'dot',
  },
  {
    text: 'Tax ID #',
    value: 'tax_id',
  },
  {
    text: 'Phone',
    value: 'phone',
  },
  {
    text: 'Status',
    value: 'status',
  },
];

const FactoringClientBrokerCarriersItems = createTableItems(
  ({ data, navigateTo, updateDiscountRate, setDiscountRate, factoringClientId }) => {
    const status = get(data, 'status', undefined);
    const email = get(data, 'carrier_owner_profile.email', undefined);
    const companyName = get(data, 'carrier_company_profile.name', undefined);
    const phoneNumber = get(data, 'carrier_owner_profile.phone_number_cell',
      get(data, 'carrier_owner_profile.phone_number_work', undefined));
    const mc = get(data, 'carrier_company_profile.mc', undefined);
    const dot = get(data, 'carrier_company_profile.dot', undefined);
    const tax = get(data, 'carrier_company_profile.tax_id', undefined);
    const discountRate = get(data, 'discount_rate', undefined);

    return [
      (() => {
        switch (status) {
          case 'approved':
            return <b><span className='text-success'>Approved</span></b>;
          case 'declined':
            return <b><span className='text-danger'>Declined</span></b>;
          case 'pending':
          default:
            return <b><span className='text-orange'>Pending</span></b>;
        }
      })(),
      email,
      companyName,
      phoneNumber,
      mc,
      dot,
      tax,
      <input
        className='form-control'
        type='number'
        min='0'
        value={data.discount_rate}
        onChange={e => setDiscountRate(data.id, e.target.value)}
      />,
      <div
        style={{
          display: 'flex',
        }}
        >
        <div>
          <button
            style={{
              marginLeft: '8px',
            }}
            className='btn btn-orange'
            onClick={() => {
              navigateTo(`/admin/factoring/client/${factoringClientId}/paymentcreate`);
            }}
            >
            Pay
          </button>
          <button
            style={{
              marginLeft: '8px',
            }}
            className='btn btn-primary'
            onClick={() => {
              updateDiscountRate(data.id, data.discount_rate);
            }}
            >
            Update
          </button>
        </div>
      </div>,
    ];
  });

const mapStateToProps = state => ({
  groups: state.resource.factoringGroups,
  loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getCarriers: filters => dispatch(searchFactoringCarriers(filters)),
  updateDiscountRate: async (id, discountRate) => {
    try {
      if (String(discountRate).trim() === '') {
        throw Error('Invalid discount rate');
      }
      await dispatch(updateFactoringCarrier(id, { discount_rate: discountRate }));
      dispatch(openModal('success', { message: 'Successfully updated discount rate.' }));
    }
    catch (err) {
      dispatch(openModal('error', { message: err.message || 'Error updating discount rate.' }));
    }
  },
  updateStatus: async idStatus => {
    try {
      await Promise.all(idStatus.map(({ id, status }) => dispatch(updateFactoringCarrier(id, { status }))));
      dispatch(openModal('success', { message: 'Successfully updated broker approved status.' }));
    }
    catch (err) {
      dispatch(openModal('error', { message: err.message || 'Error updating broker approved status.' }));
    }
  },
});

export default
compose(
  Resource('factoringclient', {
    expirationTime: -Infinity,
    idPropName: ['params', 'id'],
  }),
  spinnerIfFetching(),
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )
)(
  class FactoringClientBrokerCarriers extends Component {
    constructor(props) {
      super(props);

      this.state = {
        business_name: get(props, 'item.data.company_profile.name'),
        searchField: '',
        searchType: 'carrier_dba',
        carriers: [],
        count: 0,
        searching: false,
        factoringClientId: this.props.params.id,
        selectedFactorers: [],
        status: 'pending',
      };

      this.onTextChange = this.onTextChange.bind(this);
      this.onDropdownChange = this.onDropdownChange.bind(this);
      this.onDropdownStatusChange = this.onDropdownStatusChange.bind(this);
      this.onSearch = this.onSearch.bind(this);
      this.fetchPage = this.fetchPage.bind(this);
    }

    async componentDidMount() {
      const res = await this.props.getCarriers({
        factoring_client_id: this.props.params.id,
      });

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        count: res.count,
        carriers: res.results,
        factoringClientId: this.props.params.id,
      });
    }

    onDropdownChange(event) {
      this.setState({
        searchType: event.value,
      });
    }

    onDropdownStatusChange(event) {
      this.setState({
        status: event.value,
      });
    }

    onTextChange(event) {
      this.setState({
        searchField: event.target.value,
      });
    }

    async onSearch() {
      const query = {};
      const { searchField, searchType, factoringClientId } = this.state;
      query[searchType] = searchField;
      query.factoring_client_id = factoringClientId;

      if (searchField === '') {
        this.setState({
          searching: false,
        });
      }

      const res = await this.props.getCarriers(query);

      this.setState({
        carriers: res.results,
        count: res.count,
      });
    }

    async fetchPage(page) {
      const filters = {};
      filters.factoring_client_id = this.state.factoringClientId;

      if (this.state.searching) {
        const { searchField, searchType } = this.state;
        filters[searchType] = searchField;
      }

      const limit = 20;
      const offset = page * limit;

      const carriers = await this.props.getCarriers({ ...filters, offset });

      this.setState({
        carriers: carriers.results,
      });
    }

    render() {
      const { history, updateDiscountRate } = this.props;
      const { carriers, count } = this.state;

      const setDiscountRate = (id, rate) => {
        const index = carriers.findIndex(carrier => {
          return carrier.id === id;
        });

        const newCarriers = carriers;
        newCarriers[index].discount_rate = rate;

        this.setState({
          carriers: newCarriers,
        });
      };

      return (
        <div
          style={{
            marginLeft: '100px',
            marginRight: '100px',
          }}
          >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '12px',
              marginBottom: '12px',
            }}
            >
            <div>
              <h3>{this.state.business_name}</h3>
            </div>
            <div
              style={{
                display: 'inline-flex',
                marginTop: '10px',
                marginBottom: '5px',
              }}
              >
              <input
                className='form-control'
                type='text'
                placeholder='Search for'
                value={this.state.searchField}
                onChange={this.onTextChange}
              />
              <div>
                <DropdownList
                  style={{
                    minWidth: '11em',
                    marginLeft: '12px',
                  }}
                  data={options}
                  defaultValue='carrier_dba'
                  valueField='value'
                  textField='text'
                  onChange={this.onDropdownChange}
                />
              </div>
              <div>
                <button
                  style={{
                    marginLeft: '12px',
                  }}
                  className='btn btn-orange'
                  onClick={this.onSearch}
                  >
                  Search
                </button>
              </div>
              <div
                style={{
                  marginLeft: '12px',
                }}
                >
                <h4>Status:</h4>
              </div>
              <div>
                <DropdownList
                  style={{
                    minWidth: '11em',
                    marginLeft: '12px',
                  }}
                  data={[
                    {
                      text: 'Pending',
                      value: 'pending',
                    },
                    {
                      text: 'Approved',
                      value: 'approved',
                    },
                    {
                      text: 'Declined',
                      value: 'declined',
                    },
                  ]}
                  valueField='value'
                  textField='text'
                  onChange={this.onDropdownStatusChange}
                  defaultValue='pending'
                />
              </div>
              <div>
                <button
                  style={{ marginLeft: '12px' }} className=' pull-right btn btn-orange' onClick={async () => {
                    if (this.state.selectedFactorers.length !== 0) {
                      const { status } = this.state;
                      const param = this.state.selectedFactorers.map(selected => ({ id: selected.id, status }));
                      await this.props.updateStatus(param);
                      this.setState({
                        carriers: [],
                        selectedFactorers: [],
                      });
                      await this.onSearch();
                    }
                  }}
                                                                                        >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <ResponsiveTable
            placeholder='No results.'
            headers={['Status', 'Email', 'Name', 'Phone #', 'MC #', 'DOT #', 'TAX ID', 'Discount Rate', 'Action']}
            selectable={true}
            onItemsSelect={item => {
              if (item.length > this.state.selectedFactorers.length) {
                this.setState({
                  selectedFactorers: (uniqBy(concat(item, this.state.selectedFactorers), item => item.data.id)).map(f => f.data),
                });
              }
              else {
                this.setState({
                  selectedFactorers: item.map(f => f.data),
                });
              }
            }}
            items={uniqBy(concat(this.state.selectedFactorers, carriers), item => item.id).map(c => ({
              data: c,
              navigateTo: history.push,
              setDiscountRate,
              updateDiscountRate,
              factoringClientId: this.props.params.id,
            }))}
            {...FactoringClientBrokerCarriersItems}
            withPagination={true}
            count={count}
            limit={20}
            fetchPage={this.fetchPage}
          />
        </div>
      );
    }
  }
);
