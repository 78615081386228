/**
 * Reducer for handling the factoringdebtor by factoringclient resource actions
 * @module reducers/resourceBy/factoringclient/factoringdebtor
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/factoringclient/factoringdebtor
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringclient.factoringdebtor;

import factoringdebtorByfactoringclientResource, { factoringdebtorByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringdebtor';


export default function factoringdebtorByfactoringclientResourceReducer(state = initialState, action) {
  state = factoringdebtorByfactoringclientResource.reduce(state, action);
  state = factoringdebtorByfactoringclientUSER.reduce(state, action);
  return state;
}
