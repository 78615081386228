import React from 'react';
import styled from 'styled-components';

const ColoredCircle = ({ size, color, children }) =>
  <span
    className='ColoredCircle'
    style={{
      ...(() => color ? { backgroundColor: color } : undefined)(),
      ...(() => size ? {
        height: size,
        width: size,
        borderRadius: size !== undefined ? size / 2 : undefined,
        margin: size !== undefined ? size / 4 : undefined,
      } : undefined)(),
    }}
    >
    {children}
  </span>
;

const StyledColoredCircle = styled(ColoredCircle)`
  .ColoredCircle {
    display: inline-block;
    vertical-align: middle;
    margin: 5px;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    background-color: #00BC48;
    position: relative;
    & > * {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export default StyledColoredCircle;
