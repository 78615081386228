/**
 * Component that displays the a help form
 * @module components/pure/form/HelpForm
 * @since 3.0.0
 * @requires actions/user
 * @requires actions/ui
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, SubmissionError, reduxForm, reset } from 'redux-form';
import validate from 'validate.js';

import { createHelpTicket } from 'actions/user';
import { openModal } from 'actions/ui';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import SubmitButton from 'components/pure/form/SubmitButton';


export const FORM_NAME = 'HelpForm';

export default compose(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit(fields, dispatch) {
      return dispatch(createHelpTicket(fields))
        .then(res => {
          if (res.status >= 400 && res.status < 500) {
            return res.json().then(errs => {
              for (const key in errs) {
                errs[key] = errs[key][0];
              }
              throw new SubmissionError(errs);
            });
          }
          return res;
        })
        .then(() => {
          dispatch(openModal('success', { message: 'Thank you for contacting us, we will contact you shortly.' }));
          dispatch(reset(FORM_NAME));
        })
      ;
    },
    validate: fields => validate(fields, {
      name: {
        presence: true,
      },
      email: {
        presence: true,
        email: true,
      },
      message: {
        presence: true,
      },
    }),
  })
)(({ handleSubmit, submitting }) =>
  <div>
    <Field name='name' component={LabeledInput} label='Name:' />
    <Field name='email' component={LabeledInput} type='email' label='Email Address:' />
    <Field name='message' component={LabeledInput} ComponentClass='textarea' className='grow' style={{ resize: 'vertical' }} label='Enter Message:' />
    <SubmitButton disabled={submitting} onClick={handleSubmit} />
  </div>
);
