import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import validate from 'validate.js';
import { createLineItemCategories, updateLineItemCategory } from 'actions/lineitemcategories';
import { openModal } from 'actions/ui';

export default compose(
  connect(
    null,
    dispatch => ({
      successModal: message => dispatch(openModal('success', { message })),
      errorModal: message => dispatch(openModal('error', { message })),
    })
  ),
  reduxForm({
    form: 'LineItemCategoryCreateForm',
    onSubmit: async (fields, dispatch, props) => {
      if (props.edit) {
        try {
          await dispatch(updateLineItemCategory(fields.id)(fields));
          await props.close();
          await props.successModal('Successfully Updated the Line Item Category.');
        }
        catch (err) {
          await props.close();
          await props.errorModal('Failed to Update the Line Item Category.');
        }

      }
      else {
        try {
          await dispatch(createLineItemCategories(fields));
          await props.close();
          await props.successModal('Successfully Created the Line Item Category.');
        }
        catch (err) {
          await props.close();
          await props.errorModal('Failed to Create the Line Item Category.');
        }

      }
    },
    validate: fields => {
      return validate(fields, {
        applied_against: {
          presence: true,
        },
        applied_by: {
          presence: true,
        },
        name: {
          presence: {
            allowEmpty: false,
          },
        },
        description: {
          presence: {
            allowEmpty: false,
          },
        },
      });
    },
  })
)(({ onEdit, handleSubmit, edit }) => (
  <form onSubmit={handleSubmit}>
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        margin: '16px',
      }}
      >
      <Field
        name='name'
        label='Name'
        component={LabeledInput}
        className='input'
      />
      <Field
        name='applied_against'
        component={LabeledDropdown}
        label={'Who\'s Charged'}
        data={[
          {
            text: 'Client',
            value: 'client',
          },
          {
            text: 'Debtor',
            value: 'debtor',
          },
          {
            text: 'ComFreight',
            value: 'organization',
          },
          {
            text: 'Proof',
            value: 'proof',
          },
        ]}
      />
      <Field
        name='applied_by'
        component={LabeledDropdown}
        label={'Who charges'}
        data={[
          {
            text: 'Client',
            value: 'client',
          },
          {
            text: 'Debtor',
            value: 'debtor',
          },
          {
            text: 'ComFreight',
            value: 'organization',
          },
          {
            text: 'Take out of Client\'s Reserve',
            value: 'client_reserve_negative',
          },
          {
            text: 'Add to Client\'s Reserve',
            value: 'client_reserve_positive',
          },
        ]}
      />
      <Field
        name='description'
        ComponentClass='textarea'
        component={LabeledInput}
        label='Description'
        style={{ height: '100px' }}
      />
      <Field
        name='discount_rate'
        label='Discount Rate'
        component={LabeledInput}
        className='input'
      />
      <div>
        <Field
          name='is_client_invoiced'
          component={CheckboxInput}
          label='Is Client Invoiced?'
        />
      </div>
      <div>
        <Field
          name='is_debtor_invoiced'
          component={CheckboxInput}
          label='Is Debtor Invoiced?'
        />
      </div>
      <div>
        <Field
          name='apply_discount'
          component={CheckboxInput}
          label='Apply Discount?'
        />
      </div>
      <Field
        name='should_collate'
        component={CheckboxInput}
        label='Should Collate?'
      />
      <Field
        name='invoice_name'
        label='Name on the Invoice'
        component={LabeledInput}
        className='input'
      />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-orange' type='submit'>{edit ? 'Edit' : 'Create'}</button>
    </div>
  </form>
));
