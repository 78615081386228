/* global EMPTY_PLACEHOLDER */

import React from 'react';
import createTableItems from 'helpers/createTableItems';
import { Field, reduxForm } from 'redux-form';
import cs from 'classnames';
import { Link } from 'react-router-v4';
import formatPennies from 'helpers/formatPennies';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import getSelfType from 'selectors/getSelfType';
import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';
import { USER_TYPE } from 'helpers';
import moment from 'moment';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import formatDays from 'helpers/formatDays';
import { get } from 'lodash';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isAnyAdmin from 'selectors/isAnyAdmin';
import map from 'lodash/map';

const disable_set_status = status => {
  return ['paid'].includes(status);
};

const PaymentsTableComponents = (showLineItems, isDispatchBroker, noSelect = true) => createTableItems(
  ({ data }) => {
    let status = data.status;
    if (isDispatchBroker && status === 'special_pending') {
      status = 'pending';
    }
    const checkbox = disable_set_status(data.status) ?
      ' ' :
      <Field
        name={data.id}
        component={CheckboxInput}
      />;

    const wire = data.payment_method === 'wire' ? <MaterialIcon name='check' className='text-danger' style={{ fontSize: '2em' }} /> : '';
    const assignedAdmin = get(data, 'assigned_admin.name', 'Not Assigned');
    const attachments = (
      <div >
        {
          data.attachments.length > 0 &&
            data.attachments[data.attachments.length - 1].after_issue === true &&
              <div className='text-success'>
                <MaterialIcon name='attach_file' />New!
                <br />({moment(data.attachments[data.attachments.length - 1].uploaded).format('MM/DD, hh:mm a')})
              </div>
        }
        {' '}
        <span
          className={cs('text-uppercase', {
            'text-success': ['approved', 'paid', 'remote_approved'].includes(status),
            'text-warning': ['pending', 'special_pending'].includes(status),
            'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
            'text-blue': ['new'].includes(status),
          })}
          > {status}
        </span>
        {' '}
        {data.status_timeline && data.status_timeline[data.status_timeline.length - 1] ?
          moment(data.status_timeline[data.status_timeline.length - 1].status_set_datetime).format('l LT') :
          null
        }
      </div>
    );

    const daysToPay = data.days_to_pay ? formatDays(data.days_to_pay) : null;

    const comfreightOwned = get(data, 'factoring.aladdin_owned', true) ? 'False' : 'True';

    const moreButton = (
      <Link to={`/admin/factoring/transaction/${data.id}`}>
        <button className='btn btn-orange'>More</button>
      </Link>
    );

    if (showLineItems) {
      return [
        ...(() => noSelect ? {} : { checkbox })(),
        data.factoring.company_profile.name,
        data.debtor.dba,
        formatPennies(data.amount),
        data.invoice_number,
        data.user_load_number,
        data.pending_line_items_count || 0,
        formatPennies(data.client_reserve_negative_active || 0),
        data.created.format('l LTS'),
        data.discount_rate,
        wire,
        assignedAdmin,
        formatPennies(data.amount_funded) || EMPTY_PLACEHOLDER,
        attachments,
        daysToPay,
        comfreightOwned,
        moreButton,
      ];
    }
    return [
      ...(() => noSelect ? {} : { checkbox })(),
      data.factoring.company_profile.name,
      data.debtor.dba,
      formatPennies(data.amount),
      data.invoice_number,
      data.user_load_number,
      data.created.format('l LTS'),
      data.discount_rate,
      wire,
      assignedAdmin,
      formatPennies(data.amount_funded) || EMPTY_PLACEHOLDER,
      attachments,
      daysToPay,
      comfreightOwned,
      moreButton,
    ];

  },
);


const PaymentsTableComponentsForRemote = (showLineItems, isDispatchBroker) => createTableItems(
  ({ data }) => {
    let status = data.status;
    if (isDispatchBroker && status === 'special_pending') {
      status = 'pending';
    }
    return [
      data.factoring.company_profile.name,
      data.debtor.dba,
      formatPennies(data.amount),
      data.invoice_number,
      data.user_load_number,
      data.created.format('l LTS'),
      data.discount_rate,
      data.payment_method === 'wire' ? <MaterialIcon name='check' className='text-danger' style={{ fontSize: '2em' }} /> : '',
      get(data, 'assigned_admin.name', 'Not Assigned'),
      formatPennies(data.amount_funded) || EMPTY_PLACEHOLDER,
      <b
        className={cs('text-uppercase', {
          'text-success': ['approved', 'paid', 'remote_approved'].includes(status),
          'text-warning': ['pending', 'special_pending'].includes(status),
          'text-red': ['declined', 'incomplete', 'pending_delivery'].includes(status),
          'text-blue': ['new'].includes(status),
        })}
        >
        {
          data.attachments.length > 0 ?
            data.attachments[data.attachments.length - 1].after_issue === true ?
              <div className='text-success'><MaterialIcon name='attach_file' /> New!
                <br />({moment(data.attachments[data.attachments.length - 1].uploaded).format('MM/DD, hh:mm a')})
              </div> :
              null :
            null
        }
        {' '}
        <span
          className={cs('text-uppercase', {
            'text-success': ['approved', 'paid', 'remote_approved'].includes(status),
            'text-warning': ['pending', 'special_pending'].includes(status),
            'text-red': ['declined', 'incomplete', 'pending_delivery'].includes(status),
            'text-blue': ['new'].includes(status),
          })}
          >  {status}
        </span>
        {' '}
        {data.status_timeline[data.status_timeline.length - 1] ?
          moment(data.status_timeline[data.status_timeline.length - 1].status_set_datetime).format('l LT') :
          null
        }
      </b>,
      data.days_to_pay ? formatDays(data.days_to_pay) : null,
      <Link to={`/admin/factoring/transaction/${data.id}`}>
        <button className='btn btn-orange'>More</button>
      </Link>,
    ];
  },
);


const PaymentsTable = compose(
  connect(
    state => ({
      ids: state.admin.factoring.payment.ids,
      isFetching: state.admin.factoring.payment.isFetching,
      payments: state.resource.factoringpayment,
      currentOrder: state.admin.factoring.payment.ordering,
      type: getSelfType(state),
      isDispatchBroker: isDispatchBroker(state),
      isAnyAdmin: isDispatchBroker(state),
    }),
    {
      fetchNext: factoringpaymentPaginatedResource.fetchNext,
      sort: factoringpaymentPaginatedResource.sort,
    },
  ),
  reduxForm({
    form: 'FactoringPaymentsAdmin',
    destroyOnUnmount: false,
  }),
)(({ ids, isFetching, fetchNext, payments, sort, currentOrder, type, path, isDispatchBroker, isAnyAdmin, ...props }) => {
  const showLineItems = path === 'haulpay_review' || path === 'remote_approved';
  if (isDispatchBroker && !isAnyAdmin) {
    return (
      <ResponsiveTable
        containerProps={{
          className: 'col-fill',
        }}
        isFetching={isFetching}
        fetchNext={fetchNext}
        sortable={true}
        currentOrder={currentOrder}
        sort={sort}
        headers={[
          { text: 'Select', ordering: false },
          { text: 'Client Business', ordering: 'factoring' },
          { text: 'Debtor', ordering: 'debtor' },
          { text: 'Invoice Amt.', ordering: 'amount' },
          { text: 'Invoice #', ordering: 'invoice_number' },
          { text: 'Load #', ordering: 'user_load_number' },
          showLineItems && { text: 'Pending Line Items', ordering: false },
          showLineItems && { text: 'Reserve Balance', ordering: false },
          { text: 'Requested At', ordering: 'created' },
          { text: 'Discount %', ordering: 'discount_rate' },
          { text: 'Wire', ordering: 'payment_method' },
          { text: 'Transaction Rep', ordering: 'assigned_admin' },
          { text: 'Amt. Funded', ordering: 'amount_funded' },
          { text: 'Status', ordering: false },
          { text: 'Aging', ordering: 'days_to_pay' },
          { text: 'ComFreight Owned' },
          { text: 'More', ordering: false },
        ].filter(item => item)}
        placeholder='No results'
        TableItem={PaymentsTableComponents(showLineItems, isDispatchBroker).TableItem}
        BlockTableItem={PaymentsTableComponents(showLineItems, isDispatchBroker).BlockTableItem}
        items={ids.map(id => payments[id])}
      />
    );
  }
  else if (type === USER_TYPE.FACTORING_REMOTE_ADMIN || type === USER_TYPE.FACTORING_REMOTE_COORDINATOR) {
    return (
      <ResponsiveTable
        containerProps={{
          className: 'col-fill',
        }}
        isFetching={isFetching}
        fetchNext={fetchNext}
        sortable={true}
        currentOrder={currentOrder}
        sort={sort}
        headers={[
          { text: 'Client Business', ordering: 'factoring' },
          { text: 'Debtor', ordering: 'debtor' },
          { text: 'Invoice Amt.', ordering: 'amount' },
          { text: 'Invoice #', ordering: 'invoice_number' },
          { text: 'Load #', ordering: 'user_load_number' },
          showLineItems && { text: 'Pending Line Items', ordering: false },
          showLineItems && { text: 'Reserve Balance', ordering: false },
          { text: 'Requested At', ordering: 'created' },
          { text: 'Discount %', ordering: 'discount_rate' },
          { text: 'Wire', ordering: 'payment_method' },
          { text: 'Transaction Rep', ordering: 'assigned_admin' },
          { text: 'Amt. Funded', ordering: 'amount_funded' },
          { text: 'Status', ordering: false },
          { text: 'Aging', ordering: 'days_to_pay' },
          { text: 'More', ordering: false },
        ].filter(item => item)}
        placeholder='No results'
        TableItem={PaymentsTableComponentsForRemote(showLineItems, isDispatchBroker).TableItem}
        BlockTableItem={PaymentsTableComponentsForRemote(showLineItems, isDispatchBroker).BlockTableItem}
        items={ids.map(id => payments[id])}
      />
    );
  }
  if (isDispatchBroker && get(props, 'setSelectedItems', null)) {
    const BROKER_ITEM_ID_SELECTOR = 'data.id';
    const extraTableProps = get(props, 'setSelectedItems', null) ?
      {
        onItemsSelect: items => {
          const filterdData = map(items, object => {
            const { data = {} } = object;
            return data.id;
          });
          props.setSelectedItems(filterdData);
        },
        selectable: true,
        idSelector: BROKER_ITEM_ID_SELECTOR,
      } :
      {};
    return (
      <ResponsiveTable
        containerProps={{
          className: 'col-fill',
        }}
        isFetching={isFetching}
        fetchNext={fetchNext}
        sortable={true}
        currentOrder={currentOrder}
        sort={sort}
        headers={[
          { text: 'Client Business', ordering: 'factoring' },
          { text: 'Debtor', ordering: 'debtor' },
          { text: 'Invoice Amt.', ordering: 'amount' },
          { text: 'Invoice #', ordering: 'invoice_number' },
          { text: 'Load #', ordering: 'user_load_number' },
          showLineItems && { text: 'Pending Line Items', ordering: false },
          showLineItems && { text: 'Reserve Balance', ordering: false },
          { text: 'Requested At', ordering: 'created' },
          { text: 'Discount %', ordering: 'discount_rate' },
          { text: 'Wire', ordering: 'payment_method' },
          { text: 'Transaction Rep', ordering: 'assigned_admin' },
          { text: 'Amt. Funded', ordering: 'amount_funded' },
          { text: 'Status', ordering: false },
          { text: 'Aging', ordering: 'days_to_pay' },
          { text: 'ComFreight Owned' },
          { text: 'More', ordering: false },
        ].filter(item => item)}
        placeholder='No results'
        TableItem={PaymentsTableComponents(showLineItems, isDispatchBroker, false).TableItem}
        BlockTableItem={PaymentsTableComponents(showLineItems, isDispatchBroker, false).BlockTableItem}
        items={ids.map(id => payments[id])}
        {...extraTableProps}
      />);
  }
  return (
    <ResponsiveTable
      containerProps={{
        className: 'col-fill',
      }}
      isFetching={isFetching}
      fetchNext={fetchNext}
      sortable={true}
      currentOrder={currentOrder}
      sort={sort}
      headers={[
        { text: 'Select', ordering: false },
        { text: 'Client Business', ordering: 'factoring' },
        { text: 'Debtor', ordering: 'debtor' },
        { text: 'Invoice Amt.', ordering: 'amount' },
        { text: 'Invoice #', ordering: 'invoice_number' },
        { text: 'Load #', ordering: 'user_load_number' },
        showLineItems && { text: 'Pending Line Items', ordering: false },
        showLineItems && { text: 'Reserve Balance', ordering: false },
        { text: 'Requested At', ordering: 'created' },
        { text: 'Discount %', ordering: 'discount_rate' },
        { text: 'Wire', ordering: 'payment_method' },
        { text: 'Transaction Rep', ordering: 'assigned_admin' },
        { text: 'Amt. Funded', ordering: 'amount_funded' },
        { text: 'Status', ordering: false },
        { text: 'Aging', ordering: 'days_to_pay' },
        { text: 'ComFreight Owned' },
        { text: 'More', ordering: false },
      ].filter(item => item)}
      placeholder='No results'
      TableItem={PaymentsTableComponents(showLineItems, isDispatchBroker).TableItem}
      BlockTableItem={PaymentsTableComponents(showLineItems, isDispatchBroker).BlockTableItem}
      items={ids.map(id => payments[id])}
    />
  );

});

export default PaymentsTable;
