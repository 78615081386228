import React from 'react';

import MaterialIcon from 'components/pure/MaterialIcon';
import AttachmentIcon from './AttachmentIcon';
import { isEmpty } from 'lodash';

class LoadAttachmentButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      url: null,
      display: false,
      loading: false,
    };
    this.loadURL = this.loadURL.bind(this);
  }

  async componentDidMount() {
    await this.loadURL();
  }

  async componentDidUpdate(PrevProps) {
    const { refresh } = this.props;
    if(refresh !== PrevProps.refresh && refresh) {
      await this.loadURL();
    }
  }

  async loadURL() {
    const { getURL } = this.props;
    this.setState({ loading: true });
    try {
      if (typeof getURL === 'function') {
        const url = await getURL();
        this.setState({ url });
      }
    }
    catch (err) {
      throw err;
    }
    finally {
      this.setState({ loading: false });
    }
  }

  render() {
    let { getURL, download_url, preview_url, fileName, remove, isNew = false, id, ...rest } = this.props;
    const { url, loading, display } = this.state;
    if (url) {
      // backend returns a string for the url
      if (typeof url === 'string') {
        preview_url = url;
        download_url = url;
      }
      else {
        ({ download_url, preview_url } = url);
      }
    }
    if ((download_url || preview_url) && display) {
      return (
        <span>
          <a href={preview_url} target='_blank' rel='noopener noreferrer' {...rest} style={{ marginRight: 5 }}>
            <button className='btn btn-default' type='button'>Open</button>
          </a>
          <a href={download_url} target='_blank' rel='noopener noreferrer' {...rest} style={{ marginRight: 5 }}>
            <button className='btn btn-default' type='button'>
              <MaterialIcon name='file_download' size={18} />
            </button>
          </a>
          {
            remove && (
              <button className='btn btn-danger' type='button' onClick={remove}>
                <MaterialIcon name='delete' size={18} />
              </button>
            )
          }
        </span>
      );
    }

    return (
      <span>
        {
          loading ?
          <div id={id}><MaterialIcon name='sync' spin={true} /></div>
            : null
        }
        {' '}
        <AttachmentIcon
          {...rest}
          style={{
            ...rest.style,
            opacity: loading ? 0.2 : undefined,
          }}
          onClick={() => this.setState({
            display: !display
          })}
          isNew={isNew}
          download_url={download_url}
          preview_url={preview_url}
          filename={fileName}
          loading={loading}
        />
      </span>
    );
  }
}

export default LoadAttachmentButton;
