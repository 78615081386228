/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, mapProps } from 'recompose';
import { Field, reduxForm, FieldArray, change, getFormValues } from 'redux-form';
import { fetchTerms, updateTerms } from 'actions/factoring';
import { closeModal, openModal } from '../../../actions/ui';
import ModalBody from '../ModalBody';
import LabeledInput from '../form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import get from 'lodash/get';
import find from 'lodash/find';
import getBrokerTerms from '../../../selectors/getBrokerTerms';
import getAllBrokerTerms from '../../../selectors/getAllBrokerTerms';
import Toggle from 'material-ui/Toggle';
import Spinner from '../Spinner';
import segmentEvents from '../../../helpers/segmentEvents';
import Tooltip from 'components/pure/Tooltip';
import MaterialIcon from 'components/pure/MaterialIcon';
import { isEmpty } from 'lodash';

const FORM_NAME = 'TERMS_PAYMENTS_SPEED';

const styles = {
  button: {
    backgroundColor: '#FF7800',
    color: '#fff',
  },
  flexRow: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
  },
  text: {
    fontSize: 16,
    color: '#000000',
    fontWeight: '100',
  },
  icon: {
    color: '#4a90e2',
  },
  customBackgroundDialog: {
    backgroundColor: 'rgba(92, 105, 121, 0.8)',
  },
  timeIcon: {
    //fontSize: 16,
    color: '#979797',
  },
  labelStyle: {
    color: '#333333',
    fontWeight: 'normal',
    fontSize: 15,
  },
};

const payoutTermsOption = [
  {
    value: 'ach_1_day',
    label: '1 Day (3% Quick Pay Fee)',
  },
  {
    value: 'ach_7_day',
    label: '7 Days (Fee)',
  },
  {
    value: 'ach_15_day',
    label: '15 Days (Fee)',
  },
  {
    value: 'ach_21_day',
    label: '21 Days (Fee)',
  },
  {
    value: 'ach_30_day',
    label: '30 Days (Fee)',
  },
];

const PaymentSpeed = props => {
  const data = [{
    text: '1 Day',
    value: 0,
  },
  {
    text: '7 Days',
    value: 7,
  },
  {
    text: '15 days',
    value: 15,
  },
  {
    text: '21 Days',
    value: 21,
  },
  {
    text: '30 Days',
    value: 30,
  },
  ...props.withFactorCompany ? [{
    text: '30 Days - Factors',
    value: 31,
  }] : [],];

  const ListItem = ({ item: { text } }) => (
    <div><span>{text}</span></div>
  );
  return (
    <Field
      component={LabeledDropdown}
      style={{
        minWidth: '4em',
        marginLeft: '5px',
      }}
      data={data}
      textField='text'
      valueField='value'
      itemComponent={ListItem}
      {...props}
    />
  );
};

const BrokerPortion = props => {
  const data = [{
    text: '0%',
    value: 0,
  },
  {
    text: '50%',
    value: 50,
  },
  {
    text: '100%',
    value: 100,
  }];

  const ListItem = ({ item: { text } }) => (
    <div><span>{text}</span></div>
  );
  return (
    <Field
      component={LabeledDropdown}
      style={{
        minWidth: '4em',
        marginLeft: '5px',
      }}
      data={data}
      textField='text'
      valueField='value'
      itemComponent={ListItem}
      {...props}
    />
  );
};

const paymentTermsArray = ({ fields, terms, change, formValues, displayEditDisplayPayment = false }) =>
  <div style={{ width: '100%', display: 'flex', flexDirection: 'column', padding: 5 }}>
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      <div style={{ flex: 0.2, padding: 5 }}>
        Enable Option?
      </div>
      <div style={{ flex: 0.2, padding: 5 }}>
        Payment Speed
      </div>
      {displayEditDisplayPayment &&
        <div style={{ flex: 0.2, padding: 5 }}>
          Display
        </div>
      }
      <div style={{ flex: 0.2, padding: 5 }}>
        HP Discount
      </div>
      <div style={{ flex: 0.2, padding: 5 }}>
        Your Portion %
      </div>
      <div style={{ flex: 0.2, padding: 5 }}>
        Carrier's Rate
      </div>
    </div>
    {fields.map((term, index) => {
      return (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 0.2, padding: 5 }}>
            <Field
              label=''
              defaultToggled={terms[index].enable_payment_profile}
              component={Toggle} name={`${term}.enable_payment_profile`}
              onToggle={(value, isInputChecked) => change(`${term}.enable_payment_profile`, isInputChecked)}
            />
          </div>
          {
            terms[index].payee === 'factoring_company' ?
              <div style={{ flex: displayEditDisplayPayment ? 0.6 : 0.4, padding: 5 }}>
                <LabeledInput
                  value='Enable Carrier to Select Their Factor'
                  disabled={true}
                />
              </div>
              :
              <div style={{ display: 'flex', flexDirection: 'row', flex: displayEditDisplayPayment ? 0.6 : 0.4, padding: 5 }}>
                <div style={{ flex: 0.4, padding: 5 }}>
                  <PaymentSpeed name={`${term}.payout_days`} disabled withFactorCompany />
                </div>
                {displayEditDisplayPayment &&
                  <div style={{ flex: 0.4, padding: 5 }}>
                    <Field
                      component={LabeledInput}
                      name={`${term}.name`}
                    />
                  </div>
                }
                <div style={{ flex: 0.4, padding: 5 }}>
                  <Field
                    component={LabeledInput}
                    name={`${term}.discount_rate`}
                    disabled={true}
                    format={value => `${value}%`}
                    parse={value => value}
                  />
                </div>
              </div>
          }
          <div style={{ flex: 0.2, padding: 5 }}>
            <BrokerPortion
              name={`${term}.factoring_fee_split_rate`}
              disabled={terms[index].payee === 'factoring_company'}
              onObjectChange={event =>
                change(
                  `${term}.carrier_rate`,
                  get(formValues, `${term}.discount_rate`, 3.5) - (get(formValues, `${term}.discount_rate`, 3.5) * (event.value / 100))
                )}
            />
          </div>
          <div style={{ flex: 0.2, padding: 5 }}>
            <Field
              component={LabeledInput}
              name={`${term}.carrier_rate`}
              disabled={true}
              format={value => `${value}%`}
              parse={value => value}
            />
          </div>
        </div>
      )
    }
    )}
    {!isEmpty(get(formValues, 'default')) &&
    <div
      style={{ ...styles.flexRow, flexDirection: 'column', margin: '-5px 5px 5px 5px' }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 0.2, padding: 5 }}></div>
        <div style={{ flex: 0.8, padding: 5 }}>
          <span style={styles.text}>
            <b>Choose the default payment speed for carriers boarding with a factoring company.</b> {' '}
          </span>
          <Tooltip
            placement='top'
            text={<p style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5, zIndex: 999999999 }}>
              This shows and sets the default payment speed for invited carriers that board with a factoring company.
            </p>}>

            <MaterialIcon name='info' size={20} style={{ color: '#2196f3', padding: '0px 5px' }} />
          </Tooltip>
        </div>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ flex: 0.2, padding: 5 }} />
        <Field
          component={LabeledInput}
          name="default.id"
          hidden={true}
        />
        <div style={{ display: 'flex', flexDirection: 'row', flex: displayEditDisplayPayment ? 0.6 : 0.4, padding: 5 }}>
          <div style={{ flex: 0.4, padding: 5 }}>
            <PaymentSpeed name="default.payout_days" onChange={(event, value) => {
              try {
                const term = find(terms, { payout_days: value });
                change('default.payout_days', value);
                change('default.name', term.name);
                change('default.discount_rate', term.discount_rate);
              } catch (error) {

              }
            }} />
          </div>
          {displayEditDisplayPayment &&
            <div style={{ flex: 0.4, padding: 5 }}>
              <Field
                component={LabeledInput}
                name="default.name"
                disabled={true}
              />
            </div>
          }
          <div style={{ flex: 0.4, padding: 5 }}>
            <Field
              component={LabeledInput}
              disabled={true}
              format={value => `${value}%`}
              parse={value => value}
              name="default.discount_rate"
            />
          </div>
        </div>
        <div style={{ flex: 0.2, padding: 5 }}>
          <BrokerPortion
            name="default.factoring_fee_split_rate"
            disabled={true}
          />
        </div>
        <div style={{ flex: 0.2, padding: 5 }}>
          <Field
            component={LabeledInput}
            disabled={true}
            format={value => `${value}%`}
            parse={value => value}
            name="default.carrier_rate"
          />
        </div>
      </div>
    </div>
    }
  </div>;

const BrokerDefaultSettings = ({ closeModal, fields, terms, handleSubmit, change, submitting, factoring, formValues, allTerms }) =>
  <div>
    <ModalBody closeModal={closeModal} >
      <div style={{ width: '100%' }}>
        <p style={{ ...styles.text, color: '#FF7800', fontSize: 18 }}>Edit Default Settings</p>
        <div style={{ ...styles.flexRow }}>
          <p style={styles.text}>
            These are the presets default options (if enabled) that invited carriers will get to select from during their boarding process to your HaulPay group.
          </p>
        </div>
      </div>

      <FieldArray name='terms' component={paymentTermsArray} terms={allTerms} change={change} formValues={formValues} displayEditDisplayPayment={factoring.edit_speed_display} />
    </ModalBody>
    <div
      style={{ ...styles.flexRow, flexDirection: 'column', margin: '-5px 5px 5px 5px' }}
    >
      <div
        style={{
          display: 'flex',
          flex: 0.5,
          flexDirection: 'row',
          justifyContent: 'flex-end',
        }}
      >
        <button
          style={{
            marginLeft: '12px',
          }}
          className='btn btn-orange '
          onClick={handleSubmit}
          disabled={submitting}
        >
          {
            submitting ?
              <span>
                Save
                <Spinner style={{ fontSize: 3 }} />
              </span>
              :
              'Save'
          }
        </button>
      </div>
    </div>
  </div>;

export default compose(connect(
  state => {
    const user = get(state, `resource.user[${state.user.id}].data`, {});
    const factoring = get(state, `resource.factoringclient[${user.factoring_id}].data`, {});
    const terms = getBrokerTerms(state);
    const allTerms = getAllBrokerTerms(state);
    const defaultPaymentSpeed = find(allTerms, { payee: 'factoring_company' }) || {};
    return {
      user,
      factoring,
      terms,
      formValues: getFormValues(FORM_NAME)(state),
      defaultPaymentSpeed,
      allTerms,
    };
  },
  dispatch => ({
    fetchTerms: (...args) => dispatch(fetchTerms(...args)),
    change: (...args) => dispatch(change(FORM_NAME, ...args)),
    closeModal() {
      dispatch(closeModal());
    },
  })
),
  mapProps(state => ({
    initialValues: {
      terms: state.terms,
      default: state.defaultPaymentSpeed
    },
    ...state,
  })),
  lifecycle({
    async componentWillMount() {
      const { fetchTerms, user = {} } = this.props;
      fetchTerms(user.factoring_id);
    },
  }),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, { user, closeModal }) {
      const promises = [];
      try {
        fields.terms.map(async value => promises.push(
          dispatch(
            updateTerms(
              user.factoring_id,
              value.id,
              value
            )
          )));
        promises.push(dispatch(
          updateTerms(
            user.factoring_id,
            fields.default.id,
            fields.default
          )
        ))
        await Promise.all(promises);
        window.analytics.track(segmentEvents.BROKER_UPDATED_THEIR_DEFAULTS);
        closeModal();
        dispatch(openModal('success', { message: 'Payment terms has been updated.' }));
      }
      catch (e) {
        dispatch(openModal('ERROR', { message: 'Error while updating payment terms.' }));
      }

    },
  })
)(BrokerDefaultSettings);
