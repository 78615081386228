/**
 * Displays the main left-hand side ComFreight menu
 * @module components/pure/Menu
 * @since 3.0.0
 * @property {object} props
 * @property {function} [onCloseClick] - a function to call to close the menu
 * @property {node|node[]} children - the MenuItems or MenuItem derivatives to show in the list
 * @property {any} [props....rest] - props passed to containing i component
 */
import 'styles/Menu';
import React from 'react';
import PropTypes from 'prop-types';


export const Menu = ({ children }) =>
  <div className='Menu'>
    <div className='Menu__top navbar navbar-inverse navbar-static-top'>
      <img src='/public/image/haulpay_diamond_small.png' />
    </div>
    <div className='Menu__list'>
      {children}
    </div>
  </div>
  ;

Menu.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Menu;
