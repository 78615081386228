/* global API */
import APIFetch from 'datatypes/APIFetch';


/**
 @param {string} load_id
 @param {string} user_id
 */
export const dispatchLoad = (load_id, user_id) => async (dispatch, getState) => {
  const res = await dispatch(APIFetch(`${API.host}/dispatch/load/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      load: load_id,
      assigned_user: user_id,
    }),
  }));
  if (res.status !== 201) {
    throw await res.text();
  }
  return res;
};


export const DISPATCH_LOAD_CONFIRM = Symbol('DISPATCH_LOAD_CONFIRM');
/**
 @param {string} load_id
 @param {number} amount
 */
export const dispatchLoadConfirm = (load_id, amount) => async (dispatch, getState) => {
  const state = getState();
  const load = state.resource.load[load_id];
  const res = await dispatch(APIFetch(`${API.host}/dispatch/load/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      id: load.data.dispatched_load.id,
      amount,
    }),
  }));
  if (res.status !== 200) {
    throw await res.text();
  }
  dispatch({
    type: DISPATCH_LOAD_CONFIRM,
    payload: {
      id: load_id,
      amount,
    },
  });
  return res;
};

export const DISPATCH_LOAD_CANCEL = Symbol('DISPATCH_LOAD_CANCEL');
/**
 @param {string} load_id
 @param {string} user_id
 */
export const dispatchLoadCancel = (load_id, user_id) => async (dispatch, getState) => {
  const state = getState();
  const load = state.resource.load[load_id];
  const res = await dispatch(APIFetch(`${API.host}/dispatch/load/`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      id: load.data.dispatched_load.id,
    }),
  }));
  if (res.status !== 204) {
    throw res;
  }
  dispatch({
    type: DISPATCH_LOAD_CANCEL,
    payload: {
      load_id,
      user_id,
    },
  });
  return res;
};

/**
 @param {string} token
 @param {string} user_id
 */
export const updateDispatching = (token, user_id) => async (dispatch, getState) => {
  const res = await dispatch(APIFetch(`${API.host}/cmftstripe/cards/`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      authorization: `bearer ${getState().user.token}`,
    },
    body: JSON.stringify({
      token,
      user: user_id,
    }),
  }));
  if (res.status !== 201) {
    throw res;
  }
  return res;
};
