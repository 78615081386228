/**
 * Reducer for handling the factoringdebtornote resource actions
 * @module reducers/resource/factoringdebtornote
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringdebtornote
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringdebtornote;

import factoringdebtornoteResource from 'actions/resource/factoringdebtornote';
import factoringdebtornoteByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/factoringdebtornote';
import debtorcombinednotesResource from 'actions/admin/debtorcombinednotes';


export default function factoringdebtornoteResourceReducer(state = initialState, action) {
  state = factoringdebtornoteResource.reduce(state, action);
  switch (action.type) {
    case debtorcombinednotesResource.actions.FETCH_REQUEST_SUCCESS: {
      return {
        ...state,
        ...action.payload.filter(({ debtor }) => debtor).reduce((acc, curr) => {
          acc[curr.id] = factoringdebtornoteResource.parse(state[curr.id], curr);
          return acc;
        }, {}),
      };
    }
  }
  if (factoringdebtornoteByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...factoringdebtornoteResource.parseMany(state, action.payload.results),
    };
  }
  if (factoringdebtornoteByFactoringdebtorResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')) {
    return {
      ...state,
      [action.payload.id]: factoringdebtornoteResource.parse(state[action.payload.id], action.payload),
    };
  }
  return state;
}
