
const canvas = document.createElement('canvas');
const ctx = canvas.getContext('2d');
const IMAGE_OUTPUT_TYPE = 'image/jpeg';

const generateName = () => Math.random().toString(36).replace(/[^a-z0-9]+/g, '').substr(0, 12);

const cropImage = (file, crop) => new Promise(resolve => {
  const img = new Image;
  const objectURL = (file instanceof File || file instanceof Blob) ? URL.createObjectURL(file) : String(file);

  img.onload = () => {
    const x = Math.round(img.width * (crop.x / 100));
    const y = Math.round(img.height * (crop.y / 100));
    const width = Math.round(img.width * (crop.width / 100)) || img.width;
    const height = Math.round(img.height * (crop.height / 100)) || img.height;

    canvas.width = width;
    canvas.height = height;

    ctx.drawImage(img, x, y, width, height, 0, 0, width, height);

    if (canvas.toBlob) {
      canvas.toBlob(blob => {
        URL.revokeObjectURL(objectURL);

        if (file.name) {
          blob.name = file.name.replace(/(?:\.[^\.]+)?$/, '.jpg');
        }
        else {
          blob.name = `${generateName()}.jpg`;
        }
        resolve(blob);
      }, IMAGE_OUTPUT_TYPE);
    }
    else {
      const imageData = canvas.toDataURL(IMAGE_OUTPUT_TYPE);
      URL.revokeObjectURL(objectURL);
      resolve(imageData);
    }
  };
  img.src = objectURL;
});

export default cropImage;
