import React from 'react';

import moment from 'moment';

import colors from 'styles/colors.json';
import MaterialIcon from 'components/pure/MaterialIcon';
import AttachmentIcon from 'components/pure/AttachmentIcon';
import Tooltip from 'components/pure/Tooltip';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import Checkbox from 'components/pure/Checkbox';
import CheckboxInput from '../../../pure/form/inputs/CheckboxInput';
import { Field, FieldArray } from 'redux-form';
import { find, get, isEmpty } from 'lodash';

const CategoryMapping = cat => {
  switch (cat) {
    case 'late_fee':
      return 'Late Fee';
    case 'detention_fee':
      return 'Detention Fee';
    case 'damage_penalty':
      return 'Damage Penalty';
    case 'lumper_fee':
      return 'Lumper Fee';
    case 'misc':
      return 'Miscellaneous';
    default:
      return cat;
  }
};

const VisibilityMapping = vis => {
    if (Array.isArray(vis)) {
      if (
         (vis.includes('shipper') ||
      vis.includes('debtor')) &&
      vis.includes('carrier')
      ) {
        return 'Carrier & Shipper/Debtor';
      }

      if (vis.includes('shipper') || vis.includes('debtor')) {
        return 'Shipper/Debtor Only';
      }

      if (vis.includes('carrier')) {
        return 'Carrier Only';
      }
    }
};

const VisibilityInput = ({ input: { onChange, onBlur, value }, ...props }) => (
  <LabeledDropdown
    {...props}
    defaultValue='Carrier & Shipper/Debtor'
    input={{
      onChange: visibility => {
        let visibleTo = [];
        if (visibility === 'Carrier Only') {
          visibleTo = ['carrier'];
        } else if (visibility === 'Shipper/Debtor Only') {
          visibleTo = ['shipper', 'debtor'];
        } else if (visibility === 'Carrier & Shipper/Debtor') {
          visibleTo = ['carrier', 'shipper', 'debtor'];
        }
        onChange(visibleTo);
      },
      onBlur: onBlur,
      value: VisibilityMapping(value)
    }}
    data={['Carrier Only', 'Shipper/Debtor Only', 'Carrier & Shipper/Debtor']}
    placeholder="Visible To"
    style={{ margin: '0 0.5em' }}
    containerProps={{
      style: {
        marginBottom: 0
      }
    }}
  />
);

const getRowColumns = ({
  fields,
  data,
  lastDocIssueDate,
  onDeleteClick,
  onEditClick,
  deletedAttachments,
}) => {
  if (!data.length)
    return (
      <table className="table" style={{ fontSize: 'smaller' }}>
        <thead>
          <tr>
            <th>File Name</th>
            <th>Category</th>
            <th>Visibility</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan="4">No Attachments</td>
          </tr>
        </tbody>
      </table>
    );
  return (
    <table className="table" style={{ fontSize: 'smaller' }}>
      <thead>
        <tr>
          <th>File Name</th>
          <th>Category</th>
          <th>Visibility</th>
          <th>Actions</th>
        </tr>
      </thead>
      {fields.map((attachment, index) => {
        if(!isEmpty(find(deletedAttachments, ["id", get(data, `[${index}].id`)]))) {
          return;
        }
        const object = data[index] || {};
        const actions = object.uploaded ? (
          <div
            style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -0.25em' }}
          >
            {!attachment.disableEdit && (
              <MaterialIcon
                style={{
                  cursor: 'pointer',
                  fontSize: 20,
                  color: '#2196f3',
                  margin: '0 0.25em'
                }}
                name="edit"
                onClick={() => {
                  onEditClick(object);
                }}
              />
            )}
            <a
              href={object.download_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <MaterialIcon
                style={{
                  cursor: 'pointer',
                  fontSize: 20,
                  color: '#2196f3',
                  margin: '0 0.25em'
                }}
                name="cloud_download"
              />
            </a>
            <a
              href={object.preview_url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <MaterialIcon
                style={{
                  cursor: 'pointer',
                  fontSize: 20,
                  color: '#417505',
                  margin: '0 0.25em'
                }}
                name="remove_red_eye"
              />
            </a>
            {!object.disableDelete && (
              <MaterialIcon
                style={{
                  cursor: 'pointer',
                  fontSize: 20,
                  color: '#d9534f',
                  margin: '0 0.25em'
                }}
                name="delete"
                onClick={() => onDeleteClick(object)}
              />
            )}
          </div>
        ) : object.failed && object.failed === true ? (
          <Tooltip text="Uploads Processing Failed">
            <span>{' — Failed '}</span>
          </Tooltip>
        ) : (
          <div className="FactoringPaymentAttachment">
            <AttachmentIcon
              style={{
                maxHeight: '1em'
              }}
            />
            <Tooltip text="Uploads may take up to 15 minutes to process.">
              <span>
                {' — Processing '}
                <MaterialIcon name="sync" spin={true} />
              </span>
            </Tooltip>
          </div>
        );

        return (
          <tbody key={`row_${index}`}>
            <tr>
              {[
                <div>
                  {lastDocIssueDate &&
                    lastDocIssueDate < new Date(object.uploaded) && (
                      <span style={{ color: '#0FAF44' }}>NEW!!</span>
                    )}
                  {object.filename}
                  {object.uploaded && (
                    <p
                      style={{
                        color: colors.DARK_GRAY,
                        fontSize: 12,
                        fontWeight: 'bold'
                      }}
                    >
                      {moment(object.uploaded).format('l LTS')}
                    </p>
                  )}
                </div>,
                object.hasOwnProperty('category')
                  ? CategoryMapping(object.category)
                  : '-',
                object.hasOwnProperty('visible_to') ? (
                  <Field
                    component={VisibilityInput}
                    name={`${attachment}.visible_to`}
                  />
                ) : (
                  '-'
                ),
                actions
              ].map((columnData, j) => (
                <td key={`col_${index}_${j}`}>{columnData}</td>
              ))}
            </tr>
          </tbody>
        );
      })}
    </table>
  );
};

const AttachmentsTable = ({
  attachments,
  lastDocIssueDate,
  onDeleteClick,
  onEditClick,
  deletedAttachments,
}) => (
  <FieldArray
    name="updated_attachments"
    component={getRowColumns}
    data={attachments}
    onDeleteClick={onDeleteClick}
    onEditClick={onEditClick}
    lastDocIssueDate={lastDocIssueDate}
    deletedAttachments={deletedAttachments}
  />
);

export default AttachmentsTable;
