/**
 * Component for searching for users
 * @module components/container/UserSearch
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/user
 * @requires components/hoc/requireAdmin
 * @requires components/pure/Header
 * @requires components/pure/form/UserSearchForm
 * @requires components/pure/ResponsiveTable
 * @requires components/pure/ResponsiveContainer
 * @requires components/pure/EmailLink
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';

import { fetchNext, sort } from 'actions/search/user';

import createTableItems from 'helpers/createTableItems';

import Header from 'components/pure/Header';
import UserSearchForm from 'components/pure/form/UserSearchForm';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const UserSearchTableComponents = createTableItems(
  ({ item }) => [
    moment(item.data.created).format('l LT'),
    <Link to={`/admin/user/search/${item.data.id}`}>{item.data.email}</Link>,
    item.data.assigned_admin_name,
    item.data.factoring ? item.data.factoring.factoring_approved : '',
    <Link to={`/admin/user/search/${item.data.id}`}>{item.data.contact_email}</Link>,
    item.data.dba,
    item.data.contact_name,
    item.data.contact_phone,
    item.data.mc,
    item.data.dot,
    item.data.state,
    item.data.equipment_types.join(', '),
    moment(item.data.last_login).format('l LT'),
    <Link to={`/admin/user/search/${item.data.id}`}><button className='btn btn-orange'>Edit</button></Link>,
  ],
  compose(
    connect(
      (state, { id }) => ({
        item: state.resource.usersearch[id],
      }),
    ),
  ),
  () => ({
    style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
  }),
);

const UserSearchTable = compose(
  connect(
    state => ({
      items: state.search.user.ids.map(id => ({ id })),
      isFetching: state.search.user.isFetching,
      currentOrder: state.search.user.ordering,
    }),
    {
      sort,
      fetchNext,
    },
  ),
)(props =>
  <ResponsiveTable
    isFetching={props.isFetching}
    fetchNext={props.fetchNext}
    sort={props.sort}
    currentOrder={props.currentOrder}
    containerProps={{
      className: 'col-fill',
    }}
    headers={[
      { text: 'Created at', ordering: 'created' },
      { text: 'Email', ordering: false },
      { text: 'Assigned', ordering: false },
      { text: 'HP Status', ordering: false },
      { text: 'Contact Email', ordering: false },
      { text: 'DBA', ordering: false },
      { text: 'Contact', ordering: false },
      { text: 'Phone#', ordering: false },
      { text: 'MC#', ordering: false },
      { text: 'DOT#', ordering: false },
      { text: 'State', ordering: false },
      { text: 'Equipment Types', ordering: false },
      { text: 'Last Login', ordering: false },
      { text: 'More', ordering: false },
    ]}
    items={props.items}
    placeholder='No results'
    TableItem={UserSearchTableComponents.TableItem}
    BlockTableItem={UserSearchTableComponents.BlockTableItem}
    sortable={true}
  />
);

const UserTableLoadMoreButton = compose(
  connect(
    state => state.search.user,
    {
      fetchNext,
    },
  ),
)(({ next, isFetching, fetchNext }) =>
  next && !isFetching ?
    <button className='btn btn-orange visible-xs-block' onClick={fetchNext}>Load More</button>
    : null
);

const UserSearch = compose(
)(() =>
  <div className='clearfix' style={{ height: '100%', maxWidth: '100%' }}>
    <div className='col-xs-12 col-sm-2'>
      <Header>User Search</Header>
      <UserSearchForm />
    </div>
    <div className='col-xs-12 col-sm-10 col-fill'>
      <UserSearchTable />
      <UserTableLoadMoreButton />
    </div>
  </div>
);

export default UserSearch;
