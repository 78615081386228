import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, SubmissionError, reduxForm, formValueSelector } from 'redux-form';
import { Link } from 'react-router-v4';
import validate from 'validate.js';
import moment from 'moment';
import mapProps from 'recompose/mapProps';
import { get, head, isArray, last, uniq } from 'lodash';

import factoringclientResource, { getAttachment, getVoidedCheck, getCollatedAttachment, selectorCollatedAttachement, transferDocument } from 'actions/resource/factoringclient';
import { edit as editUser } from 'actions/resource/usersearch';
import { create as createAttachment } from 'actions/resource/factoringclientattachments';
import { fetch as fetchClientAuditLog } from 'actions/resource/factoringclientauditlog';
import { openModal, closeModal } from 'actions/ui';
import FetchError from 'datatypes/FetchError';
import { lifecycle, withState } from 'recompose';
import { USER_TYPE } from 'helpers';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import getPhoneParts from 'helpers/getPhoneParts';
import formatPennies from 'helpers/formatPennies';
import getSelf from 'selectors/getSelf';
import CircleSpinner from 'components/pure/CircleSpinner';

import { getAggregate } from 'actions/reserve';
import Resource from 'components/hoc/Resource';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import Header from 'components/pure/Header';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import GrowableInput from 'components/pure/form/inputs/GrowableInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import TaxIDInput from 'components/pure/form/inputs/TaxIDInput';
import { StateInput } from 'components/pure/form/inputs/StateInput';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import AdminDropdownInput from 'components/pure/form/inputs/AdminDropdownInput';
import NumberInput from 'components/pure/form/inputs/NumberInput';
import InputLabel from 'components/pure/InputLabel';
import ButtonUserAdminProfile from 'components/container/ButtonUserAdminProfile';
import FactoringStatusInfo from 'components/pure/FactoringStatusInfo';
import FMCSALink from 'components/pure/FMCSALink';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import ColoredMoney from 'components/pure/ColoredMoney';
import getSelfType from 'selectors/getSelfType';
import errorHandler from 'helpers/errorHandler';
import { Button } from 'react-bootstrap';

const ActiveReserves = ({ activeReserve, onClick }) => {
  const color = Number(activeReserve) > 0 ? 'green' : Number(activeReserve) < 0 ? 'red' : 'black';
  return (
    <a
      role='button'
      onClick={onClick}
      style={{ color }}
      >
      Active Reserve: {formatPennies(activeReserve)}
    </a>
  );
};
const FORM_NAME = 'FactoringClientEditForm';
const selector = formValueSelector(FORM_NAME);

export default compose(
  Resource('factoringclient', {
    expirationTime: -Infinity,
  }),
  spinnerIfFetching(),
  Resource('factoringclientattachments', {
    resourcePropName: 'attachments',
  }),
  spinnerIfFetching('attachments'),
  mapProps(props => {
    const attachments = get(props, 'attachments.data', []);

    return {
      ...props,
      attachments: isArray(attachments) ? attachments : [],
      refetchAttachments: props.refetchResource,
    };
  }),
  Resource('adminuser', {
    idPropName: ['item', 'data', 'user'],
    resourcePropName: 'user',
  }),
  spinnerIfFetching('user'),
  Resource('plaidaccount', {
    idPropName: ['item', 'data', 'user'],
    resourcePropName: 'plaidaccount',
  }),
  spinnerIfFetching('plaidaccount'),
  mapProps(props => ({
    ...props,
    user: get(props, 'user.data', {}),
    plaidaccount: get(props, 'plaidaccount.data', []),
  })),
  mapProps(({ item, user, attachments, plaidaccount, refetchAttachments, scrollReceivablesIntoView }) => {
    const emails = uniq(plaidaccount.reduce((acc, cur) => acc.concat(cur.emails), []).filter(email => email.primary === true));
    const bankAccounts = plaidaccount.reduce((acc, cur) => acc.concat(cur.accounts), []).filter(account => account.default === true);
    const phoneNumbers = plaidaccount.reduce((acc, cur) => acc.concat(cur.phone_numbers), []);
    const addresses = plaidaccount.reduce((acc, cur) => acc.concat(cur.addresses), []).filter(address => address.primary === true);
    const names = uniq(plaidaccount.reduce((acc, cur) => acc.concat(cur.names), []));

    return ({
      refetchAttachments,
      item,
      user,
      attachments,
      scrollReceivablesIntoView,
      initialValues: {
        email: user.email,
        contact_name: user.contact_name,
        user_address: {
          address: user.address,
          city: user.city,
          state: user.state,
          zip: user.zip,
        },
        contact_phone: getPhoneParts(user.contact_phone).phone,
        contact_phone_ext: getPhoneParts(user.contact_phone).ext,
        fax_number: user.fax_number,
        stripe_email: user.stripe_email,
        avgpay: user.avgpay,
        credit_approved: user.credit_approved,
        cargo_insurance_value: user.cargo_insurance_value,
        cargo_insurance_provider: user.cargo_insurance_provider,

        company_name: item.data.company_profile.name,
        company_address_street_one: item.data.company_profile.address.street_one,
        company_address_city: item.data.company_profile.address.city,
        company_address_state: item.data.company_profile.address.state,
        company_address_zip: item.data.company_profile.address.zip,
        company_phone: item.data.company_profile.phone_number,
        company_type: item.data.company_profile.type,
        company_tax_id: item.data.company_profile.tax_id,
        company_mc: item.data.company_profile.mc || '',
        company_dot: item.data.company_profile.dot || '',
        company_number_of_owner_operators: item.data.company_profile.number_of_owner_operators,
        company_number_of_trucks: item.data.company_profile.number_of_trucks || 0,
        company_number_of_trailers: item.data.company_profile.number_of_trailers,
        company_types_of_trailers: item.data.company_profile.types_of_trailers,
        company_years_in_business: item.data.company_profile.years_in_business,
        company_state_incorporated: item.data.company_profile.state_incorporated,
        company_current_factoring_company: item.data.company_profile.current_factoring_company,

        owner_name_first: item.data.owner_profile.name_first,
        owner_name_last: item.data.owner_profile.name_last,
        owner_address_street_one: item.data.owner_profile.address.street_one,
        owner_address_city: item.data.owner_profile.address.city,
        owner_address_state: item.data.owner_profile.address.state,
        owner_address_zip: item.data.owner_profile.address.zip,
        owner_phone_number_cell: getPhoneParts(item.data.owner_profile.phone_number_cell).phone,
        owner_phone_number_cell_ext: getPhoneParts(item.data.owner_profile.phone_number_cell).ext,
        owner_phone_number_home: getPhoneParts(item.data.owner_profile.phone_number_home).phone,
        owner_phone_number_home_ext: getPhoneParts(item.data.owner_profile.phone_number_home).ext,
        owner_email: item.data.owner_profile.email,
        owner_dob: item.data.owner_profile.date_of_birth ? moment(item.data.owner_profile.date_of_birth) : '',
        owner_ssn: item.data.owner_profile.social_number,
        owner_tax_lien_status: item.data.owner_profile.tax_lien_status,

        plaid_name_first: head((head(names) ? head(names) : '').split(' ')),
        plaid_name_last: last((head(names) ? head(names) : '').split(' ')),
        plaid_address_street_one: get(head(addresses), 'street', ''),
        plaid_address_city: get(head(addresses), 'city', ''),
        plaid_address_state: get(head(addresses), 'state', ''),
        plaid_address_zip: get(head(addresses), 'zip_code', ''),
        plaid_phone_number: get(head(phoneNumbers), 'phone_number', ''),
        plaid_phone_number_ext: '',
        plaid_email: get(head(emails), 'email_id', ''),
        plaid_bank_account: `${get(head(bankAccounts), 'type', '')} - ${get(head(bankAccounts), 'account_number_redacted', '')}`,
        plaid_bank_account_routing: `${get(head(bankAccounts), 'routing_number', '')}`,
        plaid_bank_name: `${get(head(bankAccounts), 'bank_name', '')}`,

        broker_approved: item.data.broker_approved,
        factoring_approved: item.data.factoring_approved,
        factoring_message: item.data.factoring_message,
        admin_message: item.data.admin_message,
        discount_rate: item.data.discount_rate,
        program_type: item.data.program_type,
        minimum_fee: item.data.minimum_fee,
        fuel_advance: item.data.fuel_advance,
        fuel_advance_limit: item.data.fuel_advance_limit,
        fuel_advance_fee: item.data.fuel_advance_fee,
        fuel_advance_rate: item.data.fuel_advance_rate,
        has_contract_authority_status: item.data.has_contract_authority_status,
        has_common_authority_status: item.data.has_common_authority_status,
        has_broker_authority_status: item.data.has_broker_authority_status,
        ucc_transferred: item.data.ucc_transferred,
        aladdin_owned: !item.data.aladdin_owned,
        aladdin_id: item.data.aladdin_id,
        is_allowed_to_operate: item.data.is_allowed_to_operate,
        assigned_admin: item.data.assigned_admin,
        equipment_types: (user.equipment_types || []).map(et => MultiTrailerInput.trailerTypes.find(({ value }) => et === value)),

        admin: { // not sure about these
          id: user.assigned_admin,
          name: user.assigned_admin_name,
        },
      },
    });
  }),
  connect(
    (state, { item }) => ({
      self: getSelf(state),
      user_type: getSelfType(state),
      collatedAttachment: selectorCollatedAttachement(state, get(item, 'data.id', 0)),
      isComfreightUser: selector(state, 'aladdin_owned'),
    }),
    (dispatch, { item }) => ({
      openAttachment(id, attachment) {
        return dispatch(getAttachment(id, attachment))
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Failed to fetch attachment' }));
          })
        ;
      },
      getCollatedAttachment() {
        dispatch(getCollatedAttachment(item.data.id));
      },
      deleteAttachment(id, attachment, callback) {
        return dispatch(openModal('confirm', {
          type: 'factoringclientattachment_delete',
          args: [id, attachment],
          callback,
        }));
      },
      async transferDocument() {
        try {
          dispatch(await transferDocument(item.data.id));
          dispatch(openModal('success', { message: 'File has been sent successfully.' }));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: 'Error sending collated file.' }));
        }
      },
      openVoidedCheck(id, attachment) {
        return dispatch(getVoidedCheck(id, attachment))
          .catch(err => {
            console.warn(err);
            dispatch(openModal('error', { message: 'Failed to fetch voided check' }));
          })
        ;
      },
      deleteVoidedCheck(id, attachment) {
        return dispatch(openModal('confirm', {
          type: 'factoringclientvoidedcheck_delete',
          args: [id, attachment],
        }));
      },
      approvalConfirm() {
        return dispatch(openModal('approvalConfirm', { id: item.data.id }));
      },
      deleteFactoringClient() {
        return dispatch(openModal('confirm', {
          type: 'factoringclient_delete',
          args: [item.data.id],
        }));
      },
      getAggregate(id) {
        return dispatch(getAggregate(id));
      },
      confirmSave(onConfirm) {
        dispatch(openModal('confirm', {
          type: 'userfactoringprofile_save',
          args: [{
            onConfirm,
          }],
        }));
      },
    }),
  ),
  reduxForm({
    form: FORM_NAME,
    enableReinitialize: true,
    validate(fields) {
      const errs = validate(fields, {
        company_tax_id: {
          format: {
            pattern: /^((\d{2}-\d{7})|\d{4})?$/,
            message: '^Please enter tax ID in the form "" or "XXXX" or "XX-XXXXXXX"',
          },
        },
        owner_ssn: {
          format: {
            pattern: /^(\d{4}|\d{9})?$/,
            message: '^Please enter SSN in the form "" or "XXXX" or "XXXXXXXXX"',
          },
        },
      });
      return errs;
    },
    async onSubmit(fields, dispatch, { item, self, user }) {
      let minimum_fee;
      if (item.data.minimum_fee !== fields.minimum_fee){
        minimum_fee = fields.minimum_fee;
      }
      try {

        const filePromises = get(fields, 'attachments', []).map(attachment => {
          return dispatch(createAttachment(item.data.id, attachment));
        });

        const noaPromises = get(fields, 'noa_attachments', []).map(attachment => {
          return dispatch(createAttachment(item.data.id, attachment, 'noa'));
        });

        const comfreightNoaPromises = get(fields, 'comfreight_noa', []).map(attachment => {
          return dispatch(createAttachment(item.data.id, attachment, 'comfreight_noa'));
        });

        const transferDocumentPromises = get(fields, 'transfer_document', []).map(attachment => {
          return dispatch(createAttachment(item.data.id, attachment, 'transfer_document'));
        });
        await Promise.all([...filePromises, ...noaPromises, ...comfreightNoaPromises, ...transferDocumentPromises]);

        if (fields.source !== user.source && [USER_TYPE.ADMIN, USER_TYPE.SUB_ADMIN].includes(self.data.type)) {
          await dispatch(editUser(user.id, {
            source: fields.source,
            assigned_admin: fields.admin.id,
            equipment_types: fields.equipment_types ? fields.equipment_types.map(({ value }) => value) : [],
          }));
        }
        await dispatch(factoringclientResource.edit(item.data.id, {
          owner_profile: {
            name_first: fields.owner_name_first,
            name_last: fields.owner_name_last,
            address: {
              street_one: fields.owner_address_street_one,
              city: fields.owner_address_city,
              state: fields.owner_address_state,
              zip: fields.owner_address_zip,
            },
            phone_number_cell: normalizeFormPhone(fields, 'owner_phone_number_cell'),
            phone_number_home: normalizeFormPhone(fields, 'owner_phone_number_home'),
            email: fields.owner_email,
            ...(() => moment.isMoment(fields.owner_dob) ? ({
              date_of_birth: fields.owner_dob.format(),
            }) : undefined)(),
            social_number: fields.owner_ssn,
            tax_lien_status: fields.owner_tax_lien_status,
          },
          company_profile: {
            name: fields.company_name,
            address: {
              street_one: fields.company_address_street_one,
              city: fields.company_address_city,
              state: fields.company_address_state,
              zip: fields.company_address_zip,
            },
            mc: fields.company_mc,
            dot: fields.company_dot,
            type: fields.company_type,
            tax_id: fields.company_tax_id,
            state_incorporated: fields.company_state_incorporated,
            number_of_owner_operators: fields.company_number_of_owner_operators,
            number_of_trucks: fields.company_number_of_trucks || 0,
            number_of_trailers: fields.company_number_of_trailers,
            types_of_trailers: fields.company_types_of_trailers,
            years_in_business: fields.company_years_in_business,
            current_factoring_company: fields.company_current_factoring_company,
          },
          factoring_approved: fields.factoring_approved,
          broker_approved: fields.broker_approved,
          factoring_message: fields.factoring_message,
          admin_message: fields.admin_message,
          discount_rate: fields.discount_rate,
          minimum_fee: minimum_fee,
          fuel_advance: fields.fuel_advance,
          fuel_advance_limit: fields.fuel_advance_limit,
          fuel_advance_fee: fields.fuel_advance_fee,
          fuel_advance_rate: fields.fuel_advance_rate,
          program_type: fields.program_type,
          noa_attachments: fields.noa_attachments,
          has_contract_authority_status: fields.has_contract_authority_status,
          has_common_authority_status: fields.has_common_authority_status,
          has_broker_authority_status: fields.has_broker_authority_status,
          is_allowed_to_operate: fields.is_allowed_to_operate,
          ucc_transferred: fields.ucc_transferred,
          assigned_admin: fields.assigned_admin ? fields.assigned_admin.id : undefined,
          aladdin_owned: !fields.aladdin_owned,
          aladdin_id: fields.aladdin_id,
        }));
        dispatch(openModal('success', { message: 'Successfully updated factoring user.' }));
        dispatch(fetchClientAuditLog(item.data.id));
      }
      catch (err) {
        console.warn(err);
        if (err instanceof FetchError && err.status === 400) {
          try {
            const json = JSON.parse(err.message);
            if (json && json[0]) {
              dispatch(openModal('error', { message: json[0] }));
              return;
            }
          }
          catch (e) {}
        }
        if (err.message && err.message.owner_profile && err.message.owner_profile.address) {
          const message = err.message.owner_profile.address.non_field_errors[0];
          dispatch(openModal('error', { message: `Owner Profile: ${message}` }));
          throw new SubmissionError({ owner_address_street_one: message });
        }
        else if (err.message && err.message.minimum_fee){
          const message = err.message.minimum_fee[0];
          dispatch(openModal('warning', { message: `${message}\n Contact Super Admin for any other values.` }));
        }
        else {
          dispatch(openModal('error', { message: errorHandler(err).join('\n') }));
        }
      }
    },
  }),
  withState('activeReserve', 'setActiveReserve', 0),
  lifecycle({
    async componentDidMount() {
      const userId = get(this.props, 'user.id');
      const reserve = await this.props.getAggregate(userId);
      await this.props.setActiveReserve(reserve.total_reserve);
      await this.props.getCollatedAttachment();
    },
  })
)(({
  item,
  attachments,
  user,
  createAttachment,
  handleSubmit,
  submitting,
  openAttachment,
  deleteAttachment,
  refetchAttachments,
  openVoidedCheck,
  deleteVoidedCheck,
  approvalConfirm,
  setNotNew,
  deleteFactoringClient,
  self,
  dispatch,
  user_type,
  activeReserve,
  scrollReceivablesIntoView,
  confirmSave,
  isComfreightUser,
  collatedAttachment,
  transferDocument,
}) =>
  <form>
    <div>
      <Header>
        <h3 style={{ color: '#2196f3' }}>
          <b>User Factoring Profile</b>
        </h3>
        <ActiveReserves activeReserve={activeReserve} onClick={scrollReceivablesIntoView} />
      </Header>
      <br />
      <div className='row'>
        <div className='col-xs-12 col-lg-3'>
          <Field name='admin' component={AdminDropdownInput} />
          <InputLabel label='CMFT Agreement Number' value={item.data.agreement_number} />
          <InputLabel label='User Type' value={user.user_type} />
          <InputLabel label='Created At' value={moment(user.created).format('l LTS')} />
          <InputLabel label='Last Login' value={user.last_login ? moment(user.last_login).format('l LTS') : 'Never'} />
          {
            [null, ''].includes(user.source) ?
              <Field
                name='source'
                component={LabeledInput}
                label='Source'
              />
              :
              <InputLabel label='Source' value={user.source || 'unspecified'} />
          }
          <Field
            name='email'
            component={LabeledInput}
            label={
              <div>
                Email
                {
                  item.data.should_email_user ?
                    <div style={{ fontWeight: 'normal' }}>Used for HaulPay notifications</div>
                    : null
                }
              </div>
            }
            disabled={true}
          />
          <Field name='contact_name' component={LabeledInput} label='Contact Name' disabled={true} />
          <Field
            name='user_address'
            component={GoogleLocationInput}
            label='Address'
            placeholder=''
            inputProps={{
              disabled: true,
            }}
          />
          <div className='row'>
            <div className='col-md-6'>
              <Field name='contact_phone' component={PhoneInput} label='Phone' disabled={true} />
            </div>
            <div className='col-md-6'>
              <Field name='contact_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' disabled={true} />
            </div>
          </div>
          <Field name='fax_number' component={PhoneInput} label='Fax' disabled={true} />
          <Field name='stripe_email' component={LabeledInput} label='Stripe Email' disabled={true} />
          <Field name='avgpay' component={LabeledInput} type='text' label='Average Days To Pay' disabled={true} />
          <Field
            name='credit_approved'
            component={LabeledDropdown}
            data={[
              { text: 'Yes', value: true },
              { text: 'No', value: false },
            ]}
            label='Credit Approved Emblem'
            disabled={true}
          />
          <Field name='cargo_insurance_value' component={LabeledInput} label='Cargo Insurance Value' disabled={true} />
          <Field name='cargo_insurance_provider' component={LabeledInput} label='Cargo Insurance Provider' disabled={true} />
          <LabeledInput label='Uploaded Attachments' ComponentClass={({ children }) => children}>
            <div>
              {(attachments || []).filter(({ category }) => category === '').map((attachment, i) =>
                <LoadAttachmentButton
                  key={i}
                  title={attachment}
                  // getURL={() => openAttachment(attachment.factoring, attachment.filename)}
                  getURL={() => Promise.resolve({
                    preview_url: attachment.preview_url,
                    download_url: attachment.download_url,
                  })}
                  fileName={attachment.filename}
                  remove={() => {
                    deleteAttachment(attachment.factoring, attachment.id, refetchAttachments);
                  }}
                  isNew={attachment.is_new}
                />
              )}
            </div>
          </LabeledInput>
          {
            (attachments || []).filter(({ category }) => category === 'voided_checks').length ?
              <LabeledInput label='Check for Bank' ComponentClass={({ children }) => children}>
                <div>
                  {(attachments || []).filter(({ category }) => category === 'voided_checks').map((voided_check, i) =>
                    <LoadAttachmentButton
                      key={i}
                      isNew={voided_check.is_new}
                      title={voided_check.filename}
                      getURL={() => openVoidedCheck(item.data.id, voided_check.filename)}
                      remove={() => deleteVoidedCheck(item.data.id, voided_check.filename)}
                    />
                  )}
                </div>
              </LabeledInput>
              : null
          }
          <LabeledInput label='Notice of Assignment' ComponentClass={({ children }) => children}>
            <div>
              {(attachments || []).filter(({ category }) => ['noa', 'comfreight_noa'].includes(category)).map(({ category, filename, preview_url, download_url, id, is_new }, i) =>
                <LoadAttachmentButton
                  key={i}
                  title={category}
                  isNew={is_new}
                  getURL={() => Promise.resolve({
                    preview_url: preview_url,
                    download_url,
                  })}
                  remove={() => {
                    deleteAttachment(item.data.id, id, refetchAttachments);
                  }}
                />
              )}
            </div>
          </LabeledInput>
          <LabeledInput label='Transfer Documents' ComponentClass={({ children }) => children}>
            <div>
              {(attachments || []).filter(({ category }) => category === 'transfer_document').map(({ filename, preview_url, download_url, id, is_new }, i) =>
                <LoadAttachmentButton
                  key={i}
                  title={filename}
                  isNew={is_new}
                  getURL={() => Promise.resolve({
                    preview_url: preview_url,
                    download_url,
                  })}
                  remove={() => {
                    deleteAttachment(item.data.id, id, refetchAttachments);
                  }}
                />
              )}
            </div>
          </LabeledInput>
          <LabeledInput label='Collated Attachments' ComponentClass={({ children }) => children}>
            <div>
              { !collatedAttachment.isFetching ?
                collatedAttachment.download_url ?
                  <div
                    style={{
                      display: 'flex',
                      flex: 1,
                      flexDirection: 'row',
                    }}
                    >
                    <LoadAttachmentButton
                      download_url={collatedAttachment.download_url}
                      preview_url={collatedAttachment.preview_url}
                    />
                    <div>
                      <Button
                        bsSize='small'
                        style={{
                          color: 'white',
                          backgroundColor: '#21bb00',
                        }}
                        onClick={async () => await transferDocument()}
                        >
                        Send
                      </Button>
                    </div>
                  </div>
                  : <p>No collated file found.</p>
                : <CircleSpinner />
              }
            </div>
          </LabeledInput>
        </div>
        <div className='col-xs-12 col-lg-3'>
          <Field name='company_name' component={LabeledInput} label='Legal Business Name' />
          <Field name='company_address_street_one' component={LabeledInput} label='Address' />
          <Field name='company_address_city' component={LabeledInput} label='City' />
          <Field name='company_address_state' component={LabeledInput} label='State' />
          <Field name='company_address_zip' component={LabeledInput} label='Zip' />
          <Field
            name='company_type'
            component={LabeledDropdown}
            label='Business Type'
            data={[
              'Corporation',
              'LLC',
              'Sole Proprietorship',
              'Partnership',
            ]}
          />
          <Field name='company_mc' component={LabeledInput} ComponentClass={NumberInput} label='MC #' type='number' />
          <Field name='company_dot' component={LabeledInput} ComponentClass={NumberInput} label='DOT #' type='number' />
          <Field name='company_years_in_business' component={LabeledInput} label='Years in Business' />
          <Field name='company_number_of_trucks' component={LabeledInput} label='# of Trucks' />
          <Field name='company_number_of_trailers' component={LabeledInput} label='# of Trailers' />
          <Field name='company_types_of_trailers' component={LabeledInput} label='Types of Trailers' />
          <Field name='company_number_of_owner_operators' component={LabeledInput} label='# of Owner Operators' />
          <Field name='company_state_incorporated' component={StateInput} label='State Incorporated' />
          <Field name='company_tax_id' component={TaxIDInput} label='EIN' />
          <Field name='company_current_factoring_company' component={LabeledInput} label='Current Factoring Company' />
        </div>
        <div className='col-xs-12 col-lg-3'>
          <Field name='plaid_name_first' disabled={true} component={LabeledInput} label={'Plaid First Name'} />
          <Field name='plaid_name_last' disabled={true} component={LabeledInput} label={'Plaid Last Name'} />
          <Field name='plaid_address_street_one' disabled={true} component={LabeledInput} label={'Plaid Address'} />
          <Field name='plaid_address_city' disabled={true} component={LabeledInput} label={'Plaid City'} />
          <Field name='plaid_address_state' disabled={true} component={LabeledInput} label={'Plaid State'} />
          <Field name='plaid_address_zip' disabled={true} component={LabeledInput} label={'Plaid Zip'} />
          <div className='row'>
            <div className='col-md-6'>
              <Field name='plaid_phone_number' disabled={true} component={PhoneInput} label={'Plaid Phone'} />
            </div>
            <div className='col-md-6'>
              <Field name='plaid_phone_number_ext' disabled={true} component={LabeledInput} label='Plaid Phone Ext.' type='number' />
            </div>
          </div>
          <Field name='plaid_email' disabled={true} component={LabeledInput} label={'Plaid Email'} />
          <Field name='plaid_bank_account' disabled={true} component={LabeledInput} label={'Plaid Bank Account'} />
          <Field name='plaid_bank_account_routing' disabled={true} component={LabeledInput} label={'Plaid Routing Number'} />
          <Field name='plaid_bank_name' disabled={true} component={LabeledInput} label={'Plaid Bank Name'} />
          <Field name='aladdin_id' component={LabeledInput} label={'Aladdin ID'} />
        </div>
        <div className='col-xs-12 col-lg-3'>
          <Field name='owner_name_first' component={LabeledInput} label={'Owner\'s First Name'} />
          <Field name='owner_name_last' component={LabeledInput} label={'Owner\'s Last Name'} />
          <Field name='owner_address_street_one' component={LabeledInput} label={'Owner\'s Address'} />
          <Field name='owner_address_city' component={LabeledInput} label={'Owner\'s City'} />
          <Field name='owner_address_state' component={LabeledInput} label={'Owner\'s State'} />
          <Field name='owner_address_zip' component={LabeledInput} label={'Owner\'s Zip'} />
          <div className='row'>
            <div className='col-md-6'>
              <Field name='owner_phone_number_cell' component={PhoneInput} label={'Owner\'s Cell Phone'} />
            </div>
            <div className='col-md-6'>
              <Field name='owner_phone_number_cell_ext' component={LabeledInput} label='Phone Ext.' type='number' />
            </div>
          </div>
          <div className='row'>
            <div className='col-md-6'>
              <Field name='owner_phone_number_home' component={PhoneInput} label={'Owner\'s Home Phone'} />
            </div>
            <div className='col-md-6'>
              <Field name='owner_phone_number_home_ext' component={LabeledInput} label='Phone Ext.' type='number' />
            </div>
          </div>
          <Field
            name='owner_email'
            component={LabeledInput}
            label={
              <div>
                Owner's Email
                <div style={{ fontWeight: 'normal' }}>Used for HaulPay notifications</div>
              </div>
            }
          />
          <Field
            name='owner_dob'
            component={DateTimeInput}
            label={'Owner\'s DOB'}
            pickerOptions={{
              time: false,
              initialView: 'century',
            }}
          />
          <Field name='owner_ssn' component={LabeledInput} label={'Last 4 - Owner\'s SSN'} />
          <Field
            name='owner_tax_lien_status'
            component={LabeledDropdown}
            label='Lien Status'
            data={[
              {
                text: 'Has Lien and Has Plan',
                value: 'has_tax_lien_payment_plan',
              },
              {
                text: 'Has Lien and NO Plan',
                value: 'has_tax_lien',
              },
              {
                text: 'No Lien',
                value: 'none',
              },
            ]}
          />
          <Field
            name='equipment_types'
            component={MultiTrailerInput}
            label='Equipment Types'
          />
          <div className='form-group'>
            <div><Field name='has_contract_authority_status' component={CheckboxInput} label='Has Contract Authority Status' /></div>
            <div><Field name='has_common_authority_status' component={CheckboxInput} label='Has Common Authority Status' /></div>
            <div><Field name='has_broker_authority_status' component={CheckboxInput} label='Has Broker Authority Status' /></div>
            <div><Field name='is_allowed_to_operate' component={CheckboxInput} label='Is Allowed to Operate' /></div>
            <div><Field name='ucc_transferred' component={CheckboxInput} label='UCC Transfer Complete?' /></div>
            <div><Field style={{ color: '#5CB85C' }} name='aladdin_owned' component={CheckboxInput} label='Transferred to Comfreight?' /></div>
          </div>
          <div className='form-group'>
            <label className='control-label'>Owner's Signature</label>
            <div>
              <a href={item.data.signature} target='_blank'>
                <img src={item.data.signature} style={{ maxWidth: '100%' }} />
              </a>
            </div>
          </div>
        </div>
      </div>
      <Field
        name='attachments'
        component={MultiImageInput}
        limit={Infinity}
        buttonText='Upload Documents'
        buttonClassName='btn btn-default'
        shouldCrop={true}
      />
      <Field
        name='comfreight_noa'
        component={MultiImageInput}
        limit={Infinity}
        buttonText='Add HaulPay NOA'
        buttonClassName='btn btn-default'
        shouldCrop={true}
      />
      <Field
        name='transfer_document'
        component={MultiImageInput}
        limit={Infinity}
        buttonText='Add Transfer Documents'
        buttonClassName='btn btn-default'
        shouldCrop={true}
      />
      {
        user_type !== USER_TYPE.FACTORING_REMOTE_ADMIN && !isComfreightUser ?
          <Field
            name='noa_attachments'
            component={MultiImageInput}
            limit={Infinity}
            buttonText='Add NOA Attachments'
            buttonClassName='btn btn-default'
            shouldCrop={true}
          />
          :
          undefined
      }
      <div className='text-right'>
        <FMCSALink mc={item.data.company_profile.mc} dot={item.data.company_profile.dot} />
      </div>
      <div className='text-right'>
        {/*{*/}
        {/*user_type !== USER_TYPE.FACTORING_REMOTE_ADMIN && user_type !== USER_TYPE.FACTORING_REMOTE_COORDINATOR &&*/}
        {/*<div style={{ display: 'inline-block', marginLeft: '8px' }}>*/}
        {/*<button className='btn btn-orange' type='button' onClick={() => dispatch(openModal('paymentmethods', { item }))}>*/}
        {/*<MaterialIcon name='label' />*/}
        {/*Set Default Payment</button>*/}
        {/*</div>*/}
        {/*}*/}
        {
          user_type !== USER_TYPE.FACTORING_REMOTE_ADMIN && USER_TYPE.FACTORING_REMOTE_COORDINATOR &&
          <div style={{ display: 'inline-block' }}>
            <button style={{ marginLeft: '8px', backgroundColor: '#3ca8ff', height: '35px', color: 'white' }} type='button' onClick={() => dispatch(openModal('paymentinformation', { item }))}>
              <MaterialIcon name='payment' />
            Edit Payment Methods</button>
          </div>
        }
        {
          self.data.type === USER_TYPE.ADMIN && item.data.factoring_approved !== 'approved' &&
          <button style={{ marginLeft: '8px', backgroundColor: '#ff100e', height: '35px', color: 'white' }} onClick={deleteFactoringClient} type='button' disabled={submitting}>
            <MaterialIcon name='delete' />
            Delete</button>
        }
        {
          item.data.factoring_approved === 'approved' &&
            <Link style={{ marginLeft: '8px' }} to={`/admin/factoring/client/${item.data.id}/paymentcreate`}>
              <button className='btn-payment' type='button'>
                <MaterialIcon name='attach_money' />
                Payment Request</button>
            </Link>
        }
        {
          item.data.factoring_approved === 'new' || item.data.factoring_approved === 'document_review' &&
          <div style={{ display: 'inline-block', marginLeft: '8px' }}>
            <button style={{ backgroundColor: '#21bb00', height: '35px', color: 'white' }} disabled={submitting} type='button' onClick={approvalConfirm}>
              <MaterialIcon name='send' />
              Submit for Approval</button>
          </div>
        }
        <ButtonUserAdminProfile style={{ marginLeft: '8px' }} id={user.id} />
        <button
          name='save_profile' style={{ marginLeft: '8px', backgroundColor: '#E86B00', height: '35px', color: 'white' }} disabled={submitting} onClick={event => {
            event.preventDefault();
            confirmSave(handleSubmit);
          }}
                                                                                                                                              >
          <MaterialIcon name='save' />
          {' '}
          Save Profile
        </button>
      </div>
    </div>
    <hr />
    <div>
      <Header>Factoring Profile Status</Header>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='admin_message'
              component={GrowableInput}
              props={{
                Component: LabeledInput,
                ComponentClass: 'textarea',
                placeholder: 'Internal Status Notes',
              }}
              label='Internal Status Notes'
              // horizontal
            />
          </div>
          <div className='col-lg-6'>
            <Field
              name='factoring_approved'
              component={LabeledDropdown}
              data={[
                { text: 'New', value: 'new' },
                { text: 'Document Review', value: 'document_review' },
                { text: 'Document Issue', value: 'document_issue' },
                { text: 'Pending', value: 'pending' },
                { text: 'Contract Out', value: 'contract_out' },
                { text: 'UCC Hold', value: 'ucc_hold' },
                { text: 'Not Authorized', value: 'not_authorized' },
                { text: 'Approved', value: 'approved' },
                { text: 'Declined', value: 'declined' },
                { text: 'Dead', value: 'dead' },
                { text: 'Bad Lead', value: 'bad_lead' },
              ]}
              label='Client Approval'
              // horizontal
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='broker_approved'
              component={LabeledDropdown}
              data={item.data.broker_approved === 'na' ?
                [
                  { text: 'N/A', value: 'na' },
                ] :
                [
                  { text: 'Pending', value: 'pending' },
                  { text: 'Approved', value: 'approved' },
                  { text: 'Declined', value: 'declined' },
                ]
              }
              label='Broker Approval'
              placeholder={'N/A'}
              disabled={item.data.broker_approved === 'na'}
            />
          </div>
          <div className='col-lg-6'>
            <Field
              name='factoring_message'
              component={GrowableInput}
              props={{
                Component: LabeledInput,
                ComponentClass: 'textarea',
                placeholder: 'Message to Client',
              }}
              label='Message to Client'
              // horizontal
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='program_type'
              component={LabeledDropdown}
              data={[
                { text: 'Recourse', value: 'recourse' },
                { text: 'Non-Recourse', value: 'nonrecourse' },
              ]}
              label='Client Program Type'
              // horizontal
            />
          </div>
          <div className='col-lg-6'>
            <Field
              name='discount_rate'
              component={LabeledInput}
              type='number'
              placeholder='Factoring Rate %'
              label='Discount Rate'
              // horizontal
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='minimum_fee'
              component={PennyInput}
              placeholder='Enter Min Fee Amount'
              label='Minium Fee'
              // horizontal
            />
          </div>
          <div className='col-lg-6'>
            <Field
              name='fuel_advance'
              component={LabeledDropdown}
              data={[
                { text: 'True', value: 'true' },
                { text: 'False', value: 'false' },
              ]}
              label='Fuel Advance?'
              // horizontal
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='fuel_advance_fee'
              component={PennyInput}
              placeholder='Enter Fuel Advance Fee'
              label='Fuel Advance Fee'
            />
          </div>
          <div className='col-lg-6'>
            <Field
              name='fuel_advance_rate'
              component={LabeledInput}
              type='number'
              placeholder='Enter Fuel Advance Rate'
              label='Fuel Advance Rate'
            />
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6'>
            <Field
              name='fuel_advance_limit'
              component={PennyInput}
              placeholder='Enter Fuel Advance Limit'
              label='Fuel Advance Limit'
              // horizontal
            />
          </div>
        </div>
      </div>
      <br />
      <div className='row'>
        <div className='row'>
          <div className='col-xs-12 col-sm-12'>
            <FactoringStatusInfo status_timeline={item.data.status_timeline} />
          </div>
        </div>
      </div>
      <div className='text-right'>
        <button
          style={{ backgroundColor: '#e86900', height: '35px', color: 'white' }} disabled={submitting} onClick={event => {
            event.preventDefault();
            confirmSave(handleSubmit);
          }}
                                                                                                       >
          <MaterialIcon name='save' />
          {' '}
          Save Status
        </button>
      </div>
    </div>
  </form>
);
