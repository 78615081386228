import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const WeightInput = ({ input, meta, label, placeholder = 45000 }) => (
  <div className={cs('form-group', { 'has-error': meta.touched && meta.error })}>
    <label className='control-label'>{label || 'Weight'}</label>
    <span className='input-group'>
      <input
        className='form-control'
        placeholder={placeholder}
        type='number'
        {...input}
        onChange={e => {
          const val = e.target.value;
          if (val.slice(-1).toLowerCase() === 'k') {
            const f = parseFloat(val.slice(0, -1));
            if (!Number.isNaN(f)) {
              return input.onChange((f * 1000).toString(10));
            }
          }
          input.onChange(e);
        }}
      />
      <span className='input-group-addon'>lbs</span>
    </span>
    {meta.touched && meta.error && <div className='help-block'>{meta.error}</div>}
  </div>
);

WeightInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default WeightInput;
