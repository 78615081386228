import getSelf from './getSelf';
import { memoize } from 'lodash';
import { USER_TYPE } from 'helpers';
import isEmpty from 'lodash/isEmpty';
import find from 'lodash/find';

export default memoize(state => {
  const { data } = getSelf(state);
  const isFactoring = data.factoring_approved === 'approved';
  return data.groups.length > 0 && isEmpty(find(data.groups, object => object.type === 'dispatch_broker')) && isFactoring && data.type === USER_TYPE.CARRIER;
});
