/**
 * Component that connects the RateIndexBlockTable component to state
 * @module components/container/RateIndexBlockTable
 * @since 3.0.0
 * @requires components/hoc/Resource
 * @requires components/hoc/nothingIfFetching
 * @requires components/pure/RateIndexBlockTable
 * @property {object} props
 * @property {string} props.id - the ID of the load to display a bid table for
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';

import Resource from 'components/hoc/Resource';
import nothingIfFetching from 'components/hoc/nothingIfFetching';
import RateIndexPermissionsErrorTable from 'components/pure/RateIndexPermissionsErrorTable';
import RateIndexBlockTable from 'components/pure/RateIndexBlockTable';


export default compose(
  Resource('load'),
  nothingIfFetching(),
  branch(
    props => !props.item.data.linehaul,
    renderComponent(RateIndexPermissionsErrorTable),
    Component => Component,
  ),
  Resource('map', {
    resourcePropName: 'map',
    idPropName: ({ item }) => ({
      origin: item.data.origin_coords,
      destination: item.data.destination_coords,
    }),
  }),
  nothingIfFetching('map'),
)(({ id, ...rest }) =>
  <RateIndexBlockTable {...rest} />
);
