/**
 * Component that displays a button with sort logic
 * @module components/stateful/SortButton
 * @since 3.0.0
 * @requires actions/resource/activity
 * @property {object} props
 * @property {node} [props.active_prefix] - something to prepend to the selected option
 * @property {node} [props.active_postfix] - something to append to the selected option
 * @property {string} props.currentOrder - the current sort ordering
 * @property {object[]} props.options - the sorting options to display
 * @property {string} props.options[].displayName - the text to display
 * @property {string} props.options[].ordering - the value to use this this item's sort ordering
 * @property {string[]} props.options[].adjectives - an array of adjectives to use for this item
 * @property {function} props.sort - the function to call with the new ordering value when a user selects an item from the dropdown or reverses the sort
 * @property {boolean} [props.showTriangles] - whether to show the sort direction triangles
 * @property {any} [props....rest] - props passed to containing sort-button component
 */
import colors from '../../../styles/colors.json';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


class SortButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };

    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleOrder = this.toggleOrder.bind(this);
    this.onSelect = this.onSelect.bind(this);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { props, state } = this;
    return (
      state.isOpen !== nextState.isOpen ||
      props.currentOrder !== nextProps.currentOrder
    );
  }

  static propTypes = {
    active_prefix: PropTypes.node,
    active_postfix: PropTypes.node,
    currentOrder: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      ordering: PropTypes.string.isRequired,
      adjectives: PropTypes.arrayOf(PropTypes.string),
    })).isRequired,
    sort: PropTypes.func.isRequired,
    showTriangles: PropTypes.bool,
  };

  toggleMenu(e, isOpen = !this.state.isOpen) {
    this.setState({ isOpen });
  }

  toggleOrder() {
    const { currentOrder, sort } = this.props;
    sort(currentOrder.startsWith('-') ? currentOrder.slice(1) : `-${currentOrder}`);
  }

  onSelect(e, index) {
    const { currentOrder, options, sort } = this.props;
    this.toggleMenu(e, false);
    const newSort = options[index].ordering;
    if (!currentOrder.startsWith('-')) {
      if (currentOrder === newSort) {
        // X -> -X
        return sort(`-${currentOrder}`);
      }
      // X -> Y
      return sort(newSort);
    }
    // -X -> X
    if (currentOrder.slice(1) === newSort) {
      return sort(currentOrder.slice(1));
    }
    // -X -> -Y
    sort(newSort);
  }

  render() {
    const { currentOrder, options, sort, showTriangles = true, active_prefix, active_postfix, ...rest } = this.props;
    const { isOpen } = this.state;

    const activeOption = options.find(option => currentOrder.endsWith(option.ordering));

    return (
      <div {...rest} className={cs('sort-button', rest.className)} onBlur={e => this.toggleMenu(e, false)} tabIndex={0}>
        {
          showTriangles ?
            <div className='sort-button-order' role='button' onClick={this.toggleOrder}>
              <span className={cs('glyphicon glyphicon-triangle-bottom', { 'sort-button-order-active': !currentOrder.startsWith('-') })} />
              <span className={cs('glyphicon glyphicon-triangle-top', { 'sort-button-order-active': currentOrder.startsWith('-') })} />
            </div>
            : null
        }
        <div className='sort-button-active' role='button' onClick={this.toggleMenu}>
          {active_prefix}
          {activeOption.displayName}
          {activeOption.adjectives && activeOption.adjectives.length === 2 ? ` — ${activeOption.adjectives[currentOrder.startsWith('-') ? 1 : 0]}` : null}
          {active_postfix}
        </div>
        <div className={cs('sort-button-items', { 'sort-button-items-open': isOpen })}>
          {options.map((option, i) => <div key={i} role='button' onClick={e => this.onSelect(e, i)}>{option.displayName}</div>)}
        </div>
        <style jsx>{`
          .sort-button {
            display: block;
            position: relative;
            outline: 0;
            line-height: 1em;
            padding: 10px 15px;
          }

          .sort-button-active {
            display: inline-block;
            text-align: center;
            overflow: hidden;
          }

          .sort-button-order {
            float: right;
            margin-left: -1px;
            color: lightgray;
            padding-left: 15px;
          }
          .sort-button-order > .sort-button-order-active {
            color: black;
          }

          .sort-button-items {
            z-index: 1;
            display: none;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            border: solid 1px ${colors.BORDER_GRAY};
            background: ${colors.WHITE};
            border-top: 0;
            padding: 0;
          }
          .sort-button-items.sort-button-items-open {
            display: block;
          }
          .sort-button-items > div {
            padding: 0.5em;
          }
          .sort-button-items > div:hover {
            background: ${colors.BACKGROUND_GRAY};
          }
        `}</style>
      </div>
    );
  }
}

export default SortButton;
