/*global API */

export const getReceivable = funding_request_id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/${funding_request_id}/receivables/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status >= 300) {
    throw new Error('Error getting the receivable!');
  }

  const json = await res.json();
  return json;
};
