/**
 * Actions relating to the Geocode resource
 * @module actions/resource/geocode
 * @since 3.0.0
 * @requires datatypes/Resource
 */
/*global google */
import Resource from '../../datatypes/Resource';


/**
 * Singleton for our Google Geocoder
 */
const GEOCODER = new google.maps.Geocoder;

/**
 * The Resource that handles Geocode parsing and related actions
 * @extends module:datatypes/Resource~Resource
 */
class GeocodeResource extends Resource {
  constructor() {
    super('geocode');
  }

  id(id) {
    const { address, city, state } = id;
    return [address, city, state].filter(Boolean).join('|');
  }

  getItem(state, id) {
    return state.resource[this.name][this.id(id)];
  }

  fetch(id) {
    return (dispatch, getState) => {
      const location = id;
      const state = getState();
      const item = this.getItem(state, id);
      id = this.id(id);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.fetchRequest(id));

      return new Promise((resolve, reject) => {
        GEOCODER.geocode({
          address: [location.address, location.city, location.state].join(', '),
        }, (result, status) => {
          if (status === google.maps.GeocoderStatus.OK && result.length) {
            return resolve(dispatch(this.fetchRequestSuccess(id, result[0])));
          }
          reject(dispatch(this.fetchRequestFailure(id, status)));
        });
      });
    };
  }

  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return super.parse(oldValue, {
      ...json,
    });
  }
}

/**
 * Singleton for our GeocodeResource
 */
export const geocodeResource = new GeocodeResource();

export default geocodeResource;
export const {
  fetch,
  fetchIfNeeded,
  getItem,
} = geocodeResource;
