import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import PhoneInput from 'components/pure/form/inputs/PhoneInput';
import GoogleLocationInput from 'components/pure/form/inputs/GoogleLocationInput';
import AdminDropdownInput from 'components/pure/form/inputs/AdminDropdownInput';
import NumberInput from 'components/pure/form/inputs/NumberInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';

const FORM_NAME = 'FactoringDebtorForm';
const selector = formValueSelector(FORM_NAME);

const shouldAddressInputs = manually => [
  true,
].includes(manually);
export default compose(
  reduxForm({
    form: 'FactoringDebtorForm',
    enableReinitialize: true,
    initialValues: {
      address: {},
    },
  }),
  connect(
    (state => ({
      address: selector(state, 'address'),
      manually: selector(state, 'manually'),
    })
    ),
    (dispatch, { initialValues }) => {
      dispatch(change(FORM_NAME, 'street_one', initialValues.address.street_one));
      dispatch(change(FORM_NAME, 'street_two', initialValues.address.street_two));
      dispatch(change(FORM_NAME, 'city', initialValues.address.city));
      dispatch(change(FORM_NAME, 'state', initialValues.address.state));
      dispatch(change(FORM_NAME, 'zip', initialValues.address.zip));
    }
  ),
)(({ manually, handleSubmit, accessFields = {} }) =>
  <form onSubmit={handleSubmit} className='form-horizontal'>
    <Field name='created' component={LabeledInput} disabled={true} label='Created At' horizontal={true} />
    <Field name='admin' component={AdminDropdownInput} horizontal={true} disabled={accessFields.admin} />
    <Field name='email' component={LabeledInput} label='AP Email' disabled={accessFields.email} horizontal={true} />
    <Field name='payment_status_email' component={LabeledInput} label='Payment Status Email' disabled={accessFields.email} horizontal={true} />
    <Field name='contact_email' component={LabeledInput} label='Contact Email' disabled={true} horizontal={true} />
    <Field name='noa_contact_email' component={LabeledInput} label='NOA Contact Email' disabled={accessFields.noa_contact_email} horizontal={true} />
    <Field name='contact_name' component={LabeledInput} label='Contact Name' disabled={accessFields.contact_name} horizontal={true} />
    <Field name='dba' component={LabeledInput} label='Company' disabled={accessFields.dba} horizontal={true} />
    {
      shouldAddressInputs(!manually) ?
        <Field
          name='address'
          component={GoogleLocationInput}
          label='Address'
          setValueWithoutConfirming={true}
          specificity={GoogleLocationInput.specificity.ADDRESS}
          horizontal={true}
          disabled={accessFields.address}
        />
        : null
    }
    <Field name='manually' style={{ marginLeft: '10%' }} component={CheckboxInput} disabled={accessFields.address} label='Add Address Manually' />
    {
      shouldAddressInputs(manually) ?
        <div>
          <Field name='street_one' component={LabeledInput} placeholder='' label='Street 1' disabled={accessFields.address} horizontal={true} />
          <Field name='street_two' component={LabeledInput} placeholder='' label='Street 2' disabled={accessFields.address} horizontal={true} />
          <Field name='city' component={LabeledInput} placeholder='' label='City' disabled={accessFields.address} horizontal={true} />
          <Field name='state' component={LabeledInput} placeholder='' label='State' disabled={accessFields.address} horizontal={true} />
          <Field name='zip' component={LabeledInput} placeholder='' label='Zip' disabled={accessFields.address} horizontal={true} />
        </div>
        :
        null
    }
    <div className='row'>
      <div className='col-xs-2' style={{ textAlign: 'right' }}>
        <label className='control-label'>Phone</label>
      </div>
      <div className='col-xs-4'>
        <Field name='contact_phone' component={PhoneInput} disabled={accessFields.contact_phone} horizontal={true} />
      </div>
      <div className='col-xs-6'>
        <Field name='contact_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' disabled={accessFields.contact_phone} horizontal={true} />
      </div>
    </div>
    <div className='row'>
      <div className='col-xs-2' style={{ textAlign: 'right' }}>
        <label className='control-label'>Fax</label>
      </div>
      <div className='col-xs-4'>
        <Field name='fax_number' component={PhoneInput} disabled={accessFields.fax_number} horizontal={true} />
      </div>
      <div className='col-xs-6'>
        <Field name='fax_number_ext' component={LabeledInput} label='Fax Ext.' type='number' disabled={accessFields.fax_number} horizontal={true} />
      </div>
    </div>
    <Field name='mc' component={LabeledInput} ComponentClass={NumberInput} label='MC #' disabled={accessFields.mc} horizontal={true} />
    <Field name='dot' component={LabeledInput} ComponentClass={NumberInput} label='DOT #' disabled={accessFields.dot} horizontal={true} />
    <Field name='tax_id' component={LabeledInput} ComponentClass={NumberInput} label='Tax ID #' disabled={accessFields.tax_id} horizontal={true} />
    <div className='row'>
      <div className='col-xs-2' style={{ textAlign: 'right' }}>
        <label className='control-label'>Aladdin ID</label>
      </div>
      <div className='col-xs-4'>
        <Field name='aladdin_id' component={LabeledInput} type='text' disabled={accessFields.aladdin_id} horizontal={true} />
      </div>
      <div className='col-xs-6'>
        <Field name='discount_rate' component={LabeledInput} label='Discount Rate' type='number' disabled={accessFields.discount_rate} horizontal={true} />
      </div>
    </div>
    <Field name='avgpay' component={LabeledInput} type='number' label='Average Days To Pay' disabled={accessFields.avgpay} horizontal={true} />
    <Field
      name='credit_approved'
      component={LabeledDropdown}
      disabled={accessFields.credit_approved}
      data={[
        { text: 'Pending', value: 'pending' },
        { text: 'Approved', value: 'approved' },
        { text: 'Declined', value: 'declined' },
        { text: 'Needs Review', value: 'needs_review' },
      ]}
      label='Credit Approved Emblem'
      horizontal={true}
    />
    <Field
      name='factor_dba'
      disabled={accessFields.factor_dba}
      component={LabeledDropdown}
      data={[
        'ComFreight Financial',
        'Aladdin Financial - DBA ComFreight Financial',
      ]}
      label='NOA on Invoice'
      horizontal={true}
    />
    <Field
      name='dtp_allowance'
      disabled={accessFields.dtp_allowance}
      component={LabeledDropdown}
      data={[
        { text: '15 Days', value: '15' },
        { text: '30 Days', value: '30' },
        { text: '45 Days', value: '45' },
        { text: '60 Days', value: '60' },
      ]}
      label='DTP Terms'
      horizontal={true}
    />
    <Field name='credit_approved_note' component={LabeledInput} label='Credit Approval Notes' disabled={accessFields.credit_approved_note} horizontal={true} />
    <Field
      name='credit_limit'
      label='Credit Limit'
      disabled={accessFields.credit_limit}
      horizontal={true}
      component={PennyInput}
    />
    <Field
      name='credit_available'
      label='Available Credit'
      horizontal={true}
      disabled={true}
      component={PennyInput}
    />
    <Field
      name='originals_required'
      component={LabeledDropdown}
      disabled={accessFields.originals_required}
      label='Originals Required'
      data={[
        { text: 'No', value: 'false' },
        { text: 'Yes', value: 'true' },
      ]}
      horizontal={true}
    />
    <Field name='source' component={LabeledInput} label='Source' horizontal={true} disabled={true} />
    <Field
      name='requires_scanned_copy'
      component={CheckboxInput}
      label='Requires Scanned Copy'
    />
  </form>
);
