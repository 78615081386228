import { filter, flatMap, identity, join, split, uniq } from 'lodash';

export default str =>
  join(
    filter(
      uniq(
        flatMap(
          flatMap(
            [str], s => split(s, ' ')
          ), s => split(s, ',')
        )
      ), identity
    ), ','
  );
