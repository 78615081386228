/**
 * Connected component for confirming a deletion
 * Can specify an ID to delete or an array of arguments to pass to the delete function
 * @module components/pure/modals/ConfrimDelete
 * @since 3.0.0
 * @requires actions/ui
 * @requires actions/resource/factoringclientnote
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {string} id - the ID of the item to delete
 * @property {string} type - the type of the item to delete
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
/* global fbq process */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import { change, submit } from 'redux-form';
import mapProps from 'recompose/mapProps';

import formatPennies from 'helpers/formatPennies';

import { closeModal, openModal } from 'actions/ui';
import factoringclientnoteResource from 'actions/resource/factoringclientnote';
import factoringdebtornoteResource from 'actions/resource/factoringdebtornote';
import usernoteResource from 'actions/resource/usernote';
import factoringdebtorResource from 'actions/resource/factoringdebtor';
import factoringlineitemResource from 'actions/resource/factoringlineitem';
import { deleteAttachment, doDelete as deleteFactoringClient, deleteVoidedCheck } from 'actions/resource/factoringclient';
import factoringdebtorByfactoringclient, { factoringdebtorByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringdebtor';
import * as savedloadByUserResource from 'actions/resourceBy/user/savedload';
import * as savedtruckByUserResource from 'actions/resourceBy/user/savedtruck';
import { deleteFactoringPayment, deleteAttachment as deletePaymentAttachment } from 'actions/resource/factoringpayment';
import { edit as editLoad, refreshLoad } from 'actions/resource/load';
import { refreshTruck } from 'actions/resource/truck';
import { accept as acceptBid } from 'actions/resource/bid';
import { dispatchLoadCancel } from 'actions/admin/dispatch';
import { create as createOnboardDebtor } from 'actions/resourceBy/user/onboarddebtor';
import { doDelete as deleteDocument } from 'actions/settings/documents';
import { doDelete as deleteUser } from 'actions/resource/usersearch';
import ModalWarning from 'components/pure/Modals/Warning';
import PromiseButton from 'components/pure/PromiseButton';
import { doDelete as deleteFactoringPaymentNote } from 'actions/resourceBy/factoringpayment/factoringpaymentnote';
import factoringclientByfactoringdebtor from 'actions/resourceBy/factoringdebtor/factoringclient';
import { deletePaymentMethod } from 'actions/paymentmethods';
import { deleteLineItemCategory } from 'actions/lineitemcategories';
import { placeNOA } from '../../../actions/admin/factoring/noa';
import formatError from '../../../helpers/formatError';
import segmentEvents from '../../../helpers/segmentEvents';

const confirms = {
  factoringdebtor_delete: {
    fn: ({ id, cb }) => async dispatch => {
      await dispatch(factoringdebtorResource.doDelete(id));
      if (typeof cb === 'function') {
        return cb();
      }
    },
    message: () => 'Are you sure you want to delete this debtor?',
  },
  factoringdebtorbulk_delete: {
    fn: ({ fields }) => async dispatch => {
      for (const id in fields) {
        if (fields.hasOwnProperty(id)) {
          if (fields[id] === true) {
            await dispatch(factoringdebtorResource.doDelete(id));
          }
        }
      }
    },
    message: () => 'Are you sure you want to bulk delete all this debtor?',
  },
  factoringLineItem_delete: {
    fn: ({ funding_request_id, id, cb }) => async dispatch => {
      await dispatch(factoringlineitemResource.doDelete2(funding_request_id, id));
      if (typeof cb === 'function') {
        return cb();
      }
    },
    message: () => 'Are you sure you want to delete this Line Item?',
  },
  factoringdebtornote_delete: {
    fn: factoringdebtornoteResource.doDelete,
    message: () => 'Are you sure you want to delete this note?',
  },
  factoringdebtorclient_delete: {
    fn: factoringclientByfactoringdebtor.doDelete,
    message: () => 'Are you sure you want to disassociate this client from this debtor?',
    buttonProps: {
      children: 'Remove',
    },
  },
  factoringclientnote_delete: {
    fn: factoringclientnoteResource.doDelete,
    message: () => 'Are you sure you want to delete this note?',
  },
  usernote_delete: {
    fn: usernoteResource.doDelete,
    message: () => 'Are you sure you want to delete this note?',
  },
  factoringclient_delete: {
    fn: (...args) => async dispatch => {
      await dispatch(deleteFactoringClient(...args));
      browserHistory.replace('/admin/factoring/client/new');
    },
    message: () => 'Are you sure you want to delete this client?',
  },
  factoringclientattachment_delete: {
    fn: deleteAttachment,
    message: () => 'Are you sure you want to delete this attachment?',
  },
  factoringclientvoidedcheck_delete: {
    fn: deleteVoidedCheck,
    message: () => 'Are you sure you want to delete this voided check?',
  },
  factoringclientdebtor_delete: {
    fn: factoringdebtorByfactoringclient.doDelete,
    message: () => 'Are you sure you want to disassociate this debtor from this client?',
    buttonProps: {
      children: 'Remove',
    },
  },
  factoringclientdebtor_add: {
    fn: (...args) => async dispatch => {
      await dispatch(factoringdebtorByfactoringclient.create(...args));
      dispatch(openModal('success', { message: 'Successfully associated debtor' }));
      dispatch(submit('DebtorSearch'));
    },
    message: () => 'Are you sure you want to associate this debtor with this client?',
    message_error: () => 'Failed to associate debtor',
    elementProps: {
      headerStyle: {
        backgroundColor: '#5cb85c',
        color: 'white',
      },
    },
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Associate',
    },
  },
  factoringclientdebtor_delete_user: {
    fn: factoringdebtorByfactoringclientUSER.doDelete,
    message: () => 'Are you sure you want to remove this customer?',
  },
  form_change_field: {
    fn: change,
    message: (args, { message }) => message || 'Are you sure you want to reset this field?',
  },
  savedload_delete: {
    fn: savedloadByUserResource.doDelete,
    message: () => 'Are you sure you want to remove this load from saved?',
  },
  savedtruck_delete: {
    fn: savedtruckByUserResource.doDelete,
    message: () => 'Are you sure you want to remove this truck from saved?',
  },
  factoringpayment_delete: {
    fn: deleteFactoringPayment,
    message: () => 'Are you sure you want to delete this payment?',
  },
  factoringpaymentattachment_delete: {
    fn: deletePaymentAttachment,
    message: () => 'Are you sure you want to delete this attachment?',
  },
  factoringpaymentnote_delete: {
    fn: deleteFactoringPaymentNote,
    message: () => 'Are you sure you want to delete this note?',
  },
  refresh_post: {
    fn: ({ id, type }) => async dispatch => {
      const refreshFn = {
        load: refreshLoad,
        truck: refreshTruck,
      }[type];
      const refreshAgeType = {
        load: segmentEvents.USER_REFRESHED_LOAD_AGE,
        truck: segmentEvents.USER_REFRESHED_TRUCK_AGE
      }[type]
      try {
        await dispatch(refreshFn(id));
        window.analytics.track(refreshAgeType, { [`${type}_id`]: id });
        dispatch(openModal('success', { message: 'Successfully updated post' }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to update post :(' }));
      }
    },
    message: () => 'Are you sure you want to refresh the age of this post to show up newer to other users?',
    elementProps: {
      headerChildren: 'Refresh Age of Post',
    },
    buttonProps: {
      children: 'Refresh',
    },
  },
  load_status_update: {
    fn: (id, { value }) => async dispatch => {
      try {
        await dispatch(editLoad(id, { status: value }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'You do not have permission to change the status of this load at this time.' }));
      }
    },
    message: ([id, { text }]) => `Are you sure you want to update this load's status to "${text}"?`,
    buttonProps: {
      children: 'Confirm',
    },
  },
  accept_bid: {
    fn: acceptBid,
    message: () => 'Are you sure you want to accept this bid?',
    buttonProps: {
      children: 'Accept',
    },
  },
  dispatchloadcancel: {
    fn: ({ load_id, user_id }) => async dispatch => {
      try {
        await dispatch(dispatchLoadCancel(load_id, user_id));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to cancel dispatch.' }));
      }
    },
    message: () => 'Are you sure you want to cancel this dispatch?',
    elementProps: {
      buttonChildren: 'Nevermind',
    },
    buttonProps: {
      children: 'Cancel',
    },
  },
  dispatchonboarding: {
    fn: ({ user_id, company_name, amount }) => async dispatch => {
      try {
        await dispatch(createOnboardDebtor(user_id, {
          user: user_id,
          company_name,
          amount,
        }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to onboard debtor.' }));
      }
    },
    message: ([{ company_name, amount }]) => `Are you sure you want to onboard debtor "${company_name}" for ${formatPennies(amount)}?`,
    buttonProps: {
      children: 'Onboard',
    },
  },
  document_delete: {
    fn: deleteDocument,
    message: ([id, label]) => `Are you sure you want to delete this document: "${label}"?`,
  },
  user_delete: {
    fn: id => async dispatch => {
      dispatch(deleteUser(id));
      dispatch(openModal('success', { message: 'User deleted.' }));
      browserHistory.replace('/admin/user/search');
    },
    message: ([id]) => `Are you sure you want to delete this user (${id})`,
  },
  lineitemcategory_delete: {
    fn: id => async dispatch => {
      dispatch(deleteLineItemCategory(id));
      dispatch(openModal('success', { message: 'Line Item Category deleted.' }));
    },
    message: () => 'Are you sure you want to delete this line item category?',
  },
  paymentmethods_delete: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
      dispatch(openModal('paymentinformation', { item }));
    },
    onExit: ({ onExit }) => async dispatch => {
      await onExit();
    },
    message: () => 'Are you sure you want to remove payment?',
  },
  userfactoringprofile_save: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'Are you sure you want to save?\n\nPlease note that this will save any edited field throughout the factoring profile.\n',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Save',
    },
  },
  confirm_check_email: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'Are you sure you want to invite this carrier?\n\nThey are connected to you in My Carriers.\n',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Continue',
    },
  },
  inviteduser_delete: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
      dispatch(openModal('success', { message: 'Invited user deleted.' }));
    },
    message: () => 'Are you sure you want to delete this invited user.',
  },
  sendInvoice: {
    fn: ({ onConfirm }) => async dispatch => {
      try {
        const item = await onConfirm();
        return item ? dispatch(openModal('success', { message: 'Invoice sent to customer/debtor.' })) : "";
      }
      catch (e) {
        throw e;
      }

    },
    message: () => 'Please make sure that any attached documents that are required for invoicing have completed processing for upload before confirming to send.',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Save',
    },
  },
  userfactoringprofileincomplete_save: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'Are you sure you want to save?\n\nPlease note this will create a incomplete request, we will not process this until you complete it.\n',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Save',
    },
  },
  generateNewToken_save: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'Changing your token will invalidate your current token, any action made in your TMS will not be received by comfreight until the token in your TMS is updated.',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Generate Token',
    },
  },
  placedNOA_confirm: {
    fn: (...args) => async dispatch => {
      try {
        await dispatch(factoringdebtorByfactoringclientUSER.create(args[0].client_id, {
          ...args[0],
          auto_send_noa: true,
        }));
        window.analytics.track(segmentEvents.USER_SENT_NOA);
        dispatch(openModal('success', { message: 'NOA has been sent.' }));
        setTimeout(() => window.location.reload(), 1000);
      }
      catch (e) {
        dispatch(openModal('error', { message: formatError(e, 'Error while sending NOA.') }));
      }
      finally {
        if (args[0].fetch) {
          args[0].fetch();
        }
      }
    },
    message: () => 'Would you like to place a Notice of Assignment to enable payment financing for this debtor/customer?',
    buttonProps: {
      children: 'Yes',
    },
    elementProps: {
      buttonChildren: 'No',
    },
  },
  stripeBankAccount_confirm: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'By clicking “Confirm”, you authorize HaulPay to debit the bank account specified above for any amount owed for charges arising from your use of HaulPay’s services and/or purchase of products from HaulPay, pursuant to HaulPay’s website and terms, until this authorization is revoked. You may amend or cancel this authorization at any time by providing notice to HaulPay with 30 (thirty) days notice.'
    + '\n\n If you use HaulPay’s services or purchase additional products periodically pursuant to HaulPay’s terms, you authorize HaulPay to debit your bank account periodically. Payments that fall outside of the regular debits authorized above will only be debited after your authorization is obtained.',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Confirm',
    },
  },
  userStripeCard_apply: {
    fn: ({ onConfirm }) => async dispatch => {
      const item = await onConfirm();
    },
    message: () => 'Would you like to apply for a HaulPay credit card?',
    buttonProps: {
      className: 'btn btn-orange',
      children: 'Confirm',
    },
  },
};

const Confirm = compose(
  mapProps(({ id, args, ...rest }) => {
    if (!args) {
      args = [id];
    }
    return {
      args,
      ...rest,
    };
  }),
  connect(
    null,
    (dispatch, { type, args }) => ({
      closeModal: async () => dispatch(closeModal()),
      async fn() {
        const action = confirms[type];
        try {
          await dispatch(action.fn(...args));
          dispatch(closeModal());
        }
        catch (err) {
          console.warn(err);
          let message = 'Couldn\'t do that right now :( Try again later.';
          if (action.message_error) {
            message = action.message_error(...args);
          }
          dispatch(openModal('error', { message }));
          dispatch(closeModal());
        }
      },
    }),
  ),
)(({ fn, callback, type, args, ...rest }) => {
  const {
    message = () => 'Are you sure you want to delete this?',
    Element = ModalWarning,
    elementProps = {},
    buttonProps = {},
  } = confirms[type];

  return (
    <Element
      {...elementProps}
      headerChildren={elementProps.headerChildren || 'Confirm'}
      buttonChildren={elementProps.buttonChildren || 'Cancel'}
      message={message(args, rest)}
      extraButtons={[
        React.createElement(PromiseButton, {
          className: 'btn btn-danger',
          children: 'Delete',
          ...buttonProps,
          onClick: () => {
            fn().then(() => {
              if (callback) {
                return callback();
              }
            });
          },
        }),
      ]}
    />
  );
});

Confirm.propTypes = {
  id: PropTypes.string,
  args: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.oneOf(Object.keys(confirms)),
  callback: PropTypes.func,
  onExit: PropTypes.func,
};

export default Confirm;
