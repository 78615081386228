/* global API */
import querystring from 'querystring';

export const getInvoice = (debtor, frid) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/debtor/${debtor}/funding_requests/open/${frid}/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status >= 300) {
    throw new Error('Error getting the invoice!');
  }

  const json = await res.json();
  return json;
};

export const generateReport = ({
  debtor_id,
  ...props
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/debtor/${debtor_id}/generate_report/?${querystring.stringify({
    ...props,
  })}`, {
    method: 'GET',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
  });
  const json = await res.json();
  return json;
};
