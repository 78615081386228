/* global MOBILE_BREAKPOINT */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import DateTimePicker from 'react-widgets/lib/DateTimePicker';
import RootCloseWrapper from 'react-overlays/lib/RootCloseWrapper';
import moment from 'moment';
import cs from 'classnames';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';
import noop from 'lodash/noop';

import MaterialIcon from 'components/pure/MaterialIcon';

const OPEN_STATE = {
  CALENDAR: 'calendar',
  TIME: 'time',
  CLOSED: false,
};

const DateTimeInput = compose(
  mapProps(({ format, ...rest }) => {
    if (!format) {
      if (rest.pickerOptions && rest.pickerOptions.time === false) {
        format = 'l';
      }
      else {
        format = 'l LT';
      }
    }
    if (moment.isMoment(rest.input.value) && rest.input.value.isSame(moment(), 'day')) {
      format += ' [(Today)]';
    }
    return {
      ...rest,
      format,
    };
  }),
  withState('isEditing', 'setIsEditing', false),
  withState('open', 'setOpen', false),
  withState('value', 'setValue', '')
)(
  ({
    input,
    meta,
    format,
    label,
    isEditing,
    setIsEditing,
    value,
    setValue,
    open,
    setOpen,
    pickerOptions = {},
    labelProps = {},
    inputProps = {},
    position,
  }) => {
    return (
      <RootCloseWrapper onRootClose={() => setOpen(OPEN_STATE.CLOSED)}>
        <div
          className={cs('form-group', {
            'has-error': meta.error && meta.touched,
            'has-warning': meta.warning,
          })}
          >
          {label ? (
            <label {...labelProps} className={cs('control-label', labelProps.className)}>
              {label}
            </label>
          ) : null}
          <div className='input-group'>
            <div className='date-time-input-input-container clearfix'>
              <input
                {...inputProps}
                className={cs('form-control', inputProps.className)}
                onFocus={e => {
                  const value = moment(e.target.value, format);
                  if (value.isValid()) {
                    setValue(value.format(format));
                  }
                  setIsEditing(true);
                  input.onFocus(value);
                }}
                onBlur={e => {
                  const time = moment(e.target.value, format);
                  setIsEditing(false);
                  if (time.isValid()) {
                    input.onBlur(time);
                  }
                }}
                onChange={e => {
                  setValue(e.target.value);
                }}
                value={isEditing ? value : moment.isMoment(input.value) ? input.value.format(format) : ''}
              />
              <div
                className='date-time-input-input-overlay'
                onClick={() => setOpen(open !== OPEN_STATE.CALENDAR ? OPEN_STATE.CALENDAR : OPEN_STATE.CLOSED)}
              />
            </div>
            <span className='input-group-btn'>
              <button
                style={{ marginTop: 0 }}
                className='btn'
                type='button'
                disabled={pickerOptions.calendar === false}
                onClick={() => setOpen(open !== OPEN_STATE.CALENDAR ? OPEN_STATE.CALENDAR : OPEN_STATE.CLOSED)}
                >
                <MaterialIcon name='date_range' />
              </button>
              {pickerOptions.time === false ? null : (
                <button className='btn' type='button' onClick={() => setOpen(open !== OPEN_STATE.TIME ? OPEN_STATE.TIME : OPEN_STATE.CLOSED)}>
                  <MaterialIcon name='access_time' />
                </button>
              )}
            </span>
          </div>
          <DateTimePicker
            {...pickerOptions}
            open={open}
            onToggle={noop}
            duration={0}
            value={moment.isMoment(input.value) ? input.value.toDate() : new Date()}
            onChange={date => {
              input.onChange(moment(date));
            }}
            onSelect={date => {
              input.onChange(moment(date));
              setOpen(OPEN_STATE.CLOSED);
            }}
          />
          {meta.error && meta.touched ? (
            <div className='help-block'>{meta.error}</div>
          ) : meta.warning ? (
            <div className='help-block'>{meta.warning}</div>
          ) : null}
          <style jsx>{`
          .date-time-input-input-container {
            position: relative;
          }
          .date-time-input-input-overlay {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: 10;
          }
          @media (min-width: ${MOBILE_BREAKPOINT}px) {
            .date-time-input-input-overlay {
              display: none;
            }
          }
        `}</style>
        </div>
      </RootCloseWrapper>
    );
  }
);

DateTimeInput.propTypes = {
  format: PropTypes.string,
  label: PropTypes.string,
  pickerOptions: PropTypes.object,
  position: PropTypes.string,
};

export default DateTimeInput;
