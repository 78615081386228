import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { formValueSelector, submit } from 'redux-form';

import { USER_TYPE } from 'helpers';
import * as loadSearchActions from 'actions/search/load';

import setLastTool from 'components/hoc/setLastTool';
import docTitle from 'components/hoc/docTitle';
import Search from 'components/pure/Search';
import LoadSearchForm, { FORM_NAME } from 'components/pure/form/LoadSearchForm';
import LoadSearchMobile from 'components/container/LoadSearchMobile';
import LoadSearchTable from 'components/pure/LoadSearchTable';
import LoadSearchMap from 'components/container/LoadSearchMap';
import SearchResults from 'components/pure/SearchResults';


const LoadSearchResults = compose(
  connect(
    state => ({
      ids: state.search.load.ids,
      isFetching: state.search.load.isFetching,
      ordering: state.search.load.ordering,
      view: state.search.load.globals.view,
    }),
    {
      fetchNext: loadSearchActions.fetchNext,
      sort: loadSearchActions.sort,
      setView: loadSearchActions.setView,
      refresh: () => submit(FORM_NAME),
    },
  ),
)(props =>
  <SearchResults
    {...props}
    MobileComponent={LoadSearchMobile}
    DesktopComponent={LoadSearchTable}
    GoogleMap={LoadSearchMap}
    breakpoint={830}
    orderingOptions={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerType',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Deadhead',
        ordering: 'deadhead',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
      {
        displayName: 'Target Rate',
        ordering: 'targetRate',
        adjectives: ['Min', 'Max'],
      },
    ]}
  />
);

export default compose(
  docTitle('Load Search'),
  setLastTool(props => props.location.pathname),
  connect(
    state => {
      return {
        loadType: formValueSelector(FORM_NAME)(state, 'loadType'),
      };
    },
    {
      swapOriginAndDestination: loadSearchActions.swapOriginAndDestination,
      refresh: () => submit(FORM_NAME),
    },
    ({ loadType }, { swapOriginAndDestination, refresh }, own) => {
      return {
        swapOriginAndDestination: loadType === USER_TYPE.SHIPPER ? null : swapOriginAndDestination,
        refresh,
        ...own,
      };
    },
  ),
)(({ swapOriginAndDestination, refresh }) =>
  <Search
    mountPoint={['search', 'load']}
    resource='load'
    Form={LoadSearchForm}
    SearchResults={LoadSearchResults}
    basePath='/loadsearch'
    swapOriginAndDestination={swapOriginAndDestination}
    refresh={refresh}
  />
);
