/**
 * Component for displaying a multiselect input with all the accepted ComFreight states
 * @module components/pure/form/inputs/StateInput
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/form/inputs/AbbreviatedMultiSelect
 * @property {object} props
 * @property {object} props.input - input callbacks provided by redux-form
 * @property {object} props.meta - meta information provided by redux-form
 * @property {node|node[]} [props.label] - a label to display above the input, if any
 * @property {any} [props....rest] - props passed to the AbbreviatedMultiSelect component
 */
/* global MOBILE_BREAKPOINT */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import cs from 'classnames';
import withState from 'recompose/withState';

import states from 'helpers/states';

import responsiveWindow from 'components/hoc/responsiveWindow';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import AbbreviatedMultiSelect from 'components/pure/form/inputs/AbbreviatedMultiSelect';


// transform the states into a form that the AbbreviatedMultiSelect will use
const data = [];
for (const code in states) {
  data.push({
    code,
    fullName: states[code],
  });
}

export const StateInput = props =>
  <LabeledDropdown
    {...props}
    filter={(() => {
      if (props.filter && props.filter === 'startsWith') {
        return (item, val) => {
          val = val.toLowerCase();
          return item.code.toLowerCase().startsWith(val) || item.fullName.startsWith(val);
        };
      }
      return props.filter;
    })()}
    data={data}
    textField='fullName'
    valueField='code'
  />
  ;

export const MultiStateInput = compose(
  withState('searchTerm', 'setSearchTerm', ''),
  responsiveWindow(),
)(({ input, placeholder = 'Any', label, searchTerm, setSearchTerm, isMobile, disabled }) =>
  <div
    className={cs({
      'form-group': true,
      'has-error': (!input.value || !input.value.length) && searchTerm.length,
    })}
    >
    {label && <label className='control-label'>{label}</label>}
    <div className='multi-trailer-input-container'>
      {
        !input.value || !input.value.length ?
          <div className='multi-trailer-input-placeholder'>{placeholder}</div>
          : null
      }
      <AbbreviatedMultiSelect
        disabled={disabled}
        searchTerm={searchTerm}
        onSearch={setSearchTerm}
        data={data}
        placeholder={placeholder}
        abbreviationKey='code'
        fulltextKey='fullName'
        breakPoint={2}
        onChange={input.onChange}
        onFocus={input.onFocus}
        value={input.value}
        inputProps={isMobile ? {
          type: 'button',
        } : undefined}
      />
    </div>
    {(!input.value || !input.value.length) && searchTerm.length ? <div className='help-block'>Please select type(s) from list</div> : null}
    <style jsx>{`
      .multi-trailer-input-container {
        position: relative;
      }
      .multi-trailer-input-placeholder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        color: #555;
        height: 2.286em;
        padding: .429em .857em;
      }
      @media (min-width: ${MOBILE_BREAKPOINT}px) {
        .multi-trailer-input-placeholder {
          display: none;
        }
      }
    `}</style>
  </div>
);

// export the states as a static
MultiStateInput.states = states;
// export the transformed states data as a static
MultiStateInput.data = data;

MultiStateInput.propTypes = {
  placeholder: PropTypes.string,
  label: PropTypes.node,
};

export default MultiStateInput;
