import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import factoringdebtor from 'actions/resource/factoringdebtor';


export const similarfactoringdebtorByFactoringdebtorResource = new PaginatedResourceCollection('similarfactoringdebtor', 'factoringdebtor', {
  url: new UrlAssembler('debtor/:id/related/suggestions'),
  paginatedResourceOptions: {
    baseResource: factoringdebtor,
  },
});

export default similarfactoringdebtorByFactoringdebtorResource;
export const {
  fetch,
  fetchNext,
} = similarfactoringdebtorByFactoringdebtorResource;
