import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import validate from 'validate.js';
import lifecycle from 'recompose/lifecycle';
import { flatten, get, isFinite } from 'lodash';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import { Col, Grid, Row } from 'react-bootstrap';

import { getFactoringCarrier } from 'actions/factoring';
import { USER_TYPE } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import validateAddress from 'helpers/validateAddress';
import { openModal } from 'actions/ui';
import { create } from 'actions/resource/factoringpayment';
import SubmissionError from 'datatypes/error/SubmissionError';

import nothingIf from 'components/hoc/nothingIf';
import Header from 'components/pure/Header';
import ProTip from 'components/container/ProTip';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import DebtorAutocompleteInput from 'components/pure/form/inputs/DebtorAutocompleteInput';
import CarrierNameAutocompleteInput from 'components/pure/form/inputs/CarrierNameAutocompleteInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import Spinner from 'components/pure/Spinner';
import formatError from 'helpers/formatError';
import RangeSlider from 'components/pure/form/inputs/RangeSlider';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import convertToString from 'helpers/convertAttachmentCategoriesToString';
import getSelf from 'selectors/getSelf';

const roundPercent = num => (Math.round(num * 100) / 100);

const GetPaidHeader = () => (
  <Header
    style={{
      display: 'inline-block',
      marginTop: 0,
    }}
    >
    Get Paid
    <span style={{ fontSize: '0.7em' }}> Upload Documents</span>
  </Header>
);

const Attachments = () => (
  <Field
    name='attachments'
    component={MultiImageInput}
    limit={25}
    buttonText='Upload Documents'
    buttonClassName='btn btn-default'
    confirmBeforeDeleting={true}
    shouldCrop={true}
    convertAnyway={true}
    multiple={true}
    categories={[
      'Signed Bill of Lading (Delivered)',
      'Signed Bill of Lading (Pick-Up)',
      'Rate Confirmation (Carrier)',
      'Rate Confirmation (Shipper)',
      'Truck Order Not Used',
      'Invoice',
      'Freight Bill',
      'Lumper Receipt',
      'Detention Receipt',
      'Late Fee Receipt',
      'Other',
    ]}
  />
);

const ShipperInvoiceAmount = () => (
  <Field name='shipper_invoice_amount' component={PennyInput} label='Shipper Invoice Amount' />
);

const LoadNumber = () => (
  <Field name='user_load_number' component={LabeledInput} label='Load Number' />
);

const AddDetails = ({ openMoreDetailsModal }) => (
  <div>
    <a role='button' onClick={openMoreDetailsModal}>Add More Details Here</a>
  </div>
);

const HelpButton = ({ openHelpModal }) => (
  <button className='btn btn-default' type='button' onClick={openHelpModal}>Help</button>
);

const RateSlider = ({ setCarrierRate, carrierRate, brokerRate, maxCarrierRate }) => (
  <div>
    <Row>
      <Col md={6}>
        <div
          className='input-group'
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          >
          Carrier Rate (%):
          <Field
            component={LabeledInput}
            style={{
              maxWidth: '60px',
            }}
            input={{
              value: carrierRate,
              onChange: e => {
                let value = parseFloat(e.target.value).toFixed(2);
                if (value < 0) {
                  value = 0;
                }
                else if (value >= maxCarrierRate) {
                  value = parseFloat(maxCarrierRate).toFixed(2);
                }
                setCarrierRate(parseFloat(value));
              },
            }}
            type='number'
            className='form-control'
          />
        </div>
      </Col>
      <Col md={6}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '34px',
          }}
          >
          <span>Your Rate: {brokerRate}%</span>
        </div>
      </Col>
    </Row>
    <Field
      name='carrier_rate'
      component={RangeSlider}
      min={0}
      max={parseFloat(maxCarrierRate).toFixed(2)}
      step='0.01'
    />
  </div>
);

const CarrierInvoiceAmount = () => (
  <Field name='carrier_pay' component={PennyInput} label='Carrier Invoice Amount' />
);

const BillToCompany = ({
  openAddCustomerModal,
  set_require_debtor_address,
  factoring,
  setBillToCompanyId,
  setBillToCompanyAddress,
  setDiscountRate,
}) => (
  <Field
    name='bill_to_company'
    component={DebtorAutocompleteInput}
    client_id={factoring}
    labelProps={{
      style: { display: 'block' },
    }}
    label={
      <div>
        Shipper
        <button
          className='btn-payment pull-right'
          type='button'
          onClick={openAddCustomerModal}
          style={{
            padding: '0 12px',
            marginBottom: 3,
            height: '20px',
          }}
          >
          Add New Cust.
        </button>
      </div>
    }
    onSelect={val => {
      setDiscountRate(val.discount_rate);
      setBillToCompanyId(val.id);
      setBillToCompanyAddress(val.address);


      if (!val.address || !val.address.street_one) {
        set_require_debtor_address(true);
      }
      else {
        set_require_debtor_address(false);
      }
    }}
  />
);

const CarrierName = ({ onSelect }) => (
  <Field
    name='carrier_name'
    component={CarrierNameAutocompleteInput}
    labelProps={{
      style: { display: 'block' },
    }}
    label={
      <div>
        Carrier Name
      </div>
    }
    onSelect={onSelect}
  />
);

const PaymentMethod = ({ aladdinOwned, accounts }) => {
  const data = [];

  if (aladdinOwned) {
    data.push({
      text: 'ACH',
      fee: '',
      value: 'ach',
    });
  }
  else {
    (accounts || []).map(account => {
      switch (account.method) {
        case 'ACH':
          data.push({
            text: 'ACH',
            fee: '',
            value: 'ach',
          });
          break;
        case 'DEBIT':
          data.push({
            text: 'Same Day',
            fee: '($5)',
            value: 'debit',
          });
          break;
      }
    });
  }

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}<span className='text-danger'>{fee}</span></div>
  );

  return (
    <Field
      name='payment_method'
      component={LabeledDropdown}
      label='Payment Method'
      data={data}
      itemComponent={ListItem}
    />
  );
};

const PayRates = ({ carrierRate, brokerRate, brokerCut, carrierCut, carrierPay, brokerPay }) => (
  <Row>
    <Col md={6}>
      <span>Carrier Rate: {carrierRate}%</span>
      <br />
      <span>Your Rate: {brokerRate}%</span>
    </Col>
    <Col md={6}>
      <span>
        <u>Total Carrier Pay:</u>
        <span className='text-success'>
          <strong>
            {carrierPay}
          </strong>
        </span>
      </span>
      <br />
      <span>
        <u>Your Pay:</u>
        <span className='text-success'>
          <strong>
            {brokerPay}
          </strong>
        </span>
      </span>
    </Col>
  </Row>
);

const SubmitButton = ({ submitting }) => (
  <button className='btn btn-orange pull-right' type='submit' disabled={submitting}>
    {
      submitting ?
        <span>
          <Spinner />
              PROCESSING
        </span>
        :
        'Submit'
    }
  </button>
);

const containsAll = (values, required) =>
  required
    .map(value => values.includes(value))
    .filter(valid => !valid).length === 0;

const FORM_NAME = 'FactoringRequestPaymentForm';
const selector = formValueSelector(FORM_NAME);
export default compose(
  connect(
    state => {
      const amount = selector(state, 'shipper_invoice_amount');
      const carrierInvoiceAmount = selector(state, 'carrier_pay');
      const carrierRate = selector(state, 'carrier_rate');

      let transferFee = 0;
      if (selector(state, 'payment_method') === 'debit') {
        transferFee = 500;
      }

      const discountRate = selector(state, 'discount_rate');
      const minimumFee = selector(state, 'minimum_fee');

      let maxCarrierRate, brokerRate, carrierPay, brokerPay, carrierCut, brokerCut;

      if (amount) {
        let fee = amount * (discountRate / 100);
        if (fee < minimumFee) {
          fee = minimumFee;
        }
        maxCarrierRate = (fee / carrierInvoiceAmount) * 100;

        carrierCut = carrierInvoiceAmount * (carrierRate / 100);
        carrierPay = carrierInvoiceAmount - carrierCut;

        brokerRate = roundPercent(discountRate - (((carrierInvoiceAmount - carrierPay) * 100) / amount));
        brokerCut = amount * (brokerRate / 100);
        brokerPay = (amount - carrierInvoiceAmount) - brokerCut;
        brokerPay -= transferFee;
      }
      return {
        discountRate,
        shipperAmount: amount,
        carrierRate: selector(state, 'carrier_rate'),
        carrierPay,
        carrierCut,
        carrierInvoiceAmount,
        brokerRate,
        brokerPay,
        brokerCut,
        maxCarrierRate: carrierInvoiceAmount ? maxCarrierRate : 0,
        considering_creating_invoice: selector(state, 'considering_creating_invoice'),
        factoring_id: selector(state, 'factoring_id'),
        debitAvailable: selector(state, 'debit_available'),
        self: getSelf(state),
        paymentMethod: selector(state, 'payment_method'),
      };
    },
    (dispatch, { factoring_id }) => ({
      openMoreDetailsModal() {
        dispatch(change(FORM_NAME, 'considering_creating_invoice', true));
        dispatch(openModal('factoringpaymentrequestmoredetails'));
      },
      openAddCustomerModal: () => dispatch(openModal('factoringclientadddebtor', {
        factoring: factoring_id,
        shouldUpdateFactoringRequestPaymentForm: true,
        parent: FORM_NAME,
      })),
      openHelpModal: () => dispatch(openModal('requestpaymentformhelp')),
      setForm: name => value => dispatch(change(FORM_NAME, name, value)),
      getCarrier: id => dispatch(getFactoringCarrier(id)),
    }),
  ),
  nothingIf(({ considering_creating_invoice }) => considering_creating_invoice),
  mapProps(({ self, initialValues, ...rest }) => ({
    ...rest,
    self,
    initialValues: {
      ...initialValues,
      attachments: [],
      payment_method: 'ach',
    },
    user_type: self.data.type,
  })),
  withState('require_debtor_address', 'set_require_debtor_address', false),
  withState('accounts', 'setAccounts', []),
  withState('aladdinOwned', 'setAladdinOwned', true),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, { carrierPay, user_type, initialValues, initialize, reset, set_require_debtor_address }) {
      const data = {
        amount: fields.shipper_invoice_amount,
        debtor: fields.bill_to_company_id,
        bill_to_company_phone: fields.bill_to_company_phone,
        bill_to_company_email: fields.bill_to_company_email,
        bill_to_address: typeof fields.bill_to_company_address === 'string' ? { street_one: fields.bill_to_company_address } : {
          street_one: fields.bill_to_company_address.street_one,
          city: fields.bill_to_company_address.city,
          state: fields.bill_to_company_address.state,
          zip: fields.bill_to_company_address.zip,
          country: fields.bill_to_company_address.country,
        },
        carrier_amount: carrierPay,
        attachments: convertToString(fields.attachments),
        user_load_number: fields.user_load_number,
        load: fields.source_load,
        user_notes: fields.user_notes,
        user_reference_number: fields.user_reference_number,
        payment_method: fields.payment_method,
      };
      // add "factoring" (representing the the factoring client ID) into the request payload if it exists as a field
      if (fields.factoring_id) {
        data.factoring = fields.factoring_id;
      }
      try {
        //Add Bank/Card info modal on Client side on Funding request.
        // await dispatch(openModal('paymentinformation', { fields }));
        await dispatch(create(data));
        if (!USER_TYPE.IS_ADMIN(user_type) || !USER_TYPE.IS_ALADDIN_ADMIN(user_type)) {
          if (fields.bill_to_company.credit_approved !== 'approved') {
            dispatch(openModal('warning', { message: 'This customer is not yet approved for credit terms. We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
          }
          if (fields.bill_to_company.originals_required) {
            dispatch(openModal('warning', {
              message: (
                <div>
                  <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please contact your broker directly to get the correct address to mail your original documents to.</p>
                  {/*<p>*/}
                  {/*  ComFreight Financial<br />*/}
                  {/*  1201 W. Russel St.,<br />*/}
                  {/*  Sioux Falls, SD 57104*/}
                  {/*</p>*/}
                  <p>
                    Provide tracking information for your mailed originals to
                    <a href='mailto:invoice@haulpay.io' className='space'>invoice@haulpay.io</a>
                    so that we can process your transaction as quickly as possible, by knowing when your documents will arrive.
                  </p>
                  <p>If you have more questions please call us <a href='tel:888-633-5558'>888-633-5558</a>.</p>
                  Thank you.
                </div>
              ),
            }));
          }
        }
        dispatch(openModal('success', { message: 'Successfully made payment request.' }));
        initialize({
          ...initialValues,
          considering_creating_invoice: false,
          should_create_invoice: false,
          attachments: [],
          bill_to_address: {},
          first_origin_location: {},
          final_destination_location: {},
          load_length: fields.load_length,
          load_trailer_type: fields.load_trailer_type,
        });
        reset();
        set_require_debtor_address(false);
      }
      catch (err) {
        if (err instanceof SubmissionError) {
          if (/You have already submitted this load number./.test(formatError(err))) {
            return dispatch(openModal('warning', {
              message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
            }));
          }
          if (/Debtor has been declined./.get(err, ['message', 'non_field_errors', '0'])) {
            return dispatch(openModal('error', {
              headerChildren: 'Customer Declined on Credit',
              message: 'Credit for this customer has recently been declined. We will check this customer\'s credit again in regular intervals but a payment request cannot be made for this customer at this time. Please email invoice@haulpay.io if you have more questions. Thank you.',
            }));
          }
        }
        dispatch(openModal('error', { message: 'Failed to send payment request.' }));
      }
    },
    shouldValidate(params) {
      return true;
    },
    validate(fields, { self, require_debtor_address }) {
      const errs = validate(fields, {
        attachments: {
          containsKey: {
            required: 'category',
            message: 'are required to have a category',
          },
          presence: {
            message: 'Please Upload BOL (Delivered), Shipper Rate Confirmation and Carrier Rate Confirmation',
          },
          length: {
            minimum: 1,
            tooShort: '^Please Upload BOL (Delivered), Shipper Rate Confirmation and Carrier Rate Confirmation',
          },
        },
        shipper_invoice_amount: {
          presence: true,
        },
        load_number: {
          presence: true,
        },
        carrier_pay: {
          presence: true,
        },
        ...(() => require_debtor_address ? {
          bill_to_company: {
            multipresence: {
              required: ['street_one'],
              message: '^Please enter an address.',
            },
          },
        } : {})(),
        ...(() => require_debtor_address ? {
          carrier_name: {
            multipresence: {
              required: ['street_one'],
              message: '^Please enter an address.',
            },
          },
        } : {})(),
        payment_method: {
          presence: true,
        },
      });
      ['first_pickup', 'final_delivery'].forEach(key => {
        const err = validateAddress(fields[key], 'Please enter address or city, state');
        if (err) {
          errs[key] = err;
        }
      });
      if (fields.carrier_pay > fields.shipper_invoice_amount) {
        errs.carrier_pay = 'Carrier Invoice Amount cannot be more than the Shipper Invoice Amount';
      }

      const categories = flatten(fields.attachments.map(file => file.category));
      if (!containsAll(categories, ['Rate Confirmation (Shipper)', 'Rate Confirmation (Carrier)', 'Signed Bill of Lading (Delivered)'])) {
        errs.attachments = 'Please Upload BOL (Delivered), Shipper Rate Confirmation and Carrier Rate Confirmation';
      }

      return errs;
    },
  }),
  lifecycle({
    async componentDidMount() {
      const { id } = this.props;

      const setCarrier = val => {
        this.props.setForm('debit_available')(!val.aladdin_owned && get(val, 'accounts', []).length > 0);
        this.props.setForm('carrier_name')(get(val, 'carrier_company_profile.name', null));
        this.props.setForm('minimum_fee')(val.minimum_fee);
        this.props.setForm('carrier_rate')(val.discount_rate);
        this.props.setForm('factoring_id')(val.carrier);
      };

      if (id) {
        const carrier = await this.props.getCarrier(id);
        setCarrier(carrier);
      }
    },
  }),
)(({
  maxCarrierRate,
  carrierRate,
  brokerRate,
  brokerPay,
  brokerCut,
  carrierPay,
  carrierCut,
  carrierInvoiceAmount,
  shipperAmount,
  discountRate,
  amountTotal,
  submitting,
  factoring_id,
  debitAvailable,
  handleSubmit,
  openMoreDetailsModal,
  openAddCustomerModal,
  openHelpModal,
  user_type,
  require_debtor_address,
  set_require_debtor_address,
  setForm,
  accounts,
  setAccounts,
  aladdinOwned,
  setAladdinOwned,
  paymentMethod,
}) =>
  <div className='light-placeholder' style={{ width: '100%' }}>
    <div className='clearfix' style={{ marginTop: 12.5, paddingLeft: '4%' }}>
      <GetPaidHeader />
      <ProTip
        className='pull-right'
        name='Upload to Get Paid'
        message={'Be sure to upload your Rate Confirmation (or a form of one), and a signed Bill of Lading. If you don\'t have an invoice you can create one with the Create Invoice button. If you don\'t have a formal rate confirmation you can use a copy of an email confirming the rate or awarded bid from the company you hauled for as well.'}
        youtubeEmbed='Aykn5XgennU'
      />
    </div>
    <div style={{ paddingLeft: '4%' }}>
      <div className='text-danger'>Required Documents: Signed Rate Confirmation & Signed Bill of Lading.</div>
      <small>Cutoff time is 12PM CT for next day payment. </small>
    </div>
    <hr />
    <form onSubmit={handleSubmit}>
      <Row
        style={{
          paddingBottom: '10px',
        }}
        >
        <Col md={6}>
          <Attachments />
        </Col>
        <Col md={6}>
          <div
            style={{
              paddingLeft: '10%',
              paddingRight: '10%',
            }}
            >
            <RateSlider
              carrierRate={carrierRate}
              brokerRate={brokerRate}
              maxCarrierRate={maxCarrierRate}
              setCarrierRate={setForm('carrier_rate')}
            />
            <div
              style={{
                marginTop: '16px',
              }}
              >
              <p>
                How our {formatPennies(((discountRate || 0) * (shipperAmount || 0)) / 100)} fee gets split
              </p>
              <p>
                Your Carrier will see a {(Number(carrierRate) || 0).toFixed(2)}% discount = {formatPennies(carrierCut)}
              </p>
              <p>
                You will see a {(Number(brokerRate) || 0).toFixed(2)}% discount = {formatPennies(brokerCut)}
              </p>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <ShipperInvoiceAmount />
          <BillToCompany
            openAddCustomerModal={openAddCustomerModal}
            factoring={factoring_id}
            set_require_debtor_address={set_require_debtor_address}
            setDiscountRate={setForm('discount_rate')}
            setBillToCompanyAddress={setForm('bill_to_company_address')}
            setBillToCompanyId={setForm('bill_to_company_id')}
          />
          <LoadNumber />
          <AddDetails openMoreDetailsModal={openMoreDetailsModal} />
          <HelpButton openHelpModal={openHelpModal} />
        </Col>
        <Col md={6}>
          <CarrierInvoiceAmount />
          <CarrierName
            onSelect={val => {
              setForm('payment_method')('');
              setAccounts(val.accounts);
              setAladdinOwned(val.aladdin_owned);
              setForm('debit_available')(!val.aladdin_owned && get(val, 'accounts', []).length > 0);
              setForm('carrier_name')(get(val, 'carrier_company_profile.name', null));
              setForm('minimum_fee')(val.minimum_fee);
              setForm('carrier_rate')(val.discount_rate);
              setForm('factoring_id')(val.carrier);
            }}
          />
          <div>
            <PaymentMethod accounts={accounts} aladdinOwned={aladdinOwned} />
          </div>
          <PayRates
            carrierRate={carrierRate}
            brokerRate={brokerRate}
            carrierPay={formatPennies(carrierPay)}
            brokerPay={formatPennies(brokerPay)}
          />
          <SubmitButton submitting={submitting} />
        </Col>
      </Row>
    </form>
  </div>
);
