import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';

import { create } from 'actions/resource/factoringtransactionpurchase';
import { openModal } from 'actions/ui';
import formatError from '../../../helpers/formatError';

import Resource from 'components/hoc/Resource';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import { closeModal } from '../../../actions/ui';

export default compose(
  Resource('factoringpayment'),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
        // invoice_amount: props.item.data.amount,
        amount: props.item.data.fuel_advance_amount,
        // status: 'pending_for_time_batch',
        category: 'fuel_advance',
      },
    };
  }),
  reduxForm({
    form: 'FactoringPurchaseTransactionCreateForm',
    async onSubmit(fields, dispatch, id) {
      fields.funding_request = id.id;
      try {
        await dispatch(create(fields, id.id));
        dispatch(closeModal('factoringtransactionpurchase'));
        dispatch(openModal('success', { message: 'Purchase Created !' }));
        window.location.reload(false);
      }
      catch (err) {
        dispatch(closeModal('factoringtrasactionpurchase'));
        dispatch(openModal('error', { message: formatError(err, 'Cannot Create Purchase') }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <CenteredColumn sm={4}>
    <form onSubmit={handleSubmit}>
      <Field
        name='category'
        disabled={true}
        component={LabeledDropdown}
        label='Category'
        data={[
          // {
          //   text: 'Delivery Advance',
          //   value: 'delivery_advance',
          // },
          {
            text: 'Fuel Advance',
            value: 'fuel_advance',
          },
        ]}
      />
      {/* <Field
        name='status'
        component={LabeledDropdown}
        label='Status'
        data={[
          {
            text: 'Pending For Time Batch',
            value: 'pending_for_time_batch',
          },
          {
            text: 'Bank Transfer Requested',
            value: 'bank_transfer_requested',
          },
          {
            text: 'Bank Approved',
            value: 'bank_approved',
          },
          {
            text: 'Account Credited',
            value: 'account_credited',
          },
          {
            text: 'Transaction Reversed',
            value: 'payment_reversed',
          },
        ]}
      /> */}
      {/* <Field
        name='invoice_amount'
        component={PennyInput}
        label='Invoice Amount'
      /> */}
      <Field
        name='amount'
        component={PennyInput}
        label='Amount'
      />
    </form>
  </CenteredColumn>
);
