import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Popover, { PopoverAnimationVertical } from 'material-ui/Popover';
import FloatingActionButton from 'material-ui/FloatingActionButton';
import '../../../../../styles/creditCard.scss';
import SettingsMenu from './SettingsMenu';

function CreditCard({ className = '', containerStyle = {}, display, data }) {

    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);

    const handleClick = useCallback((event) => {
        event.preventDefault();
        setOpen(true);
        setAnchorEl(event.currentTarget);
    }, []);

    const handleRequestClose = useCallback(() => {
        setOpen(false);
    }, []);

    return (
        <div className={`credit-card ${className}`} style={containerStyle}>
            <div className="credit-card-top">
                {display && 
                    <div>
                        <FloatingActionButton
                            backgroundColor='transparent'
                            zDepth={5}
                            style={{ boxShadow: 'none' }}
                            onClick={handleClick}
                        >
                            <FontAwesomeIcon icon={faBars} size='2x' />
                        </FloatingActionButton>
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            targetOrigin={{ horizontal: 'left', vertical: 'top' }}
                            onRequestClose={handleRequestClose}
                            animation={PopoverAnimationVertical}
                        >
                            <SettingsMenu status={data.status} cardId={data.id} accountId={data.accountId} />
                        </Popover>
                    </div>
                }
            </div>
            <div className="infos">
                <section className="card-number">
                    <p>Card Number</p>
                    <h1>{`**** **** **** ${data.last4}`}</h1>
                </section>
                <div className="credit-card-infos-bottom">
                    <aside className="infos--bottom">
                        <section>
                            <p>Expiry date</p>
                            <h3>{data.expMonth}/{data.expYear}</h3>
                        </section>
                        <section>
                            <p>CVV</p>
                            <h3>***</h3>
                        </section>
                    </aside>
                    <aside>
                        <h2>{data.name}</h2>
                    </aside>
                </div>
            </div>
        </div>
    )
}

export default CreditCard;