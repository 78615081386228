/**
 * File for various helper functions used throughout the app
 * @module helpers
 * @since 3.0.0
 * @requires datatypes/Point
 * @requires datatypes/PermissionsError
 */
/*global MOBILE_BREAKPOINT */
import moment from 'moment';

import geolib from 'geolib';
import Point from './datatypes/Point';
import PermissionsError from './datatypes/PermissionsError';
import states from 'helpers/states';

/**
 * Returns the distance around the globe between 2 Points in miles
 * @function
 * @param {Point} origin
 * @param {Point} destination
 * @returns {number} distance in miles
 */
export const getDistance = (origin = new Point, destination = new Point) => geolib.convertUnit('mi', geolib.getDistance(origin.toLong(), destination.toLong()));

/**
 * Checks an item's state for a PermissionsError, returns one of two things depending on whether the PermissionsError is PostResettingTieredMenuItem
 * @function
 * @param {object} item - the item on which to check for a PermissionsError
 * @param {node} thingToDisplay - the node to return if no PermissionsError is found
 * @param {node} [thingToDisplayIfErr] - the node to return if a PermissionsError is found
 * @returns {node} the node to display
 */
export const checkPermissions = (item, thingToDisplay, thingToDisplayIfErr = 'xxxxxxxxxxxxxxxx') =>
  !item || item.err instanceof PermissionsError ? thingToDisplayIfErr : thingToDisplay;


/**
 * Helper for formatting numerical weights
 * @function
 * @param {number} weight - the weight to be formatted
 * @param {number} [decimals] - the amount of decimal places to display
 * @returns {string} the formatted weight
 */
export const formatWeight = (weight, decimals = 0) => typeof weight === 'number' ? weight.toLocaleString(undefined, {
  minimumFractionDigits: decimals,
  maximumFractionDigits: decimals,
}) : weight;


const TRUCK_LOCATION_STATE_BREAKPOINT = 2;
/**
 * Helper to infer the route type of a truck that may be city-city or state-state
 * @function
 * @param {object} truck - the truck on which to infer
 * @returns {object} the inferred origin and destination in the form `{ origin: string, origin_radius: number, destination: string, destination_radius: number }`
 */
export const inferTruckRouteType = truck => {
  let origin, origin_radius, destination, destination_radius;
  origin = destination = 'Anywhere';
  origin_radius = destination_radius = 'Any';
  if (truck.origin_region) {
    if (truck.origin_region.length > 1) {
      if (truck.origin_region.length > TRUCK_LOCATION_STATE_BREAKPOINT) {
        origin = truck.origin_region.join(', ');
      }
      else {
        origin = truck.origin_region.map(state => states[state]).join(', ');
      }
    }
    else {
      if (truck.origin_city || truck.origin_region[0]) {
        origin = [truck.origin_city, truck.origin_region[0]].filter(Boolean).join(', ');
        if (truck.origin_city) {
          origin_radius = truck.origin_radius;
        }
      }
    }
  }
  if (truck.destination_region) {
    if (truck.destination_region.length > 1) {
      if (truck.destination_region.length > TRUCK_LOCATION_STATE_BREAKPOINT) {
        destination = truck.destination_region.join(', ');
      }
      else {
        destination = truck.destination_region.map(state => states[state]).join(', ');
      }
    }
    else {
      if (truck.destination_city || truck.destination_region[0]) {
        destination = [truck.destination_city, truck.destination_region[0]].filter(Boolean).join(', ');
        if (truck.destination_city) {
          destination_radius = truck.destination_radius;
        }
      }
    }
  }
  return {
    origin,
    origin_radius,
    destination,
    destination_radius,
  };
};


/**
 * Helper to determine whether the window is 'mobile' sized
 * @function
 * @returns {boolean} whether the window is 'mobile' sized
 */
export const isMobile = () => window.innerWidth <= MOBILE_BREAKPOINT;


/**
 * Helper function to transform a number representing a date into a formatted abbreviated string
 * @function
 * @param {number} date - the unix timestamp to be formatted
 * @returns {string} the formatted date
 */
export const ageAbbr = date => {
  if (Number.isNaN(Number(date))) {
    return '?';
  }
  const now = Date.now();
  const days = Math.abs(date.diff(now, 'days'));
  const hours = Math.abs(date.diff(now, 'hours'));
  const minutes = Math.abs(date.diff(now, 'minutes'));
  if (hours > 23) {
    return `${days}d`;
  }
  if (minutes > 59) {
    return `${hours}h`;
  }
  return `${minutes}m`;
};


/**
 * A map between ComFreight-abbreviations of equipment/trailer types to their full names
 */
export const trailerTypeMap = {
  'V': 'Dry Van (V)',
  'F': 'Flatbed (F)',
  'R': 'Reefer (R)',
  'AC': 'Auto Carrier (AC)',
  'BT': 'B Train Flatbed (BT)',
  'CV': 'Cargo Van (CV)',
  'VC': 'Curtain Van (VC)',
  'DD': 'Double Drop (DD)',
  'DR': 'Drayage (DR)',
  'DT': 'Dump Trailer (DT)',
  'FZ': 'Flatbed Hazmat (FZ)',
  'MX': 'Flatbed Maxi (MX)',
  'HB': 'Hopper Bottom (HB)',
  'HS': 'Hot Shot (HS)',
  'LB': 'Low Boy (LB)',
  'PO': 'Power Only (PO)',
  'RC': 'Rail Container (RC)',
  'RN': 'Reefer Intermodal (RN)',
  'RZ': 'Reefer Hazmat (RZ)',
  'RGN': 'RGN Flatbed (RGN)',
  'SD': 'Step Deck (SD)',
  'ST': 'Straight Truck (ST)',
  'T': 'Tanker (T)',
  'VN': 'Van Intermodal (VN)',
  'VZ': 'Van HazMat (VZ)',
  'WLK': 'Van Walking Floor (WLK)',
  'VV': 'Vented Van (VV)',
  'CO': 'Container',
  'OTHER': 'Other',
};

export const equipmentTypeMap = {
  'box_truck': 'Box Truck',
  'truck_load': 'Truck Load',
  'cargo_van': 'Cargo Van',
  'hot_shot': 'Hot Shot',
};


/**
 * A helper to determine if one date is older than the otherwise
 * @function
 * @param {number} val - the X in "X is older than Y"
 * @param {number} num - the Y in "X is older than Y"
 * @returns {boolean} whether X is older than Y
 */
export const isOlderThan = (val, num, denomination = 'minutes') => moment().diff(val, denomination) > num;

/**
 * A helper to determine if one point is west of another point
 * @function
 * @param {Point} origin - the X in "X is west of Y"
 * @param {Point} destination - the Y in "X is west of Y"
 * @returns {boolean} whether X is west of Y
 */
export const isWestOf = (origin, destination) => Math.abs(origin.lng) - Math.abs(destination.lng) < 0;


/**
 * A helper to pair headers to every column for BlockTable items
 * @function
 * @param {node[]} headers - the array of headers to pair
 * @param {node[]} cols - the array of columns to pair
 * @returns {node[][]} the paired headers and columns
 */
export const mapColumnsToBlockRows = (headers, cols) => cols.map((col, i) => [headers[i], col]);


/**
 * The enum for user types used throughout the app
 */
export const USER_TYPE = {
  BROKER: 'broker',
  IS_FACTORING_BROKER: 'is_factoring_broker',
  IS_INVITE_CARRIER: 'is_invite_carrier',
  CARRIER: 'carrier',
  SHIPPER: 'shipper',
  DISPATCHER: 'dispatcher',
  ADMIN: 'admin',
  SUB_ADMIN: 'sub_admin',
  ALADDIN_ADMIN: 'aladdin_admin',
  FACTORING_ADMIN: 'factoring_admin',
  FACTORING_SUB_ADMIN: 'factoring_sub_admin',
  FACTORING_REMOTE_ADMIN: 'factoring_remote_admin',
  FACTORING_REMOTE_COORDINATOR: 'factoring_remote_coordinator',
  IS_ADMIN: type => [USER_TYPE.ADMIN, USER_TYPE.SUB_ADMIN, USER_TYPE.FACTORING_ADMIN, USER_TYPE.FACTORING_SUB_ADMIN, USER_TYPE.FACTORING_REMOTE_ADMIN, USER_TYPE.FACTORING_REMOTE_COORDINATOR].includes(type),
  IS_ALADDIN_ADMIN: type => [USER_TYPE.ALADDIN_ADMIN].includes(type),
};

export const FR_TYPE = {
  NewNOA: 'new_noa',
  New: 'new',
  NewFastTrack: 'new_fasttrack',
  Pending: 'pending',
  PendingNOA: 'pending_noa',
  PendingOriginals: 'pending_originals',
  DocumentIssue: 'document_issue',
  DocumentReview: 'document_review',
  HaulPayReview: 'haulpay_review',
  Approved: 'approved',
  RemoteApproved: 'remote_approved',
  Declined: 'declined',
  Paid: 'paid',
  Deleted: 'deleted',
};

export const FUNDING_REQUEST_STATUS = {
  NEW: 'new',
  FUEL_ADVANCE: 'fuel_advance',
  INCOMPLETE: 'incomplete',
  NON_FACTORED: 'non_factored',
  NON_FACTORED_CARRIER_PAY: 'non_factored_carrier',
  PENDING_DELIVERY: 'pending_delivery',
  SPECIAL_PENDING: 'special_pending',
};

export const FUNDING_REQUEST_CONTRACT_TYPES = {
  NON_FACTORED_STANDARD: 'NON_FACTORED_STANDARD',
  NON_FACTORED_BILL_OUT: 'NON_FACTORED_BILL_OUT',
  SELF_FINANCE_NO_INVOICE: 'SELF_FINANCE_NO_INVOICE',
  SELF_FINANCE_WITH_INVOICE: 'SELF_FINANCE_WITH_INVOICE',
  STD_BROKER: 'STD_BROKER'
}

export const NO_DEBTOR_ID = 'debdebde-debd-debd-debd-111111111111';