import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import PostHistory from 'components/pure/PostHistory';
import docTitle from 'components/hoc/docTitle';
import ResourceBy from 'components/hoc/ResourceBy';
import spinnerIf from 'components/hoc/spinnerIf';
import TruckPostHistoryTable from 'components/pure/TruckPostHistoryTable';


export default compose(
  docTitle('Truck Post History'),
  connect(
    state => ({
      id: state.user.id,
    }),
    {},
  ),
  ResourceBy('user', 'truckhistory'),
  spinnerIf(({ item }) => item === undefined),
)(({ item, actions }) =>
  <PostHistory
    options={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerType',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Deadhead',
        ordering: 'deadhead',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
    ]}
    currentOrder={item.ordering}
    sort={actions.sort}
    Table={TruckPostHistoryTable}
    ids={item.ids}
    isFetching={item.isFetching}
    fetchNext={actions.fetchNext}
  />
);
