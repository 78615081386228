import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import branch from 'recompose/branch';

import formatPennies from 'helpers/formatPennies';
import resourceProperty from 'components/hoc/resourceProperty';


const UserTableRecentBid = compose(
  connect(
    (state, { id }) => {
      const item = state.resource.loadsearch[id];
      return { item };
    },
    {},
  ),
  branch(
    ({ item }) => item,
    resourceProperty('loadsearch', 'recent_bid'),
    resourceProperty('load', 'recent_bid'),
  ),
)(({ recent_bid, ...rest }) =>
  <div {...rest}>
    Most Recent Bid: {formatPennies(recent_bid)}
  </div>
);

export default UserTableRecentBid;
