import validatejs from 'validate.js';


export const numericality = options => val => {
  if (validatejs.isEmpty(val)) {
    return;
  }
  options = {
    minimum: 0,
    maximum: Number.MAX_VALUE,
    wrongSize: ({ is }) => `wrong size (should be ${is})`,
    tooBig: ({ maximum }) => `too big (maximum is ${maximum})`,
    tooSmall: ({ minimum }) => `too small (minimum is ${minimum})`,
    tokenizer: val => val,
    ...options,
  };

  if (options.hasOwnProperty('is') && options.tokenizer(val) !== options.is) {
    return options.wrongSize({ is: options.is });
  }
  if (options.tokenizer(val) > options.maximum) {
    return options.tooBig({
      maximum: options.maximum,
    });
  }
  if (options.tokenizer(val) < options.minimum) {
    return options.tooSmall({
      minimum: options.minimum,
    });
  }
};
