import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';

import Stripe, { packages } from '../../../stripe';
import { subscribe } from 'actions/user';
import { openModal } from 'actions/ui';

import BillingPackageSelect from 'components/pure/form/inputs/BillingPackageSelect';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import CurrentOrderSummary from 'components/container/CurrentOrderSummary';
import MediaQuery from 'components/pure/MediaQuery';


const stripeHander = Stripe();

export default compose(
  mapProps(({ userType, ...rest }) => ({
    userType,
    initialValues: {
      package: packages[userType][0],
      termsofservice: false,
    },
    ...rest,
  })),
  reduxForm({
    form: 'SettingsBillingSubscriptionForm',
    destroyOnUnmount: false,
    onSubmit(fields, dispatch) {
      stripeHander.open({
        amount: fields.package.stripePrice,
        token(token, args) {
          return dispatch(subscribe({
            token: token.id,
            plan: fields.package.plan_name,
            type: 'Order Completed',
            amount: fields.package.stripePrice / 100, // convert to dollars
          }))
            .then(() => {
              dispatch(openModal('success', { message: 'Thank you for subscribing!' }));
              // force refresh so they have to redownload resources that have cached PermissionErrors
              setTimeout(() => window.location.reload(), 500);
            })
            .catch(err => {
              console.warn(err);
              dispatch(openModal('error'), {
                message: 'Transaction declined. Please make you have sufficient funds and/or the information you entered to activate your account was correct. You can email support@haulpay.io if you need additional help.',
              });
            })
          ;
        },
      });
    },
    validate: fields => validate(fields, {
      termsofservice: { isTrue: true },
    }),
  }),
)(({ handleSubmit, userType }) =>
  <div>
    <Field
      name='package'
      component={BillingPackageSelect}
      selects={packages[userType]}
    />
    <MediaQuery
      mobile={
        <div style={{ marginTop: '1em' }}>
          <CurrentOrderSummary />
        </div>
      }
    />
    <div className='form-group clearfix'>
      <div className='pull-right text-right'>
        <div style={{ margin: '0.5em 0' }}>
          <Field
            name='termsofservice'
            component={CheckboxInput}
            label={<span>I agree to the <a href='//haulpay.io/tos' target='_blank' rel='noopener'>HaulPay Terms of Service</a></span>}
            errorLabel='Please accept the terms of service.'
          />
        </div>
        <button className='btn btn-primary' onClick={handleSubmit}>Continue</button>
      </div>
    </div>
  </div>
);
