/**
 * Component that displays a tabbed view and the corresponding component
 * @TODO Rename this to something more apt, or get rid of it in lieu of [StatefulTabbed]{@link module:components/stateful/StatefulTabbed}
 * @module components/pure/PaginatedResults
 * @since 3.0.0
 * @requires components/hoc/Responsive
 * @requires components/stateful/SortButton
 * @property {object} props
 * @property {function} props.refresh - a function that triggers the submission of the form
 * @property {boolean} props.isMobile - whether to display the SortButton
 * @property {string} props.view - the active view
 * @property {function} props.setView - a function to change the active view
 * @property {object} props.viewOptions - a list of view options
 * @property {string} props.ordering - the current ordering
 * @property {function} props.sort - function to trigger the sort
 * @property {object} props.orderingOptions - ordering options to be bassed to [SortButton]{@link module:components/stateful/SortButton}
 */
import React from 'react';
import PropTypes from 'prop-types';
import Nav from 'react-bootstrap/lib/Nav';
import NavItem from 'react-bootstrap/lib/NavItem';

import Responsive from 'components/hoc/Responsive';
import SortButton from 'components/stateful/SortButton';


const PaginatedResults = ({
  refresh,
  isMobile,

  view,
  setView,
  viewOptions,

  ordering,
  sort,
  orderingOptions,
}) =>
  <div className='col-fill'>
    <Nav bsStyle='tabs' activeKey={view} onSelect={setView}>
      {Object.keys(viewOptions).map(key =>
        <NavItem key={key} eventKey={key} title={`${viewOptions[key].text} View`}>{viewOptions[key].text}</NavItem>
      )}
      <li className='pull-right'>
        {
          isMobile ?
            <SortButton currentOrder={ordering} sort={sort} options={orderingOptions} />
            :
            <button className='btn btn-default' style={{ marginTop: 4 }} onClick={refresh}>Refresh</button>
        }
      </li>
    </Nav>
    <div className='col-fill'>
      {viewOptions[view].component}
    </div>
  </div>
  ;

PaginatedResults.propTypes = {
  view: PropTypes.any.isRequired,
  setView: PropTypes.func.isRequired,
  viewOptions: PropTypes.objectOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    component: PropTypes.node.isRequired,
  })).isRequired,
  ordering: SortButton.propTypes.currentOrder,
  sort: SortButton.propTypes.sort,
  orderingOptions: SortButton.propTypes.options,
};

export default Responsive()(PaginatedResults);
