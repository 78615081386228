/* global API */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';

import factoringtransactionpaymentResource from '../resource/factoringtransactionpayment';

class FactoringPaymentsResource extends PaginatedResource {
  fetch(data, next, frid) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);
      if (mystate.isFetching) {
        return Promise.resolve();
      }
      if (next === undefined) {
        next = `${API.host}/funding_requests/${frid}/receivables/`;
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: data,
      });

      try {
        const res = await dispatch(APIFetch(next, {
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.FETCH_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
}

const factoringtransactionpaymentsPaginatedResource = new FactoringPaymentsResource('factoringtransactionpayment', {
  mountPoint: ['admin', 'factoringtransactionpayment'],
  url: new UrlAssembler('funding_requests/9c3a70a1-2977-4dba-af4f-ed406750ded0/payments'),
  baseResource: factoringtransactionpaymentResource,
});


export default factoringtransactionpaymentsPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = factoringtransactionpaymentsPaginatedResource;
