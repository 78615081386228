/**
 * Component that displays the information returned from a rate index query
 * @module components/pure/RateIndexBlockTable
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/BlockTable
 * @property {object} props
 * @property {object} props.item
 * @property {object} props.item.data
 * @property {object} props.item.data.linehaul
 * @property {number} props.item.data.linehaul.linehaul_cpg
 * @property {number} props.item.data.linehaul.average_rate
 * @property {object} props.map
 * @property {object} props.map.data
 * @property {object[]} props.map.data.routes[]
 * @property {object[]} props.map.data.routes[].legs[]
 * @property {object} props.map.data.routes[].legs[].distance
 * @property {number} props.map.data.routes[].legs[].distance.value
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import convert from 'convert-units';
import get from 'lodash/get';

import formatPennies from 'helpers/formatPennies';

import BlockTable from 'components/pure/BlockTable';


const RateIndexBlockTableItem = ({ item, map }) => {
  let distance = get(map, ['data', 'routes', '0', 'legs', '0', 'distance', 'value']);
  if (distance === undefined) {
    return (
      <tbody>
        <tr>
          <td colSpan={2}>
            {'We couldn\'t calculate the rate index for you at this time :('}
          </td>
        </tr>
      </tbody>
    );
  }
  distance = convert(distance).from('m').to('mi');
  const mpg = 6;
  const est_fuel_cost = (item.data.linehaul.linehaul_cpg / mpg) * distance;

  let avg_rate = 'No Rate Data Avail.';
  let avg_rate_per_mile = 'No Rate Data Avail.';
  if (item.data.linehaul.average_rate) {
    avg_rate = formatPennies(item.data.linehaul.average_rate);
    avg_rate_per_mile = formatPennies(item.data.linehaul.average_rate / distance);
  }
  return (
    <tbody>
      <tr>
        <td style={{ fontWeight: 'normal' }}>Avg. Linehaul Rate:</td>
        <td>{avg_rate}</td>
      </tr>
      <tr>
        <td style={{ fontWeight: 'normal' }}>Avg. Rate Per Mile:</td>
        <td>{avg_rate_per_mile}</td>
      </tr>
      <tr>
        <td style={{ fontWeight: 'normal' }}>Estimated Fuel Cost:</td>
        <td>{formatPennies(est_fuel_cost)}</td>
      </tr>
      <tr>
        <td style={{ fontWeight: 'normal' }}>Estimated Revenue:</td>
        <td>{formatPennies(item.data.linehaul.average_rate - est_fuel_cost)}</td>
      </tr>
      <tr>
        <td style={{ fontWeight: 'normal' }}>Distance:</td>
        <td>{distance.toLocaleString(undefined, { maximumFractionDigits: 0 })} mi</td>
      </tr>
    </tbody>
  );
};

RateIndexBlockTableItem.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      linehaul: PropTypes.shape({
        average_rate: PropTypes.number.isRequired,
        linehaul_cpg: PropTypes.number.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  map: PropTypes.shape({
    data: PropTypes.shape({
      routes: PropTypes.arrayOf(PropTypes.shape({
        legs: PropTypes.arrayOf(PropTypes.shape({
          distance: PropTypes.shape({
            value: PropTypes.number.isRequired,
          }).isRequired,
        })).isRequired,
      })).isRequired,
    }).isRequired,
  }).isRequired,
};

const RateIndexBlockTable = ({ item, map, blockTableProps = {}, ...rest }) =>
  <div {...rest}>
    <BlockTable
      className={cs('gray compact', rest.className)}
      items={[{ item, map }]}
      ListItem={RateIndexBlockTableItem}
      {...blockTableProps}
    />
  </div>
  ;

RateIndexBlockTable.propTypes = {
  blockTableProps: PropTypes.object,
};

export default RateIndexBlockTable;
