import factoringGroupsPaginatedResource from 'actions/admin/factoring/groups';
import factoringGroupRessources from 'actions/resource/factoringGroups';
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringGroups;

export default function factoringGroupResourceReducer(state = initialState, action) {
  switch (action.type) {
    case factoringGroupsPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringGroupRessources.parseMany(state, action.payload.results),
      };
  }
  return factoringGroupRessources.reduce(state, action);
}


