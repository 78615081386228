/**
 * Error that represents Permissions errors.
 * @module datatypes/PermissionsError
 * @since 3.0.0
 */

function PermissionsError(message) {
  this.message = message;
  this.name = this.constructor.name;
  if (Error.captureStackTrace) {
    Error.captureStackTrace(this, this.constructor);
  }
}

PermissionsError.prototype = Object.create(Error.prototype);
PermissionsError.prototype.constructor = PermissionsError;

export default PermissionsError;
