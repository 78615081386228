import React from 'react';
import { USER_TYPE } from 'helpers';
import RouteTabs from 'components/pure/RouteTabs';
import ButtonFactoringClientExportPayments from 'components/container/ButtonFactoringClientExportPayments';
import ButtonCadenceReports from '../ButtonCadenceReports';

export default ({ userType, path, isDispatchBroker, isAnyAdmin }) => {
  let routes = [];
  if (isDispatchBroker && !isAnyAdmin) {
    routes = [
      ['../special_pending', 'Pending'],
      ['../document_issue', 'Doc Issue'],
      ['../incomplete', 'Incomplete'],
      ['../remote_approved', 'Remote Approved'],
      ['../approved', 'Approved'],
      ['../declined', 'Declined'],
      ['../paid', 'Paid'],
    ];
  }
  else if (userType === USER_TYPE.ALADDIN_ADMIN) {
    routes = [
      ['../new_noa', 'New (NOA)'],
      ['../new', 'New'],
      ['../fuel_advance', 'Fuel Adv'],
      ['../pending', 'Pending'],
      ['../pending_noa', 'Pending (NOA)'],
      ['../special_pending', 'Special Pending'],
      ['../pending_originals', 'Pending Originals'],
      ['../document_issue', 'Doc Issue'],
      ['../document_review', 'Doc Review'],
      ['../approved', 'Approved'],
      ['../remote_approved', 'Remote Approved'],
      ['../declined', 'Declined'],
      ['../paid', 'Paid'],
    ];
  }
  else if (userType === USER_TYPE.FACTORING_REMOTE_ADMIN) {
    routes = [
      ['../pending', 'Pending'],
      ['../pending_noa', 'Pending (NOA)'],
      ['../document_review', 'Doc Review'],
      ['../document_issue', 'Doc Issue'],
    ];
  }
  else if (userType === USER_TYPE.FACTORING_REMOTE_COORDINATOR) {
    routes = [
      ['../new_noa', 'New (NOA)'],
      ['../new', 'New'],
      ['../pending', 'Pending'],
      ['../pending_noa', 'Pending (NOA)'],
      ['../document_issue', 'Doc Issue'],
      ['../document_review', 'Doc Review'],
      ['../remote_approved', 'Approved'],
    ];
  }
  else {
    routes = [
      ['../new_noa', 'New (NOA)'],
      ['../new', 'New'],
      ['../fuel_advance', 'Fuel Adv'],
      ['../pending', 'Pending'],
      ['../pending_noa', 'Pending (NOA)'],
      ['../special_pending', 'Special Pending'],
      ['../pending_originals', 'Pending Originals'],
      ['../document_issue', 'Doc Issue'],
      ['../haulpay_review', 'CF Review'],
      ['../incomplete', 'Incomplete'],
      ['../document_review', 'Doc Review'],
      ['../approved', 'Approved'],
      ['../remote_approved', 'Remote Approved'],
      ['../declined', 'Declined'],
      ['../paid', 'Paid'],
    ];
  }
  return (
    <RouteTabs
      routes={routes}
      extras={
        <div className='clearfix'>
          <ButtonFactoringClientExportPayments
            status={path}
            className='pull-right'
            style={{ marginRight: 10, marginLeft: 10 }}
          />
          {
            (path === 'approved' && isAnyAdmin) &&
            <ButtonCadenceReports
              status={path}
              className='btn pull-right'
            />
          }
        </div>
      }
    />
  );
};
