import 'styles/ImageCropper';
import React from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop-v2';
import cs from 'classnames';
import { get } from 'lodash';
import cropImage from 'helpers/cropImage';
import rotateImage from 'helpers/rotateImage';

import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';
import MaterialIcon from 'components/pure/MaterialIcon';

const imageFileExtensions = ['.heic', '.jpeg', '.jpg', '.png', '.gif', '.bmp'];

class ImageCropper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      crop: {
        x: 20,
        y: 20,
        width: 60,
        height: 60,
      },
      cropping: false,
      file: props.file,
      objectURL: props.file instanceof File ? URL.createObjectURL(props.file) : null,
      hasRotated: false,
    };
    this.updateDimensions = this.updateDimensions.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onCancel = this.onCancel.bind(this);
  }

  componentDidMount() {
    if (typeof this.props.file === 'string') {
      const request = new XMLHttpRequest();
      request.open('GET', this.props.file, true);
      request.responseType = 'blob';
      request.onload = () => {
        this.setState({
          file: request.response,
          objectURL: URL.createObjectURL(request.response),
        });
      };
      request.send();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.file !== this.props.file && this.props.file instanceof File) {
      URL.revokeObjectURL(this.state.objectURL);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        file: this.props.file,
        objectURL: URL.createObjectURL(this.props.file),
      });
    }

    const filename = get(this.props, 'filename') || get(this.props, 'file.name', '').toLowerCase();
    const nonImage = !imageFileExtensions.some(substring => filename.includes(substring));

    if (nonImage) {
      this.onCancel();
    }

    if (prevProps.file !== this.props.file && typeof this.props.file === 'string') {
      URL.revokeObjectURL(this.state.objectURL);
      const request = new XMLHttpRequest();
      request.open('GET', this.props.file, true);
      request.responseType = 'file';
      request.onload = () => {
        this.setState({
          file: request.response,
          objectURL: URL.createObjectURL(request.response),
        });
      };
      request.send();
    }
  }

  componentWillUnmount() {
    URL.revokeObjectURL(this.state.objectURL);
    this.objectURL = null;
    this.reactCropRef = null;
  }

  static propTypes = {
    onCrop: PropTypes.func,
    convertAnyway: PropTypes.bool,
    file: PropTypes.oneOfType([PropTypes.instanceOf(File), PropTypes.string]).isRequired,
  };

  async rotate(direction) {
    this.setState({ cropping: true });
    try {
      const new_image = await rotateImage(this.state.file, direction);
      // debugger;
      this.setState({
        file: new_image,
        objectURL: URL.createObjectURL(new_image),
        hasRotated: true,
      });
    }
    catch (err) {
      console.warn(err);
    }
    finally {
      this.setState({ cropping: false });
    }
  }

  updateDimensions(crop) {
    this.setState({ crop });
  }

  onComplete() {
    const { crop } = this.state;

    this.setState({ cropping: true });
    cropImage(this.state.file, crop).then(this.props.onCrop);
  }

  onCancel() {
    // convert anyway
    if (this.props.convertAnyway) {
      // don't have to crop to convert anyway if we've already rotated - the image is already converted
      if (this.state.hasRotated) {
        this.props.onCrop(this.state.file);
        return;
      }
      this.setState({ cropping: true });
      cropImage(this.state.file, {
        x: 0,
        y: 0,
      }).then(this.props.onCrop);
      return;
    }
    this.props.onCrop(this.state.file);
  }

  render() {
    const { onCrop, onCancel, file, convertAnyway, ...rest } = this.props;
    const { crop, cropping, objectURL } = this.state;

    if (!objectURL) {
      return null;
    }

    return (
      <div {...rest} className={cs('image-cropper', rest.className)}>
        <div>
          <Header>Crop Image</Header>
          <ReactCrop ref={ref => (this.reactCropRef = ref)} src={objectURL} crop={crop} onComplete={this.updateDimensions} />
          {cropping ? (
            <div className='text-center'>
              <Spinner />
            </div>
          ) : null}
          <div className='text-right'>
            {onCancel ? (
              <button type='button' className='btn btn-default pull-left' onClick={() => onCancel(file)}>
                Cancel
              </button>
            ) : null}
            <button type='button' className='btn btn-default' onClick={() => this.rotate('left')} disabled={cropping}>
              <MaterialIcon name='rotate_left' />
            </button>
            <button type='button' className='btn btn-default' onClick={() => this.rotate('right')} disabled={cropping}>
              <MaterialIcon name='rotate_right' />
            </button>
            <button type='button' className='btn btn-default' onClick={this.onCancel} disabled={cropping}>
              Don't Crop
            </button>
            <button type='button' className='btn btn-orange' onClick={this.onComplete} disabled={cropping}>
              Crop
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ImageCropper;
