import React from 'react';

import MaterialIcon from 'components/pure/MaterialIcon';
import AttachmentIcon from 'components/pure/AttachmentIcon';
import Tooltip from 'components/pure/Tooltip';

const CategoryMapping = cat => {
  switch (cat) {
    case 'late_fee':
      return 'Late Fee';
    case 'detention_fee':
      return 'Detention Fee';
    case 'damage_penalty':
      return 'Damage Penalty';
    case 'lumper_fee':
      return 'Lumper Fee';
    case 'misc':
      return 'Miscellaneous';
    default:
      return cat;
  }
};

const getRowColumns = ({ data, lastDocIssueDate, onDeleteClick, onEditClick }) => {
  const actions = data.uploaded ?
    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 -0.25em' }}>
      {!data.disableEdit && (
        <MaterialIcon
          style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', margin: '0 0.25em' }}
          name='edit'
          onClick={() => onEditClick(data)}
        />
      )}
      <a href={data.download_url} rel='noopener noreferrer' target='_blank'>
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', margin: '0 0.25em' }} name='cloud_download' />
      </a>
      <a href={data.preview_url} rel='noopener noreferrer' target='_blank'>
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#417505', margin: '0 0.25em' }} name='remove_red_eye' />
      </a>
      {!data.disableDelete && (
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#d9534f', margin: '0 0.25em' }} name='delete' onClick={() => onDeleteClick(data)} />
      )}
    </div> :
    data.failed && data.failed === true ?
      <Tooltip text='Uploads Processing Failed'>
        <span>
          {' — Failed '}
        </span>
      </Tooltip>
      :
      <div className='FactoringPaymentAttachment'>
        <AttachmentIcon
          style={{
            maxHeight: '1em',
          }}
        />
        <Tooltip text='Uploads may take up to 15 minutes to process.'>
          <span>
            {' — Processing '}
            <MaterialIcon name='sync' spin={true} />
          </span>
        </Tooltip>
      </div>;

  return [
    <div>
      {lastDocIssueDate && lastDocIssueDate < new Date(data.uploaded) && <span style={{ color: '#0FAF44' }}>NEW!!</span>}
      {data.filename}
    </div>,
    data.hasOwnProperty('category') ? CategoryMapping(data.category) : '-',
    data.type || '-',
    actions,
  ];
};

const AttachmentsTable = ({ attachments, lastDocIssueDate, onDeleteClick, onEditClick }) => (
  <table className='table' style={{ fontSize: 'smaller' }}>
    <thead>
      <tr>
        <th>File Name</th>
        <th>Category</th>
        <th>Type</th>
        <th>Actions</th>
      </tr>
    </thead>
    {!attachments.length && (
      <tbody>
        <tr>
          <td colSpan='4'>No Attachments</td>
        </tr>
      </tbody>
    )}
    {attachments.map((attachment, i) => (
      <tbody key={`row_${i}`}>
        <tr>
          {getRowColumns({
            data: attachment,
            onDeleteClick,
            onEditClick,
            lastDocIssueDate,
          }).map((columnData, j) => <td key={`col_${i}_${j}`}>{columnData}</td>)}
        </tr>
      </tbody>
    ))}
  </table>
);

export default AttachmentsTable;
