import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringdebtor.lineitemsdebtor;

import lineitemsdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/lineitemsdebtor';


export default function lineitemsdebtorByFactoringdebtorResourceReducer(state = initialState, action) {
  state = lineitemsdebtorByFactoringdebtorResource.reduce(state, action);
  return state;
}
