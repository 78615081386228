/**
 * Component that groups a list of items into chunks by date. Expects that the items are already sorted by the given time parameter
 * @module components/pure/DateGrouper
 * @since 3.0.0
 * @property {object} props
 * @property {object[]} props.items - the items to be grouped
 * @property {string} props.dateKey - the key in each items object to find the date property
 * @property {string} [props.dateFormat = 'dddd l'] - the format for displaying the date at the head of each grouping
 * @property {object[]} [props.groups] - custom grouping parameters
 * @property {string} [props.groups[].label] - the label for this custom group
 * @property {function} [props.groups[].test] - a function to test if the given date belongs in this custom group
 * @property {element} [props.ItemComponent = 'div'] - the component to render for each item
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';


const DateGrouper = ({ items = [], dateKey, dateFormat = 'dddd l', groups = [], ItemComponent = 'div', userType, ...rest }) => {
  const dateGroups = [];
  for (const item of items) {
    let matchingGroup;
    for (const group of groups) {
      const { test, label } = group;
      const date = get(item, dateKey);
      if (test(date)) {
        matchingGroup = label;
        break;
      }
    }
    if (matchingGroup === undefined) {
      matchingGroup = get(item, dateKey).format(dateFormat);
    }
    let dateGroup = dateGroups.find(dateGroup => dateGroup.label === matchingGroup);
    if (dateGroup === undefined) {
      dateGroup = {
        label: matchingGroup,
        items: [],
      };
      dateGroups.push(dateGroup);
    }
    dateGroup.items.push(item);
  }

  return (
    <div {...rest}>
      {dateGroups.map((group, i) =>
        <div key={i} style={{ marginTop: i > 0 ? '0.5em' : undefined }}>
          <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>{group.label}</span>
          <div>
            {group.items.map((item, i) =>
              <ItemComponent key={i} {...item} userType={userType} style={{ marginTop: i > 0 ? '0.5em' : undefined, ...item.style }} />
            )}
          </div>
        </div>
      )}
    </div>
  );
};

DateGrouper.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  dateKey: PropTypes.string,
  groups: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    test: PropTypes.func.isRequired,
  })).isRequired,
  ItemComponent: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

export default DateGrouper;
