/**
 * Actions relating to the factoringdebtornote resource
 * @module actions/resource/factoringdebtornote
 * @since 3.0.1
 * @requires datatypes/ControlledResource
 * @requires datatypes/FetchError
 * @requires datatypes/APIFetch
 */
/* global API */
import moment from 'moment';

import APIFetch from 'datatypes/APIFetch';
import ControlledResource from 'datatypes/ControlledResource';
import FetchError from 'datatypes/FetchError';


/**
 * Function to get the URL for a document
 * @param {string} id - the ID of the factoring debtor which this attachment is attached to
 * @param {string} attachment - the name of the attachment
 * @returns {Promise<string>} a promise that resolves with the given url
 */
export const getAttachment = (id, attachment) => async (dispatch, getState) => {
  const state = getState();
  const res = await global.fetch(`${API.host}/admin/debtors/notes/${id}/?attachment=${attachment}`, {
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
  });
  if (res.status !== 200) {
    const text = await res.text();
    throw new FetchError(text);
  }
  const json = await res.json();
  return json.url;
};

class FactoringDebtorNoteResource extends ControlledResource {

  edit(id, data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      const formData = new FormData();
      formData.append('factoring', data.factoring);
      formData.append('note', data.note);
      if (data.attachments instanceof File) {
        formData.append('attachments', data.attachments);
      }
      else if (data.attachments) {
        Array.from(data.attachments).map(attachment => {
          formData.append('attachments', attachment);
        });
      }

      try {
        const res = await dispatch(APIFetch(`${API.host}/admin/debtors/notes/${id}/`, {
          method: 'PATCH',
          body: formData,
          headers: {
            Authorization: `Bearer ${state.user.token}`,
          },
        }));
        if (res.status !== this.options.acceptableEditStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: { id, json },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: { id, err },
        });
        throw err;
      }
    };
  }

  parse(oldValue, json) {
    return super.parse(oldValue, {
      ...json,
      created: moment(json.created),
      modified: moment(json.modified),
    });
  }
}

export const factoringdebtornoteResource = new FactoringDebtorNoteResource('factoringdebtornote', {
  url: id => `admin/debtors/notes/${id}`,
});

export const {
  edit,
  doDelete,
} = factoringdebtornoteResource;

export default factoringdebtornoteResource;
