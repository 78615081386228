import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { mapProps } from 'recompose';
import debounce from 'lodash/debounce';
import cs from "classnames";
import { getBrokerGroupCarriers } from 'actions/groups';

import Autocomplete from 'components/pure/Autocomplete';
import LabeledInput from './LabeledInput';
import MaterialIcon from 'components/pure/MaterialIcon';
import { get } from 'lodash';
import getSelf from 'selectors/getSelf';

const CarrierCardSmall = ({ carrier, icon, ...rest }) => {
  const status = get(carrier, 'payment_profile.status', "");
  return (
    <div {...rest} >
      {icon}
      <div className='pac-item-query' style={{ display: 'inline-block', paddingLeft: 6, width: 'calc(100% - 22px)', lineHeight: 'normal', marginTop: 3, whiteSpace: 'normal' }}>
        <div><p>{`${get(carrier, 'payment_profile.owner_name_first', null)} ${get(carrier, 'payment_profile.owner_name_last', null)}`}</p></div>
        <div><p>{get(carrier, 'payment_profile.company_name', null)}</p></div>
        <div><p className={cs('text-uppercase', {
          'text-success': ['approved', 'paid', 'remote_approved', 'invoice_paid', 'carrier_paid'].includes(status),
          'text-warning': ['pending', 'special_pending', 'fuel_advance', 'needs_review'].includes(status),
          'text-red': ['declined', 'incomplete', 'pending_delivery', 'document_issue'].includes(status),
          'text-blue': ['new'].includes(status),
        })}>{status}</p></div>
        <div style={{ fontSize: '0.9em' }}>
          {[
            [
              get(carrier, 'payment_profile.company_mc', null) ? `MC# ${get(carrier, 'payment_profile.company_mc', null)}` : null,
              get(carrier, 'payment_profile.company_dot', null) ? `DOT# ${get(carrier, 'payment_profile.company_dot', null)}` : null,
            ].filter(Boolean).join(', '),
          ].filter(Boolean).map((val, i) =>
            <div key={i}>{val}</div>
          )}
          <div><p>{get(carrier, 'payment_profile.owner_email', null) ? get(carrier, 'payment_profile.owner_email', null) : null}</p></div>
        </div>
      </div>
    </div>
  )
};

const CarrierNameAutoCompleteItem = ({ value, ...rest }) => {
  return (
    <CarrierCardSmall
      role='button'
      className='pac-item'
      icon={
        <MaterialIcon size={20} name='account_box' className='pac-icon' style={{ verticalAlign: 'top', color: '#498504' }} />
      }
      carrier={value}
      {...rest}
    />
  );
};

const CarrierNameAutoCompleteInput = compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
    (dispatch, { input, onSelect, client_id, ...props }) => {
      if (onSelect) {
        const originalOnChange = input.onChange;
        input = {
          ...input,
          onChange(val) {
            if (!val) {
              return;
            }
            onSelect(val);
            originalOnChange(val);
          },
        };
      }
      return {
        input,
        formatValue: val => {
          return val;
        },
        dispatch,
      };
    },
  ),
  mapProps(props => {
    const factoring_id = get(props.self, 'data.factoring_id', null);
    const status = get(props, 'status',  'approved,needs_review');
    return {
      getSuggestions: async dba => {
        if (!dba || dba.length < 2 ) return;
        const { results } = await props.dispatch(getBrokerGroupCarriers(factoring_id, {
          carrier_dba: dba,
          ...(() => status ? { status } : {})(),
        }));
        return results;
      },
      ...props
    };
  })
)(props =>
  <LabeledInput
    {...props}
    getSuggestions={props.getSuggestions}
    onFocus={() => props.input.onFocus(props.input.value)}
    onBlur={() => props.input.onBlur(props.input.value)}
    onChange={undefined}
    onSelect={props.input.onChange}
    ComponentClass={Autocomplete}
    DropdownItem={CarrierNameAutoCompleteItem}
    HistoryDropdownItem={CarrierNameAutoCompleteItem}
    formatValue={val => get(val, 'payment_profile.company_name', '')}
  />
);

export default CarrierNameAutoCompleteInput;
