/**
 * Connected modal component to prompt a user to update their shipper load alerts settings
 * @module components/pure/modals/ShipperLoadAlertModalForm
 * @since 3.0.0
 * @requires actions/settings/loadalerts
 * @requires actions/ui
 * @requires components/hoc/Modal
 * @requires components/hoc/spinnerIf
 * @requires components/pure/form/inputs/StateInput
 * @requires components/pure/form/inputs/TrailerInput
 * @requires components/pure/Header
 * @requires components/pure/Spinner
 * @requires components/pure/modals/GenericHeader
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { Field, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import withHandlers from 'recompose/withHandlers';

import * as loadalertsSettingsResource from 'actions/settings/loadalerts';
import { closeModal, unshiftModal } from 'actions/ui';

import spinnerIf from 'components/hoc/spinnerIf';
import GenericHeader from './GenericHeader';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';


const ShipperLoadAlertModalForm = compose(
  connect(
    state => state.settings.loadalerts,
    {
      ...loadalertsSettingsResource,
      unshiftModal,
      closeModal,
    }
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ data }) => data === undefined || Object.keys(data).length === 0),
  withState('hasSubmitted', 'setHasSubmitted', false),
  withHandlers({
    onSubmit: ({ edit, unshiftModal, closeModal, hasSubmitted, setHasSubmitted }) => async fields => {
      if (!hasSubmitted && !fields.pickup_states.length) {
        return setHasSubmitted(true);
      }
      try {
        await edit({
          ...fields,
          equipment_type: fields.equipment_type.map(tt => tt.value),
          pickup_states: fields.pickup_states.map(state => state.code),
        });
        closeModal();
      }
      catch (err) {
        closeModal();
        unshiftModal('error', { message: err.message || 'Failed to update shipper load alert filter' });
      }
    },
  }),
  mapProps(({ data, closeModal, hasSubmitted, onSubmit }) => ({
    onSubmit,
    hasSubmitted,
    closeModal,
    initialValues: {
      ...data,
      equipment_type: data.equipment_type.filter(Boolean).map(value => MultiTrailerInput.trailerTypes.find(tt => tt.value === value)),
      pickup_states: data.pickup_states.filter(Boolean).map(code => MultiStateInput.data.find(state => state.code === code)),
    },
  })),
  reduxForm({
    form: 'ShipperLoadAlertModalForm',
  }),
)(({ handleSubmit, submitting, closeModal, hasSubmitted }) =>
  <form onSubmit={handleSubmit}>
    <div className='modal-body'>
      <Field name='pickup_states' component={MultiStateInput} label='OK Pick Up States or Provinces' placeholder='Any' />
      <Field name='equipment_type' component={MultiTrailerInput} label='OK Equipment Types' />
    </div>
    <div className='modal-footer'>
      <Link to='/settings/notifications' onClick={closeModal}>You can also manage this in Notifications from the Settings menu.</Link>
      <button className='btn btn-orange' type='submit'>SAVE</button>
      {hasSubmitted ? <p className='text-danger'>Are you sure you want to be notified for shipper loads posted picking up in all states?</p> : null}
    </div>
    {submitting ? <div><Spinner /></div> : null}
  </form>
);

const ShipperLoadAlertModal = ({ closeModal }) =>
  <div>
    <GenericHeader
      closeModal={closeModal}
      style={{
        backgroundColor: '#f0ad4e',
        color: 'white',
      }}
      icon='warning'
      >
      Shipper Load Post Email Alerts
    </GenericHeader>
    <div>
      <div className='modal-body' style={{ paddingBottom: 0 }}>
        <p>Set the pickup states and types of loads that are ok for your company to haul. We will alert you via email and on your timeline when shippers post within your set filters.</p>
        <Header style={{ fontSize: '1.1em' }}>Shipper Load Email Alerts</Header>
      </div>
      <ShipperLoadAlertModalForm />
    </div>
  </div>
  ;

ShipperLoadAlertModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
};

export default compose(
  connect(
    null,
    { closeModal },
  ),
)(ShipperLoadAlertModal);
