import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset } from 'redux-form';
import { Link } from 'react-router-v4';
import get from 'lodash/get';

import { openModal } from 'actions/ui';
import { upload } from 'actions/settings/documents';

import Header from 'components/pure/Header';
import LoadAttachmentButton from 'components/pure/LoadAttachmentButton';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';


const FORM_NAME = 'DocumentsRecommendedUploadForm';

export default compose(
  connect(
    state => {
      const files = get(state.resource.user, [state.user.id, 'data', 'files']);
      let insurance = null;
      let authority = null;
      if (files) {
        files.forEach(file => {
          if (file.category === 'documents_insurance') {
            insurance = file;
          }
          else if (file.category === 'documents_authority') {
            authority = file;
          }
        });
      }
      return {
        user_id: state.user.id,
        insurance,
        authority,
      };
    },
    {},
  ),
  reduxForm({
    form: FORM_NAME,
    initialValues: {
      insurance: [],
      authority: [],
    },
    async onSubmit(fields, dispatch) {
      try {
        const promises = [];
        if (fields.insurance.length) {
          promises.push(dispatch(upload({
            file: fields.insurance[0],
            category: 'documents_insurance',
          })));
        }
        if (fields.authority.length) {
          promises.push(dispatch(upload({
            file: fields.authority[0],
            category: 'documents_authority',
          })));
        }
        await Promise.all(promises);
        dispatch(openModal('success', { message: 'Successfully uploaded document(s).' }));
        dispatch(reset(FORM_NAME));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to upload file(s).' }));
      }
    },
  }),
)(({ insurance, authority, user_id, handleSubmit, submitting }) =>
  <div className='clearfix'>
    <Header>Recommended Documents</Header>
    <div className='help-block'>These documents will be publicly available on your <Link to={`/user/${user_id}`}>profile.</Link></div>
    <div>
      <Field
        name='insurance'
        component={MultiImageInput}
        label={
          insurance ?
            <LoadAttachmentButton
              title='Insurance'
              getURL={() => Promise.resolve(insurance)}
            />
            :
            <span className='text-danger'>No Insurance Doc Yet</span>
        }
        limit={1}
        buttonClassName='btn btn-default'
        buttonText={insurance ? 'Replace Insurance Doc' : 'Add Insurance Doc'}
      />
      <Field
        name='authority'
        component={MultiImageInput}
        label={
          authority ?
            <LoadAttachmentButton
              title='Authority'
              getURL={() => Promise.resolve(authority)}
            />
            :
            <span className='text-danger'>No Authority Doc Yet</span>
        }
        limit={1}
        buttonClassName='btn btn-default'
        buttonText={authority ? 'Replace Authority Doc' : 'Add Authority Doc'}
      />
    </div>
    <div className='pull-right'>
      <button className='btn btn-orange' type='submit' disabled={submitting} onClick={handleSubmit}>Upload</button>
    </div>
  </div>
);
