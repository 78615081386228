/**
 * Reducer for handling the factoringdebtor resource actions
 * @module reducers/resource/factoringdebtor
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringdebtor
 * @requires actions/admin/factoring
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringlineitem;

import factoringlineitemResource from 'actions/resource/factoringlineitem';
import factoringlineitemPaginatedResource from 'actions/admin/factoring/lineitem';
import factoringlineitemSearchResource from 'actions/search/factoringlineitem';
import factoringlineitemByfactoringclient, { factoringlineitemByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringlineitem';
import childrenfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/childrenfactoringdebtor';
import lineitemsdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/lineitemsdebtor';

export default function factoringlineitemResourceReducer(state = initialState, action) {
  switch (action.type) {
    case factoringlineitemPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringlineitemResource.parseMany(state, action.payload.results),
      };
    case factoringlineitemSearchResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringlineitemResource.parseMany(state, action.payload.results),
      };
  }
  if (
    //similarfactoringdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    childrenfactoringdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    lineitemsdebtorByFactoringdebtorResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      ...factoringlineitemResource.parseMany(state, action.payload.results),
    };
  }
  if (
    factoringlineitemByfactoringclient.matchesAction(action, 'FETCH_REQUEST_SUCCESS') ||
    factoringlineitemByfactoringclientUSER.matchesAction(action, 'FETCH_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      ...factoringlineitemResource.parseMany(state, action.payload.results),
    };
  }
  if (
    factoringlineitemByfactoringclient.matchesAction(action, 'CREATE_REQUEST_SUCCESS') ||
    factoringlineitemByfactoringclientUSER.matchesAction(action, 'CREATE_REQUEST_SUCCESS')
  ) {
    return {
      ...state,
      [action.payload.id]: factoringlineitemResource.parse(state[action.payload.id], action.payload),
    };
  }
  state = factoringlineitemResource.reduce(state, action);
  return state;
}
