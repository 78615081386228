/**
 * Reducer for handling the activity settings actions
 * @module reducers/settings/activity
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/settings/activity
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.settings.activity;

import activitySettingsResource from '../../actions/settings/activity';


export default function activitySettingsReducer(state = initialState, action) {
  state = activitySettingsResource.reduce(state, action);
  return state;
}
