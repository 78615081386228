/**
 * Component that displays a table with column headers that have onClick handlers which trigger a sort on that column
 * @module components/pure/SortableTable
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.currentOrder - the current sort ordering
 * @property {function} props.sort - the function to call to sort
 * @property {object[]} props.headers - an array of header objects
 * @property {object} props.headers[] - a header object
 * @property {string} props.headers[].text - the text to display for a header
 * @property {string | boolean} props.headers[].ordering - the ordering value to actually sort by for this header, or `false` for no sorting on this header
 * @property {any} [props....rest] - props passed to [Table]{@link module:components/pure/Table} component
 */
import 'styles/SortableTable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import infiniteScroll from 'components/hoc/infiniteScroll';
import Table from './Table';


class SortableTable extends Component {
  constructor(props) {
    super(props);
    this.onSort = this.onSort.bind(this);
  }

  static propTypes = {
    ...Table.propTypes,
    currentOrder: PropTypes.string.isRequired,
    sort: PropTypes.func.isRequired,
    headers: PropTypes.arrayOf(PropTypes.shape({
      text: PropTypes.node.isRequired,
      ordering: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
      ]).isRequired,
    })).isRequired,
  };

  onSort(index) {
    const { currentOrder, sort, headers } = this.props;
    if (headers[index].ordering === false) {
      return;
    }
    const newSort = headers[index].ordering;
    if (!currentOrder.startsWith('-')) {
      if (currentOrder === newSort) {
        // X -> -X
        return sort(`-${currentOrder}`);
      }
      // X -> Y
      return sort(newSort);
    }
    // -X -> X
    if (currentOrder.slice(1) === newSort) {
      return sort(currentOrder.slice(1));
    }
    // -X -> Y
    sort(newSort);
  }

  render() {
    const { currentOrder, sort, headers, ...rest } = this.props;
    const tableHeaders = headers.map(({ ordering, text }) => {
      let children;
      if (currentOrder.endsWith(ordering)) {
        if (currentOrder.startsWith('-')) {
          children = (
            <span style={{ whiteSpace: 'nowrap' }}>
              {text}
              {' '}
              <span className='caret' />
            </span>
          );
        }
        else {
          children = (
            <span style={{ whiteSpace: 'nowrap' }}>
              {text}
              {' '}
              <span className='caret' style={{ borderTop: 0, borderBottom: 'solid 4px' }} />
            </span>
          );
        }
      }
      else {
        children = text;
      }
      return {
        children,
        ...(() => ordering ? undefined : {
          style: {
            textDecoration: 'none',
            cursor: 'auto',
          },
        })(),
      };
    });

    return (
      <div className='SortableTable col-fill'>
        <Table
          {...rest}
          headers={tableHeaders}
          onHeaderItemClick={this.onSort}
        />
      </div>
    );
  }
}

export default infiniteScroll()(SortableTable);
