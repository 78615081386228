/* global API */

export const inviteToGroup = ({ groupId, emails, phoneNumbers }) => async (dispatch, getState) => {
  const response = await global.fetch(`${API.host}/groups/${groupId}/invite/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      emails,
      phone_numbers: phoneNumbers,
    }),
  });

  if (response.status >= 300) {
    const res = await response.json();
    throw res;
  }

  return response;
};
