import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';

import { edit } from 'actions/resource/financespurchase';
import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';

export default compose(
  Resource('financespurchase', {
    idPropName: ['params', 'id'],
  }),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
        amount: props.item.data.amount,
        status: props.item.data.status,
        category: props.item.data.category,
      },
    };
  }),
  reduxForm({
    form: 'FinancesPurchaseEdit',
    async onSubmit(fields, dispatch, props) {
      try {
        await dispatch(edit(props.item.data.id, fields));
        dispatch(openModal('success', { message: 'Purchase Updated !' }));
      }
      catch (err) {
        dispatch(openModal('error', { message: 'Purchase Update Failed :(' }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <CenteredColumn sm={4}>
    <form onSubmit={handleSubmit}>
      <Field
        name='category'
        component={LabeledDropdown}
        label='Category'
        data={[
          {
            text: 'Delivery Advance',
            value: 'delivery_advance',
          },
          {
            text: 'Payment Advance',
            value: 'payment_advance',
          },
        ]}
      />
      <Field
        name='status'
        component={LabeledDropdown}
        label='Status'
        data={[
          {
            text: 'Pending for Time Batch',
            value: 'pending_for_time_batch',
          },
          {
            text: 'Bank Transfer Requested',
            value: 'bank_transfer_requested',
          },
          {
            text: 'Approved By Bank',
            value: 'bank_approved',
          },
          {
            text: 'Account Credited',
            value: 'account_credited',
          },
          {
            text: 'Payment Reveresed',
            value: 'payment_reversed',
          },
        ]}
      />
      <Field
        name='amount'
        component={PennyInput}
        label='Amount'
      />
      <div className='text-right'>
        <button className='btn btn-orange' type='submit' disabled={submitting}>Update Purchase</button>
      </div>
    </form>
  </CenteredColumn>
);
