import { get } from 'lodash';

const getNewFiles = (state, type) => {
  let files = get(state, ['upload', 'newFiles']);
  // if (type && files) {
  //   console.log("FILES", files);
  //   files = (files || {}).filter(file => file.category === type);
  // }

  return files;
};

export default getNewFiles;
