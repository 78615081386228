import { compose } from 'redux-v3';

import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';


export const financeslineitemResource = compose(
  Singleton('financeslineitem'),
  Fetchable({
    url: id => `line_item/${id}/`,
  }),
  Editable({
    url: id => `line_item/${id}/`,
  }),
)();

export const {
  selector,
  fetch,
  edit,
} = financeslineitemResource;

export default financeslineitemResource;
