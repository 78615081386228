import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { closeModal } from 'actions/ui';

import GenericHeader from './GenericHeader';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export default compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
)(({ closeModal }) =>
  <form method='post' action='https://safer.fmcsa.dot.gov/query.asp' target='_blank' rel='noopener'>
    <GenericHeader
      closeModal={closeModal}
      icon='done'
      style={{
        backgroundColor: '#5cb85c',
        color: 'white',
      }}
      >
      Look up my MC / DOT
    </GenericHeader>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      <p>Enter your business name below to check FMCSA for your MC / DOT</p>
      <input name='searchtype' value='ANY' type='hidden' />
      <input name='query_type' value='queryCarrierSnapshot' type='hidden' />
      <input name='query_param' value='NAME' type='hidden' />
      <LabeledInput name='query_string' label='Business Name' />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' type='submit'>Check FMCSA</button>
    </div>
  </form>
);
