import React from 'react';

import WarningModal from './Warning';


const email = 'invoice@haulpay.io';

export default () =>
  <WarningModal
    headerChildren='Payment Request Help'
    message={
      <div>
        <p>If you're having trouble submitting a payment request using the payment request form you can email your bill of lading and rate confirmation to <a href={`mailto:${email}`}>{email}</a> as well. Thanks!</p>
        <p className='text-center'><b>Need more help? (888) 633-5558</b></p>
      </div>
    }
  />
;
