/* global google */

const PLACES = new google.maps.places.PlacesService(document.createElement('div'));

const cache = (() => {
  const cache = Object.create(null);
  return {
    get(place) {
      return cache[place.place_id];
    },
    set(place, value) {
      return cache[place.place_id] = value;
    },
  };
})();

const getPlaceDetails = place => new Promise((resolve, reject) => {
  const cached = cache.get(place);
  if (cached) {
    return resolve(cached);
  }
  PLACES.getDetails(place, (res, status) => {
    if (status === google.maps.places.PlacesServiceStatus.OK) {
      cache.set(place, res);
      return resolve(res);
    }
    reject(res, status);
  });
});

export default getPlaceDetails;
