/**
 * Component to display row in the Load Reverse Table
 * @module components/pure/LoadReverseTableItem
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/TableItem
 * @requires components/pure/TableItemAddonBids
 * @requires components/pure/ExpandableLineItem
 * @requires components/pure/UserTable
 * @property {object} props
 * @property {object} props.user
 * @property {object} props.user.data
 * @property {string} props.user.data.mc
 * @property {object} props.item
 * @property {object} props.item.data
 * @property {string} props.item.data.id
 * @property {string} props.item.data.user_type
 * @property {moment} props.item.data.time_posted
 * @property {string} props.item.data.trailerType
 * @property {string} props.item.data.origin_city
 * @property {string} props.item.data.origin_state
 * @property {string} props.item.data.destination_city
 * @property {string} props.item.data.destination_state
 * @property {number} props.item.data.weight
 * @property {number} props.item.data.length
 * @property {moment} props.item.data.time_start
 * @property {moment} props.item.data.time_end
 * @property {moment} props.item.data.time_delivery
 * @property {function} props.initiateCopy - a function to intitiate the copy state on the Load Post page
 * @property {function} props.initiateDelete - a function to pop up the 'confirm delete' modal
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { Link } from 'react-router-v4';
import mapProps from 'recompose/mapProps';
import moment from 'moment';

import { formatWeight, mapColumnsToBlockRows } from '../../helpers';
import TableItem from './TableItem';
import ExpandableLineItem from './ExpandableLineItem';
import UserTable from './PureUserTable';


const columns = item => [
  item.data.trailerType,
  item.data.deadhead || '–',
  [item.data.origin_city, item.data.origin_state].filter(i => i !== undefined).join(', '),
  [item.data.destination_city, item.data.destination_state].filter(i => i !== undefined).join(', '),
  item.data.weight === 0 ? '—' : formatWeight(item.data.weight),
  item.data.length === 0 ? '—' : item.data.length,
  item.data.time_start.format('l LT'),
  item.data.time_end.format('l LT'),
  item.data.time_delivery ? item.data.time_delivery.format('l') : '—',
  <Link to={`/load/${item.data.id}`}><button className='btn btn-orange'>Details</button></Link>,
];

export const LoadReverseTableItem = compose(
  mapProps(({ id, item, toggleOpen, ...rest }) => ({
    ...rest,
    className: item.data.user_type === 'broker' ? 'blue-broker' : 'yellow-shipper',
    open: item.globals.open,
    toggleOpen: () => toggleOpen(item.data.id),
    style: {
      fontWeight: item.globals.viewed ? undefined : 'bold',
    },
    collapsedChild: (
      <tr>
        <td colSpan={100} style={{ padding: 0 }}>
          <UserTable
            items={[{
              item: item.data.user,
              load_id: item.data.id,
              user_id: item.data.user.data.id,
            }]}
            isShipperOnly={item.data.user_type === 'shipper'}
          />
        </td>
      </tr>
    ),
    children: (
      <tr>
        {columns(item).map((column, i) => <td key={i}>{column}</td>)}
      </tr>
    ),
  })),
)(ExpandableLineItem);


export const LoadReverseBlockTableItem = compose(
  mapProps(({ id, item, toggleOpen, headers, ...rest }) => ({
    ...rest,
    rows: mapColumnsToBlockRows(headers, columns(item)),
    className: item.data.user_type === 'broker' ? 'blue-broker' : 'yellow-shipper',
    addons: [
      <UserTable
        items={[{
          item: item.data.user,
          load_id: item.data.id,
        }]}
        isShipperOnly={item.data.user_type === 'shipper'}
      />,
    ],
  })),
)(TableItem);

LoadReverseTableItem.propTypes = LoadReverseBlockTableItem.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      trailerType: PropTypes.string.isRequired,
      origin_city: PropTypes.string.isRequired,
      origin_state: PropTypes.string.isRequired,
      destination_city: PropTypes.string.isRequired,
      destination_state: PropTypes.string.isRequired,
      weight: PropTypes.number.isRequired,
      length: PropTypes.number.isRequired,
      time_start: PropTypes.instanceOf(moment).isRequired,
      time_end: PropTypes.instanceOf(moment).isRequired,
      time_delivery: PropTypes.instanceOf(moment),
      id: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
