/**
 * Middleware for logging
 * @module middleware/logger
 * @since 3.0.0
 */
/* global process */
import createLogger from 'redux-logger-v2';

export default createLogger({
  duration: true,
  predicate: () => process.env.NODE_ENV === 'development',
  actionTransformer: action => ({
    ...action,
    type: String(action.type),
  }),
});
