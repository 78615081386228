/**
 * Factory for an object that gets and sets data in LocalStorage using user IDs. Always returns an object, but this behavior can be worked around using hooks
 * @module datatypes/SavedUserSettings
 * @since 3.0.0
 */

const defaultOptions = {
  defaults: {},
  hooks: {},
};

/**
 * @param {string} key - the key to pair with the user ID in LocalStorage
 * @param {object} [options]
 * @param {object} [options.defaults={}] - default properties to be added to the return object on initial `.get()`
 * @param {object} [options.hooks]
 * @param {function} [options.hooks.postRetrieve] - a hook to modify the data retrieved on the initial `.get()` call. Passed the data retrieved from LocalStorage
 * @param {function} [options.hooks.preSave] - a hook to modify the value before it's saved in LocalStorage, but after it's cached in this object, so subsequent `.get()` calls aren't affected
 */
const SavedUserSettings = (key, options) => {
  if (key === undefined) {
    throw new TypeError(`SavedUserSettings "${key}" requires a key, got ${key} ${typeof key}`);
  }
  options = {
    ...defaultOptions,
    ...options,
  };
  let savedInitialValues, currentId, localStorageKey;

  return {
    get(id) {
      if (id === undefined) {
        console.warn(new TypeError(`SavedUserSettings "${key}" requires an ID, got ${id}`));
      }
      if (id === currentId) {
        return savedInitialValues;
      }
      currentId = id;
      localStorageKey = `${id}-${key}`;
      let initialValues = localStorage.getItem(localStorageKey);
      if (initialValues === null) {
        initialValues = options.defaults;
      }
      else {
        try {
          initialValues = {
            ...options.defaults,
            ...JSON.parse(initialValues),
          };
          if (typeof options.hooks.postRetrieve === 'function') {
            initialValues = options.hooks.postRetrieve(initialValues);
          }
        }
        catch (e) {
          console.warn(e);
          initialValues = options.defaults;
        }
      }
      return savedInitialValues = initialValues;
    },
    set(value) {
      savedInitialValues = {
        ...savedInitialValues,
        ...value,
      };
      if (typeof options.hooks.preSave === 'function') {
        value = options.hooks.preSave(value);
      }
      if (localStorageKey) {
        localStorage.setItem(localStorageKey, JSON.stringify(value));
      }
    },
  };
};

export default SavedUserSettings;
