/**
 * Component for displaying a selectable series of stars.
 * @module components/pure/RateStars
 * @since 3.0.0
 * @requires components/pure/FontIcon
 * @property {object} props
 * @property {number} [stars=5] - the amount of stars to display
 * @property {number} [value=0] - the percentage (0-100) of stars to fill
 * @property {function} [setValue] - function to call when a star is selected, gets passed the new percentage value
 * @property {any} [props....rest] - props passed to div component
 */
import 'styles/RateStars';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import MaterialIcon from 'components/pure/MaterialIcon';


class RateStars extends Component {
  constructor(props) {
    super(props);
    this.state = { hovering: -1 };
  }

  static propTypes = {
    value: PropTypes.number,
    setValue: PropTypes.func,
    stars: PropTypes.number,
    color: PropTypes.string,
  };

  static defaultProps = {
    value: 0,
    stars: 5,
    color: '#000',
  };

  onMouseEnter(i) {
    this.setState({ hovering: i });
  }

  onMouseLeave(i) {
    this.setState({ hovering: -1 });
  }

  onClick(i) {
    const { stars, setValue } = this.props;
    setValue(Math.floor((i + 1) / stars * 100));
  }

  render() {
    const { stars, value, setValue, color, ...rest } = this.props;
    const { hovering } = this.state;

    const stars_filled = stars * (value / 100);

    const content = [];
    for (let i = 0; i < stars; i++) {
      content.push(
        <MaterialIcon
          key={i}
          name={hovering === -1 ? (i < stars_filled ? 'star' : 'star_border') : (i <= hovering ? 'star' : 'star_border')}
          role='button'
          style={{ color }}
          className={i <= hovering ? 'RateStars__hovering' : undefined}
          onMouseLeave={setValue ? () => this.onMouseLeave(i) : undefined}
          onMouseEnter={setValue ? () => this.onMouseEnter(i) : undefined}
          onClick={setValue ? () => this.onClick(i) : undefined}
        />
      );
    }

    return (
      <div {...rest} className={cs('RateStars', rest.className)}>
        {content}
      </div>
    );
  }
}

export default RateStars;
