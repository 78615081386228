/**
 * Component for the Navbar at the top of the app while logged-in.
 * @module components/container/Nav
 * @since 3.0.0
 * @requires components/pure/Hamburger
 * @requires components/container/TimelineNotificationIcon
 */
import 'styles/Nav';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { setSidebarOpen } from 'actions/ui';

import Hamburger from 'components/pure/Hamburger';
import TimelineNotificationIcon from 'components/container/TimelineNotificationIcon';


export default compose(
  connect(
    state => ({
      hamburgerIsVisible: !state.ui.menu_open,
    }),
    dispatch => ({
      onHamburgerClick: () => dispatch(setSidebarOpen()),
    }),
  ),
)(({ hamburgerIsVisible, onHamburgerClick }) =>
  <div className='Nav navbar navbar-default navbar-static-top'>
    <Hamburger onClick={onHamburgerClick} active={!hamburgerIsVisible} />
    <div className='Nav__icons' style={{ marginRight: '1.5em' }}>
      <TimelineNotificationIcon />
    </div>
  </div>
);
