import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';

import 'styles/Tables';

import financespaymentsPaginatedResource from 'actions/admin/financespayments';

import MaterialIcon from 'components/pure/MaterialIcon';
import ResponsiveTable from 'components/pure/ResponsiveTable';

import createTableItems from 'helpers/createTableItems';
import formatPennies from 'helpers/formatPennies';

const getStatusView = (data = {}) => {
  const isShortPaid = String(data.receivable_status).toLowerCase() === 'short_paid';
  const isOpen = String(data.receivable_status).toLowerCase() === 'open';
  const isOutdated = data.receivable_age > (data.debtor.dtp_allowance ? data.debtor.dtp_allowance : 30);
  let statusClassName = '';

  if (isOutdated) {
    statusClassName = 'text-danger';
  }

  if (isShortPaid) {
    statusClassName = 'text-warning';
  }

  return (
    <div>
      {isShortPaid && (
        <p>
          <span className={statusClassName}>Short Pay !!</span> <br />
          <span className='text-danger'>( {formatPennies(data.outstanding_balance)} )</span>
        </p>
      )}
      {
        isOpen && isOutdated ?
          <p><span className={statusClassName}>Unpaid</span></p>
          : !isShortPaid ?
            <p><span>Unpaid</span></p>
            : null
      }
    </div>
  );
};

const constructTableCells = ({ data }) => {
  return [
    data.user_load_number,
    formatPennies(data.amount),
    data.invoice_number,
    data.factoring_company_profile.name,
    moment(data.funded_at).format('l LTS'),
    getStatusView(data),
    <span>{Number.isFinite(Number(data.receivable_age)) ? `${data.receivable_age} day${Number(data.receivable_age) > 1 ? 's' : ''}` : '-'}</span>,
    <Link to={`/admin/factoring/transaction/${data.id}`} target='_blank' rel='noopener noreferrer'>
      <button className='btn btn-primary'>
        <MaterialIcon name='remove_red_eye' />
      </button>
    </Link>,
  ];
};

const TableComponents = createTableItems(constructTableCells);

const DebtorPaymentsTable = compose(
  connect(
    state => ({
      ids: state.admin.financespayments.ids,
      isFetching: state.admin.financespayments.isFetching,
      limit: state.admin.financespayments.limit,
      count: state.admin.financespayments.count,
      payments: state.resource.financespayment,
      currentOrder: state.admin.financespayments.ordering,
    }),
    {
      fetchPage: financespaymentsPaginatedResource.fetchPage,
    }
  )
)(({ ids, isFetching, fetchPage, payments, count, limit }) => (
  <ResponsiveTable
    isFetching={isFetching}
    fetchPage={fetchPage}
    count={count}
    limit={limit}
    withPagination={true}
    hideHeadersIfEmpty={true}
    headers={['Load#', 'Invoice Amnt', 'Invoice#', 'Client', 'Invoiced At', 'Status', 'Aging', 'Details']}
    placeholder='No invoices found'
    items={ids.map(id => payments[id])}
    TableItem={TableComponents.TableItem}
    BlockTableItem={TableComponents.BlockTableItem}
    desktopContainerProps={{
      className: 'Table_Inverse',
    }}
    mobileContainerProps={{
      className: 'Table_Inverse',
    }}
  />
));

const ConnectedDebtorPaymentsTable = compose(
  connect(null, (dispatch, ownProps) => {
    return {
      update() {
        dispatch(financespaymentsPaginatedResource.clear());
        return dispatch(
          financespaymentsPaginatedResource.fetch({
            id: ownProps.debtorId,
          })
        );
      },
    };
  }),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  })
)(DebtorPaymentsTable);

export default ConnectedDebtorPaymentsTable;
