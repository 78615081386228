import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import Header from 'components/pure/Header';
import { closeModal, openModal } from 'actions/ui';
import DebtorSimilarityFormModal from 'components/pure/DebtorSimilarityFormModal';
import { edit } from 'actions/resource/factoringdebtor';
import formatError from '../../../helpers/formatError';

const SimilarDebtors = compose(
  connect(
    null,
    dispatch => ({
      closeModal() {
        dispatch(closeModal());
      },
      async SetAsMaster(id) {
        const data = {
          master: true,
        };
        try {
          await dispatch(edit(id, data));
          dispatch(closeModal());
          dispatch(openModal('success', { message: 'Successful Debtor Set As Master.' }));
          window.location.reload();
        }
        catch (err) {
          dispatch(closeModal());
          dispatch(openModal('error', { message: formatError(err, 'Error Setting Debtor as Master.') }));
        }
      },
    }),
  ),
)(({ debtors, id, closeModal, SetAsMaster }) =>
  <div >
    <div className='modal-header' >
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <Header> Set Master Debtor</Header>
      { debtors.length === 0 ?
        <p className='text-danger'> No Similar Debtors Found, Still You can make this Debtor Master</p> :
        <p className='text-danger'> To Set Master Debtor, Please select at least 1 Similar Debtor to Transfer </p>
      }
    </div>
    <div className='modal-body' >
      <DebtorSimilarityFormModal id={id} />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' type='button' onClick={closeModal}>Cancel</button>
      { debtors.length === 0 ?
        <button className='btn btn-orange' type='button' onClick={() => SetAsMaster(id)}>Set Master</button> : null
      }
    </div>
  </div>
);

export default SimilarDebtors;
