import { compose } from 'redux-v3';

import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';


export const financesPurchaseItemsResource = compose(
  Singleton('purchases_funding_request_items'),
  Fetchable({
    url: id => `purchases/${id}/funding_request`,
  }),
  Editable({
    url: id => `purchases/${id}/funding_request`,
  }),
)();


const financesPurchasesItemsPaginatedResource = new PaginatedResource('purchases_funding_request_items', {
  mountPoint: ['admin', 'financesPurchasesItems'],
  url: new UrlAssembler('purchases/:id/funding_requests'),
  baseResource: financesPurchaseItemsResource,
});


export default financesPurchasesItemsPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = financesPurchasesItemsPaginatedResource;
