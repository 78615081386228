import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';

import * as loadalertsSettingsResource from 'actions/settings/loadalerts';
import { openModal } from 'actions/ui';

import spinnerIf from 'components/hoc/spinnerIf';
import CheckmarkTable from 'components/pure/form/CheckmarkTable';
import MultiStateInput from 'components/pure/form/inputs/StateInput';
import { MultiTrailerInput } from 'components/pure/form/inputs/TrailerInput';
import AlertFrequencyDropdown from 'components/pure/form/inputs/AlertFrequencyDropdown';
import SubmitButton from 'components/pure/form/SubmitButton';
import getSelf from 'selectors/getSelf';

const mapAlertFrequency = [
  'real',
  'two',
  'four',
];

export default compose(
  connect(
    state => ({
      ...state.settings.loadalerts,
      has_verified_email: getSelf(state).data.has_verified_email,
    }),
    {
      ...loadalertsSettingsResource,
      openModal,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
  spinnerIf(({ data }) => data === undefined || Object.keys(data).length === 0),
  mapProps(({ data, edit, openModal, has_verified_email }) => ({
    has_verified_email,
    initialValues: {
      ...data,
      alert: mapAlertFrequency.indexOf(data.alert),
      equipment_type: data.equipment_type.filter(Boolean).map(value => MultiTrailerInput.trailerTypes.find(tt => tt.value === value)),
      pickup_states: data.pickup_states.filter(Boolean).map(code => MultiStateInput.data.find(state => state.code === code)),
    },
    onSubmit(fields) {
      return edit({
        ...fields,
        alert: typeof fields.alert === 'number' ? mapAlertFrequency[fields.alert] : mapAlertFrequency[fields.alert.value],
        equipment_type: fields.equipment_type.map(tt => tt.value),
        pickup_states: fields.pickup_states.map(state => state.code),
      })
        .then(() => openModal('success', { message: 'Successfully updated settings.' }))
      ;
    },
  })),
  reduxForm({
    form: 'SettingsNotificationsShipperLoadAlertsForm',
    destroyOnUnmount: false,
  }),
)(({ handleSubmit, submitting, has_verified_email }) =>
  <div>
    <CheckmarkTable
      disabled={!has_verified_email}
      header='Shipper Load Alerts'
      types={[
        { text: 'email', icon: 'email' },
        { text: 'push', icon: 'notifications_active' },
      ]}
      rows={[
        ['Alert Me When Shipper Posts New Load(s)', 'shipper_post_alerts', 'notify_shipper_post_alerts'],
      ]}
    />
    <Field
      name='pickup_states'
      component={MultiStateInput}
      label='OK Pick Up States or Provinces'
      placeholder='Any'
      disabled={!has_verified_email}
    />
    <Field
      name='equipment_type'
      component={MultiTrailerInput}
      label='OK Equipment Types'
      disabled={!has_verified_email}
    />
    <Field
      name='alert'
      component={AlertFrequencyDropdown}
      disabled={!has_verified_email}
    />
    <SubmitButton onClick={handleSubmit} disabled={submitting || !has_verified_email}>SAVE</SubmitButton>
  </div>);
