import PaginatedResource from 'datatypes/PaginatedResource';
import UrlAssembler from 'url-assembler';
import factoringGroupResource from 'actions/resource/factoringGroups';

class FactoringGroupAdminPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }
}

const factoringGroupsPaginatedResource = new FactoringGroupAdminPaginatedResource('admin_factoring_broker_group', {
  mountPoint: ['admin', 'factoring', 'groups'],
  url: new UrlAssembler('groups'),
  baseResource: factoringGroupResource,
});

export default factoringGroupsPaginatedResource;
