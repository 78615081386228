import React from 'react';
import { compose } from 'redux-v3';
import PropTypes from 'prop-types';
import withContext from 'recompose/withContext';

import RouteTabs from 'components/pure/RouteTabs';
import ButtonFactoringClientExportPayments from 'components/container/ButtonFactoringClientExportPayments';
import { USER_TYPE } from '../../helpers';


const FactoringClient = compose(
  withContext(
    {
      params: PropTypes.object,
      userType: PropTypes.string,
    },
    ({ params, userType }) => ({ params, userType }),
  ),
)(({ children, params: { id }, userType }) => {
  const base_path = `/admin/factoring/debtor/${id}`;
  const routes = userType !== USER_TYPE.FACTORING_REMOTE_COORDINATOR ?
    [
      [base_path, 'Debtor Profile'],
      [`${base_path}/payments`, 'Payments'],
      [
        `${base_path}/invoices`,
        <span>
              Invoices
          {' '}
          <ButtonFactoringClientExportPayments
            debtor={id}
            className='pull-right'
          />
        </span>,
      ],
      [`${base_path}/clients`, 'Connected Clients'],
    ] : [[base_path, 'Debtor Profile']];
  return (
    <div className='col-fill'>
      <RouteTabs
        className='nav-justified'
        style={{ flexGrow: 0 }}
        routes={routes}
      />
      {children}
    </div>
  );
});

export default FactoringClient;
