/**
 * Reducer for handling the rate index history resource actions
 * @module reducers/resource/rateindexhistory
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/rateindex
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.load;

import {
  rateIndexResource,
  rateIndexHistoryPaginatedResource,
} from 'actions/rateindex';


export default function rateindexhistoryResourceReducer(state = initialState, action) {
  switch (action.type) {
    case rateIndexHistoryPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...rateIndexResource.parseMany(state, action.payload.results),
      };
  }
  return state;
}
