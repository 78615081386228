/**
 * Connected modal component for confirming an activity deletion
 * @module components/pure/modals/ConfirmActivityDelete
 * @since 3.0.0
 * @requires actions/ui
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {string} props.id - the ID of the activity to hide
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { closeModal, openModal } from 'actions/ui';
import { activityResource } from 'actions/resource/activity';

import ModalWarning from './Warning';


const ConfirmActivityDelete = compose(
  connect(
    null,
    (dispatch, { id }) => ({
      async doDelete() {
        try {
          await dispatch(activityResource.doDelete(id));
        }
        catch (err) {
          console.warn(err);
          dispatch(openModal('error', { message: err.message || err }));
        }
        dispatch(closeModal());
      },
    }),
  ),
)(({ doDelete, ...rest }) =>
  <ModalWarning
    headerChildren='Confirm'
    message='Are you sure you want to delete this activity?'
    buttonChildren='Cancel'
    {...rest}
    extraButtons={[
      <button className='btn btn-danger' onClick={doDelete}>Delete</button>,
    ]}
  />
);

ConfirmActivityDelete.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmActivityDelete;
