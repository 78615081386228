/* global API */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import financeslineitemResource from '../resource/financeslineitem';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';

class FactoringLineItemsPaginatedResource extends PaginatedResource {
  fetch(data, next) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isFetching) {
        return Promise.resolve();
      }

      if (next === undefined) {
        let funding_request_id;
        if (data.id) {
          funding_request_id = data.id;
        }
        next = `${API.host}/funding_requests/${funding_request_id}/items/`;
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: data,
      });

      try {
        const res = await dispatch(APIFetch(next, {
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.FETCH_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  reduce(state = this.getDefaultState(), action) {
    switch (action.type) {
      case this.actions.FETCH_REQUEST_SUCCESS: {
        const results = action.payload;
        const new_items = [
          ...state[this.options.itemsKey],
          ...results.map(this.parse).reduce((acc, curr) => {
            if (state[this.options.itemsKey].includes(curr)) {
              return acc;
            }
            acc.push(curr);
            return acc;
          }, []),
        ];
        return {
          ...state,
          isFetching: false,
          [this.options.itemsKey]: new_items,
        };
      }
    }
    return super.reduce(state, action);
  }
}


const financeslineitemsPaginatedResource = new FactoringLineItemsPaginatedResource('financeslineitems', {
  mountPoint: ['admin', 'financeslineitems'],
  url: new UrlAssembler('blah.com'),
  baseResource: financeslineitemResource,
});


export default financeslineitemsPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = financeslineitemsPaginatedResource;
