/**
 * Component for displaying an input with autocomplete
 * @module components/pure/Autocomplete
 * @since 3.0.1
 */
import 'styles/Autocomplete';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';

import storage from 'datatypes/storage';

import MaterialIcon from 'components/pure/MaterialIcon';
import ExtraInfoCard from './ExtraInfoCard';
import { debounce } from 'lodash';


export const AutocompleteDropdownItem = ({ icon = 'place', value, children, iconProps, ...rest }) =>
  <div
    role='button'
    {...rest}
    className={cs('pac-item', rest.className)}
  >
    <MaterialIcon size={20} name={icon} className='pac-icon' {...iconProps} />
    <span className='pac-item-query'>{children}</span>
  </div>
  ;

export const AutocompleteHistoryDropdownItem = props =>
  <AutocompleteDropdownItem
    icon='access_time'
    {...props}
  />
  ;

class Autocomplete extends React.Component {

  constructor(props) {
    super(props);
    let editingValue;
    if (props.hasOwnProperty('value')) {
      editingValue = props.formatValue(props.value);
      if (!editingValue) {
        editingValue = '';
      }
    }
    this.state = {
      editingValue,
      hasFocus: false,
      suggestions: null,
      history: this.getHistory(),
    };

    this.input = null;
    this.hasEditedSinceSelect = false;
    this.isSelectingFromDropdown = false;

    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.hasOwnProperty('value')) {
      let new_value = nextProps.formatValue(nextProps.value);
      if (!new_value) {
        new_value = '';
      }
      if (new_value !== this.state.editingValue) {
        this.setState({ editingValue: new_value });
      }
    }
  }

  componentWillUnmount() {
    this.input = null;

    this.onFocus = null;
    this.onBlur = null;
    this.onKeyDown = null;
    this.onChange = null;
  }

  static propTypes = {
    value: PropTypes.any, // the actual value of the input
    emptyValue: PropTypes.string, // the value to pass to onSelect when the input has been emptied
    containerProps: PropTypes.object, // props to pass to the containing element
    dropdownContainerProps: PropTypes.object, // props to pass to the element containing dropdown items
    onSelect: PropTypes.func, // a function that's called when a 'final' suggesion is selected
    getSuggestions: PropTypes.func.isRequired, // a function that, when passed a string to search by, returns a promise that resolves in an array of suggestions
    getSuggestionDetails: PropTypes.func, // a function that, when passed a previously-returned suggestion, returns a promise that resolves in a 'final' suggestion
    formatValue: PropTypes.func, // a function that, when passed the value, formats it for the input
    historyKey: PropTypes.string, // the key to look for saved autocomplete history with
    historyLimit: PropTypes.number, // the number of values to keep saved in history
    historyIsEqual: PropTypes.func, // a function to compare two items in history to see if they are equal
    extraInfo: PropTypes.array,

    DropdownItem: PropTypes.oneOfType([PropTypes.element, PropTypes.func]), // the element to render an item in the suggestion dropdown list
    HistoryDropdownItem: PropTypes.oneOfType([PropTypes.element, PropTypes.func]), // the element to render an item in the suggestion history dropdown list
    refreshSavedData: PropTypes.func,
    // input event handlers
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onKeyDown: PropTypes.func,
    onChange: PropTypes.func,
  };

  static defaultProps = {
    emptyValue: '',
    getSuggestionDetails: i => i,
    formatValue: i => i,
    historyLimit: 3,
    historyIsEqual: isEqual,
    extraInfo: [],
    DropdownItem: AutocompleteDropdownItem,
    HistoryDropdownItem: AutocompleteHistoryDropdownItem,
  }

  getSuggestions = debounce(async val => {
    const { getSuggestions } = this.props;
    try {
      const suggestions = await getSuggestions(val);
      this.setState({ suggestions });
    }
    catch (err) {
      console.warn(err);
    }
  }, 500, { leading: true })

  async getSuggestionDetails(suggestion) {
    const { getSuggestionDetails } = this.props;
    try {
      const details = await getSuggestionDetails(suggestion);
      this.updateHistory(suggestion);
      this.props.onSelect(details);
    }
    catch (err) {
      console.warn(err);
    }
  }

  static MAJOR_KEY = 'autocomplete-history';
  getHistory() {
    const key = `${Autocomplete.MAJOR_KEY}-${this.props.historyKey}`;
    let history;
    try {
      const hist_string = storage.getItem(key);
      history = JSON.parse(hist_string);
      if (!Array.isArray(history)) {
        throw new Error(`malformed autocomplete history:\n${hist_string}`);
      }
      if (this.props.validateHistory) {
        try {
          this.props.validateHistory(history);
        }
        catch (err) {
          throw new Error(`malformed autocomplete history:\n${hist_string}`);
        }
      }
    }
    catch (err) {
      storage.removeItem(key);
      history = null;
    }
    return history;
  }

  updateHistory(value) {
    const key = `${Autocomplete.MAJOR_KEY}-${this.props.historyKey}`;
    let { history } = this.state;
    if (history) {
      const index = history.findIndex(history_value => this.props.historyIsEqual(value, history_value));
      if (index !== -1) {
        history.splice(index, 1);
      }
    }
    else {
      history = [];
    }
    history.unshift(value);
    if (history.length > this.props.historyLimit) {
      history.pop();
    }
    storage.setItem(key, JSON.stringify(history));
    this.setState({ history });
  }

  onFocus(e) {
    this.setState({ hasFocus: true });
    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    this.setState({ hasFocus: false });
    // we're forcably 'finalizing' the suggestion here if we've edited the input since the last finalization
    // onBlur is also triggered when we're selecting an item from the history dropdown, so don't finalize in that case
    if (this.hasEditedSinceSelect && !this.isSelectingFromDropdown && this.state.suggestions && this.state.suggestions.length) {
      this.getSuggestionDetails(this.state.suggestions[0]);
    }
    this.isSelectingFromDropdown = false;
    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  onKeyDown(e) {
    if (e.keyCode === 13) { // enter
      if (this.state.suggestions && this.state.suggestions.length) {
        this.getSuggestionDetails(this.state.suggestions[0]);
        this.input.blur();
      }
    }
    else if (e.keyCode === 8 && e.target.value === '') {
      if (this.props.onSelect) {
        this.props.onSelect(this.props.emptyValue);
      }
      this.hasEditedSinceSelect = false;
    }
    if (this.props.onKeyDown) {
      this.props.onKeyDown(e);
    }
  }

  onChange(e) {
    if (e.target.value !== '' && e.target.value !== this.state.editingValue) {
      this.getSuggestions(e.target.value);
    }
    this.hasEditedSinceSelect = true;
    if (e.target.value === '') {
      if (this.props.onSelect) {
        this.props.onSelect(this.props.emptyValue);
      }
      this.hasEditedSinceSelect = false;
    }
    this.setState({
      editingValue: e.target.value,
      suggestions: null,
    });
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onClickDropdown(val) {
    if (val === undefined) {
      return;
    }
    this.isSelectingFromDropdown = true;
    this.hasEditedSinceSelect = false;
    this.updateHistory(val);
    if (this.props.customInput) {
      this.setState({ hasFocus: false });
    }
    if (this.props.onSelect) {
      this.props.onSelect(val);
    }
  }

  render() {
    const {
      value = '',
      emptyValue,
      containerProps = {},
      dropdownContainerProps = {},
      onSelect,
      getSuggestions,
      getSuggestionDetails,
      formatValue,
      historyKey,
      historyLimit,
      historyIsEqual,

      DropdownItem,
      HistoryDropdownItem,

      onFocus,
      onBlur,
      onKeyDown,
      onChange,
      extraInfo = [],
      refreshSavedData,
      customInput,
      displayHistory = true,
      icon = false,
      ...rest
    } = this.props;
    const {
      editingValue,
      hasFocus,
      history,
      suggestions,
    } = this.state;

    const displayValue = (hasFocus ? editingValue : formatValue(value)) || '';
    let dropdown = null;
    if (hasFocus) {
      if (!displayValue) {
        if (historyKey && !isEmpty(history) && displayHistory) {
          dropdown = (
            <div
              {...dropdownContainerProps}
              className={cs('autocomplete-dropdown', dropdownContainerProps.className)}
            >
              {history.map((value, i) =>
                <HistoryDropdownItem
                  key={i}
                  value={value}
                  onMouseDown={async () => {
                    let newValue = value;
                    if (!isUndefined(refreshSavedData)) {
                      newValue = await (refreshSavedData(value.id));
                    }
                    this.onClickDropdown(newValue);
                  }}
                >
                  {formatValue(value)}
                </HistoryDropdownItem>
              )}
            </div>
          );
        }
      }
      else {
        if (isEmpty(suggestions) && hasFocus && this.hasEditedSinceSelect && !isEmpty(extraInfo)) {
          dropdown = (
            <div
              {...dropdownContainerProps}
              className={cs('autocomplete-dropdown', dropdownContainerProps.className)}
            >
              {extraInfo.map((value, i) =>
                <ExtraInfoCard
                  role='button'
                  className='pac-item'
                  key={i}
                  onMouseDown={() => value.action()}
                >
                  {value.extraInfo}
                </ExtraInfoCard>
              )}
            </div>
          );
        }
        else {
          dropdown = (
            <div
              {...dropdownContainerProps}
              className={cs('autocomplete-dropdown', dropdownContainerProps.className)}
            >
              {(suggestions || []).map((value, i) =>
                <DropdownItem
                  key={i}
                  value={value}
                  onMouseDown={() => this.onClickDropdown(value)}
                >
                  {formatValue(value)}
                </DropdownItem>
              )}
              {extraInfo.map((value, i) =>
                <ExtraInfoCard
                  role='button'
                  className='pac-item'
                  key={i}
                  onMouseDown={() => value.action()}
                >
                  {value.extraInfo}
                </ExtraInfoCard>
              )}
            </div>
          );
        }
      }
    }

    const CustomInput = customInput ? customInput : 'input';

    return (
      <div
        {...containerProps}
        className={cs('autocomplete', containerProps.className)}
      >
        {dropdown}
        <div
          className={cs({ "input-icons": Boolean(icon) })}
        >
          {Boolean(icon) && <MaterialIcon size={30} name={icon} className='icon' style={{ color: '#a1a1a7' }} />}
          <CustomInput
            {...rest}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onKeyDown={this.onKeyDown}
            onChange={this.onChange}
            ref={ref => this.input = ref}
            className={cs('form-control', rest.className, { "input-field": Boolean(icon) })}
            value={displayValue}
          />
        </div>
      </div>
    );
  }
}

export default Autocomplete;