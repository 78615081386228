import isPlainObject from 'lodash/isPlainObject';


const BRACKET = Symbol('BRACKET');
const DOT = Symbol('DOT');
const END = Symbol('END');

const convertFromDotNotation = obj => {

  const buckets = Object.create(null);
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      const first_special_character = key.search(/[\.\[]/);
      if (first_special_character === -1) {
        let bucket = buckets[key];
        if (!bucket) {
          bucket = buckets[key] = [];
        }
        bucket.push({
          key,
          value,
          type: END,
        });
        continue;
      }
      const special_character = key[first_special_character];
      if (special_character === '.') {
        const bucket_key = key.slice(0, first_special_character);
        const new_key = key.slice(first_special_character + 1);
        let bucket = buckets[bucket_key];
        if (!bucket) {
          bucket = buckets[bucket_key] = [];
        }
        bucket.push({
          key: new_key,
          value,
          type: DOT,
        });
        continue;
      }
      if (special_character === '[') {
        const bucket_key = key.slice(0, first_special_character);
        const new_key = key.slice(first_special_character + 1).replace(/\]/, '');
        let bucket = buckets[bucket_key];
        if (!bucket) {
          bucket = buckets[bucket_key] = [];
        }
        bucket.push({
          key: new_key,
          value,
          type: BRACKET,
        });
        continue;
      }
    }
  }

  const ret = {};

  for (const key in buckets) {
    const bucket = buckets[key];
    const recurse_obj = {};
    let recurse_obj_count = 0;
    bucket.forEach(({ key, value, type }) => {
      if (type === END) {
        ret[key] = value;
        return;
      }
      recurse_obj[key] = value;
      recurse_obj_count++;
    });
    if (recurse_obj_count) {
      let value = ret[key];
      if (!value) {
        value = ret[key] = {};
      }
      const recursed_obj = convertFromDotNotation(recurse_obj);
      if (isPlainObject(value) || Array.isArray(value)) {
        Object.assign(value, recursed_obj);
      }
      else {
        ret[key] = recursed_obj;
      }
    }
  }

  return ret;
};

export default convertFromDotNotation;
