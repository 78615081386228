/**
 * Component that manages displaying the Search pages
 * @module components/pure/Search
 * @since 3.0.0
 * @requires components/container/FetchingButton
 * @requires components/pure/MediaQuery
 * @requires components/pure/MobileFilter
 * @property {object} props
 * @property {object} props.resource - 'load' or 'truck', the type of resource being searched for
 * @property {element} props.Form - the form component to render
 * @property {element} props.SearchResults - the results component to render
 * @property {object} props.location - the current location
 * @property {string} props.basePath - the default path for this route, used to swap between filter view and normal view
 */
import 'styles/Search';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { browserHistory } from 'react-router-v4';
import capitalize from 'lodash/capitalize';

import FetchingButton from 'components/container/FetchingButton';
import MediaQuery from 'components/pure/MediaQuery';
import MobileFilter from 'components/pure/MobileFilter';


class Search extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.mobileSubmit = this.mobileSubmit.bind(this);
  }

  componentDidMount() {
    this.submit();
  }

  static propTypes = {
    resource: PropTypes.string.isRequired,
    mountPoint: PropTypes.arrayOf(PropTypes.string).isRequired,
    refresh: PropTypes.func,
    Form: PropTypes.func.isRequired,
    SearchResults: PropTypes.func.isRequired,
    basePath: PropTypes.string.isRequired,
    swapOriginAndDestination: PropTypes.func,
    buttonText: PropTypes.node,
  };

  submit() {
    const { form } = this;
    if (form) {
      if (typeof form.submit === 'function') {
        return form.submit();
      }
      if (typeof form.getWrappedInstance === 'function' && typeof form.getWrappedInstance().submit === 'function') {
        return form.getWrappedInstance().submit();
      }
    }
    console.warn(`[Search ${this.props.resource}] couldn't find a form to submit`);
  }

  mobileSubmit() {
    browserHistory.push(this.props.basePath);
    this.submit();
  }

  render() {
    const {
      resource,
      mountPoint,
      Form,
      SearchResults,
      swapOriginAndDestination,
      basePath,
      buttonText = 'SEARCH',
      refresh,
    } = this.props;

    const searchResults = <SearchResults />;
    const searchForm = <Form ref={ref => this.form = ref} />;

    const mobileView = /filter\/?$/.test(location.pathname) ?
      <MobileFilter
        onDoneClick={this.mobileSubmit}
        swapOriginAndDestination={swapOriginAndDestination}
        submitText={`Search ${capitalize(resource)}s`}
        >
        {searchForm}
      </MobileFilter>
      :
      <div className='search-mobile'>
        {/* need to mount the form so .submit() is available via reference on this.form */}
        <div>{searchResults}</div>
        <div className='hidden'>{searchForm}</div>
        <div className='search-mobile-buttons'>
          {
            typeof refresh === 'function' ?
              <button className='btn btn-default' onClick={refresh}>Refresh</button>
              : null
          }
          <button className='btn btn-darkblue' onClick={() => browserHistory.push(`${basePath}/filter`)}>Filter {capitalize(resource)}s</button>
        </div>
      </div>
      ;

    return (
      <div className='Search'>
        <MediaQuery
          mobile={mobileView}
          desktop={
            <div className='SearchDesktop clearfix'>
              <div className='col-xs-12 col-sm-3'>
                {searchForm}
                <div className='form-group clearfix'>
                  {swapOriginAndDestination ? <button className='btn btn-default' onClick={swapOriginAndDestination}>Reverse Search</button> : null}
                  <FetchingButton
                    className='btn btn-orange pull-right'
                    mountPoint={mountPoint}
                    onClick={this.submit}
                    >
                    {buttonText}
                  </FetchingButton>
                </div>
              </div>
              <div className='col-xs-12 col-sm-9'>
                {searchResults}
              </div>
            </div>
          }
        />
      </div>
    );
  }
}

export default Search;
