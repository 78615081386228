import React from 'react';
import PropTypes from 'prop-types';


const buttonStyle = {
  paddingTop: 1,
  paddingBottom: 1,
  fontSize: 13,
};

export const UserTableHideButtons = ({ id, hidePost, hideUser, user_id, self, ...rest }) =>
  <div {...rest}>
    {'Block:\u00a0'}
    <button className='btn btn-danger btn-danger-gray' style={buttonStyle} onClick={hidePost}>Post</button>
    {'\u00a0\u00a0'}
    <button className='btn btn-danger btn-danger-gray' style={buttonStyle} onClick={hideUser}>User</button>
  </div>
  ;

UserTableHideButtons.propTypes = {
  id: PropTypes.string.isRequired,
  hidePost: PropTypes.func.isRequired,
  hideUser: PropTypes.func.isRequired,
};

export default UserTableHideButtons;
