import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import { push } from 'react-router-redux';
import { resetPassword } from 'actions/user';
import { openModal } from 'actions/ui';

import Header from 'components/pure//Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export default compose(
  reduxForm({
    form: 'ResetPasswordForm',
    destroyOnUnmount: false,
    onSubmit(fields, dispatch) {
      resetPassword(fields)
        .then(json => {
          if (json && json.status === 'success') {
            dispatch(openModal('success', { message: 'We sent you an email with the password reset link. Thank you.' }));
            return dispatch(push('/login'));
          }
          let message;
          if (json && json.message) {
            message = json.message;
          }
          else {
            message = 'Couldn\'t reset your password. Unknown error.';
            console.warn('Unknown response', json);
          }
          return dispatch(openModal('error', { message }));
        })
      ;
    },
    validate(fields) {
      return validate(fields, {
        email: {
          presence: true,
          email: true,
        },
      });
    },
  }),
)(({ handleSubmit }) =>
  <div className='login'>
    <Header>Reset Password</Header>
    <div className='help-block'>We'll email you a link to reset your password</div>
    <Field name='email' component={LabeledInput} label='Email Address' type='email' />
    <div>
      <button onClick={handleSubmit} className='btn btn-orange'>Reset Password</button>
    </div>
  </div>
);
