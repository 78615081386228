/* global EMPTY_PLACEHOLDER */
import React, { Component } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { Field, reduxForm } from 'redux-form';
import withState from 'recompose/withState';
import get from 'lodash/get';
import { openModal } from 'actions/ui';
import FileInput from 'components/pure/form/inputs/FileInput';

import factoringdebtorPaginatedResource, { fetchReceivablesDebtorsStats, uploadReceivablesCSV } from 'actions/admin/factoring/debtor';
import MaterialIcon from 'components/pure/MaterialIcon';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import ResponsiveTable from 'components/pure/ResponsiveTable';

import ReceivablesDebtorPaymentsTable from 'components/container/ReceivablesDebtorPaymentsTable';

import formatPennies from 'helpers/formatPennies';

const constructTableCells = ({ data }) => [
  data.company_name,
  data.email,
  get(data, ['user.type'], EMPTY_PLACEHOLDER),
  formatPennies(data.outstanding_balance),
];

const TableRow = ({ item, selectedDebtorId = null, onViewHideClick, isBlock = false }) => {
  const debtorId = get(item, 'data.id', 0);
  const isSelected = selectedDebtorId === debtorId;
  return (
    <tbody>
      {!isBlock && (
        <tr>
          {constructTableCells(item).map((cell, i) => <td key={i}>{cell}</td>)}
          <Link to={`/admin/factoring/debtor/${debtorId}/payments`} target='_blank' rel='noopener noreferrer'>
            <button className='btn btn-green' style={{ marginTop: '8px' }}>
              <MaterialIcon name='attach_money' /> Add Payment
            </button>
          </Link>          <td>
            <button className='btn btn-orange' onClick={() => onViewHideClick(isSelected ? null : debtorId)}>
              {!isSelected ? 'View' : 'Hide'}
            </button>
          </td>
        </tr>
      )}
      {isBlock &&
        constructTableCells(item).map((cell, i) => (
          <tr key={i}>
            <td style={{ width: '50%' }}>{item.headers[i]}</td>
            <td style={{ width: '50%' }}>{cell}</td>
          </tr>
        ))}
      {isBlock && (
        <tr>
          <td style={{ width: '50%' }}>Actions</td>

          <td style={{ width: '50%' }}>
            <button className='btn btn-orange' onClick={() => onViewHideClick(isSelected ? null : debtorId)}>
              {!isSelected ? 'View' : 'Hide'}
            </button>
          </td>
        </tr>
      )}

      {isSelected &&
        debtorId && (
        <tr>
          <td colSpan={constructTableCells(item).length + 2}>
            <div style={{ maxHeight: 300, overflowY: 'auto' }}>
              <ReceivablesDebtorPaymentsTable debtorId={debtorId} />
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
};

const ReceivablesCSVUpload = compose(
  reduxForm({
    form: 'ReceivablesCSVUpload',
    async onSubmit(fields, dispatch) {
      try {
        await dispatch(uploadReceivablesCSV(fields.file));

        dispatch(openModal('success', { message: 'Successfully Uploaded the File! It may take up to 60 minutes to process the CSV Upload you\'ll be notified by email when it is done processing' }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to upload data.' }));
      }
    },
  }),
)(({ handleSubmit, submitting }) =>
  <div style={{ display: 'flex', alignItems: 'left', marginBottom: '2em' }}>
    <Field
      name='file'
      component={FileInput}
      label='Upload CSV'
    />
    <button className='btn-transaction' disabled={submitting} type='submit' onClick={handleSubmit} style={{ margin: '0 0 0 1em' }}>Upload</button>
  </div>
);

const DebtorTable = compose(
  withState('selectedDebtorId', 'setSelectedDebtorId', null),
  connect(
    state => ({
      ids: state.admin.factoring.debtor.ids,
      isFetching: state.admin.factoring.debtor.isFetching,
      debtors: state.resource.factoringdebtor,
      count: state.admin.factoring.debtor.count,
      limit: state.admin.factoring.debtor.limit,
      currentOrder: state.admin.factoring.debtor.ordering,
    }),
    {
      fetchNext: factoringdebtorPaginatedResource.fetchNext,
      fetchPage: factoringdebtorPaginatedResource.fetchPage,
      sort: factoringdebtorPaginatedResource.sort,
    }
  )
)(({ ids, isFetching, limit, count, debtors, fetchPage, fetchNext, selectedDebtorId, setSelectedDebtorId, sort, currentOrder }) => (
  <ResponsiveTable
    isFetching={isFetching}
    fetchNext={fetchNext}
    fetchPage={fetchPage}
    withPagination={true}
    count={count}
    limit={limit}
    sortable={true}
    sort={sort}
    currentOrder={currentOrder}
    headers={[
      { text: 'Debtor', ordering: 'debtor' },
      { text: 'Email', ordering: 'email' },
      { text: 'User Type', ordering: false },
      { text: 'Outstanding Balance', ordering: 'outstanding_balance' },
      { text: 'Details', ordering: false },
      { text: 'Funding Requests', ordering: false },
    ]}
    placeholder='No results'
    items={ids.map(id => debtors[id])}
    TableItem={item => (
      <TableRow item={item} onViewHideClick={debtorId => setSelectedDebtorId(debtorId)} selectedDebtorId={selectedDebtorId} />
    )}
    BlockTableItem={item => (
      <TableRow isBlock={true} item={item} onViewHideClick={debtorId => setSelectedDebtorId(debtorId)} selectedDebtorId={selectedDebtorId} />
    )}
  />
));

class ReceivablesDebtorTable extends Component {
  state = {
    searchFilter: '',
  };

  componentDidMount() {
    this.props.update(this.state.searchFilter);
    this.props.fetchStats();
  }

  handleSearchChange = event => {
    this.setState({
      searchFilter: event.target.value,
    });
  };

  handleSearchSubmit = () => {
    this.props.update(this.state.searchFilter);
  };

  handleSearchKeyDown = event => {
    if (event.keyCode === 13) {
      this.handleSearchSubmit();
    }
  };

  render() {
    const { stats } = this.props;

    return (
      <div>
        <div style={{ display: 'flex' }}>
          <ReceivablesCSVUpload />
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: '2em', marginLeft: 'auto' }}>
            <LabeledInput
              value={this.state.searchFilter}
              onChange={this.handleSearchChange}
              placeholder='Debtor'
              containerProps={{ style: { marginBottom: 0 } }}
              onKeyDown={this.handleSearchKeyDown}
            />
            <button className='btn btn-primary' onClick={this.handleSearchSubmit} style={{ margin: '0 0 0 1em' }}>
              Search
            </button>
          </div>
        </div>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h4 style={{ fontWeight: 'bold' }}>
              Number of Receivables: <span className='text-success'>{stats.total_receivables}</span>
            </h4>
            <h4 style={{ fontWeight: 'bold' }}>
              Total Balance: <span className='text-success'>{formatPennies(stats.total_balance)}</span>
            </h4>
          </div>
          <DebtorTable />
        </div>
      </div>
    );
  }
}

const ConnectedReceivablesDebtorTable = compose(
  withState('stats', 'setStats', { total_receivables: 0, total_balance: 0 }),
  connect(null, (dispatch, ownProps) => ({
    async fetchStats() {
      const stats = await dispatch(fetchReceivablesDebtorsStats());
      ownProps.setStats(stats);
    },
    update(companyName) {
      dispatch(factoringdebtorPaginatedResource.clear());
      return dispatch(
        factoringdebtorPaginatedResource.fetch({
          receivables: 'true',
          company_name: companyName,
        })
      );
    },
  })),
)(ReceivablesDebtorTable);

export default ConnectedReceivablesDebtorTable;
