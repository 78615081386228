/* global EMPTY_PLACEHOLDER */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import { head } from 'lodash';
import { USER_TYPE } from 'helpers';
import { openModal } from 'actions/ui';

import { edit } from 'actions/resource/factoringpayment';
import factoringpaymentPaginatedResource from 'actions/admin/factoring/payment';
import PaymentsTable from './PaymentsTable';
import RouteTabs from './RouteTabs';
import Header from 'components/pure/Header';
import formatError from 'helpers/formatError';
import getSelfType from 'selectors/getSelfType';
import StatusField from './StatusField';
import SetStatusButton from './SetStatusButton';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isAnyAdmin from 'selectors/isAnyAdmin';
const FORM_NAME = 'FactoringPaymentsAdmin';
const formSelector = formValueSelector(FORM_NAME);

const FactoringPaymentsAdmin = compose(
  connect(
    state => ({
      type: getSelfType(state),
      isDispatchBroker: isDispatchBroker(state),
      isAnyAdmin: isAnyAdmin(state),
      status: formSelector(state, 'status'),
    }),
    dispatch => ({
      clear: () => dispatch(factoringpaymentPaginatedResource.clear()),
      fetch: (...args) => dispatch(factoringpaymentPaginatedResource.fetch(...args)),
      setStatus: status => dispatch(change(FORM_NAME, 'status', status)),
    }),
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    }),
  ),
  mapProps(props => ({
    ...props,
    update() {
      props.clear();
      let status = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
      if (status === '' && props.type === USER_TYPE.FACTORING_REMOTE_ADMIN){
        status = 'document_review';
      }
      let needs_admin_review;
      if (status === 'admin_review') {
        status = undefined;
        needs_admin_review = true;
      }
      return props.fetch({
        status,
        needs_admin_review,
      });
    },
    initialValues: {
      status: props.isDispatchBroker && !props.isAnyAdmin ? 'pending' : 'new_noa',
    },
  })),
  // update whenever this form changes (either the route changes and we want to update `status`, or the admin changes)
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate(prevProps) {
      if (this.props.status === prevProps.status) {
        this.props.update();
      }
    },
  }),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, props) {
      try {
        const setStatus = fields.status;
        delete fields.status;
        for (const funding_request_id in fields) {
          if (fields.hasOwnProperty(funding_request_id)) {
            if (fields[funding_request_id] === true) {
              await dispatch(edit(funding_request_id, { status: setStatus }, { bulk: true }));
            }
          }
        }
        dispatch(openModal('success', { message: `Successfully set status to ${setStatus}` }));
        props.update();
        props.reset();
        props.setStatus(setStatus);
      }
      catch (err) {
        dispatch(openModal('error', { message: formatError(err, 'Unable to Submit Status Change') }));
      }
    },
  }),
  lifecycle({
    componentWillUpdate(nextProps) {
      if (this.props.location.pathname !== nextProps.location.pathname) {
        const { status } = nextProps;
        this.props.reset();
        this.props.setStatus(status);
      }
    },
  }),
)(({ type, submitting, handleSubmit, location, isDispatchBroker, isAnyAdmin }) => {
  const path = head(location.pathname.split('/').reverse());
  return (
    <div className='col-fill' style={{ padding: '0 1em' }}>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          >
          <div>
            <Header>Transactions</Header>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '15px',
            }}
            >
            <div
              style={{
                minWidth: '300px',
              }}
              >
              <StatusField userType={type} path={path} isDispatchBroker={isDispatchBroker} isAnyAdmin={isAnyAdmin} />
            </div>
            <div>
              <SetStatusButton path={path} handleSubmit={handleSubmit} userType={type} submitting={submitting} />
            </div>
          </div>
        </div>
      </form>
      <RouteTabs userType={type} path={path} isDispatchBroker={isDispatchBroker} isAnyAdmin={isAnyAdmin} />
      <PaymentsTable path={path} />
    </div>
  );
});



export default FactoringPaymentsAdmin;
