/**
 * Component for viewing a list of users given their user type and whether they're subscribed
 * @module components/container/UserTypeList
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/user
 * @requires components/pure/CenteredColumn
 * @requires components/pure/Header
 * @requires components/pure/RouteTabs
 * @requires components/pure/ResponsiveTable
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import lifecycle from 'recompose/lifecycle';
import moment from 'moment';

import createTableItems from 'helpers/createTableItems';

import { clear, fetch, fetchNext } from 'actions/search/user';

import Header from 'components/pure/Header';
import RouteTabs from 'components/pure/RouteTabs';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const UserTypeListTableComponents = createTableItems(
  ({ item }) => [
    <Link to={`/admin/user/search/${item.data.id}`}>{item.data.email}</Link>,
    item.data.contact_name,
    <span style={{ maxWidth: 200, display: 'inline-block', wordWrap: 'break-word' }}>
      {item.data.contact_phone}
    </span>,
    item.data.assigned_admin_name,
    item.data.factoring ? item.data.factoring.factoring_approved : '',
    <Link to={`/admin/user/search/${item.data.id}`}>{item.data.contact_email}</Link>,
    item.data.user_type,
    moment(item.data.created).format('l LT'),
    moment(item.data.last_login).format('l LT'),
    Object.keys(item.data.activity).map(key => {
      const { requested_at } = item.data.activity[key];
      return (
        <div key={key}>{key}: {moment(requested_at).format('l LT')}</div>
      );
    }),
    item.data.state,
    item.data.zip,
    <span style={{ maxWidth: 200, display: 'inline-block', wordWrap: 'break-word' }}>
      {item.data.source || 'Unknown'}
    </span>,
    <Link to={`/admin/user/search/${item.data.id}`}>
      <button className='btn btn-orange'>More</button>
    </Link>,
  ],
  compose(
    connect(
      (state, { id }) => ({
        item: state.resource.usersearch[id],
      }),
    ),
  ),
  {
    mobile: () => ({
      style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
    }),
  },
);

export const UserTypeListTable = compose(
  connect(
    state => ({
      count: state.search.user.count,
      items: state.search.user.ids.map(id => ({ id })),
      isFetching: state.search.user.isFetching,
    }),
    {
      fetchNext,
    },
  ),
)(({ headerText, count, containerProps, ...rest }) =>
  <div {...containerProps}>
    <Header>{headerText} <strong>({count})</strong></Header>
    <ResponsiveTable
      {...rest}
      containerProps={{
        className: 'col-fill',
      }}
      desktopContainerProps={{
        style: { overflow: 'auto' },
        className: 'table-condensed',
      }}
      headers={['Email', 'Name', 'Phone #', 'Assigned', 'HP Status', 'Contact Email', 'User Type', 'Created At', 'Last Login', 'Last Posted', 'State', 'Zip Code', 'Source', 'More']}
      placeholder='No results'
      TableItem={UserTypeListTableComponents.TableItem}
      BlockTableItem={UserTypeListTableComponents.BlockTableItem}
    />
  </div>
);

const LoadMoreButton = compose(
  connect(
    state => state.search.user,
    {
      fetchNext,
    },
  ),
)(({ next, isFetching, fetchNext }) => next && !isFetching ? <button className='btn btn-orange' onClick={fetchNext}>Load More</button> : null);

const fetchUserTypes = function fetchUserTypes(nextProps = this.props) {
  const { clear, fetch, user_type, contact_status } = nextProps;
  const data = {
    user_type,
    contact_status,
  };
  if (data.user_type === 'broker_paid') {
    data.user_type = 'broker';
    data.has_active_subscription = true;
  }
  else if (data.user_type === 'carrier_paid') {
    data.user_type = 'carrier';
    data.has_active_subscription = true;
  }
  else if (data.user_type === 'shipper_paid') {
    data.user_type = 'shipper';
    data.has_active_subscription = true;
  }
  else if (data.user_type === 'dispatcher_paid') {
    data.user_type = 'dispatcher';
    data.has_active_subscription = true;
  }
  clear();
  fetch(data);
};

const UserTypeList = compose(
  connect(
    null,
    {
      clear,
      fetch,
    },
  ),
  lifecycle({
    shouldComponentUpdate(nextProps) {
      return nextProps.user_type !== this.props.user_type;
    },
    componentWillMount: fetchUserTypes,
    componentWillReceiveProps: fetchUserTypes,
  }),
)(props =>
  <div style={{ padding: '0 1em' }}>
    <RouteTabs
      routes={[
        ['../no_contact', 'No Contact'],
        ['../first', 'First Contact'],
        ['../second', 'Second Contact'],
        ['../third', 'Third Contact'],
      ]}
    />
    <UserTypeListTable
      headerText={
        <span>
          <span className='text-capitalize'>{props.user_type.replace('_', ' ')}</span> User List
        </span>
      }
    />
    <LoadMoreButton />
  </div>
);

export default UserTypeList;
