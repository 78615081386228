import React from "react";
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import GenerateTokenInput from './components/GenerateTokenInput';
import { getAPIToken, generateAPIToken } from "../../../actions/settings/API";
import { openModal } from "../../../actions/ui";
import { lifecycle, withState } from "recompose";

export default compose(
    withState('viewToken', 'setViewToken', false),
    withState('token', 'setToken', ''),
    connect(null, {
        getAPIToken,
        generateAPIToken,
        openModal
     }
    ),
    lifecycle({
        async componentDidMount() {
            const { token } = await this.props.getAPIToken();
            this.props.setToken(token);
        }
    })
)(props => {
    return <CenteredColumn
        xs={12}
        sm={10}
        className='col-fill'
    >
        <div className='col-xs-12' style={{ textAlign: 'left', float: 'none', display: 'inline-block' }}>
            <div className='clearfix'>
                <Header style={{ display: 'inline-block', marginTop: 0 }}>API</Header>
            </div>
        </div>
        <div className='col-xs-12'>
            <p>Comfreight's API allows you to integrate with our platforms.</p>
            <p>For more information, Please refer to our <a onClick={() => window.open('http://docs.comfreight.com/', '_blank').focus() }>API documentation</a>.</p>
        </div>
        <div className='col-xs-12 '>
            <GenerateTokenInput {...props} />
        </div>
    </CenteredColumn>
})