import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';
import { Link } from 'react-router-v4';
import { USER_TYPE } from 'helpers';
import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import DebtorSimilarityForm from 'components/pure/form/DebtorSimilarityForm';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import createTableItems from 'helpers/createTableItems';
import formatAddress from 'helpers/formatAddress';
import getSelfType from 'selectors/getSelfType';

const getStatusView = approvalStatus => {
  if (approvalStatus === 'approved') {
    return (
      <div className='text-success'>
        <b>HAUL PAY APPROVED</b>
      </div>
    );
  }
  if (approvalStatus === 'declined') {
    return <div className='text-error'>{approvalStatus.toUpperCase()}</div>;
  }
  return <div className='text-warning'>{String(approvalStatus).toUpperCase()}</div>;
};

const TableItems = createTableItems(({ data }) => {
  const name = data.dba || get(data, 'user.dba') || data.company_name;
  const hasNoasData =
    data.hasOwnProperty('noa_active_count') && data.hasOwnProperty('noa_placed_count') && data.hasOwnProperty('noa_sent_count');

  return [
    name,
    data.address ? formatAddress(data.address) : '-',
    data.mc ? data.mc : '-',
    data.email ? data.email : '-',
    getStatusView(data.credit_approved),
    hasNoasData ? (
      <div>
        Sent: {data.noa_sent_count} Placed: {data.noa_placed_count} Active: {data.noa_active_count}
      </div>
    ) : (
      '-'
    ),
    data.hasOwnProperty('funding_request_count') ? data.funding_request_count : '-',
    <div>
      <Link target='_blank' to={`/admin/factoring/debtor/${data.id}`} title='View debtor profile'>
        <MaterialIcon style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', marginRight: '0.5em' }} name='account_circle' />
      </Link>
    </div>,
  ];
});

export default compose(
  connect(
    state => {
      return {
        user_type: getSelfType(state),
      };
    }
  ),
  ResourceBy('factoringdebtor', 'childrenfactoringdebtor'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
)(({ id, items, user_type }) => {
  if (items.length) {
    if (user_type === USER_TYPE.ADMIN || user_type === USER_TYPE.SUB_ADMIN) {
      return (
        <div>
          <div>
            <DebtorSimilarityForm id={id} />
          </div>
          <div>
            <h4> Branch Offices </h4>
            <ResponsiveTable
              containerProps={{
                className: 'col-fill',
              }}
              withPagination={true}
              limit={5}
              isFetching={false}
              headers={[
                'Debtor',
                'Address',
                'MC#',
                'Email',
                'HP Status',
                'NOA\'s',
                'Funding Requests',
                'Actions',
              ]}
              placeholder='No Branch Offices'
              TableItem={TableItems.TableItem}
              BlockTableItem={TableItems.BlockTableItem}
              items={items.map(({ data }) => ({
                items: items,
                data: data,
              }))}
              style={{
                fontSize: 'smaller',
              }}
            />
          </div>
        </div>
      //   <div>
      //     <h4>Branch Offices</h4>
      //     {items.map(({ data }) =>
      //       <Link key={data.id} to={`/admin/factoring/debtor/${data.id}`}>
      //         <DebtorCardSmall debtor={data} />
      //       </Link>
      //     )}
      //   </div>
      );
    }
  }

  return (
    <div>
      <DebtorSimilarityForm id={id} />
    </div>

  );
});
