import React from 'react';
import { compose } from 'redux-v3';

import Tooltip from 'components/pure/Tooltip';
import MaterialIcon from 'components/pure/MaterialIcon';

export default compose(
)(() =>
  <Tooltip
    text='This carrier has been verified for liability insurance, business legitimacy and authority compliance. We still recommend verifying cargo insurance and other requirements with the carrier as needed.'
    >
    <div
      style={{
        display: 'inline-block',
      }}
      >
      <MaterialIcon name='verified_user' /> Haul Pay Verified Carrier
    </div>
  </Tooltip>
);
