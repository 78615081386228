import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import setDisplayName from 'recompose/setDisplayName';
import shallowEqual from 'recompose/shallowEqual';
import get from 'lodash/get';

import * as qaByTruckResource from 'actions/resourceBy/truck/qa';
import * as haulPayLoadByUserResource from 'actions/resourceBy/user/haulPayLoad';
import * as qaByLoadResource from 'actions/resourceBy/load/qa';
import * as matchByTruckResource from 'actions/resourceBy/truck/match';
import * as truckByUserResource from 'actions/resourceBy/user/truck';
import * as truckHistoryByUserResource from 'actions/resourceBy/user/truckhistory';
import * as bidByLoadResource from 'actions/resourceBy/load/bid';
import * as loadByUserResource from 'actions/resourceBy/user/load';
import * as loadHistoryByUserResource from 'actions/resourceBy/user/loadhistory';
import * as loadreverseByLoadResource from 'actions/resourceBy/load/loadreverse';
import * as ratingByUserResource from 'actions/resourceBy/user/rating';
import * as factoringclientnoteByFactoringclient from 'actions/resourceBy/factoringclient/factoringclientnote';
import * as usernoteByUserResource from 'actions/resourceBy/user/note';
import * as factoringpaymentByUserResource from 'actions/resourceBy/user/factoringpayment';
import * as savedloadByUserResource from 'actions/resourceBy/user/savedload';
import * as savedtruckByUserResource from 'actions/resourceBy/user/savedtruck';
import * as dispatchedloadByUserResource from 'actions/resourceBy/user/dispatchedload';
import * as onboarddebtorByUserResource from 'actions/resourceBy/user/onboarddebtor';
import * as factoringpaymentnoteByFactoringpaymentResource from 'actions/resourceBy/factoringpayment/factoringpaymentnote';
import * as similarfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/similarfactoringdebtor';
import * as childrenfactoringdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/childrenfactoringdebtor';
import * as lineitemsdebtorByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/lineitemsdebtor';
import wrapDisplayName from 'components/hoc/wrapDisplayName';


const AVAILABLE_RESOURCES = {
  truck: {
    qa: qaByTruckResource,
    match: matchByTruckResource,
  },
  user: {
    truck: truckByUserResource,
    truckhistory: truckHistoryByUserResource,
    load: loadByUserResource,
    haulPayLoad: haulPayLoadByUserResource,
    loadhistory: loadHistoryByUserResource,
    rating: ratingByUserResource,
    note: usernoteByUserResource,
    factoringpayment: factoringpaymentByUserResource,
    savedload: savedloadByUserResource,
    savedtruck: savedtruckByUserResource,
    dispatchedload: dispatchedloadByUserResource,
    onboarddebtor: onboarddebtorByUserResource,
  },
  load: {
    qa: qaByLoadResource,
    bid: bidByLoadResource,
    loadreverse: loadreverseByLoadResource,
  },
  factoringclient: {
    factoringclientnote: factoringclientnoteByFactoringclient,
  },
  factoringpayment: {
    factoringpaymentnote: factoringpaymentnoteByFactoringpaymentResource,
  },
  factoringdebtor: {
    similarfactoringdebtor: similarfactoringdebtorByFactoringdebtorResource,
    childrenfactoringdebtor: childrenfactoringdebtorByFactoringdebtorResource,
    lineitemsdebtor: lineitemsdebtorByFactoringdebtorResource,
  },
};

const DEFAULT_OPTIONS = {
  idPropName: ['id'],
  resourcePropName: 'item',
  shouldClearBeforeFetch: false,
};

const CLASSNAME = 'ResourceBy';


export default (resourceBy, resource, options) => {

  if (AVAILABLE_RESOURCES[resourceBy] === undefined || AVAILABLE_RESOURCES[resourceBy][resource] === undefined) {
    throw new TypeError(`Unimplemented resourceBy "${resourceBy}" passed to ${CLASSNAME}: "${resource}"`);
  }

  options = { ...DEFAULT_OPTIONS, ...options };

  return WrappedComponent => {
    class ResourceBy extends Component {

      componentDidMount() {
        if (options.shouldClearBeforeFetch) {
          this.props.actions.clear();
        }
        this.props.actions.fetch(this.props.fetchArgs);
      }

      componentWillUpdate(nextProps) {
        try {
          if (!nextProps.item || !shallowEqual(this.props.fetchArgs, nextProps.fetchArgs)) {
            if (options.abortRequest) {
              this.props.actions.abortRequest();
            }
            setTimeout(() => {
              if (nextProps.actions.clear) {
                nextProps.actions.clear();
              }
              nextProps.actions.fetch(nextProps.fetchArgs);
            }, 200);
          }
        } catch (e) {
          console.log("ERROR", e)
        }
      }

      static propTypes = {
        item: PropTypes.object,
        actions: PropTypes.shape({
          fetch: PropTypes.func.isRequired,
        }).isRequired,
      };

      render() {
        const { item, actions, ownProps } = this.props;
        return React.createElement(WrappedComponent, {
          ...ownProps,
          actions,
          [options.resourcePropName]: item,
        });
      }
    }

    return compose(
      setDisplayName(CLASSNAME),
      connect(
        (state, ownProps) => ({
          id: get(ownProps, options.idPropName),
          item: state.resourceBy[resourceBy][resource][get(ownProps, options.idPropName)],
        }),
        (dispatch, ownProps) => {
          const id = get(ownProps, options.idPropName);
          const actions = AVAILABLE_RESOURCES[resourceBy][resource];
          const bound = {};
          for (const key in actions) {
            bound[key] = (...args) => dispatch(actions[key](id, ...args));
          }
          return {
            ...bound,
            fetch: ['load', 'loadhistory'].includes(resource) ? (...args) => setTimeout(() => bound.fetch(...args), 2000) : bound.fetch,
            abortRequest: () => actions.abortRequest(id)
          };
        },
        (stateProps, dispatchProps, { fetchArgs, ...ownProps }) => ({
          item: stateProps.item,
          actions: dispatchProps,
          fetchArgs,
          ownProps,
        }),
      ),
      wrapDisplayName(CLASSNAME),
    )(ResourceBy);
  };
};
