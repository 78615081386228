/**
 * Component to display a table of hidden users
 * @module components/pure/HiddenUsers
 * @since 3.0.0
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {object} props.isFetching - whether we're currently fetching the blocked users
 * @property {object} props.err - an error with fetching the blocked users, if any
 * @property {object} props.data
 * @property {string} props.data.id - the ID of this 'relationship'
 * @property {string} props.data.to_user - the user being blocked
 * @property {string} props.data.to_user_dba - the dba of the user being blocked
 * @property {function} props.unHideUser - the function to call to unhide this user
 */
import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/pure/Spinner';


const HiddenUsers = ({ isFetching, err, data, unHideUser }) =>
  err ?
    <div className='alert alert-danger'>Error fetching blocked users: {err}</div>
    :
    isFetching ?
      <Spinner />
      :
      <table className='table'>
        {data.map(relationship =>
          <tbody key={relationship.id}>
            <tr>
              <td>{relationship.to_user_dba}</td>
              <td><button className='btn btn-danger' onClick={() => unHideUser(relationship.id)}>Unblock</button></td>
            </tr>
          </tbody>
        )}
      </table>
  ;

HiddenUsers.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  err: PropTypes.any,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    to_user: PropTypes.string.isRequired,
    to_user_dba: PropTypes.string.isRequired,
  })).isRequired,
  unHideUser: PropTypes.func.isRequired,
};

export default HiddenUsers;
