/**
 * Displays a header
 * @module components/pure/Header
 * @since 3.0.0
 * @property {object} props
 * @property {node|node[]} children
 * @property {any} [props....rest] - props passed to containing div component
 */
import 'styles/Header';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const Header = ({ children, small, hr, ...rest }) =>
  <div
    {...rest}
    className={cs(
      'header',
      rest.className,
      {
        'header-small': small,
        'header-hr': hr,
      },
    )}
    >
    {children}
  </div>
  ;

Header.propTypes = {
  small: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

export default Header;
