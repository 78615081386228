/**
 * Actions relating to the factoringdebtor resource
 * @module actions/resource/factoringdebtor
 * @since 3.0.1
 * @requires datatypes/ControlledResource
 */
/* global API */
import ControlledResource from 'datatypes/ControlledResource';
import Resource from 'datatypes/Resource';
import FetchError from 'datatypes/FetchError';
import APIFetch from 'datatypes/APIFetch';
import getSelf from 'selectors/getSelf';
import { USER_TYPE } from 'helpers';

import factoringdebtorByfactoringclient from 'actions/resourceBy/factoringclient/factoringdebtor';
import { fetchFactoringProfile } from 'actions/user';

/**
 * Singleton for our debtor Ressource
 */
export const factoringdebtorResource = new ControlledResource('factoringdebtor', {
  url: id => `admin/debtors/${id}`,
});
class FactoringDebtorResourceUSER extends Resource {
  fetch(id) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.fetchRequest(id));

      return dispatch(APIFetch(`${API.host}/factoring/debtors/id/?id=${id}`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch(this.fetchRequestFailure(id, err));
          return Promise.reject(err);
        })
        .then(json => dispatch(this.fetchRequestSuccess(id, json)))
        ; // eslint-disable-line indent
    };
  }
}

export const factoringdebtorResourceUSER = new FactoringDebtorResourceUSER('factoringdebtor');

export const {
  getItem,
  fetchIfNeeded,
  create,
  edit,
  transfer,
  doDelete,
} = factoringdebtorResource;
export default factoringdebtorResource;


export const userCreateDebtor = data => async (dispatch, getState) => {
  let state = getState();
  const res = await global.fetch(`${API.host}/factoring/debtors/add/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${state.user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await res.json();
  if (res.status !== 201) {
    throw new FetchError(res.status, json);
  }
  if (!USER_TYPE.IS_ADMIN(getSelf(state).data.type) && !USER_TYPE.IS_ALADDIN_ADMIN(getSelf(state).data.type)) {
    await dispatch(fetchFactoringProfile());
    state = getState();
    const user_factoringdebtor_resource = factoringdebtorByfactoringclient.getResources(state.user.factoring.data.id);
    dispatch({
      type: user_factoringdebtor_resource.actions.CREATE_REQUEST_SUCCESS,
      payload: json,
    });
  }
  return json;
};
