import getCurrentLocation from './getCurrentLocation';
import getReverseGeocode from './getReverseGeocode';

const getNearestCity = async () => {
  const currentLocation = await getCurrentLocation();
  const reverseGeocode = await getReverseGeocode(
    currentLocation.coords.latitude,
    currentLocation.coords.longitude
  );
  return reverseGeocode[0];
};

export default getNearestCity;
