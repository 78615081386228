/**
 * Component for the MenuItems displayed in the left-hand side Menu. Wraps the react-router Link and IndexLink components
 * @module components/container/MenuItem
 * @since 3.0.0
 * @requires helpers
 * @requires actions/ui
 * @property {object} props
 * @property {string} props.href - the href to link to
 * @property {function} [props.onClick] - click handler for this MenuItem
 * @property {node|node[]} props.children - children nodes for this element
 * @property {boolean} [props.doNotCloseOnClick] - don't trigger the Menu close action when this MenuItem is clicked
 */
import 'styles/MenuItem';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import cs from 'classnames';

import { setSidebarOpen } from 'actions/ui';
import { isMobile } from 'helpers';
import hrefMatchesCurrentLocation from 'helpers/hrefMatchesCurrentLocation';


const MenuItem = compose(
  connect(
    // force the Link to update correctly
    state => ({
      location: state.routing.locationBeforeTransitions,
    }),
    (dispatch, { href, onClick, closeOnClick = true }) => ({
      onClick(e) {
        if (typeof onClick === 'function') {
          return onClick(e);
        }
        if (isMobile() && closeOnClick) {
          dispatch(setSidebarOpen(false));
        }
        e.preventDefault();
        browserHistory.push(href);
      },
    }),
  ),
)(({ href, extraMatches, children, closeOnClick, location, active, ...rest }) =>
  <a
    href={href}
    {...rest}
    className={cs('menu-item', rest.className, {
      'menu-item-active': hrefMatchesCurrentLocation(href) || active || (Array.isArray(extraMatches) && extraMatches.some(hrefMatchesCurrentLocation)),
    })}
    >
    {children}
  </a>
);

MenuItem.propTypes = {
  index: PropTypes.bool,
  href: PropTypes.string.isRequired,
  extraMatches: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.instanceOf(RegExp), PropTypes.string])),
  children: PropTypes.node.isRequired,
  doNotCloseOnClick: PropTypes.bool,
};

export default MenuItem;
