import React from 'react';
import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import setDisplayName from 'recompose/setDisplayName';

const defaultOptions = {
  placeholder: 'Nothing here yet',
  style: undefined,
};

export const genericPlaceholder = (cond, options) => {
  if (cond === undefined) {
    throw new TypeError(`genericPlaceholder requires a condition, got: ${cond}`);
  }

  options = {
    ...defaultOptions,
    ...options,
  };

  return compose(
    setDisplayName('genericPlaceholder'),
    branch(
      cond,
      renderComponent(() =>
        <div style={{ textAlign: 'center' }}>
          <div style={options.style} className='help-block'>{options.placeholder}</div>
        </div>
      ),
      Component => Component,
    ),
  );
};

export default genericPlaceholder;
