/**
 * Actions relating to the Truckhistory by User resource
 * @module actions/resourceBy/user/truckhistory
 * @since 3.0.0
 * @requires datatypes/PaginatedResourceCollection
 * @requires actions/resource/truck
 */
import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import truckResource from 'actions/resource/truck';


/**
 * Our Truckhistory by User PaginatedResourceCollection instance
 */
export const truckHistoryByUserResource = new PaginatedResourceCollection('user', 'truckhistory', {
  url: new UrlAssembler('user/:id/trucks/history'),
  paginatedResourceOptions: {
    baseResource: truckResource,
  },
});

export default truckHistoryByUserResource;
export const {
  fetch,
  fetchNext,
  sort,
} = truckHistoryByUserResource;
