import React from 'react';
// import { BarChart } from 'react-d3-components';
import { AutoSizer } from 'react-virtualized/dist/commonjs/AutoSizer';
import styled from 'styled-components';

const HorizontalScrollingBarGraph = ({
  minWidth = 0,
  chartProps = {},
}) =>
  <AutoSizer disableHeight={true}>
    {({ width }) => {
      return (
        <div className='HorizontalScrollingBarGraph' style={{ width }}>
          <div style={{ width: Math.max(minWidth, width) }}>
            {/* <BarChart
              width={Math.max(minWidth, width)}
              height={400}
              tooltipMode='element'
              {...chartProps}
              margin={{
                top: 10,
                bottom: 200,
                left: 100,
                right: 10,
                ...chartProps.margin,
              }}
            /> */}
          </div>
        </div>
      );
    }}
  </AutoSizer>;

const StyledHorizontalScrollingBarGraph = styled(HorizontalScrollingBarGraph)`
  .HorizontalScrollingBarGraph {
    overflow-x: scroll;
    .tooltip {
      opacity: 1;
      background-color: white;
      border: solid 1px black;
      padding: 0.5em;
      font-size: 2em;
    }
    .x.axis text {
      text-anchor: start;
      transform: rotate(45deg);
    }
  }
`;

export default StyledHorizontalScrollingBarGraph;
