import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { submit, isSubmitting } from 'redux-form';
import { closeModal } from 'actions/ui';

import FactoringPurchaseTransactionCreateForm from 'components/pure/form/FactoringPurchaseTransactionCreateForm';
import FactoringPurchaseTransactionEditForm from 'components/pure/form/FactoringPurchaseTransactionEditForm';

const onClick = (dispatch, action) => {
  if (action) {
     dispatch(submit('FactoringPurchaseTransactionEditForm'));
     return;
  }
  dispatch(submit('FactoringPurchaseTransactionCreateForm'));
  return;
};

const FactoringTransactionPurchaseModal = compose(
  connect(
    (state, { edit }) => ({
      submitting: edit ? isSubmitting('FactoringPurchaseTransactionEditForm')(state) : isSubmitting('FactoringPurchaseTransactionCreateForm')(state),
    }),
    dispatch => ({
      closeModal: () => dispatch(closeModal()),
      dispatch,
    }),
  ),
)(({ closeModal, edit, id, frid, submitting, dispatch }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        Make a Purchase
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      {edit ?
        <FactoringPurchaseTransactionEditForm frid={frid} id={id} />
        :
        <FactoringPurchaseTransactionCreateForm id={id} />
      }
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
      <button className='btn btn-orange' type='submit' onClick={() => onClick(dispatch, edit)} disabled={submitting}>Make Purchase</button>
    </div>
  </div>
);

export default FactoringTransactionPurchaseModal;
