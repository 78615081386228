/**
 * Reducer for handling the load search actions
 * @module reducers/search/load
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/search/load
 * @listens SEARCH_LOAD_SET_VIEW
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.search.load;

import searchLoadResource, { SEARCH_LOAD_SET_VIEW } from '../../actions/search/load';


export default function searchLoadReducer(state = initialState, action) {
  state = searchLoadResource.reduce(state, action);
  switch (action.type) {
    case SEARCH_LOAD_SET_VIEW:
      return {
        ...state,
        globals: {
          ...state.globals,
          view: action.payload,
        },
      };
  }
  return state;
}
