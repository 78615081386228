/**
 * Reducer for handling the truck resource actions
 * @module reducers/resource/truck
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/truck
 * @requires actions/resourceBy/user/truck
 * @requires actions/resourceBy/user/truckhistory
 * @listens TRUCK_SET_VIEWED
 * @listens TRUCK_TOGGLE_OPEN
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.truck;

import truckResource, {
  TRUCK_SET_VIEWED,
  TRUCK_TOGGLE_OPEN,
  TRUCK_REFRESH,
} from '../../actions/resource/truck';
import truckByUserResource from '../../actions/resourceBy/user/truck';
import truckHistoryByUserResource from '../../actions/resourceBy/user/truckhistory';
import savedtruckByUserResource from 'actions/resourceBy/user/savedtruck';


export default function truckResourceReducer(state = initialState, action) {
  state = truckResource.reduce(state, action);
  if (savedtruckByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...truckResource.parseMany(state, action.payload.results),
    };
  }
  if (truckByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...truckResource.parseMany(state, action.payload.results),
    };
  }
  if (truckHistoryByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...truckResource.parseMany(state, action.payload.results),
    };
  }
  switch (action.type) {
    case TRUCK_SET_VIEWED:
      if (state[action.payload] === undefined) {
        return state;
      }
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            viewed: true,
          },
        },
      };
    case TRUCK_TOGGLE_OPEN:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          globals: {
            ...state[action.payload].globals,
            open: !state[action.payload].globals.open,
            viewed: true,
          },
        },
      };
    case TRUCK_REFRESH:
      return {
        ...state,
        [action.payload.id]: truckResource.parse(state[action.payload.id], action.payload.json),
      };
  }
  return state;
}
