import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';

import LineItemCategoryCreate from 'components/pure/form/LineItemCategoryCreate';

export default compose(
  connect(
    null,
    (dispatch, ownProps) => {
      return {
        close: async () => {
          dispatch(closeModal());
          ownProps.onClose();
        },
      };
    }
  )
)(({ close, type, data }) => {
  return (
    <div>
      <div className='modal-header'>
        <button type='button' className='close' aria-label='Close' onClick={close}><span aria-hidden='true'>×</span></button>
        <h4 className='modal-title'>
          {type ? 'Edit Line Item Category' : 'Create Line Item Category'}
        </h4>
      </div>
      <LineItemCategoryCreate edit={type === 'edit'} initialValues={data} close={close} />
    </div>
  );
});
