import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import setPropTypes from 'recompose/setPropTypes';

import { createAnswer } from 'actions/resource/qa';

import { RFQAForm } from 'components/pure/form/QAForms';


export default compose(
  setPropTypes({
    id: PropTypes.string.isRequired,
    isOwner: PropTypes.bool.isRequired,
  }),
  connect(
    (state, ownProps) => ({ qa: state.resource.qa[ownProps.id] }),
    (dispatch, ownProps) => ({
      handleAnswerSubmit(fields) {
        return dispatch(createAnswer(ownProps.resource, ownProps.resource_id, ownProps.id, fields));
      },
    })
  ),
)(({ qa, isOwner, handleAnswerSubmit }) =>
  <RFQAForm
    isOwner={isOwner}
    qa={qa.data}
    form={`QAAForm-${qa.data.id}`}
    onSubmit={handleAnswerSubmit}
  />
);
