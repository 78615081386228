/**
 * Actions relating to the Map resource
 * @module actions/resource/map
 * @since 3.0.0
 * @requires datatypes/Resource
 * @requires datatypes/Point
 */
/* global google */
import Resource from 'datatypes/Resource';
import Point from 'datatypes/Point';


/**
 * Singleton for our Google DirectionsService
 */
const DIRECTIONS_SERVICE = new google.maps.DirectionsService;

/**
 * The Resource that handles Map parsing and related actions
 * @extends module:datatypes/Resource~Resource
 */
class MapResource extends Resource {
  constructor() {
    super('map');
  }

  id(id) {
    const { origin, destination } = id;
    if (origin instanceof Point) {
      return origin.coordinates.concat(destination.coordinates).map(Math.floor).join('|');
    }
    return [origin, destination].join('|');
  }

  getItem(state, id) {
    return state.resource[this.name][this.id(id)];
  }

  fetch(id) {
    return (dispatch, getState) => {
      const { origin, destination } = id;
      const state = getState();
      const item = this.getItem(state, id);
      id = this.id(id);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.fetchRequest(id));

      return new Promise((resolve, reject) => {
        DIRECTIONS_SERVICE.route({
          origin: origin instanceof Point ? origin.toLatLng() : origin,
          destination: destination instanceof Point ? destination.toLatLng() : destination,
          travelMode: google.maps.TravelMode.DRIVING,
          avoidTolls: true,
        }, (result, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            return resolve(dispatch(this.fetchRequestSuccess(id, result)));
          }
          reject(dispatch(this.fetchRequestFailure(id, status)));
        });
      });
    };
  }

  parse(oldValue, json) {
    if (json === undefined) {
      return super.parse(oldValue);
    }
    return super.parse(oldValue, {
      ...json,
    });
  }
}

/**
 * Singleton for our MapResource
 */
export const mapResource = new MapResource();

export default mapResource;
export const {
  fetch,
  fetchIfNeeded,
  getItem,
} = mapResource;
