/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, SubmissionError, reduxForm, reset } from 'redux-form';
import get from 'lodash/get';

import * as validate from 'helpers/validate';

import { upload } from 'actions/settings/documents';
import { openModal } from 'actions/ui';

import getSelf from 'components/hoc/getSelf';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import { MultiImageInput } from 'components/pure/form/inputs/ImageInput';
import segmentEvents from '../../../helpers/segmentEvents';


const FORM_NAME = 'DocumentsUploadForm';

const DocumentsUploadForm = compose(
  getSelf,
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, props) {
      const is_subscribed = get(props, ['item', 'data', 'permissions', 'is_subscribed']);
      try {
        await dispatch(upload({
          file: fields.file[0],
          user_label: fields.label,
        }));
        window.analytics.track(segmentEvents.USER_ADDED_NEW_DOCUMENT);
        dispatch(reset(FORM_NAME));
        dispatch(openModal('success', { message: 'Successfully uploaded document.' }));
      }
      catch (err) {
        console.warn(err);
        let message = 'Failed to upload file';
        if (err && err.status === 400) {
          if (get(err, ['message', 'non_field_errors', '0']) === 'The fields user, user_label must make a unique set.') {
            message = `A file with the label "${fields.label}" already exists.`;
          }
          else {
            message = `File size limit of ${is_subscribed ? '25MB' : '5MB'} reached. Please remove some saved files or reduce your file's size and try again.`;
          }
        }
        throw new SubmissionError({
          _error: message,
        });
      }
    },
  }),
)(({ handleSubmit, submitting, error }) =>
  <div className='DocumentsUploadForm clearfix'>
    <Header>Upload Documents</Header>
    {error ? <div className='alert alert-danger'>{error}</div> : null}
    <Field
      name='label'
      component={LabeledInput}
      validate={validate.compose(
        validate.vjs.presence(),
        validate.vjs.length({
          minimum: 4,
          maximum: 40,
        }),
      )}
      label='Label'
    />
    <Field
      name='file'
      component={MultiImageInput}
      validate={validate.compose(
        validate.filesize({
          maximum: 5.243e+6,
          tooBig: () => 'This exceeds our 5MB max data storage. If you need more please message help team.',
        }),
      )}
      limit={1}
      label='File'
      buttonText='Add Documents'
      buttonClassName='btn btn-default'
      shouldCrop={true}
    />
    <div className='text-right'>
      <button className='btn btn-orange' type='submit' disabled={submitting} onClick={handleSubmit}>Upload</button>
    </div>
  </div>
);


export default DocumentsUploadForm;
