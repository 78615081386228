/**
 * Components to display http errors
 * @module components/pure/Error
 * @since 3.0.0
 * @property {object} props
 * @property {number} props.status - the http status error to display
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';


const CenteredContainer = ({ children, ...rest }) =>
  <div
    {...rest}
    style={{
      width: '100%',
      height: '100%',
      overflow: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      ...rest.style,
    }}
    >
    {children}
  </div>
  ;

const Error404 = ({ message }) =>
  <CenteredContainer
    style={{
      backgroundColor: colors.VERY_DARK_GRAY,
      textAlign: 'center',
    }}
    >
    <div
      style={{
        padding: '1em',
        marginBottom: '5%',
        color: colors.WHITE,
      }}
      >
      <h2>Oops. This page no longer exists.</h2>
      <h5>{message}</h5>
    </div>
    <img src='/public/image/deadend.png' />
  </CenteredContainer>
  ;

const Errors = {
  401: Error404,
  404: Error404,
};

const Error = props => {
  let Error = Errors[props.status];
  if (Error === undefined) {
    Error = Error404;
  }

  return <Error {...props} />;
};

Error.propTypes = {
  status: PropTypes.number.isRequired,
};

export default Error;
