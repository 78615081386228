import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';

import { resendEmailVerificationEmail } from 'actions/user';
import { openModal } from 'actions/ui';

import wrapPromisePending from 'components/hoc/wrapPromisePending';


export default compose(
  connect(
    null,
    dispatch => ({
      async onClick() {
        try {
          await dispatch(resendEmailVerificationEmail());
          dispatch(openModal('success', { message: 'Successfully sent email.' }));
        }
        catch (err) {
          console.warn(err);
          let message = 'Failed to send email :(';
          if (get(err, ['message', 'message']) === 'Token Recently requested') {
            message = 'We\'ve sent an email to your given email address. Please wait before requesting another.';
          }
          dispatch(openModal('error', { message }));
        }
      },
    }),
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
  }),
)(({ onClick, submitting, ...rest }) =>
  <a role='button' onClick={submitting ? null : onClick}>
    {rest.children || 'Click here'}
  </a>
);
