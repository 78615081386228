/**
 * Component for displaying an input with debtor autocomplete functionality
 * @module components/pure/form/inputs/DebtorAutocompleteInput
 * @since 3.0.1
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import has from 'lodash/has';

import { searchFactoringDebtors } from 'actions/factoring';

import Autocomplete from 'components/pure/Autocomplete';
import LabeledInput from './LabeledInput';
import AdminDebtorCardSmall from 'components/pure/AdminDebtorCardSmall';
import DebtorCardSmall from 'components/pure/DebtorCardSmall';
import MaterialIcon from 'components/pure/MaterialIcon';
import isAdmin from '../../../../selectors/isAdmin';
import { getDebtorById } from '../../../../actions/factoring';

export const DebtorAutocompleteItem = ({ value, ...rest }) =>
  <DebtorCardSmall
    role='button'
    className='pac-item'
    icon={
      <MaterialIcon size={20} name='place' className='pac-icon' style={{ verticalAlign: 'top' }} />
    }
    debtor={value}
    {...rest}
  />;

const AdminDebtorAutocompleteItem = ({ value, ...rest }) =>
  <AdminDebtorCardSmall
    role='button'
    className='pac-item'
    icon={
      <MaterialIcon size={20} name='place' className='pac-icon' style={{ verticalAlign: 'top' }} />
    }
    debtor={value}
    {...rest}
  />;


const DebtorAutocompleteInput = connect(
  state => ({
    admin: isAdmin(state),
  }),
  (dispatch, { input, onSelect, client_id, connected, noDebtor, filter }) => {
    if (onSelect) {
      const originalOnChange = input.onChange;
      input = {
        ...input,
        onChange(val) {
          if (!val) {
            return;
          }
          onSelect(val);
          originalOnChange(val);
        },
      };
    }
    return {
      getSuggestions: dba => dispatch(searchFactoringDebtors({
        connected,
        dba,
        client_id,
        no_debtor: noDebtor,
        filter,
      })),
      input,
      refreshSavedData: id => dispatch(getDebtorById(id)),
    };
  },
)(props => {
  return (
    <LabeledInput
      {...props}
      onFocus={() => props.input.onFocus(props.input.value)}
      onBlur={() => props.input.onBlur(props.input.value)}
      onChange={undefined}
      onSelect={props.input.onChange}
      formatValue={val => val ? val.dba : ''}
      ComponentClass={Autocomplete}
      DropdownItem={props.admin ? AdminDebtorAutocompleteItem : DebtorAutocompleteItem}
      HistoryDropdownItem={props.admin ? AdminDebtorAutocompleteItem : DebtorAutocompleteItem}
    />
  );
}
);

export default DebtorAutocompleteInput;
