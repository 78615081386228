import React from 'react';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CleaveInput from 'components/pure/form/inputs/CleaveInput';

const ExpirationDateInput = props =>
  <LabeledInput
    ComponentClass={CleaveInput}
    type='tel'
    options={{
      date: true,
      datePattern: ['m', 'Y'],
    }}
    {...props}
  />
  ;

export default ExpirationDateInput;
