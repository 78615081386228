import { get } from 'lodash';

const getFiles = (state, type) => {
  let files = get(state, ['upload', 'files'], []);

  // if (type) {
  //   files = files.filter(file => get(file, 'category') === type);
  // }

  return files;
};

export default getFiles;
