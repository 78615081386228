/**
 * Reducer for handling the rate index actions
 * @module reducers/rateindex
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/rateindex
 * @listens RATE_INDEX_FETCH
 * @listens RATE_INDEX_FETCH_SUCCESS
 * @listens RATE_INDEX_FETCH_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../INITIAL_STATE';
const initialState = INITIAL_STATE.rateindex;

import {
  RATE_INDEX_FETCH,
  RATE_INDEX_FETCH_FAILURE,
  RATE_INDEX_FETCH_SUCCESS,
  rateIndexHistoryPaginatedResource,
} from 'actions/rateindex';


export default function rateindexReducer(state = initialState, action) {
  const newHistoryState = rateIndexHistoryPaginatedResource.reduce(state.history, action);
  if (newHistoryState !== state.history) {
    state = {
      ...state,
      history: newHistoryState,
    };
  }
  switch (action.type) {
    case RATE_INDEX_FETCH:
      return {
        ...state,
        isFetching: true,
      };
    case RATE_INDEX_FETCH_SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: action.payload,
        err: undefined,
      };
    case RATE_INDEX_FETCH_FAILURE:
      return {
        ...state,
        isFetching: false,
        err: action.payload,
      };
  }
  return state;
}
