/**
 * Component that displays a child component with a certain `eventKey` property given an `activeKey` property
 * @module components/pure/SelectedComponent
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.activeKey - the key of the active component
 * @property {node[]} props.children - an array of children where either the index is their key, or a prop named `eventKey` is their key
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';


const SelectedComponent = ({ activeKey, children, ...rest }) => (
  <div {...rest}>
    {(() => {
      if (children.length) {
        if (children[0].props.eventKey === undefined) {
          return children[activeKey];
        }
        return children.find(child => child.props.eventKey === activeKey);
      }
      return undefined;
    })()}
  </div>
);

SelectedComponent.propTypes = {
  activeKey: PropTypes.any.isRequired,
};

export default SelectedComponent;
