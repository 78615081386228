/**
 * Component to display a user's profile
 * @module components/pure/User
 * @since 3.0.0
 * @requires helpers
 * @requires datatypes/Point
 * @requires components/pure/CenteredColumn
 * @requires components/pure/Header
 * @requires components/pure/FMCSALink
 * @requires components/container/UserMap
 * @requires components/container/UserProfileRatings
 * @requires components/container/UserRating
 */
import React from 'react';
import PropTypes from 'prop-types';

import { checkPermissions } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import emailIsInvalid from 'helpers/emailIsInvalid';
import formatTel from 'helpers/formatTel';
import Point from 'datatypes/Point';

import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import MaterialIcon from 'components/pure/MaterialIcon';
import FMCSALink from 'components/pure/FMCSALink';
import UserMap from 'components/container/UserMap';
import UserProfileRatings from 'components/container/UserProfileRatings';
import UserRating from 'components/container/UserRating';
import UserHideButton from 'components/container/UserHideButton';
import ButtonQuickPayApproved from 'components/container/ButtonQuickPayApproved';
import ButtonHaulPayVerified from 'components/pure/ButtonHaulPayVerified';
import UserSpecialFiles from 'components/pure/UserSpecialFiles';


const FormattingAnchor = ({ href, ...rest }) => {
  if (!/^https?:\/\//.test(href)) {
    href = `http://${href}`;
  }
  return (
    <a href={href} {...rest}>{href}</a>
  );
};

const User = ({ id, user, openRateModal }) =>
  <CenteredColumn xs={12} sm={10} columnProps={{ className: 'clearfix', style: { padding: 0 } }} style={{ width: '100%' }}>
    <div>
      <div className='row'>
        <div className='col-xs-12'>
          <Header>{checkPermissions(user, user.data.dba)}</Header>
          <UserHideButton id={id} style={{ marginBottom: '1em' }} />
        </div>
        <div className='col-xs-12 col-lg-4' style={{ marginBottom: '1em' }}>
          {user.data.image ? <img src={user.data.image} style={{ maxHeight: '300px' }} width='100%' /> : <img src='/public/image/preview_profile_pic.png' style={{ maxHeight: '300px' }} width='100%' />}
          {/* push this div down a bit so that the Rate User button's hover box-shadow doesn't clip with the image above */}
          <div className='row-2col spacer-xs' style={{ marginTop: 5 }}>
            <button className='btn btn-block btn-orange' onClick={openRateModal}>Rate User</button>
            <UserRating id={id} />
          </div>
        </div>
        {checkPermissions(user,
          <div className='col-xs-12 col-lg-8'>
            <div className='row'>
              <div className='col-xs-12 col-sm-6' style={{ fontSize: 16 }}>
                {user.data.contact_name && <p>Contact: {user.data.contact_name}</p>}
                {user.data.dba && <p>Title: {user.data.dba}</p>}
                {user.data.cargo_insurance_provider ? <p>Insurer: {user.data.cargo_insurance_provider}</p> : null}
                {user.data.cargo_insurance_value ? <p>Ins. Value: {formatPennies(user.data.cargo_insurance_value * 100)}</p> : null}
                {user.data.mc ? <p>MC: {user.data.mc}</p> : null}
                {user.data.dot ? <p>DOT: {user.data.dot}</p> : null}
                {user.data.mc || user.data.dot ? <FMCSALink mc={user.data.mc} dot={user.data.dot}>Full FMCSA Report</FMCSALink> : null}
                {user.data.credit_approved ? <ButtonQuickPayApproved /> : null}
                {user.data.factoring_approved === 'approved' ? <p><ButtonHaulPayVerified /></p> : null}
                <UserSpecialFiles data={user.data} />
              </div>
              <div className='col-xs-12 col-sm-6' style={{ fontSize: 16 }}>
                {user.data.url && <p><MaterialIcon name='open_in_new' /><FormattingAnchor href={user.data.url} target='_blank' rel='noopener noreferrer' /></p>}
                {user.data.contact_phone && <p><MaterialIcon name='phone' /><a href={`tel:${formatTel(user.data.contact_phone)}`}>{user.data.contact_phone}</a></p>}
                {user.data.contact_email && <p><MaterialIcon name='email' /><a href={`mailto:${user.data.contact_email}`}>{user.data.contact_email}</a></p>}
                {user.data.email && !emailIsInvalid(user.data.email) && <p><MaterialIcon name='email' /><a href={`mailto:${user.data.email}`}>{user.data.email}</a></p>}
                {(user.data.address || user.data.city || user.data.country) &&
                  <p><MaterialIcon name='home' />{[user.data.address, user.data.city, user.data.zip, user.data.country].filter(Boolean).join(', ')}</p>
                }
                {user.data.city && user.data.state ? <UserMap style={{ height: 300 }} location={{ address: user.data.address, city: user.data.city, state: user.data.state }} /> : null}
              </div>
            </div>
          </div>
          ,
          <div className='col-xs-12 col-lg-8'>
            <div className='row'>
              <div className='col-xs-12 col-sm-6' style={{ fontSize: 16 }}>
                <p>Contact: xxxxxxxxxxxxxxxx</p>
                <p>Title: xxxxxxxxxxxxxxxx</p>
                <p>Insurer: xxxxxxxxxxxxxxxx</p>
                <p>Ins. Value: xxxxxxxxxxxxxxxx</p>
                <p>MC: xxxxxxxxxxxxxxxx</p>
                <p>DOT: xxxxxxxxxxxxxxxx</p>
              </div>
              <div className='col-xs-12 col-sm-6' style={{ fontSize: 16 }}>
                <p><MaterialIcon name='open_in_new' /> xxxxxxxxxxxxxxxx</p>
                <p><MaterialIcon name='phone' /> xxxxxxxxxxxxxxxx</p>
                <p><MaterialIcon name='email' /> xxxxxxxxxxxxxxxx</p>
                <p><MaterialIcon name='email' /> xxxxxxxxxxxxxxxx</p>
                <p><MaterialIcon name='home' /> xxxxxxxxxxxxxxxx</p>
              </div>
            </div>
          </div>
        )}
      </div>
      {checkPermissions(user,
        user.data.about ?
          <div>
            <div className='col-xs-12'>
              <Header>More Info</Header>
              <p>{user.data.about}</p>
            </div>
          </div>
          : null,
        <div>
          <div className='col-xs-12'>
            <Header>More Info</Header>
            <p>xxxxxxxxxxxxxxxx</p>
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-xs-12'>
          <Header>Review Comments</Header>
          <UserProfileRatings id={id} />
        </div>
      </div>
    </div>
  </CenteredColumn>
  ;

User.propTypes = {
  user: PropTypes.shape({
    data: PropTypes.shape({
      dba: PropTypes.string,
      image: PropTypes.string,
      mc: PropTypes.string,
      dot: PropTypes.string,
      contact_name: PropTypes.string,
      contact_phone: PropTypes.string,
      contact_email: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      country: PropTypes.string,
      coords: PropTypes.instanceOf(Point),
      about: PropTypes.string,
    }).isRequired,
  }).isRequired,
  openRateModal: PropTypes.func.isRequired,
};

export default User;
