import React, { useCallback, useContext, useEffect, useState, useMemo } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import get from 'lodash/get';

import { openModal } from 'actions/ui';

import { Card, CardMedia, CardActions } from 'material-ui/Card';
import { CenteredSpinner } from 'components/pure/Spinner';
import MultiCarousel from 'components/pure/MultiCarousel';
import CreditCard from './components/CreditCard';
import CardContext from './context/CardContext';

function MyCards({ accountId, openModal }) {
    const { cards = [], fetchAllStripeCards } = useContext(CardContext);
    const [isFetching,  setIsFetching] = useState(true);
    useEffect(() => {
        async function asyncFunction() {
            try {
                await fetchAllStripeCards(accountId);
            } catch (err) {

            }
            await new Promise(resolve => setTimeout(resolve, 1000));
            setIsFetching(false);
        }
        accountId && asyncFunction();
    }, [accountId]);
    
    const CardItem = ({ data, display }) => <Card style={{ borderRadius: 16 }}>
        <CardMedia>
            <CreditCard
                containerStyle={{
                    borderBottomLeftRadius: 16,
                    borderBottomRightRadius: 16,
                }}
                data={data}
                display={display}
            />
        </CardMedia>
    </Card>

    
    const carouselItems = useMemo(() => cards.map(card => 
        <CardItem 
            display 
            data={{
                name: `${get(card, 'cardholder.individual.first_name')} ${get(card, 'cardholder.individual.last_name')}`, 
                last4: card.last4, 
                expMonth: card.exp_month, 
                expYear: card.exp_year,
                status: card.status,
                id: card.id,
                accountId,
            }} />
    ), [cards]);

    const onConfirmApplyCard = useCallback(async () => {
        await fetchAllStripeCards(accountId);
    }, [accountId]);
    
    const handleConfirmationModalCardApply = useCallback(() => openModal('confirm', {
        type: 'userStripeCard_apply',
        args: [{
            onConfirm: onConfirmApplyCard,
            accountId
        }]
    }), [accountId]);

    const handleOpenComfreightCreditCardForm = useCallback(() => openModal('StripeCardForm', { onConfirm: onConfirmApplyCard, accountId }), [accountId]);

    if(isFetching) {
        <CenteredSpinner />
    }

    if (cards.length) {
        return (
            <div className='col-md-12' style={{ paddingTop: 20 }}>
                <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'flex-end' }}>
                    <button className="btn btn-orange" onClick={handleOpenComfreightCreditCardForm}>Apply for New Card</button>
                </div>
                <MultiCarousel items={carouselItems} />
            </div>
        )
    }
    return (
        <div className='col-md-12'>
            <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'center', paddingTop: 50 }}>
                <Card style={{ borderRadius: 16, width: 500 }}>
                    <CardMedia>
                        <CreditCard data={{ name: 'Full Name', last4: '0000', expYear: '**', expMonth: '**' }} />
                    </CardMedia>
                    <CardActions style={{ padding: 20, '-webkit-box-align': 'center', '-webkit-box-pack': 'center', display: '-webkit-box' }}>
                        <button className="btn btn-orange" onClick={handleOpenComfreightCreditCardForm}>Apply</button>
                    </CardActions>
                </Card>
            </div>
        </div>
    )
}

export default compose(
    connect(null,
        {
            openModal,
        },
    )
)(MyCards);