import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import mapProps from 'recompose/mapProps';
import { closeModal, openModal } from 'actions/ui';
import Header from 'components/pure/Header';
import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import nothingIf from 'components/hoc/nothingIf';
import LineItemsTable from 'components/pure/LineItemsTable';
import LabeledInput from '../form/inputs/LabeledInput';

export default compose(
  ResourceBy('factoringdebtor', 'lineitemsdebtor'),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
  mapProps(({ id, items, actions, total_line_haul, total_invoice }) => ({
    form: `LineItemDisplay#${id}`,
    funding_request_id: `${id}`,
    lineItems: items.map(item => item.data),
    total_invoice: `${total_invoice}`,
    total_line_haul: `${total_line_haul}`,
    actions,
  })),
  reduxForm({
    validate(fields) {
      if (!fields.to_debtor) {
        return {
          to_debtor: 'Required',
        };
      }
    },
  })
)(({ lineItems, dispatch, handleSubmit, submitting, actions, total_invoice, total_line_haul }) => (
  <div>
    <form onSubmit={handleSubmit}>
      <div className='modal-body'>
        <div className='light-placeholder'>
          <button
            type='button' className='close' aria-label='Close' onClick={() => {
              dispatch(closeModal()); location.reload();
            }}
                                                               ><span aria-hidden='true'>×</span></button>
          <Header>Line Items</Header>
          <hr />
          <div className='col-xs-6'>
            <LabeledInput value={`\$${total_invoice / 100}`} label='Total Invoice' disabled={true} />
          </div>
          <div className='col-xs-6'>
            <LabeledInput value={`\$${total_line_haul / 100}`} label='Total Line Haul' disabled={true} />
          </div>
          <Field
            name='to_LineItem'
            component={LineItemsTable}
            lineItems={lineItems}
            onEditClick={lineItem => {
              dispatch(
                openModal('lineitemsedit', {
                  LineItem: {
                    ...lineItem,
                    funding_request_id: lineItem.funding_request,
                  },
                })
              );
              dispatch(closeModal());
            }}
            onDeleteClick={({ funding_request: funding_request_id, id }) => {
              dispatch(
                openModal('confirm', {
                  type: 'factoringLineItem_delete',
                  args: [
                    {
                      funding_request_id,
                      id,
                      cb: () => {
                        actions.fetch(); location.reload();
                      },
                    },
                  ],
                })
              );
              dispatch(closeModal());
            }}
          />
        </div>
      </div>
      <div className='modal-footer'>
        <button
          className='btn btn-default' type='button' onClick={() => {
            dispatch(closeModal()); location.reload();
          }}
                                                    >Cancel</button>
      </div>
    </form>
  </div>
));
