/**
 * Actions relating to the lead resource
 * @module actions/resource/lead
 * @since 3.0.0
 * @requires datatypes/Resource
 */
import Resource from 'datatypes/Resource';

/**
 * Singleton for our Lead Ressource
 */
export const leadResource = new Resource('lead', {
});

export default leadResource;
