/* global fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, change, formValueSelector, reduxForm, registerField } from 'redux-form';
import validate from 'validate.js';
import _, { flatten, get, isEmpty } from 'lodash';
import mapProps from 'recompose/mapProps';
import withState from 'recompose/withState';
import { Button, Col, Row } from 'react-bootstrap';
import { lifecycle, shallowEqual } from 'recompose';

import { fetchFactoringProfile } from '../../../actions/user';
import { USER_TYPE } from '../../../helpers';
import formatPennies from '../../../helpers/formatPennies';
import { openModal } from '../../../actions/ui';
import { create } from '../../../actions/resource/factoringpayment';
import SubmissionError from '../../../datatypes/error/SubmissionError';
import segmentEvents from '../../../helpers/segmentEvents';

import getSelf from '../../../selectors/getSelf';
import LabeledDropdown from '../../../components/pure/form/inputs/LabeledDropdown';
import nothingIf from '../../../components/hoc/nothingIf';
import Header from '../../../components/pure/Header';
import ProTip from '../../../components/container/ProTip';
import { MultiImageInput } from '../../../components/pure/form/inputs/ImageInput';
import DebtorAutocompleteInput from '../../../components/pure/form/inputs/DebtorAutocompleteInput';
import PennyInput from '../../../components/pure/form/inputs/PennyInput';
import LabeledInput from '../../../components/pure/form/inputs/LabeledInput';
import Spinner from '../../../components/pure/Spinner';
import formatError from '../../../helpers/formatError';
import convertToString from '../../../helpers/formatCategoriesAttachments';
import CheckboxInput from '../../pure/form/inputs/CheckboxInput';
import { FUNDING_REQUEST_STATUS } from '../../../helpers';
import { createLineItemByFundingRequest } from '../../../actions/lineitems';
import MaterialIcon from '../../../components/pure/MaterialIcon';
import Tooltip from '../../../components/pure/Tooltip';
import calculationsHOC from '../../hoc/calculationsHOC';
import Toggle from 'material-ui/Toggle';

const originalDocumentsText =
  [<p className='text-info' style={{ fontSize: '12', margin: '0px 10px', paddingTop: 5 }}>
    This debtor requires copies of Originals Documents to be mailed to them directly.
  </p>,
  <p className='text-info' style={{ fontSize: '12', margin: '0px 10px', paddingTop: 5 }}>Original Documents are physical copies of your actual signed bill of lading and any receipts or other paperwork associated with the load.</p>,
  <p className='text-info' style={{ fontSize: '12', margin: '0px 10px', paddingTop: 5 }}>Please mail them to the debtor directly and notify invoice@haulpay.io once you have sent them.</p>,
  ];

const PaymentMethod = ({ aladdinOwned, accounts }) => {
  const data = [];

  if (aladdinOwned) {
    data.push({
      text: 'Wire',
      fee: '($25)',
      value: 'wire',
    });
    data.push({
      text: 'ACH',
      fee: '',
      value: 'ach',
    });
  }
  else {
    (accounts || []).map(account => {
      switch (account.method) {
        case 'ACH':
          data.push({
            text: `ACH (****${account.account_number_redacted})`,
            fee: '',
            value: 'ach',
          });
          break;
        case 'WIRE':
          data.push({
            text: `WIRE (****${account.account_number_redacted})`,
            fee: '',
            value: 'wire',
          });
          break;
        case 'CHECK':
          data.push({
            text: 'Pay via Check',
            fee: '',
            value: 'check',
          });
          break;
        // case 'DEBIT':
        //   data.push({
        //     text: `Same Day (****${account.account_number_redacted})`,
        //     fee: '($5)',
        //     value: 'debit',
        //   });
        //   break;
      }
    });
  }

  const ListItem = ({ item: { text, fee } }) => (
    <div>{text}<span className='text-danger'>{` ${fee}`}</span></div>
  );

  return (
    <Field
      name='payment_method'
      component={LabeledDropdown}
      label='Payment Method'
      data={data}
      itemComponent={ListItem}
    />
  );
};

const containsAll = (values, required) =>
  required
    .map(value => values.includes(value))
    .filter(valid => !valid).length === 0;

const onSubmit = () => async (fields, dispatch, { user_type, initialValues, initialize, reset, set_require_debtor_address }, AdvancedAmount) => {
  if (fields.doc) {
    if (!_.isEmpty(fields.attachments)) {
      const categories = flatten(fields.attachments.map(file => file.category));
      const tonu = Boolean(_.filter(categories, item => item === 'Truck Order Not Used').length);
      const rate = Boolean(_.filter(categories, item => item === 'Rate Confirmation').length);
      const signedDeliveredBill = Boolean(_.filter(categories, item => item === 'Signed Bill of Lading (Delivered)').length);
      const signedPickUpBill = Boolean(_.filter(categories, item => item === 'Signed Bill of Lading (Pick-Up)').length);
      if (!tonu && (!rate || !(signedDeliveredBill || signedPickUpBill)) && !fields.isFuel) {
        let text = 'Please make sure to add the following document: ';
        text += !rate ? '\n - Rate confirmation ' : '';
        if (!(signedDeliveredBill || signedPickUpBill)) {
          text += !signedDeliveredBill ? '\n - Signed Bill of Lading (Delivered)' : '';
          text += !signedDeliveredBill && !signedPickUpBill ? '\n Or' : '';
          text += !signedPickUpBill ? '\n - Signed Bill of Lading (Pick-Up)' : '';
        }
        return dispatch(openModal('error', {
          message: text,
        }));
      }
      if ((!rate || !signedPickUpBill) && fields.isFuel) {
        let text = 'Please make sure to add the following document: ';
        text += !rate ? '\n - Rate confirmation ' : '';
        text += !signedPickUpBill ? '\n - Signed Bill of Lading (Pick-Up)' : '';
        return dispatch(openModal('error', {
          message: text,
        }));
      }
    }
  }

  const status = !_.isEmpty(fields.attachments) ? FUNDING_REQUEST_STATUS.NEW : FUNDING_REQUEST_STATUS.INCOMPLETE;
  const data = {
    amount: fields.amount,
    debtor: fields.bill_to.id,
    bill_to_company_phone: fields.bill_to_company_phone,
    bill_to_company_email: fields.bill_to_company_email,
    bill_to_address: typeof fields.bill_to_address === 'string' ? { street_one: fields.bill_to_address } : {
      street_one: fields.bill_to_address.street_one,
      city: fields.bill_to_address.city,
      state: fields.bill_to_address.state,
      zip: fields.bill_to_address.zip,
      country: fields.bill_to_address.country,
    },
    attachments: convertToString(fields.attachments),
    user_load_number: fields.user_load_number,
    load: fields.source_load,
    user_notes: fields.user_notes,
    user_reference_number: fields.user_reference_number,
    payment_method: fields.payment_method,
    fuel_advance_amount: fields.fuel_advance_amount,
    isBillOut: fields.isBillOut,
    nonFactored: fields.nonFactored,
    status,
    contract_type: 'STD_CARRIER',
  };
  // add "factoring" (representing the the factoring client ID) into the request payload if it exists as a field
  if (fields.factoring) {
    data.factoring = fields.factoring;
  }
  try {
    //Add Bank/Card info modal on Client side on Funding request.
    // await dispatch(openModal('paymentinformation', { fields }));
    const { payload } = await dispatch(create(data));

    if (AdvancedAmount) {
      await dispatch(createLineItemByFundingRequest(payload.id)({ amount_requested: AdvancedAmount }));
    }
    if (fields.nonFactored) {
      window.analytics.track(segmentEvents.CARRIER_CREATED_NON_FACTORED_FUNDING_REQUEST, payload);
    }
    else if (fields.fuel_advance_amount > 0) {
      window.analytics.track(segmentEvents.CARRIER_CREATED_FUNDING_REQUEST_WITH_FUEL_ADVANCE, payload);
    } else if (!fields.doc) {
      window.analytics.track(segmentEvents.CARRIER_CREATED_INCOMPLETE_FUNDING_REQUEST, payload);
    } else {
      window.analytics.track(segmentEvents.CARRIER_CREATED_FUNDING_REQUEST, payload);
    }
    if (!USER_TYPE.IS_ADMIN(user_type) || !USER_TYPE.IS_ALADDIN_ADMIN(user_type)) {
      if (fields.bill_to.credit_approved !== 'approved') {
        dispatch(openModal('warning', { message: 'This customer is not yet approved for credit terms. We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
      }
      if (fields.bill_to.originals_required) {
        dispatch(openModal('warning', {
          message: (
            <div>
              <p>This customer/debtor has indicated that they require original paperwork. While we encourage our customers to accept digital, some still require physical documents. Please contact your broker directly to get the correct address to mail your original documents to.</p>
              {/*<p>*/}
              {/*  ComFreight Financial<br />*/}
              {/*  1201 W. Russel St.,<br />*/}
              {/*  Sioux Falls, SD 57104*/}
              {/*</p>*/}
              <p>
                Provide tracking information for your mailed originals to
                <a href='mailto:invoice@haulpay.io' className='space'>invoice@haulpay.io</a>
                so that we can process your transaction as quickly as possible, by knowing when your documents will arrive.
              </p>
              <p>If you have more questions please call us <a href='tel:888-633-5558'>888-633-5558</a>.</p>
              Thank you.
            </div>
          ),
        }));
      }
    }
    if (!_.isEmpty(fields.attachments) || fields.isBillOut) {
      dispatch(openModal('success', { message: 'Successfully made payment request.' }));
    }
    else {
      dispatch(openModal('DraftSaved'));
    }
    initialize({
      ...initialValues,
      considering_creating_invoice: false,
      should_create_invoice: false,
      attachments: [],
      bill_to_address: {},
    });
    reset();
    set_require_debtor_address(false);
  }
  catch (err) {
    if (err instanceof SubmissionError) {
      if (/You have already submitted this load number./.test(formatError(err))) {
        return dispatch(openModal('warning', {
          message: 'This load number already has a funding request associated with it. Please go to Payment History and edit your previous funding request.',
        }));
      }
      if (/Debtor has been declined./.get(err, ['message', 'non_field_errors', '0'])) {
        return dispatch(openModal('error', {
          headerChildren: 'Customer Declined on Credit',
          message: 'Credit for this customer has recently been declined. We will check this customer\'s credit again in regular intervals but a payment request cannot be made for this customer at this time. Please email invoice@haulpay.io if you have more questions. Thank you.',
        }));
      }
      if (/Enter the Category/.get(err, ['message', 'attachments', 'non_field_errors', '0'])) {
        return dispatch(openModal('error', {
          headerChildren: 'Error submitting the Payment Request',
          message: 'Enter The Category for Each Attachment.',
        }));
      }
    }
    dispatch(openModal('error', { message: 'Failed to send payment request.' }));
  }


};

const FuelAdvanceTooltip = ({ text }) => <Tooltip
    text={text}>
    <MaterialIcon name="error_outline" size={20}
                  style={{ color: '#4a90e2', paddingRight: 5 }} />
</Tooltip>;

const FORM_NAME = 'FactoringRequestPaymentForm';
const selector = formValueSelector(FORM_NAME);
const selectorAdvanceAmountForm = formValueSelector('advancedAmount');
export default compose(
  connect(
    state => {
      let amount_to_be_paid = '$0.00';
      const advancedAmount = selectorAdvanceAmountForm(state, 'amount') || 0;
      const amount = selector(state, 'amount') - advancedAmount || 0;
      let transfer_fee = 0;
      switch (selector(state, 'payment_method')) {
        case 'wire':
          transfer_fee = 2500;
          break;
        case 'debit':
          transfer_fee = 500;
          break;
      }
      const isBillOut = selector(state, 'isBillOut');
      const debtor = selector(state, 'bill_to');
      const nonFactored = selector(state, 'nonFactored');
      const isNOAActive = Boolean(get(debtor, 'noa_active', undefined));
      const isDeclinedDebtor = get(debtor, ['credit_approved'], 'approved') === 'declined';
      const isApprovedDebtor = get(debtor, ['credit_approved'], 'approved') === 'approved';

      const minimum_fee = get(debtor, ['minimum_fee'], -1);
      const self_factoring_discount_rate = _.get(state.user, 'factoring.data.discount_rate', 3.5);
      const factoring_discount_rate = get(debtor, ['discount_rate'], self_factoring_discount_rate);
      const factoring_fuel_advance = get(state.user, 'factoring.data.fuel_advance', false);
      const factoring_fuel_advance_rate = get(state.user, 'factoring.data.fuel_advance_rate', 3.5);
      const self_fuel_advance_limit = get(state.user, 'factoring.data.fuel_advance_limit', amount / 2);
      const fuel_advance_limit = self_fuel_advance_limit > amount / 2 ? amount / 2 : get(state.user, 'factoring.data.fuel_advance_limit', amount / 2);
      const fuel_advance = factoring_fuel_advance ? get(debtor, ['fuel_advance'], factoring_fuel_advance) : false;
      const fuel_advance_fee = get(debtor, ['fuel_advance_fee'], 2000);
      const fuel_advance_rate = get(debtor, ['fuel_advance_rate'], factoring_fuel_advance_rate);
      const isFuel = selector(state, 'isFuel');
      const fuel_advance_amount = selector(state, 'fuel_advance_amount');
      const displayFuelRate = fuel_advance_rate !== factoring_discount_rate;
      let amount_to_be_paid_number = 0;
      if (amount) {
        if (isFuel && fuel_advance_amount) {
          const remaining_amount = amount - fuel_advance_amount;
          const fuel_advance_amount_to_be_paid = fuel_advance_amount - (fuel_advance_fee + (fuel_advance_amount * (fuel_advance_rate / 100)));
          const remaining_amount_to_paid = remaining_amount - Math.max(minimum_fee > -1 ? minimum_fee : 2000, remaining_amount * (factoring_discount_rate / 100));
          amount_to_be_paid_number = (fuel_advance_amount_to_be_paid + remaining_amount_to_paid) - transfer_fee;
          amount_to_be_paid = formatPennies(amount_to_be_paid_number);
        }
        else {
          const fee = Math.max(minimum_fee > -1 ? minimum_fee : 2000, amount * (factoring_discount_rate / 100));
          amount_to_be_paid_number = (amount - fee - transfer_fee);
          amount_to_be_paid = formatPennies(amount_to_be_paid_number);
        }
      }
      const self = getSelf(state);
      return {
        considering_creating_invoice: selector(state, 'considering_creating_invoice'),
        factoring_discount_rate,
        amount_to_be_paid,
        self,
        factoring: get(state.user.factoring, 'data.id'),
        accounts: get(state.user.factoring, 'data.accounts'),
        aladdinOwned: get(state.user.factoring, 'data.aladdin_owned'),
        paymentMethod: selector(state, 'payment_method'),
        isFuel,
        fuel_advance,
        displayFuelRate,
        fuel_advance_rate,
        fuel_advance_fee,
        fuel_advance_limit,
        amount_to_be_paid_number,
        isDeclinedDebtor,
        isApprovedDebtor,
        debtor,
        isBillOut,
        amount,
        nonFactored,
        isNOAActive,
        carrier: get(self, 'data', {})
      };
    },
    (dispatch, { factoring }) => ({
      openMoreDetailsModal() {
        dispatch(change(FORM_NAME, 'considering_creating_invoice', true));
        dispatch(openModal('factoringpaymentrequestmoredetails'));
      },
      openAddCustomerModal: () => dispatch(openModal('factoringclientadddebtor', {
        factoring,
        shouldUpdateFactoringRequestPaymentForm: true,
        parent: FORM_NAME,
      })),
      openDebtorAmountLimit: data => dispatch(openModal('DebtorAmountLimit', data)),
      openDebtorAdvancedAmount: data => dispatch(openModal('DebtorAdvancedAmount', data)),
      openHelpModal: () => dispatch(openModal('requestpaymentformhelp')),
      getFactoringProfile: () => dispatch(fetchFactoringProfile()),
      confirmSave(onConfirm) {
        dispatch(openModal('confirm', {
          type: 'userfactoringprofileincomplete_save',
          args: [{
            onConfirm,
          }],
        }));
      },
      dispatch,
    }),
  ),
  nothingIf(({ considering_creating_invoice }) => considering_creating_invoice),
  mapProps(({ self, initialValues, ...rest }) => ({
    ...rest,
    self,
    initialValues: {
      ...initialValues,
      doc: true,
      attachments: [],
      bill_to_address: {},
      payment_method: self.data.enable_pay_via_check ? 'check' : 'ach',
    },
    user_type: self.data.type,
  })),
  withState('require_debtor_address', 'set_require_debtor_address', false),
  lifecycle({
    componentDidMount() {
      const { dispatch, getFactoringProfile } = this.props;
      getFactoringProfile();
      dispatch(registerField(FORM_NAME, 'totalPaid', 'Field'));
    },
    componentDidUpdate(PrevProps) {
      const { amount_to_be_paid_number, dispatch, amount, debtor, set_require_debtor_address } = this.props;
      if (amount_to_be_paid_number !== PrevProps.amount_to_be_paid_number || amount !== PrevProps.amount) {
        dispatch(change(FORM_NAME, 'totalPaid', amount_to_be_paid_number));
      }
      if (!shallowEqual(debtor, PrevProps.debtor) && !isEmpty(debtor)) {
        dispatch(change(FORM_NAME, 'bill_to_address', debtor.address));
        if (!debtor.address || !debtor.address.street_one) {
          set_require_debtor_address(true);
        }
        else {
          set_require_debtor_address(false);
        }
        // fall back to empty string here because `bill_to_company_phone` can't be `null` on this endpoint
        dispatch(change(FORM_NAME, 'bill_to_company_phone', debtor.phone_number || ''));
        dispatch(change(FORM_NAME, 'bill_to_company_email', debtor.email));
        dispatch(change(FORM_NAME, 'factoring_discount_rate', debtor.discount_rate));
      }
    },
  }),
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    onSubmit: async (fields, dispatch, { user_type, initialValues, initialize, reset, set_require_debtor_address, openDebtorAmountLimit, openDebtorAdvancedAmount, amount_to_be_paid_number }) => {
      if (fields.isBillOut || fields.nonFactored) {
        return await onSubmit()(fields, dispatch, { user_type, initialValues, initialize, reset, set_require_debtor_address });
      }
      const creditAvailable = get(fields, 'bill_to.credit_available', 0);
      const openAdvancedMoneyModal = () =>
        openDebtorAdvancedAmount({
          action: {
            confirm: async amount => await onSubmit()(fields, dispatch, { user_type, initialValues, initialize, reset, set_require_debtor_address }, amount),
            no: async () => await onSubmit()(fields, dispatch, { user_type, initialValues, initialize, reset, set_require_debtor_address }),
          },
          fundingRequestAmount: amount_to_be_paid_number,
        });
      if (creditAvailable <= amount_to_be_paid_number) {
        return openDebtorAmountLimit({
          action: () => setTimeout(() => openAdvancedMoneyModal(), 250),
        });
      }
      return openAdvancedMoneyModal();
    },
    shouldValidate(params) {
      return true;
    },
    validate(fields, { self, require_debtor_address, fuel_advance_limit }) {
      const errs = validate(fields, {
        attachments: (value, attributes, attributeName, options, constraints) => {
          if (!attributes.doc) {
            return null;
          }
          return {
            /*containsKey: {
              required: 'category',
              message: 'are required to have a category',
            },*/
            presence: {
              message: 'We require an uploaded BOL (Delivered) and Rate Confirmation unless Truck Order Not Used',
            },
            length: {
              minimum: 1,
              tooShort: '^At least one attachment required.',
            },
          };
        },
        amount: {
          presence: true,
        },
        bill_to: (value, attributes, attributeName, options, constraints) => {
          if (attributes.nonFactored) {
            return null;
          }
          if (attributes.isBillOut) {
            return null;
          }
          return {
            presence: true,
            debtorDeclined: true,
          };
        },
        fuel_advance_amount: (value, attributes, attributeName, options, constraints) => {
          if (!attributes.isFuel) {
            return null;
          }
          return {
            presence: true,
            numericality: {
              lessThanOrEqualTo: fuel_advance_limit,
              notLessThanOrEqualTo: `must be less or equal to ${formatPennies(fuel_advance_limit)}`,
            },
          };
        },
        ...(() => require_debtor_address ? {
          bill_to_address: {
            multipresence: {
              required: ['street_one'],
              message: '^Please enter an address.',
            },
          },
        } : {})(),
        user_load_number: {
          presence: true,
        },
      });

      if (fields.attachments && fields.doc) {
        const categories = flatten(fields.attachments.map(file => file.category));

        if (!(
          containsAll(categories, ['Truck Order Not Used']) ||
          (
            containsAll(categories, ['Rate Confirmation', 'Signed Bill of Lading (Delivered)']) ||
            containsAll(categories, ['Rate Confirmation', 'Signed Bill of Lading (Pick-Up)'])
          ))
          && !fields.isFuel) {
          errs.attachments = 'We require an uploaded BOL (Delivered) or  BOL (Pick-Up) and Rate Confirmation unless Truck Order Not Used';
        }
        if (!(
          containsAll(categories, ['Rate Confirmation', 'Signed Bill of Lading (Pick-Up)']))
          && fields.isFuel) {
          errs.attachments = 'We require an uploaded BOL (Pick-Up) and Rate Confirmation';
        }
      }

      return errs;
    },
  }),
  calculationsHOC(['amount', 'debtor', 'nonFactored'], { isPaymentProfile: false, amount: 'amount', bill_to: 'debtor', non_factored: 'nonFactored', bill_out_only: 'billOutOnly', carrier_amount: 'carrierAmount', payout_days: 'payoutDays', carrier: 'carrier', shipper_offset: 'ShipperOffset', broker_rate: 'brokerRate', pass_debtor_rate_to_carrier: 'passDebtorRateToCarrier', new: true }),
)(({
  factoring_discount_rate,
  amount_to_be_paid,
  submitting,
  handleSubmit,
  openMoreDetailsModal,
  openAddCustomerModal,
  openHelpModal,
  change,
  user_type,
  require_debtor_address,
  set_require_debtor_address,
  factoring,
  accounts,
  aladdinOwned,
  paymentMethod,
  isFuel,
  fuel_advance,
  displayFuelRate = false,
  fuel_advance_rate,
  fuel_advance_fee,
  amount_to_be_paid_number,
  isDeclinedDebtor,
  confirmSave,
  debtor = {},
  isBillOut,
  isApprovedDebtor,
  calculationData,
  nonFactored,
  isNOAActive
}) =>
  <Row className='light-placeholder'>
    <div className='clearfix' style={{ marginTop: 12.5, paddingLeft: '4%' }}>
      {/*{openDebtorAmountLimit()}*/}
      <Header style={{ display: 'inline-block', marginTop: 0 }}>
        Get Paid
        <span style={{ fontSize: '0.7em' }}> Upload Documents</span>
      </Header>
      <ProTip
        className='pull-right'
        name='Upload to Get Paid'
        message={'Be sure to upload your Rate Confirmation (or a form of one), and a signed Bill of Lading. If you don\'t have an invoice you can create one with the Create Invoice button. If you don\'t have a formal rate confirmation you can use a copy of an email confirming the rate or awarded bid from the company you hauled for as well.'}
        youtubeEmbed='Aykn5XgennU'
      />
      <button className='btn btn-default pull-right' type='button' onClick={openHelpModal}>Help</button>
    </div>
    <div style={{ paddingLeft: '4%' }}>
      <div className='text-danger'>Required Documents: Signed Rate Confirmation & Signed Bill of Lading.</div>
      <small>Cutoff time is 12PM CT for next day payment. </small>
    </div>
    <hr />
    <form name='funding_request_carrier' onSubmit={handleSubmit}>
      <Row>
        <Row
          style={{
            paddingLeft: '10%',
            paddingRight: '10%',
          }}
        >
          <Col xs={16}>
            <Field
              name='attachments'
              component={MultiImageInput}
              limit={25}
              multiple={true}
              buttonText='Upload Documents'
              buttonClassName='btn btn-default'
              confirmBeforeDeleting={true}
              shouldCrop={true}
              convertAnyway={true}
              categories={[
                'Signed Bill of Lading (Delivered)',
                'Signed Bill of Lading (Pick-Up)',
                'Rate Confirmation',
                'Truck Order Not Used',
                'Invoice',
                'Freight Bill',
                'Fuel Advance',
                'Lumper Receipt',
                'Detention Receipt',
                'Late Fee Receipt',
                'Other',
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={8} lg={6} style={{ paddingLeft: '10%', paddingRight: '10%' }}>
            <Field name='amount' component={PennyInput} label='Total Invoice Amount' />
            <div className='getpaid' style={{ marginTop: '6%' }}>
              <Field
                name='user_load_number'
                component={LabeledInput}
                label='Load Number'
              />
            </div>
            <div className='getpaid'>
              <button className='btn-payment pull-right' type='button' onClick={openAddCustomerModal} style={{ padding: '0 12px', marginBottom: 3, height: '20px' }}>
                +  New Cust.
              </button>
                {console.log("factoring", factoring)}
              <Field
                name='bill_to'
                component={DebtorAutocompleteInput}
                client_id={factoring}
                labelProps={{
                  style: { display: 'block' },
                }}
                label={
                  <div>
                    Bill To Company
                  </div>
                }
                historyKey='my-debtors-fr'
              />
              {debtor.originals_required &&
                originalDocumentsText
              }
            </div>
            <div>
              {isApprovedDebtor &&
                <Field
                  style={{
                    marginBottom: 10,
                    marginTop: 10,
                  }}
                  name='nonFactored'
                  component={CheckboxInput}
                  label={
                    <div style={{ display: 'inline' }}>
                      <Tooltip text='We will process this like usual except you will get paid after we receive the money from your customer.'>
                        <MaterialIcon name='error_outline' size={20} style={{ color: '#4a90e2', paddingRight: 5 }} />
                      </Tooltip>
                      <span>This is a non-factored invoice?</span>
                    </div>}
                />
              }
            </div>
            {isDeclinedDebtor &&
              <Field
                style={{
                  marginBottom: 10,
                  marginTop: 10,
                }}
                name='isBillOut'
                component={CheckboxInput}
                label={<span>Bill Only as Non-Factored</span>}
              />
            }
              <div>
                  <div style={{ display: 'flex' }}>
                      <div style={{ display: 'flex', flex: '100%', alignItems: 'center' }}>
                          {isEmpty(debtor) ?
                              <FuelAdvanceTooltip text="Please Add Debtor to Request a Fuel Advance" />
                              : !isNOAActive ?
                                  <FuelAdvanceTooltip
                                      text="Fuel Advances are not available for this Debtor until your NOA is Placed (Active)" />
                                  : !fuel_advance ?
                                      <FuelAdvanceTooltip text="This Debtor is Not Approved for Fuel Advances" /> : null
                          }
                          <span className="control-label">Is this a fuel advance?</span>
                      </div>
                          <Field
                              style={{
                                  marginBottom: 10,
                                  marginTop: 10
                              }}
                              disabled={(!fuel_advance || isBillOut || nonFactored|| !isNOAActive)}
                              name="isFuel"
                              component={Toggle}
                              onToggle={(value, isInputChecked) => {
                                  change('isFuel', isInputChecked);
                              }}
                          />
                      </div>
                      {
                          (isFuel && fuel_advance && !isBillOut && !nonFactored && isNOAActive) &&
                          <Field name="fuel_advance_amount" component={PennyInput} label="Fuel Advance Amount" />
                      }
                  </div>
                  <div>
                  <a style={{ fontSize: 14, marginBottom: 20, marginTop: 20 }} role="button"
                     onClick={openMoreDetailsModal}>Add Details</a>
              </div>
              {!isBillOut &&
                  <div
                      style={{
                          marginBottom: 10,
                          marginTop: 10
                      }}
                  >
                      <PaymentMethod accounts={accounts} aladdinOwned={aladdinOwned} />
              </div>
            }
            {
              paymentMethod === 'wire' &&
              <p className='text-danger'><b>Wire cutoff is 12PM CT.</b></p>
            }
            <div
              style={{
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-end',
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <div>
                {(fuel_advance && isFuel) &&
                  <p>Fuel Advance Fee: {formatPennies(fuel_advance_fee)}</p>
                }
                {(fuel_advance && isFuel && displayFuelRate) &&
                  <p>Fuel Advance Rate: {fuel_advance_rate}%</p>
                }
                <p>Your Rate: {!nonFactored ? `${factoring_discount_rate}%` : formatPennies(calculationData.total_comfreight_fee)}</p>
                <p><u>Total To Be Paid:</u> <span className='text-success'><strong>{formatPennies(calculationData.factoring_payable || 0)}</strong></span></p>
              </div>
            </div>
            <Col xs={12} style={{ paddingTop: 20 }}>
              <Button
                name='submit_payment'
                className='btn btn-orange'
                onClick={() => {
                  change('doc', true);
                  handleSubmit();
                }}
                disabled={submitting}
              >
                {
                  submitting ?
                    <span>
                      <Spinner />
                      PROCESSING
                    </span>
                    :
                    'REQUEST PAYMENT'
                }
              </Button>
            </Col>
            <Col xs={12} style={{ paddingTop: 20 }}>
              <a
                role='button'
                style={{ backgroundColor: 'transparent', color: '#1777ad', borderWidth: 0 }}
                onClick={() => {
                  change('doc', false);
                  confirmSave(handleSubmit);
                }}
              >
                {
                  submitting ?
                    <span>
                      <Spinner />
                    </span>
                    :
                    <div>
                      Complete This Later
                      <Tooltip text='This will create a incomplete request, we will not process this until you complete it.'>
                        <MaterialIcon name='error_outline' size={20} style={{ color: '#f5a623', paddingLeft: 5 }} />
                      </Tooltip>
                    </div>
                }
              </a>
            </Col>
          </Col>
        </Row>x
      </Row>
    </form>
  </Row>
);
