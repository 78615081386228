import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';
import { Link } from 'react-router-v4';

import financespaymentsPaginatedResource from 'actions/admin/financespayments';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import ReceivablesDebtorTable from './ReceivablesDebtorTable';

export default compose(
  connect(
    state => {
      return {
        ids: state.admin.financespayments.ids,
        isFetching: state.admin.financespayments.isFetching,
      };
    },
    {
      clear: financespaymentsPaginatedResource.clear,
      fetch: financespaymentsPaginatedResource.fetch,
      fetchNext: financespaymentsPaginatedResource.fetchNext,
    },
  ),
  ResourceByIDs({
    resource: 'financespayment',
    idPropName: ['ids'],
  }),
  lifecycle({
    componentDidMount() {
      this.props.clear();
      this.props.fetch();
    },
  }),
)(() =>
  <div style={{ padding: '0 1em' }}>
    <h3 style={{ color: '#2196f3', marginBottom: '30' }}><b> All Receivables </b></h3>
    <ReceivablesDebtorTable />
  </div>
);
