import React from 'react';
import { compose } from 'redux-v3';
import { get } from 'lodash';

import Resource from 'components/hoc/Resource';
import Header from 'components/pure/Header';
import FactoringRequestPaymentForm from 'components/pure/form/FactoringRequestPaymentForm';


export default compose(
  Resource('factoringclient', {
    idPropName: ['params', 'id'],
  }),
)(({ item, params: { id } }) =>
  <div style={{ padding: '0 1em' }}>
    {
      item && item.data && item.data.company_profile ?
        <Header>
          {'HP Client: '}
          {
            item.data.company_profile.name ?
              item.data.company_profile.name
              :
              <span className='text-danger'>Not Set</span>
          }
        </Header>
        : null
    }
    <FactoringRequestPaymentForm
      form={'FactoringRequestPaymentForm'}
      factoring={id}
      initialValues={{
        factoring: id,
        factoring_user: get(item, 'data.user'),
      }}
    />
  </div>
);
