/**
 * Component that displays the filter button on the ComBoard
 * @module components/container/TimelineFilter
 * @since 3.0.0
 * @requires actions/resource/activity
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing Dropdown component
 */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import getSelfType from 'selectors/getSelfType';

import { USER_TYPE } from 'helpers';

import { setFilter } from 'actions/resource/activity';

import Dropdown from 'components/pure/Dropdown';
import isInviteCarrier from 'selectors/isInviteCarrier';
import isFactoringInviteCarrier from 'selectors/isFactoringInviteCarrier';

/**
 * The filtering options for this button
 */
const filters = [
  {
    displayName: 'All Activity',
    value: 'all',
  },
  {
    displayName: 'Bids Placed',
    value: 'bid_placed',
  },
  {
    displayName: 'Bids Received',
    value: 'my_load_bid_received',
  },
  {
    displayName: 'Bids Awarded',
    value: 'my_bid_awarded,my_load_bid_awarded',
  },
  {
    displayName: 'Bids Retracted',
    value: 'bid_retracted,my_load_bid_retracted',
  },
  {
    displayName: 'Under-Bid Alert',
    value: 'underbid_alert',
  },
  {
    displayName: 'Load Matches',
    value: 'my_truck_load_match',
  },
  {
    displayName: 'Truck Matches',
    value: 'my_load_truck_match',
  },
  {
    displayName: 'Shipper Load',
    value: 'shipper_alert',
  },
  {
    displayName: 'Question',
    value: 'my_load_question,my_truck_question',
  },
  {
    displayName: 'Answer',
    value: 'my_load_answer,my_truck_answer',
  },
];

const carrier_and_broker_filters = isInviteCarrier => [
  ...filters.slice(0, 1),
  ...(() => isInviteCarrier ?
    [{
      displayName: 'Assigned Loads',
      value: 'assigned_to_load',
    }] : [])(),
  {
    displayName: 'Haul Pay',
    value: 'factoring_welcome,factoring_application_pending,factoring_application_approved,factoring_application_denied,factoring_linked_debtor_approved,factoring_linked_debtor_declined,factoring_funding_request_paid,factoring_funding_request_declined,factoring_funding_request_approved,factoring_funding_request_load_delivered,factoring_funding_request_pending_originals,factoring_funding_request_document_issue',
  },
  ...filters.slice(1),
];


export default compose(
  connect(
    state => ({
      user_type: getSelfType(state),
      currentFilter: state.activity.globals.filter,
      isInviteCarrier: isInviteCarrier(state),
      isFactoringInviteCarrier: isFactoringInviteCarrier(state),
    }),
    dispatch => ({
      filter: filter => dispatch(setFilter(filter, ['timeline', 'dropdown'])),
    }),
  ),
)(({ user_type, currentFilter, filter, isInviteCarrier, isFactoringInviteCarrier, ...rest }) => {
  const inviteCarrier = isInviteCarrier || isFactoringInviteCarrier;
  const filters_ = [USER_TYPE.BROKER, USER_TYPE.CARRIER, USER_TYPE.DISPATCHER].includes(user_type) ? carrier_and_broker_filters(inviteCarrier) : filters;
  return (
    <Dropdown
      {...rest}
      items={filters_.map(({ displayName, value }) => <div role='button' onClick={() => filter(value)}>{displayName}</div>)}
      >
      <div
        role='button'
        style={{
          padding: '10px 15px',
        }}
        >
        Filter: {filters_.find(({ value }) => value === currentFilter).displayName} <span className='glyphicon glyphicon-triangle-bottom' />
      </div>
    </Dropdown>
  );
});
