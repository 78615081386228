/**
 * Reducer for handling the admin notes actions
 * @module reducers/admin/notes
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/notes
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.clientcombinednotes;

import notesResource from 'actions/admin/clientcombinednotes';


export default function notesReducer(state = initialState, action) {
  state = notesResource.reduce(state, action);
  return state;
}
