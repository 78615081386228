import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { setViewed, toggleOpen } from 'actions/resource/trucksearch';

import hiddenPost from 'components/hoc/hiddenPost';
import scrollToItem from 'components/hoc/scrollToItem';
import TruckSearchTableItem from 'components/pure/TruckSearchTableItem';


export default compose(
  connect(
    (state, ownProps) => {
      const item = state.resource.trucksearch[ownProps.id];
      const scroll_to_item = state.ui.scroll_to_item;
      return {
        scroll_to_item,
        open: item.globals.open,
        viewed: item.globals.viewed,
        data: item.data,
      };
    },
    {
      toggleOpen,
      setViewed,
    },
  ),
  hiddenPost({ idProp: 'data.id' }),
  scrollToItem({
    idPropName: ['data', 'id'],
  }),
)(TruckSearchTableItem);
