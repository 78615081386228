

const rafDebounce = fn => {
  let raf_waiting = false;
  return (...args) => {
    if (!raf_waiting) {
      requestAnimationFrame(() => {
        fn(...args);
        raf_waiting = false;
      });
    }
    raf_waiting = true;
  };
};

export default rafDebounce;
