/**
 * Component to display a link to a user's FMCSA 'Safer' search page. Internally makes a POST request to the SAFER database searching for the given MC or DOT
 * @module components/pure/FMCSALink
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.dot
 * @property {string} props.mc
 * @property {node|node[]} [props.children = 'FMCSA Report'] - node to display in the button
 */
import React from 'react';
import PropTypes from 'prop-types';


const FMCSALink = ({ mc, dot, children = 'FMCSA Report' }) =>
  !mc && !dot ? null :
  <div>
    <form method='post' action='https://safer.fmcsa.dot.gov/query.asp' target='_blank' rel='noopener'>
      <input name='searchtype' value='ANY' type='hidden' />
      <input name='query_type' value='queryCarrierSnapshot' type='hidden' />
      {mc ?
        <input name='query_param' value='MC_MX' type='hidden' />
          :
        <input name='query_param' value='USDOT' type='hidden' />
        }
      <input name='query_string' value={mc ? mc : dot} type='hidden' />
      <p>
        <a href='https://safer.fmcsa.dot.gov/query.asp' onClick={() => null}>
          <button style={{ background: 'transparent', border: 0, padding: 0 }} type='submit'>
            {children}
          </button>
        </a>
      </p>
    </form>
  </div>
  ;

FMCSALink.propTypes = {
  mc: PropTypes.string,
  dot: PropTypes.string,
  children: PropTypes.node,
};

export default FMCSALink;
