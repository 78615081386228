import validatejs from 'validate.js';
import convert from 'convert-units';


const formatBytes = bytes => {
  const converted = convert(bytes).from('B').toBest();
  return `${converted.val.toLocaleString(undefined, { maximumFractionDigits: 2 })} ${converted.unit}`;
};

export const filesize = options => val => {
  if (validatejs.isEmpty(val)) {
    return;
  }
  options = {
    minimum: 0,
    maximum: Number.MAX_VALUE,
    tooBig: ({ name, size, maximum }) => `File "${name}" is too big (${size}), max ${maximum}`,
    tooSmall: ({ name, size, minimum }) => `File "${name}" is too small (${size}), min ${minimum}`,
    ...options,
  };

  if (val.size > options.maximum) {
    return options.tooBig({
      name: val.name,
      size: formatBytes(val.size),
      maximum: formatBytes(options.maximum),
    });
  }
  if (val.size < options.minimum) {
    return options.tooSmall({
      name: val.name,
      size: formatBytes(val.size),
      minimum: formatBytes(options.minimum),
    });
  }
};
