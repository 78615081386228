import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { openModal } from 'actions/ui';

import nothingIf from 'components/hoc/nothingIf';
import UserTableHideButtons from 'components/pure/UserTableHideButtons';


export default compose(
  connect(
    state => ({ self: state.user.id }),
    (dispatch, ownProps) => ({
      hidePost: () => dispatch(openModal('confirmhidepost', { id: ownProps.id })),
      hideUser: () => dispatch(openModal('confirmhideuser', { id: ownProps.user_id })),
    }),
  ),
  nothingIf(({ self, user_id }) => self === user_id),
)(UserTableHideButtons);
