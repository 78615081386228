/**
 * Reducer for handling the accountspayable settings actions
 * @module reducers/settings/accountspayable
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/settings/accountspayable
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.settings.accountspayable;

import accountspayableSettingsResource from '../../actions/settings/accountspayable';


export default function accountspayableSettingsReducer(state = initialState, action) {
  state = accountspayableSettingsResource.reduce(state, action);
  return state;
}
