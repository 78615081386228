/**
 * Actions for the factoring debtor actions
 * @module actions/admin/factoring/debtor
 * @since 3.0.1
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringapplication
 */
/* global API */

import UrlAssembler from 'url-assembler';

import APIFetch from 'datatypes/APIFetch';
import PaginatedResource from 'datatypes/PaginatedResource';
import factoringdebtorResource from 'actions/resource/factoringdebtor';

const factoringdebtorPaginatedResource = new PaginatedResource('admin_factoring_debtor', {
  mountPoint: ['admin', 'factoring', 'debtor'],
  url: new UrlAssembler('admin/debtors'),
  baseResource: factoringdebtorResource,
});

export default factoringdebtorPaginatedResource;

export const fetchReceivablesDebtorsStats = () => async (dispatch, getState) => {
  const res = await dispatch(
    APIFetch(`${API.host}/debtor/outstanding_totals/`, {
      method: 'GET',
      headers: {
        authorization: `bearer ${getState().user.token}`,
        'content-type': 'application/json',
      },
    })
  );
  if (res.status !== 200) {
    throw await res.text();
  }

  return res.json();
};

export const uploadReceivablesCSV = file => async (dispatch, getState) => {
  const state = getState();
  const formData = new FormData();
  formData.append('file', file);
  const res = await global.fetch(`${API.host}/payments/upload_csv/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${state.user.token}`,
    },
    body: formData,
  });
  if (res.status >= 500 && res.status < 600) {
    throw res;
  }
  const json = await res.json();
  if (res.status !== 201) {
    throw json;
  }
  return json;
};
