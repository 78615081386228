/**
 * Component that displays a 'block' table, which is a table wherein the headers are in a single column to the left, and the rows are in a single column to the right
 * @module components/pure/BlockTable
 * @since 3.0.0
 * @requires components/hoc/infiniteScroll
 * @requires copmonents/hoc/placeholder
 * @property {object} props
 * @property {string[]} props.headers - the headers for the table, which get passed to the ListItem
 * @property {object[]} props.items - a list of items to pass to the ListItem components
 * @property {element} props.ListItem - the react element used to render an item in this list
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/BlockTable';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import cs from 'classnames';
import difference from 'lodash/difference';
import get from 'lodash/get';

import infiniteScroll from 'components/hoc/infiniteScroll';
import placeholder from 'components/hoc/placeholder';

import Spinner from 'components/pure/Spinner';

const LoadingOverlay = ({ children }) => (
  <div
    style={{
      position: 'absolute',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'rgba(255, 255, 255, 0.4)',
    }}
    >
    {children}
  </div>
);

class BlockTable extends Component {
  constructor() {
    super();

    this.state = {
      page: 1,
      selectedItemIds: [],
    };

    this.triggerOnItemsSelect = this.triggerOnItemsSelect.bind(this);
  }

  componentDidUpdate() {
    const currentItemIds = this.props.items.map(item => get(item, this.props.idSelector));

    if (difference(this.state.selectedItemIds, currentItemIds).length) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        selectedItemIds: this.state.selectedItemIds.filter(id => this.props.items.find(item => get(item, this.props.idSelector) === id)),
      });
    }
  }

  getItemsToRender() {
    const { items, limit } = this.props;
    const { page } = this.state;

    return !this.isClientPagination() ? items : items.filter((_, i) => (page - 1) * limit <= i && i < page * limit);
  }

  getSelectedItems() {
    const { items, idSelector } = this.props;
    const { selectedItemIds } = this.state;
    return selectedItemIds.map(id =>
      items.find(item => get(item, idSelector) === id)
    );
  }

  isClientPagination() {
    const { withPagination, fetchPage, limit } = this.props;

    return withPagination && typeof fetchPage !== 'function' && limit > 0;
  }

  isAllSelected = () => difference(this.getItemsToRender().map(item => get(item, this.props.idSelector)), this.state.selectedItemIds).length === 0;

  isItemSelected = item => this.state.selectedItemIds.includes(get(item, this.props.idSelector));

  handlePageSelect = page => () => {
    const { fetchPage } = this.props;

    this.setState({
      page,
      selectedItemIds: [],
    }, this.triggerOnItemsSelect);
    if (!this.isClientPagination()) {
      fetchPage(page - 1);
    }
  };

  handleSelectAllChange = shouldSelectAll => () => {
    this.setState({
      selectedItemIds: shouldSelectAll ? this.getItemsToRender().map(item => get(item, this.props.idSelector)) : [],
    }, this.triggerOnItemsSelect);
  }

  handleItemSelect = item => shouldSelect => {
    const { idSelector } = this.props;
    const { selectedItemIds } = this.state;
    if (shouldSelect) {
      return this.setState({
        selectedItemIds: [get(item, idSelector), ...selectedItemIds],
      }, this.triggerOnItemsSelect);
    }

    this.setState({
      selectedItemIds: selectedItemIds.filter(selectedItemId => selectedItemId !== get(item, idSelector)),
    }, this.triggerOnItemsSelect);
  }

  triggerOnItemsSelect = () => {
    const { onItemsSelect } = this.props;

    if (typeof onItemsSelect === 'function') {
      onItemsSelect(this.getSelectedItems());
    }
  }

  renderPagination() {
    const { page } = this.state;
    const { limit, isFetching, items } = this.props;
    let { count } = this.props;

    if (this.isClientPagination()) {
      count = items.length;
    }

    const totalPages = Number.isFinite(limit) && limit > 0 ? Math.ceil(count / limit) : 1;

    if (totalPages === 1) {
      return null;
    }

    return (
      <ul className='pagination'>
        <li className={isFetching || page === 1 ? 'disabled' : ''} onClick={page !== 1 && !isFetching && this.handlePageSelect(1)}>
          <span>First</span>
        </li>
        <li className={isFetching || page === 1 ? 'disabled' : ''} onClick={page !== 1 && !isFetching && this.handlePageSelect(page - 1)}>
          <span>
            <span aria-hidden='true'>&laquo;</span>
          </span>
        </li>
        {page - 3 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 3)}>
            <span>...</span>
          </li>
        )}
        {page - 2 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 2)}>
            <span>{page - 2}</span>
          </li>
        )}
        {page - 1 > 0 && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page - 1)}>
            <span>{page - 1}</span>
          </li>
        )}
        <li className={isFetching ? 'active disabled' : 'active'}>
          <span>
            {page} <span className='sr-only'>(current)</span>
          </span>
        </li>
        {page + 1 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 1)}>
            <span>{page + 1}</span>
          </li>
        )}
        {page + 2 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 2)}>
            <span>{page + 2}</span>
          </li>
        )}
        {page + 3 <= totalPages && (
          <li className={isFetching ? 'disabled' : ''} onClick={!isFetching && this.handlePageSelect(page + 3)}>
            <span>...</span>
          </li>
        )}
        <li
          className={page === totalPages || isFetching ? 'disabled' : ''}
          onClick={page !== totalPages && !isFetching && this.handlePageSelect(page + 1)}
          >
          <span>
            <span aria-hidden='true'>&raquo;</span>
          </span>
        </li>
        <li
          className={page === totalPages || isFetching ? 'disabled' : ''}
          onClick={page !== totalPages && !isFetching && this.handlePageSelect(totalPages)}
          >
          <span>Last</span>
        </li>
      </ul>
    );
  }

  renderActions() {
    const { actions, selectable } = this.props;
    const { selectedItemIds } = this.state;
    return (
      <div style={{ alignSelf: 'flex-end', alignItems: 'center', display: 'flex', marginBottom: '1em' }}>
        {selectable && (
          <button
            type='button'
            className='btn btn-primary'
            onClick={this.handleSelectAllChange(!this.isAllSelected())}
            >
            {this.isAllSelected() ? 'Deselect All' : 'Select All'}
          </button>
        )}
        <div className='text-default' style={{ margin: '4px 1em 0 1em' }}>
          Selected <b>{selectedItemIds.length}</b> item(s)
        </div>
        {actions.map((action, i) => (
          React.cloneElement(action, {
            key: i,
            onClick: action.props.onClick ? () => action.props.onClick(this.getSelectedItems()) : null,
          })
        ))}
      </div>
    );
  }

  render() {
    const { actions, selectable, idSelector, withPagination, limit, headers, items, ListItem, makeKey, striped, isFetching, style = {}, onItemsSelect, ...rest } = this.props;
    const { page } = this.state;

    const computedStyle = {
      ...style,
      filter: withPagination && isFetching ? 'blur(1px)' : style.filter || 'initial',
    };

    const paginatedItems = !this.isClientPagination() ? items : items.filter((_, i) => (page - 1) * limit <= i && i < page * limit);

    return (
      <div>
        {actions.length > 0 && this.renderActions()}
        <div {...rest} className={cs('BlockTable', { striped }, rest.className)} style={computedStyle}>
          {paginatedItems.map((item, i) => (
            <div key={makeKey ? makeKey(item) : i} className='BlockTable__main'>
              <table className='table'>
                <ListItem
                  {...item}
                  headers={headers}
                  selected={this.isItemSelected(item)}
                  selectable={selectable}
                  onSelect={this.handleItemSelect(item)}
                />
              </table>
              {paginatedItems.length - 1 !== i &&
                <div
                  style={{
                    width: '100%',
                    height: '5px',
                    backgroundColor: '#616365',
                    marginTop: '16px',
                    marginBottom: '16px',
                  }}
                />}
            </div>
          ))}
          {isFetching &&
            withPagination &&
            Boolean(items.length) && (
            <LoadingOverlay>
              <Spinner style={{ margin: 'initial' }} />
            </LoadingOverlay>
          )}
        </div>
        {Boolean(items.length) && withPagination && this.renderPagination()}
      </div>
    );
  }
}

BlockTable.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string),
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
  ListItem: PropTypes.func.isRequired,
  selectable: PropTypes.bool,
  idSelector: PropTypes.string,
  actions: PropTypes.arrayOf(PropTypes.node),
};

BlockTable.defaultProps = {
  selectable: false,
  idSelector: 'id',
  actions: [],
};


export default compose(
  placeholder(props => !props.isFetching),
  infiniteScroll()
)(BlockTable);
