/**
 * Error that represents errors while fetching.
 * @module datatypes/FetchError
 * @since 3.0.0
 */

export class FetchError {
  constructor(status, message) {
    this.status = status;
    this.message = message;
  }
}

export default FetchError;
