import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';
import setDisplayName from 'recompose/setDisplayName';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';

import PermissionsError from 'datatypes/PermissionsError';

import RateIndexPermissionsErrorTable from 'components/pure/RateIndexPermissionsErrorTable';
import genericPlaceholder from 'components/hoc/genericPlaceholder';
import spinnerIf from 'components/hoc/spinnerIf';
import RateIndexBlockTable from 'components/pure/RateIndexBlockTable';


const placeholderStyle = { fontSize: 16 };

export default compose(
  setDisplayName('RateIndexToolBlockTable'),
  connect(state => state.rateindex),
  spinnerIf(props => props.isFetching),
  branch(
    props => props.err && props.err instanceof PermissionsError,
    renderComponent(RateIndexPermissionsErrorTable),
    Component => Component,
  ),
  genericPlaceholder(props => props.data === undefined, {
    placeholder: 'Run Rate Check to Display Rate Data',
    style: placeholderStyle,
  }),
  genericPlaceholder(props => props.data.average_rate === undefined, {
    placeholder: 'No rate data available for this request at this time.',
    style: placeholderStyle,
  }),
  mapProps(props => ({
    style: {
      marginTop: '1em',
    },
    map: {
      data: props.data.map,
    },
    item: {
      data: {
        linehaul: {
          average_rate: props.data.average_rate,
          linehaul_cpg: props.data.linehaul_cpg,
        },
      },
    },
  })),
)(RateIndexBlockTable);
