/*global API */

import { flatten } from 'lodash';

export const getLineItemCategory = id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/line_items/categories/${id}/`, {
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status >= 300) {
    throw new Error('Error getting the line item category!');
  }

  const json = await res.json();
  return json;
};

export const deleteLineItemCategory = id => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/line_items/categories/${id}/`, {
    method: 'DELETE',
    headers: {
      authorization: `bearer ${getState().user.token}`,
    },
  });

  if (res.status >= 300) {
    throw new Error('Error deleting the line item category!');
  }

  return;
};

export const getLineItemCategories = () => async (dispatch, getState) => {
  let exausted = false;
  const categories = [];

  let next;

  do {
    const res = await global.fetch(next ? next : `${API.host}/funding_requests/line_items/categories/`, {
      headers: {
        authorization: `bearer ${getState().user.token}`,
      },
    });
    const json = await res.json();

    if (!json.next) {
      exausted = true;
    }
    else {
      next = json.next;
    }

    categories.push(json.results);
  } while (!exausted);

  return flatten(categories);
};

export const updateLineItemCategory = id => ({
  applied_against,
  applied_by,
  name,
  is_purchased_with_fr,
  is_client_invoiced,
  is_debtor_invoiced,
  should_collate,
  discount_rate,
  apply_discount,
  description,
  invoice_name,
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/line_items/categories/${id}/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      applied_against,
      applied_by,
      name,
      is_purchased_with_fr,
      is_client_invoiced,
      is_debtor_invoiced,
      should_collate,
      discount_rate,
      apply_discount,
      description,
      invoice_name,
    }),
  });

  if (res.status >= 300) {
    throw new Error('Error creating the line item category!');
  }

  const json = await res.json();
  return json;
};

export const createLineItemCategories = ({
  applied_against,
  applied_by,
  name,
  is_purchased_with_fr,
  is_client_invoiced,
  is_debtor_invoiced,
  should_collate,
  discount_rate,
  apply_discount,
  description,
  invoice_name,
}) => async (dispatch, getState) => {
  const res = await global.fetch(`${API.host}/funding_requests/line_items/categories/`, {
    method: 'POST',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      applied_against,
      applied_by,
      name,
      is_purchased_with_fr,
      is_client_invoiced,
      is_debtor_invoiced,
      should_collate,
      discount_rate,
      apply_discount,
      description,
      invoice_name,
    }),
  });

  if (res.status >= 300) {
    throw new Error('Error creating the line item category!');
  }

  const json = await res.json();
  return json;
};
