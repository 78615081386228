import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { unHidePost } from 'actions/hide';
import HiddenPostMobile from 'components/pure/HiddenPostMobile';


export default compose(
  connect(
    null,
    { unHide: unHidePost },
  ),
)(HiddenPostMobile);
