/**
 * Reducer for handling the map resource actions
 * @module reducers/resource/map
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/map
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.factoringpaymentrelation;

import factoringpaymentrelationResource from '../../actions/resource/factoringpaymentrelation';


export default function factoringpaymentrelationResourceReducer(state = initialState, action) {
  state = factoringpaymentrelationResource.reduce(state, action);
  return state;
}
