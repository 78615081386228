/**
 * Reducer for handling the factoringdebtor search actions
 * @module reducers/search/factoringdebtor
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/search/factoringdebtor
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.search.factoringdebtor;

import factoringdebtorSearchResource from 'actions/search/factoringdebtor';


export default function factoringdebtorSearchReducer(state = initialState, action) {
  state = factoringdebtorSearchResource.reduce(state, action);
  return state;
}
