import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import getCurrentLocation from 'helpers/getCurrentLocation';
import getNearestCity from 'helpers/getNearestCity';
import { openModal } from 'actions/ui';

import wrapPromisePending from 'components/hoc/wrapPromisePending';


export default compose(
  connect(
    null,
    {
      openModal,
    },
  ),
  wrapPromisePending({
    promiseProp: 'onClick',
    promiseFn: ({ onClick, openModal }) => async () => {
      try {
        const nearestCity = await getNearestCity();
        onClick(nearestCity);
      }
      catch (err) {
        console.warn(err);
        openModal('warning', { message: 'Current location is not available at this time.' });
      }
    },
  }),
)(({ component = 'button', submitting, ...rest }) => {
  if (!getCurrentLocation.isAvailable) {
    return null;
  }
  return React.createElement(
    component,
    {
      ...rest,
      style: {
        ...(() => submitting ? {
          color: colors.BORDER_GRAY,
          cursor: 'progress',
        } : undefined)(),
        ...rest.style,
      },
    },
    rest.children,
  );
});
