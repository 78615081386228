import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import PostHistory from 'components/pure/PostHistory';
import docTitle from 'components/hoc/docTitle';
import ResourceBy from 'components/hoc/ResourceBy';
import spinnerIf from 'components/hoc/spinnerIf';
import LoadPostHistoryTable from 'components/pure/LoadPostHistoryTable';
import { displayHistory } from 'actions/post';

export default compose(
  docTitle('Load Post History'),
  connect((state, { location: { pathname } }) => {
    const fetchArgs = pathname === '/postload/haulpay' ? { haulpay_loads: true } : {};
    return { id: state.user.id, fetchArgs };
  }, { displayHistory }),
  ResourceBy('user', 'loadhistory', { shouldClearBeforeFetch: true }),
  spinnerIf(({ item }) => item === undefined),
)(({ id, item, actions, displayHistory, displayHistoryButton = false, history = false }) =>
  <PostHistory
    options={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerType',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Deadhead',
        ordering: 'deadhead',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
    ]}
    displayHistoryButton={displayHistoryButton}
    displayHistory={displayHistory}
    history={history}
    currentOrder={item.ordering}
    sort={actions.sort}
    Table={LoadPostHistoryTable}
    ids={item.ids}
    isFetching={item.isFetching}
    fetchNext={actions.fetchNext}
  />
);
