import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';
import get from 'lodash/get';

import Resource from 'components/hoc/Resource';
import GoogleMap from 'components/pure/GoogleMap';

export default compose(
  Resource('geocode', {
    idPropName: ({ location }) => location,
  }),
  mapProps(({ location, item, ...rest }) => {
    const point = get(item, 'data.geometry.location');
    if (point === undefined) {
      return rest;
    }
    return {
      ...rest,
      points: [
        point.toJSON(),
      ],
    };
  }),
)(GoogleMap);
