/**
 * Generic class for settings-resource-related fetching and serialization. Note that not all SettingsResources implement all SettingsResources methods (some endpoints don't exist).
 * @module datatypes/SettingsResource
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 */
/*global API */
import moment from 'moment';
import get from 'lodash/get';

import APIFetch from './APIFetch';
import FetchError from './FetchError';

/**
 * SettingsResource class
 */
class SettingsResource {
  /**
   * Create a SettingsResource
   * @param {string} name - the unique identifier for this SettingsResource
   * @param {object} options
   * @param {string} options.mountPoint - where in the overall state to find this SettingsResource's state
   * @param {object} [options.hooks] - hooks for editing fetched data before serialization
   * @param {function} [options.parse] - function to override this instance's parse function
   * @param {function} [options.hooks.preDispatchCreateSuccess] - hook for editing fetched data before the create action is dispatched
   * @param {function} [options.hooks.preDispatchFetchSuccess] - hook for editing fetched data before the fetch action is dispatched
   * @param {function} [options.hooks.preDispatchEditSuccess] - hook for editing fetched data before the edit action is dispatched
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, options = {}) {

    if (typeof name !== 'string') {
      throw new Error(`${this.constructor.name} requires a name`);
    }
    if (typeof options.mountPoint !== 'string') {
      throw new Error(`${this.constructor.name} requires a mountPoint`);
    }

    this.name = name;
    this.options = {
      acceptableStatusCode: 200,
      acceptableCreateStatusCode: 201,
      ...options,
      hooks: {
        preDispatchCreateSuccess: data => data,
        preDispatchFetchSuccess: data => data,
        preDispatchEditSuccess: data => data,
        ...options.hooks,
      },
    };


    const upperName = this.name.toUpperCase();
    this.actions = {
      FETCH_REQUEST: Symbol(`${upperName}_SETTINGS_FETCH_REQUEST`),
      FETCH_REQUEST_SUCCESS: Symbol(`${upperName}_SETTINGS_FETCH_REQUEST_SUCCESS`),
      FETCH_REQUEST_FAILURE: Symbol(`${upperName}_SETTINGS_FETCH_REQUEST_FAILURE`),
      EDIT_REQUEST: Symbol(`${upperName}_SETTINGS_EDIT_REQUEST`),
      EDIT_REQUEST_SUCCESS: Symbol(`${upperName}_SETTINGS_EDIT_REQUEST_SUCCESS`),
      EDIT_REQUEST_FAILURE: Symbol(`${upperName}_SETTINGS_EDIT_REQUEST_FAILURE`),
      CREATE_REQUEST: Symbol(`${upperName}_SETTINGS_CREATE_REQUEST`),
      CREATE_REQUEST_SUCCESS: Symbol(`${upperName}_SETTINGS_CREATE_REQUEST_SUCCESS`),
      CREATE_REQUEST_FAILURE: Symbol(`${upperName}_SETTINGS_CREATE_REQUEST_FAILURE`),
    };

    this.fetch = this.fetch.bind(this);
    this.edit = this.edit.bind(this);
    this.create = this.create.bind(this);
  }

  /**
   * Fetch request action. Marks that a request to fetch a SettingsResource has been made
   * @event module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
   * @property {symbol} type - Symbol({NAME}_SETTINGS_FETCH_REQUEST)
   */
  /**
   * Fetch request success action. Marks that a request to fetch a SettingsResource has been made successfully
   * @event module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_SETTINGS_FETCH_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  /**
   * Fetch request failure action. Marks that a request to fetch a SettingsResource has failed
   * @event module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_SETTINGS_FETCH_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching this SettingsResource, or an error
   * @fires module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
   * @fires module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
   */
  fetch() {
    return (dispatch, getState) => {
      const state = getState();
      const item = get(state, this.options.mountPoint);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: undefined,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url()}/`, {
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.FETCH_REQUEST_FAILURE,
            payload: err,
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: this.options.hooks.preDispatchFetchSuccess(json),
        }))
        ; // eslint-disable-line indent
    };
  }

  /**
   * Edit request action. Marks that a request to edit a SettingsResource has been made
   * @event module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
   * @property {symbol} type - Symbol({NAME}_SETTINGS_EDIT_REQUEST)
   */
  /**
   * Edit request success action. Marks that a request to edit a SettingsResource has been made successfully
   * @event module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_SETTINGS_EDIT_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  /**
   * Edit request failure action. Marks that a request to edit a SettingsResource has failed
   * @event module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_SETTINGS_EDIT_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully editing this SettingsResource, or an error
   * @fires module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
   * @fires module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
   * @fires module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
   */
  edit(data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = get(state, this.options.mountPoint);

      if (item && item.isFetching) {
        return Promise.resolve();
      }

      let promise = Promise.resolve();
      if (item.data === undefined || item.data.id === undefined) {
        promise = promise.then(() => dispatch(this.fetch()));
      }

      return promise
        .then(() => {
          dispatch({
            type: this.actions.EDIT_REQUEST,
            payload: undefined,
          });
          const item = get(getState(), this.options.mountPoint);
          return dispatch(APIFetch(`${API.host}/${this.options.url(item)}/`, {
            method: 'PUT',
            body: JSON.stringify(data),
            headers: {
              Authorization: `Bearer ${state.user.token}`,
              'Content-Type': 'application/json',
            },
          }));
        })
        .then(res => res.status !== this.options.acceptableStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.EDIT_REQUEST_FAILURE,
            payload: err,
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: this.options.hooks.preDispatchEditSuccess(json),
        }))
        ; // eslint-disable-line indent
    };
  }

  /**
   * Create request action. Marks that a request to create a SettingsResource has been made
   * @event module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
   * @property {symbol} type - Symbol({NAME}_SETTINGS_CREATE_REQUEST)
   */
  /**
   * Create request success action. Marks that a request to create a SettingsResource has been made successfully
   * @event module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_SETTINGS_CREATE_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  /**
   * Create request failure action. Marks that a request to create a SettingsResource has failed
   * @event module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_SETTINGS_CREATE_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  /**
   * Might be completely unused now. Previously, some settings objects weren't automatically created on the backend when a user was created
   * @todo Potentially remove these functions
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully creating this SettingsResource, or an error
   * @fires module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
   * @fires module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
   * @fires module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
   */
  create(data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = get(state, this.options.mountPoint);

      if (item && item.isCreating) {
        return Promise.resolve();
      }
      data.user = state.user.id;
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url()}/`, {
        method: 'POST',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableCreateStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.CREATE_REQUEST_FAILURE,
            payload: err,
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: this.options.hooks.preDispatchCreateSuccess(json),
        }))
        ; // eslint-disable-line indent
    };
  }

  /**
   * @returns The default initial state for this SettingsResource
   */
  getDefaultState() {
    return {
      isFetching: false,
      isCreating: false,
      fetchedAt: null,
      data: null,
      err: null,
    };
  }

  /**
   * @param {object} oldValue - the current data (if any) of this SettingsResource, previous to fetching
   * @param {object} json - the data fetched from the server
   * @returns The parsed new value for this SettingsResource
   */
  parse(oldValue = {}, json) {
    if (this.options.parse) {
      return this.options.parse.apply(this, arguments); // eslint-disable-line prefer-rest-params
    }
    return {
      isFetching: false,
      isCreating: false,
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: {
        ...oldValue.data,
        ...json,
      },
    };
  }

  /**
   * The reducer for this SettingsResource. Manages state changes triggered by actions specific to this SettingsResource.
   * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST
   * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_SUCCESS
   * @listens module:datatypes/SettingsResource~SettingsResource#FETCH_REQUEST_FAILURE
   * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST
   * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_SUCCESS
   * @listens module:datatypes/SettingsResource~SettingsResource#EDIT_REQUEST_FAILURE
   * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST
   * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_SUCCESS
   * @listens module:datatypes/SettingsResource~SettingsResource#CREATE_REQUEST_FAILURE
   * @param {object} state - the current state of this SettingsResource
   * @param {object} action - the dispatched action
   * @returns The new state for this SettingsResource
   */
  reduce(state, action) {
    switch (action.type) {
      case this.actions.FETCH_REQUEST:
        return {
          ...this.parse(state),
          isFetching: true,
        };
      case this.actions.FETCH_REQUEST_SUCCESS:
        return {
          ...this.parse(state, action.payload),
          isFetching: false,
          fetchedAt: moment(),
          err: null,
        };
      case this.actions.FETCH_REQUEST_FAILURE:
        return {
          ...this.parse(state),
          isFetching: false,
          err: action.payload,
        };
      case this.actions.EDIT_REQUEST:
        return {
          ...this.parse(state),
          isFetching: true,
        };
      case this.actions.EDIT_REQUEST_SUCCESS:
        return {
          ...this.parse(state, action.payload),
          isFetching: false,
          err: null,
        };
      case this.actions.EDIT_REQUEST_FAILURE:
        return {
          ...this.parse(state),
          isFetching: false,
          err: action.payload.err,
        };
      case this.actions.CREATE_REQUEST:
        return {
          ...this.parse(state),
          isCreating: true,
        };
      case this.actions.CREATE_REQUEST_SUCCESS:
        return {
          ...this.parse(state, action.payload),
          isCreating: false,
          err: null,
        };
      case this.actions.CREATE_REQUEST_FAILURE:
        return {
          ...this.parse(state),
          isCreating: false,
          err: action.payload.err,
        };
    }
    return state;
  }
}

export default SettingsResource;
