/**
 * Component for displaying a button that is disabled while the
 * @module components/pure/PromiseButton
 * @since 3.0.0
 * @property {object} props
 * @property {function} props.onClick - the onClick handler for this button. MUST return a Promise
 * @property {any} [props....rest] - props passed to button component
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';


class PromiseButton extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
    this.onClick = this.onClick.bind(this);
    this.hasUnmounted = false;
  }

  componentWillUnmount() {
    this.hasUnmounted = true;
    if (this.promise && typeof this.promise.cancel === 'function') {
      this.promise.cancel();
    }
  }

  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  onClick(e) {
    const { onClick } = this.props;
    this.setState({ submitting: true });
    try {
      this.promise = onClick()
        .then(() => {
          if (!this.hasUnmounted) {
            this.setState({ submitting: false });
          }
        })
        .catch((...args) => {
          if (!this.hasUnmounted) {
            this.setState({ submitting: false });
          }
          return Promise.reject(...args);
        });
    }
    catch (err) {
      console.log();
    }
  }

  render() {
    const { onClick, ...rest } = this.props;
    const { submitting } = this.state;
    return <button {...rest} disabled={submitting} onClick={this.onClick} />;
  }
}

export default PromiseButton;
