/**
 * Component that displays a Truck or Load Post page
 * @module components/pure/Post
 * @since 3.0.0
 * @requires components/pure/MediaQuery
 * @requires components/pure/Header
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {string} [props.editId] - the ID of a truck or load that is being edited, if any
 * @property {function} [props.cancelEdit] - a function that cancels 'copy' or 'edit' mode
 * @property {node} props.headerText - the node to be placed in the header
 * @property {node} [props.proTip] - the node to be placed in a ProTip
 * @property {string} props.myResourceUrl - the url for the "My Xs" button
 * @property {node} props.myResourceButtonText - the child node for the "My Xs" button
 * @property {node} props.submitButtonText - the child node for the submit button
 * @property {boolean} props.isSubmitting - whether the form is currently being submitted
 * @property {element} props.Form - the component to use for the form
 * @property {function} props.submitHandler - the function to call when the form is submitted
 * @property {function} [props.validator] - the validator to be used in the form
 * @property {element} props.Results - the component to display in the results section of the page
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/Post';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-v4';
import { submit } from 'redux-form';

import MediaQuery from 'components/pure/MediaQuery';
import Header from 'components/pure/Header';
import Spinner from 'components/pure/Spinner';
import Toggle from 'material-ui/Toggle';
import { Button } from 'react-bootstrap';

export class Post extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
  }

  shouldComponentUpdate(nextProps) {
    const { props } = this;
    return (
      props.editId !== nextProps.editId ||
      props.isSubmitting !== nextProps.isSubmitting ||
      // if cancelEdit is undefined, we're creating a new thing
      // if it's not undefiend, we're editing and need to change
      props.cancelEdit !== nextProps.cancelEdit ||
      props.currentLoad !== nextProps.currentLoad
    );
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { props } = this;
    const { openPostLoadModal, isFactoringBroker, cancelEdit, submitHandler, validator, Form, isSubmitting, submitButtonText, closeModal } = props;
    if (props.editId !== prevProps.editId && props.editId !== undefined) {
      if (prevProps.editId === 'loading') {
        closeModal();
      }
      openPostLoadModal({
        children: <Form
          onSubmit={submitHandler}
          validate={validator}
          ref={ref => this.form = ref || this.form}
        />,
        title: 'Edit Load',
        control: <div style={{ display: 'flex', width: '100%' }}>
          <div style={{ justifyContent: 'flex-start' }}>
            <button className='btn btn-default' onClick={cancelEdit} disabled={isSubmitting}>Cancel</button>
          </div>
          <div style={{ justifyContent: 'flex-end', width: '100%' }}>
            <button className='btn btn-orange' onClick={this.submit} disabled={isSubmitting}>{submitButtonText}</button>
          </div>
        </div>,
        headerStyle: { color: '#E86C00', marginBottom: '20' },
        displayCloseIcon: true,
        customCloseModal: cancelEdit
      });
    }
  }

  componentWillUnmount() {
    const { cancelEdit } = this.props;
    this.form = null;
    if (cancelEdit) {
      cancelEdit();
    }
  }

  static propTypes = {
    // left-hand side
    headerText: PropTypes.string.isRequired,
    proTip: PropTypes.node,
    myResourceUrl: PropTypes.string.isRequired,
    myResourceButtonText: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    isSubmitting: PropTypes.bool.isRequired,
    cancelEdit: PropTypes.func,
    // form
    Form: PropTypes.element.isRequired,
    submitHandler: PropTypes.func.isRequired,
    validator: PropTypes.func,
    // right-hand side
    Results: PropTypes.func.isRequired,
    resultsHeaderText: PropTypes.node.isRequired,
    //Modal
    openPostLoadModal: PropTypes.func.isRequired,
  };

  submit() {
    const { dispatch } = this.props;
    dispatch(submit('LoadPost'));
  }

  render() {
    const {
      headerText,
      proTip,
      myResourceUrl,
      myResourceButtonText,
      submitButtonText,
      isSubmitting,
      cancelEdit,
      uploadExcel,
      setReverse,
      Form,
      submitHandler,
      validator,
      Results,
      resultsHeaderText,
      openPostLoadModal,
      openPendingPayment,
      location,
      displayHistory,
      history = false,
      setPostLoadRef,
      currentLoad,
      setCurrentLoad
    } = this.props;
    // openPendingPayment();
    // const form = compose()(() => <Form onSubmit={submitHandler} validate={validator} ref={ref => this.form = ref || this.form} />);
    let uploadExcelButton = null;
    let cancelButton = null;
    let myResourceButton = null;
    let myResourceButtonMobile = null;
    let myResourceButtonDesktop = null;
    if (typeof cancelEdit === 'function') {
      cancelButton = <button className='btn btn-default' onClick={cancelEdit}>Cancel</button>;
    }
    else {
      if (uploadExcel) {
        uploadExcelButton = <button className='btn btn-default pull-left' onClick={uploadExcel}>Upload File</button>;
      }
      myResourceButton = <Link to={myResourceUrl} style={{ marginRight: 5 }}><button className='btn btn-default'>{myResourceButtonText}</button></Link>;
      myResourceButtonMobile = <Link to={`${location.pathname}/history`} style={{ marginRight: 5 }}><button className='btn btn-default' style={{ fontSize: '0.85em' }}>My History</button></Link>;
      myResourceButtonDesktop = <Link to={`${location.pathname}/history`} style={{ marginRight: 5 }}><button className='btn btn-default'>View History</button></Link>;
    }
    const reverseButton = typeof setReverse === 'function' ? <button className='btn btn-default' onClick={setReverse}>Post Reverse</button> : null;
    const spinner = isSubmitting ? <div><Spinner /></div> : null;
    const openLoadModalPost = () => openPostLoadModal({
      children: <Form
        onSubmit={submitHandler}
        validate={validator}
        ref={ref => this.form = ref || this.form}
      />,
      title: 'Post a Load',
      control: <button
        className='btn btn-orange'
        onClick={this.submit}
        disabled={isSubmitting}
        >
        {submitButtonText}
      </button>,
      headerStyle: { color: '#E86C00', marginBottom: '20' },
    });
    const ResultLoad = Results(currentLoad, { pathname: location.pathname, openLoadModalPost });
    return (
      <div className='full-height' style={{ width: '100%' }}>
        <div className='container' style={{ width: '100%', padding: '1.065em' }}>
          <div className='col-xs-6' style={{ marginTop: '1.265em', display: 'flex', flexDirection: 'row' }}>
            <p style={{ display: 'flex', fontSize: '16', fontWeight: 'normal', color: '#5c6979' }}>
              Only Current Loads
            </p>
            <div className='col-xs-1'>
              <Toggle
                defaultToggled={currentLoad}
                onToggle={(value, isInputChecked) => setCurrentLoad(isInputChecked)}
              />
            </div>
          </div>
          <div className='col-xs-6' style={{ marginTop: '1.265em', marginBottom: '1.265em', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              size='small'
              className='btn btn-blue'
              onClick={openLoadModalPost}
              id='post_load'
              ref={ref => setPostLoadRef(ref)}
              >
              Post Load
            </Button>
          </div>
          <div className='col-xs-12' style={{ minHeight: 600 }}>
            {React.cloneElement(<ResultLoad />)}
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
