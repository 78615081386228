import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import setDisplayName from 'recompose/setDisplayName';
import cs from 'classnames';

import { openModal } from 'actions/ui';


export default compose(
  setDisplayName('ProTip'),
  connect(
    null,
    (dispatch, ownProps) => ({
      onClick() {
        dispatch(openModal('generic', {
          message: ownProps.message,
          headerStyle: {
            backgroundColor: '#5cb85c',
            color: 'white',
          },
          headerIcon: 'done',
          headerChildren: `Pro Tip: ${ownProps.name}`,
          youtubeEmbed: ownProps.youtubeEmbed,
        }, [(ownProps.name || 'unspecified').replace(/\s+/g, '').toLowerCase(), 'protip']));
      },
    }),
  ),
)(({ message, name, youtubeEmbed, ...rest }) =>
  <button
    {...rest}
    className={cs('btn btn-default', rest.className)}
    style={{
      fontSize: '0.85em',
      ...rest.style,
    }}
    >
    ProTip
  </button>
);
