/**
 * Component that displays a button prompting the user to sign up
 * @module components/pure/SignUpButtonLink
 * @since 3.0.0
 * @property {object} props
 * @property {node|node[]} [props.children = 'Sign Up For Access'] - button child node
 * @property {object} [props.href = '/signup'] - the URL where the user can sign up
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import { Link } from 'react-router-v4';
import cs from 'classnames';


const SignUpButtonLink = ({ children = 'Sign Up For Access', href = '/signup', ...rest }) =>
  <Link to={href}>
    <button {...rest} className={cs('btn btn-orange', rest.className)}>
      {children}
    </button>
  </Link>
  ;

export default SignUpButtonLink;
