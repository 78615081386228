import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringdebtor.factoringclient;

import factoringclientByfactoringdebtorResource from 'actions/resourceBy/factoringdebtor/factoringclient';


export default function factoringclientByfactoringdebtorResourceReducer(state = initialState, action) {
  state = factoringclientByfactoringdebtorResource.reduce(state, action);
  return state;
}
