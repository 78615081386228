/**
 * Connected component for both the Load and Truck deletion modals
 * @module components/pure/modals/Delete
 * @since 3.0.0
 * @requires actions/resource/load
 * @requires actions/resource/truck
 * @requires helpers
 * @requires components/hoc/Modal
 * @requires components/hoc/spinnerIf
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {string} props.resource - 'load' or 'truck'
 * @property {string} props.id - ID of the load or truck to be deleted
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { browserHistory } from 'react-router-v4';
import moment from 'moment';

import { doDelete as deleteLoad } from 'actions/resource/load';
import { doDelete as deleteTruck } from 'actions/resource/truck';

import { inferTruckRouteType, isMobile } from 'helpers';
import Modal from 'components/hoc/Modal';
import spinnerIf from 'components/hoc/spinnerIf';
import Spinner from 'components/pure/Spinner';


const ModalDelete = ({ item, closeModal, doDelete, formalName, name, detailsLink }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>Confirm {formalName} Removal</h4>
    </div>
    <div className='modal-body'>
      {name === 'truck' ?
        (() => {
          const { origin, destination } = inferTruckRouteType(item.data);
          return <p>Are you sure you would like to remove your {name} from {origin} to {destination} ?</p>;
        })()
        :
        <p>Are you sure you would like to remove your {name} from {item.data.origin_city}, {item.data.origin_state} to {item.data.destination_city}, {item.data.destination_state} ?</p>
      }
      <p>This {name} post will be moved to history and removed from this view.</p>
      <p>You posted this on {item.data.time_posted.format('LLLL')}</p>
    </div>
    <div className='modal-footer'>
      {item.err && <div className='alert alert-danger' role='alert'>Error: {JSON.stringify(item.err)}</div>}
      <button className='btn btn-default' disabled={item.isFetching} onClick={closeModal}>Cancel</button>
      <button className='btn btn-danger' disabled={item.isFetching} onClick={() => doDelete(item.data.id)}>Remove</button>
      {item.isFetching && <Spinner />}
    </div>
  </div>
  ;

ModalDelete.propTypes = {
  item: PropTypes.shape({
    data: PropTypes.shape({
      origin_city: PropTypes.string.isRequired,
      origin_state: PropTypes.string.isRequired,
      destination_city: PropTypes.string.isRequired,
      destination_state: PropTypes.string.isRequired,
      time_posted: PropTypes.instanceOf(moment).isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    err: PropTypes.any,
  }).isRequired,
  detailsLink: PropTypes.string.isRequired,
  formalName: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  closeModal: PropTypes.func.isRequired,
  doDelete: PropTypes.func.isRequired,
};


export default compose(
  Modal,
  connect(
    (state, ownProps) => {
      const { id, resource } = ownProps;
      const [formalName, name] = (() => {
        switch (resource) {
          case 'truck':
            return ['Truck', 'truck'];
          case 'load':
          default:
            return ['Load', 'load'];
        }
      })();
      return {
        item: state.resource[resource][id],
        detailsLink: `/${resource === 'truck' ? 'truck' : 'load'}/${id}`,
        formalName,
        name,
      };
    },
    (dispatch, ownProps) => {
      const { extraArgument = '' } = ownProps;
      const doDelete = ownProps.resource === 'truck' ? deleteTruck : deleteLoad;
      return {
        doDelete(id) {
          dispatch(doDelete(id))
            .then(() => dispatch(ownProps.closeModal()))
            .then(() => {
              if (!isMobile()) {
                browserHistory.replace(`/post${ownProps.resource}/${extraArgument}`);
              }
            })
          ;
        },
      };
    },
  ),
  spinnerIf(({ item }) => item === undefined || item.data === undefined),
)(ModalDelete);
