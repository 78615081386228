/* global API */
import { compose } from 'redux-v3';

import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';

export const financespurchaseResource = compose(
  Singleton('financespurchase'),
  Fetchable({
    url: id => `purchases/${id}/`,
  }),
  Editable({
    url: id => `purchases/${id}/`,
  }),
)();

export const {
  selector,
  fetch,
  edit,
} = financespurchaseResource;

export default financespurchaseResource;
