/**
 * Component that displays a normal infinite scroll (without only rendering the items that are in view)
 * @module components/pure/NormalInfiniteScroll
 * @since 3.0.0
 * @requires components/pure/Spinner
 * @property {object} props
 * @property {object} props.onScrollBottom - function to call when the bottom of the child element reaches the bottom of the containing element
 * @property {bool} [props.isFetching] - whether to display the loading spinner
 * @property {node|node[]} props.children
 * @property {node} [props.placeholder]
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/InfiniteScroll';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import shallowEqual from 'recompose/shallowEqual';
import debounce from 'lodash/debounce';

import Spinner from 'components/pure/Spinner';


class NormalInfiniteScroll extends Component {
  constructor(props) {
    super(props);
    this.onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', debounce(this.onScroll, 200, { leading: true }), true);
  }
  componentWillUnmount() {
    window.removeEventListener('scroll', 
    debounce(this.onScroll, 200, { leading: true }), true);
  }

  shouldComponentUpdate(nextProps) {
    return !shallowEqual(this.props, nextProps);
  }

  static propTypes = {
    onScrollBottom: PropTypes.func.isRequired,
    isFetching: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.node,
      PropTypes.arrayOf(PropTypes.node),
    ]),
    placeholder: PropTypes.node,
  };

  onScroll() {
    const { onScrollBottom, isFetching } = this.props;
    if (!onScrollBottom && !isFetching) {
      return;
    }
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 30
    ) {
      onScrollBottom();
    }
  }

  render() {
    const { children, isFetching, placeholder, onScrollBottom, ...rest } = this.props;
    return (
      <div
        {...rest}
        style={{
          display: 'flex',
          flex: 1,
          flexGrow: 1,
          overflowY: 'auto',
          flexFlow: 'column nowrap',
          flexDirection: 'column',
          overflowX: 'hidden',
        }}
        className={rest.className}
        ref={ref => this.container = ref}
        >
        {children}
        {isFetching && <div style={{ textAlign: 'center' }}><Spinner /></div>}
        {typeof placeholder !== 'boolean' && !isFetching && (!children || !children.length) ?
          placeholder ? placeholder : <div className='help-block' style={{ textAlign: 'center', marginTop: '10%' }}>Nothing here yet</div>
          : null}
      </div>
    );
  }
}

export default NormalInfiniteScroll;
