import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.financespayments;

import financespaymentsPaginatedResource from 'actions/admin/financespayments';


export default function financespaymentsPaginatedReducer(state = initialState, action) {
  state = financespaymentsPaginatedResource.reduce(state, action);
  return state;
}
