import React from 'react';
import { compose } from 'recompose';
import ModalBody from 'components/pure/ModalBody';
import ModalFooter from 'components/pure/ModalFooter';
import { connect } from 'react-redux-v5';
import { closeModal } from '../../../actions/ui';
import PureUploadFile from '../PureUploadFile';
import { TableCell, TableRow } from '@material-ui/core';

const ModalTitle = ({ title }) => (
  <h4 className='modal-title text-orange'>{title}</h4>
);

const categories = [
  {
    text: 'Signed co-broker agreement',
    value: 'Signed co-broker agreement',
  },
  {
    text: 'Other',
    value: 'Other',
  },
];

const mappedCategories = {
  'Signed co-broker agreement': 'Signed co-broker agreement',
  'Other': 'Void Other',
};

const debtorCategories = [
  {
    text: 'Shipper Acknowledgement ',
    value: 'Shipper Acknowledgement ',
  },
  {
    text: 'Other',
    value: 'Other',
  },
];
const CoBrokeringUploadDocs = ({ closeModal, relationshipId, attachments, debtorRelation, canUploadNew = true }) =>
  <div>
    <ModalBody closeModal={closeModal}>
      <ModalTitle title={'Co-Broker Upload Docs'} />
        <ModalBody>
            <PureUploadFile
                categoriesProps={{
                    abbreviationKey: 'value',
                    fulltextKey: 'text',
                    breakPoint: 2,
                }}
                categories={!debtorRelation ? categories : debtorCategories}
                requestId={relationshipId}
                type={!debtorRelation ? 'CO_BROKER_AGREEMENT_DOCS' : 'CO_BROKER_DEBTOR_AGREEMENT_DOCS'}
                attachments={attachments}
                visibility={false}
                canUploadNew={canUploadNew}
                canViewFiles={true}
            />
        </ModalBody>
    </ModalBody>
      <ModalFooter>
          <button className='btn btn-default' type='button' onClick={closeModal}>Done</button>
      </ModalFooter>
  </div>;

export default compose(connect(null, { closeModal }))(CoBrokeringUploadDocs);
