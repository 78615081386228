import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import createTableItems from 'helpers/createTableItems';

import { openModal } from 'actions/ui';
import { fetchNext } from 'actions/search/factoringdebtor';

import Header from 'components/pure/Header';
import FactoringDebtorSearchForm from 'components/pure/form/FactoringDebtorSearchForm';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const FactoringDebtorSearchTableComponents = createTableItems(
  ({ item, add_debtor }) => [
    item.data.credit_approved,
    <Link to={`/admin/user/search/${item.data.id}`}>{item.data.email}</Link>,
    item.data.name,
    item.data.company_name,
    item.data.phone_number,
    item.data.mc,
    item.data.dot,
    item.data.tax_id,
    <div>
      <Link to={`/admin/factoring/debtor/${item.data.id}`}><button className='btn btn-orange'>More</button></Link>
      {'\u00a0'}
      <button className='btn btn-success' type='button' onClick={() => add_debtor(item.data.id)}>+</button>
    </div>,
  ],
  undefined,
  () => ({
    style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
  }),
);

const FactoringDebtorSearchTable = compose(
  connect(
    state => {
      return {
        ids: state.search.factoringdebtor.ids,
        isFetching: state.search.factoringdebtor.isFetching,
        debtors: state.resource.factoringdebtor,
      };
    },
    (dispatch, { id }) => ({
      fetchNext: () => dispatch(fetchNext()),
      add_debtor(debtor) {
        dispatch(openModal('confirm', {
          type: 'factoringclientdebtor_add',
          args: [
            id,
            {
              factoring: id,
              debtor,
            },
          ],
        }));
      },
    }),
  ),
)(({ ids, debtors, add_debtor, ...rest }) =>
  <ResponsiveTable
    {...rest}
    containerProps={{
      className: 'col-fill',
    }}
    items={ids.map(id => ({
      item: debtors[id],
      add_debtor,
    }))}
    headers={['Status', 'Email', 'DBA', 'Contact', 'Phone#', 'MC#', 'DOT#', 'Tax ID#', 'More']}
    placeholder='No results'
    TableItem={FactoringDebtorSearchTableComponents.TableItem}
    BlockTableItem={FactoringDebtorSearchTableComponents.BlockTableItem}
  />
);

const FactoringDebtorSearch = compose(
)(({ params: { id } }) =>
  <div
    className='clearfix'
    style={{
      display: 'flex',
      alignItems: 'stretch',
      maxWidth: '100%',
    }}
    >
    <div className='col-xs-12 col-sm-3'>
      <Header>Factoring Client Debtor Add</Header>
      <FactoringDebtorSearchForm
        initialValues={{
          exclude_client_relation: id,
        }}
      />
    </div>
    <div className='col-xs-12 col-sm-9'>
      <FactoringDebtorSearchTable id={id} />
    </div>
  </div>
);

export default FactoringDebtorSearch;
