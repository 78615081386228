import React, { Component } from 'react';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { get, head, isEmpty, has, remove, concat } from 'lodash';
import DropdownList from 'react-widgets/lib/DropdownList';
import moment from 'moment';

import { getGroupInvited, getNextInvitedCarriers } from 'actions/groups';
import { searchFactoringCarriers } from 'actions/factoring';
import createTableItems from 'helpers/createTableItems';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import Header from 'components/pure/Header';
import groups from 'actions/admin/factoring/groups';
import { getPaymentProfile } from 'actions/groups';
import { openModal } from 'actions/ui';
import { inviteToGroup } from 'actions/invites';
import selectBrokerGroups from 'selectors/selectBrokerGroups';
import MaterialIcon from '../pure/MaterialIcon';
import PhoneInput from '../pure/form/inputs/PhoneInput';
import LabeledInput from '../pure/form/inputs/LabeledInput';
import { deleteInvitedUser } from '../../actions/admin/factoringGroupUsers';
import { sortPaymentProfilesByStatus } from './FactoringAddClients';
import getBrokerTerms from '../../selectors/getBrokerTerms';
import { fetchTerms } from 'actions/factoring';
import { formattedPaymentTerms } from '../pure/Modals/CreatePaymentProfile';

const options = [
  {
    text: 'Email',
    value: 'email_id',
  },
  {
    text: 'Phone Number',
    value: 'phone',
  },
];

const FactoringCarriersItems = createTableItems(
  ({ data, sendInvites, openResendInviteModal, confirmDelete, openMatchedInvitedCarrierModal, factoringId, getPaymentProfile, terms }) => {
    const email = get(data, 'carrier_owner_profile.email', undefined);
    const phoneNumber = get(data, 'carrier_owner_profile.phone_number', undefined);
    const lastInvited = get(data, 'carrier_owner_profile.last_invited', undefined);
    const payment_profile_ids = get(data, 'carrier_owner_profile.payment_profile_ids', []);
    const createConnection = async email => {
      try {
        const { results = [] } = await getPaymentProfile({
          limit: 20,
          owner_email: email
        });
        if (results.length) {
          openMatchedInvitedCarrierModal({
            invitedCarrier: sortPaymentProfilesByStatus(results),
            factoringId: factoringId,
            invitedUserId: get(data, 'carrier_owner_profile.id', ''),
            terms,
          });
        }
      } catch (e) {
        console.log("ERROR", e);
      }
    }
    return [
      <b><span className='text-orange'>Invited</span></b>,
      phoneNumber,
      email,
      moment(lastInvited).format('l LTS'),
      <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
        {
          !isEmpty(payment_profile_ids) ?
            <button
              className='btn btn-green btn-sm'
              onClick={() => createConnection(email)}
            >
              Create Connection
              <MaterialIcon name='link' size={20} />
            </button>
          :
            <button
              className='btn btn-green btn-sm'
              onClick={() => openResendInviteModal('CreatePaymentProfile', { email, phoneNumber, invitedUserId: data.id })}
              >
              Create Payment Profile
              <MaterialIcon name='add' size={20} />
            </button>
         }
        <div style={{ width: 10 }} />
        <button
          className='btn btn-danger'
          onClick={() => confirmDelete(data.id)}
          >
          <MaterialIcon name='clear' size={20} />
        </button>
        <div style={{ width: 10 }} />
        <button
          className='btn btn-orange btn-sm'
          // onClick={() => sendInvites(email ? 'email' : 'phone', [email || phoneNumber])}
          onClick={() => openResendInviteModal('ResendInvite', { resendSendInvites: () => sendInvites(email ? 'email' : 'phone', [email || phoneNumber]), origin: email ? 'Email' : 'Phone', sendInvites })}
          >
          Resend
          <MaterialIcon name='refresh' size={20} />
        </button>
      </div>,
    ];
  });

const mapStateToProps = state => ({
  groups: state.resource.factoringGroups,
  brokerGroups: selectBrokerGroups(state),
  loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
  factoringId: get(state.resource, ['user', state.user.id, 'data', 'factoring_id']),
  terms: formattedPaymentTerms(getBrokerTerms(state)),
});

const mapDispatchToProps = dispatch => ({
  getNextInvitedCarriers: url => dispatch(getNextInvitedCarriers(url)),
  getGroupInvited: (id, filters) => dispatch(getGroupInvited(id, filters)),
  getCarriers: filters => dispatch(searchFactoringCarriers(filters)),
  getGroups: () => dispatch(groups.fetch()),
  inviteToGroup: async (...args) => {
    try {
      await dispatch(inviteToGroup(...args));
      dispatch(openModal('success', { message: 'Invite(s) successfully sent.' }));
    }
    catch (err) {
      if (has(err, 'already_exists')) {
        return dispatch(openModal('error', { message: `The carrier ${!isEmpty(err.already_exists) ? err.already_exists[0] : err.invites_rate_limited[0]} has already been invited , would you like to resend the HaulPay invitation link?` }));
      }
      return dispatch(openModal('error', { message: err.message || 'Error sending invite(s).' }));
    }
  },
  confirmDelete(id) {
    return new Promise((resolve, reject) => {
      const onConfirm = async () => {
        try {
          const res = await dispatch(deleteInvitedUser(id));
          resolve(res);
        }
        catch (e) {
          reject(e);
        }
      };
      dispatch(openModal('confirm', {
        type: 'inviteduser_delete',
        args: [{
          onConfirm,
        }],
      }));
    });
  },
  getPaymentProfile: (...args) => dispatch(getPaymentProfile(...args)),
  openMatchedInvitedCarrierModal: args => dispatch(openModal('MatchedInvitedCarrier', args)),
  openResendInviteModal: (...args) => dispatch(openModal(...args)),
  fetchTerms: (...args) => dispatch(fetchTerms(...args)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  class FactoringCarriers extends Component {
    constructor(props) {
      super(props);

      this.state = {
        searchField: '',
        searchType: 'email_id',
        count: 0,
        searching: false,
        invited: [],
        groupId: '',
        filters: {},
        next: '',
        previous: '',
      };

      this.onTextChange = this.onTextChange.bind(this);
      this.onDropdownChange = this.onDropdownChange.bind(this);
      this.onSearch = this.onSearch.bind(this);
      this.fetchPage = this.fetchPage.bind(this);
      this.sendInvites = this.sendInvites.bind(this);
    }

    async componentDidMount() {
      await this.props.getCarriers();
      await this.props.getGroups();
      await this.props.fetchTerms(this.props.factoringId);

      const group = head(this.props.brokerGroups);
      const groupId = get(group, 'data.id');
      this.setState({
        isFetching: true,
      });
      if (groupId) {
        const { count = 0, results, next, previous } = await this.props.getGroupInvited(groupId, { deleted: true, limit: 20 });
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          count: count,
          invited: results,
          groupId,
          isFetching: false,
          next,
          previous,
        });
      }
    }

    async sendInvites(type, array = []) {
      const group = head(this.props.brokerGroups);
      const id = get(group, 'data.id');
      this.props.inviteToGroup({ groupId: id, ...(() => type === 'email' ? { emails: array, phoneNumbers: [] } : { phoneNumbers: array, emails: [] })() });
    }

    onDropdownChange(event) {
      this.setState({
        searchType: event.value,
      });
    }

    onTextChange(event) {
      this.setState({
        searchField: event.target.value,
      });
    }

    async onSearch() {
      
      const query = { limit: 20 };
      const { searchField, searchType, searching, groupId } = this.state;
      if (!groupId) return;
      this.setState({
        isFetching: true,
      });
      query[searchType] = searchField;

      if (searchField === '') {
        this.setState({
          searching: false,
        });
      }

      const { count = 0, results, next, previous } = await this.props.getGroupInvited(groupId, query);

      this.setState({
        count: count,
        invited: results,
        isFetching: false,
        next, 
        previous,
      });
    }

    async fetchPage () {
      const { next: url, isFetching, invited } = this.state;
      const { getNextInvitedCarriers } = this.props;
      if (!url || isFetching) {
        return;
      }
      this.setState({
        isFetching: true,
      });
      try {
        const { count, results, next, previous } = await getNextInvitedCarriers(url);
        this.setState({
          count: count,
          invited: concat(invited, results),
          isFetching: false,
          next,
          previous,
        });
      }
      catch (e) {
        console.log("ERROR", e)
      }
    }

    render() {
      const { isFetching, count, invited, searchType, next } = this.state;
      return (
        <div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              marginTop: '12px',
              marginBottom: '12px',
            }}
            >
            <Header>Invited Carriers ({count})</Header>
            <div
              style={{
                display: 'inline-flex',
                marginTop: '10px',
                marginBottom: '5px',
              }}
              >
              {
                searchType === 'email_id' ?
                  <LabeledInput
                    className='form-control'
                    type='text'
                    placeholder='Search for'
                    value={this.state.searchField}
                    onChange={this.onTextChange}
                  />
                  :
                  <PhoneInput
                    className='form-control'
                    placeholder='Search for'
                    value={this.state.searchField}
                    onChange={this.onTextChange}
                  />
              }
              <div>
                <DropdownList
                  style={{
                    minWidth: '11em',
                    marginLeft: '12px',
                  }}
                  data={options}
                  defaultValue='email_id'
                  valueField='value'
                  textField='text'
                  onChange={this.onDropdownChange}
                />
              </div>
              <div>
                <button
                  style={{
                    marginLeft: '12px',
                  }}
                  className='btn btn-orange'
                  onClick={this.onSearch}
                  >
                  Search
                </button>
              </div>
              <Link to='/haul-pay/addclients'>
                <button
                  style={{
                    marginLeft: '12px',
                  }}
                  className='btn btn-green'
                  >
                  + Add New
                </button>
              </Link>
            </div>
          </div>
          <ResponsiveTable
            containerProps={{
              className: 'col-fill',
            }}
            placeholder='No results.'
            headers={['Status', 'Phone number', 'Email', 'Last Invited', '']}
            items={invited.map(invite => {
              return {
                data: {
                  id: invite.id,
                  status: 'invited',
                  carrier_owner_profile: {
                    id: invite.id,
                    email: invite.email,
                    phone_number: invite.phone_number,
                    last_invited: invite.last_invited,
                    payment_profile_ids: invite.payment_profile_ids,
                  },
                },
                confirmDelete: async id => {
                  try {
                    await this.props.confirmDelete(id);
                    remove(invited, object => object.id === id);
                  }
                  catch (e) {
                    this.props.openResendInviteModal('error', { message: 'Error While deleting carrier.' });
                  }
                },
                sendInvites: this.sendInvites,
                openResendInviteModal: this.props.openResendInviteModal,
                openMatchedInvitedCarrierModal: this.props.openMatchedInvitedCarrierModal,
                getPaymentProfile: this.props.getPaymentProfile,
                factoringId: this.props.factoringId,
                terms: this.props.terms,
              };
            })}
            isFetching={isFetching}
            {...FactoringCarriersItems}
          />
          {
            next ?
              <div>
                <button type='button' className='btn btn-orange' onClick={this.fetchPage} disabled={isFetching}>Load More</button>
              </div>
              : null
          }
        </div>
      );
    }
  });
