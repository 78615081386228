import React from 'react';
import NavItem from 'react-bootstrap/lib/NavItem';

import CenteredColumn from 'components/pure/CenteredColumn';
import FactoringClientNotes from 'components/container/FactoringClientNotes';
import StatefulTabbed from 'components/stateful/StatefulTabbed';
import FactoringClientEditForm from './FactoringClientEditForm';
import FactoringClientAuditLog from './FactoringClientAuditLog';
import FactoringClientStats from './FactoringClientStats';
import FactoringClientGroupEdit from './FactoringClientGroupEdit';
import Receivables from './Receivables';
import { compose, withHandlers, withState } from 'recompose';
import sleep from 'helpers/sleep';

export default compose(
  withState('tabIndex', 'setTabIndex', 0),
  withHandlers({
    scrollReceivablesIntoView: props => async event => {
      props.setTabIndex(4);
      await sleep(50);
      const el = document.querySelector('#receivables_table');
      el.scrollIntoView();
    },
  }),
)(({ params: { id }, scrollReceivablesIntoView, tabIndex }) => {
  return (
    <CenteredColumn xs={12} md={9}>
      <FactoringClientEditForm id={id} scrollReceivablesIntoView={scrollReceivablesIntoView} />
      <div
        style={{
          margin: '2em 0',
          minHeight: '500px',
          paddingBottom: '200px',
        }}
        >
        <StatefulTabbed
          activeKey={tabIndex}
          navItems={[
            <NavItem>Internal Client Notes</NavItem>,
            <NavItem>Audit Trail</NavItem>,
            <NavItem>Client Stats</NavItem>,
            <NavItem>Groups</NavItem>,
            <NavItem>Give/Charge backs</NavItem>,
          ]}
          items={[
            <FactoringClientNotes id={id} />,
            <FactoringClientAuditLog id={id} />,
            <FactoringClientStats id={id} />,
            <FactoringClientGroupEdit id={id} />,
            <Receivables id={id} />,
          ]}
        />
      </div>
    </CenteredColumn>
  );
});
