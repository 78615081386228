import { compose } from 'redux-v3';
import lifecycle from 'recompose/lifecycle';

import wrapDisplayName from 'components/hoc/wrapDisplayName';


export default matches => compose(
  wrapDisplayName('scrollToAnchor'),
  lifecycle({
    componentDidMount() {
      const hash = window.decodeURIComponent(window.location.hash);
      if (hash.length > 2 && (typeof matches !== 'function' || matches(this.props, hash))) {
        const el = document.querySelector(hash);
        if (el) {
          el.scrollIntoView();
        }
      }
    },
  }),
);
