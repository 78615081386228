/**
 * Reducer for handling the activity resource actions
 * @module reducers/resource/activity
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/activity
 * @listens ACTIVITY_SET_VIEWED
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.activity;

import { activityResource, activityPaginatedResource, ACTIVITY_SET_VIEWED } from '../../actions/resource/activity';
import moment from 'moment';


export default function activityResourceReducer(state = initialState, action) {
  switch (action.type) {
    case activityPaginatedResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...activityResource.parseMany(state, action.payload.results),
      };
    case ACTIVITY_SET_VIEWED:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          data: {
            ...state[action.payload].data,
            viewed_on: moment(),
          },
        },
      };
  }
  return state;
}
