import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import mapProps from 'recompose/mapProps';
import renderComponent from 'recompose/renderComponent';
import nest from 'recompose/nest';
import setDisplayName from 'recompose/setDisplayName';

import Spinner from 'components/pure/Spinner';
import Error from 'components/pure/Error';


export default compose(
  setDisplayName('ResourceDetails'),
  branch(
    ({ item }) => item === undefined || item.isFetching,
    compose(
      renderComponent,
      mapProps(() => ({})),
    )(nest('div', Spinner)),
    branch(
      ({ item }) => item.err !== null,
      compose(
        mapProps(({ item }) => ({ ...item.err })),
        renderComponent(Error),
      ),
      Component => Component,
    ),
  ),
);
