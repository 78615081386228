import React from 'react';
import { Field } from 'redux-form';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle } from '@fortawesome/free-solid-svg-icons';
import colors from 'styles/colors.json';

const LineItemsType = props => {
    const data = [{
        text: 'Freight Charges',
        value: 'Freight Charges',
    }, {
        text: 'Line Haul',
        value: 'Line Haul',
    },
    {
        text: 'Fuel Surcharge',
        value: 'Fuel Surcharge',
    }, {
        text: 'Lumper Fee',
        value: 'Lumper Fee (Paid by Client)',
    }, {
        text: 'Gate Charge',
        value: 'Gate Charge',
    }, {
        text: 'Detention',
        value: 'Detention Charge',
    }, {
        text: 'Layover',
        value: 'Layover',
    }, {
        text: 'Storage',
        value: 'Storage',
    }, {
        text: 'TONU',
        value: 'TONU',
    }, {
        text: 'Other',
        value: 'Other',
    }];

    return (
        <Field
            component={LabeledDropdown}
            data={data}
            {...props}
        />
    );
};

const LineItemsForm = ({ fields, meta }) => {
    const fieldValues = fields.getAll();
    return (
        <div style={{ padding: 10 }}>
            {fields.map((line_item, index) => (
                <div className="row">
                    <div className="col-xs-8 col-sm-8" style={{ display: fieldValues[index].category === 'Other' ? "flex" : "block", gap: 10}}>
                        <Field
                            name={`${line_item}.amount`}
                            component={PennyInput}
                            label="Amount"
                        />
                        {fieldValues[index].category === 'Other' && 
                            <Field
                                name={`${line_item}.description`}
                                component={LabeledInput}
                                label="Description"
                            />
                        }
                    </div>
                    <div className="col-xs-4 col-sm-4">
                        <div className="col-xs-10 col-sm-10">
                            <LineItemsType
                                name={`${line_item}.category`}
                                label="Type"
                            />
                        </div>
                        <div 
                            className="col-xs-2 col-sm-2" 
                            style={{
                                position: 'absolute',
                                right: '0',
                                width: '300',
                                padding: '5',
                                bottom: '0',
                            }}>
                            <p onClick={() => fields.remove(index)}><FontAwesomeIcon icon={faMinusCircle} size="1x" color={colors.RED} /></p>
                        </div>
                    </div>
                </div>
            ))}
            <div className="row" style={{ padding: 10 }}>
                <p onClick={() => fields.push({ amount: 0, category: 'Line Haul'})}><FontAwesomeIcon icon={faPlusCircle} size="1x" color={colors.BLUE_BTN} /> Add another charge</p>
            </div>
        </div>
    )
}

export default LineItemsForm;