/**
 * Entry point for the whole app
 * @module index
 * @since 3.0.0
 * @requires validate
 * @requires routes
 * @requires store
 */
/* global fbq process ENABLE_TAILWIND*/
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux-v5';
import { Router, browserHistory } from 'react-router-v4';
import { syncHistoryWithStore } from 'react-router-redux';
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider';
import { GlobalStyles } from '@haulpay/ui';

require('react-widgets/lib/localizers/moment')(require('moment'));
require('./js/validate');

import routes from './routes';
import store from './js/store';
import { isDebug } from './js/helpers/segmentAnalytics';

import theme from './js/theme';

/* set debugging in segment analytis */
isDebug(process.env.NODE_ENV === 'development' ? false : null);

const history = syncHistoryWithStore(browserHistory, store);
let logPath;

if (process.env.NODE_ENV === 'production') {
  fbq('init', '880947675367039');
  logPath = () => {
    fbq('track', 'PageView');
  };
}


render(
  <Provider store={store}>
    <MuiThemeProvider muiTheme={theme}>
      {/*{JSON.parse(ENABLE_TAILWIND || false) && <GlobalStyles />}*/}
      <Router history={history} onUpdate={logPath}>
        {routes}
      </Router>
    </MuiThemeProvider>
  </Provider>,
  document.getElementById('app'),
);
