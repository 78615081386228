import React from 'react';
import { connect } from 'react-redux-v5';
import { reduce, get } from 'lodash';
import createTableItems from 'helpers/createTableItems';
import { compose } from 'redux-v3';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import formatPennies from 'helpers/formatPennies';
import DropdownList from 'react-widgets/lib/DropdownList';
import { getReserve } from 'actions/reserve';
import { lifecycle, withHandlers, withState } from 'recompose';
import ColoredMoney from 'components/pure/ColoredMoney';
import MaterialIcon from 'components/pure/MaterialIcon';
import { Field, reduxForm, submit } from 'redux-form';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import { createBulk } from 'actions/admin/factoring/funds';
import { openModal } from 'actions/ui';

const ReservePaymentsTableComponents = status => createTableItems(
  item => {
    if (status === 'active') {
      return [
        <Field
          name={item.id}
          component={CheckboxInput}
        />,
        <div>{item.category_name}</div>,
        <ColoredMoney value={item.amount}>
          {formatPennies(item.amount)}
        </ColoredMoney>,
        <div>{item.user_load_number}</div>,
        <div>{item.invoice_number}</div>,
        <ColoredMoney value={item.balance_amount}>
          {formatPennies(item.balance_amount)}
        </ColoredMoney>,
        <div>{item.age}</div>,
      ];
    }
    return [
      <div>{item.category_name}</div>,
      <ColoredMoney value={item.amount}>
        {formatPennies(item.amount)}
      </ColoredMoney>,
      <div>{item.user_load_number}</div>,
      <div>{item.invoice_number}</div>,
      <ColoredMoney value={item.balance_amount}>
        {formatPennies(item.balance_amount)}
      </ColoredMoney>,
      <div>{item.age}</div>,
    ];
  },
);

const ReservePaymentsTable = ({ reserveItems, headers = [], status = '' }) => (
  <ResponsiveTable
    id={'receivables_table'}
    containerProps={{
      className: 'col-fill',
    }}
    headers={headers}
    limit={20}
    withPagination={true}
    placeholder='No results'
    TableItem={ReservePaymentsTableComponents(status).TableItem}
    BlockTableItem={ReservePaymentsTableComponents(status).BlockTableItem}
    items={reserveItems}
  />
);

class Receivables extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdown: '',
    };
  }

  render() {
    const { reserveItems, getReserveItems, triggerFunds, submitting } = this.props;
    const { dropdown } = this.state;
    const headers = dropdown === 'active' ? ['Select', 'Line Item', 'Amount', 'Load #', 'Invoice #', 'Balance Amount', 'Age']
      : ['Line Item', 'Amount', 'Load #', 'Invoice #', 'Balance Amount', 'Age'];
    return (
      <div>
        <div
          style={{
            display: 'flex',
            padding: '16px 16px 16px 0',
            float: 'right',
          }}
          >
          <div
            style={{
              display: 'flex',
            }}
            >
            <div>
              <DropdownList
                style={{
                  minWidth: '11em',
                  marginLeft: '12px',
                }}
                data={[
                  { text: 'Active', value: 'active' },
                  { text: 'Closed', value: 'closed' },
                  { text: 'Pending', value: 'pending' },
                  { text: 'All', value: '' },
                ]}
                defaultValue='all'
                valueField='value'
                textField='text'
                onChange={event => {
                  this.setState({
                    dropdown: event.value,
                  });
                  getReserveItems(event.value);
                }}
                value={this.state.dropdown}
              />
            </div>
            <div>
              { this.state.dropdown === 'active' &&
              <div style={{ display: 'inline-block' }}>
                <button
                  style={{ marginLeft: '8px', backgroundColor: '#2e7d32', height: '35px', color: 'white' }}
                  type='button'
                  onClick={() => {
                    triggerFunds();
                  }}
                  disabled={submitting}
                  >
                  <MaterialIcon name='money_off' />
                  Release funds
                </button>
              </div>
              }
            </div>
          </div>
        </div>
        <ReservePaymentsTable status={dropdown} headers={headers} reserveItems={reserveItems} />
      </div>
    );
  }
}

const mapStatetoProp = (state, ownProps) => ({
  userId: get(state, `resource.factoringclient.${ownProps.id}.data.user`, ''),
});

const mapDispatchToProps = dispatch => ({
  getReserve: (id, status) => dispatch(getReserve(id, status)),
  triggerFunds: () => dispatch(submit('Receivables')),
});

export default compose(
  connect(mapStatetoProp, mapDispatchToProps),
  withState('reserveItems', 'setReserveItems', []),
  withHandlers({
    getReserveItems: ({ userId, getReserve, setReserveItems }) => async status => {
      const reserve = await getReserve(userId, status);
      setReserveItems(reserve);
    },
  }),
  lifecycle({
    async componentDidMount() {
      await this.props.getReserveItems();
    },
  }),
  reduxForm({
    form: 'Receivables',
    destroyOnUnmount: false,
    onSubmit: async (values, dispatch, { userId, getReserveItems, ...props }) => {
      const ids = reduce(values, (result, value, key) => {
        if (value === true) {
          result.push(key);
        }
        return result;
      }, []);
      try {
        await dispatch(createBulk(ids, userId));
        getReserveItems('active');
        dispatch(openModal('success', { message: 'Successfully funds released.' }));
      }
      catch (e) {
        const error = JSON.parse(e.message);
        const [name, array] = Object.entries(error)[0];
        dispatch(openModal('error', { message: array.toString() }));
      }
    },
  })
)(Receivables);

