/* global EMPTY_PLACEHOLDER */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { reduxForm } from 'redux-form';
import RouteTabs from 'components/pure/RouteTabs';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';

import factoringclientPaginatedResource from 'actions/admin/factoring/client';
import mapProps from 'recompose/mapProps';
import createTableItems from 'helpers/createTableItems';

import Header from 'components/pure/Header';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import MaterialIcon from 'components/pure/MaterialIcon';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';

const FactoringClientComponents = createTableItems(
  ({ data }) => {
    const difference = (data.this_period_fr - data.last_period_fr);
    let color = 'text-warning';
    let materialClass = 'menu';
    if (difference > 0){
      color = 'text-success';
      materialClass = 'arrow_upward';
    }
    else if (difference < 0) {
      color = 'text-danger';
      materialClass = 'arrow_downward';
    }
    return [
      data.company_profile.name,
      data.user.assigned_admin_name,
      data.owner_profile.email,
      data.user.user_type,
      <div className={`${color}`}><strong>{data.this_period_fr} ({difference}) <MaterialIcon name={materialClass} className={color} /></strong></div>,
      moment(data.time_posted).format('l LTS'),
      data.last_submitted_funding_request ? moment(data.last_submitted_funding_request).format('l LTS') : EMPTY_PLACEHOLDER,
      data.user.last_login ? moment(data.user.last_login).format('l LTS') : EMPTY_PLACEHOLDER,
      <Link to={`/admin/factoring/client/${data.id}`}><button className='btn btn-orange'>More</button></Link>,
    ];
  },
);

export const FactoringClientTable = compose(
  connect(
    state => ({
      ids: state.admin.factoring.client.ids,
      isFetching: state.admin.factoring.client.isFetching,
      apps: state.resource.factoringclient,
      currentOrder: state.admin.factoring.client.ordering,
    }),
    {
      fetchNext: factoringclientPaginatedResource.fetchNext,
      sort: factoringclientPaginatedResource.sort,
    }
  ),
)(({ ids, isFetching, fetchNext, sort, apps, currentOrder }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    currentOrder={currentOrder}
    sort={sort}
    headers={[
      { text: 'DBA', oredering: 'company_profile.name' },
      { text: 'Assigned Admin', ordering: 'assigned_admin_name' },
      { text: 'Contact Email', ordering: 'email' },
      { text: 'User Type', ordering: 'user_type' },
      { text: 'Stats', ordering: 'stats' },
      { text: 'Created At', ordering: false },
      { text: 'Last Invoice', ordering: false },
      { text: 'Last Login', ordering: false },
      { text: 'More', ordering: false },
    ]}
    placeholder='No results'
    TableItem={FactoringClientComponents.TableItem}
    BlockTableItem={FactoringClientComponents.BlockTableItem}
    items={ids.map(id => apps[id])}
  />
);

class FactoringClientStats extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      currentSelectedPeriod: 'week',
    };
    this.selectedPeriod = this.selectedPeriod.bind(this);
  }
  componentDidMount() {
    const { currentSelectedPeriod } = this.state;
    this.props.update({ period: currentSelectedPeriod });
  }
  componentDidUpdate() {
    const { currentSelectedPeriod } = this.state;
    this.props.update({ period: currentSelectedPeriod });
  }
  selectedPeriod(period) {
    this.setState({ currentSelectedPeriod: period });
  }
  render() {
    const { currentSelectedPeriod } = this.state;
    return (
      <div className='col-fill' style={{ padding: '0 1em' }}>
        <Header>Inactive Haul Pay Clients</Header>
        {
          location.pathname === '/admin/factoring/client/inactive' ?
            <RouteTabs
              routes={[
                ['../inactive/full', 'Truckload'],
                ['../inactive/small', 'Small Equipment'],
              ]}
              extras={
                <LabeledDropdown
                  containerProps={{
                    className: 'pull-right',
                    style: {
                      marginBottom: 0,
                    },
                  }}
                  label={null}
                  data={[
                    { text: 'Last 7 days vs Previous 7 Days', value: 'week' },
                    { text: 'Last 30 days vs Previous 30 Days', value: 'month' },
                  ]}
                  horizontal={true}
                  input={{
                    value: currentSelectedPeriod,
                    onChange: this.selectedPeriod,
                  }}
                />
              }
            /> :
            <RouteTabs
              routes={[
                ['../full', 'Truckload'],
                ['../small', 'Small Equipment'],
              ]}
              extras={
                <LabeledDropdown
                  containerProps={{
                    className: 'pull-right',
                    style: {
                      marginBottom: 0,
                    },
                  }}
                  label={null}
                  data={[
                    { text: 'Last 7 days vs Previous 7 Days', value: 'week' },
                    { text: 'Last 30 days vs Previous 30 Days', value: 'month' },
                  ]}
                  horizontal={true}
                  input={{
                    value: currentSelectedPeriod,
                    onChange: this.selectedPeriod,
                  }}
                />
              }
            />
        }
        <FactoringClientTable />
      </div>
    );
  }
}

const FactoringClientLists = compose(
  connect(
    null,
    {
      clear: factoringclientPaginatedResource.clear,
      fetch: factoringclientPaginatedResource.fetch,
    },
    (stateProps, dispatchProps, ownProps) => ({
      ...stateProps,
      ...dispatchProps,
      ...ownProps,
    }),
  ),
  mapProps(props => ({
    ...props,
    update({ period }) {
      props.clear();
      let equipment_type = location.pathname.slice(location.pathname.lastIndexOf('/') + 1);
      if (equipment_type === 'inactive'){
        equipment_type = 'full';
      }
      return props.fetch({
        inactive: true,
        equipment_type,
        period,
      });
    },
  })),
  reduxForm({
    form: 'FactoringClientsInactiveFilter',
    destroyOnUnmount: false,
    initialValues: {
      equipment_type: 'full',
    },
  }),
)(FactoringClientStats);

export default FactoringClientLists;
