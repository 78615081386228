import React from 'react';
import { Field, reduxForm } from 'redux-form';
import LabeledInput from './inputs/LabeledInput';
import LoadAttachmentButton from '../LoadAttachmentButton';

const PaymentProfileForm = ({ hasFactoringProfile, hasMC, hasDOT, urlNOA }) =>
  <div className='light-placeholder'>
    <Field
      name='carrier_name'
      component={LabeledInput}
      label={<p>Carrier Name</p>}
      placeholder=''
      disabled={true}
    />
    {
      hasMC &&
      <Field
        name='company_mc'
        component={LabeledInput}
        label={<p>MC</p>}
        placeholder=''
        disabled={true}
      />
    }
    {
      hasDOT &&
      <Field
        name='company_dot'
        component={LabeledInput}
        label={<p>DOT</p>}
        placeholder=''
        disabled={true}
      />
    }
    <Field
      name='company_name'
      component={LabeledInput}
      label={<p>Carrier Name</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_phone_number_cell'
      component={LabeledInput}
      label={<p>Carrier Phone</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_email'
      component={LabeledInput}
      label={<p>Carrier Email</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_address_street_one'
      component={LabeledInput}
      label={<p>Address</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_address_city'
      component={LabeledInput}
      label={<p>City</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_address_zip'
      component={LabeledInput}
      label={<p>Zip Code</p>}
      placeholder=''
      disabled={true}
    />
    <Field
      name='owner_address_state'
      component={LabeledInput}
      label={<p>State</p>}
      placeholder=''
      disabled={true}
    />
    {
      hasFactoringProfile &&
        <div>
          <Field
            name='factoring_company_name'
            component={LabeledInput}
            label={<p>Factoring Company</p>}
            placeholder=''
            disabled={true}
          />
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <span className='control-label' style={{ marginRight: '0.5em', fontWeight: 900 }}>
            Carrier's NOA
            </span>
            <LoadAttachmentButton getURL={() => urlNOA} />
          </div>
        </div>
    }
  </div>;

const FORM_NAME = 'payment_profile';
export default reduxForm({
  form: FORM_NAME,
  destroyOnUnmount: false,
  enableReinitialize: true,
})(PaymentProfileForm);
