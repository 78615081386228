import { compose } from 'redux-v3';
import lifecycle from 'recompose/lifecycle';
import setDisplayName from 'recompose/setDisplayName';
import escape from 'lodash/escape';

export const docTitle = title => compose(
  setDisplayName('docTitle'),
  lifecycle({
    componentDidMount() {
      if (typeof title === 'function') {
        title = title(this.props);
      }
      if (document) {
        document.title = `HaulPay | ${escape(title)}`;
      }
    },
  }),
);

export default docTitle;
