import React from 'react';

import RouteTabs from 'components/pure/RouteTabs';

const FactoringCarriers = ({ children }) => {
  const base_path = '/haul-pay/carriers';
  return (
    <div className='col-fill'>
      <RouteTabs
        style={{ flexGrow: 0 }}
        routes={[
          [`${base_path}/invited`, 'Invited Carriers'],
          [`${base_path}/`, 'My Carriers'],
        ]}
      />
      {children}
    </div>
  );
};

export default FactoringCarriers;
