import React from 'react';
import { compose } from 'redux-v3';
import { Field, change, formValueSelector, reduxForm } from 'redux-form';
import { lifecycle, mapProps, withState } from 'recompose';
import { edit } from 'actions/resource/factoringlineitemcreate';
import { closeModal, openModal } from 'actions/ui';
import CenteredColumn from 'components/pure/CenteredColumn';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import { getLineItemCategories } from 'actions/lineitemcategories';
import { connect } from 'react-redux-v5';

const FORM = 'FactoringLineItemEdit';
const selector = formValueSelector(FORM);

const shouldCheckBoxInput = (approval_status, category) => {
  const status_check = [
    'approved',
  ].includes(approval_status);
  return status_check;
};

export default compose(
  connect(
    state => ({
      approval_status: selector(state, 'approval_status'),
      category: selector(state, 'category'),
      appliedAgainst: selector(state, 'applied_against'),
    }),
    dispatch => ({
      getLineItemCategories: () => dispatch(getLineItemCategories()),
      setAppliedAgainst: against => dispatch(change(FORM, 'applied_against', against)),
      clearSelectedCategory: () => dispatch(change(FORM, 'category', null)),
    })
  ),
  mapProps(props => {
    if (!props.item || !props.item.data) {
      return props;
    }
    return {
      ...props,
      initialValues: {
      },
    };
  }),
  reduxForm({
    form: 'FactoringLineItemEdit',
    async onSubmit(fields, dispatch, props) {
      try {
        const data = {
          amount_requested: fields.amount_requested,
          amount_approved: fields.amount_approved,
          discount_rate: fields.discount_rate,
          category: fields.category,
          description: fields.description,
          approval_status: fields.approval_status,
          funding_request_id: fields.funding_request_id,
          bill_with_funding_request: fields.bill_with_funding_request,
          id: fields.id,
        };
        // replace the 'id' with the real Funding Request ID
        await dispatch(edit(data, props.LineItem.funding_request_id, props.LineItem.id));
        dispatch(closeModal());
        dispatch(openModal('success', { message: 'Line item updated!' }));
        props.refresh();
      }
      catch (err) {
        dispatch(closeModal());
        dispatch(openModal('error', { message: err.message || 'Line item update failed' }));
      }
    },
  }),
  withState('categories', 'setCategories'),
  lifecycle({
    async componentDidMount() {
      const categories = await this.props.getLineItemCategories();
      this.props.setCategories(categories.map(category => ({ value: category.id, text: category.name, applied_against: category.applied_against })));
      if (this.props.category) {
        const category = categories.filter(category => category.id === this.props.category);
        this.props.setAppliedAgainst(category[0].applied_against);
      }
    },
  })
)(({ LineItem, dispatch, handleSubmit, submitting, categories, clearSelectedCategory, appliedAgainst, approval_status }) =>
  <CenteredColumn
    xs={12}
    md={9}
    >
    <form onSubmit={handleSubmit} className='form-horizontal'>
      <div className='modal-body'>
        <Field name='amount_requested' component={PennyInput} label='Amount' horizontal={true} />
        <Field name='amount_approved' component={PennyInput} label='Amount Calculated' horizontal={true} disabled={true} />
        <Field name='discount_rate' component={LabeledInput} label='Discount Rate' horizontal={true} placeholder='<<Inherit from the Funding Request>>' />
        <Field
          name='applied_against'
          placeholder='Select'
          component={LabeledDropdown}
          data={[
            { text: 'Client', value: 'client' },
            { text: 'Debtor', value: 'debtor' },
            { text: 'ComFreight', value: 'organization' },
            { text: 'Proof', value: 'proof' },
          ]}
          label='Against Who?'
          horizontal={true}
          onChange={() => {
            clearSelectedCategory();
          }}
        />
        <Field
          name='category' component={LabeledDropdown}
          data={(categories || []).filter(category => category.applied_against === appliedAgainst)}
          label='Category'
          horizontal={true}
        />
        <Field name='description' component={LabeledInput} label='Description' horizontal={true} />
        <Field
          name='approval_status' component={LabeledDropdown}
          data={[
            { text: 'Approved', value: 'approved' },
            { text: 'Pending', value: 'pending' },
            { text: 'Declined', value: 'declined' },
            { text: 'Partial', value: 'partial' },
            { text: 'Client Dispute', value: 'client_dispute' },
            { text: 'Debtor Dispute', value: 'debtor_dispute' },
          ]}
          label='Approval Status'
          horizontal={true}
        />
        {
          shouldCheckBoxInput(approval_status) ?
            <Field
              name='bill_with_funding_request'
              className='text-success'
              component={CheckboxInput}
              labelStyle={{ marginBottom: 5 }}
              label='Bill With Funding Request?'
              style={{ marginTop: 5, marginBottom: 5, marginLeft: 30 }}
            />
            :
            null
        }
      </div>
      <div className='modal-footer'>
        <button style={{ marginLeft: '8px' }} className='btn btn-orange pull-right' disabled={submitting}>Edit Line Item</button>
        <button className='btn btn-default pull-right' onClick={() => dispatch(closeModal())}>Cancel</button>
      </div>
    </form>
  </CenteredColumn>
);
