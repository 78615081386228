/**
 * Actions relating to user search
 * @module actions/search/user
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/usersearch
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import userSearchResource from 'actions/resource/usersearch';


/**
 * Our user search [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const userSearchPaginatedResource = new PaginatedResource('search_user', {
  mountPoint: 'search.user',
  url: new UrlAssembler('admin/user/search'),
  baseResource: userSearchResource,
});

export default userSearchPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
  sort,
} = userSearchPaginatedResource;
