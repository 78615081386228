/**
 * Component to display a grid of titled items
 * @module components/pure/TitledGrid
 * @since 3.0.1
 * @property {object} props
 * @property {object} props.cols - the column count
 * @property {object} props.children - the children grid components to display
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from '../../../styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import chunk from 'lodash/chunk';
import cs from 'classnames';


const TitledGrid = ({ cols, children, justified, ...rest }) =>
  <table
    {...rest}
    className={cs('titled-grid', {
      'titled-grid-justified': justified,
    }, rest.className)}
    >
    <tbody>
      {chunk(children, cols).map((chunk, i) =>
        <tr key={i}>
          {chunk.reduce((acc, child, i) => {
            acc.push(
              <td key={acc.length}>
                {child}
              </td>
            );
            if (i !== chunk.length - 1) {
              acc.push(<td className='titled-grid-vr' key={acc.length} />);
            }
            return acc;
          }, [])}
        </tr>
      )}
    </tbody>
    <style jsx>{`
      .titled-grid {
        width: 100%;
        border-spacing: 0 0.5em;
        border-collapse: separate;
        table-layout: fixed;
      }
      .titled-grid td {
        text-align: center;
      }
      .titled-grid-justified td:first-child {
        text-align: left;
      }
      .titled-grid-justified td:last-child {
        text-align: right;
      }
      .titled-grid-vr {
        width: 1px;
        background-color: ${colors.BORDER_GRAY};
      }
    `}</style>
  </table>
  ;

const Section = ({ title, children, bottom, ...rest }) =>
  <div {...rest} className={cs('titled-grid-section', rest.className)}>
    {!bottom ? <div className='titled-grid-section-title'>{title}</div> : null}
    <div className='titled-grid-section-content'>{children}</div>
    {bottom ? <div className='titled-grid-section-title'>{title}</div> : null}
    <style jsx>{`
      .titled-grid-section {
        display: inline-block;
        text-align: center;
      }
      .titled-grid-section-title {
        text-transform: uppercase;
        font-size: 0.9em;
        font-weight: normal;
        color: ${colors.VERY_DARK_GRAY};
      }
      .titled-grid-section-content {
        font-size: 1.3em;
      }
    `}</style>
  </div>
  ;

Section.propTypes = {
  title: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  bottom: PropTypes.bool,
};

TitledGrid.Section = Section;

export default TitledGrid;
