/**
 * Actions relating to the UserSearch resource
 * @module actions/resource/usersearch
 * @since 3.0.0
 * @requires datatypes/Resource
 */
import { compose } from 'redux-v3';

import Singleton from 'datatypes/compose/Singleton';
import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import Deleteable from 'datatypes/compose/resource/Deleteable';


const usersearch = compose(
  Singleton('usersearch'),
  Editable({
    url: id => `admin/user/${id}/`,
  }),
  Deleteable({
    url: id => `admin/user/${id}/`,
  }),
  Fetchable({
    url: id => `admin/user/${id}/`,
  }),
)();

export const {
  fetch,
  fetchIfNeeded,
  edit,
  doDelete,
  selector,
} = usersearch;
export default usersearch;
