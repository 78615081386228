import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import moment from 'moment';
import lifecycle from 'recompose/lifecycle';
import shouldUpdate from 'recompose/shouldUpdate';
import shallowEqual from 'recompose/shallowEqual';
import mapProps from 'recompose/mapProps';
import getSelfType from 'selectors/getSelfType';
import { USER_TYPE } from 'helpers';
import { openModal } from 'actions/ui';
import { activityPaginatedResource, setViewed } from 'actions/resource/activity';

import placeholder from 'components/hoc/placeholder';
import infiniteScroll from 'components/hoc/infiniteScroll';
import TimelineItem from 'components/container/TimelineItem';
import DateGrouper from 'components/pure/DateGrouper';
import CenteredColumn from 'components/pure/CenteredColumn';
import YoutubeEmbed from 'components/pure/YoutubeEmbed';
import VimeoEmbed from 'components/pure/VimeoEmbed';

export default compose(
  connect(
    state => ({
      has_new_activity: state.notification,
      activities: state.activity.ids.map(id => state.resource.activity[id]),
      isFetching: state.activity.isFetching,
      next: state.activity.next,
      userType: getSelfType(state),
      embedVideo: (() => {
        switch (getSelfType(state)) {
          case USER_TYPE.SHIPPER:
            return <YoutubeEmbed video_id='rZUNYeME_NQ' style={{ maxHeight: 550 }} />;
          case USER_TYPE.BROKER:
            return <YoutubeEmbed video_id='THptit_3-Og' style={{ maxHeight: 550 }} />;
          case USER_TYPE.CARRIER:
            return <YoutubeEmbed video_id='1zDDQBvfLeE' style={{ maxHeight: 550 }} />;
        }
      })(),
    }),
    dispatch => ({
      fetch: () => dispatch(activityPaginatedResource.fetch()),
      fetchNext: () => dispatch(activityPaginatedResource.fetchNext()),
      clear: () => dispatch(activityPaginatedResource.clear()),
      doDelete: id => dispatch(openModal('confirmactivitydelete', { id })),
      setViewed: id => dispatch(setViewed(id)),
    }),
  ),
  lifecycle({
    componentDidMount() {
      if (this.props.has_new_activity || !this.props.activities.length) {
        this.props.clear();
        this.props.fetch();
      }
    },
  }),
  shouldUpdate((props, nextProps) => {
    return props.isFetching !== nextProps.isFetching || !shallowEqual(props.activities, nextProps.activities);
  }),
  placeholder(({ isFetching }) => !isFetching, {
    placeholder: 'No activities recorded (yet).',
    itemsProp: 'activities',
    componentProps: {
      style: {
        marginTop: '1em',
        marginBottom: '1em',
      },
    },
  }),
  mapProps(({ has_new_activity, activities, fetch, fetchNext, clear, doDelete, isFetching, setViewed, next, embedVideo, userType, ...rest }) => ({
    fetchNext: next ? fetchNext : undefined,
    next,
    isFetching,
    embedVideo,
    containerProps: rest,
    items: activities.map(activity => ({
      ...activity.data,
      viewed: Boolean(activity.data.viewed_on),
      onClick: () => setViewed(activity.data.id),
      onDeleteClick: e => {
        e.preventDefault();
        e.stopPropagation();
        doDelete(activity.data.id);
      },
    })),
    userType,
    dateKey: 'time_posted',
    groups: [
      {
        label: 'Today',
        test: m => m.isAfter(moment().startOf('day')),
      },
      {
        label: 'Yesterday',
        test: m => m.isAfter(moment().subtract(1, 'day').startOf('day')),
      },
    ],
    ItemComponent: TimelineItem,
  })),
  infiniteScroll({
    loadUntilFull: true,
  }),
)(({ next, embedVideo, ...rest }) =>
  <CenteredColumn xs={12} sm={10} md={8}>
    <DateGrouper {...rest} />
    {next ? null : embedVideo}
  </CenteredColumn>
);
