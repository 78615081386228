/**
 * Component that displays a column centered using inline text-center centering
 * @module components/pure/CenteredColumn
 * @since 3.0.0
 * @property {object} props
 * @property {string|number} [props.xs] - the column size to display at the xs level
 * @property {string|number} [props.sm] - the column size to display at the sm level
 * @property {string|number} [props.md] - the column size to display at the md level
 * @property {string|number} [props.lg] - the column size to display at the lg level
 * @property {node|node[]} [props.children] - the centered column's children
 * @property {object} [props.columnProps] - props to pass to the column div
 * @property {any} [props....rest] - props passed to containing div component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const CenteredColumn = ({ xs, sm, md, lg, children, columnProps = {}, ...rest }) =>
  <div
    {...rest}
    className={cs('centered-column', rest.className)}
    >
    <div
      {...columnProps}
      className={cs('centered-column-column', {
        [`col-xs-${xs}`]: Boolean(xs),
        [`col-sm-${sm}`]: Boolean(sm),
        [`col-md-${md}`]: Boolean(md),
        [`col-lg-${lg}`]: Boolean(lg),
      }, columnProps.className)}
      >
      {children}
    </div>
    <style jsx>{`
      .centered-column-column {
        text-align: left;
        float: none;
        margin-left: auto;
        margin-right: auto;
      }
    `}</style>
  </div>
  ;

CenteredColumn.propTypes = {
  xs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  md: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  lg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  columnProps: PropTypes.object,
};

export default CenteredColumn;
