/**
 * Actions for the factoring client actions
 * @module actions/admin/factoring/client
 * @since 3.0.1
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringapplication
 */
/* global API */
import UrlAssembler from 'url-assembler';

import APIFetch from 'datatypes/APIFetch';
import PaginatedResource from 'datatypes/PaginatedResource';
import factoringApplicationResource from 'actions/resource/factoringclient';


class FactoringClientAdminPaginatedResource extends PaginatedResource {
  getDefaultState() {
    return {
      ...super.getDefaultState(),
      ordering: '-created',
    };
  }
}

const factoringclientPaginatedResource = new FactoringClientAdminPaginatedResource('admin_factoring_client', {
  mountPoint: ['admin', 'factoring', 'client'],
  url: new UrlAssembler('admin/factoring/applications'),
  baseResource: factoringApplicationResource,
});

export default factoringclientPaginatedResource;

/**
 @param {string} client_id
 @param {string} debtor_id
 @param {number} rate
 @param {boolean} should_fasttrack
 */
export const updateDebtorClientRelationship = ({
  client_id,
  debtor_id,
  discount_rate,
  minimum_fee,
  should_fasttrack,
  noa_placed,
  noa_active,
 fuel_advance,
 fuel_advance_rate,
 fuel_advance_fee,
}) => async (dispatch, getState) => {
  const res = await dispatch(APIFetch(`${API.host}/admin/factoring/debtors/`, {
    method: 'PATCH',
    headers: {
      authorization: `bearer ${getState().user.token}`,
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      factoring: client_id,
      debtor: debtor_id,
      discount_rate,
      minimum_fee,
      should_fasttrack,
      noa_placed,
      noa_active,
      fuel_advance,
      fuel_advance_rate,
      fuel_advance_fee,
    }),
  }));
  if (res.status !== 200) {
    throw await res.text();
  }
  return res;
};
