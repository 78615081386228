import React, { useCallback } from 'react';
import { compose } from 'recompose';
import { connect } from 'react-redux-v5';
import { Button, Glyphicon } from 'react-bootstrap';
import { reset, SubmissionError, Field, reduxForm, getFormValues, getFormSubmitErrors, getFormMeta } from 'redux-form';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';
import isArray from 'lodash/isArray';
import isString from 'lodash/isString';

import * as validate from 'helpers/validate';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import Tooltip from 'components/pure/Tooltip';
import colors from 'styles/colors.json';
import DropZoneField from '../../pure/form/inputs/DropZoneField';
import { frCarrierBulkUpload, ascendBulkUpload } from 'actions/fileBulkUpload';
import { openModal } from 'actions/ui';
import { splitFile } from 'helpers/excelHelpers';
import Spinner from '../../pure/Spinner';

const MAX_RETRIES = 3; // Define max retries for upload
const CHUNK_SIZE = 10; // Define your chunk size

const styles = {
    mainContainer: {
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        rowGap: 15,
    },
};

const UPLOAD_TYPE_VALUES = {
    ASCEND_TMS: 'ascend_tms',
    STANDARD: 'standard',
}

const UPLOAD_TYPE = [{
    text: 'Ascend TMS Upload',
    value: UPLOAD_TYPE_VALUES.ASCEND_TMS,
},
{
    text: 'Comfreight Upload',
    value: UPLOAD_TYPE_VALUES.STANDARD,
}]

function FRBulkImport({ handleSubmit, submitting, change, values, asyncErrors, touch, formMeta, submitFailed, ...props }) {

    const removeFile = useCallback((filesKey, index) => {
        const files = filter(values[filesKey], file => file !== values[filesKey][index]);
        change(filesKey, files);
        touch(filesKey, true);
    }, [values]);

    const DropZoneInput = props => (
        <Field
            component={DropZoneField}
            accept='.xls,.xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel, .csv'
            validate={validate.compose(
                validate.vjs.presence({
                    allowEmpty: false,
                    message: '^Please select at least one file',
                }),
                validate.vjs.length({
                    minimum: 1,
                    message: '^Please select at least one file',
                })
            )}
            {...props}
        />
    )
    const spinner = submitting ? <div><Spinner style={{ fontSize: 2 }} /></div> : null;
    const SubmitButtonView = ({ link, ...props }) => (
        <div className="col-xs-12">
            <a href={link}
                target="_blank"
                rel="noreferrer"
               {...props}
                style={{ float: 'left', minWidth: 100 }}
            >
                <Button>
                    <Glyphicon glyph='glyphicon glyphicon-cloud-download' /> CSV template
                </Button>
            </a>
            <Button
                disabled={submitting}
                onClick={handleSubmit}
                className='btn btn-orange'
                style={{ float: 'right', minWidth: 100 }}
            >
                Next
                {spinner}
            </Button>
        </div>
    )

    const FilesTable = ({ filesKey }) => {
        const error = get(asyncErrors, filesKey);
        return (
            <div className={(error && submitFailed) ? 'col-xs-12 has-error' : 'col-xs-12'} style={{ minHeight: 100 }}>
                <table style={{ width: '100%' }}>
                    <thead style={{ borderBottom: '1pt solid rgba(97, 99, 101, 0.25)' }}>
                        <tr>
                            <th className='control-label'>File Name</th>
                            <th className='control-label' style={{ textAlign: 'right' }}>Action</th>
                        </tr>
                    </thead>
                    {values[filesKey].map((file, index) => (
                        <tbody>
                            <td>{file.name}</td>
                            <td style={{ textAlign: 'right' }}>
                                <Button bsStyle="link" style={{ color: colors.RED }} onClick={event => removeFile(filesKey, index)}>
                                    <Glyphicon glyph='glyphicon glyphicon-trash' />
                                </Button>
                                {error &&
                                    <Tooltip placement="top" text={error}>
                                        <Button bsStyle="link" style={{ border: 0, color: colors.RED }}>
                                            <Glyphicon glyph='glyphicon glyphicon-info-sign' style={{ fontSize: 20 }} />
                                        </Button>
                                    </Tooltip>
                                }
                            </td>
                        </tbody>
                    ))
                    }
                </table>
            </div>
    )
};

    const StandardUploadView = ({ filesKey }) => (
        <div style={styles.mainContainer}>
            <FilesTable filesKey={filesKey} />
            <div className='col-xs-12'>
                <DropZoneInput
                    name={filesKey}
                    dropZoneProps={{
                        multiple: false
                    }}
                />
            </div>
            <SubmitButtonView
                href='/public/other/broker_bulk_upload_template.xlsx'
                download='broker_bulk_upload_template.xlsx'
            />
        </div>
    );

    const AscentTmsUploadView = () => (
        <div style={styles.mainContainer}>
            <FilesTable filesKey='carriers' />
            <div className="col-xs-12">
                <DropZoneInput
                    name='carriers'
                    label='Carriers'
                />
            </div>
            <FilesTable filesKey='customers' />
            <div className="col-xs-12">
                <DropZoneInput
                    name='customers'
                    label='Customers'
                />
            </div>
            <FilesTable filesKey='load' />
            <div className="col-xs-12">
                <DropZoneInput
                    name='load'
                    label='Load'
                />
            </div>
            <SubmitButtonView />
        </div>
    );

    return <div className="container" style={{ paddingTop: 20 }}>
        <div className="col-xs-12">
            <h4>Bulk Upload</h4>
        </div>
        <div className="col-xs-12">
            <div style={styles.mainContainer}>
                <div className="col-xs-6">
                    <h5>Bulk Uploads</h5>
                    {values.uploadType === UPLOAD_TYPE_VALUES.ASCEND_TMS ?
                        <p>Add multiple funding requests using CSV template below. First upload your carriers, then shippers, then funding requests.</p>
                        : <p>Add multiple funding requests using CSV template below.</p>
                    }
                </div>
                <div className="col-xs-6">
                    <Field
                        name='uploadType'
                        component={LabeledDropdown}
                        style={{
                            minWidth: '11em',
                        }}
                        data={UPLOAD_TYPE}
                        label='Upload Type'
                        validate={validate.compose(
                            validate.vjs.presence()
                        )}
                    />
                </div>
                <div className="col-xs-6">
                    {values.uploadType === UPLOAD_TYPE_VALUES.STANDARD &&
                        <StandardUploadView filesKey='standard_upload_files' />
                    }
                    {values.uploadType === UPLOAD_TYPE_VALUES.ASCEND_TMS &&
                        <AscentTmsUploadView />
                    }
                </div>
            </div>
        </div>
    </div>
}
const FORM_NAME = 'BROKER_BULK_UPLOAD_FORM';


const onSubmitStandardBulkUpload = async (attachment, dispatch) => {
    let uploadError = null;
    let response = { success: [], fail: [] };

    try {
        const isCsv = attachment.type.includes('csv');
        const chunks = await splitFile(attachment, CHUNK_SIZE); // Use chunk size of 10 rows

        for (const chunk of chunks) {
            try {
                const chunkResponse = await frCarrierBulkUpload(chunk, isCsv, MAX_RETRIES);
                response.success.push(...chunkResponse.success);
                response.fail.push(...chunkResponse.fail);
            } catch (error) {
                uploadError = handleUploadError(error);
                console.error('Chunk upload error:', formatErrorMessage(uploadError));
                break; // Exit loop on first error for better error reporting
            }
        }
    } catch (error) {
        uploadError = handleSplitError(error);
        console.error('File split error:', formatErrorMessage(uploadError));
    }

    if (uploadError) {
        throw new SubmissionError({ standard_upload_files: formatErrorMessage(uploadError) });
    }

    let message = '';
    let modalType = '';

    if (!isEmpty(response?.success)) {
        modalType = 'success';
        message = response.success.map(successArray => (
            `Load id: ${successArray[0]}, ${successArray[1]}`
        )).join('\n');
    }

    if (!isEmpty(response?.fail)) {
        modalType = 'error';
        message += response.fail.map(failedErrors => (
            `Load id: ${failedErrors[0]}, ${failedErrors[1]} \n`
        )).join('');
    }

    dispatch(reset('FORM_NAME'));
    return dispatch(openModal(modalType, { message }));
};

const handleSplitError = (error) => {
    let errorMessage = {
        type: "Split Error",
        message: "Error splitting file",
        details: ""
    };
    if (error.message) {
        errorMessage.details = error.message;
    } else {
        errorMessage.details = "Unknown error occurred during file splitting.";
    }
    return errorMessage;
};

const handleUploadError = (error) => {
    let errorMessage = {
        type: "Upload Error",
        message: "Error uploading file chunk",
        details: ""
    };
    if (error.response) {
        if (error.response.status === 403) {
            errorMessage.details = `Permission denied: ${error.response.data.detail}`;
        } else if (isArray(error.response.data.fail)) {
            if (isArray(error.response.data.fail[0])) {
                errorMessage.details = filter(error.response.data.fail[0], o => isString(o)).join('\n');
            } else {
                errorMessage.details = error.response.data.fail.join('-');
            }
            if (isArray(error.response.data.success)) {
                errorMessage.details += `\n ${error.response.data.success.join('-')}`;
            }
        } else {
            errorMessage.details = error.response.data;
        }
    } else if (error.request) {
        errorMessage.details = "No response received from server.";
    } else {
        errorMessage.details = error.message;
    }
    return errorMessage;
};

const formatErrorMessage = (error) => {
    return `${error.type}: ${error.message}\nDetails: ${error.details}`;
};



const onSubmitAscendBulkUpload = async (attachments, dispatch) => {
    try {
        const { created_frs, created_loads, failed_load_ids = [], existing_frs, existing_loads } = await ascendBulkUpload(attachments);
        dispatch(reset(FORM_NAME));
        return dispatch(openModal('success',
        { message: `Files has been uploaded successfully, Please check Transaction Page.
            \n Funding Request created: ${created_frs}
            \n Loads created: ${created_loads}
            \n Existing Load: ${existing_loads}
            \n Existing Funding Request: ${existing_frs}
            \n ${!isEmpty(failed_load_ids) && failed_load_ids.map(failedError => (
                `\n ${failedError.error} Load id: ${failedError.load_id}`
            ))}`
        }));
    } catch (error) {
        if (error.response) {
            if (error.response?.status === 403) {
                return dispatch(openModal('error', { message: error.response?.data?.detail }));
            }
            if (isArray(error.response?.data?.fail)) {
                if (isArray(error.response?.data?.fail[0])) {
                    return dispatch(openModal('error', { message: filter(error.response?.data?.fail[0], o => isString(o)).join('\n') }));
                } else {
                    return dispatch(openModal('error', { message: error.response?.data?.fail.join('-') }));
                }
            } else {
                return dispatch(openModal('error', { message: error.response?.data }));
            }
        } else if (error.request) {
            return dispatch(openModal('error', { message: error.request }));
        } else {
            return dispatch(openModal('error', { message: error.message }));
        }
    }
}

export default compose(
    reduxForm({
        form: FORM_NAME,
        initialValues: {
            uploadType: undefined,
            standard_upload_files: [],
            carriers: [],
            customers: [],
            load: [],
        },
        async onSubmit({ standard_upload_files, carriers, customers, load }, dispatch) {
            if (standard_upload_files.length) {
                return onSubmitStandardBulkUpload(standard_upload_files[0], dispatch)
            }
            return onSubmitAscendBulkUpload({ carriers: carriers[0], customers: customers[0], loads: load[0] }, dispatch);
        },
    }),
    connect(state => ({
        values: getFormValues(FORM_NAME)(state),
        asyncErrors: getFormSubmitErrors(FORM_NAME)(state),
        formMeta: getFormMeta(FORM_NAME)(state),
    }), null),
)(FRBulkImport);
