/**
 * Component for displaying an input with a label in a form
 * @module components/pure/form/inputs/LabeledInput
 * @since 3.0.0
 * @property {object} props
 * @property {object} props.input - input callbacks provided by redux-form
 * @property {object} props.meta - meta information provided by redux-form
 * @property {Component} [props.ComponentClass='input'] - the React element to use to construct the input element
 * @property {object} [props.containerProps] - props to be passed to the containing div component
 * @property {any} [props....rest] - props passed to the input component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

const LabeledInput = ({
  input,
  meta = {},
  label,
  ComponentClass = 'input',
  containerProps = {},
  labelProps = {},
  hasAsyncValidate = false,
  horizontal = false,
  errorStyle = {},
  ...rest
}) =>
  <div
    {...containerProps}
    className={cs(
      'form-group',
      {
        'has-error': meta.touched && meta.error,
        'has-warning': meta.touched && meta.warning && !meta.error,
      },
      containerProps.className,
    )}
    >
    {
      label ?
        <label
          {...labelProps}
          className={cs('control-label', labelProps.className, {
            'col-xs-2': horizontal,
          })}
          >
          {label}
        </label>
        : null
    }
    <div
      className={cs({
        'input-valid': hasAsyncValidate && meta.dirty && !meta.error,
        'input-validating': hasAsyncValidate && meta.asyncValidating,
        'col-xs-10': horizontal,
      })}
      >
      <ComponentClass
        {...input}
        autoComplete='off'
        {...rest}
        className={cs('form-control', rest.className)}
      />
    </div>
    {meta.touched && meta.error || meta.warning ? <div style={errorStyle} className='help-block'>{meta.error || meta.warning}</div> : null}
  </div>
  ;

LabeledInput.propTypes = {
  label: PropTypes.node,
  ComponentClass: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.func,
  ]),
};

export default LabeledInput;
