import React from 'react';
import { compose } from 'redux-v3';

import formatPennies from 'helpers/formatPennies';

import Resource from 'components/hoc/Resource';
import EventLog from 'components/pure/EventLog';

export default compose(
  Resource('factoringdebtorauditlog'),
)(({ item }) => {
  if (!item || !item.data) {
    return null;
  }
  return (
    <EventLog
      events={item.data}
      transform={data => {
        const key_is_invalid = (
          [
            'debtor_modified',
            'debtor_last_edited_by_name',
            'debtor_last_edited_by_id',
            'debtor_last_edited_by_user_type',
            'debtor_assigned_admin_id',
            'debtor_assigned_admin_user_type',
            'debtor_deleted_by_id',
            'debtor_deleted_reason',
          ].includes(data.original_key) ||
          (!data.original_key.startsWith('debtor_') && !data.original_key.startsWith('user_'))
        );
        if (key_is_invalid) {
          return null;
        }
        const label_map = {
          assigned_admin_name: 'Sales Rep',
          deleted_by_name: 'Deleted By',
          name: 'Contact Name',
          company_name: 'DBA',
          phone_number: 'Contact Phone',
          factor_dba: 'Factor DBA',
          noa_contact_email: 'NOA Contact Email',
          mc: 'MC',
          dot: 'DOT',
        };
        const key = data.original_key.replace('debtor_', '');
        let { old_value, new_value } = data;
        const format_value_map = {
          originals_required(data) {
            data.old_value = data.old_value ? 'Yes' : 'No';
            data.new_value = data.new_value ? 'Yes' : 'No';
            return data;
          },
          credit_limit(data) {
            data.old_value = formatPennies(data.old_value);
            data.new_value = formatPennies(data.new_value);
            return data;
          },
        };
        const formatter = format_value_map[key];
        if (formatter) {
          ({ old_value, new_value } = formatter(data));
        }
        return {
          ...data,
          old_value,
          new_value,
          key: label_map[key] || data.key.replace('Debtor ', ''),
        };
      }}
      sort={(a, b) => {
        const order = [
          'assigned_admin_name',
          'email',
          'contact_email',
          'name',
          'company_name',
          'address',
          'phone_number',
          'fax_number',
          'mc',
          'dot',
          'avgpay',
          'credit_approved',
          'factor_dba',
          'noa_contact_email',
          'credit_approved_note',
          'credit_limit',
          'credit_available',
          'originals_required',
          'source',
        ];
        // get root key, slicing off `debtor_`
        let root_key_a, root_key_b;
        const a_has_nested = a.original_key.indexOf('.');
        if (a_has_nested !== -1) {
          root_key_a = a.original_key.slice(7, a_has_nested);
        }
        else {
          root_key_a = a.original_key.slice(7);
        }
        const b_has_nested = b.original_key.indexOf('.');
        if (b_has_nested !== -1) {
          root_key_b = b.original_key.slice(7, b_has_nested);
        }
        else {
          root_key_b = b.original_key.slice(7);
        }
        const index_a = order.indexOf(root_key_a);
        const index_b = order.indexOf(root_key_b);
        return index_a > index_b ? 1 : index_b > index_a ? -1 : 0;
      }}
    />
  );
});
