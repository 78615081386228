import get from 'lodash/get';
import moment from 'moment';


class Resource {
  constructor(name, options) {
    this.name = name;
    this.upperName = name.toUpperCase();
    this.actions = Object.create(null);
    this.selector = this.selector.bind(this);
    Object.assign(this, options);
  }

  selector(state, id) {
    return get(state.resource, [this.name, id]);
  }

  parse(oldValue = {}, newData) {
    return {
      isFetching: false,
      err: null,
      ...oldValue,
      fetchedAt: moment(),
      data: newData || oldValue.data ? {
        ...oldValue.data,
        ...newData,
      } : null,
    };
  }

  parseMany(oldValues, newValues) {
    return newValues.reduce((acc, curr) => {
      acc[curr.id] = this.parse(oldValues[curr.id], curr);
      return acc;
    }, {});
  }

  reduce(state = {}) {
    return state;
  }
}

export default Resource;
