import React, { useContext } from 'react';

import { Card } from 'material-ui/Card';
import isEmpty from 'lodash/isEmpty';

import { CenteredSpinner } from 'components/pure/Spinner';
import CardContext from './context/CardContext';

import onboardingStatus from 'helpers/walletOnboardingStatus';

import colors from 'styles/colors.json';

import MaterialIcon from 'components/pure/MaterialIcon';
import BigOrangeSubmitButton from 'components/pure/BigOrangeSubmitButton';
import useStripeOnboarding from '../../hoc/useStripeOnboarding';
import MyCards from './MyCards';
import AddStripeBankAccount from 'components/pure/AddStripeBankAccount';

function OnboardingStripeCarrier(props) {
    const { reload } = useContext(CardContext)
    const { accountSettings, handleContinue, statusMapping, isGenerateLink } = useStripeOnboarding(reload);

    if (isEmpty(accountSettings)) {
        return <CenteredSpinner />
    }

    return (
        <div style={{ position: 'relative' }} >
            <div className={[onboardingStatus.EXTERNAL_ACCOUNT_REQUIRED, onboardingStatus.PENDING, onboardingStatus.ONBOARDING_REQUIRED, onboardingStatus.DELETED, onboardingStatus.ONBOARDING_REVIEW].includes(accountSettings.onboardingStatus) && 'blurred'}>
                <MyCards {...props} accountId={accountSettings.accountId} />
            </div>
            <div className='centered' id='unBlurred'>
                {(accountSettings.onboardingRequired &&
                    accountSettings.onboardingStatus === onboardingStatus.ONBOARDING_REQUIRED) &&
                    <Card style={{ borderRadius: 10 }} containerStyle={{ minWidth: 300, minHeight: '30%', textAlign: 'center', justifyContent: 'center' }}>
                        <div className='alert' style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                                <MaterialIcon
                                    name='credit_card'
                                    size={50}
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                                <p style={{ padding: 20 }} id='unBlurred'>
                                    Please first complete the onboarding process.
                                </p>
                                <BigOrangeSubmitButton submitting={isGenerateLink} className='btn btn-orange' style={{ display: 'block' }} type='submit' onClick={handleContinue} message='Continue' />
                            </div>
                        </div>
                    </Card>
                }
                {statusMapping[accountSettings.onboardingStatus] &&
                    <Card style={{ borderRadius: 10 }} containerStyle={{ minWidth: 300, minHeight: '30%', textAlign: 'center', justifyContent: 'center' }}>
                        <div className='alert' style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                                <MaterialIcon
                                    name={statusMapping[accountSettings.onboardingStatus]?.icon}
                                    size={50}
                                    style={{
                                        color: statusMapping[accountSettings.onboardingStatus]?.color
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                                <p style={{ padding: 20 }} className={`text-${statusMapping[accountSettings.onboardingStatus]?.alertType}`} id='unBlurred'>
                                    {statusMapping[accountSettings.onboardingStatus]?.text}
                                </p>
                            </div>
                        </div>
                    </Card>
                }
                {accountSettings.onboardingStatus === onboardingStatus.EXTERNAL_ACCOUNT_REQUIRED &&
                    <Card id="external_bank_account" style={{ borderRadius: 10 }} containerStyle={{ minWidth: 300, minHeight: '30%', textAlign: 'center', justifyContent: 'center' }}>
                        <div className='alert' style={{ width: '100%' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center' }}>
                                <MaterialIcon
                                    name='account_balance'
                                    size={50}
                                    style={{
                                        color: colors.SUCCESS
                                    }}
                                />
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignContent: 'center', justifyContent: 'center' }}>
                                <p style={{ padding: 20 }} className='text-success' id='unBlurred'>
                                    Please first add a bank account to your wallet.
                                </p>
                                <AddStripeBankAccount accountId={accountSettings.accountId} context={CardContext} />
                            </div>
                        </div>
                    </Card>
                }
            </div>
        </div>
    )
}

export default OnboardingStripeCarrier;