import React from 'react';
import cs from 'classnames';


const FileInput = ({ input, meta, label, ...rest }) =>
  <div
    className={cs({
      'form-group': true,
      'has-error': meta.touched && meta.error,
      'has-warning': meta.touched && meta.warning && !meta.error,
    })}
    >
    {label ? <label className='control-label'>{label}</label> : null}
    <input
      onChange={e => {
        const files = e.target.files.length > 1 ? e.target.files : e.target.files[0];
        input.onChange(files);
      }}
      onBlur={e => {
        const files = e.target.files.length > 1 ? e.target.files : e.target.files[0];
        input.onBlur(files);
      }}
      type='file'
      {...rest}
    />
    {meta.touched && meta.error || meta.warning ? <div className='help-block'>{meta.error || meta.warning}</div> : null}
  </div>
  ;

export default FileInput;
