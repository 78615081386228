import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { submit } from 'redux-form';

import { setViewed } from 'actions/resource/trucksearch';
import * as truckSearchActions from 'actions/search/truck';

import docTitle from 'components/hoc/docTitle';
import setLastTool from 'components/hoc/setLastTool';
import scrollToItem from 'components/hoc/scrollToItem';
import Search from 'components/pure/Search';
import TruckSearchForm from 'components/pure/form/TruckSearchForm';
import TruckMobile2 from 'components/pure/TruckMobile2';
import TruckSearchSortableTable from 'components/pure/TruckSearchSortableTable';
import SearchResults from 'components/pure/SearchResults';
import TruckSearchMap from 'components/container/TruckSearchMap';


export const MobileComponent = compose(
  connect(
    (state, ownProps) => ({
      scroll_to_item: state.ui.scroll_to_item,
      truck: state.resource.trucksearch[ownProps.id],
    }),
    {
      setViewed,
    }
  ),
  scrollToItem({
    idPropName: ['truck', 'data', 'id'],
  }),
)(({ dispatch, setViewed, ...rest }) =>
  <TruckMobile2
    {...rest}
    onClick={() => setViewed(rest.truck.data.id)}
  />
);

export const TruckSearchResults = compose(
  connect(
    state => ({
      ids: state.search.truck.ids,
      isFetching: state.search.truck.isFetching,
      ordering: state.search.truck.ordering,
      view: state.search.truck.globals.view,
    }),
    {
      fetchNext: truckSearchActions.fetchNext,
      sort: truckSearchActions.sort,
      setView: truckSearchActions.setView,
      refresh: () => submit('TruckSearch'),
    },
  ),
)(props =>
  <SearchResults
    {...props}
    MobileComponent={MobileComponent}
    DesktopComponent={TruckSearchSortableTable}
    GoogleMap={TruckSearchMap}
    orderingOptions={[
      {
        displayName: 'Age',
        ordering: 'time_posted',
        adjectives: ['Oldest', 'Newest'],
      },
      {
        displayName: 'Equipment',
        ordering: 'trailerTypes',
        adjectives: ['A to Z', 'Z to A'],
      },
      {
        displayName: 'Start date',
        ordering: 'time_start',
        adjectives: ['Earliest', 'Latest'],
      },
      {
        displayName: 'Weight',
        ordering: 'weight',
        adjectives: ['Min', 'Max'],
      },
      {
        displayName: 'Length',
        ordering: 'length',
        adjectives: ['Shortest', 'Longest'],
      },
    ]}
  />
);

export default compose(
  docTitle('Truck Search'),
  setLastTool(props => props.location.pathname),
  connect(
    null,
    {
      swapOriginAndDestination: truckSearchActions.swapOriginAndDestination,
      refresh: () => submit('TruckSearch'),
    },
  ),
)(({ swapOriginAndDestination, refresh }) =>
  <Search
    mountPoint={['search', 'truck']}
    resource={'truck'}
    Form={TruckSearchForm}
    SearchResults={TruckSearchResults}
    basePath={'/trucksearch'}
    swapOriginAndDestination={swapOriginAndDestination}
    refresh={refresh}
  />
);
