/**
 * Component that displays a subscription package item for the subscription package select on the subscription page
 * @module components/pure/BillingPackageSelection
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/ColoredCircle
 * @property {object} props
 * @property {boolean} [props.active = false] - whether to display this package item in its active state
 * @property {node} [props.title] - the text to display as the title of this item
 * @property {number} props.price - the number (in pennies) to be formatted and displayed
 * @property {node} [props.periodName = 'month'] - the period of time this subscription applies for
 * @property {node} [props.note] - the sub-text underneath the title
 * @property {node} [props.balloon] - child node for the balloon, which is omitted if this prop is not present
 * @property {object} [props.balloonProps] - props to pass to the balloon
 * @property {any} [props....rest] - props passed to containing component
 */
import 'styles/BillingPackageSelection';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import formatPennies from 'helpers/formatPennies';
import ColoredCircle from 'components/pure/ColoredCircle';


const BillingPackageSelection = ({
  active = false,
  title,
  price,
  periodName = 'month',
  note,
  balloon,
  balloonProps,
  stripePrice,
  period,
  details,
  plan_name,
  upgrades_to_plan,
  ...rest
}) =>
  <div {...rest} className={cs('BillingPackageSelection', rest.className, { active })}>
    <div>{title}</div>
    <div>
      <span>{formatPennies(price)}</span>
      <span> /{periodName}</span>
    </div>
    <div><em>{note}</em></div>
    {balloon && <ColoredCircle color='#4BC800' {...balloonProps}>{balloon}</ColoredCircle>}
  </div>
  ;

BillingPackageSelection.propTypes = {
  active: PropTypes.bool,
  title: PropTypes.node,
  price: PropTypes.number,
  periodName: PropTypes.node,
  note: PropTypes.node,
  balloon: PropTypes.node,
  balloonProps: PropTypes.object,
};

export default BillingPackageSelection;
