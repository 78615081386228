/**
 * Reducer for handling the factoringpayment resource actions
 * @module reducers/resource/factoringpayment
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringpayment
 * @requires actions/admin/factoringpayment
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const initialState = INITIAL_STATE.resource.factoringpayment;

import factoringpaymentResource, {
  FACTORING_PAYMENT_DELETE,
  FACTORING_PAYMENT_ATTACHMENT_DELETE,
  FACTORING_PAYMENT_ATTACHMENTS,
} from 'actions/resource/factoringpayment';
import factoringpaymentByUserResource from 'actions/resourceBy/user/factoringpayment';
import factoringpaymentAdminResource from 'actions/admin/factoring/payment';


export default function factoringpaymentResourceReducer(state = initialState, action) {
  state = factoringpaymentResource.reduce(state, action);
  if (factoringpaymentByUserResource.matchesAction(action, 'FETCH_REQUEST_SUCCESS')) {
    return {
      ...state,
      ...factoringpaymentResource.parseMany(state, action.payload.results),
    };
  }
  switch (action.type) {
    case factoringpaymentAdminResource.actions.FETCH_REQUEST_SUCCESS:
      return {
        ...state,
        ...factoringpaymentResource.parseMany(state, action.payload.results),
      };
    case FACTORING_PAYMENT_DELETE:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          data: {
            ...state[action.payload].data,
            status: 'deleted',
          },
        },
      };
    case FACTORING_PAYMENT_ATTACHMENT_DELETE: {
      const item = state[action.payload.id];
      const index = item.data.attachments.findIndex(({ id }) => id === action.payload.attachment);
      return {
        ...state,
        [action.payload.id]: {
          ...item,
          data: {
            ...item.data,
            attachments: [
              ...item.data.attachments.slice(0, index),
              ...item.data.attachments.slice(index + 1),
            ],
          },
        },
      };
    }
    case FACTORING_PAYMENT_ATTACHMENTS: {
      const attachments = get(action, 'payload.attachments', []);
      const id = get(action, 'payload.id', '');
      const item = state[action.payload.id];
      if (isEmpty(item.data)) {
        return state;
      }
      return {
        ...state,
        [id]: {
          ...item,
          data: {
            ...item.data,
            attachments,
          },
        },
      };
    }
  }
  return state;
}
