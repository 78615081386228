import validate from 'validate.js';
import phoneNumber from 'google-libphonenumber/dist/libphonenumber';


const DEFAULT_OPTIONS = {
  region: ['US', 'MX', 'CA'],
  message: () => 'Not a valid phone number',
};

export const phone = options => val => {
  if (validate.isEmpty(val)) {
    return;
  }
  const phoneUtil = phoneNumber.PhoneNumberUtil.getInstance();
  if (typeof options === 'string') {
    options = {
      ...DEFAULT_OPTIONS,
      notValid: options,
    };
  }
  else if (typeof options === 'boolean') {
    options = DEFAULT_OPTIONS;
  }
  else {
    options = {
      ...DEFAULT_OPTIONS,
      ...options,
    };
  }
  const regions = Array.isArray(options.region) ? options.region : [options.region];
  const isValid = regions.some(region => {
    let number;
    try {
      number = phoneUtil.parse(val, region);
    }
    catch (e) {
      return false;
    }
    return phoneUtil.isValidNumber(number);
  });
  if (!isValid) {
    return options.message(options);
  }
};
