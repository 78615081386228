/**
 * Component that displays the user search form
 * @module components/pure/form/UserEditForm
 * @since 3.1.0
 * @requires actions/ui
 * @requires actions/search/user
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/SubmitButton
 */
/* global TOKEN_STORAGE_KEY */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import { Link } from 'react-router-v4';
import moment from 'moment';
import withState from 'recompose/withState';
import { get } from 'lodash';


import { USER_TYPE } from '../../../helpers';
import formatAddress from '../../../helpers/formatAddress';
import * as validate from '../../../helpers/validate';

import { openModal } from '../../../actions/ui';
import { getLoginToken } from '../../../actions/user';
import { edit } from '../../../actions/resource/usersearch';
import { upload as uploadFiles } from '../../../actions/settings/documents';
import storage from '../../../datatypes/storage';

import resourceProperty from '../../../components/hoc/resourceProperty';
import Header from '../../../components/pure/Header';
import InputLabel from '../../../components/pure/InputLabel';
import LabeledInput from '../../../components/pure/form/inputs/LabeledInput';
import LabeledDropdown from '../../../components/pure/form/inputs/LabeledDropdown';
import GoogleLocationInput from '../../../components/pure/form/inputs/GoogleLocationInput';
import AdminDropdownInput from '../../../components/pure/form/inputs/AdminDropdownInput';
import NumberInput from '../../../components/pure/form/inputs/NumberInput';
import { MultiImageInput } from '../../../components/pure/form/inputs/ImageInput';
import PennyInput from '../../../components/pure/form/inputs/PennyInput';
import LoadAttachmentButton from '../../../components/pure/LoadAttachmentButton';
import { MultiTrailerInput } from '../../../components/pure/form/inputs/TrailerInput';
import { MultiStateInput } from '../../../components/pure/form/inputs/StateInput';
import FMCSALink from '../../../components/pure/FMCSALink';
import PhoneInput from '../../../components/pure/form/inputs/PhoneInput';
import MaterialIcon from '../../../components/pure/MaterialIcon';

import normalizeFormPhone from 'helpers/normalizeFormPhone';

const FactoringStatusField = compose(
  resourceProperty('factoringclient', 'factoring_approved'),
)(({ factoring_approved }) =>
  <InputLabel.Horizontal label='Factoring Status' value={factoring_approved} />
);

export default compose(
  withState('lanes', 'setLanes', ({ initialValues }) => initialValues.lane_origin.length),
  withState('showLanes', 'setShowLanes', false),
  reduxForm({
    form: 'UserEdit',
    async onSubmit(fields, dispatch, { item, lanes }) {
      try {
        let groups = item.data.groups;
        if (fields.dispatching_interest) {
          if (!groups.find(group => group.name === 'Admin Dispatch')) {
            groups.push({
              name: 'Admin Dispatch',
              roles: [{ role: 'dispatch_carrier' }],
            });
          }
        }
        else {
          groups = groups.filter(group => group.name !== 'Admin Dispatch');
        }
        const data = {
          ...fields,
          contact_phone: normalizeFormPhone(fields, 'contact_phone'),
          fax_number: normalizeFormPhone(fields, 'fax_number'),
          credit_approved: typeof fields.credit_approved === 'object' ? fields.credit_approved.value : fields.credit_approved,
          address: fields.address.street_one || '',
          city: fields.address.city || '',
          state: fields.address.state || '',
          zip: fields.address.zip || '',
          country: fields.address.country || '',
          admin: undefined,
          assigned_admin: fields.admin.id,
          haulpay_interest: typeof fields.haulpay_interest === 'object' ? fields.haulpay_interest.value : fields.haulpay_interest,
          dispatching_interest: undefined,
          groups,
          equipment_types: fields.equipment_types.map(({ value }) => value),
          full_truck_loads_per_month: fields.full_truck_loads_per_month,
          ltl_loads_per_month: fields.ltl_loads_per_month,
          commodities_shipped: fields.commodities_shipped,
          lanes: [
            ...fields.lane_origin.slice(0, lanes).reduce((acc, curr, i) => {
              const origin = curr;
              const destination = fields.lane_destination[i];
              const ret = { origin };
              if (destination && destination.state) {
                ret.destination = destination;
              }
              acc.push(ret);
              return acc;
            }, []),
            ...(() => {
              if (!fields.lane_origin_states.length) {
                return [];
              }
              // X states -> anywhere (0 states)
              if (!fields.lane_destination_states.length) {
                return fields.lane_origin_states.map(({ code }) => {
                  return {
                    origin: { state: code },
                  };
                });
              }
              return fields.lane_origin_states.reduce((acc, curr) => {
                const origin = curr;
                for (const destination of fields.lane_destination_states) {
                  acc.push({
                    origin: { state: origin.code },
                    destination: { state: destination.code },
                  });
                }
                return acc;
              }, []);
            })(),
          ],
          lane_origin: undefined,
          lane_destination: undefined,
          lane_origin_states: undefined,
          lane_destination_states: undefined,
        };
        if (fields.files_insurance.length || fields.files_authority.length) {
          const promises = [];
          if (fields.files_insurance.length) {
            promises.push(dispatch(uploadFiles({
              user: item.data.id,
              file: fields.files_insurance[0],
              category: 'documents_insurance',
            })));
          }
          if (fields.files_authority.length) {
            promises.push(dispatch(uploadFiles({
              user: item.data.id,
              file: fields.files_authority[0],
              category: 'documents_authority',
            })));
          }
          await Promise.all(promises);
        }
        await dispatch(edit(item.data.id, data));
        dispatch(openModal('success', { message: 'User updated' }));
      }
      catch (err) {
        console.warn(err);
        let message = 'Failed to update user.';
        if (err.message) {
          if (typeof err.message === 'string') {
            message = err.message;
          }
          else if (err.message.non_field_errors) {
            message = err.message.non_field_errors[0];
          }
        }
        dispatch(openModal('error', { message }));
      }
    },
  }),
)(({ handleSubmit, submitting, item, dispatch, lanes, setLanes, showLanes, setShowLanes }) =>
  <div className='form-horizontal'>
    <div className='row'>
      <div className='col-xs-12 col-sm-6'>
        <Field
          name='admin'
          component={AdminDropdownInput}
          horizontal={true}
        />
        <InputLabel.Horizontal
          label='User Type'
          value={item.data.user_type}
        />
        <InputLabel.Horizontal
          label='Created At'
          value={moment(item.data.created).format('l LTS')}
        />
        <InputLabel.Horizontal
          label='Last Login'
          value={item.data.last_login ? moment(item.data.last_login).format('l LTS') : 'Never'}
        />
        <InputLabel.Horizontal
          label='Is Subscribed'
          value={get(item, ['data', 'permissions', 'is_subscribed'])}
        />
        <InputLabel.Horizontal
          label='Is Trialing'
          value={item.data.permissions.is_trialing}
        />
        {
          item.data.factoring && item.data.factoring.id ?
            <FactoringStatusField id={item.data.factoring.id} />
            :
            <InputLabel.Horizontal
              label='Has Applied For Factoring'
              value={false}
            />
        }
        <Field
          name='email'
          component={LabeledInput}
          label='Email'
          horizontal={true}
        />
        <Field
          name='contact_email'
          component={LabeledInput}
          label='Contact Email (PE Only)'
          disabled={item.data.external !== 'posteverywhere'}
          horizontal={true}
        />
        <Field
          name='contact_name'
          component={LabeledInput}
          label='Contact Name'
          horizontal={true}
        />
        <Field
          name='dba'
          component={LabeledInput}
          label='Company'
          horizontal={true}
        />
        <Field
          name='address'
          component={GoogleLocationInput}
          label='Address'
          horizontal={true}
        />
        {
          [null, ''].includes(item.data.source) ?
            <Field
              name='source'
              component={LabeledInput}
              label='Source'
              horizontal={true}
            />
            :
            <InputLabel.Horizontal
              label='Source'
              value={item.data.source || 'unspecified'}
              horizontal={true}
            />
        }
        <div style={{ marginLeft: '17%' }} className='col-lg-10'>
          <Field
            name='equipment_types'
            label='Equipment Type'
            component={MultiTrailerInput}
          />
        </div>


        {(() => {
          const { files } = item.data;
          let insurance = null;
          let authority = null;
          if (files) {
            files.forEach(file => {
              if (file.category === 'documents_insurance') {
                insurance = file;
              }
              else if (file.category === 'documents_authority') {
                authority = file;
              }
            });
          }
          return (
            <div>
              <Field
                name='files_insurance'
                component={MultiImageInput}
                label={
                  insurance ?
                    <LoadAttachmentButton
                      title='Insurance'
                      getURL={() => Promise.resolve(insurance)}
                    />
                    :
                    <span className='text-danger'>No Insurance Doc Yet</span>
                }
                style={{
                  float: 'left',
                  margin: '0 15px',
                  marginRight: 30,
                }}
                imageGridProps={{
                  columns: {
                    sm: 1,
                    md: 1,
                    lg: 1,
                  },
                }}
                limit={1}
                buttonClassName='btn btn-default'
                buttonText={insurance ? 'Replace Insurance Doc' : 'Add Insurance Doc'}
              />
              <Field
                name='files_authority'
                component={MultiImageInput}
                label={
                  authority ?
                    <LoadAttachmentButton
                      title='Authority'
                      getURL={() => Promise.resolve(authority)}
                    />
                    :
                    <span className='text-danger'>No Authority Doc Yet</span>
                }
                style={{
                  float: 'left',
                  margin: '0 15px',
                }}
                imageGridProps={{
                  columns: {
                    sm: 1,
                    md: 1,
                    lg: 1,
                  },
                }}
                limit={1}
                buttonClassName='btn btn-default'
                buttonText={authority ? 'Replace Authority Doc' : 'Add Authority Doc'}
              />
            </div>
          );
        })()}
      </div>
      <div className='col-xs-12 col-sm-6'>
        <div className='row'>
          <div className='col-xs-2' style={{ textAlign: 'right' }}>
            <label className='control-label'>Phone</label>
          </div>
          <div className='col-xs-4'>
            <Field name='contact_phone' component={PhoneInput} horizontal={true} />
          </div>
          <div className='col-xs-6'>
            <Field name='contact_phone_ext' component={LabeledInput} label='Phone Ext.' type='number' horizontal={true} />
          </div>
        </div>
        <div className='row'>
          <div className='col-xs-2' style={{ textAlign: 'right' }}>
            <label className='control-label'>Fax</label>
          </div>
          <div className='col-xs-4'>
            <Field name='fax_number' component={PhoneInput} horizontal={true} />
          </div>
          <div className='col-xs-6'>
            <Field name='fax_number_ext' component={LabeledInput} label='Fax Ext.' type='number' horizontal={true} />
          </div>
        </div>
        <Field
          name='mc'
          component={LabeledInput}
          ComponentClass={NumberInput}
          label='MC #'
          type='number'
          horizontal={true}
        />
        <Field
          name='dot'
          component={LabeledInput}
          ComponentClass={NumberInput}
          label='DOT #'
          type='number'
          horizontal={true}
        />
        <Field
          name='stripe_email'
          component={LabeledInput}
          label='Stripe Email'
          horizontal={true}
        />
        <Field
          name='password'
          component={LabeledInput}
          label='Password'
          horizontal={true}
        />
        <Field
          name='avgpay'
          component={LabeledInput}
          type='text'
          label='Average Days To Pay'
          horizontal={true}
        />
        <Field
          name='credit_approved'
          component={LabeledDropdown}
          data={[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ]}
          label='Credit Approved Emblem'
          disabled={true}
          horizontal={true}
        />
        <Field
          name='cargo_insurance_value'
          component={PennyInput}
          label='Cargo Insurance Value'
          horizontal={true}
        />
        <Field
          name='cargo_insurance_provider'
          component={LabeledInput}
          label='Cargo Insurance Provider'
          horizontal={true}
        />
        <Field
          name='haulpay_interest'
          component={LabeledDropdown}
          data={[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ]}
          label='Haul Pay Interest'
          horizontal={true}
        />
        <Field
          name='dispatching_interest'
          component={LabeledDropdown}
          data={[
            { text: 'Yes', value: true },
            { text: 'No', value: false },
          ]}
          label='Dispatch Client'
          horizontal={true}
        />
        <Field
          name='commodities_shipped'
          component={LabeledInput}
          label='Common Commodity'
          horizontal={true}
        />
        {
          item.data.user_type === USER_TYPE.SHIPPER ?
            <Field
              name='full_truck_loads_per_month'
              component={LabeledInput}
              label='Full TL Per Month'
              horizontal={true}
            />
            : null
        }
        {
          item.data.user_type === USER_TYPE.SHIPPER ?
            <Field
              name='ltl_loads_per_month'
              component={LabeledInput}
              label='LTL Per Month'
              horizontal={true}
            />
            : null
        }
      </div>
    </div>
    <div className='text-right'>
      <FMCSALink mc={item.data.mc} dot={item.data.dot} />
    </div>
    <div className='modal-footer row'>
      <button
        type='button'
        style={{ backgroundColor: '#ff100e', marginLeft: '5px', height: '35px', color: 'white' }}
        onClick={() => dispatch(openModal('confirm', {
          type: 'user_delete',
          args: [item.data.id],
        }))}
        >
        <MaterialIcon name='delete' />
        Delete User
      </button>
      <button
        type='button'
        onClick={async () => {
          try {
            const json = await dispatch(getLoginToken(item.data.id));
            if (!json.token) {
              throw new Error('malformed token response');
            }
            storage.setItem(TOKEN_STORAGE_KEY, json.token);
            // reload and redirect at the same time
            location.href = '/';
          }
          catch (err) {
            console.warn(err);
            openModal('error', { message: err.message || err });
          }
        }}
        style={{ backgroundColor: '#3ca8ff', marginLeft: '5px', height: '35px', color: 'white' }}
        >
        <MaterialIcon name='airplay' />
        {' '}
        Login as User
      </button>
      <Link to={`/user/${item.data.id}`}><button style={{ border: '1px solid #828282', marginLeft: '5px', backgroundColor: '#fff', height: '35px', color: 'black' }}>
        <MaterialIcon name='account_circle' />
        Profile</button></Link>
      {(() => {
        if (item.data.factoring && item.data.factoring.id && item.data.debtor_id) {
          return ([
            <Link to={`/admin/factoring/client/${item.data.factoring.id}`}><button className='btn-payment'>
              <MaterialIcon name='account_circle' />
            Client</button></Link>,
            <Link to={`/admin/factoring/debtor/${item.data.debtor_id}`}><button className='btn-payment'>
              <MaterialIcon name='account_box' />
            Debtor</button></Link>,
          ]);
        }
        if (item.data.debtor_id) {
          return ([
            <Link to={`/admin/factoring/debtor/${item.data.debtor_id}`}><button style={{ backgroundColor: '#21bb00', height: '35px', color: 'white' }} type='button'>
              <MaterialIcon name='account_box' />
            Debtor</button></Link>,
          ]);
        }
        if (item.data.factoring && item.data.factoring.id) {
          return ([
            <Link to={`/admin/factoring/client/${item.data.factoring.id}`}><button style={{ backgroundColor: '#21bb00', height: '35px', color: 'white' }} type='button'>
              <MaterialIcon name='account_circle' />
            Factoring</button></Link>,
          ]);
        }
        return null;
      })()}
      <button type='submit' style={{ backgroundColor: '#e86b00', marginLeft: '5px', height: '35px', color: 'white' }} disabled={submitting} onClick={handleSubmit} >
        <MaterialIcon name='save' />
        Update
      </button>
    </div>
    <div className='row'>
      <Header>Lanes</Header>
      {
        showLanes ?
          <div style={{ margin: '0 15px' }}>
            <div style={{ margin: '0 -15px' }}>
              <table className='table table-striped table-condensed'>
                <thead>
                  <tr>
                    <th>Lanes Quick View</th>
                  </tr>
                </thead>
                <tbody>
                  {item.data.lanes.map((lane, i) =>
                    <tr key={i}>
                      <td>
                        {formatAddress(lane.origin)} to {formatAddress(lane.destination) || 'Anywhere'}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <label style={{ display: 'block', margin: '0 -15px' }}>Edit Lanes (City - City)</label>
              {(() => {
                const ret = [];
                for (let i = 0; i < lanes; i++) {
                  if (i > 0) {
                    ret.push(<hr />);
                  }
                  ret.push(
                    <div>
                      <Field
                        name={`lane_origin[${i}]`}
                        component={GoogleLocationInput}
                        validate={validate.compose(
                          validate.vjs.presence(),
                          validate.multipresence({
                            required: ['state'],
                          }),
                        )}
                        specificity={GoogleLocationInput.specificity.CITY}
                      />
                      <Field
                        name={`lane_destination[${i}]`}
                        component={GoogleLocationInput}
                        specificity={GoogleLocationInput.specificity.CITY}
                      />
                    </div>
                  );
                }
                return ret;
              })()}
              <div style={{ margin: '0 -15px' }}>
                <p>
                  <a
                    role='button'
                    onClick={e => {
                      e.preventDefault();
                      setLanes(lanes + 1);
                    }}
                    >
                  Add another lane
                  </a>
                </p>
                {
                  lanes > 1 ?
                    <p>
                      <a
                        role='button'
                        onClick={e => {
                          e.preventDefault();
                          setLanes(lanes - 1);
                        }}
                        >
                    Remove last lane
                      </a>
                    </p>
                    : null
                }
              </div>
            </div>
            <div>
              <label style={{ display: 'block', margin: '0 -15px' }}>Edit Lanes (State - State)</label>
              <Field
                name='lane_origin_states'
                component={MultiStateInput}
              />
              <Field
                name='lane_destination_states'
                component={MultiStateInput}
              />
            </div>
            <p style={{ margin: '0 -15px' }}>
              <a
                role='button'
                onClick={e => {
                  e.preventDefault();
                  setShowLanes(false);
                }}
                >
              Hide Lanes
              </a>
            </p>
            <div className='text-right row'>
              <button type='submit' disabled={submitting} onClick={handleSubmit} className='btn btn-orange'>Update</button>
            </div>
          </div>
          :
          <p>
            <a
              role='button'
              onClick={e => {
                e.preventDefault();
                setShowLanes(true);
              }}
              >
            Show Lanes
            </a>
          </p>
      }
    </div>
    <div className='row'>
      <Header>User Outreach Status</Header>
      <Field
        name='contact_status'
        component={LabeledDropdown}
        data={(() => {
          const options = [
            { text: 'No Contact', value: 'no_contact' },
            { text: 'First Contact', value: 'first' },
            { text: 'Second Contact', value: 'second' },
            { text: 'Third Contact', value: 'third' },
          ];
          const index_of_current_option = options.findIndex(({ value }) => value === item.data.contact_status);
          if (index_of_current_option === 0) {
            return options.slice(0, 2);
          }
          if (index_of_current_option === options.length - 1) {
            return options.slice(-1);
          }
          return options.slice(index_of_current_option, index_of_current_option + 2);
        })()}
        label='Contact Status'

      />
      <div className='text-right'>
        <button type='submit'style={{ backgroundColor: '#e86b00', height: '35px', color: 'white' }} disabled={submitting} onClick={handleSubmit}>
          <MaterialIcon name='save' />
          {' '}
           Update Status</button>
      </div>
    </div>
  </div>
);
