import UrlAssembler from 'url-assembler';

import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import loadResource from 'actions/resource/load';


/**
 * Our Load by User ControlledPaginatedResourceCollection instance
 */
export const dispatchedloadByUserResource = new ControlledPaginatedResourceCollection('user', 'dispatchedload', {
  url: new UrlAssembler('load/assigned'),
  paginatedResourceOptions: {
    url: new UrlAssembler('load/assigned'),
    baseResource: loadResource,
  },
});

export default dispatchedloadByUserResource;
export const {
  fetch,
  fetchNext,
  create,
} = dispatchedloadByUserResource;
