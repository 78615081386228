/**
 * Components that display a receipt for a subscription in a pop-up
 * @TODO this needs to be finished once the data to retrieve previous subscription data is available
 * @module components/pure/Receipt
 * @since 3.0.0
 * @property {object} props
 * @property {string} props.plan - the plan to show a receipt for
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import formatPennies from 'helpers/formatPennies';


const Address = () =>
  <address>
    235 East Broadway<br />
    Suite 940<br />
    Long Beach, CA 90802<br />
    US (888) 633-5558
  </address>
  ;

const InfoBlock = ({ title, children }) =>
  <div>
    <div className='row' style={{ paddingBottom: 10, fontSize: '1.1em' }}>
      <div className='col-xs-12' style={{ backgroundColor: colors.BORDER_GRAY, color: colors.WHITE }}>
        {title}
      </div>
    </div>
    <div>{children}</div>
  </div>
  ;

const Receipt = props =>
  <html lang='en'>
    <head>
      <title>Your Receipt with HaulPay</title>
      <link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/3.3.7/css/bootstrap.min.css' integrity='sha384-BVYiiSIFeK1dGmJRAkycuHAHRg32OmUcww7on3RYdg4Va+PmSTsz/K68vbdEjh4u' crossOrigin='anonymous' />
      <style
        dangerouslySetInnerHTML={{
          __html: `
            .table {
              font-size: 14px;
            }
            .table>tbody>tr>td {
              border: 0;
            }
            .container, .col-xs-12 {
              padding-left: 5px;
              padding-right: 5px;
            }
            .row {
              margin-left: -5px;
              margin-right: -5px;
            }
          `,
        }}
      />
    </head>
    <body>
      <div className='container'>
        <div
          className='text-right hidden-print'
          dangerouslySetInnerHTML={{
            __html: '<a onclick="print()">Print</a>',
          }}
        />
        <h3>ComFreight.com, LLC</h3>
        <Address />
        <InfoBlock title='Order Information'>
          <p>Description: {props.statement_description}</p>
        </InfoBlock>
        <hr />
        <div>
          <b>Billing Information</b>
          <br />
          {
            [
              'address_city',
              'address_country',
              'address_line1',
              'address_line2',
              'address_state',
              'address_zip',
              'country',
            ]
              .filter(key => props.card[key])
              .map(key => <div key={key}>{props.card[key]}</div>)
          }
          <div>{props.card.type} XXXX-XXXX-XXXX-{props.card.last4}</div>
        </div>
        <hr />
        <div className='clearfix'>
          <table className='table table-condensed pull-right' style={{ maxWidth: 300 }}>
            <tbody>
              <tr>
                <td className='text-right'><b>Total:</b></td>
                <td className='text-right'><b>{formatPennies(props.amount)}</b></td>
              </tr>
            </tbody>
          </table>
        </div>
        <InfoBlock title='Payment Information'>
          <table className='table table-condensed' style={{ tableLayout: 'fixed' }}>
            <tbody>
              <tr>
                <td>Date/Time:</td>
                <td>{moment(props.created, 'X').format('L LTS')} UTC</td>
              </tr>
              <tr>
                <td>Payment Method:</td>
                <td>{props.card.type} XXXX-XXXX-XXXX-{props.card.last4}</td>
              </tr>
            </tbody>
          </table>
        </InfoBlock>
      </div>
    </body>
  </html>
  ;

Receipt.propTypes = {
  statement_description: PropTypes.string.isRequired,
  card: PropTypes.shape({
    address_city: PropTypes.string,
    address_country: PropTypes.string,
    address_line1: PropTypes.string,
    address_line2: PropTypes.string,
    address_state: PropTypes.string,
    address_zip: PropTypes.string,
    country: PropTypes.string,
    type: PropTypes.string.isRequired,
    last4: PropTypes.string.isRequired,
  }).isRequired,
  amount: PropTypes.number.isRequired,
  created: PropTypes.instanceOf(moment).isRequired,
};

export default Receipt;
