import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { closeModal } from 'actions/ui';

import FactoringClientDebtorRelationAuditLog from 'components/container/FactoringClientDebtorRelationAuditLog';

const FactoringClientDebtorRelationAuditLogM = compose(
  connect(
    null,
    {
      closeModal,
    },
  ),
)(({ closeModal, id }) =>
  <div>
    <div className='modal-header'>
      <button type='button' className='close' aria-label='Close' onClick={closeModal}><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        Client Debtor Relationship Audit Log
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      <FactoringClientDebtorRelationAuditLog id={id} />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Cancel</button>
    </div>
  </div>
);

export default FactoringClientDebtorRelationAuditLogM;
