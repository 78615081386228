import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.user.onboarddebtor;

import onboarddebtorByUserResource from 'actions/resourceBy/user/onboarddebtor';


export default function onboarddebtorByUserResourceReducer(state = initialState, action) {
  state = onboarddebtorByUserResource.reduce(state, action);
  return state;
}
