/**
 * Connected modal component for hiding a user
 * @module components/pure/modals/ConfirmHideUser
 * @since 3.0.0
 * @requires actions/hide
 * @requires components/hoc/Modal
 * @requires components/pure/modals/Warning
 * @requires components/pure/PromiseButton
 * @property {object} props
 * @property {string} props.id - the ID of the user to hide
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { hideUser } from 'actions/hide';

import Modal from 'components/hoc/Modal';
import PromiseButton from 'components/pure/PromiseButton';
import ModalWarning from './Warning';


const ConfirmHideUser = compose(
  Modal,
  connect(
    null,
    (dispatch, ownProps) => ({
      hideUser: () => dispatch(hideUser(ownProps.id)),
    }),
  ),
  mapProps(({ hideUser, id, ...rest }) => ({
    ...rest,
    headerChildren: 'Confirm',
    message: (
      <div>
        <p>Are you sure you want to block all posts by this user?</p>
        <p>You can manage blocked users from Settings under the Account section.</p>
      </div>
    ),
    buttonChildren: 'Cancel',
    extraButtons: [
      <PromiseButton className='btn btn-danger' onClick={hideUser}>Block</PromiseButton>,
    ],
  })),
)(ModalWarning);

ConfirmHideUser.propTypes = {
  id: PropTypes.string.isRequired,
};

export default ConfirmHideUser;
