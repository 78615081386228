import { compact, flatMap, get, includes, map, memoize } from 'lodash';

const isDispatchBroker = state => {
  const groups = get(state, 'resource.factoringGroups', []);
  const roles = compact(flatMap(groups, group => get(group, 'data.roles'), []));
  const roleNames = compact(map(roles, role => get(role, 'name', '')));

  return includes(roleNames, 'dispatch_broker_admin');
};

export default memoize(isDispatchBroker);
