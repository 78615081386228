/**
 * Reducer for handling the admin leads actions
 * @module reducers/admin/leads
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/leads
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.leads;

import leadsResource from 'actions/admin/leads';


export default function leadsReducer(state = initialState, action) {
  state = leadsResource.reduce(state, action);
  return state;
}
