import React, { useContext } from 'react';
import AddMoneyButton from './components/AddMoneyButton';
import WalletBalanceCard from './components/WalletBalanceCard';
import WalletHistoryTable from './components/WalletHistoryTable';
import WalletPendingTransactions from './components/WalletPendingTransactions';
import Header from 'components/pure/Header';
import TransactionHistoryReport from '../components/TransactionHistoryReport';
import WalletContext from '../context/WalletContext';
import Tabs from '../../../pure/Tab';

export default function Wallet(props) {
    const { fetchBrokerWalletPendingTransaction } = useContext(WalletContext);
    return (
        <div>
            <div className="col-xs-12 clearfix" style={{ margin: 10 }}>
                <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'flex-end' }}>
                    <AddMoneyButton fetchWalletPendingTransaction={fetchBrokerWalletPendingTransaction} />
                </div>
            </div>
            <div className="col-xs-12 clearfix" style={{ margin: 10 }}>
                <div style={{ display: 'flex', flex: 1, alignContent: 'center', justifyContent: 'center' }}>
                    <WalletBalanceCard />
                </div>
            </div>
            <Tabs
                tabs={[
                    { title: 'Pending Transactions', component: WalletPendingTransactions },
                    { title: 'Statement History', component: WalletHistoryTable }
                ]}
            />
        </div>
    );
}
