/**
 * Actions relating to factoringdebtor search
 * @module actions/search/factoringdebtor
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringdebtor
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import factoringlineitemResource from 'actions/resource/factoringlineitem';


/**
 * Our factoringdebtor search [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const factoringlineitemSearchPaginatedResource = new PaginatedResource('search_factoringlineitem', {
  mountPoint: ['search', 'factoringlineitem'],
  url: new UrlAssembler('admin/items'),
  baseResource: factoringlineitemResource,
});

export default factoringlineitemSearchPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = factoringlineitemSearchPaginatedResource;
