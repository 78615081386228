import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';

import { openModal } from 'actions/ui';


export default compose(
  connect(
    null,
    (dispatch, { id, type }) => {
      return {
        onClick() {
          return dispatch(openModal('confirm', {
            type: 'refresh_post',
            args: [{ id, type }],
          }));
        },
      };
    }
  ),
)(props =>
  <button
    {...props}
    className={cs('btn btn-default', props.className)}
    >
    {props.children || 'Refresh'}
  </button>
);
