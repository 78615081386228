/**
 * Root reducer for the resource by factoringdebtor paginated resource collection reducers
 * @module reducers/resourceBy/factoringdebtor
 * @since 3.0.0
 * @requires reducers/resourceBy/factoringdebtor/factoringdebtornote
 */
import { combineReducers } from 'redux-v3';

import factoringdebtornote from './factoringdebtornote';
import factoringclient from './factoringclient';
import similarfactoringdebtor from './similarfactoringdebtor';
import childrenfactoringdebtor from './childrenfactoringdebtor';
import lineitemsdebtor from './lineitemsdebtor';

export default combineReducers({
  factoringdebtornote,
  factoringclient,
  similarfactoringdebtor,
  childrenfactoringdebtor,
  lineitemsdebtor,
});
