/**
 * Component for displaying a multiselect input whose items abbreviate themselves after a given amount have been selected
 * @module components/pure/form/inputs/AbbreviatedMultiSelect
 * @since 3.0.0
 * @property {object} props
 * @property {object[]} props.data - the items to be available in the multiselect
 * @property {string} [props.abbreviationKey = 'code'] - the key in props.data to use as both the abbreviated text in the view and the value included in the array of values for this input
 * @property {string} [props.fulltextKey = 'fullName'] - the key in props.data to use as the full-text text in the view
 * @property {string[]} [props.value] - manually set a value
 * @property {node} [props.placeholder] - a placeholder to display in the text input
 * @property {number} [props.breakPoint] - the point at which selected items display their abbreviated versions instead of their full-text versions
 * @property {boolean} [props.sensitive] - whether the filtering for the suggestion box should be case sensitive if not
 * @property {any} [props....rest] - props passed to the [MultiSelect]{@link https://jquense.github.io/react-widgets/docs/#/multiselect} component
 */
import React from 'react';
import PropTypes from 'prop-types';
import Multiselect from 'react-widgets/lib/Multiselect';
import noop from 'lodash/noop';


class AbbreviatedMultiSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    this.onFocus = this.createHandler('onFocus', true);
    this.onBlur = this.createHandler('onBlur', false);
    this.onSearch = this.createHandler('onSearch', true);
    this.onClick = this.createHandler('onClick', true);
  }

  setOpen(open) {
    if (this.props.disabled) {
      return;
    }
    if (this.state.open === open) {
      return;
    }
    this.setState({ open: open });
  }

  createHandler(funcName, setOpenValue) {
    const handler = function handler(e) {
      this.setOpen(setOpenValue);
      if (typeof this.props[funcName] === 'function') {
        this.props[funcName](e);
      }
    };
    return handler.bind(this);
  }

  render() {
    const { data, abbreviationKey, fulltextKey, value, placeholder = '', breakPoint = 4, sensitive = false, onChange, ...rest } = this.props;
    const { open } = this.state;
    return (
      <Multiselect
        data={data}
        placeholder={placeholder}
        valueField={abbreviationKey || ''}
        textField={fulltextKey || ''}
        tagComponent={fulltextKey ? props => {
          const key = (value && value.length > breakPoint) ? abbreviationKey : fulltextKey;
          return (
            <span>{props.item[key]}</span>
          );
        } : undefined}
        filter={sensitive ?
          (item, val) => item[abbreviationKey].startsWith(val) || item[fulltextKey].startsWith(val) :
          (item, val) => item[abbreviationKey].toLowerCase().startsWith(val.toLowerCase()) || item[fulltextKey].toLowerCase().startsWith(val.toLowerCase())
        }
        onChange={(...data) => {
          onChange(...data);
          this.setState({ open: false });
        }}
        {...rest}
        open={open}
        onToggle={noop}
        onClick={this.onClick}
        onSearch={this.onSearch}
        onSelect={this.onBlur}
        onFocus={this.onFocus}
        onBlur={this.onBlur}
        value={value || []}
      />
    );
  }
}

AbbreviatedMultiSelect.propTypes = {
  data: PropTypes.array.isRequired,
  abbreviationKey: PropTypes.string,
  fulltextKey: PropTypes.string,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,

  placeholder: PropTypes.string,
  breakPoint: PropTypes.number,
  sensitive: PropTypes.bool,
};

export default AbbreviatedMultiSelect;
