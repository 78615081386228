import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import lifecycle from 'recompose/lifecycle';
import moment from 'moment';
import get from 'lodash/get';

import { USER_TYPE } from 'helpers';
import USER_FACTORING_STATUS from 'helpers/USER_FACTORING_STATUS';
import { all_packages_by_name } from '../../stripe';

import { openModal } from 'actions/ui';
import { fetchFactoringProfile, getSubscriptionStatus } from 'actions/user';
import getSelf from 'selectors/getSelf';
import { CenteredSpinner } from 'components/pure/Spinner';
import Header from 'components/pure/Header';
import BillingPackageSelection from 'components/pure/BillingPackageSelection';
import SettingsBillingUpgradeSubscriptionButton from 'components/container/SettingsBillingUpgradeSubscriptionButton';
import SettingsBillingUpdateSubscriptionButton from 'components/container/SettingsBillingUpdateSubscriptionButton';
import SettingsBillingHistory from 'components/container/SettingsBillingHistory';
import BackgroundHeader from 'components/pure/BackgroundHeader';
import SettingsBillingSubscriptionForm from 'components/pure/form/SettingsBillingSubscriptionForm';
import FullAccessBenefits from 'components/pure/FullAccessBenefits';
import CurrentOrderSummary from 'components/container/CurrentOrderSummary';


export default compose(
  connect(
    state => {
      const self = getSelf(state);
      const { subscription, factoring } = state.user;
      const isFetching = subscription.isFetching || factoring.isFetching || self.isFetching;
      return {
        self,
        factoring: factoring.data,
        subscription: subscription.data,
        isFetching,
      };
    },
    {
      openModal,
      getSubscriptionStatus,
      fetchFactoringProfile,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.getSubscriptionStatus();
      this.props.fetchFactoringProfile();
    },
  }),
)(({ self, subscription, factoring, isFetching, openModal }) => {
  if (isFetching) {
    return (
      <CenteredSpinner />
    );
  }
  const userType = self.data.type;
  // legacy page
  if (self.data.external === 'legacy' && self.data.type !== 'shipper') {
    return (
      <div>
        <Header>Subscription</Header>
        <button onClick={() => openModal('legacydeactivate')} className='btn btn-block btn-danger'>DEACTIVATE ACCOUNT</button>
        <p>If you have more questions please email us at <a href='mailto:support@haulpay.io'>support@haulpay.io</a>.</p>
      </div>
    );
  }
  // inactive subscription (not recurring anymore)
  const is_inactive = (
    !subscription ||
    subscription.cancel_at_period_end ||
    subscription.ended_at ||
    subscription.status === 'past_due' ||
    subscription.status === 'canceled' ||
    subscription.status === 'unpaid' ||
    moment(subscription.current_period_end).isBefore(moment())
  );
  if (is_inactive) {
    let messages = null;
    if (subscription) {
      messages = [];
      if (subscription.canceled_at) {
        messages.push(<p>Your subscription was canceled at {moment(subscription.canceled_at).format('L LTS')}</p>);
      }
      if (subscription.status === 'past_due' || subscription.status === 'unpaid') {
        messages.push(<p>The billing for your subscription previously failed.</p>);
      }
      if (subscription.canceled_at) {
        messages.push(<p>Your full access {subscription.ended_at || moment(subscription.current_period_end).isBefore(moment()) ? 'ended' : 'will end'} on {moment(subscription.current_period_end).format('L LTS')}</p>);
      }
      if (subscription.ended_at || subscription.status === 'past_due' || subscription.status === 'canceled' || subscription.status === 'unpaid' || moment(subscription.current_period_end).isBefore(moment())) {
        messages.push(<p>To regain full access, please resubscribe below.</p>);
      }
      else {
        messages.push(<p>If you resubscribe now, your next charge will be on {moment(subscription.current_period_end).add(1, 'second').format('L LTS')}</p>);
      }
      messages.push(<p>Thank you.</p>);
    }
    return (
      <div>
        {
          messages ?
            <div>{React.createElement('strong', null, ...messages)}</div>
            : null
        }
        {
          factoring && factoring.factoring_approved === USER_FACTORING_STATUS.APPROVED ?
            <div>
              <BackgroundHeader>Full Access</BackgroundHeader>
              <p>Because you are a Haul Pay Factoring user you have full access for free. Thank you!</p>
            </div>
            :
            <div className='row clearfix'>
              <div className='col-xs-12 col-sm-8'>
                <BackgroundHeader className='text-capitalize' style={{ paddingRight: 15 }}>
                  <span>Full Access</span>
                  <button className='visible-xs-inline-block btn btn-orange pull-right' onClick={() => openModal('fullaccessbenefits', { type: userType })}>View Benefits</button>
                </BackgroundHeader>
                <SettingsBillingSubscriptionForm userType={userType} />
                {
                  userType === USER_TYPE.CARRIER ?
                    <div className='clearfix'>
                      <hr />
                      <p>
                    An alternative to subscribing for a paid subscription is to join our Haul Pay digital factoring service. Once approved for Haul Pay you will have full access to all software, load boards and additional tools like credit checking, instant invoice payment advances and more.
                        <Link to='/haul-pay'>
                          <button className='btn btn-success pull-right'>Join Haul Pay Instead</button>
                        </Link>
                      </p>
                    </div>
                    : null
                }
                <FullAccessBenefits className='hidden-xs' type={userType} />
              </div>
              <div className='hidden-xs col-xs-12 col-sm-4'>
                <div style={{ margin: '1em 0' }}>
                  <CurrentOrderSummary />
                </div>
              </div>
            </div>
        }
        {
          subscription && subscription.history && subscription.history.length ?
            <div className='row clearfix'>
              <SettingsBillingHistory />
            </div>
            : null
        }
      </div>
    );
  }
  // active subscription
  return (
    <div>
      <Header>Current Plan</Header>
      <div style={{ maxWidth: 400 }}>
        <BillingPackageSelection {...all_packages_by_name[subscription.plan]} active={true} balloon={undefined} />
        {(() => {
          const current_plan_upgrades_to = get(all_packages_by_name, [subscription.plan, 'upgrades_to_plan']);
          const upgrade_to_plan = all_packages_by_name[current_plan_upgrades_to];
          if (upgrade_to_plan) {
            return <SettingsBillingUpgradeSubscriptionButton to={upgrade_to_plan} />;
          }
          return null;
        })()}
        <SettingsBillingUpdateSubscriptionButton />
        <button className='btn btn-danger btn-block' onClick={() => openModal('confirmcancelaccount')}>Deactivate My Account</button>
      </div>
      <SettingsBillingHistory />
    </div>
  );
});
