import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import { openModal } from 'actions/ui';

import RateStars from 'components/pure/RateStars';


export default compose(
  connect(
    (state, { user_id, truck_id, load_id, useBid }) => {
      let rating = null;
      if (truck_id) {
        const truck = state.resource.truck[truck_id] || state.resource.trucksearch[truck_id];
        rating = truck.data.average_rating;
      }
      else if (load_id) {
        const load = state.resource.load[load_id] || state.resource.loadsearch[load_id];
        if (useBid) {
          rating = (load.data.bids.find(bid => bid.id === useBid) || {}).average_rating;
        }
        else {
          rating = load.data.average_rating;
        }
      }
      const is_self = user_id === state.user.id;
      return { rating, user_id, is_self };
    },
    (dispatch, { openRateModal, user_id }) => {
      if (openRateModal) {
        return {
          openRateModal() {
            return dispatch(openModal('userrate', { user_id }));
          },
        };
      }
      return {};
    },
  ),
)(({ rating, user_id, is_self, openRateModal }) => {
  if (is_self) {
    return null;
  }

  let text = <div>Rate This User.</div>;
  if (user_id) {
    if (openRateModal) {
      text = (
        <a role='button' onClick={openRateModal}>{text}</a>
      );
    }
    else {
      text = (
        <a href={`/user/${user_id}`} target='_blank' rel='noopener'>{text}</a>
      );
    }
  }

  return (
    <div>
      {
        !rating && rating !== 0 ? null :
        <RateStars
          value={rating}
          color='#FFA500'
          style={{
              padding: 0,
              textAlign: 'left',
            }}
        />
      }
      {text}
    </div>
  );
});
