/**
 * Component that displays a tbody section in a table
 * @module components/pure/TableItem
 * @since 3.0.0
 * @property {object} props
 * @property {node[][]} props.rows - an array of an array of nodes to transform into table rows and cells
 * @property {object} [props.addons] - an array of table row addons, a node that will span the entire column
 * @property {any} [props....rest] - props passed to [Table]{@link module:components/pure/Table} component
 */
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';

const TableItem = ({ rows, addons, children, selected, selectable, onSelect, isBlockItem, ...rest }) => {
  if (!rows) {
    rows = children;
  }
  let maxCells = 1;
  return (
    <tbody {...rest}>
      {selectable && isBlockItem && (
        <tr>
          <td colSpan={get(rows, '[0].length', 1)}>
            <CheckboxInput input={{ value: selected, onChange: onSelect }} />
          </td>
        </tr>
      )}
      {rows.map((row, i) => {
        if (row.length > maxCells) {
          maxCells = row.length;
        }
        return (
          <tr key={i}>
            {selectable && !isBlockItem && (
              <td>
                <CheckboxInput input={{ value: selected, onChange: onSelect }} />
              </td>
            )}
            {row.map((cell, i) =>
              <td key={i} style={{ width: `${1 / row.length * 100}%` }}>{cell}</td>
            )}
          </tr>
        );
      })}
      {addons && addons.map((addon, i) =>
        <tr key={i} className='table-item-addon'><td colSpan={maxCells}>{addon}</td></tr>
      )}
    </tbody>
  );
};

TableItem.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.node)).isRequired,
};

export default TableItem;
