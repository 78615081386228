import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';
import { browserHistory, withRouter } from 'react-router-v4';
import validate from 'validate.js';
import mapProps from 'recompose/mapProps';
import lifecycle from 'recompose/lifecycle';

import { resetPasswordConfirm } from 'actions/user';
import { openModal } from 'actions/ui';

import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export default compose(
  withRouter,
  lifecycle({
    componentWillMount() {
      const { router, location: { query: { token } } } = this.props;
      if (!token) {
        router.replace('/resetpassword');
      }
    },
  }),
  mapProps(({ location: { query: { token } } }) => {
    return {
      initialValues: { token },
    };
  }),
  reduxForm({
    form: 'ResetPasswordConfirmForm',
    async onSubmit(fields, dispatch) {
      try {
        const json = await resetPasswordConfirm({
          new_password: fields.password,
          reset_password_token: fields.token,
        });

        if (json && json.status === 'success') {
          dispatch(openModal('success', { message: 'Successfully reset password' }));
          browserHistory.push('/login');
          return;
        }

        let message;
        if (json && json.message) {
          message = json.message;
        }
        else {
          message = 'Couldn\'t reset your password. Unknown error';
          console.warn('Unknown response', json);
        }
        return dispatch(openModal('error', { message }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to reset password' }));
      }
    },
    validate(fields) {
      return validate(fields, {
        token: {
          presence: true,
        },
        password: {
          presence: true,
          length: {
            minimum: 4,
            maximum: 30,
          },
        },
        password_confirm: {
          presence: true,
          equality: 'password',
        },
      });
    },
  }),
)(({ handleSubmit }) =>
  <div className='login'>
    <Header>Reset Password</Header>
    <Field name='token' component={LabeledInput} label='Token' containerProps={{ className: 'hidden' }} />
    <Field name='password' component={LabeledInput} label='Password' type='password' />
    <Field name='password_confirm' component={LabeledInput} label='Password Confirm' type='password' />
    <div>
      <button onClick={handleSubmit} className='btn btn-orange'>Reset Password</button>
    </div>
  </div>
);
