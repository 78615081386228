/**
 * Displays a header with a gray background
 * @module components/pure/BackgroundHeader
 * @since 3.0.0
 * @requires components/pure/Header
 * @property {object} props - see [Header]{@link module:components/pure/Header}
 */
import colors from 'styles/colors.json';
import React from 'react';

import Header from './Header';

const style = {
  padding: '0.25em 0',
  paddingLeft: 15,
  backgroundColor: colors.BACKGROUND_GRAY,
};

export default ({ children, ...rest }) =>
  <Header
    {...rest}
    style={{
      ...style,
      ...rest.style,
    }}
    >
    {children}
  </Header>
;
