import React from 'react';
import { USER_TYPE } from 'helpers';
import Spinner from 'components/pure/Spinner';

const disable_set_status = status => {
  return ['paid'].includes(status);
};

export default ({ submitting, userType, path, handleSubmit }) => {
  if (userType === USER_TYPE.FACTORING_REMOTE_ADMIN || disable_set_status(path)) {
    return null;
  }
  return (
    <button className='btn-payment' disabled={submitting} type='submit' onClick={handleSubmit}>
      {
        submitting ? <span><Spinner />PROCESSING</span> : 'Set Status'
      }
    </button>
  );

};
