/**
 * Component to display a fancy hamburger button. Has active and inactive states.
 * @module components/pure/Hamburger
 * @since 3.0.0
 * @requires styles/Hamburger
 * @property {object} props
 * @property {boolean} [props.active] - whether to display the hamburger button in its active state or not
 * @property {any} [props....rest] - props passed to containing div component
 */
import 'styles/Hamburger';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


export const Hamburger = ({ active, ...rest }) =>
  <div {...rest} className={cs('Hamburger', { active }, rest.className)} role='button'>
    <span>toggle menu</span>
  </div>
  ;

Hamburger.propTypes = {
  active: PropTypes.bool,
};

export default Hamburger;
