import UrlAssembler from 'url-assembler';

import PaginatedResourceCollection from 'datatypes/PaginatedResourceCollection';
import factoringdebtor from 'actions/resource/factoringdebtor';


export const lineitemsdebtorByFactoringdebtorResource = new PaginatedResourceCollection('lineitemsdebtor', 'factoringdebtor', {
  url: new UrlAssembler('funding_requests/:id/items'),
  paginatedResourceOptions: {
    baseResource: factoringdebtor,
  },
});

export default lineitemsdebtorByFactoringdebtorResource;
export const {
  fetch,
  fetchNext,
} = lineitemsdebtorByFactoringdebtorResource;
