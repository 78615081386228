/**
 * Generic class for paginated-resource-related fetching and serialization
 * @module datatypes/PaginatedResource
 * @since 3.0.0
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 */
/*global API */
import get from 'lodash/get';

import APIFetch from './APIFetch';
import FetchError from './FetchError';

/**
 * PaginatedResource class
 */
class PaginatedResource {

  /**
   * Create a PaginatedResource
   * @param {string} name - the unique identifier for this PaginatedResource
   * @param {object} options
   * @param {string} options.mountPoint - where in the overall state to find this PaginatedResource's state
   * @param {UrlAssembler} options.url - the UrlAssembler object for this PaginatedResource's endpoint
   * @param {number} [options.acceptableStatusCode] - the HTTP status code that the PaginatedResource uses to confirm a successful fetch request
   * @param {string} [options.itemsKey] - the property in this PaginatedResource's state under which to store the list of IDs of Resources
   * @param {module:datatypes/Resource~Resource} [options.baseResource] - the Resource that this PaginatedResource is a list of
   * @param {function} [options.belongsInCollection] - a function that determines whether, on fetching the Resource specified by options.baseResource, to append that Resource to this PaginatedResource. This function is passed the fetched data in its first argument.
   * @param {object} [options.globals] - any globals/meta/miscellaneous information to be tied to this Resource in state
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, options = {}) {

    if (typeof options.mountPoint !== 'string' && !Array.isArray(options.mountPoint)) {
      throw new TypeError(`${this.constructor.name} ${name} requires a mount point, got: ${options.mountPoint}`);
    }
    if (typeof options.url !== 'object') {
      throw new TypeError(`${this.constructor.name} ${name} requires a url, got: ${options.url}`);
    }

    this.name = name;
    this.options = {
      acceptableStatusCode: 200,
      itemsKey: 'ids',
      globals: {},
      belongsInCollection: () => true,
      extraBulkCreateEvents: [],
      ...options,
    };

    const upperName = this.name.toUpperCase();
    this.actions = {
      FETCH_REQUEST: Symbol(`${upperName}_PAGINATED_FETCH_REQUEST`),
      FETCH_REQUEST_SUCCESS: Symbol(`${upperName}_PAGINATED_FETCH_REQUEST_SUCCESS`),
      FETCH_REQUEST_FAILURE: Symbol(`${upperName}_PAGINATED_FETCH_REQUEST_FAILURE`),
      CLEAR: Symbol(`${upperName}_PAGINATED_CLEAR`),
      CLEAR_PAGE: Symbol(`${upperName}_PAGINATED_CLEAR_PAGE`),
      SET_SORT: Symbol(`${upperName}_PAGINATED_SET_SORT`),
      MANUAL_SORT: Symbol(`${upperName}_MANUAL_SORT`),
    };

    this.controller = new AbortController();
    this.fetchNext = this.fetchNext.bind(this);
    this.fetchPage = this.fetchPage.bind(this);
    this.fetch = this.fetch.bind(this);
    this.sort = this.sort.bind(this);
    this.clear = this.clear.bind(this);
    this.abort = this.abort.bind(this);
  }

  // ACTIONS
  /**
   * Fetch request action. Marks that a request to fetch the PaginatedResource with given parameters has been made
   * @event module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @property {symbol} type - Symbol({NAME}_PAGINATED_FETCH_REQUEST)
   * @property {object} payload - the parameters specified for the PaginatedResource
   */
  /**
   * Fetch request success action. Marks that a request to fetch the PaginatedResource has been made successfully
   * @event module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_PAGINATED_FETCH_REQUEST_SUCCESS)
   * @property {object} payload - the data fetched from the server
   */
  /**
   * Fetch request failure action. Marks that a request to fetch the PaginatedResource has failed
   * @event module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_PAGINATED_FETCH_REQUEST_FAILURE)
   * @property {any} payload - the error that occurred
   */
  /**
   * Sort set action. Sets the property on which to include in the fetch request as the `ordering` url parameter
   * @event module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
   * @property {symbol} type - Symbol({NAME}_PAGINATED_SET_SORT)
   * @property {string} payload - the new property on which to sort
   */
  /**
   * Clear action. Removes references to all Resources stored in this PaginatedResource's store
   * @event module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
   * @property {symbol} type - Symbol({NAME}_PAGINATED_CLEAR)
   */
  clear() {
    return {
      type: this.actions.CLEAR,
      payload: undefined,
    };
  }

  /**
   * @param {string} ordering - the new property on which to sort
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching this PaginatedResource, or an error
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   */
  sort(ordering) {
    return (dispatch, getState) => {
      const { parameters } = this.getState(getState());
      dispatch({
        type: this.actions.SET_SORT,
        payload: ordering,
      });
      dispatch(this.clear());
      return dispatch(this.fetch(parameters));
    };
  }

  /**
   * @returns {Promise<void | Action>} A promise that resolves in `undefined`, if this PaginatedResource has no more to fetch, or the action/error returned from module:datatypes/PaginatedResource~PaginatedResource#fetch
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   */
  fetchNext() {
    return (dispatch, getState) => {
      const { next, parameters } = this.getState(getState());
      if (next === null) {
        return Promise.resolve();
      }
      return dispatch(this.fetch(parameters, next));
    };
  }

  fetchPage(pageIdx = 0) {
    return (dispatch, getState) => {
      const { parameters, limit } = this.getState(getState());

      const page = Number.isFinite(Number(pageIdx)) ? Number(pageIdx) : 0;

      return dispatch(this.fetch({
        ...parameters,
        offset: page * limit,
      }, undefined, { clearAfterFetch: true, isPageRequest: true }));
    };
  }

  /**
   * @returns {Promise<void | Action>}
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   */
  fetchAll() {
    return (dispatch, getState) => {
      const state = this.getState(getState());
      if (state.isFetching) {
        return Promise.resolve();
      }
      dispatch(this.clear());
      return dispatch(this.fetch())
        .then(() => {
          const keepFetching = () => {
            return dispatch(this.fetchNext())
              .then(() => {
                const state = this.getState(getState());
                if (state.next !== null) {
                  return keepFetching();
                }
              })
              ; // eslint-disable-line indent
          };
          return keepFetching();
        });
    };
  }

  /**
   * @param {object} data - information to pass in the query parameters for this fetch request
   * @param {string} next - the url for the next chunk of Resources returned by the PaginatedResource URL. Typically provided to us from the backend, and kept in the store for this PaginatedResource under the `next` key
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching this PaginatedResource, or an error
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @fires module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   */
  fetch({ token = '', ...data } = {}, next, { clearAfterFetch = false, isPageRequest = false } = {}) {
    return async (dispatch, getState) => {
      const token = data.token;
      delete data.token;
      const state = getState();
      const mystate = this.getState(state);
      if (mystate.isFetching) {
        return Promise.resolve();
      }

      if (next === undefined) {
        const { ordering, limit } = mystate;
        next = decodeURI(`${API.host}/${this.options.url.segment('/').param({
          limit,
          ordering,
          ...data,
        }).toString()}`);
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: data,
      });

      try {
        this.controller = new AbortController();
        const { signal } = this.controller;
        const res = await dispatch(APIFetch(next, {
          signal,
          headers: {
            Authorization: `Bearer ${state.user.token || token}`,
            'Content-Type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: json,
          options: {
            clearAfterFetch,
            isPageRequest,
          },
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.FETCH_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  abort() {
    return this.controller.abort();
  }

  // REDUCER
  /**
   * @param {object} state - the overall state
   * @returns {object} the state for this PaginatedResource
   */
  getState(state) {
    const mystate = get(state, this.options.mountPoint);
    if (mystate === undefined) {
      return this.getDefaultState();
    }
    return mystate;
  }

  /**
   * @returns The default initial state for this PaginatedResource
   */
  getDefaultState() {
    return {
      isFetching: false,
      parameters: {},
      ordering: '-time_posted',
      limit: 20,
      next: null,
      count: null,
      globals: { ...this.options.globals },
      err: undefined,
      [this.options.itemsKey]: [],
    };
  }

  /**
   * Gets the data that's put into the list of items under this PaginatedResource's store's `options.itemsKey` that references a Resource
   * @param {object} item - the Resource for this PaginatedResource
   * @returns The parsed new value for this Resource
   */
  parse(item) {
    return item.id;
  }

  /**
   * The reducer for this PaginatedResource. Manages state changes triggered by actions specific to this PaginatedResource
   * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
   * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
   * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
   * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
   * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
   * @param {object} state - the current state of this PaginatedResource
   * @param {object} action - the dispatched action
   * @returns The new state for this PaginatedResource
   */
  reduce(state = this.getDefaultState(), action) {
    switch (action.type) {
      case this.actions.FETCH_REQUEST:
        return {
          ...state,
          isFetching: true,
          parameters: action.payload,
        };
      case this.actions.FETCH_REQUEST_SUCCESS: {
        let { next, count, results } = action.payload;
        const { clearAfterFetch, isPageRequest } = (action.options || {});
        if (!results && Array.isArray(action.payload)) {
          results = action.payload;
        }

        const prevItems = clearAfterFetch ? [] : state[this.options.itemsKey];
        const new_items = [
          ...prevItems,
          ...results.map(this.parse).reduce((acc, curr) => {
            if (prevItems.includes(curr)) {
              return acc;
            }
            acc.push(curr);
            return acc;
          }, []),
        ];
        // Sometimes the backend will send us duplicates, resulting in an incorrect 'total' count. Compensate for that here.
        if (!isPageRequest && !next && new_items.length < count) {
          count = new_items.length;
        }

        return {
          ...state,
          isFetching: false,
          next: next,
          count: count,
          [this.options.itemsKey]: new_items,
        };
      }
      case this.actions.FETCH_REQUEST_FAILURE:
        return {
          ...state,
          isFetching: false,
          err: action.payload,
        };
      case this.actions.CLEAR:
        return {
          ...state,
          parameters: {},
          next: null,
          count: null,
          [this.options.itemsKey]: [],
        };
      case this.actions.CLEAR_PAGE:
        return {
          ...state,
          [this.options.itemsKey]: [],
        };
      case this.actions.SET_SORT:
        return {
          ...state,
          ordering: action.payload,
        };
    }
    if (this.options.baseResource !== undefined) {
      if ([this.options.baseResource.actions.CREATE_BULK_REQUEST_SUCCESS, ...this.options.extraBulkCreateEvents].includes(action.type)) {
        const additions = [];
        for (const item of action.payload) {
          if (this.options.belongsInCollection(item)) {
            additions.push(this.parse(item));
          }
        }
        let new_items;
        if (this.options.append_to_top_on_create) {
          new_items = [
            ...additions,
            ...state[this.options.itemsKey],
          ];
        }
        else {
          new_items = [
            ...state[this.options.itemsKey],
            ...additions,
          ];
        }
        if (additions.length) {
          return {
            ...state,
            [this.options.itemsKey]: new_items,
          };
        }
        return state;
      }
      switch (action.type) {
        case this.options.baseResource.actions.CREATE_REQUEST_SUCCESS:
          if (this.options.belongsInCollection(action.payload)) {
            let new_items;
            if (this.options.append_to_top_on_create) {
              new_items = [
                this.parse(action.payload),
                ...state[this.options.itemsKey],
              ];
            }
            else {
              new_items = [
                ...state[this.options.itemsKey],
                this.parse(action.payload),
              ];
            }
            return {
              ...state,
              [this.options.itemsKey]: new_items,
            };
          }
          return state;
        case this.options.baseResource.actions.DELETE_REQUEST_SUCCESS: {
          const index = state[this.options.itemsKey].indexOf(action.payload.id);
          if (index !== -1) {
            return {
              ...state,
              [this.options.itemsKey]: [
                ...state[this.options.itemsKey].slice(0, index),
                ...state[this.options.itemsKey].slice(index + 1),
              ],
            };
          }
          return state;
        }
      }
    }
    return state;
  }
}

export default PaginatedResource;
