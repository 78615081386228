import React from 'react';
import RaisedButton from 'material-ui/RaisedButton';
import colors from 'styles/colors.json';
import { connect } from 'react-redux-v5';
import { openModal } from 'actions/ui';

const styles = {
    labelStyle: {
        fontSize: 14,
        textTransform: 'capitalize !important',
    },
    btn: {
        minWidth: 150
    }
}

function AddMoneyButton({ openModal, fetchWalletPendingTransaction }) {
    return (
        <RaisedButton
            label='Add Money'
            labelColor={colors.WHITE}
            backgroundColor={colors.GREEN}
            style={styles.btn}
            labelStyle={styles.labelStyle}
            onClick={() => openModal('WalletDepositReceivable', { isStripeDeposit: true, fetchWalletPendingTransaction })}
        />
    )
}

export default connect(
    () => {},
    { openModal }
)(AddMoneyButton)