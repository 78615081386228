/* global EMPTY_PLACEHOLDER fbq process */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm, reset, submit } from 'redux-form';
import { withState, lifecycle, mapProps } from 'recompose';
import { Link } from 'react-router-v4';
import APIError from 'datatypes/error/APIError';
import createTableItems from 'helpers/createTableItems';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';

import { closeModal, openModal } from 'actions/ui';
import { searchFactoringDebtors, setFactoringDebtorStatus } from 'actions/factoring';

import wrapPromisePending from 'components/hoc/wrapPromisePending';
import Header from 'components/pure/Header';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import ResponsiveTable from 'components/pure/ResponsiveTable';

import { fetchFactoringProfile } from 'actions/user';
import { updateRelationship } from 'actions/factoring';
import { factoringdebtorByfactoringclientUSER } from 'actions/resourceBy/factoringclient/factoringdebtor';

import _, { get, isEmpty, uniq, includes } from 'lodash';
import Spinner from 'components/pure/Spinner';
import FactoringClientCreditApprovalStatus from 'components/pure/FactoringClientCreditApprovalStatus';
import formatPennies from 'helpers/formatPennies';
import Tooltip from 'components/pure/Tooltip';
import AttachmentIcon from 'components/pure/AttachmentIcon';
import MaterialIcon from '../pure/MaterialIcon';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isFactoringBroker from 'selectors/isFactoringBroker';
import SendNOA from '../pure/Modals/SendNOA';
import CreditIncreaseForm, { FORM_NAME as increaseCreditFormName } from '../pure/form/CreditIncreaseForm';
import shallowEqual from 'recompose/shallowEqual';
import shouldUpdate from 'recompose/shouldUpdate';
import segmentEvents from '../../helpers/segmentEvents';

export const NOAStatus = compose(
  connect(
    state => {
      return {
        client_id: get(state.user, ['factoring', 'data', 'id']),
      };
    },
    {
      openModal,
    },
  ),
)(({ data, openModal, client_id, fetch }) => {

  if (data.noa_active) {
    return <span className='text-green' style={{ color: '#21bb00' }}>Placed (Active)</span>;
  }

  if (data.noa_sent) {
    return <span className='text-info'>Sent (Pending)</span>;
  }

  if (data.noa_requested) {
    return <span className='text-warning'>Request Sent</span>;
  }

  if (data.credit_approved === 'approved' ) {
      return <span className='text-danger noa' onClick={() =>
          openModal('confirm', {type: 'placedNOA_confirm', args: [{client_id, debtor: data.id}]}) }>
          Not Sent (click to Send)</span>;
  }

  return <span className='text-danger noa'>Not Sent</span>;
});

const originalDocumentsText =
  [<p style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5 }}>
    This debtor requires copies of Originals Documents to be mailed to them directly.
  </p>,
  <p style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5 }}>Original Documents are physical copies of your actual signed bill of lading and any receipts or other paperwork associated with the load.</p>,
  <p style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5 }}>Please mail them to the debtor directly and notify invoice@haulpay.io once you have sent them.</p>,
  ];
const scannedDocumentText =
  [<p className='text-highlight' style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5 }}>
    Debtor has indicated that they require clearly, scanned copies of the Proof of Delivery.
  </p>,
  <p className='text-highlight' style={{ fontSize: '14', fontWeight: '600', margin: '0px 10px', paddingTop: 5 }}>Pictures images will not be accepted.</p>,
  ];

const ViewHistory = ({ id, isBroker }) => (
  <b>
    <Link
      style={{
        color: '#ff7800',
      }}
      to={isBroker ? `/haul-pay/transaction/history/customer/${id}` : `/haul-pay/getpaid/history/customer/${id}`}
    >
      View History
    </Link>
  </b>
);

const AddCustomer = ({ onClick }) => (
  <b>
    <Link
      style={{
        color: '#ff7800',
      }}
      onClick={onClick}
    >
      + Add Customer
    </Link>
  </b>
);

const AddCustomerReviewCredit = ({ onClick }) => (
  <b>
    <Link
      style={{
        color: '#ff7800',
      }}
      onClick={onClick}
    >
      + Add Customer & Review Credit
    </Link>
  </b>
);

const CO_BROKER_OPTIONS = [{
      text: "No Acknowledgement on File",
      value: "NO_ACKNOWLEDGMENT_ON_FILE"
    }, {
      text: "Acknowledgment Received",
      value: "ACKNOWLEDGMENT_RECEIVED"
    }, {
      text: "Approved",
      value: "APPROVED"
    },{
      text: "Declined",
      value: "DECLINED"
    },
  ]
const FactoringUserDebtorTableItems = (isBroker, fetch) => createTableItems(
  ({ data, setFactoringDebtorStatus, openRequestIncreaseModal, handleSubmit, isAljex, openUploadCoBrokerDocs, allowCoBrokerShipperDebtor }) => {
    const tooltipText = [];
    if (data.credit_approved_note) {
      tooltipText.push(<p style={{ fontSize: '14' }}>{data.credit_approved_note}</p>);
    }
    if (data.originals_required) {
      tooltipText.push(<p style={{ fontSize: '14' }}>Original Documents are required by this debtor for approval</p>);
    }
    return [
        data.company_name,
        <b>
            <FactoringClientCreditApprovalStatus status={data.credit_approved} />
            {
                data.originals_required &&
                <Tooltip text={originalDocumentsText}>
                    <MaterialIcon name="info" size={20} style={{ color: '#2196f3', padding: '0px 5px' }} />
                </Tooltip>
            }
            {
                data.requires_scanned_copy &&
                <Tooltip text={scannedDocumentText}>
                    <MaterialIcon name="description" size={20} style={{ color: '#C48011', padding: '0px 5px' }} />
                </Tooltip>
            }
        </b>,
        formatPennies(data.credit_available),
        data.discount_rate ? `${data.discount_rate}%` : '',
        get(data, 'address.street_one', ''),
        get(data, 'address.state', ''),
        data.mc ? data.mc : data.dot,
        ...(()=> allowCoBrokerShipperDebtor ?
        [<Field
            component={LabeledDropdown}
            style={{ minWidth: 220 }}
            data={CO_BROKER_OPTIONS}
            name="co_broker_allowed"
            disabled
        />,
        <button
            className="btn btn-orange"
            style={{
                backgroundColor: '#2196f3',
                fontSize: '14px',
                color: 'white',
                border: 0
            }}
            onClick={openUploadCoBrokerDocs}
            type="button"
        >
            <MaterialIcon className="mdi-24px" name="backup" />
        </button>] : [])(),
        <NOAStatus data={data} fetch={fetch} />,
        !isEmpty(tooltipText) ?
            <Tooltip text={tooltipText}>
                <MaterialIcon name="error_outline" size={20} style={{ color: '#FF100E' }} />
            </Tooltip>
            : null,
        ...(() => isAljex ? [<Field
            component={LabeledInput}
            name="external_code"
        />] : [])(),
        <div>
            {isAljex &&
                <div style={{ display: 'flex', flex: 1 }}>
                    <button
                        style={{
                            marginLeft: '8px'
                        }}
                        className="btn btn-orange"
                        onClick={() => {
                            // navigateTo(`/haul-pay/getpaid/request?id=${data.id}`);
                            handleSubmit();
                        }}
                    >
                        Save
                    </button>
                </div>}
            <ViewHistory id={data.id} isBroker={isBroker} />
            {
                data.credit_approved === 'pending' && (
                    <p>
                        <b>
                            <Link
                                style={{
                                    color: '#ff7800'
                                }}
                                onClick={async () => await setFactoringDebtorStatus()}
                            >
                                Review Credit
                            </Link>
                        </b>
                    </p>
                )
            }
            <p>
                <b>
                    <Link
                        style={{
                            color: '#ff7800'
                        }}
                        onClick={() => openRequestIncreaseModal(data)}
                    >
                        Request Increase
                    </Link>
                </b>
            </p>
        </div>
    ];
  },
    compose(
        connect(
            (state, ownProp) => {
                return {
                    isAljex: get(state.user, 'factoring.data.datasync_option', false),
                    allowCoBrokerShipperDebtor: get(state.user, 'factoring.data.allow_co_broker_shipper_debtor', false),
                    factoring_id: get(state.user, ['factoring', 'data', 'id']),
                    factoringProfile: get(state.user, ['factoring', 'data'], {}),
                    form: `UPDATE_DEBTOR_PROFILE_ID_${ownProp.data.id}`,
                    initialValues: {
                        external_code: ownProp.data.external_code || '',
            co_broker_allowed: ownProp.data.co_broker_allowed || '',
          },
        };
      },
      (dispatch, { data }) => ({
        openRequestIncreaseModal: debtor => dispatch(openModal('warning', {
          message: <CreditIncreaseForm debtor={debtor} />,
          customCloseModal: () => {
            dispatch(submit(increaseCreditFormName));
          },
          buttonChildren: 'Request',
        })),
        openUploadCoBrokerDocs: () => dispatch(openModal('CoBrokeringUploadDocs', {
          attachments: data.attachments || [],
          relationshipId: data.relationship_id,
          debtorRelation: true,
        })),
        async setFactoringDebtorStatus() {
          try {
            window.analytics.track(segmentEvents.USER_REQUESTED_CUSTOMERS_CREDIT_REVIEWED, { company_name: data.company_name, customer_id: data.id });
            await dispatch(setFactoringDebtorStatus({
              id: data.id,
              credit_approved: 'needs_review',
            }));
            dispatch(openModal('warning', { message: 'We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
            fetch();
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'There was an error processing your request.' }));
          }
        },
      })
    ),
    wrapPromisePending({
      promiseProp: 'setFactoringDebtorStatus',
    }),
    reduxForm({
      destroyOnUnmount: true,
      forceUnregisterOnUnmount: true,
      enableReinitialize: true,
      async onSubmit(fields, dispatch, { data, factoring_id }) {
        try {
          const res = await dispatch(updateRelationship({
            external_code: fields.external_code,
            id: data.id,
            factoring: factoring_id,
            debtor: data.id,
            co_broker_allowed: data.co_broker_allowed,
          }));
          dispatch(openModal('success', { message: 'Debtor has been updated.' }));
        }
        catch (err) {
          if(err.factoring)
            return dispatch(openModal('error', { message: err.factoring }))
          dispatch(openModal('error', { message: 'Couldn\'t update debtor. Please try again later.' }));
        }
      },
    }),
  ),
);

const FactoringUserDebtorTable = compose(
  withState("filters", "setFilters", {}),
  connect(
    (state, { id }) => {
      const factoringClient = state.resourceBy.factoringclient.factoringdebtor[id];
      let ids = [];
      let isFetching = false;
      let next = null;
      let count = 0;
      if (factoringClient) {
        ids = factoringClient.ids;
        isFetching = factoringClient.isFetching;
        next = factoringClient.next;
        count = factoringClient.count;
      }
      return {
        ids,
        isFetching,
        next,
        count,
        debtors: state.resource.factoringdebtor,
        isBroker: isDispatchBroker(state) || isFactoringBroker(state),
        isAljex: get(state.user, 'factoring.data.datasync_option', false),
        allowCoBrokerShipperDebtor: get(state.user, 'factoring.data.allow_co_broker_shipper_debtor', false),
      };
    },
    (dispatch, { id }) => ({
      fetch: filters => dispatch(factoringdebtorByfactoringclientUSER.fetch(id, filters)),
      fetchNext: filters => dispatch(factoringdebtorByfactoringclientUSER.fetchNext(id, filters)),
      clear: () => dispatch(factoringdebtorByfactoringclientUSER.clear(id)),
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetch();
    },
  }),
)(({ ids = [], isFetching, fetchNext, clear, debtors, next, count, isBroker, fetch, isAljex = false, filters, setFilters, debtorAdded, allowCoBrokerShipperDebtor }) =>
  <div>
    <Header
      style={{
        marginTop: '64px',
        marginBottom: '64px',
        color: '#4a90e2',
      }}
    >
      Your Customers: {count + debtorAdded}
    </Header>
    <SearchForm
      form="customer"
      names={{
        dba: "company_name"
      }}
      onSubmit={fields => {
        clear();
        fetch({
          company_name: fields.company_name,
          [fields.search_type]: fields.search_type_text,
        })
        setFilters({
          company_name: fields.company_name,
          [fields.search_type]: fields.search_type_text,
        })
      }}
    />
    <ResponsiveTable
      isFetching={isFetching}
      items={!isFetching ? uniq(ids).map(id => debtors[id]) : []}
      placeholder='No customers (yet!) Try adding some.'
      TableItem={FactoringUserDebtorTableItems(isBroker, fetch).TableItem}
      BlockTableItem={FactoringUserDebtorTableItems(isBroker, fetch).BlockTableItem}
      headers={['DBA', 'Credit', 'Remaining Credit', 'Discount Rate', 'Address', 'State', 'MC/Dot', ...(() => allowCoBrokerShipperDebtor ? ['Co Broker Allowed', 'Upload Docs'] : [])(), 'NOA Status', 'Status Reason', ...(() => isAljex ? ['External Code'] : [])(), 'Action']}
    />
    {
      !next ? null :
        <div className='text-right'>
          <button className='btn btn-orange' type='button' onClick={() => fetchNext(filters)}>Load More</button>
        </div>
    }
  </div>
);

const FORM_NAME = 'FactoringUserAssociateDebtorForm';

const FactoringCreditCheckListItems = createTableItems(
  ({ data, addCustomer, setFactoringDebtorStatus, openSendNOAModal, factoringClient }) => {
    const tooltipText = [];
    if (data.credit_approved_note) {
      tooltipText.push(<p style={{ fontSize: '14' }}>{data.credit_approved_note}</p>);
    }
    if (data.originals_required) {
      tooltipText.push(<p style={{ fontSize: '14' }}>Original Documents are required by this debtor for approval</p>);
    }
    return [
      data.company_name,
      <b>
        <FactoringClientCreditApprovalStatus status={data.credit_approved} />
        {
          data.originals_required &&
          <Tooltip text={originalDocumentsText}>
            <MaterialIcon name='info' size={20} style={{ padding: '0px 5px' }} />
          </Tooltip>
        }
        {
          data.requires_scanned_copy &&
          <Tooltip text={scannedDocumentText}>
            <MaterialIcon name='description' size={20} style={{ color: '#C48011', padding: '0px 5px' }} />
          </Tooltip>
        }
      </b>,
      formatPennies(data.credit_available),
      `${data.discount_rate}%`,
      get(data, 'address.street_one', ''),
      get(data, 'address.state', ''),
      data.mc ? data.mc : data.dot,
      !isEmpty(tooltipText) ?
        <Tooltip text={tooltipText}>
          <MaterialIcon name='error_outline' size={20} style={{ color: '#FF100E' }} />
        </Tooltip>
        : null,
      (() => {
        if (data.is_connected) {
          return <p style={{ color: '#FF100E', fontSize: 12, fontWeight: 'bold' }}>Already Connected Below</p>;
        }
        switch (data.credit_approved) {
          case 'declined':
            return (<AddCustomer
              onClick={() => {
                addCustomer(data.id);
              }}
            />);
          case 'needs_review':
          case 'approved':
            return (<AddCustomer
              onClick={() => {
                openSendNOAModal({
                  debtor: data,
                  cancel: () => addCustomer(data.id, false),
                  confirm: () => addCustomer(data.id, true),
                });
                // addCustomer(data.id);
              }}
            />);
          case 'pending':
          default:
            return (<AddCustomerReviewCredit
              onClick={() => setFactoringDebtorStatus().then(async () => {
                try {
                  addCustomer(data.id, false, { adjucate_debtor: false });
                } catch (e) {
                  throw e;
                }
              })}
            />);
        }
      })(),
    ];
  },
  compose(
    withState('has_been_set', 'set_has_been_set', false),
    connect(
      state => {
        return {
          factoring_id: get(state.user, ['factoring', 'data', 'id']),
          factoringProfile: get(state.user, ['factoring', 'data'], {}),
        };
      },
      (dispatch, { data, set_has_been_set, debtorAdded, setDebtorAdded, ...props }) => ({
        async setFactoringDebtorStatus() {
          try {
            await dispatch(setFactoringDebtorStatus({
              id: data.id,
              credit_approved: 'needs_review',
            }));
            dispatch(openModal('warning', { message: 'We are checking their credit now and will alert you of approval within 1 business day. Thank you.' }));
            set_has_been_set(true);
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'There was an error processing your request.' }));
          }
        },
        openSendNOAModal: props => dispatch(openModal('SendNOA', props)),
        addCustomer: (factoringId, factoringProfile) => async (debtorId, autoSendNoa = false, extraParams = {}) => {
          setDebtorAdded(debtorAdded + 1);
          return dispatch(factoringdebtorByfactoringclientUSER.create(factoringId, {
            debtor: debtorId,
            auto_send_noa: autoSendNoa,
            ...extraParams,
          }))
            .then(() => {
              if (factoringProfile.discount_rate < data.discount_rate) {
                dispatch(openModal('warning', { message: 'This customer\'s rate is higher than yours, the future funding requests will inherit this customers rate.' }));
              }
              dispatch(reset(FORM_NAME));
              dispatch(openModal('success', { message: 'Successfully added customer.' }));
            })
            .then(() => window.location.reload())
            .catch(err => {
              console.warn(err);
              if (err instanceof APIError && err.status === 400 && get(err, ['message', 'non_field_errors', '0']) === 'The fields factoring, debtor must make a unique set.') {
                dispatch(openModal('error', { message: 'You\'re already associated with that customer.' }));
              }
              else {
                dispatch(openModal('error', { message: 'Failed to add customer :(' }));
              }
            })
            ;
        },
      }),
      (stateProps, dispatchProps, ownProps) => ({
        ...stateProps,
        ...dispatchProps,
        ...ownProps,
        addCustomer: dispatchProps.addCustomer(stateProps.factoring_id, stateProps.factoringProfile),
      })
    ),
    wrapPromisePending({
      promiseProp: 'setFactoringDebtorStatus',
    }),
  ),
);

const FactoringUserCustomers = compose(
  connect(
    state => {
      return {
        factoring_id: get(state.user, ['factoring', 'data', 'id']),
      };
    },
    {
      fetchFactoringProfile,
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchFactoringProfile();
    },
  })
)(({ factoring_id, filters, debtorAdded }) =>
  factoring_id ?
    <FactoringUserDebtorTable id={factoring_id} filters={filters} debtorAdded={debtorAdded} />
    :
    <Spinner />
);
const SearchDropDown = props => {
  const data = [{
    text: 'MC#',
    value: 'mc',
  },
  {
    text: 'DOT#',
    value: 'dot',
  },
  {
    text: 'TAX ID#',
    value: 'tax_id',
  }];

  const ListItem = ({ item: { text } }) => (
    <div>{text}</div>
  );

  return (
    <Field
      style={{ minWidth: 75, marginRight: 5 }}
      name='search_type'
      component={LabeledDropdown}
      data={data}
      itemComponent={ListItem}
      {...props}
    />
  );
};

const SearchForm = compose(
  connect(({ form, ...props }) => {
    ({
      form: `FactoringCreditCheckSearchForm-${form}`,
      ...props
    })
  }),
  reduxForm({}))(props => <form onSubmit={props.handleSubmit(props.onSubmit)}>
    <div
      style={{
        display: 'flex',
        flex: 1,
        alignItems: 'flex-end',
        marginTop: '45px',
        marginBottom: '32px',
        flexWrap: 'wrap',
      }}
    >
      <div
        style={{
          minWidth: '280px',
          marginRight: '16px',
        }}
      >
        <Field
          name={get(props, "names.dba", "dba")}
          component={LabeledInput}
          label='Company Name'
        />
      </div>
      <div
        style={{
          display: 'flex',
          marginTop: 10,
          marginLeft: 5,
          marginRight: 5,
          flexWrap: 'wrap',
        }}
      >
        <p style={{ fontSize: 18 }}> Or:</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginRight: 15,
          marginLeft: 15,
        }}
      >
        <SearchDropDown />
        <Field
          name='search_type_text'
          component={LabeledInput}
        />
      </div>
      <div
        style={{
          marginRight: '16px',
        }}
      >
        <button
          className='btn btn-orange'
          type='submit'
          disabled={props.submitting}
          style={{
            height: '35px',
            marginBottom: '10px',
            minWidth: '130px',
          }}
        >
          Search Credit
        </button>
      </div>
      {props.openAddCustomerCreditCheckModal &&
        <div>
          <button
            type='button'
            onClick={props.openAddCustomerCreditCheckModal}
            style={{
              height: '35px',
              marginBottom: '10px',
              border: '1px solid #21bb00',
              backgroundColor: '#21bb00',
              color: 'white',
              minWidth: '130px',
            }}
          >
            + New Cust
          </button>
        </div>
      }
    </div>
    <div>
      {props.render && props.render(props)}
    </div>
  </form>
  )

export default compose(
  withState("debtorAdded", "setDebtorAdded", 0),
  connect(
    state => {
      const factoringId = get(state.user, ['factoring', 'data', 'id']);
      const factoringClient = get(state, `resourceBy.factoringclient.factoringdebtor[${factoringId}]`, {});
      return {
        factoringClient,
      };
    },
    {
      openAddCustomerCreditCheckModal: () => openModal('addcustomercreditcheck', { parent: 'FactoringCreditCheckSearchForm' }),
    },
  ),
)(class FactoringCreditCheckSearchForm extends React.Component {
  state = {
    debtors: [],
  };
  onSubmit = this.onSubmit.bind(this);

  async onSubmit(fields, dispatch) {
    try {
      const data = {
        dba: fields.dba,
        [fields.search_type]: fields.search_type_text,
      };
      const debtors = await dispatch(searchFactoringDebtors(data));
      window.analytics.track(segmentEvents.USER_SEARCHED_SHIPPERS_CREDIT_CHECK);
      this.setState({ debtors });
    }
    catch (err) {
      console.warn(err);
      dispatch(openModal('error', { message: 'There was an error while searching.' }));
    }
  }

  render() {
    const { openAddCustomerCreditCheckModal, factoringClient, debtorAdded, setDebtorAdded } = this.props;
    const { debtors } = this.state;

    return (
      <div className='col-xs-12'>
        <div
          style={{
            color: '#5c6979',
            fontSize: '16px',
            marginTop: '20px',
            marginBottom: '70px',
          }}
        >
          You can check companies credit for free.
        </div>
        <SearchForm
          render={props => <ResponsiveTable
            isFetching={props.submitting}
            items={debtors.map(debtor => ({
              data: debtor,
              factoringClient: factoringClient.ids || [],
              setDebtorAdded: setDebtorAdded,
              debtorAdded: debtorAdded
            }))}
            placeholder='No results.'
            headers={['DBA', 'Credit', 'Remaining Credit', 'Discount Rate', 'Address', 'State', 'MC/Dot', 'Status Reason', 'Action']}
            {...FactoringCreditCheckListItems}
          />}
          form="credit"
          onSubmit={this.onSubmit}
          openAddCustomerCreditCheckModal={openAddCustomerCreditCheckModal}
        />
        <FactoringUserCustomers debtorAdded={debtorAdded} />
      </div>
    );
  }
});
