/**
 * Component that displays a floating-right orange submit button
 * @module components/pure/form/SubmitButton
 * @since 3.0.0
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const SubmitButton = ({ children = 'Submit', ...rest }) =>
  <div className='form-group text-right'>
    <button
      type='submit'
      {...rest}
      className={cs('btn btn-orange', rest.className)}
      >
      {children}
    </button>
  </div>
  ;

SubmitButton.propTypes = {
  children: PropTypes.node,
};

export default SubmitButton;
