import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import withState from 'recompose/withState';
import DropdownList from 'react-widgets/lib/DropdownList';
import { get } from 'lodash';
import { USER_TYPE } from 'helpers';
import { openModal } from 'actions/ui';
import getSelf from 'selectors/getSelf';
import Resource from 'components/hoc/Resource';


const options = [
  {
    text: 'Status Unset',
    value: null,
  },
  {
    text: 'Rate Confirmed',
    value: 'rate_confirmed',
  },
  {
    text: 'Dispatched',
    value: 'dispatched',
  },
  {
    text: 'Picking Up',
    value: 'picking_up',
  },
  {
    text: 'In Transit',
    value: 'in_transit',
  },
  {
    text: 'Delivering',
    value: 'delivering',
  },
  {
    text: 'Completed',
    value: 'completed',
  },
  {
    text: 'Cancelled',
    value: 'cancelled',
  },
];

const LoadStatusForm = compose(
  connect(
    state => ({
      self: getSelf(state),
    }),
    {
      openModal,
    },
  ),
  Resource('load'),
  withState('value', 'setValue', ({ item }) => item && item.data && item.data.status),
)(({ id, item, self, value, openModal, style }) => {

  const acceptedBid = (item.data.bids || []).find(bid => bid.bid_status === 'accepted');

  return (
    <div
      style={{
        display: 'inline-block',
        verticalAlign: 'middle',
        ...style,
      }}
      >
      <DropdownList
        style={{
          minWidth: '11em',
        }}
        data={options}
        placeholder='Unset'
        textField='text'
        valueField='value'
        value={value}
        // disable if:
        disabled={Boolean(
          // we don't have the data for whatever reason
          !item ||
          !item.data ||
          // or if the status is 'completed'
          item.data.status === 'completed' ||
          item.data.is_deleted ||
          // closed and not winner of bid
          item.data.is_bidding_closed && !(
            acceptedBid &&
            acceptedBid.user_id === get(self, 'data.id')
          ) ||
          // or if:
          (
            self &&
            self.data &&
            !(
              // we're not the assigned user
              (
                item.data.assigned_user &&
                item.data.assigned_user === self.data.id
              ) || (
                !item.data.assigned_user &&
                item.data.user_type !== USER_TYPE.SHIPPER
              ) ||
              // and we're not the accepted bidder
              (
                acceptedBid &&
                acceptedBid.user_id === self.data.id
              ) ||
              // and we don't own the load
              item.data.user_id === self.data.id
            )
          )
        )}
        onChange={val => openModal('confirm', {
          type: 'load_status_update',
          args: [
            id,
            val,
          ],
        })}
      />
    </div>
  );
});

LoadStatusForm.options = options;

export default LoadStatusForm;
