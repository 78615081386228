/**
 * Connected modal component to prompt a legac user to deactivate their legacy subscription
 * @module components/pure/modals/LegacyDeactivate
 * @since 3.0.0
 * @requires actions/ui
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/modals/GenericHeader
 */
/* global API */
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';

import { closeModal, openModal } from '../../../actions/ui';

import GenericHeader from './GenericHeader';
import LabeledInput from '../form/inputs/LabeledInput';


const LegacyDeactivate = compose(
  connect(null, { closeModal }),
  reduxForm({
    form: 'LegacyDeactivate',
    onSubmit(fields, dispatch) {
      return dispatch((dispatch, getState) => global.fetch(`${API.host}/help/legacy_cancel`, {
        method: 'POST',
        body: JSON.stringify(fields),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getState().user.token}`,
        },
      }))
        .then(res => {
          if (res.status !== 200) {
            return res.text().then(text => Promise.reject(text));
          }
          dispatch(openModal('success', { message: 'Successfully deactivated your subscription.' }));
          dispatch(closeModal());
          setTimeout(() => location.reload(), 3000);
        })
        .catch(err => {
          let message = 'Error deactivating subscription';
          if (err) {
            if (err.message) {
              message = err.message;
            }
          }
          dispatch(closeModal());
          dispatch(openModal('error', { message }));
        })
      ;
    },
    validate: fields => validate(fields, {
      name: {
        presence: true,
      },
      last4: {
        presence: true,
        length: {
          is: 4,
        },
        numericality: {
          onlyInteger: true,
        },
      },
    }),
  }),
)(({ handleSubmit, submitting, closeModal }) =>
  <form onSubmit={handleSubmit}>
    <GenericHeader closeModal={closeModal}>Confirm</GenericHeader>
    <div className='modal-body'>
      <Field name='name' component={LabeledInput} label='Name on Billing Card:' />
      <Field name='last4' component={LabeledInput} label='Last 4 Numbers on Billing Card:' />
    </div>
    <div className='modal-footer'>
      <button className='btn btn-default' onClick={closeModal}>Nevermind</button>
      <button className='btn btn-danger' role='submit' disabled={submitting}>Cancel Subscription</button>
    </div>
  </form>
);

export default LegacyDeactivate;
