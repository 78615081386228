import React from 'react';
import PropTypes from 'prop-types';

import ColoredCircle from 'components/pure/ColoredCircle';


const AverageDaysToPay = ({ avgpay }) =>
  avgpay ?
    <div>
      <span>AVG DAYS TO PAY: {avgpay}</span>
      <ColoredCircle
        color={(() =>
          avgpay >= 60 ? '#ED1C24' :
            avgpay >= 45 ? '#E86C00' :
              avgpay >= 35 ? '#0395FE' : '#00BC48'
        )()}
      />
    </div>
    : null
  ;

AverageDaysToPay.propTypes = {
  avgpay: PropTypes.number,
};

export default AverageDaysToPay;
