/* global API */
import { compose } from 'redux-v3';

import Fetchable from 'datatypes/compose/resource/Fetchable';
import Editable from 'datatypes/compose/resource/Editable';
import Createable, { CREATE_KEY } from 'datatypes/compose/resource/Createable';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import PermissionsError from 'datatypes/PermissionsError';
import SubmissionError from 'datatypes/error/SubmissionError';

class FactoringTransactionlineitemResource extends compose(
  Fetchable({
    url: (funding_request_id, id) => `funding_requests/${funding_request_id}/items/${id}`,
  }),
  Editable(),
  Createable(),
)() {
  create(data, id) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.selector(state, CREATE_KEY);

      if (item && item.isFetching) {
        return;
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });
    try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${id}/items/`, {
          method: 'POST',
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
          body: JSON.stringify(data),
        }));
        if (res.status !== 201) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            if (res.status === 400) {
              throw new SubmissionError(json);
            }
            throw new PermissionsError(json);
          }
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        if (typeof data.attachments === 'undefined'){
          return dispatch({
            type: this.actions.CREATE_REQUEST_SUCCESS,
            payload: data,
          });
        }

        for (let i = 0; i < data.attachments.length; i++) {
          const { attachments } = data;
          const fileObj = {};
          fileObj.filename = attachments[i].name;
          const resAttachment = await dispatch(APIFetch(`${API.host}/funding_requests/${id}/items/${json.id}/attachments/`, {
            method: 'POST',
            headers: {
              authorization: `bearer ${state.user.token}`,
              'content-type': 'application/json',
            },
            body: JSON.stringify(fileObj),
          }));

          const jsonAttachment = await resAttachment.json();

          const { upload_url: { fields, url } } = jsonAttachment;

          const formData = new FormData();
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              formData.append(key, fields[key]);
            }
          }
          formData.append('file', data.attachments[i]);
          await global.fetch(url, {
            method: 'POST',
            body: formData,
          });
        }
      // if (resAttachment.status !== 201) {
      //   if (resAttachment.status >= 400 && resAttachment.status < 500) {
      //     const jsonAttachment = await resAttachment.json();
      //     if (resAttachment.status === 400) {
      //       throw new SubmissionError(jsonAttachment);
      //     }
      //     throw new PermissionsError(jsonAttachment);
      //   }
      //   const text = await resAttachment.text();
      //   throw new FetchError(resAttachment.status, text);
      // }
      // const jsonAttachment = await resAttachment.json();
      // }
        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: data,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

edit(data, frid, id) {
  return async (dispatch, getState) => {
    const state = getState();
    const item = this.selector(state, id);

    if (item && item.isFetching) {
      return;
    }
    dispatch({
      type: this.actions.EDIT_REQUEST,
      payload: id,
    });

    try {
      const res = await dispatch(APIFetch(`${API.host}/funding_requests/${frid}/items/${id}/`, {
        method: 'PATCH',
        headers: {
          authorization: `bearer ${state.user.token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify(data),
      }));
      if (res.status !== 200) {
        if (res.status >= 400 && res.status < 500) {
          const json = await res.json();
          if (res.status === 400) {
            throw new SubmissionError(json);
          }
          throw new PermissionsError(json);
        }
        const text = await res.text();
        throw new FetchError(res.status, text);
      }
      const json = await res.json();
      return dispatch({
        type: this.actions.EDIT_REQUEST_SUCCESS,
        payload: {
          id,
          json,
        },
      });
    }
    catch (err) {
      dispatch({
        type: this.actions.EDIT_REQUEST_FAILURE,
        payload: {
          id,
          err,
        },
      });
      throw err;
    }
  };
}
}

const factoringtransactionlineitemResource = new FactoringTransactionlineitemResource('factoringtransactionlineitem');

export const {
  selector,
  fetch,
  fetchIfNeeded,
  edit,
  create,
} = factoringtransactionlineitemResource;

export default factoringtransactionlineitemResource;
