import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';

import ratingsByUserResource from 'actions/resourceBy/user/rating';

import RateStars from 'components/pure/RateStars';


const UserRating = compose(
  connect(
    (state, ownProps) => {
      const ratingByUserState = state.resourceBy.user.rating[ownProps.id] || {};
      let rating = null;
      if (ratingByUserState && ratingByUserState.ids && ratingByUserState.ids.length) {
        rating = ratingByUserState.ids.reduce((acc, id) => state.resource.rating[id].data.rating + acc, 0) / ratingByUserState.ids.length;
      }
      return {
        rating,
        next: ratingByUserState.next,
      };
    },
    {
      fetchAll: ratingsByUserResource.fetchAll,
    },
  ),
)(class UserRating extends React.Component {
  componentDidMount() {
    this.props.fetchAll(this.props.id);
  }
  render() {
    if (this.props.rating === null) {
      return null;
    }
    return (
      <RateStars value={this.props.rating} color='#FFA500' />
    );
  }
});

UserRating.propTypes = {
  id: PropTypes.string.isRequired,
};

export default UserRating;
