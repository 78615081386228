import React from 'react';
import { compose } from 'redux-v3';

import RouteTabs from 'components/pure/RouteTabs';
import CenteredColumn from 'components/pure/CenteredColumn';


export default compose(
)(({ children }) =>
  <div className='col-fill'>
    <CenteredColumn
      xs={12}
      sm={10}
      style={{ flexGrow: 0 }}
      >
      <RouteTabs
        routes={[
          ['/saved/posts/loads', 'Saved Loads'],
          ['/saved/posts/trucks', 'Saved Trucks'],
        ]}
      />
    </CenteredColumn>
    {children}
  </div>
);
