/**
 * Reducer for handling the load by user resource actions
 * @module reducers/resourceBy/factoringclient/factoringclientnote
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resourceBy/factoringclient/factoringclientnote
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_SUCCESS
 * @listens module:datatypes/ControlledPaginatedResource~ControlledPaginatedResource#PAGINATED_CREATE_REQUEST_FAILURE
 */
import INITIAL_STATE from '../../../INITIAL_STATE';
const initialState = INITIAL_STATE.resourceBy.factoringclient.factoringclientnote;

import factoringclientnoteByFactoringclientResource from 'actions/resourceBy/factoringclient/factoringclientnote';

export default function factoringclientnoteByFactoringclientResourceReducer(state = initialState, action) {
  state = factoringclientnoteByFactoringclientResource.reduce(state, action);
  return state;
}
