/**
 * Reducer for handling the factoringpayment resource actions
 * @module reducers/resource/factoringpayment
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/resource/factoringpayment
 * @requires actions/admin/factoringpayment
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/Resource~Resource#FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.resource.getbankinfo;

import GetBankInfoResource from 'actions/resource/GetBankInfo';
import bankinfoAdminResource from 'actions/admin/factoring/bank';



export default function getbankinfoResourceReducer(state = initialState, action) {
    state = GetBankInfoResource.reduce(state, action);
      switch (action.type) {
        case bankinfoAdminResource.actions.FETCH_REQUEST_SUCCESS:
          return {
            ...state,
            ...GetBankInfoResource.parseMany(state, action.payload.results),
          };
        }
    return state;
}
