import * as React from "react";
import { Input, InputProps } from 'antd';

function AntInput(props: InputProps) {
    return (
        <Input
            {...props}
        />
    )
}
export default AntInput;