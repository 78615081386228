import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import { fetchHiddenUsers } from 'actions/hide';
import { openModal } from 'actions/ui';
import HiddenUsers from 'components/pure/HiddenUsers';

export default compose(
  connect(
    state => state.hide.users,
    {
      fetchHiddenUsers,
      unHideUser: id => openModal('confirmunhideuser', { id }),
    },
  ),
  lifecycle({
    componentDidMount() {
      this.props.fetchHiddenUsers();
    },
  }),
)(HiddenUsers);
