import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger';
import BSTooltip from 'react-bootstrap/lib/Tooltip';


const Tooltip = ({ text, children, placement ='bottom', ...rest }) =>
  <OverlayTrigger
    placement={placement}
    overlay={Array.isArray(text) ? React.createElement(BSTooltip, null, ...text) : React.createElement(BSTooltip, null, text)}
    {...rest}
    >
    {children}
  </OverlayTrigger>
  ;

Tooltip.propTypes = {
  text: PropTypes.node.isRequired,
};

export default Tooltip;
