import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import lifecycle from 'recompose/lifecycle';

import factoringclientPaginatedResource from 'actions/admin/factoring/client';

import { FactoringClientTable } from 'components/container/FactoringClientLists';


export default compose(
  connect(
    null,
    dispatch => ({
      update(assigned_admin, period, ordering) {
        dispatch(factoringclientPaginatedResource.clear());
        dispatch(factoringclientPaginatedResource.fetch({ assigned_admin, period }));
      },
    }),
  ),
  lifecycle({
    componentWillMount() {
      const { currentSelectedPeriod, selectedAdmin } = this.props;
      if (selectedAdmin) {
        this.props.update(selectedAdmin.id, currentSelectedPeriod);
      }
      if (currentSelectedPeriod) {
        this.props.update(selectedAdmin.id, currentSelectedPeriod);
      }
    },
    componentWillReceiveProps(nextProps) {
      if ((this.props.selectAdmin !== nextProps.selectedAdmin) || (this.props.selectedPeriod !== nextProps.currentSelectedPeriod)) {
        this.props.update(nextProps.selectedAdmin ? nextProps.selectedAdmin.id : undefined, nextProps.currentSelectedPeriod ? nextProps.currentSelectedPeriod : undefined);
      }
    },
  }),
)(({ selectedAdmin }) =>
  <FactoringClientTable
    containerProps={{ className: 'col-fill' }}
    headerText={
      selectedAdmin.id === null ?
        <span>Unassigned Users</span>
        :
        <span>{selectedAdmin.name || selectedAdmin.email || selectedAdmin.id} Assigned Users</span>
    }
  />
);
