import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import cs from 'classnames';
import pick from 'lodash/pick';


const ReduxFormFieldErrors = ({ form, fields, errors, dispatch, ...rest }) =>
  <div {...rest} className={cs('has-error', rest.className)}>
    {errors.map((error, i) => <div key={i} className='help-block' style={{ marginBottom: 0 }}>{error}</div>)}
  </div>
  ;

ReduxFormFieldErrors.propTypes = {
  form: PropTypes.string.isRequired,
  fields: PropTypes.arrayOf(PropTypes.string).isRequired,
  errors: PropTypes.arrayOf(PropTypes.string).isRequired,
  dispatch: PropTypes.func,
};

export default compose(
  connect((state, ownProps) => {
    let { fields } = ownProps;
    if (!Array.isArray(fields)) {
      fields = [fields];
    }
    const form = state.form[ownProps.form];
    // filter out fields that were touched
    // or are the same as their initial value
    fields = fields.filter(field =>
      form.fields && form.fields[field] && form.values && form.initial && (form.fields[field].touched || form.values[field] !== form.initial[field])
    );
    const errors = Object.values(pick(form.syncErrors, fields));
    return { errors };
  }),
)(ReduxFormFieldErrors);
