import React from 'react';

import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';


const LoadTypeInput = props =>
  <LabeledDropdown
    {...props}
    data={[
      {
        value: '',
        text: 'All Loads',
      },
      {
        value: 'broker',
        text: 'Broker Loads',
      },
      {
        value: 'shipper',
        text: 'Shipper Loads',
      },
    ]}
  />
  ;

export default LoadTypeInput;
