/**
 * Component for a generic success modal
 * @module components/pure/modals/Success
 * @since 3.0.0
 * @requires components/hoc/Modal
 * @requires components/hoc/ModalTimeout
 * @requires components/pure/modals/Generic
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalGeneric component
 */
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import Modal from 'components/hoc/Modal';
import ModalTimeout from 'components/hoc/ModalTimeout';
import ModalGeneric from './Generic';


const ModalSuccess = compose(
  mapProps(props => ({
    ...props,
    headerStyle: {
      backgroundColor: '#5cb85c',
      color: 'white',
    },
    headerIcon: 'done',
    headerChildren: 'Success',
  })),
  Modal,
  ModalTimeout,
)(ModalGeneric);

export default ModalSuccess;
