/**
 * Higher order component that displays nothing (returns null) if the current user is not one of the given user types
 * @module components/hoc/nothingIfNotUserType
 * @since 3.0.0
 * @requires components/hoc/nothingIf
 */
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import getSelf from 'selectors/getSelf';

import nothingIf from 'components/hoc/nothingIf';


export const nothingIfNotUserType = acceptable_user_types => compose(
  connect(
    state => {
      const self = getSelf(state);
      return { self };
    },
  ),
  nothingIf(({ self }) => !acceptable_user_types.includes(self.data.type)),
);

export default nothingIfNotUserType;
