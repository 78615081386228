/**
 * Actions relating to the paginated combined factoring notes + user notes
 * @module actions/admin/notes
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires datatypes/APIFetch
 * @requires actions/resource/usernote
 * @requires actions/resource/factoringdebtornote
 */
/* global API */
import querystring from 'querystring';
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import usernoteResource from 'actions/resource/usernote';
import usernoteByUserResource from 'actions/resourceBy/user/note';
import factoringdebtornoteResource from 'actions/resource/factoringdebtornote';
import factoringdebtornoteByFactoringdebtorResource from 'actions/resourceBy/factoringdebtor/factoringdebtornote';


class CombinedFactoringDebtorNotesAndUserNotesPaginatedResource extends PaginatedResource {

  fetch(data, next) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isFetching) {
        return Promise.resolve();
      }

      if (next === undefined) {
        const target = {};
        if (data.debtor) {
          target.debtor_id = data.debtor;
        }
        if (data.user) {
          target.user_id = data.user;
        }
        next = `${API.host}/admin/debtors/notes_timeline/?${querystring.stringify(target)}`;
      }
      dispatch({
        type: this.actions.FETCH_REQUEST,
        payload: data,
      });

      try {
        const res = await dispatch(APIFetch(next, {
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.FETCH_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.FETCH_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  reduce(state = this.getDefaultState(), action) {
    if (
      factoringdebtornoteByFactoringdebtorResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS') ||
      usernoteByUserResource.matchesAction(action, 'CREATE_REQUEST_SUCCESS')
    ) {
      return {
        ...state,
        ids: [
          action.payload.id,
          ...state.ids,
        ],
      };
    }
    switch (action.type) {
      case factoringdebtornoteResource.actions.DELETE_REQUEST_SUCCESS:
      case usernoteResource.actions.DELETE_REQUEST_SUCCESS: {
        const new_ids = [];
        for (const id of state.ids) {
          if (id !== action.payload.id) {
            new_ids.push(id);
          }
        }
        return {
          ...state,
          ids: new_ids,
        };
      }
      case this.actions.FETCH_REQUEST_SUCCESS: {
        const results = action.payload;
        const new_items = [
          ...state[this.options.itemsKey],
          ...results.map(this.parse).reduce((acc, curr) => {
            if (state[this.options.itemsKey].includes(curr)) {
              return acc;
            }
            acc.push(curr);
            return acc;
          }, []),
        ];
        return {
          ...state,
          isFetching: false,
          [this.options.itemsKey]: new_items,
        };
      }
    }
    return super.reduce(state, action);
  }
}

const combinednotesResource = new CombinedFactoringDebtorNotesAndUserNotesPaginatedResource('factoring_debtor_user_combined_notes', {
  mountPoint: ['admin', 'debtorcombinednotes'],
  url: new UrlAssembler('admin/debtors/notes_timeline'),
});


export default combinednotesResource;
export const {
  fetch,
  fetchNext,
  clear,
} = combinednotesResource;
