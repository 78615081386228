/**
 * Component to display a map that displays a single truck's route
 * @module components/pure/TruckDetails
 * @since 3.0.0
 * @requires helpers
 * @requires datatypes/Point
 * @requires components/hoc/Resource
 * @requires components/hoc/nothingIfFetching
 * @requires components/pure/GoogleMap
 * @property {object} props
 * @property {object} props.id - the ID of the truck
 */
import React from 'react';
import { compose } from 'redux-v3';
import onlyUpdateForKeys from 'recompose/onlyUpdateForKeys';
import branch from 'recompose/branch';
import get from 'lodash/get';

import { isWestOf } from 'helpers';
import Point from 'datatypes/Point';

import Resource from 'components/hoc/Resource';
import nothingIfFetching from 'components/hoc/nothingIfFetching';
import GoogleMap, { TruckIcon, TruckIconLeft } from 'components/pure/GoogleMap';


export default compose(
  Resource('truck'),
  nothingIfFetching(),
  // load the route from Google if we have both an origin and destination, otherwise just render the circles and points
  branch(
    ({ item }) => (
      item.data.origin_coords instanceof Point &&
      item.data.destination_coords instanceof Point &&
      // Points default to Baja California, so make sure that we actually have an origin and destination too
      !Point.isDefault(item.data.origin_coords) &&
      !Point.isDefault(item.data.destination_coords)
    ),
    compose(
      Resource('map', {
        resourcePropName: 'map',
        idPropName: ({ item }) => ({
          origin: item.data.origin_coords,
          destination: item.data.destination_coords,
        }),
      }),
      nothingIfFetching('map'),
    ),
    Component => Component,
  ),
  onlyUpdateForKeys(['item', 'map']),
)(({ item, map }) => {
  // only display origin and destination if we have origin and destination coords and those coords aren't the default coords
  const hasOrigin = (item.data.origin_coords instanceof Point && !Point.isDefault(item.data.origin_coords));
  const hasDestination = (item.data.destination_coords instanceof Point && !Point.isDefault(item.data.destination_coords));
  const circles = [];
  const points = [];
  if (hasOrigin) {
    if (item.data.origin_city) {
      circles.push({
        radius: item.data.origin_radius,
        ...item.data.origin_coords.toLatLng(),
      });
    }
    points.push({
      position: item.data.origin_coords.toLatLng(),
      icon: item.data.destination_coords && isWestOf(item.data.origin_coords.toLatLng(), item.data.destination_coords.toLatLng()) ? TruckIconLeft : TruckIcon,
      label: item.data.destination_coords ? 'A' : '',
    });
  }
  if (hasDestination) {
    if (item.data.destination_city) {
      circles.push({
        radius: item.data.destination_radius,
        ...item.data.destination_coords.toLatLng(),
      });
    }
    points.push({
      position: item.data.destination_coords.toLatLng(),
      label: 'B',
    });
  }
  return (
    <GoogleMap
      circles={circles}
      points={points}
      routes={[get(map, ['data', 'routes', '0', 'legs', '0', 'steps'], []).map(step => step.polyline.points)]}
    />
  );
});
