import storage from './storage';


const SavedSetting = {
  Set: storage_key => {
    let data;
    try {
      data = JSON.parse(storage.getItem(storage_key)) || {};
    }
    catch (e) {
      console.warn(e);
      data = {};
      storage.setItem(storage_key, '{}');
    }
    const save = () => {
      try {
        storage.setItem(storage_key, JSON.stringify(data));
      }
      catch (err) {
        console.warn(err);
        try {
          storage.setItem(storage_key, '{}');
        }
        catch (err) {}
      }
    };
    return {
      has(id) {
        return data.hasOwnProperty(id);
      },
      set(id) {
        data[id] = true;
        save();
        return this;
      },
      remove(id) {
        delete data[id];
        save();
        return this;
      },
      toggle(id) {
        if (data[id]) {
          return this.remove(id);
        }
        return this.set(id);
      },
    };
  },
};

export default SavedSetting;
