/**
 * Component that displays the bids table and bids field on Load and Truck details pages
 * @module components/pure/BidsOtherUser
 * @since 3.0.0
 * @requires helpers
 * @requires components/pure/Header
 * @requires components/pure/BlockTable
 * @requires components/pure/form/BidCreateForm
 * @requires components/pure/form/Countdown
 * @requires components/hoc/scrollToAnchor
 * @requires components/hoc/Resource
 * @property {object} props
 * @property {object[]} props.bids
 * @property {object} props.bids[].data
 * @property {moment} props.bids[].data.time_end
 * @property {string} props.bids[].data.user_id
 * @property {string} props.bids[].data.bid_status
 * @property {object} props.load
 * @property {object} props.load.data
 * @property {moment} props.load.data.time_end
 * @property {string} props.user_id - the current user's ID
 * @property {function} props.onSubmit - a function to call when the bid form is submitted
 * @property {function} props.retractBid - a function to call to retract a bid given a bid's ID
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import moment from 'moment';

import { USER_TYPE } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import Header from 'components/pure/Header';
import BlockTable from 'components/pure/BlockTable';
import Countdown from 'components/pure/Countdown';
import BidCreateForm from 'components/pure/form/BidCreateForm';
import scrollToAnchor from 'components/hoc/scrollToAnchor';


/**
 * Component to display a row in the bid table
 * @function
 * @param {object} props
 * @param {object} props.bid
 * @param {object} props.bid.data
 * @param {string} props.bid.data.id
 * @param {string} props.user_id
 * @param {function} props.retractBid
 */
const BidsOtherUserItem = compose(
  scrollToAnchor((props, hash) => props.bids.some(bid => hash === `#bid-${bid.data.id}`)),
)(({ load, bids, user_id, retractBid, has_permissions }) =>
  <tbody>
    <tr>
      <td style={{ fontWeight: 'normal' }}>Amount</td>
      <td>Time Submitted</td>
      <td>{'\u00a0'}</td>
    </tr>
    {bids.map(bid =>
      <tr id={`bid-${bid.data.id}`} key={bid.data.id}>
        <td style={{ fontWeight: 'normal' }}>{has_permissions ? formatPennies(bid.data.amount) : formatPennies(bid.data.amount).replace(/\d/g, 'X')}</td>
        <td>{bid.data.time_posted.format('L LT')}</td>
        <td>
          {user_id === bid.data.user_id ? bid.data.bid_status === 'accepted' ?
            <div>
              <div className='text-success'>You Won This Bid</div>
              <a href={`/user/${load.data.user_id}`} target='_blank' rel='noopener'><button className='btn btn-success'>View Contact Info</button></a>
            </div>
            :
            <button className='btn btn-danger' onClick={() => retractBid(bid.data.id)}>Retract</button>
            : null}
        </td>
      </tr>
    )}
  </tbody>
);

const BidsOtherUser = ({ id, has_permissions, user_id, bids, onSubmit, retractBid, load, openBidCreatePerMileModal }) => {
  const hasBeenAccepted = bids.some(bid => bid.data.bid_status === 'accepted');
  const hasExpired = moment().isAfter(load.data.time_end);
  return (
    <div>
      <Header style={{ color: colors.RED }}>
        {
          load.data.user_id === '2aee6627-fec2-4470-856f-6dc34beff839' ? null :
            hasBeenAccepted ?
              'Load Has Been Covered' :
              hasExpired || load.data.is_deleted ?
                [USER_TYPE.BROKER, USER_TYPE.DISPATCHER].includes(load.data.user_type) ?
                  'Load has expired.' :
                  'Bidding Has Ended' :
                load.data.user_type !== USER_TYPE.BROKER ?
                  <div>Time Left to Bid: {<Countdown time={load.data.time_end} />}</div>
                  : null
        }
      </Header>
      <Header>Bidding:</Header>
      {bids && bids.err ?
        <div className='alert alert-danger' role='alert'>Error {bids.err}</div>
        :
        <div>
          <BlockTable
            className='gray compact'
            items={[{ load, bids, user_id, retractBid, has_permissions }]}
            placeholder={<div className='help-block'>No bids yet</div>}
            ListItem={BidsOtherUserItem}
          />
          {!hasBeenAccepted && !hasExpired && !load.data.is_deleted ? <BidCreateForm onSubmit={onSubmit} style={{ paddingTop: '1em' }} /> : null}
          {!hasBeenAccepted && !hasExpired && !load.data.is_deleted ? <button className='btn btn-default' onClick={openBidCreatePerMileModal}>Enter Per Mile</button> : null}
        </div>
      }
    </div>
  );
};

BidsOtherUser.propTypes = {
  id: PropTypes.string.isRequired,
  bids: PropTypes.arrayOf(PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      amount: PropTypes.number.isRequired,
      time_posted: PropTypes.instanceOf(moment).isRequired,
      user_id: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  load: PropTypes.shape({
    data: PropTypes.shape({
      user_type: PropTypes.oneOf(Object.values(USER_TYPE)).isRequired,
    }).isRequired,
  }).isRequired,
  user_id: PropTypes.string, // current user's id
  onSubmit: PropTypes.func.isRequired,
  retractBid: PropTypes.func.isRequired,
};

export default BidsOtherUser;
