/**
 * Component for searching for factoring clients
 * @module components/container/FactoringClientSearch
 * @since 3.1.0
 * @requires helpers
 * @requires helpers/createTableItems
 * @requires actions/search/factoringclient
 * @requires components/hoc/requireAdmin
 * @requires components/pure/Header
 * @requires components/pure/form/FactoringClientSearchForm
 * @requires components/pure/ResponsiveTable
 */
import colors from 'styles/colors.json';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';

import createTableItems from 'helpers/createTableItems';

import { fetchNext } from 'actions/search/factoringclient';

import Header from 'components/pure/Header';
import FactoringClientSearchForm from 'components/pure/form/FactoringClientSearchForm';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const ClientSearchTableComponents = createTableItems(
  ({ item }) => [
    item.data.factoring_approved,
    <Link to={`/admin/factoring/client/${item.data.id}`}>{item.data.owner_profile.email}</Link>,
    item.data.company_profile.name,
    item.data.owner_profile.name,
    item.data.company_profile.phone_number,
    item.data.company_profile.mc,
    item.data.company_profile.dot,
    <Link to={`/admin/factoring/client/${item.data.id}`}><button className='btn btn-orange'>More</button></Link>,
  ],
  compose(
    connect(
      (state, { id }) => ({
        item: state.resource.factoringclient[id],
      }),
    ),
  ),
  () => ({
    style: { borderTop: `solid 3px ${colors.BORDER_GRAY}` },
  }),
);

const ClientSearchTable = compose(
  connect(
    state => ({
      items: state.search.factoringclient.ids.map(id => ({ id })),
      isFetching: state.search.factoringclient.isFetching,
    }),
    {
      fetchNext,
    },
  ),
)(props =>
  <ResponsiveTable
    {...props}
    containerProps={{
      className: 'col-fill',
    }}
    headers={['Status', 'Email', 'DBA', 'Contact', 'Phone#', 'MC#', 'DOT#', 'More']}
    placeholder='No results'
    TableItem={ClientSearchTableComponents.TableItem}
    BlockTableItem={ClientSearchTableComponents.BlockTableItem}
  />
);

const ClientTableLoadMoreButton = compose(
  connect(
    state => state.search.user,
    {
      fetchNext,
    },
  ),
)(({ next, isFetching, fetchNext }) =>
  next && !isFetching ?
    <button className='btn btn-orange visible-xs-inline-block' onClick={fetchNext}>Load More</button>
    : null
);

const ClientSearch = compose(
)(() =>
  <div className='clearfix' style={{ maxWidth: '100%' }}>
    <div className='col-xs-12 col-sm-3'>
      <Header>Client Search</Header>
      <FactoringClientSearchForm />
    </div>
    <div className='col-xs-12 col-sm-9'>
      <ClientSearchTable />
      <ClientTableLoadMoreButton />
    </div>
  </div>
);

export default ClientSearch;
