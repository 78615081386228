/**
 * Actions relating to load maps
 * @module actions/search/loadmap
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/loadsearch
 */
/* global API */
import UrlAssembler from 'url-assembler';

import PaginatedResource from '../../datatypes/PaginatedResource';
import loadSearchResource from '../resource/loadsearch';


/**
 * The PaginatedResource that handles the load pagination and related actions for the load maps
 * @extends module:datatypes/PaginatedResource~PaginatedResource
 */
class LoadMapPaginatedResource extends PaginatedResource {

  constructor(...args) {
    super(...args);
    const upperName = this.name.toUpperCase();
    this.actions = {
      ...this.actions,
      CITIES_WITH_LOADS_FETCH_REQUEST: Symbol(`${upperName}_CITIES_WITH_LOADS_FETCH_REQUEST`),
      CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS: Symbol(`${upperName}_CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS`),
      CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE: Symbol(`${upperName}_CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE`),
    };
    this.fetchCitiesWithLoads = this.fetchCitiesWithLoads.bind(this);
  }

  /**
   * @param {string} state - the iso state code
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully fetching this PaginatedResource, or an error
   * @fires module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST
   * @fires module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS
   * @fires module:actions/search/loadmap/LoadMapPaginatedResource~LoadMapPaginatedResource#CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE
   */
  fetchCitiesWithLoads(state_code) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);
      if (mystate.citiesWithLoads.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.CITIES_WITH_LOADS_FETCH_REQUEST,
        payload: undefined,
      });
      try {
        const res = await global.fetch(`${API.host}/load/cities?state=${state_code}`, {
          headers: {
            Authorization: `Bearer ${state.user.token}`,
            'Content-Type': 'application/json',
          },
        });
        if (res.status !== 200) {
          if (res.status >= 400 && res.status < 500) {
            const json = await res.json();
            throw json;
          }
          const text = await res.text();
          throw text;
        }
        let citiesWithLoads = await res.json();
        // remove duplicates
        citiesWithLoads = Array.from(citiesWithLoads.reduce((acc, city) => acc.add(city), new Set));
        dispatch({
          type: this.actions.CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS,
          payload: citiesWithLoads,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE,
          payload: err,
        });
      }
    };
  }


  getDefaultState() {
    return {
      ...super.getDefaultState(),
      citiesWithLoads: {
        isFetching: false,
        data: [],
        err: null,
      },
    };
  }

  reduce(state = this.getDefaultState(), action) {
    state = super.reduce(state, action);
    switch (action.type) {
      case this.actions.CITIES_WITH_LOADS_FETCH_REQUEST:
        return {
          ...state,
          citiesWithLoads: {
            ...state.citiesWithLoads,
            isFetching: true,
            err: null,
          },
        };
      case this.actions.CITIES_WITH_LOADS_FETCH_REQUEST_SUCCESS:
        return {
          ...state,
          citiesWithLoads: {
            isFetching: false,
            err: null,
            data: action.payload,
          },
        };
      case this.actions.CITIES_WITH_LOADS_FETCH_REQUEST_FAILURE:
        return {
          ...state,
          citiesWithLoads: {
            isFetching: true,
            err: action.payload,
            data: [],
          },
        };
    }
    return state;
  }
}

/**
 * Our load map [PaginatedResource]{@link module:datatypes/PaginatedResource~PaginatedResource}
 */
const loadMapPaginatedResource = new LoadMapPaginatedResource('load_map', {
  mountPoint: 'search.loadmap',
  url: new UrlAssembler('load/search'),
  baseResource: loadSearchResource,
});

export default loadMapPaginatedResource;
export const {
  fetch,
  fetchNext,
  fetchCitiesWithLoads,
  sort,
  clear,
} = loadMapPaginatedResource;
