import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { lifecycle, withState } from 'recompose';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import { Link } from 'react-router-v4';
import MaterialIcon from 'components/pure/MaterialIcon';
import { getLineItemCategories } from 'actions/lineitemcategories';

const TableItems = createTableItems(({ data, onEdit, onDelete }) => {
  return [
    data.name,
    (() => {
      switch (data.applied_against) {
        case 'client':
          return 'Client';
        case 'debtor':
          return 'Debtor';
        case 'proof':
          return 'Proof';
        case 'organization':
          return 'ComFreight';
        default:
          return data.applied_against;
      }
    })(),
    (() => {
      switch (data.applied_by) {
        case 'client':
          return 'Client';
        case 'debtor':
          return 'Debtor';
        case 'proof':
          return 'Proof';
        case 'organization':
          return 'ComFreight';
        case 'client_reserve_positive':
          return 'Add to Client Reserve';
        case 'client_reserve_negative':
          return 'Take from Client Reserve';
        default:
          return data.applied_by;
      }
    })(),
    data.on_debtor_invoice ? 'Yes' : 'No',
    data.on_client_invoice ? 'Yes' : 'No',
    data.apply_discount ? 'Yes' : 'No',
    data.discount_rate,
    data.collated ? 'Yes' : 'No',
    data.description,
    data.invoice_name,
    <div>
      <MaterialIcon
        style={{ cursor: 'pointer', fontSize: 20, color: '#2196f3', marginRight: '1em' }}
        name='edit'
        onClick={() => onEdit(data)}
      />
      <MaterialIcon
        style={{ cursor: 'pointer', fontSize: 20, color: '#d9534f' }} name='delete' onClick={() => {
          onDelete(data.id);
        }}
      />
    </div>,
  ];
});

export default (({ onAdd, onEdit, onDelete, categories }) => (
  <div
    style={{
      margin: '8px',
    }}
    >
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '8px',
      }}
      >
      <h3>Line Item Categories</h3>
      <button className='btn btn-green' style={{ margin: '8px' }} onClick={onAdd}>
        Add +
      </button>
    </div>
    <ResponsiveTable
      containerProps={{
        className: 'col-fill',
      }}
      isFetching={false}
      headers={[
        'Name',
        'Applied Against',
        'Applied By',
        'On Debtor Invoice',
        'On Client Invoice',
        'Apply Discount',
        'Discount Rate',
        'Collated',
        'Description',
        'Actions',
        'Invoice Name',
      ]}
      placeholder='No Line Item Categories'
      TableItem={TableItems.TableItem}
      BlockTableItem={TableItems.BlockTableItem}
      items={categories.map(category => ({ data: category, onEdit, onDelete }))}
    />
  </div>
));
