import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const TaxIDInput = ({ input, meta, label, ...rest }) =>
  <div
    className={cs({
      'form-group': true,
      'has-error': meta.touched && meta.error,
      'has-warning': meta.touched && meta.warning && !meta.error,
    })}
    >
    {label ? <label className='control-label'>{label}</label> : null}
    <input
      {...input}
      {...rest}
      className={cs('form-control', rest.className)}
      onChange={e => {
        let { value } = e.target;
        if (value && !/^[\d-]{1,10}$/.test(value)) {
          return;
        }
        if (value.length >= 3) {
          value = value.replace(/[^\d]/, '');
          value = `${value.slice(0, 2)}-${value.slice(2)}`;
        }
        input.onChange(value);
      }}
    />
    {meta.touched && meta.error || meta.warning ? <div className='help-block'>{meta.error || meta.warning}</div> : null}
  </div>
  ;

TaxIDInput.propTypes = {
  label: PropTypes.node,
};

export default TaxIDInput;
