import React from 'react';


const InputLabel = ({ label, value }) =>
  <div className='form-group'>
    <label className='control-label'>{label}</label>
    <input className='form-control text-capitalize' disabled={true} value={value} />
  </div>
  ;

InputLabel.Horizontal = ({ label, value }) =>
  <div className='form-group'>
    <label className='control-label col-xs-2'>{label}</label>
    <div className='col-xs-10'>
      <input className='form-control text-capitalize' disabled={true} value={value} />
    </div>
  </div>
;

export default InputLabel;
