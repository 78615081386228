/**
 * Component that displays the a help form
 * @module components/pure/form/HelpForm
 * @since 3.0.0
 * @requires actions/user
 * @requires actions/ui
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/SubmitButton
 */
/* global fbq process*/
import React from 'react';
import { compose } from 'redux-v3';
import { Field, SubmissionError, reduxForm, reset } from 'redux-form';
import validate from 'validate.js';

import { requestCreditIncrease } from 'actions/factoring';
import { openModal, closeModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';

import PennyInput from 'components/pure/form/inputs/PennyInput';
import segmentEvents from '../../../helpers/segmentEvents';

export const FORM_NAME = 'CREDIT_INCREASE_FORM';

export default compose(
  reduxForm({
    form: FORM_NAME,
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch, { debtor }) {
      try {
        await dispatch(
          requestCreditIncrease({
            ...fields,
            id: debtor.relationship_id
          })
        );
        dispatch(closeModal());
        dispatch(
          openModal('warning', {
            message:
              'We are checking their credit now and will alert you of approval winthin 1 business day. Thank you.'
          })
        );
        window.analytics.track(segmentEvents.USER_REQUESTED_CREDIT_INCREASE_CUSTOMER, { debtor_id: debtor.id, relationship_id: debtor.relationship_id });
        dispatch(reset(FORM_NAME));
      } catch (e) {
        dispatch(closeModal());
        dispatch(
          openModal('error', {
            message: 'Error while requesting to increase credit amount.'
          })
        );
      }
    },
    validate: fields =>
      validate(fields, {
        amount: {
          presence: true
        }
      })
  })
)(({ handleSubmit, submitting, debtor = {} }) => (
  <div>
    <p>Customer Name: {debtor.company_name}</p>
    <p>Currect Available Credit: {formatPennies(debtor.credit_available)}</p>
    <p>
      Please enter the amount of addional credit you would like this increased
      by
    </p>
    <Field
      name="amount"
      component={PennyInput}
      type="email"
      label="Request Credit Increase Amount"
    />
  </div>
));
