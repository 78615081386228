/* global process */
import { trackType } from '../helpers/segmentAnalytics';

// react-router-redux page change event type
const PAGE_CHANGE = '@@router/LOCATION_CHANGE';
const required = param =>
  new Error(
    `${param} is required for segment anaytics tracking [segmentanalytics.js]`,
  );

/**
 * track currrent page using react-router-redux events;
 * @param {object} action redux action
 */
function autoTrackPages(action) {
  if (action.type === PAGE_CHANGE) {
    window.analytics.page(action.payload.pathname);
  }
}

/**
 * track events and their properites to be shared in segment
 * @param {*} { event, properties } event: event name, properties: event properties
 */
function track({ event, properties }) {
  if (!event) {
    return required('event');
  }
  window.analytics.track(event, properties);
}

/**
 * track user's identity
 *
 * @param {*} { userId, traits } required userId and traits to be passed along
 */
function identify({ userId, traits }) {
  window.analytics.identify(userId, traits);
}

/**
 * @description manually track pages and include extra properties
 * @param {*} payload
 */
function page(payload) {
  window.analytics.page(payload);
}

/**
 * track group the user belongs to. i.e. drivers, owners.
 * @param {*} { groupId, traits }
 */
function group({ groupId, traits }) {
  if (!groupId) {
    return required('groupId');
  }
  window.analytics.group(groupId, traits);
}

const trackReducer = ({ type, payload }) => {
  const tracked = {
    [trackType.PAGE]: page,
    [trackType.TRACK]: track,
    [trackType.IDENTIFY]: identify,
    [trackType.GROUP]: group,
  }[type](payload);
  // warn if a required params is missing
  if (tracked instanceof Error && process.env.NODE_ENV !== 'production') {
    console.warn(tracked);
  }
};

/**
 * redux middleware used to track events, identity, and pages that are sent to segment.
 */
export default () => next => action => {
  autoTrackPages(action);
  if (action.meta && action.meta.analytics) {
    const trackData = action.meta.analytics;
    if (Array.isArray(trackData)) {
      trackData.forEach(data => trackReducer(data));
    }
    else {
      trackReducer(trackData);
    }
  }
  return next(action);
};
