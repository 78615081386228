import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { closeModal } from 'actions/ui';
import formatPennies from 'helpers/formatPennies';
import { Field, change, formValueSelector, reduxForm, reset } from 'redux-form';
import validate from 'validate.js';
import { isNumber } from 'lodash';
import { lifecycle, withState } from 'recompose';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import LabeledDropdown from 'components/pure/form/inputs/LabeledDropdown';
import ResponsiveTable from 'components/pure/ResponsiveTable';
import createTableItems from 'helpers/createTableItems';
import { getLineItemCategories } from 'actions/lineitemcategories';
import NumberInput from 'components/pure/form/inputs/NumberInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import { createLineItemByFundingRequest, getLineItemsByFundingRequest } from 'actions/lineitems';
import MaterialIcon from 'components/pure/MaterialIcon';
import { getInvoice } from 'actions/invoice';

const formatStatus = status => {
  switch (status) {
    case 'approved':
      return 'Approved';
    case 'pending':
      return 'Pending';
    default:
      return status;
  }
};

const formatRecievableStatus = status => {
  switch (status) {
    case 'open':
      return 'Open';
    case 'paid':
      return 'Paid';
    case 'short_paid':
      return 'Short Paid';
    case 'write_off':
      return 'Write Off';
    case 'double_paid':
      return 'Double Paid';
    case 'client_settled':
      return 'Client Settled';
    case 'over_paid':
      return 'Over Paid';
    default:
      return status;
  }
};

const FORM_NAME = 'DEBTOR_PAYMENT_LINE_ITEMS';

const afterSubmit = (result, dispatch) =>
  dispatch(reset(FORM_NAME));

const TableItems = createTableItems(({ data }) => {
  return [
    formatPennies(data.amount_requested),
    data.discount_rate && isNumber(data.discount_rate) && `${data.discount_rate}%`,
    data.category_name,
    data.description,
    formatStatus(data.approval_status),
  ];
});

const LineItemsTable = ({ lineItems }) => (
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={false}
    headers={[
      'Amount',
      'Discount Rate',
      'Category',
      'Description',
      'Approved',
    ]}
    placeholder='No Line Items'
    TableItem={TableItems.TableItem}
    BlockTableItem={TableItems.BlockTableItem}
    items={lineItems.map(lineItem => ({
      data: lineItem,
    }))}
  />
);

const selector = formValueSelector(FORM_NAME);

export default compose(
  connect(
    state => {
      return {
        againstWho: selector(state, 'applied_against'),
      };
    },
    (dispatch, props) => ({
      getInvoice: frid => dispatch(getInvoice(props.debtorId, frid)),
      closeModal: () => dispatch(closeModal()),
      getLineItemCategories: () => dispatch(getLineItemCategories()),
      getLineItemsByFundingRequest: id => dispatch(getLineItemsByFundingRequest(id)),
      clearSelectedCategory: () => dispatch(change(FORM_NAME, 'category', null)),
    })
  ),
  withState('categories', 'setCategories', []),
  withState('selectedInvoice', 'setSelectedInvoice', null),
  withState('balances', 'setBalances', {}),
  withState('lineItems', 'setLineItems', []),
  withState('localInvoices', 'setLocalInvoices', []),
  withState('fetchingLineItems', 'setFetchingLineItems', false),
  withState('frid', 'setFRID', undefined),
  lifecycle({
    async componentDidMount() {
      const categories = await this.props.getLineItemCategories();
      this.props.setCategories(categories.map(category => ({
        text: category.name,
        value: category.id,
        applied_against: category.applied_against,
      })));

      this.props.setLocalInvoices(this.props.invoices);
    },
    componentShouldUpdate() {
      return true;
    },
    async componentDidUpdate(prevProps) {
      const {
        setFetchingLineItems,
        selectedInvoice,
        getLineItemsByFundingRequest,
        setLineItems,
      } = this.props;
      if ((selectedInvoice !== prevProps.selectedInvoice) && selectedInvoice) {
        setFetchingLineItems(true);
        const lineItems = await getLineItemsByFundingRequest(selectedInvoice);
        setLineItems(lineItems);
      }
    },
  }),
  reduxForm({
    form: FORM_NAME,
    async onSubmit(fields, dispatch, props) {
      fields.approval_status = fields.approved ? 'approved' : 'pending';
      const response = await dispatch(createLineItemByFundingRequest(props.frid)(fields));
      const updated = await props.getInvoice(props.frid);
      const filtered = props.localInvoices.filter(invoice => invoice.id === updated.id);
      filtered.push(updated);
      props.setLocalInvoices(filtered.map(invoice => ({ data: invoice })));
      props.balances[response.funding_request] = updated.outstanding_balance;
      props.setBalances(props.balances);
    },
    onSubmitSuccess: afterSubmit,
    initialValues: {
      approved: true,
    },
    validate: (fields, props) => {
      return validate(fields, {
        amount_requested: {
          presence: true,
        },
        category: {
          presence: true,
        },
        applied_against: {
          presence: true,
        },
      });
    },
  },
  ))(({
  handleSubmit,
  invoices,
  setInvoices,
  localInvoices,
  setLocalInvoices,
  done,
  closeModal,
  categories,
  selectedInvoice,
  setSelectedInvoice,
  balances,
  setBalances,
  lineItems,
  setLineItems,
  setFRID,
  getLineItemsByFundingRequest,
  againstWho,
  clearSelectedCategory,
}) => (
  <div>
    <div className='modal-header'>
      <button
        type='button' className='close' aria-label='Close' onClick={() => {
          closeModal(); done();
        }}
                                                           ><span aria-hidden='true'>×</span></button>
      <h4 className='modal-title'>
        Adjustments
      </h4>
    </div>
    <div className='modal-body' style={{ whiteSpace: 'pre-line' }}>
      {
        localInvoices.map(invoice => {
          const balance = invoice.data.outstanding_balance;
          if (!isNumber(balances[invoice.data.id])) {
            balances[invoice.data.id] = balance;
          }
          setBalances(balances);
          return (
            <div>
              {!selectedInvoice && (
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '8px' }}>
                  <h4>Invoice #{invoice.data.invoice_number}</h4>
                  <h4 style={{ display: 'flex' }}>
                    <div>{formatRecievableStatus(invoice.data.receivable_status)}: </div>
                    <div>{formatPennies(Math.abs(invoice.data.outstanding_balance))}</div>
                  </h4>
                  {balances[invoice.data.id] === 0 ?
                    <div><MaterialIcon name='check' style={{ color: 'green', fontSize: '32px' }} /></div>
                    :
                    <button
                      className='btn btn-orange' onClick={() => {
                        setFRID(invoice.data.id);
                        setSelectedInvoice(invoice.data.id);
                      }}
                                                 >Adjust</button>
                  }

                </div>
              )}
              {selectedInvoice === invoice.data.id &&
                <div>
                  <div>
                    <h4 style={{ display: 'flex' }}>
                      <div>{formatRecievableStatus(invoice.data.receivable_status)}: </div>
                      {formatPennies(Math.abs(invoice.data.outstanding_balance))}
                    </h4>
                    <hr />
                    <form onSubmit={handleSubmit}>
                      <Field name='amount_requested' component={PennyInput} label='Amount' onChange={value => console.log(value)} />
                      <Field name='discount_rate' ComponentClass={NumberInput} component={LabeledInput} label='Discount Rate' />
                      <Field
                        name='applied_against'
                        placeholder='Select'
                        component={LabeledDropdown}
                        data={[
                          { text: 'Client', value: 'client' },
                          { text: 'Debtor', value: 'debtor' },
                          { text: 'ComFreight', value: 'organization' },
                          { text: 'Proof', value: 'proof' },
                        ]}
                        label='Against Who?'
                        onChange={clearSelectedCategory}
                      />
                      { againstWho && (
                        <Field
                          name='category'
                          placeholder='Select Category'
                          component={LabeledDropdown}
                          data={categories.filter(category => category.applied_against === againstWho)}
                          label='Category'
                        />
                      )}
                      <Field
                        name='description'
                        component={LabeledInput}
                        label='Description'
                        ComponentClass='textarea'
                        style={{
                          height: '150px',
                        }}
                      />
                      <Field name='approved' component={CheckboxInput} label='Approved' />
                    </form>
                    <div
                      style={{ float: 'right' }}
                      >
                      <button
                        onClick={async () => {
                          await handleSubmit();
                          const lineItems = await getLineItemsByFundingRequest(selectedInvoice);
                          setLineItems(lineItems);
                        }}
                        className='btn btn-orange'
                        type='submit'
                        disabled={balances[invoice.data.id] === 0}
                        >
                        Add Line Item
                      </button>
                    </div>
                    <br />
                    <h4>Line Items</h4>
                    <hr />
                    <LineItemsTable lineItems={lineItems} />
                  </div>
                </div>
              }
            </div>
          );
        })
      }
    </div>
    <div className='modal-footer'>
      {selectedInvoice ?
        <div>
          <button
            className='btn btn-orange'
            onClick={() => {
              setFRID(null);
              setSelectedInvoice(null);
            }}
            >
            Next
          </button>
        </div>
        :
        <div>
          <button
            className='btn btn-orange'
            disabled={Object.values(balances).filter(balance => balance !== 0).length > 0}
            onClick={() => {
              closeModal();
              done();
            }}
            >
            Done
          </button>
        </div>
      }
    </div>
  </div>
));
