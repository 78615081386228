/**
 * Higher order component. Displays a spinner if the given condition is truthy
 * @module components/hoc/spinnerIf
 * @since 3.0.0
 * @requires components/pure/Spinner
 * @param {function} cond - truthy checking function, gets passed the component props
 * @param {object} [options]
 * @param {Component} [options.spinner=[Spinner]{@link module:components/pure/Spinner}] - the spinner to display
 */
import React from 'react';
import { compose } from 'redux-v3';
import branch from 'recompose/branch';
import renderComponent from 'recompose/renderComponent';
import setDisplayName from 'recompose/setDisplayName';

import Spinner from 'components/pure/Spinner';


const DEFAULT_OPTIONS = {
  spinner: Spinner,
};

export default function spinnerIf(cond, options) {
  options = {
    ...DEFAULT_OPTIONS,
    ...options,
  };
  if (!cond) {
    throw new TypeError(`spinnerIf requires a conditional, got: ${cond}`);
  }

  return compose(
    setDisplayName('spinnerIf'),
    branch(
      cond,
      renderComponent(() => (
        <div className='container'>
          <options.spinner />
        </div>
      )),
      Component => Component,
    ),
  );
}
