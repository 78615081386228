/**
 * Reducer for handling the admin debtorcombinednotes actions
 * @module reducers/admin/debtorcombinednotes
 * @since 3.0.0
 * @requires INITIAL_STATE
 * @requires actions/admin/debtorcombinednotes
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_SUCCESS
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_FETCH_REQUEST_FAILURE
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_SET_SORT
 * @listens module:datatypes/PaginatedResource~PaginatedResource#PAGINATED_CLEAR
 */
import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.debtorcombinednotes;

import debtorcombinednotesResource from 'actions/admin/debtorcombinednotes';


export default function debtorcombinednotesReducer(state = initialState, action) {
  state = debtorcombinednotesResource.reduce(state, action);
  return state;
}
