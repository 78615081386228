/**
 * Actions for the factoring debtor actions
 * @module actions/admin/factoring/debtor
 * @since 3.0.1
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/factoringapplication
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import factoringlineitemResource from 'actions/resource/factoringlineitem';


const factoringlineitemPaginatedResource = new PaginatedResource('admin_factoring_lineitem', {
  mountPoint: ['admin', 'items'],
  url: new UrlAssembler('funding_requests/:id/items'),
  baseResource: factoringlineitemResource,
});

export default factoringlineitemPaginatedResource;
