import 'styles/FactoringSignUpMarketing';
import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Link } from 'react-router-v4';
import { lifecycle } from 'recompose';

import Header from 'components/pure/Header';
import YoutubeEmbed from 'components/pure/YoutubeEmbed';
import MediaQuery from 'components/pure/MediaQuery';
import MaterialIcon from 'components/pure/MaterialIcon';
import IframeResizer from 'react-iframe-resizer-super';
import VimeoEmbed from 'components/pure/VimeoEmbed';
import get from 'lodash';

const SignupPageButton = compose(
  connect(
    state => ({ logged_in: state && state.user && state.user.logged_in, userType: get(state, ['resource', get(state, 'user.id', ''), 'data', 'type'], '') }),
  ),
)(({ logged_in }) =>
  logged_in ?
    <Link to='/haul-pay/join'><button className='btn btn-orange' type='button'>Join Haul Pay</button></Link>
    :
    <a href='https://app.haulpay.co/signup/carrier'><button className='btn btn-orange' type='button'>Try Haul Pay</button></a>
);

// hack to make scrolling to this anchor work cross-browser (wasnt working in chrome)
const CirclesAnchor = compose(
  lifecycle({
    componentDidMount() {
      setTimeout(() => {
        const hash = window.decodeURIComponent(window.location.hash);
        if (hash === '#circles') {
          const container = document.querySelector('.App__children');
          const this_element = document.getElementById('circles');
          container.scrollTop = this_element.offsetTop;
        }
      });
    },
  }),
)(() => <hr id='circles' />);

const CircleSection = ({ headerText, text, image, alt, reverse = false, noButton = false }) =>
  reverse ?
    <MediaQuery
      mobile={
        <div className='factoring-signup-circlesection'>
          <div className='factoring-signup-circlesection-image'>
            <div className='factoring-signup-circlesection-image-container'>
              {typeof image === 'string' ? <img src={image} alt={alt} /> : image}
            </div>
            <div className='factoring-signup-circlesection-image-gradient' />
            <div className='factoring-signup-circlesection-image-blur' style={{ backgroundImage: typeof image === 'string' ? `url("${image}")` : undefined }} />
          </div>
          <div className='factoring-signup-circlesection-text'>
            <Header>{headerText}</Header>
            <p>{text}</p>
            {noButton ? null : <div><SignupPageButton /></div>}
          </div>
        </div>
      }
      desktop={
        <div className='factoring-signup-circlesection'>
          <div className='factoring-signup-circlesection-text'>
            <Header>{headerText}</Header>
            <p>{text}</p>
            {noButton ? null : <div><SignupPageButton /></div>}
          </div>
          <div className='factoring-signup-circlesection-image text-right'>
            <div className='factoring-signup-circlesection-image-container'>
              {typeof image === 'string' ? <img src={image} alt={alt} /> : image}
            </div>
            <div className='factoring-signup-circlesection-image-gradient' />
            <div className='factoring-signup-circlesection-image-blur' style={{ backgroundImage: typeof image === 'string' ? `url("${image}")` : undefined }} />
          </div>
        </div>
      }
    />
    :
    <div className='factoring-signup-circlesection'>
      <div className='factoring-signup-circlesection-image'>
        <div className='factoring-signup-circlesection-image-container'>
          {typeof image === 'string' ? <img src={image} alt={alt} /> : image}
        </div>
        <div className='factoring-signup-circlesection-image-gradient' />
        <div className='factoring-signup-circlesection-image-blur' style={{ backgroundImage: typeof image === 'string' ? `url("${image}")` : undefined }} />
      </div>
      <div className='factoring-signup-circlesection-text'>
        <Header>{headerText}</Header>
        <p>{text}</p>
        {noButton ? null : <div className='text-right'><SignupPageButton /></div>}
      </div>
    </div>
  ;

const GrayBox = ({ topText, bottomText, undertext }) =>
  <div className='factoring-signup-graybox-container'>
    <div className='factoring-signup-graybox'>
      <div>{topText}</div>
      <div>{bottomText}</div>
    </div>
    <p className='text-center' style={{ fontSize: 14, margin: '1em 0' }}>
      {(Array.isArray(undertext) ? undertext : [undertext]).map(text =>
        <div>
          <MaterialIcon name='check' className='text-success' style={{ fontWeight: 'bold' }} />
          {' '}
          {text}
        </div>
      )}
    </p>
  </div>
  ;
const FormGrid = ({ children, title, text, header }) =>
  <div className='factoring-signup-form'>
    <div className='factoring-signup-form-text'>
      <div className='title'>{title}</div>
      <p>{text}</p>
    </div>
    <div className='factoring-signup-form-iframe'>
      <div>{header}</div>
      {children}
    </div>
  </div>
;
const BenefitsGridItem = ({ title, body, image, alt }) =>
  <div className='col-xs-12 col-sm-4' style={{ padding: '30px 50px' }}>
    <div className='text-center'>
      <div>
        <img src={image} alt={alt} style={{ maxWidth: '100%' }} />
      </div>
      <Header small={true}><b>{title}</b></Header>
      <p>{body}</p>
    </div>
  </div>
  ;

const FactoringSignUpMarketing = compose(
)(({ form, logged_in, noVideos, showForm, imageLocation = 'public/image', location: { pathname = '/haul-pay' }, userType = 'carrier', ...props }) =>
  <div className='factoring-signup-marketing'>
    {/*{((!pathname.includes('/postload/haulpay') && !pathname.includes('/haul-pay')) || !logged_in) &&*/}
    {/*  <style*/}
    {/*    dangerouslySetInnerHTML={{ __html: `*/}
    {/*      .App__children { position: unset !important; }*/}
    {/*  ` }}*/}
    {/*  />*/}
    {/*}*/}
    <div>
      <div className='factoring-signup-marketing-logo text-center' style={{ margin: '2em 0' }}>
        <img style={{ maxWidth: '100%', padding: '1em' }} src={`${imageLocation}/comfreight haul pay.png`} />
        {
          logged_in ?
            <div>
            Get Full Access to our Load Board
              <br />
            for Free When You Join Haul Pay.
            </div>
            :
            <div className='text-orange' style={{ fontSize: 20 }}><b>(888) 633-5558</b></div>
        }
      </div>
      {
        logged_in ?
          <div className='text-center'>
            <SignupPageButton />
            {
              noVideos ? null :
                userType === 'broker' ? <VimeoEmbed video_id='383207316' /> :
                <VimeoEmbed video_id='317604150' />
            }
          </div>
          :
          <div className='text-center' style={{ margin: '0 -38px' }}>
            {
              noVideos ? null :
                userType === 'broker' ? <VimeoEmbed video_id='383207316' /> :
                <YoutubeEmbed video_id='O727LCYFG-Y' mute={false} />
            }
          </div>
      }
      <CirclesAnchor />
      <CircleSection
        image={`${imageLocation}/haul pay trucker male.png`}
        alt='Trucking Factoring'
        headerText='Fastest Load Payments'
        text={userType === 'carrier' || !logged_in ? 'Haul Pay is a new type of factoring that gets you the fastest payments for all of your hauls!' : 'HaulPay enables you pay carriers as fast as the same day with unlimited capital!'}
        reverse={true}
      />
      <div className='factoring-signup-graybox-container-container'>
        <GrayBox
          topText='Low'
          bottomText='Flat Rate per Load'
          undertext={[
            'No long-term contracts.',
            'No early termination fees.',
          ]}
        />
        <GrayBox
          topText='24 Hrs.'
          bottomText={userType === 'carrier' || !logged_in ? 'To Get Paid' : 'Carrier Pay'}
          undertext={[
            'Available same day pay.',
            'Easy payment requests.',
          ]}
        />
        <GrayBox
          topText='Zero'
          bottomText='Extra Fees'
          undertext={userType === 'carrier' || !logged_in ? [
            'No account minimums.',
            'No reserve holdbacks.',
          ] : [
            'No account minimums.',
            'Free Booking Mobile App.',
          ]
          }
        />
      </div>
      <p className='text-center' style={{ fontSize: 14, marginBottom: '1em' }}>
        {userType === 'carrier' || !logged_in ? 'Special rates for larger volumes available.' : 'You can pass the quick pay fee off to the carrier or choose to offset it to your shipper making the program free!'}
      </p>
      {
        (userType === 'carrier' || !logged_in) &&
        <div className='factoring-signup-blurb'>
          <p>Haul Pay is true Non-Recourse. Most factoring companies who claim to be "nonrecourse" have ways of coming after your assets. We also do not hold a reserve. Once you're paid, you're paid!</p>
        </div>
      }
      {
        (userType === 'carrier' || !logged_in) &&
          <div>
            <hr />
            <CircleSection
              image={`${imageLocation}/haul pay trucker male 2.png`}
              alt='Freight Factoring'
              headerText='Manage Payments 24/7'
              text='Request payments instantly for your hauls from any device, anytime. Send a copy of your signed BOL and Rate Confirmation to get paid.'
            />
            <hr />
            <CircleSection
              image={`${imageLocation}/quick pay haul pay trucker.png`}
              alt='Trucker Factoring'
              headerText='Ditch Old School Factoring'
              text='Say goodbye to complicated factoring programs, hidden fees and quick pay delays. Get same day funding at a low flat rate for all of your loads.'
              reverse={true}
            />
            <hr />
            <CircleSection
              image={`${imageLocation}/quick pay trucking.png`}
              alt='Faster Freight Payments'
              headerText='Fast Free Credit Checks'
              text={'Most companies that you haul for are already approved for credit. Our technology enables us to approve your new customers in real time.'}
              noButton={!logged_in}
            />
            <hr id='getstarted' style={{ marginBottom: '4em' }} />
            {
              showForm ?
                <div className='factoring-signup-form-container' >
                  <div className='text-center'>
                    <Header style={{ fontSize: 25 }}>We Help You Find Loads Too</Header>
                    <p className='factoring-signup-blurb text-left'>
                      Haul Pay users get free full access to our advanced load board and freight marketplace as well as digital docs and our Haul Pay mobile app.
                    </p>
                    {logged_in ? null : <div><SignupPageButton /></div>}
                  </div>
                  <hr id='getstarted' style={{ marginBottom: '4em' }} />
                </div>
                : null
            }
            <div className='text-center'>
              <Header style={{ fontSize: 25 }}>More Benefits</Header>
              <p className='factoring-signup-blurb text-left'>
                Haul Pay is more than faster payments. Our app creates invoices for you. We also help with your cutomer onboarding and much more. We're a true partner. Let's build a business together!
              </p>
            </div>
            <div className='row'>
              <BenefitsGridItem
                title='Fastest Payments'
                body='Payments arrive in your bank account the next day.'
                image={`${imageLocation}/1 Day Freight Payments.png`}
                alt='1 Day Freight Payments'
              />
              <BenefitsGridItem
                title='Free Credit Checks'
                body='Instant free credit checks via web and mobile apps.'
                image={`${imageLocation}/Free Freight Credit Checks.png`}
                alt='Free Freight Credit Checks'
              />
              <BenefitsGridItem
                title='Free Load Board'
                body='Free full access to the HaulPay load board.'
                image={`${imageLocation}/Free Load Board.png`}
                alt='Free Load Board'
              />
            </div>
            <div className='row'>
              <BenefitsGridItem
                title='Dedicated Support'
                body='Enjoy a dedicated account manager and support team.'
                image={`${imageLocation}/Dedicated Account Manager.png`}
                alt='Dedicated Account Manager'
              />
              <BenefitsGridItem
                title='Fuel Advances'
                body='After a short history, unlock 50% advances at the time of pickup.'
                image={`${imageLocation}/Freight Fuel Advances.png`}
                alt='Freight Fuel Advances'
              />
              <BenefitsGridItem
                title='Fuel Discounts'
                body='Available fuel card with line and $0.10 off per gallon.'
                image={`${imageLocation}/Fuel Discounts.png`}
                alt='Fuel Discounts'
              />
            </div>
            <div className='row'>
              <BenefitsGridItem
                title='Free Payment Software'
                body='Real-time alerts for payment status and history tracking.'
                image={`${imageLocation}/Free Factoring Software.png`}
                alt='Free Factoring Software'
              />
              <BenefitsGridItem
                title='Web and Mobile App'
                body='Request payments and track status from anywhere, 24/7.'
                image={`${imageLocation}/Web and Mobile App.png`}
                alt='Web and Mobile App'
              />
              <BenefitsGridItem
                title='Equipment & Tires'
                body='Available equipment financing options and tire discounts.'
                image={`${imageLocation}/Trucking Tire Discounts.png`}
                alt='Trucking Tire Discounts'
              />
            </div>
            <div className='text-center'><SignupPageButton /></div>
            <hr />
            <p className='factoring-signup-blurb'><b>Do you currently have a factoring company?</b> Let us check on the hidden fees and obligations that might be in your current factoring arrangement. Contact us to learn more.</p>
            <div className='text-center' style={{ marginBottom: '2em' }}>
              <Header>
                Call Us Toll Free <span style={{ display: 'inline-block', fontWeight: 'bold' }}>(888) 633-5558</span>
                <p>Para uso en español extensión 2704</p>
              </Header>
            </div>
          </div>
      }

    </div>
  </div>
);

export default FactoringSignUpMarketing;
