/**
 * Connected modal component to prompt a user to log in
 * @module components/pure/modals/LoginPrompt
 * @since 3.0.0
 * @requires actions/ui
 * @requires components/pure/modals/Warning
 * @property {object} props
 * @property {any} [props....rest] - props passed to containing ModalWarning component
 */
import React from 'react';
import { Link } from 'react-router-v4';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { closeModal } from 'actions/ui';

import ModalWarning from './Warning';


const LoginPrompt = compose(
  connect(
    null,
    dispatch => ({
      closeModal: () => dispatch(closeModal()),
    }),
  ),
  mapProps(props => ({
    ...props,
    message: props.message || 'Please log in to use this feature.',
    buttonChildren: 'Cancel',
    extraButtons: [
      <Link to='/login'><button className='btn btn-primary' onClick={props.closeModal}>Log in</button></Link>,
    ],
  }))
)(ModalWarning);

export default LoginPrompt;
