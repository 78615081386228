import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import moment from 'moment';
import { Field, reduxForm } from 'redux-form';
import validate from 'validate.js';
import lifecycle from 'recompose/lifecycle';

import PermissionsError from 'datatypes/PermissionsError';
import { openModal } from 'actions/ui';
import containsEmail from 'helpers/containsEmail';
import containsPhone from 'helpers/containsPhone';

import ExpandingInput from 'components/pure/form/inputs/ExpandingInput';
import LineItemHorizontal from 'components/pure/LineItemHorizontal';


export const QALineItem = ({ label, time, user, children, ...rest }) =>
  <LineItemHorizontal
    {...rest}
    label={
      <div>
        <div style={{ fontSize: '1.3em' }}><b>{label}</b></div>
        <div style={{ fontSize: '0.75em' }}>{time.format('M/DD/YY')}</div>
        <div style={{ fontSize: '0.75em' }}>{time.format('H:mm')}</div>
        {
          user ?
            <div style={{ fontSize: '0.75em' }}>
              <a target='_blank' href={`/user/${user}`}>View Profile</a>
            </div>
            : null
        }
      </div>
    }
    >
    {children}
  </LineItemHorizontal>
  ;

const HASH_ID_PREFIX_QUESTION = 'question-';
const HASH_ID_PREFIX_ANSWER = 'answer-';

export const QAForm = ({ handleSubmit, submitting, qa, isOwner }) =>
  <form style={{ marginBottom: '1em' }} onSubmit={handleSubmit}>
    <QALineItem
      label='Q'
      time={qa.time_posted}
      style={{ backgroundColor: '#fff' }}
      id={`${HASH_ID_PREFIX_QUESTION}${qa.id}`}
      user={qa.user}
      >
      {qa.question}
    </QALineItem>
    {
      qa.answer_set && qa.answer_set.length ?
        qa.answer_set.map((answer, i) =>
          <QALineItem
            key={i}
            label='A'
            time={answer.time_posted}
            style={{ backgroundColor: '#EFEFEF' }}
            id={`${HASH_ID_PREFIX_ANSWER}${answer.id}`}
            >
            {answer.answer}
          </QALineItem>
        )
        :
        isOwner ?
          <Field
            name='answer'
            onlyValidateAfterSubmit={true}
            component={ExpandingInput}
            inputProps={{
              placeholder: 'Answer this question...',
            }}
            buttonProps={{
              disabled: submitting,
            }}
            buttonText='Enter Answer'
          />
          : null
    }
  </form>
  ;

QAForm.propTypes = {
  qa: PropTypes.shape({
    question: PropTypes.string.isRequired,
    time_posted: PropTypes.instanceOf(moment).isRequired,
    answer_set: PropTypes.arrayOf(PropTypes.shape({
      answer: PropTypes.string.isRequired,
      time_posted: PropTypes.instanceOf(moment).isRequired,
    })).isRequired,
  }).isRequired,
  isOwner: PropTypes.bool.isRequired,
};

export const RFQAForm = compose(
  reduxForm({
    destroyOnUnmount: true,
    validate(fields) {
      return validate(fields, {
        answer: {
          presence: true,
        },
      });
    },
  }),
  lifecycle({
    componentDidMount() {
      const hash = window.decodeURIComponent(window.location.hash);
      const exists = hash.indexOf(HASH_ID_PREFIX_QUESTION) !== -1;
      if (exists) {
        document.querySelector(hash).scrollIntoView();
      }
    },
  }),
)(QAForm);

export const QuestionForm = ({ handleSubmit, submitting }) =>
  <form className='form form-group' onSubmit={handleSubmit}>
    <Field
      name='question'
      onlyValidateAfterSubmit={true}
      component={ExpandingInput}
      inputProps={{
        placeholder: 'Ask a new question...',
      }}
      buttonProps={{
        disabled: submitting,
      }}
      buttonText='Send Message'
    />
  </form>
  ;

export const RFQuestionForm = compose(
  connect((state, ownProps) => ({
    onSubmit(fields, dispatch) {
      const { logged_in } = state.user;
      if (!logged_in) {
        return dispatch(openModal('loginprompt'));
      }
      return ownProps.onSubmit(fields, dispatch)
        .catch(err => {
          if (err instanceof PermissionsError) {
            return dispatch(openModal('upgradeaccount'));
          }
          console.warn(err);
          dispatch(openModal('error', { message: err.message || err.detail || 'Failed to post message.' }));
        })
      ;
    },
  })),
  reduxForm({
    validate(fields) {
      const errs = validate(fields, {
        question: {
          presence: true,
        },
      });
      if (containsEmail(fields.question) || containsPhone(fields.question)) {
        errs.question = 'Your profile is viewable to other parties when you submit a question or a bid. Please do not include contact info in your questions/messages here. Thank you.';
      }
      return errs;
    },
  }),
)(QuestionForm);
