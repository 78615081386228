/**
 * Generic class for controlled single-resource-related fetching and serialization
 * @module datatypes/ControlledResource
 * @since 3.0.0
 * @requires datatypes/Resource
 * @requires datatypes/APIFetch
 * @requires datatypes/FetchError
 */
/*global API */
import omit from 'lodash/omit';

import Resource from './Resource';
import APIFetch from './APIFetch';
import FetchError from './FetchError';


/**
 * ControlledResource class
 * @extends module:datatypes/Resource~Resource
 */
class ControlledResource extends Resource {

  /**
   * Create a ControlledResource
   * @param {string} name - the unique identifier for this Resource
   * @param {object} [options]
   * @param {UrlAssembler} options.url - the UrlAssembler object for this ControlledResource's endpoint
   * @param {number} [options.acceptableCreateStatusCode] - the HTTP status code that the ControlledResource uses to confirm a successful create request
   * @param {number} [options.acceptableEditStatusCode] - the HTTP status code that the ControlledResource uses to confirm a successful edit request
   * @param {number} [options.acceptableDeleteStatusCode] - the HTTP status code that the ControlledResource uses to confirm a successful delete request
   * @param {any} [options....rest] - any other properties to be placed on the `options` property
   */
  constructor(name, options) {
    super(name, options);

    this.options = {
      ...this.options,
      acceptableCreateStatusCode: 201,
      acceptableEditStatusCode: 200,
      acceptableDeleteStatusCode: 204,
      ...options,
    };

    const upperName = this.name.toUpperCase();
    this.actions = {
      ...this.actions,
      CREATE_REQUEST: Symbol(`${upperName}_CREATE_REQUEST`),
      CREATE_REQUEST_SUCCESS: Symbol(`${upperName}_CREATE_REQUEST_SUCCESS`),
      CREATE_REQUEST_FAILURE: Symbol(`${upperName}_CREATE_REQUEST_FAILURE`),
      EDIT_REQUEST: Symbol(`${upperName}_EDIT_REQUEST`),
      EDIT_REQUEST_SUCCESS: Symbol(`${upperName}_EDIT_REQUEST_SUCCESS`),
      EDIT_REQUEST_FAILURE: Symbol(`${upperName}_EDIT_REQUEST_FAILURE`),
      DELETE_REQUEST: Symbol(`${upperName}_DELETE_REQUEST`),
      DELETE_REQUEST_SUCCESS: Symbol(`${upperName}_DELETE_REQUEST_SUCCESS`),
      DELETE_REQUEST_FAILURE: Symbol(`${upperName}_DELETE_REQUEST_FAILURE`),
    };

    this.create = this.create.bind(this);
    this.edit = this.edit.bind(this);
    this.transfer = this.transfer.bind(this);
    this.doDelete = this.doDelete.bind(this);
  }

  /**
   * Create request action. Marks that a request to create a ControlledResource has been made
   * @event module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
   * @property {symbol} type - Symbol({NAME}_CREATE_REQUEST)
   */
  /**
   * Create request success action. Marks that a request to create a ControlledResource has been made successfully
   * @event module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_CREATE_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  /**
   * Create request failure action. Marks that a request to create a ControlledResource has failed
   * @event module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_CREATE_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully creating this ControlledResource, or an error
   * @fires module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
   * @fires module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
   * @fires module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
   */
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, null);

      if (item && item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.CREATE_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url('', data)}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableCreateStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        return dispatch({
          type: this.actions.CREATE_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.CREATE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  /**
   * Edit request action. Marks that a request to edit a ControlledResource has been made
   * @event module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
   * @property {symbol} type - Symbol({NAME}_EDIT_REQUEST)
   */
  editRequest(id) {
    return {
      type: this.actions.EDIT_REQUEST,
      payload: id,
    };
  }
  /**
   * Edit request success action. Marks that a request to edit a ControlledResource has been made successfully
   * @event module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_EDIT_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  editRequestSuccess(id, json) {
    return {
      type: this.actions.EDIT_REQUEST_SUCCESS,
      payload: { id, json },
    };
  }
  /**
   * Edit request failure action. Marks that a request to edit a ControlledResource has failed
   * @event module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_EDIT_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  editRequestFailure(id, err) {
    return {
      type: this.actions.EDIT_REQUEST_FAILURE,
      payload: { id, err },
    };
  }
  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully editing this ControlledResource, or an error
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
   * @fires module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
   */
  edit(id, data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url(id, data)}/`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableEditStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.EDIT_REQUEST_FAILURE,
            payload: { id, err },
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: { id, json },
        }))
        ; // eslint-disable-line indent
    };
  }

  transfer(id, data) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, data.transferred_to);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: id,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url(id, data)}/`, {
        method: 'PATCH',
        body: JSON.stringify(data),
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableEditStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : res.json())
        .catch(err => {
          dispatch({
            type: this.actions.EDIT_REQUEST_FAILURE,
            payload: { id, err },
          });
          return Promise.reject(err);
        })
        .then(json => dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: { id, json },
        }))
        ; // eslint-disable-line indent
    };
  }

  /**
   * Delete request action. Marks that a request to delete a ControlledResource has been made
   * @event module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
   * @property {symbol} type - Symbol({NAME}_DELETE_REQUEST)
   */
  /**
   * Delete request success action. Marks that a request to delete a ControlledResource has been made successfully
   * @event module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
   * @property {symbol} type - Symbol({NAME}_DELETE_REQUEST_SUCCESS)
   * @property {object} payload - the data returned from the server
   */
  /**
   * Delete request failure action. Marks that a request to delete a ControlledResource has failed
   * @event module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
   * @property {symbol} type - Symbol({NAME}_DELETE_REQUEST_FAILURE)
   * @property {object} payload - the error that occurred
   */
  /**
   * @returns {Promise<Action>} A promise that resolves in the action dispatched as a result of successfully deleting this ControlledResource, or an error
   * @fires module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
   * @fires module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
   * @fires module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
   */
  doDelete(id) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.DELETE_REQUEST,
        payload: id,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url(id)}/`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableDeleteStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : undefined)
        .catch(err => {
          dispatch({
            type: this.actions.DELETE_REQUEST_FAILURE,
            payload: { id, err },
          });
          return Promise.reject(err);
        })
        .then(() => dispatch({
          type: this.actions.DELETE_REQUEST_SUCCESS,
          payload: { id, item },
        }))
        ; // eslint-disable-line indent
    };
  }

  doDelete2(funding_request_id, id) {
    return (dispatch, getState) => {
      const state = getState();
      const item = this.getItem(state, id);

      if (item.isFetching) {
        return Promise.resolve();
      }
      dispatch({
        type: this.actions.DELETE_REQUEST,
        payload: id,
      });

      return dispatch(APIFetch(`${API.host}/${this.options.url(funding_request_id, id)}`, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${state.user.token}`,
          'Content-Type': 'application/json',
        },
      }))
        .then(res => res.status !== this.options.acceptableDeleteStatusCode ? res.text().then(text => Promise.reject(new FetchError(res.status, text))) : undefined)
        .catch(err => {
          dispatch({
            type: this.actions.DELETE_REQUEST_FAILURE,
            payload: { id, err },
          });
          return Promise.reject(err);
        })
        .then(() => dispatch({
          type: this.actions.DELETE_REQUEST_SUCCESS,
          payload: { id, item },
        }))
        ; // eslint-disable-line indent
    };
  }

  /**
   * The reducer for this ControlledResource. Manages state changes triggered by actions specific to this ControlledResource.
   * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST
   * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_SUCCESS
   * @listens module:datatypes/ControlledResource~ControlledResource#CREATE_REQUEST_FAILURE
   * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST
   * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_SUCCESS
   * @listens module:datatypes/ControlledResource~ControlledResource#EDIT_REQUEST_FAILURE
   * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST
   * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_SUCCESS
   * @listens module:datatypes/ControlledResource~ControlledResource#DELETE_REQUEST_FAILURE
   * @param {object} state - the current state of this ControlledResource
   * @param {object} action - the dispatched action
   * @returns The new state for this ControlledResource
   */
  reduce(state, action) {
    state = super.reduce(state, action);
    switch (action.type) {
      case this.actions.CREATE_REQUEST:
        return {
          ...state,
          [null]: {
            ...this.parse(),
            isFetching: true,
          },
        };
      case this.actions.CREATE_REQUEST_SUCCESS:
        return {
          ...omit(state, 'null'),
          [action.payload.id]: {
            ...this.parse(state.null, action.payload),
            isFetching: false,
            err: null,
          },
        };
      case this.actions.CREATE_REQUEST_FAILURE:
        return {
          ...state,
          [null]: {
            ...this.parse(),
            isFetching: false,
            err: action.payload,
          },
        };
      case this.actions.EDIT_REQUEST:
        return {
          ...state,
          [action.payload]: {
            ...state[action.payload],
            isFetching: true,
          },
        };
      case this.actions.EDIT_REQUEST_SUCCESS:
        return {
          ...state,
          [action.payload.id]: {
            ...this.parse(state[action.payload.id], action.payload.json),
            isFetching: false,
            err: null,
          },
        };
      case this.actions.EDIT_REQUEST_FAILURE:
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            isFetching: false,
            err: action.payload.err,
          },
        };
      case this.actions.DELETE_REQUEST:
        return {
          ...state,
          [action.payload]: {
            ...state[action.payload],
            isFetching: true,
          },
        };
      case this.actions.DELETE_REQUEST_SUCCESS:
        return omit(state, action.payload.id);
      case this.actions.DELETE_REQUEST_FAILURE:
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            isFetching: false,
            err: action.payload.err,
          },
        };
    }
    return state;
  }

}

export default ControlledResource;
