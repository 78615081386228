/**
 * Higher order component that displays nothing (returns null) if the resource given by `itemPropName` is fetching
 * @module components/hoc/nothingIfFetching
 * @since 3.0.0
 * @requires components/hoc/nothingIf
 * @param {string} [itemPropName = 'item'] - the prop name of the resource to check
 */
import { compose } from 'redux-v3';
import setDisplayName from 'recompose/setDisplayName';

import nothingIf from 'components/hoc/nothingIf';


export const nothingIfFetching = (itemPropName = 'item') => compose(
  setDisplayName('nothingIfFetching'),
  nothingIf(props => props[itemPropName] === undefined || props[itemPropName].isFetching),
);

export default nothingIfFetching;
