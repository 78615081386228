/**
 * Component to display a pair of formatted city-state pairs
 * @module components/pure/LocationFormat
 * @since 3.0.1
 * @requires helpers
 * @requires components/pure/LocationFormat
 * @property {object} props
 * @property {string} props.origin_city - the origin city
 * @property {string} props.origin_state - the destination state
 * @property {any} [props....rest] - props passed to containing component
 */
import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';

import { inferTruckRouteType } from 'helpers';

import MaterialIcon from 'components/pure/MaterialIcon';
import AddressesFormat from 'components/pure/AddressesFormat';


const RouteFormat = ({
  origin = [],
  destination = [],
  truncation_limit,
  displayed_location_separator,
  tooltip_location_separator,
  displayed_location_prefix,
  tooltip_location_prefix,
  ignore = {
    address: true,
    street_one: false,
    zip: false,
    country: false,
  },
  ...rest
}) => {
  const [
    formatted_origin,
    formatted_destination,
  ] = [origin, destination].map(addresses =>
    <AddressesFormat
      truncation_limit={truncation_limit}
      displayed_location_separator={displayed_location_separator}
      tooltip_location_separator={tooltip_location_separator}
      displayed_location_prefix={displayed_location_prefix}
      tooltip_location_prefix={tooltip_location_prefix}
      addresses={addresses}
      ignore={ignore}
    />
  );

  return (
    <span {...rest} className={cs('route-format', rest.className)}>
      {formatted_origin}
      {' '}
      <MaterialIcon name='arrow_forward' style={{ color: colors.INPUT_BLACK }} />
      {' '}
      {formatted_destination}
    </span>
  );
};

RouteFormat.propTypes = {
  origin: PropTypes.arrayOf(PropTypes.object).isRequired,
  destination: PropTypes.arrayOf(PropTypes.object).isRequired,
};

/**
 * RouteFormat that infers origin and destination given a truck shape
 * @since 3.0.1
 * @property {object} props
 * @property {object} props.truck
 * @property {object} props.truck.data
 */
const RouteFormatTruck = ({ truck, color = colors.INPUT_BLACK }) => {
  let { origin, origin_radius, destination, destination_radius } = inferTruckRouteType(truck.data);
  if (origin !== 'Anywhere' && origin_radius !== 'Any') {
    origin = `${origin} (${origin_radius})`;
  }
  if (destination !== 'Anywhere' && destination_radius !== 'Any') {
    destination = `${destination} (${destination_radius})`;
  }
  return (
    <div
      style={{
        fontSize: '1.3em',
      }}
      >
      {origin}
      {' '}
      <MaterialIcon name='arrow_forward' size={22} style={{ color }} />
      {' '}
      {destination}
    </div>
  );
};

RouteFormatTruck.propTypes = {
  truck: PropTypes.shape({
    data: PropTypes.object.isRequired,
  }).isRequired,
};

RouteFormat.Truck = RouteFormatTruck;

export default RouteFormat;
