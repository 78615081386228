import React, { Component } from 'react';
import { connect } from 'react-redux-v5';
import { get } from 'lodash';
import { compose } from 'redux-v3';

import groups from 'actions/admin/factoring/groups';
import getSelf from 'components/hoc/getSelf';

import FactoringRequestPaymentFormBroker from './FactoringRequestPaymentFormBroker';
import FactoringRequestPaymentFormClient from './FactoringRequestPaymentFormClient';
import Spinner from 'components/pure/Spinner';
import { USER_TYPE } from 'helpers';
import selectBrokerGroups from 'selectors/selectBrokerGroups';

class FactoringRequestPayment extends Component {
  async componentDidMount() {
    await this.props.getGroups();
  }

  render() {
    const userType = this.props.item.data.type;
    const { brokerGroups, loading, location } = this.props;

    const id = get(location, 'query.id', undefined);

    if (!loading) {
      if (brokerGroups.length > 0 && USER_TYPE.BROKER === userType) {
        return (<FactoringRequestPaymentFormBroker id={id} />);
      }
      return (<FactoringRequestPaymentFormClient />);

    }
    return <Spinner />;

  }
}

const mapStateToProps = state => ({
  groups: state.resource.factoringGroups,
  brokerGroups: selectBrokerGroups(state),
  loading: state.admin.factoring.groups.isFetching || state.admin.factoringGroupUsers.isFetching,
});

const mapDispatchToProps = dispatch => ({
  getGroups: () => dispatch(groups.fetch()),
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  getSelf
)(FactoringRequestPayment);
