/* global API */
import UrlAssembler from 'url-assembler';

import APIFetch from 'datatypes/APIFetch';
import FetchError from 'datatypes/FetchError';
import APIError from 'datatypes/error/APIError';
import ControlledPaginatedResourceCollection from 'datatypes/ControlledPaginatedResourceCollection';
import ControlledPaginatedResource from 'datatypes/ControlledPaginatedResource';
import factoringpaymentnoteResource from 'actions/resource/factoringpaymentnote';


class FactoringPaymentNotesControlledPaginatedResource extends ControlledPaginatedResource {
  create(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isCreating) {
        return Promise.resolve();
      }
      dispatch(this.createRequest());

      const sent_data = {
        ...data,
        factoring_payment_id: undefined,
        attachments: data.attachments ? data.attachments instanceof FileList ? Array.from(data.attachments).map(attachment => ({
          filename: attachment.name,
        })) : [{ filename: data.attachments.name }] : undefined,
      };

      try {
        const res = await dispatch(APIFetch(`${API.host}/funding_requests/${data.factoring_payment_id}/notes/`, {
          method: 'POST',
          body: JSON.stringify(sent_data),
          headers: {
            authorization: `Bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));
        if (res.status !== this.options.acceptableCreateStatusCode) {
          const text = await res.text();
          throw new FetchError(res.status, text);
        }
        const json = await res.json();
        await Promise.all(json.attachments.map(({ upload_url: { url, fields } }) => {
          const formData = new FormData();
          for (const key in fields) {
            if (fields.hasOwnProperty(key)) {
              formData.append(key, fields[key]);
            }
          }
          if (data.attachments instanceof FileList) {
            Array.from(data.attachments).forEach(attachment => {
              formData.append('file', attachment);
            });
          }
          else {
            formData.append('file', data.attachments);
          }
          return global.fetch(url, {
            method: 'POST',
            body: formData,
          });
        }));
        dispatch(this.createRequestSuccess(json));
      }
      catch (err) {
        dispatch(this.createRequestFailure(err));
        throw err;
      }
    };
  }

  edit(data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isEditing) {
        return;
      }
      dispatch({
        type: this.actions.EDIT_REQUEST,
        payload: undefined,
      });

      const sent_data = {
        ...data,
        factoring_payment_id: undefined,
        attachments: data.attachments ? data.attachments instanceof FileList ? Array.from(data.attachments).map(attachment => ({
          filename: attachment.name,
        })) : [{ filename: data.attachments.name }] : undefined,
      };

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url}/${data.id}/`, {
          method: 'PATCH',
          body: JSON.stringify(sent_data),
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableEditStatusCode) {
          throw new APIError(res.status, await res.text());
        }
        const json = await res.json();

        if (data.attachments) {
          await Promise.all(json.attachments.slice(-data.attachments.length).map(({ upload_url: { url, fields } }) => {
            const formData = new FormData();
            for (const key in fields) {
              if (fields.hasOwnProperty(key)) {
                formData.append(key, fields[key]);
              }
            }
            if (data.attachments instanceof FileList) {
              Array.from(data.attachments).forEach(attachment => {
                formData.append('file', attachment);
              });
            }
            else {
              formData.append('file', data.attachments);
            }
            return global.fetch(url, {
              method: 'POST',
              body: formData,
            });
          }));
        }

        dispatch({
          type: this.actions.EDIT_REQUEST_SUCCESS,
          payload: json,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.EDIT_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }

  doDelete(id, data) {
    return async (dispatch, getState) => {
      const state = getState();
      const mystate = this.getState(state);

      if (mystate.isDeleting) {
        return;
      }
      dispatch({
        type: this.actions.DELETE_REQUEST,
        payload: undefined,
      });

      try {
        const res = await dispatch(APIFetch(`${API.host}/${this.options.url}/${id}/`, {
          method: 'DELETE',
          body: JSON.stringify(data),
          headers: {
            authorization: `bearer ${state.user.token}`,
            'content-type': 'application/json',
          },
        }));

        if (res.status !== this.options.acceptableDeleteStatusCode) {
          const text = await res.text();
          throw new APIError(res.status, text);
        }
        dispatch({
          type: this.actions.DELETE_REQUEST_SUCCESS,
          payload: id,
        });
      }
      catch (err) {
        dispatch({
          type: this.actions.DELETE_REQUEST_FAILURE,
          payload: err,
        });
        throw err;
      }
    };
  }
}

class FactoringPaymentNoteByFactoringPaymentControlledPaginatedResourceCollection extends ControlledPaginatedResourceCollection {
  getResourceType() {
    return FactoringPaymentNotesControlledPaginatedResource;
  }
}

export const factoringpaymentnoteByFactoringpaymentResource = new FactoringPaymentNoteByFactoringPaymentControlledPaginatedResourceCollection('factoringpayment', 'factoringpaymentnote', {
  url: new UrlAssembler('funding_requests/:id/notes'),
  paginatedResourceOptions: {
    append_to_top_on_create: true,
    acceptableDeleteStatusCode: 200,
    baseResource: factoringpaymentnoteResource,
  },
});

export default factoringpaymentnoteByFactoringpaymentResource;
export const {
  fetch,
  create,
  edit,
  doDelete,
} = factoringpaymentnoteByFactoringpaymentResource;
