import React from 'react';
import { withState } from 'recompose';

import MaterialIcon from 'components/pure/MaterialIcon';

const Drawer = ({ title, expanded, setExpanded, children }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}
    >
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        borderTop: '1px',
      }}
      >
      <div
        style={{
          width: '100%',
        }}
        >
        {title}
      </div>
      <div
        style={{
          userSelect: 'none',
        }}
        onClick={() => setExpanded(!expanded)}
        >
        <MaterialIcon size={60} name={expanded ? 'arrow_drop_up' : 'arrow_drop_down'} />
      </div>
    </div>
    <div
      style={{
        borderTop: '1px solid #979797',
      }}
      >
      <div
        style={{
          transition: 'all .5s ease-in-out',
          maxHeight: expanded ? '100%' : '0',
          overflow: 'hidden',
        }}
        >
        {children}
      </div>
    </div>
  </div>
);

export default withState('expanded', 'setExpanded', true)(Drawer);
