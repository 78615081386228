import { CloseOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button } from '@haulpay/ui';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';

const columns = [
    {
        title: 'Status',
        key: 'status',
        ellipsis: true,
    },
    {
        title: 'Phone number',
        key: 'phone_number',
        render: (data) => data?.phone_number,
        ellipsis: true,
    },
    {
        title: 'Email',
        key: 'email',
        render: (data) => data?.email,
        ellipsis: true,
    },
    {
        title: 'Last Invited',
        key: 'last_invited',
        render: (data) => moment(data?.last_invited).format('l LTS'),
        ellipsis: true,
    },
    {
        render: () => (
            <Row gutter={[8, 8]}>
                <Col span={11}>
                    <Button type="primary" icon={<PlusOutlined />}>
                        Create Payment Profile
                    </Button>
                </Col>
                <Col span={3}>
                    <Button type="primary" danger icon={<CloseOutlined />} />
                </Col>
                <Col span={5}>
                    <Button type="primary" icon={<ReloadOutlined />}>
                        Resend
                    </Button>
                </Col>
            </Row>
        ),
        key: 'actions',
    },
];
export default columns;
