import React, { Component } from 'react';

import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { change, formValueSelector, reset, submit } from 'redux-form';
import { Link, browserHistory } from 'react-router-v4';
import { replace } from 'react-router-redux';
import NavItem from 'react-bootstrap/lib/NavItem';
import { get, head, pick, startCase, toLower } from 'lodash';
import moment from 'moment';
import { lifecycle, mapProps, withHandlers, withState } from 'recompose';
import FactoringLineItems from './FactoringLineItems';
import { USER_TYPE, trailerTypeMap } from 'helpers';
import formatPennies from 'helpers/formatPennies';
import { edit, fetchCollated, fetchInvoice } from 'actions/resource/factoringpayment';
import { openModal } from 'actions/ui';
import { currentUser } from 'actions/user';
import { getAggregate, getGiveBackReserve } from 'actions/reserve';
import nothingIf from 'components/hoc/nothingIf';
import normalizeFormPhone from 'helpers/normalizeFormPhone';
import isDispatchBroker from 'selectors/isDispatchBroker';
import isAnyAdmin from 'selectors/isAnyAdmin';
import formatAddressFields from 'helpers/formatAddressFields';
import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import ResourceByIDs from 'components/hoc/ResourceByIDs';
import wrapPromisePending from 'components/hoc/wrapPromisePending';

import FactoringClientNotes from 'components/container/FactoringClientNotes';
import CenteredColumn from 'components/pure/CenteredColumn';
import Header from 'components/pure/Header';
import { CenteredSpinner } from 'components/pure/Spinner';
import StatefulTabbed from 'components/stateful/StatefulTabbed';
import EventLog from 'components/pure/EventLog';

import ImageCropper from 'components/pure/ImageCropper';
import formatError from 'helpers/formatError';
import MaterialIcon from 'components/pure/MaterialIcon';
import getSelfType from 'selectors/getSelfType';
import sleep from 'helpers/sleep';

import ClientDebtorNOA from './ClientDebtorNOA';
import FactoringPaymentNotes from './FactoringPaymentNotes';
import FactoringPaymentEditForm from './FactoringPaymentEditForm';
import FactoringPaymentsList from './FactoringPaymentsList';
import FactoringPurchasesList from './FactoringPurchasesList';
import TransactionAndLineItemsAttachmentsTable from './TransactionAndLineItemsAttachmentsTable';

import convertToString from 'helpers/convertAttachmentCategoriesToString';
import awsImagesUpload from 'helpers/awsImageUpload';
import DebtorPastCredit from '../../pure/Modals/DebtorPastCredit';

const formSelector = formValueSelector('FactoringPaymentEditForm');

const selectUserId = props => get(props, 'item.data.factoring.user.id');

class FactoringPaymentsAdminEdit extends Component {
  state = {
    attachmentToCrop: null,
    reserveTotal: null,
  };

  refetchInterval = null;

  async componentDidMount() {
    this.refetchIfAttachmentsNotUploaded();

    try {
      const userId = get(this.props, 'item.data.factoring.user.id');
      const aggregate = await get(this.props, 'getAggregate', async () => ({}))(userId);

      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        reserveTotal: aggregate.positive_available + aggregate.negative_available,
        negativePending: aggregate.negative_pending,
        positivePending: aggregate.positive_pending,
      });
    }
    catch (err) {
      
    }
  }

  async componentDidUpdate(prevProps) {
    const userId = selectUserId(this.props);

    const prevUserId = selectUserId(prevProps);

    this.refetchIfAttachmentsNotUploaded();


    if (userId !== prevUserId) {
      const aggregate = await get(this.props, 'getAggregate', async () => ({}))(userId);
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        reserveTotal: aggregate.positive_available + aggregate.negative_available,
        positiveAvailable: aggregate.positive_available,
        negativeAvailable: aggregate.negative_available,
        negativePending: aggregate.negative_pending,
        positivePending: aggregate.positive_pending,
      });
    }
  }

  refetch = () => {
    const { refetchResource, item } = this.props;
    const shouldRefetch = typeof refetchResource === 'function' && Array.isArray(get(item, 'data.attachments')) && item.data.attachments.length && item.data.attachments.some(attachment => !(attachment.failed === true) && !attachment.uploaded);
    if (shouldRefetch) {
      refetchResource();
    }
    return shouldRefetch;
  };

  refetchIfAttachmentsNotUploaded() {
    if (!this.refetchInterval) {
      this.refetchInterval = setTimeout(() => {
        const shouldRefetch = this.refetch();
        clearTimeout(this.refetchInterval);
        this.refetchInterval = null;
        if (shouldRefetch) {
          this.refetchIfAttachmentsNotUploaded();
        }
      }, 5000);
    }
  }

  handleAttachmentCropRequest = attachment => {
    this.setState({
      attachmentToCrop: attachment,
    });
  };

  handleAttachmentCropCancel = () => {
    this.setState({
      attachmentToCrop: null,
    });
  };

  handleAttachmentCropResult = croppedImage => {
    const { attachmentToCrop } = this.state;
    const { currentCroppedAttachments = [] } = this.props;
    this.setState({
      attachmentToCrop: null,
    });

    const currentDatetime = moment().format('YYYYMMDDHHmmss');
    const filenameParts = attachmentToCrop.filename.split('.');
    const filenamePure = filenameParts.slice(0, -1).join('.').replace(/_\d{14}_edited/g, '');
    const filenameExtension = filenameParts[filenameParts.length - 1];
    const newFilename = `${filenamePure}_${currentDatetime}_edited.${filenameExtension}`;
    croppedImage.filename = newFilename;
    croppedImage.name = newFilename;
    croppedImage.category = attachmentToCrop.category;
    croppedImage.visible_to = attachmentToCrop.visible_to;
    this.props.setCroppedAttachments(currentCroppedAttachments.concat(croppedImage));
  };

  render() {
    const {
      tabIndex,
      onSubmit,
      line_items,
      submitting,
      item,
      triggerEdit,
      triggerDelete,
      deleteAttachment,
      fetchInvoice,
      fetchCollated,
      usertype,
      openPaymentModal,
      openPurchaseModal,
      scrollLineItemsIntoView,
      updateReserveLineItems,
      reserveLineItems,
      setReserveLineItems,
      isDispatchBroker,
      isAnyAdmin,
      openPastCreditModal,
      amount,
      status,
    } = this.props;
    const { attachmentToCrop } = this.state;

    const userId = selectUserId(this.props);

    const lineItems = line_items.length;
    const pendingLineItems = line_items.filter(lineItem => lineItem.approval_status === 'pending').length;
    let lastDocIssueDate = null;
    const statusTimeline = get(item, 'data.status_timeline', []);
    const lastStatus = statusTimeline[statusTimeline.length - 1];

    if (lastStatus && lastStatus.status === 'document_issue') {
      lastDocIssueDate = new Date(lastStatus.status_set_datetime);
    }
    const creditLeft = amount - get(item, 'data.debtor.credit_available', 0);

    return (
      <CenteredColumn xs={12} md={8} style={{ paddingBottom: '150px' }}>
        <div className='row'>
          <Header>
            <h3 className='col-lg-6 col-sm-6 col-md-6' style={{ color: '#2196f3', marginLeft: '1%' }}><b>Funding Request</b></h3>
            {isAnyAdmin && <h3 className='col-lg-6 col-sm-6 col-md-6' style={{ color: 'red' }} hidden={get(item, 'data.factoring.aladdin_owned')}><b>**Transferred to Comfreight**</b></h3>}
            {
              get(item, 'data.fuel_advance_amount') ? <h5 className='text-success col-lg-12 col-sm-12 col-md-12' style={{ fontStyle: 'italic' }}>Client requested a fuel advance</h5> : null
            }
          </Header>
        </div>
        <br />
        {item && item.data && item.data.payment_method === 'wire' ? (
          <p className='text-danger'>
            <b>Wire Transfer Requested</b>
          </p>
        ) : null}
        {item && item.data ? (
          <FactoringPaymentEditForm
            userId={userId}
            onSubmit={onSubmit}
            scrollLineItemsIntoView={scrollLineItemsIntoView}
            disableAll={item.data.status === 'paid' && usertype !== USER_TYPE.ADMIN && !['joanna@comfreight.com', 'raisa@comfreight.com'].includes(this.props.adminEmail)}
            initialValues={{
              ...pick(item.data, [
                'amount',
                'invoice_number',
                'user_reference_number',
                'first_origin_location',
                'final_destination_location',
                'load_length',
                'load_trailer_type',
                'debtor',
                'user_notes',
                'fuel_advance_amount',
                'fuel_advance_fee',
                'fuel_advance_rate',
                'net_worth',
                'bill_to_company_email',
                'days_to_pay',
                'amount_funded',
                'status',
                'status_note',
                'user_load_number',
                'payment_method',
                'assigned_admin',
                'unpaid_purchase_amount',
                'invoice_notes',
              ]),
              commodity: Number(item.data.commodity) || 0,
              freight_class: Number(item.data.freight_class) || 0,
              reserve_total: this.state.reserveTotal,
              positive_pending: this.state.positivePending,
              negative_pending: this.state.negativePending,
              positive_available: this.state.positiveAvailable,
              negative_available: this.state.negativeAvailable,
              bill_to_address: formatAddressFields(item.data.bill_to_address),
              bill_to_company_phone: String(item.data.bill_to_company_phone).split(';')[0],
              bill_to_company_phone_ext: String(item.data.bill_to_company_phone).split(';')[1],
              debtor_credit_approved: item.data.debtor.credit_approved === 'approved' ? 'Credit Approved' : 'Not Approved',
              created: get(item, 'data.created') ? moment(item.data.created).format('l LT') : undefined,
              source: startCase(toLower(item.data.source)),
              funded_at: item.data.funded_at ? item.data.funded_at.format('l LT') : '',
              discount_rate: `${item.data.discount_rate}%`,
              attachments: [],
            }}
            item={item}
            billToCompanyAdditionalComponent={item && item.data && (
              <ClientDebtorNOA debtorId={item.data.debtor.id} factoringId={item.data.factoring.id} fundingRequestId={item.data.id} />
            )}
            factoring={item.data.factoring}
            accounts={get(item, 'data.accounts', [])}
            lineItems={lineItems}
            pendingLineItems={pendingLineItems}
            attachments={
              <div className='text-right'>
                {attachmentToCrop &&
                  <ImageCropper
                    filename={attachmentToCrop.filename}
                    file={attachmentToCrop.download_url}
                    onCrop={this.handleAttachmentCropResult}
                    onCancel={this.handleAttachmentCropCancel}
                  />
                }
                <TransactionAndLineItemsAttachmentsTable
                  id={item.data.id}
                  attachments={item.data.attachments}
                  lastDocIssueDate={lastDocIssueDate}
                  onDeleteClick={({ id }) => deleteAttachment(item.data.id, id)}
                  onEditClick={this.handleAttachmentCropRequest}
                  fetchInvoice={() => fetchInvoice(item.data.id)}
                  fetchCollated={() => fetchCollated(item.data.id)}
                />
              </div>
            }
            refresh={() => updateReserveLineItems(userId)}
            reserveLineItems={reserveLineItems}
            clearReserveLineItems={() => {
              setReserveLineItems([]);
            }}
          />
        ) : (
          <CenteredSpinner />
        )}
        <div className='text-right'>
          {get(item, 'data.status') === 'fuel_advance' && (
            <button style={{ backgroundColor: '#f50057', height: '35px', color: 'white', marginRight: 5 }} type='button' onClick={() => openPurchaseModal(item.data.id)} >
              <MaterialIcon name='payment' />
              Make a Purchase
            </button>
          )}
          {get(item, 'data.status') === 'paid' && (
            <button style={{ backgroundColor: '#3ca8ff', height: '35px', color: 'white' }} type='button' onClick={() => openPaymentModal(item.data.id)} >
              <MaterialIcon name='attach_money' />
              Record a Payment
            </button>
          )}
          {get(item, 'data.debtor.id') && ![USER_TYPE.FACTORING_REMOTE_ADMIN].includes(usertype) && isAnyAdmin && (
            <Link to={`/admin/factoring/debtor/${item.data.debtor.id}`}>
              <button
                type='button'
                style={{ backgroundColor: '#21bb00', height: '35px', color: 'white' }}
                >
                <MaterialIcon name='account_box' /> Debtor
              </button>
            </Link>
          )}
          {get(item, 'data.factoring.id') && ![USER_TYPE.FACTORING_REMOTE_ADMIN].includes(usertype) && (
            <Link to={`/admin/factoring/client/${item.data.factoring.id}`}>
              <button
                type='button'
                style={{ backgroundColor: '#21bb00', marginLeft: '5px', height: '35px', color: 'white' }}
                >
                <MaterialIcon name='account_circle' />
                {' '}
                Client
              </button>
            </Link>
          )}
          {get(item, 'data.status') !== 'paid' && get(item, 'data.status') !== 'approved' && get(item, 'data.status') !== 'remote_approved' && (
            <button
              className='btn-Delete'
              onClick={triggerDelete}
              type='button'
              style={{ marginLeft: '5px' }}
              >
              <MaterialIcon name='delete' />
              Delete
            </button>
          )}
          {get(item, 'data.needs_admin_review') && usertype && usertype === USER_TYPE.ADMIN ? (
            <button className='btn btn-success' type='button' onClick={triggerEdit} disabled={submitting} style={{ marginBottom: 5 }}>
              {' '}
              Approve Changes{' '}
            </button>
          ) :
            <button
              className='btn-transaction'
              onClick={() => {
                if (status === 'approved'
                  && status !== get(item, 'data.status', 'new')
                  && creditLeft > 0) {
                  openPastCreditModal({ availableCredit: get(item, 'data.debtor.credit_available', 0), amount });
                }
                else {
                  triggerEdit();
                }
              }}
              disabled={submitting}
              type='button'
              >
              <MaterialIcon name='save' />
             Update{' '}
            </button>}
        </div>
        {get(item, 'data.factoring.id') && (
          <div style={{ margin: '2em 0', minHeight: '300px' }}>
            <StatefulTabbed
              activeKey={tabIndex}
              navItems={[
                <NavItem>Internal Transaction Notes</NavItem>,
                <NavItem>Internal Client Notes</NavItem>,
                <NavItem>Audit Trail</NavItem>,
                <NavItem>Purchases</NavItem>,
                <NavItem>Reserve</NavItem>,
                <NavItem>Line Items</NavItem>,
              ]}
              items={[
                <FactoringPaymentNotes id={item.data.id} />,
                <FactoringClientNotes id={item.data.factoring.id} />,
                <EventLog
                  events={item.data.audit_log}
                  transform={(data, i, arr) => {
                    const key_is_invalid =
                      [
                        'funding_request_last_edited_by_name',
                        'funding_request_last_edited_by_id',
                        'funding_request_modified',
                        'funding_request_assigned_admin_id',
                        'funding_request_debtor_id',
                        'funding_request_last_edited_by_user_type',
                      ].includes(data.original_key) || !data.original_key.startsWith('funding_request_');
                    if (key_is_invalid) {
                      return null;
                    }
                    const label_map = {
                      assigned_admin_name: 'Assigned Admin',
                      invoice_number: 'Our Invoice #',
                      user_reference_number: 'Client Invoice #',
                      first_origin_location: 'First Pick Up',
                      final_destination_location: 'Final Delivery',
                      load_trailer_type: 'Equipment Type',
                      debtor: 'Bill To Company',
                      bill_to_address: 'Bill To Company Address',
                      'bill_to_address.street_one': 'Bill To Company Address : Street One',
                      'bill_to_address.city': 'Bill To Company Address : City',
                      'bill_to_address.state': 'Bill To Company Address : State',
                      'bill_to_address.zip': 'Bill To Company Address : Zip',
                      'bill_to_address.country': 'Bill To Company Address : Country',
                      discount_rate: 'Rate for this Transaction',
                      status: 'Funding Request Status',
                      status_note: 'STATUS Notes',
                      user_load_number: 'Load #',
                      user_notes: 'User Notes',
                      invoice_notes: 'Invoice Notes',
                      assigned_admin: 'Transaction Rep',
                      debtor_name: 'Bill To Company',
                      fuel_advance_amount: 'Fuel Advance Amount',
                      fuel_advance_rate: 'Fuel Advance Rate',
                      fuel_advance_fee: 'Fuel Advance Fee',
                    };
                    const key = data.original_key.replace('funding_request_', '');
                    let { old_value, new_value } = data;
                    const format_value_map = {
                      debtor_name(data) {
                        const id_diff = arr.find(({ original_key }) => original_key === 'funding_request_debtor_id');
                        if (!id_diff) {
                          return data;
                        }
                        data.old_value = <Link to={`/admin/factoring/debtor/${id_diff.old_value}`}>{data.old_value}</Link>;
                        data.new_value = <Link to={`/admin/factoring/debtor/${id_diff.new_value}`}>{data.new_value}</Link>;
                        return data;
                      },
                      needs_admin_review(data) {
                        data.old_value = data.old_value ? 'Yes' : 'No';
                        data.new_value = data.new_value ? 'Yes' : 'No';
                        return data;
                      },
                      load_trailer_type(data) {
                        data.old_value = trailerTypeMap[data.old_value];
                        data.new_value = trailerTypeMap[data.new_value];
                        return data;
                      },
                      amount(data) {
                        data.old_value = formatPennies(data.old_value);
                        data.new_value = formatPennies(data.new_value);
                        return data;
                      },
                      status(data) {
                        const new_data = {
                          ...data,
                        };
                        ['old_value', 'new_value'].forEach(key => {
                          const value = [
                            { text: 'New (NOA)', value: 'new_noa' },
                            { text: 'New', value: 'new' },
                            { text: 'Fuel Advance', value: 'fuel_advance' },
                            { text: 'Pending', value: 'pending' },
                            { text: 'Pending (NOA)', value: 'pending_noa' },
                            { text: 'Pending Originals', value: 'pending_originals' },
                            { text: 'Document Issue', value: 'document_issue' },
                            { text: 'CF Review', value: 'haulpay_review' },
                            { text: 'Document Review', value: 'document_review' },
                            { text: 'Approved', value: 'approved' },
                            { text: 'Incomplete', value: 'incomplete' },
                            { text: 'Remote Approved', value: 'remote_approved' },
                            { text: 'Declined', value: 'declined' },
                            { text: 'Paid', value: 'paid' },
                            { text: 'Deleted', value: 'deleted' },
                            { text: 'New - Fast Track', value: 'new_fasttrack' },
                          ].find(({ value }) => value === data[key]);
                          if (value && value.text) {
                            new_data[key] = value.text;
                          }
                          else {
                            new_data[key] = value;
                          }
                        });
                        return new_data;
                      },
                    };
                    format_value_map.amount_funded = format_value_map.amount;
                    const formatter = format_value_map[key];
                    if (formatter) {
                      ({ old_value, new_value } = formatter(data));
                    }
                    return {
                      ...data,
                      old_value,
                      new_value,
                      key: label_map[key] || data.key.replace('Funding Request ', ''),
                    };
                  }}
                  sort={(a, b) => {
                    const order = [
                      'assigned_admin_name',
                      'amount',
                      'invoice_number',
                      'user_load_number',
                      'user_reference_number',
                      'first_origin_location',
                      'final_destination_location',
                      'load_length',
                      'load_trailer_type',
                      'user_notes',
                      'invoice_notes',
                      'debtor',
                      'bill_to_address',
                      'bill_to_company_email',
                      'bill_to_company_phone',
                      'funded_at',
                      'discount_rate',
                      'amount_funded',
                      'status',
                      'status_note',
                      'payment_method',
                      'attachments',
                    ];
                    // get root key, slicing off `funding_request_`
                    let root_key_a, root_key_b;
                    const a_has_nested = a.original_key.indexOf('.');
                    if (a_has_nested !== -1) {
                      root_key_a = a.original_key.slice(16, a_has_nested);
                    }
                    else {
                      root_key_a = a.original_key.slice(16);
                    }
                    const b_has_nested = b.original_key.indexOf('.');
                    if (b_has_nested !== -1) {
                      root_key_b = b.original_key.slice(16, b_has_nested);
                    }
                    else {
                      root_key_b = b.original_key.slice(16);
                    }
                    const index_a = order.indexOf(root_key_a);
                    const index_b = order.indexOf(root_key_b);
                    // debugger;
                    return index_a > index_b ? 1 : index_b > index_a ? -1 : 0;
                  }}
                />,
                <FactoringPurchasesList id={item.data.id} />,
                <FactoringPaymentsList id={item.data.id} />,
                <FactoringLineItems id={item.data.id} refresh={() => updateReserveLineItems(userId)} />,
              ]}
            />
          </div>
        )}
      </CenteredColumn>
    );
  }
}

export default compose(
  ResourceBy('factoringdebtor', 'lineitemsdebtor', {
    idPropName: ['params', 'id'],
  }),
  nothingIf(({ item }) => !item),
  ResourceByIDs({
    resource: 'factoringdebtor',
    shouldUpdate: (props, nextProps) => {
      return props.item.isFetching !== nextProps.item.isFetching;
    },
  }),
  mapProps(({ items, ...rest }) => ({
    refresh: rest.actions.fetch,
    line_items: items.map(item => item.data),
    ...rest,
  })),
  Resource('factoringpayment', {
    idPropName: ['params', 'id'],
    expirationTime: -Infinity,
  }),
  connect(
    state => ({
      usertype: getSelfType(state),
      currentCroppedAttachments: formSelector(state, 'cropped_attachments'),
      amount: formSelector(state, 'amount'),
      status: formSelector(state, 'status'),
      isDispatchBroker: isDispatchBroker(state),
    }),
    (dispatch, ownProps) => {
      const { item } = ownProps;
      if (!get(item, 'data.id')) {
        return {};
      }
      const { data: { id } } = item;

      return {
        openPastCreditModal(data) {
          return dispatch(openModal('DebtorPastCredit', data));
        },
        setCroppedAttachments(attachments) {
          try {
            return dispatch(change('FactoringPaymentEditForm', 'cropped_attachments', attachments));
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'Couldn\'t crop attachment image at this time. Please try again later.' }));
          }
        },
        fetchInvoice(...args) {
          try {
            return dispatch(fetchInvoice(...args));
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'Couldn\'t fetch invoice at this time. Please try again later.' }));
          }
        },
        fetchCollated(...args) {
          try {
            return dispatch(fetchCollated(...args));
          }
          catch (err) {
            console.warn(err);
            dispatch(openModal('error', { message: 'Couldn\'t fetch pdf at this time. Please try again later.' }));
          }
        },
        triggerEdit() {
          return dispatch(submit('FactoringPaymentEditForm'));
        },
        triggerDelete() {
          return dispatch(
            openModal('confirm', {
              type: 'factoringpayment_delete',
              args: [id],
            })
          );
        },
        deleteAttachment(id, attachment) {
          return dispatch(
            openModal('confirm', {
              type: 'factoringpaymentattachment_delete',
              args: [id, attachment],
            })
          );
        },
        openPaymentModal(id) {
          return dispatch(openModal('factoringtransactionpayment', {
            id: id,
          }));
        },
        openPurchaseModal(id) {
          return dispatch(openModal('factoringtransactionpurchase', {
            id: id,
          }));
        },
        openTransactionModal(id) {
          return dispatch(openModal('factoringtransactionsview', {
            id: id,
          }));
        },
        getAggregate(client_id) {
          return dispatch(getAggregate(client_id));
        },
      };
    }
  ),
  connect(
    state => ({
      isDispatchBroker: isDispatchBroker(state),
      isAnyAdmin: isAnyAdmin(state),
    }),
    {
      getGiveBackReserve,
      currentUser,
    }
  ),
  withState('adminEmail', 'setAdminEmail', ''),
  withState('tabIndex', 'setTabIndex', 0),
  withState('reserveLineItems', 'setReserveLineItems', []),
  withHandlers({
    scrollLineItemsIntoView: props => async () => {
      props.setTabIndex(5);
      await sleep(50);
      const el = document.querySelector('#lineItemTable');
      el.scrollIntoView();
    },
    updateReserveLineItems: props => async userId => {
      await props.refresh();
      const lineItems = await props.getGiveBackReserve(userId);
      props.setReserveLineItems(lineItems);
    },
  }),
  lifecycle({
    async componentDidMount() {
      const userId = selectUserId(this.props);
      const email = await this.props.currentUser();
      this.props.setAdminEmail(email);
      if (userId) {
        await this.props.updateReserveLineItems(userId);
      }
    },
    async componentDidUpdate(prevProps) {
      const prevUserId = selectUserId(prevProps);
      const userId = selectUserId(this.props);

      if (userId && (prevUserId !== userId)) {
        await this.props.updateReserveLineItems(userId);
      }
    },
  }),
  wrapPromisePending(({ item, usertype }) => (fields, dispatch) => {
    if (fields.bol_missing) {
      fields.status_note += '- Missing Bill Of Lading \n';
    }
    if (fields.bol_blurry) {
      fields.status_note += '- Blurry Bill Of Lading \n';
    }
    if (fields.rate_missing) {
      fields.status_note += '- Missing Rate Confirmation \n';
    }
    if (fields.rate_blurry) {
      fields.status_note += '- Blurry Rate confirmation \n';
    }
    if (fields.receipt_missing) {
      fields.status_note += '- Missing Receipt \n';
    }
    if (fields.receipt_blurry) {
      fields.status_note += '- Blurry Receipt \n';
    }

    return dispatch(
      edit(item.data.id, {
        ...(() => {
          if (item.data.amount !== fields.amount) {
            return { amount: fields.amount };
          }
          return {};
        })(),
        ...pick(fields, [
          'invoice_number',
          'user_reference_number',
          'bill_to_company_email',
          'load_length',
          'load_trailer_type',
          'amount_funded',
          'status',
          'status_note',
          'user_load_number',
          'payment_method',
          'user_notes',
          'invoice_notes',
          'fuel_advance_amount',
          'fuel_advance_fee',
          'fuel_advance_rate',
          'commodity',
          'freight_class',
        ]),
        debtor: fields.debtor.id,
        first_origin_location: formatAddressFields(fields.first_origin_location),
        final_destination_location: formatAddressFields(fields.final_destination_location),
        bill_to_address: formatAddressFields(fields.bill_to_address),
        bill_to_company_phone: normalizeFormPhone(fields, 'bill_to_company_phone'),
        attachments: convertToString(fields.attachments),
        cropped_attachments: convertToString(fields.cropped_attachments),
        assigned_admin: get(fields, 'assigned_admin.id'),
      })
    )
      .then(result => {
        const all = get(fields, 'cropped_attachments', []).concat(get(fields, 'attachments', []));
        const attachments = get(result, 'payload.json.attachments', []);
        const files = attachments.map(attachment => {
          const file = head(all.filter(file => file.filename === attachment.filename));
          return {
            upload_url: attachment.upload_url,
            file: file,
          };
        }).filter(({ upload_url, file }) => upload_url && file);

        return awsImagesUpload(files);
      })
      .then(() => {
        if (usertype === USER_TYPE.FACTORING_REMOTE_ADMIN && fields.status === 'remote_approved'){
          return dispatch(replace('/'));
        }
        dispatch(openModal('success', { message: 'Successfully edited transaction' }));
        dispatch(reset('FactoringPaymentEditForm'));
      })
      .catch(err => {
        dispatch(openModal('error', { message: formatError(err, 'Error updating the Funding Request') }));
      });
  }),
)(FactoringPaymentsAdminEdit);
