/**
 * Component to display an item in a table that has a toggleable sibling
 * @module components/pure/ExpandableLineItem
 * @since 3.0.0
 * @property {object} props
 * @property {node} props.children - the main child node
 * @property {node} props.collapsedChild - the toggled sibling
 * @property {boolean} props.open - whether the toggled sibling is displayed
 * @property {function} props.toggleOpen - function to toggle open state, passed to children element
 * @property {any} [props....rest] - props passed to containing component
 */
import React, { cloneElement } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';


const ExpandableLineItem = ({ children, collapsedChild, open, toggleOpen, ...rest }) =>
  <tbody
    {...rest}
    className={cs({ open }, rest.className)}
    style={{ cursor: 'pointer', ...rest.style }}
    >
    {cloneElement(children, {
      onClick: toggleOpen,
    })}
    {
      open ?
        collapsedChild
        : undefined
    }
  </tbody>
  ;

ExpandableLineItem.propTypes = {
  children: PropTypes.node.isRequired,
  collapsedChild: PropTypes.node.isRequired,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

export default ExpandableLineItem;
