/**
 * Container component for the entire App.
 * Renders [LoggedOutContainer]{@link module:components/pure/LoggedOutContainer} if the user is not logged in.
 * Renders [App]{@link module:components/container/App} otherwise.
 * Also serves as the mount point for modals.
 * @module components/container/App
 * @since 3.0.0
 * @requires helpers
 * @requires helpers/IDRegex
 * @requires actions/ui
 * @requires actions/user
 * @requires components/container/Nav
 * @requires components/container/MenuItem
 * @requires components/container/FactoringMenuItem
 * @requires components/container/Modals
 * @requires components/container/AppAlertBar
 * @requires components/pure/Menu
 * @requires components/pure/TieredMenuItem
 * @requires components/pure/LoggedOutContainer
 * @requires components/pure/Spinner
 */
import 'styles/App';
import React, { Component } from 'react';
import { connect } from 'react-redux-v5';
import throttle from 'lodash/throttle';
import get from 'lodash/get';
import cs from 'classnames';

import { USER_TYPE, isMobile } from 'helpers';
import IDRegex from 'helpers/IDRegex';
import { setSidebarOpen } from 'actions/ui';
import { login } from 'actions/user';

import Nav from 'components/container/Nav';
import MenuItem from 'components/container/MenuItem';
import FactoringMenuItem from 'components/container/FactoringMenuItem';
import Modals from 'components/container/Modals';
import AppAlertBar from 'components/container/AppAlertBar';
import Menu from 'components/pure/Menu';
import TieredMenuItem from 'components/pure/TieredMenuItem';
import LoggedOutMenu from 'components/pure/LoggedOutMenu';
import Spinner from 'components/pure/Spinner';
import getSelfType from 'selectors/getSelfType';

export default connect(
  state => ({
    menu_open: state.ui.menu_open,
    logged_in: state.user.logged_in,
    loading: state.user.loading,
    user_type: getSelfType(state),
    user_is_subscribed: get(state.resource.user, [state.user.id, 'data', 'permissions', 'is_subscribed']),
    dont_show_loggedoutmenu: state.ui.dont_show_loggedoutmenu,
    has_api_access: get(state.resource.user, `${state.user.id}.data.tms_external_api_access`, false),
  }),
  dispatch => ({
    login: () => dispatch(login()),
    closeMenu: () => dispatch(setSidebarOpen(false)),
  }),
)(class App extends Component {

  async componentDidMount() {
    const { login, menu_open, closeMenu } = this.props;
    await login();
    window.addEventListener('resize', throttle(() => (
      isMobile() && menu_open && closeMenu()
    ), 300));
  }

  render() { // eslint-disable-line complexity
    const {
      children,
      menu_open,
      logged_in,
      loading,
      user_type,
      closeMenu,
      dont_show_loggedoutmenu,
      user_is_subscribed,
      has_api_access = false,
    } = this.props;
    const has_api_access_tab = user_type === USER_TYPE.BROKER && has_api_access;
    if (loading) {
      return <Spinner />;
    }
    return (
      <div className={cs('App', { Menu_open: menu_open })}>
        {
          user_type === USER_TYPE.CARRIER ||
          user_type === USER_TYPE.SHIPPER ||
          user_type === USER_TYPE.BROKER ||
          user_type === USER_TYPE.DISPATCHER ?
            <AppAlertBar />
            : null
        }
        <div className='App__container'>
          {logged_in && <div className='Menu__backdrop' onClick={closeMenu} />}
          {logged_in &&
            <Menu onCloseClick={closeMenu}>
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <MenuItem href='/admin/dashboard'>Dashboard</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <MenuItem href='/trucksearch'>Truck Search</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <MenuItem href='/loadsearch'>Load Search</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <MenuItem href='/admin/user/search'>User Search</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/user/list/myusers/no_contact'
                    subItems={[
                      ['All Assigned', '/admin/user/list/myusers/no_contact', '/admin/user/list/myusers/first', '/admin/user/list/myusers/second', '/admin/user/list/myusers/third'],
                      ['HP Interest', '/admin/user/list/hp_interested/no_contact', '/admin/user/list/hp_interested/first', '/admin/user/list/hp_interested/second', '/admin/user/list/hp_interested/third'],
                    ]}
                    >
                  My Users
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/user/list/broker/no_contact'
                    subItems={[
                      ['Brokers', '/admin/user/list/broker/no_contact', '/admin/user/list/broker/first', '/admin/user/list/broker/second', '/admin/user/list/broker/third'],
                      ['Carriers', '/admin/user/list/carrier/no_contact', '/admin/user/list/carrier/first', '/admin/user/list/carrier/second', '/admin/user/list/carrier/third'],
                      ['Shippers', '/admin/user/list/shipper/no_contact', '/admin/user/list/shipper/first', '/admin/user/list/shipper/second', '/admin/user/list/shipper/third'],
                      ['Dispatchers', '/admin/user/list/dispatcher/no_contact', '/admin/user/list/dispatcher/first', '/admin/user/list/dispatcher/second', '/admin/user/list/dispatcher/third'],
                      ['Paid Brokers', '/admin/user/list/broker_paid/no_contact', '/admin/user/list/broker_paid/first', '/admin/user/list/broker_paid/second', '/admin/user/list/broker_paid/third'],
                      ['Paid Carriers', '/admin/user/list/carrier_paid/no_contact', '/admin/user/list/carrier_paid/first', '/admin/user/list/carrier_paid/second', '/admin/user/list/carrier_paid/third'],
                      ['Paid Shippers', '/admin/user/list/shipper_paid/no_contact', '/admin/user/list/shipper_paid/first', '/admin/user/list/shipper_paid/second', '/admin/user/list/shipper_paid/third'],
                      ['Paid Dispatchers', '/admin/user/list/dispatcher_paid/no_contact', '/admin/user/list/dispatcher_paid/first', '/admin/user/list/dispatcher_paid/second', '/admin/user/list/dispatcher_paid/third'],
                    ]}
                    >
                  Users
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ||
                user_type === USER_TYPE.ALADDIN_ADMIN ||
                user_type === USER_TYPE.FACTORING_ADMIN ||
                user_type === USER_TYPE.FACTORING_SUB_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/factoring/client/lead_in'
                    subItems={[
                      ['Client Flow', '/admin/factoring/client/lead_in', '/admin/factoring/client/new', '/admin/factoring/client/broker_invited', '/admin/factoring/client/contract_out', '/admin/factoring/client/pending', '/admin/factoring/client/ucc_hold', '/admin/factoring/client/approved', '/admin/factoring/client/declined', '/admin/factoring/client/inactive'],
                      ['Invited Carriers', '/admin/factoring/broker-invited/pending', '/admin/factoring/broker-invited/approved', '/admin/factoring/broker-invited/declined'],
                      ['Client Search', '/admin/factoring/client/search'],
                      ['Soft Leads', '/admin/factoring/client/leads/carrier', '/admin/factoring/client/leads/broker', '/admin/factoring/client/leads/dispatcher'],
                    ]}
                    extraMatchingRoutes={[new RegExp(`/admin/factoring/client/${IDRegex}`)]}
                    >
                  Factor Clients
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.FACTORING_REMOTE_ADMIN || user_type === USER_TYPE.FACTORING_REMOTE_COORDINATOR ?
                  <MenuItem
                    href='/admin/factoring/client/search'
                    >
                Client Search
                  </MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ||
                user_type === USER_TYPE.ALADDIN_ADMIN ||
                user_type === USER_TYPE.FACTORING_ADMIN ||
                user_type === USER_TYPE.FACTORING_SUB_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/factoring/debtor/pending'
                    subItems={[
                      ['Debtor Flow', '/admin/factoring/debtor/pending', '/admin/factoring/debtor/approved', '/admin/factoring/debtor/declined', '/admin/factoring/debtor/needs_review'],
                      ['Debtor Search', '/admin/factoring/debtor/search'],
                      ['Debtor Create', '/admin/factoring/debtor/create'],
                    ]}
                    extraMatchingRoutes={[
                      '/factoring/debtor/create',
                      new RegExp(`/admin/factoring/debtor/${IDRegex}`),
                    ]}
                    >
                  Debtors
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.ALADDIN_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/factoring/transaction/new_noa'
                    subItems={[
                      ['Transactions', '/admin/factoring/transaction/new_noa', '/admin/factoring/transaction/new', '/admin/factoring/transaction/pending', '/admin/factoring/transaction/pending_noa', '/admin/factoring/transaction/pending_originals', '/admin/factoring/transaction/document_issue', '/admin/factoring/transaction/approved', '/admin/factoring/transaction/remote_approved', '/admin/factoring/transaction/declined', '/admin/factoring/transaction/paid'],
                      ['Search', '/admin/factoring/transaction/search'],
                    ]}
                    >
                  Fundings
                  </TieredMenuItem>
                  :
                  user_type === USER_TYPE.FACTORING_REMOTE_ADMIN ?
                    <TieredMenuItem
                      defaultHref='/admin/factoring/transaction/document_review'
                      subItems={[
                        ['Transactions', '/admin/factoring/transaction/document_issue', '/admin/factoring/transaction/document_review', '/admin/factoring/transaction/remote_approved'],
                        ['Search', '/admin/factoring/transaction/search'],
                      ]}
                      >
                  Fundings
                    </TieredMenuItem>
                    :
                    user_type === USER_TYPE.FACTORING_REMOTE_COORDINATOR ?
                      <TieredMenuItem
                        defaultHref='/admin/factoring/transaction/new'
                        subItems={[
                          ['Transactions', '/admin/factoring/transaction/new_noa', '/admin/factoring/transaction/new', '/admin/factoring/transaction/pending', '/admin/factoring/transaction/pending_noa', '/admin/factoring/transaction/document_issue', '/admin/factoring/transaction/remote_approved'],
                          ['Search', '/admin/factoring/transaction/search'],
                        ]}
                        >
                  Fundings
                      </TieredMenuItem>
                      :
                      user_type === USER_TYPE.ADMIN || user_type === USER_TYPE.SUB_ADMIN || user_type === USER_TYPE.FACTORING_ADMIN || user_type === USER_TYPE.FACTORING_SUB_ADMIN ?
                        <TieredMenuItem
                          defaultHref='/admin/factoring/transaction/new_noa'
                          subItems={[
                            ['Transactions', '/admin/factoring/transaction/new_noa', '/admin/factoring/transaction/new', '/admin/factoring/transaction/fuel_advance', '/admin/factoring/transaction/pending', '/admin/factoring/transaction/pending_noa', '/admin/factoring/transaction/pending_originals', '/admin/factoring/transaction/document_issue', '/admin/factoring/transaction/haulpay_review', '/admin/factoring/transaction/document_review', '/admin/factoring/transaction/approved', '/admin/factoring/transaction/remote_approved', '/admin/factoring/transaction/declined', '/admin/factoring/transaction/paid', '/admin/factoring/transaction/incomplete'],
                            ['Search', '/admin/factoring/transaction/search'],
                          ]}
                          >
                  Fundings
                        </TieredMenuItem>
                        :
                        null
              }
              {
                user_type === USER_TYPE.ADMIN ||
                user_type === USER_TYPE.SUB_ADMIN ?
                  <TieredMenuItem
                    defaultHref='/admin/finances/payments'
                    subItems={[
                      ['Receivables', '/admin/finances/payments'],
                      ['Purchases', '/admin/finances/purchases'],
                      ['Line Item Categories', '/admin/finances/lineitems/categories'],
                    ]}
                    >
                  Finances
                  </TieredMenuItem>
                  : null
              }
              {
                USER_TYPE.IS_ADMIN(user_type) || USER_TYPE.IS_ALADDIN_ADMIN(user_type) ?
                  <TieredMenuItem
                    defaultHref='/admin/settings/account'
                    subItems={[
                      ['Account', '/admin/settings/account'],
                    ]}
                    >
                  Settings
                  </TieredMenuItem>
                  : null
              }

              {
                user_type === USER_TYPE.SHIPPER ?
                  <TieredMenuItem
                    defaultHref='/'
                    subItems={[
                      ['Activity', '/'],
                      ['Saved Trucks', '/saved/trucks'],
                    ]}
                    >
                  Timeline
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.CARRIER ?
                  <TieredMenuItem
                    defaultHref='/'
                    subItems={[
                      ['Activity', '/'],
                      ['Saved Loads', '/saved/loads'],
                    ]}
                    >
                  Timeline
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ?
                  <TieredMenuItem
                    defaultHref='/'
                    subItems={[
                      ['Activity', '/'],
                      ['Saved Posts', '/saved/posts/loads', '/saved/posts/trucks'],
                    ]}
                    >
                  Timeline
                  </TieredMenuItem>
                  : null
              }
              <FactoringMenuItem />
              {
                user_type === USER_TYPE.SHIPPER ?
                  <MenuItem href='/postload/all-loads'>Post Load</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ||
                (user_is_subscribed && user_type === USER_TYPE.SHIPPER) ?
                  <MenuItem href='/trucksearch'>Truck Search</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ||
                user_type === USER_TYPE.CARRIER ?
                  <MenuItem href='/loadsearch'>Load Search</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ||
                user_type === USER_TYPE.CARRIER ?
                  <MenuItem href='/posttruck'>Post Truck</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ?
                  <MenuItem href='/postload/all-loads'>Post Load</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.CARRIER ||
                user_type === USER_TYPE.SHIPPER ||
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ?
                  <MenuItem href='/ratetrend'>Rate Trend</MenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.CARRIER ||
                user_type === USER_TYPE.SHIPPER ||
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ?
                  <TieredMenuItem
                    defaultHref='/settings/notifications'
                    subItems={[
                      ['Notifications', '/settings/notifications'],
                      ['Documents', '/settings/documents'],
                      ['Account', '/settings/account'],
                      ['Profile', '/settings/profile/user', '/settings/profile/accounting'],
                      ['Billing', '/settings/billing/subscription', '/settings/billing/dispatch'],
                      ...(has_api_access_tab ? [['API', '/settings/API']] : [])
                    ]}
                    >
                  Settings
                  </TieredMenuItem>
                  : null
              }
              {
                user_type === USER_TYPE.CARRIER ||
                user_type === USER_TYPE.SHIPPER ||
                user_type === USER_TYPE.BROKER ||
                user_type === USER_TYPE.DISPATCHER ?
                  <MenuItem href='/help'>Help</MenuItem>
                  : null
              }
              <MenuItem href='/logout' id='logout'>Log Out</MenuItem>
            </Menu>
          }
          <div className='App__main' style={{ left: logged_in ? undefined : 0 }}>
            {
              logged_in ?
                <div className='App__header' style={{ top: dont_show_loggedoutmenu ? 0 : undefined }}>
                  <Nav />
                </div>
                :
                dont_show_loggedoutmenu ? null :
                <LoggedOutMenu />
            }
            <div className='App__children' style={{ top: logged_in ? undefined : dont_show_loggedoutmenu ? 0 : 70 }}>
              {children}
            </div>
          </div>
        </div>
        <Modals />
      </div>
    );
  }
});
