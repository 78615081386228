import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Link } from 'react-router-v4';
import lifecycle from 'recompose/lifecycle';
import mapProps from 'recompose/mapProps';
import getContext from 'recompose/getContext';
import withState from 'recompose/withState';
import moment from 'moment';
import validate from 'validate.js';

import { openModal } from 'actions/ui';
import { updateDebtorClientRelationship } from 'actions/admin/factoring/client';
import factoringclientByfactoringdebtor from 'actions/resourceBy/factoringdebtor/factoringclient';

import createTableItems from 'helpers/createTableItems';

import ResponsiveTable from 'components/pure/ResponsiveTable';
import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';
import DateTimeInput from 'components/pure/form/inputs/DateTimeInput';
import PennyInput from 'components/pure/form/inputs/PennyInput';
import FetchError from 'datatypes/FetchError';


const FactoringClientComponents = createTableItems(
  ({ data, remove_client, submitting, handleSubmit, noa_sent, set_noa_sent, noa_placed, noa_active, debtor_id, dispatch }) => {
    const inputDatesInvalid = noa_placed && noa_active && moment(noa_placed).isAfter(noa_active);
    const noaDatesInvalid = data.noa_placed && data.noa_active && moment(data.noa_placed).isAfter(data.noa_active);

    return [
      data.company_profile.name,
      data.owner_profile.email,
      moment(data.time_posted).format('l LTS'),
      <div style={{ maxWidth: '4em' }}>
        <Field name='discount_rate' component={LabeledInput} type='number' />
      </div>,
      <div style={{ maxWidth: '8em' }}>
        <Field name='minimum_fee' component={PennyInput} type='number' />
      </div>,
      <div style={{ minWidth: '25em' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div>
            {
              noa_sent ?
                `Sent ${moment(noa_sent).format('l LTS')}`
                : 'NOA not sent'
            }
          </div>
          <button
            type='button'
            className='btn btn-orange'
            onClick={() => dispatch(openModal('placenoa', {
              client_id: data.id,
              debtor_id,
              onSent: () => set_noa_sent(moment().format()),
            }))}
            >
            {noa_sent ? 'Re-' : ''}Send NOA
          </button>
        </div>
        <div className='row'>
          <div className='col-md-6'>
            <Field
              name='noa_placed'
              component={DateTimeInput}
              pickerOptions={{ time: false }}
              label='NOA Placed'
            />
          </div>
          <div className='col-md-6'>
            <Field
              name='noa_active'
              component={DateTimeInput}
              pickerOptions={{ time: false, min: noa_placed ? moment(noa_placed).toDate() : undefined }}
              label='NOA Active'
            />
          </div>
        </div>
        { inputDatesInvalid && (
          <p className='text-danger'>NOA Active must be later than NOA Placed</p>
        )}
        { noaDatesInvalid && (
          <p className='text-warning'>WARNING: NOA Active must be later than NOA Placed</p>
        )}
      </div>,
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <Link to={`/admin/factoring/client/${data.id}`}><button className='btn btn-orange' style={{ marginBottom: 5 }}>More</button></Link>
        <button className='btn btn-danger' type='button' onClick={() => remove_client(data.id)} style={{ marginBottom: 5 }}>X</button>
        <button className='btn btn-orange' disabled={submitting || inputDatesInvalid} onClick={handleSubmit} style={{ marginBottom: 5 }}>Update</button>
        <button className='btn btn-orange' disabled={submitting} onClick={() => dispatch(openModal('factoringclientdebtorrelationauditlog', { id: data.relationship_id }))} style={{ marginBottom: 5 }}>Audit Trail</button>
      </div>,
    ];
  },
  compose(
    getContext({
      params: PropTypes.object,
    }),
    connect((state, { params, data }) => ({
      params,
      noa_placed: formValueSelector(`FactoringClientClientRateForm#${data.id}`)(state, 'noa_placed'),
      noa_active: formValueSelector(`FactoringClientClientRateForm#${data.id}`)(state, 'noa_active'),
    }), null),
    mapProps(({ params, data, noa_placed, noa_active, ...rest }) => ({
      data,
      ...rest,
      form: `FactoringClientClientRateForm#${data.id}`,
      initialValues: {
        discount_rate: data.discount_rate,
        minimum_fee: data.minimum_fee,
        should_fasttrack: data.should_fasttrack,
        noa_placed: data.noa_placed ? moment(data.noa_placed) : undefined,
        noa_active: data.noa_active ? moment(data.noa_active) : undefined,
      },
      noa_placed,
      noa_active,
      debtor_id: params.id,
      async onSubmit(fields, dispatch) {
        const diff = {};
        Object.keys(fields)
          .forEach(key => {
            const value = fields[key];
            const dataValue = data[key];
            if (value !== dataValue) {
              diff[key] = value;
            }
          });
        try {
          await dispatch(updateDebtorClientRelationship({
            debtor_id: params.id,
            client_id: data.id,
            ...diff,
          }));
          dispatch(openModal('success', { message: 'Updated factoring debtor.' }));
        }
        catch (err) {
          console.warn(err);
          if (err instanceof FetchError && err.status === 400) {
            try {
              const json = JSON.parse(err.message);
              if (json && json[0]) {
                dispatch(openModal('error', { message: json[0] }));
                return;
              }
            }
            catch (e) {}
          }
          if (err.message && err.message.minimum_fee) {
            const message = err.message.minimum_fee[0];
            dispatch(openModal('warning', { message: `${message}\n Contact Super Admin for any other values.` }));
          }
          else {
            dispatch(openModal('error', { message: 'Failed to update factoring debtor.' }));
          }
        }
      },
    })),
    withState('noa_sent', 'set_noa_sent', ({ data }) => data.noa_sent),
    reduxForm({
      validate(fields) {
        return validate(fields, {
          rate: {
            numericality: {
              greaterThanOrEqualTo: 0,
            },
          },
          noa_active: {
            creldatetime: {
              earliest: 'noa_placed',
            },
          },
        });
      },
    }),
  ),
);

const FactoringClientTable = compose(
  connect(
    (state, { id }) => {
      const factoringclient = state.resourceBy.factoringdebtor.factoringclient[id];
      return {
        ...factoringclient,
        debtors: state.resource.factoringclient,
      };
    },
    (dispatch, { id }) => ({
      fetchNext: () => dispatch(factoringclientByfactoringdebtor.fetchNext(id)),
      remove_client(client) {
        dispatch(openModal('confirm', {
          type: 'factoringdebtorclient_delete',
          args: [id, client, { client }],
        }));
      },
    }),
  ),
)(({ ids = [], isFetching = false, fetchNext, debtors, remove_client, ordering, ...props }) =>
  <ResponsiveTable
    containerProps={{
      className: 'col-fill',
    }}
    isFetching={isFetching}
    fetchNext={fetchNext}
    sortable={true}
    currentOrder={ordering}
    headers={['DBA', 'Contact Email', 'Created At', 'Rate', 'Minimum Fee', 'NOA Summary', 'Actions']}
    placeholder='No results'
    TableItem={FactoringClientComponents.TableItem}
    BlockTableItem={FactoringClientComponents.BlockTableItem}
    items={ids.map(id => ({
      ...debtors[id],
      remove_client,
    }))}
  />
);

const FactoringClientPayments = compose(
  connect(
    null,
    (dispatch, { params: { id } }) => ({
      update() {
        dispatch(factoringclientByfactoringdebtor.clear(id));
        return dispatch(factoringclientByfactoringdebtor.fetch(id));
      },
    }),
  ),
  lifecycle({
    componentDidMount() {
      this.props.update();
    },
    componentDidUpdate() {
      this.props.update();
    },
  }),
)(({ params: { id } }) =>
  <div className='col-fill' style={{ padding: '0 1em' }}>
    <FactoringClientTable id={id} />
  </div>
);

export default FactoringClientPayments;
