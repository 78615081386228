/**
 * Component to display a table for the Load Details page for loads in the reverse direction
 * @module components/pure/LoadReverseTable
 * @since 3.0.0
 * @requires components/container/LoadReverseTableItem
 * @requires components/pure/ResponsiveTable
 * @property {object} props
 * @property {object} props.parent_id - the ID of the load of which these loads are the reverse route
 * @property {string[]} props.ids
 * @property {boolean} [props.isFetching = false]
 * @property {function} props.fetchNext
 * @property {any} [props....rest] - props passed to containing component
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import mapProps from 'recompose/mapProps';

import { LoadReverseBlockTableItem, LoadReverseTableItem } from './LoadReverseTableItem';
import ResponsiveTable from 'components/pure/ResponsiveTable';


const LoadReverseTable = compose(
  mapProps(({ parent_id, ids, isFetching = false, fetchNext }) => ({
    containerProps: {
      style: { padding: '1em 0', height: '100%' },
    },
    headers: ['Type', 'D.H.', 'Origin', 'Destination', 'Weight', 'Length', 'Ready', 'Close', 'Deliver', 'More'],
    items: ids ? ids.map(id => ({ parent_id, id })) : [],
    isFetching: isFetching,
    placeholder: <div className='help-block'>No backhaul loads currently found</div>,
    fetchNext: fetchNext,
    BlockTableItem: LoadReverseBlockTableItem,
    TableItem: LoadReverseTableItem,
  })),
)(ResponsiveTable);

LoadReverseTable.propTypes = {
  ids: PropTypes.arrayOf(PropTypes.string),
  isFetching: PropTypes.bool,
  fetchNext: PropTypes.func,
};

export default LoadReverseTable;
