import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import mapProps from 'recompose/mapProps';

import { setViewed } from 'actions/resource/load';

import Resource from 'components/hoc/Resource';
import LoadMobile2 from 'components/pure/LoadMobile2';
import spinnerIfFetching from 'components/hoc/spinnerIfFetching';
import hiddenPostBranch from 'components/hoc/hiddenPostBranch';
import scrollToItem from 'components/hoc/scrollToItem';
import HiddenPostMobile from 'components/container/HiddenPostMobile';


export default compose(
  connect(
    (state, ownProps) => {
      const load = state.resource.load[ownProps.id];
      const scroll_to_item = state.ui.scroll_to_item;
      return {
        load,
        scroll_to_item,
      };
    },
    {
      setViewed,
    },
  ),
  spinnerIfFetching('load'),
  hiddenPostBranch(HiddenPostMobile, { idProp: ['load', 'data', 'id'] }),
  mapProps(({ dispatch, setViewed, load, ...rest }) => ({
    ...rest,
    onClick: () => setViewed(load.data.id),
    load,
    id: load.data.user_id,
  })),
  Resource('user', { resourcePropName: 'user' }),
  mapProps(({ dispatch, ...rest }) => ({ ...rest })),
  scrollToItem({
    idPropName: ['load', 'data', 'id'],
  }),
)(LoadMobile2);
