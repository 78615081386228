import INITIAL_STATE from '../../INITIAL_STATE';
const initialState = INITIAL_STATE.admin.factoringtransactionpayments;

import factoringtransactionpaymentsPaginatedResource from 'actions/admin/factoringtransactionpayments';


export default function financespaymentsPaginatedReducer(state = initialState, action) {
  state = factoringtransactionpaymentsPaginatedResource.reduce(state, action);
  return state;
}
