import React, { useCallback, useEffect, useState } from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import get from 'lodash/get';

import { fetchFactoringProfile } from 'actions/user';

import { CenteredSpinner } from 'components/pure/Spinner';

import Wallet from './Wallet';
import BrokerStripeWallet from './BrokerStripeWallet';
import { ContextProvider } from './context/WalletContext';

function BrokerWallet({ factoring, fetchFactoringProfile, ...props }) {

    const [isFetching, setIsFetching] = useState(true);

    useEffect(() => {
        async function asyncFunction() {
            try {
                await fetchFactoringProfile();
            } catch (error) { }
            setTimeout(() => setIsFetching(false), 200);
        }
        asyncFunction()
    }, []);

    if (isFetching) {
        return <CenteredSpinner />
    }

    return (
        <ContextProvider >
            {!factoring?.stripe_enabled ? (
                <Wallet {...props}/>
            ) : <BrokerStripeWallet {...props} />
            }
        </ContextProvider>
    )
}

export default compose(
    connect(state => ({
        factoring: get(state, 'user.factoring.data', {}),
    }), {
        fetchFactoringProfile,
    })
)(BrokerWallet)