import React from 'react';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import withState from 'recompose/withState';
import mapProps from 'recompose/mapProps';

import { openModal } from 'actions/ui';

import Resource from 'components/hoc/Resource';
import ResourceBy from 'components/hoc/ResourceBy';
import Header from 'components/pure/Header';
import CenteredColumn from 'components/pure/CenteredColumn';
import NormalInfiniteScroll from 'components/pure/NormalInfiniteScroll';
import LoadMobile from 'components/pure/LoadMobile4';
import Dropdown from 'components/pure/Dropdown';
import LoadStatusForm from 'components/pure/form/LoadStatusForm';


const SavedLoadTableItem = compose(
  Resource('load', { resourcePropName: 'load' }),
)(LoadMobile);

const SavedLoadFilter = ({ filter, setFilter }) =>
  <Dropdown
    style={{
      display: 'inline-block',
      float: 'right',
    }}
    items={LoadStatusForm.options.map(({ text, value }) => <div role='button' onClick={() => setFilter(value)}>{text}</div>)}
    justifyRight={true}
    >
    <div
      role='button'
      style={{
        padding: '10px 15px',
      }}
      >
      {'Filter: '}
      {
        filter ?
          <span>
            {LoadStatusForm.options.find(({ value }) => value === filter).text}
          </span>
          :
          'None'
      }
      {' '}
      <span className='glyphicon glyphicon-triangle-bottom' />
    </div>
  </Dropdown>
  ;

export default compose(
  connect(
    state => ({ user_id: state.user.id }),
    {
      openModal,
    }
  ),
  withState('filter', 'setFilter', null),
  mapProps(({ filter, ...rest }) => ({
    ...rest,
    filter,
    fetchArgs: {
      status: filter,
    },
  })),
  ResourceBy('user', 'savedload', { idPropName: ['user_id'] }),
)(({ user_id, openModal, item: { isFetching, ids } = {}, actions: { fetchNext }, filter, setFilter }) =>
  <CenteredColumn
    xs={12}
    sm={10}
    className='col-fill'
    columnProps={{
      className: 'col-fill',
    }}
    >
    <div style={{ flexGrow: 0 }}>
      <Header style={{ display: 'inline-block' }}>Saved Loads</Header>
      <SavedLoadFilter filter={filter} setFilter={setFilter} />
    </div>
    <NormalInfiniteScroll
      isFetching={isFetching}
      onScrollBottom={fetchNext}
      placeholder={<div className='help-block text-center'>No loads saved (yet)</div>}
      >
      {
        !ids ? null :
          ids.map(id =>
            <SavedLoadTableItem
              key={id}
              id={id}
              unSave={() => openModal('confirm', {
                type: 'savedload_delete',
                args: [user_id, id, { load: id }],
              })}
            />
          )
      }
    </NormalInfiniteScroll>
  </CenteredColumn>
);
