/**
 * Component to display circles, routes and points on a Google map. See {@link https://developers.google.com/maps/documentation/javascript/3.exp/reference}
 * @module components/pure/GoogleMap
 * @since 3.0.0
 * @property {object} props
 * @property {object[]} [props.circles] - a list of circles to display
 * @property {number} props.circles[].radius - the radius of this circle
 * @property {number} props.circles[].lat - the latitude value of this circle
 * @property {number} props.circles[].lng - the longitude value of this circle
 * @property {string[][]} [props.routes] - a list of [polyline]{@ https://github.com/mapbox/polyline} strings to display
 * @property {object[]} [props.points] - a list of points to display
 * @property {object} [props.points[]] - an object representing a point, has one of two forms:
 * @property {number} [props.points[].lat] - the latitude value of this point
 * @property {number} [props.points[].lng] - the lng value of this point
 * @property {object} [props.points[].position] - the position of this point
 * @property {number} [props.points[].position.lat] - the latitude value of this point
 * @property {number} [props.points[].position.lng] - the lng value of this point
 * @property {string | object} [props.points[].icon] - an icon to display at this point
 * @property {string} [props.points[].icon.url] - the absolute url to an image to display for this icon
 * @property {object} [props.points[].icon.labelOrigin] - an x,y point to display the label of this icon at
 * @property {number} [props.points[].icon.labelOrigin.x]
 * @property {number} [props.points[].icon.labelOrigin.y]
 * @property {string | object} [props.points[].label] - a label to display under this point's icon
 * @property {string} [props.points[].label.color] - the color of the label
 * @property {string} [props.points[].label.fontWeight] - the fontWeight of the label
 * @property {string} [props.points[].label.text] - the text of the label
 * @property {any} [props....rest] - props passed to containing component
 */
/* global google */
import 'styles/GoogleMap';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import polyline from 'polyline';
import flatten from 'lodash/flatten';


class GoogleMap extends Component {

  shouldComponentUpdate(newProps) {
    this.removeComponents();
    this.addPoints(newProps.points, this._map.google);
    this.addRoutes(newProps.routes, this._map.google);
    this.addCircle(newProps.circles, this._map.google);
    this.centerAroundPoints();
    // don't re-render our container
    return false;
  }

  componentDidMount() {
    let map;
    // check if we've made our cache yet
    if (google.__CACHED_MAPS__ === undefined) {
      google.__CACHED_MAPS__ = [];
    }
    else {
      // check cache for a free map if we've already created it
      for (const cached_map of google.__CACHED_MAPS__) {
        if (cached_map.free) {
          map = cached_map;
          break;
        }
      }
    }
    // if we didn't find a free map, make another one
    if (map === undefined) {
      map = {
        free: false,
        google: this.createMap(),
      };
      google.__CACHED_MAPS__.push(map);
    }

    // add map to element
    this._mapNode.appendChild(map.google.getDiv());
    google.maps.event.trigger(map.google, 'resize');
    this._map = map;

    this.addPoints(this.props.points, this._map.google);
    this.addRoutes(this.props.routes, this._map.google);
    this.addCircle(this.props.circles, this._map.google);
    this.centerAroundPoints();
  }

  componentDidUpdate() {
    google.maps.event.trigger(this._map.google, 'resize');
  }

  componentWillUnmount() {
    this.removeComponents();
    // remove the map div from our map ref
    this._mapNode.removeChild(this._mapNode.childNodes[0]);
    // set map as free for future use
    this._map.free = true;
    this._map = null;
  }

  static propTypes = {
    circles: PropTypes.arrayOf(PropTypes.shape({
      radius: PropTypes.number.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired,
    })),
    routes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
    points: PropTypes.arrayOf(PropTypes.oneOfType([
      PropTypes.shape({
        lat: PropTypes.number.isRequired,
        lng: PropTypes.number.isRequired,
      }),
      PropTypes.shape({
        position: PropTypes.shape({
          lat: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
          ]).isRequired,
          lng: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.func,
          ]).isRequired,
        }),
        icon: PropTypes.oneOfType([
          PropTypes.shape({
            url: PropTypes.string,
            labelOrigin: PropTypes.shape({
              x: PropTypes.number,
              y: PropTypes.number,
            }),
          }),
          PropTypes.string,
        ]),
        label: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.shape({
            color: PropTypes.string,
            fontWeight: PropTypes.string,
            text: PropTypes.string,
          }),
        ]),
      }),
    ])),
    mapOptions: PropTypes.shape({
      draggable: PropTypes.bool,
    }),
  };

  static defaultProps = {
    routes: [],
    points: [],
    circles: [],
  };

  createMap() {
    const { mapOptions } = this.props;
    const options = {
      zoom: 4,
      // scaleControl: true,
      // center of US
      center: {
        lat: 37.2583527,
        lng: -96.6529332,
      },
      // maxZoom: 9,
      scrollwheel: false,
      ...mapOptions,
    };
    return new google.maps.Map(document.createElement('div'), options);
  }

  static MAX_ZOOM = 11;
  centerAroundPoints() {
    if (this._points.length === 0) {
      return;
    }
    const bounds = new google.maps.LatLngBounds();
    this._points.forEach(point => bounds.extend(point.getPosition()));
    this._circles.forEach(circle => bounds.union(circle.getBounds()));
    this._map.google.setCenter(bounds.getCenter());
    this._map.google.fitBounds(bounds);
    google.maps.event.addListenerOnce(this._map.google, 'idle', () => {
      if (this._map && this._map.google && this._map.google.getZoom() > GoogleMap.MAX_ZOOM) {
        this._map.google.setZoom(GoogleMap.MAX_ZOOM);
      }
    });
  }

  addRoutes(routes, google_map) {
    const _routes = routes.map(route => (
      new google.maps.Polyline({
        path: flatten(route.map(p => polyline.decode(p).map(point => ({
          lat: point[0],
          lng: point[1],
        })))),
        map: google_map,
        clickable: false,
        strokeColor: '#FF0000',
        strokeOpacity: 1.0,
        strokeWeight: 2,
      })
    ));
    this._routes = _routes;
  }

  addCircle(circles, google_map) {
    const _circles = circles.map(({ lat, lng, radius }) =>
      new google.maps.Circle({
        clickable: false,
        strokeColor: '#00FF00',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#00FF00',
        fillOpacity: 0.15,
        map: google_map,
        center: { lat, lng },
        radius: radius / 0.00062137,
      })
    );
    this._circles = _circles;
  }

  addPoints(points, google_map) {
    const _points = points.map(point => {
      const marker = new google.maps.Marker({
        ...(() => {
          if (point.position) {
            return point;
          }
          return {
            position: point,
          };
        })(),
        map: google_map,
      });
      if (point.onClick) {
        marker.addListener('click', () => {
          point.onClick(marker, this);
        });
      }
      return marker;
    });
    this._points = _points;
  }

  removeComponents() {
    if (this._routes) {
      this._routes.forEach(route => route.setMap(null));
      this._routes = null;
    }
    if (this._points) {
      this._points.forEach(point => {
        point.setMap(null);
        google.maps.event.clearListeners(point, 'click');
      });
      this._points = null;
    }
    if (this._circles) {
      this._circles.forEach(circle => circle.setMap(null));
      this._circle = null;
    }
  }

  render() {
    const { circles, routes, points, ...rest } = this.props;
    return (
      <div {...rest} ref={ref => this._mapNode = ref} className='GoogleMap col-fill' />
    );
  }
}

export const TruckIcon = {
  url: '/public/image/icon/Truckwds.png',
  labelOrigin: { x: 25, y: 9 },
};

export const TruckIconLeft = {
  url: '/public/image/icon/Truckwds_left.png',
  labelOrigin: { x: 48, y: 9 },
};

export const LoadIcon = {
  url: '/public/image/icon/Palletwds.png',
  labelOrigin: { x: 15, y: 9 },
};

export default GoogleMap;
