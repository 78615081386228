import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux-v3';
import { connect } from 'react-redux-v5';
import { Field, formValueSelector } from 'redux-form';
import getContext from 'recompose/getContext';
import * as validate from 'helpers/validate';
import cs from 'classnames';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';
import SetValueInput from 'components/pure/form/inputs/SetValueInput';
import NumberInput from 'components/pure/form/inputs/NumberInput';


let MCorDOTorEINInputs;

const MCorDOTorEINInput = ({ selected_number, selected_number_prop, ...rest }) =>
  <div
    className={cs('input-group', {
      'input-group-lg': /input-lg/.test(rest.className),
      'input-group-sm': /input-sm/.test(rest.className),
    })}
    >
    <NumberInput
      {...rest}
      className={cs('form-control', rest.className)}
      placeholder={`${selected_number.toUpperCase()}:`}
    />
    <span className='input-group-btn'>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTorEINInputs.MC} >
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTorEINInputs.MC })} type='button'>MC</button>
      </Field>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTorEINInputs.DOT} >
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTorEINInputs.DOT })} type='button'>DOT</button>
      </Field>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTorEINInputs.EIN} >
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTorEINInputs.EIN })} type='button'>TAX ID</button>
      </Field>
      <Field name={selected_number_prop} component={SetValueInput} value_to_set={MCorDOTorEINInputs.DUNS} >
        <button className={cs('btn btn-default', { active: selected_number === MCorDOTorEINInputs.DUNS })} type='button'>DUNS</button>
      </Field>
    </span>
  </div>
  ;

MCorDOTorEINInputs = compose(
  getContext({
    _reduxForm: PropTypes.object,
  }),
  connect(
    (state, { _reduxForm, selected_number_prop = 'selected_number' }) => {
      const selected_number = formValueSelector(_reduxForm.form)(state, selected_number_prop) || MCorDOTorEINInputs.MC;
      return {
        selected_number,
        selected_number_prop,
      };
    },
    null,
    (stateProps, dispatchPropos, { _reduxForm, ...rest }) => {
      return {
        ...stateProps,
        ...rest,
      };
    },
  ),
)(({ selected_number, selected_number_prop, fieldProps, containerProps }) =>
  <div
    {...containerProps}
    >
    <Field
      name='dot'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTorEINInputs.DOT },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTorEINInput}
    />
    <Field
      name='mc'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTorEINInputs.MC },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTorEINInput}
      validate={validate.compose(
        validate.vjs.length({
          maximum: 8,
        }),
      )}
    />
    <Field
      name='tax_id'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTorEINInputs.EIN },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTorEINInput}
      validate={validate.compose(
        validate.vjs.length({
          tokenizer: value => {
            return value.replace('-', '');
          },
          is: 9,
        }),
      )}
    />
    <Field
      name='duns'
      {...fieldProps}
      component={LabeledInput}
      containerProps={{
        className: { hidden: selected_number !== MCorDOTorEINInputs.DUNS },
      }}
      selected_number={selected_number}
      selected_number_prop={selected_number_prop}
      ComponentClass={MCorDOTorEINInput}
      validate={validate.compose(
        validate.vjs.length({
          is: 9,
        }),
      )}
    />
  </div>
);

MCorDOTorEINInputs.MC = 'mc';
MCorDOTorEINInputs.DOT = 'dot';
MCorDOTorEINInputs.EIN = 'tax_id';
MCorDOTorEINInputs.DUNS = 'duns';
export default MCorDOTorEINInputs;
