/**
 * Actions relating to the paginated lead list
 * @module actions/admin/leads
 * @since 3.0.0
 * @requires datatypes/PaginatedResource
 * @requires actions/resource/lead
 */
import UrlAssembler from 'url-assembler';

import PaginatedResource from '../../datatypes/PaginatedResource';
import leadResource from '../resource/lead';


const leadPaginatedResource = new PaginatedResource('admin_leads', {
  mountPoint: ['admin', 'leads'],
  url: new UrlAssembler('admin/factoring/leads'),
  baseResource: leadResource,
});

export default leadPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = leadPaginatedResource;
