import colors from 'styles/colors.json';
import React from 'react';
import PropTypes from 'prop-types';

import PureCheckbox from 'components/pure/Checkbox';


/**
 @param {object} input
 @param {function} input.onChange
 @param {any} input.value
 @param {object} [meta]
 @param {any} [meta.error]
 @param {boolean} [meta.touched]
 @param {node} [label]
 @param {node} [errorLabel]
 @param {object} [checkboxProps]
 @param {boolean} [disabled]
 */
const Checkbox = ({
  input,
  meta = {},
  label,
  errorLabel,
  checkboxProps = {},
  disabled,
  spacing,
  labelStyle = {
    marginBottom: 0,
  },
  ...rest
}) =>
  <div
    {...rest}
    style={{
      display: 'inline-block',
      color: meta.error && meta.touched ? colors.RED : undefined,
      ...rest.style,
    }}
    >
    <label
      style={labelStyle}
      >
      <PureCheckbox
        {...checkboxProps}
        style={{
          fontSize: '1.4em',
          ...checkboxProps.style,
        }}
        disabled={disabled}
        value={input.value}
        onClick={input.onChange}
        onChange={input.onChange}
      />
      {spacing ? spacing : '\u00a0'}
      {label}
    </label>
    {'  '}
    {errorLabel && meta.error && meta.touched && <label style={{ margin: 0, verticalAlign: 'middle' }}>{errorLabel}</label>}
  </div>
  ;

Checkbox.propTypes = {
  label: PropTypes.node,
  errorLabel: PropTypes.node,
  checkboxProps: PropTypes.object,
};

export default Checkbox;
