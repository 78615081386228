import React from 'react';
// import { d3 } from 'react-d3-components';

// import HorizontalScrollingBarGraph from 'components/pure/HorizontalScrollingBarGraph';
import ColoredCircle from 'components/pure/ColoredCircle';

const SourceGraphFactoringClient = ({ data }) => {
  const bars = [];
  data
    .slice()
    .sort((a, b) => (b.approved_count + b.declined_count) - (a.approved_count + a.declined_count))
    .forEach(sourceData => {
      if (sourceData.declined_count + sourceData.approved_count === 0) {
        return;
      }
      ['declined_count', 'approved_count'].forEach((stat, i) => {
        let bar = bars[i];
        if (!bar) {
          bar = {
            label: stat,
            values: [],
          };
          bars.push(bar);
        }
        bar.values.push({
          x: sourceData.source ? sourceData.source : 'No source',
          y: sourceData[stat],
        });
      });
    })
  ;
  const total_approved = data.reduce((acc, curr) => acc + curr.approved_count, 0);
  const total_declined = data.reduce((acc, curr) => acc + curr.declined_count, 0);
  const total = total_approved + total_declined;

  // const colorScale = d3.scale.ordinal();
  // colorScale.domain(['declined_count', 'approved_count']);
  // colorScale.range(['#a94442', '#3c763d']);

  return (
    <div>
      <h4>Factoring Clients</h4>
      {
        bars.length ?
          <div>
            <div>
              <p className='text-capitalize'>
                <ColoredCircle size={12} color='#a94442' />
                Denied
              </p>
              <p className='text-capitalize'>
                <ColoredCircle size={12} color='#3c763d' />
                Approved
              </p>
            </div>
            {/* <HorizontalScrollingBarGraph
              minWidth={data.reduce((acc, curr) => {
                return acc + curr.source.length * 3;
              }, 0)}
              chartProps={{
                colorScale,
                data: bars,
                tooltipHtml: (_x, _y0, y) => y.toString(10),
              }}
            /> */}
            <table className='table table-condensed table-striped table-hover'>
              <thead>
                <tr>
                  <th>Source</th>
                  <th>Approved</th>
                  <th>Declined</th>
                  <th>% Total</th>
                </tr>
              </thead>
              <tbody>
                {data
                  .filter(source => source.approved_count + source.declined_count > 0)
                  .sort((a, b) => (b.approved_count + b.declined_count) - (a.approved_count + a.declined_count))
                  .map(({ source, approved_count, declined_count }, i) =>
                    <tr key={i}>
                      <th>{source || 'No source'}</th>
                      <td>{approved_count} {approved_count ? `(${(approved_count / total_approved * 100).toFixed(2)}%)` : '(0%)'}</td>
                      <td>{declined_count} {declined_count ? `(${(declined_count / total_declined * 100).toFixed(2)}%)` : '(0%)'}</td>
                      <td>{approved_count + declined_count ? `${((approved_count + declined_count) / total * 100).toFixed(2)}%` : '(0%)'}</td>
                    </tr>
                  )
                }
              </tbody>
            </table>
          </div>
          :
          <h5>No data.</h5>
      }
    </div>
  );
};

export default SourceGraphFactoringClient;
