/**
 * Component that displays the factoring client search form
 * @module components/pure/form/FactoringClientSearchForm
 * @since 3.0.1
 * @requires actions/ui
 * @requires actions/search/user
 * @requires components/pure/form/inputs/LabeledInput
 * @requires components/pure/form/inputs/LabeledDropdown
 * @requires components/pure/form/SubmitButton
 */
import React from 'react';
import { compose } from 'redux-v3';
import { Field, reduxForm } from 'redux-form';

import { openModal } from 'actions/ui';
import { clear, fetch } from 'actions/search/factoringclient';
import CheckboxInput from 'components/pure/form/inputs/CheckboxInput';

import LabeledInput from 'components/pure/form/inputs/LabeledInput';


export default compose(
  reduxForm({
    form: 'ClientSearch',
    destroyOnUnmount: false,
    async onSubmit(fields, dispatch) {
      try {
        dispatch(clear());
        await dispatch(fetch({
          company_profile__dot: fields.dot,
          company_profile__mc: fields.mc,
          company_profile__name: fields.dba,
          owner_profile__email: fields.email,
          owner_profile__name: fields.contact_name,
          address: fields.address,
          phone_number: fields.contact_phone,
          broker_clients: fields.broker_clients,
        }));
      }
      catch (err) {
        console.warn(err);
        dispatch(openModal('error', { message: 'Failed to search.' }));
      }
    },
  }),
)(({ handleSubmit, submitting, reset }) =>
  <form onSubmit={handleSubmit}>
    <Field name='email' component={LabeledInput} label='Email' />
    <Field name='dba' component={LabeledInput} label='Business Name' />
    <Field name='contact_phone' component={LabeledInput} label='Phone' />
    <Field name='contact_name' component={LabeledInput} label='Contact Name' />
    <Field name='mc' component={LabeledInput} label='MC #' type='number' />
    <Field name='dot' component={LabeledInput} label='DOT #' type='number' />
    <Field name='address' component={LabeledInput} label='Address' />
    <Field name='broker_clients' component={CheckboxInput} label='Only Brokers? ' />
    <div className='text-right margin-top-medium'>
      <button className='btn btn-default pull-left' type='button' onClick={reset}>Reset</button>
      <button name='search' className='btn btn-orange' disabled={submitting} type='submit'>SEARCH</button>
    </div>
  </form>
);
