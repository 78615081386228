import UrlAssembler from 'url-assembler';

import PaginatedResource from 'datatypes/PaginatedResource';
import financespaymentResource from '../resource/financespayment';


const financespaymentsPaginatedResource = new PaginatedResource('financespayments', {
  mountPoint: ['admin', 'financespayments'],
  url: new UrlAssembler('debtor/:id/funding_requests/open'),
  baseResource: financespaymentResource,
});


export default financespaymentsPaginatedResource;
export const {
  fetch,
  fetchNext,
  clear,
} = financespaymentsPaginatedResource;
